import {
    defaultOptionsPhoneOwner, StateBasicForm,
    usePrepareStateForm,
    usePrepareValidationFields
} from "../../../../../containers/PatientField";
import {useMemo} from "react";
import _ from "lodash";
import {basicFields} from "../helper/basicFields";
import {timesSelectedList} from "../helper/timesSelectedList";
import {PropsUseInitialStateForm} from "../types/hooks";

export default function useInitialStateForm({fields}: PropsUseInitialStateForm) {
    const prepareStateForm = usePrepareStateForm({fields})
    const prepareValidationFields = usePrepareValidationFields({fields})

    return useMemo(() => {
        const basic = {
            ...prepareStateForm(basicFields),
            optionsPhoneOwner: _.cloneDeep(defaultOptionsPhoneOwner),
            validationFields: {
                ...prepareValidationFields(basicFields)
            }
        } as StateBasicForm

        return {
            basic,
            branches: [],
            directionsNames: [],
            directions: null,
            doctor: null,
            wantThisDoctor: false,
            timeSelected: timesSelectedList[0],
            timeCounter: 3,
            date: null,
            comment: '',
            validationFields: {
                directions: false,
                date: false
            },
            currentPatient: undefined,
            relations: {
                relationsList: []
            }
        }

    }, [])
}
