import React from "react";
import {usePatientField} from "../containers/PatientField";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";
import YesAndNoRadio from "../../../components-ui/Inputs/Radio/components/YesAndNoRadio";

export default function Boolean() {
    const {field, onChangeField, isEdit, value, error, onChangeValidationItem} = usePatientField()
    let children = null
    if (isEdit) {
        if (!!field.nullable) {
            children = <YesAndNoRadio checked={value}
                                      isError={error as boolean}
                                      onChange={val => {
                                          onChangeField(val, field.name)
                                          if (!!onChangeValidationItem) onChangeValidationItem(field.name)
                                      }}/>
        } else {
            children = <Checkbox label={''}
                                 checked={value}
                                 onChange={val => onChangeField(val, field.name)} />
        }

    } else {
        children = value ? 'Да' : 'Нет'
    }
    return (
        <>
            {children}
        </>
    )
}
