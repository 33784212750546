import * as React from "react";
import {RowDesc, RowItemDesc} from "../styledStages";
import {CellDesc} from "../typesStages";
import {StagedPlanItem} from "../../../containers/TreatmentPlan/types";
import Helper from "../../../common/helpers/main";
import { useDrag } from 'react-dnd'
import {PlanItemsStatuses} from "../../../containers/TreatmentPlansConstructor/types";
import {setIsScrollArea} from "../../../redux/actions/commonActions";
import {useDispatch} from "react-redux";


interface PItemServices {
    cellDesc: CellDesc[],
    stagedPlanItem: StagedPlanItem,
    itemsStatuses: PlanItemsStatuses | null,
    stageIndex: number
}

export default function ItemServices({cellDesc, stagedPlanItem, itemsStatuses, stageIndex} : PItemServices) {

    return (
        <RowDesc isStageConfirmed={stagedPlanItem.isStageConfirmed}>
            {cellDesc.map(cell => <CellServices cell={cell}
                                                stagedPlanItem={stagedPlanItem}
                                                itemsStatuses={itemsStatuses}
                                                stageIndex={stageIndex}
                                                key={stagedPlanItem.serviceName + '_' + cell.code} />)}
        </RowDesc>
    )
}

interface PCellServices {
    cell: CellDesc,
    stagedPlanItem: StagedPlanItem,
    itemsStatuses: PlanItemsStatuses | null,
    stageIndex: number
}

const CellServices = ({cell, stagedPlanItem, itemsStatuses, stageIndex} : PCellServices) => {
    const dispatch = useDispatch();
    const {itemCode, status} = stagedPlanItem;

    const [, drag] = useDrag({
        item: {
            itemCode,
            type: 'card',
            typeDrag: 'service',
            index: stageIndex
        },
        begin: () => {
            setTimeout(() => {
                dispatch(setIsScrollArea(true));
            }, 500)
        },
        end: () => {
            setTimeout(() => {
                dispatch(setIsScrollArea(false));
            }, 500)
        },
    })

    let name = '' as any;
    let isDnd = false;
    let refDrag = null;

    switch (cell.code) {
        case "service":
            isDnd = true;
            refDrag = drag;
            name = stagedPlanItem.serviceName;
            break;
        case "target":
            const target = stagedPlanItem.target;
            if (typeof target === "object") {
                name = target.join(', ');
            } else {
                name = target;
            }
            break;
        case "quantity":
            name = stagedPlanItem.quantity;
            break;
        case "price":
            name = Helper.Price.format(stagedPlanItem.price);
            break;
        case "status" :
            if (itemsStatuses !== null) {
                name = itemsStatuses[status]
            }
            break;

        default:
            name = '';
            break;
    }

    let newName = name + '';

    const propsItem = {
        width: cell.width,
        isDnd,
        isService: isDnd,
        ref: refDrag
    } as any;

    if (newName.length > cell.numberOfCharacters) {
        newName = name.slice(0, cell.numberOfCharacters) + '...';
        propsItem.title = name;
    }

    return <RowItemDesc key={stagedPlanItem.serviceName + '_' + cell.code} children={newName} {...propsItem}/>
};

