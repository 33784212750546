import React from 'react';
import {WrapContent} from "../../../styledUI";
import DoubleBar from "../../../../../components-ui/Charts/DoubleBar";
import {dataDoubleBar} from "./data/data";
import Helper from "../../../../../common/helpers/main";

const DoubleBarPage = () => {
    return (
        <>
            <h1>DoubleBar</h1>
            <WrapContent>
                <DoubleBar data={dataDoubleBar} width="90%" height={300}
                           formatterValue={value => Helper.String.numberFormat(value, 0)}
                           formatterCode={code => Helper.Date.formatDate(code, 'DD-MM-YY')}
                           formatterTitleTooltip={code => Helper.Date.formatDate(code, 'LL')}
                           formatterNameTooltip={code => code === 'barOne' ? 'Записались' : 'Пришли'}
                />
            </WrapContent>
        </>
    );
};

export default DoubleBarPage;
