import React, {FC, ReactChild, ReactNode} from 'react';
import TaskButtons from "./TaskButtons";
import {OnChangeSelectedScript, TaskCallCenter} from "../../../types/common";
import { SContainerWrap, STaskList } from '../../../../../containers/Tasks/style';

interface PropsTaskContent {
    buttons: ReactChild | ReactNode
    task: TaskCallCenter
    isDeadline?: boolean
    onChangeSelectedScript: OnChangeSelectedScript
}

const TaskContent: FC<PropsTaskContent> = (
    {
        children,
        buttons,
        task,
        isDeadline= false,
        onChangeSelectedScript
    }) => {

    return (
        <SContainerWrap isDeadline={isDeadline}>
            <STaskList>
                {children}
                <TaskButtons onChangeSelectedScript={onChangeSelectedScript} task={task} children={buttons} />
            </STaskList>
        </SContainerWrap>
    );
};

export default TaskContent;
