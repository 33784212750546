import styled from "styled-components";

interface IBox {
    withMargin?: boolean
}

export const Box = styled.div<IBox>`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #b8c0d0;
  border: 1px solid ${props => props.theme.colors.secondary};
  margin: ${props => props.withMargin ? "28px 32px 48px 33px" : '0'};
`;

interface IBoxBody {
    nonBorderBottom?: boolean
}

export const BoxBody = styled.div<IBoxBody>`
    padding: 30px 27px;
    ${props => !(!!props.nonBorderBottom) && `
        border-bottom: 1px solid ${props.theme.colors.borderGray};
    `}

`;

export const TableBox = styled.div`
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #b8c0d0;
`;

interface PTwoBoxes {
    margin?: string
}

export const TwoBoxes = styled.div.attrs({className: 'TwoBoxes'})<PTwoBoxes>`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: ${props => !!props.margin ? props.margin : 0};
    & > div {
        width: 49%;
    }
`

export const InfoBox = styled.div.attrs({className: 'InfoBox'})`
    padding-bottom: 45px;
`


export const InfoBoxWrap = styled.div.attrs({className: 'InfoBoxWrap'})`
    border-top: 1px solid ${props => props.theme.colors.lightBlue};
    padding: 17px 20px 0;
`

export const BoxColumn = styled.div.attrs({className: 'BoxColumn'})`
    display: flex;
    flex-direction: column;
`
