import React, {useState} from 'react';
import Typography from '../../../../../../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../../../../../../components-ui/Layout/Box';
import {Label} from '../../../../../../../../components-ui/DataDisplay/Label';
import {
    commonLabelOption, commonSelectOption,
} from '../../../../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import TextField from '../../../../../../../../components-ui/Inputs/TextField/TextField';
import Button from '../../../../../../../../components-ui/Inputs/Button/Button';
import {TemplateSalary} from '../../types';
import {Salary} from '../../../AddingEditingEmployee/types';
import ServerCommand from '../../../../../../../../common/server/server-command';
import {useModal} from '../../../../../../../../containers/DialogsManager/containers/Modal/Modal';

interface Props {
    pattern: TemplateSalary;
    rates: Pick<Salary, 'services' | 'group_services'>;
    switchType: 'services' | 'group_services';
    refreshDataFromServer: () => void
}

const SavePattern = ({pattern, rates, switchType, refreshDataFromServer}: Props) => {

    const {closeModal} = useModal()

    const isNewPattern = pattern.id === -1;


    const [name, setName] = useState(pattern.name);

    const [loader, setLoader] = useState(false)

    const onSave = () => {
        setLoader(true)
        const data: TemplateSalary = isNewPattern ? {
            type: switchType,
            name,
            parameters: rates[switchType] ?? {},
        } : {
            id: pattern.id,
            type: switchType,
            name,
            parameters: rates[switchType] ?? {},
        };
       ServerCommand.post("salary/template", data, () => {
           closeModal()
           refreshDataFromServer()
       }).finally(() => {
           setLoader(false)
       })
    };

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='middle-title' fontWeight='600' textAlign='center'
                        children={`${isNewPattern ? 'Создать' : 'Изменить'} новый шаблон`} />

            <Box margin='39px 0 0 0'>
                <Label labelText='Название шаблона' isRequired={true} {...commonLabelOption} >
                    <TextField onChange={setName} value={name} />
                </Label>
                <Label labelText='' {...commonLabelOption}>
                    <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                        <Button children='Сохранить'
                                size='md'
                                loader={loader}
                                variant={!!name ? 'success' : 'disabled'}
                                onClick={onSave}
                        />
                    </Box>
                </Label>
            </Box>
        </Box>
    );
};

export default SavePattern;
