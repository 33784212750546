import styled from "styled-components";
import {PropsSContainer, PropsSContainerItem, PropsSContainerWrap} from "../types/style";

export const SContainer = styled.div<PropsSContainer>`
    padding: ${props => props.padding || '30px 8px 32px 20px'} ;
    .icon-call-center {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        flex-shrink: 0;
        margin-top: 3px;
        &--missed {
            width: 16px;
        }
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`

export const SContainerWrap = styled.span<PropsSContainerWrap>`
    position: relative;
    display: inherit;
    cursor: ${props => !!props.onClick ? 'pointer' : 'inherit'};
    ${props => props.isPostponedTo && `
        opacity: 0.65;
    `}
    ${props => props.isDeadline && `
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 4px;
            height: 100%;
            left: 0;
            top: 0;
            background: ${props.theme.colors.danger};
            z-index: 1;
            border-radius: 4px 0 0 4px;
        }
    `}
    ${props => props.isOutgoing && `
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            left: -4px;
            top: -4px;
            border: 4px solid ${props.theme.colors.success};
            border-radius: 8px;
            z-index: -1;
        }
    `}
`

export const SContainerItem = styled.div<PropsSContainerItem>`
    padding: ${props => props.padding || '0 20px 0'};
    margin: ${props => !!props.margin ? props.margin : '0'};
    .STab {
        padding-bottom: 6px;
        padding-top: 20px;
        & > span::before {
            bottom: -7px;
        }
    }
`

export const SBoxFlex = styled.div<PropsSContainerItem>`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: ${props => !!props.margin ? props.margin : '0'};
    padding: ${props => !!props.padding ? props.padding : '0'};
    cursor: ${props => !!props.onClick ? 'pointer' : 'inherit'};
    flex-wrap: wrap;
`

export const SBoxFlexCenter = styled(SBoxFlex)<PropsSContainerItem>`
    align-items: center;
    .call-center-arrow-select {
        display: flex;
        width: 8px;
        height: 8px;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        transform: rotate(90deg);
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`
