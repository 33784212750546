import * as React from "react";
import {StandardModal, StandardModalTitle} from "../../../../components-ui/styledComponent/modals";
import Select from '../../../../components-ui/Inputs/Select/Select'
import {useState} from "react";
import Button from "../../../../components-ui/Inputs/Button/Button";
import {WrapAddModal} from "../styled";
import {useModal} from "../../../DialogsManager/containers/Modal/Modal";

interface PModalAddDoctor {
    doctors: any,
    addDoctor: any
}


export default function ModalAddDoctor({doctors, addDoctor}: PModalAddDoctor) {
    const {closeModal} = useModal();
    const [selected, setSelected] = useState<any>(null);

    return (
        <StandardModal className="modal-add-doctor">
            <StandardModalTitle>Добавление врача</StandardModalTitle>
            <WrapAddModal>
                <span>Врач</span>
                <Select options={doctors}
                        value={selected}
                        onChange={setSelected}
                        widthSelect="100%"
                        placeholder="не выбран"
                />
                <Button children="Добавить" size="md" onClick={() => {
                    addDoctor(+selected.value);
                    setSelected(null);
                    closeModal();
                }} variant={selected !== null ? "success" : "disabled"}/>
            </WrapAddModal>
        </StandardModal>
    )
}
