import React from "react";
import {FieldsPatient} from "../../../common/types/patient";
import {prepareFio} from "../helper/prepareTextField";
import PatientCard from "../../../content/PatientCard/PatientCard";
import {useDialogsManager} from "../../DialogsManager/DialogsManager";
import { STextPhone } from "../../PatientField/style/phoneView";

interface PTextEmail {
    relations: FieldsPatient[],
}

export default function TextRelations({relations}: PTextEmail) {
    const {showSlideModal} = useDialogsManager();
    if (!relations.length) return <>-</>;
    return (
        <div>
            {relations.map(patient => {
                return (
                    <div style={{marginBottom: '5px', cursor: 'pointer'}}>
                        <STextPhone onClick={event => {
                            event.stopPropagation();
                            showSlideModal(<PatientCard patientId={patient.id} />)
                        }}>{prepareFio(patient)}</STextPhone>
                    </div>
                )
            })}
        </div>
    )
}
