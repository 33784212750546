import React from "react";
import {usePatientField} from "../containers/PatientField";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import useDisplayPatientList from "../hooks/useDisplayPatientList";
import useDebounce from "../../../common/hooks/useDebounce";
import useSearchPatientList from "../../../common/hooks/useSearchPatientList";
import Helper from "../../../common/helpers/main";
import ResultSearchPatient from "../../../components/ResultSearchPatient/ResultSearchPatient";
import {PatientValues} from "../types/common";
import useWidthInput from "../hooks/useWidthInput";

export default function String() {
    const {
        field,
        value,
        isEdit,
        onChangeField,
        labelRef,
        loadingPatient,
        error,
        onChangeValidationItem,
    } = usePatientField();
    const widthInput = useWidthInput(field)
    const [displayPatientList, setDisplayPatientList] = useDisplayPatientList(labelRef);
    const debouncedValue = useDebounce(value, 500);
    const [patientList, loaderPatientList] = useSearchPatientList({value: debouncedValue, nameField: field.name});
    let children = null;


    const onClickPatient = (patient: PatientValues) => {
        if (!!loadingPatient) loadingPatient(patient);
        setDisplayPatientList(false)
    }

    if (isEdit) {
        children = (
            <TextField value={value}
                       widthInput={widthInput}
                       isError={error as boolean}
                       autoFocus={false}
                       mask={field.mask}
                       nameInput={field.name}
                       onChange={(val) => {
                           let newValue = val;
                           if (field.name === 'lastName' || field.name === 'name' || field.name === 'secondName') {
                               newValue = Helper.String.ucFirst(newValue)
                               if (field.name === 'lastName') {
                                   if (newValue.length >= 2) {
                                       if (!displayPatientList) setDisplayPatientList(true)
                                   } else {
                                       if (displayPatientList) setDisplayPatientList(false)
                                   }
                               }
                           }
                           onChangeField(newValue, field.name)
                           if (!!onChangeValidationItem) onChangeValidationItem(field.name)
                       }}
                       additionalContent={<ResultSearchPatient patientList={patientList}
                                                               displayPatientList={displayPatientList}
                                                               loaderPatientList={loaderPatientList}
                                                               minWidth='470px'
                                                               onClickPatient={onClickPatient}/>}
            />
        )
    } else {
        children = value
    }

    return (
        < >
            {children}
        </>
    )
}
