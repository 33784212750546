import React from 'react';
import Typography from '../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../components-ui/Layout/Box';
import useSalariesPage from './hooks/useSalariesPage';
import SalariesFilter from './components/SalariesFilter';
import SalariesTables from './components/SalariesTable';
import LoaderPage from '../../components/LoaderPage';

const SalariesPage = () => {

    const {
        getResponse,
        loaderPage,
        loaderTable,
        selectedMonth,
        onChangeMonth,
        onChangeYear,
        selectedBranches,
        setSelectedBranches,
        salaryReport,
        setSalaryReport
    } = useSalariesPage()

    return (
        <Box padding="62px 20px 80px 28px">
            <Typography variant="title" fontWeight="bold">Расчёт зарплаты</Typography>

            <SalariesFilter selectedBranches={selectedBranches}
                            setSalariesBranchesFilter={setSelectedBranches}
                            selectedMonth={selectedMonth}
                            onChangeYear={onChangeYear}
                            onChangeMonth={onChangeMonth}
            />
            {!loaderPage ?
                <SalariesTables salaryReport={salaryReport}
                                setSalaryReport={setSalaryReport}
                                loader={loaderTable}
                                selectedMonth={selectedMonth}
                                selectedBranches={selectedBranches}
                                loadingFromServer={getResponse}
                />
                :
                <LoaderPage height={338}/>
            }
        </Box>
    );
};

export default SalariesPage;
