import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonSelectOption, commonTextFieldOption} from "../../data";
import TextField from "../../../../../components-ui/Inputs/TextField/TextField";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {IStateCashReturn} from "../../types";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {useSelector} from "react-redux";

interface Props {
    state: IStateCashReturn
    setState: Dispatch<SetStateAction<IStateCashReturn>>
}

const Comment = ({state, setState}: Props) => {

    const handleComment = useCallback((comment: string) => {
        setState(prevState => ({...prevState, comment}))
    }, [setState])


    return (
        <>
            <Label labelText="Комментарий" {...commonLabelOption}>
                <TextField
                    {...commonTextFieldOption}
                    value={state.comment}
                    isTextArea={true}
                    onChange={handleComment}
                />
            </Label>

        </>
    );
};

export default Comment;
