import _ from "underscore";
import { IPatientField } from "../../PatientField";


export const pickEditableField = (field: IPatientField) => {
    return _.pick(field, 'title', 'name', 'type', 'required', 'nullable')
}

export const pickEditableFieldValues = (field: IPatientField) => {
    return _.pick(field, 'name', 'required', 'variants')
}
