import {useEffect, useMemo, useState} from "react";
import Helper from "../helpers/main";
import {useDateUpdate} from "./useDateUpdate";

interface Props {
    startTime: string,
    delay?: number
}

export const useElapsedTime = ({startTime, delay = 1}: Props) => {
    const [time, setTime] = useState(new Date().getTime() - new Date(startTime).getTime())

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setTime(new Date().getTime() - new Date(startTime).getTime())
        }, delay * 1000 * 60)
        return () => clearTimeout(timeoutId)
    }, [time])

    return useMemo(() => {

        const minutes = Math.floor(time / 60 / 1000);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        let result = '';
        let hoursResult = hours;
        let minutesResult = minutes;
        if (days > 0) {
            result += Helper.getCountString(days, ['день', 'дня', 'дней']) + ' '
            hoursResult = Math.floor(hours - (days * 24))
            if (hoursResult <= 0) {
                minutesResult = Math.floor(minutes - (days * 24 * 60))
            }
        }

        if (hoursResult > 0) {
            minutesResult = Math.floor(minutes - (hours * 60));
            result += Helper.getCountString(hoursResult, ['час', 'часа', 'часов']) + ' '
        }

        if (minutesResult > 0) {
            result += Helper.getCountString(minutesResult, ['минута', 'минуты', 'минут']) + ' '
        }



        return result
    }, [time])

}
