import React from 'react';
import './Manual.scss';
import ServerCommand from "../../../../common/server/server-command";
import PropTypes from "prop-types";
import Table from "../../../../components/Table/Table";
import Helper from "../../../../common/helpers/main";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Box from "../../../../components-ui/Layout/Box";
import {connect} from "react-redux";
import Tooltip from "../../../../components-ui/DataDisplay/Tooltip/Tooltip";

const manualColumns = [
    {
        title: 'Прием',
        code: 'duration'
    },
    {
        title: 'Записан',
        code: 'createdAt',
    },
    {
        title: 'Врач',
        code: 'doctor'
    },
    {
        title: 'Статус',
        code: 'status'
    },
    {
        title: 'Записал',
        code: 'userCreated'
    }
]


class Manual extends React.Component {


    static propTypes = {
        patientId: PropTypes.number.isRequired,
    };

    state = {
        manualsList: null,
        cancellationReasons: null,
        statusTitle: null,
        focusedVisitId: null,
        user: this.props.user,
        loaderButton: false
    }

    componentDidMount() {
        ServerCommand
            .get('visit/list-for-patient', {
                patientId: this.props.patientId
            })
            .then(response => {
                this.setState({
                    manualsList: response.list,
                    statusTitle: response.variantsTitles.status,
                    cancellationReasons: response.cancellationReasonsMap
                })
            })
    }

    render() {

        if (!this.state.manualsList) {
            return null
        }

        let tableData = Helper.clone(this.state.manualsList)

        tableData.forEach(item => {
            item['duration'] = Helper.Date.formatDate(item.timeStart, 'D ru_month_gen YYYY') + ' (c ' + Helper.Date.formatTime(item.timeStart) + ' до ' + Helper.Date.formatTime(item.timeEnd) + ')'
            item['createdAt'] = Helper.Date.formatDate(item.createdAt, 'D ru_month_gen YYYY') + ' в ' + Helper.Date.formatTime(item.createdAt)
            item['status'] = this.renderStatus(item)
            item['doctor'] =
                <div className="manual__doctor">
                    <div className="manual__doctor-indicator" style={{backgroundColor: item.doctor.color}}/>
                    <span className="manual__doctor-name">{Helper.getFio(item.doctor)}</span>
                </div>
            item['userCreated'] =
                <div className="manual__doctor">
                    <span className="manual__doctor-name">{Helper.getFio(item.userCreated)}</span>
                </div>
            console.log(item)
        })

        const filterFocusedReception = () => !!this.state.focusedVisitId && this.state.manualsList
            .find((reception) => reception.id === this.state.focusedVisitId.code)

        const canResumeReception = filterFocusedReception()?.canBeRevived

        const recummeReception = () => {
            this.setState(prevState => ({...prevState, loaderButton: true}))
            ServerCommand
                .post('visit/revive', {visitId: this.state.focusedVisitId.code})
                .then(() => {
                    this.setState(prevState => {
                        const newManualsList = prevState.manualsList
                            .map((reception) => reception.id === this.state.focusedVisitId.code ?
                                {...reception, canBeRevived: false, status: 'IN_PROGRESS'} :
                                {...reception}
                            )
                        return (
                            {...prevState, manualsList: newManualsList, loaderButton: false}
                        )
                    })

                })
        }

        return (

            <>
                {(this.state.manualsList.length > 0) ?
                    <>
                        <div className="box">
                            <div className="box-body">
                                <div className="card">
                                    <div className="manual__table">
                                        <Table data={tableData}
                                               columns={manualColumns}
                                               focusedElement={this.state.focusedVisitId}
                                               onFocus={this.handleFocusVisit}
                                        />
                                    </div>
                                </div>
                                {!!this.state.user.canReviveVisits &&
                                    <Box margin="20px 0 0 0">
                                        {!this.state.focusedVisitId ?
                                            <>
                                                <Button
                                                    variant={"disabled"}
                                                    children="Возобновить"
                                                    data-for="resumeReception"
                                                    data-tip="Выберите приём"
                                                />
                                                <Tooltip id="resumeReception" effect="solid"/>
                                            </>
                                            :
                                            <>
                                                <Button
                                                    variant={canResumeReception ? "standard" : "disabled"}
                                                    onClick={recummeReception}
                                                    loader={this.state.loaderButton}
                                                    children="Возобновить"
                                                    data-for="resumeReception"
                                                    data-tip="Выбранный приём<br/> возобновить нельзя"
                                                />
                                                {!canResumeReception && <Tooltip id="resumeReception" effect="solid"/>}
                                            </>
                                        }
                                    </Box>
                                }
                            </div>
                        </div>


                    </>
                    : this.renderNoManuals()
                }
            </>


        )
    }

    renderStatus(item) {
        if ((item.status === 'CANCELED') || (item.status === 'CANCELED_IN_ADVANCE')) {
            const reasonCode = item.params?.rejectionReason;
            const comment = item.params?.rejectionComment;

            const reason = reasonCode ? this.state.cancellationReasons[reasonCode] : null;

            return (
                <div className="manual__status">
                    <span
                        className="manual__status-text manual__status-text--canceled">{this.state.statusTitle[item.status]}</span>
                    <div className="manual__note">
                        <div className="manual__question-mark">?</div>
                        <div className="manual__note-bubble">
                            {reason && (
                                <p className="manual__note-text">
                                    <span className="manual__note-header">Причина</span>: {reason.name.toLowerCase()}
                                </p>
                            )}
                            {comment && (
                                <>
                                    <div className="manual__note-header">Комментарий</div>
                                    <p className="manual__note-comment">{comment}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )
        } else {
            return this.state.statusTitle[item.status]
        }
    }

    renderNoManuals() {
        return (
            <div className="box box--min">
                <h2>Приемов пока нет</h2>
                <p>Пациент не прошел прием у врача</p>
            </div>
        )
    }

    handleFocusVisit = focusedVisitId => this.setState({focusedVisitId})
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(Manual)
