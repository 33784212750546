import {useMemo} from 'react';
import _ from 'lodash';
import {notTrashed} from '../../../common/helpers/general-helper';
import {User} from '../../../types';
import {Laboratory} from '../../LaboratoryPage/types';
import {useSelector} from 'react-redux';
import {OptionSelect} from '../../../components-ui/Inputs/Select/typesSelect';

interface Props {
    laboratories: Laboratory[] | undefined
    selectedBranch: OptionSelect
}

const useOptionsFilters = ({laboratories, selectedBranch}: Props) => {

    const users = useSelector(state => state.common.users);

    const optionsFilteredLaboratories = _
        .chain(laboratories)
        //.filter(lab => lab.active)
        .map((lab) => {
            return {value: lab.id, label: lab.name};
        })
        .unshift({value: 0, label: 'Все лаборатории'})
        .value();

    const optionsFilteredDoctors = useMemo(() => {
        return _
            .chain(users)
            .filter(notTrashed)
            .filter((user: User) => user.branches.includes(selectedBranch.value) && user.role === 'doctor')
            .map((user: User) => {
                return {value: user.id, label: user.fio, color: user.color};
            })
            .sortBy(user => user.label)
            .unshift({value: 0, label: 'Все врачи', color: '#dedede'})
            .value();
    }, [selectedBranch, users]);

    const optionsFilteredTechnic = useMemo(() => {
        return _
            .chain(users)
            .filter(notTrashed)
            .filter((user: User) => user.branches.includes(selectedBranch.value) && user.role === 'dental-technician')
            .map((user: User) => {
                return {value: user.id, label: user.fio};
            })
            .sortBy(user => user.label)
            .unshift({value: 0, label: 'Все техники'})
            .value();
    }, [selectedBranch, users]);

    return {optionsFilteredDoctors, optionsFilteredLaboratories, optionsFilteredTechnic}
};

export default useOptionsFilters;
