import {useSelector} from "react-redux";
import {OptionSelect} from "../../components-ui/Inputs/Select/typesSelect";
import {useMemo} from "react";

export const useOptionsSelectExpenditures = (): OptionSelect[] => {
    const expenditures = useSelector(state => state.common.expenditures);
    return useMemo(() => expenditures.map(expenditure => ({
        label: expenditure.name,
        value: expenditure.id
    })), [expenditures])
}
