import React from "react";
import Button from "../../components-ui/Inputs/Button/Button";
import ServerCommand from "../../common/server/server-command";

interface PButtonsGroup {
    editMode: boolean,
    selectMode: string,
    btnVariant: string,
    cases: any,
    handleAddGroupBtn: any,
    handleAddItemBtn: any,
    isEnabledUpdateBtn: any,
    handleUpdateBtn: any,
    isEnabledCopyBtn: any,
    handleCloneBtn: any,
    clonedRow: any,
    handlePasteBtn: any,
    isEnabledDeleteBtn: any,
    handleDelBtn: any
}

export default function ButtonGroup({
                                        editMode, btnVariant, cases, handleAddGroupBtn, selectMode, clonedRow,
                                        handleAddItemBtn, handleCloneBtn, handleDelBtn, handlePasteBtn, handleUpdateBtn,
                                        isEnabledCopyBtn, isEnabledDeleteBtn, isEnabledUpdateBtn
                                    }: PButtonsGroup) {

    if (editMode && selectMode === 'single') {
        return (
            <div className="table-with-groups__btns">
                <Button variant={btnVariant}
                        size="long"
                        children={`Добавить группу`}
                        onClick={handleAddGroupBtn}/>
                <Button variant={btnVariant}
                        size="long"
                        children={`Добавить ${cases.accusative}`}
                        onClick={handleAddItemBtn}/>
                <Button variant={isEnabledUpdateBtn() ? 'default' : 'disabled'}
                        size="long"
                        children="Редактировать"
                        onClick={handleUpdateBtn}/>
                <Button
                    variant={isEnabledCopyBtn() ? 'default' : 'disabled'}
                    size="long"
                    children="Копировать"
                    onClick={handleCloneBtn}/>
                <Button variant={!!clonedRow ? 'default' : 'disabled'}
                        size="long"
                        children="Вставить"
                        onClick={handlePasteBtn}/>
                <Button variant={isEnabledDeleteBtn() ? 'danger' : 'disabled'}
                        size="long"
                        children="Удалить"
                        onClick={handleDelBtn}/>
                <Button variant="default"
                        size="long"
                        children="Скачать прайс"
                        onClick={() => {
                            window.open('/price-list-export');
                        }}/>
            </div>
        )
    } else {
        return null
    }

}
