import {useEffect, useState} from "react";
import {PropsUseForm} from "../types/hooks";
import useInitialStateForm from "./useInitialStateForm";
import {StateBasicForm, StateForms, StatePreparationForAdmission} from "../types/common";
import _ from 'lodash'
import prepareVisibleFields from "../helper/prepareVisibleFields";
import useValidateForm from "../../../../../containers/PatientField/hooks/useValidateForm";
import {defaultTabs} from "../helper/defaultTabs";

export default function useForms({data, fields}: PropsUseForm) {
    const initialState = useInitialStateForm({data, fields})
    const [forms, setForms] = useState<StateForms>(_.cloneDeep(initialState.forms))
    const [state, setState] = useState<StatePreparationForAdmission>(initialState.state)
    const validateForm = useValidateForm({fields});

    const changeTabs = (prevForms: StateForms) => {
        return defaultTabs
            .filter(tab => !!prevForms[tab.code] && !!prevForms[tab.code].visibleFields.length)
            .map(tab => {
                tab.warning = !validateForm(prevForms[tab.code].basic, prevForms[tab.code].visibleFields)
                return tab
            })
    }

    useEffect(() => {
        setForms(_.cloneDeep(initialState.forms))
        setState(initialState.state)
    }, [initialState])

    useEffect(() => {
        setState(prevState => {
            prevState.tabs = changeTabs(forms)
            return {...prevState}
        })
    }, [forms])

    useEffect(() => {
        setState(prevState => {
            let prevForms = _.cloneDeep(forms);
            _.forEach(prevForms, (form, formName) => {
                prevForms[formName].visibleFields = prepareVisibleFields({
                    documents: data.documents,
                    checkboxes: prevState.checkboxes,
                    formName
                })
            })

            prevState.tabs = changeTabs(prevForms)
            const findCurrentTab = prevState.tabs.find(tab => tab.code === prevState.currentTab)
            if (!findCurrentTab && _.values(prevState.checkboxes).some(check => check)) {
                prevState.currentTab = prevState.tabs[0].code as string
            }
            setForms(prevForms)
            return {
                ...prevState
            }
        })
    }, [JSON.stringify(state.checkboxes)])

    const resetRepresentative = () => {
        setForms(prevForms => {
            if (!!prevForms.representative) {
                prevForms.representative = {
                    ...prevForms.representative,
                    basic: _.clone(initialState.emptyForms.representative.basic),
                    currentPatient: undefined
                }
            }
            return {
                ...prevForms
            }
        })
    }

    const onChangeField = (value: any, fieldName: keyof StateBasicForm, formName: string) => {
        setForms(prevForms => {
            if (!!prevForms[formName]) {
                prevForms[formName] = {
                    ...prevForms[formName],
                    basic: {
                        ...prevForms[formName].basic,
                        [fieldName]: value
                    }
                }
            }
            return {
                ...prevForms
            }
        })
    }

    const onChangeValidation = (fieldName: keyof StateBasicForm, formName: string) => {
        setForms(prevForms => {
            if (!!prevForms[formName]) {
                prevForms[formName] = {
                    ...prevForms[formName],
                    basic: {
                        ...prevForms[formName].basic,
                        validationFields: {
                            ...prevForms[formName].basic.validationFields,
                            [fieldName]: false
                        }
                    }
                }
            }
            return {
                ...prevForms
            }
        })
    }

    const onChangeCheckbox = (value: boolean, checkboxName: string) => {
        setState( (prevState) => ({
            ...prevState,
            checkboxes: {
                ...prevState.checkboxes,
                [checkboxName]: value
            }
        }))
    }

    const onChangeTab = (currentTab: string) => setState(prevState => ({...prevState, currentTab}))

    return {
        forms,
        state,
        resetRepresentative,
        onChangeField,
        onChangeValidation,
        onChangeCheckbox,
        onChangeTab,
        setForms,
        setState,
        initialState
    }
}
