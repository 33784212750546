import React, {Dispatch, SetStateAction} from 'react';
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";
import Box from "../../../../components-ui/Layout/Box";
import Select from "../../../../components-ui/Inputs/Select/Select";

interface Props {
    optionsBranches: OptionSelect[]
    selectedBranch: OptionSelect
    setSelectedBranches: Dispatch<SetStateAction<OptionSelect>>
}

const FilterBranches = ({optionsBranches, selectedBranch, setSelectedBranches}: Props) => {
    return (
        <Box margin="32px 0 0 0" display="flex" width="480px" justifyContent="space-between" alignItems="center">
                <Select options={optionsBranches} value={selectedBranch} onChange={setSelectedBranches} sizeSelect="sm"/>
        </Box>
    );
};

export default FilterBranches;