import React, {useState, Fragment, useRef, useMemo} from "react";
import {STableCell, STableRow} from "../style/common";
import usePrepareNested from "../hooks/usePrepareNested";
import NestedArrowIcon from "../components/NestedArrowIcon";
import prepareValueByType from "../helper/prepareValueByType";
import {IColumn, IRow} from "../types/common";
import {VariantTable} from "../types/components";

interface Props {
    fieldNameNested?: string
    row: IRow
    isNested?: boolean
    columns: IColumn[]
    onClickRow?: (row: IRow) => void
    isHovering: boolean
    fontSizeRow?: string
    variantTable: VariantTable
    indexRow?: number
    modalRow?: any
    setModalRow?: any
    customRowsCells?: boolean
}

export default function Row(
    {
        row,
        isNested = false,
        fieldNameNested,
        columns,
        onClickRow,
        isHovering,
        fontSizeRow,
        variantTable,
        customRowsCells
    }: Props) {

    const [openNested, setOpenNested] = useState(false)
    const nested = usePrepareNested({row, fieldNameNested})
    const refRow = useRef(null) as any;
    return useMemo(() =>
            <>
                <STableRow variantTable={variantTable} fontSizeRow={fontSizeRow} isHovering={isHovering} isNested={isNested}
                           customRowsCells={customRowsCells} onClick={() => !!onClickRow && onClickRow(row)} ref={refRow}>
                    {columns.map((column, index) => {
                        let childrenCell = row[column.field];
                        if (!!column.valueFormatter) childrenCell = column.valueFormatter({
                            childrenCell, row, isNested, refRow
                        });
                        childrenCell = prepareValueByType(childrenCell, column.type)
                        if (index === 0 && !!nested.length) {
                            childrenCell = <NestedArrowIcon openNested={openNested} setOpenNested={setOpenNested}
                                                            childrenCell={childrenCell}/>
                        }
                        return (
                            <Fragment key={column.field + row.id}>
                                <STableCell variantTable={variantTable}
                                            customRowsCells={customRowsCells}
                                            customPaddingCells={column.paddingCell}
                                            style={column.customStyles}
                                            color={!!column.color && column.color('text', isNested)}
                                            children={childrenCell}
                                            width={column.width}
                                            minWidth={column.minWidth}
                                            align={column.align || 'left'}/>
                                {(!!column.sortable && column.sortPosition !== 'right') && (
                                    <STableCell variantTable={variantTable}
                                                width="36px"
                                                align={'left'}
                                                minWidth={column.minWidth}
                                                sortPosition={column.sortPosition || 'right'}
                                                isSort={true}
                                    />
                                )}
                            </Fragment>
                        )
                    })}
                </STableRow>
                {openNested && nested.map(item => <Row variantTable={variantTable} row={item} isNested={true} key={item.id}
                                                       columns={columns} onClickRow={onClickRow} isHovering={isHovering}
                                                       fieldNameNested={fieldNameNested} fontSizeRow={fontSizeRow}/>)}
            </>
        , [row, openNested, columns])

}
