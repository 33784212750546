import styled from "styled-components";

export const WrapUI = styled.div`
    display: flex;
`;

export const UIContentWrap = styled.div`
    width: calc(100% - 200px);
`;

export const WrapUINav = styled.ul`
    width: 300px;
    height: 100vh;
    border-right: 3px solid black;
    overflow-y: scroll;
    overflow-x: hidden;
    & > li {
        & > span {

        }
        & > ul {
            & > li {
                & > a {
                    text-decoration: none;
                    color: black;
                    &:hover {
                        color: darkblue;
                    }
                    &.active {
                        color: #00d4d4;
                    }
                    & > span {

                    }
                }
            }
        }
    }
`;

export const WrapBorder = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.12);
    padding: 24px;
    border-radius: 4px;
    margin: auto;
    display: flex;
    outline: 0;
    position: relative;
    justify-content: center;
`;

export const WrapContent = styled.div`
    padding: 20px;
    background: ${props => props.theme.colors.white};
`;
