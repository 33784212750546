import React from 'react';
import Box from '../../../../../../components-ui/Layout/Box';
import _ from 'lodash'
import {namesCalls, colorsCalls} from "../data/data";
import {WrapLegendItem} from "../../../../style";
import Avatar from "../../../../../../components-ui/DataDisplay/Avatar";
import Typography from "../../../../../../components-ui/DataDisplay/Typography/Typography";

const Legend = () => {
    return (
        <Box display="flex"
             padding="4px 0"
             margin="0 0 38px"
             alignItems="center"
             width="100%">
            {_.map(namesCalls, (value, key) => {
                return (
                    <Box display="flex"
                         alignItems="center"
                         margin="0 24px 0 0"
                         key={key}>
                        <WrapLegendItem color={colorsCalls[key]}>
                            <Avatar size="8px" bgColor={colorsCalls[key]}/>
                        </WrapLegendItem>
                        <Typography variant="small" cursor="default">
                            {value}
                        </Typography>
                    </Box>
                )
            })}
        </Box>
    );
};

export default Legend;
