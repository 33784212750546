import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const ConfirmedByMessageIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M21.6,0H2.4A2.41,2.41,0,0,0,0,2.4V24l4.8-4.8H21.6A2.41,2.41,0,0,0,24,16.8V2.4A2.41,2.41,0,0,0,21.6,0Z"/>
            <polygon fill="#fff"
                     points="10.8 14.3 6.35 9.85 8.05 8.15 10.8 10.9 16.55 5.15 18.25 6.85 10.8 14.3"/>
        </WrapIcon>
    );
});
