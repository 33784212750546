import {Dispatch, SetStateAction, useEffect} from "react";
import {echo} from "../../../common/server/echo";
import {
    CallCompletedEventParams, CallCreatedEventParams, CallResultSavedParams,
    TaskCreatedEventParams,
    TaskDeletedEventParams,
    TaskUpdatedEventParams
} from "../types/eventParams";
import {useSlideModal} from "../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {useSelector} from "react-redux";
import {Call} from "../types/common";
import useDeleteTaskCalls from "./useDeleteTaskCalls";
import {StateCallCenter} from "../types/components";

export interface Props {
    setState: Dispatch<SetStateAction<StateCallCenter>>
    state: StateCallCenter
}

const useEchoCalls = ({setState, state}: Props) => {
    const {setIsClose} = useSlideModal()
    const user = useSelector(state => state.user);

    const deleteTaskCalls = useDeleteTaskCalls({setState})

    useEffect(() => {

        const taskCreatedHandler = (params: TaskCreatedEventParams) => {
            setState(prevState => {
                const findIndexCall = prevState.plannedCalls.findIndex(plannedCall => plannedCall.phone === params.phone);
                if (findIndexCall !== -1) {
                    if (prevState.plannedCalls[findIndexCall].tasks.findIndex(task => task.id === params.task.id) === -1) {
                        prevState.plannedCalls[findIndexCall].tasks.push(params.task)
                    }
                } else {
                    prevState.plannedCalls.push({
                        phone: params.phone,
                        phoneOwner: params.phoneOwner,
                        tasks: [params.task],
                        result: null
                    })
                }
                if (!!prevState.detail && prevState.detail.phone === params.phone) {
                    if (prevState.detail.tasks.findIndex(task => task.id === params.task.id) === -1) {
                        prevState.detail.tasks.push(params.task)
                    }
                }
                return {...prevState}
            })
        }

        const taskUpdatedHandler = (params: TaskUpdatedEventParams) => {
            setState(prevState => {
                const findIndexCall = prevState.plannedCalls.findIndex(plannedCall => plannedCall.phone === params.phone);

                if (findIndexCall !== -1) {
                    const findIndexTask = prevState.plannedCalls[findIndexCall].tasks.findIndex(task => task.id === params.task.id)
                    prevState.plannedCalls[findIndexCall].tasks[findIndexTask] = params.task;

                    if (!!prevState.detail && prevState.detail.phone === params.phone) {
                        const findIndexTask = prevState.detail.tasks.findIndex(task => task.id === params.task.id)
                        prevState.detail.tasks[findIndexTask] = params.task;
                    }
                }

                return {...prevState}
            })
        }

        const taskDeletedHandler = (params: TaskDeletedEventParams) => deleteTaskCalls(params.taskId)

        const updateCanSaveResult = (contactPhone: string) => {
            setState(prevState => {
                const findIndex = prevState.plannedCalls.findIndex(plannedCall => plannedCall.phone === contactPhone)
                if (findIndex !== -1) prevState.plannedCalls[findIndex].canSaveResult = true
                if (!!prevState.detail && contactPhone === prevState.detail.phone) prevState.detail.canSaveResult = true;
                return {...prevState}
            })
        }

        const callCreatedHandler = ({call, callerType}: CallCreatedEventParams) => {
            setState(prevState => {
                if (!prevState.calls.some(prevCall => prevCall.contactPhone === call.contactPhone)) {
                    prevState.calls.push({
                        ...call,
                        callerType
                    });
                }
                return {...prevState}
            })
            if (!call.incoming) updateCanSaveResult(call.contactPhone)
        }

        const callAnsweredHandler = (params: Call) => {
            setState(prevState => {
                if (!params.incoming) prevState.raisedCalls.push(params);

                if (prevState.calls.some(call => call.contactPhone === params.contactPhone)) {
                    const findIndexCall = prevState.calls.findIndex(call => call.contactPhone === params.contactPhone);
                    if (params.answerTime) prevState.calls[findIndexCall].answerTime = new Date(params.answerTime as any) as any;
                    if (prevState.calls[findIndexCall].userId === null) prevState.calls[findIndexCall].userId = params.userId;
                    if (prevState.calls[findIndexCall].incoming) updateCanSaveResult(params.contactPhone)
                }

                return {...prevState}
            })
        }

        const callCompletedHandler = (params: CallCompletedEventParams) => {
            setState(prevState => {
                const findPlannedCall = prevState.plannedCalls.find(plannedCall => plannedCall.phone === params.contactPhone);
                if (!!findPlannedCall) {
                    findPlannedCall.result = params.result
                    if (!!findPlannedCall.result && findPlannedCall.result.callerType) {
                        findPlannedCall.canSaveResult = false;
                    }
                }

                if (!!prevState.detail) {
                    prevState.detail.result = params.result;
                    if (prevState.detail.phone === params.contactPhone && (!!prevState.detail.result && !!prevState.detail.result.callerType)) {
                        prevState.detail.canSaveResult = false;
                    }
                }

                const findIndexRaisedCalls = prevState.raisedCalls.findIndex(call => call.contactPhone === params.contactPhone);
                if (findIndexRaisedCalls !== -1) prevState.raisedCalls[findIndexRaisedCalls].isRaised = true;

                prevState.calls = prevState.calls.filter(phoneCall => phoneCall.contactPhone !== params.contactPhone)

                return {...prevState}
            })
        }

        const callResultSavedHandler = (params: CallResultSavedParams) => {
            setState(prevState => {
                const findPlannedCall = prevState.plannedCalls.find(plannedCall => plannedCall.phone === params.contactPhone);
                if (!!findPlannedCall) {
                    findPlannedCall.result = {
                        callerType: params.callerType,
                        comment: params.comment,
                        topics: params.topics
                    }
                    if (!prevState.calls.some(call => call.contactPhone === findPlannedCall.phone)) {
                        findPlannedCall.canSaveResult = false
                    }
                }

                if (params.userId === user.id) setIsClose(true);

                if (!!prevState.detail && prevState.detail.phone === params.contactPhone) {
                    prevState.detail.result = {
                        callerType: params.callerType,
                        comment: params.comment,
                        topics: params.topics
                    }
                }

                return {...prevState}
            })
        }

        user.callCenterBranches.forEach(branchId => {
            echo.private(`call-center.${branchId}`)
                .listen('CallCenter\\TaskCreated', taskCreatedHandler)
                .listen('CallCenter\\TaskUpdated', taskUpdatedHandler)
                .listen('CallCenter\\TaskDeleted', taskDeletedHandler)
                .listen('CallCenter\\CallCreated', callCreatedHandler)
                .listen('CallCenter\\CallAnswered', callAnsweredHandler)
                .listen('CallCenter\\CallCompleted', callCompletedHandler)
                .listen('CallCenter\\CallResultSaved', callResultSavedHandler)
        })

        return () => {
            user.callCenterBranches.forEach(branchId => {
                echo.private(`call-center.${branchId}`)
                    .stopListening('CallCenter\\TaskCreated')
                    .stopListening('CallCenter\\TaskUpdated')
                    .stopListening('CallCenter\\TaskDeleted')
                    .stopListening('CallCenter\\CallCreated')
                    .stopListening('CallCenter\\CallAnswered')
                    .stopListening('CallCenter\\CallCompleted')
                    .stopListening('CallCenter\\CallResultSaved')
            })
        }


    }, [state.branches, user.callCenterBranches, user.id, setState, deleteTaskCalls, setIsClose])
}

export default useEchoCalls
