import React, {useEffect, useState} from 'react';
import {PagePriceList} from "./style"
import ImportantArticles from "./containers/ImportantArticles";
import Icon from "../../components-ui/DataDisplay/Icon";
import Box from "../../components-ui/Layout/Box";
import {useLoadData} from "./hooks/useLoadData";
import Loader from "../../components-ui/Feedback/Loader/Loader";
import PriceList from "./containers/PriceList";
import InfoPrice from "./containers/InfoPrice";
import OtherArticles from "./containers/OtherArticles";
import FixedArticles from "./containers/FixedArticles";
import {Article} from "./types";

const PatientPriceList = () => {

    const {data, loaderPage} = useLoadData()

    const [otherArticles, setOtherArticles] = useState<Article[]>([])

    useEffect(() => {
        document.body.classList.add("patient_price_list")
        return () => {
            document.body.classList.remove("patient_price_list")
        }
    }, [])

    return (
        <PagePriceList>
            <Loader visible={loaderPage} width={50} height={50}/>
            <Box display="flex" justifyContent="center">
                <Icon name="logoNs" width="160px" height="44px" margin="0 auto"/>
            </Box>
            {(!loaderPage && data)
            &&
            <>
                <FixedArticles articles={data.importantArticles}/>
                <ImportantArticles articles={data.importantArticles}/>
                <PriceList data={data} setOtherArticles={setOtherArticles}/>
                <InfoPrice services={data.inVisitPrice}/>
                {otherArticles.length ?
                    <OtherArticles articles={otherArticles}/>
                    : null
                }
            </>

            }

        </PagePriceList>
    );
};

export default PatientPriceList;