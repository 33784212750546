import {useState} from "react";
import {ReceiptsReport} from "../../../../types";
import {StateReceipts} from "../types";
import useInitialReceipts from "./useInitialReceipts";
import useHandlersReceipts from "./useHandlersReceipts";

interface Props {
    report: ReceiptsReport
}

const useReceipts = ({report}: Props) => {
    const [state, setState] = useState<StateReceipts>({
        currentTab: null,
        selectedDirection: null,
        hoverIndex: -1
    })

    useInitialReceipts({setState, report})

    return {
        state,
        handlers: useHandlersReceipts({setState})
    }

}

export default useReceipts
