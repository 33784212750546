import React, {useMemo} from 'react';
import {STaskList} from "../../../../containers/Tasks/style";
import {ManagerTask} from "../../types/common";
import {BadTreatmentPlanPayload} from "../../types/taskData";
import {Patients} from "../Labels";

interface Props {
    task: ManagerTask<BadTreatmentPlanPayload>
    renderBranches: () => React.ReactNode | null
}

const BadTreatmentPlan = ({task, renderBranches}: Props) => {
    const treatmentPlanType: 'performed' | 'recommended' = useMemo(() => {
        switch (task.type) {
            case "not_changed_plan_in_survey":
                return 'performed'
            default:
                return 'recommended'
        }
    }, [task.type])
    return (
        <STaskList padding="19px 0 16px">
            {renderBranches()}
            <Patients patients={[task.payload.patient]} colorPatient="info" activeSectionNav="TREATMENT_PLAN"
                      treatmentPlanType={treatmentPlanType}/>
        </STaskList>
    );
};

export default BadTreatmentPlan;
