import React from 'react';
import {Article} from "../types";
import Box from "../../../components-ui/Layout/Box";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import Carousel from 'react-elastic-carousel'
import {useDevice} from "../../../common/hooks/useDevice";
import OtherArticle from "../content/OtherArticle";

export interface Props {
    articles: Article[]
}

const OtherArticles = ({articles}: Props) => {

    const device = useDevice()

    return (
        <Box margin={device !== "mobile" ? "49px 0 0 0" : "28px 0 0 0"}>
            <Typography variant={device !== "mobile" ? "bigger-title" : "mobile-title"}>Полезные статьи</Typography>
            <Box margin={device !== "mobile" ? "20px 0 0 0" : "8px 0 0 0"}>
                {
                    device !== "mobile" && articles.length < 4
                        ?
                        <Box display="flex" width="fit-content">
                            {articles.map((article) =>
                                <OtherArticle key={article.id} article={article} isSlider={false}/>
                            )}
                        </Box>
                        :
                        <Box margin="0 -20px">
                            <Carousel outerSpacing={6} isRTL={false} itemsToShow={device !== "mobile" ? 3.05 : 1.03}
                                      pagination={false}
                                      showArrows={false}>
                                {articles.map((article) =>
                                    <OtherArticle key={article.id} article={article} isSlider={true}/>
                                )}
                            </Carousel>
                        </Box>
                }
            </Box>
        </Box>
    );

};

export default OtherArticles;