import styled from "styled-components";

interface PropsSBoxStatusHistory {
    isAnswerTime: boolean
}

export const SBoxStatusHistory = styled.span.attrs({className: 'SBoxStatusHistory'})<PropsSBoxStatusHistory>`
    display: flex;
    align-items: center;
    & > div {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: ${props => props.isAnswerTime ? props.theme.colors.success : props.theme.colors.danger};
        fill: ${props => props.theme.colors.white};
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: ${props => props.isAnswerTime ? '10px' : '6px'};
            height: ${props => props.isAnswerTime ? '10px' : '6px'};
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`
