import ServerCommand from "../../../../common/server/server-command";
import {Dispatch, SetStateAction, useCallback} from "react";
import {CashCloseParams, GetCashCloseResult, IStateCashClose} from "../types";
import {useModal} from "../../../../containers/DialogsManager/containers/Modal/Modal";
import Kkm from "../../../../common/server/kkm";
import {useSelector} from "react-redux";
import {KkmResponse} from "../../../../common/types/server";
import {useErrorModal} from "../../../../components/Errors";
import Helper from "../../../../common/helpers/main";
import {KkmSetting} from "../../../../types";

interface Props {
    loadingFromServer?: () => void
    setLoader: Dispatch<SetStateAction<boolean>>
    setKkmError: Dispatch<SetStateAction<string>>
    state: IStateCashClose
    kkmSetting: KkmSetting
}

const useSubmit = ({
                       loadingFromServer,
                       setLoader,
                       setKkmError,
                       state,
                       kkmSetting
                   }: Props) => {

    const {closeModal} = useModal()

    const handleCloseCash =  useCallback(() => {
        setLoader(true);

        const kkm = new Kkm(kkmSetting)

        if (state.selectedKkm) {

            // @ts-ignore
            kkm.closeShift().then((response: KkmResponse) => {
                console.log(response)

                if (response.Status == 0) {
                    closeModal()

                    if (loadingFromServer) loadingFromServer()
                } else {
                    setLoader(false);
                    setKkmError(response.Error)
                    console.log(response.Error)
                }
            }).catch(reason => setKkmError(reason))
        }


    }, [
        loadingFromServer,
        setLoader,
        state,
        kkmSetting
    ])

    const handleXReport =  useCallback(() => {
        setLoader(true);

        const kkm = new Kkm(kkmSetting)

        if (state.selectedKkm) {

            // @ts-ignore
            kkm.xReport().then((response: KkmResponse) => {
                console.log(response)

                if (response.Status == 0) {
                    closeModal()

                    if (loadingFromServer) loadingFromServer()
                } else {
                    setLoader(false);
                    setKkmError(response.Error)
                    console.log(response.Error)
                }
            }).catch(reason => setKkmError(reason))
        }


    }, [
        loadingFromServer,
        setLoader,
        state,
        kkmSetting
    ])

    const handleOpenCash =  useCallback(() => {
        setLoader(true);

        const kkm = new Kkm(kkmSetting)

        if (state.selectedKkm) {

            // @ts-ignore
            kkm.openShift().then((response: KkmResponse) => {
                console.log(response)

                if (response.Status == 0) {
                    closeModal()

                    if (loadingFromServer) loadingFromServer()
                } else {
                    setLoader(false);
                    setKkmError(response.Error)
                    console.log(response.Error)
                }
            }).catch(reason => setKkmError(reason))
        }


    }, [
        loadingFromServer,
        setLoader,
        state,
        kkmSetting
    ])

    return {
        handleXReport,
        handleOpenCash,
        handleCloseCash
    }
};

export default useSubmit;
