import React from "react";
import {HeadDoctorTable, HeadGroupTable, HeadTable, HeadText} from "../styledTableRecommendedServices";

export default function HeaderTable() {
    return (
        <HeadTable>
            <HeadGroupTable>
                <HeadText>
                    Группа
                </HeadText>
            </HeadGroupTable>
            <HeadDoctorTable>
                <HeadText>
                    Врач
                </HeadText>
            </HeadDoctorTable>
        </HeadTable>
    )
}
