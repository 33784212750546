import React, {useEffect, useMemo, useRef} from 'react'
import {PropsCounter} from "./typesCounter";
import {SCounterWrap, SCounterInput} from './styledCounter';
import Button from "../Button/Button";
import SVG from "../../../common/helpers/SVG";

export default function Counter(
    {min = 1, max = Infinity, step = 1, disableInput = false, onChange, value, type, margin, height, size = "sm"}: PropsCounter
) {
    const ref = useRef(null) as any
    const handleKeyDown = (e: any) => {
        if (document.activeElement === ref.current) {
            if (e.which === 38) {
                e.preventDefault();
                incCount()
            } else if (e.which === 40) {
                decCount()
            }
        }
    };
    const handleChange = (e: any) => {
        let newValue = e.target.value;
        if (newValue.length === 0) {
            onChange(newValue);
        } else {
            if (!isNaN(+newValue) && +newValue <= max && +newValue >= min) onChange(+newValue);
        }
    };
    const incCount = () => (+value + step) <= max && onChange(+value + step);
    const decCount = () => (+value - step) >= min && onChange(+value - step);
    const handleBlur = (e: any) => (e.target.value.length === 0) && onChange(min);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [value])

    const heightCounter = useMemo(() => {
        if (!!height) return height;
        switch (size) {
            case "md":
                return "38px"
            case "sm":
            default:
                return '24px'
        }
    }, [])

    return (
        <SCounterWrap onClick={(e) => e.stopPropagation()} margin={margin} size={size}>
            <Button variant={min === value ? 'disabled' : 'default'}
                    onClick={decCount}
                    {...commonButtonProps}
                    height={heightCounter}
                    children={<SVG name="minus"/>}
            />
            <SCounterInput ref={ref}
                           value={value}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           disabled={disableInput}
                           height={heightCounter}
            />
            <Button variant={max === value ? 'disabled' : 'default'}
                    onClick={incCount}
                    {...commonButtonProps}
                    height={heightCounter}
                    children={<SVG name="plus"/>}
            />
        </SCounterWrap>
    )
}

const commonButtonProps = {
    width: '18px',
    isIcon: true,
    noBorder: true,
    noShadow: true
}

