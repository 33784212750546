import _ from "underscore";
import {SetStateConfig} from "../types";
import {TeethMap} from "../../../common/types/teethmap";
import Helper from "../../../common/helpers/main";

export const componentDidMount = (teethMap: TeethMap, setConfig: SetStateConfig, setSnapshotConfig: any) => {

    setConfig(prevState => {
        prevState = Helper.clone(teethMap.config);
        _.each(prevState, (teeth) => teeth.forEach(tooth => {
            tooth.selected = false
            tooth.unconfirmed = false
            if (!!tooth.diagnoses) {
                tooth.diagnoses.forEach(diagnosis => {
                    //@ts-ignore
                    const textSplit = diagnosis.text.split(' ');
                    let newContent = '';
                    let newText = [] as string[];
                    textSplit.forEach((txt: string, index: number) => {
                        newContent += txt + ' ';
                        if (newContent.length >= 40 || index === textSplit.length - 1) {
                            newText.push(newContent)
                            newContent = ''
                        }
                    })
                    diagnosis.text = newText;
                })
            }
        }))

        return {
            ...prevState
        }
    });
    setSnapshotConfig(Helper.clone(teethMap.config));
}
