import React, {useEffect, useState} from 'react';
// @ts-ignore
import DateHelper from "common/helpers/date-helper";
import {Clock} from "../styledHeader";
import {useSelector} from "react-redux";

const Time = () => {
    const serverTimeDiff = useSelector(state => state.common.serverTimeDiff)
    const [currentTime, setCurTime] = useState(DateHelper.currentTime(serverTimeDiff));

    useEffect(() => {
        const update = setInterval(() => {
            setCurTime(DateHelper.currentTime(serverTimeDiff));
        }, 1000000000000000000);
        return () => {
            clearInterval(update);
        }
    }, []);

    return (
        <Clock>
            {DateHelper.formatTime(currentTime)}
        </Clock>
    )
};

export default Time;
