import React from "react";
import {useWAP} from "../../../WaitingAndPlanned";
import {Label} from "../../../../../../../components-ui/DataDisplay/Label";
import DateTime from "../../../../../../../components-ui/Inputs/DateTime/DateTime";
import commonLabelOptions from "../../../../../../../containers/PatientField/helper/commonLabelOptions";

export default function DateFormWAP() {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const {form, setForm} = useWAP()
    const {date, validationFields} = form;
    return (
        <Label labelText='Дата' isRequired={true}
               {...commonLabelOptions(true, 'WAP')}>
            <DateTime selected={date}
                      minDate={tomorrow}
                      placeholderText="не выбрана"
                      widthInput="336px"
                      widthModal="336px"
                      isError={validationFields.date}
                      onChange={value => {
                          setForm(prevState => {
                              return {
                                  ...prevState,
                                  date: value,
                                  validationFields: {
                                      ...prevState.validationFields,
                                      date: false
                                  }
                              }
                          })
                      }} />
        </Label>
    )
}
