
export function defineParameters(text: string) {
    let textAndParameters = [] as any[];
    if (text.indexOf('⊆') !== -1) {
        let justText = '';
        let stopJustText = false;
        let parameter = {
            name: '',
            options: [] as any[],
            isMultipleList: false
        } as any;
        let stopName = true;
        let optionText = '';
        let isDefault = false;
        text.split('').forEach(elem => {

            switch (elem) {
                case "→" :
                    stopName = false;
                    break;
                case "ǁ":
                    parameter.options.push({
                        text: optionText,
                        isDefault
                    });
                    isDefault = false;
                    optionText = '';
                    break;
                case "Ð":
                    isDefault = true;
                    break;
                case "⊆":
                    textAndParameters.push(justText);
                    stopJustText = true;
                    break;
                case "⊇":
                    stopJustText = false;
                    if (!stopName) {
                        parameter.options.push({
                            text: optionText,
                            isDefault
                        });
                    }
                    stopName = true;
                    isDefault = false;
                    textAndParameters.push(parameter);
                    parameter = {
                        name: '',
                        options: [],
                        isMultipleList: false
                    };
                    optionText = '';
                    justText = '';
                    break;
                case "ᗰ":
                    parameter.isMultipleList = true
                    break;
                default:
                    if (!stopJustText && stopName) justText += elem;
                    if (stopJustText && stopName) parameter.name += elem;
                    if (!stopName) optionText += elem;
                    break;
            }

        });
        if (justText.length > 0) textAndParameters.push(justText);
    } else {
        textAndParameters = [text]
    }
    return textAndParameters
}
