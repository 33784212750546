import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const PrinterIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M20.27,4.92A3.64,3.64,0,0,1,24,8.46v5.73a3.65,3.65,0,0,1-3.73,3.54h-.66v4.5A1.82,1.82,0,0,1,17.75,24H6.18a1.83,1.83,0,0,1-1.86-1.77v-4.5H3.73A3.65,3.65,0,0,1,0,14.19V8.47A3.65,3.65,0,0,1,3.73,4.92h.59V3.55A3.65,3.65,0,0,1,8.05,0h7.84a3.65,3.65,0,0,1,3.73,3.55V4.92ZM5.73,3.54V4.92H18.2V3.55a2.27,2.27,0,0,0-2.32-2.21H8.05A2.27,2.27,0,0,0,5.73,3.55ZM18.2,22.24V14.82H5.73v7.42a.44.44,0,0,0,.45.43H17.75a.45.45,0,0,0,.45-.43Zm4.39-8V8.47a2.27,2.27,0,0,0-2.32-2.2H3.73a2.27,2.27,0,0,0-2.32,2.2v5.72a2.27,2.27,0,0,0,2.32,2.2h.59V14.82h-1a.68.68,0,0,1-.7-.66h0a.68.68,0,0,1,.7-.67H20.55a.67.67,0,1,1,0,1.34h-.93V16.4h.65a2.27,2.27,0,0,0,2.32-2.21ZM20.25,7.55a.68.68,0,1,1,0,1.35H18.82a.7.7,0,0,1-.71-.67h0a.69.69,0,0,1,.71-.68ZM7.44,18.13a.68.68,0,1,1,0-1.35h9a.68.68,0,1,1,0,1.35Zm9.06,1.4a.67.67,0,1,1,0,1.34h-9a.69.69,0,0,1-.71-.66h0a.69.69,0,0,1,.71-.67Z"/>
        </WrapIcon>
    );
});
