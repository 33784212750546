import styled from "styled-components";

const Item = styled.div`
    position: fixed;
    display: flex;
    width: 100vw;
    height: 10vh;
    z-index: 999999;
    left: 0;
`;

export const Top = styled(Item)`
    top: 0;
`;

export const Bottom = styled(Item)`
    bottom: 0;
`;
