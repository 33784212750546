import React, {useRef, useState} from "react";
import useOutsideClick from "../../../../../common/hooks/useOutsideClick";
import {PropsLabel} from "../../../types/labels";
import {SLabel, SCounts, SLabelIconWrap, SLabelInfo, SLabelTitle, SLabelText, SLabelHint} from '../../../style/labels'
import SVG from "../../../../../common/helpers/SVG";
import Icon from "../../../../../components-ui/DataDisplay/Icon";

export default function Label({maximizeSlideModal, canBeDeleted, params, idModal, removeSlideModal}: PropsLabel) {
    const [showHint, setShowHint] = useState(false);
    const ref = useRef(null) as any
    useOutsideClick(ref, () => setShowHint(false))

    const onClickLabel = () => {
        if (!showHint) maximizeSlideModal()
    }

    const onContextMenu = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (canBeDeleted) setShowHint(true)
    }

    if (!params) return null;
    return (
        <SLabel onClick={onClickLabel}
                onContextMenu={onContextMenu}
                ref={ref}
        >
            {!!params.badgeText && <SCounts children={params.badgeText}/>}
            <SLabelIconWrap>
                <Icon name={params.icon} width="24px" height="24px" color="white" />

            </SLabelIconWrap>
            <SLabelInfo>
                <SLabelTitle children={params.title}/>
                <SLabelText children={params.text}/>
            </SLabelInfo>
            {showHint &&
            <SLabelHint>
                <span onClick={() => removeSlideModal(idModal)}>Удалить</span>
            </SLabelHint>}
        </SLabel>
    )
}
