import React from 'react';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import {GetTimesheetListResult} from '../../../types';
import {ITimesheetRow} from '../types';
import ModalMarkEmployee from '../modals/containers/ModalMarkEmployee';
import ModalDeleteTimeSheetRecord from '../modals/containers/ModalDeleteTimeSheetRecord';

interface Props {
    data: GetTimesheetListResult
    selectedBranchId: number
}

const useOptions = ({data, selectedBranchId}: Props) => {

    const {showModal} = useDialogsManager();

    return (employee: ITimesheetRow) => {
        if (employee.endTime === null) {
            return [
                {
                    value: '1',
                    label: 'Отметить время ухода',
                    onClick: () => showModal(
                        <ModalMarkEmployee data={data}
                                           selectedBranchId={selectedBranchId}
                                           employee={employee}
                                           isComing={false} />
                    )
                },
                {
                    value: '2',
                    label: 'Изменить время прихода',
                    onClick: () => showModal(
                        <ModalMarkEmployee data={data}
                                           selectedBranchId={selectedBranchId}
                                           employee={employee}
                                           isComing={true} />
                    )
                },
                {
                    value: '3',
                    label: 'Удалить',
                    onClick: () => showModal(<ModalDeleteTimeSheetRecord id={employee.id}/>)
                }
            ]
        } else {
            return [
                {
                    value: '1',
                    label: 'Изменить время прихода',
                    onClick: () => showModal(
                        <ModalMarkEmployee data={data}
                                           selectedBranchId={selectedBranchId}
                                           employee={employee}
                                           isComing={true} />
                    )
                },
                {
                    value: '2',
                    label: 'Изменить время ухода',
                    onClick: () => showModal(
                        <ModalMarkEmployee data={data}
                                           selectedBranchId={selectedBranchId}
                                           employee={employee}
                                           isComing={false} />
                    )
                },
                {
                    value: '3',
                    label: 'Удалить',
                    onClick: () => showModal(<ModalDeleteTimeSheetRecord id={employee.id}/>)
                }
            ]
        }
    }
};

export default useOptions;