import styled from "styled-components";
import {BoxBody} from "../../../components-ui/styledComponent/common/wraps";

export const WrapTopSearch = styled.div.attrs({className: 'WrapTopSearch'})`
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    & > button {
      flex-shrink: 0;
      margin-left: 20px;
    }
`

export const BoxBodyTopSearch = styled(BoxBody)`
    padding-top: 16px;
    padding-bottom: 16px;
`
