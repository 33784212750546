import React from "react";
import Select from "../../../../components-ui/Inputs/Select/Select";
import Button from "../../../../components-ui/Inputs/Button/Button";
import ServerCommand from "../../../../common/server/server-command";
import TableWithGroupsTree from "../../../../components/TableWithGroupsTree/TableWithGroupsTree";
import Helper from "../../../../common/helpers/main";
import Table from "../../../../components/Table/Table";
import PropTypes from "prop-types";
import moment from "moment";
import {SlideModalContext} from "../../../../containers/DialogsManager/containers/SlideModal/SlideModal";

export default class PopupInvoices extends React.Component {
    static contextType = SlideModalContext;

    servicesColumns = [
        {
            title: 'Код',
            code: 'code'
        },
        {
            title: 'Материал',
            code: 'name',
            searchable: true
        },
        {
            title: 'Остаток',
            code: 'quantity'
        },
        {
            title: 'Ед. измерения',
            code: 'measureShortName'
        }
    ];

    resultColumns = [
        {
            title: 'Материал',
            code: 'name',
            containsGroupTitle: true
        },
        {
            title: 'Цена',
            code: 'changePrice',
            type: 'changePrice'
        },
        {
            title: 'Количество',
            code: 'count',
            type: 'number',
            editable: true,
        },
        {
            title: 'Ед. измерения',
            code: 'measureShortName',
        },
        {
            title: 'К оплате',
            code: 'sum',
            type: 'price',
            countTotal: true
        },
    ];

    static propTypes = {
        reloading: PropTypes.func.isRequired,
        providers: PropTypes.array.isRequired,
        materials: PropTypes.array.isRequired,
        materialsGroups: PropTypes.object.isRequired,
        createInvoice: PropTypes.bool,
        detail: PropTypes.object,
        viewReloading: PropTypes.func,
    };

    static defaultProps = {
        createInvoice: false
    };

    state = {
        selectedGroupId: 0,
        chosenServiceId: 0,
        providers: null,
        searchQuery: '',
        selectedProvider: null,
        addedServices: {},
        btnDisabled: true
    };


    componentDidMount() {

        const providers = this.props.providers.map(provider => {
            return {
                value: provider.id,
                label: provider.name
            }
        });

        let newState = { providers, selectedProvider: providers[0] };

        if (this.props.createInvoice) {
            let addedServices = {};
            if (this.props.detail.items !== null) {
                this.props.detail.items.forEach((item) => {
                    addedServices[item.materialId] = {
                        changePrice: String(item.price),
                        count: item.quantity,
                        groupId: item.groupId,
                        id: item.materialId,
                        measureShortName: item.measureShortName,
                        name: item.name,
                        isChosen: true
                    }
                });
            }
            newState.addedServices = addedServices;
            newState.selectedProvider = providers[this.props.detail.providerId - 1];
        }

        this.setState(newState);
    }

    render() {

        if (this.state.providers === null) {
            return null
        }

        let newMaterial = Helper.clone(this.props.materials);
        let resultTableData = Helper.clone(Object.values(this.state.addedServices));

        resultTableData.forEach(service => {
            if (service.changePrice !== undefined) {
                service.sum = service.count * +(service.changePrice.replace(/\s/g, ''));
            }

        });

        let date = moment().format('DD MMMM YYYY');
        let popupTitle = 'Новая накладная';

        if (this.props.createInvoice) {
            date = moment(this.props.detail.date).format('DD MMMM YYYY');
            popupTitle = `Редактирование накладной (№ ${this.props.detail.id}) от ${date}`;
        }

        return (
            <div className="new-invoices">
                <div className="header-popup__title">{popupTitle}</div>
                <div className="box">
                    <div className="box-body">
                        <span>Поставщик</span>
                        <Select
                            options={this.state.providers}
                            value={this.state.selectedProvider}
                            onChange={this.changeSelectedProvider.bind(this)}
                        />
                        <h4>Список материалов</h4>
                        <div className="box">
                            <TableWithGroupsTree
                                groups={this.props.materialsGroups}
                                columns={this.servicesColumns}
                                data={newMaterial}
                                getRowCode={(service) => service.id}
                                selectedRows={Object.keys(this.state.addedServices).map(serviceId => Number(serviceId))}
                                onSelect={(selectedServicesIds, serviceId) => this.toggleServiceAdd(serviceId, newMaterial)}
                                searchPlaceholder="Поиск услуги"
                                scrollHeight={260}
                            />
                        </div>
                        <h4>Накладная от {date}</h4>
                        <div className="box">
                            <div className="treatment-plan__result-table">
                                <Table columns={this.resultColumns}
                                       data={resultTableData}
                                       selectMode="single"
                                       isEditable={true}
                                       selectedRows={[this.state.chosenServiceId]}
                                       onSelect={this.handleResultTableSelect.bind(this)}
                                       getRowCode={item => item.id}
                                       onChange={this.handleChangeResultTable.bind(this)}
                                       onRowDelete={serviceId => this.toggleServiceAdd(serviceId)}
                                       groups={this.props.materialsGroups}
                                       inputChangeNumber={this.inputChangeNumber.bind(this)}
                                />
                            </div>
                        </div>
                        <div className="box__btns">
                            <Button variant={this.state.btnDisabled ? 'disabled' : 'success'} children="Сохранить"
                                    size="long" onClick={this.changeWaybill}/>
                            <Button variant="default" children="Отменить" size="long"
                                    onClick={() => this.context.closeSlideModal()}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    changeSelectedProvider(selectedProvider) {
        let newState = {selectedProvider, btnDisabled: false};

        if (Object.values(this.state.addedServices).length <= 0) {
            newState.btnDisabled = true;
        }

        this.setState(newState)
    }

    /** Изменят цену в инпуте таблицы
     * @param serviceId {number}
     * @param fieldCode {string}
     * @param fieldValue {string} */
    inputChangeNumber(serviceId, fieldCode, fieldValue) {
        if (fieldCode === "changePrice") {
            let addedServices = Helper.clone(this.state.addedServices);
            addedServices[serviceId].changePrice = fieldValue;
            let btnDisabled = false;
            if (fieldValue.length <= 0) {
                btnDisabled = true;
            }
            this.setState({addedServices, btnDisabled});
        }
    }

    /**
     * Добавляет / удаляет услугу из накладной
     * @param serviceId {number}
     * @param newMaterial {Object}
     */
    toggleServiceAdd(serviceId, newMaterial) {
        let addedServices = Helper.clone(this.state.addedServices);
        let newState = {};

        if (!!addedServices[serviceId]) {

            delete addedServices[serviceId];

            if (serviceId === this.state.chosenServiceId) {
                newState.chosenServiceId = 0;
            }

        } else {

            let addedService = Helper.clone(Helper.Array.toObject(newMaterial, 'id')[serviceId]);
            addedService.count = 1;
            addedService.changePrice = '';
            addedService.isChosen = true;

            addedServices[serviceId] = addedService;
        }

        newState.addedServices = addedServices;
        newState.btnDisabled = !Object.values(addedServices).length; // Дизаблит кнопку Сохранить
        this.setState(newState);

    }

    /** При изменение количества добавляется счетчик
     * @param serviceId {number}
     * @param fieldCode {string}
     * @param fieldValue {number} */
    handleChangeResultTable(serviceId, fieldCode, fieldValue) {
        if (fieldCode === "count") {
            this.setServiceCount(serviceId, fieldValue)
        }
    }

    /** Вспомогательная функция для handleChangeResultTable
     * @param serviceId {number}
     * @param count {number} */
    setServiceCount(serviceId, count) {
        let addedServices = Helper.clone(this.state.addedServices);
        addedServices[serviceId].count = count;
        this.setState({addedServices, btnDisabled: false});
    }

    /** Добавляет выделение в таблицу
     * @param serviceId {number}
     * @param selectedServiceIds */
    handleResultTableSelect(selectedServiceIds, serviceId) {
        const selectedService = this.state.addedServices[serviceId];

        this.toggleChosenService(selectedService)
    }

    /** Вспомогательная функция для handleResultTableSelect
     * @param service */
    toggleChosenService(service) {
        let newState = {};

        if (this.state.chosenServiceId === service.id) {
            newState.chosenServiceId = 0
        } else {
            newState.chosenServiceId = service.id;
        }

        this.setState(newState)
    }

    /** Добавляем или редактируем накладную */
    changeWaybill = () => {

        const waybill = {
            providerId: this.state.selectedProvider.value,
            items: Helper.mapObj(this.state.addedServices, (item) => {
                return {
                    materialId: item.id,
                    price: item.changePrice.replace(/\s/g, ''),
                    quantity: item.count
                }
            })
        };

        let nameCommand = 'material/add-waybill';

        if (this.props.createInvoice) {
            nameCommand = 'material/update-waybill';
            waybill.id = this.props.detail.id
        } else {
            waybill.date = moment().format('YYYY-MM-DD');
        }

        this.setState({btnDisabled: true});

        const command = new ServerCommand(nameCommand, waybill);
        command.exec().then(() => {
            this.context.closeSlideModal();
            this.props.reloading();
            if (this.props.createInvoice) {
                this.props.viewReloading();
            }
            this.setState({btnDisabled: false});
        });
    }


}
