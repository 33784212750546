import React from "react";
import styled from "styled-components";
import {ITheme} from "../../../../common/Theme/Theme";

interface IPaper {
    children?: any,
    isModal?: boolean,
    arrowPosition?: 'top' | 'left' | 'right' | 'bottom',
    position?: 'top' | 'left' | 'right' | 'bottom',
    active?: boolean,
    padding?: string,
    margin?: string,
    theme?: ITheme | any,
    maxWidth?: string,
    minHeight?: number | string,
    minWidth?: string,
    isHidden?: boolean,
    zIndex?: number,
    borderRadius?: string
    height?: string
    width?: string
    wrapHeight?: string
}

const boxShadow = (props: IPaper) => {
    if (props.isModal) {
        if (props.active) {
            return props.theme.shadow.defaultActive
        } else {
            return props.theme.shadow.defaultModalLight
        }
    } else {
        if (props.active) {
            return props.theme.shadow.defaultActive
        } else {
            return props.theme.shadow.default
        }
    }
};

const topWrap = (props: IPaper) => {
    switch (props.position) {
        case "top":
        case "right":
        case "left":
        case "bottom":
            return 'calc(100% + 8px)';
        default:
            return 'auto'
    }
};

const leftWrap = (props: IPaper) => {
    switch (props.position) {
        case "bottom":
        case "top":
            return '50%';
        case "right":
        case "left":
        default:
            return 'auto'
    }
};

const rightWrap = (props: IPaper) => {
    switch (props.position) {
        case "right":
        case "left":
        case "bottom":
        case "top":
        default:
            return 'auto'
    }
};

const bottomWrap = (props: IPaper) => {
    switch (props.position) {
        case "top":
        case "right":
        case "left":
        case "bottom":
        default:
            return 'auto'
    }
};

const Wrap = styled.div<IPaper>`
  border-radius: ${props => !!props.borderRadius ? props.borderRadius : '4px'};
  box-shadow: ${boxShadow};
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.padding || 0};
  margin: ${props => !!props.margin ? props.margin : '0 auto'};
  position: ${props => !!props.position ? 'absolute' : 'relative'};
  max-width: ${props => !!props.maxWidth ? props.maxWidth : '100%'};
  min-height: ${props => !!props.minHeight ? props.minHeight + 'px' : 'auto'};
  min-width: ${props => !!props.minWidth ? props.minWidth : 'auto'};;
  top: ${topWrap};
  left: ${leftWrap};
  right: ${rightWrap};
  bottom: ${bottomWrap};
  transform: translate(${props => props.position === 'top' || props.position === 'bottom' ? '-50%' : 0}, ${props => props.position === 'left' || props.position === 'right' ? '-50%' : 0});
  z-index: ${props => props.zIndex};
  ${props => !!props.height && `height: ${props.height};`};
  ${props => !!props.width && `width: ${props.width};`};
`;

interface PWrapContent {
    isHidden: boolean,
    height?: string
}

const WrapContent = styled.div<PWrapContent>`
  overflow: ${props => props.isHidden ? 'hidden' : 'visible'};
  border-radius: 4px;
  ${props => !!props.height && `height: ${props.height};`};
`;

const transformArrow = (props: IPaper) => {
    switch (props.arrowPosition) {
        case "bottom":
            return 180;
        case "left":
            return -90;
        case "right":
            return 90;
        case "top":
        default:
            return 0
    }
};
const marginLeft = (props: IPaper) => {
    switch (props.arrowPosition) {
        case "left":
            return '0px';
        case "right":
            return '-4px';
        case "top":
        case "bottom":
        default:
            return '-8px'
    }
};
const marginRight = (props: IPaper) => {
    switch (props.arrowPosition) {
        case "left":
            return '-4px';
        case "bottom":
        case "right":
        case "top":
        default:
            return '0px'
    }
};
const marginTop = (props: IPaper) => {
    switch (props.arrowPosition) {
        case "left":
        case "right":
            return '-4px';
        case "bottom":
        case "top":
        default:
            return '0px'
    }
};

const Arrow = styled.div<IPaper>`
  position: absolute;
  bottom: ${props => props.arrowPosition === 'top' ? '100%' : 'auto'};
  right: ${props => props.arrowPosition === 'left' ? '100%' : 'auto'};
  left: ${props => (props.arrowPosition === 'top' || props.arrowPosition === 'bottom') ? '50%' : props.arrowPosition === 'right' ? '100%' : 'auto'};
  top: ${props => (props.arrowPosition === 'left' || props.arrowPosition === 'right') ? '50%' : props.arrowPosition === 'bottom' ? '100%' : 'auto'};
  margin-left: ${marginLeft};
  margin-top: ${marginTop};
  margin-right: ${marginRight};
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent ${props => props.theme.colors.white} transparent;
  transform: rotate(${transformArrow}deg);
`;

const Paper = React.forwardRef((
    {
        children,
        active,
        arrowPosition,
        isModal,
        padding,
        position,
        margin,
        maxWidth,
        minHeight,
        minWidth,
        isHidden = true,
        zIndex = 11,
        borderRadius,
        height,
        width,
        wrapHeight
    }: IPaper, ref: any) => {
    return (
        <Wrap active={active} arrowPosition={arrowPosition} padding={padding}
              position={position} margin={margin} isModal={isModal} maxWidth={maxWidth}
              minHeight={minHeight} minWidth={minWidth} zIndex={zIndex} borderRadius={borderRadius}
              height={height} width={width} wrapHeight={wrapHeight} ref={ref}
        >
            {!!arrowPosition && <Arrow arrowPosition={arrowPosition}/>}
            <WrapContent isHidden={isHidden} height={wrapHeight || height} children={children}/>
        </Wrap>
    )
})

export default Paper
