import tinycolor from 'tinycolor2'

let ColorHelper = {

    lighten: function (initColor, value) {
        let color = tinycolor(initColor),
            coef = 1 - Math.pow((color.getBrightness() / 255), 3);

        return color.lighten(value * coef).toString();
    },

    darken: function (initColor, value) {
        let color = tinycolor(initColor),
            coef = color.getBrightness() / 255;

        return color.darken(value * coef).toString();
    },

    hexToRgb: function (hex) {
        return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
            , (m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1).match(/.{2}/g)
            .map(x => parseInt(x, 16))
    }
};

export default ColorHelper
