import React from 'react';
import {SContainerItem} from '../../../../../containers/Tasks/style';
import Button from "../../../../../components-ui/Inputs/Button/Button";
import {TaskComplete} from "../../../types/components";
import {Divider} from "../../../../../components-ui/DataDisplay/Divider";
import useLoader from "../../../../../components/Errors";

interface Props {
    taskId: number
    taskComplete: TaskComplete
}

const ButtonComplete = ({taskId, taskComplete}: Props) => {
    const [loader, setLoader] = useLoader()

    const onClickButton = () => {
        setLoader(true)
        taskComplete(taskId).then(() => setLoader(false))
    }
    return (
        <>
            <Divider/>
            <SContainerItem padding="23px 20px 32px">
                <Button fontSize="14px" height="40px" children="Завершить" variant="default" width="100%"
                        loader={loader} onClick={onClickButton}/>
            </SContainerItem>
        </>
    );
};

export default ButtonComplete;
