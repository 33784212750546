import React, {useMemo} from "react";
import Button from "../../../components-ui/Inputs/Button/Button";
import Helper from "../../../common/helpers/main";
import {Label} from "../../../components-ui/DataDisplay/Label";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import {DirectionsFields} from "./components/DirectionsFields";
import {commonLabelOption} from "./data";
import Discount from "./components/Discount";
import PaymentMethod from "./components/PaymentMethod";
import InsuranceSum from "./components/InsuranceSum";
import AccountBalance from "./components/AccountBalance";
import {useSelector} from "react-redux";
import {useOptionsBranchesUser} from "../../../common/hooks/useOptionsBranchesUser";
import FilterBranches from "./components/FilterBranches";
import Box from "../../../components-ui/Layout/Box";
import FilterPatient from "./components/FilterPatient";
import useCashCreate from "./hooks/useCashCreate";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import Kkm from "../../../common/server/kkm";
import KkmSelect from "./components/KkmSelect";
import Operation from "./components/Operation";
import FilterUser from "./components/FilterUser";
import Comment from "./components/Comment";
import Expenditure from "./components/Expenditure";
import CashBox from "./components/CashBox";
import ValidationLinkInfo from "../../../components/ValidationLinkInfo/ValidationLinkInfo";

interface Props {
    patientId?: number
    loadingFromServer: () => void
}

const CashCreate = ({patientId: fixedPatientId, loadingFromServer}: Props) => {

    const discountTypeOptions = useSelector(state => state.common.discountTypeOptions);
    const paymentMethodOptions = useSelector(state => state.common.paymentMethodOptions);
    const cashboxOptions = useSelector(state => state.common.cashboxOptions);
    const operationOptions = [
        {value: 'P', label: 'Внести аванс'},
        {value: 'U', label: 'Внесение ДС сотрудником'},
        {value: 'C', label: 'Внесение ДС контрагентом'}
    ]
    const optionsBranches = useOptionsBranchesUser()

    const {
        kkmError,
        data,
        patientId,
        setPatientId,
        userId,
        setUserId,
        state,
        setState,
        handleSubmit,
        loader,
        paymentSum,
        kkmOptions
    } = useCashCreate({
        fixedPatientId,
        loadingFromServer,
        discountTypeOptions,
        paymentMethodOptions,
        cashboxOptions,
        operationOptions,
        optionsBranches
    })

    if (fixedPatientId !== undefined && data === null) {
        return null
    }

    const validate = () => {
        let valid = true
        valid = valid && !!state.values[Object.keys(state.values)[0]]
        valid = valid && state.branch !== undefined
        switch (state.type) {
            case 'P':
                valid = valid && !!patientId
                break
            case 'U':
                valid = valid && !!userId
                valid = valid && !!state.selectedExpenditure?.value
                break
            case 'C':
                valid = valid && !!state.selectedExpenditure?.value
                break
            default:
                return valid = false
        }
        if (!!state.selectedKkm) {
            valid = valid && !kkmError
        }
        if (state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' && kkmOptions.length > 0 && !state.selectedKkm) {
            valid = false
        }

        return valid
    }

    return (
        <Box padding="30px 27px 69px" minWidth="549px" width="549px">
            <Typography children="Добавление оплаты" textAlign="center" variant="bigger-title" margin="25px 0 59px"/>
            {(state.type == 'P' && data && patientId) &&
            <Box position="absolute" positionOffsets={{top: "110px"}}>
                <Label labelText="" {...commonLabelOption}>
                        <span>
                            На счёте пациента {Helper.Price.format(data.account)} руб.
                        </span>
                </Label>
            </Box>
            }

            <Operation state={state} setState={setState} operationOptions={operationOptions}/>

            {state.type == 'P' && fixedPatientId === undefined &&
            <FilterPatient setPatientId={setPatientId}/>
            }

            {(state.type == 'U' || state.type == 'C') &&
                <Comment state={state} setState={setState}/>
            }

            {(state.type == 'U' || state.type == 'C') &&
                <Expenditure state={state} setState={setState}/>
            }

            {state.type == 'U' &&
                <FilterUser setUserId={setUserId}/>
            }

            {optionsBranches.length > 1
                ?
                <FilterBranches state={state} setState={setState}/>
                :
                null
            }

            <CashBox state={state} setState={setState}/>


            {state.type == 'P' && state.selectedCashbox.value == 'ADDITIONAL_BOX_OFFICE' &&
                <PaymentMethod state={state} setState={setState}/>
            }

            {state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' && kkmOptions.length > 0 &&
                <KkmSelect state={state} setState={setState} kkmOptions={kkmOptions}/>
            }

            <DirectionsFields
                values={state.values} setState={setState}
                directionsBalance={!!data ? data.directionsBalance : null}/>

            {state.type == 'P' &&
                <Discount state={state} setState={setState} paymentSum={paymentSum}/>
            }

            {state.type == 'P' &&
                <InsuranceSum state={state} setState={setState} paymentSum={paymentSum}/>
            }

            <Label labelText="" {...commonLabelOption}>
                <Button
                    variant={(validate()) ? 'success' : 'disabled'}
                    children='Внести'
                    loader={loader}
                    size="md" onClick={handleSubmit}/>
            </Label>

            {(data && patientId) &&
            <AccountBalance data={data} paymentSum={paymentSum}/>
            }
            <ValidationLinkInfo validate={!kkmError} onClickIsValidate={() => {}}
                                text={kkmError} linkText={""}/>
        </Box>
    )
};

export default CashCreate;
