import * as React from "react";
import {HeaderDesc} from "../styledStages";
import {CellDesc} from "../typesStages";

interface PHeaderDescription {
    cellDesc: CellDesc[]
}


export default function HeaderDescription({cellDesc}: PHeaderDescription) {
    return (
        <HeaderDesc>
            {cellDesc.map(cell => {
                return (
                    <div key={cell.code} style={{width: cell.width}}>
                        {cell.name}
                    </div>
                )
            })}
        </HeaderDesc>
    )
}
