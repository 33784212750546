import {ItemChangeConfig} from "../types";
import {Tooth} from "../../../common/types/teethmap";

export default function changeItemConfig(prevItem: Tooth, nextItem: ItemChangeConfig, statusIndependentAttributes: number[]) {
    prevItem.unconfirmed = false;
    prevItem.status = nextItem.status
    changeAttributesConfig(prevItem, nextItem, statusIndependentAttributes)
    changeGumDiseaseConfig(prevItem, nextItem)
}
export const changeAttributesConfig = (prevItem: Tooth, nextItem: ItemChangeConfig, statusIndependentAttributes: number[]) => {
    if (!!nextItem.attributes) {
        prevItem.attributes = nextItem.attributes
    } else {
        if (!!prevItem.attributes) {
            prevItem.attributes = prevItem.attributes.filter(attribute => statusIndependentAttributes.indexOf(attribute) !== -1)
        }
    }
}

export const changeGumDiseaseConfig = (prevItem: Tooth, nextItem: ItemChangeConfig) => {
    if (!!nextItem.gumDisease) {
        prevItem.gumDisease = nextItem.gumDisease
    } else {
        if (!!prevItem.gumDisease) delete prevItem.gumDisease
    }
}
