import React from 'react';
import Tab from "./elements/Tab/Tab";
import Button from "../../Inputs/Button/Button";
import {PTabs} from './typesTabs';
import {SWrapTabs, STabs} from './styledTabs'
import SVG from "../../../common/helpers/SVG";

export default function Tabs(
    {
        tabs,
        activeTab,
        onChange,
        variant = 'default',
        size = 'default',
        align = 'left',
        widthTab,
        disabled = false,
        borderBottom = false,
        noLeftMargin = false,
        onTabAdd,
        onTabDelete,
        onTabReplace,
        margin
    }: PTabs) {

    const isNote = tabs.some(tab => !!tab.note);

    return (
        <SWrapTabs align={align} variant={variant} borderBottom={borderBottom} margin={margin}>
            <STabs align={align} variant={variant} noLeftMargin={noLeftMargin} borderBottom={borderBottom}>
                {tabs.map((tab, index) => {
                    return <Tab tab={tab}
                                key={tab.code}
                                activeTab={tab.code === activeTab}
                                onChange={onChange}
                                widthTab={widthTab}
                                variant={variant}
                                isNote={isNote}
                                noLeftMargin={noLeftMargin}
                                isFirstChild={index === 0}
                                isLastChild={index === tabs.length - 1}
                                onTabDelete={onTabDelete}
                                disabled={disabled}
                                size={size}
                                onTabReplace={onTabReplace}/>
                })}
            </STabs>
            {onTabAdd &&
            <Button isIcon variant="default" size="sm" onClick={onTabAdd}><SVG name="plus"/></Button>}
        </SWrapTabs>
    )
}
