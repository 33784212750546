import React from 'react';
import {GetDoneServicesResult} from '../../../types';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import {Label} from '../../../../../components-ui/DataDisplay/Label';
import {
    commonLabelOption,
    commonSelectOption,
} from '../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import Box from '../../../../../components-ui/Layout/Box';
import Button from '../../../../../components-ui/Inputs/Button/Button';
import {OptionSelect} from '../../../../../components-ui/Inputs/Select/typesSelect';
import DateTime from '../../../../../components-ui/Inputs/DateTime/DateTime';
import useMarkDoneEmployees from './hooks/useMarkDoneEmployees';
import Select from '../../../../../components-ui/Inputs/Select/Select';
import {IDoneEmployeeServiceRow} from '../types';
import _ from 'lodash';
import Counter from '../../../../../components-ui/Inputs/Counter/Counter';

interface Props {
    services: GetDoneServicesResult['services'];
    service?: IDoneEmployeeServiceRow;
    refreshDataFromServer: () => void;
    selectedBranch: OptionSelect;
}


const ModalMarkDoneEmployeeService = ({services, service, refreshDataFromServer, selectedBranch}: Props) => {

    const {
        state,
        loader,
        saveDoneEmployeeService,
        handleDate,
        handleEmployee,
        handleDoctor,
        handleService,
        handleQuantity,
        optionsDoctors,
        optionsTechnics,
        optionsServices
    } = useMarkDoneEmployees(
        {services, service, refreshDataFromServer, selectedBranch},
    );

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='middle-title' fontWeight='600'
                        textAlign='center'>
                {`${service ? 'Изменение' : 'Добавление'} услуги`}
            </Typography>
            <Box margin='39px 0 0 0'>
                <Label labelText='Дата' isRequired={true} {...commonLabelOption}>
                    <DateTime selected={state.date}
                              maxDate={new Date()}
                        // @ts-ignore
                              onChange={(val: Date) => {
                                  handleDate(val);
                              }} />
                </Label>
                <Label labelText='Техник' isRequired={true} {...commonLabelOption} >
                    <Select {...commonSelectOption}
                            colorSelect='white'
                            placeholder='Выберите техника'
                            value={state.employee}
                            options={optionsTechnics}
                            onChange={(value) => handleEmployee(value)}
                    />
                </Label>
                <Label labelText='Врач' isRequired={true} {...commonLabelOption} >
                    <Select {...commonSelectOption}
                            colorSelect='white'
                            placeholder='Выберите врача'
                            variant='colored'
                            value={state.doctor}
                            options={optionsDoctors}
                            onChange={(value) => handleDoctor(value)}
                    />
                </Label>
                <Label labelText='Услуга' isRequired={true} {...commonLabelOption} >
                    <Select {...commonSelectOption}
                            colorSelect='white'
                            placeholder='Выберите услугу'
                            value={state.service}
                            options={optionsServices}
                            onChange={(value) => handleService(value)}
                    />
                </Label>
                <Label labelText='Количество' isRequired={true} {...commonLabelOption} >
                    <Counter value={state.quantity}
                             onChange={(val) => handleQuantity(+val)}/>
                </Label>
            </Box>
            <Label labelText='' {...commonLabelOption}>
                <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                    <Button children='Сохранить'
                            size='md'
                            loader={loader}
                            variant={_.every(state, Boolean) ? 'success' : 'disabled'}
                            onClick={saveDoneEmployeeService}
                    />
                </Box>
            </Label>
        </Box>
    );
};

export default ModalMarkDoneEmployeeService;
