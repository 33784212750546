import React, {ReactChild, ReactNode} from "react";
import Paper from "../../../../UI-Collection/Elements/Surfaces/Paper";
import Header from "../components/Header";
import {Divider} from "../../../../../components-ui/DataDisplay/Divider";
import Buttons from "../components/Buttons";
import {GroupsButtonName, NoticesQuantity} from "../types";
import {TasksInfo} from "../../../types";
import {useFilter} from "../index";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import SelectCheckbox from "../../../../../components-ui/Inputs/SelectCheckbox/SelectCheckbox";
import { SContainerItem } from "../../../style";

interface Props {
    groupsButtonName: GroupsButtonName
    noticesQuantity: NoticesQuantity
    noticeQuantityTotal: number
    tasksInfo: TasksInfo
    noticeName?: string
    headerRightComponent?: ReactChild | ReactNode
    footerComponent?: ReactChild | ReactNode
    isMarketing?: boolean
    handleHeightFilter: (heightFilter: number) => void
    selectedFilter: string
    handleSelectedFilter: (selectedFilter: string) => void
    optionsBranches?: OptionSelect[]
    selectedBranches?: number[]
    handleBranches?: (values: OptionSelect[]) => void
}

const Filter = ({
                    footerComponent,
                    headerRightComponent,
                    noticeName,
                    noticeQuantityTotal,
                    noticesQuantity,
                    groupsButtonName,
                    tasksInfo,
                    isMarketing,
                    handleHeightFilter,
                    selectedFilter,
                    handleSelectedFilter,
                    optionsBranches,
                    selectedBranches,
                    handleBranches
                }: Props) => {

    const {
        showHiddenButtons,
        setShowHiddenButtons,
        refHeader,
        refFooter,
        refTop,
        heightContainerButtons
    } = useFilter({
        groupsButtonName,
        noticesQuantity,
        isMarketing,
        noticeQuantityTotal,
        tasksInfo,
        handleHeightFilter,
        selectedFilter,
        handleSelectedFilter
    })

    return (
        <Paper borderRadius="0px" active={true} isHidden={false} zIndex={14}>
            <span ref={refHeader}>
                <Header noticeName={noticeName}
                        noticeQuantityTotal={noticeQuantityTotal}
                        headerRightComponent={headerRightComponent}/>
                <Divider/>
            </span>
            <span ref={refTop}>
                {(!!optionsBranches && optionsBranches.length > 1 && !!selectedBranches && !!handleBranches ) && (
                    <>
                        <SContainerItem padding="7px 20px 8px">
                            <SelectCheckbox options={optionsBranches}
                                            placeholder="Все доступные филиалы"
                                            selected={optionsBranches.filter(branch => !!selectedBranches && selectedBranches.includes(branch.value))}
                                            onChange={handleBranches}
                                            width="100%"
                                            variant="sm"
                            />
                        </SContainerItem>
                        <Divider/>
                    </>
                )}
            </span>
            <Buttons showHiddenButtons={showHiddenButtons}
                     setShowHiddenButtons={setShowHiddenButtons}
                     noticeQuantityTotal={noticeQuantityTotal}
                     groupsButtonName={groupsButtonName}
                     noticesQuantity={noticesQuantity}
                     selectedFilter={selectedFilter}
                     handleSelectedFilter={handleSelectedFilter}
                     heightContainerButtons={heightContainerButtons}
                     tasksInfo={tasksInfo}
            />
            <span ref={refFooter}>
                {!!footerComponent && (
                    <>
                        <Divider/>
                        {footerComponent}
                    </>
                )}
            </span>
        </Paper>
    );
}

export default Filter
