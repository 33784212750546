import React, {Dispatch, SetStateAction} from "react";
import Tabs from "../../../../../components-ui/Navigation/Tabs/Tabs";
import Box from "../../../../../components-ui/Layout/Box";
import {StateDetailed} from "../hooks/useDetailed";

interface Props {
    detailedState: StateDetailed
    setDetailedState: Dispatch<SetStateAction<StateDetailed>>
}

const PatientsTabs = ({detailedState, setDetailedState}: Props) => {
    if (!detailedState.tabPatient || !detailedState.optionsTabsPatients) return null;
    return (
        <Box position="absolute" positionOffsets={{top: '173px', left: '0', transformX: '-100%'}} zIndex={1}>
            <Tabs tabs={detailedState.optionsTabsPatients} activeTab={detailedState.tabPatient}
                  onChange={tabPatient => setDetailedState(prevState => ({...prevState, tabPatient}))} variant="list"/>
        </Box>
    )
}

export default PatientsTabs
