import {useMemo} from "react";
import {ReceiptsReport} from "../../../../../../types";
import {StateReceipts} from "../../../types";

interface Props {
    sumsCodes: string[]
    report: ReceiptsReport
    state: StateReceipts
}

const useAllTotalsSum = ({sumsCodes, report, state}: Props) => {
    return useMemo(() => {
        let res: { [key: string]: number } = {};
        sumsCodes.forEach(code => {
            if (state.currentTab === 'doctors' && state.selectedDirection) {
                const directionDoctors = state.selectedDirection === 'all' ? report.current.general.doctors : report.current.directions[state.selectedDirection].doctors
                res[code] = directionDoctors[+code].sum
            } else {
                res[code] = report.current.directions[code].sum
            }
        })
        return res;
    }, [JSON.stringify(report), state.currentTab, state.selectedDirection])
}

export default useAllTotalsSum
