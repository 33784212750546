import {StateAnalytics, StateWidget} from "../types";
import Helper from "../../../common/helpers/main";

interface Props {
    startDate?: string
    endDate?: string
    widget: StateWidget
    widgetCode: keyof StateAnalytics
}

export const prepareReportsParam = ({startDate, endDate, widgetCode, widget}: Props) => {
    return {
        startDate: Helper.Date.formatDateForServer(!!startDate ? startDate : widget.startDate),
        endDate: Helper.Date.formatDateForServer(!!endDate ? endDate : widget.endDate),
        code: Helper.getSnakeCase(widgetCode)
    }
}
