import React from 'react';
import './CaseHistory.scss';
import PropTypes from "prop-types";
import ServerCommand from 'common/server/server-command';
import Table from "../../../../components/Table/Table";
import Select from "../../../../components-ui/Inputs/Select/Select";
import Button from "../../../../components-ui/Inputs/Button/Button";
import moment from 'moment/moment';
import 'moment/min/locales.min';  // оставить для отображение русского языка
import Helper from 'common/helpers/main'
import PopupCaseHistory from "./PopupCaseHistory/PopupCaseHistory";
import {DialogsManagerContext} from "../../../../containers/DialogsManager/DialogsManager";
import PopupCaseHistoryImport from "./PopupCaseHistoryImport/PopupCaseHistoryImport";

export default class CaseHistory extends React.Component {
    static contextType = DialogsManagerContext;
    state = {
        list: null,
        selectedDoctor: {
            value: 'all',
            label: 'Все',
        },
        selectedTooth: {
            value: 'all',
            label: 'Все'
        },
        newSurveyPopupId: null,
        doctors: null
    };

    static propTypes = {
        patientId: PropTypes.number.isRequired,
        patient: PropTypes.object.isRequired
    };

    caseHistoryColumns = [
        {
            title: 'Прием',
            code: 'visitTime',
            containsGroupTitle: true
        },
        {
            title: 'Врач',
            code: 'doctor'
        },
        {
            title: 'Диагнозы',
            code: 'diagnoses'
        },
    ];

    componentDidMount() {
        moment.locale('ru');
        this.loadData()
    };

    render() {

        if (this.state.list === null) {
            return null
        }

        return (
            <div className="case-history-patient-card">
                {(this.state.list.length > 0) ? this.renderCaseHistoryList() : this.renderNoCaseHistory()}
            </div>
        )
    };

    renderCaseHistoryList() {

        const selectedTooth = this.state.selectedTooth.value;
        let items = Helper.clone(this.state.list);

        /* добавляем информацию о врачах */
        items.forEach(item => item.doctor = this.state.doctors[item.doctorId]);

        /* фильтрация по врачу и зубам */
        items = items.filter((item) => {
            const suitableByDoctor = (this.state.selectedDoctor.value === item.doctor?.id || this.state.selectedDoctor.value === 'all');
            return suitableByDoctor && ((selectedTooth === 'all') || item.teeth.includes(selectedTooth));
        });

        return (
            <div className="box">
                <div className="box-body box-body--top">
                    <Select variant="colored"
                            label={'Врач:'}
                            value={this.state.selectedDoctor}
                            options={this.getDoctorsOptions()}
                            onChange={(selectedDoctor) => this.setState({selectedDoctor})}
                    />
                    <Select label={'Зуб:'}
                            value={this.state.selectedTooth}
                            options={this.getTeethOptions(items)}
                            onChange={(selectedTooth) => this.setState({selectedTooth})}
                    />
                    <Button children="Сбросить" size="md" variant="standard" onClick={() => this.setState({
                        selectedDoctor: {
                            value: 'all',
                            label: 'Все',
                            color: 'transparent'
                        },
                        selectedTooth: {
                            value: 'all',
                            label: 'Все'
                        }
                    })}/>
                </div>
                <div className="box-content">
                    <div className="box-body">
                        <div className="card">
                            <Table columns={this.caseHistoryColumns}
                                   data={items}
                                   prepareDataItem={this.prepareVisits}
                                   selectMode="single"
                                   getRowCode={(caseHistory) => {
                                       return {
                                           visitId: caseHistory.visitId,
                                           historyId: caseHistory.historyId
                                       }
                                   }}
                                   onSelect={(selectedIds, id) => {
                                       this.showPopup(id, items)
                                   }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    renderNoCaseHistory() {
        return (
            <div className="box box--min">
                <div className="case-history__info-tip">
                    <h2>Истории болезни пока нет</h2>
                    <p className="case-history__info">Пациент не был на приеме у врача</p>
                </div>
            </div>
        )
    };

    renderVisitDoctor(doctor, visitId) {
        if (doctor) {
            return (
                <span className="table__doctor-wrap" key={doctor.id + ' ' + visitId}>
                <span className="table__doctor-circle" style={{backgroundColor: doctor.color}}/>
                    {Helper.getFio(doctor)}
            </span>
            );
        } else {
            return null
        }
    }

    prepareVisits = ({item: visit}) => {
        const {doctors} = this.state;
        const doctor = doctors[visit.doctorId];

        visit.visitTime = moment(visit.visitTime).format('DD MMMM YYYY в HH:mm');
        visit.doctor = this.renderVisitDoctor(doctor, visit.visitId);
        visit.diagnoses = [];

        visit.targetsDiagnoses.forEach(targetsDiagnosis => {
            let diagnosis = targetsDiagnosis.diagnosis.length ? targetsDiagnosis.diagnosis : 'диагноз не заполнен';

            if (diagnosis.endsWith('.')) {
                diagnosis = diagnosis.substr(0, diagnosis.length - 1);
            }

            visit.diagnoses.push(targetsDiagnosis.units.join(', ') + ': ' + Helper.lcfirst(diagnosis), <br/>);
        });
    };

    getDoctorsOptions() {
        let doctors = Helper.mapObj(this.state.doctors, doctor => ({
            label: Helper.getFio(doctor),
            value: doctor.id,
            color: doctor.color
        }));

        doctors.sort((doctorA, doctorB) => {
            if (doctorA.label > doctorB.label) {
                return 1
            } else if (doctorA.label < doctorB.label) {
                return -1
            } else {
                return 0
            }
        });

        doctors.unshift({
            value: 'all',
            label: 'Все',
        });

        return doctors
    };

    getTeethOptions = (list) => {
        let teeth = list.reduce((teeth, caseHistory) => (caseHistory.teeth) ? teeth.concat(caseHistory.teeth) : teeth, []);

        teeth = Helper.Array.unique(teeth).sort();

        teeth = teeth.map((tooth) => ({
            label: tooth,
            value: tooth,
        }));

        teeth.unshift({
            value: 'all',
            label: 'Все'
        });
        return teeth
    };

    showPopup = (id, items) => {
        let date = null;
        if (items !== null) {
            items.forEach(item => {
                if (item.visitId === id.visitId || item.historyId === id.historyId) {
                    date = item.visitTime;
                }
            });
        }
        if (id.visitId) {
            this.props.showSlideModal(<PopupCaseHistory
                visitId={id.visitId}
                patient={this.props.patient}
                date={date}
                loadData={this.loadData}
            />)
        } else if (id.historyId) {
            this.props.showSlideModal(<PopupCaseHistoryImport
                historyId={id.historyId}
                patient={this.props.patient}
                date={date}
                loadData={this.loadData}
            />)
        }

    };

    loadData = () => {
        return new Promise((resolve) => {
            ServerCommand
                .get('case-history/list-for-patient', {
                    patientId: this.props.patientId
                })
                .then((response) => {
                    this.setState({
                        list: response.list ? response.list : [],
                        doctors: Helper.Array.toObject(response.doctors, 'id')
                    });
                });

            resolve();
        })
    };
}
