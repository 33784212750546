import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const MouseRightIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 15.2 24"} ref={ref}>
            <path d="M0,7.6v8.8a7.6,7.6,0,1,0,15.2,0V7.6A7.6,7.6,0,0,0,0,7.6Zm.78,4.15H14.41V16.4a6.82,6.82,0,1,1-13.63,0Zm0-4.15A6.83,6.83,0,0,1,7.24.79V11H.78Z"/>
        </WrapIcon>
    );
});
