import React from 'react';
import Box from "../../../../components-ui/Layout/Box";
import Button from "../../../../components-ui/Inputs/Button/Button";
import PaymentCreationForm from "../../../../content/modals/PaymentCreationForm";
import {useDialogsManager} from "../../../../containers/DialogsManager/DialogsManager";

interface Props {
    refreshDataFromServer: () => void
}

const AddPayment = ({refreshDataFromServer}: Props) => {

    const {showModal} = useDialogsManager();
    return (
        <>
            <Box margin="20px 0 40px 24px">
                <Button variant="success" children="Добавить оплату" onClick={() => showModal(
                    <PaymentCreationForm
                        loadingFromServer={refreshDataFromServer}
                    />)
                }/>
            </Box>
        </>

    );
};

export default AddPayment;
