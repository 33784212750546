import {Dispatch, SetStateAction, useState} from 'react';
import {useModal} from '../../../../../../containers/DialogsManager/containers/Modal/Modal';
import {OptionSelect} from '../../../../../../components-ui/Inputs/Select/typesSelect';
import {SaveTimesheetParams} from '../../../../../../content/TimeSheet/types';
import ServerCommand from '../../../../../../common/server/server-command';
import {ModalAddEmployeeState} from '../../types';
import DateHelper from '../../../../../../common/helpers/date-helper';
import useServerTimeMoment from '../../../../../../common/hooks/useServerTimeMoment';


interface Props {
    state: ModalAddEmployeeState;
    setState: Dispatch<SetStateAction<ModalAddEmployeeState>>;
    selectedBranchId: number;
    isDisabledEndTime: boolean;
    setIsDisabledendTime: Dispatch<SetStateAction<boolean>>;
    refreshDataFromServer: () => void;
}

const useModalTimesheetRecord = ({
                                     selectedBranchId,
                                     state,
                                     setState,
                                     isDisabledEndTime,
                                     setIsDisabledendTime,
                                     refreshDataFromServer,
                                 }: Props) => {

    const currentTime = useServerTimeMoment().format(DateHelper.FORMAT_TIME_MINUTES);

    const isDateCurrent = DateHelper.formatDateForServer(state.date) ===
        DateHelper.formatDateForServer(new Date());

    const validate = !!state.employee && !!(isDateCurrent || state.endTime) && state.startTime !== state.endTime;

    const {closeModal} = useModal();

    const [validateMark, setValidateMark] = useState(false);

    const changeDisabledCheckBox = () => {
        setState(prevState => ({...prevState, endTime: isDisabledEndTime ? '' : currentTime}));
        setIsDisabledendTime(prevState => !prevState);
    };

    const handleEmployee = (employee: OptionSelect) => {
        setState(prevState => {
            return {...prevState, employee};
        });
        setValidateMark(validate);
    };

    const handleStartComment = (val: string) => {
        setState(prevState => {
            return {...prevState, startTimeComment: val};
        });
    };

    const handleStartTime = (newTime: string) => {
        setState(prevState => {
            return {...prevState, startTime: newTime};
        });
    };

    const handleEndComment = (val: string) => {
        setState(prevState => {
            return {...prevState, endTimeComment: val};
        });
    };

    const handleEndTime = (newTime: string) => {
        setState(prevState => {
            return {...prevState, endTime: newTime};
        });
        setValidateMark(validate);
    };

    const handleDate = (val: Date) => {
        setState(prevState => ({
            ...prevState, date: val,
            endTime: DateHelper.formatDateForServer(val) ===
            DateHelper.formatDateForServer(new Date()) && isDisabledEndTime ? currentTime : prevState.endTime,
        }));
    };

    const postData = {
        employeeId: state?.employee?.value,
        date: DateHelper.formatDateForServer(state.date),
        startTime: state.startTime,
        startTimeComment: state?.startTimeComment || null,
        endTime: state?.endTime || null,
        endTimeComment: state?.endTimeComment && state.endTime ? state.endTimeComment : null,
        branchId: selectedBranchId,
    } as SaveTimesheetParams;

    const saveEmployee = () => {
        ServerCommand.post('timesheet-record/save', postData, () => {
            refreshDataFromServer();
            closeModal();
        }).then();
    };

    return {
        handleEmployee,
        handleStartComment,
        handleStartTime,
        handleEndComment,
        handleEndTime,
        handleDate,
        changeDisabledCheckBox,
        validateMark,
        setValidateMark,
        saveEmployee,
        validate,
        isDateCurrent,
    };
};

export default useModalTimesheetRecord;
