import {DirectionsQuantity, PlanItem, PlanProcedure} from "../../containers/TreatmentPlan/plan-types";
import _ from "underscore";
import Obj from "./object";
import Arr from "./Arr";
import TreatmentPlan from "../../containers/TreatmentPlan/TreatmentPlan";

// TODO переместить в отдельный класс PlanItem?

export function getDirectionsQuantity(item: PlanItem): DirectionsQuantity {
    if (item.directionsQuantity && _.size(item.directionsQuantity) > 0) {
        return item.directionsQuantity;
    }

    return {
        [item.directions[0]]: item.quantity
    };
}

export function getDirectionQuantity(item: PlanItem, direction: string): number {
    const directionsQuantity = getDirectionsQuantity(item);

    return (direction in directionsQuantity) ? directionsQuantity[direction] : 0;
}

/**
 * Возвращает заполненные направления для указанного пункта плана лечения. Заполненными считаются направления, по котороым
 * количество больше 0
 * @param items
 */
export function getFilledDirections(items: PlanItem | PlanItem[]): string[] {
    const filledDirections: string[] = [];

    Arr.wrap(items).forEach(item => {
        let itemDirections: string[] = [];

        if (item.directions.length === 1) {
            itemDirections = item.directions;
        } else if (item.directionsQuantity) {
            const filledDirectionsQuantity = Obj.filter(item.directionsQuantity, (quantity: number) => quantity > 0);
            itemDirections = _.keys(filledDirectionsQuantity);
        }

        filledDirections.push(...itemDirections);
    });

    return _.uniq(filledDirections);
}

export function deleteDirection(item: PlanItem, direction: string): PlanItem|null {
    if (item.directionsQuantity) {
        const quantityDirection = item.directionsQuantity[direction];
        delete item.directionsQuantity[direction];
        item.quantity -= quantityDirection;

        if (item.quantity > 0) {
            return item;
        }
    }

    return null;
}

/**
 * Определяет, является ли указанная процедура единственной активной в своём пункте плана лечения
 * @param procedure
 * @param planItem
 * @param matchProceduresByCode
 */
export function isSingleActiveProcedure(procedure: PlanProcedure, planItem: PlanItem, matchProceduresByCode: boolean = false): boolean {
    const getProcedureCode: GetProcedureCode = matchProceduresByCode
        ? (procedure, planItem) => TreatmentPlan.getProcedureCode(procedure, planItem)
        : procedure => String(procedure.id);

    const procedureCode = getProcedureCode(procedure, planItem);

    return procedure.isActive
        && !planItem.procedures.some(itemProcedure => {
            return itemProcedure.isActive && procedureCode !== getProcedureCode(itemProcedure, planItem);
        });
}

type GetProcedureCode = (procedure: PlanProcedure, planItem: PlanItem) => number|string
