import styled from "styled-components";
import {PropsSDoctorColor, PSTaskItem, PSTaskList} from "../types/style";
import {SContainerItem} from "./index";

export const STaskList = styled.div<PSTaskList>`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: ${props => props.padding || '0'};
    width: 100%;
`

export const STaskItem = styled(SContainerItem)<PSTaskItem>`
    display: flex;
    justify-content: space-between;
    align-items: ${props => props.alignItems || 'center'};
    padding-top: 1px;
    padding-bottom: 3px;
    background: ${props => props.isHighlighted ? props.theme.colors.warningActive : props.theme.colors.white};
    .call-center-download-button, .call-center-deleteWL-button {
        fill: ${props => props.theme.colors.textDarkGray};
        width: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 18px;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10px;
            height: 12px;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    .call-center-deleteWL-button {
        fill: ${props => props.theme.colors.white};
    }
`

export const STaskButtons = styled(STaskItem)<PSTaskItem>`
    ${props => !!props.padding && `padding: ${props.padding};`};
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 4px;
    & > div {
        margin-bottom: 12px;
    }

    .button-task-content {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
`

export const SDoctorColor = styled.div<PropsSDoctorColor>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${props => props.color};
    margin-right: 5px;
`

export const SListCare = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

export const SItemCare = styled.li`
    padding: 3px 0 5px 20px;
    position: relative;
    font-size: 14px;
    &::before {
        content: '';
        position: absolute;
        top: 11px;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: ${props => props.theme.colors.info};
    }
`
