import React, {Dispatch, SetStateAction} from 'react';
import Box from '../../../../../../../../components-ui/Layout/Box';
import Typography from '../../../../../../../../components-ui/DataDisplay/Typography/Typography';
import TextField from '../../../../../../../../components-ui/Inputs/TextField/TextField';
import {Salary} from '../../../AddingEditingEmployee/types';

interface Props {
    dataResponse: {directions: [], services: [], servicesGroups: []},
    rates: Pick<Salary, 'services' | 'group_services'>,
    setRates: Dispatch<SetStateAction<Pick<Salary, 'services' | 'group_services'>>>,
}

const DirectionsTable = ({dataResponse, rates, setRates}: Props) => {

    return (
        <Box display='flex' margin='20px' flexDirection='column' gap='10px'>
            {dataResponse.directions.map(({code, name}) =>
                <Box display='flex' justifyContent='space-between'>
                    <Typography>{name}</Typography>
                    <TextField
                        widthInput='55px'
                        size='min'
                        isNumber
                        placeholder=""
                        onChange={(value) => {
                            if (+value > 100) {
                                value = '100';
                            }
                            setRates(prevState => ({
                                ...prevState,
                                services: {
                                    directions: {
                                        ...prevState.services?.directions,
                                        [code]: {
                                            ratePercent: value,
                                        },
                                    },
                                },
                            }));
                        }}
                        value={rates?.services?.directions?.[code]?.ratePercent ?? ''} />
                </Box>,
            )}
        </Box>

    );
};

export default DirectionsTable;
