import * as React from "react";
import {DashedButtonLink, PopupWrap, PopupDesc, PopupTitle} from "../../../components-ui/styledComponent/common/commonStyle";
import {useState, Fragment, useRef} from "react";
import styled from "styled-components";
import useOutsideClick from "../../../common/hooks/useOutsideClick";
import Helper from "../../../common/helpers/main";

interface PButtonTargetsHistory {
    dataButton: DataButton[];
}
interface DataButton {
    date: string,
    text: string
}

export default function ButtonTargetsHistory({dataButton}: PButtonTargetsHistory) {
    const ref = useRef();
    const [active, setActive] = useState(false);
    let textButton = 'Показать историю';
    let className = "history";
    if (active) {
        textButton = "Скрыть историю";
        className += " active"
    }
    useOutsideClick(ref, () => {
        setActive(false)
    });
    return (
        <Wrap ref={ref}>
            <DashedButtonLink onClick={() => setActive(!active)} minSize className={className}>
                <span>{textButton}</span>
            </DashedButtonLink>
            {active && <PopupWrap absolute>
                {dataButton.map(item => {
                    return (
                        <Fragment key={item.date}>
                            <PopupTitle children={Helper.Date.formatDate(item.date, 'DD MMMM YYYY в HH:mm')} />
                            <PopupDesc children={item.text} />
                        </Fragment>
                    )
                })}
            </PopupWrap>}
        </Wrap>
    )
}


const Wrap = styled.div<any>`
    width: 100%;
    position: relative;
`;
