import styled from "styled-components";
import {Link} from "../../containers/UI-Collection/Elements/Navigation/Link";


export const WrapTable = styled.div`
    border-radius: 4px;
    border: solid 1px ${props => props.theme.colors.borderGray};
    position: relative;
    z-index: 2;
`;

interface IRow {
    isHeader?: boolean
}
export const Row = styled.div<IRow>`
    border-bottom: solid 1px ${props => props.isHeader ? 'transparent' :props.theme.colors.borderGray};
    display: flex;
    position: ${props => props.isHeader ? 'sticky' : 'relative'};
    top: ${props => props.isHeader ? '-30px' : 'auto'};
    background: #fff;
    z-index: ${props => props.isHeader ? 100 : 'none'};
    ${({isHeader, theme}) => isHeader && `
    &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        box-sizing: content-box;
        height: calc(100% - 1px);
        width: 100%;
        border: solid 1px ${theme.colors.borderGray};
        border-radius: 4px 4px 0 0;
    }
    `}
`;

export const RowSpan = styled.span`
  & > div {
      border-bottom: solid 1px ${props => props.theme.colors.borderGray};
  }
  &:last-child {
      & > div {
          border-bottom: none;
      }
  }
`

export const RowNumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px ${props => props.theme.colors.borderGray};
    width: 40px;
    min-height: 50px;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.colors.textGray};
`;

interface IRowItem {
    isHover?: boolean
    opacity?: number
    edit?: boolean,
    isFocus?: boolean
}

export const RowItem = styled.div<IRowItem>`
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    width: calc(100% - 40px);
    min-height: 50px;
    cursor: ${props => props.edit ? 'grab' : 'auto'};
    opacity: ${props => props.opacity};
    box-shadow: ${props => props.isHover ? '0 2px 8px 0 rgba(46, 47, 48, 0.2)' : 'none'};
    z-index: ${props => props.isFocus ? 12 : 11};
    &:active {
        cursor: ${props => props.edit ? 'grabbing' : 'auto'};
    }
`;

export const RowName = styled.div`
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
`;

export const RowDate = styled.div`
    width: 35%;
    display: flex;
    align-items: center;
`;

export const RowButton = styled.div`
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & span {
        margin-left: 20px;
    }
    & button > div {
        width: 12px;
        height: 12px;
    }
    button.close {
        margin-left: 30px;
        & > div {
            fill: ${props => props.theme.colors.white};
            width: 8px;
            height: 8px;
        }
    }
`;

interface ILinkTable {
    active: boolean
}

export const LinkTable = styled.a<ILinkTable>`
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${props => props.theme.colors.info};
    cursor: pointer;
    display: flex;
    align-items: center;
    & > div {
        width: 10px;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: ${props => props.theme.colors.info};
        margin-right: 10px;
        transform: rotate(${props => props.active ? '180deg' : 0});
        transition: .3s;
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`;

export const HeaderDesc = styled.div`
    display: flex;
    width: 100%;
    background: ${props => props.theme.colors.secondary};
    padding-left: 40px;
    & > div {
        color: ${props => props.theme.colors.textGray};
        text-transform: uppercase;
        width: 20%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.4px;
        padding: 8px 18px 7px;
    }
`;

export const WrapDesc = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

interface IRowDesc {
    isStageConfirmed?: boolean
}

export const RowDesc = styled.div<IRowDesc>`
    width: 100%;
    display: flex;
    padding-left: 40px;
    background-color: ${props => props.isStageConfirmed ? 'transparent' : props.theme.colors.warning};
    z-index: 1;
`;

interface IRowItemDesc {
    isDnd: boolean,
    width: string,
    isService?: boolean,
    isDragging: boolean
}

export const RowItemDesc = styled.div<IRowItemDesc>`
    width: ${props => props.width};
    min-height: 52px;
    display: flex;
    align-items: center;
    padding: 15px 18px 15px ${props => props.isService ? '37px' : '18px'};
    user-select: ${props => props.isDnd ? 'none' : 'auto'};
    cursor: ${props => props.isDnd ? 'grab' : 'auto'};
    border: 1px solid transparent;
    opacity: ${props => props.isDragging ? 0 : 1};
    ${({isDnd}) => isDnd && `
    &:hover {
      cursor: grab;
      border-radius: 8px;
      box-shadow: 0 2px 8px 0 rgba(46, 47, 48, 0.2);
      border-color: #e2e6ed;
    }
    `}

`;

export const RowGroup = styled.a<ILinkTable>`
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: ${props => props.theme.colors.text};
    & > div {
        width: 10px;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: ${props => props.theme.colors.textDarkGray};
        fill-rule: nonzero;
        margin-right: 10px;
        transform: rotate(${props => props.active ? '90deg' : 0});
        transition: .3s;
        flex-shrink: 0;
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`;

export const DoctorCell = styled.span`
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
`;

export const StagedLink = styled(Link)`
    margin-bottom: 14px;
`

export const SpanButton = styled.span`
    display: inline-block;
    position: relative;
`;


export const PerformerWrap = styled.span`
    display: block;
    width: 100%;
    & > span {
        display: block;
        width: 100%;
    }
`;

export const LineStages = styled.div.attrs({className: 'LineStages'})`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.colors.lightBlue};
  margin-top: 6px;
`
