import usePortal from "react-useportal";
import {useSlideModal} from "../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {StateCallCenter} from "../../types/components";
import React, {Dispatch, SetStateAction, useMemo} from "react";
import {Call, PlannedCall} from "../../types/common";
import PanelCalls from "./components/PanelCalls";

interface Props {
    marketingPlannedCalls: PlannedCall[]
    panelCalls: Call[]
    setState: Dispatch<SetStateAction<StateCallCenter>>
}

const IncomingCallPanel = ({marketingPlannedCalls, panelCalls, setState}: Props) => {
    const {Portal} = usePortal();
    const {isMinimized} = useSlideModal()

    return useMemo(() => {
        if (isMinimized && !!panelCalls.length) {
            return (
                <Portal>
                    <PanelCalls marketingPlannedCalls={marketingPlannedCalls} panelCalls={panelCalls} setState={setState}/>
                </Portal>
            )
        } else {
            return null
        }
    }, [isMinimized, panelCalls, marketingPlannedCalls])
}

export default IncomingCallPanel
