import React from "react";
import SubTitleTask from "../../../../containers/Tasks/components/UI/SubTitleTask";
import {callCenterTasksInfo} from "../../helper/getDataCallCenter";
import {useSelector} from "react-redux";
import {
    BackCall,
    Care,
    ConfirmVisit,
    LeadPlan,
    LongAbsence,
    MissedVisit,
    PlannedVisit,
    ScheduleChange, SurveyInvite, TreatmentRefusal, WaitingList
} from "./containers/Variants";
import {MethodsCallCenter, OnChangeSelectedScript, TasksDefault} from "../../types/common";
import {InfoCallCenter} from "../../types/hooks";
import { STaskList } from "../../../../containers/Tasks/style";

interface Props {
    tasks: TasksDefault
    allDeadline: boolean
    uniqPatient?: TasksDefault
    personalPhone: string
    validate?: boolean
    infoCallCenter: InfoCallCenter
    methodsCallCenter: MethodsCallCenter
    onChangeSelectedScript: OnChangeSelectedScript
}

const TasksContent = (
    {
        tasks,
        uniqPatient,
        allDeadline,
        personalPhone,
        validate = true,
        infoCallCenter,
        methodsCallCenter,
        onChangeSelectedScript
    }: Props) => {


    const user = useSelector(state => state.user);
    const isBlocked = !user.ignoreCallTasksBlocking && tasks.every(task => task.isBlocked);

    return (
        <STaskList padding="20px 0 16px">
            {!!callCenterTasksInfo[tasks[0].type].title && <SubTitleTask children={callCenterTasksInfo[tasks[0].type].title}/>}
            {tasks.map(task => {

                const taskProps = {
                    validate,
                    uniqPatient,
                    blockedButtons: isBlocked && infoCallCenter.types[task.type].callRequired,
                    personalPhone,
                    allDeadline,
                    methodsCallCenter,
                    infoCallCenter,
                    onChangeSelectedScript
                }

                switch (task.type) {
                    case "back_call":
                        return <BackCall task={task} {...taskProps}/>
                    case "care":
                        return <Care task={task} {...taskProps}/>
                    case "confirm_visit":
                        return <ConfirmVisit task={task} {...taskProps}/>
                    case "lead_plan":
                        return <LeadPlan task={task} {...taskProps}/>
                    case "long_absence":
                        return <LongAbsence task={task} {...taskProps}/>
                    case "missed_visit":
                        return <MissedVisit task={task} {...taskProps}/>
                    case "planned_visit":
                        return <PlannedVisit task={task} {...taskProps}/>
                    case "schedule_change":
                        return <ScheduleChange task={task} {...taskProps}/>
                    case "survey_invite":
                        return <SurveyInvite task={task} {...taskProps}/>
                    case "treatment_refusal":
                        return <TreatmentRefusal task={task} {...taskProps}/>
                    case "waiting_list":
                        return <WaitingList task={task} {...taskProps}/>
                    default:
                        return null;
                }
            })}
        </STaskList>
    )
}

export default TasksContent
