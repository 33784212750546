import moment from "moment";
import _ from "underscore";
import {TaskCallCenter} from "../types/common";

const preparePatient = (task: TaskCallCenter, personalPhone?: string | number, cellName?: string): any => {
    const timeStart = moment(task.payload.timeStart).format('HH:mm');
    const timeEnd = moment(task.payload.timeEnd).format('HH:mm');

    const patient = _.pick(task.targetPerson, 'lastName', 'name', 'secondName', 'cardNumber') as any;
    patient.id = task.targetPerson.patientId;

    if (!!personalPhone) patient.personalPhone = personalPhone;
    if (!!cellName) patient.cellName = cellName;

    return {
        visitId: task.payload.visitId,
        patient,
        timeStart,
        timeEnd,
    };
}

export default preparePatient
