import {Dispatch, SetStateAction, useEffect} from "react";
import minimizeLabelModalCalls from "./helper/minimizeLabelModalCalls";
import {PlannedCall} from "../../types/common";
import {useSlideModal} from "../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import _ from 'lodash'
import {StateCallCenter} from "../../types/components";

interface Props {
    state: StateCallCenter
    setState: Dispatch<SetStateAction<StateCallCenter>>
    marketingPlannedCalls: PlannedCall[]
    noticeQuantityTotal: number
}

const useChangePlannedCalls = ({marketingPlannedCalls, noticeQuantityTotal, state, setState}: Props) => {
    const {calls, plannedCalls} = state;
    const {setMinimizeParams, setIsClose} = useSlideModal();
    useEffect(() => {
        minimizeLabelModalCalls(plannedCalls, setMinimizeParams, noticeQuantityTotal)

        if (calls.length && calls.some(call => !!call.answerTime)) {
            let resIsCloseIconModal = false;
            calls.forEach(call => {
                if (!plannedCalls.some(plannedCall => plannedCall.phone === call.contactPhone)) {
                    resIsCloseIconModal = true
                }
            })
            if (resIsCloseIconModal) {
                setIsClose(true)
            }
        }

        setState(prevState => {
            const detail = prevState.detail
            if (!!detail) {
                const findDetail = marketingPlannedCalls.find(plannedCall => plannedCall.phone === detail.phone);
                prevState.detail = !!findDetail ? findDetail : null;
            }

            if (!!prevState.raisedCalls.length) {
                const clonePrevState = _.cloneDeep(prevState.raisedCalls);
                clonePrevState.forEach((call, index) => {
                    const findPlannedCall = prevState.plannedCalls.find(plannedCall => plannedCall.phone === call.contactPhone);
                    const filterCall = () => {
                        setIsClose(true)
                        prevState.raisedCalls = prevState.raisedCalls.filter((prevItem, idx) => index !== idx)
                    }
                    if (!!findPlannedCall && call.isRaised) {
                        if (!!findPlannedCall.tasks[0].postponedTo) {
                            filterCall()
                        } else if (!findPlannedCall.canSaveResult) {
                            filterCall()
                        }
                    } else if (!findPlannedCall && call.isRaised) {
                        filterCall()
                    }
                })
            }
            return {...prevState}
        })

    }, [JSON.stringify(plannedCalls), noticeQuantityTotal])
}

export default useChangePlannedCalls
