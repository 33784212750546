import styled from "styled-components";

export const Wrapper = styled.div`
    width: 342px;
    padding-top: 30px;
    border-right: 1px solid #D7D7D7;
    flex-shrink: 0;
`;

export const CalendarWrap = styled.div`
    position: relative;
`;

export const Header = styled.div`
    display: flex;
`;

export const HeaderDay = styled.div`
    width: 40px;
    height: 36px;
    font-size: 13px;
    color: ${props => props.theme.colors.textDarkGray};
    text-align: center;
    & + div {
       margin-left: 1px;
    }
`;

interface IBody {
    ref?: any
}

export const Body = styled.div<IBody>`
    width: 300px;
    height: 205px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        transform: translateY(4px);
        width: 2px;
        border-radius: 4px;
        background-color: ${props => props.theme.colors.borderGray};
        transition: background-color .4s;
        
        &:hover {
              background-color: ${props => props.theme.colors.borderGrayActive};
        }
    }
    &::-webkit-scrollbar-button {
        width: 4px;
        height: 4px;
        background-color: transparent;
        opacity: 0;
        visibility: hidden;
    }
`;

export const BodyWrap = styled.div`
  transform: translate(4px, 4px);
`;

export const ControlsWrap = styled.div`
    position: absolute;
    top: 41px;
    left: 305px;
`;

export const ControlsButton = styled.button`
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    width: 21px;
    height: 40px;
    border-radius: 2px;
    background-color: #f1f1f1;
    cursor: pointer;
    margin-top: -3px;
    border: none;
    outline: none;
    &:first-child {
        margin-top: 0;
    }
    &::after {
        content: '';
        z-index: 13;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const getTransformControls = (props: any) => {
    if (props.down) {
        if (props.month) {
            if (props.two) {
                return 'translate(-50%, -50%)'
            } else {
                return 'translate(-50%, -100%)'
            }
        } else {
            return 'translate(-50%, 0)'
        }
    } else {
        if (props.month) {
            if (props.two) {
                return 'translate(-50%, -50%) rotate(180deg)'
            } else {
                return 'translate(-50%, -100%) rotate(180deg)'
            }
        } else {
            return 'translate(-50%, -50%) rotate(180deg)'
        }
    }
};

interface IControlsArrow {
    down?: boolean,
    month?: boolean,
    two?: boolean
}

export const ControlsArrow = styled.div<IControlsArrow>`
    width: 17px;
    height: 8.5px;
    position: absolute;
    left: 50%;
    top: 50%;
    fill: ${props => props.theme.colors.textDarkGray};
    transform: ${getTransformControls};
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > svg {
            width: 100%;
            height: 100%;
        }
    }
`;

interface IDayWrap {
    active?: boolean
}

export const DayWrap = styled.div<IDayWrap>`
    position: relative;
    float: left;
    width: 40px;
    height: 40px;
    margin: 1px 0 0 1px;
    cursor: pointer;
    user-select: none;
    &::after {
        content: '';
        z-index: 13;
        position: absolute;
        top: ${props => props.active ? '-4px' : 0};
        left: ${props => props.active ? '-4px' : 0};
        width: ${props => props.active ? '48px' : '100%'};
        height: ${props => props.active ? '48px' : '100%'};
    }
    &::before {
        content: '';
        z-index: 4;
        position: absolute;
        top: -4px;
        left: -4px;
        width: 48px;
        height: 48px;
        border-radius: 4px;
        background-color: ${props => props.active ? props.theme.colors.primary : 'transparent'};
    }
`;

interface IDayContent {
    current?: boolean,
    active?: boolean
}

export const DayContent = styled.div<IDayContent>`
    z-index: ${props => props.active ? 5 : 3};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    line-height: 14px;
    text-align: center;
    background-color: #eaeaea;
    color: #454545;
    background-image: ${props => props.current ? "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODJCOUY2NjdCMjAyMTFFODkzRkNBMDg4QTdEOEJEMTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODJCOUY2NjhCMjAyMTFFODkzRkNBMDg4QTdEOEJEMTYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4MkI5RjY2NUIyMDIxMUU4OTNGQ0EwODhBN0Q4QkQxNiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4MkI5RjY2NkIyMDIxMUU4OTNGQ0EwODhBN0Q4QkQxNiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuWhF8cAAABlSURBVHja7NjBDYAwDAPABrETq7D/ImGBVrxog3qe4CT7k0Red7Zxos3J0HC04ikPPBdV+jalVPFWG4yCrlAxICAgICAgICAgICAgIOBPPgvZu+gXx3drrw12u5+8SU/0z/IIMACAXQjmXfKT2AAAAABJRU5ErkJggg==')" : 'none'}
`;

export const DayD = styled.div`
    font-size: 15px;
`;

export const DayM = styled.div`
    font-size: 11px;
    line-height: 10px;
`;
