import {useState, useRef, useEffect} from "react";

export default function useHover() {
    const [value, setValue] = useState(false);

    const ref = useRef(null) as any;

    const handleMouseOver = () => setValue(true);
    const handleMouseMove = () => {
        if (!value) setValue(true);
    }
    const handleMouseOut = () => setValue(false);

    useEffect(
        () => {
            const node = ref.current as any;
            if (node) {
                node.addEventListener('mouseenter', handleMouseOver);
                node.addEventListener('mousemove', handleMouseMove);
                node.addEventListener('mouseleave', handleMouseOut);

                return () => {
                    node.removeEventListener('mouseenter', handleMouseOver);
                    node.removeEventListener('mousemove', handleMouseMove);
                    node.removeEventListener('mouseleave', handleMouseOut);
                };
            }
        },
        [ref.current] );
    return [ref, value, setValue];
}
