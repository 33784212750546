import React, {useState} from "react";
import TextField from "../../../../../../components-ui/Inputs/TextField/TextField";
import {Label} from "../../../../../../components-ui/DataDisplay/Label";

export default function NumberTextField() {

    const [value, setValue] = useState('');
    const [value2, setValue2] = useState('');

    return (
        <div>
            <Label labelText="NumberTextField" align="topLeft">
                <TextField value={value} onChange={(val:string) => setValue(val)} widthInput={360} isNumber={true}/>
            </Label>

            <pre>
                {
                    `<Label labelText="NumberTextField" align="topLeft">
                        <TextField value={value} onChange={(val) => setValue(val)} widthInput={360} isNumber={true} />
                    </Label>`
                }
            </pre>

            <br/>
            <br/>
            <Label labelText="NumberTextFieldMin" align="topLeft">
                <TextField value={value} onChange={(val:string) => setValue(val)} size="min" widthInput={100} isNumber={true}/>
            </Label>

            <pre>
                {
                    `<Label labelText="NumberTextFieldMin" align="topLeft">
                <TextField value={value} onChange={(val:string) => setValue(val)} size="min" widthInput={100} isNumber={true}/>
            </Label>`
                }
            </pre>

            <br/>
            <br/>
            <Label labelText="NumberTextFieldMin" align="topLeft">
                <TextField value={value2} onChange={setValue2} size="min" widthInput={100} isNumber={true} min={0} max={30}/>
            </Label>

            <pre>
                {
                    ` <Label labelText="NumberTextFieldMin" align="topLeft">
                <TextField value={value2} onChange={setValue2} size="min" widthInput={100} isNumber={true} min={0} max={30}/>
            </Label>`
                }
            </pre>
        </div>
    )
}
