import * as React from "react";
import {PureComponent} from 'react';
import * as ReactDOM from 'react-dom';
import './MultipleCounter.scss';
import {PIconWarning, PMultipleCounter, SMultipleCounter, Values} from "./types";
import Helper from "../../../common/helpers/main";
import Counter from "../Counter/Counter";
import {Icon} from "../../../components/Icons/Icons";
import Tooltip from "../../DataDisplay/Tooltip/Tooltip";

export default class MultipleCounter extends PureComponent<PMultipleCounter, SMultipleCounter> {
    private update: any;

    static defaultProps = {
        disableCountersInputs: false
    };

    state = {
        showPopup: false,
        canEditGeneralValues: false,
        animateClassWarning: false
    };

    render() {
        const {generalValue, note, itemCode} = this.props;
        const {canEditGeneralValues, animateClassWarning, showPopup} = this.state;
        const iconProps = {canEditGeneralValues, animateClassWarning, note, itemCode};
        return (
            <div className="multiple-counter" style={this.getStyle()}>
                <button className="multiple-counter__select"
                        type="button"
                        onClick={this.onClickBtn}
                >
                    {generalValue}
                    <Icon icon="new-arrow"/>
                </button>
                <Popup showPopup={showPopup} handleCounter={this.handleCounter} {...this.props}/>
                <IconWarning {...iconProps}/>
            </div>
        )
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.removePopup);
        const allValues = this.calcValuesSum();
        const canEditGeneralValues = allValues >= this.props.generalValue;
        this.setState({canEditGeneralValues});
    }

    componentDidUpdate(prevProps: Readonly<PMultipleCounter>): void {
        if (prevProps.attract !== this.props.attract) {
            if (!this.state.animateClassWarning) {
                this.update = this.updateAnimate();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.removePopup);
        clearInterval(this.update)
    }

    calcValuesSum = (values: Values = this.props.values) => {
        let allValues = 0;
        Object.values(values).forEach((value: any) => allValues += value);
        return allValues
    };

    getStyle = () => {
        const {showPopup} = this.state;
        let style = {} as any;
        if (showPopup) style.zIndex = 3;
        return style
    };

    onClickBtn = () => {
        const {showPopup} = this.state;
        this.setState({showPopup: !showPopup})
    };

    handleCounter = (counterValue: number, nameField: string): void => {
        let {canEditGeneralValues} = this.state as any;
        const {onChange, generalValue, minGeneral} = this.props as any;
        const values = Helper.clone(this.props.values);
        values[nameField] = counterValue;
        const allValues = this.calcValuesSum(values);

        if (!canEditGeneralValues) {
            if (allValues === generalValue || generalValue === 0) {
                canEditGeneralValues = true;
                this.setState({canEditGeneralValues});
            } else {
                onChange(values, generalValue)
            }

        }
        if (canEditGeneralValues) {
            if (allValues >= minGeneral) {
                onChange(values, allValues)
            }
        }
    };

    updateAnimate = () => {
        this.setState({animateClassWarning: true});
        setTimeout(() => {
            this.setState({animateClassWarning: false});
        }, 2500)
    };

    removePopup = (e: any) => {
        if (this.state.showPopup) {
            const node = ReactDOM.findDOMNode(this);
            if (node && !node.contains(e.target)) {
                this.setState({showPopup: false})
            }
        }
    };
}

const Popup = (props: PMultipleCounter) => {
    if (!props.showPopup) return null;
    const {note, values, min, titles, handleCounter, minGeneral, generalValue, renderTitle, disableCountersInputs} = props;
    let allValue = 0;
    let fields = Helper.mapObj(values, (value: number, nameField: string) => {
        allValue += value;
        return (
            <div className="multiple-counter__popup-item">
                    <span className="multiple-counter__popup-name">
                        {renderTitle ? renderTitle(nameField) : titles[nameField]}
                    </span>
                <Counter onChange={(counterValue) => handleCounter(counterValue, nameField)}
                         value={value}
                         min={generalValue === minGeneral && value === minGeneral ? minGeneral : min}
                         disableInput={disableCountersInputs}
                />
            </div>
        )
    });
    return (
        <div className="multiple-counter__popup">
            <p className="multiple-counter__popup-header">
                {note}
            </p>
            {fields}
        </div>
    )
};

const IconWarning = ({canEditGeneralValues, animateClassWarning, note, itemCode}: PIconWarning) => {
    if (canEditGeneralValues) return null;
    let className = '';
    if (animateClassWarning) className = 'pulse';
    return (
        <>
            <span data-for={'warning-tooltip' + itemCode} data-tip={note} className="multiple-counter__wrap-icon">
                <Icon icon="new-warning" iconClass={className}/>
            </span>
            <Tooltip id={'warning-tooltip' + itemCode} />
        </>
    )
};
