import {useEffect} from "react";
import {PropsUseChangeOtherPersonalPhone} from "../types/hooks";

export default function useChangeOtherPersonalPhone({form, setForm}: PropsUseChangeOtherPersonalPhone) {

    useEffect(() => {
        if (!!form.basic.phoneOwner && form.basic.phoneOwner.value === 'other') {
            setForm(prevState => {
                let personalPhone = prevState.otherPatient.personalPhone;
                if (prevState.basic.personalPhone.length === 11) {
                    personalPhone = prevState.basic.personalPhone
                }
                return {
                    ...prevState,
                    otherPatient: {
                        ...prevState.otherPatient,
                        personalPhone
                    }
                }
            })
        }
    }, [form.basic.phoneOwner]);
}
