import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const HiddenPassIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 20.06"} ref={ref}>
            <path d="M17.66,17.23a11.69,11.69,0,0,1-5.83,1.47Q4,18.7,0,10A17.17,17.17,0,0,1,4.37,3.94L2,1.54,3.5,0,22,18.46,20.43,20ZM15,14.54,13.37,13A3.27,3.27,0,0,1,9,8.59L7.43,7A5.44,5.44,0,0,0,15,14.54Zm2.22-3a5.44,5.44,0,0,0-3.7-6.74,5.61,5.61,0,0,0-3,0L7.69,2a11.88,11.88,0,0,1,4.14-.7q7.82,0,12.1,8.7a19.26,19.26,0,0,1-3.33,4.92ZM12.45,6.77l2.74,2.74A3.27,3.27,0,0,0,12.45,6.77Z"/>
        </WrapIcon>
    );
});
