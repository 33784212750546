import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const SaveIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M2.67,24H21.33A2.66,2.66,0,0,0,24,21.33V6.67L17.33,0H2.67A2.66,2.66,0,0,0,0,2.67V21.33A2.66,2.66,0,0,0,2.67,24ZM5.33,2.67h5.34V5.33h2.66V2.67H16V8H5.33Zm0,10.66H18.67v8H5.33Z"/>
        </WrapIcon>
    );
});
