import React, {forwardRef} from 'react';
import { WrapIcon } from '../../../containers/WrapIcon';
import { IIcon } from '../../../types/common';

export const KanbanIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M21.19,0H2.81A2.81,2.81,0,0,0,0,2.81V21.19A2.81,2.81,0,0,0,2.81,24H21.19A2.81,2.81,0,0,0,24,21.19V2.81A2.81,2.81,0,0,0,21.19,0Zm.94,21.19a1,1,0,0,1-.94.94H2.81a.94.94,0,0,1-.93-.94V11.44H22.13ZM1.88,9.56V2.81a.93.93,0,0,1,.93-.93H21.19a.94.94,0,0,1,.94.93V9.56Zm17.76,6.1H4.36V13.78H19.64Zm0,4.22H4.36V18H19.64Z"/>
        </WrapIcon>
    );
});
