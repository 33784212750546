import React from 'react';
import FilterWidgetWrap from "../../../../components/FilterWidgetWrap";
import {HandlersAverageCheck, StateAverageCheck} from "../types";
import {tabsAverageCheck} from "../data/tabsAverageCheck";
import Button from "../../../../../../components-ui/Inputs/Button/Button";

interface Props {
    codes: string[]
    state: StateAverageCheck
    handlers: HandlersAverageCheck
}

const Filter = ({state, handlers, codes}: Props) => {

    if (!!codes.length && !!state.currentTab) {
        return (
            <FilterWidgetWrap>
                <Button children={tabsAverageCheck.directions.toLowerCase()}
                        margin="0 0 0 8px"
                        fontSize="14px"
                        height="32px"
                        noShadow={true}
                        variant={state.currentTab === 'directions' ? 'info' : 'standard'}
                        onClick={() => handlers.handleCurrentTab('directions')}
                />
                <Button children={tabsAverageCheck.doctors.toLowerCase()}
                        margin="0 0 0 8px"
                        fontSize="14px"
                        height="32px"
                        noShadow={true}
                        variant={state.currentTab === 'doctors' ? 'info' : 'standard'}
                        onClick={() => handlers.handleCurrentTab('doctors')}
                />
            </FilterWidgetWrap>
        );
    } else {
        return null
    }


};

export default Filter;
