import useMinimizeParams from './useMinimizeParams';
import {useOptionsBranchesUser} from '../../../common/hooks/useOptionsBranchesUser';
import {useEffect, useState} from 'react';
import {OptionSelect} from '../../../components-ui/Inputs/Select/typesSelect';
import ServerCommand from '../../../common/server/server-command';
import {useLoader} from '../../../components/Errors/hooks/useLoader';
import {GetTimesheetListParams, GetTimesheetListResult} from '../types';
import useNeedCompleteTimeSheet from './useNeedCompleteTimeSheet';
import useServerEvents from './useServerEvents';

const useTimeSheet = () => {

    const [data, setData] = useState<GetTimesheetListResult>({
        records: [],
        schedule: [],
        timesheetCheckDuration: 0,
        excludeEmployeesRoles: []
    });

    const {timeSheetDialogBadgeText, checkTimeCame} = useNeedCompleteTimeSheet(data);

    useMinimizeParams({timeSheetDialogBadgeText, checkTimeCame});

    const optionsBranches = useOptionsBranchesUser();

    const [selectedBranch, setSelectedBranch] = useState<OptionSelect>(optionsBranches[0]);

    const [loader, setLoader] = useLoader(true);

    const params = {branchId: selectedBranch.value} as GetTimesheetListParams;

    useEffect(() => {
        setLoader(true);
        ServerCommand
            .get('timesheet-record/list', params,
            ).then((response: GetTimesheetListResult) => {
            setData(response);
            setLoader(false);
        });
    }, [selectedBranch]);

    useServerEvents({setData, selectedBranch})

    return {
        optionsBranches,
        selectedBranch,
        setSelectedBranch,
        data,
        loader,
    };
};

export default useTimeSheet;
