import React, {useState} from 'react';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../../../components-ui/Layout/Box';
import {Laboratory} from '../../../types';
import {
    commonLabelOption, commonSelectOption,
    commonTextFieldOption,
} from '../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import TextField from '../../../../../components-ui/Inputs/TextField/TextField';
import {Label} from '../../../../../components-ui/DataDisplay/Label';
import Checkbox from '../../../../../components-ui/Inputs/Checkbox/Checkbox';
import Button from '../../../../../components-ui/Inputs/Button/Button';
import ValidationLinkInfo from '../../../../../components/ValidationLinkInfo/ValidationLinkInfo';
import {useModal} from '../../../../../containers/DialogsManager/containers/Modal/Modal';
import useLoader from '../../../../../components/Errors';
import ServerCommand from '../../../../../common/server/server-command';

interface Props {
    laboratory?: Laboratory
    refreshDataFromServer: () => void
}

const LaboratoryMark = ({laboratory, refreshDataFromServer}: Props) => {

    const [state, setState] = useState(laboratory || {name: '', active: true})

    const {closeModal} = useModal();

    const [loader, setLoader] = useLoader();

    const [validationCheck, setValidationCheck] = useState(false);

    const saveXrayPicture = () => {
        setLoader(true);
        ServerCommand.post(`laboratory/${laboratory ? 'update' : 'create'}`, state, () => {
            closeModal();
            refreshDataFromServer();
        }).then();
    };

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        children={`${!!laboratory ? 'Изменение' : 'Добавление'} лаборатории`} textAlign='center' />
            <Box margin='39px 0 0 0'>
                <Label {...commonLabelOption}
                       isRequired={true}
                       labelText="Название"
                       align="leftTop"
                       marginLabel="8px 10px 0 0">
                    <TextField
                        {...commonTextFieldOption}
                        value={state.name}
                        onChange={(val) => setState(prevState => ({...prevState, name: val}))}
                        isError={!state.name && validationCheck}
                    />
                </Label>

                <Label labelText="Активна" {...commonLabelOption} >
                    <Checkbox label='' checked={state.active} onChange={(val) => setState(prevState => ({...prevState, active: val}))}/>
                </Label>

                <Label labelText='' {...commonLabelOption}>
                    <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                        <Button children='Сохранить'
                                size='md'
                                loader={loader}
                                variant={state.name ? 'success' : 'disabled'}
                                onClick={saveXrayPicture}
                        />
                        <Box maxWidth='172px' margin='0 0 0 16px'>
                            <ValidationLinkInfo validate={!!state.name}
                                                onClickIsValidate={() => setValidationCheck(true)
                            } />
                        </Box>
                    </Box>
                </Label>
            </Box>

        </Box>
    );
};

export default LaboratoryMark;
