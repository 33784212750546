import React from "react";
import PatientField, {useShownFields, usePrepareValueField,} from "../../../../../../../containers/PatientField";
import Button from "../../../../../../../components-ui/Inputs/Button/Button";
import {useSelector} from "react-redux";
import Box from "../../../../../../../components-ui/Layout/Box";
import _ from 'lodash'
import {usePreparationForAdmission} from "../../../PreparationForAdmission";

export default function Patient() {
    const {forms, state} = usePreparationForAdmission()
    return (
        <>
            {_.map(forms, (form, formName) => {
                return (
                    <Box display={state.currentTab === formName ? "block" : "none"}>
                        <CurrentForm formName={formName}/>
                    </Box>
                )
            })}
        </>

    )
}

const CurrentForm = ({formName}: {formName: string}) => {
    const fieldsGroup = useSelector(store => store.patient.fields.group)
    const {
        forms,
        onChangeField,
        onChangeValidation,
        fields,
        setForms,
        loadingPatient,
        resetRepresentative
    } = usePreparationForAdmission()
    const {visibleFields, basic, currentPatient} = forms[formName];
    const parentName = formName === 'patient' ? 'PreparationPatient' : 'PreparationRepresentative'
    const shownFields = useShownFields({
        currentPatient,
        formFields: _.keys(basic),
        parentName,
        fields
    })
    const shownVisibleFields = useShownFields({
        currentPatient,
        formFields: visibleFields,
        parentName,
        fields
    })
    const prepareValueField = usePrepareValueField({basic, fields})
    return (
        <>
            {fieldsGroup.map((fieldGroup, indexGroup) => {
                const shownFieldsGroup = shownFields.filter(shownField => fields[shownField].group === fieldGroup.code)
                const shownVisibleFieldsGroup = shownVisibleFields.filter(shownField => fields[shownField].group === fieldGroup.code)
                return (
                    <Box key={fieldGroup.code} display={!!shownVisibleFieldsGroup.length ? "block" : "none"}>
                        <Box as="h3">
                            {fieldGroup.name}
                        </Box>

                        {shownFieldsGroup.map((fieldName, index) => {
                            const valueField = prepareValueField({fieldName})
                            if (valueField === null) return null
                            return (
                                <Box key={fieldName + formName} display={_.includes(shownVisibleFields, fieldName) ? "block" : "none"}>
                                    <PatientField {...valueField}
                                                  onChangeField={(val, name) => onChangeField(val, name, formName)}
                                                  currentPatient={currentPatient}
                                                  autoFocus={indexGroup === 0 && index === shownFieldsGroup.length - 1}
                                                  personalPhone={basic.personalPhone}
                                                  parentName={parentName}
                                                  setForm={setForms}
                                                  currentTab={formName}
                                                  loadingPatient={patient => loadingPatient(patient, formName)}
                                                  onChangeValidationItem={(name) => onChangeValidation(name, formName)}
                                    />
                                </Box>
                            )
                        })}
                    </Box>
                )
            })}

            {formName === 'representative' && (
                <Box display="flex" justifyContent="flex-end" width="92%" margin="70px 0 0">
                    <Button children="Сбросить форму" size="md" variant="danger"
                            onClick={resetRepresentative}/>
                </Box>

            )}
        </>
    )
}
