import styled from "styled-components";

export const SResetIconWrap = styled.div.attrs({className: 'SResetIconWrap'})`
    position: absolute;
    display: flex;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.textDarkGray};
    justify-content: center;
    align-items: center;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    transition: background-color .15s;
    cursor: pointer;
    & > div {
        width: 6px;
        height: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: ${props => props.theme.colors.white};
        transition: fill .2s;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    &:hover {
        background-color: ${props => props.theme.colors.text};
        & > div {
            fill: ${props => props.theme.colors.whiteDark};
        }
    }
`
