import React from 'react';
import HeaderPatientCard from './HeaderPatientCard/HeaderPatientCard'
import NavPatientCard from '../../components-ui/Navigation/NavMenu/NavPatientCard/NavPatientCard'
import ServerCommand from "common/server/server-command";
import PropTypes from "prop-types";
import Helper from 'common/helpers/main'
import Patient from "./Section/Patient/Patient";
import Photo from "./Section/Photo/Photo";
import Manual from "./Section/Manual/Manual";
import './PatientCard.scss'
import PatientRecord from "./PatientRecord";
import Modal from "../../containers/DialogsManager/containers/Modal/Modal";
import CallHistory from "./Section/CallHistory/CallHistory";
import TreatmentPlanSectionWrap from "./Section/TreatmentPlan/TreatmentPlanSectionWrap";
import CaseHistoryWrap from "./Section/CaseHistory/CaseHistoryWrap";
import InvoiceListWrap from "./Section/Invoice/InvoiceListWrap";
import Payments from "./Section/Payments/Payments";

export default class PatientCard extends React.Component {

    state = {
        patient: null,
        doctors: null,
        fields: null,
        fieldsGroups: null,
        activeSectionNav: 'PATIENT',
        section: null,
        PatientRecord: false,
        relationTypesTitles: null,
        samePhonePatients: [],
        relatives: []
    };

    static propTypes = {
        patientId: PropTypes.number.isRequired,
        activeSectionNav: PropTypes.string,
        treatmentPlanType: PropTypes.string
    };

    static defaultProps = {
        activeSectionNav: 'PATIENT',
        treatmentPlanType: 'recommended'
    };

    componentDidMount() {
        this.loadingFromServer();
        this.setState({activeSectionNav: this.props.activeSectionNav})
    }


    render() {
        const patient = this.state.patient;

        const sectionNavPatientCard = [
            {name: 'Пациент', code: 'PATIENT'},
            {name: 'План лечения', code: 'TREATMENT_PLAN'},
            {name: 'История болезни', code: 'CASE_HISTORY'},
            {name: 'Фотографии', code: 'PHOTO'},
            {name: 'Приемы', code: 'MANUAL'},
            {name: 'Счета', code: 'INVOICES'},
            {name: 'Оплаты', code: 'PAYMENTS'},
            {name: 'История звонков', code: 'CALL_HISTORY'},
            /*{name: 'История изменениий', code: 'CHANGE_HISTORY'}*/
        ];

        if (patient === null) {
            return null
        }

        let content = null;

        switch (this.state.activeSectionNav) {
            case "PATIENT":
                content = <Patient patient={this.state.patient}
                                   fields={this.state.fields}
                                   loadingFromServer={this.loadingFromServer.bind(this)}
                                   fieldsGroups={this.state.fieldsGroups}
                                   relationTypesTitles={this.state.relationTypesTitles}
                                   samePhonePatients={this.state.samePhonePatients}
                                   relatives={this.state.relatives}
                />;
                break;
            case "TREATMENT_PLAN":
                content = <TreatmentPlanSectionWrap patientId={this.props.patientId}
                                                initialPlanType={this.props.treatmentPlanType}
                />;
                break;
            case "CASE_HISTORY":
                content = <CaseHistoryWrap
                    patientId={this.props.patientId}
                    patient={this.state.patient}
                />;
                break;
            case "PHOTO":
                content = <Photo patientId={this.props.patientId}/>;
                break;
            case "MANUAL":
                content = <Manual patientId={this.props.patientId}/>;
                break;
            case "INVOICES":
                content = <InvoiceListWrap patientId={this.props.patientId}/>;
                break;

            case "PAYMENTS":
                content = <Payments patientId={this.props.patientId}/>;
                break;
            case "CALL_HISTORY":
                content = <CallHistory personalPhone={this.state.patient.personalPhone} />
                break;
            default:
                break;
        }


        return (
            <div className="patient-card">
                <HeaderPatientCard
                    fioPatient={Helper.getFio(patient)}
                    numberCard={this.state.patient.number}
                    isPrimary={this.state.patient.isNew}
                    onClick={() => this.setState({PatientRecord: true})}
                />
                <NavPatientCard
                    sectionNavPatientCard={sectionNavPatientCard}
                    activeSectionNav={this.state.activeSectionNav}
                    onChange={(section) => this.setState({activeSectionNav: section})}
                />
                {content}
                <Modal
                    isOpen={this.state.PatientRecord}
                    closeModal={() => this.setState({PatientRecord: false})}
                >
                    <PatientRecord patientId={this.props.patientId} onClick={() => this.setState({PatientRecord: false}) }/>
                </Modal>
            </div>
        );
    }
    loadingFromServer () {
        const params = {
            primary: this.props.patientId,
            getFields: true,
            getDoctors: true,
            selectSamePhonePatients: ['id', 'name', 'lastName', 'secondName', 'personalBirthday'],
            selectRelatives: ['id', 'name', 'lastName', 'secondName', 'personalBirthday', 'personalPhone', 'personalGender']
        };

        ServerCommand
            .get('patient/detail', params)
            .then(response => {
                if (!!response.detail.passportIssuedDate) response.detail.passportIssuedDate = response.detail.passportIssuedDate.split(' ')[0];
                delete response.fields.fio
                delete response.fields.address
                delete response.fields.relation
                delete response.fields.phone

                this.setState({
                    patient: response.detail,
                    fields: response.fields,
                    doctors: response.doctors,
                    fieldsGroups: response.fieldsGroups,
                    relationTypesTitles: response.relationTypesTitles,
                    samePhonePatients: response.samePhonePatients,
                    relatives: response.relatives
                })
            });
    }
}
