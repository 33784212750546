import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const AnalyticsCalls = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 24"} ref={ref}>
            <path d="M3.12,1.8a1.25,1.25,0,0,1,1.75.11h0L9.54,7a1.27,1.27,0,0,1,0,1.76h0l-3.11,3a7.22,7.22,0,0,0,2.1,3.7,7.28,7.28,0,0,0,3.69,2.1h0l3-3.11A1.26,1.26,0,0,1,17,14.45h0l5.14,4.67a1.28,1.28,0,0,1,.11,1.76h0l-2.35,2.74A1.27,1.27,0,0,1,19,24h-.46A19.12,19.12,0,0,1,5.43,18.57,19.13,19.13,0,0,1,0,5.56V5.24C0,5.09,0,5,0,5a1.27,1.27,0,0,1,.37-.82h0ZM12.62,0A11.13,11.13,0,0,1,24,11.37H21.47a8.55,8.55,0,0,0-8.85-8.84h0Zm0,5.05c4.08,0,6.32,2.24,6.32,6.32H16.42c0-2.66-1.13-3.79-3.79-3.79h0Z"/>
        </WrapIcon>
    );
});
