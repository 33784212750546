import React from 'react';
import {GetDoneServicesResult} from '../../types';
import {useDialogsManager} from '../../../../containers/DialogsManager/DialogsManager';
import useTableFormatter from './hooks/useTableFormatter';
import Table from '../../../../components-ui/DataDisplay/Table';
import Button from '../../../../components-ui/Inputs/Button/Button';
import Box from '../../../../components-ui/Layout/Box';
import Typography from '../../../../components-ui/DataDisplay/Typography/Typography';
import Loader from '../../../../components-ui/Feedback/Loader/Loader';
import {Laboratory} from '../../../LaboratoryPage/types';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import ModalMarkDoneLaboratoryService from './modals/ModalMarkDoneLaboratoryService';

interface Props {
    doneLaboratoryServices: GetDoneServicesResult['doneLaboratoryServices'];
    services: GetDoneServicesResult['services'];
    laboratories?: Laboratory[];
    loaderTable: boolean;
    refreshDataFromServer: () => void;
    selectedBranch: OptionSelect;
}

const DoneLaboratoryServicesTable = ({
                                         doneLaboratoryServices,
                                         services,
                                         laboratories,
                                         loaderTable,
                                         refreshDataFromServer,
                                         selectedBranch,
                                     }: Props) => {

    const {showModal} = useDialogsManager();

    const {columns, rows} = useTableFormatter(
        {
            services,
            laboratories,
            selectedBranch,
            refreshDataFromServer,
            doneLaboratoryServices
        },
    );

    return (
        <Box>
            <Typography fontWeight='800' variant='min-title' margin='0 0 12px'>
                Выполненные услуги внешней лаборатории
            </Typography>
            <Box position='relative'>
                <Loader visible={loaderTable} />
                <Table columns={columns}
                       rows={rows}
                       variantTable='outline'
                       isHovering={false}
                       fontSizeRow='14px'
                />
            </Box>
            <Button margin='12px 0 0 0'
                    width='180px'
                    variant='default'
                    size='md'
                    children='Добавить'
                    onClick={() => showModal(
                        <ModalMarkDoneLaboratoryService
                            services={services}
                            laboratories={laboratories}
                            selectedBranch={selectedBranch}
                            refreshDataFromServer={refreshDataFromServer}
                        />
                    )}
            />
        </Box>
    );
};

export default DoneLaboratoryServicesTable;
