import React from "react";
import {InfoBox, InfoBoxWrap} from "../../../../../components-ui/styledComponent/common/wraps";
import Paper from "../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import {usePreparationForAdmission} from "../PreparationForAdmission";

export default function PaymentSchedule() {
    const {props} = usePreparationForAdmission();
    const {paymentSchedule} = props.data;
    if (!paymentSchedule) return null;

    return (
        <Paper minWidth="100%" margin="0 0 24px">
            <InfoBox>
                <Typography variant="min-title" padding="25px 20px 11px">График платежей</Typography>
                <InfoBoxWrap>
                    {paymentSchedule}
                </InfoBoxWrap>
            </InfoBox>
        </Paper>
    )
}
