import * as React from "react";
import {PRecommendedServices} from "./typesRecommendedServices";
import {Box} from "../../../../../components-ui/styledComponent/common/wraps";
import {WrapRecommendedServices, WrapServices} from "./styledRecommendedServices";
import HeadRecommendedServices from "./elements/HeadRecommendedServices/HeadRecommendedServices";
import {useState} from "react";
import TableRecommendedServices from "./elements/TableRecommendedServices/TableRecommendedServices";
import Services from "../Services";

export default function RecommendedServices({recommendedServices, services, addToPlan, servicesGroups, plan, directionsMap, handleSelectService, planItemDisabledTip, selectedDoctorId, servicesAll, startWithRecommendedServices, showRecommendedServices }: PRecommendedServices) {
    const [selected, setSelected] = useState<'recommended' | 'all'>(startWithRecommendedServices() ? 'recommended' : 'all');

    return (
        <Box>
            <WrapRecommendedServices>
                {showRecommendedServices && (
                    <HeadRecommendedServices selected={selected} setSelected={setSelected}/>
                )}
                {selected === 'recommended' && <TableRecommendedServices recommendedServices={recommendedServices}
                                                                         services={services}
                                                                         addToPlan={addToPlan}
                                                                         servicesGroups={servicesGroups}
                                                                         setSelected={setSelected}
                />}
                {selected === 'all' && (
                    <WrapServices>
                        <Services plan={plan}
                                  directionsMap={directionsMap}
                                  selectedDoctorId={selectedDoctorId}
                                  services={servicesAll}
                                  servicesGroups={servicesGroups}
                                  planItemDisabledTip={planItemDisabledTip}
                                  handleSelectService={handleSelectService}
                        />
                    </WrapServices>
                )}
            </WrapRecommendedServices>
        </Box>
    );
}
