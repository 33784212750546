import React, {useState} from 'react';
import Button from "../../../components-ui/Inputs/Button/Button";
import KanbanCard from "../KanbanCard";
import {Icon} from "../../../components/Icons/Icons";
import Payment from "../../Payment/Payment";
import CanceledModal from "../Modal/CanceledModal";
import {useDialogsManager} from "../../../containers/DialogsManager/DialogsManager";
import {useSlideModal} from "../../../containers/DialogsManager/containers/SlideModal/SlideModal";

interface PropsVisitCompletedKanbanCard {
    visit: any,
    doctor: any,
    removeBgColor?: any,
    setNextState?: any
}

export default function VisitCompletedKanbanCard({visit, doctor, removeBgColor, setNextState}: PropsVisitCompletedKanbanCard) {
    const [openModal, setOpenModal] = useState(false);
    const {showSlideModal} = useDialogsManager();
    const {isMinimized} = useSlideModal()

    const renderHeader = () => {
        if (visit.stateData.isCanceled) {
            return (
                <div className="kanban-card__header kanban-card__header--danger">
                    Отменен <Icon icon="new-cancel"/>
                </div>
            );
        }

    };

    const renderButton = () => {
        if (visit.stateData.isCanceled) {
            return (
                <Button
                    variant="default"
                    children="Обработать"
                    size="sm-standard"
                    onClick={() => setOpenModal(true)}
                />
            );
        } else {
            return (
                <Button
                    variant="default"
                    children="Расчитать"
                    size="sm-standard"
                    onClick={() => showSlideModal(<Payment visitId={+visit.id}/>)}
                />
            );
        }
    };

    const renderModal = () => {
        if (openModal) {
            return (
                <CanceledModal
                    closeModal={() => setOpenModal(false)}
                    visit={visit}
                    setNextState={setNextState}
                />
            )
        }
        return null
    };

    return (
        <KanbanCard
            visit={visit}
            isMinimized={isMinimized}
            removeBgColor={removeBgColor}
            doctor={doctor}
            renderHeader={renderHeader}
            renderButton={renderButton}
            renderModal={renderModal}
        />
    );
}
