import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import ChartPie from "../../../../../components-ui/Charts/Pie";
import { dataPie } from "./data/data";
import {useSelector} from "react-redux";
import useDirectionsColors from "../../../../../common/hooks/useDirectionsColors";

export default function PiePage() {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [activeCell, setActiveCell] = useState<string | number | null>('therapy');
    const directions = useSelector(state => state.common.directions)
    const directionsColors = useDirectionsColors()
    return (
        <>
            <h1>Pie</h1>
            <WrapContent>
                <ChartPie
                    data={dataPie.sort((a, b) => directions[a.code].sort - directions[b.code].sort)}
                    colors={directionsColors}
                    activeCell={activeCell}
                    hoverIndex={activeIndex}
                    setActiveCell={setActiveCell}
                    setActiveIndex={setActiveIndex}
                />
                <pre>
                    {`
const [activeIndex, setActiveIndex] = useState(-1);
const [activeCell, setActiveCell] = useState('therapy');
const directions = useSelector(state => state.common.directions);
const directionsColors = useDirectionsColors();
<ChartPie
    data={dataPie.sort((a, b) => directions[a.code].sort - directions[b.code].sort)}
    colors={directionsColors}
    activeCell={activeCell}
    activeIndex={activeIndex}
    setActiveCell={setActiveCell}
    setActiveIndex={setActiveIndex}
/>
                    `}
                </pre>
            </WrapContent>
        </>
    )
}
