import React, {useState} from 'react';
import Typography from '../../../../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../../../../components-ui/Layout/Box';
import {Label} from '../../../../../../components-ui/DataDisplay/Label';
import {commonLabelOption, commonSelectOption, commonTextFieldOption,} from '../../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import TextField from '../../../../../../components-ui/Inputs/TextField/TextField';
import {IRow} from '../../../../../../components-ui/DataDisplay/Table';
import Button from '../../../../../../components-ui/Inputs/Button/Button';
import useLoader from '../../../../../../components/Errors';
import ServerCommand from '../../../../../../common/server/server-command';
import {SavePremium, SelectedMonth} from '../../../../types';
import moment from 'moment';
import DateHelper from '../../../../../../common/helpers/date-helper';
import {useModal} from '../../../../../../containers/DialogsManager/containers/Modal/Modal';

interface Props {
    row: IRow,
    branchId: number,
    comment: string | null
    selectedMonth: SelectedMonth
    changeComment: (row: IRow, branchId: number, value: string | null) => void
}

const SalariesModalMarkComment = ({row, branchId, comment, selectedMonth, changeComment}: Props) => {

    const {closeModal} = useModal();

    const [loader, setLoader] = useLoader();

    const [commentState, setCommentState] = useState<string | null>(comment)

    const saveSalaryComment = () => {
        setLoader(true);
        ServerCommand.post('salary/premium/save', {
            month: String(moment(`${selectedMonth.year}-${selectedMonth.month}`).format(DateHelper.FORMAT_SERVER_MONTH)),
            employeeId: row.id,
            branchId,
            sum: row.branches[branchId].premium,
            comment: commentState
        } as SavePremium)
        changeComment(row, branchId, commentState)
        closeModal();
    }

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        children='Комментарий к премии / штрафу' textAlign='center' />
            <Box margin='39px 0 0 0'>
                <Label {...commonLabelOption}
                       labelText="Комментарий"
                       align="leftTop"
                       marginLabel="8px 10px 0 0">
                    <TextField
                        {...commonTextFieldOption}
                        value={commentState ? commentState : ''}
                        onChange={setCommentState}
                        isTextArea={true}
                    />
                </Label>
            </Box>
            <Label labelText='' {...commonLabelOption}>
                <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                    <Button children='Сохранить'
                            size='md'
                            loader={loader}
                            variant={'success'}
                            onClick={saveSalaryComment}
                    />
                </Box>
            </Label>
        </Box>
    );
};

export default SalariesModalMarkComment;
