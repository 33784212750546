import React from 'react';
import {WrapContent} from '../../../styledUI';
import Icon from '../../../../../components-ui/DataDisplay/Icon';
import Box from "../../../../../components-ui/Layout/Box";
import {NamesSvg} from '../../../../../components-ui/DataDisplay/Icon/types/common';
import {IColors} from "../../../../../common/Theme/Theme";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import {NAMES_SVG_OBJ} from '../../../../../components-ui/DataDisplay/Icon/types/data';
import _ from 'lodash';
import Tooltip from '../../../../../components-ui/DataDisplay/Tooltip/Tooltip';

interface ItemIcon {
    code: string,
    name: NamesSvg,
    color?: keyof IColors
}

const upper = ['18', '17', '16', '15', '14', '13', '12', '11', '21', '22', '23', '24', '25', '26', '27', '28']
const down = ['48', '47', '46', '45', '44', '43', '42', '41', '31', '32', '33', '34', '35', '36', '37', '38']
const upperKind = ['58', '57', '56', '55', '54', '53', '52', '51', '61', '62', '63', '64', '65', '66', '67', '68']
const downKind = ['88', '87', '86', '85', '84', '83', '82', '81', '71', '72', '73', '74', '75', '76', '77', '78']

const typedIcons = _.map(NAMES_SVG_OBJ, (name, code) => {
    return {code: code, name: name}
})


const IconsPage = () => {
    return (
        <div>
            <h1>Иконки</h1>
            <WrapContent>
                <h5>
                    Добавление иконок производится через добавление svg path в <br/>resources/react-app/src/components-ui <br/>
                    А также в объект NAMES_SVG_OBJ и интерфейс NamesSvgInterface
                </h5>
                <pre>{'<Icon name="close" color="danger" fontSize="50px"/>'}</pre>
                <Box>
                    <StandardIcons array={typedIcons}/>
                    <br/>
                    <br/>
                    <SeparatorIcons name="DENTALCARD" key="DENTALCARD" />
                    <br/>
                    <br/>
                    <ToothIcons array={upper}/>
                    <br/>
                    <ToothIcons array={down} alignItems="flex-start"/>
                    <br/>
                    <br/>
                    <ToothIcons array={upperKind}/>
                    <br/>
                    <ToothIcons array={downKind} alignItems="flex-start"/>
                </Box>
            </WrapContent>
        </div>
    );
};

const StandardIcons = ({array}: { array: ItemIcon[] }) => {
    let lastCode = ''
    return (
        <Box display="flex" flexWrap="wrap">
            {array.map(icon => {
                const iconCode = icon.code.split('_')[0]

                if (iconCode === 'DENTALCARD') return null

                if (iconCode !== lastCode) {
                    lastCode = iconCode
                    return <>
                        <SeparatorIcons name={iconCode} key={icon.code} />
                        <StandardIcon icon={icon} key={icon.code} />
                    </>

                } else {
                    return  <StandardIcon icon={icon} key={icon.code} />

                }

            })}
        </Box>
    )
}

const StandardIcon = ({icon}: {icon: ItemIcon}) =>
    <Box display="flex" flexDirection="column" margin="0 1px 5px" backgroundColor="whiteDark"
         alignItems="center" width="130px">

        <Icon name={icon.name} width="40px" height="40px" margin="0 0 5px" color={icon.color}/>

        <Typography variant="min">{icon.name}</Typography>

    </Box>


const SeparatorIcons = ({name}: {name: string}) =>
        <Box display="flex" flexDirection="column" margin="30px 1px 10px" backgroundColor="whiteDark"
             alignItems="center" width="100%" height="62px" justifyContent="center">
            <Typography variant="min-title" color="purple">{name + ':'}</Typography>
        </Box>




const ToothIcons = ({array, alignItems = 'flex-end'}: { array: string[], alignItems?: any }) => {
    return (
        <Box display="flex">
            {array.map(tooth => (
                <Box display="flex" width="32px" height="56px" justifyContent="center" alignItems={alignItems}
                     key={'upper' + tooth}>
                    <Icon name={`tooth${tooth}`}
                          data-for={tooth}
                          data-tip={`tooth${tooth}`}
                    />
                    <Tooltip id={tooth}/>

                </Box>

            ))}
        </Box>
    )
}

export default IconsPage;
