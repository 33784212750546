import React from 'react';
import Box from "../../components-ui/Layout/Box";
import useAnalytics from "./hooks/useAnalytics";
import Header from "./components/Header";
import {widgetsInfo} from './data/widgetsInfo';
import Widget from "./containers/Widget";
import LoaderPage from "../../components/LoaderPage";

const Analytics = () => {
    const {state, handlers, allDateRange, loaderPage, optionsBranches, selectedBranches} = useAnalytics();

    if (!loaderPage) {
        return (
            <Box display="flex" flexDirection="column" padding="44px 20px 100px 30px">
                <Header allDateRange={allDateRange} handlers={handlers}
                        selectedBranches={selectedBranches} optionsBranches={optionsBranches}
                />
                {widgetsInfo.map((widget, index) => (
                    <Widget widget={widget}
                            index={index}
                            state={state}
                            handlers={handlers}
                            key={widget.code}
                            selectedBranches={selectedBranches}
                    />
                ))}
            </Box>
        );
    } else {
        return (
            <LoaderPage />
        )
    }
};

export default Analytics;
