import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth17Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 10.91 24"} width={width || "20"} height={height || "44"} ref={ref}>
            <path d="M3.27,0c.09.06.43.32,1,1.54a2.21,2.21,0,0,0,.24,1.1c.94,2,1.17,7.78,1,9.07C4.68,10.82,3.62,5.2,3.62,3A3.15,3.15,0,0,0,3,.63,1.64,1.64,0,0,1,3.27,0ZM2.33,1.36c.11.11.34.46.34,1.61,0,1.36.94,9,2.55,9.72a.87.87,0,0,0,1-.22c.65-.65.19-8.08-.84-10.24a.89.89,0,0,1-.11-.79.26.26,0,0,1,.27,0C6.83,1.92,9.43,5.75,10,16.65a5.58,5.58,0,0,0-1.29-.71,4.27,4.27,0,0,0-2-.17c-.34,0-.73.06-1.21.06a11.65,11.65,0,0,1-1.56-.08A4.19,4.19,0,0,0,2,15.87a2.6,2.6,0,0,0-.65.26c-.7-5.35-1-13.43,1-14.78Zm8.52,19.45c-.25,1.6-.75,2.62-1.49,3-1,.52-2.35-.13-3.34-.6a3.42,3.42,0,0,0-1.27-.47,4.87,4.87,0,0,0-1.2.21c-1.2.32-2.23.59-3.29-1.55a3.35,3.35,0,0,1,.12-2.85,3.12,3.12,0,0,1,1.88-1.76,3.41,3.41,0,0,1,1.52-.1,14,14,0,0,0,3,0,3.33,3.33,0,0,1,1.6.1,3.71,3.71,0,0,1,2.53,4Z"/>
        </WrapIcon>
    );
});
