export const groups = [
    {
        id: 2,
        name: "Ортопедия",
        code: "ОРТ",
        groupId: null,
        directions: ["orthopedics"],
        careCallDelay: null,
        archive: false,
        groups: [{
            id: 284,
            name: "Коронки",
            code: " ",
            groupId: 2,
            directions: ["orthopedics"],
            careCallDelay: null,
            archive: false
        }, {
            id: 285,
            name: "Вкладки",
            code: " ",
            groupId: 2,
            directions: ["orthopedics"],
            careCallDelay: null,
            archive: false
        }, {
            id: 286,
            name: "Виниры",
            code: " ",
            groupId: 2,
            directions: ["orthopedics"],
            careCallDelay: null,
            archive: false
        }, {
            id: 287,
            name: "Съемные протезы",
            code: " ",
            groupId: 2,
            directions: ["orthopedics"],
            careCallDelay: null,
            archive: false,
            groups: [{
                id: 288,
                name: "Пластиночные протезы",
                code: " ",
                groupId: 287,
                directions: ["orthopedics"],
                careCallDelay: null,
                archive: false
            }, {
                id: 289,
                name: "Бюгельные протезы",
                code: " ",
                groupId: 287,
                directions: ["orthopedics"],
                careCallDelay: null,
                archive: false
            }, {
                id: 290,
                name: "dentalD",
                code: " ",
                groupId: 287,
                directions: ["orthopedics"],
                careCallDelay: null,
                archive: false
            }, {
                id: 291,
                name: "Починка",
                code: " ",
                groupId: 287,
                directions: ["orthopedics"],
                careCallDelay: null,
                archive: false
            }]
        }, {
            id: 292,
            name: "Ветераны",
            code: " ",
            groupId: 2,
            directions: ["orthopedics"],
            careCallDelay: null,
            archive: false
        }]
    }, {
        id: 3,
        name: "Хирургия",
        code: "ХР",
        groupId: null,
        directions: ["surgery"],
        careCallDelay: null,
        archive: false,
        groups: [{
            id: 283,
            name: "Импланты",
            code: " ",
            groupId: 3,
            directions: ["implantology"],
            careCallDelay: null,
            archive: false
        }]
    }, {
        id: 4,
        name: "Терапия",
        code: "ТР",
        groupId: null,
        directions: null,
        careCallDelay: null,
        archive: false,
        groups: [{
            id: 100,
            name: "Лечение кариеса",
            code: "01",
            groupId: 4,
            directions: null,
            careCallDelay: null,
            archive: false
        }, {
            id: 101,
            name: "Лечение  пульпита и периодонтита",
            code: "02",
            groupId: 4,
            directions: null,
            careCallDelay: null,
            archive: false,
            groups: [{
                id: 114,
                name: "Призмафил",
                code: "",
                groupId: 101,
                directions: null,
                careCallDelay: null,
                archive: true,
                groups: [{
                    id: 119,
                    name: "1 канал",
                    code: "",
                    groupId: 114,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 123,
                        name: "1-е посещение",
                        code: "",
                        groupId: 119,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 124,
                        name: "2-е посещение",
                        code: "",
                        groupId: 119,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 120,
                    name: "2 канала",
                    code: "",
                    groupId: 114,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 125,
                        name: "1-е посещение",
                        code: "",
                        groupId: 120,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 126,
                        name: "2-е посещение",
                        code: "",
                        groupId: 120,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 121,
                    name: "3 канала",
                    code: "",
                    groupId: 114,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 127,
                        name: "1-е посещение",
                        code: "",
                        groupId: 121,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 128,
                        name: "2-е посещение",
                        code: "",
                        groupId: 121,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 122,
                    name: "4 канала",
                    code: "",
                    groupId: 114,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 129,
                        name: "1-е посещение",
                        code: "",
                        groupId: 122,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 130,
                        name: "2-е посещение",
                        code: "",
                        groupId: 122,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }]
            }, {
                id: 115,
                name: "Церам Икс",
                code: "",
                groupId: 101,
                directions: null,
                careCallDelay: null,
                archive: true,
                groups: [{
                    id: 131,
                    name: "1 канал",
                    code: "",
                    groupId: 115,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 135,
                        name: "1-е посещение",
                        code: "",
                        groupId: 131,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 136,
                        name: "2-е посещение",
                        code: "",
                        groupId: 131,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 132,
                    name: "2 канала",
                    code: "",
                    groupId: 115,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 137,
                        name: "1-е посещение",
                        code: "",
                        groupId: 132,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 138,
                        name: "2-е посещение",
                        code: "",
                        groupId: 132,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 133,
                    name: "3 канала",
                    code: "",
                    groupId: 115,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 139,
                        name: "1-е посещение",
                        code: "",
                        groupId: 133,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 140,
                        name: "2-е посещение",
                        code: "",
                        groupId: 133,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 134,
                    name: "4 канала",
                    code: "",
                    groupId: 115,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 141,
                        name: "1-е посещение",
                        code: "",
                        groupId: 134,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 142,
                        name: "2-е посещение",
                        code: "",
                        groupId: 134,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }]
            }, {
                id: 116,
                name: "Филтек",
                code: "",
                groupId: 101,
                directions: null,
                careCallDelay: null,
                archive: true,
                groups: [{
                    id: 143,
                    name: "1 канал",
                    code: "",
                    groupId: 116,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 147,
                        name: "1-е посещение",
                        code: "",
                        groupId: 143,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 148,
                        name: "2-е посещение",
                        code: "",
                        groupId: 143,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 144,
                    name: "2 канала",
                    code: "",
                    groupId: 116,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 149,
                        name: "1-е посещение",
                        code: "",
                        groupId: 144,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 150,
                        name: "2-е посещение",
                        code: "",
                        groupId: 144,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 145,
                    name: "3 канала",
                    code: "",
                    groupId: 116,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 151,
                        name: "1-е посещение",
                        code: "",
                        groupId: 145,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 152,
                        name: "2-е посещение",
                        code: "",
                        groupId: 145,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 146,
                    name: "4 канала",
                    code: "",
                    groupId: 116,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 153,
                        name: "1-е посещение",
                        code: "",
                        groupId: 146,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 154,
                        name: "2-е посещение",
                        code: "",
                        groupId: 146,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }]
            }, {
                id: 117,
                name: "Estelite",
                code: "",
                groupId: 101,
                directions: null,
                careCallDelay: null,
                archive: true,
                groups: [{
                    id: 155,
                    name: "1 канал",
                    code: "",
                    groupId: 117,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 159,
                        name: "1-е посещение",
                        code: "",
                        groupId: 155,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 160,
                        name: "2-е посещение",
                        code: "",
                        groupId: 155,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 156,
                    name: "2 канала",
                    code: "",
                    groupId: 117,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 161,
                        name: "1-е посещение",
                        code: "",
                        groupId: 156,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 162,
                        name: "2-е посещение",
                        code: "",
                        groupId: 156,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 157,
                    name: "3 канала",
                    code: "",
                    groupId: 117,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 163,
                        name: "1-е посещение",
                        code: "",
                        groupId: 157,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 164,
                        name: "2-е посещение",
                        code: "",
                        groupId: 157,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 158,
                    name: "4 канала",
                    code: "",
                    groupId: 117,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 165,
                        name: "1-е посещение",
                        code: "",
                        groupId: 158,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 166,
                        name: "2-е посещение",
                        code: "",
                        groupId: 158,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }]
            }, {
                id: 118,
                name: "ESTHET X",
                code: "",
                groupId: 101,
                directions: null,
                careCallDelay: null,
                archive: true,
                groups: [{
                    id: 167,
                    name: "1 канал",
                    code: "",
                    groupId: 118,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 171,
                        name: "1-е посещение",
                        code: "",
                        groupId: 167,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 172,
                        name: "2-е посещение",
                        code: "",
                        groupId: 167,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 168,
                    name: "2 канала",
                    code: "",
                    groupId: 118,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 173,
                        name: "1-е посещение",
                        code: "",
                        groupId: 168,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 174,
                        name: "2-е посещение",
                        code: "",
                        groupId: 168,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 169,
                    name: "3 канала",
                    code: "",
                    groupId: 118,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 175,
                        name: "1-е посещение",
                        code: "",
                        groupId: 169,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 176,
                        name: "2-е посещение",
                        code: "",
                        groupId: 169,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }, {
                    id: 170,
                    name: "4 канала",
                    code: "",
                    groupId: 118,
                    directions: null,
                    careCallDelay: null,
                    archive: true,
                    groups: [{
                        id: 177,
                        name: "1-е посещение",
                        code: "",
                        groupId: 170,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }, {
                        id: 178,
                        name: "2-е посещение",
                        code: "",
                        groupId: 170,
                        directions: null,
                        careCallDelay: null,
                        archive: true
                    }]
                }]
            }, {
                id: 179,
                name: "Пульпит",
                code: "",
                groupId: 101,
                directions: null,
                careCallDelay: null,
                archive: false,
                groups: [{
                    id: 252,
                    name: "Лечение в несколько посещений",
                    code: "",
                    groupId: 179,
                    directions: null,
                    careCallDelay: null,
                    archive: false,
                    groups: [{
                        id: 180,
                        name: "1-е посещение",
                        code: "",
                        groupId: 252,
                        directions: null,
                        careCallDelay: null,
                        archive: false,
                        groups: [{
                            id: 182,
                            name: "1 канал",
                            code: "",
                            groupId: 180,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 183,
                            name: "2 канала",
                            code: "",
                            groupId: 180,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 184,
                            name: "3 канала",
                            code: "",
                            groupId: 180,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 185,
                            name: "4 канала",
                            code: "",
                            groupId: 180,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }]
                    }, {
                        id: 181,
                        name: "Повторное посещение",
                        code: "",
                        groupId: 252,
                        directions: null,
                        careCallDelay: null,
                        archive: false,
                        groups: [{
                            id: 186,
                            name: "Призмафил",
                            code: "",
                            groupId: 181,
                            directions: null,
                            careCallDelay: null,
                            archive: false,
                            groups: [{
                                id: 191,
                                name: "1 канал",
                                code: "",
                                groupId: 186,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 193,
                                name: "2 канала",
                                code: "",
                                groupId: 186,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 194,
                                name: "3 канала",
                                code: "",
                                groupId: 186,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 195,
                                name: "4 канала",
                                code: "",
                                groupId: 186,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }]
                        }, {
                            id: 187,
                            name: "CHARISMA",
                            code: "",
                            groupId: 181,
                            directions: null,
                            careCallDelay: null,
                            archive: false,
                            groups: [{
                                id: 192,
                                name: "2 канала",
                                code: "",
                                groupId: 187,
                                directions: null,
                                careCallDelay: null,
                                archive: true
                            }, {
                                id: 196,
                                name: "1 канал",
                                code: "",
                                groupId: 187,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 197,
                                name: "2 канала",
                                code: "",
                                groupId: 187,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 198,
                                name: "3 канала",
                                code: "",
                                groupId: 187,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 199,
                                name: "4 канала",
                                code: "",
                                groupId: 187,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }]
                        }, {
                            id: 188,
                            name: "Филтек",
                            code: "",
                            groupId: 181,
                            directions: null,
                            careCallDelay: null,
                            archive: false,
                            groups: [{
                                id: 200,
                                name: "1 канал",
                                code: "",
                                groupId: 188,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 201,
                                name: "2 канала",
                                code: "",
                                groupId: 188,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 202,
                                name: "3 канала",
                                code: "",
                                groupId: 188,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 203,
                                name: "4 канала",
                                code: "",
                                groupId: 188,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }]
                        }, {
                            id: 189,
                            name: "Estelite",
                            code: "",
                            groupId: 181,
                            directions: null,
                            careCallDelay: null,
                            archive: false,
                            groups: [{
                                id: 204,
                                name: "1 канал",
                                code: "",
                                groupId: 189,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 205,
                                name: "2 канала",
                                code: "",
                                groupId: 189,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 206,
                                name: "3 канала",
                                code: "",
                                groupId: 189,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 207,
                                name: "4 канала",
                                code: "",
                                groupId: 189,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }]
                        }, {
                            id: 190,
                            name: "ESTHET X",
                            code: "",
                            groupId: 181,
                            directions: null,
                            careCallDelay: null,
                            archive: false,
                            groups: [{
                                id: 208,
                                name: "1 канал",
                                code: "",
                                groupId: 190,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 209,
                                name: "2 канала",
                                code: "",
                                groupId: 190,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 210,
                                name: "3 канала",
                                code: "",
                                groupId: 190,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }, {
                                id: 211,
                                name: "4 канала",
                                code: "",
                                groupId: 190,
                                directions: null,
                                careCallDelay: null,
                                archive: false
                            }]
                        }]
                    }]
                }, {
                    id: 253,
                    name: "Лечение в 1 посещение",
                    code: "",
                    groupId: 179,
                    directions: null,
                    careCallDelay: null,
                    archive: false,
                    groups: [{
                        id: 254,
                        name: "1 канал",
                        code: "",
                        groupId: 253,
                        directions: null,
                        careCallDelay: null,
                        archive: false,
                        groups: [{
                            id: 258,
                            name: "Призмафил",
                            code: "",
                            groupId: 254,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 262,
                            name: "CHARISMA",
                            code: "",
                            groupId: 254,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 266,
                            name: "Филтек",
                            code: "",
                            groupId: 254,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 270,
                            name: "Estelite",
                            code: "",
                            groupId: 254,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 274,
                            name: "ESTHET X",
                            code: "",
                            groupId: 254,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }]
                    }, {
                        id: 255,
                        name: "2 канала",
                        code: "",
                        groupId: 253,
                        directions: null,
                        careCallDelay: null,
                        archive: false,
                        groups: [{
                            id: 259,
                            name: "Призмафил",
                            code: "",
                            groupId: 255,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 263,
                            name: "CHARISMA",
                            code: "",
                            groupId: 255,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 267,
                            name: "Филтек",
                            code: "",
                            groupId: 255,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 271,
                            name: "Estelite",
                            code: "",
                            groupId: 255,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 275,
                            name: "ESTHET X",
                            code: "",
                            groupId: 255,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }]
                    }, {
                        id: 256,
                        name: "3 канала",
                        code: "",
                        groupId: 253,
                        directions: null,
                        careCallDelay: null,
                        archive: false,
                        groups: [{
                            id: 260,
                            name: "Призмафил",
                            code: "",
                            groupId: 256,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 264,
                            name: "CHARISMA",
                            code: "",
                            groupId: 256,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 268,
                            name: "Филтек",
                            code: "",
                            groupId: 256,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 272,
                            name: "Estelite",
                            code: "",
                            groupId: 256,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 276,
                            name: "ESTHET X",
                            code: "",
                            groupId: 256,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }]
                    }, {
                        id: 257,
                        name: "4 канала",
                        code: "",
                        groupId: 253,
                        directions: null,
                        careCallDelay: null,
                        archive: false,
                        groups: [{
                            id: 261,
                            name: "Призмафил",
                            code: "",
                            groupId: 257,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 265,
                            name: "CHARISMA",
                            code: "",
                            groupId: 257,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 269,
                            name: "Филтек",
                            code: "",
                            groupId: 257,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 273,
                            name: "Estelite",
                            code: "",
                            groupId: 257,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 277,
                            name: "ESTHET X",
                            code: "",
                            groupId: 257,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }]
                    }]
                }]
            }, {
                id: 213,
                name: "Периодонтит",
                code: "",
                groupId: 101,
                directions: null,
                careCallDelay: null,
                archive: false,
                groups: [{
                    id: 215,
                    name: "1-е посещение",
                    code: "",
                    groupId: 213,
                    directions: null,
                    careCallDelay: null,
                    archive: false,
                    groups: [{
                        id: 216,
                        name: "1 канал",
                        code: "",
                        groupId: 215,
                        directions: null,
                        careCallDelay: null,
                        archive: false
                    }, {
                        id: 217,
                        name: "2 канала",
                        code: "",
                        groupId: 215,
                        directions: null,
                        careCallDelay: null,
                        archive: false
                    }, {
                        id: 218,
                        name: "3 канала",
                        code: "",
                        groupId: 215,
                        directions: null,
                        careCallDelay: null,
                        archive: false
                    }, {
                        id: 219,
                        name: "4 канала",
                        code: "",
                        groupId: 215,
                        directions: null,
                        careCallDelay: null,
                        archive: false
                    }]
                }, {
                    id: 220,
                    name: "повторное посещение",
                    code: "",
                    groupId: 213,
                    directions: null,
                    careCallDelay: null,
                    archive: false,
                    groups: [{
                        id: 221,
                        name: "Призмафил",
                        code: "",
                        groupId: 220,
                        directions: null,
                        careCallDelay: null,
                        archive: false,
                        groups: [{
                            id: 222,
                            name: "1 канал",
                            code: "",
                            groupId: 221,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 223,
                            name: "2 канала",
                            code: "",
                            groupId: 221,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 224,
                            name: "3 канала",
                            code: "",
                            groupId: 221,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 225,
                            name: "4 канала",
                            code: "",
                            groupId: 221,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }]
                    }, {
                        id: 226,
                        name: "Церам Икс",
                        code: "",
                        groupId: 220,
                        directions: null,
                        careCallDelay: null,
                        archive: false,
                        groups: [{
                            id: 227,
                            name: "1 канал",
                            code: "",
                            groupId: 226,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 228,
                            name: "2 канала",
                            code: "",
                            groupId: 226,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 229,
                            name: "3 канала",
                            code: "",
                            groupId: 226,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 230,
                            name: "4 канала",
                            code: "",
                            groupId: 226,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }]
                    }, {
                        id: 231,
                        name: "Филтек",
                        code: "",
                        groupId: 220,
                        directions: null,
                        careCallDelay: null,
                        archive: false,
                        groups: [{
                            id: 232,
                            name: "1 канал",
                            code: "",
                            groupId: 231,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 233,
                            name: "2 канала",
                            code: "",
                            groupId: 231,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 234,
                            name: "3 канала",
                            code: "",
                            groupId: 231,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 235,
                            name: "4 канала",
                            code: "",
                            groupId: 231,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }]
                    }, {
                        id: 236,
                        name: "Estelite",
                        code: "",
                        groupId: 220,
                        directions: null,
                        careCallDelay: null,
                        archive: false,
                        groups: [{
                            id: 237,
                            name: "1 канал",
                            code: "",
                            groupId: 236,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 238,
                            name: "2 канала",
                            code: "",
                            groupId: 236,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 239,
                            name: "3 канала",
                            code: "",
                            groupId: 236,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 240,
                            name: "4 канала",
                            code: "",
                            groupId: 236,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }]
                    }, {
                        id: 241,
                        name: "ESTHET X",
                        code: "",
                        groupId: 220,
                        directions: null,
                        careCallDelay: null,
                        archive: false,
                        groups: [{
                            id: 242,
                            name: "1 канал",
                            code: "",
                            groupId: 241,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 243,
                            name: "2 канала",
                            code: "",
                            groupId: 241,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 244,
                            name: "3 канала",
                            code: "",
                            groupId: 241,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }, {
                            id: 245,
                            name: "4 канала",
                            code: "",
                            groupId: 241,
                            directions: null,
                            careCallDelay: null,
                            archive: false
                        }]
                    }]
                }]
            }, {
                id: 214,
                name: "Периодонтит острый",
                code: "",
                groupId: 101,
                directions: null,
                careCallDelay: null,
                archive: false
            }, {
                id: 247,
                name: "Депульпирование по ортопедическим показаниям",
                code: "",
                groupId: 101,
                directions: null,
                careCallDelay: null,
                archive: false,
                groups: [{
                    id: 248,
                    name: "1 канал",
                    code: "",
                    groupId: 247,
                    directions: null,
                    careCallDelay: null,
                    archive: false
                }, {
                    id: 249,
                    name: "2 канала",
                    code: "",
                    groupId: 247,
                    directions: null,
                    careCallDelay: null,
                    archive: false
                }, {
                    id: 250,
                    name: "3 канала",
                    code: "",
                    groupId: 247,
                    directions: null,
                    careCallDelay: null,
                    archive: false
                }, {
                    id: 251,
                    name: "4 канала",
                    code: "",
                    groupId: 247,
                    directions: null,
                    careCallDelay: null,
                    archive: false
                }]
            }]
        }, {
            id: 102,
            name: "Восстановление зуба пломбой «ЭКОНОМ-КЛАСС»",
            code: "03",
            groupId: 4,
            directions: null,
            careCallDelay: null,
            archive: true
        }, {
            id: 103,
            name: "Восстановление зуба пломбой «БИЗНЕС КЛАСС»",
            code: "04",
            groupId: 4,
            directions: null,
            careCallDelay: null,
            archive: true
        }, {
            id: 104,
            name: "Восстановление зуба пломбой «ПРЕМИУМ КЛАСС»",
            code: "05",
            groupId: 4,
            directions: null,
            careCallDelay: null,
            archive: true
        }, {
            id: 105,
            name: "Лечение заболеваний пародонта",
            code: "06",
            groupId: 4,
            directions: null,
            careCallDelay: null,
            archive: false,
            groups: [{
                id: 106,
                name: "Лечение пародонтита: (курс)",
                code: "1.52",
                groupId: 105,
                directions: null,
                careCallDelay: null,
                archive: false
            }]
        }, {
            id: 212,
            name: "Эстетическая стоматология",
            code: "",
            groupId: 4,
            directions: null,
            careCallDelay: null,
            archive: false
        }]
    }, {
        id: 6,
        name: "Ортодонтия",
        code: "ОРД",
        groupId: null,
        directions: ["orthodontics"],
        careCallDelay: null,
        archive: false
    }, {
        id: 8,
        name: "Детская стоматология",
        code: "ДТ",
        groupId: null,
        directions: ["children"],
        careCallDelay: null,
        archive: false,
        groups: [{
            id: 108,
            name: "Профилактика кариеса",
            code: "01",
            groupId: 8,
            directions: ["children"],
            careCallDelay: null,
            archive: false
        }, {
            id: 109,
            name: "Лечение кариеса молочных зубов",
            code: "02",
            groupId: 8,
            directions: ["children"],
            careCallDelay: null,
            archive: false
        }, {
            id: 110,
            name: "Лечение пульпита зубов с не сформированными корнями",
            code: "03",
            groupId: 8,
            directions: ["children"],
            careCallDelay: null,
            archive: false,
            groups: [{
                id: 278,
                name: "Лечение в 1 посещение",
                code: "",
                groupId: 110,
                directions: ["children"],
                careCallDelay: null,
                archive: false
            }, {
                id: 279,
                name: "Лечение в 2 посещения",
                code: "",
                groupId: 110,
                directions: ["children"],
                careCallDelay: null,
                archive: false
            }]
        }, {
            id: 111,
            name: "Лечение пульпита постоянного зуба с сформированными корнями",
            code: "04",
            groupId: 8,
            directions: ["children"],
            careCallDelay: null,
            archive: false
        }, {
            id: 280,
            name: "Лечение пульпита молочных зубов",
            code: "",
            groupId: 8,
            directions: ["children"],
            careCallDelay: null,
            archive: false,
            groups: [{
                id: 281,
                name: "Лечение в 1 посещение",
                code: "",
                groupId: 280,
                directions: ["children"],
                careCallDelay: null,
                archive: false
            }, {
                id: 282,
                name: "Лечение в 2 посещения",
                code: "",
                groupId: 280,
                directions: ["children"],
                careCallDelay: null,
                archive: false
            }]
        }]
    }, {
        id: 107,
        name: "Профилактика кариозных и не кариозных поражений полости рта",
        code: "ПРОФ",
        groupId: null,
        directions: null,
        careCallDelay: null,
        archive: false
    }, {
        id: 112,
        name: "Плановое посещение (коррекция, динамическое наблюдение)",
        code: "",
        groupId: null,
        directions: null,
        careCallDelay: null,
        archive: false
    }, {
        id: 113,
        name: "Сертификат",
        code: "1",
        groupId: null,
        directions: null,
        careCallDelay: null,
        archive: false
    }, {
        id: 246,
        name: "Дополнительные",
        code: "",
        groupId: null,
        directions: null,
        careCallDelay: null,
        archive: false
    }]
