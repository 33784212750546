import {Props} from "../types";

export const getFontStyle = (props: Props) => {
    const {fontStyle, currentAs} = props;
    if (!!fontStyle) {
        return fontStyle
    } else {
        switch (currentAs) {
            case "span":
                return 'inherit'
            default:
                return 'normal'
        }
    }
}
