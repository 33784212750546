import {ReceiptsReport} from "../../../../types";
import {useMemo} from "react";
import _ from 'lodash';
import {PieDataItem} from "../../../../../../components-ui/Charts/Pie";
import {useSelector} from "react-redux";
import {StateReceipts} from "../types";

interface Props {
    report: ReceiptsReport
    stateKey: string
    state: StateReceipts
}

const usePieData = ({report, stateKey, state}: Props) => {
    const directions = useSelector(state => state.common.directions)
    return useMemo(() => {
        if (report.current.fullMonths.length <= 1) {
            if (state.currentTab === 'doctors' && !!state.selectedDirection) {
                const directionDoctors = state.selectedDirection === 'all' ? report.current.general.doctors : report.current.directions[state.selectedDirection].doctors
                return _.map(directionDoctors, (value, code) : PieDataItem => {
                    return {
                        code,
                        value: value.sum
                    }
                }).sort((a, b) => report.current.general.doctors[+b.code].sum - report.current.general.doctors[+a.code].sum)
            } else {
                return _.map(report.current.directions, (value, code) : PieDataItem => {
                    return {
                        code,
                        value: value.sum
                    }
                }).sort((a, b) => directions[a.code].sort - directions[b.code].sort)
            }

        } else {
            return null;
        }
    }, [JSON.stringify(report), stateKey, state.currentTab, state.selectedDirection])
}

export default usePieData
