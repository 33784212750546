import {useSelector} from "react-redux";
import {useMemo} from "react";
import _ from "lodash";
import {PropsUseFields} from "../types/hooks";
import { PatientFieldKey } from "../types/fields";

export const useFields = ({parentName}: PropsUseFields) => {

    const patientFields = useSelector(state => state.patient.fields.fields)
    return useMemo(() => {
        const res = _.cloneDeep(patientFields);
        _.forEach(res, (field, fieldName: PatientFieldKey) => {
            switch (fieldName) {
                case "personalPhone":
                case "additionalPhone":
                    res[fieldName].mask = '+7 (999) 999-99-99'
                    break;
                case "passportSn":
                    res[fieldName].mask = '9999 999999'
                    break;
                case "inn":
                    res[fieldName].mask = '9999999999'
                    break;
                default:
                    break;
            }
        })
        if (parentName === "Preparation") {
            _.forEach(res, (field, fieldName) => {
                switch (fieldName) {
                    case "passportSn":
                    case "passportIssuedBy":
                    case "passportIssuedDate":
                    case "personalCity":
                        res[fieldName].required = true
                        break;
                }
            })
        }
        _.forEach(res, (field, fieldName) => {
            if (field.type === 'composite' && !!field.subFields) {
                res[fieldName].required = field.subFields.some(subField => res[subField].required)
            }
        })
        return res
    }, []);
}
