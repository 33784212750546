import {useMemo} from "react";
import {ReceiptsReport} from "../../../../types";
import {StateReceipts} from "../types";

interface Props {
    report: ReceiptsReport
    state: StateReceipts
}

const useTotalSum = ({report, state}: Props) => {

    return useMemo(() => {
        let totalSum = 0;
        if (!!state.selectedDirection) {
            totalSum = state.selectedDirection === 'all'
                ? report.current.general.sum
                : !!report.current.directions[state.selectedDirection] ? report.current.directions[state.selectedDirection].sum : 0
        }
        let previousPercentage: null | number = null;

        const getGeneralSum = (data: any): number => {
            return state.selectedDirection === 'all' || state.selectedDirection === null
                ? data.general.sum
                : !!data.directions[state.selectedDirection] ? data.directions[state.selectedDirection].sum : null
        }

        const previousGeneralSum = getGeneralSum(report.previous.data)
        if (!!previousGeneralSum) {
            previousPercentage = Math.round(100 * (totalSum - previousGeneralSum) / previousGeneralSum);
        }
        let previousYearPercentage: null | number = null;
        if (!!report.previousYear) {
            const previousYearGeneralSum = getGeneralSum(report.previousYear.data);
            if (!!previousYearGeneralSum) {
                previousYearPercentage = Math.round(100 * (totalSum - previousYearGeneralSum) / previousYearGeneralSum)
            }
        }

        return {
            totalSum,
            previousPercentage,
            previousYearPercentage
        }
    }, [JSON.stringify(report), state.selectedDirection])
}

export default useTotalSum
