import Helper from "../../../common/helpers/main";
import getSplitValue from "../../../components-ui/Inputs/CalendarField/helpers/getSplitValue";
import {FieldsPatient} from "../../../common/types/patient";
import { IPatientField } from "../../PatientField";

export const preparePersonalBirthday = (personalBirthday: string, field: IPatientField | null = null) => {
    if (!personalBirthday) {
        return '-'
    } else {
        const {day, month, year} = getSplitValue(personalBirthday);
        const newDate = [year, month, day].join('-');
        const age = !!field && field.name !== 'passportIssuedDate' ? ` (${Helper.Date.getAge(newDate)})` : ''
        return Helper.Date.formatDate(newDate, 'DD MMMM YYYY') + age
    }
}

export const prepareEnum = (element: any, field: IPatientField) => {
    if (element === null) {
        return '-'
    } else {
        if (element === '') {
            return '-'
        } else {
            const findElement = field.variants?.find(variant => variant.value === element);
            return !!findElement ? findElement.label : '-'
        }
    }
}

export const prepareDate = (date: string) => {
    if (!date) {
        return '-'
    } else {
        const {day, month, year} = getSplitValue(date);
        const newDate = [year, month, day].join('-');
        return `${Helper.Date.formatDate(newDate, 'DD MMMM YYYY')}`
    }
}

export const prepareAddress = (patient: FieldsPatient) => {
    const personalCity = !!patient.personalCity ? patient.personalCity : null;
    const personalStreet = !!patient.personalStreet ? patient.personalStreet : null;
    const personalHome = !!patient.personalHome ? patient.personalHome : null;
    const personalHousing = !!patient.personalHousing ? patient.personalHousing : null;
    const personalApartment = !!patient.personalApartment ? patient.personalApartment : null;
    const res = [personalCity, personalStreet, personalHome, personalHousing, personalApartment]
        .filter(item => item !== null).join(', ')
    return !!res ? res : '-'
}

export const prepareFio = (patient: FieldsPatient) => {
    const name = !!patient.name ? patient.name : null;
    const secondName = !!patient.secondName ? patient.secondName : null;
    const lastName = !!patient.lastName ? patient.lastName : null;
    return [lastName, name, secondName].filter(item => item !== null).join(' ')
}
