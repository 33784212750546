import React, {useContext, useState} from "react";
import {activeTab, ContextChoiceOrCompletion, PropsChoiceOrCompletion} from "./types/common";
import {tabs} from "./helper/tabs";
import useInitial from "./hooks/useInitial";
import Paper from "../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Loader from "../../../../components-ui/Feedback/Loader/Loader";
import TabsChoiceOrCompletion from "./components/TabsChoiceOrCompletion";
import NextVisit from "./components/NextVisit";
import Reason from "./components/Reason";
import Footer from "./components/Footer";
import Title from "./components/Title";
import {COMPLETION} from "./helper/constants";
import SubTitle from "./components/SubTitle";
import RegisterButton from "./components/RegisterButton";
import Box from "../../../../components-ui/Layout/Box";
import useLoader from "../../../../components/Errors";

const ChoiceOrCompletionContext = React.createContext({} as ContextChoiceOrCompletion);
export const useChoiceOrCompletion = () => useContext(ChoiceOrCompletionContext)

export default function ChoiceOrCompletion({visitId, goToNextStep, parentName, patient, isSocialVisit}: PropsChoiceOrCompletion) {
    const [activeTab, setActiveTab] = useState<activeTab>(tabs[0].code);
    const [loaderNextBtn, setLoaderNextBtn] = useLoader();
    const {form, setForm, onChangeSelectedReason, onChangeComment, onChangeReason} = useInitial({visitId, parentName});

    if (!!form.nextVisit.visitId) {
        const completionHasTabs = parentName === COMPLETION && form.isPlanFulfilled === false && !form.nextVisit.isExists;
        return (
            <ChoiceOrCompletionContext.Provider value={{
                form,
                setForm,
                activeTab,
                setActiveTab,
                loaderNextBtn,
                setLoaderNextBtn,
                goToNextStep,
                onChangeSelectedReason,
                visitId,
                onChangeComment,
                onChangeReason,
                parentName,
                completionHasTabs,
                patient,
                isSocialVisit
            }}>
                <Paper maxWidth="700px" margin="0 auto" padding="45px 30px" isHidden={false}>
                    <Title/>
                    <SubTitle/>
                    <TabsChoiceOrCompletion/>
                    {(activeTab === 'nextVisit' && completionHasTabs) && <RegisterButton/>}
                    {activeTab !== 'nextVisit' && <Reason/>}
                    <NextVisit/>
                    <Footer/>
                </Paper>
            </ChoiceOrCompletionContext.Provider>
        )
    } else {
        return (
            <Box display="flex" width="100%" height="200px" position="relative">
                <Loader visible={true} isBackground={false}/>
            </Box>
        )
    }
}
