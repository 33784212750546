import styled from "styled-components";

export const SLabelsWrap = styled.div.attrs({className: 'SPopupLabelList'})`
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    border-radius: 9px 0 0 9px;
    box-shadow: ${props => props.theme.shadow.popupLabel};
    background: ${props => props.theme.colors.white};
`

export const SLabel = styled.div.attrs({className: 'SPopupLabel'})`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 8px 6px 8px 8px;
    cursor: pointer;
    position: relative;
    &:hover {
        .SLabelInfo {
            display: flex !important;
        }
    }
`

export const SCounts = styled.div.attrs({className: 'SPopupCounts'})`
    position: absolute;
    right: -2px;
    top: -2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    opacity: 0.95;
    background: ${props => props.theme.colors.offline};
    color: ${props => props.theme.colors.white};
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const SLabelIconWrap = styled.div.attrs({className: 'SPopupLabelIconWrap'})`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colors.primary};
    border-radius: 9px;

    .dialog-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`

export const SLabelInfo = styled.div.attrs({className: 'SLabelInfo'})`
    display: none;
    position: absolute;
    font-size: 0.875rem;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.tooltipBgDark};
    width: max-content;
    padding: 12px;
    right: 65px;
    border-radius: 4px;
    flex-direction: column;
`

export const SLabelTitle = styled.p.attrs({className: 'SPopupLabelTitle'})`
    margin: 0 0 6px 0;
    font-weight: 600;
    font-style: normal;
`

export const SLabelText = styled.p.attrs({className: 'SPopupLabelText'})`
    margin: 0;
    line-height: 1.43;
`

export const SLabelHint = styled.div.attrs({className: 'SPopupLabelHint'})`
    font-size: 14px;
    position: absolute;
    bottom: 8px;
    left: -100px;
    background-color: ${props => props.theme.colors.white};
    border-radius: 2px;
    padding: 5px 0;
    box-shadow: ${props => props.theme.shadow.popupLabel};
    border: solid 1px ${props => props.theme.colors.borderGray};
    cursor: pointer;
    z-index: 14;
    span {
        display: block;
        padding: 5px 20px;
    }
    &:hover span{
        background-color: ${props => props.theme.colors.textGray};
        color: ${props => props.theme.colors.white};
    }
`
