import _ from 'underscore'
import {User} from "../../types";

/**
 * Проверяет, может ли врач выполнить переданную услугу
 * @param doctor
 * @param service
 * @param acceptWithoutSpecialities
 */
export function canPerformService(doctor: User, service: {directions: null | string[]}, acceptWithoutSpecialities: boolean = false): boolean {
    if (doctor.specialities && (doctor.specialities.length > 0) && (service.directions !== null)) {
        return _.intersection(doctor.specialities, service.directions).length > 0;
    }

    return acceptWithoutSpecialities;
}

export function hasSpecialities(doctor: User, specialities: string[]): boolean {
    if (doctor.specialities === null) {
        return specialities.length === 0;
    }

    return _.difference(specialities, doctor.specialities).length === 0;
}

/**
 * Фильтрует список врачей - оставляет только не удалённых
 * @param doctors
 */
export function withoutTrashed(doctors: User[]): User[] {
    return _.filter(doctors, doctor => doctor.deletedAt === null);
}
