import React from 'react';
import {ManagerTask} from "../../types/common";
import {LongVisitWaitingPayload} from "../../types/taskData";
import {STaskList} from '../../../../containers/Tasks/style';
import {Patients, VisitDelayStartTime} from "../Labels";
import {ButtonsWrap} from "../Buttons";
import {VisitWaitingIsOver} from "../Buttons/VisitWaitingIsOver";

interface Props {
    task: ManagerTask<LongVisitWaitingPayload>
    renderBranches: () => React.ReactNode | null
}

const LongVisitWaiting = ({task, renderBranches}: Props) => {
    return (
        <>
            <STaskList padding="19px 0 16px">
                {renderBranches()}
                <Patients patients={[task.payload.patient]}/>
                <VisitDelayStartTime visitDelayStartTime={task.payload.visitDelayStartTime}/>
            </STaskList>
            <ButtonsWrap>
                <VisitWaitingIsOver visitId={task.payload.visitId} />
            </ButtonsWrap>
        </>

    );
};

export default LongVisitWaiting;
