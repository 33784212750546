import styled from "styled-components";

interface PInputWrap {
    widthInput?: string | number,
    ref?: any,
    margin?: string
}

const width = (props: PInputWrap) => {
    if (!!props.widthInput) {
        if (typeof props.widthInput === 'string') {
            return props.widthInput
        } else {
            return props.widthInput + 'px'
        }
    } else {
        return 'auto'
    }
}

export const InputWrap = styled.span.attrs({className: 'InputWrap'})<PInputWrap>`
  position: relative;
  width: ${width};
  display: inline-block;
  margin: ${props => !!props.margin ? props.margin : '0'};
`;

export const SLabelTextField = styled.span.attrs({className: 'SLabelTextField'})`
    position: absolute;
    top: 50%;
    left: 16px;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    transform: translateY(-57%);
    color: ${props => props.theme.colors.textGray};
    & > span {
        font-size: 14px;
        color: ${props => props.theme.colors.danger};
    }
`
