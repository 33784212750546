import React, {Dispatch, SetStateAction} from 'react';
import GroupsTree from '../../../../../../../../components-ui/GroupsTree';
import useServicesTable from './hooks/useServicesTable';
import {GetActualRatesResult} from '../../../../../../../LaboratoryServiceRatesPage/types';
import WrapForRates from './components/WrapForRates';
import Box from '../../../../../../../../components-ui/Layout/Box';
import {Salary} from '../../../AddingEditingEmployee/types';

interface Props {
    dataResponse: GetActualRatesResult
    rates: Pick<Salary, "services" | "group_services">
    setRates: Dispatch<SetStateAction<Pick<Salary, 'services' | 'group_services'>>>
}

const ServicesTable = ({dataResponse, rates, setRates}: Props) => {
    const {servicesGroups, selectedGroup, onClickGroup} = useServicesTable({dataResponse})

    return (
        <Box display="flex" justifyContent="space-between">
            <GroupsTree servicesGroups={servicesGroups}
                        onClickGroup={onClickGroup}
                        selectedGroup={selectedGroup}
                        showServices
            />
            <Box padding="9px 0 13px"
                 width="240px"
                 display="flex"
                 flexDirection="column"
                 alignItems="flex-end"
                 justifyContent="flex-end"
            >
                <WrapForRates servicesGroups={servicesGroups} rates={rates} setRates={setRates}/>
            </Box>
        </Box>
    );
};

export default ServicesTable;
