import React from 'react';
import PropTypes from 'prop-types';

export default class Box extends React.Component {
    static propTypes = {
        modification: PropTypes.string,
        size: PropTypes.string
    };
    render() {
        const {modification, size} = this.props;
        let className = 'box';

        if (size) {
            className += '-' + size;
        }

        if (modification) {
            className += ' ' + className + '--' + this.props.modification
        }

        return (
            <div className={className}>
                {this.props.children}
            </div>
        )
    }


}
