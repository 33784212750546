import React from 'react'
import PropTypes from 'prop-types'
import _ from 'underscore'

class CellContextMenu extends React.PureComponent {
    static propTypes = {
        commands: PropTypes.array.isRequired,
        timeStart: PropTypes.string,
        timeEnd: PropTypes.string,
        chairId: PropTypes.number,
        date: PropTypes.string,

        onShowActionVariants: PropTypes.func,
        onHideActionVariants: PropTypes.func,
        onCommandExec: PropTypes.func,
    };

    commandsWithVariants = {
        'schedule/change-doctor': true
    };

    render() {
        let commands = _.clone(this.props.commands);
        this.sortCommandVariants(commands);

        if (!!commands) {
            return (
                <div className="dClndr_popup_menu" onClick={this.blockEvent} onContextMenu={this.blockEvent}>
                    <ul className="dClndr_pmenu1">

                        {commands.map(command =>
                            <li className={command.variants ? 'dClndr_phasmenu' : ''}
                                onMouseEnter={this.props.onShowActionVariants}
                                onMouseLeave={this.props.onHideActionVariants}
                                onClick={(this.commandsWithVariants[command.code] && command.variants) ? null : this.processCommand.bind(this, command.code, null)}
                                key={command.code}>
                                {command.name}

                                {this.commandsWithVariants[command.code] && (
                                    <ul className="dClndr_psubmenu">
                                        {this.commandsWithVariants[command.code] && command.variants && command.variants.map(variant =>
                                            <li onClick={this.processCommand.bind(this, command.code, variant.code)}
                                                key={command.code + '_' + variant.code}>
                                                {variant.name}
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </li>
                        )}
                    </ul>
                </div>
            );
        } else {
            return null;
        }
    }

    blockEvent(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    processCommand(commandCode, variantCode = '', e) {
        if (this.props.onCommandExec) {
            this.props.onCommandExec(commandCode, variantCode);
        }
    }

    sortCommandVariants(commands) {
        commands.forEach(command => {
            if (!!command.variants) {
                command.variants.sort((variantA, variantB) => {
                    if (variantA.code && !variantB.code) {
                        return 1;
                    } else if (!variantA.code && variantB.code) {
                        return -1;
                    }
                    if (variantA.name > variantB.name) {
                        return 1;
                    } else if (variantA.name < variantB.name) {
                        return -1;
                    } else return 0;
                });
            }
        })
    }


}

export default CellContextMenu
