import React from 'react';
import Typography from '../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../components-ui/Layout/Box';
import useSettingsEmployees from './hooks/useSettingsEmployees';
import EmployeesTable from './components/EmployeesTable';
import Button from '../../../components-ui/Inputs/Button/Button';
import AddingEditingEmployee from './components/modals/AddingEditingEmployee';
import Paper from '../../../containers/UI-Collection/Elements/Surfaces/Paper';
import {useDialogsManager} from '../../../containers/DialogsManager/DialogsManager';
import LoaderPage from '../../../components/LoaderPage';
import EmployeesFilter from './components/EmployeesFilter';

const SettingsEmployeesPage = () => {

    const {
        state,
        setState,
        refScrollList,
        onScroll,
        refreshDataFromServer
    } = useSettingsEmployees();

    const {showSlideModal} = useDialogsManager();
    return (
        <Box padding='62px 20px 80px 28px'>
            <Typography variant='title' fontWeight='bold'>Сотрудники</Typography>
            <EmployeesFilter state={state} setState={setState}/>
            <Paper padding='24px' minHeight={600}>
                {state.usersList
                    ?
                    <EmployeesTable data={state.usersList}
                                    refScrollList={refScrollList}
                                    onScroll={onScroll}
                                    refreshDataFromServer={refreshDataFromServer}
                    />
                    :
                    <LoaderPage height={400}/>
                }
                <Button margin='24px 0 0 0'
                        variant='default'
                        size='md'
                        children='Добавить'
                        onClick={() => showSlideModal(<AddingEditingEmployee refreshDataFromServer={refreshDataFromServer}/>)}
                />
            </Paper>
        </Box>
    );
};

export default SettingsEmployeesPage;
