import React from "react";
import {useWAP} from "../../../WaitingAndPlanned";
import commonLabelOptions from "../../../../../../../containers/PatientField/helper/commonLabelOptions";
import Select from "../../../../../../../components-ui/Inputs/Select/Select";
import {Label} from "../../../../../../../components-ui/DataDisplay/Label";
import {useOptionsSelectBranches} from "../../../../../../../common/hooks";

export default function BranchesWAP() {
    const {form, onChangeField} = useWAP();
    const branches = useOptionsSelectBranches();

    return (
        <Label labelText='Филиалы'
               {...commonLabelOptions(true, 'WAP')}
               marginWrap="0 0 19px 0"
        >
            <Select options={branches}
                    value={form.branches}
                    widthSelect="334px"
                    isMulti={true}
                    onChange={value => {
                        onChangeField(!!value ? value : [], 'branches')
                    }}
                    placeholder="все филиалы"/>
        </Label>
    )

}
