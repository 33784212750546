import React from 'react';
import {useModal} from '../../../../../containers/DialogsManager/containers/Modal/Modal';
import ServerCommand from '../../../../../common/server/server-command';
import {StandardDeletionParams} from '../../../../../types';
import ConfirmModal from '../../../../../content/modals/ConfirmModal';

interface Props {
    id: number
    refreshDataFromServer: () => void
}

const LaboratoryDelete = ({id, refreshDataFromServer}: Props) => {

    const {closeModal} = useModal()

    const deleteLab = () => {
        ServerCommand.post('laboratory/delete', {id: id} as StandardDeletionParams, () => {
            closeModal()
            refreshDataFromServer();
        }).then()
    }

    return (
        <ConfirmModal
            descrText="Вы точно хотите удалить лабораторию?"
            submitText="Удалить лабораторию"
            onClose={closeModal}
            onSumbit={deleteLab}/>
    );
};

export default LaboratoryDelete;
