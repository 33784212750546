import React, {useRef} from 'react';
import Column from './Column';
import CurTime from './CurTime';

interface Props {
    schdl: any
    branchId: any
    patients: any
    commands: any
    date: any
    timeLine: any
    update: any
    splitInterval: any
    uniteInterval: any
    availableTimeUnite: any
    filter: any
    resizeVisit: any
    currentDate: any
    onCopyPatient: any
    changeTimeHighlight: any
    transferPatient: any
    clearPatientsBuffer: any
    isCurDay: any
    branches: any

    doctors: any
    getMoment: any
    currentTime: any
    timeLineNode: any
    curServerMoment: any
    getTopCurTime: any
    shadowCurDate: any,
    handleCellClick: (selectedCell: {timeStart: string, timeEnd: string, chairId: number, date: string}) => {},
    highlightedCells: any
}

const SheduleBrancheDay = (
    {
        branches,
        isCurDay,
        timeLineNode,
        getMoment,
        timeLine,
        curServerMoment,
        getTopCurTime,
        shadowCurDate,
        date,
        currentDate,
        schdl,
        branchId,
        filter,
        handleCellClick,
        highlightedCells,
        ...props
    }: Props) => {
    const ref = useRef(null) as any;
    const findBranch = branches.find((branch: any) => +branch.id === +branchId);

    return (
        <div style={{paddingRight: '5px'}}>
            {!!findBranch && (
                <div className='dayCalendar_branch'><span>{findBranch.name}</span></div>
            )}
            <div className='dayCalendar_body' ref={ref}>
                {schdl.map((chairSchedule: any) =>
                    <Column cells={chairSchedule.cells}
                            chair={chairSchedule.chair}
                            mainDoctors={chairSchedule.mainDoctors}
                            key={chairSchedule.chair.id + '_' + branchId}
                            getMoment={getMoment}
                            date={date}
                            chairId={chairSchedule.chair.id}
                            timeLine={timeLine}
                            timeTo={filter.timeTo}
                            currentDate={currentDate}
                            handleCellClick={handleCellClick}
                            highlightedCells={highlightedCells}
                            {...props}
                    />,
                )}

                {isCurDay && !!timeLineNode.current && !!ref.current && (
                    <>
                        <CurTime timeLine={timeLine}
                                 timeLineNode={timeLineNode}
                                 getMoment={getMoment}
                                 curServerMoment={curServerMoment}
                                 getTopCurTime={getTopCurTime}
                                 topBody={ref.current.getBoundingClientRect().top}
                        />
                        {shadowCurDate}
                    </>
                )}
                {date < currentDate && <span className='table__cell--shadow' />}
            </div>
        </div>
    );
};

export default SheduleBrancheDay;
