import React from "react";
import {WrapContent} from "../../../styledUI";
import {List, ListItem} from "../../../../../components-ui/DataDisplay/List";

export default function ListPage() {
    return (
        <>
            <h1>List</h1>
            <WrapContent>
                <div>
                    <div>
                        <List>
                            <ListItem>
                                list - item - span
                            </ListItem>
                            <ListItem onClick={() => console.log('ok')}>
                                list - item - link
                            </ListItem>
                            <ListItem onClick={() => console.log('ok')} active={true}>
                                list - item - link
                            </ListItem>
                        </List>
                        <pre>
                        {
                            '<List>\n' +
                            '    <ListItem>\n' +
                            '        list - item - span\n' +
                            '    </ListItem>\n' +
                            '    <ListItem onClick={() => console.log(\'ok\')}>\n' +
                            '        list - item - link\n' +
                            '    </ListItem>\n' +
                            '    <ListItem onClick={() => console.log(\'ok\')} active={true}>\n' +
                            '        list - item - link\n' +
                            '    </ListItem>\n' +
                            '</List>'
                        }
                    </pre>
                    </div>
                </div>

            </WrapContent>
        </>
    )
}
