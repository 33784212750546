import _ from "lodash";
import {PlannedCall, TaskCallCenter} from "../../../../../types/common";
import {PlannedCalls} from "../../../../../types/components";

interface Props {
    detail: PlannedCall | null,
    progressCall: TaskCallCenter[],
    plannedCalls: PlannedCalls | null,
}

const prepareContactPerson = ({detail, progressCall, plannedCalls}: Props) => {
    let contactPerson = null as any;
    if (!!detail) {
        if (!!detail.phoneOwner && !!detail.phoneOwner.id) {
            if (!!progressCall.length && progressCall[0].payload.patients.length > 1) {
                contactPerson = detail.phoneOwner
            }
            if (!contactPerson) {
                if (!!plannedCalls) {
                    const idsPlannedCalls = _.keys(plannedCalls)
                    const detailPhoneOwner = detail.phoneOwner as any
                    if (idsPlannedCalls.some(id => +id !== detailPhoneOwner.id)) {
                        contactPerson = detail.phoneOwner
                    }
                }
            }
        } else {
            contactPerson = 'не указано'
        }
    }
    return contactPerson;
}

export default prepareContactPerson
