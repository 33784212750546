import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth74Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 20 23.95"} width={width || "12"} height={height || "15"} ref={ref}>
            <path d="M9.3,0H9.21C7.92,0,5,2,.36,7.44c-.68.78-.55,5.13,1.69,9.93C3,19.35,5.52,24,9.16,24,15.72,24,19.64,15.77,20,11.19,20.27,7.81,11.4.19,9.31,0V0Z"/>
        </WrapIcon>
    );
});
