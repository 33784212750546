import {useEffect, useState} from 'react';
import {GetDirectionsBalanceResult, IStateCashCreate} from "../types";
import useLoader from "../../../../components/Errors";
import useLoadData from "./useLoadData";
import useSubmit from "./useSubmit";
import usePaymentSum from "./usePaymentSum";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";
import Kkm from "../../../../common/server/kkm";
import {useSelector} from "react-redux";
import {useOptionsSelectExpenditures} from "../../../../common/hooks/useOptionsSelectExpenditures";
import {KkmSetting} from "../../../../types";
import {KkmList} from "../../../../pages/Settings/Kkm/types";

interface Props {
    fixedPatientId?: number
    loadingFromServer?: () => void
    discountTypeOptions: OptionSelect[]
    paymentMethodOptions: OptionSelect[]
    cashboxOptions: OptionSelect[]
    operationOptions: OptionSelect[]
    optionsBranches: OptionSelect[]
}

const useCashCreate = ({fixedPatientId, loadingFromServer, discountTypeOptions, paymentMethodOptions, cashboxOptions, operationOptions, optionsBranches}: Props) => {

    const users = useSelector(state => state.common.users)
    const currentUser = useSelector(state => state.user)

    const [data, setData] = useState<GetDirectionsBalanceResult | null>(null);

    const [kkmError, setKkmError] = useState('')

    const [patientId, setPatientId] = useState<number | undefined>(fixedPatientId)

    const [userId, setUserId] = useState<number | undefined>(undefined)

    const [kkmOptions, setKkmOptions] = useState<OptionSelect[]>([])

    const [kkmSetting, setKkmSetting] = useState<KkmSetting>({
        user: null,
        url: null,
        password: null,
        numberDevice: null,
        cashierName: null,
        cashierINN: null
    })

    // const kkmSetting = useSelector(state => state.common.kkmSetting)

    // useEffect(() => {
    //     const kkm = new Kkm(kkmSetting);
    //     kkm.getList().then((selectList: OptionSelect[]) => {
    //         setKkmOptions(selectList)
    //     })
    // }, [])

    const [state, setState] = useState<IStateCashCreate>({
        type: "P",
        discount: "",
        insuranceSum: "",
        comment: "",
        selectedDiscountType: discountTypeOptions[0],
        selectedPaymentMethod: paymentMethodOptions[0],
        selectedCashbox: cashboxOptions[0],
        selectedExpenditure: undefined,
        selectedOperation: operationOptions[0],
        selectedKkm: kkmOptions.length > 1 ? undefined : kkmOptions[0],
        values: {general: ''},
        branch: optionsBranches.length > 1 ? undefined : optionsBranches[0],
        kkmList: []
    })

    const [loader, setLoader] = useLoader();

    useLoadData({setState, setData, setKkmOptions, patientId, branchId: state.branch?.value})

    useEffect(() => {
        const getCashier = (kkm: KkmList) => {

            if (kkm.cashierId) {
                return {
                    cashierName: users[kkm.cashierId].fio,
                    cashierINN: users[kkm.cashierId].inn
                }
            } else {
                return {
                    cashierName: currentUser.fio,
                    cashierINN: currentUser.inn
                }
            }

        }

        const kkm = state.kkmList.find(kkm => kkm.id == state.selectedKkm?.value)

        console.log(kkm)

        !!kkm && setKkmSetting({
            user: kkm.login,
            url: kkm.url,
            password: kkm.password,
            numberDevice: kkm.numberDevice,
            ...getCashier(kkm)
        })


    }, [state.selectedKkm])

    useEffect(() => {
        if (state.selectedKkm) {
            if (!kkmSetting.cashierINN) {
                setKkmError('Не указан ИНН кассира')
            }
        } else {
            setKkmError('')
        }
    }, [kkmSetting])

    const handleSubmit = useSubmit({
        patientId,
        userId,
        loadingFromServer,
        setLoader,
        setKkmError,
        state,
        data,
        kkmSetting
    })

    const paymentSum = usePaymentSum({values: state.values});

    return {kkmError, data, patientId, setPatientId, userId, setUserId, state, setState, handleSubmit, loader, paymentSum, kkmOptions}

};

export default useCashCreate;
