import {PlannedCall} from "../../../types/common";
import {ParamsMinimizable} from "../../../../../containers/DialogsManager/types/slideModals";

const minimizeLabelModalCalls = (plannedCalls: PlannedCall[],
                                 setMinimizeParams: (params: ParamsMinimizable) => void,
                                 countsCall: number) => {
    if (!!setMinimizeParams) {
        setMinimizeParams({
            icon: 'callCenter',
            title: 'Журнал звонков',
            text: '',
            canBeDeleted: false,
            keySlideModal: 'call-center',
            badgeText: countsCall
        })
    }
}
export default minimizeLabelModalCalls
