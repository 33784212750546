import {OptionSelect} from '../../../../../../components-ui/Inputs/Select/typesSelect';
import {useMemo, useState} from 'react';
import {useModal} from '../../../../../../containers/DialogsManager/containers/Modal/Modal';
import useLoader from '../../../../../../components/Errors';
import ServerCommand from '../../../../../../common/server/server-command';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {notTrashed} from '../../../../../../common/helpers/general-helper';
import {User} from '../../../../../../types';
import {IDoneEmployeeServiceRow} from '../../types';
import {GetDoneServicesResult} from '../../../../types';
import DateHelper from '../../../../../../common/helpers/date-helper';

interface Props {
    services: GetDoneServicesResult['services'];
    service?: IDoneEmployeeServiceRow;
    refreshDataFromServer: () => void;
    selectedBranch: OptionSelect;
}

interface State {
    date: Date;
    service?: OptionSelect;
    employee?: OptionSelect;
    doctor?: OptionSelect;
    quantity: number;
}

const useMarkDoneEmployees = ({services, service, selectedBranch, refreshDataFromServer}: Props) => {

    const users = useSelector(state => state.common.users);

    const {closeModal} = useModal();

    const [loader, setLoader] = useLoader();

    const optionsDoctors = useMemo(() => {
        return _
            .chain(users)
            .filter(notTrashed)
            .filter((user: User) => user.branches.includes(selectedBranch.value) && user.role === 'doctor')
            .map((user: User) => {
                return {value: user.id, label: user.fio, color: user.color};
            })
            .sortBy(user => user.label)
            .value();
    }, [selectedBranch, users]);

    const optionsTechnics = useMemo(() => {
        return _
            .chain(users)
            .filter(notTrashed)
            .filter((user: User) => user.branches.includes(selectedBranch.value) && user.role === 'dental-technician')
            .map((user: User) => {
                return {value: user.id, label: user.fio};
            })
            .sortBy(user => user.label)
            .value();
    }, [selectedBranch, users]);

    const optionsServices = useMemo(() => {
        return services.map(service => ({value: service.id, label: service.name}))
    }, [services])

    const [state, setState] = useState<State>({
        date: service?.date ? new Date(service.date) : new Date(),
        doctor: service?.doctor,
        employee: service?.employee,
        quantity: service?.quantity || 1,
        service: service?.service,
    });

    const handleDate = (val: Date) => {
        setState(prevState => ({...prevState, date: val}))
    }

    const handleEmployee = (value: OptionSelect) => {
        setState(prevState =>
            ({...prevState, employee: value}),
        );
    };

    const handleDoctor = (value: OptionSelect) => {
        setState(prevState =>
            ({...prevState, doctor: value}),
        );
    };

    const handleService = (value: OptionSelect) => {
        setState(prevState =>
            ({...prevState, service: value}),
        );
    };

    const handleQuantity = (value: number) => {
        setState(prevState =>
            ({...prevState, quantity: value}),
        );
    };

    const params = () => (
        service ? {
            date: DateHelper.formatDateForServer(state?.date),
            employeeId: state?.employee?.value,
            doctorId: state?.doctor?.value,
            serviceId: state?.service?.value,
            quantity: state?.quantity,
            id: service.id,
        } : {
            date: DateHelper.formatDateForServer(state?.date),
            employeeId: state?.employee?.value,
            doctorId: state?.doctor?.value,
            serviceId: state?.service?.value,
            quantity: state?.quantity,
            branchId: selectedBranch.value,
        }
    );

    const saveDoneEmployeeService = () => {
        setLoader(true);
        ServerCommand
            .post(`laboratory/done-employee-service/${service ? 'update' : 'add'}`,
                params()).then(() => {
            refreshDataFromServer();
            closeModal();
        });
    };

    return {
        state,
        loader,
        saveDoneEmployeeService,
        handleDate,
        handleEmployee,
        handleDoctor,
        handleService,
        handleQuantity,
        optionsDoctors,
        optionsTechnics,
        optionsServices
    }
};

export default useMarkDoneEmployees;
