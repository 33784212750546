import React from 'react'
import PropTypes from 'prop-types'
import TreatmentPlansConstructor from "../../../../containers/TreatmentPlansConstructor/TreatmentPlansConstructor";
import ServerCommand from "../../../../common/server/server-command";
import StepFooter from "../../components/StepFooter/StepFooter";
import SaveButtonLabel from "../../../../containers/TreatmentPlansConstructor/SaveButtonLabel";

export default class TreatmentPlanStep extends React.Component {

    state = {
        loaderNextBtnRecommended: false,
        loaderNextBtn: false,
        loaderPrint: false,
    }

    static propTypes = {
        visitId: PropTypes.number.isRequired,
        patientId: PropTypes.number.isRequired,
        isPrimaryVisit: PropTypes.bool.isRequired,
        isSocialVisit: PropTypes.bool.isRequired,
        isDoctorPrimaryVisit: PropTypes.bool.isRequired,
        goToNextStep: PropTypes.func.isRequired,
        nextStep: PropTypes.string.isRequired,
        visit: PropTypes.object
    };

    render() {
        return (
            <TreatmentPlansConstructor getLoadCommandData={this.getLoadCommandData}
                                       checkItemsStatuses={!this.props.isPrimaryVisit}
                                       renderFooter={this.renderFooter}
                                       sendPlan={this.sendPlan}
                                       initialPrintPrices={!this.props.isSocialVisit}
                                       currentVisitId={this.props.visitId}
                                       patientId={this.props.patientId}
                                       visit={this.props.visit}
                                       isDoctorPrimaryVisit={this.props.isDoctorPrimaryVisit}
            />
        )
    }

    getLoadCommandData = () => ({visitId: this.props.visitId});

    renderFooter = ({isRecommended, savePlan, switchPlan, validation, attractToQuantityDivide, doctors, canPrint, isPlanChanged}) => {
        const validationSuccess = validation.isSuccess();

        return (
            <StepFooter onPrintButtonClick={() => {
                this.setState({loaderPrint: true})
                savePlan().then(() => {
                    this.setState({loaderPrint: false});
                })
            }}
                        onNextButtonClick={this.handleNextButtonClick.bind(this, switchPlan, isRecommended, savePlan, isPlanChanged)}
                        nextBtnName={this.getNextButtonCaption(isRecommended)}
                        showPrintBtn={!isRecommended}
                        nextBtnActive={validationSuccess && (isRecommended || !canPrint)}
                        printBtnActive={canPrint && validationSuccess}
                        printBtnName="Сохранить и распечатать"
                        printBtnLabel={this.renderPrintButtonLabel(validation, attractToQuantityDivide, isRecommended, doctors, canPrint)}
                        nextBtnLabel={this.renderNextButtonLabel(validation, isRecommended, attractToQuantityDivide, doctors)}
                        showLabel={true}
                        loaderPrint={this.state.loaderPrint}
                        loaderNextBtn={isRecommended ? this.state.loaderNextBtnRecommended :this.state.loaderNextBtn}
            />
        )
    };


    renderPrintButtonLabel(validation, attractToQuantityDivide, isRecommended, doctors, canPrint) {
        return (
            <SaveButtonLabel
                successLabel="Пациенту на подпись, вклеить в карту, отдать пациенту"
                validation={validation}
                attractToQuantityDivide={attractToQuantityDivide}
                isRecommended={isRecommended}
                doctors={doctors}
                canPrint={canPrint}
            />
        );
    }

    renderNextButtonLabel(validation, isRecommended, attractToQuantityDivide, doctors) {
        if (!isRecommended) return '';

        return (
            <SaveButtonLabel
                validation={validation}
                attractToQuantityDivide={attractToQuantityDivide}
                doctors={doctors}
                isRecommended={isRecommended}
            />
        );
    }

    getNextButtonCaption(isRecommended) {
        let caption;

        if (isRecommended) {
            caption = 'К согласованному плану';
        } else {
            caption = (this.props.nextStep === 'CHOICE') ? 'К решению пациента' : 'К лечению';
        }

        return caption;
    }

    handleNextButtonClick = (switchPlan, isRecommended, savePlan, isPlanChanged) => {

        if (isRecommended) {
            /* переход к выполняемому плану */
            this.setState({loaderNextBtnRecommended: true});
            savePlan().then(() => {
                this.setState({loaderNextBtnRecommended: false});
                switchPlan();
            });
        } else {
            this.setState({loaderNextBtn: true})

            // проверяем, был ли изменён согласованный план относительно той версии,
            // которая хранится на сервере
            if (isPlanChanged('performed')) {
                // если план был изменён, то сохраняем план, затем переходим на следующий шаг
                savePlan().then(() => this.setNextStep());
            } else {
                // иначе сразу переходим к следующему шагу
                this.setNextStep();
            }
        }
    };

    setNextStep = () => {
        const command = new ServerCommand('visit/set-next-state', {visitId: this.props.visitId});
        command.exec().then(() => this.props.goToNextStep());
    }

    sendPlan = (planData) => {
        planData.visitId = this.props.visitId;
        const command = new ServerCommand('treatment-plan/save', planData);
        return command.exec();
    };
}

