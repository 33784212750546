import React from 'react';
import PropTypes from "prop-types";
import Button from "../../../components-ui/Inputs/Button/Button";
import KanbanCard from "../KanbanCard";
import CheckListModal from "../Modal/CheckListModal";
import Modal from "../../../containers/DialogsManager/containers/Modal/Modal";

export default class CheckListKanbanCard extends React.Component {
    static propTypes = {
        visit: PropTypes.object.isRequired,
        doctor: PropTypes.object.isRequired,
        isMinimized: PropTypes.bool,
        removeBgColor: PropTypes.func,
        setNextState: PropTypes.func,
        addBgColor: PropTypes.func
    };

    state = {
        openModal: false
    };


    render() {
        return (
            <KanbanCard
                visit={this.props.visit}
                isMinimized={this.props.isMinimized}
                removeBgColor={this.props.removeBgColor}
                doctor={this.props.doctor}
                renderButton={this.renderButton}
                renderModal={this.renderModal}
                addBgColor={this.props.addBgColor}
            />
        );
    }

    renderButton = () => {
        return (
            <Button variant="default" children="Открыть чек-лист" size="sm-standard" onClick={() => this.openModal()}/>
        );
    };

    renderModal = () => {
        return (
            <Modal
                isOpen={this.state.openModal}
                closeModal={this.openModal.bind(this)}
            >
                <CheckListModal
                    closeModal={this.openModal.bind(this)}
                    visit={this.props.visit}
                    addBgColor={this.props.addBgColor}
                    setNextState={this.props.setNextState}
                />
            </Modal>

        )
    };

    openModal = () => {
        const openModal = !this.state.openModal;
        this.setState({openModal});
    }
}
