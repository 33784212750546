import React from 'react';
import {
    tableHandleScroll,
    TableHeader,
} from '../../../../../components-ui/DataDisplay/Table/style/scrollStyledComponents';
import Box from '../../../../../components-ui/Layout/Box';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import {DEFAULT_PADDING_HEADER_CELLS} from '../../../../../components-ui/DataDisplay/Table/common';
import {Variant} from '../../../../../components-ui/DataDisplay/Typography/types';

interface Props {
    columns: {name: string | undefined, width: string}[];
    scrollClassName: string;
    handleScroll: tableHandleScroll;
    textVariant?: Variant
}
//TODO нужно переделать таблицу, этот компонент шаблонный, если не успею развить идею вынести в папку Table
const TableScrollHeaderComponent = ({columns, scrollClassName, handleScroll, textVariant}: Props) => {
    return (
        <TableHeader scrollClassName={scrollClassName} handleScroll={handleScroll}>
            <Box width='max-content'
                 display='flex'
                 height='inherit'
                 justifyContent='space-between'
                 boxSizing='content-box'
                 alignItems='center'>
                {columns.map(col =>
                    <Typography width={col.width} variant={textVariant || undefined} padding={DEFAULT_PADDING_HEADER_CELLS} fontWeight='bold'>
                        {col.name}
                    </Typography>)}
            </Box>
        </TableHeader>
    );
};

export default TableScrollHeaderComponent;
