import React, {useEffect, useMemo, useState} from "react";
import {useEditableField} from "../EditableField";
import SaveButtons from "./SaveButtons";
import ServerCommand from "../../../common/server/server-command";
import {ValueEditableField} from "../typesEditableField";
import Select from "../../../components-ui/Inputs/Select/Select";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";

export default function PhoneOwner() {
    const {field, patientId, setEditField} = useEditableField();
    const phoneOwner = field.values.phoneOwner as ValueEditableField;
    const personalPhone = field.values.personalPhone as ValueEditableField;
    const [selected, setSelected] = useState<null | OptionSelect>(phoneOwner.value);
    const [valid, setValid] = useState<boolean>(!!phoneOwner.validation)

    const noCanceledButton = useMemo(() => {
        return !(!!phoneOwner.value) || phoneOwner.value.value === 'other'
    }, []);

    const onClickSave = () => {
        if (!!selected) {
            const command = new ServerCommand('patient/edit', {
                id: patientId,
                [phoneOwner.name]: selected.value,
                personalPhone: personalPhone.value
            });
            command.exec().then(() => {
                setEditField(false);
                field.onChange(selected);

                if (!!field.setState) {
                    field.setState((prevState: any) => {
                        return {
                            ...prevState,
                            currentPatient: prevState.currentPatient === null ? null : {
                                ...prevState.currentPatient,
                                phoneOwner: selected.value,
                                personalPhone: personalPhone.value
                            }
                        }
                    })
                }
            });
        }
    };

    useEffect(() => {
        setSelected(phoneOwner.value);
    }, [phoneOwner.value])

    useEffect(() => {
        if (phoneOwner.validation) setValid(true);
    }, [phoneOwner.validation])

    return (
        <>
            <Select value={selected}
                    options={!!phoneOwner.variants ? phoneOwner.variants.sort((a: any, b: any) => a.sort > b.sort ? 1 : -1) : []}
                    widthSelect="288px"
                    widthMenu="334px"
                    placeholder="Не выбран"
                    isDisabled={!!phoneOwner.disabled}
                    isError={valid}
                    onChange={(selectedEnum) => {
                        field.onChange(selectedEnum);
                        if (!!phoneOwner.onResetValidation) {
                            setValid(false);
                            phoneOwner.onResetValidation();
                        }
                    }}/>
            {(!!selected && selected.value !== 'other') && <SaveButtons onClickSave={onClickSave} noCanceledButton={noCanceledButton}/>}
        </>
    )
}
