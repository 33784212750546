import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from "../Icons/Icons";
import Helper from "common/helpers/main";

export default class Element extends React.PureComponent {

    static propTypes = {
        element: PropTypes.object,
        onClick: PropTypes.func,
        className: PropTypes.string,
        selectedElementsIds: PropTypes.array,
        isMultipleSelect: PropTypes.bool,
        dblclick: PropTypes.func,
        isFocused: PropTypes.bool
    };

    render() {
        let className = `tree__item-title-group tree__item-title-group--empty`;

        if (this.isSelected()) {
            className += this.props.isFocused ? " tree__item-title-group--focused" : " tree__item-title-group--selected"
        }
        let numbering = null;
        if (this.props.element.numbering !== undefined) numbering = this.props.element.numbering;
        return (
            <li className="tree__item">
                <div
                    className={className}
                    onClick={this.handleClick.bind(this)}
                    onDoubleClick={() => this.props.dblclick(this.props.element)}
                >
                    {!this.props.isMultipleSelect &&
                        <Icon icon="new-element-group"/>
                    }
                    {this.props.isMultipleSelect && this.useSelectMode() &&
                        <div className="table__checkbox">
                            <Icon icon="new-check"/>
                        </div>
                    }
                    <span className="tree__item-title">{numbering} {this.props.element.name}</span>
                </div>
            </li>
        )
    }

    handleClick() {
        let selectedElementsIds = Helper.clone(this.props.selectedElementsIds);

        if(this.useSelectMode()) {
            if (this.props.isMultipleSelect) {
                if (this.isSelected()) {
                    let index = selectedElementsIds.indexOf(this.props.element.id);

                    selectedElementsIds.splice(index, 1);
                } else {
                    selectedElementsIds.push(this.props.element.id);
                }
            } else {
                if (this.isSelected()) {
                    selectedElementsIds = [];
                } else {
                    selectedElementsIds = [this.props.element.id];
                }
            }
        }

        this.props.onClick(this.props.element, selectedElementsIds, true);
    }

    isSelected = () => {
        return this.useSelectMode() && this.props.selectedElementsIds.indexOf(this.props.element.id) > -1
    };

    useSelectMode = () => !!this.props.selectedElementsIds;
}
