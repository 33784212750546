import React from "react";
import {useSelector} from "react-redux";
import useDirectionsColors from "../../../../../../../../common/hooks/useDirectionsColors";
import useTotalSumDirection from "../hooks/useTotalSumDirection";
import useWidthLinePercentage from "../hooks/useWidthLinePercentage";
import {HeaderItem, LineItem, WrapItem} from "../style";
import Typography from "../../../../../../../../components-ui/DataDisplay/Typography/Typography";
import Box from "../../../../../../../../components-ui/Layout/Box";
import Helper from "../../../../../../../../common/helpers/main";
import Percentage from "../../../components/Percentage";
import {PropsDirections} from "../index";
import useUsersColor from "../../../../../../../../common/hooks/useUsersColor";


interface Props extends Omit<PropsDirections, 'sumsCodes'> {
    code: string
    index: number
    allTotalsSum: { [key: string]: number }
}

const Sum = ({code, report, handlers, index, state, allTotalsSum}: Props) => {
    const directions = useSelector(state => state.common.directions)
    const users = useSelector(state => state.common.users)
    const directionsColors = useDirectionsColors()
    const usersColors = useUsersColor()
    const sums = useTotalSumDirection({report, code, state})
    const isNotDoctorsTab = state.currentTab !== 'doctors'
    const active = isNotDoctorsTab && code === state.selectedDirection;
    const widthLinePercentage = useWidthLinePercentage({report, code, allTotalsSum})
    const onClickItem = () => {
        if (state.currentTab !== 'doctors') handlers.handleSelectedDirection(code)
    }

    return (
        <WrapItem onClick={onClickItem}
                  onMouseEnter={() => handlers.handleHoverIndex(index)}
                  active={active}
                  isNotDoctorsTab={isNotDoctorsTab}>
            <HeaderItem>
                <Typography children={isNotDoctorsTab ? directions[code].name : users[+code].fio}
                            variant="small"
                            fontWeight="600"/>
                <Box display="flex">
                    <Typography variant="small"
                                children={Helper.String.numberFormat(sums.totalSum, 0)}
                                margin="0 8px 0 0"
                    />
                    {sums.previousPercentage !== null && (
                        <Percentage percent={sums.previousPercentage}
                                    margin="0 8px 0 0"
                                    data-for={`previousPercentage-${code}`}
                                    data-tip={`Приращение относительно предыдущего аналогичного периода<br/>
(${Helper.Date.formatDate(report.previous.startDate, 'DD MMM YYYY')} - ${Helper.Date.formatDate(report.previous.endDate, 'DD MMM YYYY')})`}
                        />
                    )}
                    {sums.previousYearPercentage !== null && !!report.previousYear && (
                        <Percentage percent={sums.previousYearPercentage}
                                    margin="0 8px 0 0"
                                    data-for={`previousYearPercentage-${code}`}
                                    data-tip={`Приращение относительно аналогичного периода в прошлом году<br/>
(${Helper.Date.formatDate(report.previousYear.startDate, 'DD MMM YYYY')} - ${Helper.Date.formatDate(report.previousYear.endDate, 'DD MMM YYYY')})`}
                        />
                    )}
                </Box>
            </HeaderItem>
            <LineItem active={active} widthLine={widthLinePercentage} colorBg={isNotDoctorsTab ? directionsColors[code] : usersColors[code]}/>
        </WrapItem>
    )
}

export default Sum
