import $ from 'jquery'
import Helper from 'common/helpers/main'
import _ from 'underscore'
import {echo, reloadEcho} from "./echo";
import DateHelper from "../helpers/date-helper";

window.addEventListener('storage', e => {
    if (e.key === 'accessToken') {
        ServerCommand.authLossHandlers.forEach(handler => handler());
    }
});

export default class ServerCommand {
    restApiUrl = Helper.resolveUrl('/api/');
    hasFiles = false;

    static authLossHandlers = [];

    static errorStub = null;

    constructor(code, data = {}, success = null, method = 'POST') {
        this.code = code;
        this.hasFiles = this._checkFiles(data);
        this.data = this._prepareData(data);
        this.success = success;
        this.method = method;
    }

    exec() {
        return new Promise((resolve, reject) => {
            let ajaxParams = {
                url: this.restApiUrl + this.code + '/',
                data: this.data,
                dataType: 'json',
                complete: this._handleResponse.bind(this, resolve, reject),
                method: this.method,
            };

            const accessToken = window.localStorage.getItem('accessToken');
            if (accessToken) {
                ajaxParams.headers = {
                    'Authorization': 'Bearer ' + accessToken,
                    'X-Socket-ID': echo.socketId()
                }
            }

            if (this.hasFiles) {
                ajaxParams.processData = false;
                ajaxParams.contentType = false;
                ajaxParams.type = 'POST';
            }

            $.ajax(ajaxParams);
        });
    }

    _handleResponse(resolve, reject, jqXHR) {
        let response = jqXHR.responseJSON;
        let self = this.constructor;

        if (Helper.isDev() && !!ServerCommand.errorStub) {
            const errorStub = ServerCommand.errorStub;
            ServerCommand.errorStub = null;
            errorStub.type = 'server_request_error';

            reject(errorStub, jqXHR);
        }

        if (jqXHR.status === 200) {
            if (typeof this.success === 'function') {
                this.success(response, this);
            }

            resolve(response);
        } else {
            let error;

            if (response?.error) {
                error = Helper.clone(response);
                error.description = error.error_description;
                delete error.error_description;

                error.code = error.error;
                delete error.error;

                error.status = jqXHR.status;
                error.type = 'server_request_error';

                reject(error, jqXHR);

            } else if (jqXHR.status === 401) {
                ServerCommand.authLossHandlers.forEach(handler => handler());
            } else {
                const description = 'Ошибка запроса к серверу';

                error = {
                    type: 'server_request_error',
                    code: 'request_error',
                    description,
                    hint: '',
                    message: description,
                    status: jqXHR.status
                };

                reject(error, jqXHR);
            }
        }

        if (this.queueCode && self.queues[this.queueCode]) {
            let nextQueueItem = self.queues[this.queueCode].pop();
            nextQueueItem.command._send(nextQueueItem.resolve, nextQueueItem.reject);
        }
    }

    _checkFiles(data) {
        let result = false;

        Helper.forEachObj(data, fieldValue => {

            if (Array.isArray(fieldValue) && this._checkFiles(fieldValue)) {
                result = true;
                return false;
            }

            if (fieldValue instanceof window.File) {
                result = true;
                return false;
            }
        });

        return result;
    }

    _prepareData(data) {
        data = Helper.clone(data);

        let result;
        this._cast(data);

        if (Helper.isDev() && process.env.REACT_APP_TOKEN) {
            data.token = process.env.REACT_APP_TOKEN;
        }

        if (this.hasFiles) {
            result = new window.FormData();

            Helper.forEachObj(data, (fieldValue, fieldKey) => {
                if(Array.isArray(fieldValue)) {
                    fieldKey += '[]';

                    fieldValue.forEach(item => {
                        result.append(fieldKey, item);
                    })
                } else {
                    result.append(fieldKey, fieldValue);
                }
            });
        } else {
            result = data;
        }

        return result;
    }

    _cast(data) {
        _.each(data, (value, key) => {
            switch (typeof value) {
                case 'boolean':
                    value = Number(value);
                    break;

                case 'object':
                    if (Array.isArray(value) && value.length === 0) {
                        value = '';
                    } else if (value instanceof window.Date) {
                        value = DateHelper.formatDateTimeForServer(value);
                    } else if (!(value instanceof window.File)) {
                        value = this._cast(value);
                    }
                    break;

                default:
                    break;
            }

            data[key] = value;
        })

        return data;
    }

    static run(commandCode, data, method = 'POST') {
        const command = new ServerCommand(commandCode, data, null, method);
        return command.exec();
    }

    static get(resourceCode, data, successHandler = null) {
        const command = new ServerCommand(resourceCode, data, successHandler, 'GET');
        return command.exec();
    }

    static post(resourceCode, data, successHandler = null) {
        const command = new ServerCommand(resourceCode, data, successHandler, 'POST');
        return command.exec();
    }

    static async auth(login, password) {
        const data = {
            grant_type: 'password',
            client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
            username: login,
            password,
            scope: '*'
        };

        const {access_token} = await ServerCommand.run('oauth/token', data);
        window.localStorage.setItem('accessToken', access_token);

        reloadEcho();
    }

    static logout() {
        ServerCommand.post('auth/logout');
        window.localStorage.removeItem('accessToken');

        if (echo !== null) {
            echo.disconnect();
        }
    }

    static isAuthorized() {
        return !!window.localStorage.getItem('accessToken');
    }

    static onAuthLoss(handler) {
        ServerCommand.authLossHandlers.push(handler);
    }

    static offAuthLoss(handler) {
        ServerCommand.authLossHandlers = ServerCommand.authLossHandlers.filter(authLossHandler => authLossHandler !== handler);
    }

    static getAccessToken() {
        return window.localStorage.getItem('accessToken');
    }
}
