import * as React from "react";
import {PServicesListTitle} from "../types";
import {Box, BoxBody} from "../../../../components-ui/styledComponent/common/wraps";
import styled from "styled-components";

const WrapServicesListTitle = styled.div.attrs({className: 'WrapServicesListTitle'})`
    margin: 24px 0 30px;
`

export default function ServicesListTitle({servicesListTitle, handleServicesHidden}: PServicesListTitle) {
    if (servicesListTitle) {
        return (
            <WrapServicesListTitle>
                <Box>
                    <BoxBody nonBorderBottom>
                    <span className="box__link box__link--highlighted"
                          onClick={handleServicesHidden}>
                            {servicesListTitle}
                    </span>
                    </BoxBody>
                </Box>
            </WrapServicesListTitle>
        )
    } else {
        return null
    }
}
