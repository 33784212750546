import styled from "styled-components";


export const WrapTooltip = styled.div`
    background-color: ${props => props.theme.colors.text};
    border-radius: 2px;
    z-index: 100;
`

interface PropsHeaderTooltip {
    isDataTooltip?: boolean
}

export const HeaderTooltip = styled.div<PropsHeaderTooltip>`
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    ${props => !props.isDataTooltip && `
        border-bottom: 1px solid #1b1c1d;
    `}
    ${props => props.isDataTooltip && `
        border-radius: 2px;
    `}
`

export const ListTooltip = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px 12px 11px;
`

interface PropsItemTooltip {
    padding?: string
}

export const ItemTooltip = styled.div<PropsItemTooltip>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.padding || '2px 0'};
`


