import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth52Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 18.25 24"} width={width || "14"} height={height || "19"} ref={ref}>
            <path d="M18.1,20.67a3.51,3.51,0,0,1-1.33,2.47c-1.71,1.08-6.72.88-9.19.78H6.74C2.42,23.78.51,23.24.23,18.61a32,32,0,0,1,1-12.42C2.26,2.86,4.61.52,7.32.08A5.35,5.35,0,0,1,8.21,0c2.09,0,4.09,1.18,5.71,3.42C15.86,6,19,15.73,18.09,20.68h0Z"/>
        </WrapIcon>
    );
});
