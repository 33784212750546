import ServerCommand from "../../../common/server/server-command";
import prepareDataExistingPatient
    from "../../../containers/PatientField/helper/prepareDataExistingPatient";
import preparePersonalGender
    from "../../../containers/PatientField/helper/preparePersonalGender";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import {PropsUseLoadingPatient} from "../types/hooks";
import {PatientValues, ResponsePatientList} from "../../../containers/PatientField/types/common";
import usePrepareRelations from "../../../containers/PatientField/hooks/usePrepareRelations";
import useLoader from "../../../components/Errors";

export default function useLoadingPatient({setForm, fields}: PropsUseLoadingPatient) {
    const [loaderPatient, setLoaderPatient] = useLoader();
    const prepareRelations = usePrepareRelations()

    const loadingPatient = (patient: PatientValues): void => {
        setLoaderPatient(true);
        ServerCommand.get('patient/list', {
            filter: !patient.personalPhone ? {id: patient.id} : {personalPhone: patient.personalPhone},
            select: ['personalPhone', 'name', 'lastName', 'secondName', 'id', 'personalBirthday', 'personalGender', 'source', 'phoneOwner', 'hasMissingTeeth'],
            getRelationsToPatient: patient.id,
            unsetFieldsToActualize: true
        }).then((response: ResponsePatientList) => {
            setForm(prevState => {
                const {
                    filterPatientList,
                    optionsPhoneOwner,
                    currentPatient,
                    phoneOwner
                } = prepareDataExistingPatient(response.list, patient)
                let source = null as null | OptionSelect;
                if (!!currentPatient.source) {
                    const variantsSource = fields.source.variants as OptionSelect[];
                    source = variantsSource.find(variant => variant.value === currentPatient.source) as OptionSelect;
                }
                if (currentPatient.hasMissingTeeth === null) currentPatient.visibleHasMissingTeeth = true;
                let otherPatient = prevState.otherPatient;
                if (!!currentPatient.phoneOwner) {
                    if (!!phoneOwner && phoneOwner.value === 'other') otherPatient.isProvidedData = false;
                }
                const {relationsPatients, relationsValidate} = prepareRelations(filterPatientList)
                const statePatient = {
                    lastName: !!currentPatient.lastName ? currentPatient.lastName : '',
                    name: !!currentPatient.name ? currentPatient.name : '',
                    secondName: !!currentPatient.secondName ? currentPatient.secondName : '',
                    personalBirthday: !!currentPatient.personalBirthday ? currentPatient.personalBirthday : '',
                    personalGender: preparePersonalGender(currentPatient, fields),
                    personalPhone: !!currentPatient.personalPhone ? currentPatient.personalPhone : '',
                    phoneOwner,
                    source,
                    hasMissingTeeth: currentPatient.hasMissingTeeth
                } as any
                return {
                    ...prevState,
                    basic: {
                        ...prevState.basic,
                        ...statePatient,
                        optionsPhoneOwner
                    },
                    relations: {
                        ...prevState.relations,
                        relations: relationsPatients,
                        relationsList: filterPatientList,
                        validationFields: relationsValidate
                    },
                    otherPatient: {
                      ...prevState.otherPatient,
                      ...otherPatient
                    },
                    currentPatient: {
                        id: currentPatient.id,
                        ...statePatient
                    }
                }
            })
            setLoaderPatient(false);
        });
    };

    return {
        loaderPatient,
        loadingPatient
    }
}
