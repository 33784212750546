import React, {useContext, useState} from "react";
import HeaderModal from "../../../../containers/DialogsManager/containers/Modal/components/HeaderModal";
import Box from "../../../../components-ui/Layout/Box";
import {tabsNameList} from "./helper/tabsNameList";
import {ContextWAP, PropsWAP} from "./types/types";
import Loader from "../../../../components-ui/Feedback/Loader/Loader";
import TabsWAP from "./components/TabsWAP";
import BasicFormWAP from "./components/BasicFormWAP";
import FooterWAP from "./components/FooterWAP";
import FormWAP from "./containers/FormWAP/FormWAP";
import ResetFormWAP from "./components/ResetFormWAP";
import useInitial from "./hooks/useInitial";
import useChangeActiveTab from "./hooks/useChangeActiveTab";
import useForm from "./hooks/useForm";
import useLoadingPatient from "./hooks/useLoadingPatient";
import useChangeDoctor from "./hooks/useChangeDoctor";
import useChangeWantThisDoctor from "./hooks/useChangeWantThisDoctor";
import useDuplicate from "../../../../containers/PatientField/hooks/useDuplicate";
import {useFields} from "../../../../containers/PatientField";

const WAPContext = React.createContext({} as ContextWAP);
export const useWAP = () => useContext(WAPContext)

export default function WaitingAndPlanned(props: PropsWAP) {
    const fields = useFields({});
    const [activeTab, setActiveTab] = useState(props.isPlanTab ? tabsNameList[1].code : tabsNameList[0].code)
    const {
        form,
        setForm,
        resetForm,
        onChangeFieldBasic,
        onChangeValidationBasic,
        onChangeField,
        onChangeValidationField
    } = useForm({fields})
    const {loadingPatient, loaderPatient} = useLoadingPatient({setForm, fields})
    useInitial({props, loadingPatient, setForm})
    useChangeDoctor({setForm, form})
    useChangeWantThisDoctor({setForm, form})
    useChangeActiveTab({activeTab, setForm})
    const {patientDuplicate, setPatientDuplicate, onClickPatientDuplicate} = useDuplicate({
        form,
        loadingPatient,
        fields
    })

    if (!!form.directionsNames.length) {
        return (
            <WAPContext.Provider value={{
                props,
                form,
                setForm,
                activeTab,
                setActiveTab,
                loadingPatient,
                resetForm,
                onChangeFieldBasic,
                onChangeField,
                onChangeValidationField,
                onChangeValidationBasic,
                patientDuplicate,
                setPatientDuplicate,
                onClickPatientDuplicate,
                fields
            }}>
                <Box boxShadow="defaultModal">
                    <Loader visible={loaderPatient}/>
                    <HeaderModal children="Добавление пациента в:"/>
                    <Box backgroundColor="bgGray" width="780px" padding="55px 24px 44px" borderRadius="0 0 4px 4px">
                        <Box border="borderGray" borderRadius="4px" backgroundColor="white" padding="52px 48px 20px"
                               position="relative">
                            <TabsWAP/>
                            <BasicFormWAP/>
                            <FormWAP/>
                            <ResetFormWAP/>
                        </Box>
                        <FooterWAP/>
                    </Box>
                </Box>
            </WAPContext.Provider>
        )
    } else {
        return (
            <Box position="relative" width="40px">
                <Loader visible={true}/>
            </Box>
        )
    }
}
