import {Dispatch, SetStateAction} from "react";
import {StateCallsEfficiency, CurrentTabEfficiency} from "../types";
import {OptionSelect} from "../../../../../../components-ui/Inputs/Select/typesSelect";

interface Props {
    setState: Dispatch<SetStateAction<StateCallsEfficiency>>
}

const useHandlersReceipts = ({setState}: Props) => {

    return {
        handleCurrentTab: (currentTab: CurrentTabEfficiency) => setState(prevState => ({...prevState, currentTab})),
        handleSelected: (currentSelected: OptionSelect) => setState(prevState => ({...prevState, currentSelected})),
    }
}

export default useHandlersReceipts
