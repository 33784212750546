import React, {useState} from 'react';
import Helper from "../../../common/helpers/main";
import ContentEditable from "../../../components-ui/Inputs/ContentEditable/ContentEditable";
import TextArea from "../../../components-ui/Inputs/TextArea";
import {getFieldsValues, sortItemsFields, sortTemplateText} from "../helperCaseHistory";
import ButtonTargetsHistory from "./ButtonTargetsHistory";
import {PFields, ResponseItemsFields} from "../typesCaseHistory";
import _ from 'underscore';
import {PromptField, WrapperField} from "../styledCaseHistory";

const Fields = (props: PFields | any) => {

    let {visitTargetSelected, visitTarget, itemsFields} = props;
    const typeToothSelected = (visitTargetSelected.length > 0) ? 'visit' : 'plan';
    const {toothFieldsValues, selectedItems, toothTargetsHistory} = getFieldsValues(props);

    itemsFields = sortItemsFields(Object.values(Helper.clone(itemsFields)));

    return (
        <div className={`wrap-half ${(visitTarget.length > 1) ? 'form-group' : ''}`}>
            {itemsFields.map((field: ResponseItemsFields, idx: number) => {
                return (<Field field={field}
                               props={props}
                               typeToothSelected={typeToothSelected}
                               toothFieldsValues={toothFieldsValues}
                               idx={idx}
                               selectedItems={selectedItems}
                               toothTargetsHistory={toothTargetsHistory}
                               key={props[typeToothSelected + 'TargetSelected'] + '_' + field.name + '_wrap'}
                />)
            })}
        </div>
    )
};

const Field = ({field, props, typeToothSelected, toothFieldsValues, idx, selectedItems, toothTargetsHistory}: any) => {
    const [hover, setHover] = useState(false);
    let {
        fieldName, fieldPopupList, editMode, onClickField, templatesList: propsTemplateList, onClickDiagnosis,
        deleteDiagnosis, handleChange, resetFieldPopupList, groupsIdDiagnosis, onClickParameters
    } = props;

    const fieldRequired = (field.required) ? <span className='form-group__field-required'
                                                   key={props[typeToothSelected + 'TargetSelected'] + '_' + field.name}>*</span> : '';

    let value = (!!toothFieldsValues[field.name] ? toothFieldsValues[field.name].text : '');
    const isPromptDisabled = !!toothFieldsValues[field.name] ? toothFieldsValues[field.name].disabled : false;
    const textAreaProps = {
        title: [field.title, fieldRequired],
        value,
        groupsIdDiagnosis,
        onClickParameters,
        key: props[typeToothSelected + 'TargetSelected'] + '_' + field.name,
        activeClass: editMode ? '' : (fieldName === field.name) ? 'active' : '',
        isError: field.isError,
        disabled: editMode || isPromptDisabled,
        autoFocus: idx === 0,


        onClick: () => onClickField(field.name),
        fieldName: field.name
    };

    let content = null;

    if (field.name === 'diagnosis') {

        const templatesList = Helper.clone(propsTemplateList);
        let diagnosisList = templatesList.filter((item: any) => item.field === 'diagnosis');
        diagnosisList.sort(sortTemplateText);

        content = <ContentEditable popupList={fieldPopupList}
                                   onClickPopupItem={onClickDiagnosis}
                                   selectedItems={selectedItems}
                                   deleteItem={deleteDiagnosis}
                                   onChange={(e: any) => handleChange(e, field.name, diagnosisList)}
                                   resetPopupList={resetFieldPopupList}
                                   {...textAreaProps}

        />
    } else {
        content = <TextArea initialOpened={field.required}
                            onChange={(e: any) => handleChange(e, field.name)}
                            {...textAreaProps}
        />
    }

    let contentTargetsHistory = null;

    if (toothTargetsHistory !== undefined) {
        if (toothTargetsHistory[field.name] !== undefined) {
            if (toothTargetsHistory[field.name].length > 0) {
                contentTargetsHistory = <ButtonTargetsHistory dataButton={toothTargetsHistory[field.name]}/>
            }
        }
    }

    return (
        <WrapperField isMargin={contentTargetsHistory === null} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {content}
            {contentTargetsHistory}
            {hover && (!!toothFieldsValues[field.name] ? toothFieldsValues[field.name].disabled : false) && <PromptField
                children="Текст этого поля отличается для выбранных зубов. Изменить текст можно для каждого зуба отдельно"/>}
        </WrapperField>
    )
};

export default Fields;
