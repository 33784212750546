import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const FullScreenIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M16,2V0h8V8H22V2ZM8,2H2V8H0V0H8Zm8,22V22h6V16h2v8ZM8,24H0V16H2v6H8Z"/>
        </WrapIcon>
    );
});
