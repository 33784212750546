import useLoader from '../../../components/Errors';
import React, {useCallback, useEffect, useState} from 'react';
import ServerCommand from '../../../common/server/server-command';
import {GetLaboratoryPricelistResult} from '../types';
import {TableWidthGroupTreeCell} from '../../../components/TableWithGroupsTree/types';
import Box from '../../../components-ui/Layout/Box';
import ButtonForOptions from '../../../components-ui/Inputs/ButtonForOptions/ButtonForOptions';
import ChangeLaboratoryService from '../components/modals/ChangeLaboratoryService';
import Typography from '../../../components-ui/DataDisplay/Typography/Typography';
import PriceHelper from '../../../common/helpers/price-helper';
import DateHelper from '../../../common/helpers/date-helper';
import {useDialogsManager} from '../../../containers/DialogsManager/DialogsManager';

const useLaboratoryPricelistPage = () => {

    const {showModal} = useDialogsManager();

    const [loaderPage, setLoaderPage] = useLoader(true);

    const [laboratoryPricesResponse, setLaboratoryPricesResponse] = useState<GetLaboratoryPricelistResult>();

    const methods = {
        addItem: 'laboratory/service/create',
        addGroup: 'laboratory/service/create-group',
        updateItem: 'laboratory/service/update',
        updateGroup: 'laboratory/service/update-group',
        deleteItem: 'laboratory/service/delete',
        deleteGroup: 'laboratory/service/delete-group',
    };

    const columns: {code: string; title: string; className?: string; searchable: boolean}[] =
        laboratoryPricesResponse?.laboratories.map(lab => (
            {
                title: lab.name,
                //нужно для добавления суффикса в className
                code: `lab_${lab.id}`,
                className: 'lab',
                searchable: false,
            }
        )) || [];

    columns.unshift({
        title: 'Услуга',
        code: 'name',
        searchable: true,
    });

    useEffect(() => {
        ServerCommand
            .get('laboratory/service/price-list')
            .then((response: GetLaboratoryPricelistResult) => {
                setLaboratoryPricesResponse(response);
                setLoaderPage(false);
            });
    }, []);

    const refreshDataFromServer = useCallback(() => {
        return ServerCommand
            .get('laboratory/service/price-list')
            .then((response: GetLaboratoryPricelistResult) => {
                setLaboratoryPricesResponse(response);
                setLoaderPage(false);
            });
    }, []);

    const servicesCases = {
        nominative: 'услуга',
        genitive: 'услуги',
        accusative: 'услугу',
        newNominative: 'новая',
        newGenitive: 'новой',
    };

    const prepareDataItem = ({item: service}: {item: TableWidthGroupTreeCell}) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        laboratoryPricesResponse?.laboratories.forEach(lab => {

            let serviceRate = laboratoryPricesResponse?.servicePrices[service.id]?.[lab.id];

            service['lab_' + lab.id] = <Box display='flex' margin='0 0 0 -30px' gap='10px'>
                <ButtonForOptions options={[{
                    value: service.name + lab.id,
                    label: 'Изменить',
                    onClick: () => showModal(
                        <ChangeLaboratoryService service={service}
                                                 lab={lab}
                                                 price={String(serviceRate?.actual?.price ?? 0)}
                                                 refreshDataFromServer={refreshDataFromServer}
                        />,
                    ),
                }]} position='left-bottom' />
                <Typography>
                    {PriceHelper.format(serviceRate?.actual?.price ?? 0)}
                </Typography>
                {serviceRate?.future && <Typography variant='min' fontStyle='italic' lineHeight='1.8'>
                    {
                        `(${PriceHelper.format(serviceRate?.future?.price)} c
                                        ${DateHelper.formatDate(
                            serviceRate?.future?.activeFrom,
                            'DD MMM YYYY').slice(2)})`
                    }
                </Typography>}
            </Box>;
        });
    };

    const itemFields = [laboratoryPricesResponse?.serviceFields.name, laboratoryPricesResponse?.serviceFields.direction];


    return {
        loaderPage,
        laboratoryPricesResponse,
        columns,
        methods,
        refreshDataFromServer,
        servicesCases,
        prepareDataItem,
        itemFields,
    };

};

export default useLaboratoryPricelistPage;
