import {Dispatch, SetStateAction, useEffect} from "react";
import ServerCommand from "../../../../common/server/server-command";
import {
    IStateCashClose,
    GetCashCloseResult,
    GetCashCloseParams,
    IMethodCloseList
} from "../types";
import moment from "moment";
import Helper from "../../../../common/helpers/main";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";
import {ResponseKkmList} from "../../../../pages/Settings/Kkm/types";
import {GetCashSumParams, GetCashSumResult} from "../../CashReturn/types";

interface Props {
    setState: Dispatch<SetStateAction<IStateCashClose>>
    setKkmOptions: Dispatch<SetStateAction<OptionSelect[]>>
    branchId: number | undefined
}

const useLoadData = ({setState, setKkmOptions, branchId}: Props) => {

    useEffect(() => {
        if (!!branchId) {
            ServerCommand.get('cash/sum', {branchId: branchId} as GetCashSumParams).then((response: GetCashSumResult) => {

                setState(prevState => ({
                    ...prevState,
                    cashboxSum: response,
                }))
            });
        }
    }, [branchId])

    useEffect(() => {
        if (!!branchId) {
            ServerCommand.get('kkm/list', {
                filter: {
                    branchId: branchId
                }
            }).then((response: ResponseKkmList) => {

                setState(prevState => ({
                    ...prevState,
                    kkmList: response.list,
                    selectedKkm: undefined
                }))
                setKkmOptions(response.list.map((kkm) => {
                    return {
                        value: kkm.id,
                        label: kkm.name
                    }
                }))

            });
        }
    }, [branchId])


};

export default useLoadData;
