import styled from 'styled-components';

interface PropsSButtonForOptions {
    opened: boolean
}

export const SButtonForOptions = styled.div<PropsSButtonForOptions> `
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${props => props.opened ? props.theme.colors.secondary : 'transparent'};
  transition: 0.3s;
  &:hover {
    background: ${props => props.theme.colors.secondary};
  }
`
