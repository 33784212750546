import {useMemo} from "react";
import _ from "lodash";
import {
    defaultOptionsPhoneOwner,
    usePrepareStateForm,
    usePrepareValidationFields,
    StateBasicForm
} from "../../../containers/PatientField";
import {basicFields, otherPatientFields} from "../helper/basicFields";
import {StateForm, StateOtherPatient, StateRelations} from "../types/form";
import {PropsUseInitialStateForm} from "../types/hooks";

export default function useInitialStateForm({fields}: PropsUseInitialStateForm) {
    const prepareStateForm = usePrepareStateForm({fields})
    const prepareValidationFields = usePrepareValidationFields({fields})

    return useMemo(() => {
        const basic = {
            ...prepareStateForm(basicFields),
            optionsPhoneOwner: _.cloneDeep(defaultOptionsPhoneOwner),
            validationFields: {
                ...prepareValidationFields(basicFields)
            }
        } as StateBasicForm

        const relations = {
            relations: {},
            relationsList: [],
            validationFields: {}
        } as StateRelations

        const otherPatient = {
            isProvidedData: true,
            ...prepareStateForm(otherPatientFields),
            validationFields: {
                ...prepareValidationFields(otherPatientFields)
            }
        } as StateOtherPatient

        return {
            basic,
            relations,
            otherPatient,
            isSocial: false,
            comment: '',
            currentPatient: undefined
        } as StateForm
    }, [])
}
