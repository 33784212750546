import {useSelector} from "react-redux";
import {useMemo} from "react";

export const defaultColor = '#447ce6';

const colors = ['#447ce6', '#fdbc00', '#aa70cb', '#2d445f', '#e063e3', '#85d415', '#8f4550', '#c3795d']

export const clinicColor = '#00d6d6'


const useDirectionsColors = (isClinicColor = false) => {
    const directions = useSelector(state => state.common.directions);
    const directionsCodes = Object.keys(directions)
    return useMemo(() => {
        const directionsColors: { [key: string]: string } = {};
        if (isClinicColor) directionsColors.clinic = clinicColor;

        directionsCodes
            .sort((a, b) => directions[a].sort - directions[b].sort)
            .forEach((directionCode, index) => {
                directionsColors[directionCode] = colors[index % colors.length]
            })

        return directionsColors;
    }, [])
}

export default useDirectionsColors
