import React, {useRef} from 'react';
import Button from "../../../components-ui/Inputs/Button/Button";
import ServerCommand from "../../../common/server/server-command";
import {PropsUpdateComment} from "./types";
import Loader from "../../../components-ui/Feedback/Loader/Loader";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import Box from "../../../components-ui/Layout/Box";
import {useModal} from "../../../containers/DialogsManager/containers/Modal/Modal";
import useUpdateComment from "./hooks/useUpdateComment";
import {formatFioAndCardNumber} from "../../../common/helpers/Patient";
import useLoader from "../../../components/Errors";

export default function UpdateComment({visitId}: PropsUpdateComment) {
    const ref = useRef(null) as any
    const {closeModal} = useModal();
    const [state, setState] = useUpdateComment(visitId)
    const [loader, setLoader] = useLoader()

    const onClickComment = (e: any) => {
        if (state.comment.length === 0) {
            if (!!ref.current._ref) ref.current._ref.focus()
            return false
        }
        setLoader(true)
        const command = new ServerCommand('visit/update-comment', {
            visitId,
            comment: state.comment
        });
        command.exec().then(() => {
            closeModal();
            setLoader(false)
        });
    }

    if (!!state.patient) {
        return (
            <Box width="560px" padding="45px 30px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Typography variant="bigger-title" textAlign="center" margin="0 0 17px" children="Комментарий к приему"/>
                <Typography textAlign="center" margin="0 0 34px"
                            children={formatFioAndCardNumber(state.patient)}/>
                <TextField onChange={value => setState(prevState => ({...prevState, comment: value}))}
                           value={state.comment}
                           autoFocus={state.isFocusPrompt}
                           isTextArea={true}
                           widthInput="400px"
                           ref={ref}
                />
                <Button variant="danger"
                        children='Добавить комментарий'
                        margin="23px 0 0"
                        loader={loader}
                        onClick={onClickComment}/>
            </Box>
        )
    } else {
        return <Loader visible={true} />
    }

}

