import {useCallback, useEffect, useRef, useState} from 'react';
import ServerCommand from '../../../../common/server/server-command';
import {EmployeesFilter, StateKkmList, KkmList} from '../types';
import {pageSize} from '../../../PatientsList/data';
import {useLazyLoading} from '../../../../common/hooks/useLazyLoading';

const useSettingsKkm = () => {

    const refScrollList = useRef(null) as any

    const [state, setState] = useState<StateKkmList>({
        kkmList: null,
    })
    const loadData = (nextPage: boolean = false) => {
        ServerCommand.get("kkm/list").then((response) => {
            setState({kkmList: response.list})
        });
    };

    const refreshDataFromServer = useCallback(() => loadData(), [])

    useEffect(() => loadData(), [])

    return {
        state,
        setState,
        refreshDataFromServer
    }
};

export default useSettingsKkm;
