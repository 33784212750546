import styled from "styled-components";
import {ITheme} from "../../common/Theme/Theme";

export const HeaderWrap = styled.div`
  position: relative;
  z-index: 13;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 1px 1px 0 #dfe2ea;
  align-items: center;
  min-width: 100%;
`;

export const Controls = styled.div`
    display: flex;
    align-items: center;
    width: 70%;
`;

export const HeaderSearch = styled.div<any>`
    width: 60%;
    position: relative;
    margin-right: 20px;
    input {
        padding: 11px 0 13px 40px;
    }
    .info-box__group-scrollbar {
        min-width: 100%;
        top: 60px;
        z-index: 1000;
    }
`;

export const Clock = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: ${props => props.theme.colors.text};
    line-height: 25px;
    width: 200px;
    margin-right: 50px;
`;

interface IHeaderUser {
    active?: boolean,
    ref?: any
}

export const HeaderUser = styled.div.attrs({className: 'header-user'})<IHeaderUser>`
    position: relative;
    display: flex;
    align-items: center;
    padding: 18px 20px;
    cursor: pointer;
    background: ${props => props.active ? props.theme.colors.secondary : 'transparent'};
    &:hover {
        background: ${props => props.theme.colors.secondary};
    }
`;


export const UserData = styled.div.attrs({className: 'user-data'})`
    display: flex;
    align-items: center;
    text-align: left;
    margin-left: 28px;
    color: ${props => props.theme.colors.textDarkGray};
    margin-right: 10px;
`;

export const UserInfo = styled.div.attrs({className: 'user-info'})`
    white-space: nowrap;
    margin-right: 40px;
`;

export const UserName = styled.div.attrs({className: 'userName'})`
    line-height: 1.5;
    font-size: 16px;
`;

export const UserRole = styled.div.attrs({className: 'UserRole'})`
    line-height: 1.71;
    font-size: 14px;
    color: ${props => props.theme.colors.textGray};
`;

interface IUserArrow {
    active?: boolean
    theme: ITheme
}

export const UserArrow = styled.div.attrs({className: 'UserArrow'})<IUserArrow>`
    padding: 15px;
    & > div {
        width: 12px;
        height: 8px;
        fill: ${props => props.theme.colors.textDarkGray};
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            transform: rotate(${props => props.active ? '-180deg' : '0'});
            transition: .15s;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`;
