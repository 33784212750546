import React from "react";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import Box from "../../../../../components-ui/Layout/Box";
import {useWAP} from "../WaitingAndPlanned";
import ValidationLinkInfo from "../../../../../components/ValidationLinkInfo/ValidationLinkInfo";
import {useModal} from "../../../../../containers/DialogsManager/containers/Modal/Modal";
import useSubmit from "../hooks/useSubmit";
import useValidationOfFields from "../hooks/useValidationOfFields";
import useNewValidateForm from "../../../../../containers/PatientField/hooks/useNewValidateForm";

export default function FooterWAP() {
    const {closeModal} = useModal();
    const {loaderSubmit, handleSubmit} = useSubmit()
    const {form, activeTab, setForm, patientDuplicate, onClickPatientDuplicate, fields} = useWAP();
    const validate = useValidationOfFields();
    const prepareNewValidationFields = useNewValidateForm({fields})
    const {date, currentPatient, directions, wantThisDoctor} = form;

    const onClickIsValidate = () => {
        setForm(prevState => {
            prevState.basic.validationFields = prepareNewValidationFields(prevState.basic)
            prevState.validationFields.date = activeTab === 'scheduled-appointments' && date === null;
            prevState.validationFields.directions = !wantThisDoctor && (activeTab === 'waiting-list' && (directions === null || directions.length === 0))
            return {...prevState}
        })
    }
    return (
        <Box display="flex" alignItems="center" margin="24px 0 0">
            <Button children="Отменить" flexShrink="0" variant="default" width="146px" onClick={() => closeModal()}/>
            <Button children="Добавить" flexShrink="0" variant={validate ? 'success' : 'disabled'}
                    loader={loaderSubmit}
                    width="146px" margin="0 10px 0 16px"
                    onClick={handleSubmit}/>
            <ValidationLinkInfo validate={patientDuplicate === null} onClickIsValidate={onClickPatientDuplicate}
                                text="Введённые данные пациента уже есть в базе. Исправьте данные, либо"
                                linkText="выберите пациента из базы"/>
            {patientDuplicate === null && (
                <ValidationLinkInfo validate={validate} onClickIsValidate={onClickIsValidate}
                                    currentPatient={currentPatient}/>
            )}
        </Box>
    )
}
