import React, {useState} from 'react';
import {GetPatientPaymentsResult} from "../types";
import useTableFormatter from "../hooks/useTableFormatter";
import Table from "../../../../../components-ui/DataDisplay/Table";
import Box from '../../../../../components-ui/Layout/Box';
import Paper from '../../../../../containers/UI-Collection/Elements/Surfaces/Paper';
import Tabs from "../../../../../components-ui/Navigation/Tabs/Tabs";

interface Props {
    data: GetPatientPaymentsResult
}

const PaymentsTable = ({data}: Props) => {

    let tabs = []
    if (data.payments.length > 0) {
        tabs.push({
            name: 'Оплаты',
            code: 'payments',
        });
    }
    if (data.paymentImports.length > 0) {
        tabs.push({
            name: 'Импортированные оплаты',
            code: 'paymentImports',
        });
    }

    const [activeTable, setActiveTable] = useState(data.payments.length > 0 ? 'payments' : 'paymentImports');

    const {paymentColumns, paymentRows, paymentImportColumns, paymentImportRows} = useTableFormatter({data})

    return (
        <Box>
            <Tabs tabs={tabs}
                  activeTab={activeTable}
                  onChange={setActiveTable}
            />
            <Paper padding="30px 27px">
                <Table variantTable="outline"
                       columns={activeTable == 'payments' ? paymentColumns : paymentImportColumns}
                       rows={activeTable == 'payments' ? paymentRows : paymentImportRows} isHovering={false}
                />
            </Paper>
        </Box>
    );
};

export default PaymentsTable;
