import {useMemo} from "react";
import {ReceiptsReport} from "../../../../../../types";
import {StateReceipts} from "../../../types";

interface Props {
    report: ReceiptsReport
    code: string
    state: StateReceipts
}

const useTotalSumDirection = ({report, code, state}: Props) => {

    return useMemo(() => {
        const tabIsDoctors = state.currentTab === 'doctors'
        let totalSum = 0;
        if (tabIsDoctors && !!state.selectedDirection) {
            const directionDoctors = state.selectedDirection === 'all' ? report.current.general.doctors : report.current.directions[state.selectedDirection].doctors
            totalSum = directionDoctors[+code].sum
        } else {
            totalSum = report.current.directions[code].sum
        }
        let previousPercentage: null | number = null;
        let previousYearPercentage: null | number = null;
        const previousSum = (prevSum: number) => {
            return Math.round(100 * (totalSum - prevSum) / prevSum)
        }
        if (tabIsDoctors && !!state.selectedDirection) {
            let previousDirectionDoctors;

            if (state.selectedDirection === 'all' && !!report.previous.data.general) {
                previousDirectionDoctors = report.previous.data.general.doctors
            } else if (state.selectedDirection !== 'all') {
                previousDirectionDoctors = report.previous.data.directions[state.selectedDirection]?.doctors
            }

            if (previousDirectionDoctors && previousDirectionDoctors[+code]) {
                previousPercentage = previousSum(previousDirectionDoctors[+code].sum)
            }
            if (!!report.previousYear) {
                const previousYearDirectionDoctors = state.selectedDirection === 'all'
                    ? report.previousYear.data.general.doctors
                    : !!report.previousYear.data.directions[state.selectedDirection]
                        ? report.previousYear.data.directions[state.selectedDirection].doctors
                        : null;
                if (!!previousYearDirectionDoctors && !!previousYearDirectionDoctors[+code]) {
                    previousYearPercentage = previousSum(previousYearDirectionDoctors[+code].sum)
                }
            }
        } else {
            if (!!report.previous.data.directions[code]) {
                previousPercentage = previousSum(report.previous.data.directions[code].sum);
            }
            if (!!report.previousYear && !!report.previousYear.data.directions[code]) {
                previousYearPercentage = previousSum(report.previousYear.data.directions[code].sum)
            }
        }

        return {
            totalSum,
            previousPercentage,
            previousYearPercentage
        }
    }, [JSON.stringify(report), state.selectedDirection, state.currentTab])
}

export default useTotalSumDirection
