import React from "react"
import PropTypes from 'prop-types'
import Helper from "../../common/helpers/main";
import Select from "../../components-ui/Inputs/Select/Select";
import Button from "../../components-ui/Inputs/Button/Button";
import ServerCommand from "../../common/server/server-command";
import Checkbox from "../../components-ui/Inputs/Checkbox/Checkbox";
import MultipleEnumField from "./MultipleEnumField";
import DateTime from "../../components-ui/Inputs/DateTime/DateTime";
import Counter from "../../components-ui/Inputs/Counter/Counter";
import TextArea from "../../components-ui/Inputs/TextArea";

export default class ItemPopup extends React.Component {

    static propTypes = {
        cases: PropTypes.object,
        selectedGroup: PropTypes.object,
        type: PropTypes.string,
        closePopup: PropTypes.func,
        updateData: PropTypes.func.isRequired,
        fieldsValues: PropTypes.object.isRequired,
        fields: PropTypes.array,
        methods: PropTypes.object,
        onAfterSave: PropTypes.func,
    };

    static defaultProps = {
        onAfterSave: () => null
    };

    state = {
        values: this.getInitialValues()
    };

    focusedField = null;

    componentDidMount() {
        if (!!this.focusedField) {
            this.focusedField.focus()
        }
    }

    render() {

        const {cases, selectedGroup} = this.props;

        let btnText = 'Добавить',
            btnHandle = this.saveNewRow,
            popupHeader = Helper.ucfirst(cases.newNominative) + ' ' + cases.nominative,
            popupSubheader;

        if(!!selectedGroup) {
            popupSubheader = [`Размещение ${cases.newGenitive} ${cases.genitive} в группу`,
                <br/>, `«${selectedGroup.name}»`];
        }
        else {
            popupSubheader = '';
        }


        if (this.props.type === 'edit') {
            btnText = 'Сохранить';
            btnHandle = this.editRow;
            popupHeader = `Редактирование ${cases.genitive}`;
        }

        if (this.props.type === 'copy') {
            popupHeader = `Копирование ${cases.genitive}`;
            btnHandle = this.saveNewRow;
        }

        let mainFields = [],
            sidebarFields = [];

        this.props.fields.forEach(field => {
            if (field.inSidebar) {
                sidebarFields.push(field)
            } else {
                mainFields.push(field)
            }
        })

        let popupClassName = 'table-with-groups__popup';

        if (this.props.fields.some(field => field.inSidebar)) {
            popupClassName += ' table-with-groups__popup--with-sidebar'
        }

        return (
            <div className={popupClassName}>
                <p className="table-with-groups__popup-header">{popupHeader}</p>
                {this.props.type === 'new' &&
                <p className="popup__subheader">{popupSubheader}</p>
                }
                <div className="popup__content">
                    <div className="popup__add-service">
                        <div className="popup__fields">
                            {mainFields.map(this.renderFields)}
                        </div>
                        {!!sidebarFields.length &&
                        <div className="popup__sidebar">
                            {sidebarFields.map(this.renderFields)}
                        </div>}
                    </div>
                    <div className="popup__btns">
                        <Button variant={this.isValid() ? 'success' : 'disabled'}
                                size="long"
                                children={btnText}
                                onClick={btnHandle}/>
                        <Button variant="default"
                                size="long"
                                children="Отменить"
                                onClick={this.props.closePopup}/>
                    </div>
                </div>
            </div>
        )
    }

    getInitialValues() {
        let values = {}

        this.props.fields.forEach(field => {
            let value

            if ((field.isCleaningOnCopy && this.props.type === 'copy') || typeof this.props.fieldsValues[field.name] === 'undefined') {
                //Если поле очищается при копировании строки таблицы и значение этого поля не указано, то выбираем значение по умолчанию
                if ('defaultValue' in field) {
                    //Если с сервера пришло значение по умолчанию, то устанавливаем его в качестве значения поля
                    if (!field.defaultValue && (field.type === 'string' || field.type === 'text')) {
                        value = '';
                    } else {
                        value = field.defaultValue;
                    }
                } else {
                    //Если с сервера не пришло значение поля по умолчанию, то устанавливаем его сами
                    switch (field.type) {
                        case 'enum':
                            value = field.variants[0].value
                            break
                        case 'datetime':
                            value = Helper.Date.formatDate(new Date())
                            break

                        default:
                            value = ''
                            break
                    }
                }
            } else {
                //Если значения полей уже указаны, то устанавливаем их
                value = this.props.fieldsValues[field.name]
            }

            values[field.name] = value
        })

        return values
    }

    renderFields = (field) => {
        let fieldComponent = null,
            generalProps = {};

        const {values} = this.state;
        let displaySeparateLabel = true;

        if (this.props.type === 'copy' && field.isCleaningOnCopy) {
            generalProps.ref = ref => !this.focusedField && (this.focusedField = ref)
        }

        if (!!field.render) {
            fieldComponent = field.render({
                value: values[field.name],
                onChange: (value) => this.saveNewFieldValue(field.name, value)
            })
        } else {
            switch (field.type) {
                case 'datetime':
                    fieldComponent = <DateTime selected={new Date(values[field.name])}
                                               onChange={(date) => this.saveNewFieldValue(field.name, Helper.Date.formatDate(date))} />;
                    break;

                case 'enum':
                    const currentVariantValue = values[field.name] ? values[field.name] : field.variants[0].value
                    let currentVariant = {};

                    field.variants.forEach(variant => {
                        if (variant.value === currentVariantValue) {
                            currentVariant = variant
                        }
                    });

                    fieldComponent = <Select options={field.variants}
                                               value={currentVariant}
                                               onChange={(selectedVariant) => this.saveNewFieldValue(field.name, selectedVariant.value)}
                                               {...generalProps}
                    />;
                    break;

                case 'multiple_enum':
                    fieldComponent = (
                        <MultipleEnumField
                            value={values[field.name]}
                            options={field.variants}
                            onChange={selectedValues => this.saveNewFieldValue(field.name, selectedValues)}
                            {...generalProps}
                        />
                    );

                    break;

                case 'float':
                    field.validate = (value) => {
                        return !isNaN(value)
                    }
                case 'string':
                    let inputClassName = (field.size === "small") ? "popup__input popup__input--small" : "popup__input"
                    if (field.required && !values[field.name]) {

                    }

                    fieldComponent = <input className={inputClassName}
                                            value={values[field.name]}
                                            onChange={(e) => this.saveNewFieldValue(field.name, e.target.value)}
                                            {...generalProps}
                    />;
                    break;


                case 'text':
                    fieldComponent = (
                        <TextArea title={field.title}
                                  value={values[field.name]}
                                  onChange={e => this.saveNewFieldValue(field.name, e.target.value)}
                                  {...generalProps}
                        />
                    );
                    displaySeparateLabel = false;
                    break;

                case 'integer':
                case 'smallint':
                    fieldComponent = (
                        <Counter value={values[field.name]}
                                 onChange={(value) => this.saveNewFieldValue(field.name, value)}
                        />
                    );
                    break;


                case 'boolean':
                    fieldComponent = (
                        <Checkbox label={field.title}
                                  checked={values[field.name]}
                                  onChange={() => this.toggleBooleanField(field.name)}
                        />
                    );
                    displaySeparateLabel = false;
                    break;
                default:
                    break;
            }
        }

        if (field.dontWrap) {
            return fieldComponent;
        }

        let wrapperClass = 'popup__input-wrapper';

        if (displaySeparateLabel) {
            wrapperClass += ' popup__input-wrapper--separate-label';
        }

        return (
            <div className={wrapperClass}>
                {displaySeparateLabel && <div className="popup__label">{field.title}</div>}
                {fieldComponent}
            </div>
        )
    };

    isValid = () => {
        let isValid = true;

        this.props.fields.forEach(field => {
            if (field.required && isValid) {
                const fieldValue = this.state.values[field.name];
                if ((field.validate && !field.validate(fieldValue)) || !fieldValue) {
                    isValid = false;
                }
            }
        });

        return isValid
    };

    saveNewFieldValue(fieldName, value) {
        let values = Helper.clone(this.state.values);
        values[fieldName] = value;
        this.setState({values})
    }

    saveNewRow = () => {
        let values = Helper.clone(this.state.values);
        const {selectedGroup, closePopup, updateData, onAfterSave} = this.props;

        if(!!selectedGroup) {
            values.groupId = selectedGroup.id;
        }

        let command = new ServerCommand(this.props.methods.addItem, values);

        command.exec().then(response => {
            closePopup();
            updateData().then(() => {
                values.id = response.id;
                onAfterSave(values);
            });
        })

    };

    toggleBooleanField(fieldName) {
        this.setState(state => {
            state.values[fieldName] = !state.values[fieldName];
            return state;
        });
    }


    editRow = () => {
        const {onAfterSave} = this.props;
        let values = Helper.clone(this.state.values);
        values.id = this.props.fieldsValues.id;

        let command = new ServerCommand(this.props.methods.updateItem, values);
        command.exec()
            .then(this.props.closePopup)
            .then(this.props.updateData)
            .then(() => onAfterSave && onAfterSave(values));
    };
}
