import React from 'react';
import PropTypes from 'prop-types';
import './Label.scss';


export default class Label extends React.Component {
    static propTypes = {
        labelName: PropTypes.any.isRequired,
        isRequired: PropTypes.bool,
        column: PropTypes.bool,
    };

    render() {

        let className = 'info-box__item';

        if (this.props.column) {
            className += ' info-box__item--column'
        }

        return (
            <div className={className}>
                <div className="info-box__label">
                    {this.props.labelName}
                    {this.props.isRequired &&
                    <span className="text-danger">*</span>}
                </div>
                {this.props.children}
            </div>
        )
    }

}
