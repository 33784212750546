import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {InfoManagerTasks, StateManagerTasks} from "../types/components";
import ServerCommand from "../../../common/server/server-command";
import {GetManagerTasksResponse} from "../types/response";
import {useSelector} from "react-redux";

interface Props {
    setState: Dispatch<SetStateAction<StateManagerTasks>>
    state: StateManagerTasks
}

const useInitialManagerTasks = ({setState, state}: Props) => {
    const allBranches = useSelector(state1 => state1.common.allBranches);
    const [infoManagerTasks, setInfoManagerTasks] = useState<InfoManagerTasks>({
        types: {},
        optionsBranches: state.branches.map(branchId => ({
            value: branchId,
            label: allBranches[branchId].name
        }))
    })

    useEffect(() => {
        ServerCommand.get('manager/task/list').then(({tasks, ...response}: GetManagerTasksResponse) => {
            setState(prevState => ({...prevState, tasks}))
            setInfoManagerTasks(prevState => ({...prevState, ...response}))
        })
    }, [])

    return infoManagerTasks

}

export default useInitialManagerTasks
