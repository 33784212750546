import styled from "styled-components";
import {PItemHeadRecommendedServices} from "./typesHeadRecommendedServices";
import Helper from "../../../../../../../common/helpers/main";

export const WrapHeadRecommendedServices = styled.div.attrs({className: 'WrapHeadRecommendedServices'})`
    display: flex;
    margin-bottom: 28px;
`

export const ItemHeadRecommendedServices = styled.div.attrs({className: 'ItemHeadRecommendedServices'})<any>`
    display: flex;
    align-items: center;
    margin-right: 24px;
    cursor: ${props => props.variant === 'all' ? 'default' : 'pointer'};
`

export const NumberHeadRecommendedServices = styled.div.attrs({className: 'NumberHeadRecommendedServices'})<PItemHeadRecommendedServices>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 8px;
    border: solid 1px ${props => props.theme.colors.info};
    background-color: ${props => props.selected ? props.theme.colors.info : props.theme.colors.white};
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.selected ? props.theme.colors.white : props.theme.colors.info};
    margin-right: 12px;
`

export const TextHeadRecommendedServices = styled.div.attrs({className: 'TextHeadRecommendedServices'})<PItemHeadRecommendedServices>`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.selected ? props.theme.colors.text : props.theme.colors.info};
`

export const InfoHeadRecommendedServices = styled.div.attrs({className: 'InfoHeadRecommendedServices'})`
    width: 16px;
    height: 16px;
    border: 1px solid ${props => `rgba(${Helper.Color.hexToRgb(props.theme.colors.textGray)}, 0.8)`};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    border-radius: 50%;
    position: relative;
    .prompt {
        position: absolute;
        top: 35px;
        max-width: 410px;
        text-align: center;
    }
    .prompt__triangle {
        bottom: auto;
        top: -8px;
        transform: translateX(-50%) rotate(180deg);
    }
`
