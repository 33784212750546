import * as React from 'react';
import {Component} from 'react';
import {sizeCell, resizable} from '../helper/constants'
import Avatar from "../elements/Avatar";
import Resizable from "../elements/Resizable";
import Helper from "../../../common/helpers/main";
import {CellProps, CellState} from "../types/types";
import {positionTooltip, typeResizable} from "../types/elements";
import DoctorWrap from "./DoctorWrap";

export default class Cell extends Component<CellProps, CellState> {

    state = {
        activeClass: false,
        heightWrap: null,
        startHeightWrap: null,
        transformWrap: 0,
        isResize: false
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: CellProps) {
        if (!Helper.isEqual(prevProps.data, this.props.data)) {
            this.loadData();
        }
    }

    loadData = () => {
        const {data} = this.props;
        const {height} = sizeCell;
        let heightWrap = height;
        if (data.collCellDoctor !== undefined) {
            heightWrap = data.collCellDoctor * (sizeCell.height - 1) + 1;
        }
        this.setState({
            heightWrap,
            startHeightWrap: heightWrap,
            transformWrap: 0,
        });
    };


    render() {
        const {data, selectedCell, chairId, doctors, handleTooltip} = this.props;
        const {isResize, heightWrap, transformWrap} = this.state;
        const style = {
            height: sizeCell.height + 'px',
            width: sizeCell.width + 'px',
            zIndex: 0,
        };

        if (data.doctorTime !== undefined) {
            style.zIndex = 1
        }

        if (data.doctorId === 0) {
            if (selectedCell !== null) {
                if (selectedCell.data.timeStart === data.timeStart && selectedCell.chairId === chairId) {
                    style.zIndex = 1;
                }
            }
        }
        if (isResize) {
            style.zIndex = 15;
        }

        return (
            <div className="cell"
                 style={style}
                 onMouseDown={this.handleCell}
                 onContextMenu={this.handleContextCell}
            >
                <DoctorWrap
                    data={data}
                    doctors={doctors}
                    handleTooltip={handleTooltip}
                    selectedCell={selectedCell}
                    chairId={chairId}
                    heightWrap={heightWrap}
                    transformWrap={transformWrap}
                    renderResizable={this.renderResizable}
                />
                {this.renderCellWrap()}
            </div>
        )
    }

    renderCellWrap = () => {
        const {data, selectedCell, chairId} = this.props;
        const {heightWrap, transformWrap} = this.state;
        const {height} = sizeCell;
        const activeClass = selectedCell !== null ? (selectedCell.chairId + '_' + selectedCell.data.timeStart === (chairId + '_' + data.timeStart)) : false;

        let wrap = null;

        if (data.doctorId === 0 || data.doctorId === 'cookie') {
            wrap = <>
                <span
                    className={`cell__wrap ${activeClass && 'cell__wrap--active'}`}
                    style={{
                        height: height + 'px',
                        transform: `translateY(${transformWrap}px)`,
                        zIndex: 3
                    }}
                />
                <span
                    className={`cell__wrap-dashed ${activeClass && 'cell__wrap-dashed--active'}`}
                    style={{
                        height: heightWrap + 'px',
                        transform: `translateY(${transformWrap}px)`,
                        zIndex: 2
                    }}
                />
                {activeClass && this.renderResizable()}
            </>
        }

        return wrap;
    };

    renderResizable = () => {
        return resizable.map(typeResizable => {
            return <Resizable typeResizable={typeResizable}
                              onResizeStart={this.onResizeStart}
                              onResize={this.onResize}
                              onResizeStop={this.onResizeStop}
                              key={typeResizable}
                              data={this.props.data}
            />
        });
    };

    handleCell = (e: any) => {
        const {handleClickCell, data, chairId} = this.props;
        handleClickCell({chairId, data}, e.shiftKey);
    };

    handleContextCell = (e: any) => {

        e.preventDefault();
        e.stopPropagation();
        const {data, handleContextMenu, chairId} = this.props;
        const graphElement = e.target.parentNode.parentNode.parentNode.getBoundingClientRect();
        const positionContextMenu = {
            top: e.clientY - graphElement.y,
            left: e.clientX - graphElement.x
        };
        let type = 'default';
        if (data.doctorId === 'conflict') {
            type = 'conflict'
        }
        handleContextMenu(positionContextMenu, {chairId, data}, type);
    };

    onResizeStart = () => this.setState({
        startHeightWrap: this.state.heightWrap,
        isResize: true
    });

    onResize = (resizable: number, type: typeResizable) => {
        const {heightWrap, startHeightWrap, transformWrap} = this.state as any;
        const {height} = sizeCell;
        const {data} = this.props as any;
        const maxHeight = startHeightWrap + (type === "bottom" ? data.maxBottom * (height - 1) : data.maxTop * (height - 1));

        let newState: CellState = {};


        if (heightWrap < maxHeight) {

        }
        newState.heightWrap = type === 'bottom' ? heightWrap + resizable : heightWrap - resizable;
        newState.transformWrap = type === 'bottom' ? 0 : transformWrap + resizable;
        if (newState.heightWrap < height) {
            newState.heightWrap = height;
            newState.transformWrap = type === 'bottom' ? 0 : startHeightWrap - height;
        }
        if (maxHeight <= newState.heightWrap) {
            newState.heightWrap = maxHeight;
            newState.transformWrap = type === 'bottom' ? 0 : -(maxHeight - startHeightWrap);
        }

        this.setState(newState)
    };

    onResizeStop = () => {
        const {height} = sizeCell;
        const {handleResizeStop, chairId} = this.props as any;
        let data = Helper.clone(this.props.data);
        const {heightWrap, startHeightWrap, transformWrap} = this.state;
        let newState: any = {heightWrap, startHeightWrap, transformWrap};

        let collCells = 0;
        let resizableWay = '';
        let resizeWay = '';

        if (newState.transformWrap !== 0) {  // перетягивание top
            resizableWay = 'top';
            newState.heightWrap = null;
            if (newState.transformWrap > 0) {  // перетягивание top вниз
                resizeWay = 'bottom';
                collCells = Math.round(newState.transformWrap / height);
            } else { // перетягивание top вверх
                resizeWay = 'top';
                collCells = Math.ceil((newState.transformWrap * -1) / height);
            }
        } else { // перетягивание bottom
            resizableWay = 'bottom';
            if (newState.heightWrap > newState.startHeightWrap) { // перетягивание bottom вниз
                resizeWay = 'bottom';
                collCells = Math.ceil((newState.heightWrap - newState.startHeightWrap) / height);
                newState.heightWrap = newState.startHeightWrap + (collCells * (height - 1));
            } else { // перетягивание bottom вверх
                resizeWay = 'top';
                collCells = Math.ceil((newState.startHeightWrap - newState.heightWrap) / (height + 1));
                newState.heightWrap = newState.startHeightWrap - (collCells * (height - 1));
            }
        }

        if (data.doctorId === 0) {
            data.doctorId = 'cookie';
        }

        if (collCells !== 0) {
            const cellArguments = {
                data, chairId, collCells, resizeWay, resizableWay
            };
            handleResizeStop(cellArguments);
            newState.transformWrap = 0;
        } else {
            newState.transformWrap = 0;
            newState.heightWrap = newState.startHeightWrap;
        }

        newState.isResize = false;

        this.setState(newState);

    };

}
