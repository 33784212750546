import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {StateUpdateComment} from "../types";
import ServerCommand from "../../../../common/server/server-command";

export default function useUpdateComment(visitId: number) {
    const [state, setState] = useState<StateUpdateComment>({
        isFocusPrompt: true,
        patient: null,
        comment: ''
    });
    useEffect(() => {
        ServerCommand
            .get('visit/comment-form', {visitId})
            .then(response => {
                setState(prevState => ({
                    ...prevState,
                    patient: response.patient,
                    comment: response.comment
                }))
            })
    }, [])
    return [state, setState] as [StateUpdateComment, Dispatch<SetStateAction<StateUpdateComment>>]
}
