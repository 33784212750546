import * as React from "react";
import {useState} from "react";
import CallFaker from "../../../common/server/CallFaker";
import StartCallButtons from "./StartCallButtons";
import CallButtons from "./CallButtons";
import {Label} from "../../../components-ui/DataDisplay/Label";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import {CallFakerPanelWrap, ButtonsWrap} from '../style'
import Select from "../../../components-ui/Inputs/Select/Select";
import {Option} from "../../../common/types/common";
import useHandsets from "../hooks/useHandsets";
import {useSelector} from "react-redux";
import _ from 'lodash';
import {Handset} from "../types/common";
import {IBranch} from "../../../types";

export default function CallFakerPanel () {
    const [callStarted, setCallStarted] = useState(false);
    const [answered, setAnswered] = useState(false);

    const [contactPhone, setContactPhone] = useState('71212222222')

    const branches = useSelector(state => state.common.branches);
    const branchesOptions = branches.map(branch => ({label: branch.name, value: branch.id}));
    const [branchOption, setBranchOption] = useState<Option>(branchesOptions[0]);

    let [handsetOption, setHandsetOption] = useState<Option|null>(null);
    const handsets = useHandsets();

    if (handsets === null) {
        return null;
    }

    const handsetsOptions: Option[] = handsets
        .filter(handset => handset.branches === null || handset.branches.includes(branchOption.value))
        .map(handset => {
            return {
                label: String(handset.id),
                value: handset.phoneNumber
            };
        });

    handsetOption = handsetOption ?? handsetsOptions[0];

    const handset = _.find(handsets, {phoneNumber: handsetOption.value}) as Handset;
    const branch = _.find(branches, {id: branchOption.value}) as IBranch;

    const callFaker = new CallFaker(contactPhone, branch.phoneNumbers ? branch.phoneNumbers[0] : null);

    function startCall(incoming: boolean) {
        incoming ? callFaker.startIncomingCall() : callFaker.startOutgoingCall((handsetOption as Option).value);
        setCallStarted(true);
    }

    function answer() {
        callFaker.answer((handsetOption as Option).value);
        setAnswered(true);
    }

    function endCall() {
        callFaker.endCall(answered);
        setCallStarted(false);
        setAnswered(false);
    }

    function changeBranch(branchOption: Option) {
        setBranchOption(branchOption);

        if (handset.branches !== null && !handset.branches.includes(branchOption.value)) {
            setHandsetOption(null);
        }
    }

    return (
        <CallFakerPanelWrap>
            <h3>Имитация звонка</h3>

            <Label labelText="Телефон">
                <TextField value={contactPhone}
                           mask="+7 (999) 999-99-99"
                           widthInput="180px"
                           onChange={setContactPhone}
                           isDisabled={callStarted}
                />
            </Label>

            <Label labelText="Филиал" marginWrap="20px 0 0">
                <Select options={branchesOptions}
                        value={branchOption}
                        onChange={changeBranch}
                        isDisabled={callStarted}
                />
            </Label>

            <Label labelText="Номер трубки" marginWrap="20px 0 0">
                <Select options={handsetsOptions}
                        value={handsetOption}
                        onChange={setHandsetOption}
                        isDisabled={callStarted}
                />
            </Label>

            <ButtonsWrap>
                {callStarted
                    ? <CallButtons answered={answered} answer={answer} endCall={endCall}/>
                    : <StartCallButtons startCall={startCall}/>
                }
            </ButtonsWrap>
        </CallFakerPanelWrap>
    );
}
