import React from 'react';
import PropTypes from 'prop-types';
import ServerCommand from "../../common/server/server-command";
import Modal from "../../containers/DialogsManager/containers/Modal/Modal";
import ConfirmModal from "../../content/modals/ConfirmModal";

export default class DeletePopup extends React.PureComponent{

    static propTypes = {
        targetAccusativeType: PropTypes.string.isRequired,
        targetId: PropTypes.number.isRequired,
        targetName: PropTypes.string.isRequired,
        deleteMethod: PropTypes.string.isRequired,
        updateData: PropTypes.func.isRequired,
        close: PropTypes.func.isRequired,
        beforeDelete: PropTypes.func,
    };

    render() {
        const {targetAccusativeType, targetName, close} = this.props;

        return (
            <Modal isOpen={true} closeModal={close}>
                <ConfirmModal
                    descrText={`Вы точно хотите удалить ${targetAccusativeType} «${targetName}»?`}
                    submitText={`Удалить ${targetAccusativeType}`}
                    onClose={close}
                    onSumbit={this.delete}/>
            </Modal>
        );
    }

    delete = () => {
        const {targetId, deleteMethod, updateData, close, beforeDelete} = this.props;
        ServerCommand.run(deleteMethod, {id: targetId}).then(() => {
            close();
            if(beforeDelete) {
                beforeDelete(targetId);
            }

            updateData();
        });
    }
}

