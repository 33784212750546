import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import TimeCounter from "../../../../../components-ui/Inputs/TimeCounter/TimeCounter";
import TimeCounterNew from "../../../../../components-ui/Inputs/TimeCounterNew/TimeCounterNew";

export default function TimeCounterPage() {
    const [value, setValue] = useState('15');
    const onClickDown = () => setValue(String(+value - 15))
    const onClickUp = () => setValue(String(+value + 15))

    const [time, setTime] = useState('08:00');
    return (
        <>
            <h1>InputNumber</h1>
            <WrapContent>
                <h3>Из styled-components</h3>
                <TimeCounter value={value}
                             onChange={setValue}
                             onClickDown={onClickDown}
                             onClickUp={onClickUp}/>
                <pre>
                    {
                        `<TimeCounter value={value} onChange={setValue} onClickDown={onClickDown} onClickUp={onClickUp}/>`
                    }
                </pre>
            </WrapContent>

            <h1>new time counter</h1>
            <WrapContent>
                <TimeCounterNew value={time} onChange={setTime} step={5} maxValue="18:30" minValue="06:00"/>
                <pre>
                    {
                        `<TimeCounterNew value={time} onChange={setTime} step={5}/>`
                    }
                </pre>
            </WrapContent>
        </>
    )
}
