import {ScriptType, ScriptGroupList} from '../../../types/common';
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import useChangeSelectedScript from '../hooks/useChangeSelectedScript';
import useInitialScripts from '../hooks/useInitialScripts';
import Box from '../../../../../components-ui/Layout/Box';
import Paper from '../../../../../containers/UI-Collection/Elements/Surfaces/Paper';
import TextField from '../../../../../components-ui/Inputs/TextField/TextField';
import {Divider} from '../../../../../components-ui/DataDisplay/Divider';
import Scrollbar from '../../../../../components-ui/DataDisplay/Scrollbar/Scrollbar';
import ScriptGroup from '../components/ScriptGroup';
import Button from '../../../../../components-ui/Inputs/Button/Button';
import Icon from '../../../../../components-ui/DataDisplay/Icon';
import Script from './Script';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import AddGroupScript from '../components/EditScript/modals/AddGroupScript';
import {useSelector} from "react-redux";

interface Props {
    groupsScript: {
        [key: string]: ScriptGroupList[]
    };
    selectedScript: ScriptType | null;
    setSelectedScript: Dispatch<SetStateAction<ScriptType | null>>;
    searchScript: string;
    setSearchScript: Dispatch<SetStateAction<string>>;
    refreshDataFromServer: () => void;
}


const Scripts = ({
                     groupsScript,
                     setSelectedScript,
                     selectedScript,
                     searchScript,
                     setSearchScript,
                     refreshDataFromServer,
                 }: Props) => {

    const {refList, setRefItem} = useChangeSelectedScript();
    useInitialScripts({groupsScript, setSelectedScript});

    const [isEditing, setIsEditing] = useState(false);

    const user = useSelector(state => state.user);

    const [isNewScript, setIsNewScript] = useState(false);

    useEffect(() => {
        setIsEditing(false);
    }, [selectedScript]);


    const {showModal} = useDialogsManager();

    return useMemo(() => {
        return (
            <Box position='fixed'
                 height='684px'
                 positionOffsets={{top: '50%', left: '37.5%', transformY: '-50%', transformX: '-50%'}}>
                <Paper height='100%'>
                    <Box display='flex' height='100%'>
                        <Box display='flex' flexDirection='column' width='358px' backgroundColor='secondary'
                             height='100%' padding='0 0 21px'>
                            <Box padding='16px 24px'>
                                <TextField onChange={setSearchScript} value={searchScript}
                                           widthInput='100%'
                                           size='sm'
                                           isReset={!!searchScript}
                                           onClickReset={() => setSearchScript('')}
                                           isSearch={true} placeholder='Поиск темы' />
                            </Box>
                            {user.canEditCallScript && (
                                <Box display="flex" gap="8px" margin="0 0 16px 24px">
                                    <Button variant='default'
                                            size='sm'
                                            width='max-content'
                                            startIcon={<Box margin='0 7px 0 0'><Icon name='plus' /></Box>}
                                            onClick={() => {
                                                setIsEditing(true);
                                                setIsNewScript(true);
                                            }}
                                    >
                                        Новый скрипт
                                    </Button>
                                    <Button variant='default'
                                            size='sm'
                                            width='max-content'
                                            startIcon={<Box margin='0 7px 0 0'><Icon name='plus' /></Box>}
                                            onClick={() => {
                                                showModal(<AddGroupScript refreshData={refreshDataFromServer}/>)
                                            }}
                                    >
                                        Новая группа
                                    </Button>
                                </Box>
                            )}
                            <Divider />
                            <Scrollbar height='100%' ref={refList}>
                                <Box display='flex' flexDirection='column' padding='21px 12px 0'>
                                    {(!!groupsScript.marketing.length) && (
                                        <>
                                            {groupsScript.marketing.map((marketingGroup) => {
                                                if (!marketingGroup.scriptList.length) return null
                                                return (
                                                    <ScriptGroup key={marketingGroup.id}
                                                                 scriptGroup={marketingGroup}
                                                                 setSelectedScript={setSelectedScript}
                                                                 selectedScript={selectedScript}
                                                                 setRefItem={setRefItem}
                                                                 refreshDataFromServer={refreshDataFromServer}
                                                    />
                                                );
                                            })}
                                            {groupsScript.marketing.some(marketingGroup => !!marketingGroup.scriptList.length) && (
                                                <Divider color='borderGray' margin='0 0 11px' />
                                            )}
                                        </>
                                    )}
                                    {!!groupsScript.noMarketing.length && (
                                        <>
                                            {groupsScript.noMarketing.map((scriptGroup) => {
                                                if (!scriptGroup.scriptList.length) return null
                                                return (
                                                    <ScriptGroup key={scriptGroup.id}
                                                                 scriptGroup={scriptGroup}
                                                                 setSelectedScript={setSelectedScript}
                                                                 selectedScript={selectedScript}
                                                                 setRefItem={setRefItem}
                                                                 refreshDataFromServer={refreshDataFromServer}
                                                    />
                                                );
                                            })}
                                        </>
                                    )}
                                </Box>
                            </Scrollbar>
                        </Box>
                        <Script groupsScript={groupsScript}
                                selectedScript={selectedScript}
                                setIsEditing={setIsEditing}
                                isEditing={isEditing}
                                isNewScript={isNewScript}
                                setIsNewScript={setIsNewScript}
                                refreshDataFromServer={refreshDataFromServer}
                        />
                    </Box>
                </Paper>
            </Box>
        );
    }, [selectedScript, searchScript, JSON.stringify(groupsScript), refList, isEditing]);
};

export default Scripts;
