import React from 'react';
import Paper from "../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import Box from "../../../components-ui/Layout/Box";
import Icon from "../../../components-ui/DataDisplay/Icon";
import {ServiceForPatent} from "../types";
import {useDevice} from "../../../common/hooks/useDevice";
import {InfoBox, InfoIcon} from "../style";

interface Props {
    services: ServiceForPatent[]
}

const InfoPrice = ({services}: Props) => {

    const device = useDevice()

    return (
        <Paper
            margin={device !== "mobile" ? "76px 0 0 0" : "35vw 0 0 0"}
            padding={device !== "mobile" ? "32px 21px 34px" : "109px 12px 30px"}
            borderRadius="8px"
            isHidden={false}>
            <Box position="relative" display="flex" justifyContent="space-between"
                 flexDirection={device !== "mobile" ? "row" : "column-reverse"}>
                <InfoBox>
                    <Typography variant="small-title" fontWeight="normal">В стоимость приёма входит (оплачивается отдельно):</Typography>
                    <Box margin="12px 0 0 0">
                        {services.map((service, key) =>
                            <Box key={key}>
                                <Typography variant="middle"
                                            margin={device !== "mobile" ? "0 0 8px 0" : "0 0 7px 0"}>{service.nameInVisitPrice} — {service.price} руб.</Typography>
                                {service.note &&
                                <Typography variant="small" fontStyle="italic">{service.note}</Typography>
                                }
                            </Box>
                        )}
                    </Box>
                </InfoBox>
                <InfoIcon>
                    <Box position="absolute" positionOffsets={device !== "mobile" ? {bottom: "-10px"} : {bottom: "0"}}>
                        <Icon width="100%" height="fit-content" name="bigPrice"/>
                    </Box>
                </InfoIcon>
            </Box>
        </Paper>
    );
};

export default InfoPrice;
