import styled from "styled-components";

export const SPopupBtns = styled.div`
  margin-left: 0;
  border-top: 1px solid ${props => props.theme.colors.lightBlue};
  padding-top: 27px;
  display: flex;
  justify-content: center;

  button:not(:last-of-type) {
    margin-right: 16px;
  }
`;
