import {useOptionsBranchesUser} from '../../../common/hooks/useOptionsBranchesUser';
import {useCallback, useEffect, useState} from 'react';
import {OptionSelect} from '../../../components-ui/Inputs/Select/typesSelect';
import useLoader from '../../../components/Errors';
import ServerCommand from '../../../common/server/server-command';
import {GetActualRatesParams, GetActualRatesResult} from '../types';

const useXrayRate = () => {

    const optionsBranches = useOptionsBranchesUser()

    const [selectedBranch, setSelectedBranch] = useState<OptionSelect>(optionsBranches[0])

    const [loaderPage, setLoaderPage] = useLoader(true);

    const [loaderTable, setLoaderTable] = useLoader(false)

    const [xrayRateResponse, setXrayRateResponse] = useState<GetActualRatesResult>({rates: []})

    const refreshDataFromServer = useCallback(() => {
        setLoaderTable(true)
        ServerCommand
            .get('xray-picture/rate/actual', {branchId: selectedBranch.value} as GetActualRatesParams,
            ).then((response: GetActualRatesResult) => {
            setXrayRateResponse(response)
            setLoaderTable(false)
        })
    }, [selectedBranch])

    useEffect(() => {
        setLoaderTable(true)
        ServerCommand
            .get('xray-picture/rate/actual', {branchId: selectedBranch.value} as GetActualRatesParams)
            .then((response: GetActualRatesResult) => {
                setXrayRateResponse(response)
                setLoaderPage(false)
                setLoaderTable(false)
            })
    }, [selectedBranch])

    return {
        optionsBranches,
        selectedBranch,
        setSelectedBranch,
        loaderPage,
        loaderTable,
        xrayRateResponse,
        refreshDataFromServer
    }
};

export default useXrayRate;
