import React, {Dispatch, SetStateAction, useMemo} from "react";
import {IColumn, IRow} from "../../../../../../components-ui/DataDisplay/Table";
import {ConsultationsReport} from "../../../../types";
import {StateConsultations} from "../types";
import {useSelector} from "react-redux";
import Box from "../../../../../../components-ui/Layout/Box";
import Typography from "../../../../../../components-ui/DataDisplay/Typography/Typography";
import _ from 'lodash';
import Helper from "../../../../../../common/helpers/main";
import Failed from "../containers/Failed";
import LedToTreatment from "../containers/LedToTreatment";

interface Props {
    report: ConsultationsReport
    state: StateConsultations
    refTable: any
    startDate: string
    endDate: string
    setSubTable: Dispatch<SetStateAction<any>>
    selectedBranches: number[]
}

const useTableConsultations = ({report, state, refTable, startDate, endDate, setSubTable, selectedBranches}: Props) => {
    const users = useSelector(state1 => state1.common.users);
    const directions = useSelector(state1 => state1.common.directions)

    const columns = useMemo(() => {
        const doctorsColumn: IColumn = {
            headerName: 'Врач',
            field: 'name',
            valueFormatter: ({childrenCell}) => users[childrenCell].fio,
            minWidth: '150px',
            sortable: true,
            sortType: 'string',
            sortPosition: 'right',
            sortFormatter: ({childrenCell}) => users[childrenCell].fio,
        }
        const directionsColumn: IColumn = {
            headerName: 'Направление',
            field: 'name',
            valueFormatter: ({childrenCell}) => directions[childrenCell].name,
            minWidth: '140px',
            sortable: true,
            sortType: 'string',
            sortPosition: 'right',
            sortFormatter: ({childrenCell}) => directions[childrenCell].name
        }

        const commonColumns: IColumn[] = [
            state.currentTab === 'doctors' ? doctorsColumn : directionsColumn
        ]

        if (state.currentButton === 'consultationsCount') {
            return [
                ...commonColumns,
                {
                    headerName: 'Осмотрено',
                    field: 'all',
                    width: '100px'
                },
                {
                    headerName: 'В плане',
                    field: 'withPlan',
                    width: '85px',
                    valueFormatter: ({childrenCell}) => !!childrenCell ? childrenCell : 0
                },
                {
                    headerName: 'Согласились лечиться',
                    field: 'ledToTreatment',
                    valueFormatter: ({childrenCell, row, refRow}) => {
                        if (!!row.postponed) {
                            return (
                                <Box display="flex">
                                    <Typography as="span" variant="small"
                                                children={`${!!childrenCell ? childrenCell : 0}+`}/>
                                    <LedToTreatment childrenCell={childrenCell}
                                                    refRow={refRow}
                                                    refTable={refTable}
                                                    state={state}
                                                    row={row}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    setSubTable={setSubTable}
                                                    selectedBranches={selectedBranches}
                                    />
                                </Box>
                            )
                        } else {
                            return !!childrenCell ? childrenCell : 0
                        }
                    },
                    width: '195px'
                },
                {
                    headerName: 'Перенаправлено',
                    field: 'redirected',
                    width: '135px',
                    valueFormatter: ({childrenCell}) => !!childrenCell ? childrenCell : 0
                },
                {
                    headerName: 'Ушли',
                    field: 'failed',
                    width: '70px',
                    valueFormatter: ({childrenCell, refRow, row}) => {
                        if (!!childrenCell) {
                            return <Failed childrenCell={childrenCell}
                                           refRow={refRow}
                                           refTable={refTable}
                                           state={state}
                                           row={row}
                                           startDate={startDate}
                                           endDate={endDate}
                                           setSubTable={setSubTable}
                                           selectedBranches={selectedBranches}/>
                        } else {
                            return 0
                        }
                    }
                },
                {
                    headerName: 'Эффективность',
                    field: 'efficiency',
                    valueFormatter: ({row}) => `${Math.round(100 * (row.all - (!!row.failed ? row.failed : 0)) / row.all)}%`,
                    width: '145px',
                    sortable: true,
                    sortType: 'number',
                    sortPosition: 'right',
                    defaultSort: true,
                    sortFormatter: ({row}) => Math.round(100 * (row.all - (!!row.failed ? row.failed : 0)) / row.all)
                }
            ] as IColumn[]
        } else {
            return [
                ...commonColumns,
                {
                    headerName: 'Сумма рекомендуемых планов',
                    field: 'recommended',
                    width: '256px',
                    valueFormatter: ({childrenCell}) => Helper.String.numberFormat(+childrenCell, 0)
                },
                {
                    headerName: 'Сумма выполняемых планов',
                    field: 'performed',
                    width: '241px',
                    valueFormatter: ({childrenCell}) => Helper.String.numberFormat(+childrenCell, 0)
                },
                {
                    headerName: 'Эффективность',
                    field: 'efficiency',
                    valueFormatter: ({row}) => `${(!!row.recommended && row.recommended > row.performed) ? Math.round(100 * (row.performed) / row.recommended) : 100}%`,
                    width: '145px',
                    sortable: true,
                    sortType: 'number',
                    sortPosition: 'right',
                    defaultSort: true,
                    sortFormatter: ({row}) => (!!row.recommended && row.recommended > row.performed) ? Math.round(100 * (row.performed) / row.recommended) : 100
                }
            ] as IColumn[]
        }

    }, [JSON.stringify(report), state.currentTab, state.currentButton])

    const rows = useMemo(() => {
        return _.map(report[state.currentButton][state.currentTab], (value: any, name) => {
            let defaultSort;
            if (state.currentButton === 'consultationsCount') {
                defaultSort = Math.round(100 * (value.all - (!!value.failed ? value.failed : 0)) / value.all)
            } else {
                defaultSort = (!!value.recommended && value.recommended > value.performed) ? Math.round(100 * (value.performed) / value.recommended) : 100
            }
            return {
                ...value,
                name,
                defaultSort
            }
        }).sort((a, b) => b.defaultSort - a.defaultSort) as IRow[]
    }, [JSON.stringify(report), state.currentTab, state.currentButton])

    return {
        columns,
        rows
    }
}

export default useTableConsultations
