import React from 'react';
import {ManagerTask} from "../../types/common";
import {InsufficientWorkloadPayload} from "../../types/taskData";
import {Date, WorkloadPercent} from "../Labels";
import {STaskList} from "../../../../containers/Tasks/style";

interface Props {
    task: ManagerTask<InsufficientWorkloadPayload>
    renderBranches: () => React.ReactNode | null
}

const InsufficientWorkload = ({task, renderBranches}: Props) => {
    return (
        <STaskList padding="19px 0 16px">
            {renderBranches()}
            <Date date={task.payload.date}/>
            <WorkloadPercent workloadPercent={task.payload.workloadPercent}/>
        </STaskList>
    );
};

export default InsufficientWorkload;
