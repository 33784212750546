import _ from "underscore";
import {DentalCardTooth, SetStateConfig} from "../types";
import {TeethMap} from "../../../common/types/teethmap";
import Helper from "../../../common/helpers/main";

interface HandleButtonContextMenu {
    statusId: number,
    setConfig: SetStateConfig,
    saveTeethMap: any,
    teethMap: TeethMap
}

export const handleButtonContextMenu = ({statusId, saveTeethMap, setConfig, teethMap} : HandleButtonContextMenu) => {

    const {statusList, statusIndependentAttributes, rootsMap, canalsMap} = teethMap;
    const currentStatus = statusList[statusId];

    setConfig(prevState => {
        _.each(prevState, (teeth, nameConfig) => {
            teeth.forEach((tooth: DentalCardTooth) => {
                if (tooth.selected) {
                    const prevStatus = statusList[tooth.status];
                    if (prevStatus.group !== currentStatus.group) {
                        if (!!tooth.attributes) {
                            tooth.attributes = tooth.attributes.filter(attribute => statusIndependentAttributes.indexOf(attribute) !== -1)
                        }
                    }
                    if (!currentStatus.withRootsAndCanals) {
                        if (!!tooth.roots) delete tooth.roots;
                        if (!!tooth.canals) delete tooth.canals;
                    } else {
                        const isChildTooth = Helper.TeethMap.isChildTooth(tooth.number);
                        if (!isChildTooth) {
                            if (!tooth.roots) tooth.roots = rootsMap[tooth.number];
                            if (!tooth.canals) tooth.canals = canalsMap[tooth.number];
                        }
                    }
                    tooth.status = statusId;
                    tooth.unconfirmed = false;
                }
            })
        });
        return {
            ...prevState
        }
    });
    saveTeethMap()
}
