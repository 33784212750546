import React, {useState} from 'react';
import Modal from "../../../../../containers/DialogsManager/containers/Modal/Modal";
import WaitingAndPlanned from "../../../../../content/CallCenter/containers/WaitingAndPlanned/WaitingAndPlanned";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import SVG from "../../../../../common/helpers/SVG";
import {useSelector} from "react-redux";

const WAPButton = () => {
    const savePatientCallCenter = useSelector(state => state.callCenter?.savePatient) as any;
    const [openWAP, setOpenWAP] = useState(false);
    return (
        <>
            {openWAP && (
                <Modal isOpen={true} closeModal={() => setOpenWAP(false)} isIconClose={false}>
                    <WaitingAndPlanned patient={savePatientCallCenter}/>
                </Modal>
            )}
            <Button isIcon
                    variant="default"
                    size="lg"
                    margin="0 0 0 30px"
                    title="Добавить в лист ожидания или запланировать приём"
                    onClick={() => setOpenWAP(true)}
                    children={<SVG name="call-center/download"/>}/>
        </>
    );
};

export default WAPButton;
