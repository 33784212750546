import {useEffect} from "react";
import preparePhoneOwnerName from "../helper/preparePhoneOwnerName";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import {defaultOptionsPhoneOwner} from "../helper/defaultOptionsPhoneOwner";
import {usePatientField} from "../containers/PatientField";
import {PropsUseChangePatientList} from "../types/hooks";
import usePrepareRelations from "./usePrepareRelations";

export default function useChangePatientList({patientList}: PropsUseChangePatientList) {
    const prepareRelations = usePrepareRelations();
    const {value, currentPatient, setForm, parentName, currentTab} = usePatientField()
    useEffect(() => {
        if (String(value).length === 11 && !!patientList) {
            const filterPatientList = patientList.filter(patient => patient.id !== currentPatient?.id);
            const patientOptionsPhoneOwner = filterPatientList.map((patient, index) => {
                return {
                    label: preparePhoneOwnerName(patient),
                    value: patient.id,
                    sort: index + 2
                }
            });

            if (!!setForm) {
                setForm((prevState: any) => {
                    const prepareState = (state: any) => {
                        const selfPatient = patientList.find(patient => patient.phoneOwner === 'self');
                        let phoneOwner = state.basic.phoneOwner as null | OptionSelect;
                        if (!!selfPatient) {
                            if (!!currentPatient && currentPatient.personalPhone === value && selfPatient.id === currentPatient.id) {
                                phoneOwner = defaultOptionsPhoneOwner.find(option => option.value === 'self') as OptionSelect;
                            } else {
                                phoneOwner = {
                                    value: selfPatient.id,
                                    label: preparePhoneOwnerName(selfPatient)
                                } as OptionSelect
                            }
                        } else {
                            if (!!phoneOwner) phoneOwner = null
                        }

                        if (parentName === "NewVisitForm") {
                            const {relationsPatients, relationsValidate} = prepareRelations(filterPatientList);
                            state.relations.relations = relationsPatients;
                            state.relations.validationFields = relationsValidate;
                            if (!!phoneOwner && phoneOwner.value === 'other') {
                                state.isProvidedData = false
                            }
                        }
                        state.relations.relationsList = filterPatientList;


                        return {
                            ...state,
                            basic: {
                                ...state.basic,
                                optionsPhoneOwner: defaultOptionsPhoneOwner.concat(patientOptionsPhoneOwner),
                                phoneOwner
                            }
                        }
                    }

                    if (!!currentTab) {
                        prevState[currentTab] = prepareState(prevState[currentTab])
                    } else {
                        prevState = prepareState(prevState)
                    }

                    return {...prevState}
                })
            }
        }
    }, [patientList])
}
