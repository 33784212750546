import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import Counter from "../../../../../components-ui/Inputs/Counter/Counter";

export default function CounterPage() {
    const [value, setValue] = useState<any>(1);
    return (
        <>
            <h1>CounterPage</h1>
            <WrapContent>
                <div>
                    <Counter onChange={setValue} value={value} />
                    <pre>
                        {
                            `<Counter onChange={setValue} value="${value}" />`
                        }
                    </pre>
                </div>

            </WrapContent>
        </>
    )
}
