import {useSelector} from "react-redux";
import {useMemo} from "react";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";

export const useOptionsSpecialities = (): OptionSelect[] => {
    const specialities = useSelector(state => state.common.directions)
    return useMemo(() => {
        const specialitiesMap = Object.values(specialities).sort((a, b) => a.sort - b.sort).map(speciality => ({
            value: speciality.code,
            label: speciality.name
        }))
        specialitiesMap.unshift({
            value: '',
            label: 'Все специальности',
        });
        return specialitiesMap
    }, [])
}
