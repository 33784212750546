import React from 'react';
import LoaderPage from '../../../../../../components/LoaderPage';
import Typography from '../../../../../../components-ui/DataDisplay/Typography/Typography';
import Button from '../../../../../../components-ui/Inputs/Button/Button';
import useAddingEditingEmployee from './hooks/useAddingEditingEmployee';
import Box from '../../../../../../components-ui/Layout/Box';
import TextField from "../../../../../../components-ui/Inputs/TextField/TextField";
import Select from "../../../../../../components-ui/Inputs/Select/Select";
import CardForm from "../../../../../../components-ui/DataDisplay/CardForm";
import {commonSelectOption} from "../../../../../../content/modals/CashCreate/data";

interface AddingEditingEmployeeProps {
    kkmId?: number;
    refreshDataFromServer: () => void
}

const AddingEditingKkm = ({kkmId, refreshDataFromServer}: AddingEditingEmployeeProps) => {

    const {
        loadingPage,
        kkmOptions,
        optionsBranches,
        optionsCashier,
        state,
        setState,
        validate,
        saveKkm,
        loadingRequest,
        validationState,
        setValidationState,
    } = useAddingEditingEmployee({kkmId, refreshDataFromServer});

    return (
        <>
            {loadingPage ? <LoaderPage /> :
                <>
                    <Typography variant='middle-title' fontWeight='bold'>
                        {`${kkmId ? 'Изменение' : 'Добавление'} ККМ`}
                    </Typography>
                    <div style={{columnCount: 1, maxWidth: '1224px', marginTop: '15px'}}>
                        <CardForm style={{margin: '0 0 16px 0'}} title='Данные' forms={[
                            {
                                label: 'Логин',
                                isRequired: true,
                                input: <TextField
                                    value={state.login ?? ''}
                                    isTextArea={true}

                                    onChange={(value => {
                                        setState(prevState => ({
                                            ...prevState,
                                            login: value,
                                        }))
                                    })}
                                />,
                            },
                            {
                                label: 'Пароль',
                                input: <TextField
                                    value={state.password ?? ''}
                                    isTextArea={true}
                                    onChange={(value => {
                                        setState(prevState => ({
                                            ...prevState,
                                            password: value,
                                        }))
                                    })}
                                />,
                            },
                            {
                                label: 'URL сервера',
                                input: <TextField
                                    value={state.url ?? ''}
                                    isTextArea={true}
                                    onChange={(value => {
                                        setState(prevState => ({
                                            ...prevState,
                                            url: value,
                                        }))
                                    })}
                                />,
                            },
                            {
                                label: 'ККМ',
                                isRequired: true,
                                input: <Select
                                    options={kkmOptions}
                                    value={state.selectedKkm}
                                    onChange={(value => {
                                        setState(prevState => ({
                                            ...prevState,
                                            selectedKkm: value,
                                            name: value.label,
                                            numberDevice: value.value
                                        }))
                                    })}
                                />,
                            },
                            {
                                label: 'Филиал',
                                isRequired: true,
                                input: <Select
                                    options={optionsBranches}
                                    value={state.selectedBranch}
                                    onChange={(value => {
                                        setState(prevState => ({
                                            ...prevState,
                                            selectedBranch: value,
                                            branchId: value.value
                                        }))
                                    })}
                                />,
                            },
                            {
                                label: 'Кассир по умолчанию',
                                isRequired: true,
                                input: <Select
                                    menuPlacement='top'
                                    options={optionsCashier}
                                    value={state.selectedCashier}
                                    onChange={(value => {
                                        setState(prevState => ({
                                            ...prevState,
                                            selectedCashier: value,
                                            cashierId: value.value
                                        }))
                                    })}
                                />,
                            },
                        ]} />
                    </div>
                    <Box display='flex'
                         justifyContent='flex-end'
                         maxWidth='1224px'
                         gap='25px'
                         alignItems='center'
                         padding='50px 0 0 0'>
                        <Button children='Сохранить'
                                loader={loadingRequest}
                                size='md'
                                variant={validate() ? 'success' : 'disabled'}
                                onClick={saveKkm}
                        />
                    </Box>
                </>
            }
        </>
    );
};

export default AddingEditingKkm;
