import {ReceiptsReport} from "../../../../types";
import {useMemo} from "react";
import _ from 'lodash';
import {useSelector} from "react-redux";
import {LineDataItem} from "../../../../../../components-ui/Charts/ChartLine";
import {StateReceipts} from "../types";

interface Props {
    report: ReceiptsReport
    startDate: string
    endDate: string
    state: StateReceipts
}

const useLineData = ({report, startDate, endDate, state}: Props) => {
    const directions = useSelector(state => state.common.directions)
    return useMemo(() => {
        if (report.current.fullMonths.length > 1) {
            if (state.currentTab === 'doctors' && !!state.selectedDirection) {
                const directionDoctors = state.selectedDirection === 'all' ? report.current.general.doctors : report.current.directions[state.selectedDirection].doctors
                return _.map(directionDoctors, (data, name) : LineDataItem => {
                    return {
                        name,
                        data: report.current.fullMonths.map(code => {
                            let res = {code} as any
                            if (!!data.months && !!data.months[code]) {
                                res.value = data.months[code]
                            } else {
                                res.value = 0
                            }
                            return res
                        })
                    }
                }).sort((a, b) => directionDoctors[+b.name].sum - directionDoctors[+a.name].sum)
            } else {
                return _.map(report.current.directions, (direction, directionCode) : LineDataItem => {
                    return {
                        name: directionCode,
                        data: report.current.fullMonths.map(code => {
                            let res = {code} as any
                            if (!!direction.months[code]) {
                                res.value = direction.months[code]
                            } else {
                                res.value = 0
                            }
                            return res
                        })
                    }
                }).sort((a, b) => directions[a.name].sort - directions[b.name].sort)
            }
        } else {
            return null;
        }
    }, [JSON.stringify(report), startDate, endDate, state.currentTab, state.selectedDirection])
}

export default useLineData
