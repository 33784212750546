import React, {useEffect, useState} from "react";
import {Paragraph} from "../../../../components-ui/styledComponent/common/typography";
import {useInfo} from "../../DentalRecords";
import {DentalCardTooth, SConfig, SInfo} from "../../types";
import {InfoWrap, InfoItem, InfoTitle, FullScreenButton, InfoContentWrap, InfoText} from './styledInfo';
import Scrollbars from "../../../../components-ui/Scrollbars/scrollbars";
import Paper from "../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import SVG from "../../../../common/helpers/SVG";
import Attributes from "./elements/Attributes";
import PerformedServices from "./elements/PerformedServices";
import Diagnoses from "./elements/Diagnoses";
import Helper from "../../../../common/helpers/main";

export default function Info() {
    const {teethMap, config, newServicesNames} = useInfo() as SInfo;
    const [full, setFull] = useState(false);
    const [infoList, setInfoList] = useState<DentalCardTooth[]>([]);
    const cloneConfig = Helper.clone(config) as SConfig;
    const allConfig = cloneConfig.upper.concat(cloneConfig.lower);
    const {attributesNames, servicesNames} = teethMap;

    useEffect(() => {
        const validateConfig = (tooth: DentalCardTooth) => {
            return (!!tooth.attributes && !!tooth.attributes.length) || !!tooth.diagnoses || !!tooth.performedServices || tooth.roots || tooth.canals
        }
        if (allConfig.some(tooth => validateConfig(tooth))) {
            allConfig.forEach(tooth => {
                if (!!tooth.attributes) {
                    tooth.attributes = tooth.attributes.filter(attr => !!attributesNames[attr])
                }
            })
            if (allConfig.some(tooth => tooth.selected)) {
                setInfoList(allConfig.filter(tooth => (validateConfig(tooth)) && tooth.selected))
            } else {
                setInfoList(allConfig.filter(tooth => validateConfig(tooth)))
            }
        } else {
            if (infoList.length > 0) setInfoList([]);
        }

        const scrollAreaContent = document.querySelector('.dental-card-info-scroll .scrollarea-content') as any;
        if (scrollAreaContent !== null) {
            scrollAreaContent.style = {marginTop: '0px !important'};
        }
    }, [config])


    let content;

    if (infoList.length === 0) {
        content = <Paragraph type="info" size="sm" margin="10px 20px 0  0">Здесь будут характеристики зубов из Диагнокат, выполненные услуги и диагнозы из истории болезни </Paragraph>
    } else {
        content = (
            <>
                <Scrollbars className="dental-card-info-scroll" style={{height: 'max-content', maxHeight: full ? 'calc(100vh - 20px)' : '390px'}}>
                    <InfoContentWrap>
                        {infoList.map(tooth => {
                            return (
                                <InfoItem key={"info" + tooth.number}>
                                    <InfoTitle children={`Зуб ${tooth.number}`} />
                                    {(!!tooth.roots || !!tooth.canals) && (
                                        <div style={{marginBottom: '10px'}}>
                                            {!!tooth.roots && (
                                                <InfoText>{Helper.getCountString(tooth.roots, ['корень', 'корня', 'корней'])}</InfoText>
                                            )}
                                            {!!tooth.canals && (
                                                <InfoText>{Helper.getCountString(tooth.canals, ['канал', 'канала', 'каналов'])}</InfoText>
                                            )}
                                        </div>
                                    )}
                                    <Attributes attributes={tooth.attributes}
                                                attributesNames={attributesNames}
                                                toothNumber={tooth.number} />
                                    <PerformedServices performedServices={tooth.performedServices}
                                                       servicesNames={newServicesNames} />
                                    <Diagnoses diagnoses={tooth.diagnoses} />
                                </InfoItem>
                            )
                        })}
                    </InfoContentWrap>
                </Scrollbars>
                <FullScreenButton onClick={() => setFull(!full)}>
                    <SVG name={full ? 'small-screen' : 'full-screen'} />
                </FullScreenButton>
            </>
        )
    }

    return (
        <InfoWrap full={full && !!infoList.length}>
            <Paper padding="10px 3px 10px 20px" minWidth="498px" margin="0">
                {content}
            </Paper>
        </InfoWrap>
    )
};
