import React from 'react';
import PropTypes from "prop-types";
import moment from "moment/moment";
import KanbanCard from "../KanbanCard";
import Helper from "../../../common/helpers/general-helper";

export default class TreatmentKanbanCard extends React.Component {
    static propTypes = {
        visit: PropTypes.object.isRequired,
        doctor: PropTypes.object.isRequired,
        isMinimized: PropTypes.bool,
        removeBgColor: PropTypes.func,
    };

    state = {
        timer: Math.ceil(this.props.visit.stateData.duration)
    };

    componentDidMount() {
        let timer = Helper.clone(this.state.timer);
        setInterval(() => {
            timer += 1;
            this.setState({timer});
        }, 1000);
    }

    render() {
        return (
            <KanbanCard
                visit={this.props.visit}
                isMinimized={this.props.isMinimized}
                removeBgColor={this.props.removeBgColor}
                doctor={this.props.doctor}
                renderHeader={this.renderHeader}
            />
        );
    }

    renderHeader = () => {
        return (
            <div className="kanban-card__header">
                Длится {moment.unix(this.state.timer).utc().format((this.state.timer > 60 * 60) ? 'HH ч mm м' : 'mm м')}
            </div>
        );
    };

}