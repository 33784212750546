import {useCallback, useEffect, useState} from 'react';
import {useOptionsBranchesUser} from '../../../common/hooks/useOptionsBranchesUser';
import {OptionSelect} from '../../../components-ui/Inputs/Select/typesSelect';
import useLoader from '../../../components/Errors';
import ServerCommand from '../../../common/server/server-command';
import {GetActualRatesParams, GetActualRatesResult} from '../types';

const useLaboratoryServiceRates = () => {

    const optionsBranches = useOptionsBranchesUser();

    const [selectedBranch, setSelectedBranch] = useState<OptionSelect>(optionsBranches[0]);

    const [loaderPage, setLoaderPage] = useLoader(true);

    const [loaderTable, setLoaderTable] = useLoader(false);

    const [laboratoryServiceRatesResponse, setLaboratoryServiceRatesResponse] = useState<GetActualRatesResult>({
        rates: {},
        serviceGroups: [],
        services: []
    });

    const params: GetActualRatesParams = {
        branchId: selectedBranch.value,
    };

    useEffect(() => {
        setLoaderTable(true);
        ServerCommand
            .get('laboratory/service/rate/actual', params)
            .then((response) => {
                setLaboratoryServiceRatesResponse(response);
                setLoaderPage(false);
                setLoaderTable(false);
            });
    }, [selectedBranch]);

    const refreshDataFromServer = useCallback(() => {
        setLoaderTable(true);
        ServerCommand
            .get('laboratory/service/rate/actual', params)
            .then((response) => {
                setLaboratoryServiceRatesResponse(response);
                setLoaderPage(false);
                setLoaderTable(false);
            });
    }, [selectedBranch]);

    return {
        loaderPage,
        loaderTable,
        laboratoryServiceRatesResponse,
        selectedBranch,
        setSelectedBranch,
        optionsBranches,
        refreshDataFromServer
    };

};

export default useLaboratoryServiceRates;
