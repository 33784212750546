import React from 'react';
import Typography from '../../../../../../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../../../../../../components-ui/Layout/Box';
import {Label} from '../../../../../../../../components-ui/DataDisplay/Label';
import {
    commonLabelOption, commonSelectOption,
    commonTextFieldOption,
} from '../../../../../../../TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import TextField from '../../../../../../../../components-ui/Inputs/TextField/TextField';
import Checkbox from '../../../../../../../../components-ui/Inputs/Checkbox/Checkbox';
import Button from '../../../../../../../../components-ui/Inputs/Button/Button';
import ValidationLinkInfo from '../../../../../../../../components/ValidationLinkInfo/ValidationLinkInfo';
import {ScriptType, ScriptGroupList} from '../../../../../../types/common';
import Select from '../../../../../../../../components-ui/Inputs/Select/Select';
import Radio from '../../../../../../../../components-ui/Inputs/Radio/Radio';
import {servicesTypeForLongAbsenceArr, TEETH_NUMBERS} from './constansts';
import useModalEditScript from './hooks/useModalEditScript';

interface Props {
    selectedScript: ScriptType | null;
    text: string;
    groupsScript: {[p: string]: ScriptGroupList[]};
    refreshData: () => void;
}

const ModalEditScript = ({selectedScript, text, groupsScript, refreshData}: Props) => {

    const {
        directionsOptions,
        serviceTypesOptions,
        postponeTreatmentOptions,
        state,
        setState,
        servicesForLongAbsence,
        setServicesForLongAbsence,
        validationCheck,
        setValidationCheck,
        groupsOptions,
        taskTypesOptions,
        loader,
        onClickSave,
    } = useModalEditScript({selectedScript, text, groupsScript, refreshData});

    return (
        <Box padding='45px 30px 76px' minWidth='562px' backgroundColor='rgb(255, 255, 255)'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        children={`${selectedScript?.id ? 'Изменение' : 'Добавление'} скрипта`} textAlign='center' />
            <Box margin='39px 0 0 0'>
                <Label {...commonLabelOption}
                       isRequired={true}
                       labelText='Название'
                       align='leftTop'
                       marginLabel='8px 10px 0 0'>
                    <TextField
                        {...commonTextFieldOption}
                        value={state.name}
                        onChange={(val) => setState(prevState => ({...prevState, name: val}))}
                        isTextArea={true}
                        isError={!state.name.length && validationCheck}
                    />
                </Label>

                <Label labelText='Маркетинговый' {...commonLabelOption} >
                    <Checkbox label='' checked={state.marketing} onChange={(val) => {
                        setState({...state, marketing: val});
                    }} />
                </Label>

                <Label labelText='Группа' isRequired={true} {...commonLabelOption} >
                    <Select {...commonSelectOption}
                            colorSelect='white'
                            isError={!state.groupId && validationCheck}
                            value={groupsOptions.filter(option => option.value === state.groupId)[0]}
                            options={groupsOptions}
                            onChange={(val) => setState(prevState => ({...prevState, groupId: val.value}))}
                    />
                </Label>

                <Label {...commonLabelOption}
                       labelText='Приоритет'
                       align='leftTop'
                       marginLabel='8px 10px 0 0'>
                    <TextField
                        {...commonTextFieldOption}
                        value={state.priority || ''}
                        onChange={(val) => setState(prevState => ({...prevState, priority: +val}))}
                        isNumber
                        placeholder=''
                    />
                </Label>

                <Label labelText='Тип задач' {...commonLabelOption} >
                    <Select {...commonSelectOption}
                            colorSelect='white'
                            value={taskTypesOptions.find(val => val.value === state.taskTypes?.[0])}
                            options={taskTypesOptions}
                            onChange={(val) => setState(prevState => ({...prevState, taskTypes: [val.value]}))}
                    />
                </Label>
                {state.taskTypes?.some(val => ['care', 'survey_invite', 'treatment_refusal'].includes(val))
                    &&
                    <>
                        <Label labelText='Не для выбранных зубов' {...commonLabelOption} >
                            <Select {...commonSelectOption}
                                    isMulti
                                    colorSelect='white'
                                    value={state?.selectionRules?.targetNot}
                                    options={TEETH_NUMBERS.map(val => ({value: val, label: val}))}
                                    onChange={(val) => setState(prevState => (
                                        {
                                            ...prevState,
                                            selectionRules: {
                                                ...prevState.selectionRules,
                                                targetNot: val,
                                            },
                                        }
                                    ))}
                            />
                        </Label>
                        <Label labelText='Нет новых выполненных услуг из списка направлений' {...commonLabelOption} >
                            <Select {...commonSelectOption}

                                    isMulti
                                    colorSelect='white'
                                    value={state?.selectionRules?.notServicesDoneAfter}
                                    options={directionsOptions}
                                    onChange={(val) => setState(prevState => (
                                        {
                                            ...prevState,
                                            selectionRules: {
                                                ...prevState.selectionRules,
                                                notServicesDoneAfter: val,
                                            },
                                        }
                                    ))}
                            />
                        </Label>
                        <Label labelText='Принадлежит ли услуга к указанным типам' {...commonLabelOption} >
                            <Select {...commonSelectOption}

                                    isMulti
                                    colorSelect='white'
                                    value={state?.selectionRules?.serviceTypes}
                                    options={serviceTypesOptions}
                                    onChange={(val) => setState(prevState => (
                                        {
                                            ...prevState,
                                            selectionRules: {
                                                ...prevState.selectionRules,
                                                serviceTypes: val,
                                            },
                                        }
                                    ))}
                            />
                        </Label>
                        <Label labelText='Принадлежит ли услуга к указанным направлениям' {...commonLabelOption} >
                            <Select {...commonSelectOption}

                                    isMulti
                                    colorSelect='white'
                                    value={state?.selectionRules?.serviceDirections}
                                    options={directionsOptions}
                                    onChange={(val) => setState(prevState => (
                                        {
                                            ...prevState,
                                            selectionRules: {
                                                ...prevState.selectionRules,
                                                serviceDirections: val,
                                            },
                                        }
                                    ))}
                            />
                        </Label>
                    </>
                }
                {state.taskTypes?.includes('lead_plan') &&
                    <Label labelText='Привязка к причине откладывания лечения' {...commonLabelOption} >
                        <Select {...commonSelectOption}
                                colorSelect='white'
                                value={state.selectionRules?.postponeTreatmentReason}
                                options={postponeTreatmentOptions}
                                onChange={(val) => setState(prevState => (
                                    {
                                        ...prevState,
                                        selectionRules: {
                                            ...prevState.selectionRules,
                                            postponeTreatmentReason: val,
                                        },
                                    }
                                ))}
                        />
                    </Label>
                }
                {state.taskTypes?.includes('long_absence') && <>
                    <Label labelText='' {...commonLabelOption} >
                        <Box>
                            <Radio value={servicesTypeForLongAbsenceArr[0]}
                                   checked={servicesForLongAbsence === 'doneServices'}
                                   onClick={() => {
                                       setState(prevState => ({
                                           ...prevState,
                                           selectionRules: {
                                               hasUndoneServices: false,
                                               doneServices: prevState.selectionRules?.[servicesForLongAbsence]
                                           }
                                       }))
                                       setServicesForLongAbsence('doneServices');
                                   }}
                            >
                                Выполненные услуги
                            </Radio>
                            <Radio value={servicesTypeForLongAbsenceArr[1]}
                                   checked={servicesForLongAbsence === 'undoneServices'}
                                   onClick={() => {
                                       setState(prevState => ({
                                           ...prevState,
                                           selectionRules: {
                                               hasUndoneServices: true,
                                               undoneServices: prevState.selectionRules?.[servicesForLongAbsence]
                                           }
                                       }))
                                       setServicesForLongAbsence('undoneServices');
                                   }}
                            >
                                Невыполненные услуги
                            </Radio>
                        </Box>
                    </Label>

                    <Label labelText='Не для выбранных зубов' {...commonLabelOption} >
                        <Select {...commonSelectOption}
                                isMulti
                                colorSelect='white'
                                value={state.selectionRules?.[servicesForLongAbsence]?.targetNot}
                                options={TEETH_NUMBERS.map(val => ({value: val, label: val}))}
                                onChange={(val) => setState(prevState => (
                                    {
                                        ...prevState,
                                        selectionRules: {
                                            ...prevState.selectionRules,
                                            [servicesForLongAbsence]: {
                                                ...prevState.selectionRules?.[servicesForLongAbsence],
                                                targetNot: val,
                                            },
                                        },
                                    }
                                ))}
                        />
                    </Label>
                    <Label
                        labelText='Нет новых выполненных услуг из списка направлений' {...commonLabelOption} >
                        <Select {...commonSelectOption}
                                isMulti
                                colorSelect='white'
                                value={state.selectionRules?.[servicesForLongAbsence]?.notServicesDoneAfter}
                                options={directionsOptions}
                                onChange={(val) => setState(prevState => (
                                    {
                                        ...prevState,
                                        selectionRules: {
                                            ...prevState.selectionRules,
                                            [servicesForLongAbsence]: {
                                                ...prevState.selectionRules?.[servicesForLongAbsence],
                                                notServicesDoneAfter: val,
                                            },
                                        },
                                    }
                                ))}
                        />
                    </Label>
                    <Label labelText='Принадлежит ли услуга к указанным типам' {...commonLabelOption} >
                        <Select {...commonSelectOption}

                                isMulti
                                colorSelect='white'
                                value={state.selectionRules?.[servicesForLongAbsence]?.serviceTypes}
                                options={serviceTypesOptions}
                                onChange={(val) => setState(prevState => (
                                    {
                                        ...prevState,
                                        selectionRules: {
                                            ...prevState.selectionRules,
                                            [servicesForLongAbsence]: {
                                                ...prevState.selectionRules?.[servicesForLongAbsence],
                                                serviceTypes: val,
                                            },
                                        },
                                    }
                                ))}
                        />
                    </Label>
                    <Label labelText='Принадлежит ли услуга к указанным направлениям' {...commonLabelOption} >
                        <Select {...commonSelectOption}

                                isMulti
                                colorSelect='white'
                                value={state.selectionRules?.[servicesForLongAbsence]?.serviceDirections}
                                options={directionsOptions}
                                onChange={(val) => setState(prevState => (
                                    {
                                        ...prevState,
                                        selectionRules: {
                                            ...prevState.selectionRules,
                                            [servicesForLongAbsence]: {
                                                ...prevState.selectionRules?.[servicesForLongAbsence],
                                                serviceDirections: val,
                                            },
                                        },
                                    }
                                ))}
                        />
                    </Label>
                </>}

                <Label labelText='' {...commonLabelOption}>
                    <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                        <Button children='Сохранить'
                                size='md'
                                loader={loader}
                                variant={!!state.name.length && !!state.groupId ? 'success' : 'disabled'}
                                onClick={onClickSave}
                        />
                        <Box maxWidth='172px' margin='0 0 0 16px'>
                            <ValidationLinkInfo validate={!!state.name.length && !!state.groupId}
                                                onClickIsValidate={() => {
                                                    setValidationCheck(true);
                                                }} />
                        </Box>
                    </Box>
                </Label>
            </Box>

        </Box>
    );
};

export default ModalEditScript;
