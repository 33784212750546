import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import styled from "styled-components";
import Tooltip, {Effect, Place, Variant} from "../../../../../components-ui/DataDisplay/Tooltip/Tooltip";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import Checkbox from "../../../../../components-ui/Inputs/Checkbox/Checkbox";

const WrapTooltip = styled.div`
    display: block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 60px;
    text-align: center;
    color: #222;
    background: rgba(255, 255, 255, 1);
    box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.5);
    border: 1px solid #333;
    cursor: pointer;
`

export default function TooltipPage() {
    const [place, setPlace] = useState<Place>('top');
    const [variant, setVariant] = useState<Variant>('dark');
    const [effect, setEffect] = useState<Effect>('float');
    const [isArrow, setIsArrow] = useState(true);
    return (
        <>
            <h1>Tooltip (подсказки)</h1>
            <p>Всплывающие подсказки отображают информативный текст когда пользователь наводит курсор на элемент,
                фокусируется на нем или нажимает на него.</p>
            <WrapContent>
                <div>
                    <WrapTooltip data-for="main"
                                 data-tip="Hello<br />multiline<br />tooltip">
                        ◕‿‿◕
                    </WrapTooltip>
                    <Tooltip id="main" place={place} effect={effect} variant={variant} isArrow={isArrow}/>
                    <pre>
                        {
                            `
<WrapTooltip data-for="main"
            data-tip="Hello<br />multiline<br />tooltip">
        ◕‿‿◕
</WrapTooltip>
<Tooltip id="main" place={${place}} effect={${effect}} variant={${variant}} isArrow={${isArrow}}/>
`
                        }
                    </pre>
                </div>
                <div style={{width: '600px', display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                    <Button variant={place === 'top' ? 'success' : 'default'} children="place-top" onClick={() => setPlace('top')} />
                    <Button variant={place === 'right' ? 'success' : 'default'} children="place-right" onClick={() => setPlace('right')} />
                    <Button variant={place === 'left' ? 'success' : 'default'} children="place-left" onClick={() => setPlace('left')} />
                    <Button variant={place === 'bottom' ? 'success' : 'default'} children="place-bottom" onClick={() => setPlace('bottom')} />
                </div>
                <div style={{width: '300px', display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                    <Button variant={variant === 'dark' ? 'success' : 'default'} children="variant-dark" onClick={() => setVariant('dark')} />
                    <Button variant={variant === 'white' ? 'success' : 'default'} children="variant-white" onClick={() => setVariant('white')} />
                </div>
                <div style={{width: '300px', display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                    <Button variant={effect === 'float' ? 'success' : 'default'} children="effect-float" onClick={() => setEffect('float')} />
                    <Button variant={effect === 'solid' ? 'success' : 'default'} children="effect-solid" onClick={() => setEffect('solid')} />
                </div>
                <div style={{width: '300px', display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                    <Checkbox label='isArrow' checked={isArrow} onChange={(value: boolean) => setIsArrow(value)} />
                </div>
            </WrapContent>
        </>
    )
}
