import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {EditActualAccountParams, GetPaymentsCheckFormResult, IPaymentsFilters} from '../../../types';
import DateHelper from '../../../../../common/helpers/date-helper';
import PriceHelper from '../../../../../common/helpers/price-helper';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import {useSelector} from 'react-redux';
import Helper from '../../../../../common/helpers/main';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import PatientCard from '../../../../PatientCard/PatientCard';
import TextField from '../../../../../components-ui/Inputs/TextField/TextField';
import Box from '../../../../../components-ui/Layout/Box';
import Icon from '../../../../../components-ui/DataDisplay/Icon';
import _ from 'lodash';
import ServerCommand from '../../../../../common/server/server-command';
import {IPaymentRow, IPaymentService} from '../types';
import {OptionSelect} from '../../../../../components-ui/Inputs/Select/typesSelect';
import Tooltip from '../../../../../components-ui/DataDisplay/Tooltip/Tooltip';
import {useDevice} from '../../../../../common/hooks/useDevice';
import Invoice from '../../../../PatientCard/Section/Invoice/Invoice';
import TableScrollHeaderComponent from '../components/TableScrollHeaderComponent';
import ModalDeletePayment from '../components/ModalDeletePayment';
import {
    DEFAULT_PADDING_CELLS,
    DEFAULT_PADDING_HEADER_CELLS,
    END_PADDING_CELL,
    END_PADDING_HEADER_CELLS,
} from '../../../../../components-ui/DataDisplay/Table/common';
import {InnerTable, CustomCell} from '../../../../../components-ui/DataDisplay/Table/style/scrollStyledComponents';
import {WIDTH_TABLE_COLUMNS} from '../common';

interface Props {
    data: GetPaymentsCheckFormResult;
    selectedBranch: OptionSelect;
    filters: IPaymentsFilters;
    refreshDataFromServer: () => void,
}

const useTableFormatter = ({data, selectedBranch, filters, refreshDataFromServer}: Props) => {

    const device = useDevice();

    const currentDate = DateHelper.formatDateForServer(new Date());

    const {showSlideModal, showModal} = useDialogsManager();

    const paymentMethods = useSelector(state => state.common.paymentMethods);

    const servicesNames = useSelector(state => state.common.servicesNames);

    const doctors = useSelector(state => state.common.doctors);

    const [paymentRows, setPaymentRows] = useState<IPaymentRow[]>([]);

    const sendActualAccountParams = (row: IPaymentRow, value: string) => {
        !isNaN(+value) &&
        ServerCommand
            .post('patient/edit-actual-account', {
                id: row.patientId,
                account: !value.length ? null : +value,
                branchId: selectedBranch.value,
            } as EditActualAccountParams).then();

    };

    const scrollClassName = 'tablePaymentsScroll';

    const handleScroll = useCallback((scrollPosition: number) => {
        document.querySelectorAll(`.${scrollClassName}`).forEach((item) => {
            item.scrollLeft = scrollPosition;
        });
    }, []);

    const sendDebounced = useCallback(_.debounce(sendActualAccountParams, 3000), []);

    const onChangeTrueBalance = (row: IPaymentRow, value: string) => {
        //TODO слетают значения фактического баланса при применении фильтра из-за изменения не data.payments, а внутреннего стэйта
        setPaymentRows(prevState => {
            return prevState.map((paymentRow) => {
                return paymentRow.patientId === row.patientId ? {
                    ...paymentRow,
                    trueBalance: value,
                } : paymentRow;
            });
        });
        sendDebounced(row, value);
    };

    const paymentColumns: IColumn<IPaymentRow>[] = useMemo(() => [
        {
            field: 'date',
            headerName: 'Дата',
            width: WIDTH_TABLE_COLUMNS.date,
            paddingCell: DEFAULT_PADDING_CELLS,
            paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
        },
        {
            field: 'fio',
            headerName: 'ФИО пациента',
            width: WIDTH_TABLE_COLUMNS.fio,
            paddingCell: DEFAULT_PADDING_CELLS,
            paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
            valueFormatter: ({row, childrenCell}) =>
                <Typography color='info'
                            variant='small'
                            isPartHidden={row.services.length <= 1}
                            cursor='pointer'
                            onClick={() => showSlideModal(<PatientCard
                                patientId={row.patientId} />)}>{childrenCell}</Typography>,

        },
        {
            field: 'paymentMethod',
            width: '29vw',
            headerFormatter: () => <TableScrollHeaderComponent scrollClassName={scrollClassName}
                                                               handleScroll={handleScroll} />,
            paddingHeaderCell: '',
            paddingCell: '',
            customStyles: {flexGrow: 1},
            valueFormatter: (({row}) =>
                    <InnerTable scrollClassName={scrollClassName} handleScroll={handleScroll}>
                        <Box padding='0 5px 0 25px'
                             boxSizing='content-box'
                             display='flex'
                             width={`calc(${WIDTH_TABLE_COLUMNS.paymentMethod} + ${WIDTH_TABLE_COLUMNS.invoice} + ${WIDTH_TABLE_COLUMNS.services} + ${WIDTH_TABLE_COLUMNS.doctors});`}
                             height='inherit'
                             justifyContent='space-between'
                             alignItems='stretch'>
                            {
                                row.services.length <= 1 ?
                                    <Box padding='12px 0' width={WIDTH_TABLE_COLUMNS.paymentMethod}>
                                        <Typography
                                            width={WIDTH_TABLE_COLUMNS.paymentMethodCovered}
                                            variant='small'
                                            isPartHidden={true}
                                            data-for={row.paymentMethod}
                                            data-tip={row.paymentMethod}>
                                            {row.paymentMethod}
                                        </Typography>
                                        <Tooltip id={row.paymentMethod} effect='solid' />
                                    </Box>
                                    :
                                    <Box padding='12px 0' width={WIDTH_TABLE_COLUMNS.paymentMethod}>
                                        <Typography variant='small'>
                                            {row.paymentMethod}
                                        </Typography>
                                    </Box>
                            }
                            {
                                row.invoices.length ?
                                    <Box width={WIDTH_TABLE_COLUMNS.invoice}>
                                        {row.invoices.map(invoice =>
                                            <Box
                                                padding={DEFAULT_PADDING_CELLS}
                                                display='flex'
                                                alignItems='center'
                                                onClick={() => showSlideModal(
                                                    <Invoice
                                                        invoiceId={invoice}
                                                        servicesGroups={data.servicesGroups}
                                                    />,
                                                )}
                                            >
                                                <Typography as='span'
                                                            cursor='pointer'
                                                            color='info'
                                                            variant='small'>
                                                    Счёт №{invoice}
                                                </Typography>
                                            </Box>,
                                        )}
                                    </Box>

                                    :
                                    <Box width={WIDTH_TABLE_COLUMNS.invoice} />
                            }


                            <CustomCell padding='0 0 0 1px' width={WIDTH_TABLE_COLUMNS.services} isGrow={true}>
                                {row.services.map((service: IPaymentService) => {
                                        if ((device === 'desktop' && service.serviceName.length > 40) ||
                                            (device !== 'desktop' && service.serviceName.length > 26)) {
                                            return <>
                                                <Typography
                                                    variant='small'
                                                    isPartHidden={true}
                                                    data-for={service.serviceName}
                                                    data-tip={service.serviceName}>
                                                    {service.serviceName}
                                                </Typography>
                                                <Tooltip id={service.serviceName} effect='solid' />
                                            </>;
                                        } else {
                                            return <>
                                                <Typography variant='small'>{service.serviceName}</Typography>
                                            </>;
                                        }
                                    },
                                )}
                            </CustomCell>
                            {
                                !!row.doctors.length ?
                                    <CustomCell width={WIDTH_TABLE_COLUMNS.doctors}>
                                        {row.doctors.map((doctor) =>
                                            <Typography variant='small'>{doctor}</Typography>,
                                        )}
                                    </CustomCell>
                                    :
                                    <Box width={WIDTH_TABLE_COLUMNS.doctors} />
                            }

                        </Box>
                    </InnerTable>
            ),
        },
        {
            field: 'sum',
            headerName: 'Сумма, руб.',
            width: WIDTH_TABLE_COLUMNS.sum,
            paddingCell: '',
            paddingHeaderCell: '0 0 0 17px',
            valueFormatter: (({row}) =>
                    <CustomCell width={WIDTH_TABLE_COLUMNS.sum}>
                        {row.services.map((service: IPaymentService) =>
                            <Typography variant='small'>{PriceHelper.format(service.sum)}</Typography>,
                        )}
                    </CustomCell>
            ),
        },
        {
            field: 'balance',
            headerName: 'Баланс, руб.',
            width: WIDTH_TABLE_COLUMNS.balance,
            paddingCell: DEFAULT_PADDING_CELLS,
            paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
            valueFormatter: ({row}) => {
                const isBordered = () => {
                    if (row.balance > 0) {
                        if (+row.balance !== +row.trueBalance) {
                            if (row.trueBalance === null || !row.trueBalance.toString().length)
                                return 'warningBright';
                            else
                                return 'danger';
                        }

                    } else if (+row.balance !== +row.trueBalance) {
                        if (row.trueBalance === null || !row.trueBalance.toString().length)
                            return undefined;
                        else
                            return 'danger';
                    }
                };
                return (
                    < Typography
                        width='max-content'
                        variant='small'
                        height='24px'
                        padding='0 5px'
                        borderedColor={isBordered()}>
                        {PriceHelper.format(row.balance)}
                    </Typography>
                );

            },
        },
        {
            field: 'trueBalance',
            headerName: 'Фактический баланс, руб.',
            width: WIDTH_TABLE_COLUMNS.trueBalance,
            paddingCell: END_PADDING_CELL,
            paddingHeaderCell: END_PADDING_HEADER_CELLS,
            valueFormatter: ({row}) => {
                if (row.managementEndDate === null || row.managementEndDate > currentDate) {
                    return <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <TextField
                            widthInput='100%'
                            value={row.trueBalance !== null ? row.trueBalance : ''}
                            size='min'
                            isNumber={true}
                            placeholder=''
                            onChange={(value) => onChangeTrueBalance(row, value)}
                        />
                        <Box cursor='pointer' margin='0' padding='0 7px' onClick={() => {
                            showModal(<ModalDeletePayment refreshDataFromServer={refreshDataFromServer}
                                                          selectedBranch={selectedBranch} id={row.id} price={row.sum} isCheck={row.checks.length > 0} />);
                        }
                        }>
                            <Icon width='8px' height='8px' name='delete' color='danger' />
                        </Box>
                    </Box>;
                } else {
                    return row.trueBalance !== null && <Typography>{PriceHelper.format(+row.trueBalance)}</Typography>;
                }
            },
        },
    ], [currentDate, refreshDataFromServer]);

    useEffect(() => {
        handleScroll(0);
        setPaymentRows(
            data.payments
                .map((payment) => {
                    return ({
                        id: payment.id,
                        patientId: payment.patientId,
                        date: DateHelper.formatDate(payment.date, 'DD MMM YYYY'),
                        fio: Helper.getFullName(data.patients[payment.patientId]),
                        services: payment.distribution.map(service => {
                                if (typeof service.serviceCode === 'number') {
                                    return {serviceName: servicesNames[+service.serviceCode], sum: service.sum};
                                } else {
                                    if (service.serviceCode === 'additional') {
                                        return {serviceName: 'Доп. услуги', sum: service.sum};
                                    } else {
                                        return {serviceName: 'Аванс', sum: service.sum};
                                    }
                                }
                            },
                        ),
                        doctors: !!payment.doctors.length ? doctors.filter(doc => payment.doctors.includes(doc.id)).map(doc => doc.fio) : [],
                        invoices: payment.invoices,
                        checks: payment.checks,
                        paymentMethod: paymentMethods[payment.method],
                        balance: data.patients[payment.patientId].account,
                        sum: payment.sum,
                        trueBalance: data.patients[payment.patientId].actualAccount,
                        managementEndDate: payment.managementEndDate,
                    });
                })
                .filter((payment) =>
                    !!filters.paymentMethod.value ?
                        payment.paymentMethod.includes(filters.paymentMethod.label) :
                        payment,
                ).filter((payment) =>
                !!filters.doctor.value ?
                    payment.doctors.includes(filters.doctor.label) :
                    payment,
            ).filter((payment) =>
                payment.fio.toLowerCase().includes(filters.patient.toLowerCase()),
            ).filter((payment) =>
                payment.services.find((service) => service.serviceName.toLowerCase().includes(filters.service.toLowerCase())),
            ),
        );
    }, [data.payments, filters]);

    return {paymentRows, paymentColumns};
};

export default useTableFormatter;
