import React, {useState} from "react";
import TextField from "../../../../../../components-ui/Inputs/TextField/TextField";

export default function Search() {
    const [value, setValue] = useState('');
    return (
        <div>
            <TextField value={value}
                       onChange={(val:string) => setValue(val)}
                       placeholder="Поиск пациента по ФИО и телефону" isSearch={true} widthInput={'100%'}/>
            <pre>
                {
                    `<TextField value={value}
            onChange={(val) => setValue(val)}
            placeholder="Поиск пациента по ФИО и телефону"
            isSearch={true}
            widthInput={\'100%\'}/>`
                }
            </pre>
        </div>
    )
}
