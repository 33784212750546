import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth56Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 23.94 15.88"} width={width || "22"} height={height || "15"} ref={ref}>
            <path d="M23.88,8.8c-.47,3.39-1.32,5.5-2.54,6.24s-3.07.13-5-.55a13.71,13.71,0,0,0-4.37-1,6.46,6.46,0,0,0-3.11,1.17C6.6,16,4.46,17.21,1.15,12.78A6.43,6.43,0,0,1,.54,6.52C1.68,3.34,4.33.74,6.17.28A7.08,7.08,0,0,1,8.06,0,11.5,11.5,0,0,1,9.24.08a27.52,27.52,0,0,0,5.95.07,6.75,6.75,0,0,1,3.22.21,9.07,9.07,0,0,1,3.31,2.25A7.49,7.49,0,0,1,23.89,8.8Z"/>
        </WrapIcon>
    );
});
