import React, {Dispatch, SetStateAction} from "react";
import {IDirectionsBalance, IStatePaymentCreationForm, IPaymentCreationFormValues} from "../../types";
import useDirectionsFields from "./hooks/useDirectionsFields";
import DirectionField from "./components/DirectionField";

interface Props {
    values: IPaymentCreationFormValues,
    setState: Dispatch<SetStateAction<IStatePaymentCreationForm>>
    directionsBalance: IDirectionsBalance
}

export const DirectionsFields = ({directionsBalance, ...props}: Props) => {
    const fields = useDirectionsFields({directionsBalance})
    return (
        <>
            {fields.map(field => <DirectionField key={field.code} field={field} {...props} />)}
        </>
    )
};
