import React from 'react';
import {useModal} from '../../../../../../containers/DialogsManager/containers/Modal/Modal';
import ServerCommand from '../../../../../../common/server/server-command';
import {StandardDeletionParams} from '../../../../../../types';
import ConfirmModal from '../../../../../modals/ConfirmModal';

interface Props {
    id: number
    refreshDataFromServer: () => void
    name: string
}

const DeleteGroup = ({id, refreshDataFromServer, name}: Props) => {
    const {closeModal} = useModal()

    const deleteLab = () => {
        ServerCommand.post('call-center/script-group/delete', {id: id} as StandardDeletionParams, () => {
            closeModal()
            refreshDataFromServer();
        }).then()
    }

    return (
        <ConfirmModal
            descrText={`Вы точно хотите удалить группу ${name}?`}
            submitText="Удалить группу"
            onClose={closeModal}
            onSumbit={deleteLab}/>
    );
};

export default DeleteGroup;
