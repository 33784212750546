import React, {useState} from "react";
import SaveButtons from "./SaveButtons";
import {useEditableField} from "../EditableField";
import {ValueEditableField} from "../typesEditableField";
import ServerCommand from "../../../common/server/server-command";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";

export default function FieldCheckbox() {
    const {field, patientId, setEditField} = useEditableField();
    const values = Object.values(field.values)[0] as ValueEditableField;
    const [value, setValue] = useState(values.value);

    const onClickSave = () => {
        const command = new ServerCommand('patient/edit', {id: patientId, [values.name]: value});
        command.exec().then(() => {
            field.onChange(value);
            setEditField(false);
        });
    }
    return (
        <>
            <Checkbox label='' checked={value} onChange={setValue} />
            <SaveButtons onClickSave={onClickSave}/>
        </>
    )
}
