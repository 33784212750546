import React from "react";
import Helper from "../../../common/helpers/main";
import { SWrapTextPhone, SItemTextPhone, STitleTextPhone, STextPhone } from "../../PatientField/style/phoneView";

interface PTextPhone {
    personalPhone: string,
    additionalPhone: string,
    phoneOwner: string | null | number
}

export default function TextPhone({personalPhone, additionalPhone, phoneOwner}: PTextPhone) {

    return (
        <SWrapTextPhone>
            <SItemTextPhone>
                <STitleTextPhone>{phoneOwner === 'self' ? 'личный' : 'контактный'}</STitleTextPhone>
                <STextPhone onClick={event => event.stopPropagation()}
                            href={`tel:${personalPhone}`}>
                    {Helper.Phone.format(personalPhone)}
                </STextPhone>
            </SItemTextPhone>
            {!!additionalPhone && (
                <SItemTextPhone>
                    <STitleTextPhone>контактный</STitleTextPhone>
                    <STextPhone onClick={event => event.stopPropagation()}
                                href={`tel:${additionalPhone}`}>
                        {Helper.Phone.format(additionalPhone)}
                    </STextPhone>
                </SItemTextPhone>
            )}
        </SWrapTextPhone>
    )
}
