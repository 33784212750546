import React from 'react';
import {WrapTooltip, HeaderTooltip, ListTooltip, ItemTooltip} from "../../style/tooltip";
import Typography from "../../../DataDisplay/Typography/Typography";
import _ from 'lodash'

interface Props {
    active?: any
    payload?: any
    label?: any
    formatterTitleTooltip?: (value: string) => any
    formatterNameTooltip?: (name: any) => any
    formatterValue?: (value: number) => string
    [key: string]: any
}

const CustomTooltip = (props: Props) => {
    const {active, payload, formatterNameTooltip, formatterTitleTooltip, formatterValue} = props
    if (active) {
        if (!!payload && !!payload.length && !!payload[0].payload) {
            const item = payload[0].payload
            return (
                <WrapTooltip>
                    <HeaderTooltip>
                        <Typography children={!!formatterTitleTooltip ? formatterTitleTooltip(item.code) : item.code}
                                    color="white"
                                    variant="min"
                                    margin="0 16px 0 0"
                                    whiteSpace="nowrap"
                        />
                    </HeaderTooltip>
                    <ListTooltip>
                        <ItemTooltip>
                            <Typography children={!!formatterNameTooltip ? formatterNameTooltip('barOne') : ''}
                                        variant="min"
                                        color="white"
                                        margin="0 16px 0 0"
                                        whiteSpace="nowrap"/>
                            <Typography children={!!formatterValue ? formatterValue(item.barOne) : item.barOne}
                                        variant="min"
                                        color="white"
                                        whiteSpace="nowrap"/>
                        </ItemTooltip>
                        <ItemTooltip>
                            <Typography children={!!formatterNameTooltip ? formatterNameTooltip('barTwo') : ''}
                                        variant="min"
                                        color="white"
                                        margin="0 16px 0 0"
                                        whiteSpace="nowrap"/>
                            <Typography children={!!formatterValue ? formatterValue(item.barTwo) : item.barTwo}
                                        variant="min"
                                        color="white"
                                        whiteSpace="nowrap"/>
                        </ItemTooltip>
                    </ListTooltip>
                </WrapTooltip>
            );
        } else {
            return null
        }
    } else {
        return null
    }
};

export default CustomTooltip;
