import React, {Dispatch, SetStateAction, useEffect} from "react";
import SVG from "../../../../../common/helpers/SVG";
import {SPasswordToggle} from "./styledPasswordToggle";

interface Props {
    passwordVisible: boolean
    setPasswordVisible: Dispatch<SetStateAction<boolean>>
    isPassword?: boolean
}

export default function PasswordToggle({isPassword, passwordVisible, setPasswordVisible}: Props) {

    useEffect(() => {
        if (isPassword) setPasswordVisible(true);
    }, []);

    if (!isPassword) return null;
    return (
        <SPasswordToggle
            onClick={() => setPasswordVisible(!passwordVisible)}>
            <SVG name={!passwordVisible ? "no_pas" : "yes_pas"}/>
        </SPasswordToggle>
    )
}
