import {ManagerTask} from "../../../types/common";
import DateHelper from "../../../../../common/helpers/date-helper";

const getMinMaxDateTime = (selectedDate: any, task: ManagerTask, currentDate: any) => {
    const minDate = new Date(currentDate);
    let minTime = new Date(currentDate);
    minTime.setSeconds(0)
    let maxDate = new Date(currentDate)
    maxDate.setFullYear(maxDate.getFullYear() + 1)
    let maxTime = new Date(maxDate)
    maxTime.setHours(22)
    maxTime.setMinutes(0)
    maxTime.setSeconds(0)

    if (!DateHelper.areEqualDates(selectedDate, minDate)) {
        minTime.setHours(8)
        minTime.setMinutes(0)
    } else {
        if (minTime.getMinutes() > 30) {
            minTime.setHours(minTime.getHours() + 1)
            minTime.setMinutes(0)
        } else {
            minTime.setMinutes(30)
        }
    }

    if (task.deadline !== null) {
        let minDeadline = new Date(task.deadline as any)
        maxDate = minDeadline
        if (maxDate.getHours() < 8) {
            maxDate.setDate(maxDate.getDate() - 1)
        }
        if (minDeadline.getHours() === 0) {
            maxDate.setHours(21);
            maxDate.setMinutes(30);
        }

        if (DateHelper.areEqualDates(selectedDate, maxDate)) {
            maxTime = new Date(maxDate)
            maxTime.setSeconds(0)
        }
    }

    return {
        minDate,
        minTime,
        maxDate,
        maxTime
    }
}

export default getMinMaxDateTime
