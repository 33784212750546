import React from 'react'
import PropTypes from 'prop-types'
import './MultipleEnumField.scss'
import SelectCheckbox from "../../components/Table/Elements/SelectCheckbox";
import Obj from "../../common/helpers/object";
import Helper from "../../common/helpers/main";
import Arr from "../../common/helpers/Arr";

export default function MultipleEnumField(props) {
    const {onChange} = props;
    const value = props.value ? props.value : [];
    const options = markCheckedOptions(props.options, value);

    const handleSelectChange = (option, isSelected) => {
        let newValue;

        if(isSelected && !value.includes(option.value)) {
            newValue = Helper.clone(value);
            newValue.push(option.value);
        } else {
            newValue = Arr.delete(value, option.value)
        }

        onChange(newValue);
    };

    return (
        <div className="multiple-enum-field">
            <SelectCheckbox
                options={options}
                onChange={handleSelectChange}
            />
        </div>
    );
}

MultipleEnumField.propTypes = {
    value: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

/**
 * Получает массив опций селекта по списку имеющихся допольнительных услуг
 * @param {array} options - массив id услуг, которые будут отмечены как выбранные
 * @param {array} selectedValues - массив id услуг, которые будут отмечены как выбранные
 * @return {*}
 */
function markCheckedOptions(options, selectedValues) {
    const selectedValuesMap = Obj.fill(selectedValues, true);
    options = Helper.clone(options);
    options.forEach(option => option.checked = selectedValuesMap[option.value]);
    return options;
}
