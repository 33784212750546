import React, {useState} from "react";
import {SInitialSurvey, Values} from "../types";
import {TemplatesItem} from "../../../common/types/templates";
import * as _ from "underscore";
import {defineParameters} from "../../Parameters/helperParameters";
import Helper from "../../../common/helpers/main";

interface SValues {
    values: Values,
    openModalParameters: boolean,
    defineText: any
}

export interface HandlersValues {
    setValues: (values: Values) => void,
    addFromTemplate: (template: TemplatesItem, isDefine: boolean) => void,
    handleFieldChange: (fieldName: string, value: string) => void,
    canceledValueFields: () => void,
    closeModalParameters: () => void
}

export default function useValues (snapshot: any, setState: any, state: any) {
    const [values, setValues] = useState<Values | {}>({}); // Значение всех полей первичного осмотра
    const [openModalParameters, setOpenModalParameters] = useState(false); // Если есть параметры, открывает модальное окно
    const [defineText, setDefineText] = useState([]); // Значение полей при наличие параметров в тексте

    /** Подготавливает текст для добавления из шаблона в поле */
    const prepareNewValueTemplate = (values: Values, fieldName: string, value: string) => {
        if (values !== undefined) {
            if (values[fieldName] !== undefined) {
                let newValue = values[fieldName] as any;
                if (newValue === '') {
                    newValue = []
                } else {
                    newValue = newValue.split(', ');
                }
                newValue.push(value);
                values[fieldName] = newValue.join(', ');

            } else {
                values[fieldName] = value;
            }
        } else {
            values = {};
            if (values[fieldName] === undefined) {
                values[fieldName] = value;
            }
        }

    };

    /** Добавляет из шаблона в поле */
    const addFromTemplate = (template: TemplatesItem, isDefine: boolean = true) => {
        const newValues = _.clone(values);
        const {field, text} = template;
        if (isDefine) {
            const define = _.clone(template) as any;
            define.text = defineParameters(template.text);
            if (define.text.length > 1) {
                setDefineText(define)
                setOpenModalParameters(true);
            } else {
                prepareNewValueTemplate(newValues, field, text)
            }
        } else {
            prepareNewValueTemplate(newValues, field, text);
            setOpenModalParameters(false);
        }
        setValues(newValues)
    };

    /** Меняет текст в поле*/
    const handleFieldChange = (fieldName: string, value: string) => {
        let newValues = _.clone(values) as any;
        newValues[fieldName] = value;
        setValues(newValues);
    };

    /** Отменяет введенные действия до последнего снимка экрана*/
    const canceledValueFields = () => {
        const cloneSnap = Helper.clone(snapshot);
        if (cloneSnap.values !== undefined) {
            setValues(cloneSnap.values)
            const newState = Helper.clone(state);
            newState.teethMap.config = cloneSnap.teethMap;
            setState(newState);
        }
    };

    return [
        {
            values,
            openModalParameters,
            defineText
        },
        {
            setValues,
            addFromTemplate,
            handleFieldChange,
            canceledValueFields,
            closeModalParameters: () => setOpenModalParameters(false)
        }
    ] as [SValues, HandlersValues]
}
