import {useEffect} from "react";
import Helper from "../../../common/helpers/main";
import _ from 'lodash';
import {useSlideModal} from "../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {PropsUseUpdateNewVisit} from "../types/hooks";

export default function useUpdateNewVisit({form, props, initialForm} : PropsUseUpdateNewVisit) {
    const {labelParams, removeMinimizeParams, setMinimizeParams } = useSlideModal();
    useEffect(() => {
        if (!_.isEqual(form, initialForm)) {
            if (!labelParams) {
                setMinimizeParams({
                    icon: 'newVisitForm',
                    title: 'Запись на приём',
                    text: `${Helper.Date.formatDate(props.date, 'DD.MM.YYYY', 'YYYY-MM-DD')} на ${props.timeStart}, кресло ${props.chairId}`,
                    canBeDeleted: true,
                    keySlideModal: props.timeStart + props.chairId,
                })
            }
        } else {
            if (!!labelParams) {
                removeMinimizeParams()
            }
        }
    }, [form])
}
