import React, {ReactChild, ReactNode, Fragment, forwardRef} from 'react';
import {STableHeader, STableHeaderCell} from "../style/common";
import {IColumn} from "../types/common";
import {VariantTable} from "../types/components";
import Icon from "../../Icon";
import {StateSortTable} from "../hooks/useSortTable";
import Box from "../../../Layout/Box";

interface Props {
    isWrap: boolean
    header?: ReactNode | ReactChild | any
    columns: IColumn[]
    variantTable: VariantTable
    fontSizeRow?: string
    isScrolling: boolean
    stateColumnSort: StateSortTable
    handleStateColumnSort: (fieldName: string) => void
    customRowsCells?: boolean
}

const Header = forwardRef((
    {
        isWrap,
        header,
        columns,
        variantTable,
        fontSizeRow,
        isScrolling,
        handleStateColumnSort,
        stateColumnSort,
        customRowsCells
    }: Props, ref: any) => {

    return (
        <STableHeader isScrolling={isScrolling} customRowsCells={customRowsCells} fontSizeRow={fontSizeRow} isWrap={isWrap} isHeader={!!header}
                      variantTable={variantTable} isSortable={columns.some(column => column.sortable)} ref={ref as any}>
            {columns.map(column => {
                let nameIcon = column.defaultSort !== undefined ? column.defaultSort ? 'sortAsc' : 'sortDesc' : 'sortDefault';
                if (stateColumnSort[column.field] !== null && stateColumnSort[column.field] !== undefined) {
                    nameIcon = stateColumnSort[column.field] ? 'sortAsc' : 'sortDesc'
                } else if (stateColumnSort[column.field] === null) {
                    nameIcon = 'sortDefault';
                }

                let sortable = (
                    <>
                        {!!column.sortable && (
                            <STableHeaderCell sortPosition={column.sortPosition || 'right'}
                                              align="left"
                                              variantTable={variantTable}
                                              width="36px"
                                              isSort={true}
                                              onClick={() => handleStateColumnSort(column.field)}
                                              cursor="pointer"
                                              children={(
                                                  <Icon name={nameIcon}
                                                        width="11px" height="11px"/>
                                              )}/>
                        )}
                    </>
                ) as any

                let content = (
                    <STableHeaderCell variantTable={variantTable} align={column.align || 'left'}
                                      style={column.customStyles}
                                      customRowsCells={customRowsCells}
                                      customPaddingCells={column.paddingHeaderCell}
                                      children={!column.headerFormatter ? column.headerName : column.headerFormatter()} width={column.width}
                                      cursor={!!column.sortable ? 'pointer' : 'default'}
                                      onClick={() => {
                                          if (!!column.sortable) handleStateColumnSort(column.field)
                                      }}
                    />
                )

                if (!!column.sortPosition && column.sortPosition === 'right') {
                    sortable = null
                    content = (
                        <STableHeaderCell variantTable={variantTable} align={column.align || 'left'}
                                          children={(
                                              <Box as="span" display="flex" alignItems="center"
                                                   width={!!column.width ? column.width + 'px' : '100%'}
                                                   minHeight={variantTable === 'outline' ? '35px' : 'auto'}
                                              >
                                                  <Box display="flex" alignItems="center"
                                                       cursor="pointer"
                                                       minHeight="inherit"
                                                       onClick={() => handleStateColumnSort(column.field)}>
                                                      {column.headerName}
                                                      <Icon
                                                          name={nameIcon}
                                                          width="11px"
                                                          height="11px"
                                                          margin="0 0 0 8px"/>
                                                  </Box>
                                              </Box>
                                          )} width={column.width}/>
                    )
                }
                return (
                    <Fragment key={column.field}>
                        {content}
                        {sortable}
                    </Fragment>
                )
            })}
        </STableHeader>
    );
})

export default Header;
