import Helper from "./main"
import Obj from "./object"
import _ from 'underscore'

const Arr = {


    /**
     * Возвращает элементы из первого массива, которых нет во втором массиве
     * @param {array} array1
     * @param {array} array2
     * @return {*}
     */
    diff: function<T> (array1: T[], array2: T[]) : T[] {
        let curArray = this.unique(array1);

        return curArray.filter(value =>
        {
            return (array2.indexOf(value) === -1);
        });
    },

    /**
     * Удаляет из массива повторяющиеся элементы
     * @param array
     * @return {*}
     */
    unique: function<T> (array: T[]) : T[] {
        return array.filter((value, index) =>
        {
            return (array.indexOf(value) === index);
        });
    },

    /**
     * Создаёт объект из массива. В качестве ключей объекта использует значения указанного поля дочерних объектов массива.
     * @param array
     * @param keyFieldOrHandler - поле дочерних объектов массива, значения которого нужно будут использованы в качестве ключей создаваемого объекта.
     * @param deleteKey - если true - удаляет из дочерних объектов поле, значения которого были использованы в качестве ключей
     */
    toObject: function<T>(array: T[], keyFieldOrHandler: string|KeyFieldCallback|null = null, deleteKey: boolean = false) : Map<T> {
        let result: Map<T> = {};
        let getKey: KeyFieldCallback|null = null;

        if(keyFieldOrHandler !== null) {
            getKey = (typeof keyFieldOrHandler === 'function') ? keyFieldOrHandler : ((item: any) => item[keyFieldOrHandler]);
        }

        if(deleteKey) {
            array = Helper.clone(array);
        }

        array.forEach((item, index) => {
            let key = (getKey ? getKey(item) : index);

            if(key && deleteKey) {
                delete (item as any)[key];
            }

            result[key] = item;
        });

        return result;
    },

    /**
     * Превращает массив в объект, меняя ключи и значения местами
     * @param array
     */
    flip(array: (number|string)[]) : FlipMap {
        let result: FlipMap = {};
        array.forEach((item, index) => result[item] = index);
        return result;
    },

    /**
     * Сжимает переданный массив array по указанным полям byFields - для каждой встреченной комбинации byFields оставляет
     * единственный элемент с указанием числа повторений в поле quantity (по умолчанию)
     * @param {array} array
     * @param {array|function} byFields - массив полей, либо функция, возвращающая уникальную строку для каждой комбинации
     * по переданному элементу массива
     * @param {object} additionalParams - дополнительные параметры:
     *  quantityField - название поля, в которое будет заноситься количество
     *  stackFields - названия полей, которые необходимо поместить в стек для возможности их последующего восстановления
     *      функцией expand
     *  handler - дополнительная функция-обработчик сжатия для добавления дополнительных полей в сжатый элемент. В качестве
     *      аргументов получает массив изначальных элементов для текущей комбинации и сжатый элемент
     * @return {array}
     */
    squeeze(array: any[], byFields: any, additionalParams : any = {}) {
        let squeezedItems: any = {};
        let originalItemsMap: any = {};
        let {stackFields, handler, quantityField, getItemKey} = additionalParams;

        if(!quantityField) {
            quantityField = 'quantity';
        }

        array.forEach(item => {
            let key: any;

            if(typeof byFields === 'function') {
                key = byFields(item);
            } else {
                key = byFields.map((fieldName: any) => item[fieldName]).join('_');
            }

            if(key in squeezedItems) {
                let squeezedItem = squeezedItems[key];
                squeezedItem[quantityField] += (quantityField in item) ? item[quantityField] : 1;
            } else {
                let squeezedItem = Helper.clone(item);
                squeezedItem[quantityField] = (quantityField in squeezedItem) ? squeezedItem[quantityField] : 1;
                squeezedItems[key] = squeezedItem;

                if (getItemKey) {
                    squeezedItem.itemKey = key;
                }
            }

            if(stackFields) {
                if(!squeezedItems[key].fieldsStack) {
                    squeezedItems[key].fieldsStack = [];
                }

                squeezedItems[key].fieldsStack.push(
                    Helper.filterObj(item, (fieldValue: any, fieldName: any) => stackFields.includes(fieldName))
                );

                stackFields.forEach((fieldName: any) => delete squeezedItems[key][fieldName]);
            }

            if(!!handler) {
                if(!originalItemsMap[key]) {
                    originalItemsMap[key] = [];
                }
                originalItemsMap[key].push(item);
            }
        });

        if(!!handler) {
            Obj.forEach(squeezedItems, (squeezedItem: any, key: any) => {
               handler(originalItemsMap[key], squeezedItem, key);
            });
        }

        return Object.values(squeezedItems);
    },

    expand(squeezedArray: any[], handler: any = null) {
        let expandedArray: any[] = [];

        squeezedArray.forEach(item => {
            const fieldsStack = Helper.clone(item.fieldsStack);
            const expandedItems = [];

            for(let i = 0; i < item.quantity; i++) {
                const newItem = Helper.clone(item);
                delete newItem.quantity;
                delete newItem.fieldsStack;

                if(fieldsStack && (fieldsStack.length > 0)) {
                    Object.assign(newItem, fieldsStack.pop())
                }

                expandedItems.push(newItem);
            }

            if(!!handler) {
                handler(item, expandedItems);
            }

            expandedArray = expandedArray.concat(expandedItems);
        });

        return expandedArray;
    },

    /**
     * Подсчитывает количество одинаковых элементов в массиве, возвращает объект, в котором ключи - элементы массива,
     * а значения - количество элементов
     * @param {array} array
     * @return {object}
     */
    countValues(array: (string|number)[]): CountMap {
        return array.reduce((result: CountMap, value: string|number) => {
            if(!result[value]) {
                result[value] = 0;
            }

            result[value]++;

            return result;
        }, {});
    },

    intersect(arrayA: string[], arrayB: string[]): string[] {
        const arrayBMap = this.flip(arrayB);
        return arrayA.filter(value => value in arrayBMap);
    },

    merge<T>(arrayA: T[], arrayB: T[]): T[] {
        return arrayA.concat(
            arrayB.filter(value => !arrayA.includes(value))
        );
    },

    delete<T>(array: T[], targetValue: T) : T[] {
        return array.filter(value => value !== targetValue);
    },

    mapToString(array: any[]) : string[] {
        return array.map(item => String(item));
    },

    includesAll(array: any[], searchItems: any[]): boolean {
        return _.difference(searchItems, array).length === 0;
    },

    /**
     * Помещает переданное значение в массив, если оно не является массивом ("оборачивает" значение в массив)
     * @param value
     */
    wrap<T>(value: T[] | T): T[] {
        if (Array.isArray(value)) {
            return value;
        }

        return [value];
    }
};


export default Arr;

type KeyFieldCallback = (item: any) => number | string;

type Map<T> = {
    [key: string]: T
};

type FlipMap = {
    [key: string]: number
}

type CountMap = {
    [countedValue: string]: number
}
