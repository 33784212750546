import React from "react";
import Button from "../../../../../components-ui/Inputs/Button/Button";

export default function DialogPage() {
    return (
        <>
            <h1>SlideModal</h1>
            <Button children="Open SlideModal" variant="success" />
        </>
    )
}
