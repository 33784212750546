import GeneralHelper from "./general-helper";
import Helper from "./main";
import _ from 'underscore';

const Obj = {
    empty: GeneralHelper.isEmptyObj,
    keys: Object.keys,
    values: Object.values,
    assign: Object.assign,

    /**
     * Заполняет ключи, перечисленные в массиве keys значением value (предварительно клонирует объект)
     * аргумент value
     * @param {array} keys
     * @param value
     */
    fill: function(keys, value) {
        const object = {};

        keys.forEach(key => {
            object[key] = value;
        });

        return object;
    },

    /**
     * Заполняет отсутствующие ключи, перечисленные в массиве keys значением value (предварительно клонирует объект)
     * аргумент value
     * @param {array} keys
     * @param value
     * @param {object} object - объект для заполнению, по умолчанию пустой объект
     */
    fillEmpty: function(keys, value, object = null) {
        if(object) {
            object = Helper.clone(object);
        } else {
            object = {};
        }

        keys.forEach(key => {
            if(!(key in object)) {
                object[key] = value;
            }
        });

        return object;
    },

    sum: function(object) {
        return this.values(object).reduce((sum, value) => sum + value, 0);
    },

    slice: function(object, keys) {
        const result = {};
        keys.forEach(key => result[key] = object[key]);
        return result;
    },

    some: function(object, handler) {
        let result = false;

        this.forEach(object, (value, key) => {
            if(handler(value, key)) {
                result = true;
                return false;
            }
        });

        return result;
    },

    /**
     * Возвращает длину (размер) объекта
     * @param object
     * @return {number}
     */
    length: object => !!object ? Object.values(object).length : 0,

    every: (object, handler) => {
        let result = true;

        Obj.forEach(object, (value, key) => {
            if(!handler(value, key)) {
                result = false;
                return false;
            }
        });

        return result;
    },

    /**
     * Возвращает массив по указанному объекту аналогично методу map у массивов
     * @param {object} object
     * @param {function} handler
     * @return {Array}
     */
    map: function (object, handler) {
        let result = [];

        this.forEach(object, (value, key) => {
            result.push(handler(value, key, object));
        });

        return result;
    },

    /**
     * Фильтрует объект аналогично методу filter у массивов
     * @param {object} object
     * @param {function} handler
     */
    filter: function (object, handler) {
        const result = {};
        this.forEach(object, function (value, key, object) {
            if (handler(value, key, object)) {
                result[key] = value;
            }
        });

        return result;
    },

    /**
     * Обходит объект аналогично методу forEach у массивов
     * @param object - объект для обхода
     * @param handler - функция-обработчик, в качестве аргументов получает значение, ключ и сам объект. Если функция вернёт false, обход завершится (будет выполнен break)
     * @param from - ключ, с которого нужно начать обход
     * @param end - ключ, на котором нужно завершить обход (данный ключ тоже не попадёт в обход)
     */
    forEach: function (object, handler, from = null, end = null) {
        let bActiveKey = (from === null);

        for (let key in object) {
            if ((end !== null) && (key === end)) break;

            if (key === from) {
                bActiveKey = true;
            }

            if (bActiveKey) {
                if (object.hasOwnProperty(key)) {
                    let result = handler(object[key], key, object);
                    if (result === false) {
                        break;
                    }
                }
            }
        }
    },

    mergeSum: function (objectA, objectB) {
        const sum = Object.assign({}, objectA);

        this.forEach(objectB, (value, key) => {
            if(key in sum) {
                sum[key] += value;
            } else {
                sum[key] = value;
            }
        });

        return sum;
    },

    pluck: function (object, nestedObjectsKey) {
        return Obj.map(object, nestedObject => nestedObject[nestedObjectsKey]);
    },

    /**
     * Вычитает значения одного объекта из значений другого объекта с соответствующими ключами
     * @param minuendObject
     * @param subtrahendObject
     * @return {*}
     */
    subtractObjects: function (minuendObject, subtrahendObject) {
        const result = Helper.clone(minuendObject);

        this.forEach(minuendObject, (value, key) => {
            if(!!subtrahendObject[key]) {
                result[key] -= subtrahendObject[key]
            }
        });

        return result;
    },

    limit: function (object, maxValues) {
        const limitedObject = {};

        _.each(object, (value, key) => {
            const maxValue = (key in maxValues) ? maxValues[key] : 0;
            if(value > maxValue) {
                value = maxValue;
            }

            limitedObject[key] = value;
        });

        return limitedObject;
    }
};

export default Obj;