import {useMemo} from "react";
import {ReceiptsReport} from "../../../../types";
import {useSelector} from "react-redux";

interface Props {
    report: ReceiptsReport
}

const useDirectionsCodes = ({report}: Props) => {
    const directions = useSelector(state => state.common.directions)
    return useMemo(() => {
        return Object.keys(report.current.directions).sort((a, b) => directions[a].sort - directions[b].sort)
    }, [JSON.stringify(report)]);
}

export default useDirectionsCodes
