import React from 'react';
import Helper from "../../common/helpers/general-helper";
import PropTypes from "prop-types";

export default class Resizable extends React.PureComponent {

    static propTypes = {
        isTop: PropTypes.bool,
        background: PropTypes.string,
        onResizeStop: PropTypes.func,
        maxHeightBottom: PropTypes.number,
        maxHeightTop: PropTypes.number,
        defaultHeight: PropTypes.number,
        resizeColor: PropTypes.string,
        onResizeStart: PropTypes.func,
        onResize: PropTypes.func,
        isHovered: PropTypes.bool
    };

    static defaultProps = {
        isTop: false,
        background: 'transparent',
        resizeColor: 'transparent'
    };

    state = {
        edit: false,
        start: 0,
        height: 0,
        transform: 0,
        style: {
            display: 'block',
            width: '180px',
            cursor: 'ns-resize',
            zIndex: '4',
            position: 'absolute',
            top: '0',
            left: '0',
            borderRadius: '2px',
        },
        styleWrap: {
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            cursor: 'ns-resize',
        },
        widthWrap: '180px',
        heightWrap: '5px',
        position: 'absolute',
        zIndex: '6',
        zIndexWrap: '7',
        direction: false
    };


    render() {
        const {style, edit, start, height, transform, styleWrap, widthWrap, heightWrap, position, zIndex, zIndexWrap, direction} = Helper.clone(this.state);
        const {isTop, defaultHeight, background, maxHeightBottom, maxHeightTop, onResizeStop, resizeColor, onResizeStart, isHovered} = this.props;
        style.height = (5 + height) + 'px';
        style.transform = `translateY(${transform}px)`;
        style.zIndex = zIndex;
        styleWrap.width = widthWrap;
        styleWrap.height = heightWrap;
        styleWrap.position = position;
        styleWrap.zIndex = zIndexWrap;

        if (isHovered) {
            style.background = background;
        }


        if (isTop) {
            if (direction) {
                style.borderTop = '5px solid ' + resizeColor;
            } else {
                style.borderBottom = '5px solid ' + resizeColor;
                style.background = 'transparent';
            }
        } else {
            style.top = (defaultHeight - 5) + 'px';
            styleWrap.top = (defaultHeight - 5) + 'px';
            if (direction) {
                style.borderTop = '5px solid ' + resizeColor;
                style.background = 'transparent';
            } else {
                style.borderBottom = '5px solid ' + resizeColor;
            }

        }

        if (edit) {
            styleWrap.top = '0';
        }

        return (
            <>
                <div
                    style={styleWrap}
                    onMouseDown={(e) => {
                        onResizeStart();
                        this.setState(
                            {
                                edit: true,
                                start: e.clientY,
                                widthWrap: '100vw',
                                heightWrap: '100vh',
                                position: 'fixed',
                                zIndex: '998',
                                zIndexWrap: '999',
                            })
                    }}
                    onMouseMove={(e) => {
                        if (edit) {
                            let newState = {
                                widthWrap: '100vw',
                                heightWrap: '100vh',
                                position: 'fixed',
                                zIndex: '998',
                                zIndexWrap: '999',
                            };
                            if (isTop) {
                                if (start > e.clientY) {
                                    if (maxHeightTop  > start - e.clientY) {
                                        newState.height = start - e.clientY;
                                        newState.transform = -(start - e.clientY);
                                    }
                                    newState.direction = true;
                                } else if (start < e.clientY) {
                                    if (defaultHeight - 12 > e.clientY - start) {
                                        newState.height = e.clientY - start;
                                    }
                                    newState.direction = false;
                                }

                            } else {
                                if (start < e.clientY) {
                                    if (maxHeightBottom - defaultHeight > e.clientY - start) {
                                        newState.height = e.clientY - start;
                                    }
                                    newState.direction = false;
                                } else if (start > e.clientY) {
                                    if (defaultHeight - 12 > start - e.clientY) {
                                        newState.height = start - e.clientY;
                                        newState.transform = -(start - e.clientY);
                                    }
                                    newState.direction = true;
                                }
                            }

                            this.setState(newState);
                        }
                    }}
                    onMouseUp={() => {
                        if (onResizeStop) {
                            onResizeStop(height, direction, isTop)
                        }
                        this.setState(
                            {
                                edit: false,
                                widthWrap: '180px',
                                heightWrap: '5px',
                                position: 'absolute',
                                zIndex: '6',
                                zIndexWrap: '7',
                                height: 0,
                                transform: 0,
                            });
                    }}
                />
                <span
                    style={style}
                />
            </>
        )
    }
}



