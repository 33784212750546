import {Dispatch, SetStateAction, useEffect} from "react";
import {tabsReceipts} from "../data/tabsReceipts";
import {ReceiptsReport} from "../../../../types";
import {StateReceipts} from "../types";
import {useSelector} from "react-redux";

interface Props {
    setState: Dispatch<SetStateAction<StateReceipts>>
    report: ReceiptsReport
}

const useInitialReceipts = ({report, setState}: Props) => {
    const directions = useSelector(state => state.common.directions)
    useEffect(() => {
        setState(prevState => {
            const directionsCode = Object.keys(report.current.directions).sort((a, b) => directions[a].sort - directions[b].sort)
            if (directionsCode.length) {
                if (!prevState.currentTab) {
                    prevState.currentTab = tabsReceipts[1].code
                }
                if (!prevState.selectedDirection) {
                    prevState.selectedDirection = directionsCode[0]
                } else {
                    if (!directionsCode.some(direction => prevState.selectedDirection === direction)) {
                        prevState.selectedDirection = directionsCode[0]
                    }
                }
            } else {
                if (!!prevState.currentTab || !!prevState.selectedDirection) {
                    prevState.currentTab = null;
                    prevState.selectedDirection = null;
                }
            }
            return {...prevState}
        })

    }, [JSON.stringify(report)])
};

export default useInitialReceipts;
