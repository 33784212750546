import {useEffect} from "react";

export function useUpDownArrows(upHandler: () => void, downHandler: () => void, focused?: boolean) {
    const handleKeyDown = (e: any) => {
        if (e.which === 38) {
            upHandler();
            e.preventDefault();
        } else if (e.which === 40) {
            downHandler();
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (focused) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [focused, handleKeyDown]);
}
