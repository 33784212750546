import React from 'react'
import PropTypes from 'prop-types'
import GroupsTree from "../../components/TableWithGroupsTree/GroupsTree";
import Table from "../../components/Table/Table";
import Helper from "common/helpers/main";
import ButtonGroup from "../../components-ui/Wraps/old/ButtonGroup/ButtonGroup";
import Modal from "../../containers/DialogsManager/containers/Modal/Modal";
import ItemPopup from "../../components/TableWithGroupsTree/ItemPopup";
import DeletePopup from "../../components/TableWithGroupsTree/DeletePopup";
import Checkbox from "../../components-ui/Inputs/Checkbox/Checkbox";
import TextField from "../../components-ui/Inputs/TextField/TextField";

const proceduresResultColumns = [
    {
        title: 'Название',
        code: 'name'
    },
    {
        title: 'Количество',
        code: 'quantity',
        editable: true,
        type: 'number'
    }
];

export default class ServiceProceduresField extends React.PureComponent {
    static propTypes = {
        value: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        procedures: PropTypes.array.isRequired,
        procedureFields: PropTypes.array.isRequired,
        groups: PropTypes.array.isRequired,
        updateData: PropTypes.func.isRequired,
        procedureCases: PropTypes.object.isRequired
    };

    state = {
        focusedElement: null,
        popupType: null,
        searchQuery: ''
    };

    render() {
        let foundItems = null;
        let data = Helper.clone(this.props.procedures);
        data = Array.isArray(data) ? data : Object.values(data);

        /* --- фильтрация элементов по выбранной группе и поисковой фразе --- */

        if (this.state.searchQuery.length > 0) {
            foundItems = this.filterRowsBySearch(data);
        } else {
            foundItems = data;
        }
        return (
            <>
                <TextField onChange={(searchQuery) => this.setState({searchQuery})}
                           value={this.state.searchQuery}
                           isSearch={true}
                           placeholder="Поиск по процедурам"
                           widthInput="100%"
                           margin="5px 0 15px"
                           isReset={!!this.state.searchQuery}
                           onClickReset={() => this.setState({searchQuery: '', focusedElement: null})}
                />
                <div className="popup__list">
                    <GroupsTree groups={this.getGroupsList(foundItems)}
                                isMultipleSelect={false}
                                scrollHeight={224}
                                elements={foundItems}
                                getElementGroupId={procedure => procedure.groupId}
                                onSelect={this.handleSelectGroupTree}
                                dblclickElement={this.addServiceProcedure}
                                selectedElementsIds={[this.getSelectedProcedureId()]}
                                isFocused={true}
                                withHeader={false}/>
                    <div className="popup__result-list">
                        <div className="popup__result-table">
                            <Table data={this.props.value}
                                   columns={proceduresResultColumns}
                                   isEditable={true}
                                   withHeader={false}
                                   sortBy="order"
                                   prepareDataItem={this.prepareServiceProcedure}
                                   getRowCode={this.getServiceProcedureCode}
                                   onChange={this.handleChangeProcedure}
                                   onReorder={this.handleReorderProcedures}
                                   onRowDelete={this.deleteProcedureByCode}
                                   draggable={this.props.value.length > 1}
                                   focusedElement={this.getTableFocusedElement()}
                                   onFocus={this.handleTableFocus}
                            />
                        </div>
                    </div>
                </div>
                {this.renderButtonGroup()}
                {this.renderPopup()}
            </>
        )
    }

    renderButtonGroup = () => {
        const buttons = [
            {
                variant: 'default',
                text: 'Создать новую процедуру',
                key: 'addNewProcedure',
                onClick: () => this.showPopup('add')
            },
            {
                variant: !!this.state.focusedElement ? 'default' : 'disabled',
                text: 'Изменить процедуру',
                key: 'editProcedure',
                onClick: () => this.showPopup('update')
            },
            {
                variant: !!this.state.focusedElement ? 'alert' : 'disabled',
                text: 'Удалить процедуру',
                key: 'removeProcedure',
                onClick: () => this.showPopup('delete')
            }
        ];
        return <ButtonGroup buttons={buttons}/>
    };

    renderPopup() {
        const {popupType} = this.state;
        let popup;

        if ((popupType === 'add') || (popupType === 'update')) {
            popup = this.renderSavePopup(popupType === 'add');
        } else if (popupType === 'delete') {
            popup = this.renderDeletePopup();
        }

        return popup;
    }

    renderSavePopup(add) {
        const procedures = Helper.Array.toObject(this.props.procedures, 'id');

        return <Modal
            isOpen={true}
            closeModal={this.closePopup}
            isIconClose={true}
        >
            <ItemPopup
                updateData={this.props.updateData}
                fields={this.props.procedureFields}
                fieldsValues={add ? {} : procedures[this.getFocusedProcedureId()]}
                type={add ? 'new' : 'edit'}
                methods={{addItem: 'service/add-procedure', updateItem: 'service/update-procedure'}}
                cases={this.props.procedureCases}

                closePopup={this.closePopup}
                onAfterSave={add ? this.addServiceProcedure : null}
            />
        </Modal>
    }

    renderDeletePopup() {
        const {procedureCases} = this.props;
        const proceduresMap = Helper.Array.toObject(this.props.procedures, 'id');
        const selectedProcedure = proceduresMap[this.getFocusedProcedureId()];

        return (
            <DeletePopup
                targetId={selectedProcedure.id}
                targetName={selectedProcedure.name}
                targetAccusativeType={procedureCases.accusative}
                deleteMethod="service/delete-procedure"
                updateData={this.props.updateData}
                close={this.closePopup}
                beforeDelete={this.deleteProcedureById}
            />
        );
    }

    renderServiceProcedureName(serviceProcedure) {
        const procedureCode = this.getServiceProcedureCode(serviceProcedure);
        return (
            <Checkbox
                label={serviceProcedure.name}
                checked={serviceProcedure.defaultActive}
                onChange={defaultActive => this.setProcedureDefaultActive(procedureCode, defaultActive)}
            />
        );
    }

    filterRowsBySearch(data) {
        let searchQuery = this.state.searchQuery.toLowerCase();
        return data.filter((item) => {
            return String(item.name).toLowerCase().indexOf(searchQuery) > -1
        });
    }

    getGroupsList(rows) {
        let groups = {};
        let rowsGroupsIds = {};

        rows.forEach((row) => rowsGroupsIds[row.groupId] = true);

        if (this.state.searchQuery.length) {
            groups = this.filterRowGroup(Helper.clone(Object.values(this.props.groups)), (group) => rowsGroupsIds[group.id] === true)
        } else {
            groups = Helper.clone(this.props.groups);
        }
        return groups;
    }

    filterRowGroup(groups, handler) {

        return groups.filter((group) => {
            if (group.groups) {
                group.groups = Helper.clone(this.filterRowGroup(group.groups, handler))
            }

            return (group.groups && group.groups.length > 0) || handler(group)
        })
    }

    prepareServiceProcedure = ({item: serviceProcedure}) => {
        const proceduresMap = Helper.Array.toObject(this.props.procedures, 'id');
        const procedure = proceduresMap[serviceProcedure.procedureId];

        serviceProcedure.name = procedure.name;
        serviceProcedure.name = this.renderServiceProcedureName(serviceProcedure);
    };

    handleReorderProcedures = reorderedProcedures => {
        let serviceProcedures = this.getServiceProceduresMap();
        reorderedProcedures.forEach(procedure => {
            serviceProcedures[procedure.code].order = procedure.order;
        });
        serviceProcedures = Object.values(serviceProcedures).sort((a, b) => a.order - b.order);
        this.props.onChange(serviceProcedures);
        this.setState({focusedElement: null})
    };

    deleteProcedureByCode = procedureCode => {
        const value = this.getServiceProceduresMap();
        delete value[procedureCode];
        this.props.onChange(Object.values(value));
    };

    deleteProcedureById = procedureId => {
        const {value, onChange} = this.props;
        const newValue = value.filter(serviceProcedure => serviceProcedure.procedureId !== procedureId);
        onChange(Object.values(newValue));
    };

    handleChangeProcedure = (procedureCode, fieldCode, newValue) => {
        this.setProcedureQuantity(procedureCode, newValue);
    };

    addServiceProcedure = procedure => {
        const value = Helper.clone(this.props.value);

        const maxOrder = value.reduce((maxOrder, procedure) => ((maxOrder > procedure.order) ? maxOrder : procedure.order), 0);

        value.push({
            procedureId: procedure.id,
            quantity: 1,
            order: value.length ? maxOrder + 1 : 0,
            defaultActive: true
        });

        this.props.onChange(value);
    };

    setProcedureQuantity(procedureCode, quantity) {
        this.changeProcedure(procedureCode, procedure => procedure.quantity = quantity);
    };

    setProcedureDefaultActive(procedureCode, defaultActive) {
        this.changeProcedure(procedureCode, procedure => procedure.defaultActive = defaultActive)
    }

    changeProcedure(procedureCode, changeHandler) {
        const value = this.getServiceProceduresMap();
        changeHandler(value[procedureCode]);
        this.props.onChange(Object.values(value));
    }

    getServiceProcedureCode = serviceProcedure => {
        return serviceProcedure.procedureId + '_' + serviceProcedure.order;
    };

    getServiceProceduresMap = () => {
        return Helper.Array.toObject(Helper.clone(this.props.value), this.getServiceProcedureCode);
    };

    handleSelectGroupTree = (procedure, selectedProcedures, isProcedure) => {
        this.setState({focusedElement: isProcedure ? {type: 'procedure', code: procedure.id} : null})
    };

    showPopup(popupType) {
        this.setState({popupType});
    }

    closePopup = () => {
        this.setState({popupType: null});
    };

    getSelectedProcedureId() {
        const {focusedElement} = this.state;

        if (!!focusedElement && (focusedElement.type === 'procedure')) {
            return focusedElement.code;
        }

        return null;
    }

    getTableFocusedElement() {
        const {focusedElement} = this.state;

        if (!!focusedElement && (focusedElement.type === 'serviceProcedure')) {
            return {
                code: focusedElement.code
            }
        }

        return null;
    }

    /**
     * Возвращает id процедуры, на которой установлен фокус (независимо от того, в дереве или таблице)
     * @return {*}
     */
    getFocusedProcedureId() {
        const {focusedElement} = this.state;
        let procedureId = null;

        if (focusedElement.type === 'procedure') {
            procedureId = focusedElement.code;
        } else if (focusedElement.type === 'serviceProcedure') {
            const serviceProceduresMap = this.getServiceProceduresMap();
            procedureId = serviceProceduresMap[focusedElement.code].procedureId;
        }

        return procedureId;
    }

    handleTableFocus = ({code}) => {
        this.setState({
            focusedElement: {code, type: 'serviceProcedure'}
        });
    };
}
