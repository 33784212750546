import React, {Dispatch, SetStateAction, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import Steps from "../../../content/Steps/Steps";
import Kanban from "../../../content/Kanban/Kanban";
import CallCenter from "../../../content/CallCenter/CallCenter";
import {CurrentVisit} from "../types/common";
import {SlideModals} from "../types/slideModals";
import ManagerTasks from "../../../content/ManagerTasks";
import CheckPayments from "../../../content/CheckPayments";
import TimeSheet from "../../../content/TimeSheet";

export default function useInitialDialogs() {
    const visits = useSelector(state => state.doctorRole.visits);
    const user = useSelector(state => state.user);
    const currentVisits = Object.values(visits.inProgress) as CurrentVisit[];
    const init = useMemo(() => {
        const newSlideModals = {} as SlideModals
        let lastId = 0;
        
        if (user.canViewManagerTasks) {
            newSlideModals[++lastId] = {
                component: <ManagerTasks/>,
                isMinimized: true,
                isScrollable: false,
                widthDialog: '420px',
                sort: 0
            }
        }

        if (!!currentVisits.length) {
            currentVisits.forEach((visit) => {
                newSlideModals[++lastId] = {
                    component: <Steps visitId={visit.id}/>,
                    isMinimized: true,
                    sort: 0,
                    keySlideModal: visit.id + 'step'
                }
            })
        }
        if (user.canViewKanban) {
            newSlideModals[++lastId] = {
                component: <Kanban/>,
                isMinimized: true,
                isScrollable: false,
                sort: 0,
            }
        }
        if (user.canViewCallCenter) {
            newSlideModals[++lastId] = {
                component: <CallCenter/>,
                isMinimized: true,
                isScrollable: false,
                widthDialog: '420px',
                sort: 0
            }
        }
        if (user.canCheckPayments) {
            newSlideModals[++lastId] = {
                component: <CheckPayments/>,
                isMinimized: true,
                widthDialog: '94vw',
                minWidthDialog: '1360px',
                sort: 0
            }
        }
        if (user.needKeepTimesheet) {
            newSlideModals[++lastId] = {
                component: <TimeSheet/>,
                isMinimized: true,
                sort: 0
            }
        }
        return newSlideModals;
    }, [])

    const [slideModals, setSlideModals] = useState<SlideModals>(init);

    return [slideModals, setSlideModals] as [SlideModals, Dispatch<SetStateAction<SlideModals>>]
}
