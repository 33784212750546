import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const Comment = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 12 10"} ref={ref}>
            <path d="M10.2181 0H1.78201C1.309 0 0.855396 0.178538 0.521066 0.496179C0.186822 0.813888 -0.000678982 1.24466 3.35507e-06 1.69369V6.14926C-0.000914033 6.59845 0.186335 7.02953 0.520659 7.3474C0.854989 7.66535 1.30874 7.84403 1.78201 7.84403H2.40002V9.32469C2.40002 10.0274 2.84524 10.212 3.38884 9.73472L5.54157 7.84403H10.2216C10.6942 7.84315 11.1471 7.66408 11.4806 7.3462C11.8141 7.02841 12.0009 6.5978 12 6.14924V1.69367C12.0007 1.24464 11.8132 0.813867 11.4789 0.496159C11.1446 0.178532 10.691 0 10.218 0H10.2181ZM3.40416 4.61537C3.24431 4.61642 3.09058 4.55609 2.97717 4.44782C2.86377 4.33948 2.8 4.19215 2.8 4.03846C2.8 3.88477 2.86377 3.73737 2.97725 3.6291C3.09065 3.52083 3.24431 3.4605 3.40423 3.46155C3.56408 3.46267 3.71686 3.52503 3.82867 3.63484C3.94054 3.74465 4.0022 3.8928 3.99994 4.04651C3.99834 4.19754 3.93487 4.34199 3.82335 4.44845C3.71191 4.5549 3.56124 4.61488 3.40415 4.61537L3.40416 4.61537ZM5.79478 4.61434C5.63581 4.61301 5.48381 4.55113 5.37231 4.44225C5.2608 4.33331 5.19878 4.1864 5.20002 4.03361C5.20118 3.8809 5.26545 3.73483 5.37871 3.62755C5.4919 3.52029 5.64482 3.46057 5.80379 3.46155C5.96283 3.46253 6.11488 3.52413 6.22668 3.63273C6.33849 3.74139 6.40079 3.88822 6.4 4.04095C6.39781 4.19388 6.3331 4.33981 6.21984 4.44729C6.10665 4.55476 5.95394 4.61511 5.79477 4.61538L5.79478 4.61434ZM8.20308 4.61433C8.04397 4.61517 7.89106 4.55517 7.77796 4.44763C7.66486 4.34008 7.60088 4.19379 7.6 4.04094C7.59921 3.88808 7.66165 3.74117 7.77352 3.63251C7.88546 3.52385 8.03773 3.46232 8.19683 3.46154C8.35601 3.46078 8.50892 3.5207 8.62202 3.62825C8.73513 3.73579 8.7991 3.88208 8.79998 4.03494C8.80136 4.18807 8.73913 4.3354 8.62712 4.44435C8.5151 4.55329 8.36248 4.61482 8.20308 4.61538L8.20308 4.61433Z"/>
        </WrapIcon>
    );
});