import React from "react";
import TextField from "../../../../../../../components-ui/Inputs/TextField/TextField";
import {usePatientField} from "../../../../PatientField";
import { useComposite } from "../EditComposite";
import useWidthInput from "../../../../../hooks/useWidthInput";
import {IPatientField} from "../../../../../types/fields";

export default function StringEdit() {
    const {composite, onChangeField, onChangeValidationItem, value} = usePatientField()
    const {subField, index} = useComposite()
    const field = !!composite ? composite[subField].field : {} as IPatientField
    const widthSelect = useWidthInput(field)
    let fieldLabel = !!field.label && field.label as any
    if (!!fieldLabel && field.required) {
        fieldLabel = [fieldLabel, <span>*</span>]
    }
    if (!!composite) {
        return (
            <TextField value={value[subField]}
                       autoFocus={index === 0}
                       isError={composite[subField].error}
                       widthInput={widthSelect}
                       label={fieldLabel}
                       nameInput={composite[subField].field.name}
                       onChange={value => {
                           onChangeField(value, subField)
                           if (!!onChangeValidationItem) onChangeValidationItem(subField)
                       }}
            />
        )
    } else {
        return null
    }

}
