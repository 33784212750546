import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const UnconfirmedByMessageIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path
                d="M21.6,0H2.4A2.41,2.41,0,0,0,0,2.4V24l4.8-4.8H21.6A2.41,2.41,0,0,0,24,16.8V2.4A2.41,2.41,0,0,0,21.6,0Z"/>
            <polygon fill="#fff"
                     points="9 4.5 7.5 6 10.5 9 7.5 12 9 13.5 12 10.5 15 13.5 16.5 12 13.5 9 16.5 6 15 4.5 12 7.5 9 4.5"/>
        </WrapIcon>
    );
});
