import * as React from "react";
import LoaderSpinner from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import './Loader.scss'
import styled from "styled-components";

interface PropsLoader {
    visible?: boolean,
    height?: number,
    width?: number,
    color?: string,
    type?: 'Oval' | 'ThreeDots',
    className?: string,
    isBackground?: boolean,
    zIndex?: number,
    customStyle?: {
        [key: string]: any
    }
}

export default function Loader({
                                   visible = false,
                                   className,
                                   color = '#00d6d6',
                                   height = 25,
                                   type = "Oval",
                                   width = 50,
                                   isBackground = true,
                                   zIndex = 22,
                                   customStyle = {}
                               }: PropsLoader) {

    let classNameLoader = 'loader';
    if (className !== undefined) classNameLoader += ` loader--${className}`;
    if (!isBackground) classNameLoader += ' loader--no-bg'
    return <LoaderSpinner
        visible={visible}
        type={type}
        color={color}
        height={height}
        width={width}
        className={classNameLoader}
        style={{
            zIndex,
            ...customStyle
        }}
    />;
}

interface PropsSWrapLoader {
    loader?: boolean
    minHeight?: string
}

export const SWrapLoader = styled.div<PropsSWrapLoader>`
  position: relative;
  ${props => props.minHeight && `min-height: 100vh;`}
  ${props => props.loader && `
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.7);
            z-index: 15;
        }
    `}
`
