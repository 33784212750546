import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const ToothOutlineIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M16.75,24h-.56l-.29-.47a11.46,11.46,0,0,1-1.31-3.38l0-.11a15.67,15.67,0,0,0-1.16-3.33c-.58-1-1.17-1-1.37-1s-.85.11-1.38,1A15,15,0,0,0,9.5,20l-.05.21a12.73,12.73,0,0,1-1,2.88l-.24.42-.47.08c-3.23.54-5.19-4.69-5.4-5.29S-1.07,10.64.39,4.24A5,5,0,0,1,3.54.44h0c2.26-.83,5-.3,6.73.06l.2,0a11.06,11.06,0,0,0,1.47.23A12.82,12.82,0,0,0,13.48.53l.21,0c1.8-.35,4.52-.87,6.78,0a5,5,0,0,1,3.16,3.79c1.45,6.34-1.66,13.33-2,14.1a19.45,19.45,0,0,1-2.56,4.2A3.26,3.26,0,0,1,16.75,24ZM12,13.71a3.51,3.51,0,0,1,3.1,2,16,16,0,0,1,1.36,3.83l0,.11a14.23,14.23,0,0,0,.64,2c.15-.16.3-.33.37-.42a17.41,17.41,0,0,0,2.26-3.73c.14-.29,3.22-7,1.89-12.83a3,3,0,0,0-1.89-2.35c-1.75-.64-4.14-.18-5.73.12l-.2,0a11.62,11.62,0,0,1-1.92.27,10.64,10.64,0,0,1-1.85-.26l-.21,0C8.32,2.15,6,1.68,4.23,2.32h0A3,3,0,0,0,2.34,4.68C1,10.54,4.06,17.26,4.19,17.54c.56,1.57,1.72,3.64,2.77,4,.2-.53.38-1.21.56-1.86l.05-.21a15.72,15.72,0,0,1,1.36-3.8A3.53,3.53,0,0,1,12,13.71Z"/>
        </WrapIcon>
    );
});
