import React from 'react';
import {ManagerTask} from "../../types/common";
import {InvoicesInconsistencyPayload} from "../../types/taskData";
import {STaskList} from "../../../../containers/Tasks/style";
import {Date, Patients} from "../Labels";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";
import PriceHelper from "../../../../common/helpers/price-helper";

interface Props {
    task: ManagerTask<InvoicesInconsistencyPayload>
    renderBranches: () => React.ReactNode | null
}

const InvoicesInconsistency = ({task, renderBranches}: Props) => {

    return (
            <STaskList padding="19px 0 16px">
                {renderBranches()}
                <Date date={task.payload.date}/>
                <Patients patients={[task.payload.patient]} colorPatient="info" activeSectionNav="INVOICES"/>
                <LabelTask label={'Баланс'}
                           children={`${PriceHelper.format(task.payload.patient.account)} руб.`}/>
                <LabelTask label={'Фактический баланс'}
                           children={`${PriceHelper.format(task.payload.patient.actualAccount)} руб.`}/>
            </STaskList>

    );
};

export default InvoicesInconsistency;