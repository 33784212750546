import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const UserFpIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 21.82 24"} ref={ref}>
            <path d="M5.1,11.5a8,8,0,0,0,11.62,0,12,12,0,0,1,5.1,5.5A12,12,0,0,1,0,17,12.08,12.08,0,0,1,5.1,11.5Zm5.81.5a6,6,0,1,1,6-6A6,6,0,0,1,10.91,12Z"/>
        </WrapIcon>
    );
});
