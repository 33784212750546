import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const TargetIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M12,24A12,12,0,1,1,24,12,12,12,0,0,1,12,24ZM12,1.08A10.92,10.92,0,1,0,22.92,12,10.93,10.93,0,0,0,12,1.08Z"/>
            <path d="M12,24a.54.54,0,0,1-.54-.54v-5a.54.54,0,0,1,1.08,0v5A.54.54,0,0,1,12,24ZM23.46,12.54h-5a.54.54,0,0,1,0-1.08h5a.54.54,0,0,1,0,1.08Zm-18.27,0H.54a.54.54,0,1,1,0-1.08H5.19a.54.54,0,1,1,0,1.08ZM12,5.73a.54.54,0,0,1-.54-.54V.54a.54.54,0,1,1,1.08,0V5.19A.54.54,0,0,1,12,5.73Z"/>
        </WrapIcon>
    );
});
