import React from 'react';
import ReactDOM from 'react-dom';
import CellMenu from './CellMenu';
import GeneralHelper from '../../../../common/helpers/general-helper';
import ColorHelper from '../../../../common/helpers/color-helper';
import ServerCommand from '../../../../common/server/server-command';
import {Icon} from '../../../../components/Icons/Icons';
import Helper from '../../../../common/helpers/main';
import Resizable from '../../../../components/Resizable/Resizable';
import SVG from '../../../../common/helpers/SVG';


class Cell extends React.Component {
    defaultCellHeight = 22;
    defaultCellMargin = 2;

    state = {
        isHovered: false,
        activeClass: false,
    };

    render() {
        if (this.props.isBlocked) {
            return (
                this.renderCell()
            );
        } else {
            let detailInfo;

            if (!!this.props.patient) {
                detailInfo = {
                    patient: GeneralHelper.clone(this.props.patient),
                    timeStart: this.props.realTimeStart,
                    timeEnd: this.props.timeEnd,
                };
            } else {
                detailInfo = null;
            }

            return (
                <CellMenu renderCell={this.renderCell.bind(this)}
                          commands={this.props.commands}
                          getCellNode={this.getCellNode.bind(this)}
                          detailInfo={detailInfo}
                          cellType={!!this.props.patient ? 'visit' : 'schedule'}
                          onCommandResult={() => this.props.onUpdate()}
                          update={this.props.onUpdate}
                          timeStart={this.props.timeStart}
                          timeEnd={this.props.timeEnd}
                          timeLine={this.props.timeLine}
                          chairId={this.props.chairId}
                          date={this.props.date}
                          doctorId={this.props.doctorId}
                          splitInterval={this.props.splitInterval}
                          uniteInterval={this.props.uniteInterval}
                          availableTimeUnite={this.props.availableTimeUnite}
                          visitId={this.props.visitId}
                          patientId={this.props.patientId}
                          isResize={this.state.activeClass}
                          onCopyPatient={this.props.onCopyPatient}
                          isSocial={this.props.isSocial}
                          changeTimeHighlight={this.props.changeTimeHighlight}
                          currentDate={this.props.currentDate}
                          currentTime={this.props.currentTime}
                          comment={this.props.comment}
                          transferPatient={this.props.transferPatient}
                          clearPatientsBuffer={this.props.clearPatientsBuffer}

                          handleCellClick={this.props.handleCellClick}
                />
            );
        }
    }

    renderCell(mixin = {}, menu = null) {

        const {
            doctor,
            patient,
            isMainDoctor,
            maxHeightTop,
            currentDate,
            date,
            timeEnd,
            timeStart,
            currentTime,
            isPrimaryVisit,
            isFirstVisit,
            isSocial,
            isSurvey,
            maxHeightBottom,
            status,
            isConfirmed,
            isConfirmedByMessage,
        } = this.props;
        const {isHovered} = this.state;

        let cellAttrs = {};
        let resizeTop = null;
        let resizeBottom = null;
        const defaultHeight = this.getHeight();

        let cellStyle = {
            height: defaultHeight + 'px',
            marginBottom: this.defaultCellMargin + 'px',
            position: 'relative',
        };

        let cellContent = [];
        let className = 'dayCalendar_interval';

        if (doctor) {
            cellStyle.color = ColorHelper.darken(doctor.color, 50);

            if (patient) {
                cellStyle.backgroundColor = ColorHelper.lighten(doctor.color, 30);
                cellStyle.borderColor = ColorHelper.lighten(doctor.color, 13);
                let resizeColor = 'transparent';
                let resizeBackground = 'transparent';

                if (isHovered) {
                    resizeColor = ColorHelper.lighten(doctor.color, 25);
                    resizeBackground = cellStyle.backgroundColor;
                }
                if ((maxHeightTop.maxHeight > this.defaultCellHeight ||
                        defaultHeight > this.defaultCellHeight) &&
                    currentDate <= date
                ) {
                    resizeTop = <Resizable
                        background={resizeBackground}
                        resizeColor={resizeColor}
                        onResizeStart={this.onResizeStart.bind(this)}
                        onResizeStop={this.onResizeStop.bind(this)}
                        maxHeightBottom={this.props.maxHeightBottom.maxHeight}
                        maxHeightTop={this.props.maxHeightTop.maxHeight}
                        defaultHeight={defaultHeight}
                        isTop={true}
                        isHovered={this.state.isHovered}
                    />;
                    if (currentDate === date && timeStart < currentTime) {
                        resizeTop = null;
                    }
                }
                if ((maxHeightBottom.maxHeight > this.defaultCellHeight ||
                        defaultHeight > this.defaultCellHeight) &&
                    currentDate <= date
                ) {
                    resizeBottom = <Resizable
                        background={cellStyle.backgroundColor}
                        resizeColor={resizeColor}
                        onResizeStart={this.onResizeStart.bind(this)}
                        onResizeStop={this.onResizeStop.bind(this)}
                        maxHeightBottom={this.props.maxHeightBottom.maxHeight}
                        maxHeightTop={this.props.maxHeightTop.maxHeight}
                        defaultHeight={defaultHeight}
                        isHovered={this.state.isHovered}
                    />;
                    if (currentDate === date && timeEnd < currentTime) {
                        resizeBottom = null;
                    }
                }

                cellContent.push(<span key='patient-fio'>{GeneralHelper.getFio(patient)}</span>);

                let socialClass = '';

                if (isPrimaryVisit && !isFirstVisit) {
                    socialClass = 'patient-initial-inspection--social';
                    cellContent.push(
                        <span
                            className='patient-initial-inspection'
                            key={'isPrimaryVisit' + this.props.timeStart}
                        >
                              1
                            <span className='patient-initial-inspection--hover'>
                                Первичный приём
                            </span>
                    </span>);
                }
                if (isFirstVisit) {
                    socialClass = 'patient-initial-inspection--social';
                    cellContent.push(
                        <span
                            className='patient-initial-inspection'
                            key={'isFirstVisit' + timeStart}
                        >
                            <Icon icon='new-user_fp' />
                            <span className='patient-initial-inspection--hover patient-initial-inspection--patient'>
                                Первичный пациент
                            </span>
                    </span>);
                }

                if (isSocial) {
                    cellContent.push(
                        <span
                            className={`patient-initial-inspection ${socialClass}`}
                            key={'isSocial' + timeStart}
                        >
                            <Icon icon='new-tender' />
                            <span className='patient-initial-inspection--hover patient-initial-inspection--tender'>
                                Прием по соц. защите
                            </span>
                    </span>);
                }

                let classNameConfirmed = '';

                if (isSurvey) {
                    classNameConfirmed = 'patient-cell-prompt-icon-right--left';
                    cellContent.push(
                        <span className={`patient-cell-prompt-icon-right`}
                              key={'prof_inspection' + timeStart}
                        >
                                <SVG name='prof_inspection' />
                                <span className='patient-cell-prompt-popup-right'>
                                    Проф. осмотр
                                </span>
                            </span>,
                    );
                }

                if (isConfirmed) {
                    if (isConfirmedByMessage) {
                        cellContent.push(
                            <span className={`patient-cell-prompt-icon-right ${classNameConfirmed}`}
                                  key={'isConfirmed' + timeStart}
                            >
                                <SVG name='confirmed_by_message' />
                                <span className='patient-cell-prompt-popup-right'>
                                    Пациент подтвердил приём через СМС
                                </span>
                            </span>,
                        );
                    } else {
                        cellContent.push(
                            <span className={`patient-cell-prompt-icon-right ${classNameConfirmed}`}
                                  key={'isConfirmed' + timeStart}
                            >
                                <SVG name='confirmed' className='icon-confirmed' />
                                <span className='patient-cell-prompt-popup-right'>
                                    Приём подтверждён
                                </span>
                            </span>,
                        );
                    }
                } else if (isConfirmedByMessage === false) {
                    cellContent.push(
                        <span className={`patient-cell-prompt-icon-right ${classNameConfirmed}`}
                              key={'isConfirmed' + timeStart}
                        >
                                <SVG name='unconfirmed_by_message' className='unconfirmed_by_message' />
                                <span className='patient-cell-prompt-popup-right'>
                                    Пациент отказался от приёма через СМС
                                </span>
                        </span>,
                    );
                }

                if (status === 'CANCELED') {
                    className += ' canceled';
                }
            } else {
                cellStyle.backgroundColor = ColorHelper.lighten(doctor.color, 40);
            }

            if (!isMainDoctor) {
                cellContent.push(<span className='freedoctor_intrvl' key='doctor-fio'>{'Врач - ' + doctor.fio}</span>);
            }

            className += ' resrvdI';
        } else {
            className += ' emptyI';
        }

        if (this.props.isBlocked) {
            cellStyle.visibility = 'hidden';
        }

        if (this.state.activeClass) {
            className += ' resize';
        }

        const handleClick = () => {
            let data = {
                timeStart,
                timeEnd,
                chairId: this.props.chairId,
                date
            };
            this.props.handleCellClick(data)
        }

        if (this.props.highlightedCells.arrCells.some((cell) =>
            cell.timeStart === timeStart &&
            cell.timeEnd === timeEnd && this.props.highlightedCells.date === date &&
            this.props.highlightedCells.chairId === this.props.chairId)) {
            cellStyle.backgroundColor = '#508ff46e';
            cellStyle.boxShadow = "0px 0px 0px 2px rgba(98, 185, 251, 0.85) inset"
        }

        cellAttrs.style = cellStyle;
        cellAttrs.className = className;

        Object.assign(cellAttrs, mixin);

        return (
            <div {...cellAttrs} onClick={handleClick} onMouseMove={() => this.setState({isHovered: true})}
                 onMouseOut={() => this.setState({isHovered: false})}>
                {resizeTop}
                {cellContent}{menu}
                {resizeBottom}
            </div>
        );
    }

    getHeight() {
        let height = -this.defaultCellMargin;

        GeneralHelper.forEachObj(this.props.timeLine, timeLineItem => {
            let timeLineItemHeight = timeLineItem.height || this.defaultCellHeight;
            height += timeLineItemHeight + this.defaultCellMargin;

        }, this.props.timeStart, this.props.timeEnd);


        return height;
    }

    getCellNode() {
        return ReactDOM.findDOMNode(this);
    }

    onResizeStop(height, direction, isTop) {
        const {timeEnd, timeStart, maxHeightBottom, maxHeightTop, realTimeStart} = this.props;
        let timeHeight = [];
        let timeIndex = null;
        let newTimeEnd = null;
        let newTimeStart = null;
        let collCell = 0;
        const timeLine = Object.keys(this.props.timeLine);
        timeLine.push(this.props.timeTo);

        Helper.forEachObj(this.props.timeLine, (value, time) => {
            if (value.height === null) {
                value.height = this.defaultCellHeight;
            }
            if (isTop) {
                if (direction) {
                    if (time < timeStart) {
                        timeHeight.push(value.height);
                    }
                } else {
                    if (time >= timeStart) {
                        timeHeight.push(value.height);
                    }
                }
            } else {
                if (direction) {
                    if (time < timeEnd) {
                        timeHeight.push(value.height);
                    }
                } else {
                    if (time >= timeEnd) {
                        timeHeight.push(value.height);
                    }
                }
            }

        });

        if (isTop) {
            timeIndex = timeLine.indexOf(realTimeStart);
            if (direction) {
                timeHeight = timeHeight.reverse();
            }
        } else {
            timeIndex = timeLine.indexOf(timeEnd);
            if (timeIndex === -1) {
                timeIndex = timeLine.length;
            }
            if (direction) {
                timeHeight = timeHeight.reverse();
            }
        }

        let colTimeHeight = 0;

        timeHeight.forEach(i => {
            if (height / colTimeHeight > 1) {
                colTimeHeight += i + this.defaultCellMargin;
                collCell += 1;
            }
        });

        if (isTop) {
            if (direction) {
                collCell = -collCell;
            } else {
                collCell -= 1;
            }
        } else {
            if (direction) {
                collCell = -collCell + 1;
            }
        }

        timeLine.forEach((time, index) => {
            if (index === (timeIndex + collCell)) {
                if (isTop) {
                    newTimeStart = time;
                    newTimeEnd = timeEnd;
                } else {
                    newTimeStart = realTimeStart;
                    newTimeEnd = time;
                }
            }
        });


        if (isTop) {
            if (newTimeStart >= timeEnd || maxHeightTop.newTime > newTimeStart) {
                return false;
            }
        } else {
            if (newTimeEnd <= timeStart || maxHeightBottom.newTime < newTimeEnd) {
                return false;
            }
        }

        this.props.resizeVisit(this.props.date, realTimeStart, newTimeStart, newTimeEnd, this.props.chairId);
        const command = new ServerCommand('visit/update-time', {
            id: this.props.visitId,
            timeStart: newTimeStart,
            timeEnd: newTimeEnd,
        });
        command.exec().then(() => {
            this.props.onUpdate();
            this.setState({activeClass: false});
        });
    }

    onResizeStart() {
        this.setState({activeClass: true});
    }

}

export default Cell;
