import React from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {Label} from '../../../../../../components-ui/DataDisplay/Label';
import {commonLabelOption, commonSelectOption} from '../../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import Select from '../../../../../../components-ui/Inputs/Select/Select';
import {OptionSelect} from '../../../../../../components-ui/Inputs/Select/typesSelect';

interface Props {
    type?: OptionSelect
    handleType: (val: OptionSelect) => void
    isValid: boolean
}

const SelectPictureType = ({type, handleType, isValid}: Props) => {

    const xrayPictureTypes = useSelector(state => state.common.xrayPictureTypes);

    const optionsXrayPictureTypes = _.map(xrayPictureTypes, (type, key) => {
        return {value: key, label: type};
    });

    return (
        <Label labelText="Тип" {...commonLabelOption} isRequired={true} >
            <Select {...commonSelectOption}
                    colorSelect="white"
                    placeholder="Выберите тип снимка"
                    isError={isValid}
                    value={type}
                    options={optionsXrayPictureTypes}
                    onChange={(value) => handleType(value)}/>
        </Label>
    );
};

export default SelectPictureType;