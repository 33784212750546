import {useMemo} from "react";
import _ from 'lodash'
import {useSelector} from "react-redux";
import {InfoCallCenter} from "../types/hooks";
import {StateCallCenter} from "../types/components";
import {NoticesQuantity, TasksInfo} from "../../../containers/Tasks";
import prepareGroupsButtonName from "../../../containers/Tasks/utils/prepareGroupsButtonName";

interface Props {
    state: StateCallCenter
    infoCallCenter: InfoCallCenter,
    tasksInfo: TasksInfo,
    noticesQuantity: NoticesQuantity
}

const useGroupsButtonName = ({state, infoCallCenter, tasksInfo, noticesQuantity}: Props) => {
    const {isMarketing} = state;
    const isMarketingAdmin = useSelector(state => state.user.role) === 'marketing-admin';

    return useMemo(() => {
        const allTypes = _.cloneDeep(infoCallCenter.types);
        allTypes['all'] = {
            priority: 1000000,
            code: 'all',
            marketing: false,
            callRequired: false,
        }
        let cloneAmount = Object.keys(_.cloneDeep(noticesQuantity)).sort((a, b) => {
            return allTypes[b].priority - allTypes[a].priority
        });

        if (!isMarketing) {
            cloneAmount = cloneAmount.filter(key => {
                if (key === 'all') {
                    return true
                } else {
                    const currentMarketing = allTypes[key].marketing
                    return isMarketingAdmin ? currentMarketing : !currentMarketing
                }
            })
        }

        return prepareGroupsButtonName({cloneAmount, tasksInfo})
    }, [Object.keys(noticesQuantity).length, isMarketing])
}

export default useGroupsButtonName
