import React, {Fragment} from "react";
import {LinkParameters} from "../../CaseHistory/styledCaseHistory";
import {PText} from "../parametersInterfaces";

export default function Text({text, selected, radioValues, inputValues, active, setActive, checkboxesValues}: PText) {
    return <>
        {
            text.map((item) => {
                if (typeof item === "string") {
                    return (
                        <Fragment key={item}>
                            {item}
                        </Fragment>);
                } else {
                    let linkText = '' as string | null | string[] | any;
                    switch (selected[item.name]) {
                        case "radio" :
                            linkText = radioValues[item.name];
                            break;
                        case "checkbox" :
                            linkText = checkboxesValues[item.name]
                                .filter((option: any) => option.isDefault)
                                .map((option: any) => option.text)
                                .join(', ');
                            break;
                        default:
                            linkText = inputValues[item.name];
                            break;
                    }
                    if (linkText === '') linkText = '_ _ _ _';
                    return <LinkParameters key={item.name}
                                           active={active.name === item.name}
                                           onClick={() => setActive(item)}
                                           children={linkText}/>
                }
            })
        }
    </>
}
