import styled from "styled-components";
import {PFullScreenButton, PTitleInfo} from "./typesInfo";

export const InfoWrap = styled.div.attrs({className: 'InfoWrap'})<PFullScreenButton>`
    width: ${props => props.full ? '533px' : '498px'};
    position: ${props => props.full ? 'fixed' : 'relative'};
    right: ${props => props.full ? '18px' : '0'};
    top: 0;
    height: ${props => props.full ? "100vh" : '412px'};
    z-index: 12;
    & > div {
        height: 100%;
        overflow: hidden;
    }
`

export const InfoContentWrap = styled.div.attrs({className: 'InfoContentWrap'})`
    padding-right: 17px;
`

export const InfoItem = styled.div.attrs({className: 'InfoItem'})`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const InfoTitle = styled.div.attrs({className: 'InfoTitle'})`
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${props => props.theme.colors.text};
    margin-bottom: 9px;
    margin-top: 19px;
`

export const InfoSubtitle = styled.div.attrs({className: 'InfoSubtitle'})`
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: ${props => props.theme.colors.text};
    margin-bottom: 4px;
`

export const InfoTextList = styled.div.attrs({className: 'InfoTextList'})`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
`

export const InfoText = styled.div.attrs({className: 'InfoText'})`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${props => props.theme.colors.text};
    margin-bottom: 4px;
    max-width: 100%;
    display: flex;
`

export const FullScreenButton = styled.button.attrs({className: 'FullScreenButton', type: 'button'})`
    padding: 0;
    margin: 0;
    position: absolute;
    right: 20px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    border: none;
    background: ${props => props.theme.colors.secondary};
    cursor: pointer;
    outline: none;
    z-index: 5;
    & > div {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                width: 100%;
                height: 100%;
                fill: ${props => props.theme.colors.svgGray};
            }
        }
    }
    &:hover {
        background: ${props => props.theme.colors.bgButtonGray};
        & > div & > div & > svg {
            fill: ${props => props.theme.colors.bgDarkActive};
        }
    }
`

export const WrapService = styled.div.attrs({className: 'WrapService'})`
    margin-top: 8px;
`

export const TitleInfo = styled.div.attrs({className: 'TitleInfo'})<PTitleInfo>`
    display: flex;
    align-items: center;
    cursor: pointer;
    .title-info-text {
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: ${props => props.theme.colors.info};
        margin-right: 8px;
    }
    .title-info-triangle {
        width: 10px;
        height: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                width: 100%;
                height: 100%;
                transition: all .3s;
                fill: ${props => props.theme.colors.info};
                transform: rotate(${props => props.open ? '180deg' : '0'});
            }
        }
    }
`

export const WrapDateAndText = styled.div.attrs({className: 'WrapDateAndText'})`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: 4px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 100%;
        border-bottom: 2px dotted #c0c3ca;
    }
`

export const ServiceText = styled.div.attrs({className: 'ServiceText'})`
    width: calc(100% - 110px);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${props => props.theme.colors.text};
    position: relative;
    z-index: 2;
`

export const ServiceTextChild = styled.div.attrs({className: 'ServiceTextChild'})`
    width: max-content;
    background: ${props => props.theme.colors.white};
`

export const ServiceDate = styled.div.attrs({className: 'ServiceDate'})`
    width: 110px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: ${props => props.theme.colors.text};
    background: ${props => props.theme.colors.white};
    position: relative;
    z-index: 2;
`


