import Helper from "../helpers/main";
import $ from 'jquery'

/**
 * Печатает документы
 */
export default class Printer
{
    /**
     * Печатает документы
     * @param documents - массив, элементами которого могут быть id документов, либо объект с ключами id (id документа) и template (шаблон вывода документа)
     * @return Promise
     */
    static print(documents)
    {
        return new Promise((resolve, reject) =>
        {
            if (documents && documents.length > 0) {
                let documentsList = documents.map(document => (typeof document === 'number') ? {id: document} : document);
                window.open(Helper.resolveUrl('/docs/?') + $.param({documents: documentsList}), '_blank');

                setTimeout(() => resolve(), 500);
            } else {
                resolve();
            }
        });
    }
}