import React from 'react';
import {useNewVisitForm} from "../NewVisitForm";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";
import {Footer, FooterButtonWrap} from "../style/common";
import {BoxBody} from "../../../components-ui/styledComponent/common/wraps";
import Button from "../../../components-ui/Inputs/Button/Button";
import _ from 'lodash';
import ValidationLinkInfo from "../../../components/ValidationLinkInfo/ValidationLinkInfo";
import useSubmit from "../hooks/useSubmit";
import useValidationOfFields from "../hooks/useValidationOfFields";
import useNewValidateForm from "../../../containers/PatientField/hooks/useNewValidateForm";

export default function FooterNewVisit() {
    const {loaderSubmit, handleSubmit} = useSubmit()
    const {
        form,
        isOtherPatientValue,
        setForm,
        patientDuplicate,
        otherPatientDuplicate,
        onClickPatientDuplicate,
        onClickOtherPatientDuplicate,
        fields
    } = useNewVisitForm();
    const {basic, otherPatient} = form;
    const {phoneOwner} = basic

    const validate = useValidationOfFields();
    const prepareNewValidationFields = useNewValidateForm({fields})

    const onClickIsValidate = () => {
        setForm(prevState => {
            prevState.basic.validationFields = prepareNewValidationFields(prevState.basic)
            if (((!!phoneOwner && phoneOwner.value === 'other') || isOtherPatientValue) && otherPatient.isProvidedData) {
                prevState.otherPatient.validationFields = prepareNewValidationFields(prevState.otherPatient)
            }
            if (Object.keys(prevState.relations.relations).length > 0) {
                _.forEach(prevState.relations.relations, (val, key) => {
                    if (val === null || val === undefined) prevState.relations.validationFields[key] = true;
                });
            }
            return {
                ...prevState
            }
        })
    }

    return (
        <BoxBody nonBorderBottom={true}>
            <Footer>
                <Checkbox
                    label='По соцзащите'
                    onChange={(isSocial: boolean) => setForm(prevState => ({...prevState, isSocial}))}
                    checked={form.isSocial}
                />
                <FooterButtonWrap>
                    <Button
                        onClick={handleSubmit}
                        loader={loaderSubmit}
                        variant={validate && !patientDuplicate && !otherPatientDuplicate ? 'success' : 'disabled'}
                        children='Записать пациента'
                        margin="0 20px 0 0"
                    />
                    <ValidationLinkInfo validate={patientDuplicate === null} onClickIsValidate={onClickPatientDuplicate}
                                        text="Введённые данные пациента уже есть в базе. Исправьте данные, либо"
                                        linkText="выберите пациента из базы"/>
                    {patientDuplicate === null && (
                        <ValidationLinkInfo validate={otherPatientDuplicate === null}
                                            onClickIsValidate={onClickOtherPatientDuplicate}
                                            text="Введённые данные владельца телефона уже есть в базе. Исправьте данные, либо"
                                            linkText="выберите владельца телефона из базы"/>
                    )}
                    {patientDuplicate === null && otherPatientDuplicate === null && (
                        <ValidationLinkInfo validate={validate} onClickIsValidate={onClickIsValidate}/>
                    )}

                </FooterButtonWrap>
            </Footer>
        </BoxBody>

    )
}
