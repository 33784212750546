export default class IntersectionCatcher {
    catchHandlers = [];

    constructor() {
        this.observer = new IntersectionObserver(this._handleIntersection, {threshold: 1});
    }

    _handleIntersection = (entries) => {
        entries.forEach(entry => {
            this.catchHandlers.forEach((catchHandler, index) => {
                const isSameTarget = catchHandler.target === entry.target;

                if(isSameTarget) {
                    catchHandler.handle();
                }

                if(isSameTarget || !catchHandler.target.isConnected) {
                    this.observer.unobserve(catchHandler.target);
                    this.catchHandlers.splice(index, 1);
                }
            });
        });
    };

    watchTarget(target, catchHandler) {
        this.observer.observe(target);
        this.catchHandlers.push({
            handle: catchHandler,
            target
        });
    }
}