import React from "react";
import {PropsCallHistory} from "./types/common";
import useInitialCallHistory from "./hooks/useInitialCallHistory";
import Loader from "../../../../components-ui/Feedback/Loader/Loader";
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import Table from "../../../../components/Table/Table";
import {columnTable} from "./helper/columnTable";
import Helper from "../../../../common/helpers/main";
import {callCenterTasksInfo} from "../../../CallCenter/helper/getDataCallCenter";
import SVG from "../../../../common/helpers/SVG";
import Paper from "../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Box from "../../../../components-ui/Layout/Box";

export default function CallHistory({personalPhone}: PropsCallHistory) {
    const {loader, historyData} = useInitialCallHistory({personalPhone})
    if (loader) {
        return (
            <Box display="flex" width="100%" height="200px" position="relative">
                <Loader visible={true} isBackground={false}/>
            </Box>
        )
    } else if (!!historyData.completedCalls && !!historyData.completedCalls.length) {
        const prepareDataItem = ({item}: any) => {
            item.date = Helper.Date.formatDate(item.startTime, 'DD MMMM в HH:mm');
            const user = historyData.users.find(user => user.id === item.userId) as any;
            if (!!user) {
                item.user = Helper.getFio(user)
            } else {
                item.user = '-'
            }
            item.type = <TypeCall incoming={item.incoming}/>
            item.status = <AnswerTime answerTime={!!item.answerTime}/>;
            if (!!item.callerType) {
                item.callerType = historyData.callerTypes[item.callerType];
            } else {
                item.callerType = '-';
            }
            if (!!item.topics && !!item.topics.length) {
                item.topics = item.topics.filter((topic: string) => topic !== 'all')
                    .map((topic: string) => historyData.directionsNames[topic]).join(', ');
            }
            if (!!item.tasksTypes && !!item.tasksTypes.length) {
                item.tasks = item.tasksTypes.map((taskType: string) => callCenterTasksInfo[taskType].name).join(', ')
            } else {
                item.tasks = '-'
            }
            if (!item.comment) item.comment = '-';
        }
        return (
            <div className="card card--call-history">
                <Table
                    columns={columnTable}
                    data={historyData.completedCalls}
                    selectMode="none"
                    prepareDataItem={prepareDataItem}
                    scrollHeight={567}
                />
            </div>
        )
    } else {
        return (
            <Paper maxWidth="max-content">
                <Typography children="Нет завершённых звонков"
                            variant="bigger-title"
                            textAlign="center" padding="45px 60px 71px"/>
            </Paper>

        )
    }
}

const TypeCall = ({incoming}: {incoming: boolean}) => {
    return (
        <div title={incoming ? 'Входящий' : 'Исходящий'}>
            <SVG className="icon-call-history-type"
                 name={`call-center/${incoming ? 'incoming' : 'outgoing'}`}/>
        </div>
    )
}

const AnswerTime = ({answerTime}: { answerTime: boolean }) => {
    return (
        <div title={answerTime ? 'Дозвонился' : 'Не дозвонился'}>
            <SVG
                className={`icon-call-history-status icon-call-history-status--${answerTime ? 'check' : 'close'}`}
                name={answerTime ? 'check' : 'close'}/>
        </div>
    )
}
