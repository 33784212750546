import React from "react";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import {useChoiceOrCompletion} from "../ChoiceOrCompletion";
import { CHOICE } from "../helper/constants";

export default function Title() {
    const {parentName, completionHasTabs} = useChoiceOrCompletion();

    let titleText;

    if (parentName === CHOICE) {
        titleText = 'Решение пациента на лечение';
    } else if (completionHasTabs) {
        titleText = 'План лечения не закончен, запишите пациента на следующий прием';
    } else {
        titleText = 'Завершение приема';
    }

    return (
        <Typography variant="bigger-title"
                    children={titleText}
                    margin={`24px 0 32px`} textAlign="center"/>
    )
}
