import React, {forwardRef} from 'react';
import { WrapIcon } from '../../../containers/WrapIcon';
import { IIcon } from '../../../types/common';

export const PrepareVisitIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M21,0a3,3,0,0,1,3,3V21a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Zm0,2H3a1,1,0,0,0-1,.88V21a1,1,0,0,0,.88,1H21a1,1,0,0,0,1-.88V3A1,1,0,0,0,21,2ZM9.54,16.3a1,1,0,0,1,0,1.33L6.87,20.29a.94.94,0,0,1-1.33,0L4.27,19A.94.94,0,0,1,5.6,17.7l.61.6,2-2A.94.94,0,0,1,9.54,16.3Zm9.4,1.06a.94.94,0,1,1,0,1.87h-6.5a.94.94,0,1,1,0-1.87ZM9.54,10a1,1,0,0,1,0,1.33L6.87,14a1,1,0,0,1-1.33,0L4.27,12.73a1,1,0,0,1,0-1.33.94.94,0,0,1,1.33,0l.61.61,2-2A1,1,0,0,1,9.54,10Zm9.4,1.06a.94.94,0,1,1,0,1.88h-6.5a.94.94,0,1,1,0-1.88ZM9.54,3.71A.94.94,0,0,1,9.54,5L6.87,7.7a.94.94,0,0,1-1.33,0L4.27,6.43A.94.94,0,0,1,5.6,5.1l.61.61,2-2A.94.94,0,0,1,9.54,3.71Zm9.4,1.06a.94.94,0,1,1,0,1.87h-6.5a.94.94,0,1,1,0-1.87Z"/>
        </WrapIcon>
    );
});
