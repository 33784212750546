import styled from "styled-components";
import {SButton} from "../../../../components-ui/Inputs/Button/styledButton";

export const WrapDentalCardButtons = styled.div.attrs({className: 'WrapDentalCardButtons'})`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const WrapDentalCardButton = styled.div.attrs({className: 'WrapDentalCardButton'})`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const DentalCardButton = styled(SButton)`
    width: 200px;
    color: #4f5965;
`

export const WrapCheckLoading = styled.div.attrs({className: 'WrapCheckLoading'})`
    display: flex;
    align-items: center;
    margin-left: 18px;
    .check-loading-svg {
        width: 12px;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                width: 100%;
                height: 100%;
                fill: ${props => props.theme.colors.success};
            }
        }
    }
    .check-loading-text {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: ${props => props.theme.colors.textGray};
          margin-left: 6px;
    }
`

export const WrapConfirmButtons = styled.div.attrs({className: 'WrapConfirmButtons'})`
    display: flex;
    & > button {
        margin-left: 16px;
        color: #4f5965;
    }
`
