import React from 'react';
import ServerCommand from "../../../common/server/server-command";
import Helper from "common/helpers/main";
import TableWithGroupsTree from "../../../components/TableWithGroupsTree/TableWithGroupsTree";

const materialColumns = [
    {
        title: 'Код',
        code: 'code',
        searchable: true
    },
    {
        title: 'Материал',
        code: 'name',
        searchable: true
    },
    {
        title: 'Остаток',
        code: 'quantity',
    },
    {
        title: 'Ед. измерения',
        code: 'measure'
    },
];

const materialCases = {
    nominative: 'материал',
    genitive: 'материала',
    accusative: 'материал',
    newNominative: 'Новый материал',
    newGenitive: 'нового материала'
};

export default class MaterialsList extends React.Component {
    state = {
        materialsGroupsList: null,
        materialsList: null,
        selectedMaterialId: 0,
        measureUnits: null,
        fields: null
    };

    componentDidMount() {
        this.updateData()
    }

    render() {
        if (this.state.materialsList === null) {
            return null
        }

        let groups = [{
                code: "ALL",
                groupId: 0,
                groups: this.state.materialsGroupsList,
                id: 0,
                name: "Все группы",
                isOpened: true
            }],
            tableData = Helper.clone(this.state.materialsList)

        const fields = Helper.clone(this.state.fields)

        fields.measureId.type = 'enum'
        fields.code.size = "small"
        fields.measureId.variants = this.state.measureUnits.map((measure) => {
            return {
                code: measure.id,
                title: measure.name
            }
        })


        fields.code.isCleaningOnCopy = true

        const editableFields = [fields.code, fields.name, fields.measureId]

        tableData = tableData.map(material => {
            material.measure = this.state.measureUnits[material.measureId - 1].shortName

            return material
        });

        const methods = {
            addItem: 'material/add',
            addGroup: 'material/add-group',
            updateItem: 'material/update',
            updateGroup: 'material/update-group',
            deleteItem: 'material/delete',
            deleteGroup: 'material/delete-group'
        }

        return (
            <div className="card">
                <div className="materials__table">
                    <TableWithGroupsTree
                        groups={groups}
                        columns={materialColumns}
                        data={tableData}
                        getRowCode={(material) => material.id}
                        searchPlaceholder="Поиск материала"
                        selectMode="single"
                        onSelect={(materialsIds, selectedMaterialId) => this.setState({selectedMaterialId})}
                        selectedRows={[this.state.selectedMaterialId]}
                        editMode={true}
                        clearSelection={this.clearSelection.bind(this)}
                        updateData={this.updateData.bind(this)}
                        scrollHeight={260}
                        cases={materialCases}
                        popupFields={editableFields}
                        methods={methods}
                    />
                </div>
            </div>
        )
    }

    updateData() {
        let command = new ServerCommand('material/get-list', {
            getMeasureUnits: true,
            getGroups: true,
            getFields: true
        });

        command.exec().then(response => this.setState({
            materialsGroupsList: response.groups,
            materialsList: response.list,
            measureUnits: response.measureUnits,
            fields: response.fields
        }));
    }

    clearSelection() {
        this.setState({selectedMaterialId: 0})
    }
}
