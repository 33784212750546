import React, {useContext} from "react";
import '../../style/Modal.scss';
import ReactModal from 'react-modal'
import SVG from "../../../../common/helpers/SVG";
import {customStyleModalStandard} from "../../style/modal";
import {ContextModal} from "../../types/modals";

interface PropsModal {
    isOpen?: boolean,
    isIconClose?: boolean,
    isNotClose?: boolean,
    closeModal: () => void,
    customStyles?: any,
    children: any,
    shouldCloseOnEsc?: boolean
}

export const ModalContext = React.createContext({} as ContextModal);
export const useModal = () => useContext(ModalContext);

export default function Modal(
    {
        isOpen = false,
        isIconClose = true,
        isNotClose = false,
        closeModal,
        customStyles,
        children,
        shouldCloseOnEsc = true
    }: PropsModal) {

    const styles = customStyleModalStandard(customStyles)

    return (
        <ReactModal
            isOpen={isOpen}
            style={styles}
            onRequestClose={() => closeModal()}
            ariaHideApp={false}
            shouldCloseOnEsc={!isNotClose && shouldCloseOnEsc}
            shouldCloseOnOverlayClick={!isNotClose}
        >
            {isIconClose && <div className="modal__close" onClick={() => closeModal()}>
                <SVG name="close"/>
            </div>}
            <ModalContext.Provider value={{
                closeModal
            }}>
                {children}
            </ModalContext.Provider>
        </ReactModal>
    )
}
