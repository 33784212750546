import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import Radio from "../../../../../components-ui/Inputs/Radio/Radio";

export default function RadioPage() {
    const [value, setValue] = useState(false)
    return (
        <>
            <h1>RadioPage</h1>
            <WrapContent>
                <div>
                    <Radio value="Radio1" checked={value} onClick={() => setValue(!value)} children="Radio кнопка"/>
                    <pre>
                        {
                            `<Radio value="Radio1" checked={value} onClick={() => setValue(!value)} children="Radio кнопка"/>`
                        }
                    </pre>
                </div>

            </WrapContent>
        </>
    )
}
