import React, {Dispatch, SetStateAction} from 'react';
import {ScriptType, ScriptGroupList} from '../../../types/common';
import EditScript from '../components/EditScript/EditScript';
import SelectedScript from '../components/SelectedScript';

interface Props {
    selectedScript: ScriptType | null;
    isEditing: boolean;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
    groupsScript: {[p: string]: ScriptGroupList[]}
    isNewScript: boolean
    setIsNewScript: Dispatch<SetStateAction<boolean>>;
    refreshDataFromServer: () => void;
}
const Script = ({selectedScript, isEditing, setIsEditing, groupsScript, isNewScript, setIsNewScript, refreshDataFromServer}: Props) => {
    return (
        <>
            {isEditing ?
                <EditScript selectedScript={!isNewScript ? selectedScript : null}
                            setIsEditing={setIsEditing}
                            setIsNewScript={setIsNewScript}
                            groupsScript={groupsScript}
                            refreshDataFromServer={refreshDataFromServer}
                />
                :
                <SelectedScript selectedScript={selectedScript} setIsEditing={setIsEditing} refreshDataFromServer={refreshDataFromServer}/>
            }
        </>
    );
};

export default Script;
