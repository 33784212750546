import React, {forwardRef} from 'react';
import { WrapIcon } from '../../../containers/WrapIcon';
import { IIcon } from '../../../types/common';

export const DocumentDownloadIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 20 24"} ref={ref}>
            <path d="M13.4,0,20,6V24H0V0ZM12.31,1.41H1.54V22.59H18.46V7H12.31ZM10.83,7.5v4.85H15L10,18,5,12.35H9.17V7.5Zm3-5.1V5.63h3.52Z"/>
        </WrapIcon>
    );
});
