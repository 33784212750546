import React from 'react';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import useTableFormatter from './useTableFormatter';
import {UserList} from '../../types';
import Table from '../../../../../components-ui/DataDisplay/Table';
import AddingEditingEmployee from '../modals/AddingEditingEmployee';
import { DebouncedFunc } from 'lodash';

interface Props {
    data: UserList[]
    refScrollList: any
    onScroll: DebouncedFunc<() => void>
    refreshDataFromServer: () => void
}

const EmployeesTable = ({data, refScrollList, onScroll, refreshDataFromServer}: Props) => {

    const {showSlideModal} = useDialogsManager();

    const {employeesColumn} = useTableFormatter()

    return (
            <Table
                variantTable="outline"
                maxHeight="520px"
                columns={employeesColumn}
                rows={data}
                refScrollList={refScrollList}
                onScroll={onScroll}
                onClickRow={(row)=> showSlideModal(<AddingEditingEmployee userId={row.id} refreshDataFromServer={refreshDataFromServer}/>)}
            />

    );
};

export default EmployeesTable;
