import React from 'react';
import {OptionSelect} from '../../../../../../components-ui/Inputs/Select/typesSelect';
import _ from 'lodash';
import {notTrashed} from '../../../../../../common/helpers/general-helper';
import {User} from '../../../../../../types';
import {useSelector} from 'react-redux';
import {Label} from '../../../../../../components-ui/DataDisplay/Label';
import {commonLabelOption, commonSelectOption} from '../../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import Select from '../../../../../../components-ui/Inputs/Select/Select';

interface Props {
    creator?: OptionSelect
    handleCreator: (val: OptionSelect) => void
    selectedBranch: number
    isValid: boolean
}

const SelectCreator = ({creator, handleCreator, selectedBranch, isValid}: Props) => {

    const users = useSelector(state => state.common.users);

    const optionsFiltredCreator = _
        .chain(users)
        .filter(notTrashed)
        .filter((user: User) => user.branches.includes(selectedBranch) && user.role === "assistant")
        .map((user: User) => {
            return {value: user.id, label: user.fio};
        })
        .sortBy(user => user.label)
        .value();


    return (
        <Label labelText="Сотрудник" isRequired={true} {...commonLabelOption} >
            <Select {...commonSelectOption}
                    colorSelect="white"
                    placeholder="Выберите сотрудника"
                    value={creator}
                    isError={isValid}
                    options={optionsFiltredCreator}
                    onChange={(value) => handleCreator(value)}/>
        </Label>
    );
};

export default SelectCreator;