import {Dispatch, SetStateAction, useEffect} from "react";
import {PropsLoadingReports, ReportParams, StateAllDateRange, StateAnalytics} from "../types";
import {prepareReportsParam} from "../utils";
import _ from "lodash";
import ServerCommand from "../../../common/server/server-command";
import Helper from "../../../common/helpers/main";
import {useSelector} from "react-redux";
import useLoader from "../../../components/Errors";

interface Props {
    setAllDateRange: Dispatch<SetStateAction<StateAllDateRange>>
    setState: Dispatch<SetStateAction<StateAnalytics>>
    allDateRange: StateAllDateRange
    state: StateAnalytics
    selectedBranches: number[]
}

const useLoadingReports = ({setState, setAllDateRange, allDateRange, state, selectedBranches}: Props) => {
    const userBranches = useSelector(state1 => state1.user.branches)
    const [loaderPage, setLoaderPage] = useLoader(true);

    const loadingReports = ({startDate, endDate, widgetCode, branches}: PropsLoadingReports) => {
        let reports: ReportParams[];

        if (!!widgetCode) {
            setState(prevState => ({...prevState, [widgetCode]: {...prevState[widgetCode], loader: true}}))
            reports = [{...prepareReportsParam({startDate, endDate, widgetCode, widget: state[widgetCode]})}]
        } else {
            setAllDateRange(prevAllDateRange => ({...prevAllDateRange, loader: true}))
            setState(prevState => {
                _.forEach(prevState, (widget, widgetCode) => {
                    prevState[widgetCode as keyof StateAnalytics].loader = true;
                })
                return {...prevState}
            })
            reports = _.map(state, (widget, widgetCode): ReportParams => {
                return {
                    ...prepareReportsParam({
                        startDate: !!startDate ? startDate : !!endDate ? allDateRange.startDate : widget.startDate,
                        endDate: !!endDate ? endDate : !!startDate ? allDateRange.endDate : widget.endDate,
                        widget,
                        widgetCode: widgetCode as keyof StateAnalytics
                    })
                }
            })
        }

        ServerCommand.get('analytics/report/list', {
            reports,
            branches: !!branches ? !!branches.length ? branches : userBranches : !!selectedBranches.length ? selectedBranches : userBranches
        }).then(response => {
            setState(prevState => {
                _.forEach(response.reports, (report, reportCode) => {
                    const widgetCode = Helper.getCamelCase(reportCode, false) as keyof StateAnalytics;
                    prevState[widgetCode].report = report
                    prevState[widgetCode].loader = false
                })
                return {...prevState}
            })
            setAllDateRange(prevAllDateRange => ({...prevAllDateRange, loader: false}))
            if (loaderPage) setLoaderPage(false);
        })
    }

    useEffect(() => loadingReports({}), [])

    return {
        loadingReports,
        loaderPage,
        setLoaderPage
    }
}

export default useLoadingReports
