import React from "react";
import ReactTooltip from "react-tooltip";
import './Tooltip.scss'

interface Offset {
    top?: number;
    right?: number;
    left?: number;
    bottom?: number;
}

export type Place = 'top' | 'right' | 'bottom' | 'left';
export type Variant = 'dark' | 'white';
export type Effect = 'float' | 'solid';

export interface TooltipProps {
    children?: React.ReactNode;
    place?: Place; // Tooltip styling theme
    effect?: Effect; // Global tooltip offset, e.g., offset={{ top: 10, left: 5 }}
    offset?: Offset; // Support <br /> to make explicitly multiline tooltip comments
    multiline?: boolean; // Add 1px white border
    className?: string;// HTML id attribute
    id?: string;// Inject raw HTML? (This is a security risk)
    html?: boolean;// Time delay for hiding popup
    disable?: boolean;
    clickable?: boolean;
    variant?: Variant,
    isArrow?: boolean,
    globalEventOff?: string,
    delayUpdate?: number,
    delayShow?: number
    [key: string]: any
}
export default function Tooltip({className, ...props}: TooltipProps) {
    return (
        <ReactTooltip {...props}
                      className={`react-tooltip react-tooltip--${props.variant} ${!props.isArrow && 'react-tooltip--no-arrow'} ${className}`}
                      event="mouseover mouseenter"
                      eventOff="mouseleave mouseout scroll mousewheel blur"
        />
    )
}

Tooltip.show = (target: Element) => {
    ReactTooltip.show(target)
};
Tooltip.hide = (target?: Element) => {
    ReactTooltip.hide(target)
};
Tooltip.rebuild = () => {
    ReactTooltip.rebuild()
};

Tooltip.defaultProps = {
    variant: 'dark',
    multiline: true,
    effect: 'solid',
    place: 'top',
    isArrow: true,
    clickable: false
}
