import { useCallback} from "react";
import _ from "lodash";

interface PropsUseLazyLoading {
    onIntersection: any,
    containerRef: any,
    delay?: number,
    marginFromBottom?: number
}

export function useLazyLoading({
                                   onIntersection,
                                   delay = 1000,
                                   containerRef,
                                   marginFromBottom = 10
                               }: PropsUseLazyLoading) {

    return useCallback(
        _.throttle(() => {
            const currentRef = containerRef.current;
            if (!!currentRef) {
                const containerScrollTop = currentRef.scrollTop;
                const containerHeight = currentRef.clientHeight;
                const scrollHeight = currentRef.scrollHeight;
                if (
                    scrollHeight -
                    containerScrollTop -
                    containerHeight -
                    marginFromBottom <=
                    0
                ) {
                    onIntersection();
                }
            }

        }, delay),
        [onIntersection, containerRef, marginFromBottom, delay]
    );
}
