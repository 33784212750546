import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const DocumentIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 19.2 24"} ref={ref}>
            <path d="M17.74,24H1.45A1.43,1.43,0,0,1,0,22.62V1.38A1.43,1.43,0,0,1,1.46,0H14.37a1.15,1.15,0,0,1,.89.4l3.46,3.21a1.2,1.2,0,0,1,.48.94V22.62A1.43,1.43,0,0,1,17.74,24ZM14.33,1.17H1.47a.21.21,0,0,0-.22.21V22.62a.21.21,0,0,0,.21.21H17.73a.21.21,0,0,0,.22-.21V4.56s0,0-.06-.08Zm.06,15.7a.6.6,0,1,1,0,1.2H4.85a.6.6,0,1,1,0-1.2Zm0-3.64a.6.6,0,1,1,0,1.2H4.85a.6.6,0,1,1,0-1.2Zm0-3.63a.6.6,0,1,1,0,1.2H4.85a.6.6,0,1,1,0-1.2ZM10.14,6a.6.6,0,1,1,0,1.2H4.85a.6.6,0,1,1,0-1.2Z"/>
        </WrapIcon>
    );
});
