import React from 'react'
import PropTypes from 'prop-types'
import './TextArea.scss'
import Textarea from 'react-textarea-autosize';
import _ from "underscore";

export default class TextArea extends React.Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
    }

    static propTypes = {
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        placeholder: PropTypes.string,
        initialOpened: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        autoFocus: PropTypes.bool,
        activeClass: PropTypes.string,
        warningClass: PropTypes.bool,
        isError: PropTypes.bool,
        fieldName: PropTypes.string,
        onClickParameters: PropTypes.func
    };

    static defaultProps = {
        placeholder: '',
        initialOpened: false,
        value: '',
        disabled: false,
        autoFocus: false,
        isOnClick: false,
        variant: PropTypes.oneOf(["required"]),
        activeClass: ''
    };

    fieldNode = null;

    render() {
        const {disabled, value : propsValue, isError, fieldName, title, placeholder, activeClass, onChange, autoFocus, onClickParameters} = this.props;

        let formControlClass = (disabled) ? 'form-control--disabled' : '';

        let value = propsValue;
        let isParameters = false;

        if (typeof value !== "string") {
            if (value.prev !== undefined) {
                value = _.clone(value);
                let {prev, next} = value;
                next = next.map(item => {
                    if (typeof item === "string") {
                        return item;
                    } else {
                        return '_ _ _ _'
                    }
                });
                next = next.join(' ');
                value = prev + ' ' + next;
            } else {
                value = _.clone(value).map(item => {
                    if (typeof item === "string") {
                        return item;
                    } else {
                        return '_ _ _ _'
                    }
                });
                value = value.join(' ');
            }
            isParameters = true;

        }

        let className = 'textarea-wrap';
        if (isError) className += ' textarea-variant--danger';
        if (isParameters) className += ' textarea-variant--parameters';

        return (
            <div
                className={className}
                ref={this.textInput}
                id={fieldName}
                onClick={() => {
                    if (isParameters) {
                        if (!!onClickParameters) onClickParameters(propsValue, fieldName);
                    }
                }}
            >

                <label className="textarea-title">{title}</label>
                <Textarea name="text-area"
                          placeholder={placeholder}
                          value={value}
                          className={`form-control ${formControlClass} ${activeClass}`}
                          onChange={onChange}
                          disabled={disabled}
                          onFocus={this.onClick}
                          autoFocus={autoFocus}
                          inputRef={ref => this.fieldNode = ref}
                />
                {isParameters && <span className="textarea-parameters">Заполните поля в тексте</span>}
            </div>
        )
    }

    onClick = () => {
        if (this.props.onClick) this.props.onClick();
    };

    focus = (params) => this.fieldNode.focus(params);

}
