import styled from "styled-components";
import {PropsSAvatar, PropsSAvatarStatus, PropsSWrapAvatar, PropsSAvatarText} from "../types";

const standardSize = '48px'

export const SAvatarWrap = styled.div.attrs({className: 'SAvatarWrap'})<PropsSWrapAvatar>`
    width: ${props => props.size || standardSize};
    height: ${props => props.size || standardSize};
    border-radius: 50%;
    position: relative;
    flex-shrink: 0;
    ${props => !!props.margin && `margin: ${props.margin};`};
    ${props => !!props.bgColorTheme && `background: ${props.theme.colors[props.bgColorTheme]};`}
    ${props => !!props.bgColor && `background: ${props.bgColor};`}
`;

export const SAvatar = styled.div.attrs({className: 'SAvatar'})<PropsSAvatar>`
    width: ${props => props.size || standardSize};
    height: ${props => props.size || standardSize};
    outline: 0;
    background-size: 100%;
    background-image: url("${props => props.img}");
`;

const avatarStatus = (props: PropsSAvatarStatus) => {
    switch (props.status) {
        case "online":
            return props.theme.colors.online;
        case "offline":
            return props.theme.colors.offline;
        default:
            return props.theme.colors.white
    }
};

export const SAvatarStatus = styled.div.attrs({className: 'SAvatarStatus'})<PropsSAvatarStatus>`
    position: absolute;
    top: 4px;
    right: -5px;
    width: calc(${props => props.size || standardSize} / 3.65);
    height: calc(${props => props.size || standardSize} / 3.65);
    background-color: ${props => props.theme.colors.white};
    border-radius: 50%;
    &:after{
        content: '';
        position: absolute;
        top: 30%;
        right: 30%;
        width: calc(${props => props.size || standardSize} / 7.65);
        height: calc(${props => props.size || standardSize} / 7.65);
        background-color: ${avatarStatus};
        border-radius: 50%;
    }
`;

export const SAvatarText = styled.div<PropsSAvatarText>`
    position: absolute;
    top: 0;
    left: 0;
    color: ${props => props.theme.colors.white};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => !!props.fontSizeText ? props.fontSizeText : `calc(${props.size || standardSize} - 6px)`};
    text-transform: uppercase;
    user-select: none;
`
