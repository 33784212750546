import {useEffect, useState} from "react";
import {ResponseAnalyticsDoctorsRecommendations, StateReports} from "../types";
import ServerCommand from "../../../common/server/server-command";
import {getInitialStartDate} from "../utils";
import Helper from "../../../common/helpers/main";
import {allDoctors} from "../data";
import {useSelector} from "react-redux";
import useLoader from "../../../components/Errors";

const useReports = () => {
    const userBranches = useSelector(state => state.user.branches)
    const allBranches = useSelector(state => state.common.allBranches)
    const [state, setState] = useState<StateReports>({
        startDate: getInitialStartDate(),
        endDate: new Date(),
        selectedDoctor: allDoctors,
        selectedBranch: {
            value: userBranches[0],
            label: allBranches[userBranches[0]].name
        }
    })

    const [data, setData] = useState<ResponseAnalyticsDoctorsRecommendations | null>(null);
    const [loaderPage, setLoaderPage] = useLoader(true);
    const [loaderData, setLoaderData] = useLoader();

    const loadData = () => {
        setLoaderData(true)
        ServerCommand.get('analytics/doctors-recommendations', {
            startDate:  Helper.Date.formatDateForServer(state.startDate),
            endDate:  Helper.Date.formatDateForServer(state.endDate),
            branchId: state.selectedBranch.value
        }).then((response: ResponseAnalyticsDoctorsRecommendations) => {
            setData(response);
            setLoaderPage(false)
            setLoaderData(false)
        })
    };

    useEffect(loadData, [state.startDate, state.endDate, state.selectedBranch.value]);

    return {
        state,
        setState,
        data,
        loaderPage,
        loaderData
    }
};

export default useReports;