import React from 'react';
import ConfirmModal from '../../../../../../modals/ConfirmModal';
import {useModal} from '../../../../../../../containers/DialogsManager/containers/Modal/Modal';
import ServerCommand from '../../../../../../../common/server/server-command';
import {StandardDeletionParams} from '../../../../../../../types';

interface Props {
    id: number
    refreshDataFromServer?: () => void
}

const ModalDeleteTimeSheetRecord = ({id, refreshDataFromServer}: Props) => {

    const {closeModal} = useModal()

    const deletePayment = () => {
        ServerCommand.post('timesheet-record/delete', {id: id} as StandardDeletionParams, () => {
            closeModal()
            refreshDataFromServer && refreshDataFromServer()
        })
    }

    return (
            <ConfirmModal
                descrText="Вы точно хотите удалить запись табеля?"
                submitText="Удалить запись табеля"
                onClose={closeModal}
                onSumbit={deletePayment}/>
    );
};

export default ModalDeleteTimeSheetRecord;
