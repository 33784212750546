import ServerCommand from "../../../common/server/server-command";
import {Dispatch, SetStateAction} from "react";
import {StateManagerTasks} from "../types/components";
import useLoader from "../../../components/Errors";

interface Props {
    setState: Dispatch<SetStateAction<StateManagerTasks>>
}

const useMethodsManagerTasks = ({setState}: Props) => {

    const postponeServerCommand = (tasksId: number, date?: any) => {
        ServerCommand.post('manager/task/postpone', {
            tasksIds: [tasksId],
            postponeTo: !!date ? date : null
        }).then(() => {})
    }

    const taskComplete = (taskId: number | number[]) => {
        return new Promise((resolve, reject) => {
            ServerCommand.post('manager/task/complete', {
                tasksIds: typeof taskId === 'number' ? [taskId] : taskId
            }).then(() => {
                setState(prevState => ({...prevState, tasks: prevState.tasks.filter(task => task.id !== taskId)}))
                resolve()
            })
        })

    }

    return {
        postponeServerCommand,
        taskComplete
    }
}

export default useMethodsManagerTasks
