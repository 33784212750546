import React from 'react';
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";

interface Props {
    efficiencyPercent: number
}

export const EfficiencyPercent = ({efficiencyPercent}: Props) => {

    return (
        <LabelTask label="Эффективность входящих звонков"
                   children={`${efficiencyPercent}%`}
                   alignItems="flex-start"
        />
    );
};
