import {Dispatch, SetStateAction, useEffect} from 'react';
import {echo} from '../../../common/server/echo';
import {useSelector} from 'react-redux';
import {StandardDeletedEventData} from '../../../types';
import {GetXrayPicturesListResult, XrayPicture, XrayPictureSavedData} from '../types';
import moment from 'moment';

interface Props {
    selectedBranchId: number;
    creatorId: number;
    startDate: Date;
    endDate: Date;
    setData: Dispatch<SetStateAction<GetXrayPicturesListResult>>;
}

const useServerEvents = ({selectedBranchId, creatorId, startDate, endDate, setData}: Props) => {

    const user = useSelector(state => state.user);

    const suitableForFilters = (xrayPicture: XrayPicture) => {
        return (xrayPicture.creatorId === creatorId || creatorId === 0) &&
            moment(xrayPicture.createdAt).isBetween(startDate, endDate, 'day', '[]')

    };

    const addingPicture = (prevState: GetXrayPicturesListResult, xrayData: XrayPictureSavedData) => (
        {
            pictures: [...prevState.pictures, xrayData.picture],
            patients: !prevState.patients[xrayData.patient.id] ?
                {...prevState.patients, [xrayData.patient.id]: xrayData.patient} :
                {...prevState.patients},
        }
    );

    const deletePicture = (id: number) => {
        setData(prevState => ({
            ...prevState,
            pictures: prevState.pictures.filter(picture => picture.id !== id),
        }));
    };
    //TODO При каждом изменении параметра фильра, происходит переподписка
    useEffect(() => {
        echo.private(`${!user.canViewAllXrayPictures ? 'today-' : ''}xray-pictures.${selectedBranchId}`)
            .listen('XrayPicture\\XrayPictureCreated', (xrayData: XrayPictureSavedData) => {
                suitableForFilters(xrayData.picture) &&
                setData(prevState => (
                    addingPicture(prevState, xrayData)
                ));
            })
            .listen('XrayPicture\\XrayPictureUpdated', (xrayData: XrayPictureSavedData) => {
                if (suitableForFilters(xrayData.picture)) {

                    setData(prevState => {
                        const isExistPicture = () => {
                            if (!!prevState.pictures.find(picture => picture.id === xrayData.picture.id)) {
                                return {
                                    pictures: prevState.pictures
                                        .map(prevPicture =>
                                            prevPicture.id === xrayData.picture.id ?
                                                xrayData.picture :
                                                prevPicture),
                                    patients: !prevState.patients[xrayData.patient.id] ?
                                        {...prevState.patients, [xrayData.patient.id]: xrayData.patient} :
                                        {...prevState.patients},
                                };
                            } else return addingPicture(prevState, xrayData);
                        };

                        return isExistPicture();
                    });
                } else
                    deletePicture(xrayData.picture.id);
            })
            .listen('XrayPicture\\XrayPictureDeleted', ({id}: StandardDeletedEventData) => {
                deletePicture(id);
            });
        return () => {
            echo.leave(`${!user.canViewAllXrayPictures ? 'today-' : ''}xray-pictures.${selectedBranchId}`);
        };
    }, [selectedBranchId, startDate, endDate, creatorId]);
};

export default useServerEvents;