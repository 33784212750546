import {useEffect, useMemo, useState} from "react";
import useInitialDate from "./useInitialDate";
import Helper from "../../../../../common/helpers/main";
import {useDateUpdate} from "../../../../../common/hooks";
import {TasksInfo} from "../../../../../containers/Tasks";
import {ManagerTask} from "../../../types/common";
import getMinMaxDateTime from "../utils/getMinMaxDateTime";
import {PostponeServerCommand} from "../../../types/components";

interface Props {
    task: ManagerTask,
    postponeServerCommand: PostponeServerCommand
    tasksInfo: TasksInfo
}

const usePostponeTask = ({task, tasksInfo, postponeServerCommand}: Props) => {
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);
    const [date, setDate] = useState<any>(useInitialDate({task, tasksInfo}))
    const [switchValue, setSwitchValue] = useState(false);
    const minMaxDateTime = useMemo(() => ({...getMinMaxDateTime(date, task, currentDate)}), [date, task, currentDate])

    const deadlineChange = () => {
        if (task.deadline !== null) {
            const minDeadline = new Date(task.deadline as any)
            if (new Date(currentDate).getTime() >= minDeadline.getTime()) {
                setDate(null)
                setSwitchValue(false)
            } else if (date === null) {
                setDate(minDeadline)
            } else if (minDeadline.getTime() < date.getTime()) {
                setDate(minDeadline)
            }
        }
    }

    useEffect(() => {
        let newSwitchValue = switchValue;
        if (task.postponedTo !== null) {
            const handleSwitchPostponedTo = () => {
                newSwitchValue = true;
                setSwitchValue(newSwitchValue)
                setDate(new Date(task.postponedTo as any))
            }
            if (!switchValue) handleSwitchPostponedTo()

        } else {
            if (switchValue) setSwitchValue(false)
            deadlineChange()
        }
    }, [task])

    useEffect(() => {
        if (switchValue && task.postponedTo === null) {
            const timerId = setTimeout(() => {
                postponeServerCommand(task.id, date)
            }, 3000)
            return () => clearTimeout(timerId)
        } else if (!switchValue && task.postponedTo !== null) {
            const timerId = setTimeout(() => {
                postponeServerCommand(task.id)
            }, 3000)
            return () => clearTimeout(timerId)
        }
    }, [switchValue])

    return {
        date, setDate, switchValue, setSwitchValue, minMaxDateTime
    }

}

export default usePostponeTask
