import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth18Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 12 24"} width={width || "22"} height={height || "44"} ref={ref}>
            <path d="M3.22,0c.72.07,3.3,3.47,3.87,6.07a23.07,23.07,0,0,1-.26,3.71C6.12,5.89,4.67,3.5,3.27,2.14,3,.57,3.15.11,3.21,0ZM0,1.75a1.26,1.26,0,0,1,1,.09c1.94.74,4.43,3.79,5.09,10l0,.18a.78.78,0,0,0,.79.56.77.77,0,0,0,.61-.75c.06-.25,1.37-6.1,0-9.12a1.68,1.68,0,0,1-.22-1c.61.17,3.17,3.12,3.79,14.76A7.08,7.08,0,0,0,3,15.52a6.71,6.71,0,0,0-.71.47C2.18,12.74,1.86,4.34.22,2.1A1.52,1.52,0,0,1,0,1.76Zm11.11,22c-.71.5-1.95,0-3-.46a5.54,5.54,0,0,0-1.91-.56,4.39,4.39,0,0,0-1.53.47c-.78.35-1.58.71-2.18.4s-.88-1-1.15-2.14a4.2,4.2,0,0,1,2.1-5,6.08,6.08,0,0,1,6,.06A4.61,4.61,0,0,1,12,20.66c0,1.65-.3,2.74-.88,3.13Z"/>
        </WrapIcon>
    );
});
