import React from 'react';
import usePaymentsData from "./hooks/usePaymentsData";
import PaymentAbsentMessage from "./containers/PaymentAbsentMessage";
import PaymentsTable from "./containers/PaymentsTable";

interface Props {
    patientId: number
}

const Payments = ({patientId}: Props) => {

    const {paymentResponse} = usePaymentsData({patientId})

    return (
        <>
            {!!paymentResponse ?
                (!paymentResponse.payments.length && !paymentResponse.paymentImports.length) ?
                    <PaymentAbsentMessage/> :
                    <PaymentsTable data={paymentResponse}/>
                : null
            }
        </>


    );
};

export default Payments;
