import React from "react";
import {WrapContent} from "../../../styledUI";
import Paper from "../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Button from "../../../../../components-ui/Inputs/Button/Button";

export default function PaperPage() {
    return (
        <>
            <h1>Paper</h1>
            <WrapContent>
                <div>
                    <div>
                        <Paper>
                            <div>
                                просто текст
                            </div>
                            <Button children="просто кнопка" variant="success" />
                            <div>
                                просто текст
                            </div>
                        </Paper>
                        <pre>
                        {
                            '<Paper>\n' +
                            '     <div>\n' +
                            '         просто текст\n' +
                            '     </div>\n' +
                            '     <Button children="просто кнопка" variant="success" />\n' +
                            '     <div>\n' +
                            '         просто текст\n' +
                            '     </div>\n' +
                            '</Paper>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Paper isModal={true} arrowPosition="bottom">
                            <div>
                                просто текст
                            </div>
                            <Button children="просто кнопка" variant="success" />
                            <div>
                                просто текст
                            </div>
                        </Paper>
                        <pre>
                        {
                            '<Paper isModal={true} arrowPosition="top">\n' +
                            '     <div>\n' +
                            '         просто текст\n' +
                            '     </div>\n' +
                            '     <Button children="просто кнопка" variant="success" />\n' +
                            '     <div>\n' +
                            '         просто текст\n' +
                            '     </div>\n' +
                            '</Paper>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Paper isModal={true} active={true} padding="20px 40px 35px">
                            <div>
                                просто текст
                            </div>
                            <Button children="просто кнопка" variant="success" />
                            <div>
                                просто текст
                            </div>
                        </Paper>
                        <pre>
                        {
                            '<Paper isModal={true} active={true} padding="20px 40px 35px">\n' +
                            '     <div>\n' +
                            '         просто текст\n' +
                            '     </div>\n' +
                            '     <Button children="просто кнопка" variant="success" />\n' +
                            '     <div>\n' +
                            '         просто текст\n' +
                            '     </div>\n' +
                            '</Paper>'
                        }
                    </pre>
                    </div>
                </div>

            </WrapContent>
        </>
    )
}
