import React, {forwardRef} from 'react';
import {WrapIcon} from '../../containers/WrapIcon';
import {IIcon} from '../../types/common';

export const UpperJawIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref} viewBox={viewBox || "0 0 24 23.11"}>
            <path d="M2.07,13A1.45,1.45,0,0,1,3.33,14.6l0,.25a1.42,1.42,0,0,1-1.57,1.24h0A1.44,1.44,0,0,1,.46,14.5l0-.24A1.42,1.42,0,0,1,2.06,13Z"/>
            <path d="M2.53,9.89a1.39,1.39,0,0,1,1.16,1.56l-.06.37A1.34,1.34,0,0,1,2.1,13h0A1.36,1.36,0,0,1,.94,11.41L1,11A1.36,1.36,0,0,1,2.52,9.89Z"/>
            <path d="M3.1,6.78a1.29,1.29,0,0,1,1,1.51l-.09.51a1.27,1.27,0,0,1-1.48,1h0a1.32,1.32,0,0,1-1-1.52l.09-.5a1.29,1.29,0,0,1,1.49-1Z"/>
            <path d="M4.35,3.63a1.22,1.22,0,0,1,.71,1.55L4.78,6a1.17,1.17,0,0,1-1.5.7h0a1.21,1.21,0,0,1-.71-1.55l.28-.78a1.17,1.17,0,0,1,1.49-.71Z"/>
            <path d="M12,1a1,1,0,0,1-1,1h-.75a1,1,0,0,1-1-1,1,1,0,0,1,1-1H11A1,1,0,0,1,12,1Z"/>
            <path d="M9.15,1.15A1,1,0,0,1,8.41,2.4l-.74.2a1,1,0,0,1-1.22-.71v0A1,1,0,0,1,7.18.62L7.92.43a1,1,0,0,1,1.22.71Z"/>
            <path d="M6.35,2.08a1.06,1.06,0,0,1-.18,1.44l-.29.22A1,1,0,0,1,4.51,3.6l0,0a1,1,0,0,1,.18-1.43L5,1.91a1,1,0,0,1,1.37.15Z"/>
            <path d="M22.74,20.19A1.42,1.42,0,0,1,21.16,19h0L21,17.81a1.43,1.43,0,1,1,2.83-.36h0L24,18.59A1.45,1.45,0,0,1,22.74,20.19Z"/>
            <path d="M22.29,16.09a1.42,1.42,0,0,1-1.58-1.24h0l0-.24A1.44,1.44,0,0,1,21.93,13a1.41,1.41,0,0,1,1.58,1.23h0l0,.25A1.44,1.44,0,0,1,22.29,16.09Z"/>
            <path d="M21.92,13a1.36,1.36,0,0,1-1.55-1.13h0l-.05-.37A1.38,1.38,0,0,1,21.47,9.9,1.35,1.35,0,0,1,23,11h0l.05.37A1.38,1.38,0,0,1,21.92,13Z"/>
            <path d="M21.44,9.83a1.29,1.29,0,0,1-1.5-1v0l-.09-.5a1.31,1.31,0,0,1,1-1.52,1.28,1.28,0,0,1,1.5,1h0l.09.51a1.3,1.3,0,0,1-1,1.51Z"/>
            <path d="M20.74,6.66A1.17,1.17,0,0,1,19.23,6V6l-.28-.79a1.22,1.22,0,0,1,.71-1.55,1.17,1.17,0,0,1,1.51.67v0l.28.78A1.22,1.22,0,0,1,20.74,6.66Z"/>
            <path d="M14.79,1a1,1,0,0,1-1,1H13a1,1,0,0,1-1-1h0a1,1,0,0,1,1-1h.75a1,1,0,0,1,1,1Z"/>
            <path d="M17.55,1.87a1,1,0,0,1-1.21.73h0l-.74-.19a1,1,0,0,1-.74-1.25A1,1,0,0,1,16.06.42h0l.74.19A1,1,0,0,1,17.55,1.87Z"/>
            <path d="M19.51,3.57a1,1,0,0,1-1.36.19l0,0-.29-.23a1,1,0,0,1-.18-1.43A1,1,0,0,1,19,1.89l0,0,.29.23A1,1,0,0,1,19.51,3.57Z"/>
            <path d="M.31,20.24s-.87,2.87,1.62,2.87c9.34,0,4.3-12.93,10-12.93M23.53,20.27s.87,2.84-1.61,2.84c-9.35,0-4.31-12.93-10-12.93"/>
            <path d="M1.72,16.22A1.44,1.44,0,0,1,3,17.81L2.84,19a1.42,1.42,0,0,1-1.57,1.24h0A1.44,1.44,0,0,1,0,18.6l.13-1.14a1.42,1.42,0,0,1,1.57-1.24Z"/>
        </WrapIcon>
    );
});
