import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const Employee = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 8 10"} ref={ref}>
            <path d="M5.15979 5.10638C7.60834 5.52403 7.94954 6.18148 7.99421 8.46438L7.99769 8.64797L7.99871 8.68417L7.99933 8.69699L7.99952 8.69879L7.99965 8.69928L7.99973 8.69921L8 8.6963L7.99986 8.87795L7.99821 8.88092L7.99516 8.88609L7.99044 8.89367C7.90359 9.02954 7.17221 10 4.0001 10C0.827958 10 0.0966091 9.02954 0.00976372 8.89367L0.00503714 8.88609L0.00199179 8.88092L0.000341593 8.87795L0 8.63301L0.000509517 8.64027C0.000611827 8.6404 0.000626984 8.64039 0.000642344 8.64038L0.000888078 8.6393L0.00147514 8.63166L0.00252651 8.60596L0.00477705 8.51984L0.00606499 8.45806C0.0513354 6.18037 0.39415 5.5237 2.84044 5.10638L2.84719 5.114L2.85324 5.12049L2.86123 5.12874L2.86766 5.13515L2.87496 5.14225L2.88315 5.14999L2.89223 5.15834L2.90221 5.16725L2.91309 5.17668C2.91497 5.1783 2.9169 5.17993 2.91886 5.18158L2.93111 5.19173C3.08138 5.31433 3.42401 5.52571 4.00015 5.52571C4.50326 5.52571 4.82828 5.36452 5.00264 5.24202L5.01905 5.23029C5.0217 5.22836 5.02432 5.22644 5.0269 5.22453L5.0419 5.21324C5.0516 5.20582 5.06067 5.19863 5.06914 5.19173L5.08138 5.18158C5.08334 5.17993 5.08527 5.1783 5.08715 5.17668L5.09803 5.16725L5.10801 5.15834L5.11708 5.14999L5.12527 5.14225L5.13258 5.13515L5.139 5.12874L5.14699 5.12049L5.15463 5.11226L5.15979 5.10638ZM3.88889 0C5.68964 0 6 1.09545 6 2.44681C6 3.79816 5.05477 4.89362 3.88889 4.89362C2.72298 4.89362 1.77778 3.79816 1.77778 2.44681C1.77778 1.09545 2.08813 0 3.88889 0Z"/>
        </WrapIcon>
    );
});