import React from "react";
import {WrapContent} from "../../../styledUI";
import Tag from "../../../../../components-ui/DataDisplay/Tag/Tag";

export default function TagPage() {

    return (
        <>
            <h1>Теги</h1>
            <WrapContent>
                <div>
                    <Tag>Пропущенный звонок</Tag>
                    <pre>
                        {
                            '<Tag>Пропущенный звонок</Tag>'
                        }
                    </pre>
                </div>
            </WrapContent>
        </>
    )

}
