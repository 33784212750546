import {Dispatch, SetStateAction} from "react";
import _ from "lodash";
import {timeoutSlideModal} from "../helper/timeout";
import {OptionsModal, StateModal} from "../types/modals";
import {ParamsMinimizable, SlideModals} from "../types/slideModals";


export default function useMethodsDialogs(setSlideModals: Dispatch<SetStateAction<SlideModals>>, setModals: Dispatch<SetStateAction<StateModal[]>>) {
    const showSlideModal = (component: any, idModal?: string, isMinimized: boolean = false) => {
        setSlideModals(prevState => {
            const maxSort = _.maxBy(Object.values(prevState), 'sort');
            const maxId = _.max(Object.keys(prevState).map(key => +key))
            const lastId = !!idModal ? idModal : !!maxId ? (maxId + 1).toString() : '1';
            const commonProps = !!prevState[lastId] ? prevState[lastId] : {}
            return {
                ...prevState,
                [lastId]: {
                    ...commonProps,
                    component,
                    isMinimized: isMinimized,
                    sort: !!maxSort ? +maxSort.sort + 1 : 1,
                }
            }
        })
    }

    const openSlideModal = (idModal: string) => {
        setSlideModals(prevState => {
            const maxSort = _.maxBy(Object.values(prevState), 'sort');
            prevState[idModal].isMinimized = false;
            prevState[idModal].sort = !!maxSort ? +maxSort.sort + 1 : 1;
            return {
                ...prevState
            }
        })
    }

    const showModal = (component: any, options?: OptionsModal) => {
        setModals(prevState => {
            const newModal = {component} as any;
            if (!!options) newModal.options = options;
            prevState.push(newModal)
            return [...prevState]
        });
    }

    const _removeSlideModal = (idModal: string) => {
        setSlideModals(prevState => {
            prevState[idModal].isMinimized = true;
            return {
                ...prevState
            }
        })
        setTimeout(() => {
            setSlideModals(prevState => {
                delete prevState[idModal]
                return {
                    ...prevState
                }
            })
        }, timeoutSlideModal)
    }

    const _minimizeSlideModal = (idModal: string) => {
        setSlideModals(prevState => {
            prevState[idModal].isMinimized = true
            return {
                ...prevState
            }
        })
        setTimeout(() => {
            setSlideModals(prevState => {
                prevState[idModal].sort = 0
                return {
                    ...prevState
                }
            })
        }, timeoutSlideModal + 50)
    }

    const _maximizeSlideModal = (idModal: string) => {
        setSlideModals(prevState => {
            const maxSort = _.maxBy(Object.values(prevState), 'sort');
            prevState[idModal].isMinimized = false;
            prevState[idModal].sort = !!maxSort ? +maxSort.sort + 1 : 1;
            return {
                ...prevState
            }
        })
    }

    const _setMinimizeParams = (idModal: string, params: ParamsMinimizable) => {
        setSlideModals(prevState => {
            prevState[idModal].labelParams = {
                ...prevState[idModal].labelParams,
                ..._.pick(params, 'icon', 'title', 'text', 'badgeText')
            }

            if (!!params.canBeDeleted) prevState[idModal].canBeDeleted = params.canBeDeleted;
            if (!!params.keySlideModal) prevState[idModal].keySlideModal = params.keySlideModal;

            return {
                ...prevState
            }
        })
    }

    const _removeMinimizeParams = (idModal: string) => {
        setSlideModals(prevState => {
            if (!!prevState[idModal].labelParams) {
                delete prevState[idModal].labelParams
            }
            return {
                ...prevState
            }
        })
    }

    return {
        showSlideModal, _removeSlideModal, _minimizeSlideModal, _maximizeSlideModal, _setMinimizeParams,
        _removeMinimizeParams, showModal, openSlideModal
    }
}
