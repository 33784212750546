import React from "react";
import DateTime from "../../../../../components-ui/Inputs/DateTime/DateTime";
import TextField from "../../../../../components-ui/Inputs/TextField/TextField";
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {PropsReasonInput} from "../types/components";
import {useChoiceOrCompletion} from "../ChoiceOrCompletion";


export default function ({inputName, input}: PropsReasonInput) {
    const {activeTab, onChangeReason} = useChoiceOrCompletion();
    if (activeTab !== "nextVisit") {
        if (!!input) {
            let content = null;
            switch (input.type) {
                case "date":
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1)
                    content = <DateTime widthInput="340px"
                                        widthModal="340px"
                                        placeholderText="Выберите дату"
                                        selected={!!input.value ? new Date(input.value) : input.value}
                                        margin="0 0 24px"
                                        onChange={value => onChangeReason(value, activeTab, inputName)}
                                        minDate={tomorrow}

                    />
                    break;
                case "text":
                default:
                    content = <TextField value={!!input.value ? input.value : ''}
                                         isTextArea={true}
                                         minHeight="72px"
                                         widthInput="100%"
                                         margin="0 0 24px"
                                         onChange={value => onChangeReason(value, activeTab, inputName)}
                    />
                    break;
            }
            return (
                <Label labelText={input.name} align="leftTop" widthLabel="180px" widthLabelWrap="100%"
                       marginLabel="0 20px 0 0" isRequired={true}>
                    {content}
                </Label>
            )
        } else {
            return null
        }
    } else {
        return null
    }

}
