import styled from "styled-components";

export const SWrapTextPhone = styled.div.attrs({className: 'SWrapTextPhone'})`
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    & > div {
        margin-bottom: 13px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`

export const SItemTextPhone = styled.div.attrs({className: 'SItemTextPhone'})`
    display: flex;
    flex-direction: column;
`

export const STitleTextPhone = styled.div.attrs({className: 'STitleTextPhone'})`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: ${props => props.theme.colors.textGray};
    margin-bottom: 3px;
`

export const STextPhone = styled.a.attrs({className: 'STextPhone'})`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${props => props.theme.colors.info};
    text-decoration: none;
    cursor: pointer;
`
