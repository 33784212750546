import styled from "styled-components";

export const LoaderWrap = styled.div`
    margin-left: 18px;
    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
