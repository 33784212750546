import React from 'react';
import {WrapTooltip, HeaderTooltip, ListTooltip, ItemTooltip} from "../../style/tooltip";
import Typography from "../../../DataDisplay/Typography/Typography";

interface Props {
    active?: any
    payload?: any
    label?: any
    formatterTitleTooltip?: (value: string) => any
    formatterNameTooltip?: (name: any) => any
    formatterValue?: (value: number) => string
    paddingTooltip?: string
    [key: string]: any
}

const CustomTooltip = ({active, payload, formatterValue, formatterTitleTooltip, formatterNameTooltip, paddingTooltip}: Props) => {
    if (active) {
        if (!!payload && !!payload.length && !!payload[0].payload) {
            const item = payload[0].payload
            return (
                <WrapTooltip>
                    <HeaderTooltip isDataTooltip={!item.dataTooltip}>
                        <Typography children={!!formatterTitleTooltip ? formatterTitleTooltip(item.code) : item.code}
                                    color="white"
                                    variant="min"
                                    margin="0 16px 0 0"
                                    whiteSpace="nowrap"
                        />
                        <Typography children={!!formatterValue ? formatterValue(item.value) : item.value}
                                    variant="min"
                                    fontWeight="600"
                                    color="white"
                                    whiteSpace="nowrap"/>
                    </HeaderTooltip>
                    {!!item.dataTooltip && !!item.dataTooltip.length && (
                        <ListTooltip>
                            {item.dataTooltip.map((tooltip: {value: number, code: string}) => {
                                return (
                                    <ItemTooltip padding={paddingTooltip}>
                                        <Typography children={!!formatterNameTooltip ? formatterNameTooltip(tooltip.code) : tooltip.code}
                                                    variant="min"
                                                    color="white"
                                                    margin="0 16px 0 0"
                                                    whiteSpace="nowrap"/>
                                        <Typography children={!!formatterValue ? formatterValue(tooltip.value) : tooltip.value}
                                                    variant="min"
                                                    color="white"
                                                    whiteSpace="nowrap"/>
                                    </ItemTooltip>
                                )
                            })}
                        </ListTooltip>
                    )}

                </WrapTooltip>
            );
        } else {
            return null
        }
    } else {
        return null
    }
};

export default CustomTooltip;
