import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth12Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 7.64 24"} width={width || "14"} height={height || "44"} ref={ref}>
            <path d="M1.42,3c-.15-2,.33-3,.65-3,.58,0,2.52,2.51,3.86,14.25a3.39,3.39,0,0,0-3-1.09,3.58,3.58,0,0,0-1.8.9A48,48,0,0,0,1.4,3ZM7.57,22.61a1.47,1.47,0,0,1-.55,1c-.72.45-2.82.37-3.85.33H2.82C1,23.91.21,23.68.09,21.74A13.38,13.38,0,0,1,.5,16.55,3.52,3.52,0,0,1,3.06,14a2.43,2.43,0,0,1,.38,0,3,3,0,0,1,2.38,1.43c.81,1.1,2.13,5.15,1.75,7.22Z"/>
        </WrapIcon>
    );
});
