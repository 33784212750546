import React, {forwardRef, ReactChild, ReactNode} from 'react';
import {IIcon} from "../types/common";
import { SSvg } from '../style/common';

interface PropsWrapIcon extends IIcon {
    children: ReactChild | ReactNode
}

export const WrapIcon = forwardRef((
    {
        children,
        ...props
    }: PropsWrapIcon, ref) => {
    return (
        <SSvg {...props} children={children} ref={ref} />
    );
});
