import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const TenderIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M.7,24a.7.7,0,0,1-.7-.7v-.75a1.41,1.41,0,0,1,1.41-1.41V20.3a2,2,0,0,1,2-2h7.31a2,2,0,0,1,2,2v.84a1.41,1.41,0,0,1,1.41,1.41v.75a.7.7,0,0,1-.71.7Zm8.1-8.52L4.57,9.87A1.4,1.4,0,1,1,6.81,8.18l4.24,5.61a1.41,1.41,0,0,1-.28,2A1.4,1.4,0,0,1,8.8,15.48ZM18.91,7.87,14.68,2.25a1.4,1.4,0,0,1,.27-2,1.41,1.41,0,0,1,2,.28l4.23,5.62a1.4,1.4,0,0,1-2.24,1.69ZM15.82,12a7.62,7.62,0,0,1-3.3,1.46L7.59,6.87a7.85,7.85,0,0,1,5.62-4.23l4.92,6.53A7.57,7.57,0,0,1,15.82,12Zm1.9.19.92,1.21-2.25,1.7-.91-1.22a9.89,9.89,0,0,0,1.19-.75A10.49,10.49,0,0,0,17.72,12.14Zm1.77,2.34,4.23,5.61a1.41,1.41,0,0,1-2.25,1.69l-4.23-5.61Z"/>
        </WrapIcon>
    );
});
