import React, {useEffect, useMemo, useState} from 'react';
import {GetXrayPicturesListResult} from '../../../types';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import {IXRayPicturesRow} from '../types';
import ButtonForOptions from '../../../../../components-ui/Inputs/ButtonForOptions/ButtonForOptions';
import DateHelper from '../../../../../common/helpers/date-helper';
import {useSelector} from 'react-redux';
import Helper from '../../../../../common/helpers/main';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import useOptions from './useOptions';
import _ from 'lodash';
import PatientCard from '../../../../../content/PatientCard/PatientCard';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';

interface Props {
    xRayPicturesResponse: GetXrayPicturesListResult;
    selectedBranch: number;
}

const useTableFormatter = ({xRayPicturesResponse, selectedBranch}: Props) => {

    const currentUser = useSelector(state => state.user);

    const users = useSelector(state => state.common.users);

    const {showSlideModal} = useDialogsManager();

    const xrayPictureTypes = useSelector(state => state.common.xrayPictureTypes);

    const [xrayPicuresRows, setXrayPicturesRows] = useState<IXRayPicturesRow[]>();

    const buttonOptions = useOptions({selectedBranch});

    const xrayPicturesColumns: IColumn<IXRayPicturesRow>[] = useMemo(() => {
        const columns: IColumn<IXRayPicturesRow>[] = [
            {
                field: 'creator',
                headerName: 'Сотрудник',
            },
            {
                field: 'doctor',
                headerName: 'Врач',
            },
            {
                field: 'patient',
                headerName: 'Пациент',
                width: '20vw',
                valueFormatter: ({row}) =>
                    <Typography color='info'
                                variant='small'
                                cursor='pointer'
                                onClick={() => showSlideModal(<PatientCard
                                    patientId={row.patientId} />)}>{row.patient}</Typography>,
            },
            {
                field: 'type',
                headerName: 'Вид снимка',
                valueFormatter: ({row}) =>
                    <>
                        {xrayPictureTypes[row.type]}
                    </>

            },
            {
                field: 'qualitative',
                headerName: 'Качественный',
                valueFormatter: ({row}) => {
                    return row.qualitative ?
                        <Typography variant='small'>Да</Typography>
                        :
                        <Typography variant='small' color='danger'>Нет</Typography>;
                }
            },
            {
                field: 'comment',
                headerName: 'Комментарий',
                width: '18vw'
            },
            {
                field: 'buttons',
                width: '45px',
                valueFormatter: ({row}) =>
                    <ButtonForOptions
                        options={buttonOptions(row)}
                        position='left-bottom'
                    />,


            },
        ];
        if (currentUser.canViewAllXrayPictures) {
            columns.unshift({
                field: 'date',
                headerName: 'Дата',
            });
        }
        return columns;
    }, [xRayPicturesResponse]);

    useEffect(() => {
        setXrayPicturesRows(
            _.sortBy(
                xRayPicturesResponse.pictures.map((picture) => ({
                    id: picture.id,
                    date: DateHelper.formatDate(picture.createdAt, 'DD MMM YYYY'),
                    creator: users[picture.creatorId].fio,
                    creatorId: picture.creatorId,
                    doctor: users[picture.doctorId].fio,
                    doctorId: picture.doctorId,
                    patient: Helper.getFullName(xRayPicturesResponse.patients[picture.patientId]),
                    patientId: picture.patientId,
                    type: picture.type,
                    qualitative: picture.qualitative,
                    comment: picture.comment,
                })),
                'date'));

    }, [xRayPicturesResponse]);

    return {
        xrayPicturesColumns,
        xrayPicuresRows,
    };
};

export default useTableFormatter;