import {Call, PlannedCall, TaskCallCenter} from "../../../../../types/common";

interface Props {
    detail: PlannedCall | null,
    progressCall: TaskCallCenter[],
    calls: Call[]
}

const prepareTimeToCall = ({progressCall, detail, calls}: Props) => {
    let res = null;
    if (!!detail) {
        if (!!progressCall.length) {
            if (!calls.some(call => call.contactPhone === detail.phone)) {
                const progress = progressCall[0];
                res = progress.payload.startTime
            }
        }
    }
    return res;
}

export default prepareTimeToCall
