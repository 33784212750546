import Helper from "../../../common/helpers/main";

const getNewDate = (newDate: Date | [Date, Date] | null, minMaxDateTime: any) => {
    let stateDate = newDate;
    const {minTime, maxTime, maxDate, minDate} = minMaxDateTime;
    const formatMinDate = Helper.Date.formatDate(minDate as any, 'YYYY-MM-DD');
    const formatMaxDate = Helper.Date.formatDate(maxDate as any, 'YYYY-MM-DD');
    const formatDate = Helper.Date.formatDate(stateDate as any, 'YYYY-MM-DD');
    const formatTime = Helper.Date.formatDate(stateDate as any, 'HH:mm');
    const formatMinTime = Helper.Date.formatDate(minTime as any, 'HH:mm');
    const formatMaxTime = Helper.Date.formatDate(maxTime as any, 'HH:mm');
    if (formatMinDate === formatDate) {
        if (formatTime < formatMinTime) stateDate = minTime
    }
    if (formatMaxDate === formatDate) {
        if (formatTime > formatMaxTime) stateDate = maxDate
    }
    return stateDate
}

export default getNewDate
