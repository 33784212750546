import React from 'react';
import {IColors} from "../../../common/Theme/Theme";
import styled from "styled-components";
import Helper from "../../../common/helpers/main";

interface Props {
    display?: 'block' | 'inline' | 'inline-block'
    target?: '_blank' | '_parent' | '_self' | '_top'
    href: string
    children?: any
    margin?: string
    fontSize?: string
    color?: keyof IColors
    outline?: boolean
    isCircle?: boolean
    padding?: string
    notUnderline?: boolean
}

const SLink = styled.a<Props>`
    ${props => !!props.display ? `display: ${props.display};` : `
        display: flex;
        justify-content: center;
        align-items: center;
    `}
    
    font-size: ${props => props.fontSize || '1rem'};
    line-height: 1.43;
    color: ${props => props.theme.colors[props.color as keyof IColors]};
    text-decoration: none;
    outline: none;
    ${props => !!props.padding && `padding: ${props.padding};`};
    ${props => !!props.margin && `margin: ${props.margin};`};
    ${props => !!props.outline && `border: 2px solid ${props.theme.colors[props.color as keyof IColors]};`};
    ${props => !!props.isCircle && `border-radius: 50%;`};
    ${props => (!props.outline || !props.isCircle) && `
        text-decoration: underline;
        text-decoration-color: rgba(${Helper.Color.hexToRgb(props.theme.colors[props.color as keyof IColors])}, 0.3);
        text-decoration-style: dashed;
        text-decoration-thickness: from-font;
        text-underline-offset: 3px;
        &:hover {
            text-decoration-color: rgba(${Helper.Color.hexToRgb(props.theme.colors[props.color as keyof IColors])}, 0.6);
        }
    `};
    ${props => !!props.notUnderline && `text-decoration: none;`}
`

export const Link = ({target = '_blank', color = 'info',  ...props}: Props) => {
    return <SLink target={target} color={color} {...props} />;
};
