import {Dispatch, SetStateAction, useEffect} from 'react';
import {echo} from '../../../common/server/echo';
import {GetTimesheetListResult, TimesheetRecord, TimesheetRecordDeletedData} from '../types';
import {OptionSelect} from '../../../components-ui/Inputs/Select/typesSelect';

interface Props {
    setData: Dispatch<SetStateAction<GetTimesheetListResult>>
    selectedBranch: OptionSelect
}

const useServerEvents = ({setData, selectedBranch}: Props) => {
    useEffect(() => {
        echo.private(`today-timesheet.${selectedBranch.value}`)
            .listen('Salary\\Timesheet\\TimesheetRecordCreated', (record: TimesheetRecord) => {
                setData(prevState => ({...prevState,
                    records: [...prevState.records, record]}))
            })
            .listen('Salary\\Timesheet\\TimesheetRecordUpdated', (record: TimesheetRecord) => {
                setData(prevState =>  ({...prevState,
                    records: prevState.records.map(prevRecord =>
                        prevRecord.id === record.id ? record : prevRecord
                    )}))
            })
            .listen('Salary\\Timesheet\\TimesheetRecordDeleted', ({id}: TimesheetRecordDeletedData) => {
                setData(prevState => ({...prevState,
                    records: prevState.records.filter(record => record.id !== id)}))
            });
        return () => {
            echo.leave(`today-timesheet.${selectedBranch.value}`);
        };
    }, [selectedBranch]);
};

export default useServerEvents;