import React from "react";
import {PStatuses} from "./typesDentalCardInfo";
import {
    DentalCardInfoWrap,
    StatusList,
    Status,
    HelpListWrap,
    HelpList,
    HelpItem,
    StatusMissing
} from "./styledDentalCardInfo";
import {
    CURED_STATUS,
    HEALTHY_STATUS,
    INITIAL_SURVEY,
    SICK_STATUS, TREATMENT_PLAN,
    UNCONFIRMED_STATUS
} from "../../../../common/constants/DentalRecords";
import SVG from "../../../../common/helpers/SVG";
import {useDentalCard} from "../../DentalRecords";
import {SDentalCard} from "../../types";

const statuses = [
    {
        code: HEALTHY_STATUS,
        name: 'Здоровый'
    },
    {
        code: SICK_STATUS,
        name: 'Проблемный'
    },
    {
        code: CURED_STATUS,
        name: 'Леченый'
    },
    {
        code: UNCONFIRMED_STATUS,
        name: 'Неподтверждённый'
    },
] as PStatuses[]

export default function DentalCardInfo() {
    const {parentComponentName: variant} = useDentalCard() as SDentalCard;
    return (
        <DentalCardInfoWrap variant={variant}>
            <StatusList>
                {statuses.map(status => {
                    if (variant === TREATMENT_PLAN && status.code === UNCONFIRMED_STATUS) return null;
                    return (
                        <Status key={status.code} status={status.code} variant={variant} >
                            <span className="status-color" />
                            <span className="status-text">{status.name}</span>
                        </Status>
                    )
                })}
                <StatusMissing variant={variant}>
                    <SVG name='close' className="missing-svg" />
                    <span className="status-text">Отсутствует</span>
                </StatusMissing>
            </StatusList>
            <HelpListWrap variant={variant}>
                <HelpList>
                    <HelpItem variant={variant}>
                        <span className="help-ctrl">
                            Ctrl
                        </span>
                        <span className="help-item-text">
                            Выбрать несколько
                        </span>
                    </HelpItem>
                    {variant === INITIAL_SURVEY && (
                        <HelpItem variant={variant}>
                            <SVG name="mouse-right" className="help-mouse" />
                            <span className="help-item-text">
                                Состояния зуба
                            </span>
                        </HelpItem>
                    )}
                </HelpList>
            </HelpListWrap>
        </DentalCardInfoWrap>
    )
}
