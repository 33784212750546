import React, {Dispatch, SetStateAction} from 'react';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import Box from '../../../../components-ui/Layout/Box';
import DateRange from '../../../../components-ui/Inputs/DateRange';
import Select from '../../../../components-ui/Inputs/Select/Select';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import TextField from '../../../../components-ui/Inputs/TextField/TextField';
import {IPaymentsFilters} from '../../types';

interface Props {
    filters: IPaymentsFilters
    setFilters: Dispatch<SetStateAction<IPaymentsFilters>>
    optionsBranches: OptionSelect[]
    selectedBranch: OptionSelect
    setSelectedBranches: Dispatch<SetStateAction<OptionSelect>>
    startDate: Date,
    setStartDate: Dispatch<SetStateAction<Date>>
    endDate: Date,
    setEndDate: Dispatch<SetStateAction<Date>>
}

const PaymentFilter = ({
                           filters,
                           setFilters,
                           optionsBranches,
                           selectedBranch,
                           setSelectedBranches,
                           startDate,
                           setStartDate,
                           endDate,
                           setEndDate,
                       }: Props) => {

    const paymentMethods = useSelector(state => state.common.paymentMethods);

    const doctors = useSelector(state => state.common.doctors);

    const optionsPaymentMethods = _.map(paymentMethods, (val, key) => {
        return {value: key, label: val};
    });

    optionsPaymentMethods.unshift({
        value:  '',
        label: 'Все способы оплаты',
    });

    const optionsFilteredDoctors = doctors
        .filter(doc => !doc.deletedAt && doc.branches.includes(selectedBranch.value))
        .map(doc => {
            return {value: doc.id, label: doc.fio, color: doc.color};
        });

    optionsFilteredDoctors.unshift({
        value: 0,
        label: 'Все врачи',
        color: '#dedede',
    });

    return (
        <Box margin='32px 0 0 0' display='flex' gap='10px' flexWrap='wrap' width='fit-content' alignItems='center'>
            {optionsBranches.length > 1 ?
                <Select options={optionsBranches} value={selectedBranch} colorSelect='white'
                        onChange={setSelectedBranches} sizeSelect='sm' /> : null}
            <DateRange startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}
                       maxDate={new Date()} />

            <TextField
                value={filters.patient}
                size='sm'
                widthInput='300px'
                placeholder='Поиск пациента по ФИО'
                isSearch={true}
                onChange={(value) => setFilters(prevState => {
                    return {...prevState, patient: value};
                })}
                isReset={!!filters.patient}
                onClickReset={() => setFilters(prevState => {
                    return {...prevState, patient: ''};
                })}
            />


            <Select options={optionsPaymentMethods}
                    placeholder='Поиск по способам оплаты'
                    value={filters.paymentMethod}
                    onChange={(value) => setFilters(prevState => {
                        return {...prevState, paymentMethod: value};
                    })}
                    widthSelect='300px'
                    sizeSelect='sm'
                    colorSelect='white'
            />
            <Select options={optionsFilteredDoctors}
                    onChange={(value) => setFilters(prevState => {
                        return {...prevState, doctor: value};
                    })}
                    widthSelect='240px'
                    value={!!filters.doctor ? filters.doctor : 'Все врачи'}
                    sizeSelect='sm'
                    colorSelect='white'
                    variant='colored'
                    placeholder='Все врачи'
            />

            <TextField
                onChange={(value) => setFilters(prevState => {
                    return {...prevState, service: value};
                })}
                placeholder='Поиск по услуге'
                isSearch={true}
                value={!!filters.service ? filters.service : ''}
                size='sm'
                widthInput='252px'
                isPartHidden={true}
            />
        </Box>
    );
};

export default PaymentFilter;