import React from "react";
import { WrapContent } from "../../../styledUI";
import {Badge} from "../../../../../components-ui/DataDisplay/Badge";
import Button from "../../../../../components-ui/Inputs/Button/Button";

export default function BadgePage() {
    return (
        <>
            <h1>Badge</h1>
            <WrapContent>
                <div>
                    <Badge badgeContent="17">
                        <Button children="кнопка для badge" variant="success" />
                    </Badge>
                    <pre>
                        {
                            `
<Badge badgeContent="17">
    <Button children="кнопка для badge" variant="success" />
</Badge>
                            `
                        }
                    </pre>
                </div>
            </WrapContent>
        </>
    )
}
