import * as React from "react";
import {Component} from 'react';
import Cell from "../Cell/Cell";
import Helper from "../../../common/helpers/main";
import {prepareColumn} from '../../../common/helpers/graph-helper'
import {ColumnProps} from "../types/types";
import {connect} from "react-redux";

class Columns extends Component<ColumnProps> {


    render() {
        const {handleTooltip, timeStartInterval, timeEndInterval, chairId, handleResizeStop, data,
            handlePositionMenu, selectedCell, handleClickCell, handleContextMenu, title, variant, doctors} = this.props;
        const doctorsObj = Helper.Array.toObject(doctors, 'id');

        const dataPrepareColumn = {
            timeStartInterval,
            timeEndInterval,
            data: data.filter((cell => cell.doctorId !== 0))
        };
        return (
            <div className="column">
                {this.renderTitle(title, variant)}
                {prepareColumn(dataPrepareColumn).map((cell) => {

                    return <Cell key={cell.timeStart}
                                 data={cell}
                                 doctors={doctorsObj}
                                 handleTooltip={handleTooltip}
                                 chairId={chairId}
                                 handleResizeStop={handleResizeStop}
                                 handlePositionMenu={handlePositionMenu}
                                 handleClickCell={handleClickCell}
                                 selectedCell={selectedCell}
                                 handleContextMenu={handleContextMenu}
                    />
                })}
            </div>
        )
    }

    renderTitle = (title: string, variant: string) => <div className={`column__title column__title--${variant}`} children={title} />;

}

const mapStateToProps = (state: any) => ({
   doctors: state.common.doctors
});

export default connect(mapStateToProps)(Columns);
