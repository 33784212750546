import styled from "styled-components";

export const Background = styled.div.attrs({className: 'background'})`
    min-height: 100vh;
    padding: 40px 0;
    width: 100%;
    background-color: ${props => props.theme.colors.textDarkGray};
    background-image: url("/images/teeth_bg.png");
    background-size: 240px auto;
    background-repeat: repeat;
    word-break: break-all;
    transition: all .2s;
    text-align: center;
`;

export const LoginForm = styled.form.attrs({className: 'login-form'})`
    margin-bottom: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > button {
        width: 100%;
        max-width: 180px;
    }
`;

export const InputGroup = styled.div.attrs({className: 'group-input'})`
    margin: 0 auto 39px;
    & > .Label {
        margin-bottom: 15px;
    }
`;
