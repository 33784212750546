
export type INITIAL_SURVEY_TYPE = 'INITIAL_SURVEY'
export const INITIAL_SURVEY = 'INITIAL_SURVEY'

export type TREATMENT_PLAN_TYPE = 'TREATMENT_PLAN'
export const TREATMENT_PLAN = 'TREATMENT_PLAN'

export type HEALTHY_STATUS_TYPE = 'HEALTHY'
export const HEALTHY_STATUS = 'HEALTHY'
export type SICK_STATUS_TYPE = 'SICK'
export const SICK_STATUS = 'SICK'
export type CURED_STATUS_TYPE = 'CURED'
export const CURED_STATUS = 'CURED'
export type UNCONFIRMED_STATUS_TYPE = 'UNCONFIRMED'
export const UNCONFIRMED_STATUS = 'UNCONFIRMED'
