import React, {useEffect, useMemo, useRef, useState} from 'react';
import useOutsideClick from '../../../common/hooks/useOutsideClick';
import {SButtonForOptions} from './styledButtonForOptions';
import {IButtonForOptions, IOptionsButton} from './typesButtonForOptions';
import Box from '../../Layout/Box';
import {List, ListItem} from '../../DataDisplay/List';
import Icon from '../../DataDisplay/Icon';
import {createPortal} from 'react-dom';

const Options = ({isOpen, options, position, positionOffsets, closeOptions}: IOptionsButton) => {

    const element = useMemo(() => {
        const element = document.createElement('div')
        element.id = 'buttonForOptionsList'
        return element
    }, [])

    const refOptions = useRef(null)

    useOutsideClick(refOptions, () => closeOptions())

    const positionStyle = () => {
        switch (position) {
            case 'left-top': return {transform: 'translate(calc(-100% - 5px), calc(-100% + 20px));'}
            case 'left-bottom': return {transform: 'translate(calc(-100% - 5px), 0%);'}
            case 'right-top': return {transform: 'translate(25px, calc(-100% + 20px));'}
            case 'right-bottom': return {transform: 'translate(25px, 0%);'}
        }
    }


    const OptionsList = <Box position="absolute" zIndex={45} width="max-content" boxShadow="defaultModalLight" borderRadius="4px"
                             positionOffsets={positionOffsets} transform={positionStyle().transform}>
        <List ref={refOptions}>
            {options.map(option => {
                return (
                    <ListItem key={option.value}
                              onClick={() => {
                                  closeOptions(); option.onClick()
                              }}>
                        {option.label}
                    </ListItem>
                )
            })}
        </List>
    </Box>

    useEffect(() => {
        if (isOpen) document.body.appendChild(element)
        return () => {
            document.body.removeChild(element)
        }
    }, [element, isOpen, options, position])

    return  createPortal(OptionsList, element)
}

const ButtonForOptions = ({options, position, outsideOpenOptions = true, setOutsideOpenOptions }: IButtonForOptions) => {

    const [openOptions, setOpenOptions] = useState(false)

    const closeOptions = () => {
        setOpenOptions(false);
        if (setOutsideOpenOptions) {
            setOutsideOpenOptions(false);
        }}

    const ref = useRef(null) as any;

    const absolutePosition = ref.current && { left: `${Math.ceil(ref.current.getBoundingClientRect().x)}px`,
            top: `${Math.ceil(ref.current.getBoundingClientRect().y)}px`}

    useEffect(() => {
        if (!outsideOpenOptions) setOpenOptions(false)
        // @ts-ignore
        document.getElementById('root').addEventListener('scroll', () => setOpenOptions(false))

        return () => {
            // @ts-ignore
            document.getElementById('root').removeEventListener('scroll', () => setOpenOptions(false))
        }
    }, [outsideOpenOptions])

    return (
        <Box>
            <SButtonForOptions ref={ref} opened={openOptions && outsideOpenOptions} onClick={() => {
                setOpenOptions(!openOptions);
                if (setOutsideOpenOptions) {
                    setOutsideOpenOptions(!openOptions);
                }}
            }>
                <Icon name="dots" height="10px" color={openOptions && outsideOpenOptions ? 'info' : 'textDarkGray'}/>
            </SButtonForOptions>
            {(openOptions && outsideOpenOptions) && <Options options={options}
                                     position={position}
                                     positionOffsets={absolutePosition}
                                     closeOptions={closeOptions}
                                     isOpen={openOptions && outsideOpenOptions}
            />}

        </Box>

    );
};

export default ButtonForOptions;
