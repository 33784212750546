import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {SPopupBtns} from "./style";
import Button from "../../../components-ui/Inputs/Button/Button";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import {commonLabelOption, commonSelectOption} from "../PaymentCreationForm/data";
import Select from "../../../components-ui/Inputs/Select/Select";
import {Label} from "../../../components-ui/DataDisplay/Label";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import Box from "../../../components-ui/Layout/Box";

interface Props {
    onClose: () => void
    onSumbit: () => void
    selectedKkm: OptionSelect | undefined
    setSelectedKkm: Dispatch<SetStateAction<OptionSelect | undefined>>
    kkmOptions: OptionSelect[]
    isCheck: boolean
}

const PaymentDelete = ({onClose, onSumbit, selectedKkm, setSelectedKkm, kkmOptions, isCheck}: Props) => {

    const [loader, setLoader] = useState(false)

    const submitHandler = () => {
        setLoader(true)
        onSumbit()
    }
    const handleKkmSelect = useCallback((selectedKkm: OptionSelect) => {
        setSelectedKkm(selectedKkm)
    }, [setSelectedKkm])

    useEffect(() => {
        return () => {setLoader(true)}
    }, [])

    return (
        <Box padding="30px 27px 69px" minWidth="549px">
            <Typography children="Удаление оплаты" textAlign="center" variant="bigger-title" margin="25px 0 59px"/>
            {isCheck && (
                <Label labelText="ККМ" {...commonLabelOption} >
                    <Select
                        {...commonSelectOption}
                        options={kkmOptions}
                        value={selectedKkm}
                        onChange={handleKkmSelect}
                    />
                </Label>
            )}
            <SPopupBtns>
                <Button variant="danger"
                        size="long"
                        children="Удалить оплату"
                        loader={loader}
                        onClick={submitHandler}/>
                <Button variant="default"
                        size="long"
                        children="Отменить"
                        onClick={onClose}/>
            </SPopupBtns>
        </Box>

    );
};

export default PaymentDelete;
