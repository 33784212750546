import React, {useState, useContext, createContext} from "react";
import commonLabelOptions from "../helper/commonLabelOptions";
import {Label} from "../../../components-ui/DataDisplay/Label";
import Enum from "../components/Enum";
import useChangeIsPatient from "../hooks/useChangeIsPatient";
import ActionButton from "../components/ActionButton";
import useHover from "../../../common/hooks/useHover";
import Phone from "../components/Phone";
import Boolean from "../components/Boolean";
import Date from "../components/Date";
import Composite from "./Composite/Composite";
import String from "../components/String";
import useIsViewMode from "../hooks/useIsViewMode";
import {ContextPatientField, PropsPatientField} from "../types/patientField";

const PatientFieldContext = createContext({} as ContextPatientField);
export const usePatientField = () => useContext(PatientFieldContext);

export default function PatientField(
    {
        field,
        value,
        onChangeField,
        loadingPatient,
        currentPatient,
        parentName = 'NewVisitForm',
        isMargin = true,
        setForm,
        personalPhone,
        error,
        onChangeValidationItem,
        autoFocus = false,
        composite,
        currentTab
    }: PropsPatientField) {

    const [isEdit, setIsEdit] = useState(true)
    const [ref, hover] = useHover();
    const isViewMode = useIsViewMode({currentPatient, field, value, personalPhone, composite, parentName})
    useChangeIsPatient({currentPatient, setIsEdit, isViewMode, personalPhone, parentName})

    let children = null

    switch (field.type) {
        case "enum":
            children = <Enum/>
            break;
        case "phone":
            children = <Phone key={field.name}/>
            break;
        case "boolean":
            children = <Boolean/>
            break;
        case "date":
            children = <Date/>
            break;
        case "composite":
            children = <Composite/>
            break;
        case "string":
            children = <String/>
            break;
        case "float":
        case "integer":
        default:
            break;
    }


    return (
        <PatientFieldContext.Provider value={{
            field,
            value,
            onChangeField,
            loadingPatient,
            currentPatient,
            parentName,
            isMargin,
            isEdit,
            setIsEdit,
            labelRef: ref,
            setForm,
            error,
            onChangeValidationItem,
            autoFocus,
            composite,
            currentTab,
            personalPhone
        }}>
            <Label labelText={field.title}
                   isRequired={field.required}
                   ref={ref}
                   align={(isEdit && !currentPatient) || field.name === "personalPhone" ? "left" : "leftTop"}
                   onClick={() => {
                       if (!!currentPatient && !isEdit) setIsEdit(true)
                   }}
                   {...commonLabelOptions(isMargin, parentName)}>
                {children}
                {field.name !== 'personalPhone' && !!currentPatient && isViewMode && (hover || isEdit) &&
                <ActionButton/>}
            </Label>
        </PatientFieldContext.Provider>
    )
}
