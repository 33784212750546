import {useCallback} from "react";
import {singIn} from "../../../redux/actions/commonActions";
import ServerCommand from "../../../common/server/server-command";
import {useDispatch} from "react-redux";
import {HandlersLogin, StateLogin} from "../types";

interface Props {
    state: StateLogin
    handlers: HandlersLogin
}

const useSubmit = ({handlers, state}: Props) => {
    const {login, password} = state
    const {setErrorCode, setIncorrectData} = handlers
    const dispatch = useDispatch();
    
    const saveError = useCallback((error: any, submittedData: any) => {
        setErrorCode(error.code);
        if (error.code === 'invalid_grant') setIncorrectData(submittedData);
    }, [setErrorCode, setIncorrectData]);
    
    return useCallback((event: any) => {
        event.preventDefault();
        ServerCommand.auth(login, password).then(
            () => {
                dispatch(singIn());
                window.location.reload();
            },
            (error: any) => saveError(error, {login, password})
        );
    }, [dispatch, login, password, saveError])
}

export default useSubmit