import React, {useState} from "react";
import {useEditableField} from "../EditableField";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import ServerCommand from "../../../common/server/server-command";
import SaveButtons from "./SaveButtons";

interface SFieldAddress {
    personalCity: string
    personalStreet: string
    personalHome: string
    personalHousing: string
    personalApartment: string,
    validPersonalCity: boolean
}

export default function FieldAddress() {
    const {field, patientId, setEditField} = useEditableField();
    const {values, onChange} = field;
    const [state, setState] = useState<SFieldAddress>({
        personalCity: values.personalCity.value,
        personalStreet: values.personalStreet.value,
        personalHome: values.personalHome.value,
        personalHousing: values.personalHousing.value,
        personalApartment: values.personalApartment.value,
        validPersonalCity: false
    })

    const onChangeFieldCity = (value: string) => {
        setState(prevState => ({
            ...prevState,
            personalCity: value,
            validPersonalCity: false
        }))
    }

    const onChangeField = (value: string, fieldName: keyof SFieldAddress) => {
        setState(prevState => ({
            ...prevState,
            [fieldName]: value
        }))
    }

    const onClickSave = () => {
        const {personalApartment, personalHousing, personalHome, personalStreet, personalCity} = state;
        if (!personalCity.length && values.personalCity.required) {
            setState(prevState => ({...prevState, validPersonalCity: true}))
            return;
        }

        const command = new ServerCommand('patient/edit', {id: patientId, personalApartment, personalHousing, personalHome, personalStreet, personalCity});
        command.exec().then(() => {
            onChange({personalApartment, personalHousing, personalHome, personalStreet, personalCity})
            setEditField(false);
        });
    }

    return (
        <div>
            <TextField onChange={onChangeFieldCity}
                       value={state.personalCity}
                       label={values.personalCity.required ? ["н.п.", <span>*</span>] : "н.п."}
                       margin="0 0 10px 0"
                       isError={state.validPersonalCity}
            />
            <TextField onChange={value => onChangeField(value, 'personalStreet')}
                       value={state.personalStreet}
                       label="ул."
                       margin="0 0 10px 0"
            />
            <TextField onChange={value => onChangeField(value, 'personalHome')}
                       value={state.personalHome}
                       label="д."
                       margin="0 0 10px 0"
            />
            <TextField onChange={value => onChangeField(value, 'personalHousing')}
                       value={state.personalHousing}
                       label="кор."
                       margin="0 0 10px 0"
            />
            <TextField onChange={value => onChangeField(value, 'personalApartment')}
                       value={state.personalApartment}
                       label="кв."
            />
            <SaveButtons onClickSave={onClickSave}/>
        </div>
    )
}
