import React from "react";
import Typography from "../../../../../../../components-ui/DataDisplay/Typography/Typography";
import Helper from "../../../../../../../common/helpers/main";
import {StateCallCenter} from "../../../../../types/components";
import { SContainerItem } from "../../../../../../../containers/Tasks/style";

interface Props {
    state: StateCallCenter
}

const PhoneHeader = ({state}: Props) => {
    const {detail} = state
    if (!detail) return null
    return (
        <SContainerItem>
            <Typography display="inline-block" children={Helper.Phone.format(detail.phone)}/>
        </SContainerItem>
    )
}

export default PhoneHeader
