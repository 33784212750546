import React, {useEffect, useRef} from 'react'
import {days} from "./helperCalendar";
import CalendarBody from "./elements/CalendarBody";
import {Wrapper, CalendarWrap, Header, HeaderDay, Body, BodyWrap} from "./styledCalendar";
import Controls from "./elements/Controls";
import {useDispatch, useSelector} from "react-redux";
import {calendarUpload} from "../../redux/actions/scheduleActions";
import ServerCommand from "../../common/server/server-command";
import moment from "moment";
import Tooltip from '../../components-ui/DataDisplay/Tooltip/Tooltip';
import {GetCalendarParams} from "./types";


export default function Calendar({handleSelectedDates}: any) {
    const ref = useRef() as any;
    const filter = useSelector(state => state.schedule.filter);
    const calendar = useSelector(state => state.schedule.calendar);
    const dispatch = useDispatch();

    const getCalendarParams = () => {
        let data = Object.assign({}, filter) as GetCalendarParams;
        let loadStartDate = moment(calendar.dateFrom),
            loadEndDate = moment(calendar.dateTo);

        data.dateFrom = loadStartDate.format('YYYY-MM-DD')
        data.dateTo = loadEndDate.format('YYYY-MM-DD')

        if (!data.dateFrom) data.dateFrom = null
        if (!data.dateTo) data.dateTo = null
        if (!data.timeFrom) data.timeFrom = null
        if (!data.timeTo) data.timeTo = null
        if (!data.branches || data.branches.length === 0) data.branches = null
        if (!data.speciality) data.speciality = null
        if (!data.doctorId) data.doctorId = null

        return data
    }

    const handleControl = (direction: string, unit: string) => {
        const scrollTop = ref.current.scrollTop;
        const clientHeight = ref.current.clientHeight;
        const scrollHeight = ref.current.scrollHeight;
        const loadWeeksCount = ((unit === 'week') ? 1 : 4);
        let data = getCalendarParams();
        let loadStartDate = moment(calendar.dateFrom),
            loadEndDate = moment(calendar.dateTo);
        let scroll = unit === 'week' ? 41 : 164;
        if (direction === 'top') {
            scroll = Math.round((scrollTop + 4 - scroll) / 41) * 41 + 5;
            if (scroll < 0) loadStartDate.add('-' + loadWeeksCount, 'w');
        } else {
            scroll = Math.round((scrollTop + 4 + scroll) / 41) * 41 + 5;
            if (scroll + clientHeight - 41 + 5 > scrollHeight) loadEndDate.add('+' + loadWeeksCount, 'w');
        }

        if (scroll < 0 || scroll + clientHeight - 41 + 5 > scrollHeight) {
            data.dateFrom = loadStartDate.format('YYYY-MM-DD');
            data.dateTo = loadEndDate.format('YYYY-MM-DD');
            ServerCommand
                .get('schedule/calendar', data, (response: any) => {
                    dispatch(calendarUpload(response));
                    ref.current.scrollTo(0, unit === 'week' ? scroll + 41 : scroll + 164)
                })
                .then(() => {
                    ref.current.scrollTo(0, scroll);
                    Tooltip.rebuild();
                });
        } else {
            ref.current.scrollTo(0, scroll);
        }
    };

    const updateCalendar = () => {
        const data = getCalendarParams()
        if (data.dateFrom) {
            ServerCommand.get('schedule/calendar', getCalendarParams(), (response: any) => {
                dispatch(calendarUpload(response));
            }).then(() => Tooltip.rebuild());
        }
    }

    useEffect(() => {
        updateCalendar()
    }, [JSON.stringify(filter)])

    useEffect(() => {
        const timer = setInterval(() => {
            updateCalendar()
        }, 1000 * 60 * 10)

        return () => clearInterval(timer)
    }, [JSON.stringify(filter)])

    return (
        <Wrapper>
            <CalendarWrap>
                <Header children={days.map(day => (<HeaderDay key={day} children={day}/>))}/>
                <Body ref={ref}>
                    <BodyWrap>
                        <CalendarBody handleSelectedDates={handleSelectedDates}/>
                    </BodyWrap>
                </Body>
                <Controls handleControl={handleControl}/>
                <Tooltip html place="top" offset={{top: -15}}/>
            </CalendarWrap>
        </Wrapper>
    );
}
