import React from 'react';
import PropTypes from 'prop-types';
import './IDS.scss'
import ServerCommand from "../../../../common/server/server-command";
import Printer from "../../../../common/server/printer";
import StepFooter from "../../components/StepFooter/StepFooter";
import Helper from "../../../../common/helpers/main";
import Table from "../../../../components/Table/Table";
import SelectCheckbox from "../../../../components/Table/Elements/SelectCheckbox";

const idsColumns = [
    {
        title: 'Услуги согласованного плана',
        code: 'serviceName'
    },
    {
        title: 'Зуб',
        code: 'target'
    },
    {
        title: 'ИДС',
        code: 'consents',
    }
];


export default class IDS extends React.Component {
    constructor(props) {
        super(props);
        this.handlerLoader = this.handlerLoader.bind(this)
    }

    static propTypes = {
        plan: PropTypes.object.isRequired,
        visitId: PropTypes.number.isRequired,
        goToNextSubStep: PropTypes.func.isRequired
    };

    state = {
        tableData: [],
        selectedConsentsGroupsCodes: this.getInitiallySelectedItemsIds(),
        selectedConsents: this.getInitiallySelectedConsents(),
        suggestToSelectPlanItem: false,
        loaderPrint: false
    };

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.handlerLoader)
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.handlerLoader)
    }

    render() {
        if (this.props.plan === null) return null;

        return (
            <div className="ids">
                <div className="box-body">
                    <div className="card">
                        <Table
                            data={this.getConsentsGroups()}
                            columns={idsColumns}
                            selectMode="multiple"
                            prepareDataItem={this.prepareConsentGroup}
                            selectedRows={this.state.selectedConsentsGroupsCodes}
                            onSelect={this.handleSelectConsentGroup}
                        />
                    </div>
                </div>
                <StepFooter
                    nextBtnName="К лечению"
                    showPrintBtn={true}
                    variantDisabledClass={true}
                    onNextButtonClick={this.props.goToNextSubStep}
                    onPrintButtonClick={this.handlePrintButtonClick}
                    printBtnLabel={this.getPrintButtonText()}
                    printBtnActive={this.getValidatePrint()}
                    nextBtnLabel={"Распечатайте ИДС"}
                    loaderPrint={this.state.loaderPrint}
                />
            </div>
        )
    }

    renderConsentSelect(consentGroupCode, options) {
        return (
            <SelectCheckbox options={options} onChange={this.handleChangeConsent.bind(this, consentGroupCode)}/>
        );
    }

    getValidatePrint = () => {
        const {selectedConsents} = this.state;
        let res = false;
        Object.values(selectedConsents).forEach(item => {
            if (!!item.length) res = true;
        });
        return res;
    }

    getPrintButtonText() {

        if(!this.getValidatePrint()) {
            return 'Выберите хотя бы одну услугу согласованного плана';
        }
        else if(this.getValidatePrint()) {
            return 'Пациенту на подпись';
        }

        return '';
    }

    getConsentsGroups() {
        const {plan} = this.props;
        const {selectedConsents} = this.state;

        let result = [];

        plan.items.forEach(planItem => {
            if(planItem.voluntaryConsents.length > 0) {
                const consentGroup = Helper.clone(planItem);
                consentGroup.code = consentGroup.id;
                delete consentGroup.id;

                consentGroup.consentsOptions = planItem.voluntaryConsents.map(consentCode => ({
                    label: plan.availableConsents[consentCode].name,
                    value: consentCode,
                    checked: selectedConsents[planItem.id].includes(consentCode)
                }));
                result.push(planItem);
            }
        });

        const commonItem = {
            code: 'commonConsents',
            target: '',
            serviceName: 'Ситуативные услуги',
            consentsOptions: []
        };

        Helper.forEachObj(plan.availableConsents, consent => {
            if (consent.isCommon) {
                commonItem.consentsOptions.push({
                    label: consent.name,
                    value: consent.code,
                    checked: selectedConsents.commonConsents.includes(consent.code)
                })
            }
        });

        result.push(commonItem);

        return result;
    }

    prepareConsentGroup = ({item: consentGroup}) => {
        consentGroup.rowCode = consentGroup.code;
        consentGroup.consents = this.renderConsentSelect(consentGroup.code, consentGroup.consentsOptions)
    };

    getInitiallySelectedItemsIds() {
        const {plan} = this.props;
        const chosenItemsIds = [];

        plan.items.forEach(item => {
            if(!Helper.isEqual(item.voluntaryConsents, item.printedConsents)) {
                chosenItemsIds.push(item.id);
            }
        });

        return chosenItemsIds;
    }

    getInitiallySelectedConsents() {
        const {plan} = this.props;
        const result = {};

        plan.items.forEach(item => {
            result[item.id] = Helper.Array.diff(item.voluntaryConsents, item.printedConsents);
        });

        result.commonConsents = [];

        return result;
    }

    handleSelectConsentGroup = (selectedConsentsGroupsCodes) => {
        this.setState({selectedConsentsGroupsCodes});
    };

    handlePrintButtonClick = () => {
        const {selectedConsentsGroupsCodes, selectedConsents} = this.state;
        this.setState({loaderPrint: true})

        if (selectedConsentsGroupsCodes.length === 0) {
            this.setState({suggestToSelectPlanItem: true});
            return false
        }

        let planItemsConsents = [];
        let commonConsents = [];

        selectedConsentsGroupsCodes.forEach(itemId => {
            if(itemId === 'commonConsents') {
                commonConsents = selectedConsents[itemId];
            }
            else if (selectedConsents[itemId] && selectedConsents[itemId].length > 0) {
                planItemsConsents.push({
                    itemId,
                    consents: selectedConsents[itemId]
                });
            }
        });

        const command = new ServerCommand('voluntary-consent/generate-documents', {
            visitId: this.props.visitId,
            planItemsConsents,
            commonConsents
        });

        command.exec().then((response) => {
            this.setState({loaderPrint: false})
            return Printer.print(response.documents)
        });
    };

    handleChangeConsent = (consentGroupCode, selectedConsentOption, isSelected) => {
        const consentCode = selectedConsentOption.value;
        const selectedConsents = Helper.clone(this.state.selectedConsents);
        let {selectedConsentsGroupsCodes} = this.state;

        const groupSelectedConsents = selectedConsents[consentGroupCode];

        if(isSelected) {
            if(!groupSelectedConsents.includes(consentCode)) {
                groupSelectedConsents.push(consentCode);
            }
        }
        else {
            groupSelectedConsents.splice(groupSelectedConsents.indexOf(consentCode), 1);
        }

        const newState = {selectedConsents};

        if(!selectedConsentsGroupsCodes.includes(consentGroupCode)) {
            selectedConsentsGroupsCodes = Helper.clone(selectedConsentsGroupsCodes);
            selectedConsentsGroupsCodes.push(consentGroupCode);
            newState.selectedConsentsGroupsCodes = selectedConsentsGroupsCodes;
        }

        this.setState(newState);
    }

    handlerLoader(event) {
        const reason = event.reason;
        if (reason.type === 'server_request_error') {
            this.setState({loader: false})
        }
    }
}
