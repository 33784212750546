import * as React from "react";
import {Component} from 'react';
import './Graph.scss';
import ServerCommand from "../../common/server/server-command";
import {joinTimeInterval} from "../../common/helpers/graph-helper";
import Tabs from "../../components-ui/Navigation/Tabs/Tabs";
import {graphDays, graphWeeks} from './constants'
import Helper from "../../common/helpers/main";
import {GraphPageState, PClinicWorkingTime, Template} from "./types";
import BoxBody from "../../components-ui/Wraps/old/BoxBody";
import Schedule from "../../components/Schedule/Schedule";
import {chair, ChairTemplate, DayTemplate} from "../../common/types/schedule";
import SaveGraph from "./Modals/Save";
import {DialogsManagerContext} from "../../containers/DialogsManager/DialogsManager";
import {connect} from "react-redux";
import Select from "../../components-ui/Inputs/Select/Select";
import {IBranch} from "../../types";
import {OptionSelect} from "../../components-ui/Inputs/Select/typesSelect";
import OfferToSave from "./Modals/OfferToSave";
import {Prompt} from "react-router-dom";
import Loader from "../../components-ui/Feedback/Loader/Loader";
import Box from "../../components-ui/Layout/Box";
import LoaderPage from "../../components/LoaderPage";
import Button from "../../components-ui/Inputs/Button/Button";

class GraphPage extends Component<any, GraphPageState> {
    constructor(props: any) {
        super(props);
        this.handlerLoader = this.handlerLoader.bind(this)
        this.refSelect = React.createRef();
    }

    static contextType = DialogsManagerContext
    refSelect: any = null;

    state = {
        clinicWorkingTime: {
            timeStart: '',
            timeEnd: ''
        },
        timeInterval: [],
        template: null,
        useWeekParity: true,
        chairs: [],
        weekTab: graphWeeks[0].code,
        dayTab: graphDays[0].code,
        snapshotTemplate: null,
        loader: false,
        scrollTo: 0,
        selectedBranch: this.props.user.branches[0],
        allowRedirect: false,
        loaderBranch: false,
        loaderSave: false,
        selectedFix: false
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | null | undefined {
        const {
            useWeekParity,
            weekTab,
            dayTab,
            timeInterval,
            template,
            chairs,
            loader,
            scrollTo,
            selectedBranch
        } = this.state as any;

        if (template === null) {
            return <LoaderPage/>;
        }

        return (
            <>
                <Prompt
                    when={this.state.allowRedirect ? false : this.validCanceled()}
                    message={location => {
                        this.context.showModal(<OfferToSave selectedBranch={this.state.selectedBranch}
                                                            onClickBtnSave={this.onClickBtnSave}
                                                            didMount={() => this.setState({allowRedirect: true})}
                                                            onChangeLocation={() => this.props.history.push(location.pathname)}
                        />, {isIconClose: false})
                        return false
                    }}
                />
                <BoxBody modification="graph">
                    <h2 ref={this.refSelect}>График работы врачей</h2>
                    <Box display="flex" margin="0 0 32px" position="relative" zIndex={25}>
                        {this.getOptionsBranches().length > 1 && (
                            <Select sizeSelect="sm" marginSelect="0 16px 0 0" options={this.getOptionsBranches()}
                                    widthSelect="375px" label="Филиал:" colorLabel="textGray" isFixIndicator={true}
                                    value={this.getValueBranch()} onChange={this.onChangeBranch}/>
                        )}
                        {useWeekParity &&
                        <Tabs
                            tabs={graphWeeks}
                            variant="primary"
                            activeTab={weekTab}
                            onChange={this.handleWeekTabs}
                            size='lg'
                            widthTab="142px"
                        />
                        }
                    </Box>
                    <Tabs
                        tabs={graphDays}
                        variant="classic"
                        activeTab={dayTab}
                        onChange={this.handleDayTabs}
                        widthTab="110px"
                    />
                    <Box position="relative">
                        <Loader visible={this.state.loaderBranch}
                                customStyle={{alignItems: 'flex-start', paddingTop: '250px'}}/>
                        <Schedule
                            timeInterval={timeInterval}
                            template={template[weekTab][dayTab]}
                            chairs={chairs}
                            allTemplate={template}
                            dayTab={dayTab}
                            key={weekTab + '_' + dayTab}
                            parentKey={weekTab + '_' + dayTab + '_' + selectedBranch}
                            handleTemplate={this.handleTemplate}
                            scrollTo={scrollTo}
                            handleScrollTo={this.handleScrollTo}
                            selectedBranch={selectedBranch}
                        />
                    </Box>
                    <Box display="flex" margin="32px 0 0">
                        <Button children="Отменить" variant={this.validCanceled() ? "default" : "disabled"}
                                onClick={() => this.onClickCanceled()} loader={loader} size="md"/>
                        <Button children="Сохранить..." variant={this.validCanceled() ? "success" : "disabled"}
                                onClick={() => this.onClickBtnSave()} loader={loader} size="md" margin="0 0 0 18px"/>
                    </Box>
                </BoxBody>
            </>

        );
    }

    componentDidMount(): void {
        window.addEventListener('unhandledrejection', this.handlerLoader)
        this.loadForm()
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.handlerLoader)
    }

    handlerLoader(event: any) {
        const reason = event.reason;
        if (reason.type === 'server_request_error') {
            this.setState({loader: false, loaderBranch: false, loaderSave: false})
        }
    }

    loadForm = (branchId: number = this.state.selectedBranch) => {
        ServerCommand.get('schedule-template/main-form', {branchId}).then(response => {
            const {useWeekParity, template, clinicWorkingTime, chairs} = response;

            let newTemplate = {} as any;

            graphWeeks.forEach(week => {
                if (week.code !== 'all') {
                    newTemplate[week.code] = {}
                }
            })

            if (useWeekParity) newTemplate.all = {};

            Object.keys(newTemplate).forEach(week => {
                graphDays.forEach(day => {
                    newTemplate[week][day.code] = []
                })
            })

            Helper.forEachObj(template, (week: any, weekName: string) => {

                newTemplate[weekName] = newTemplate[weekName] ?? {};

                Helper.forEachObj(week, (day: any, dayName: string) => {

                    if (newTemplate[weekName][dayName] === undefined) newTemplate[weekName][dayName] = {};

                    if (useWeekParity && newTemplate.all[dayName] === undefined) newTemplate.all[dayName] = {};

                    chairs.forEach((chair: any) => newTemplate[weekName][dayName][chair.id] = []);

                    Helper.forEachObj(day, (chair: any, chairName: string) => {
                        chair.forEach((item: any) => {
                            item.doctorId = item.employeeId;
                            delete item.employeeId;

                            if (item.doctorId !== 0) newTemplate[weekName][dayName][chairName].push(item)
                        });
                    })
                })
            });

            this.toggleConflictGraph(chairs, newTemplate, clinicWorkingTime);

            let newState = {
                clinicWorkingTime,
                timeInterval: joinTimeInterval(template, clinicWorkingTime),
                template: newTemplate,
                useWeekParity,
                chairs,
                snapshotTemplate: newTemplate,
                loaderBranch: false
            };
            this.setState(newState)
        });
    }

    toggleConflictGraph = (chairs: chair[], template: Template | any, clinicWorkingTime: PClinicWorkingTime) => {
        graphDays.forEach(day => {
            chairs.forEach(chair => {
                const even: ChairTemplate[] | undefined = template.even ? template.even[day.code][chair.id] : undefined;
                const odd: ChairTemplate[] | undefined = template.odd ? template.odd[day.code][chair.id] : undefined;
                let err = [{
                    timeStart: clinicWorkingTime.timeStart,
                    timeEnd: clinicWorkingTime.timeEnd,
                    doctorId: 'conflict',
                    conflictDoctorsId: []
                }];

                if (even === undefined && odd !== undefined) {
                    odd.forEach((item) => {
                        // @ts-ignore
                        err[0].conflictDoctorsId.push(item.doctorId);
                    });
                    template.all[day.code][chair.id] = err;
                } else if (odd === undefined && even !== undefined) {
                    even.forEach((item) => {
                        // @ts-ignore
                        err[0].conflictDoctorsId.push(item.doctorId);
                    });
                    template.all[day.code][chair.id] = err;
                } else {
                    if (even !== undefined && odd !== undefined) {
                        let isConflict = false;
                        if (even.length && !odd.length) isConflict = true;
                        if (!even.length && odd.length) isConflict = true;
                        if (!isConflict) {
                            even.forEach((item: any, index: number) => {
                                if (!Helper.isEqual(item, odd[index])) {
                                    isConflict = true;
                                }
                            });
                        }

                        if (isConflict) {
                            let conflictDoctorsId = [] as any;
                            even.forEach((item: any) => conflictDoctorsId.push(item.doctorId));
                            odd.forEach((item: any) => conflictDoctorsId.push(item.doctorId));
                            conflictDoctorsId = Helper.Array.unique(conflictDoctorsId);
                            err[0].conflictDoctorsId = conflictDoctorsId;
                            template.all[day.code][chair.id] = err;
                        } else {
                            template.all[day.code][chair.id] = even;
                        }
                    }
                }
            })
        });
    };

    handleWeekTabs = (weekTab: string) => this.setState({weekTab});

    handleDayTabs = (dayTab: string) => this.setState({dayTab});

    handleScrollTo = (scrollTo: number) => this.setState({scrollTo});

    handleTemplate = (dayTemplate: DayTemplate): void => {
        const {weekTab, dayTab, chairs, clinicWorkingTime} = this.state;
        const template = Helper.clone(this.state.template);
        if (weekTab !== 'all') {
            template[weekTab][dayTab] = dayTemplate;
            this.toggleConflictGraph(chairs, template, clinicWorkingTime);
        } else {
            graphWeeks.forEach(week => {
                if (week.code !== 'all') {
                    chairs.forEach((chair: any) => {
                        const selectChair = dayTemplate[chair.id] as any;
                        const currentChair = template[week.code][dayTab][chair.id] as any;
                        if (selectChair !== undefined) {
                            if (selectChair.some((item: any) => item.doctorId === 'conflict')) {
                                template[week.code][dayTab][chair.id] = currentChair;
                            } else {
                                template[week.code][dayTab][chair.id] = selectChair;
                            }
                        } else {
                            template[week.code][dayTab][chair.id] = [];
                        }

                    });
                } else {
                    template[week.code][dayTab] = dayTemplate;
                }
            });
        }

        this.setState({template});
    };

    onClickCanceled = () => this.setState({template: this.state.snapshotTemplate});

    onClickBtnSave = (branchId: number = this.state.selectedBranch, onCloseSave?: () => void, onCloseCancel?: () => void) => {
        this.setState({loader: true});
        const templateChanges = this.getTemplateChanges() as any;

        ServerCommand.post('schedule-template/apply-form', {
            templateChanges,
            branchId
        }).then(response => {
            this.setState({loader: false})
            return this.context.showModal(<SaveGraph
                conflictsCountMap={response.conflictsCount}
                onCloseSave={onCloseSave}
                onCloseCancel={onCloseCancel}
                branchId={branchId}
                getTemplateChanges={this.getTemplateChanges}
                setSnapshotTemplate={this.setSnapshotTemplate}
            />, {isIconClose: false})
        });

    };

    setSnapshotTemplate = () => {
        this.setState({snapshotTemplate: this.state.template})
    }

    getTemplateChanges = () => {
        const {template, snapshotTemplate, timeInterval} = this.state as any;
        let templateChanges = {} as any;
        Helper.forEachObj(template, (week: any, weekName: any) => {
            if (weekName !== 'all') {
                Helper.forEachObj(week, (day: any, dayName: any) => {
                    Helper.forEachObj(day, (chair: any, chairName: any) => {
                        if (!Helper.isEqual(chair, snapshotTemplate[weekName][dayName][+chairName])) {
                            if (templateChanges[weekName] === undefined) templateChanges[weekName] = {};
                            if (templateChanges[weekName][dayName] === undefined) templateChanges[weekName][dayName] = {};
                            if (chair.length === 0) {
                                chair = [{
                                    timeStart: timeInterval[0],
                                    timeEnd: timeInterval[timeInterval.length - 1],
                                    doctorId: 0
                                }]
                            }
                            chair = chair.map((item: any) => {
                                return {
                                    timeStart: item.timeStart,
                                    timeEnd: item.timeEnd,
                                    employeeId: item.doctorId
                                }
                            });
                            templateChanges[weekName][dayName][chairName] = chair
                        }

                    });
                })
            }
        });
        return templateChanges;
    };

    validCanceled = () => {
        const {template, snapshotTemplate} = this.state as any;
        let result = false;

        if (template !== null) {
            Helper.forEachObj(template, (week: any, weekName: any) => {
                Helper.forEachObj(week, (day: any, dayName: any) => {
                    Helper.forEachObj(day, (chair: any, chairName: any) => {
                        if (!Helper.isEqual(chair, snapshotTemplate[weekName][dayName][+chairName])) {
                            result = true
                        }
                    });
                })
            });
        }

        return result;

    };

    getOptionsBranches = (): OptionSelect[] => {
        return this.props.branches.map((branch: IBranch) => ({
            value: branch.id,
            label: branch.name
        }))
    }

    getValueBranch = (): OptionSelect => {
        return this.getOptionsBranches().find(option => option.value === this.state.selectedBranch) as OptionSelect
    }

    onCloseCancelSaveModal = (selectedBranch: number) => {
        this.setState({loaderBranch: false, selectedBranch})
    }

    onChangeBranch = (value: OptionSelect) => {
        const selectedBranch = value.value;
        this.setState({selectedBranch, loaderBranch: true})
        if (this.validCanceled()) {
            this.context.showModal(<OfferToSave selectedBranch={this.state.selectedBranch}
                                                newSelectedBranch={selectedBranch}
                                                onClickBtnSave={this.onClickBtnSave}
                                                onCloseCancelSaveModal={this.onCloseCancelSaveModal}
                                                loadForm={this.loadForm}
            />, {isIconClose: false, shouldCloseOnEsc: false})
            return false;
        }

        this.loadForm(selectedBranch)
    }

}

const mapStateToProps = (state: any) => ({
    user: state.user,
    branches: state.common.branches
});

export default connect(mapStateToProps)(GraphPage)
