import {GetPaymentsCheckFormResult} from "../types";
import {useEffect, useState} from "react";
import useServerTimeMoment from "../../../common/hooks/useServerTimeMoment";
import DateHelper from "../../../common/helpers/date-helper";
import {useSelector} from "react-redux";

const useNeedConfirmPayment = (paymentsResponse: GetPaymentsCheckFormResult) => {

    const user = useSelector(state => state.user);

    const currentTimeMoment = useServerTimeMoment();
    const currentTime = currentTimeMoment.format(DateHelper.FORMAT_TIME_MINUTES)

    const paymentsCheckTimeMoment = useServerTimeMoment(paymentsResponse.paymentsCheckTime, DateHelper.FORMAT_TIME_MINUTES)

    const [checkTimeCame, setCheckTimeCame] = useState(false)

    const needConfirmPayment = checkTimeCame && !paymentsResponse.paymentsChecked && user.needCheckPayments

    // Обновление checkTimeCame при наступлении времени проверки оплат (paymentsCheckTime)
    useEffect(() => {
        // Обновляем checkTimeCame - сравниваем время на текущий момент
        const newCheckTimeCame = !!paymentsResponse.paymentsCheckTime && paymentsResponse.paymentsCheckTime <= currentTime;
        setCheckTimeCame(newCheckTimeCame)

        // Если время проверки оплат ещё не наступило, устанавливаем таймаут, чтобы обновить checkTimeCame в момент
        // наступления времени проверки оплат
        if (!!paymentsResponse.paymentsCheckTime && !newCheckTimeCame) {
            const paymentsCheckTimeDiff = paymentsCheckTimeMoment.diff(currentTimeMoment)

            let timeout: ReturnType<typeof setTimeout>

            if (paymentsCheckTimeDiff >= 0) {
                timeout = setTimeout(() => setCheckTimeCame(true), paymentsCheckTimeDiff)
            }

            return () => {
                clearTimeout(timeout)
            }
        }

    }, [paymentsResponse.paymentsCheckTime])

    return {needConfirmPayment}


};

export default useNeedConfirmPayment;
