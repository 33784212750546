import {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {headerTabsDetail} from "../../../helper/getDataCallCenter";
import {HistoryData, PlannedCalls, StateCallCenter} from "../../../types/components";
import {TaskCallCenter, TasksDefault} from "../../../types/common";
import sortTasks from "../../../../../containers/Tasks/utils/sortTasks";
import Helper from "../../../../../common/helpers/main";
import {useDateUpdate} from "../../../../../common/hooks";
import {InfoCallCenter} from "../../../types/hooks";
import {Tab} from "../../../../../components-ui/Navigation/Tabs/elements/Tab/typesTab";
import useLoader from "../../../../../components/Errors";
import useInitialProgressCall from "./useInitialProgressCall";
import useProgressCallPatient from "./useProgressCallPatient";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import _ from "underscore";
import {useSelector} from "react-redux";

interface Props {
    state: StateCallCenter
    infoCallCenter: InfoCallCenter
    setScriptsType: Dispatch<SetStateAction<string | null>>
}

export interface StateDetailed {
    plannedCalls: PlannedCalls
    progressCall: TaskCallCenter[]
    headerTab: string
    validationAfterSave: boolean
    tabPatient: string | number | null
    optionsTabsPatients: null | Tab[]
    historyData: HistoryData
    selectedTheme: OptionSelect[] | null
    selectedType: OptionSelect | null
}

const useDetailed = ({state, infoCallCenter, setScriptsType}: Props) => {
    const directions = useSelector(state => state.common.directions)
    const isMarketingAdmin = useSelector(state => state.user.role) === 'marketing-admin';
    const callerTypesReduce = useSelector(state => state.common.callerTypes);

    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);

    const directionsNames = useMemo(() => {
        return _.map(directions, (direction) => {
            return {value: direction.code, label: direction.name} as OptionSelect
        })
    }, [directions])

    const callerTypes = useMemo(() => {
        return _.map(callerTypesReduce, (label, value) => {
            return {value, label} as OptionSelect
        })
    }, [])

    const [detailedState, setDetailedState] = useState<StateDetailed>({
        plannedCalls: {},
        progressCall: [],
        headerTab: headerTabsDetail[0].code,
        validationAfterSave: true,
        tabPatient: null,
        optionsTabsPatients: null,
        historyData: {completedCalls: [], users: []},
        selectedTheme: null,
        selectedType: null
    })

    const patient = useProgressCallPatient({detailedState})

    const [futureComment, setFutureComment] = useState(!!patient && !!patient.futureVisit && !!patient.futureVisit.comment ? patient.futureVisit.comment : '');

    const [loaderHistory, setLoaderHistory] = useLoader()

    useEffect(() => {
        setDetailedState(prevState => {
            let newProgressCall = [] as TaskCallCenter[];
            let newPlannedCalls = {} as {[key: string]: TasksDefault};
            if (!!state.detail) {
                const _sortTasks = sortTasks(state.detail.tasks, currentDate, infoCallCenter.types) as TaskCallCenter[]
                _sortTasks.forEach(task => {
                    if (task.type === 'incoming_call' || task.type === 'missed_call') {
                        newProgressCall.push(task)
                    } else {
                        const patientId = task.targetPerson.patientId as number;
                        if (newPlannedCalls[patientId] === undefined) newPlannedCalls[patientId] = [];
                        newPlannedCalls[patientId].push(task)
                    }
                })
            }
            return {...prevState, plannedCalls: newPlannedCalls, progressCall: newProgressCall}
        })
    }, [JSON.stringify(state.detail)])

    useEffect(() => {
        setDetailedState(prevState => ({...prevState, tabPatient: null, optionsTabsPatients: null}))
    }, [state.detail?.phone])

    useInitialProgressCall({state, detailedState, setDetailedState, setScriptsType})

    useEffect(() => {
        setDetailedState(prevState => ({
            ...prevState,
            validationAfterSave: true,
            headerTab:headerTabsDetail[0].code,
            historyData: {completedCalls: [], users: []},
            selectedTheme: null,
            selectedType: null
        }))
        setLoaderHistory(false)
    }, [state.detail?.phone])

    useEffect(() => {
        setDetailedState(prevState => {
            if (!!state.detail && !!state.detail.result && !!state.detail.result.topics) {
                prevState.selectedTheme = state.detail.result.topics.map(topic => directionsNames.find(option => option.value === topic) as OptionSelect)
            }
            return {...prevState}
        })
    }, [state.detail?.result])

    useEffect(() => {
        if (!!detailedState.progressCall.length) {
            setDetailedState(prevState => {
                const currentCall = state.calls.find(call => call.contactPhone === state.detail?.phone);
                let selectedType = callerTypes.find(option => {
                    if (!!state.detail && !!state.detail.result && !!state.detail.result.callerType) {
                        return option.value === state.detail.result.callerType
                    } else {
                        if (!!patient) {
                            return option.value === (patient.futureVisit === null ? 'existing_patient' : 'recorded_patient')
                        } else {
                            return option.value === 'new_patient'
                        }
                    }
                }) as OptionSelect

                if (!!currentCall && !!currentCall.callerType) {
                    selectedType = callerTypes.find(option => option.value === currentCall.callerType) as OptionSelect
                }

                return {
                    ...prevState,
                    selectedType
                }

            })
        }
    }, [detailedState.tabPatient, JSON.stringify(detailedState.progressCall), state.detail?.result])

    useEffect(() => {
        if (!!detailedState.progressCall.length) {
            if (!!detailedState.selectedType && (detailedState.selectedType.value === 'existing_patient' || detailedState.selectedType.value === 'new_patient')) {
                setScriptsType(detailedState.selectedType.value)
            } else {
                if (!isMarketingAdmin) {
                    if (!state.isMarketing) setScriptsType(null)
                }

            }
        }
    }, [detailedState.selectedType, JSON.stringify(detailedState.progressCall), state.detail?.result])

    const isCallSubject = !!detailedState.selectedType && (detailedState.selectedType.value === 'existing_patient' || detailedState.selectedType.value === 'new_patient')

    const validate = useMemo(() => {
        let result = true;
        if (isCallSubject) result = !!detailedState.selectedTheme && !!detailedState.selectedTheme.length;
        return result
    }, [detailedState.selectedTheme, detailedState.selectedType])

    return {
        directionsNames,
        callerTypes,
        detailedState,
        setDetailedState,
        patient,
        futureComment,
        setFutureComment,
        loaderHistory,
        setLoaderHistory,
        isCallSubject,
        validate
    }

}

export default useDetailed
