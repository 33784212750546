import React from 'react';
import TaskContent from "../TaskContent";
import {TaskCallCenter} from "../../../../types/common";
import {SurveyInviteData} from "../../../../types/taskData";
import {Services} from "../../../../components/Labels";
import {FinalButton, NoteDownButton, OpenPlanButton} from "../../../../components/Buttons";
import {CommonTaskProps} from "../../types/common";

interface Props extends CommonTaskProps {
    task: TaskCallCenter<SurveyInviteData>
}

export const SurveyInvite = ({task, onChangeSelectedScript, ...props}: Props) => {
    return (
        <TaskContent onChangeSelectedScript={onChangeSelectedScript} buttons={<Buttons task={task} {...props}/>}
                     task={task}>
            <Services task={task}/>
        </TaskContent>
    );
};

const Buttons = ({
                     task,
                     validate,
                     blockedButtons,
                     methodsCallCenter
                 }: Omit<Props, 'onChangeSelectedScript'>) => {
    return (
        <>
            <NoteDownButton validate={validate} task={task} blockedButtons={blockedButtons}
                            methodsCallCenter={methodsCallCenter}/>
            <FinalButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
            <OpenPlanButton task={task} methodsCallCenter={methodsCallCenter}/>
        </>
    )
}
