import React, {Fragment} from 'react';
import {STableCell, STableGroup} from "../style/common";
import Scrollbar from "../../Scrollbar/Scrollbar";
import usePrepareRows from "../hooks/usePrepareRows";
import Row from "./Row";
import {IColumn, IGroup, IRow} from "../types/common";
import {VariantTable} from "../types/components";
import {StateSortTable} from "../hooks/useSortTable";
import Box from "../../../Layout/Box";

interface Props {
    fieldNameNested?: string
    isNested?: boolean
    columns: IColumn[]
    onClickRow?: (row: IRow) => void
    isHovering: boolean
    rows: IRow[]
    groups?: IGroup[]
    fieldNameGroupId?: string
    highlightedGroupsIds?: (number | string)[]
    sortBy?: string
    height?: string
    onScroll?: (event: any) => void
    refScrollList?: any
    fontSizeRow?: string
    variantTable: VariantTable
    stateColumnSort: StateSortTable
    maxHeight?: string
    minHeight?: string
    customRowsCells?: boolean
}

const Rows = (
    {
        fieldNameNested,
        isNested,
        columns,
        customRowsCells,
        onClickRow,
        isHovering,
        height,
        onScroll,
        refScrollList,
        fontSizeRow,
        variantTable,
        maxHeight,
        minHeight,
        ...props
    }: Props) => {
    const prepareRows = usePrepareRows({...props, columns, isNested})

    return (
        <Box minHeight={minHeight}>
            <Scrollbar height={height || maxHeight || 'auto'} onScroll={onScroll} ref={refScrollList}>
                {prepareRows.map(group => {
                    return (
                        <Fragment key={!!group.group ? group.group.id : 'group'}>
                            {!!group.group && <STableGroup variantTable={variantTable}
                                                           children={<STableCell variantTable={variantTable} align="left"
                                                                                 children={group.group.name}/>}/>}
                            {group.rows.map((row, indexRow) => <Row variantTable={variantTable} row={row} key={row.id}
                                                                    onClickRow={onClickRow} columns={columns} indexRow={indexRow}
                                                                    isHovering={isHovering} customRowsCells={customRowsCells}
                                                                    fieldNameNested={fieldNameNested}
                                                                    isNested={isNested} fontSizeRow={fontSizeRow}/>)}
                        </Fragment>
                    )
                })}
            </Scrollbar>
        </Box>

    );
};

export default Rows;
