import React from 'react';
import {FieldsPatient} from "../../../../common/types/patient";
import CaseHistory from "./CaseHistory";
import {useDialogsManager} from "../../../../containers/DialogsManager/DialogsManager";

interface Props {
    patientId: number
    patient: FieldsPatient
}

const CaseHistoryWrap = ({patientId, patient}: Props) => {

    const {showSlideModal} = useDialogsManager()

    return (
        <CaseHistory showSlideModal={showSlideModal} patientId={patientId} patient={patient}/>
    );
};

export default CaseHistoryWrap;