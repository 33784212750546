import {useSelector} from "react-redux";
import moment from "moment";
import DateHelper from "../helpers/date-helper";

const useServerTimeMoment = (time?: string, format: string = DateHelper.FORMAT_TIME) => {
    const serverTimeDiff = useSelector(state => state.common.serverTimeDiff)

    const timeMoment = time ? moment(time, format) : moment()

    return timeMoment.subtract(serverTimeDiff)
}

export default useServerTimeMoment;
