import * as React from "react";
import "./TooltipIcon.scss";
import {useState} from "react";
import Prompt from "../Prompt/Prompt";

interface PropsTooltipIcon {
    tip: string
}

export default function TooltipIcon ({tip}: PropsTooltipIcon) {
    const [hover, setHover] = useState(false);
    return (
            <div className="tooltip-icon" onMouseLeave={() => setHover(false)} onMouseEnter={() => setHover(true)}>
                ?
                {hover && <Prompt text={tip} isTriangle={true} />}
            </div>
    );
};

