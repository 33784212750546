import React from 'react';
import Box from "../../../../../../../../../../components-ui/Layout/Box";
import Avatar from "../../../../../../../../../../components-ui/DataDisplay/Avatar";
import Typography from "../../../../../../../../../../components-ui/DataDisplay/Typography/Typography";
import {ListItem} from "../../../../../../../../../../components-ui/DataDisplay/List";
import {useSelector} from "react-redux";
import {HandlersAverageCheck, StateAverageCheck} from "../../../../../types";
import {BarDataItem} from "../../../../../../../../../../components-ui/Charts/ChartBar";
import useHover from "../../../../../../../../../../common/hooks/useHover";
import AverageTooltip from "../../../components/AverageTooltip";

interface Props {
    codes: string[]
    code: string
    index: number
    state: StateAverageCheck
    handlers: HandlersAverageCheck
    data: BarDataItem[]
}

const AdditionalItem = ({code, index, codes, handlers, state, data}: Props) => {

    const [ref, hover] = useHover()

    const users = useSelector(state1 => state1.common.users)
    return (
        <ListItem cursor="default" isHover={state.hoverIndex === index + codes.length}
                  onMouseEnter={() => handlers.handleHoverIndex(index + codes.length)}
                  onMouseLeave={() => handlers.handleHoverIndex(-1)}
                  ref={ref}
        >
            <Box display="flex" flexWrap="nowrap" alignItems="center" padding="0 15px 0 0">
                <Avatar size="12px"
                        bgColor={users[+code].color || '#447ce6'}
                        margin="0 12px 0 0"
                />
                <Typography variant="small" cursor="default" flexShrink={0} whiteSpace="nowrap">
                    {users[+code].fio}
                </Typography>
            </Box>
            {hover && <AverageTooltip index={index + codes.length} state={state} data={data} positionOffsets={{top: `0`, right: 'calc(100% + 8px)'}} />}
        </ListItem>
    );
};

export default AdditionalItem;
