import React from "react";
import Radio from "../../../components-ui/Inputs/Radio/Radio";
import _ from "underscore";
import {IOption, POptions} from "../parametersInterfaces";
import {ParameterInput} from "../styledParameters";

export default function Options({item, radioValues, selected, setRadioValues, setSelected, inputValues, setInputValues}: POptions) {

    const onChangeInput = (e: any) => {
        const fieldValue = e.target.value
        const newInputValues = _.clone(inputValues);
        newInputValues[item.name] = fieldValue;
        setInputValues(newInputValues)
    };

    const onClickInput = (e: any) => {
        const newInputValues = _.clone(inputValues);
        const newSelected = _.clone(selected);
        newInputValues[item.name] = e.target.value;
        newSelected[item.name] = 'input';
        setInputValues(newInputValues);
        setSelected(newSelected);
    };

    const onClickRadio = (option: IOption) => {
        const newRadioValues = _.clone(radioValues);
        const newSelected = _.clone(selected);
        newRadioValues[item.name] = option.text;
        newSelected[item.name] = 'radio';
        setRadioValues(newRadioValues);
        setSelected(newSelected);
    };

    let options = null;
    let inputParameter = null;

    if (item.options.length > 0) {
        options = item.options.map((option: any) => {
            return (
                <Radio value={option.text}
                       children={option.text}
                       onClick={() => onClickRadio(option)}
                       checked={radioValues[item.name] === option.text && selected[item.name] === 'radio'}
                       key={option.text}/>
            )
        });
        inputParameter = <Radio value={'input' + item.name}
                                children={[<ParameterInput value={inputValues[item.name]}
                                                          onChange={onChangeInput}
                                                          width={280}/>]}
                                onClick={onClickInput}
                                checked={selected[item.name] === 'input'} />
    } else {
        inputParameter = <ParameterInput value={inputValues[item.name]}
                                         onChange={onChangeInput}
                                         onClick={onClickInput}
                                         width={310}/>;
    }

    return (
        <>
            {options}
            {inputParameter}
        </>
    )
}
