import * as React from "react";
import Schedule from "./Schedule";
import {useDispatch, useSelector} from "react-redux";

export default function ScheduleReducer(props: any) {

    const schedule = useSelector(state => state.schedule.schedule);
    const calendarData = useSelector(state => state.schedule.calendar);
    const filter = useSelector(state => state.schedule.filter);
    const switcher = useSelector(state => state.schedule.switcher);
    const splittedTime = useSelector(state => state.schedule.splittedTime);
    const transferPatient = useSelector(state => state.schedule.transferPatient);
    const copiedPatient = useSelector(state => state.schedule.copiedPatient);
    const doctors = useSelector(state => state.common.doctors);
    const serverTimeDiff = useSelector(state => state.common.serverTimeDiff);
    const user = useSelector(state => state.user);
    const initialDate = useSelector(state => state.common.initialDate);
    const selectedDates = useSelector(state => state.schedule.selectedDates);
    const dispatch = useDispatch();

    return <Schedule {...props}
                     schedule={schedule}
                     splittedTime={splittedTime}
                     transferPatient={transferPatient}
                     doctors={doctors}
                     filter={filter}
                     serverTimeDiff={serverTimeDiff}
                     dispatch={dispatch}
                     calendarData={calendarData}
                     copiedPatient={copiedPatient}
                     user={user}
                     initialDate={initialDate}
                     selectedDates={selectedDates}
                     switcher={switcher}
    />
}
