import React from 'react';
import PropTypes from 'prop-types';
import './Guarantees.scss';
import ServerCommand from "../../../../common/server/server-command";
import StepFooter from "../../components/StepFooter/StepFooter";
import Printer from "../../../../common/server/printer";
import Table from "../../../../components/Table/Table";
import Helper from "../../../../common/helpers/general-helper";


const planColumns = [
    {
        title: 'Выполенная работа',
        code: 'serviceName'
    },
    {
        title: 'Гарантия',
        code: 'duration',
        type: 'text',
        editable: true
    },
    {
        title: 'Срок службы',
        code: 'shelfLife',
        type: 'text',
        editable: true
    },
    {
        title: 'Успешность лечения',
        code: 'successChance',
        type: 'number',
        editable: true
    }
];


export default class Guarantees extends React.Component {

    constructor(props) {
        super(props);
        this.handlerLoader = this.handlerLoader.bind(this)
    }

    static propTypes = {
        visitId: PropTypes.number.isRequired,
        goToNextStep: PropTypes.func.isRequired
    };

    state = {
        warranties: null,
        arePrinted: false,
        selectedRows: {},
        loaderPrint: false,
        loaderNextBtn: false
    };

    render() {

        if (this.state.warranties === null) {
            return null
        }

        return (
            <div className={(this.state.warranties.length) ? `box` : `box-sm`}>
                <div className="warranties">
                        {this.renderList()}
                    <StepFooter
                        nextBtnName="Завершить прием"
                        showPrintBtn={!!this.state.warranties.length}
                        nextBtnActive={this.validNextBtn()}
                        variantDisabledClass={true}
                        onNextButtonClick={this.handleNextButtonClick}
                        onPrintButtonClick={this.handlePrintButtonClick}
                        printBtnLabel={this.getLabelPrintBtn()}
                        printBtnActive={this.validateWarrantiesList()}
                        nextBtnLabel={"Распечатайте гарантии"}
                        loaderPrint={this.state.loaderPrint}
                        loaderNextBtn={this.state.loaderNextBtn}
                    />
                </div>
            </div>
        )
    }

    renderList() {
        const {warranties} = this.state;

        if (warranties.length) {
            return (
                <div className="box-body">
                    <div className="card">
                        <Table
                            data={warranties}
                            columns={planColumns}
                            getRowCode={item => item.planItemId}
                            selectMode="multiple"
                            selectedRows={Object.values(this.state.selectedRows)}
                            prepareDataItem={this.prepareWarranty}
                            onSelect={this.onSelectWarranty}
                            onChange={this.changeFieldsWarranties}
                            counterMin={0}
                        />
                    </div>
                </div>
            );
        }
        else return (
            <div className="choice__content--center">
                <p className="note">Нет завершённых услуг</p>
            </div>
        )
    }

    prepareWarranty = ({item: warranty}) => {
        if(warranty.target.measure === 'TOOTH') {
            warranty.serviceName = `${warranty.serviceName} (зуб ${warranty.target.name})`;
        }
        else {
            warranty.serviceName = `${warranty.serviceName} (${warranty.target.name})`;
        }
    };

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.handlerLoader)
        ServerCommand
            .get('warranty/list-for-visit', {visitId: this.props.visitId})
            .then(response => {
                let newState = {
                    warranties: response.warranties
                };

                let selectedRows = Helper.clone(this.state.selectedRows);

                if (response.warranties.length) {
                    response.warranties.forEach(warranty => {
                        if (warranty.duration.length === 0 || warranty.shelfLife.length === 0 || warranty.successChance.length === 0) {
                            selectedRows[warranty.planItemId] = warranty.planItemId;
                            newState.arePrinted = true;
                        }
                    })
                }

                newState.selectedRows = selectedRows;

                this.setState(newState);
            });
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.handlerLoader)
    }

    handlePrintButtonClick = () => {

        if(!this.validateWarrantiesList()) return;

        this.setState({loaderPrint: true})

        let data = {
            visitId: this.props.visitId
        };
        this.state.warranties.forEach(warranty => {
            if(this.state.selectedRows[warranty.planItemId] !== undefined) {
                if (data.warranties === undefined) {
                    data.warranties = [];
                }
                data.warranties.push(warranty)
            }
        });

        const command = new ServerCommand('warranty/generate-documents', data);
        command.exec().then(response => {
            Printer.print([response.document]).then(
                () => this.setState({arePrinted: true, loaderPrint: false})
            );
        });
    };

    handleNextButtonClick = () => {
        this.setState({loaderNextBtn: true})
        const command = new ServerCommand('visit/set-next-state', {visitId: this.props.visitId});
        command.exec().then(() => {
            this.props.goToNextStep()
            this.setState({loaderNextBtn: false})
        });
    };

    onSelectWarranty = (listItemId, itemId) => {
        let selectedRows = Helper.clone(this.state.selectedRows);

        if (selectedRows[itemId] !== undefined) {
            delete selectedRows[itemId];
        } else {
            selectedRows[itemId] = itemId;
        }

        this.setState({selectedRows});
    };

    changeFieldsWarranties = (rowCode, cellCode, value) => {
        const warranties = Helper.clone(this.state.warranties).map(warranty => {
            if (warranty.planItemId === rowCode) {
                if (cellCode === 'successChance') {
                    if (+value <= 100) {
                        warranty[cellCode] = value;
                    }
                } else {
                    warranty[cellCode] = value;
                }
            }
            return warranty
        });

        this.setState({warranties});

    };

    validateWarrantiesList() {

        let selectedRows = Helper.clone(this.state.selectedRows);
        let result = false;

        if (Object.values(selectedRows).length > 0) {
            result = true;

            this.state.warranties.forEach(warranty => {
                if (selectedRows[warranty.planItemId] !== undefined) {
                    if ( warranty.duration.length === 0 || warranty.shelfLife.length === 0 || warranty.successChance.length === 0) {
                        result = false;
                    }
                    if (warranty.duration.length > 0 && warranty.shelfLife.length > 0) {
                        result = true;
                    }
                    if (+warranty.successChance > 0) {
                        result = true;
                    }
                }
            })
        }

        return result;
    }

    getLabelPrintBtn = () => {
        if (Object.keys(this.state.selectedRows).length === 0) {
            return 'Выберите услуги'
        }
        if (this.validateWarrantiesList()) {
            return 'Пациенту на подпись, вклеить в карту, отдать пациенту'
        } else {
            return 'Укажите гарантию и срок службы или успешность лечения для выбранных услуг'
        }
    };

    validNextBtn() {

        let selectedRows = Object.values(Helper.clone(this.state.selectedRows));

        let result = true;

        if (selectedRows.length > 0) {
            result = this.state.arePrinted;
        }
        return result
    }

    handlerLoader(event) {
        const reason = event.reason;
        if (reason.type === 'server_request_error') {
            this.setState({loader: false})
        }
    }


}
