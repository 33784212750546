import styled from "styled-components";

interface PropsSwitcher {
    isDisabled: boolean,
    labelPosition: 'left' | 'right'
}

export const Switcher = styled.label.attrs({className: 'switcher'})<PropsSwitcher>`
    user-select: none;
    display: flex;
    height: 26px;
    align-items: center;
    margin-left: ${props => props.labelPosition === 'right' ? '35px' : '0'};
    justify-content: flex-start;
`;

export const SwitcherInput = styled.input.attrs({className: 'SwitcherInput', type: 'checkbox'})`
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 20px;
`;

interface ISwitcher {
    active?: boolean,
    isDisabled: boolean,
    labelPosition?: 'left' | 'right',
    fontSize?: string
}

export const SwitcherText = styled.span<ISwitcher>`
    position: relative;
    padding: ${props => props.labelPosition === 'left' ? '0 60px 0 0' : '0 0 0 60px'} ;
    height: 26px;
    cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
    display: flex;
    align-items: center;
    font-size: ${props => !!props.fontSize ? props.fontSize : '16px'};
    &:before {
        content: '';
        position: absolute;
        cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};
        top: 0;
        left: ${props => props.labelPosition === 'left' ? 'auto' : 0};
        right: ${props => props.labelPosition === 'right' ? 'auto' : 0};
        width: 48px;
        height: 26px;
        border-radius: 14px;
        transition: .2s;
        background-color: ${props => props.isDisabled ? props.theme.colors.secondary : props.active ? props.theme.colors.info : 'transparent'};
        border: solid 2px ${props => props.isDisabled ? props.theme.colors.secondary : props.active ? props.theme.colors.info : props.theme.colors.borderGray};
    }
    &:after {
        content: '';
        position: absolute;
        cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};
        top: 2px;
        left: ${props => props.labelPosition === 'left' ? 'auto' : props.active ? '24px' : '2px'};
        right: ${props => props.labelPosition === 'right' ? 'auto' : props.active ? '2px' : '24px'};
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: ${props => props.isDisabled ? props.theme.colors.white : '#FFF'};
        box-shadow: ${props => props.active ? '0 1px 1px 2px rgba(25, 60, 127, 0.25)' : '0 1px 1px 2px rgba(170, 174, 178, 0.25)'};
        transition: .2s;
    }
`;
