import * as React from 'react'
import {WrapTable} from "../styledStages";
import HeaderStages from "./HeaderStages";
import ItemStages from "./ItemStages";
import {Stage} from "../../../containers/TreatmentPlan/types";
import {PlanItemsStatuses} from "../../../containers/TreatmentPlansConstructor/types";
import {ObjServicesGroup} from "../typesStages";
import update from 'immutability-helper';
import {useCallback} from "react";
import StagedPlan from "../../../containers/TreatmentPlan/StagedPlan";
import _ from 'underscore';
import PlanAddition from "./PlanAddition";

interface IStagesTable {
    stages: Stage[],
    stagedPlan: StagedPlan
    itemsStatuses: PlanItemsStatuses | null,
    servicesGroups: ObjServicesGroup,
    setStages: (stages: Stage[]) => void,
    planAddition?: Stage,
    requiredDurationStages: number[]
}

export default function StagesTable({stages, stagedPlan, itemsStatuses, servicesGroups, setStages, planAddition, requiredDurationStages}: IStagesTable) {

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard = stages[dragIndex]
            setStages(
                update(stages, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }),
            )
        },
        [stages],
    )

    const newStages = stages.filter(elem => !elem.isPlanAddition);

    return (

        <WrapTable>
            <HeaderStages confirmChanges={stagedPlan.confirmChanges}
                          hasUnconfirmedChanges={stagedPlan.hasUnconfirmedChanges()}/>
            {newStages.map((stage, index: number) => {
                const newPlanItems = _.where(stagedPlan.getItems(), {stageId: stage.id})
                return <ItemStages key={stage.id}
                                   stagedPlan={stagedPlan}
                                   stage={stage}
                                   stages={stages}
                                   index={index}
                                   planItems={newPlanItems}
                                   itemsStatuses={itemsStatuses}
                                   servicesGroups={servicesGroups}
                                   setStages={setStages}
                                   requiredDurationStage={requiredDurationStages.some(req => req === stage.id)}
                                   moveCard={moveCard}/>
            })}
            {!!planAddition && <PlanAddition stage={planAddition}
                                             stages={stages}
                                             planItems={_.where(stagedPlan.getItems(), {stageId: planAddition.id})}
                                             itemsStatuses={itemsStatuses}
                                             servicesGroups={servicesGroups}
                                             stagedPlan={stagedPlan}
                                             setStages={setStages}/>}
        </WrapTable>
    )
}
