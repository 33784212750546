import React, {Dispatch, SetStateAction} from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonTextFieldOption} from "../../data";
import {WrapPaymentCreationForm} from "../../../../PatientCard/Section/Patient/styledPatient";
import TextField from "../../../../../components-ui/Inputs/TextField/TextField";
import useLoadUser from "../../../../../common/hooks/useLoadUser";
import ResultSearchPatient from "../../../../../components/ResultSearchPatient/ResultSearchPatient";
import Box from "../../../../../components-ui/Layout/Box";

interface Props {
    setUserId: Dispatch<SetStateAction<number | undefined>>
}

const FilterUser = ({setUserId}: Props) => {

    const {
        user,
        ref,
        patientList,
        displayPatientList,
        loaderPatientList,
        onChangeSearchBar,
        onClickPatient,
        onResetSearch
    } = useLoadUser({setUserId: setUserId})

    return (
        <Label labelText="Сотрудник" isRequired={true} {...commonLabelOption}>
            <WrapPaymentCreationForm>
                <Box position="relative">
                    <TextField
                        {...commonTextFieldOption}
                        value={user}
                        placeholder="Поиск сотрудника по ФИО и телефону"
                        isSearch={true}
                        onChange={onChangeSearchBar}
                        isReset={!!user}
                        onClickReset={onResetSearch}
                    />
                    <ResultSearchPatient patientList={patientList}
                                         displayPatientList={displayPatientList}
                                         loaderPatientList={loaderPatientList}
                                         onClickPatient={onClickPatient}
                                         minWidth="500px"
                                         ref={ref}/>
                </Box>

            </WrapPaymentCreationForm>
        </Label>
    );
};

export default FilterUser;
