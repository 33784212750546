import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {
    GroupServices,
} from '../../../../../../../../LaboratoryServiceRatesPage/containers/LaboratoryRatesTable/hooks/useLaboratoryRatesTable';
import Box from '../../../../../../../../../components-ui/Layout/Box';
import TextField from '../../../../../../../../../components-ui/Inputs/TextField/TextField';
import Tabs from '../../../../../../../../../components-ui/Navigation/Tabs/Tabs';
import {Salary} from '../../../../AddingEditingEmployee/types';
import {LaboratoryService} from '../../../../../../../../LaboratoryPricelistPage/types';

interface Props {
    servicesGroups: GroupServices[];
    rates: Pick<Salary, 'services' | 'group_services'>;
    setRates: Dispatch<SetStateAction<Pick<Salary, 'services' | 'group_services'>>>;
}

interface InputProps {
    item: LaboratoryService;
    rates: Pick<Salary, 'services' | 'group_services'>;
    setRates: Dispatch<SetStateAction<Pick<Salary, 'services' | 'group_services'>>>;
    isGroup: boolean;
}

const InputForGroupsAndServices = ({item, rates, setRates, isGroup}: InputProps) => {
    const keyType = isGroup ? 'groups' : 'services';

    const prevRateType = rates.group_services?.[keyType]?.[item.id]?.ratePercent ? "ratePercent" : "rate"

    const [switchType, setSwitchType] = useState<'rate' | 'ratePercent'>(prevRateType);
    const [rateValue, setRateValue] = useState(rates.group_services?.[keyType]?.[item.id]?.[switchType] ?? '');
    //TODO стейты rateValue и switchType не подхватывают новый объект rates при смене шаблона
    useEffect(() => {
        setRates(prevState => ({
            ...prevState,
            group_services: {
                ...prevState.group_services,
                [keyType]: {
                    ...prevState.group_services?.[keyType],
                    [item.id]: {
                        [switchType]: rateValue,
                    },
                },
            },
        }));
        if (switchType === "ratePercent" && rateValue > 100) {
            setRateValue(100)
        }
    }, [rateValue, switchType]);

    const groupId = item.groupId ?? 0

    return <Box padding='1px 19px' display='flex' gap='8px' alignItems='center'>
        <TextField widthInput='120px' size='min' isNumber
                   placeholder={rates.group_services?.groups?.[groupId]?.[switchType] ?? ""}
                   value={rateValue}
                   onChange={setRateValue}
        />
        <Tabs
            variant='primary'
            widthTab='36px'
            size='lg'
            tabs={[
                {code: 'rate', name: 'Р'},
                {code: 'ratePercent', name: '%'},
            ]}
            activeTab={switchType}
            onChange={setSwitchType}
        />
    </Box>;
};

const WrapForRates = ({servicesGroups, rates, setRates}: Props) => {
    return (
        <>
            {servicesGroups.map((item) => {
                return (
                    <>
                        {!!item.id && <InputForGroupsAndServices item={item} rates={rates} setRates={setRates} isGroup />}
                        {(item.isOpen && item.groups && item.groups.length > 0) &&
                            <WrapForRates servicesGroups={item.groups as GroupServices[]}
                                          rates={rates}
                                          setRates={setRates}
                            />
                        }
                        {item.isOpen && item.services?.map(service => {
                            return (
                                <InputForGroupsAndServices item={service}
                                                           rates={rates}
                                                           setRates={setRates}
                                                           isGroup={false} />
                            );
                        })}
                    </>
                );
            })}

        </>
    );
};

export default WrapForRates;
