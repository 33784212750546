import React, {useContext, useEffect, useState} from "react";
import SVG from "../../../../common/helpers/SVG";
import {ContextSlideModal, PropsSlideModal} from "../../types/slideModals";
import './SlideModal.scss'

export const SlideModalContext = React.createContext({} as ContextSlideModal);
export const useSlideModal = () => useContext(SlideModalContext);

export const SlideModal = ({slideModal, idModal, methods} : PropsSlideModal) => {
    const {_setMinimizeParams, _minimizeSlideModal, _removeSlideModal, _maximizeSlideModal, _removeMinimizeParams, showSlideModal} = methods;
    const [isHidden, setIsHidden] = useState(true);
    const [isClose, setIsClose] = useState(true)
    useEffect(() => {
        if (slideModal.isMinimized && !isHidden) {
            const timeOutId = setTimeout(() => {
                setIsHidden(true)
            }, 350)
            return () => clearTimeout(timeOutId)
        } else if (!slideModal.isMinimized && isHidden) {
            setIsHidden(false)
        }
    }, [slideModal.isMinimized])


    const isScrollable = slideModal.isScrollable === undefined ? true : slideModal.isScrollable;

    const contentComponent = React.cloneElement(slideModal.component, slideModal);
    const isHiddenClass = isHidden ? 's-slide-modal-wrap--hidden' : '';
    const isMinimizedClass = slideModal.isMinimized ? 's-slide-modal-wrap--minimized' : '';

    return (
        <SlideModalContext.Provider value={{
            setMinimizeParams: params => _setMinimizeParams(idModal, params),
            closeSlideModal: () => !!slideModal.labelParams ? _minimizeSlideModal(idModal) : _removeSlideModal(idModal),
            removeSlideModal: () => _removeSlideModal(idModal),
            maximizeSlideModal: () => _maximizeSlideModal(idModal),
            isMinimized: slideModal.isMinimized,
            labelParams: slideModal.labelParams,
            removeMinimizeParams: () => _removeMinimizeParams(idModal),
            showSlideModal,
            setIsClose,
            isClose
        }}>
            <div className={`s-slide-modal-wrap ${isHiddenClass} ${isMinimizedClass}`} style={{
                zIndex: !!slideModal.sort ? slideModal.sort + 40 : 40
            }} data-idx={slideModal.sort}>
                <div className={`s-slide-modal ${slideModal.isMinimized ? 's-slide-modal-right' : 's-slide-modal-left'}`} style={{
                    width: !!slideModal.widthDialog ? slideModal.widthDialog : '1360px',
                    maxWidth: !!slideModal.widthDialog ? slideModal.widthDialog : '1360px',
                    minWidth: !!slideModal.minWidthDialog ? slideModal.minWidthDialog : 'auto'
                }}>
                    {isClose && (
                        <button type="button" className="s-close-button" onClick={() => {
                            if (!!slideModal.labelParams) {
                                _minimizeSlideModal(idModal)
                            } else {
                                _removeSlideModal(idModal)
                            }
                        }}>
                            <SVG name="close"/>
                        </button>
                    )}
                    {isScrollable ? <div className="s-slide-modal-scroll-area" children={contentComponent} /> : contentComponent}
                </div>
            </div>
        </SlideModalContext.Provider>
    )
}

export default SlideModal
