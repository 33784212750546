import Helper from "../../common/helpers/main";
import ReactDOM from "react-dom";
import {
    IFieldsRequired,
    IFieldsValues,
    ISelectedDiagnosis, PFields, ResponseItemsFields, ResponseTemplatesList,
    ResponseTypeCaseHistory, SCaseHistory,
    TypesCaseHistory
} from "./typesCaseHistory";
import _ from "underscore";

export const types = ['visit', 'plan'] as TypesCaseHistory[];

/** Наполняем зубами табы зубов*/
export const getFilledTeeth = (fieldsRequired: IFieldsRequired, fieldsValues: IFieldsValues, selectedDiagnosis: {[key: string] : ISelectedDiagnosis[]}) => {
    let filledTeeth = [] as any;
    Helper.forEachObj(fieldsRequired, (fields: any, nameFields: any) => {
        let success = true;
        const fieldValue = fieldsValues[nameFields];
        const requiredFieldSelected = selectedDiagnosis[nameFields];
        if (fieldValue !== undefined) {
            if (!!fieldValue) {
                fields.forEach((requiredFieldName: any) => {
                    const requiredFieldValue = fieldValue[requiredFieldName];
                    const valid = (typeof requiredFieldValue === 'undefined') || requiredFieldValue.length < 3;
                    if (requiredFieldName === 'diagnosis') {
                        if (requiredFieldSelected === undefined) {
                            if (valid) success = false;
                        } else {
                            if (requiredFieldSelected.length === 0) {
                                if (valid) success = false;
                            }
                        }
                    } else {
                        if (valid) success = false;
                    }

                });
            } else if (fields.length === 0) success = false;

            if (success) {
                //nameFields = nameFields.split('_');
                if (nameFields[1].length <= 2) {
                    filledTeeth.push(nameFields);
                } else {
                    filledTeeth.push(nameFields);
                }
            }
        }
    });
    return filledTeeth
};

/** Подготавливаем зубы к отправке в компонент ToothSelect*/
export const prepareTargetTooth = (caseHistory: ResponseTypeCaseHistory) => {
    if (!!caseHistory) {
        return caseHistory.records.map(record => record.target);
    } else {
        return [];
    }
};

/** добавляем обязательность полей*/
export const addRequiredFields = (itemsFields: {[key: string]: ResponseItemsFields}, fieldsRequired: IFieldsRequired, type: string, target: string) => {
    itemsFields = Helper.clone(itemsFields);
    fieldsRequired = Helper.clone(fieldsRequired);

    Helper.forEachObj(itemsFields, (item: ResponseItemsFields, key: string) => {
        itemsFields[key].required = false;
        itemsFields[key].isError = false;
        itemsFields[key] = item;
    });
    if (fieldsRequired[type + '_' + target] !== undefined) {
        fieldsRequired[type + '_' + target].forEach(field => itemsFields[field].required = true);
    }
    return itemsFields;
};

/** Проверяет, сохранена ли история болезни на сервере */
export const isCaseHistorySaved = (caseHistory: ResponseTypeCaseHistory | any) => {
    if (!('id' in caseHistory)) return false;
    return !caseHistory.records.some((record: any) => !('id' in record));
};

/* Создает снимок старых стейтов */
export const getSnapshot = (state: SCaseHistory) => Helper.clone({fieldsValues: state.fieldsValues});

export const resultValidateFields = (fieldsRequired: IFieldsRequired, fieldsValues: IFieldsValues, selectedDiagnosis: {[key: string] : ISelectedDiagnosis[]}) => {
    let result = [] as any;
    Helper.forEachObj(fieldsRequired, (fields: any, key: any) => {
        if (fieldsValues[key] === undefined) fieldsValues[key] = {};
        if (selectedDiagnosis[key] === undefined) selectedDiagnosis[key] = [];
        fields.forEach((field: any) => {
            if (fieldsValues[key][field] === '' || typeof fieldsValues[key][field] !== "string") {
                if (field === 'diagnosis') {
                    if (selectedDiagnosis[key].length === 0) {
                        result.push({typeAndNumber: key, fieldName: field});
                    }
                } else {
                    result.push({typeAndNumber: key, fieldName: field});
                }
            }
        })
    });

    return result;
};

/** Прокрутка к первому незаполненному полю */
export const scrollingFirstField = (firstErrorField: any) => {
    setTimeout(() => {
        let firstErrorFieldNode = ReactDOM.findDOMNode(firstErrorField) as any;
        if (!!firstErrorFieldNode) {
            firstErrorField.focus({preventScroll: true});
            firstErrorFieldNode.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        }
    }, 500);
};

export const filterValueDiagnosis = (item: ResponseTemplatesList, value: string, field: string, selectedDiagnosis: {[key: string] : ISelectedDiagnosis[]}) => {
    const newFilter = selectedDiagnosis[field] === undefined ? true : !selectedDiagnosis[field].some(element => element.id === item.id);
    return (item.text.toLowerCase().indexOf(value.toLowerCase()) !== -1 && newFilter);
};

export const filteredTemplates = (templatesList: ResponseTemplatesList[], relatedItem: ResponseTemplatesList) => {
    const relatedList = templatesList.filter((template: any) => relatedItem.relatedTemplates.some((relatedTemplate) => template.id === relatedTemplate.templateId));




    const relatedObj = {} as {
        [key: string]: ResponseTemplatesList[]
    };
    relatedList.forEach((element: any) => {
        if (relatedObj[element.field] === undefined) relatedObj[element.field] = [];
        relatedObj[element.field].push(element);
    });
    return relatedObj;
};

export const sortTemplateText = (a: ResponseTemplatesList, b: ResponseTemplatesList) => {
    const nameA = a.text.toLowerCase(), nameB = b.text.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0
};

export function sortItemsFields(itemsFields: ResponseItemsFields[] | any) {
    return itemsFields.sort((a: any, b: any) => b.required - a.required);
}

export function getFirstFieldName(itemsFields: {[key: string]: ResponseItemsFields}) {
    return sortItemsFields(Object.values(itemsFields))[0].name;
}

export function getFieldsValues(props: PFields | any) {
    let {visitTargetSelected, selectedDiagnosis, targetsHistory} = props;
    const typeToothSelected = (visitTargetSelected.length > 0) ? 'visit' : 'plan';
    const targetSelected = props[typeToothSelected + 'TargetSelected'];
    const fieldsValues = _.clone(props.fieldsValues);

    const getFieldsValue = (newFieldsValues: any) => {
        const res = _.clone(newFieldsValues);
        if (res !== undefined) {
            if (Object.keys(res).length) {
                _.each(res, (val: any, key) => {
                    res[key] = {
                        disabled: false,
                        text: !!val.text ? val.text : val
                    }
                })
            }
        }
        return res;
    };

    let toothFieldsValues = {} as any;
    if (!!fieldsValues[typeToothSelected + '_' + targetSelected[0]]) {
        toothFieldsValues = getFieldsValue(_.clone(fieldsValues[typeToothSelected + '_' + targetSelected[0]]) as any);
    }
    let selectedItems = _.clone(selectedDiagnosis[typeToothSelected + '_' + targetSelected[0]]);
    let toothTargetsHistory = _.clone(targetsHistory[typeToothSelected + '_' + targetSelected[0]]) as any;

    if (targetSelected.length > 1) {
        let fieldsNameValues = [] as string[];
        Object.values(fieldsValues).forEach((fieldValue: any) => {
            Object.keys(fieldValue).forEach(fieldName => fieldsNameValues.push(fieldName))
        });
        fieldsNameValues = _.uniq(fieldsNameValues);
        const isTargetsHistory = Object.keys(targetsHistory).length;
        let fieldsNameHistory = [] as string[];
        if (isTargetsHistory) {
            Object.values(targetsHistory).forEach((targetValue: any) => {
                Object.keys(targetValue).forEach(targetName => fieldsNameHistory.push(targetName))
            })
        }
        fieldsNameHistory = _.uniq(fieldsNameHistory);

        targetSelected.forEach((target: string, index: number) => {
            if (index !== 0) {
                if (fieldsNameValues.length) {
                    const currentFieldValue = getFieldsValue(fieldsValues[typeToothSelected + '_' + target]) as any;
                    fieldsNameValues.forEach((fieldName) => {
                        let disabled = false;
                        if (currentFieldValue !== undefined) {

                            if (toothFieldsValues !== undefined) {

                                if (currentFieldValue[fieldName] !== undefined) {

                                    if (toothFieldsValues[fieldName] !== undefined) {

                                        if (typeof currentFieldValue[fieldName].text !== "string" && typeof toothFieldsValues[fieldName].text) {
                                            currentFieldValue[fieldName].text.forEach((elem: any, idx: number) => {
                                                if (!_.isEqual(elem, toothFieldsValues[fieldName].text[idx])) disabled = true;
                                            })
                                        } else {
                                            if (currentFieldValue[fieldName].text !== toothFieldsValues[fieldName].text) disabled = true;
                                        }

                                    } else {
                                        if (currentFieldValue[fieldName].text.length > 0) disabled = true;
                                    }

                                } else {
                                    if (toothFieldsValues[fieldName] !== undefined) {
                                        if (toothFieldsValues[fieldName].text.length > 0) disabled = true;
                                    }
                                }

                            } else {

                                if (currentFieldValue[fieldName] !== undefined) {
                                    if (currentFieldValue[fieldName].text.length > 0) disabled = true;
                                }

                            }

                        } else {
                            if (toothFieldsValues !== undefined) {
                                if (toothFieldsValues[fieldName] !== undefined) {
                                    if (toothFieldsValues[fieldName].text.length > 0) disabled = true;
                                }
                            }
                        }

                        if (disabled) {
                            if (toothFieldsValues[fieldName] === undefined) toothFieldsValues[fieldName] = {};
                            toothFieldsValues[fieldName].disabled = disabled;
                            toothFieldsValues[fieldName].text = '';
                        }

                    })
                }

                let disabledDiagnosis = false;
                const currentDiagnosisSelected = _.clone(selectedDiagnosis[typeToothSelected + '_' + target]);
                if (selectedItems !== undefined) {
                    if (currentDiagnosisSelected !== undefined) {
                        if (selectedItems.length > 0) {
                            if (currentDiagnosisSelected.length > 0) {
                                if (selectedItems.length === currentDiagnosisSelected.length) {
                                    selectedItems.forEach((selectedItem: ISelectedDiagnosis) => {
                                        disabledDiagnosis = !(currentDiagnosisSelected.some((elem: ISelectedDiagnosis) => elem.id === selectedItem.id));
                                    })
                                } else {
                                    disabledDiagnosis = true;
                                }
                            } else {
                                disabledDiagnosis = true;
                            }
                        } else {
                            if (currentDiagnosisSelected.length > 0) disabledDiagnosis = true;
                        }
                    } else {
                        if (selectedItems.length > 0) disabledDiagnosis = true;
                    }
                } else {
                    if (currentDiagnosisSelected !== undefined) disabledDiagnosis = true;
                }
                if (disabledDiagnosis) {
                    if (toothFieldsValues.diagnosis === undefined) toothFieldsValues.diagnosis = {text: ''};
                    toothFieldsValues.diagnosis.disabled = disabledDiagnosis;
                    selectedItems = [];
                }

                if (isTargetsHistory) {
                    const currentTargetHistory = _.clone(targetsHistory[typeToothSelected + '_' + target]);
                    fieldsNameHistory.forEach((fieldName) => {
                        if (toothTargetsHistory !== undefined) {
                            if (currentTargetHistory !== undefined) {
                                if (toothTargetsHistory[fieldName] !== undefined) {
                                    if (currentTargetHistory[fieldName] !== undefined) {
                                        if (toothTargetsHistory[fieldName].length > 0) {
                                            if (currentTargetHistory[fieldName].length > 0) {
                                                const objCurrentTargetHistory = Helper.Array.toObject(currentTargetHistory[fieldName], 'date') as any;
                                                const newTargetHistory = [] as any;
                                                toothTargetsHistory[fieldName].forEach((targetHistory: any) => {
                                                    if (objCurrentTargetHistory[targetHistory.date] !== undefined) {
                                                        if (objCurrentTargetHistory[targetHistory.date].text === targetHistory.text) newTargetHistory.push(targetHistory);
                                                    }
                                                });
                                                toothTargetsHistory[fieldName] = newTargetHistory;
                                            } else {
                                                toothTargetsHistory[fieldName] = [];
                                            }
                                        } else {
                                            if (currentTargetHistory[fieldName].length > 0) toothTargetsHistory[fieldName] = [];
                                        }
                                    } else {
                                        toothTargetsHistory[fieldName] = [];
                                    }
                                } else {
                                    if (currentTargetHistory[fieldName] !== undefined) toothTargetsHistory[fieldName] = [];
                                }

                            } else {
                                toothTargetsHistory[fieldName] = [];
                            }
                        } else {
                            if (currentTargetHistory !== undefined) {
                                toothTargetsHistory = {};
                                if (currentTargetHistory[fieldName] !== undefined) {
                                    toothTargetsHistory[fieldName] = [];
                                }
                            }
                        }
                    })
                }
            }
        });
    }

    return {
        toothFieldsValues, selectedItems, toothTargetsHistory
    }


}
