import {Dispatch, SetStateAction, useEffect} from "react";
import {StateCallCenter} from "../../types/components";

interface Props {
    state: StateCallCenter
    setState: Dispatch<SetStateAction<StateCallCenter>>
}

const usePanelCalls = ({state, setState}: Props) => {
    const {calls} = state;
    const initialPanelCalls = () => {
        setState(prevState => ({
            ...prevState,
            panelCalls: prevState.calls.filter(call => {
                let isAnswered = true;
                if (!!call.answerTime) isAnswered = false;
                return call.incoming && !call.answerTime && isAnswered
            })
        }))
    }

    useEffect(() => initialPanelCalls(), [JSON.stringify(calls)])
}

export default usePanelCalls
