import React, {useState} from "react";
import {Divider} from "../../../../../../components-ui/DataDisplay/Divider";
import LabelTask from "../../../../../../containers/Tasks/components/UI/LabelTask";
import Helper from "../../../../../../common/helpers/main";
import useFilterCalls from "./hooks/useFilterCalls";
import FilterHistory from "./components/FilterHistory";
import TagsHistory from "./components/TagsHistory";
import StatusHistory from "./components/StatusHistory";
import SubTitleTask from "../../../../../../containers/Tasks/components/UI/SubTitleTask";
import Typography from "../../../../../../components-ui/DataDisplay/Typography/Typography";
import Loader from "../../../../../../components-ui/Feedback/Loader/Loader";
import Box from "../../../../../../components-ui/Layout/Box";
import {StateDetailed} from "../../hooks/useDetailed";
import { STaskList, STaskItem } from "../../../../../../containers/Tasks/style";
import {useSelector} from "react-redux";

interface Props {
    loaderHistory: boolean
    detailedState: StateDetailed
}

const DetailedHistory = ({loaderHistory, detailedState}: Props) => {
    const {historyData} = detailedState;
    const {completedCalls, users} = historyData;
    const [selectedFilter, setSelectedFilter] = useState('all')
    const filterCalls = useFilterCalls({selectedFilter, detailedState})
    const callerTypes = useSelector(state => state.common.callerTypes)
    const directions = useSelector(state => state.common.directions)
    if (loaderHistory) {
        return (
            <Box position="relative" width="100%" height="100px">
                <Loader visible={true}/>
            </Box>
        )
    }
    if (!!completedCalls.length) {
        return (
            <>
                <FilterHistory selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter}
                               detailedState={detailedState}/>
                {filterCalls.map((call, index) => {
                    return (
                        <span key={call.id + 'history'}>
                        <STaskList padding="0 0 28px">
                            <TagsHistory call={call}/>
                            <LabelTask label="Дата и время"
                                       children={Helper.Date.formatDate(call.startTime, 'DD MMMM в HH:mm')}/>
                            <StatusHistory call={call}/>
                            {!!call.userId && (
                                <LabelTask label="Администратор"
                                           children={Helper.getFio(users.find(user => user.id === call.userId) as any)}/>
                            )}
                            {!!call.callerType && (
                                <LabelTask label="Тип звонящего"
                                           children={callerTypes[call.callerType]}/>
                            )}
                            {(!!call.topics) && (
                                <LabelTask label="Тема" children={call.topics.filter(topic => topic !== 'all')
                                    .map(topic => directions[topic].name).join(', ')}/>
                            )}
                            {!!call.comment && (
                                <>
                                    <SubTitleTask children="Комментарий результата звонка" margin="28px 0 8px"/>
                                    <STaskItem>
                                        <Typography children={call.comment} variant="small"/>
                                    </STaskItem>
                                </>
                            )}
                        </STaskList>
                            {index !== filterCalls.length - 1 && (
                                <Divider/>
                            )}
                    </span>
                    )
                })}

            </>
        )
    } else {
        return (
            <Typography children="Нет завершённых звонков" fontStyle="italic" textAlign="center" padding="20px"/>
        )
    }


}

export default DetailedHistory
