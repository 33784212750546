import React from 'react';
import {useSelector} from "react-redux";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";

interface Props {
    visitState: string
}

export const VisitState = ({visitState}: Props) => {
    const visitStatesNames = useSelector(state => state.common.visitStatesNames);
    if (!visitStatesNames[visitState]) return null
    return (
        <LabelTask label="Текущий шаг приёма"
                   children={visitStatesNames[visitState]}
                   alignItems="flex-start"
        />
    );
};
