import {Props} from "../types";

export const getFontWeight = (props: Props) => {
    const {currentAs, fontWeight, variant} = props;
    if (!!fontWeight) {
        return fontWeight
    } else {
        switch (currentAs){
            case "span":
                return 'inherit'
            case "h2":
            case "h3":
            case "h4":
                return variant === "small-title" ? "bold" : '600'
            default:
                return 'normal'
        }
    }
}
