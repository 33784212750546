import ServerCommand from "../../../../common/server/server-command";
import {Dispatch, SetStateAction, useCallback} from "react";
import {ReturnCashParams, GetDirectionsBalanceResult, IStateCashReturn} from "../types";
import {useModal} from "../../../../containers/DialogsManager/containers/Modal/Modal";
import Kkm from "../../../../common/server/kkm";
import {useSelector} from "react-redux";
import {KkmResponse} from "../../../../common/types/server";
import {useErrorModal} from "../../../../components/Errors";
import {CreateCashParams} from "../../CashCreate/types";
import {KkmSetting} from "../../../../types";

interface Props {
    patientId: number | undefined
    userId: number | undefined
    loadingFromServer?: () => void
    setLoader: Dispatch<SetStateAction<boolean>>
    setKkmError: Dispatch<SetStateAction<string>>
    state: IStateCashReturn
    data: GetDirectionsBalanceResult | null
    kkmSetting: KkmSetting
}

const useSubmit = ({
                       patientId,
                       userId,
                       loadingFromServer,
                       setLoader,
                       setKkmError,
                       state,
                       data,
                       kkmSetting
                   }: Props) => {

    const {closeModal} = useModal()

    return useCallback(() => {
        let params = null
        switch (state.type) {
            case 'P':
                if (patientId == undefined) {
                    return;
                }
                params = {
                    type: state.type,
                    patientId,
                    returnSum: -state.value,
                    method: state.selectedPaymentMethod.value,
                    cashbox: state.selectedCashbox.value,
                    branchId: state.branch?.value,
                } as ReturnCashParams;

                break;
            case 'U':
                if (userId == undefined) {
                    return;
                }
                params = {
                    type: state.type,
                    userId,
                    expenditureId: state.selectedExpenditure?.value,
                    comment: state.comment,
                    returnSum: -state.value,
                    cashbox: state.selectedCashbox.value,
                    branchId: state.branch?.value
                } as ReturnCashParams;
                break;
            case 'C':
                params = {
                    type: state.type,
                    expenditureId: state.selectedExpenditure?.value,
                    comment: state.comment,
                    returnSum: -state.value,
                    method: state.selectedPaymentMethod.value,
                    cashbox: state.selectedCashbox.value,
                    branchId: state.branch?.value
                } as ReturnCashParams;
                break;
        }

        if (params) {
            setLoader(true);

            const command = new ServerCommand('payment/return', params);
            const kkm = new Kkm(kkmSetting)

            if (state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' && state.selectedKkm) {
                switch (state.type) {
                    case 'P':
                        // @ts-ignore
                        kkm.registerCheck(
                            1,
                            true,
                            +state.value,
                            0,
                            state.selectedCashbox.value === 'MAIN_BOX_OFFICE').then((response: KkmResponse) => {
                            console.log(response)

                            if (response.Status == 0) {

                                command.exec().then((paymentId) => {
                                    closeModal()
                                    if (loadingFromServer) loadingFromServer()
                                });

                                // const command = new ServerCommand('payment/save-print-check', {
                                //     checkNumber: response.CheckNumber,
                                //     sessionNumber: response.SessionNumber,
                                //     sessionCheckNumber: response.SessionCheckNumber,
                                //     url: response.URL,
                                //     idCommand: response.IdCommand,
                                //     paymentId: paymentId
                                // });
                                // command.exec().then(() => {
                                //
                                // })
                            } else {
                                setLoader(false);
                                setKkmError(response.Error)
                                console.log(response.Error)
                            }
                        }).catch((reason : string) => setKkmError(reason))
                        break
                    case 'U':
                    case 'C':
                        // @ts-ignore
                        kkm.paymentCash(state.value).then((response: KkmResponse) => {
                            if (response.Status == 0) {
                                command.exec().then((paymentId) => {
                                    closeModal()
                                    if (loadingFromServer) loadingFromServer()
                                });
                            } else {
                                setLoader(false);
                                setKkmError(response.Error)
                                console.log(response.Error)
                            }
                        }).catch(reason => setKkmError(reason))
                        break
                }
            } else {
                command.exec().then((paymentId) => {
                    closeModal()
                    if (loadingFromServer) loadingFromServer()
                });
            }

        }

    }, [
        patientId,
        userId,
        loadingFromServer,
        setLoader,
        state,
        data,
        kkmSetting
    ])

};

export default useSubmit;
