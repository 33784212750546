import {useSlideModal} from '../../../../../../../containers/DialogsManager/containers/SlideModal/SlideModal';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {RequestKkmAdd, RequestKkmEdit, HandsetDetail} from '../types';
import ServerCommand from '../../../../../../../common/server/server-command';
import {OptionSelect} from "../../../../../../../components-ui/Inputs/Select/typesSelect";
import Kkm from "../../../../../../../common/server/kkm";
import {useOptionsBranchesUser} from "../../../../../../../common/hooks/useOptionsBranchesUser";
import Helper from "../../../../../../../common/helpers/main";
import {User} from "../../../../../../../types";

interface Props {
    handsetId?: number;
    detail?: HandsetDetail
    refreshDataFromServer: () => void;
}

const useAddingEditingHandset = ({handsetId, refreshDataFromServer, detail}: Props) => {

    const {closeSlideModal} = useSlideModal();

    const optionsBranches = useOptionsBranchesUser()

    const [loadingPage, setloadingPage] = useState(false);

    const [loadingRequest, setLoadingRequest] = useState(false);

    const [state, setState] = useState<HandsetDetail>(detail ?? {
        id: 0,
        phoneNumber: null,
        selectedBranch: [],
        branches: [],
    });

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            selectedBranch: optionsBranches.filter(item => detail?.branches.includes(item.value)),
        }))
    }, [detail])

    const [validationState, setValidationState] = useState(false);

    const validate = () => {
        return !!state.phoneNumber && state.branches.length > 0;
    }

    const saveHandset = () => {
        setLoadingRequest(true);

        const data = {
            id: state.id,
            phoneNumber: state.phoneNumber,
            branches: state.branches,
        } as RequestKkmEdit;
        ServerCommand.post(
            `handset/${handsetId ? 'edit' : 'add'}`,
            data,
            () => {
                closeSlideModal();
                refreshDataFromServer();
            }).finally(() => {
            setLoadingRequest(false);
        });
    };

    const deleteHandset = () => {

        const data = {
            id: state.id
        };
        ServerCommand.post(
            `handset/delete`,
            data,
            () => {
                closeSlideModal();
                refreshDataFromServer();
            }).finally(() => {
            setLoadingRequest(false);
        });
    };

    return {
        loadingPage,
        optionsBranches,
        state,
        setState,
        validate,
        saveHandset,
        deleteHandset,
        loadingRequest,
        validationState,
        setValidationState,
    };
};

export default useAddingEditingHandset;
