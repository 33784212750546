import React, {useMemo} from "react";
import {PCalendarField, ValidateField} from "./typesCalendarField";
import Select from "../Select/Select";
import {useCalendarField} from "./helpers/useCalendarField";
import TextField from "../TextField/TextField";
import {WrapCalendarField} from "./styledCalendarField";

export const defaultValidateField = {day: false, month: false, year: false};
export type ValidateCalendarField = ValidateField;

export default function CalendarField({value, onChange, validateField = defaultValidateField, autoFocus = false, widthCalendar = '300px', menuPlacement} : PCalendarField) {

    const [date, handlers] = useCalendarField(value, onChange);
    const {day, month, year, options} = date;
    const {onChangeDay, onBlurDay, onChangeMonth, onChangeYear} = handlers;

    const widths = useMemo(() => {
        const splitWidthCalendar = +widthCalendar?.split('px')[0];
        let select = '';
        let textField = '';
        if (splitWidthCalendar >= 300) {
            textField = '71px'
            select = (splitWidthCalendar - 170) + 'px';
        } else {
            textField = '60px'
            select = (splitWidthCalendar - 135) + 'px';
        }
        return {
            select,
            textField
        }
    }, []);

    return (
        <WrapCalendarField widthCalendar={widthCalendar}>
            <TextField value={day}
                       autoFocus={autoFocus}
                       placeholder='День'
                       onChange={onChangeDay}
                       isError={validateField.day}
                       mask="99"
                       widthInput={widths.textField}
                       textAlignInput="center"
                       onBlur={onBlurDay}
            />
            <Select value={month}
                    menuPlacement={menuPlacement}
                    placeholder="Месяц"
                    options={options}
                    widthSelect={widths.select}
                    widthMenu="250px"
                    isError={validateField.month}
                    onChange={(option: any) => onChangeMonth(option)}/>
            <TextField value={year}
                       placeholder='Год'
                       onChange={onChangeYear}
                       isError={validateField.year}
                       widthInput={widths.textField}
                       textAlignInput="center"
                       mask="9999"
            />
        </WrapCalendarField>
    )
}
