import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const MinusBoldIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 4.8"} ref={ref}>
            <rect width="24" height="4.8" rx="1.2"/>
        </WrapIcon>
    );
});
