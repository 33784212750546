import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const PaymentsCheck = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 24"} ref={ref}>
            <path xmlns="http://www.w3.org/2000/svg" d="M12 0C14.1063 0 16.1757 0.554454 18.0001 1.60764C19.8242 2.661 21.339 4.17582 22.3924 5.99996C23.4455 7.82422 24 9.8936 24 12C24 15.1826 22.7357 18.235 20.4853 20.4852C18.2354 22.7356 15.1828 24 12 24C8.81736 24 5.76501 22.7357 3.51482 20.4853C1.26438 18.2354 0 15.1828 0 12C0 8.81736 1.26432 5.76501 3.51475 3.51482C5.65902 1.36999 8.53309 0.120205 11.5566 0.00822703L12 0ZM12 1.93548C6.44152 1.93548 1.93548 6.44152 1.93548 12C1.93548 17.5585 6.44152 22.0645 12 22.0645C17.5585 22.0645 22.0645 17.5585 22.0645 12C22.0645 6.44152 17.5585 1.93548 12 1.93548ZM13.4798 5C15.8835 5 17.8397 6.95621 17.8397 9.36C17.8397 11.6592 16.0481 13.5541 13.7795 13.7097L13.6792 13.714L13.6798 13.7467L11.8932 13.746V14.066L16.1731 14.0667C16.6334 14.0667 17.0197 14.3871 17.1206 14.817L17.1398 14.9266L17.1464 15.04C17.1464 15.5162 16.8026 15.9158 16.3418 15.9981L16.2242 16.0119L16.1731 16.0133L11.9192 16.013L11.9199 18.48C11.9199 18.9404 11.5994 19.3266 11.1696 19.4276L11.06 19.4468L10.9466 19.4533C10.4704 19.4533 10.0708 19.1096 9.98848 18.6487L9.97466 18.5312L9.97318 15.959L9.0533 15.96C8.59292 15.96 8.20668 15.6395 8.10573 15.2097L8.08655 15.1001L8.08 14.9867C8.08 14.5105 8.42376 14.1108 8.8846 14.0286L9.00212 14.0147L9.97318 14.013V13.693L8.97331 13.6933C8.51293 13.6933 8.12669 13.3729 8.02573 12.943L8.00655 12.8334L8 12.72C8 12.2438 8.34376 11.8442 8.80461 11.7619L8.92212 11.7481L8.97331 11.7467L9.97318 11.746V6.946L9.07997 6.94667C8.61959 6.94667 8.23335 6.62618 8.13239 6.19637L8.11322 6.08676L8.10666 5.97333C8.10666 5.49715 8.45043 5.09751 8.91127 5.01522L9.02879 5.0014L13.4798 5ZM11.8932 6.973V11.8H13.4532C14.7826 11.8 15.8664 10.7162 15.8664 9.38667C15.8664 8.13662 14.9069 7.09994 13.6843 6.98414L13.5234 6.97432L11.8932 6.973Z"/>
        </WrapIcon>
    );
});
