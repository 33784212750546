import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth76Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 14.74"} width={width || "22"} height={height || "14"} ref={ref}>
            <path d="M23.88,6.21c-.28-2.14-.71-2.88-1-3.12a.81.81,0,0,0-.5-.14,5.2,5.2,0,0,0-.69.07,3.58,3.58,0,0,1-2.51-.32C14.74,0,13.55.14,9.94,1.84c-1.45.7-2.65-.07-3.7-.76C4.81.15,3.46-.73,1,.94-.35,1.79-.18,5.85.66,9.37a6.61,6.61,0,0,0,1.13,2.69c1.21,1.44,3.35,1.47,8,1.13a7.55,7.55,0,0,1,3.75.67c1.33.53,2.84,1.14,7,.75a2.82,2.82,0,0,0,1.95-1.16c1.26-1.53,1.8-4.31,1.42-7.23Z"/>
        </WrapIcon>
    );
});
