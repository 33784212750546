import Echo from "laravel-echo"
import Helper from "../helpers/main";

window.Pusher = require('pusher-js');

export let echo = createEcho();

function createEcho() {
    const accessToken = window.localStorage.getItem('accessToken');
    const host = Helper.isDev() ? process.env.REACT_APP_DEV_SERVER : window.location.hostname;
    const protocol = Helper.isDev() ? 'http' : window.location.protocol;

    return new Echo({
        broadcaster: 'pusher',
        key: process.env.REACT_APP_PUSHER_APP_KEY,
        wsHost: host,
        wsPort: 6001,
        disableStats: true,
        forceTLS: false,
        authEndpoint: 'http://' + host + '/broadcasting/auth',
        auth: {
            headers: {
                'Authorization': 'Bearer ' + accessToken,
            },
        },
    });
}

export function reloadEcho() {
    echo.disconnect();
    echo = createEcho();
}
