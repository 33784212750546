import React from 'react'
import './Pricelist.scss'
import ServerCommand from "../../common/server/server-command";
import TableWithGroupsTree from "../../components/TableWithGroupsTree/TableWithGroupsTree";
import {Icon} from "../../components/Icons/Icons";
import Helper from "common/helpers/main";
import ProcedureMaterialsField from "./ProcedureMaterialsField";
import ServiceProceduresField from "./ServiceProceduresField";
import {prepareServicesFromServer} from "../../common/Service/Service";
import TurnkeyPrice from "../../components/Table/Elements/TurnkeyPrice";
import Arr from "../../common/helpers/Arr";
import CareCallDelayField from "./CareCallDelayField";
import {connect} from "react-redux";
import LoaderPage from '../../components/LoaderPage';

const servicesColumns = [
    {
        title: 'Услуга',
        code: 'name',
        searchable: true
    },
    {
        title: 'Код',
        code: 'code',
        searchable: true
    },
    {
        title: 'Количество',
        code: 'quantity',
    },
    {
        title: 'Цена',
        code: 'price',
        type: 'price'
    },
];

const servicesCases = {
    nominative: 'услуга',
    genitive: 'услуги',
    accusative: 'услугу',
    newNominative: 'новая',
    newGenitive: 'новой'
};

const procedureCases = {
    nominative: 'процедура',
    genitive: 'процедуры',
    accusative: 'процедуру',
    newNominative: 'новая',
    newGenitive: 'новой'
};

const methods = {
    addItem: 'service/add',
    addGroup: 'service/add-group',
    updateItem: 'service/update',
    updateGroup: 'service/update-group',
    deleteItem: 'service/delete',
    deleteGroup: 'service/delete-group'
};

class PriceList extends React.Component {

    state = {
        servicesGroups: null,
        services: null,
        procedures: null,
        selectedServiceId: 0,
        filterDate: new Date(),
        procedureFields: null,
        materials: null,
        priceType: null
    };

    componentDidMount() {
        this.loadData()
    }

    render() {

        if (this.state.services === null) {
            return <LoaderPage/>
        }

        return (
            <div className="box box--with-margin">
                <div className="box-body">
                    <div className="pricelist">
                        <p className="pricelist__header">Прейскурант</p>
                        <div className="card">

                            <div className="pricelist__service-tab">
                                <div className="pricelist__service-table">
                                    <TableWithGroupsTree
                                        groups={this.state.servicesGroups}
                                        columns={this.prepareServiceColumns()}
                                        data={this.state.services}
                                        searchPlaceholder="Поиск услуги"
                                        selectMode="single"
                                        prepareDataItem={this.prepareService}
                                        prepareDataNestedItem={this.prepareProcedure}
                                        editMode={this.props.user.canUpdatePriceList}
                                        clearSelection={this.clearSelection}
                                        updateData={this.loadServices}
                                        withFilter={true}
                                        filterDate={this.state.filterDate}
                                        onFilterDateChange={this.changeFilterDate}
                                        onFilterReset={this.resetFilterDate}
                                        scrollHeight={260}
                                        cases={servicesCases}
                                        methods={methods}
                                        sortGroups={false}

                                        itemFields={this.getServiceFields()}
                                        groupFields={this.getServiceGroupFields()}
                                        nestedItemFields={this.getProcedureFields()}
                                        prepareClonedItem={this.prepareServiceCopy}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderItemCodeIcon(item) {
        let icon;

        switch (item.measure) {
            case 'JAW':
                icon = <Icon icon="new-jaw"/>;
                break;
            case 'BOTH_JAWS':
                icon = <Icon icon="new-both_jaw"/>;
                break;
            case 'ORAL_CAVITY':
                icon = <Icon icon="new-oral_cavity"/>;
                break;
            case 'PRODUCT':
                icon = <Icon icon="new-product"/>;
                break;
            case 'SEGMENT':
                icon = <Icon icon="new-segment"/>;
                break;
            default:
                icon = <Icon icon="new-tooth"/>;
                break;
        }

        return icon
    }

    renderServiceProceduresField = fieldProps => {
        const procedures = this.state.procedures.map((procedure) => {
            this.state.priceType.map(type => {
                procedure['price_' + type.id] = procedure.prices.find(price => price.priceTypeId === type.id)?.price
            })
            return procedure
        });
        this.bindGroupsToProcedures(procedures);

        return (
            <ServiceProceduresField
                procedures={procedures}
                groups={this.state.servicesGroups[0].groups}
                procedureFields={this.getProcedureFields()}
                procedureCases={procedureCases}
                updateData={this.loadData}
                {...fieldProps}
            />
        );
    };

    renderProcedureMaterialsField = fieldProps => {
        return (
            <ProcedureMaterialsField
                materials={this.state.materials}
                groups={this.state.materialsGroups}
                {...fieldProps}
            />
        );
    };

    renderCareCallDelayField = fieldProps => {
        return (
            <CareCallDelayField {...fieldProps}/>
        );
    }

    getServiceFields() {
        const serviceFields = Helper.clone(this.state.serviceFields);

        /* --- поле привязки процедур --- */

        serviceFields.procedures = {
            name: 'procedures',
            required: false,
            title: 'Процедуры',
            type: 'custom',
            inSidebar: true,
            defaultValue: [],
            render: this.renderServiceProceduresField,
        };

        /* --- поле символьного кода --- */

        serviceFields.code.size = "small";
        serviceFields.code.isCleaningOnCopy = true;

        /* --- поля обазятельных и привязанных доп. услуг --- */

        const additionalServicesVariants = this.getAdditionalServicesVariants();

        Object.assign(serviceFields.requiredAdditionalServices, {
            defaultValue: [],
            type: 'multiple_enum',
            variants: additionalServicesVariants
        });

        Object.assign(serviceFields.relatedAdditionalServices, {
            defaultValue: [],
            type: 'multiple_enum',
            variants: additionalServicesVariants
        });

        serviceFields.careCallDelay.render = this.renderCareCallDelayField;
        serviceFields.careCallDelay.dontWrap = true;

        return [
            serviceFields.measure,
            serviceFields.code,
            serviceFields.name,
            serviceFields.activeFrom,
            serviceFields.isAdditional,
            serviceFields.isSeparate,
            serviceFields.isRequired,
            serviceFields.isRequiredFirst,
            serviceFields.requiredAdditionalServices,
            serviceFields.relatedAdditionalServices,
            serviceFields.careCallDelay,
            serviceFields.procedures,

            serviceFields.showToPatientSeparately,
            serviceFields.nameForPatient,
            serviceFields.noteForPatient,

            serviceFields.excludeFromSalary,
        ];
    }

    getServiceGroupFields() {
        const {serviceGroupFields} = this.state;

        serviceGroupFields.careCallDelay.render = this.renderCareCallDelayField;
        serviceGroupFields.careCallDelay.dontWrap = true;

        return [
            serviceGroupFields.name,
            serviceGroupFields.careCallDelay,
            serviceGroupFields.showToPatient,
            serviceGroupFields.articlesIds
        ];
    }

    getProcedureFields() {
        const procedureFields = Helper.clone(this.state.procedureFields);

        /*procedureFields.materials = {
            name: "materials",
            required: false,
            title: "Материалы",
            type: "custom",
            inSidebar: true,
            defaultValue: [],
            render: this.renderProcedureMaterialsField,
        };*/

        procedureFields.code.size = "small";
        procedureFields.code.isCleaningOnCopy = true;

        let result = [
            procedureFields.measure,
            procedureFields.code,
            procedureFields.name,
            procedureFields.price,
        ];

        this.state.priceType.map(type => {
            result.push({
                title: type.name,
                name: 'price_' + type.id,
                required: true,
                type: 'float',
                sort: result.length
            })
        })

        return result;
    }

    getAdditionalServicesVariants() {
        const {services} = this.state;
        return services.filter(service => service.isAdditional).map(service => {
            return {
                label: service.name,
                value: service.id
            };
        });
    }

    bindGroupsToProcedures = procedures => {
        const {services} = this.state;
        procedures = Helper.Array.toObject(procedures, 'id');

        services.forEach(service => {
            service.procedures.forEach(serviceProcedure => {
                const procedure = procedures[serviceProcedure.procedureId];
                if (!procedure.groupId) {
                    procedure.groupId = [];
                }

                procedure.groupId.push(service.groupId);
            });
        });

        Helper.forEachObj(procedures, procedure => {
            procedure.groupId = procedure.groupId ? Helper.Array.unique(procedure.groupId) : [0];

            if (procedure.groupId.length > 1) {
                procedure.groupId.push(0);
            }
        });
    };

    prepareServiceColumns = () => {
        let result = Helper.clone(servicesColumns);

        this.state.priceType.map(type => {
            result.push({
                title: type.name,
                code: 'price_' + type.id,
                type: 'price'
            })
        })

        return result
    }

    prepareService = ({item: service}) => {
        service.code = [this.renderItemCodeIcon(service), service.code];
        service.nestedItems = service.procedures;
        service.quantity = null;
        service.price = <TurnkeyPrice sum={service.price}
                                      turnkeyPrice={service.turnkeyPrice}
                                      turnkeyPriceParts={service.turnkeyPriceParts}
                                      services={Arr.toObject(this.state.services, 'id')}/>

        this.state.priceType.map(type => {
            service['price_' + type.id] = Helper.Price.format(service.prices.find(price => price.priceTypeId === type.id)?.price)
        })
    };

    prepareProcedure = ({item: procedure}) => {
        procedure.code = [this.renderItemCodeIcon(procedure), procedure.code];
        procedure.isPale = !procedure.defaultActive;
        procedure.price *= procedure.quantity;

        this.state.priceType.map(type => {
            procedure['price_' + type.id] = procedure.prices.find(price => price.priceTypeId === type.id)?.price * procedure.quantity
        })
    };

    clearSelection = () => {
        this.setState({selectedServiceId: 0})
    };

    loadData = (parts = null) => {
        console.log('load');
        return ServerCommand.get('service/price-list', {parts}).then(response => {
            const {parts} = response;
            const newState = {};

            if (parts.services) {
                newState.servicesGroups = this.transformServiceGroups(response.servicesGroups);
                newState.services = prepareServicesFromServer(
                    response.services,
                    response.servicesGroups,
                    !!response.procedures ? response.procedures : this.state.procedures,
                    response.directions,
                    response.servicesRelationsRules,
                    response.priceType
                );
                newState.serviceFields = response.serviceFields;
                newState.serviceGroupFields = response.serviceGroupFields;
            }

            if (parts.procedures) {
                newState.procedures = response.procedures;
                newState.priceType = response.priceType;
                newState.procedureFields = response.procedureFields;
            }

            if (parts.materials) {
                newState.materialsGroups = response.materialsGroups;
                newState.materials = response.materials;
            }

            this.setState(newState);
        });
    };

    loadServices = () => this.loadData(['services']);

    transformServiceGroups = groups => {
        return [{
            code: "ALL",
            groupId: 0,
            groups,
            id: 0,
            name: "Все группы",
            isOpened: true
        }];
    };

    prepareServiceCopy = serviceCopy => {
        serviceCopy.procedures.forEach(procedure => delete procedure.id);
    };

    changeFilterDate = (date) => {
        this.setState({filterDate: date});

        ServerCommand.get('service/list', {date: Helper.Date.formatDateForServer(date)}).then(response => {
            this.setState({
                services: response.list
            })
        })

    };

    resetFilterDate = () => {
        this.setState({filterDate: new Date()})
    };
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(PriceList)
