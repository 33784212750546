import {useMemo} from "react";
import {PropsUseShowFields} from "../types/hooks";

export const useShownFields = ({formFields, currentPatient, parentName, fields}: PropsUseShowFields) => {
    return useMemo(() => {
        let shownFields = formFields;
        if (!!currentPatient) {
            let newFields = [] as string[]
            const compositeFields = Object.values(fields).filter(field => field.type === 'composite')
            shownFields = shownFields.filter(fieldName => {
                let res = true;
                const findComposite = compositeFields.find(field => {
                    if (parentName !== "PreparationPatient") {
                        if (field.name === 'phone') return false
                    }
                    return !!field.subFields && field.subFields.some(subField => subField === fieldName)
                })
                if (!!findComposite) {
                    res = false
                    if (newFields.every(newField => newField !== findComposite.name)) newFields.push(findComposite.name)
                }
                return res
            })
            shownFields = [...shownFields, ...newFields]
        }
        return shownFields.filter(fieldName => !!fields[fieldName]).sort((fieldA, fieldB) => fields[fieldA].sort - fields[fieldB].sort)
    }, [currentPatient, formFields])
}
