import React from "react";
import {SItemTextPhone, STextPhone, STitleTextPhone, SWrapTextPhone} from "../../../../../style/phoneView";
import Helper from "../../../../../../../common/helpers/main";
import {usePatientField} from "../../../../PatientField";

export default function PhoneView() {
    const {composite, value} = usePatientField()

    console.log(value.phoneOwner)
    console.log(typeof value.phoneOwner)

    const getPhoneOwner = (phoneOwner: any) => {
        if (phoneOwner ===  undefined) {
            return 'контактный'
        }
        if (typeof phoneOwner === "string") {
            if (phoneOwner === 'self') {
                return 'личный'
            } else {
                return 'контактный'
            }
        }
        if (phoneOwner?.value === 'self') {
            return 'личный'
        } else {
            return 'контактный'
        }
    }

    if (!!composite) {
        return (
            <SWrapTextPhone>
                <SItemTextPhone>
                    <STitleTextPhone>
                        {getPhoneOwner(value.phoneOwner)}
                    </STitleTextPhone>
                    <STextPhone onClick={event => event.stopPropagation()}
                                href={`tel:${value.personalPhone}`}>
                        {Helper.Phone.format(value.personalPhone)}
                    </STextPhone>
                </SItemTextPhone>
                {!!value.additionalPhone && (
                    <SItemTextPhone>
                        <STitleTextPhone>контактный</STitleTextPhone>
                        <STextPhone onClick={event => event.stopPropagation()}
                                    href={`tel:${value.additionalPhone}`}>
                            {Helper.Phone.format(value.additionalPhone)}
                        </STextPhone>
                    </SItemTextPhone>
                )}
            </SWrapTextPhone>
        )
    } else {
        return null
    }

}
