import { IPatientField } from "../../PatientField"

export const fieldFIO = {
    group: 'basic',
    name: 'fio',
    required: true,
    title: 'ФИО',
    type: 'FIO',
    children: {
        lastName: {
            group: "basic",
            name: "lastName",
            required: true,
            title: "Фамилия",
            type: "string"
        },
        name: {
            group: "basic",
            name: "name",
            required: true,
            title: "Имя",
            type: "string"
        },
        secondName: {
            group: "basic",
            name: "secondName",
            required: false,
            title: "Отчество",
            type: "string"
        }
    }
} as IPatientField

export const filterFioName = (fieldName: string) => {
    return fieldName !== 'lastName' && fieldName !== 'name' && fieldName !== 'secondName'
}

export const someFioName = (fieldName: string) => {
    return fieldName === 'lastName' || fieldName === 'name' || fieldName === 'secondName'
}

export const fieldPhone = {
    group: "basic",
    name: "phone",
    required: true,
    title: "Телефон",
    type: "phone",
    children: {
        personalPhone: {
            group: "basic",
            name: "personalPhone",
            required: true,
            title: "Телефон",
            type: "phone"
        },
        phoneOwner: {
            group: "basic",
            name: "phoneOwner",
            required: true,
            title: "Владелец телефона",
            type: "enum"
        },
        additionalPhone: {
            group: "basic",
            name: "additionalPhone",
            required: false,
            title: "Резервный телефон",
            type: "phone"
        }
    }
} as IPatientField

export const filterPhoneName = (fieldName: string) => {
    return fieldName !== 'personalPhone' && fieldName !== 'phoneOwner' && fieldName !== 'additionalPhone'
}

export const somePhoneName = (fieldName: string) => {
    return fieldName === 'personalPhone' || fieldName === 'phoneOwner' || fieldName === 'additionalPhone'
}

export const fieldAddress = {
    group: "basic",
    name: "address",
    required: false,
    title: "Адрес проживания",
    type: "address",
    children: {
        personalCity: {
            group: "basic",
            name: "personalCity",
            required: false,
            title: "Город",
            type: "string"
        },
        personalStreet: {
            group: "basic",
            name: "personalStreet",
            required: false,
            title: "Улица",
            type: "string"
        },
        personalHome: {
            group: "basic",
            name: "personalHome",
            required: false,
            title: "Дом",
            type: "string",
        },
        personalHousing: {
            group: "basic",
            name: "personalHousing",
            required: false,
            title: "Корпус",
            type: "string"
        },
        personalApartment: {
            group: "basic",
            name: "personalApartment",
            required: false,
            title: "Квартира",
            type: "string"
        }
    }
} as IPatientField

export const filterAddressName = (fieldName: string) => {
    return fieldName !== 'personalCity' && fieldName !== 'personalStreet' && fieldName !== 'personalHome' && fieldName !== 'personalHousing' && fieldName !== 'personalApartment'
}

export const someAddressName = (fieldName: string) => {
    return fieldName === 'personalCity' || fieldName === 'personalStreet' || fieldName === 'personalHome' || fieldName === 'personalHousing' || fieldName === 'personalApartment'
}

