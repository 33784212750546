import styled from "styled-components";

export const SearchIconWrap = styled.div.attrs({className: 'SearchIconWrap'})`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    & > div {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: ${props => props.theme.colors.textGray};
        transition: fill .2s;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`
