import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonTextFieldOption} from "../../data";
import TextField from "../../../../../components-ui/Inputs/TextField/TextField";
import {IStateCashCreate} from "../../types";

interface Props {
    state: IStateCashCreate
    setState: Dispatch<SetStateAction<IStateCashCreate>>
    paymentSum: number
}

const InsuranceSum = ({state, setState, paymentSum}: Props) => {
    const handleInsuranceSum = useCallback((insuranceSum: string) => {
        setState(prevState => ({...prevState, insuranceSum}))
    }, [setState])

    return (
        <Label labelText="По ДМС" {...commonLabelOption}>
            <TextField
                {...commonTextFieldOption}
                value={state.insuranceSum}
                isNumber={true}
                min={0}
                onChange={handleInsuranceSum}
                max={paymentSum - +state.discount}
            />
        </Label>
    );
};

export default InsuranceSum;
