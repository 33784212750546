import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const CheckedIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 20"} ref={ref}>
            <path d="M20.61,0l-12,13L3.38,8.38,0,12l7.19,6.47L8.87,20l1.55-1.67C14.68,13.72,19.74,8.27,24,3.65Z"/>
        </WrapIcon>
    );
});
