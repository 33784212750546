import {ToothText, ToothSegment} from "../styledToothSelect";
import React from "react";

interface PToothContent {
    active: boolean,
    targetItem: any,
    setHoverSegment: any,
    status: any
}

export default function ToothContent({active, targetItem, setHoverSegment, status}: PToothContent) {
    if (targetItem.measure === "SEGMENT") {
        return <ToothSegment onMouseEnter={() => setHoverSegment(true)}
                             onMouseLeave={() => setHoverSegment(false)}
                             active={active}/>;
    } else {
        let name = targetItem.name;
        if (!!status) {
            if (status.group !== "HEALTHY") {
                name += ' ' + status.code
            }
        }
        return <ToothText active={active} children={name}/>;
    }
}
