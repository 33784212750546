import React, {useState} from "react";
import {useEditableField} from "../EditableField";
import {ValueEditableField} from "../typesEditableField";
import ServerCommand from "../../../common/server/server-command";
import SaveButtons from "./SaveButtons";
import TextField from "../../../components-ui/Inputs/TextField/TextField";

export default function FieldDefault() {
    const {field, patientId, setEditField} = useEditableField();
    const values = Object.values(field.values)[0] as ValueEditableField;
    const [value, setValue] = useState(values.value);
    const [valid, setValid] = useState(false);

    const onClickSave = () => {
        if (values.required && (!!values.mask ? value.indexOf('_') !== -1 || !value.length : !value.length)) {
            setValid(true)
            return;
        }
        const command = new ServerCommand('patient/edit', {id: patientId, [values.name]: value});
        command.exec().then(() => {
            field.onChange(value);
            setEditField(false);
        });
    }
    const commonProps = {} as any;
    if (!!values.mask) commonProps.mask = values.mask;

    return (
        <>
            <TextField {...commonProps}
                       value={value}
                       widthInput="290px"
                       autoFocus={true}
                       isError={valid}
                       onChange={(val) => {
                           setValue(val)
                           if (values.required && !!val.length) {
                               setValid(false)
                           }
                       }}
            />
            <SaveButtons onClickSave={onClickSave}/>
        </>
    )
}
