import React from "react";
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../../../containers/Tasks/data/commonPropsSComponent";
import _ from "lodash";
import Helper from "../../../../common/helpers/main";
import {User} from "../../../../types";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";
import {useSelector} from "react-redux";
import {TaskCallCenter} from "../../types/common";
import {SDoctorColor, STaskItem} from "../../../../containers/Tasks/style";

interface Props {
    task: TaskCallCenter
}

export const FreedTime = ({task}: Props) => {
    const doctors = useSelector(state => state.common.doctors);
    const branches = useSelector(state => state.common.allBranches)
    const activeBranches = useSelector(state => state.common.branches)
    return (
        <>
            <STaskItem>
                <Typography {...commonPropsTypography}
                            fontWeight="bold" margin="15px 0 5px"
                            children="Найденное время"/>
            </STaskItem>
            {_.map(task.payload.freedTime, (freedBranches, freedDate) =>
                _.map(freedBranches, (doctorsList, branchId) =>
                    <span key={branchId}>
                            <STaskItem>
                                <Typography {...commonPropsTypography}
                                            fontWeight="600" fontStyle="italic" margin="0 0 5px"
                                            children={`${Helper.Date.formatDate(freedDate, 'DD MMMM')}${activeBranches.length > 1 ? ', ' + branches[branchId].name : ''}`}/>
                            </STaskItem>
                        {_.map(doctorsList, (values, doctorId) => {
                            const doctor = _.clone(doctors).find((doctor: any) => doctor.id === +doctorId) as User
                            return values.map((times: any) => <LabelTask
                                    label={`с ${times.timeStart} до ${times.timeEnd}`}>
                                    <SDoctorColor color={doctor.color as string}/>
                                    <span>{Helper.getFio(doctor)}</span>
                                </LabelTask>
                            )
                        })}
                        </span>
                )
            )}


        </>
    )
}
