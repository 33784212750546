import React from 'react';
import {WrapLegendItem} from "../../../../../../../../style";
import Avatar from "../../../../../../../../../../components-ui/DataDisplay/Avatar";
import Typography from "../../../../../../../../../../components-ui/DataDisplay/Typography/Typography";
import Box from "../../../../../../../../../../components-ui/Layout/Box";
import {HandlersAverageCheck, StateAverageCheck} from "../../../../../types";
import {BarDataItem} from "../../../../../../../../../../components-ui/Charts/ChartBar";
import {useSelector} from "react-redux";
import useDirectionsColors from "../../../../../../../../../../common/hooks/useDirectionsColors";
import useHover from "../../../../../../../../../../common/hooks/useHover";
import AverageTooltip from "../../../components/AverageTooltip";
import { clinicInfo } from '../../../../../../../../data/widgetsInfo';
import _ from 'lodash'

interface Props {
    code: string
    index: number
    state: StateAverageCheck
    handlers: HandlersAverageCheck
    data: BarDataItem[]
}

const Item = ({code, index, state, handlers, data}: Props) => {
    const users = useSelector(state1 => state1.common.users)
    const directions = _.clone(useSelector(state1 => state1.common.directions))
    const directionsColors = useDirectionsColors(true);
    const [ref, hover] = useHover()
    directions.clinic = clinicInfo
    return (
        <Box display="flex"
             alignItems="center"
             margin="0 24px 0 0" key={code}
             onMouseEnter={() => handlers.handleHoverIndex(index)}
             onMouseLeave={() => handlers.handleHoverIndex(-1)}
             position="relative"
             ref={ref}
        >
            <WrapLegendItem
                color={state.currentTab === 'directions' ? directionsColors[code] || '#447ce6' : '#447ce6'}
                isHover={index === state.hoverIndex}>
                {state.currentTab === 'doctors' && <Avatar text={users[+code].fio[0]} size="22px"
                                                           bgColor={users[+code].color || '#447ce6'}/>}
                {state.currentTab !== 'doctors' &&
                <Avatar size="8px" bgColor={directionsColors[code] || '#447ce6'}/>}
            </WrapLegendItem>
            <Typography variant="small" cursor="default">
                {state.currentTab === 'doctors' ? users[+code].fio : directions[code].name}
            </Typography>
            {hover && <AverageTooltip index={index} state={state} data={data} positionOffsets={{top: `calc(100% + 8px)`, left: '0'}} />}
        </Box>
    );
};

export default Item;
