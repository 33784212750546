import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Box from '../../../../../components-ui/Layout/Box';
import TextField from '../../../../../components-ui/Inputs/TextField/TextField';
import useDebounce from '../../../../../common/hooks/useDebounce';
import {StateUserList} from '../../types';
import Select from '../../../../../components-ui/Inputs/Select/Select';
import {useSelector} from 'react-redux';

interface Props {
    state: StateUserList,
    setState: Dispatch<SetStateAction<StateUserList>>
}

const activeFilterOptions = [
    {
        label: "Работающие",
        value: "active"
    },
    {
        label: "Неработающие",
        value: "notActive"
    }
]


const EmployeesFilter = ({state, setState}: Props) => {

    const roles = useSelector(state => state.common.roles)

    const rolesOptions = Object.entries(roles).map(([key, value]) => ({
        value: key, label: value
    }))
    rolesOptions.unshift({value: "", label: "Все профессии"})

    const [role, setRole] = useState(rolesOptions[0])

    const [search, setSearch] = useState(state.searchQuery);

    const [activeFilter, setActiveFilter] = useState(state.activeFilter ? activeFilterOptions[0] : activeFilterOptions[1])

    const debounceSearch = useDebounce(search, 500)

    useEffect(() => {
        setState(prevState => (
            {...prevState,
                searchQuery: debounceSearch,
                activeFilter: activeFilter.value === "active",
                roleFilter: role.value
            }
        ))
    }, [debounceSearch, activeFilter, role])

    return (
        <Box display='flex' gap='0 16px' margin='22px 0'>
            <TextField isSearch
                       widthInput="410px"
                       placeholder='ФИО, телефон, E-mail, ИНН или снилс'
                       onChange={setSearch}
                       value={search}
            />
            <Select options={activeFilterOptions}
                    value={activeFilter}
                    variant="default"
                    onChange={setActiveFilter}
            />
            <Select options={rolesOptions}
                    value={role}
                    variant="default"
                    onChange={setRole}
            />
        </Box>
    );
};

export default EmployeesFilter;
