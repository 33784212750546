import React from 'react';
import {useNewVisitForm} from "../NewVisitForm";
import Paper from "../../../containers/UI-Collection/Elements/Surfaces/Paper";
import {InfoBox, InfoBoxWrap} from "../../../components-ui/styledComponent/common/wraps";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import PatientField from '../../../containers/PatientField';

export default function ExtraNewVisit() {
    const { form, onChangeFieldBasic, fields} = useNewVisitForm();

    return (
        <Paper minWidth="100%" margin="0 0 24px" zIndex={12} isHidden={false}>
            <InfoBox>
                <Typography variant="min-title" padding="25px 20px 11px">Дополнительно</Typography>
                <InfoBoxWrap>
                    <PatientField field={fields.source}
                                  currentPatient={form.currentPatient}
                                  value={form.basic.source}
                                  onChangeField={onChangeFieldBasic}
                    />
                </InfoBoxWrap>
            </InfoBox>
        </Paper>
    )
}
