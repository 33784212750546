import React from "react";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import {usePatientField} from "../containers/PatientField";
import useDebounce from "../../../common/hooks/useDebounce";
import useSearchPatientList from "../../../common/hooks/useSearchPatientList";
import useDisplayPatientList from "../hooks/useDisplayPatientList";
import {PatientValues} from "../types/common";
import ResultSearchPatient from "../../../components/ResultSearchPatient/ResultSearchPatient";
import useChangePatientList from "../hooks/useChangePatientList";
import useWidthInput from "../hooks/useWidthInput";
import Helper from "../../../common/helpers/main";

export default function Phone() {
    const {
        field,
        value,
        currentPatient,
        labelRef,
        loadingPatient,
        setForm,
        parentName,
        error,
        currentTab
    } = usePatientField()
    const widthInput = useWidthInput(field)
    const [displayPatientList, setDisplayPatientList] = useDisplayPatientList(labelRef);
    const debouncedValue = useDebounce(value, 500);
    const [patientList, loaderPatientList] = useSearchPatientList({
        value: debouncedValue,
        nameField: field.name,
        parentPatientId: currentPatient?.id,
        select: ['personalPhone', 'name', 'lastName', 'secondName', 'id', 'personalBirthday', 'personalGender', 'phoneOwner']
    });
    useChangePatientList({patientList})

    const onChangePhone = (textFieldValue: string) => {
        const replaceValue = Helper.Phone.formatServer(textFieldValue);
        if (replaceValue.length >= 2) {
            if (!displayPatientList) setDisplayPatientList(true)
        } else {
            if (displayPatientList) setDisplayPatientList(false)
        }
        if (!!setForm) {
            setForm((prevState: any) => {
                const prepareState = (state: any) => {
                    let phoneOwner = state.basic.phoneOwner;
                    if (replaceValue.length < 11) {
                        phoneOwner = null
                    } else if (!!currentPatient && currentPatient.personalPhone !== replaceValue) {
                        phoneOwner = null
                    }
                    return {
                        ...state,
                        relations: {
                            ...state.relations,
                            relationsList: replaceValue.length < 11 ? [] : state.relations.relationsList
                        },
                        basic: {
                            ...state.basic,
                            personalPhone: replaceValue,
                            phoneOwner: phoneOwner,
                            validationFields: {
                                ...state.basic.validationFields,
                                personalPhone: false
                            }
                        }
                    }
                }
                if (!!currentTab) {
                    prevState[currentTab] = prepareState(prevState[currentTab])
                } else {
                    if (parentName === "NewVisitForm") {
                        prevState.relations.relations = replaceValue.length < 11 ? {} : prevState.relations.relations
                    }
                    prevState = prepareState(prevState)
                }

                return {
                    ...prevState
                }
            })
        }
    }

    const onClickPatient = (patient: PatientValues) => {
        if (!!loadingPatient) loadingPatient(patient);
        setDisplayPatientList(false);
    }

    const commonProps = {} as any;

    if (field.name !== 'additionalPhone') {
        commonProps.autoFocus = true
    }

    return (
        <TextField onChange={onChangePhone}
                   value={value}
                   mask={field.mask}
                   key={field.name + 'phone'}
                   widthInput={widthInput}
                   isError={error as boolean}
                   nameInput={field.name}
                   {...commonProps}
                   additionalContent={<ResultSearchPatient patientList={patientList}
                                                           displayPatientList={displayPatientList}
                                                           loaderPatientList={loaderPatientList}
                                                           minWidth='470px'
                                                           onClickPatient={onClickPatient}/>}
        />
    )
}
