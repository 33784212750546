import React, {FC} from 'react'
import './style/App.scss'
import Helper from "../../common/helpers/main";
import Theme from "../../common/Theme/Theme";
import useApp from "./hooks/useApp";
import Routers from "../Routers/Routers";
import {useDevice} from "../../common/hooks/useDevice";
import {createGlobalStyle} from "styled-components";

const App: FC = () => {

    const device = useDevice()

    //TODO  вынести в отдельный компонент.
    const GlobalStyle = createGlobalStyle`
      html {
        font-size: ${device === "tablet" ? "14px" : device === "mobile" ? "12px" : "16px"};
      }
    `

    const ErrorModal = useApp()

    return (
        <Theme>
            <GlobalStyle />
            <div className={`App${Helper.isDev() ? ' App--development' : ''}`}>
                <Routers/>
                <div id="modal-root"/>
                {ErrorModal}
            </div>
        </Theme>
    );
}

export default App
