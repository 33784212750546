/**
 * Проверяет, заполнена ли строка
 * @param str
 */
export function stringFilled(str: string): boolean {
    return str.trim().length > 0;
}

/**
 * Проверяет, заполнена ли маска (проверяет наличие символа маски в строке)
 * @param str
 */
export function maskFilled(str: string): boolean {
    return !str.includes('_');
}

/**
 * Проверяет, заполнена ли строка даты
 * @param date
 */
export function dateStringFilled(date: string|null): boolean {
    return date !== null && maskFilled(date) && date.length === 10;
}

/**
 * Проверяет, заполнена ли строка телефона
 * @param phone
 */
export function phoneFilled(phone: string): boolean {
    return phone.length == 11;
}
