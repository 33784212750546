import React from 'react';
import Typography from "../../../../../../components-ui/DataDisplay/Typography/Typography";

interface Props {
    fontSize?: string
    percent: number | null
    margin?: string
    'data-for'?: string
    'data-tip'?: string
}

const Percentage = ({fontSize = '12px', percent, margin, ...props}: Props) => {
    return (
        <>
            <Typography fontSize={fontSize}
                        data-for={props["data-for"]}
                        data-tip={props["data-tip"]}
                        cursor="default"
                        color={!percent ? 'textGray' : percent < 0 ? 'dec' : 'inc'}
                        margin={margin} children={!!percent ? `${percent > 0 ? '+' + percent : percent}%` : ''} />
        </>
    );
};

export default Percentage;
