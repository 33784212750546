
export const namesCalls = {
    incoming: 'Входящие',
    outgoing: 'Исходящие',
} as {
    [key: string]: string
}

export const colorsCalls = {
    incoming: '#447ce6',
    outgoing: '#fdbc00',
} as {
    [key: string]: string
}
