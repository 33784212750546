import {Props} from "../types";

export const getDisplayTypography = (props: Props) => {
    const {display, currentAs} = props;
    if (!!display) {
        return display
    } else {
        switch (currentAs) {
            case "span":
                return 'initial'
            default:
                return 'block'
        }
    }
}
