import React, {Dispatch, SetStateAction} from 'react';
import DateTime from "../DateTime/DateTime";
import Box from "../../Layout/Box";

export const getStartDate = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), 1)
}

interface Props {
    startDate: any
    setStartDate: Dispatch<SetStateAction<any>>
    endDate: any
    setEndDate: Dispatch<SetStateAction<any>>
    margin?: string,
    position?: 'left' | 'right' | 'center'
    maxDate?: any
    handleOpenDateRange?: () => void
    handleCloseDateRange?: () => void
}

const DateRange = (
    {
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        margin,
        position = 'left',
        maxDate,
        handleCloseDateRange,
        handleOpenDateRange
    }: Props) => {

    return (
        <Box display="flex" margin={margin}>
            <DateTime selected={startDate} onChange={setStartDate} selectsStart={true} startDate={startDate}
                      endDate={endDate} maxDate={endDate} isDateRange={true} dateFormat="d MMM yyyy" position={position}
                      onFocus={handleOpenDateRange} onBlur={handleCloseDateRange}
            />
            <DateTime selected={endDate} onChange={setEndDate} selectsEnd={true} startDate={startDate}
                      endDate={endDate} minDate={startDate} isDateRange={true} dateFormat="d MMM yyyy"
                      position={position} maxDate={maxDate}
                      onFocus={handleOpenDateRange} onBlur={handleCloseDateRange}
            />
        </Box>
    );
};

export default DateRange;
