import React from 'react';
import Box from "../../../components-ui/Layout/Box";
import {PriceListItem, PriceListTree, PriceListElements} from "../style";
import Icon from "../../../components-ui/DataDisplay/Icon";
import {IServicesGroup} from "../types";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import PriceHelper from "../../../common/helpers/price-helper";
import {useDevice} from "../../../common/hooks/useDevice";


interface Props {
    item: IServicesGroup
    onClickGroup: (id: number) => void
}

const ListItem = ({item, onClickGroup}: Props) => {

    const device = useDevice()

    return (
        <>
            <PriceListItem backgroundColor={!item.groups && item.isOpen ? "secondary" : ""}
                           onClick={() => onClickGroup(item.id)}>
                <Box display="flex" alignItems="center">
                    {device !== "mobile" && <Icon margin="2px 0 0 0" name="colorFolder"/>}
                    <Box margin="0 0 0 12px">
                        <Typography unSelectable={true}
                                    fontWeight={!item.groupId ? "bold" : "normal"}>{item.name}</Typography>
                    </Box>
                </Box>
                <Box margin="0 0 0 15px" height="16px">
                    <Icon name={item.isOpen ? "minusRound" : "plusRound"}/>
                </Box>
            </PriceListItem>

            {(item.groups || item.services) && item.isOpen &&

            <PriceListTree>
                {item.groups && item.groups.map((group) =>
                    <ListItem key={group.id} item={group as IServicesGroup} onClickGroup={onClickGroup}/>)
                }

                {item.services?.length ?

                    <PriceListElements>

                        {item.services.map((service, key) =>

                            <Box key={item.id + key} display="flex" justifyContent="space-between" padding="10px 0">
                                <Typography unSelectable={true}>{service.name}</Typography>
                                <Box width="max-content" margin="0 0 0 10px">
                                    <Typography unSelectable={true}
                                                width="inherit">{PriceHelper.format(service.price)}</Typography>
                                </Box>
                            </Box>
                        )}

                    </PriceListElements>

                    : null
                }

            </PriceListTree>

            }

        </>
    )

};

export default ListItem;