import React from 'react';
import {CallsEfficiencyReport} from "../../../types";
import useCallsEfficiency from "./hooks/useCallsEfficiency";
import useTableEfficiency from "./hooks/useTableEfficiency";
import NoReport from "../../../components/NoReport";
import ContentWidgetWrap from "../../../components/ContentWidgetWrap";
import TwoColumnsWrap from '../../../components/TwoColumnsWrap';
import Table from "../../../../../components-ui/DataDisplay/Table";
import Filter from "./components/Filter";
import SwirlWrap from "../../SwirlWrap/SwirlWrap";
import useTextSwirl from "./hooks/useTextSwirl";
import {legendSwirl} from "./data/data";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";

interface Props {
    report: CallsEfficiencyReport
}

const CallsEfficiency = ({report}: Props) => {
    const {state, handlers, optionsSelected} = useCallsEfficiency({report})
    const {columns, rows} = useTableEfficiency({state, report})
    const textSwirl = useTextSwirl({report, state, optionsSelected})
    if (!!report.failedCalls.length) {
        return (
            <>
                <Filter state={state} handlers={handlers} optionsSelected={optionsSelected} report={report}/>
                <ContentWidgetWrap>
                    <TwoColumnsWrap firstColumn={null}
                                    lastColumn={<Typography variant="small" fontWeight="bold" textTransform="uppercase"
                                                            children="Отказы от записи" margin="0 0 11px"/>}/>
                    <TwoColumnsWrap
                        firstColumn={<SwirlWrap textSwirl={textSwirl}
                                                legends={legendSwirl}
                        />}
                        lastColumn={<Table columns={columns}
                                           rows={rows}
                                           key={String(state.currentTab) + String(state.currentSelected.value)}
                                           variantTable="outline"
                                           maxHeight="398px"
                                           isHovering={false}
                                           fontSizeRow="14px"/>}/>
                </ContentWidgetWrap>
            </>
        );
    } else {
        return <NoReport/>
    }

};

export default CallsEfficiency;
