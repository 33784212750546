import styled from 'styled-components';

export interface GroupItemProps {
    isActive: boolean
}

export const SGroupItem = styled.div<GroupItemProps>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 5px 19px;
  ${props => !props.isActive && `cursor: pointer`};
  ${props => props.isActive && `background: #f1f8ff`};
  &:hover {
    background: ${props => !props.isActive && props.theme.colors.secondary};
  }
`;
