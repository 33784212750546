import {useSelector} from "react-redux";
import {useMemo} from "react";
import {OptionSelect} from "../../components-ui/Inputs/Select/typesSelect";

interface Props {
    isColor?: boolean
    isBranches?: boolean
    isFilterDeletedAt?: boolean
}

export const useOptionsSelectDoctors = ({isBranches, isColor, isFilterDeletedAt}: Props): OptionSelect[] => {
    const doctors = useSelector(state => state.common.doctors)

    return useMemo(() => {
        let result = doctors;
        if (isFilterDeletedAt) {
            result = result.filter(doctor => doctor.deletedAt === null)
        }

        return result.map(doctor => {
            const options = {} as any;

            if (isColor) options.color = doctor.color
            if (isBranches) options.branches = doctor.branches

            return {
                value: doctor.id,
                label: doctor.fio,
                ...options
            }
        })
    }, [doctors])

}