import * as React from "react";
import {SaveModalProps} from "../types";
import Helper from "../../../common/helpers/main";
import {Label} from "../../../components-ui/DataDisplay/Label";
import DateTime from "../../../components-ui/Inputs/DateTime/DateTime";
import _ from 'underscore'
import {useModal} from "../../../containers/DialogsManager/containers/Modal/Modal";
import {useRef, useState} from "react";
import useOutsideClick from "../../../common/hooks/useOutsideClick";
import Box from "../../../components-ui/Layout/Box";
import Button from "../../../components-ui/Inputs/Button/Button";
import ServerCommand from "../../../common/server/server-command";
import useLoader from "../../../components/Errors";

export default function Save(props: SaveModalProps) {
    const [selectedDate, setSelectedDate] = useState<any>(null)
    const ref = useRef(null) as any
    const {closeModal} = useModal()
    const [loader, setLoader] = useLoader()

    const onCloseCancel = () => {
        if (!!props.onCloseCancel) {
            props.onCloseCancel()
        }
    }

    const onCloseSave = () => {
        if (!!props.onCloseSave) {
            props.onCloseSave()
        }
    }

    useOutsideClick(ref, onCloseCancel)

    const getParams = () => {
        const {conflictsCountMap} = props;
        let conflictsCount = 0;
        let promptText = null;

        if (selectedDate !== null) {
            const momentDate = Helper.Date.formatDate(selectedDate, 'YYYY-MM-DD');
            const lastDate = getLastConflictDate();
            if (momentDate <= lastDate) {
                promptText = (
                    <span className="modal-graph__prompt">
                        При сохранении конфликтные приёмы
                        <br/>
                        будут отменены и занесены в задачи обзвона
                    </span>
                );
            }

            _.each(conflictsCountMap, (count, date) => {
                if (date >= momentDate) {
                    conflictsCount += count;
                }
            });
        }
        return {
            conflictsCount, promptText
        }
    };

    const getTomorrow = () => {
        let tomorrow = new Date();
        return tomorrow.setDate(new Date().getDate() + 1);
    };

    const getPromptDatePicker = (conflictsCount: number) => {
        let promptDatePicker = null;
        if (conflictsCount > 0) {
            const visitCountWord = Helper.getCountString(conflictsCount, ['приёма', 'приёмов', 'приёмов']);

            promptDatePicker = (
                <div className="prompt-date-picker">
                    Применение графика с выбранной даты
                    <br/>
                    приведёт к отмене {visitCountWord}
                </div>
            )
        }
        return promptDatePicker;
    };

    const getLastConflictDate = () => {
        const conflictsDate = Object.keys(props.conflictsCountMap);
        let lastDate = null as any;
        if (conflictsDate.length) {
            conflictsDate.forEach((date: string) => {
                if (lastDate === null) lastDate = date;
                if (lastDate < date) lastDate = date;
            })
        }
        return lastDate;
    };

    const addClassConflict = (date: any) => {
        const currentDate = Helper.Date.formatDate(date, 'YYYY-MM-DD');
        const lastDate = getLastConflictDate();
        const tomorrow = getTomorrow() as any;
        const tomorrowFormat = Helper.Date.formatDate(tomorrow, 'YYYY-MM-DD');
        if (currentDate <= lastDate && currentDate >= tomorrowFormat) {
            return 'conflict-date';
        } else {
            return null
        }
    };

    const {conflictsCount, promptText} = getParams();
    const promptDatePicker = getPromptDatePicker(conflictsCount);
    const tomorrow = getTomorrow() as any;

    return (
        <div className="modal-graph modal-graph--save" ref={ref}>
            <h3>Сохранение изменений в графике</h3>
            <Label labelText="Дата активации" minWidthLabel="113px" marginWrap="0 0 23px">
                <DateTime minDate={tomorrow}
                          selected={selectedDate}
                          onChange={(selectedDate) => setSelectedDate(selectedDate)}
                          dayClassName={addClassConflict}
                          placeholderText="не выбрана"
                          calendarClassName="graph-date"
                          shouldCloseOnSelect={!Object.values(props.conflictsCountMap).length}
                          children={promptDatePicker}/>
            </Label>
            <Box display="flex" margin="9px 0 0 133px">
                <Button children="Закрыть" variant="default" width="152px" onClick={() => {
                    onCloseCancel()
                    closeModal()
                }}/>
                <Button children="Сохранить"
                        variant={selectedDate !== null
                            ? !!conflictsCount ? 'danger' : 'success'
                            : 'disabled'}
                        margin="0 0 0 18px" width="152px"
                        loader={loader}
                        onClick={() => {
                            const templateChanges = props.getTemplateChanges() as any;

                            if (Object.values(templateChanges).length) {
                                setLoader(true)
                                props.setSnapshotTemplate()
                                const data = {
                                    templateChanges,
                                    sinceDate: Helper.Date.formatDateForServer(selectedDate),
                                    branchId: props.branchId
                                };
                                const saveCommand = new ServerCommand('schedule-template/apply', data);
                                saveCommand.exec().then(() => {
                                    setLoader(false)
                                    onCloseSave();
                                    closeModal();
                                });
                            }
                        }}/>
            </Box>
            {promptText}
        </div>
    )
}
