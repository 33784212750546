import React, {useState} from "react";
import {PPerformedServices} from "../typesInfo";
import {TitleInfo, WrapDateAndText, ServiceDate, ServiceText, ServiceTextChild, WrapService} from "../styledInfo";
import SVG from "../../../../../common/helpers/SVG";
import Helper from "../../../../../common/helpers/main";

export default function PerformedServices({performedServices, servicesNames}: PPerformedServices) {
    const [open, setOpen] = useState(true);

    if (!(!!performedServices)) return null;

    return (
        <WrapService>
            <TitleInfo open={open} onClick={() => setOpen(!open)}>
                <div className="title-info-text">Выполненные услуги</div>
                <SVG name="triangle" className="title-info-triangle"/>
            </TitleInfo>
            {open && performedServices.map((service) => {
                return (
                    <WrapDateAndText key={'performed' + service.serviceId}>
                        <ServiceText>
                            {servicesNames[service.serviceId].map(name => {
                                return (
                                    <ServiceTextChild key={name}>
                                        {name}
                                    </ServiceTextChild>
                                )
                            })}
                        </ServiceText>
                        <ServiceDate>
                            {Helper.Date.formatDate(service.completionDate, 'll')}
                        </ServiceDate>
                    </WrapDateAndText>
                )
            })}
        </WrapService>
    )
}


