import React from 'react';
import TaskContent from "../TaskContent";
import {TaskCallCenter} from "../../../../types/common";
import {LeadPlanData} from "../../../../types/taskData";
import {Doctor, Reasons, UniquePatient, VisitDate, VisitTime} from "../../../../components/Labels";
import {FinalButton, NoteDownButton, OpenPlanButton} from "../../../../components/Buttons";
import {CommonTaskProps} from "../../types/common";
import VisitBranch from "../../../../components/Labels/VisitBranch";

interface Props extends CommonTaskProps {
    task: TaskCallCenter<LeadPlanData>
}

export const LeadPlan = ({task, uniqPatient, infoCallCenter, onChangeSelectedScript, ...props}: Props) => {
    return (
        <TaskContent onChangeSelectedScript={onChangeSelectedScript} buttons={<Buttons task={task} {...props}/>}
                     task={task}>
            <VisitBranch branchId={task.payload.lastVisit.branchId}/>
            <VisitDate timeStart={task.payload.lastVisit.timeStart}/>
            <VisitTime timeStart={task.payload.lastVisit.timeStart} timeEnd={task.payload.lastVisit.timeEnd}/>
            <Doctor doctorId={task.payload.lastVisit.doctorId}/>
            <UniquePatient task={task} uniqPatient={uniqPatient}/>
            <Reasons parentName="postpone" task={task} infoCallCenter={infoCallCenter}/>
        </TaskContent>
    );
};

const Buttons = ({
                     task,
                     validate,
                     blockedButtons,
                     methodsCallCenter
                 }: Omit<Props, 'uniqPatient' | 'infoCallCenter' | 'onChangeSelectedScript'>) => {

    return (
        <>
            <NoteDownButton validate={validate} task={task} blockedButtons={blockedButtons}
                            methodsCallCenter={methodsCallCenter}/>
            <FinalButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
            <OpenPlanButton task={task} methodsCallCenter={methodsCallCenter}/>
        </>
    )
}

