import React, {useState} from 'react';
import './Filter.scss';
import {useSelector} from "react-redux";
import SelectedDoctors from "./components/SelectedDoctors";
import OpeningTime from "./components/OpeningTime";
import ResetFilter from "./components/ResetFilter";
import NonWorkingChairs from "./components/NonWorkingChairs";
import WAPButton from "./components/WAPButton";
import SelectedBranches from "./components/SelectedBranches";
import SelectedSpecialities from "./components/SelectedSpecialities";

export default function Filter() {
    const filter = useSelector(state => state.schedule.filter);
    const [time, setTime] = useState([
        (filter !== null) ? filter.timeFrom : null,
        (filter !== null) ? filter.timeTo : null
    ]);

    return (
        <div className="filter">
            <SelectedBranches/>
            <SelectedSpecialities/>
            <SelectedDoctors/>
            <OpeningTime time={time} setTime={setTime}/>
            <ResetFilter setTime={setTime}/>
            <NonWorkingChairs />
            <WAPButton/>
        </div>
    )
}

