import {useMemo} from "react";
import {IRow} from "../types/common";

interface Props {
    row: IRow
    fieldNameNested?: string
}

export default function usePrepareNested({row, fieldNameNested}: Props) {
    return useMemo(() => {
        let result = [] as IRow[];
        if (!!fieldNameNested && !!row[fieldNameNested]) {
            result = row[fieldNameNested]
        }
        return result;
    }, [row])
}
