import React, {useMemo} from 'react';
import {Article} from "../types";
import Box from "../../../components-ui/Layout/Box";
import Paper from "../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import {Link} from "../../../components-ui/Navigation/Link";
import Icon from "../../../components-ui/DataDisplay/Icon";
import {useDevice} from "../../../common/hooks/useDevice";

export interface Props {
    articles: Article[]
}

const ImportantArticles = ({articles}: Props) => {

    const device = useDevice()

    return useMemo(() => {
        return (
            <Box margin={device !== "mobile" ? "40px 0 0 0" : "20px 0 0 0"} display="flex" justifyContent="space-between" flexWrap="wrap">
                {articles.map((article) =>
                    <Paper key={article.id}
                           margin={device !== "mobile" ? "12px 0" : "7px 0"}
                           padding={device !== "mobile" ? "15px 20px 26px" : "12px 12px 15px"}
                           width={device !== "mobile" ? "49%" : "100%"}
                           maxWidth={device !== "mobile" ? "360px" : "auto"}
                           borderRadius="8px"
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Box maxWidth={device !== "mobile" ? "248px" : "80%"} padding={device !== "mobile" ? "11px 0 0 0" : "4px 0 0 0"}>
                                <Typography variant="middle">{article.description}</Typography>

                                <Box margin={device !== "mobile" ? "8px 0 0 0" : "2px 0 0 0"}>
                                    <Link display="block" notUnderline={true} href={article.link} children={
                                        <Typography variant="middle" as="span" margin="8px 0 0 0">Подробнее</Typography>
                                    }/>
                                </Box>

                            </Box>
                            <Box backgroundColor="primary" borderRadius={device !== "mobile" ? "16px" : "8px"}
                                 minWidth={device !== "mobile" ? "56px" : "44px"}
                                 height={device !== "mobile" ? "56px" : "44px"}
                                 padding={device !== "mobile" ? "12px" : "10px"}
                                 margin={device !== "mobile" ? "0 0 0 12px" : "0 0 0 10px"}>
                                <Icon name={article.icon} width="100%" height="100%" color="white"/>
                            </Box>
                        </Box>
                    </Paper>
                )}
            </Box>
        )
    }, [device])
};

export default ImportantArticles;