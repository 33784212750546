import React, {useEffect, useMemo, useState} from "react";
import Checkbox from "../../../../../../../../../components-ui/Inputs/Checkbox/Checkbox";
import SVG from "../../../../../../../../../common/helpers/SVG";
import {
    ItemTableWrap,
    WrapCheckbox,
    WrapNameGroup,
    TextGroup,
    DoctorGroup,
    DoctorSelectGroup,
    WrapServices
} from "./styledItemTable";
import {PCheckboxGroupActions, PItemTable} from "./typesItemTable";
import HeaderServiceTable from "../HeaderServiceTable/HeaderServiceTable";
import ServiceTable from "../ServiceTable/ServiceTable";
import {OptionDoctor} from "../../typesTableRecommendedServices";
import {canPerformService} from "../../../../../../../../../common/helpers/Doctor";
import Select from "../../../../../../../../../components-ui/Inputs/Select/Select";
import _ from 'underscore';
import useHover from "../../../../../../../../../common/hooks/useHover";

export default function ItemTable(
    {
        group,
        optionsDoctors,
        indexGroup,
        handleServiceDoctor,
        handleGroupDoctor,
        handleCheckGroup,
        handleCheckService,
        handleGroupActionsDoctor
    }: PItemTable) {
    const [hoverRef, hover, setHover] = useHover();
    const [openContent, setOpenContent] = useState(true);
    const [groupActions, setGroupActions] = useState(false)
    const [checkboxGroupActions, setCheckboxGroupActions] = useState<PCheckboxGroupActions>(_.mapObject(_.indexBy(group.services, 'id'), () => false));

    const selectedDoctorGroupActions = () => {
        const servicesGroupActions = group.services.filter(service => checkboxGroupActions[service.id])
        let result = null;
        if (!!servicesGroupActions.length) {
            const firstDoctor = servicesGroupActions[0].doctor;
            let forEachResult = true;
            servicesGroupActions.forEach(service => {
                if (!_.isEqual(firstDoctor, service.doctor)) {
                    forEachResult = false
                }
            });
            if (forEachResult) {
                result = firstDoctor
            }
        }
        return result
    };

    const optionsDoctorsGroup = optionsDoctors.filter((doctor: any) => canPerformService(doctor, group))
    const resetCheckboxGroupActions = () => {
        setCheckboxGroupActions(prevState => {
            _.each(prevState, (val, key) => {
                prevState[key] = false;
            })
            return {
                ...prevState
            }
        })
    }

    useEffect(() => {
        if (!groupActions) resetCheckboxGroupActions()
    }, [groupActions])

    const onChangeDoctorGroupActions = (doctor: OptionDoctor) => {
        const servicesIds = [] as number[];
        _.each(checkboxGroupActions, (val, key) => {
            if (val) servicesIds.push(+key)
        })
        handleGroupActionsDoctor(doctor, indexGroup, servicesIds)
        resetCheckboxGroupActions();
    }



    return (
        <>
            <ItemTableWrap ref={hoverRef}>
                <WrapCheckbox>
                    <Checkbox label={''}
                              checked={group.checked}
                              onChange={(checked: boolean) => handleCheckGroup(checked, indexGroup)}
                    />
                </WrapCheckbox>

                <WrapNameGroup openContent={openContent} onClick={() => setOpenContent(!openContent)}>
                    <SVG name="triangle" className="group-triangle"/>
                    <TextGroup>
                        {group.groupName}
                    </TextGroup>
                </WrapNameGroup>
                {(!hover && group.doctor !== null) && (
                    <DoctorGroup>
                        {group.doctor.label}
                    </DoctorGroup>
                )}
                {(hover || group.doctor === null) && (
                    <DoctorSelectGroup>
                        <Select options={optionsDoctorsGroup}
                                variant="colored"
                                widthSelect="260px"
                                sizeSelect="sm"
                                colorSelect="white"
                                value={group.doctor}
                                placeholder="не выбран"
                                onChange={(doc: OptionDoctor) => {
                                    handleGroupDoctor(doc, indexGroup)
                                    setHover(false);
                                }}
                        />
                    </DoctorSelectGroup>
                )}
            </ItemTableWrap>
            {openContent && (
                <WrapServices>
                    <HeaderServiceTable groupActions={groupActions} setGroupActions={setGroupActions} services={group.services} />
                    {group.services.map((service, index) => {
                        return (
                            <ServiceTable service={service}
                                          optionsDoctors={optionsDoctors}
                                          key={service.id + 'ServiceTable'}
                                          indexGroup={indexGroup}
                                          indexService={index}
                                          groupActions={groupActions}
                                          checkboxGroupActions={checkboxGroupActions}
                                          setCheckboxGroupActions={setCheckboxGroupActions}
                                          selectedDoctorGroupActions={selectedDoctorGroupActions()}
                                          optionsDoctorsGroup={optionsDoctorsGroup}
                                          onChangeDoctorGroupActions={onChangeDoctorGroupActions}
                                          handleServiceDoctor={handleServiceDoctor}
                                          handleCheckService={handleCheckService}/>
                        )
                    })}
                </WrapServices>
            )}
        </>
    )
}



