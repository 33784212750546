import React from 'react';
import {Content, Info, InfoHeader, InfoStatus, Item, Notify, NotifyButton, PatientName, Signal, Time} from "./styled";
import Helper from "../../../../common/helpers/main";
import {useDispatch, useSelector} from "react-redux";
import {useDialogsManager} from "../../../DialogsManager/DialogsManager";
import {IVisit} from "../../../../common/types/common";
import Steps from "../../../../content/Steps/Steps";
import {startVisit} from "../../../../redux/actions/doctorRoleActions";

const NotificationVisit = () => {
    const dispatch = useDispatch();
    const {showSlideModal} = useDialogsManager();
    const visits = useSelector(state => state.doctorRole.visits);
    const waiting = Object.values(visits.waiting) as IVisit[];

    const showSteps = (visitId: number) => {
        dispatch(startVisit(visitId));
        showSlideModal( <Steps visitId={visitId}/>)
    };
    if (waiting.length === 0) return null;
    return (
        <Notify>
            {waiting.map((visit) => (
                <Item key={visit.id}>
                    <Info>
                        <InfoHeader>
                            <InfoStatus>Пациент ожидает</InfoStatus>
                        </InfoHeader>
                        <Content>
                            <Signal/>
                            <PatientName>{Helper.getFio(visit.patient)}</PatientName>
                            <Time>{Helper.Date.formatTime(visit.timeStart)} - {Helper.Date.formatTime(visit.timeEnd)}</Time>
                        </Content>
                    </Info>
                    <NotifyButton onClick={() => showSteps(visit.id)}>
                        Принять пациента
                    </NotifyButton>
                </Item>
            ))}
        </Notify>
    );
};

export default NotificationVisit;
