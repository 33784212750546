import {useModal} from "../../../../../containers/DialogsManager/containers/Modal/Modal";
import {DataHandleSubmit, DataHandleSubmitWAP} from "../types/types";
import DateHelper from "../../../../../common/helpers/date-helper";
import ServerCommand from "../../../../../common/server/server-command";
import _ from "lodash";
import {useWAP} from "../WaitingAndPlanned";
import useLoader from "../../../../../components/Errors";

export default function useSubmit() {
    const {form, activeTab, props} = useWAP()
    const {closeModal} = useModal();
    const [loaderSubmit, setLoaderSubmit] = useLoader();

    const addWAP = (patientId: number) => {
        const data = {
            patientId,
            directions: !!form.directions ? form.directions.map(direction => direction.value) : null,
            doctorId: form.doctor?.value,
            thisDoctorExactly: form.wantThisDoctor,
            comment: form.comment
        } as DataHandleSubmitWAP
        if (activeTab === 'waiting-list') {
            if (form.timeSelected.value === 'other') {
                data.daysCount = +form.timeCounter
            } else {
                data.daysCount = +form.timeSelected.value
            }
            data.branches = form.branches.length ? form.branches.map(branch => +branch.value) : null
        } else {
            data.date = DateHelper.formatDateForServer(form.date)
        }

        ServerCommand.post(activeTab === 'waiting-list' ? 'waiting-list/create-record' : 'planned-visit/create' , data)
            .then(() => {
                if (!!props.onEndSaveWap) {
                    props.onEndSaveWap()
                }
                closeModal()
            })
    }

    const handleSubmit = () => {
        setLoaderSubmit(true)
        const data = _.pick(form.basic, ['name', 'lastName', 'secondName', 'personalPhone', 'personalBirthday']) as DataHandleSubmit;
        if (!!form.basic.phoneOwner) {
            if (form.basic.phoneOwner.value === 'other') {
                data.phoneOwner = null;
            } else {
                data.phoneOwner = form.basic.phoneOwner.value
            }
        }
        data.personalGender = form.basic.personalGender?.value;
        if (!form.currentPatient) {
            ServerCommand.post('patient/add', data).then((response) => addWAP(response.primary.id))
        } else {
            let isChanging = false;
            _.forEach(form.currentPatient, (value, key) => {
                if (!!form.basic[key] && form.basic[key] !== value) isChanging = true
            })
            if (isChanging) {
                data.id = form.currentPatient.id;
                ServerCommand.post('patient/edit', data).then(() => {
                    if (!!form.currentPatient) addWAP(form.currentPatient.id)
                })
            } else {
                addWAP(form.currentPatient.id)
            }
        }
    };

    return {
        handleSubmit,
        loaderSubmit
    }
}
