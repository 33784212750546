import React, {forwardRef} from 'react';
import { WrapIcon } from '../../../containers/WrapIcon';
import { IIcon } from '../../../types/common';

export const MissedIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 18"} ref={ref}>
            <path d="M12,9.07a16.87,16.87,0,0,1,11.71,4.71,1,1,0,0,1,.29.71,1,1,0,0,1-.29.72l-2.48,2.5a1,1,0,0,1-.71.29,1,1,0,0,1-.7-.28,11.35,11.35,0,0,0-2.67-1.87,1,1,0,0,1-.56-.9V11.82A14.52,14.52,0,0,0,12,11.09a14.91,14.91,0,0,0-4.6.72v3.13a1,1,0,0,1-.56.9,11.59,11.59,0,0,0-2.66,1.87,1,1,0,0,1-.7.28,1,1,0,0,1-.71-.29L.29,15.2A1,1,0,0,1,0,14.49a1,1,0,0,1,.29-.71A16.87,16.87,0,0,1,12,9.07ZM11.67,0V1.16H9l3.26,3.25L16.68,0l.82.82L12.25,6.05,8.17,2V4.65H7V0Z"/>
        </WrapIcon>
    );
});
