import Box from "../../../../../components-ui/Layout/Box";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import React from "react";
import {useWAP} from "../WaitingAndPlanned";

export default function ResetFormWAP() {
    const {resetForm, setPatientDuplicate} = useWAP();
    return (
        <Box display="flex" justifyContent="flex-end" margin="0">
            <Button variant="danger" size="sm" children="Сбросить форму" onClick={() => {
                resetForm()
                setPatientDuplicate(null)
            }} />
        </Box>
    )
}
