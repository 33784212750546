import React from 'react'
import './HeaderPatientCard.scss'
import PropTypes from 'prop-types'
import {formatCardNumber} from "../../../common/helpers/Patient";

export default class HeaderPatientCard extends React.Component {
    static propTypes = {
        fioPatient: PropTypes.string,
        numberCard: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        isPrimary: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        onClick: PropTypes.func
    };

    render() {
        const isPrimary = (this.props.isPrimary) ? 'Первичный' : '';
        return (
            <div className="header-popup header-popup--patient-card">
                <div className="header-popup__left">
                    <div className="header-popup__min-txt">{isPrimary}</div>
                    <div className="header-popup__patient">
                        <div className="header-popup__title">{this.props.fioPatient} {formatCardNumber(this.props.numberCard)}</div>
                    </div>
                </div>
            </div>
        )
    }
}
