import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const NotifyFilledIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 21.28 24"} ref={ref}>
            <path d="M21.28,16.92A14,14,0,0,1,17.63,6.7,5.89,5.89,0,0,0,14.23.85a8,8,0,0,0-7.18,0A5.89,5.89,0,0,0,3.65,6.7,14,14,0,0,1,0,16.92m21.28,0c-5.8,4.73-15.48,4.73-21.28,0m8.25,5.19A2.19,2.19,0,0,0,10.64,24,2.2,2.2,0,0,0,13,22.11"/>
        </WrapIcon>
    );
});
