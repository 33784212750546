import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth57Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 16.17"} width={width || "20"} height={height || "14"}  ref={ref}>
            <path d="M23.87,9.15c-.56,3.54-1.65,5.77-3.28,6.61-2.17,1.13-5.17-.3-7.35-1.33a7.53,7.53,0,0,0-2.79-1,11.16,11.16,0,0,0-2.63.46c-2.64.7-4.92,1.3-7.25-3.4A7.37,7.37,0,0,1,.84,4.19,6.77,6.77,0,0,1,5,.31,7.59,7.59,0,0,1,8.33.1a30.14,30.14,0,0,0,6.51.07,7.21,7.21,0,0,1,3.51.24c3.26,1.26,6.22,4.42,5.56,8.73h0Z"/>
        </WrapIcon>
    );
});
