import React from 'react';
import useTableFormatter from './hooks/useTableFormatter';
import {GetSalariesTimesheetResponce} from '../../types';
import Loader from '../../../../components-ui/Feedback/Loader/Loader';
import Paper from '../../../../containers/UI-Collection/Elements/Surfaces/Paper';
import Table from '../../../../components-ui/DataDisplay/Table';
import Button from '../../../../components-ui/Inputs/Button/Button';
import Box from '../../../../components-ui/Layout/Box';
import {useDialogsManager} from '../../../../containers/DialogsManager/DialogsManager';
import ModalTimesheetRecord from '../modals/ModalTimesheetRecord';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import ModalAddTimeSheet from '../modals/ModalAddTimesheet';

interface Props {
    loaderTable: boolean;
    data: GetSalariesTimesheetResponce;
    selectedBranch: OptionSelect;
    refreshDataFromServer: () => void
}

const SalariesTimesheetTable = ({loaderTable, data, selectedBranch, refreshDataFromServer}: Props) => {

    const {showModal} = useDialogsManager();

    const {rows, columns} = useTableFormatter({data, selectedBranch, refreshDataFromServer});
    return (
        <Paper padding='24px'>
            <Loader visible={loaderTable} />
            <Table columns={columns}
                   rows={rows}
                   variantTable='outline'
                   isHovering={false}
                   height='470px'
            />
            <Box display='flex' gap='16px'>
                <Button margin='24px 0 0 0'
                        variant='default'
                        size='md'
                        children='Добавить график'
                        onClick={() => showModal(
                            <ModalAddTimeSheet data={data}
                                               selectedBranchId={selectedBranch.value}
                                               refreshDataFromServer={refreshDataFromServer}
                            />
                        )}
                />
                <Button margin='24px 0 0 0'
                        variant='default'
                        size='md'
                        children='Отметить сотрудника'
                        onClick={() => showModal(
                            <ModalTimesheetRecord data={data}
                                                  selectedBranchId={selectedBranch.value}
                                                  refreshDataFromServer={refreshDataFromServer}
                            />
                        )}
                />
            </Box>
        </Paper>
    );
};

export default SalariesTimesheetTable;
