import React from 'react'
import PropTypes from 'prop-types';
import './NavAppt.scss'

export default class NavAppt extends React.Component {

    static propTypes = {
        steps: PropTypes.array.isRequired,
        currentStep: PropTypes.string.isRequired,
        onPassedItemClick: PropTypes.func.isRequired
    }

    render() {
        let isPassed = true;
        const stepsTemplate = this.props.steps.map((item, index) => {
            let content = item.name;
            let itemClass= "popup-nav__item";

            if (item.code === this.props.currentStep) {
                itemClass += " popup-nav__item--active";
                isPassed = false;
            } else if (isPassed) {
                itemClass += " popup-nav__item--passed";
            }

            let navItemProps = {
                className: itemClass,
                key: index
            };

            if (isPassed) {
                navItemProps.onClick = () => this.props.onPassedItemClick(item.code);
            }

            return (
                <div {...navItemProps}>
                    {content}
                </div>
            )
        });

        return (
            <div className="popup-nav">
                {stepsTemplate}
            </div>
        )
    }
}