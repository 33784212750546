import styled from "styled-components";
import {PSCheckboxAfter, PSCheckboxText, sizeCheckbox} from "./typesCheckbox";
import Helper from "../../../common/helpers/main";

interface PropsSCheckboxLabel {
    margin?: string
    size: sizeCheckbox
}

const minHeight = (props: {size: sizeCheckbox}) => {
    switch (props.size) {
        case "sm":
            return '22px'
        case "md":
        default:
            return '24px'
    }
}

const minWidth = (props: {size: sizeCheckbox}) => {
    switch (props.size) {
        case "sm":
            return '16px'
        case "md":
        default:
            return '18px'
    }
}

export const SCheckboxLabel = styled.label.attrs({className: 'SCheckboxLabel'})<PropsSCheckboxLabel>`
    min-width: ${minWidth};
    min-height: ${minHeight};
    display: inline-block;
    .checkbox-required {
        color: ${props => props.theme.colors.danger};
    }
    ${props => !!props.margin && `
        margin: ${props.margin};
    `}
`

const backgroundBeforeText = (props: PSCheckboxText) => {
    const {disabled, theme, checked} = props;
    if (disabled) {
        if (checked) {
            return `rgba(${Helper.Color.hexToRgb(theme?.colors.primary)}, 0.6)`
        } else {
            return theme?.colors.secondary;
        }
    } else {
        if (checked) {
            return theme?.colors.primary;
        } else {
            return theme?.colors.white;
        }
    }
}

const fontSize = (props: PSCheckboxText) => {
    switch (props.size) {
        case "sm":
            return '14px'
        case "md":
        default:
            return '16px'
    }
}

const padding = (props: PSCheckboxText) => {
    switch (props.size) {
        case "sm":
            return '0 0 0 28px'
        case "md":
        default:
            return '0 0 0 30px'
    }
}

const size = (props: PSCheckboxText) => {
    switch (props.size) {
        case "sm":
            return '16px'
        case "md":
        default:
            return '18px'
    }
}

export const SCheckboxText = styled.span.attrs({className: 'SCheckboxText'})<PSCheckboxText>`
    display: block;
     min-width: ${minWidth};
    min-height: ${minHeight};
    position: relative;
    padding: ${padding};
    cursor: pointer;
    font-size: ${fontSize};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${props => props.theme.colors.text};
    user-select: none;
    &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: ${size};
        height: ${size};
        border-radius: 2px;
        border: solid 1px ${props => props.checked ? props.theme.colors.primary : props.theme.colors.borderGray};
        background-color: ${backgroundBeforeText};
        transition: background-color .3s ease, border-color .3s ease;
    }
`

const widthAfter = (props: PSCheckboxAfter) => {
    switch (props.size) {
        case "sm":
            return '10px'
        case "md":
        default:
            return '12px'
    }
}

const heightAfter = (props: PSCheckboxAfter) => {
    switch (props.size) {
        case "sm":
            return '8px'
        case "md":
        default:
            return '10px'
    }
}

const topAfter = (props: PSCheckboxAfter) => {
    switch (props.size) {
        case "sm":
        case "md":
        default:
            return '7px'
    }
}

const leftAfter = (props: PSCheckboxAfter) => {
    switch (props.size) {
        case "sm":
        case "md":
        default:
            return '3px'
    }
}

export const SCheckboxAfter = styled.span.attrs({className: 'SCheckboxAfter'})<PSCheckboxAfter>`
    opacity: ${props => props.checked ? 1 : 0};
    position: absolute;
    z-index: 1;
    left: ${leftAfter};
    top: ${topAfter};
    transition: opacity .3s ease;
    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${widthAfter};
        height: ${heightAfter};
        fill: ${props => props.theme.colors.white};
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`

export const SCheckboxNone = styled.span.attrs({className: 'SCheckboxNone'})`
    opacity: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    position: absolute;
    z-index: -1;
    font-size: 14px;
`

export const SCheckboxTooltip = styled.span.attrs({className: 'SCheckboxTooltip'})`
    min-width: 80px;
    min-height: 40px;
    padding: 11px 16px 13px;
    border-radius: 4px;
    background-color: ${props => `rgba(${Helper.Color.hexToRgb(props.theme.colors.tooltipBgDark)}, 0.95)`} ;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: ${props => props.theme.colors.white};
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3233;
    text-align: center;
    white-space: nowrap;
`
