import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth37Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 13.09 24"} width={width || "24"} height={height || "44"} ref={ref}>
            <path d="M11.56,2.09A2.4,2.4,0,0,0,9.27.53,7.53,7.53,0,0,0,7.07,1,4,4,0,0,1,6,1.29,3.71,3.71,0,0,1,4.47.76c-1-.48-2.11-1-2.95-.6S.32,1.59.06,3.27A3.74,3.74,0,0,0,.68,6.08a2.63,2.63,0,0,0,1.7,1.08,3.44,3.44,0,0,0,1.39-.1,7.85,7.85,0,0,1,1.67-.19,8.37,8.37,0,0,1,2.28.32,3.73,3.73,0,0,0,1.66.16,2.93,2.93,0,0,0,2.28-1.51,4.35,4.35,0,0,0-.09-3.75ZM1.92,13c.26.89.48,1.73.64,2.54C3.52,21,5.91,24,6.87,24c0,0,.23,0,.34-.48a7.17,7.17,0,0,0-.78-3.47,13.69,13.69,0,0,1-1.12-4.59c0-.56.22-1,.6-1.05s.64,0,1,.66c4.39,7.84,5.8,8.65,6.09,8.73A4,4,0,0,0,13,22a5.34,5.34,0,0,0-.52-1.43,17,17,0,0,1-1.18-3.27C10.65,15,11,10,11.22,7.65a4.64,4.64,0,0,1-1.69.61,3.36,3.36,0,0,1-.63.06,5.85,5.85,0,0,1-1.44-.23,7.39,7.39,0,0,0-2-.29A6.65,6.65,0,0,0,3.94,8a4.39,4.39,0,0,1-1.73.11A3.48,3.48,0,0,1,.86,7.56,22.29,22.29,0,0,0,1.94,13h0Z"/>
        </WrapIcon>
    );
});
