import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const DeleteIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M12,7.79,19.35.44a1.49,1.49,0,0,1,2.12,0l2.09,2.09a1.49,1.49,0,0,1,0,2.12L16.21,12l7.35,7.35a1.49,1.49,0,0,1,0,2.12l-2.09,2.09a1.49,1.49,0,0,1-2.12,0L12,16.21,4.65,23.56a1.49,1.49,0,0,1-2.12,0L.44,21.47a1.49,1.49,0,0,1,0-2.12L7.79,12,.44,4.65a1.49,1.49,0,0,1,0-2.12L2.53.44a1.49,1.49,0,0,1,2.12,0Z"/>
        </WrapIcon>
    );
});
