import React from 'react';
import {DoneLaboratoryService, GetDoneServicesResult} from '../../../types';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import {useSelector} from 'react-redux';
import ButtonForOptions from '../../../../../components-ui/Inputs/ButtonForOptions/ButtonForOptions';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import DateHelper from '../../../../../common/helpers/date-helper';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import ModalDeleteDoneLaboratoryService from '../modals/ModalDeleteDoneLaboratoryService';
import {Laboratory} from '../../../../LaboratoryPage/types';
import {OptionSelect} from '../../../../../components-ui/Inputs/Select/typesSelect';
import {IDoneLaboratoryServiceRow} from '../types';
import ModalMarkDoneLaboratoryService from '../modals/ModalMarkDoneLaboratoryService';

interface Props {
    services: GetDoneServicesResult['services'];
    refreshDataFromServer: () => void;
    selectedBranch: OptionSelect
    laboratories?: Laboratory[];
    doneLaboratoryServices: DoneLaboratoryService[]
}

const useTableFormatter = ({services, laboratories, selectedBranch, refreshDataFromServer, doneLaboratoryServices}: Props) => {

    const {showModal} = useDialogsManager();

    const users = useSelector(state => state.common.users);

    //const laboratories = useSelector(state => state.common.laboratories);

    const columns: IColumn<IDoneLaboratoryServiceRow>[] = [
        {
            field: 'date',
            headerName: 'Дата',
            valueFormatter: ({childrenCell}) => <Typography>
                {DateHelper.formatDate(childrenCell, 'DD MMM YYYY')}
            </Typography>,
        },
        {
            field: 'laboratory',
            headerName: 'Лаборатория',
            valueFormatter: ({row}) => <>
                {row.laboratory.label}
            </>,
        },
        {
            field: 'doctor',
            headerName: 'Врач',
            valueFormatter: ({row}) => <>
                {row.doctor.label}
            </>,
        },
        {
            field: 'service',
            headerName: 'Услуга',
            valueFormatter: ({row}) => <>
                {row.service.label}
            </>,
        },
        {
            field: 'quantity',
            headerName: 'Количество',
        },
        {
            field: 'buttonForOptions',
            width: "50px",
            valueFormatter: ({row}) =>
                <ButtonForOptions position='left-bottom'
                                  options={[
                                      {
                                          value: 'Изменить',
                                          label: 'Изменить',
                                          onClick: () => showModal(
                                              <ModalMarkDoneLaboratoryService
                                                service={row}
                                                services={services}
                                                laboratories={laboratories}
                                                selectedBranch={selectedBranch}
                                                refreshDataFromServer={refreshDataFromServer}
                                              />
                                          ),
                                      },
                                      {
                                          value: 'Удалить',
                                          label: 'Удалить',
                                          onClick: () => showModal(
                                              <ModalDeleteDoneLaboratoryService
                                                  id={row.id}
                                                  refreshDataFromServer={refreshDataFromServer} />,
                                          ),
                                      },
                                  ]} />,
        },
    ];

    const rows = doneLaboratoryServices.map(row => ({
        id: row.id,
        date: row.date,
        service: {value: row.serviceId, label: services.find(service => service.id === row.serviceId)?.name},
        laboratory: {value: row.laboratoryId, label: laboratories?.find(lab => lab.id === row.laboratoryId)?.name},
        doctor: {value: row.doctorId, label: users[row.doctorId].fio, color: users[row.doctorId].color},
        quantity: row.quantity
    } as IDoneLaboratoryServiceRow))

    return {columns, rows};
};

export default useTableFormatter;
