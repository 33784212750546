import * as React from "react";
import Button from "../../../components-ui/Inputs/Button/Button";

export default function StartCallButtons({startCall}: Props) {
    return (
        <>
            <Button variant="default" children="Запустить исходящий звонок" onClick={() => startCall(false)}/>
            <Button variant="default" children="Запустить входящий звонок" onClick={() => startCall(true)}/>
        </>
    );
}

interface Props {
    startCall: (incoming: boolean) => void
}
