import {useMemo, useRef, useState} from "react";
import {WidthItems} from "../types";
import _ from "lodash";
import {StateAverageCheck} from "../../../types";
import useWidowSize from "../../../../../../../../common/hooks/useWidowSize";

interface Props {
    state: StateAverageCheck
    codes: string[]
}

const useLegend = ({state, codes}: Props) => {
    const refWrap = useRef<HTMLElement>(null)
    const [widthItems, setWidthItems] = useState<WidthItems>({})
    const widowSize = useWidowSize()

    const widthWrap = useMemo(() => {
        if (!!refWrap.current) {
            return refWrap.current.getBoundingClientRect().width
        } else {
            return 1200
        }
    }, [refWrap.current, widowSize])

    const visibleCodes = useMemo(() => {
        let additionalUsers = [] as string[];
        if (state.currentTab === 'doctors') {
            if (_.sumBy(Object.values(widthItems), item => item + 54) > widthWrap) {
                const newCodes = [] as string[];
                let max = 0;
                codes.forEach(code => {
                    max += widthItems[code] + 54
                    if (max < widthWrap - 75) {
                        newCodes.push(code)
                    } else {
                        additionalUsers.push(code)
                    }
                })
                return {
                    codes: newCodes,
                    additionalUsers
                }
            } else {
                return {
                    codes,
                    additionalUsers
                };
            }
        } else {
            return {
                codes,
                additionalUsers
            }
        }
    }, [state.currentTab, JSON.stringify(codes), widthItems, widthWrap, widowSize])

    return {
        refWrap,
        setWidthItems,
        visibleCodes
    }
}

export default useLegend
