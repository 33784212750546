import React from 'react';
import './HeaderAppt.scss';
import moment from 'moment';
import PatientCard from "../../../PatientCard/PatientCard";
import Time from "../../../../containers/Header/elements/Time";
import {formatCardNumber} from "../../../../common/helpers/Patient";
import {useDialogsManager} from "../../../../containers/DialogsManager/DialogsManager";
import {StepVisit} from "../../types";

interface Props {
    visit: StepVisit,
}

const HeaderAppt = ({visit}: Props) => {

    const {showSlideModal} = useDialogsManager();
    let timeDate = new Date(visit.timeStart).toLocaleString('ru', {day: 'numeric', month: 'long'});
    let timeStart = moment(visit.timeStart).format('HH:mm');
    let timeEnd = moment(visit.timeEnd).format('HH:mm');

    return (
        <div className="header-popup">
            <div className="header-popup__left">
                <div className="header-popup__min-txt">
                    {`${timeDate} с ${timeStart} до ${timeEnd}`}
                </div>
                <div className="header-popup__patient">
                    <div className="header-popup__title">Прием пациента&nbsp;</div>
                    <div className="header-popup__user"
                         onClick={() => showSlideModal(<PatientCard patientId={visit.patient.id}/>)}>
                        {`${visit.patient.lastName} ${visit.patient.name} ${formatCardNumber(visit.patientNumber)}`}
                    </div>
                </div>
            </div>
            <div className="header-popup__right">
                <Time/>
            </div>
        </div>
    )
}

export default HeaderAppt