import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import TextField from "../../../../../components-ui/Inputs/TextField/TextField";

export default function LabelPage() {
    const [value, setValue] = useState('')
    return (
        <>
            <h1>Label</h1>
            <WrapContent>
                <div>
                    <Label labelText="Вот такой вот лейбл">
                        <TextField onChange={setValue} value={value} />
                    </Label>
                    <pre>{}</pre>
                </div>

            </WrapContent>
        </>
    )
}
