import _ from "underscore";
import TreatmentPlan from "../../containers/TreatmentPlan/TreatmentPlan";
import {Option} from "../types/common";
import {PlanItem} from "../../containers/TreatmentPlan/plan-types";
import {Tab} from "../../components-ui/Navigation/Tabs/elements/Tab/typesTab";
import Arr from "../helpers/Arr";
import {hasSpecialities} from "../helpers/Doctor";
import {getFilledDirections} from "../helpers/treatment-plan-item";
import Helper from "../helpers/main";
import {UsersMap} from "../../types";
import { notTrashed } from "../helpers/general-helper";

export default function getDoctorsTabs(params: DoctorsTabsParams): Tab[] {

    let {planItems, doctors, replaceablePerformers, addCreatorNote, forceCurDoctorTab} = _.defaults(params,{
            forceCurDoctorTab: true
        });

    let performers = TreatmentPlan.getPerformers(planItems, doctors, forceCurDoctorTab);
    const currentDoctorId = (window as any).user.id;

    /* --- определяем каких врачей можно удалять из плана --- */

    // удалить можно врача, у которого нет выполненных услуг

    const deletablePerformersMap = _.chain(planItems)
        .groupBy('performerId')
        .reject(performerItems => performerItems.some(performerItem => performerItem.isDone))
        .flatten()
        .pluck('performerId')
        .invert()
        .value();

    if (currentDoctorId && forceCurDoctorTab) {
        delete deletablePerformersMap[currentDoctorId];
    }

    /* --- получаем карту порекомендовавших врачей --- */

    let performersRecommendersMap: PerformersRecommendersMap | null = null;

    if (addCreatorNote) {
        performersRecommendersMap = getPerformersRecommendersMap(planItems);
    }

    /* --- получаем заменяемых врачей --- */

    let doctorsReplacementOptions: DoctorsReplacementOptions | null;

    if (replaceablePerformers) {
        replaceablePerformers = _.invert(replaceablePerformers);
        doctorsReplacementOptions = getDoctorsReplacementOptions(doctors, performers, planItems);
    }

    /* --- собираем массив табов --- */

    return performers.map(performerId => {
        const performerTab: Tab = {
            name: performerId ? !!doctors[performerId].fio ? doctors[performerId].fio : Helper.getFio(doctors[performerId]) : 'Общее',
            code: Number(performerId),
            isDeletable: performerId in deletablePerformersMap
        };

        if (replaceablePerformers && doctorsReplacementOptions) {
            performerTab.isReplaceable = performerId in replaceablePerformers;
            performerTab.replacementOptions = doctorsReplacementOptions[performerId]
        }

        if (performersRecommendersMap && performersRecommendersMap[performerId]) {
            const creatorId = performersRecommendersMap[performerId];

            if (creatorId !== performerId) {
                performerTab.note = 'Составил ' + Helper.getFio(doctors[creatorId]);
            }
        }

        return performerTab;
    });
}

function getPerformersRecommendersMap(planItems: PlanItem[]): PerformersRecommendersMap {
    const performersRecommendersMap: PerformersRecommendersMap = {};

    _.chain(planItems)
        .groupBy('performerId')
        .each((performerItems, performerId) => {
            let creatorsIds: number[] = [];

            performerItems.forEach(item => creatorsIds = creatorsIds.concat(item.creatorsIds));

            creatorsIds = _.uniq(creatorsIds);

            if ((creatorsIds.length === 1) && creatorsIds[0] && creatorsIds[0] !== Number(performerId)) {
                performersRecommendersMap[Number(performerId)] = performerItems[0].creatorsIds[0];
            }
        });

    return performersRecommendersMap;
}

function getDoctorsReplacementOptions(doctors: UsersMap, usedDoctorsIds: number[], planItems: PlanItem[]): DoctorsReplacementOptions {
    const suitableDoctors = _.chain(doctors)
        .omit(Arr.mapToString(usedDoctorsIds))
        .filter(notTrashed)
        .values()
        .sortBy('fio')
        .value();

    return _.chain(planItems)
        .groupBy('performerId')
        .pick(Arr.mapToString(usedDoctorsIds))
        .mapObject(performerItems => {
            const performerItemsDirections = getFilledDirections(performerItems);

            return suitableDoctors
                .filter(doctor => hasSpecialities(doctor, performerItemsDirections))
                .map(doctor => {
                    return {
                        label: doctor.fio,
                        value: doctor.id,
                        color: doctor.color
                    };
                });
        })
        .value();
}

interface DoctorsTabsParams {
    planItems: PlanItem[]
    doctors: UsersMap
    addedDoctors?: number[]
    createdPlanToDoctors?: number[]
    replaceablePerformers?: number[]
    addCreatorNote?: boolean
    forceCurDoctorTab?: boolean
}

interface PerformersRecommendersMap {
    [performerId: number]: number
}

interface DoctorsReplacementOptions {
    [performerId: number]: Option[]
}
