import React, {useMemo} from 'react';
import {
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    LineChart,
    ResponsiveContainer
} from "recharts";
import CustomTooltip from "./components/CustomTooltip";
import CustomTick from "../components/CustomTick";
import _ from 'lodash';

const defaultColors = ['#447ce6', '#fdbc00', '#aa70cb']

export interface LineDataItem {
    name: string,
    data: { code: string, value: number }[]
}

interface Props {
    data: LineDataItem[]
    colors?: { [key: string]: string }
    activeCell?: string | number | null
    hoverIndex?: number
    formatterCodeTooltip?: (code: string) => string
    formatterCode?: (code: string) => string
    formatterValue?: (value: number) => string
    formatterName?: (name: string) => any
    formatterSort?: (a: any, b: any) => any
    width: string | number
    height: string | number
    isWidthYAxis?: boolean
}

const ChartLine = (
    {
        data,
        colors,
        hoverIndex,
        activeCell,
        formatterCode,
        formatterValue,
        formatterName,
        formatterCodeTooltip,
        width,
        height,
        isWidthYAxis = true,
        formatterSort
    }: Props) => {

    const widthYAxis = useMemo(() => {
        if (isWidthYAxis) {
            let concatData = [] as any[];
            data.forEach(item => concatData = [...concatData, ...item.data]);
            const maxValue = Math.ceil(_.maxBy(concatData, 'value').value / 10) * 10;
            const formattedValue = !!formatterValue ? formatterValue(maxValue) : String(maxValue)
            return !!formattedValue ? formattedValue.length * 9.5 : 9.5
        } else {
            return 0
        }
    }, [JSON.stringify(data)])

    const commonPropsYAxis = {} as any;
    if (isWidthYAxis) {
        commonPropsYAxis.width = widthYAxis
    }

    return (
        <ResponsiveContainer width={width} height={height}>
            <LineChart>
                <CartesianGrid vertical={false}/>
                <XAxis dataKey="code"
                       tick={<CustomTick type="x" textAnchor="middle" formatterCode={formatterCode} formatterValue={formatterValue}/>}
                       allowDuplicatedCategory={false}
                       axisLine={false}
                       tickLine={false}
                       interval="preserveStartEnd"
                />
                <YAxis dataKey="value"
                       tick={<CustomTick type="y" textAnchor="end" propsX={-1} propsY={4} formatterCode={formatterCode} formatterValue={formatterValue}/>}
                       axisLine={false}
                       tickLine={false}
                       tickFormatter={value => !!formatterValue ? formatterValue(value) : value}
                       {...commonPropsYAxis}
                />
                <Tooltip content={<CustomTooltip formatterCode={formatterCodeTooltip} formatterValue={formatterValue}
                                                 formatterName={formatterName} formatterSort={formatterSort}/>}/>
                {data.map((item, index) => {
                    let currentFill = defaultColors[index % defaultColors.length];
                    if (!!colors && !!colors[item.name]) currentFill = colors[item.name];
                    let strokeWidth = 1;
                    let strokeOpacity = 100;
                    if (!!activeCell && activeCell === item.name) {
                        strokeWidth = 3;
                    } else {
                        if (hoverIndex !== undefined && hoverIndex === index) {
                            strokeWidth = 2;
                            strokeOpacity = 50;
                        }
                    }
                    return (
                        <Line type="linear"
                              dataKey="value"
                              data={item.data}
                              key={item.name}
                              name={item.name}
                              stroke={currentFill}
                              strokeWidth={strokeWidth}
                              strokeOpacity={strokeOpacity}
                              dot={false}
                        />
                    )
                })}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default ChartLine;
