import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './Button.scss'
import {Icon} from '../../components/Icons/Icons'
import Loader from "../Feedback/Loader/Loader";

export default class Button extends Component {

    constructor(props) {
        super(props);
        this.disabledTooltip = React.createRef();
    }

    componentDidMount() {
        if (!!this.props.disabledTip) {
            const widthTooltip = this.disabledTooltip.current.offsetWidth;
            this.setState({widthTooltip})
        }
    }

    state = {
        isHovered: false,
        widthTooltip: 0,
        positionTooltip: {
            x: 0,
            y: 0
        }
    };

    render() {
        const {size, variant, action, text, children, type, align, disabledTip, loader} = this.props;
        const {isHovered, positionTooltip, widthTooltip} = this.state;

        const styleTooltip = {
            left: positionTooltip.x - 16 - (widthTooltip / 2),
            top: positionTooltip.y + 20
        };

        return (
            <button type={type}
                    className={`btn btn-size-${size} btn-variant-${variant} btn-align-${align} ${loader && 'btn-loader-disabled'}`}
                    onClick={this.handleClick}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseMove={this.handleMouseMove}
                    onMouseLeave={this.handleMouseLeave}

            >

                {!!disabledTip && <span children={disabledTip} className="checkbox-none" ref={this.disabledTooltip}/>}

                {action === 'reset' && <Icon icon="new-check" iconClass="check-res"/>}
                {action === 'submit' && <Icon icon="new-check" iconClass="check-res"/>}
                {action === 'print' && <Icon icon="new-print"/>}
                <span className="btn-text">
                            {text || children}
                        </span>
                {action === 'next' && <Icon icon="icon-arrow"/>}

                {isHovered && (variant === 'disabled') && !!disabledTip &&
                <span className="checkbox-tooltip" style={styleTooltip}>
                    {disabledTip}
                </span>}
                <Loader visible={loader} />
            </button>
        )
    }

    handleClick = (e) => {
        if (this.props.variant !== 'disabled') {
            this.props.onClick(e);
        }
    };

    handleMouseEnter = (e) => {
        const {disabledTip} = this.props;
        if (!!disabledTip) {
            if (disabledTip.length) {
                this.setState({
                    isHovered: true,
                    positionTooltip: {
                        x: e.clientX,
                        y: e.clientY
                    }
                })
            }
        }
    };

    handleMouseMove = (e) => {
        const {disabledTip} = this.props;
        if (!!disabledTip) {
            if (disabledTip.length) {
                this.setState({
                    positionTooltip: {
                        x: e.clientX,
                        y: e.clientY
                    }
                })
            }
        }
    };

    handleMouseLeave = () => {
        const {disabledTip} = this.props;
        if (!!disabledTip) {
            if (disabledTip.length) {
                this.setState({isHovered: false})
            }
        }
    };
}

Button.defaultProps = {
    loader: false,
    size: "md",
    type: 'button',
    variant: "default",
    align: "center",
    disabledTip: null,
    onClick: () => {
    },
};

Button.propTypes = {
    size: PropTypes.oneOf(["sm", "md", "lg", "long"]),
    variant: PropTypes.oneOf(["primary", "secondary", "success", "disabled", "default", "outline--secondary", "warning", "third", "link-white", "dark", "alert", "reset", "success btn-print", "disabled btn-print"]),
    action: PropTypes.oneOf(["next", "submit", "print"]),
    align: PropTypes.oneOf(["center", "left", "right"]),
    text: PropTypes.string,
    onClick: PropTypes.func,
    disabledTip: PropTypes.string,
    loader: PropTypes.bool
};
