import styled from "styled-components";

export const WrapPaymentCreationForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    & > div {
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: ${props => props.theme.colors.textDarkGray};
    }
`

export const SWrapRelationPatient = styled.div.attrs({className: 'SWrapRelationPatient'})`
    border-bottom: 1px solid ${props => props.theme.colors.lightBlue};
    padding: 17px 0 10px;
    position: relative;
`

export const SWrapButtons = styled.div.attrs({className: 'SWrapButtons'})`
    position: absolute;
    right: 0;
    top: 13px;
`
