import * as React from 'react'
import {SaveButtonLabelProps} from "./types"
import Helper from '../../common/helpers/main'
import _ from 'underscore'

export default function SaveButtonLabel(props : SaveButtonLabelProps) {
    const {validation, successLabel, attractToQuantityDivide, isRecommended, doctors, canPrint} = props;
    const planTypeName = isRecommended ? 'рекоммендуемый' : 'согласованный';

    function getDoctorsFio(doctorsIds: number[]): string {
        return doctorsIds.map(doctorId => Helper.getFio(doctors[doctorId])).join(', ');
    }

    if(validation.isSuccess()) {
        if(canPrint === false) {
            return 'Укажите, какой план нужно распечатать';
        } else {
            return successLabel;
        }
    }
    else if (validation.hasError('empty_plan')) {
        return `Добавьте услуги в ${planTypeName} план лечения`;
    }
    else if (validation.hasError('not_added_additional_services')) {
        return `Добавьте дополнительные услуги в ${planTypeName} план лечения`;
    }
    else if (validation.hasError('not_defined_recommended_visit_decision')) {
        return 'Укажите решение пациента по записи к рекомендованным врачам: ' + getDoctorsFio(validation.getErrorParam('not_defined_recommended_visit_decision', 'forPerformers'));
    }
    else if (validation.hasError('empty_stage_name')) {
        const stagesNumbers: number[] = validation.getErrorParam('empty_stage_name', 'stagesNumbers');

        if (stagesNumbers.length === 1) {
            return 'Укажите название для этапа №' + stagesNumbers[0];
        } else {
            return 'Укажите названия для этапов ' + stagesNumbers.map(stageNumber => '№' + stageNumber).join(', ');
        }
    }
    else if (validation.hasError('empty_stage_duration')) {
        const stages = validation.getErrorParam('empty_stage_duration', 'stages');
        return 'Укажите срок выполнения для этапов: ' + _.pluck(stages, 'name').join(', ');
    }
    else if (validation.hasError('unconfirmed_stages_changes')) {
        return 'Подтвердите изменения в этапах плана лечения';
    }
    else {
        return (
            <>
                Разделите <span className="btn-label-link" onClick={attractToQuantityDivide}>нераспределённое количество услуг</span> по направлениям
            </>
        );
    }
}

SaveButtonLabel.defaultProps = {
    successLabel: ''
};

