import React from "react";
import Radio from "../Radio";
import Box from "../../../Layout/Box";

interface PropsYesAndNoRadio {
    checked: boolean|null,
    onChange: (value: boolean) => void,
    isError?: boolean
}

export default function YesAndNoRadio({checked, onChange, isError}: PropsYesAndNoRadio) {

    return (
        <Box display="flex">
            <Radio value={'yesAndNoRadio'} checked={checked === null ? false : checked} children="Да" margin="0 15px 0 0"
                   error={isError}
                   onClick={() => {
                       if (checked === false || checked === null) onChange(true)
                   }}/>
            <Radio value={'yesAndNoRadio'} checked={checked === null ? false : !checked} children="Нет" margin="0"
                   error={isError}
                   onClick={() => {
                       if (checked === true || checked === null) onChange(false)
                   }}/>
        </Box>
    )
}
