import IntersectionCatcher from "../IntersectionCatcher/IntersectionCatcher";

const intersectionCatcher = new IntersectionCatcher();

const Scroll = {
    elementIntoView: function(target, scrollOptions = {}, waitTime = 0) {
        scrollOptions = Object.assign({behavior: 'smooth'}, scrollOptions);

        return new Promise(resolve => {
            target.scrollIntoView(scrollOptions);
            const catchHandler = (waitTime > 0) ? () => setTimeout(resolve, waitTime) : resolve;
            intersectionCatcher.watchTarget(target, catchHandler);
        });
    }
};

export default Scroll;