import {useState} from "react";
import {ScriptType, ScriptGroupList} from "../../../types/common";
import _ from "lodash";

interface Props {
    groupsScript: {
        [key: string]: ScriptGroupList[]
    }
}

const useSelectedScript = ({groupsScript}: Props) => {

    const [selectedScript, setSelectedScript] = useState<ScriptType | null>(null)

    const onChangeSelectedScript = (scriptId: number) => {
        let newSelectedScript = null;
        _.forEach(groupsScript, (group) => {
            _.forEach(group, (values) => {
                const findScript = values.scriptList.find(script => script.id === scriptId);
                if (!!findScript) {
                    newSelectedScript = findScript
                }
            })
        })
        setSelectedScript(newSelectedScript)
    }
    return {
        selectedScript,
        setSelectedScript,
        onChangeSelectedScript
    }
}

export default useSelectedScript
