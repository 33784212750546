import React, {forwardRef, useEffect} from "react";
import Paper from "../../containers/UI-Collection/Elements/Surfaces/Paper";
import Scrollbars from "../../components-ui/Scrollbars/scrollbars";
import {List, ListItem, SNoResultListItem} from "../../components-ui/DataDisplay/List";
import Helper from "../../common/helpers/main";
import {PatientValues} from "../../containers/PatientField";
import {SResultSearchPatient} from "./styledResultSearchPatient";
import Loader from "../../components-ui/Feedback/Loader/Loader";

interface PResultSearchPatient {
    patientList: PatientValues[] | null,
    displayPatientList: boolean,
    loaderPatientList: boolean,
    onClickPatient: (patient: PatientValues) => void,
    topPosition?: number,
    minWidth?: string
}

const ResultSearchPatient = forwardRef((props: PResultSearchPatient, ref: any) => {
    const {patientList, onClickPatient, topPosition, minWidth, displayPatientList, loaderPatientList} = props;

    useEffect(() => {
        const scrollAreaContent = document.querySelector('.result-search-patient-scroll .scrollarea-content') as any;
        if (scrollAreaContent !== null) scrollAreaContent.style = {marginTop: '0px !important'};
    }, [patientList])

    if (!displayPatientList || !(!!patientList)) return null

    return (
        <SResultSearchPatient ref={ref} topPosition={topPosition} minWidth={minWidth}>
            <Paper>
                <Scrollbars style={{height: 'max-content', maxHeight: '340px'}}
                            className="result-search-patient-scroll"
                            color='black'>
                    <List>
                        {loaderPatientList ?
                            (<Loader visible={true}/>)
                            : !!patientList.length ?
                                patientList.map(patient => {
                                    return (
                                        <ListItem key={patient.id} onClick={() => onClickPatient(patient)}>
                                            {`${Helper.Phone.format(patient.personalPhone)} (${patient.lastName} ${patient.name} ${patient.secondName})`}
                                        </ListItem>
                                    )
                                })
                                : (
                                    <ListItem>
                                        <SNoResultListItem children="Нет вариантов"/>
                                    </ListItem>
                                )
                        }
                    </List>
                </Scrollbars>
            </Paper>
        </SResultSearchPatient>
    )
})

export default ResultSearchPatient;

