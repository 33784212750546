import React from 'react'
import PropTypes from 'prop-types'
import Select from "../../../components-ui/Inputs/Select/Select";

export default class TableSelect extends React.Component {

    static propTypes = {
        cell: PropTypes.object.isRequired,
        row: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired
    };

    render() {
        return (
            <div className="table__cell-select" onClick={(e) => e.stopPropagation()}>
                <Select options={this.props.cell.content.options}
                        value={this.props.cell.content.current}
                        onChange={(selectTable) => {
                            this.props.onChange(this.props.row.code, this.props.cell.code, selectTable);
                        }}
                />

            </div>
        )
    }
}
