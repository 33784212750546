import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const OutlineArrowDownIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 21"} ref={ref}>
            <path d="M12,21a.76.76,0,0,1-.65-.37L.1,1.13A.77.77,0,0,1,.1.38.73.73,0,0,1,.75,0h22.5a.73.73,0,0,1,.65.38.77.77,0,0,1,0,.75L12.65,20.63A.74.74,0,0,1,12,21ZM2.05,1.5,12,18.75,22,1.5Z"/>
        </WrapIcon>
    );
});
