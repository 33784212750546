import React, {Dispatch, SetStateAction} from 'react';
import useChangeField from "../../hooks/useChangeField";
import {IDirectionField} from "../../types";
import {IPaymentCreationFormValues, IStatePaymentCreationForm} from "../../../../types";
import {Label} from "../../../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonTextFieldOption} from "../../../../data";
import {WrapPaymentCreationForm} from "../../../../../../PatientCard/Section/Patient/styledPatient";
import TextField from "../../../../../../../components-ui/Inputs/TextField/TextField";
import Helper from "../../../../../../../common/helpers/main";

interface Props {
    setState: Dispatch<SetStateAction<IStatePaymentCreationForm>>
    field: IDirectionField
    values: IPaymentCreationFormValues,
}

const DirectionField = ({setState, field, values}: Props) => {
    const handleField = useChangeField({field, setState})
    return (
        <Label labelText={field.name} isRequired={true} {...commonLabelOption}>
            <WrapPaymentCreationForm>
                <TextField
                    {...commonTextFieldOption}
                    value={values[field.code]}
                    isNumber={true}
                    min={0}
                    onChange={handleField}/>
                {!!field.pay &&
                    <div>
                        Осталось оплатить {Helper.String.numberFormat(field.pay.sum - field.pay.paid)} руб.
                    </div>}
            </WrapPaymentCreationForm>
        </Label>
    )
};

export default DirectionField;