import {ParentNamePatientField} from "../types/common";
import { PropsUsePrepareValidationFields, ReturnUsePrepareValidationFields, ResultUsePrepareValidationFields } from "../types/hooks";



export const usePrepareValidationFields = ({fields}: PropsUsePrepareValidationFields): ReturnUsePrepareValidationFields => {

    return (form: string[], parentName?: ParentNamePatientField) : ResultUsePrepareValidationFields => {
        const result = {} as ResultUsePrepareValidationFields
        form.forEach(formField => {
            if (fields[formField].required) result[formField] = false
            if (parentName === "Preparation") {
                switch (formField) {
                    case "passportSn":
                    case "passportIssuedBy":
                    case "passportIssuedDate":
                    case "personalCity":
                        result[formField] = false
                        break;
                    default:
                        break;
                }
            }
        })
        return result
    }
}
