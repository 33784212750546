import React from 'react';
import TreatmentPlanSection from "./TreatmentPlanSection";
import {useDialogsManager} from "../../../../containers/DialogsManager/DialogsManager";

interface Props {
    patientId: number
    initialPlanType?: string
}

const TreatmentPlanSectionWrap = ({patientId, initialPlanType}: Props) => {

    const {showSlideModal} = useDialogsManager()

    return (
        <TreatmentPlanSection
            showSlideModal={showSlideModal}
            patientId={patientId}
            initialPlanType={initialPlanType}/>
    );
};

export default TreatmentPlanSectionWrap;