
export default function getSplitValue(value: string) {
    if (value !== '' && value !== undefined && value !== '-' && value !== null && value !== 'null' && value !== 'undefined' && value.length === 10) {
        const splitValue = value.split('-');
        const day = splitValue[2].length === 4 ? splitValue[0] : splitValue[2];
        const month = splitValue[1];
        const year = splitValue[0].length === 4 ? splitValue[0] : splitValue[2];
        return {
            day, month, year
        }
    } else {
        return {
            day: '', month: '', year: ''
        }
    }

}
