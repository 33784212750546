import {useMemo} from "react";
import Helper from "../../../common/helpers/main";

interface Props {
    startDate: string
    isDay?: boolean
}

const useFormatXDate = ({startDate, isDay = true}: Props) => {

    return useMemo(() => {
        const startYear = Helper.Date.formatDate(startDate, 'YYYY')
        const currentYear = Helper.Date.formatDate(new Date() as any, 'YYYY')
        return (date: string) => {
            if (currentYear === startYear) {
                return Helper.Date.formatDate(date, isDay ? 'DD MMM' : 'MMM')
            } else {
                return Helper.Date.formatDate(date, isDay ? 'DD.MM.YYг.' : 'MM.YYг.')
            }

        }
    }, [startDate])
}

export default useFormatXDate
