import React, {FC} from 'react';
import Box from "../../../components-ui/Layout/Box";

const FilterWidgetWrap: FC = ({children}) => {
    return (
        <Box display="flex" justifyContent="flex-end" height="34px" alignItems="center" margin="0">
            {children}
        </Box>
    );
};

export default FilterWidgetWrap;
