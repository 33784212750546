import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const ArrowIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 13.37"} ref={ref}>
            <path d="M21.57.42a1.44,1.44,0,0,1,2,0,1.35,1.35,0,0,1,0,1.95L13,13a1.44,1.44,0,0,1-2,0L.4,2.34A1.36,1.36,0,0,1,.39.43l0,0a1.44,1.44,0,0,1,2,0L12,10Z"/>
        </WrapIcon>
    );
});
