import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonSelectOption} from "../../data";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {IStateCashCreate} from "../../types";
import {useSelector} from "react-redux";

interface Props {
    state: IStateCashCreate
    setState: Dispatch<SetStateAction<IStateCashCreate>>
}

const CashBox = ({state, setState}: Props) => {

    const cashboxOptions = useSelector(state => state.common.cashboxOptions);

    const handleCashbox = useCallback((selectedCashbox: OptionSelect) => {
        setState(prevState => ({...prevState, selectedCashbox}))
    }, [setState])
    return (
        <Label labelText="Касса" {...commonLabelOption} >
            <Select
                {...commonSelectOption}
                options={cashboxOptions}
                value={state.selectedCashbox}
                onChange={handleCashbox}
            />
        </Label>
    );
};

export default CashBox;
