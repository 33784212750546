import * as React from 'react'
import {RowButton, RowDate, RowItem, RowName, RowNumber, Row} from "../styledStages";
import Button from "../../../components-ui/Inputs/Button/Button";

interface PHeaderStages {
    confirmChanges: () => void,
    hasUnconfirmedChanges: boolean
}

export default function HeaderStages({confirmChanges, hasUnconfirmedChanges}: PHeaderStages) {
    return (
        <Row isHeader>
            <RowNumber children="#"/>
            <RowItem>
                <RowName>
                    Этап
                    {/*<Button variant="circle" style={{marginLeft: '12px'}}>*/}
                    {/*    <SVG name="plus" className="button-plus"/>*/}
                    {/*</Button>*/}
                </RowName>
                <RowDate>
                    Срок выполнения
                </RowDate>
                <RowButton>
                    {hasUnconfirmedChanges && (
                        <Button variant="success" size="sm" onClick={() => {confirmChanges()}}>
                            Подтвердить изменения
                        </Button>
                    )}

                </RowButton>
            </RowItem>
        </Row>
    )
}
