import React from 'react';
import InvoiceList from "./InvoiceList";
import {useDialogsManager} from "../../../../containers/DialogsManager/DialogsManager";

interface Props {
    patientId: number
}

const InvoiceListWrap = ({patientId}: Props) => {

    const {showSlideModal} = useDialogsManager()

    return (
        <InvoiceList showSlideModal={showSlideModal} patientId={patientId}/>
    );
};

export default InvoiceListWrap;