import {useState} from "react";
import {HandlersLogin, StateLogin} from "../types";

const useStateLogin = () : {state: StateLogin, handlers: HandlersLogin} => {
    const [state, setState] = useState<StateLogin>({
        login: '',
        password: '',
        incorrectData: null,
        errorCode: ''
    })

    return {
        state,
        handlers: {
            setLogin: (login: string) => setState(prevState => ({...prevState, login})),
            setPassword: (password: string) => setState(prevState => ({...prevState, password})),
            setIncorrectData: (incorrectData: any) => setState(prevState => ({...prevState, incorrectData})),
            setErrorCode: (errorCode: string) => setState(prevState => ({...prevState, errorCode}))
        }
    }
}

export default useStateLogin