import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {EditActualAccountParams, GetPaymentsCheckFormResult, IPaymentsFilters} from '../../../types';
import DateHelper from '../../../../../common/helpers/date-helper';
import PriceHelper from '../../../../../common/helpers/price-helper';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import {useSelector} from 'react-redux';
import Helper from '../../../../../common/helpers/main';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import {IPaymentRow, IPaymentService} from '../types';
import {OptionSelect} from '../../../../../components-ui/Inputs/Select/typesSelect';
import {useDevice} from '../../../../../common/hooks/useDevice';
import {
    DEFAULT_PADDING_CELLS,
    DEFAULT_PADDING_HEADER_CELLS,
    END_PADDING_CELL,
    END_PADDING_HEADER_CELLS,
} from '../../../../../components-ui/DataDisplay/Table/common';
import {InnerTable, CustomCell} from '../../../../../components-ui/DataDisplay/Table/style/scrollStyledComponents';
import {WIDTH_TABLE_COLUMNS} from '../common';


interface Props {
    data: GetPaymentsCheckFormResult;
    selectedBranch: OptionSelect;
    filters: IPaymentsFilters;
    refreshDataFromServer: () => void,
}

const useTableFormatter = ({data, selectedBranch, filters, refreshDataFromServer}: Props) => {

    const device = useDevice();

    const currentDate = DateHelper.formatDateForServer(new Date());

    const {showSlideModal, showModal} = useDialogsManager();

    const paymentMethods = useSelector(state => state.common.paymentMethods);

    const servicesNames = useSelector(state => state.common.servicesNames);

    const doctors = useSelector(state => state.common.doctors);

    const expenditures = useSelector(state => state.common.expenditures);

    const [paymentRows, setPaymentRows] = useState<IPaymentRow[]>([]);
    const [inflowRows, setInflowRows] = useState<IPaymentRow[]>([]);
    const [outflowRows, setOutflowRows] = useState<IPaymentRow[]>([]);

    const scrollClassName = 'tablePaymentsScroll';

    const handleScroll = useCallback((scrollPosition: number) => {
        document.querySelectorAll(`.${scrollClassName}`).forEach((item) => {
            item.scrollLeft = scrollPosition;
        });
    }, []);

    const paymentColumns: IColumn<IPaymentRow>[] = useMemo(() => [
        {
            field: 'date',
            headerName: 'Дата',
            width: WIDTH_TABLE_COLUMNS.date,
            paddingCell: DEFAULT_PADDING_CELLS,
            paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
        },
        // {
        //     field: 'payment',
        //     headerName: 'Дата',
        //     headerFormatter: () => <TableScrollHeaderComponent scrollClassName={scrollClassName}
        //                                                        handleScroll={handleScroll} />,
        //     width: '400px',
        //     customStyles: {flexGrow: 1},
        //     paddingCell: '',
        //     paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
        //     valueFormatter: (({row}) =>
        //         <InnerTable scrollClassName={scrollClassName} handleScroll={handleScroll}>
        //             <CustomCell padding='0 0 0 1px' width={'100%'} isGrow={true}>
        //             {row.payment.map((payment) =>
        //                 <Box padding='0 5px 0 25px !important'
        //                      display='flex'
        //                      minWidth={`calc(${WIDTH_TABLE_COLUMNS.type} + ${WIDTH_TABLE_COLUMNS.fio} + ${WIDTH_TABLE_COLUMNS.services} + ${WIDTH_TABLE_COLUMNS.sum} + ${WIDTH_TABLE_COLUMNS.sum});`}
        //                      height='inherit'
        //                      justifyContent='space-between'
        //                      alignItems='stretch'>
        //
        //                     <Typography padding='12px 5px 10px 20px' variant='small' width={WIDTH_TABLE_COLUMNS.type}>
        //                         {payment.type}
        //                     </Typography>
        //                     <Typography padding='12px 5px 10px 20px' variant='small' width={WIDTH_TABLE_COLUMNS.fio}>
        //                         {payment.employee}
        //                     </Typography>
        //                     {!!payment.detail.expenditure && (
        //                         <Box minWidth={WIDTH_TABLE_COLUMNS.services}
        //                              flexGrow={1}
        //                         >
        //                             <Typography padding='12px 5px 10px 20px' width={WIDTH_TABLE_COLUMNS.services} variant='small'>
        //                                 {expenditures.find((expenditure) => expenditure.id == payment.detail.expenditure)?.name}: <i>{payment.detail.comment}</i>
        //                             </Typography>
        //                         </Box>
        //                     )}
        //                     {payment.detail.services != undefined && (payment.detail.services.length > 0 ? (
        //                         <CustomCell padding='0 0 0 1px' width={WIDTH_TABLE_COLUMNS.services} isGrow={true}>
        //                             {payment.detail.services.map((serviceId: number) => {
        //                                 return (
        //                                     <Typography variant='small'>
        //                                         {servicesNames[serviceId]}
        //                                     </Typography>
        //                                 )
        //                             })}
        //                         </CustomCell>
        //                     ) : (
        //                         <Box minWidth={WIDTH_TABLE_COLUMNS.services}
        //                              flexGrow={1}
        //                         >
        //                             <Typography padding='12px 5px 10px 20px' width={WIDTH_TABLE_COLUMNS.services} variant='small'>
        //                                 Аванс
        //                             </Typography>
        //                         </Box>
        //                     ))}
        //                     <Typography padding='12px 5px 10px 20px' variant='small' width={WIDTH_TABLE_COLUMNS.sum}>
        //                         {PriceHelper.format(payment.cashboxMain)}
        //                     </Typography>
        //                     <Typography padding='12px 5px 10px 20px' variant='small' width={WIDTH_TABLE_COLUMNS.sum}>
        //                         {PriceHelper.format(payment.cashboxAdditional)}
        //                     </Typography>
        //                 </Box>
        //             )}
        //             </CustomCell>
        //         </InnerTable>
        //     )
        // },
        {
            field: 'type',
            headerName: 'Тип операции',
            width: WIDTH_TABLE_COLUMNS.type,
            paddingCell: '',
            paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
            valueFormatter: (({row}) =>
                    <CustomCell padding='0 0 0 1px' width={WIDTH_TABLE_COLUMNS.type}>
                        {row.payment.map((payment) =>
                            <Typography variant='small'>
                                {payment.type}
                            </Typography>
                        )}
                    </CustomCell>
            ),
        },
        {
            field: 'employee',
            headerName: 'Пользователь/Пациент',
            width: WIDTH_TABLE_COLUMNS.fio,
            paddingCell: '',
            paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
            valueFormatter: (({row}) =>
                    <CustomCell padding='0 0 0 1px' width={WIDTH_TABLE_COLUMNS.fio}>
                        {row.payment.map((payment) =>
                            <Typography variant='small'>
                                {payment.employee}
                            </Typography>
                        )}
                    </CustomCell>
            ),
        },
        {
            field: 'detail',
            headerName: 'Детально',
            width: WIDTH_TABLE_COLUMNS.fio,
            paddingCell: '',
            customStyles: {flexGrow: 1},
            paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
            valueFormatter: (({row}) =>
                    <CustomCell padding='0 0 0 1px' width={'auto'} isGrow={true}>
                        {row.payment.map((payment) => {
                            if (!!payment.detail.expenditure) {
                                return (
                                    <Typography variant='small'>
                                        {expenditures.find((expenditure) => expenditure.id == payment.detail.expenditure)?.name}: <i>{payment.detail.comment}</i>
                                    </Typography>
                                )
                            } else if (!!payment.detail.services) {
                                if (payment.detail.services.length > 0) {
                                    return (
                                        <>
                                            {payment.detail.services.map((serviceId: number) => {
                                                return (
                                                    <Typography variant='small'>
                                                        {servicesNames[serviceId]}
                                                    </Typography>
                                                )
                                            })}
                                        </>
                                    )
                                } else {
                                    return (
                                        <Typography variant='small'>
                                            Аванс
                                        </Typography>
                                    )
                                }
                            } else {
                                return (
                                    <Typography variant='small'>
                                    </Typography>
                                )
                            }
                        })}
                    </CustomCell>
            ),
        },
        {
            field: 'cashboxMain',
            hide: true,
            headerName: 'Основная',
            width: WIDTH_TABLE_COLUMNS.sum,
            paddingCell: '',
            paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
            valueFormatter: (({row}) =>
                    <CustomCell padding='0 0 0 1px' width={WIDTH_TABLE_COLUMNS.sum}>
                        {row.payment.map((payment) =>
                            <Typography variant='small'>
                                {PriceHelper.format(payment.cashboxMain)}
                            </Typography>
                        )}
                    </CustomCell>
            ),
        },
        {
            field: 'cashboxAdditional',
            headerName: 'Дополнительная',
            width: WIDTH_TABLE_COLUMNS.sum,
            paddingCell: '',
            paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
            valueFormatter: (({row}) =>
                    <CustomCell padding='0 0 0 1px' width={WIDTH_TABLE_COLUMNS.sum}>
                        {row.payment.map((payment) =>
                            <Typography variant='small'>
                                {PriceHelper.format(payment.cashboxAdditional)}
                            </Typography>
                        )}
                    </CustomCell>
            ),
        },
    ], [currentDate, refreshDataFromServer]);

    const getTypeString = (inflow: boolean, type: string) => {
        if (inflow) {
            switch (type) {
                case 'payment':
                    return 'Оплата услуг'
                case 'user':
                    return 'Внесение пользователем'
                case 'partner':
                    return 'Внесение контрагентом'
            }
        } else {
            switch (type) {
                case 'payment':
                    return 'Возврат аванса'
                case 'user':
                    return 'Выдача пользователю'
                case 'partner':
                    return 'Выдача контрагенту'
                case 'salary':
                    return 'Выдача зарплаты'
            }
        }
        return ''
    }

    useEffect(() => {
        const defaultPaymentRows = [
            {
                date: 'Доход денежных средств',
                payment: [
                    {
                        type: '',
                        employee: '',
                        detail: '',
                        cashboxMain: (() => {
                            let sum = 0

                            data.inflow
                                .map((day) => {
                                    {
                                        day.payment.map((payment) => {
                                            sum += payment.cashboxMain
                                        })
                                    }
                                })

                            return sum
                        })(),
                        cashboxAdditional: (() => {
                            let sum = 0

                            data.inflow
                                .map((day) => {
                                    {
                                        day.payment.map((payment) => {
                                            sum += payment.cashboxAdditional
                                        })
                                    }
                                })

                            return sum
                        })()
                    }
                ]
            }
        ] as IPaymentRow[]
        handleScroll(0);
        setInflowRows(data.inflow
            .map((day) => {
                return ({
                    id: DateHelper.formatDate(day.date, 'DDMMYYYY'),
                    date: DateHelper.formatDate(day.date, 'DD MMM YYYY'),
                    payment: day.payment.map((payment) => {
                        return {
                            type: getTypeString(payment.inflow, payment.type),
                            employee: payment.employee ? Helper.getFullName(payment.employee) : '-',
                            detail: payment.detail,
                            cashboxMain: payment.cashboxMain,
                            cashboxAdditional: payment.cashboxAdditional
                        }
                    })
                });
            }));
        setOutflowRows(data.outflow
            .map((day) => {
                return ({
                    id: DateHelper.formatDate(day.date, 'DDMMYYYY'),
                    date: DateHelper.formatDate(day.date, 'DD MMM YYYY'),
                    payment: day.payment.map((payment) => {
                        return {
                            type: getTypeString(payment.inflow, payment.type),
                            employee: payment.employee ? Helper.getFullName(payment.employee) : '-',
                            detail: payment.detail,
                            cashboxMain: payment.cashboxMain,
                            cashboxAdditional: payment.cashboxAdditional
                        }
                    })
                });
            }))
    }, [data.inflow, data.outflow, filters]);

    return {inflowRows, outflowRows, paymentRows, paymentColumns};
};

export default useTableFormatter;
