export const weeks = [
    {name: 'Четная неделя', code: 'even'},
    {name: 'Нечетная', code: 'odd'}
];

export const daysWeek = [
    {name: 'Пн', code: '1'},
    {name: 'Вт', code: '2'},
    {name: 'Ср', code: '3'},
    {name: 'Чт', code: '4'},
    {name: 'Пт', code: '5'},
    {name: 'Сб', code: '6'},
    {name: 'Вс', code: '7'},
];

export interface PMonths {
    value: string,
    label: string,
    days: number,
    isDisabled: boolean
}

export const months = [
    {value: '01', label: 'Январь', days: 31, isDisabled: false},
    {value: '02', label: 'Февраль', days: 29, isDisabled: false},
    {value: '03', label: 'Март', days: 31, isDisabled: false},
    {value: '04', label: 'Апрель', days: 30, isDisabled: false},
    {value: '05', label: 'Май', days: 31, isDisabled: false},
    {value: '06', label: 'Июнь', days: 30, isDisabled: false},
    {value: '07', label: 'Июль', days: 31, isDisabled: false},
    {value: '08', label: 'Август', days: 31, isDisabled: false},
    {value: '09', label: 'Сентябрь', days: 30, isDisabled: false},
    {value: '10', label: 'Октябрь', days: 31, isDisabled: false},
    {value: '11', label: 'Ноябрь', days: 30, isDisabled: false},
    {value: '12', label: 'Декабрь', days: 31, isDisabled: false}
] as PMonths[];
