import {useEffect, useState} from "react";
import ServerCommand from "../../../../../common/server/server-command";
import {useSelector} from "react-redux";
import {echo} from "../../../../../common/server/echo";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {PatientChoiceFormResponse, TreatmentCompleteFormResponse} from "../types/response";
import {activeTab, NextVisit, StateForm} from "../types/common";
import {PropsUseInitial} from "../types/hooks";
import {CHOICE} from "../helper/constants";

export default function useInitial({visitId, parentName}: PropsUseInitial) {
    const user = useSelector(state => state.user);

    const [form, setForm] = useState<StateForm>({
        nextVisit: {
            comment: '',
            isExists: false,
            visitId: 0
        },
        postponeTreatmentReasons: {
            reasons: [],
            inputs: {}
        },
        refuseTreatmentReasons: {
            reasons: [],
            inputs: {}
        }
    })

    useEffect(() => {
        const resourceCode = parentName === CHOICE ? 'visit/patient-choice-form' : 'visit/treatment-complete-form';
        ServerCommand.get(resourceCode, {visitId})
            .then((response: PatientChoiceFormResponse | TreatmentCompleteFormResponse) => setForm(prevState => {
                if (response.nextVisit.visitId === visitId) prevState.nextVisit = response.nextVisit;
                if (!!response.postponeTreatmentReasons) {
                    prevState.postponeTreatmentReasons = {
                        inputs: response.postponeTreatmentReasons.inputs,
                        reasons: [{
                            optionSelect: null,
                            options: response.postponeTreatmentReasons.reasons
                        }]
                    }
                }
                if (!!response.refuseTreatmentReasons) {
                    prevState.refuseTreatmentReasons = {
                        inputs: response.refuseTreatmentReasons.inputs,
                        reasons: [{
                            optionSelect: null,
                            options: response.refuseTreatmentReasons.reasons
                        }]
                    }
                }
                if (response.isPlanFulfilled !== undefined) {
                    prevState.isPlanFulfilled = response.isPlanFulfilled
                }
                return {
                    ...prevState
                }
            }))

        echo.private('doctor-visits.' + user.doctorId)
            .listen('NextVisitUpdated', (response: NextVisit) => {
                if (response.visitId === visitId) {
                    setForm(prevState => {
                        return {
                            ...prevState,
                            nextVisit: response
                        }
                    })
                }

            });

        return () => {
            echo.private('doctor-visits.' + user.doctorId).stopListening('NextVisitUpdated');
        }

    }, []);

    const onChangeSelectedReason = (optionSelect: OptionSelect, index: number, activeTab: activeTab) => {
        setForm(prevState => {
            if (activeTab !== "nextVisit") {
                prevState[activeTab].reasons[index] = {
                    options: prevState[activeTab].reasons[index].options,
                    optionSelect
                }
                if (index <= prevState[activeTab].reasons.length - 1) {
                    prevState[activeTab].reasons = prevState[activeTab].reasons.filter((item, idx) => idx <= index)
                }
                if (!!optionSelect.subReasons) {
                    prevState[activeTab].reasons.push({
                        optionSelect: null,
                        options: optionSelect.subReasons
                    })
                }
            }
            return {
                ...prevState
            }
        })
    }

    const onChangeComment = (comment: string) => {
        setForm(prevState => {
            prevState.nextVisit.comment = comment;
            return {...prevState}
        })
    }

    const onChangeReason = (value: any, activeTab: activeTab, inputName: string) => {
        setForm(prevState => {
            if (activeTab !== "nextVisit") prevState[activeTab].inputs[inputName].value = value;
            return {
                ...prevState
            }
        })
    }

    return {
        form,
        setForm,
        onChangeSelectedReason,
        onChangeComment,
        onChangeReason
    }
}
