import React from "react";
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {buttonTitles} from "../../helper/getDataCallCenter";
import SVG from "../../../../common/helpers/SVG";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Box from "../../../../components-ui/Layout/Box";
import {CommonButtonProps} from "../../types/components";

interface Props extends CommonButtonProps {
    blockedButtons: boolean
}

export const DeleteWLButton = ({task, blockedButtons, margin, methodsCallCenter}: Props) => {
    const {deleteWaitingList} = methodsCallCenter
    return (
        <Box className="button-task-content" margin={margin}>
            <Button {...commonPropsButton} variant={blockedButtons ? 'disabled' : "danger"}
                    title={blockedButtons ? buttonTitles.blocked : buttonTitles.deleteWL}
                    onClick={() => deleteWaitingList(task.payload.recordId)}
                    children={<SVG name="call-center/deleteWL"
                                   className="call-center-deleteWL-button"/>}
            />
        </Box>
    )
}
