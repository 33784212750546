import styled from "styled-components";
import {Props} from "../index";

export interface SProps extends Props {
    ref: any
}


export const SWrapAlert = styled.div<SProps>`
    display: flex;
    align-items: flex-start;
    border-radius: ${props => props.borderRadius || '4px'};
    border: solid 1px ${props => props.theme.colors.warningDark};
    background-color: ${props => props.theme.colors.warning};
    padding: ${props => props.padding || '17px 20px 18px'};
    font-size: ${props => props.fontSize || '14px'};
    margin: ${props => props.margin || "0 0 44px"};
    ${props => !!props.width && `width: ${props.width};`};
`
