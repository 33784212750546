import preparePhoneOwnerName from "./preparePhoneOwnerName";
import {defaultOptionsPhoneOwner} from "./defaultOptionsPhoneOwner";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import {PatientValues} from "../types/common";


export default function prepareDataExistingPatient(list: PatientValues[], patient: PatientValues) {
    const currentPatient = list.find(item => item.id === patient.id) as PatientValues;
    const filterPatientList = list.filter(patient => patient.id !== currentPatient.id);
    const patientOptionsPhoneOwner = filterPatientList.map((patient, index) => {
        return {
            label: preparePhoneOwnerName(patient),
            value: patient.id,
            sort: index + 2
        }
    });
    const optionsPhoneOwner = defaultOptionsPhoneOwner.concat(patientOptionsPhoneOwner)

    let phoneOwner = null as null | OptionSelect;
    if (!!currentPatient.phoneOwner) {
        phoneOwner = optionsPhoneOwner.find(option => option.value === currentPatient.phoneOwner) as OptionSelect;
    }
    return {phoneOwner, filterPatientList, currentPatient, optionsPhoneOwner}
}
