import Helper from "../../../common/helpers/main";
import {DentalCardTooth} from "../types";

export const getJawButtonStatus = (configJaw: DentalCardTooth[]) => {
    let result = false;
    configJaw.forEach(tooth => {
        if (!Helper.TeethMap.isChildTooth(tooth.number)) result = true;
    })
    return result;
}
