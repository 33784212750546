import Helper from "../../../../common/helpers/main";

export default function prepareValueByType(value: any, type: string) {
    switch (type) {
        case "price":
            return Helper.Price.format(value)
        default:
            return value;
    }
}
