import {useMemo} from "react";
import _ from "lodash";
import {StateDetailed} from "../../../hooks/useDetailed";

interface Props {
    detailedState: StateDetailed
    selectedFilter: string
}

const useFilterCalls = ({selectedFilter, detailedState}: Props) => {
    const {historyData} = detailedState;
    const {completedCalls} = historyData;
    return useMemo(() => {
        let res = _.cloneDeep(completedCalls);
        if (selectedFilter !== 'all') {
            res = res.filter(call => call.tasksTypes.indexOf(selectedFilter) !== -1);
        }
        return res;
    }, [selectedFilter, JSON.stringify(completedCalls)])
}

export default useFilterCalls
