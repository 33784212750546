import React from 'react';
import TaskContent from "../TaskContent";
import {TaskCallCenter} from "../../../../types/common";
import {ConfirmVisitData} from "../../../../types/taskData";
import {UniquePatient, VisitDate, VisitTime} from '../../../../components/Labels';
import {AddWLButton, CanceledButton, ConfirmButton, TransferButton} from "../../../../components/Buttons";
import prepareIsDeadline from '../../../../helper/prepareIsDeadline';
import {CommonTaskProps} from "../../types/common";
import Helper from "../../../../../../common/helpers/main";
import {useDateUpdate} from "../../../../../../common/hooks";
import VisitBranch from "../../../../components/Labels/VisitBranch";

interface Props extends CommonTaskProps {
    task: TaskCallCenter<ConfirmVisitData>
}

export const ConfirmVisit = ({
                                 task,
                                 allDeadline,
                                 uniqPatient,
                                 methodsCallCenter,
                                 onChangeSelectedScript,
                                 ...props
                             }: Props) => {
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);
    return (
        <TaskContent onChangeSelectedScript={onChangeSelectedScript}
                     buttons={<Buttons task={task} {...props} methodsCallCenter={methodsCallCenter}/>} task={task}
                     isDeadline={prepareIsDeadline(allDeadline, task, currentDate)}>
            <VisitBranch branchId={task.payload.branchId}/>
            <VisitDate timeStart={task.payload.timeStart}/>
            <VisitTime timeStart={task.payload.timeStart} timeEnd={task.payload.timeEnd}/>
            <UniquePatient task={task} uniqPatient={uniqPatient}/>
        </TaskContent>
    );
};

const Buttons = ({
                     task,
                     blockedButtons,
                     validate,
                     personalPhone,
                     methodsCallCenter
                 }: Omit<Props, 'uniqPatient' | 'allDeadline' | 'onChangeSelectedScript'>) => {
    return (
        <>
            <ConfirmButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
            <TransferButton task={task} validate={validate} blockedButtons={blockedButtons}
                            methodsCallCenter={methodsCallCenter}/>
            <CanceledButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
            <AddWLButton task={task} personalPhone={personalPhone} blockedButtons={blockedButtons}
                         methodsCallCenter={methodsCallCenter}/>
        </>
    )
}
