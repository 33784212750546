import {ServiceGroup} from "../../../../../../types";
import {sortName} from "../../../../../../../../common/helpers/graph-helper";
import {ComponentDidMountTable, ObjRecommendedGroups, RecommendedService} from "../typesTableRecommendedServices";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {canPerformService} from "../../../../../../../../common/helpers/Doctor";
import Helper from "../../../../../../../../common/helpers/main";

export const useComponentDidMountTable = ({recommendedServices, services, servicesGroups, setRecommendedGroups} : ComponentDidMountTable) => {
    const user = useSelector(state => state.user);
    const [start, setStart] = useState(true);
    useEffect(() => {
        if (!!recommendedServices) {
            const newServices = Object.values(recommendedServices).map(service => {
                const doctor = canPerformService(user, services[service.serviceId]) ? {
                    value: user.id,
                    label: Helper.getFio(user),
                    color: user.color
                } : null;

                return {
                    id: service.id,
                    name: services[service.serviceId].name,
                    code: services[service.serviceId].code,
                    target: service.target,
                    groupId: services[service.serviceId].groupId,
                    directions: services[service.serviceId].directions,
                    doctor
                }
            }) as RecommendedService[];
            if (start) {
                newServices.forEach(service => service.checked = true);
            }
            const objRecommendedGroups = {} as ObjRecommendedGroups;

            const forEachServicesGroup = (groups: ServiceGroup[], parentGroup: ServiceGroup) => {
                groups.forEach(group => prepareRecommendedGroups(group, parentGroup))
            }

            const prepareRecommendedGroups = (group: ServiceGroup, parentGroup: ServiceGroup) => {
                const findServices = newServices.filter((elem) => elem.groupId === group.id);
                if (findServices.length > 0) {
                    if (objRecommendedGroups[parentGroup.id] === undefined) {
                        objRecommendedGroups[parentGroup.id] = {
                            groupName: parentGroup.name,
                            services: [],
                            id: parentGroup.id,
                            directions: parentGroup.directions,
                            doctor: null
                        }
                        if (start) objRecommendedGroups[parentGroup.id].checked = true;
                    }
                    findServices.forEach(findService => {
                        objRecommendedGroups[parentGroup.id].services.push(findService)
                    })

                }
                if (!!group.groups) forEachServicesGroup(group.groups, parentGroup);
            }

            servicesGroups.forEach((group) => prepareRecommendedGroups(group, group))

            const newRecommendedGroups = Object.values(objRecommendedGroups)
                .sort((a, b) => a.id > b.id ? 1 : -1);

            newRecommendedGroups.forEach(group => {
                group.services.sort((a, b) => sortName(a.name, b.name))
                const forAllServices = group.services.some(service => canPerformService(user, service));
                group.doctor = forAllServices ? {
                    value: user.id,
                    label: Helper.getFio(user),
                    color: user.color
                } : null as any;
            })

            if (start) setStart(false);

            setRecommendedGroups(newRecommendedGroups)
        }
    }, [recommendedServices])
}
