export const commonLabelOption = {
    widthLabelWrap: '420px',
    widthLabel: '125px',
    minWidthLabel: '33%',
    marginWrap: '0 0 20px'
}

export const commonSelectOption = {
    widthMenu: "355px",
    widthSelect: "355px",
}

export const commonTextFieldOption = {
    widthInput: "356px"
}
