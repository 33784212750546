import Box from "../../../../../../components-ui/Layout/Box";
import Avatar from "../../../../../../components-ui/DataDisplay/Avatar";
import React from "react";
import {useSelector} from "react-redux";

const useFormatterUser = () => {
    const users = useSelector(state => state.common.users)
    return (value: any) => {
        const user = users[value as any]
        if (!!user) {
            let color = '#00d6d6'
            if (!!user.color) color = user.color;
            return (
                <Box display="flex" alignItems="center">
                    <Avatar bgColor={color} margin="0 8px 0 0" size="16px" text={user.fio[0]}/>
                    <Box>{user.fio}</Box>
                </Box>
            )
        } else {
            return null
        }

    }
}

export default useFormatterUser
