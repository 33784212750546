import * as React from "react";
import {ServiceDirection} from "../../common/types/ServicesDirections";
import TooltipIcon from "../../components-ui/DataDisplay/TooltipIcon/TooltipIcon";

interface  PropsServiceDirectionName {
    direction: ServiceDirection
}

const ServiceDirectionName = ({direction}: PropsServiceDirectionName) => {
    return (
        <>
            {direction.name + ' '}
            {!!direction.tip && (
                <TooltipIcon tip={direction.tip}/>
            )}
        </>
    );
};

export default ServiceDirectionName;
