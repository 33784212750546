import React, {Dispatch, SetStateAction} from "react";
import {Divider} from "../../../../../../../components-ui/DataDisplay/Divider";
import Select from "../../../../../../../components-ui/Inputs/Select/Select";
import Typography from "../../../../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../../../../../../containers/Tasks/data/commonPropsSComponent";
import {StateDetailed} from "../../../hooks/useDetailed";
import {OptionSelect} from "../../../../../../../components-ui/Inputs/Select/typesSelect";
import { STaskList, STaskItem } from "../../../../../../../containers/Tasks/style";

interface Props {
    detailedState: StateDetailed
    setDetailedState: Dispatch<SetStateAction<StateDetailed>>
    directionsNames: OptionSelect[]
}

const ThemeProgressCall = ({detailedState, setDetailedState, directionsNames}: Props) => {
    if (!!detailedState.selectedType && (detailedState.selectedType.value === 'existing_patient' || detailedState.selectedType.value === 'new_patient')) {
        return(
            <>
                <Divider/>
                <STaskList padding="14px 0">
                    <STaskItem margin="2px 0">
                        <Typography {...commonPropsTypography}
                                    width="125px"
                                    children="Тема разговора"/>
                        <Select value={detailedState.selectedTheme}
                                onChange={selectedTheme => {
                                    setDetailedState(prevState => ({
                                        ...prevState,
                                        selectedTheme,
                                        validationAfterSave: true
                                    }))
                                }}
                                options={directionsNames}
                                placeholder="не выбрана"
                                sizeSelect="sm"
                                widthSelect="215px"
                                isMulti={true}
                        />
                    </STaskItem>
                </STaskList>
            </>
        )
    } else {
        return null
    }
}

export default ThemeProgressCall
