import {useMemo} from "react";
import {PropsUseInitialStateForm} from "../types/hooks";
import {
    defaultOptionsPhoneOwner, IPatientField,
    usePrepareStateForm,
    usePrepareValidationFields
} from "../../../../../containers/PatientField";
import _ from 'lodash'
import {EmptyForm, StateForms, StatePreparationForAdmission} from "../types/common";
import preparePhoneOwnerName from "../../../../../containers/PatientField/helper/preparePhoneOwnerName";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {defaultTabs} from "../helper/defaultTabs";
import useValidateForm from "../../../../../containers/PatientField/hooks/useValidateForm";
import prepareVisibleFields from "../helper/prepareVisibleFields";

type StateName = 'patient' | 'representative'
type FormFields = {
    [key in StateName]: string[]
}

export default function useInitialStateForm({data, fields}: PropsUseInitialStateForm) {
    const prepareStateForm = usePrepareStateForm({fields});
    const prepareValidationFields = usePrepareValidationFields({fields});
    const validateForm = useValidateForm({fields});

    return useMemo(() => {
        const formsFields = {} as FormFields;
        const forms = {} as StateForms
        const state = {
            currentTab: 'patient',
            checkboxes: {},
            tabs: [],
            patient: data.patient,
            documentRequired: {}
        } as StatePreparationForAdmission
        const emptyForms = {} as EmptyForm;

        data.documents.forEach(document => {
            state.checkboxes[document.type] = document.isRequired
            state.documentRequired[document.type] = document.isRequired
            _.forEach(document.usedFields, (fieldNames, key) => {
                const keyName = key as StateName;
                if (formsFields[keyName] === undefined) formsFields[keyName] = []
                if (!!fieldNames) formsFields[keyName] = _.concat(formsFields[keyName], fieldNames)
            })
        })

        _.forEach(formsFields, (fieldNames, formName) => {
            const uniqFieldNames = _.uniq(fieldNames) as string[];
            const currentPatient = data.data[formName];
            if (emptyForms[formName] === undefined) {
                emptyForms[formName] = {
                    basic: {
                        optionsPhoneOwner: [],
                        validationFields: {}
                    },
                    relations: {
                        relationsList: []
                    }
                }
            }
            if (forms[formName] === undefined) {
                forms[formName] = {
                    basic: {
                        optionsPhoneOwner: [],
                        validationFields: {}
                    },
                    currentPatient,
                    visibleFields: prepareVisibleFields({
                        documents: data.documents,
                        checkboxes: state.checkboxes,
                        formName
                    }),
                    relations: {
                        relationsList: []
                    }
                }
            }
            let optionsPhoneOwner = _.cloneDeep(defaultOptionsPhoneOwner)
            if (!!currentPatient && currentPatient.samePhonePatients.length) {
                const samePhonePatients = currentPatient.samePhonePatients
                optionsPhoneOwner = optionsPhoneOwner.concat(samePhonePatients.map((patient, index) => {
                    return {
                        label: preparePhoneOwnerName(patient),
                        value: patient.id,
                        sort: index + 3
                    }
                }))
            }
            emptyForms[formName].basic = {
                ...prepareStateForm(uniqFieldNames),
                optionsPhoneOwner,
                validationFields: {
                    ...prepareValidationFields(uniqFieldNames, 'Preparation')
                }
            }
            forms[formName].basic = _.cloneDeep(emptyForms[formName].basic)
            if (!!currentPatient) {
                uniqFieldNames.forEach(fieldName => {
                    const field = fields[fieldName] as IPatientField
                    if (!!currentPatient[fieldName]) {
                        forms[formName].basic[fieldName] = currentPatient[fieldName]
                        switch (field.type) {
                            case "enum":
                                if (fieldName === 'phoneOwner') {
                                    forms[formName].basic[fieldName] = forms[formName].basic.optionsPhoneOwner.find((option: OptionSelect) => option.value === currentPatient[fieldName])
                                } else {
                                    if (!!field.variants && !!field.variants.length) {
                                        const findEnumValue = field.variants.find(variant => variant.value === currentPatient[fieldName])
                                        if (!!findEnumValue) {
                                            forms[formName].basic[fieldName] = findEnumValue
                                        }
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                        if (fieldName === 'passportIssuedDate') {
                            currentPatient[fieldName] = !!currentPatient[fieldName] ? currentPatient[fieldName].split(' ')[0] : ''
                            forms[formName].basic[fieldName] = currentPatient[fieldName]
                        }
                    }
                })
            } else {
                if (formName !== 'patient') {
                    emptyForms[formName].basic.relation = null
                }
            }
            const findTab = defaultTabs.find(tab => tab.code === formName);
            if (!!findTab) {
                if (forms[formName].visibleFields.length) {
                    findTab.warning = !validateForm(forms[formName].basic, forms[formName].visibleFields)
                }
                state.tabs.push(findTab);
            }
        })

        return {forms, state, emptyForms}
    }, [JSON.stringify(data)])
}
