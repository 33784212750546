import styled from "styled-components";
import Helper from "../../../common/helpers/main";

interface PropsWrapLegendItem {
    color?: string
    isHover?: boolean
}

export const WrapLegendItem = styled.span<PropsWrapLegendItem>`
    display: flex;
    position: relative;
    width: max-content;
    height: max-content;
    margin-right: 12px;
    &::after {
        content: '';
        position: absolute;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        top: -4px;
        left: -4px;
        border-radius: 50%;
        z-index: -1;
        transition: background-color .3s;
        background: ${props => props.isHover ? `rgba(${Helper.Color.hexToRgb(props.color)}, 0.5)` : 'transparent'};
    }
`


