import React, {useState} from "react";
import {PDiagnoses, PPerformedServices} from "../typesInfo";
import {TitleInfo, WrapDateAndText, ServiceDate, ServiceText, ServiceTextChild, WrapService} from "../styledInfo";
import SVG from "../../../../../common/helpers/SVG";
import Helper from "../../../../../common/helpers/main";

export default function Diagnoses({diagnoses}: PDiagnoses) {
    const [open, setOpen] = useState(true);

    if (!(!!diagnoses)) return null;

    return (
        <WrapService>
            <TitleInfo open={open} onClick={() => setOpen(!open)}>
                <div className="title-info-text">Диагнозы</div>
                <SVG name="triangle" className="title-info-triangle"/>
            </TitleInfo>
            {open && diagnoses.map((diagnosis, index) => {
                const text = diagnosis.text as string[];
                return (
                    <WrapDateAndText key={'performed' + text.join(',')}>
                        <ServiceText>
                            {text.map(txt => {
                                return (
                                    <ServiceTextChild key={txt}>
                                        {txt}
                                    </ServiceTextChild>
                                )
                            })}

                        </ServiceText>
                        <ServiceDate>
                            {Helper.Date.formatDate(diagnosis.date, 'll')}
                        </ServiceDate>
                    </WrapDateAndText>
                )
            })}
        </WrapService>
    )
}


