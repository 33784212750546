import React from 'react';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import useTableFormatter from './useTableFormatter';
import {HandsetList} from '../../types';
import Table from '../../../../../components-ui/DataDisplay/Table';
import { DebouncedFunc } from 'lodash';
import AddingEditingHandset from "../modals/AddingEditingHandset";

interface Props {
    data: HandsetList[]
    refreshDataFromServer: () => void
}

const HandsetTable = ({data, refreshDataFromServer}: Props) => {

    const {showSlideModal} = useDialogsManager();

    const {handsetColumn} = useTableFormatter()

    return (
            <Table
                variantTable="outline"
                maxHeight="520px"
                columns={handsetColumn}
                rows={data}
                onClickRow={(row)=> showSlideModal(<AddingEditingHandset handsetId={row.id} detail={data.find(handset => handset.id == row.id)} refreshDataFromServer={refreshDataFromServer}/>)}
            />

    );
};

export default HandsetTable;
