import {Dispatch, SetStateAction} from "react";
import {StateCallCenter} from "../../types/components";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";

interface Props {
    setState: Dispatch<SetStateAction<StateCallCenter>>
}

const useHandlersState = ({setState}: Props) => {
    const handleHeightFilter = (heightFilter: number) => setState(prevState => ({...prevState, heightFilter}))

    const handleSelectedFilter = (selectedFilter: string) => setState(prevState => ({...prevState, selectedFilter}))

    const handleIsMarketing = (isMarketing: boolean) => setState(prevState => ({...prevState, isMarketing}))

    const handleSearchFilter = (searchFilter: string) => setState(prevState => ({...prevState, searchFilter}))

    const handleBranches = (values: OptionSelect[]) => setState(prevState => ({
        ...prevState,
        branches: !!values && !!values.length ? values.map((value: OptionSelect) => +value.value) : []
    }))

    return {
        handleHeightFilter,
        handleSelectedFilter,
        handleIsMarketing,
        handleSearchFilter,
        handleBranches
    }
}

export default useHandlersState
