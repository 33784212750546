import React from "react";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import SVG from "../../../../../common/helpers/SVG";
import Box from "../../../../../components-ui/Layout/Box";
import useValidationOfFields from "../hooks/useValidationOfFields";
import ValidationLinkInfo from "../../../../../components/ValidationLinkInfo/ValidationLinkInfo";
import usePrintDocument from "../hooks/usePrintDocument";
import useClickIsValidate from "../hooks/useClickIsValidate";
import useSubmit from "../hooks/useSubmit";
import useSubmitValidation from "../hooks/useSubmitValidation";

export default function ButtonsPreparation() {
    const {isChangedBirthday, validateForm, isCheckboxes} = useValidationOfFields()
    const validateSubmit = useSubmitValidation()
    const {loaderSave, loaderPrint, handleSaveBtn, handlePrintBtn} = usePrintDocument()
    const {onClickLinkValidateForm, onClickLinkValidateCheckboxes} = useClickIsValidate()
    const {handleNextState, loaderNext} = useSubmit()

    return (
        <Box>
            <Box display="flex" margin="32px 0 0">
                <Button variant={isCheckboxes && validateForm && !isChangedBirthday ? 'success' : 'disabled'}
                        children='Распечатать'
                        size="md"
                        margin="0 24px 9px 0"
                        loader={loaderPrint}
                        startIcon={<SVG name="print"/>}
                        onClick={handlePrintBtn}
                />
                {isChangedBirthday && (
                    <Button variant={isCheckboxes && validateForm ? 'success' : 'disabled'}
                            children='Сохранить'
                            size="md"
                            margin="0 24px 9px 0"
                            loader={loaderSave}
                            onClick={handleSaveBtn}
                    />
                )}
                <Button variant={validateSubmit && !isCheckboxes ? 'success' : 'disabled'}
                        children='Готово'
                        margin="0 0 9px"
                        size="md"
                        loader={loaderNext}
                        onClick={handleNextState}/>
            </Box>
            {isCheckboxes && (
                <ValidationLinkInfo validate={validateForm} onClickIsValidate={onClickLinkValidateForm}/>
            )}
            {!isCheckboxes && (
                <ValidationLinkInfo validate={validateSubmit} onClickIsValidate={onClickLinkValidateCheckboxes}
                                    text="Распечатайте" linkText="обязательные документы"/>
            )}
        </Box>
    )
}
