import React, {useMemo} from 'react';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import CustomTick from "../components/CustomTick";
import CustomTooltip from "./components/CustomTooltip";
import _ from "lodash";

export interface DoubleBarDataItem {
    code: string
    barOne: number
    barTwo: number
}

interface Props {
    data: DoubleBarDataItem[]
    width: string | number
    height: string | number
    formatterValue?: (value: number) => string
    formatterCode?: (code: string) => string
    formatterTitleTooltip?: (value: string) => any
    formatterNameTooltip?: (name: any) => any
}

const DoubleBar = (
    {
        data,
        width,
        height,
        formatterValue,
        formatterCode,
        formatterTitleTooltip,
        formatterNameTooltip
    }: Props) => {

    const widthYAxis = useMemo(() => {
        let concatData = [] as number[];
        data.forEach(item => {
            concatData.push(item.barOne)
            concatData.push(item.barTwo)
        });
        concatData = concatData.filter(item => item !== undefined)
        const max = _.max(concatData);
        if (!!max) {
            const maxValue = Math.ceil(max / 10) * 10;
            const formattedValue = !!formatterValue ? formatterValue(maxValue) : String(maxValue)
            return !!formattedValue ? formattedValue.length * 9.5 : 9.5
        } else {
            return 10
        }
    }, [JSON.stringify(data)])


    return (
        <ResponsiveContainer width={width} height={height}>
            <BarChart data={data}>
                <CartesianGrid vertical={false}/>
                <XAxis dataKey="code"
                       tick={<CustomTick type="x" textAnchor="middle" formatterCode={formatterCode}
                                         formatterValue={formatterValue}/>}
                       axisLine={false}
                       tickLine={false}
                       interval="preserveStartEnd"
                />
                <YAxis tick={<CustomTick type="y" textAnchor="end" propsX={-1} propsY={4}
                                         formatterValue={formatterValue}/>}
                       axisLine={false}
                       tickLine={false}
                       tickFormatter={value => !!formatterValue ? formatterValue(value) : value}
                       width={widthYAxis}
                />
                <Tooltip content={<CustomTooltip formatterValue={formatterValue}
                                                 formatterTitleTooltip={formatterTitleTooltip}
                                                 formatterNameTooltip={formatterNameTooltip}/>}
                         cursor={{fill: '#daebff'}}
                />

                <Bar dataKey="barOne" fill='#447ce6' radius={[2, 2, 0, 0]}/>
                <Bar dataKey="barTwo" fill='#fdbc00' radius={[2, 2, 0, 0]}/>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default DoubleBar;
