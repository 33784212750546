import {Dispatch, SetStateAction} from "react";
import {StateReceipts} from "../types";

interface Props {
    setState: Dispatch<SetStateAction<StateReceipts>>
}

const useHandlersReceipts = ({setState}: Props) => {

    return {
        handleCurrentTab: (currentTab: string) => setState(prevState => ({...prevState, currentTab})),
        handleSelectedDirection: (selectedDirection: string | number) => setState(prevState => ({...prevState, selectedDirection})),
        handleHoverIndex: (hoverIndex: number) => setState(prevState => ({...prevState, hoverIndex}))
    }
}

export default useHandlersReceipts
