import React from "react";
import _ from "underscore";
import {MethodsCallCenter, OnChangeSelectedScript, TasksDefault} from "../../../../types/common";
import Paper from "../../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Typography from "../../../../../../components-ui/DataDisplay/Typography/Typography";
import Helper from "../../../../../../common/helpers/main";
import {Divider} from "../../../../../../components-ui/DataDisplay/Divider";
import ItemTasks from "./components/ItemTasks";
import PatientCard from "../../../../../PatientCard/PatientCard";
import {useDialogsManager} from "../../../../../../containers/DialogsManager/DialogsManager";
import Box from "../../../../../../components-ui/Layout/Box";
import {InfoCallCenter} from "../../../../types/hooks";
import {TasksInfo} from "../../../../../../containers/Tasks";
import {StateDetailed} from "../../hooks/useDetailed";
import {StateCallCenter} from "../../../../types/components";

interface Props {
    infoCallCenter: InfoCallCenter
    methodsCallCenter: MethodsCallCenter
    tasksInfo: TasksInfo
    detailedState: StateDetailed
    validate: boolean
    state: StateCallCenter
    onChangeSelectedScript: OnChangeSelectedScript
}

const PlannedCalls = (
    {
        infoCallCenter,
        methodsCallCenter,
        tasksInfo,
        detailedState,
        validate,
        state,
        onChangeSelectedScript
    }: Props) => {

    const {showSlideModal} = useDialogsManager();

    if (!detailedState.plannedCalls) return null
    return (
        <>
            {_.map(detailedState.plannedCalls, (tasks, patientId) => {
                const tasksType = {} as { [key: string]: TasksDefault }
                tasks.forEach(task => {
                    if (tasksType[task.type] === undefined) tasksType[task.type] = [];
                    tasksType[task.type].push(task)
                })
                const noName = tasks.filter(task => !!task.targetPerson.name).length === 0;
                const isLinkName = !(noName || !tasks[0].targetPerson.patientId);
                const mapTasksType = _.map(tasksType, (sortTaskType, nameType) => ({nameType, sortTaskType})).sort((a, b) => {
                    return infoCallCenter.types[b.nameType].priority - infoCallCenter.types[a.nameType].priority
                })
                return (
                    <Paper key={patientId} isHidden={false} margin="0 0 12px">
                        <Box padding="30px 20px 11px">
                            <Typography color={isLinkName ? 'info' : 'text'}
                                        cursor={isLinkName ? "pointer" : "default"}
                                        variant="min-title"
                                        onClick={() => {
                                            if (isLinkName) {
                                                showSlideModal(<PatientCard
                                                    patientId={tasks[0].targetPerson.patientId}/>)
                                            }
                                        }}
                                        children={noName ? 'имя не указано' : Helper.getFullName(tasks[0].targetPerson)}/>
                        </Box>
                        <Divider/>
                        {mapTasksType.map(item => {
                            return (
                                <ItemTasks key={item.nameType + 'taskType'}
                                           nameType={item.nameType}
                                           sortTaskType={item.sortTaskType}
                                           validate={validate}
                                           infoCallCenter={infoCallCenter} methodsCallCenter={methodsCallCenter}
                                           tasksInfo={tasksInfo}
                                           onChangeSelectedScript={onChangeSelectedScript}
                                           personalPhone={!!state.detail ? state.detail.phone : ''}
                                />
                            )
                        })}
                    </Paper>
                )
            })}
        </>
    )
}

export default PlannedCalls
