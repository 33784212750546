import React, {useState} from 'react';
import useServerTimeMoment from '../../../../../common/hooks/useServerTimeMoment';
import DateHelper from '../../../../../common/helpers/date-helper';
import useModalTimesheetRecord from './hooks/useModalTimesheetRecord';
import Box from '../../../../../components-ui/Layout/Box';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import {commonLabelOption, commonSelectOption, commonTextFieldOption} from './data';
import {Label} from '../../../../../components-ui/DataDisplay/Label';
import SelectEmployee from './components/SelectEmployee';
import Button from '../../../../../components-ui/Inputs/Button/Button';
import ValidationLinkInfo from '../../../../../components/ValidationLinkInfo/ValidationLinkInfo';
import {ModalAddEmployeeState} from '../types';
import TimeCounterNew from '../../../../../components-ui/Inputs/TimeCounterNew/TimeCounterNew';
import TextField from '../../../../../components-ui/Inputs/TextField/TextField';
import DateTime from '../../../../../components-ui/Inputs/DateTime/DateTime';
import {GetSalariesTimesheetResponce} from '../../../types';
import Checkbox from '../../../../../components-ui/Inputs/Checkbox/Checkbox';
import {TimesheetRecord} from '../../../../../content/TimeSheet/types';
import {OptionSelect} from '../../../../../components-ui/Inputs/Select/typesSelect';


interface Props {
    data: GetSalariesTimesheetResponce;
    selectedBranchId: number;
    refreshDataFromServer: () => void
    definitiveTimesheet?: {employee: OptionSelect, timesheetRecord: TimesheetRecord}
}

const ModalTimesheetRecord = ({data, selectedBranchId, refreshDataFromServer, definitiveTimesheet}: Props) => {

    const currentTime = useServerTimeMoment().format(DateHelper.FORMAT_TIME_MINUTES);

    const [state, setState] = useState<ModalAddEmployeeState>({
        date: definitiveTimesheet ? new Date(String(definitiveTimesheet?.timesheetRecord.date)) : new Date(),
        employee: definitiveTimesheet?.employee,
        startTime: definitiveTimesheet?.timesheetRecord.startTime || currentTime,
        startTimeComment: definitiveTimesheet?.timesheetRecord.startTimeComment || '',
        endTime: definitiveTimesheet?.timesheetRecord.endTime || '',
        endTimeComment: definitiveTimesheet?.timesheetRecord.endTimeComment || '',
    });

    const [isDisabledEndTime, setIsDisabledendTime] = useState(!!definitiveTimesheet?.timesheetRecord.endTime)

    const {
        handleEmployee,
        handleStartComment,
        handleStartTime,
        handleEndComment,
        handleEndTime,
        handleDate,
        changeDisabledCheckBox,
        validateMark,
        setValidateMark,
        saveEmployee,
        validate,
        isDateCurrent
    } = useModalTimesheetRecord(
        {
            selectedBranchId,
            state,
            setState,
            isDisabledEndTime,
            setIsDisabledendTime,
            refreshDataFromServer
        }
        );

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        children='Запись табеля' textAlign='center' />
            <Box margin='39px 0 0 0'>

                <Label labelText='Дата' isRequired={true} {...commonLabelOption}>
                    <DateTime selected={state.date}
                              maxDate={new Date()}
                        // @ts-ignore
                              onChange={(val: Date) => {
                                  handleDate(val);
                              }} />
                </Label>

                <SelectEmployee
                    isDisabled={!!definitiveTimesheet}
                    isError={!state.employee && validateMark}
                    data={data}
                    value={state.employee}
                    handleEmployee={handleEmployee}
                    selectedBranchId={selectedBranchId}
                />


                <Label labelText='Время прихода' isRequired={true} {...commonLabelOption} >
                    <TimeCounterNew value={state.startTime}
                                    onChange={handleStartTime}
                                    step={5}
                                    maxValue={state.endTime || currentTime}
                    />
                </Label>

                <Label {...commonLabelOption}
                       labelText='Комментарий к времени прихода'
                       align='leftTop'
                       marginLabel='8px 10px 0 0'>
                    <TextField
                        {...commonTextFieldOption}
                        value={state.startTimeComment}
                        onChange={handleStartComment}
                        isTextArea={true}
                    />
                </Label>

                <Label labelText='Время ухода' isRequired={!isDateCurrent} {...commonLabelOption} >
                   <Box display="flex" gap="10px" alignItems="center">
                       <TimeCounterNew value={state.endTime}
                                       isDisabledInput={!isDisabledEndTime}
                                       isError={!isDateCurrent && !state.endTime && validateMark}
                                       onChange={handleEndTime}
                                       step={5}
                                       maxValue={isDateCurrent ? currentTime : undefined}
                                       minValue={state.startTime}
                       />
                       <Checkbox label="Отметить уход" onChange={changeDisabledCheckBox} checked={isDisabledEndTime}/>
                   </Box>
                </Label>

                <Label {...commonLabelOption}
                       labelText='Комментарий к времени ухода'
                       align='leftTop'
                       marginLabel='8px 10px 0 0'>
                    <TextField
                        {...commonTextFieldOption}
                        value={state.endTimeComment}
                        onChange={handleEndComment}
                        isDisabled={!isDisabledEndTime}
                        isTextArea={true}
                    />
                </Label>

                <Label labelText='' {...commonLabelOption}>
                    <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                        <Button children='Сохранить'
                                size='md'
                                variant={validate ? 'success' : 'disabled'}
                                onClick={saveEmployee}
                        />
                        <Box maxWidth='172px' margin='0 0 0 16px'>
                            <ValidationLinkInfo validate={validate}
                                                onClickIsValidate={() => setValidateMark(!validate)} />
                        </Box>
                    </Box>
                </Label>

            </Box>
        </Box>
    );
};

export default ModalTimesheetRecord;
