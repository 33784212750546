import React from "react";
import {useSlideModal} from "../../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import ServerCommand from "../../../../../common/server/server-command";
import Helper from "../../../../../common/helpers/main";
import {useChoiceOrCompletion} from "../ChoiceOrCompletion";
import Box from "../../../../../components-ui/Layout/Box";
import {CHOICE, COMPLETION} from "../helper/constants";
import _ from "lodash";

export default function Footer() {
    const {
        activeTab,
        loaderNextBtn,
        goToNextStep,
        setLoaderNextBtn,
        form,
        visitId,
        parentName,
        completionHasTabs
    } = useChoiceOrCompletion();
    const {removeSlideModal} = useSlideModal();
    let nameBtn = parentName === COMPLETION ? 'Завершить прием' : 'К лечению';

    let disabled = parentName === COMPLETION ? completionHasTabs : false;

    if (activeTab !== 'nextVisit') {
        nameBtn = 'Завершить прием';
        const lastReason = form[activeTab].reasons[form[activeTab].reasons.length - 1].optionSelect;
        disabled = true;
        if (!!lastReason) {
            if (!lastReason.subReasons) {
                if (!!lastReason.inputs) {
                    disabled = _.chain(form[activeTab].inputs)
                        .pick(lastReason.inputs)
                        .some(input => !input.value)
                        .value();
                } else {
                    disabled = false;
                }
            }
        }
    }

    const onClickBtnFooter = () => {
        setLoaderNextBtn(true)
        let data = {visitId} as any;
        let commandBtnClickFooter = goToNextStep;
        if (!!form.nextVisit.comment) data.nextVisitComment = form.nextVisit.comment;
        if (activeTab !== "nextVisit") {
            commandBtnClickFooter = removeSlideModal;
            const lastReason = form[activeTab].reasons[form[activeTab].reasons.length - 1].optionSelect;
            if (!!lastReason) {
                data[activeTab.slice(0, -1)] = lastReason.value;
                if (!!lastReason.inputs) {
                    lastReason.inputs.forEach((item: string) => {
                        const input = form[activeTab].inputs[item];
                        const codeCamelCase = _.camelCase(input.code);
                        let value = input.value;
                        if (input.type === 'date') value = Helper.Date.formatDateForServer(value);
                        data[codeCamelCase] = value
                    })
                }
            }
        } else if (parentName === COMPLETION) {
            commandBtnClickFooter = removeSlideModal;
        }
        const commandCode = parentName === CHOICE ? 'visit/set-patient-choice' : 'visit/complete-treatment'
        ServerCommand.run(commandCode, data).then(() => {
            commandBtnClickFooter();
            setLoaderNextBtn(false);
        });
    }


    const propsBtnCompletion = {} as any;
    if (disabled) {
        if (activeTab === "nextVisit") {
            propsBtnCompletion.title = 'Запишите пациента на следующий приём';
        } else {
            propsBtnCompletion.title = 'Выберите причину и заполните обязательные поля';
        }
    }

    return (
        <Box display="flex" margin="33px 0 20px 200px">
            <Button children={nameBtn}
                    onClick={onClickBtnFooter}
                    variant={disabled ? 'disabled' : 'success'}
                    width="185px"
                    loader={loaderNextBtn}
                    {...propsBtnCompletion}
            />
        </Box>
    )
}
