import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {PlannedCall} from "../../../types/common";
import {useSlideModal} from "../../../../../containers/DialogsManager/containers/SlideModal/SlideModal";

interface Props {
    isMarketing: boolean
    detail: PlannedCall | null
}

const useChangeScriptsVisibility = ({isMarketing, detail}: Props) => {
    const {isMinimized} = useSlideModal()
    const user = useSelector(state => state.user);
    const marketingAdmin = user.role === 'marketing-admin';
    const [openScript, setOpenScript] = useState(false);
    const [scriptsType, setScriptsType] = useState<null | string>(null)

    useEffect(() => {
        if (!scriptsType) {
            if (marketingAdmin) {
                setScriptsType('existing_patient')
            } else {
                if (isMarketing) {
                    setScriptsType('existing_patient')
                }
            }
        }
    }, [isMarketing, scriptsType])

    useEffect(() => {
        if (!!scriptsType && !marketingAdmin && !isMarketing && !detail) setScriptsType(null)
    }, [isMarketing, detail])

    useEffect(() => {
        if (!isMinimized) {
            const timerId = setTimeout(() => {
                setOpenScript(true)
            }, 500);
            return () => {
                clearTimeout(timerId)
                setOpenScript(false)
            };
        } else {
            setOpenScript(false)
        }
    }, [isMinimized])

    return {
        openScript,
        setOpenScript,
        scriptsType,
        setScriptsType
    }
}

export default useChangeScriptsVisibility
