export const tabs = [
    {
        name: "Бабушкин А. Ю.",
        code: 2614,
        isDeletable: false
    },
    {
        name: "Батуев Б. О.",
        code: 13641,
        isDeletable: false
    },
    {
        name: "Норполов Б. А.",
        code: 2924,
        isDeletable: false
    },
    {
        name: "Цырендашиева А. Б.",
        code: 13654,
        isDeletable: false
    }
]

export const tabsTwo = [
    {name: "Бабушкин А. Ю.", code: 2614, isDeletable: false}
]
