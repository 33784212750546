import React, {useMemo} from 'react';
import {TasksInfo} from "../../../../containers/Tasks";
import {InfoManagerTasks, MethodsManagerTasks, StateManagerTasks} from "../../types/components";
import useFilterManagerTasks from "../../hooks/useFilterManagerTasks";
import Scrollbar from "../../../../components-ui/DataDisplay/Scrollbar/Scrollbar";
import { SContainer } from '../../../../containers/Tasks/style';
import TaskItem from "../TaskItem";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";

interface Props {
    state: StateManagerTasks
    infoManagerTasks: InfoManagerTasks
    refScrollList: any
    tasksInfo: TasksInfo
    methodsManagerTasks: MethodsManagerTasks
    handleSelectedUser: (selectedUser: OptionSelect) => void
}

const TasksList = ({state, refScrollList, infoManagerTasks, tasksInfo, methodsManagerTasks, handleSelectedUser}: Props) => {
    const [filterTasks, onScroll] = useFilterManagerTasks({state, infoManagerTasks, refScrollList, tasksInfo})
    return useMemo(() => {
        return (
            <Scrollbar height={`calc(100vh - ${state.heightFilter}px)`}
                       onScroll={onScroll}
                       ref={refScrollList}>
                <SContainer>
                    {filterTasks.map(task => {
                        return <TaskItem task={task}
                                         tasksInfo={tasksInfo}
                                         handleSelectedUser={handleSelectedUser}
                                         methodsManagerTasks={methodsManagerTasks}
                                         selectedBranches={state.branches}
                                         key={'manager-task' + task.id}/>
                    })}
                </SContainer>
            </Scrollbar>
        )
    }, [refScrollList, tasksInfo, state.heightFilter, JSON.stringify(filterTasks), JSON.stringify(state.branches)])
};

export default TasksList;
