import {Dispatch, SetStateAction} from "react";
import _ from "lodash";
import {PropsLoadingReports, StateAllDateRange, StateAnalytics} from "../types";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";

interface Props {
    setAllDateRange: Dispatch<SetStateAction<StateAllDateRange>>
    setState: Dispatch<SetStateAction<StateAnalytics>>
    loadingReports: (props: PropsLoadingReports) => void
    setSelectedBranches: Dispatch<SetStateAction<number[]>>
}

const useHandlersState = ({setAllDateRange, setState, loadingReports, setSelectedBranches}: Props) => {


    const handleAllStartDate = (startDate: any) => {
        setAllDateRange(prevDateRange => {
            setState(prevState => {
                _.forEach(prevState, (widget, widgetCode) => {
                    prevState[widgetCode as keyof StateAnalytics].startDate = startDate
                    prevState[widgetCode as keyof StateAnalytics].endDate = prevDateRange.endDate
                })
                return {...prevState}
            })
            return {...prevDateRange, startDate}
        })
        loadingReports({startDate})
    }

    const handleAllEndDate = (endDate: any) => {
        setAllDateRange(prevDateRange => {
            setState(prevState => {
                _.forEach(prevState, (widget, widgetCode) => {
                    prevState[widgetCode as keyof StateAnalytics].startDate = prevDateRange.startDate
                    prevState[widgetCode as keyof StateAnalytics].endDate = endDate
                })
                return {...prevState}
            })
            return {...prevDateRange, endDate}
        })
        loadingReports({endDate})
    }

    const handleWidgetStartDate = (startDate: any, widgetCode: keyof StateAnalytics) => {
        setState(prevState => ({...prevState, [widgetCode]: {...prevState[widgetCode], startDate}}))
        loadingReports({startDate, widgetCode})
    }

    const handleWidgetEndDate = (endDate: any, widgetCode: keyof StateAnalytics) => {
        setState(prevState => ({...prevState, [widgetCode]: {...prevState[widgetCode], endDate}}))
        loadingReports({endDate, widgetCode})
    }

    const handleBranches = (values: OptionSelect[]) => {
        const branches = !!values && !!values.length ? values.map((value: OptionSelect) => +value.value) : []
        setSelectedBranches(branches)
        loadingReports({branches})
    }

    return {
        handleAllStartDate,
        handleAllEndDate,
        handleWidgetStartDate,
        handleWidgetEndDate,
        handleBranches
    }
}

export default useHandlersState
