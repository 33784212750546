import React, {useMemo} from "react";
import Button from "../../../components-ui/Inputs/Button/Button";
import Helper from "../../../common/helpers/main";
import {Label} from "../../../components-ui/DataDisplay/Label";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import {commonLabelOption} from "./data";
import {useSelector} from "react-redux";
import {useOptionsBranchesUser} from "../../../common/hooks/useOptionsBranchesUser";
import FilterBranches from "./components/FilterBranches";
import Box from "../../../components-ui/Layout/Box";
import useCashSalary from "./hooks/useCashSalary";
import KkmSelect from "./components/KkmSelect";
import SalarySum from "./components/SalarySum";
import FilterUser from "./components/FilterUser";
import CashBox from "./components/CashBox";
import ValidationLinkInfo from "../../../components/ValidationLinkInfo/ValidationLinkInfo";

interface Props {
    userId?: number
    loadingFromServer: () => void
    paid: number
    salarySum: number
    month: string
}

const CashSalary = ({userId: fixedUserId, paid, salarySum, month, loadingFromServer}: Props) => {

    const cashboxOptions = useSelector(state => state.common.cashboxOptions);

    const optionsBranches = useOptionsBranchesUser()

    const {
        kkmError,
        data,
        userId,
        setUserId,
        state,
        setState,
        handleSubmit,
        loader,
        maxSum,
        kkmOptions
    } = useCashSalary({
        month,
        paid,
        salarySum,
        fixedUserId,
        loadingFromServer,
        cashboxOptions,
        optionsBranches
    })


    const validate = () => {
        let valid = true
        valid = valid && !!state.value
        valid = valid && state.branch !== undefined
        valid = valid && !!userId

        if (!state.cashboxSum[state.selectedCashbox.value]) {
            valid = false
        } else if (state.cashboxSum[state.selectedCashbox.value] < state.value) {
            valid = false
        }

        if (!!state.selectedKkm) {
            valid = valid && !kkmError
        }

        return valid
    }

    return (
        <Box padding="30px 27px 69px" minWidth="549px">
            <Typography children="Выплата зарплаты" textAlign="center" variant="bigger-title" margin="25px 0 59px"/>
            <Box position="absolute" positionOffsets={{top: "110px"}}>
                <Label labelText="" {...commonLabelOption}>
                    <span>
                        Выплачено {Helper.Price.format(paid)} руб.
                    </span>
                </Label>
            </Box>

            {fixedUserId === undefined &&
            <FilterUser setUserId={setUserId}/>
            }
            {optionsBranches.length > 1
                ?
                <FilterBranches state={state} setState={setState}/>
                :
                null
            }


            <CashBox state={state} setState={setState}/>

            {state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' &&
                <KkmSelect state={state} setState={setState} kkmOptions={kkmOptions}/>
            }

            <SalarySum state={state} setState={setState} minSum={-paid} maxSum={maxSum}/>

            <Label labelText="" {...commonLabelOption}>
                <Button
                    variant={(validate()) ? 'success' : 'disabled'}
                    children='Выплатить'
                    loader={loader}
                    size="md" onClick={handleSubmit}/>
            </Label>

            <ValidationLinkInfo validate={!kkmError} onClickIsValidate={() => {}}
                                text={kkmError} linkText={""}/>

        </Box>
    )
};

export default CashSalary;
