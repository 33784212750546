import {useMemo} from "react";
import {IPaymentCreationFormValues} from "../types";

interface Props {
    values: IPaymentCreationFormValues
}

const usePaymentSum = ({values}: Props): number => {
  return useMemo(() => {
      if (!!Object.keys(values).length) {
          return +Object.values(values).reduce((a, b) => +a + +b);
      } else {
          return 0
      }
  }, [values])
};

export default usePaymentSum;