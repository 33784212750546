import {useMemo} from "react";
import _ from "lodash";
import {PropsUseOtherPatientValue} from "../types/hooks";

export default function useOtherPatientValue({form}: PropsUseOtherPatientValue) {

    return useMemo(() => {
        let result = false;
        if (form.basic.phoneOwner?.value !== 'other' && form.basic.personalPhone === form.otherPatient.personalPhone) {
            return false;
        }
        const valuesOtherPatient = _.pick(form.otherPatient, ['lastName', 'name', 'secondName', 'relation']);
        _.forEach(valuesOtherPatient, (value) => {
            if (!!value) result = true;
        })
        return result;
    }, [form.otherPatient, form.basic.personalPhone, form.basic.phoneOwner])
}
