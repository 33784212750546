import React, {Dispatch, forwardRef, SetStateAction, useRef} from 'react';
import Helper from "../../../../../../../common/helpers/main";
import {GetFailedConsultationsParams, GetFailedConsultationsResult, StateConsultations} from "../../types";
import ServerCommand from "../../../../../../../common/server/server-command";
import Box from "../../../../../../../components-ui/Layout/Box";
import Paper from "../../../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Table from "../../../../../../../components-ui/DataDisplay/Table";
import Typography from "../../../../../../../components-ui/DataDisplay/Typography/Typography";
import useOutsideClick from "../../../../../../../common/hooks/useOutsideClick";
import useColumnsFailed from "./hooks/useColumnsFailed";
import getPositionOffsets from "../../utils/getPositionOffsets";
import Loader from "../../../../../../../components-ui/Feedback/Loader/Loader";
import useError from "../../hooks/useError";
import {useDialogsManager} from "../../../../../../../containers/DialogsManager/DialogsManager";
import {useSelector} from "react-redux";

interface Props {
    childrenCell: string | number
    refRow: any
    refTable: any
    state: StateConsultations
    row: {
        [key: string]: any
    }
    startDate: string
    endDate: string
    setSubTable: Dispatch<SetStateAction<any>>
    selectedBranches: number[]
}

const Failed = ({childrenCell, refTable, refRow, state, row, startDate, endDate, setSubTable, selectedBranches}: Props) => {
    const {slideModals} = useDialogsManager()
    const refSubTable = useRef(null) as any;
    const userBranches = useSelector(state1 => state1.user.branches)

    useOutsideClick(refSubTable, () => {
        if (!Object.values(slideModals).some(modal => !modal.isMinimized)) {
            setSubTable(null)
        }
    }, [slideModals])

    const subColumns = useColumnsFailed()

    useError({setSubTable})

    return (
        <Typography children={childrenCell}
                    as="span"
                    cursor="pointer"
                    color="info"
                    variant="small"
                    onClick={() => {
                        if (!!refRow && !!refRow.current && !!refTable && !!refTable.current) {
                            const positionOffsets = getPositionOffsets(refRow, refTable)
                            const data = {
                                startDate: Helper.Date.formatDateForServer(startDate),
                                endDate: Helper.Date.formatDateForServer(endDate),
                                branches: !!selectedBranches.length ? selectedBranches : userBranches
                            } as GetFailedConsultationsParams
                            if (state.currentTab === 'doctors') {
                                data.doctorId = row.name
                            } else {
                                data.direction = row.name
                            }

                            setSubTable((
                                <ContentFailed positionOffsets={positionOffsets} ref={refSubTable}>
                                    <Loader visible={true} />
                                </ContentFailed>
                            ))


                            ServerCommand.get('analytics/failed-consultations', data).then((response: GetFailedConsultationsResult) => {
                                if (response.failedConsultations.length) {
                                    setSubTable((
                                        <ContentFailed positionOffsets={positionOffsets} ref={refSubTable}>
                                            <Table columns={subColumns}
                                                   rows={response.failedConsultations}
                                                   variantTable="outline"
                                                   maxHeight="343px"
                                                   isHovering={false}
                                                   fontSizeRow="14px" />
                                        </ContentFailed>
                                    ))
                                } else {
                                    setSubTable((
                                        <ContentFailed positionOffsets={positionOffsets} ref={refSubTable}>
                                            <Typography children="Неудавшихся консультаций нет" variant="middle-title" textAlign="center"/>
                                        </ContentFailed>
                                    ))
                                }

                            })
                        }
                    }}
        />
    );
};

interface PropsContent {
    positionOffsets: any
    children: any
}

const ContentFailed = forwardRef(({positionOffsets, children}: PropsContent, ref: any) => {

    return (
        <Box position="absolute" zIndex={17} positionOffsets={positionOffsets} ref={ref}>
            <Paper minWidth="780px" isHidden={false} padding="24px" minHeight={85}>
                {children}
            </Paper>
        </Box>
    )
})

export default Failed;
