import React, {useState} from "react";
import TextField from "../../../../../../components-ui/Inputs/TextField/TextField";

const data = [
    {
        code: '111',
        value: '111'
    },
    {
        code: '222',
        value: '222'
    },
    {
        code: 'ass',
        value: 'ass'
    },
    {
        code: 'dsds',
        value: 'dsds'
    },
]

export default function Autocomplete() {

    const [value, setValue] = useState('');

    return (
        <div>
            <TextField value={value}
                       onChange={(val:string) => setValue(val)}
                       widthInput={360}
                       dataAutocomplete={data}
            />
            <pre>
                            {
                                `<TextField value={value} onChange={(val) => setValue(val)} widthInput={360} dataAutocomplete={data} />`
                            }
                        </pre>
        </div>
    )
}
