import {useMemo} from "react";
import {ReceiptsReport} from "../../../../types";
import {useSelector} from "react-redux";
import {StateReceipts} from "../types";

interface Props {
    report: ReceiptsReport
    state: StateReceipts
}

const useSumsCodes = ({report, state}: Props) => {
    const directions = useSelector(state => state.common.directions)
    return useMemo(() => {
        if (state.currentTab === 'doctors' && !!state.selectedDirection) {
            const directionDoctors = (state.selectedDirection === 'all' || !report.current.directions[state.selectedDirection]
                ? report.current.general.doctors
                : report.current.directions[state.selectedDirection].doctors) ?? {}
            return Object.keys(directionDoctors).sort((a, b) => directionDoctors[+b].sum - directionDoctors[+a].sum);
        } else {
            return Object.keys(report.current.directions).sort((a, b) => directions[a].sort - directions[b].sort)
        }
    }, [JSON.stringify(report), state.currentTab, state.selectedDirection]);
}

export default useSumsCodes
