import {useCallback, useEffect, useState} from 'react';
import ServerCommand from '../../../../../../../common/server/server-command';
import {Salary} from '../../AddingEditingEmployee/types';
import {GetListTemplateSalary, TemplateSalary} from '../types';
import _ from 'lodash';

interface Props {
    salary?: Salary;
}

const useDetailRates = ({salary}: Props) => {

    const emptyPattern = {type: 'services', name: 'Без шаблона', id: -1, parameters: {}} as const;

    const [dataResponse, setDataResponse] = useState<{directions: []; services: []; servicesGroups: [];}>(
        {
            directions: [],
            services: [],
            servicesGroups: [],
        },
    );

    const [rates, setRates] = useState<Pick<Salary, 'services' | 'group_services'>>(salary ?? {});

    const [switchType, setSwitchType] = useState<'services' | 'group_services'>(salary ? salary.services ? 'services' : 'group_services' : 'services');

    const [patterns, setPatterns] = useState<TemplateSalary[]>([emptyPattern]);

    const [pattern, setPattern] = useState<TemplateSalary>(patterns[0]);


    useEffect(() => {
        ServerCommand.get('service/price-list', {}, ({directions, services, servicesGroups}: any) => {
            setDataResponse({directions, services, servicesGroups});
        });
        ServerCommand.get('salary/template', {}, (response: GetListTemplateSalary) => {
            setPatterns(prevState => ([...prevState, ...response.list]));
        });
    }, []);
    useEffect(() => {
        if (!_.isEqual(pattern, emptyPattern)) {
            setSwitchType(pattern.type);
            setRates(prevState => ({...prevState, [pattern.type]: pattern.parameters}));
            console.log("a")
        }
    }, [pattern]);
    const refreshDataFromServer = useCallback(() => {
        setPatterns([emptyPattern]);
        ServerCommand.get('salary/template', {}, (response: GetListTemplateSalary) => {
            setPatterns(prevState => ([...prevState, ...response.list]));
        });
    }, []);

    return {
        dataResponse,
        rates,
        setRates,
        switchType,
        setSwitchType,
        patterns,
        pattern,
        setPattern,
        refreshDataFromServer,
    };
};

export default useDetailRates;
