import styled from "styled-components";

export const SWrapIcon = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.primary};
    margin-right: 12px;
`
