import React, {createContext, useContext} from "react";
import useSortSubFields from "../../hooks/useSortSubFields";
import StringEdit from "./components/StringEdit";
import Box from "../../../../../../components-ui/Layout/Box";
import {usePatientField} from "../../../PatientField";
import PhoneEdit from "./components/PhoneEdit";
import EnumEdit from "./components/EnumEdit";
import {ContextComposite} from "../../../../types/patientField";

const CompositeContext = createContext({} as ContextComposite);
export const useComposite = () => useContext(CompositeContext);

export default function EditComposite() {
    const {composite} = usePatientField()
    const subFields = useSortSubFields()

    if (!!composite) {
        return (
            <Box display="flex" flexDirection="column">
                {subFields.map((subField, index) => {
                    let content = null;
                    switch (composite[subField].field.type) {
                        case "string":
                            content = <StringEdit/>
                            break;
                        case "phone":
                            content = <PhoneEdit/>
                            break;
                        case "enum":
                            content = <EnumEdit/>
                            break;
                        default:
                            break;
                    }
                    return (
                        <CompositeContext.Provider key={subField + 'composite'}
                                                   value={{subField, index, subFields}}>
                            <Box margin={index !== subFields.length - 1 ? "0 0 10px" : "0"}>
                                {content}
                            </Box>
                        </CompositeContext.Provider>
                    )
                })}
            </Box>
        )
    } else {
        return null
    }
}
