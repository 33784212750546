import styled from "styled-components";
import {PropsSChild, PropsSWrapChip} from "../types";

export const SWrapChip = styled.div.attrs({className: 'SWrapChip'})<PropsSWrapChip>`
    width: max-content;
    max-width: 100%;
    border-radius: 2px;
    background-color: ${props => !!props.bg ? props.theme.colors[props.bg] : props.theme.colors.blue};
    padding: 0 12px;
    margin: ${props => props.margin || "0 2px 2px 0"};
    display: flex;
    align-items: center;
    min-height: 40px;
`

export const STextChip = styled.span.attrs({className: 'STextChip'})<PropsSChild>`
    display: block;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => !!props.color ? props.theme.colors[props.color] : props.theme.colors.text};
    padding-bottom: 4px;
`

export const SWrapIcon = styled.span.attrs({className: 'SWrapIcon'})`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 12px;
`
