import Helper from "common/helpers/main";

const Group = {
    /**
     * Применяет функцию к каждой группе в дереве групп
     * @param tree
     * @param handler
     */
    forEach: function(tree, handler) {
        Helper.forEachObj(tree, group => {
            handler(group);
            if (!!group.groups) {
                this.forEach(group.groups, handler);
            }
        })
    },

    /**
     * Расправляет дерево групп в простой объект - карту групп по id
     * @param tree
     * @param expandedTree
     */
    expandTree: function(tree, expandedTree = {}) {
        if (!Array.isArray(tree)) {
            tree = Object.values(tree);
        }

        tree.forEach(group => {
            expandedTree[group.id] = group;

            if (group.groups) {
                expandedTree = this.expandTree(group.groups, expandedTree);
            }
        });

        return expandedTree;
    },

    getActualGroups: function(allGroups, clone = true) {
        if(clone) {
            allGroups = Helper.clone(allGroups);
        }

        return allGroups.filter(group => {
            if(!group.archive && group.groups) {
                group.groups = this.getActualGroups(group.groups, false);
            }

            return !group.archive;
        });
    }
};

export default Group
