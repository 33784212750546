import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const CardCatalogueIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 19.2"} ref={ref}>
            <path d="M22.25,18.65a.64.64,0,0,1-.64.55H2.39a.64.64,0,0,1-.64-.55L0,6.78a.64.64,0,0,1,.64-.73h22.7a.64.64,0,0,1,.64.73Zm-1.2-.73L22.6,7.33H1.4L3,17.92ZM7.54,1.29c.11.2.22.42.32.63H20.65a1.47,1.47,0,0,1,1,.4,1.42,1.42,0,0,1,.42,1v.9a.65.65,0,0,1-1.29,0v-.9a.18.18,0,0,0,0-.1.18.18,0,0,0-.11,0H7.44a.63.63,0,0,1-.59-.39c-.1-.22-.27-.57-.45-.91-.09-.16-.18-.3-.26-.42A1.15,1.15,0,0,0,6,1.28H3.37a.12.12,0,0,0-.12.11V4.23a.65.65,0,0,1-1.3,0V1.38A1.42,1.42,0,0,1,3.34,0H6.17c.45,0,.72.27,1.06.78C7.33.93,7.43,1.1,7.54,1.29Zm.12,10.05a.69.69,0,0,1,0-1.38h7.81a.69.69,0,0,1,0,1.38Z"/>
        </WrapIcon>
    );
});
