import React from 'react';
import {SBoxFlex, SContainerItem} from "../../../../../containers/Tasks/style";
import Tag from "../../../../../components-ui/DataDisplay/Tag/Tag";
import {ManagerTask} from "../../../types/common";
import {TasksInfo} from "../../../../../containers/Tasks";
import Title from './Title';
import {Divider} from "../../../../../components-ui/DataDisplay/Divider";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";

interface Props {
    task: ManagerTask
    tasksInfo: TasksInfo
    handleSelectedUser: (selectedUser: OptionSelect) => void
}

const Header = ({task, tasksInfo, ...props}: Props) => {
    return (
        <>
            <SBoxFlex padding="21px 0 0">
                <SContainerItem>
                    <Tag children={tasksInfo[task.type].name} margin="0 0 11px 0"/>
                    <Title task={task} {...props} />
                </SContainerItem>
            </SBoxFlex>
            <Divider/>
        </>
    );
};

export default Header;
