import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const AnalyticsIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M23.5,12.48A.5.5,0,0,1,24,13,12,12,0,0,1,4.3,21.18a.47.47,0,0,1-.18-.36.43.43,0,0,1,.08-.29l.06-.09,4.29-4.28a.5.5,0,0,1,.64-.06A5,5,0,0,0,17,12.89a.51.51,0,0,1,.4-.41H23.5Zm-.56,1h-5.1A6,6,0,0,1,12,18a6.06,6.06,0,0,1-2.75-.66L9,17.15l-3.6,3.61a11,11,0,0,0,17.52-7ZM3.18,4.07a.49.49,0,0,1,.29.08l.09.06L7.84,8.5a.5.5,0,0,1,.06.64,5,5,0,0,0,0,5.67.5.5,0,0,1,0,.56l-.06.08L3.56,19.74a.5.5,0,0,1-.26.13H3.19a.47.47,0,0,1-.37-.18,12,12,0,0,1,0-15.45A.47.47,0,0,1,3.18,4.07Zm.06,1.25a11,11,0,0,0-.25,13l.25.34L6.85,15a6,6,0,0,1-.18-5.79l.18-.32ZM13.07,0A12,12,0,0,1,24,10.93a.52.52,0,0,1-.13.38.49.49,0,0,1-.27.15H17.44a.49.49,0,0,1-.49-.41,5,5,0,0,0-4-4,.51.51,0,0,1-.41-.4V.5a.52.52,0,0,1,.16-.37A.52.52,0,0,1,13.07,0Zm.46,1.06v5.1a6.07,6.07,0,0,1,4.24,4l.07.27h5.1a11,11,0,0,0-9.09-9.36ZM11,0a.52.52,0,0,1,.38.13.49.49,0,0,1,.15.27V6.56a.49.49,0,0,1-.41.49,5,5,0,0,0-1.92.8.52.52,0,0,1-.29.09.46.46,0,0,1-.27-.09l-.08-.06L4.26,3.51a.49.49,0,0,1-.14-.38.47.47,0,0,1,.18-.36A12,12,0,0,1,11,0Zm-.46,1.06a11,11,0,0,0-4.8,1.88l-.35.25L9,6.8a6.16,6.16,0,0,1,1.23-.55l.32-.09Z"/>
        </WrapIcon>
    );
});
