import * as React from "react";
import {PureComponent} from "react";
import './Arrow.scss';
import {ArrowProps, ArrowDefaultProps} from "../types/elements";
import {Icon} from "../../Icons/Icons";

export default class Arrow extends PureComponent<ArrowProps> {
    static defaultProps: ArrowDefaultProps;
    render() {
        const {variant, onClick} = this.props;
        return (
            <div
                className={`graph-arrow graph-arrow--${variant}`}
                onClick={() => onClick()}
            >
                <Icon icon="new-arrow-select"/>
            </div>
        )
    }
}

Arrow.defaultProps = {
    variant: 'left'
};
