import {useState} from "react";
import {StateConsultations} from "../types";
import useHandlersConsultations from "./useHandlersConsultations";
import {tabsConsultations} from "../data";

const useConsultations = () => {
    const [state, setState] = useState<StateConsultations>({
        currentTab: tabsConsultations[1].code,
        currentButton: 'consultationsCount'
    })

    return {
        state,
        handlers: useHandlersConsultations({setState})
    }
}

export default useConsultations
