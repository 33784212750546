import {Icon} from "./containers/Icon"

export default Icon

export {WrapIcon} from './containers/WrapIcon'
export {AnalyticsIcon} from './components/standard/menu/AnalyticsIcon'
export {ArrowIcon} from './components/standard/arrows/ArrowIcon'
export {BackIcon} from './components/standard/arrows/BackIcon'
export {BasketIcon} from './components/standard/action/BasketIcon'
export {CardCatalogueIcon} from './components/standard/menu/CardCatalogueIcon'
export {CheckedIcon} from './components/standard/checked/CheckedIcon'
export {CloseIcon} from './components/standard/cross/CloseIcon'
export {CheckedBoldIcon} from './components/standard/checked/CheckedBoldIcon'
export {ConfirmedByMessageIcon} from './components/standard/scheduleCell/ConfirmedByMessageIcon'
export {DeleteIcon} from './components/standard/cross/DeleteIcon'
export {CheckedThinIcon} from './components/standard/checked/CheckedThinIcon'
export {MaterialsIcon} from './components/standard/menu/MaterialsIcon'
export {EditIcon} from './components/standard/action/EditIcon'
export {FullScreenIcon} from './components/standard/screen/FullScreenIcon'
export {InformationIcon} from './components/standard/info/InformationIcon'
export {MaximizeIcon} from './components/standard/action/MaximizeIcon'
export {MinusBoldIcon} from './components/standard/minus/MinusBoldIcon'
export {MinusIcon} from './components/standard/minus/MinusIcon'
export {MinusRoundIcon} from './components/standard/minus/MinusRoundIcon'
export {MouseRightIcon} from './components/standard/info/MouseRightIcon'
export {HiddenPassIcon} from './components/standard/pass/HiddenPassIcon'
export {OptionsIcon} from './components/standard/menu/OptionsIcon'
export {PlusThinIcon} from './components/standard/plus/PlusThinIcon'
export {DocumentIcon} from './components/standard/menu/DocumentIcon'
export {XRayIcon} from './components/standard/menu/XRayIcon'
export {PrinterIcon} from './components/standard/action/PrinterIcon'
export {ProductIcon} from './components/standard/rest/ProductIcon'
export {SortAsc} from './components/standard/rest/SortAsc'
export {SortDesc} from './components/standard/rest/SortDesc'
export {SortDefault} from './components/standard/rest/SortDefault'
export {Credit} from './components/standard/rest/Credit'
export {BigPriceIcon} from './components/standard/rest/BigPriceIcon'
export {Guarantee} from './components/standard/rest/Guarantee'
export {LogoNs} from './components/standard/rest/LogoNs'
export {Saving} from './components/standard/rest/Saving'
export {TaskClinic} from './components/standard/rest/TaskClinic'
export {Employee} from './components/standard/rest/Employee'
export {Comment} from './components/standard/rest/Comment'
export {PaymentsCheck} from './components/standard/popupLabel/PaymentsCheck'
export {TimeSheetIcon} from './components/standard/popupLabel/TimeSheetIcon'
export {InspectionIcon} from './components/standard/scheduleCell/inspectionIcon'
export {SaveIcon} from './components/standard/action/SaveIcon'
export {ScheduleIcon} from './components/standard/menu/ScheduleIcon'
export {SearchIcon} from './components/standard/action/SearchIcon'
export {SmallScreenIcon} from './components/standard/screen/SmallScreenIcon'
export {OutlineArrowDownIcon} from './components/standard/arrows/OutlineArrowDownIcon'
export {PlayArrowIcon} from './components/standard/arrows/PlayArrowIcon'
export {UnconfirmedByMessageIcon} from './components/standard/scheduleCell/UnconfirmedByMessageIcon'
export {WarningIcon} from './components/standard/info/WarningIcon'
export {ViewPassIcon} from './components/standard/pass/ViewPassIcon'
export {DocumentDeleteIcon} from './components/standard/callCenter/DocumentDeleteIcon'
export {DocumentDownloadIcon} from './components/standard/callCenter/DocumentDownloadIcon'
export {IncomingIcon} from './components/standard/callCenter/IncomingIcon'
export {MissedIcon} from './components/standard/callCenter/MissedIcon'
export {NotifyIcon} from './components/standard/info/NotifyIcon'
export {OutgoingIcon} from './components/standard/callCenter/OutgoingIcon'
export {HelpIcon} from './components/standard/info/HelpIcon'
export {TimeIcon} from './components/standard/info/TimeIcon'
export {TimeDotted} from './components/standard/info/TimeDotted'
export {AddIcon} from './components/standard/action/AddIcon'
export {CallCenterIcon} from './components/standard/popupLabel/CallCenterIcon'
export {NewVisitFormIcon} from './components/standard/popupLabel/NewVisitFormIcon'
export {KanbanIcon} from './components/standard/popupLabel/KanbanIcon'
export {PrepareVisitIcon} from './components/standard/popupLabel/PrepareVisitIcon'
export {ToothOutlineIcon} from './components/teeth/ToothOutlineIcon'
export {BothJawIcon} from './components/teeth/BothJawIcon'
export {LowerJawIcon} from './components/teeth/LowerJawIcon'
export {OralCavityIcon} from './components/teeth/OralCavityIcon'
export {SegmentIcon} from './components/teeth/SegmentIcon'
export {ToothHalfIcon} from './components/teeth/ToothHalfIcon'
export {Crown} from './components/teeth/Crown'
export {ToothRecovery} from './components/teeth/ToothRecovery'
export {LaboratoryIcon} from './components/standard/menu/LaboratoryIcon'
export {Tooth11Icon} from './components/dental-card/Tooth11Icon'
export {Tooth12Icon} from './components/dental-card/Tooth12Icon'
export {Tooth13Icon} from './components/dental-card/Tooth13Icon'
export {Tooth14Icon} from './components/dental-card/Tooth14Icon'
export {Tooth15Icon} from './components/dental-card/Tooth15Icon'
export {Tooth16Icon} from './components/dental-card/Tooth16Icon'
export {Tooth17Icon} from './components/dental-card/Tooth17Icon'
export {Tooth18Icon} from './components/dental-card/Tooth18Icon'
export {Tooth31Icon} from './components/dental-card/Tooth31Icon'
export {Tooth32Icon} from './components/dental-card/Tooth32Icon'
export {Tooth33Icon} from './components/dental-card/Tooth33Icon'
export {Tooth34Icon} from './components/dental-card/Tooth34Icon'
export {Tooth35Icon} from './components/dental-card/Tooth35Icon'
export {Tooth36Icon} from './components/dental-card/Tooth36Icon'
export {Tooth37Icon} from './components/dental-card/Tooth37Icon'
export {Tooth38Icon} from './components/dental-card/Tooth38Icon'
export {Tooth51Icon} from './components/dental-card/Tooth51Icon'
export {Tooth52Icon} from './components/dental-card/Tooth52Icon'
export {Tooth53Icon} from './components/dental-card/Tooth53Icon'
export {Tooth54Icon} from './components/dental-card/Tooth54Icon'
export {Tooth55Icon} from './components/dental-card/Tooth55Icon'
export {Tooth56Icon} from './components/dental-card/Tooth56Icon'
export {Tooth57Icon} from './components/dental-card/Tooth57Icon'
export {Tooth58Icon} from './components/dental-card/Tooth58Icon'
export {Tooth71Icon} from './components/dental-card/Tooth71Icon'
export {Tooth72Icon} from './components/dental-card/Tooth72Icon'
export {Tooth73Icon} from './components/dental-card/Tooth73Icon'
export {Tooth74Icon} from './components/dental-card/Tooth74Icon'
export {Tooth75Icon} from './components/dental-card/Tooth75Icon'
export {Tooth76Icon} from './components/dental-card/Tooth76Icon'
export {Tooth77Icon} from './components/dental-card/Tooth77Icon'
export {Tooth78Icon} from './components/dental-card/Tooth78Icon'
export {ToothIcon} from './components/teeth/ToothIcon'
export {UpperJawIcon} from './components/teeth/UpperJawIcon'

//old-icons
export {ArrowThinIcon} from './components/standard/arrows/ArrowThinIcon'
export {NotifyFilledIcon} from './components/standard/info/NotifyFilledIcon'
export {DoubleFilledArrowDownIcon} from './components/standard/arrows/DoubleFilledArrowDownIcon'
export {FilledArrowDownIcon} from './components/standard/arrows/FilledArrowDownIcon'
export {NotifyOldIcon} from './components/standard/info/NotifyOldIcon'
export {CanceledIcon} from './components/standard/rest/CanceledIcon'
export {CheckOldIcon} from './components/standard/checked/CheckOldIcon'
export {CloseThinIcon} from './components/standard/cross/CloseThinIcon'
export {ColorDocumentIcon} from './components/standard/rest/ColorDocumentIcon'
export {ColorFolderIcon} from './components/standard/rest/ColorFolderIcon'
export {DotsIcon} from './components/standard/rest/DotsIcon'
export {FolderIcon} from './components/standard/rest/FolderIcon'
export {InstructionIcon} from './components/standard/info/InstructionIcon'
export {LowerJawFrontIcon} from './components/teeth/LowerJawFrontIcon'
export {LongArrowIcon} from './components/standard/arrows/LongArrowIcon'
export {LongArrowThinIcon} from './components/standard/arrows/LongArrowThinIcon'
export {LowerJawOutlineIcon} from './components/teeth/LowerJawOutlineIcon'
export {MapIcon} from './components/standard/rest/MapIcon'
export {PlusIcon} from './components/standard/plus/PlusIcon'
export {PlusBoldIcon} from './components/standard/plus/PlusBoldIcon'
export {PlusRoundIcon} from './components/standard/plus/PlusRoundIcon'
export {Printer3DIcon} from './components/standard/rest/Printer3DIcon'
export {PrinterOldIcon} from './components/standard/action/PrinterOldIcon'
export {DoubleSlashIcon} from './components/standard/slash/DoubleSlashIcon'
export {SlashIcon} from './components/standard/slash/SlashIcon'
export {TargetIcon} from './components/standard/rest/TargetIcon'
export {TenderIcon} from './components/standard/scheduleCell/TenderIcon'
export {TimeOutlineIcon} from './components/standard/info/TimeOutlineIcon'
export {UpperJawOutlineIcon} from './components/teeth/UpperJawOutlineIcon'
export {UserFpIcon} from './components/standard/scheduleCell/UserFpIcon'
export {WarningOutlineIcon} from './components/standard/info/WarningOutlineIcon'
export {WtfIcon} from './components/standard/rest/WtfIcon'
export {Money} from './components/analytics/Money'
export {AverageCheck} from './components/analytics/AverageCheck'
export {LoadingTheClinic} from './components/analytics/LoadingTheClinic'
export {AnalyticsCalls} from './components/analytics/AnalyticsCalls'
export {CallEfficiency} from './components/analytics/CallEfficiency'
export {EfficiencyOfVisits} from './components/analytics/EfficiencyOfVisits'
export {Message} from './components/analytics/Message'
export {SalaryIcon} from './components/standard/menu/SalaryIcon'
export {CashBoxIcon} from './components/standard/menu/CashBoxIcon'
