import * as React from 'react';
import PriceHelper from '../../common/helpers/price-helper';
import './TotalSum.scss';

const TotalSum = (props: Props) => {
    const {sums} = props;

    return (
        <div className="total-sum">
            <div className="total-sum__rows">
                <div className="total-sum__titles">
                    {sums.map((sum: Sum) => (
                        <div className="total-sum__row" key={sum.title + 'titles'}>
                            {sum.title}
                        </div>
                    ))}
                </div>

                <div className="total-sum__values">
                    {sums.map((sum: Sum) => (
                        <div className="total-sum__row" key={sum.title + 'values'}>
                            {PriceHelper.format(sum.value)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

type Props = {
    sums: Sum[]
}

type Sum = {
    title: string,
    value: number
}

export default TotalSum
