import React, {useEffect, useState} from "react";
import BoxBody from "../../components-ui/Wraps/old/BoxBody";
import {
    TitleParameters,
    WrapParameters,
    TextParameters,
    WrapperParameters,
    Parameter,
    ParameterTitle,
    ParametersButtons
} from "../CaseHistory/styledCaseHistory";
import Text from "./elementes/Text";
import {IParameter, ISave, ISelected, IText, IValues, PParametersContent} from "./parametersInterfaces";
import Options from "./elementes/Options";
import * as _ from "underscore";
import MultipleList from "./elementes/MultipleList";
import Button from "../../components-ui/Inputs/Button/Button";

export default function ParametersContent({field, text, saveText, closeModal}: PParametersContent) {
    let listItems = _.clone(text.text);
    if (listItems.next !== undefined) {
        listItems = listItems.next;
    }
    const parameters = listItems.filter((item: any) => typeof item !== "string");
    const [active, setActive] = useState(parameters[0]);
    const [radioValues, setRadioValues] = useState<IValues>({});
    const [inputValues, setInputValues] = useState<IValues>({});
    const [checkboxesValues, setCheckboxesValues] = useState<IValues>({});
    const [selected, setSelected] = useState<ISelected>({});

    useEffect(() => {
        const {newRadioValues, newInputValues, newSelected, newCheckboxes} = loadData(parameters);
        setRadioValues(newRadioValues);
        setInputValues(newInputValues);
        setCheckboxesValues(newCheckboxes);
        setSelected(newSelected);
    }, []);

    const propsSave = {saveText, text, radioValues, inputValues, selected, checkboxesValues};

    return (
        <BoxBody>
            <WrapParameters>
                <TitleParameters children={field.title}/>
                <TextParameters>
                    <Text text={listItems}
                          selected={selected}
                          radioValues={radioValues}
                          inputValues={inputValues}
                          checkboxesValues={checkboxesValues}
                          active={active}
                          setActive={setActive}/>
                </TextParameters>
                <WrapperParameters>
                    {parameters.map((item: any) => {
                        return (
                            <Parameter key={item.name} onClick={() => setActive(item)}>
                                <ParameterTitle children={item.name} active={item.name === active.name}/>
                                {item.isMultipleList ?
                                    <MultipleList item={item}
                                                  checkboxesValues={checkboxesValues}
                                                  setCheckboxesValues={setCheckboxesValues}
                                    />
                                    : <Options item={item}
                                               radioValues={radioValues}
                                               selected={selected}
                                               setRadioValues={setRadioValues}
                                               setSelected={setSelected}
                                               inputValues={inputValues}
                                               setInputValues={setInputValues}/>}
                            </Parameter>
                        )
                    })}
                </WrapperParameters>
                <ParametersButtons>
                    <Button children="Сохранить"
                            variant={validate(parameters, selected, inputValues) ? 'success' : 'disabled'}
                            onClick={() => save(propsSave)}
                            size="long"/>
                    <Button children="Отменить"
                            variant="default"
                            onClick={closeModal}
                            size="long"/>
                </ParametersButtons>
            </WrapParameters>
        </BoxBody>
    )
}

const validate = (parameters: IParameter[], selected: ISelected, inputValues: IValues) => {
    let res = true;
    parameters.forEach(parameter => {
        if (parameter.isMultipleList) {
            let resOption = false;
            parameter.options.forEach((option) => {
                if (!resOption) {
                    if (option.isInput) {
                        if (option.isDefault) {
                            if (option.text !== '') resOption = true;
                        }
                    } else {
                        if (option.isDefault) {
                            resOption = true;
                        }
                    }
                }

            })
            if (!resOption) res = false;
        } else {
            if (selected[parameter.name] === 'input') {
                if (inputValues[parameter.name] === '') {
                    res = false;
                }
            }
        }

    });
    return res;
};

const loadData = (parameters: IParameter[]) => {
    let newRadioValues = {} as IValues;
    let newInputValues = {} as IValues;
    let newSelected = {} as ISelected;
    let newCheckboxes = {} as IValues;

    parameters.forEach(parameter => {
        if (parameter.options.length > 0) {
            const optionDefault = parameter.options.find((option: any) => option.isDefault);
            if (parameter.isMultipleList) {
                newCheckboxes[parameter.name] = parameter.options;
                newCheckboxes[parameter.name].push({
                    text: '',
                    isDefault: false,
                    isInput: true
                })
                newSelected[parameter.name] = 'checkbox';
            } else {
                if (optionDefault !== undefined) {
                    newRadioValues[parameter.name] = optionDefault.text;
                    newSelected[parameter.name] = 'radio';
                } else {
                    newRadioValues[parameter.name] = null;
                    newSelected[parameter.name] = 'input';
                }
            }

        } else {
            newRadioValues[parameter.name] = null;
            newSelected[parameter.name] = 'input';
        }
        newInputValues[parameter.name] = '';
    });

    return {newRadioValues, newInputValues, newSelected, newCheckboxes}
};

const save = ({saveText, text, radioValues, inputValues, selected, checkboxesValues}: ISave) => {
    let element = _.clone(text) as IText;

    if (element.text.next !== undefined) {
        element.text = element.text.next
    }
    let newText = '';
    element.text.forEach((item: any) => {
        if (typeof item !== "string") {
            switch (selected[item.name]) {
                case "radio" :
                    newText += radioValues[item.name]
                    break;
                case "checkbox" :
                    newText += checkboxesValues[item.name]
                        .filter((option: any) => option.isDefault)
                        .map((option: any) => option.text)
                        .join(', ');
                    break;
                default:
                    newText += inputValues[item.name]
                    break;
            }
        } else {
            newText += item;
        }
    });
    if (text.text.next !== undefined) {
        element.text = text.text.prev + ' ' + newText;
    } else {
        element.text = newText;
    }

    saveText(element, false);
};
