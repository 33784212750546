import {useEffect, useState} from "react";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import {PropsUseDuplicate} from "../types/hooks";
import { PatientValues, usePrepareStateForm, usePrepareValidationFields, PatientFieldKey } from "..";

export default function useDuplicate(
    {
        form, isOtherPatientValue, loadingPatient, setIsDateOtherPatient, setForm, otherPatientFields, fields
    }: PropsUseDuplicate) {

    const [patientDuplicate, setPatientDuplicate] = useState<null | PatientValues>(null)
    const [otherPatientDuplicate, setOtherPatientDuplicate] = useState<null | PatientValues>(null)
    const prepareStateForm = usePrepareStateForm({fields})
    const prepareValidationFields = usePrepareValidationFields({fields})

    const {currentPatient, relations, basic, otherPatient} = form;
    const {phoneOwner} = basic;

    useEffect(() => {

        const findDuplicate = (currentForm: PatientValues) => {
            const splitPersonalBirthdayPatient = currentForm.personalBirthday.split('-')
            let personalBirthdayPatient = currentForm.personalBirthday;
            if (splitPersonalBirthdayPatient[0].length === 2) {
                personalBirthdayPatient = splitPersonalBirthdayPatient.reverse().join('-');
            }
            return relations.relationsList
                .find((patient: PatientValues) => patient.personalPhone === currentForm.personalPhone
                    && patient.name.trim() === currentForm.name.trim()
                    && patient.lastName.trim() === currentForm.lastName.trim()
                    && patient.personalBirthday === personalBirthdayPatient)
        }

        if (!currentPatient && !!relations.relationsList.length) {
            const findPatient = findDuplicate(basic)
            if (!!findPatient) {
                setPatientDuplicate(findPatient)
            } else {
                setPatientDuplicate(null)
                if (isOtherPatientValue !== undefined) {
                    if (((!!phoneOwner && phoneOwner.value === 'other') || isOtherPatientValue) && otherPatient.isProvidedData) {

                        if (relations.relationsList
                            .some((patient: PatientValues) => patient.personalPhone === otherPatient.personalPhone
                                && patient.name.trim() === otherPatient.name.trim()
                                && patient.lastName.trim() === otherPatient.lastName.trim())) {

                            if (!!setIsDateOtherPatient) setIsDateOtherPatient(true);
                            if (isOtherPatientValue) {
                                const findOtherPatient = findDuplicate(otherPatient)
                                if (!!findOtherPatient) {
                                    setOtherPatientDuplicate(findOtherPatient)
                                } else {
                                    setOtherPatientDuplicate(null)
                                }
                            }
                        } else {
                            setOtherPatientDuplicate(null)
                            if (!!setIsDateOtherPatient) setIsDateOtherPatient(false)
                        }
                    }
                }
            }
        } else {
            setPatientDuplicate(null)
            setOtherPatientDuplicate(null)
        }
    }, [form])


    const onClickPatientDuplicate = () => {
        if (!!patientDuplicate) {
            loadingPatient(patientDuplicate)
            setPatientDuplicate(null)
        }
    }

    const onClickOtherPatientDuplicate = () => {
        if (!!otherPatientDuplicate) {
            setForm((prevState: any) => {
                const phoneOwner = prevState.basic.optionsPhoneOwner.find((option: OptionSelect) => option.value === otherPatientDuplicate.id) as OptionSelect
                const otherPatient = {
                    isProvidedData: true,
                    ...prepareStateForm(otherPatientFields as PatientFieldKey[]),
                    validationFields: {
                        ...prepareValidationFields(otherPatientFields as PatientFieldKey[])
                    }
                }
                return {
                    ...prevState,
                    basic: {
                        ...prevState.basic,
                        phoneOwner
                    },
                    otherPatient
                }
            })
            setOtherPatientDuplicate(null)
        }
    }

    return {
        patientDuplicate,
        otherPatientDuplicate,
        setPatientDuplicate,
        setOtherPatientDuplicate,
        onClickPatientDuplicate,
        onClickOtherPatientDuplicate
    }

}
