import React from "react";
import Tag from "../../../../../../../components-ui/DataDisplay/Tag/Tag";
import {callCenterTasksInfo} from "../../../../../helper/getDataCallCenter";
import {CompletedCall} from "../../../../../types/common";
import { SContainerItem, SBoxFlex } from "../../../../../../../containers/Tasks/style";

interface Props {
    call: CompletedCall
}

const TagsHistory = ({call}: Props) => {
    return (
        <SContainerItem>
            <SBoxFlex padding="40px 0 13px">
                {call.tasksTypes.map(type => {
                    return (
                        <Tag children={callCenterTasksInfo[type].name} key={call.id + 'history' + type} margin="0 7px 8px 0"/>
                    )
                })}
            </SBoxFlex>
        </SContainerItem>
    )
}

export default TagsHistory
