import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const WarningIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 22.59"} ref={ref}>
            <path d="M13.06.58a1.26,1.26,0,0,0-2.12,0L.14,20.84A1.17,1.17,0,0,0,.17,22a1.21,1.21,0,0,0,1,.58H22.8a1.21,1.21,0,0,0,1-.58,1.17,1.17,0,0,0,0-1.17Z"/>
            <polygon fill="#fff" points="13.2 19.01 10.8 19.01 10.8 16.63 13.2 16.63 13.2 19.01"/>
            <polygon fill="#fff" points="10.8 14.25 10.8 8.29 13.2 8.29 13.2 14.25 10.8 14.25"/>
        </WrapIcon>
    );
});
