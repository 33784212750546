import React, {useEffect, useState} from "react";
import {useEditableField} from "../EditableField";
import SaveButtons from "./SaveButtons";
import ServerCommand from "../../../common/server/server-command";
import {ValueEditableField} from "../typesEditableField";
import Select from "../../../components-ui/Inputs/Select/Select";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";

export default function FieldEnum() {
    const {field, patientId, setEditField} = useEditableField();
    const values = Object.values(field.values)[0] as ValueEditableField;
    const [selected, setSelected] = useState(values.value);
    const [isError, setError] = useState(values.validation);

    const onClickSave = () => {
        if (selected !== null) {
            const command = new ServerCommand('patient/edit', {
                id: patientId,
                [values.name]: field.type === 'multiple_enum'
                    ? !!selected && !!selected.length ? selected.map((option: OptionSelect) => option.value) : null
                    : selected.value
            });

            command.exec().then(() => {
                field.onChange(selected);
                setEditField(false);
            });

            setError(false);
        } else {
            setError(true);
        }
    }

    useEffect(() => {
        if (values.validation) {
            setError(true);
        }
    },[values.validation]);

    return (
        <>
            <Select value={selected}
                    options={!!values.variants ? values.variants : []}
                    onChange={(selectedEnum) => {setSelected(selectedEnum); setError(false)}}
                    isMulti={field.type === 'multiple_enum'}
                    isError={isError}
            />
           <SaveButtons onClickSave={onClickSave}/>
        </>
    )
}
