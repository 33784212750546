import React from "react";
import Button from "../../../components-ui/Inputs/Button/Button";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import {Label} from "../../../components-ui/DataDisplay/Label";
import {PropsCancellation} from "./typesCancellation";
import useCancellation from "./hooks/useCancellation";
import Loader from "../../../components-ui/Feedback/Loader/Loader";
import Select from "../../../components-ui/Inputs/Select/Select";
import Box from "../../../components-ui/Layout/Box";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";
import {formatFioAndCardNumber} from "../../../common/helpers/Patient";


export default function Cancellation(props: PropsCancellation) {
    const {state, setState, onClickCancellation, onChangeSelectedReason, loader} = useCancellation(props);
    const {patient, selectedReasons, waitingList, validate, comment} = state;
    if (!!patient) {
        const lastSelect = selectedReasons[selectedReasons.length - 1].optionSelect
        return (
            <Box width="560px" padding="45px 30px">
                <Typography variant="bigger-title" textAlign="center" margin="0 0 17px" children="Отмена приёма"/>
                <Typography textAlign="center" margin="0 0 34px"
                            children={formatFioAndCardNumber(patient)}/>
                <Label labelText="Причина" isRequired={true} align="leftTop" minWidthLabel="110px"
                       marginLabel="9px 15px 0 0">
                    <Box display="flex" flexDirection="column">
                        {selectedReasons.map((selectedReason, index) => {
                            return (
                                <Select options={selectedReason.options}
                                        value={selectedReason.optionSelect}
                                        placeholder="не выбрана"
                                        widthSelect="340px"
                                        widthMenu="340px"
                                        isError={index === selectedReasons.length - 1 && validate}
                                        key={selectedReason.optionSelect?.value}
                                        onChange={(optionSelect) => onChangeSelectedReason(optionSelect, index)}
                                        marginSelect="0 0 16px"/>
                            )
                        })}
                    </Box>
                </Label>
                <Label labelText="Комментарий" marginLabel="0 15px 0 0"
                       widthLabelWrap="90%" minWidthLabel="110px"
                       children={<TextField onChange={value => setState(prevState => ({...prevState, comment: value}))}
                                            value={comment}
                                            isTextArea={true}
                                            widthInput="340px"
                       />}/>
                {(!!lastSelect && !!lastSelect.waitingList) && (
                    <Checkbox label="Добавить в лист ожидания" checked={waitingList} margin="10px 0 0 125px"
                              onChange={checked => setState(prevState => ({...prevState, waitingList: checked}))}
                    />
                )}
                <Box margin="23px 0 0 125px" display="flex" alignItems="center">
                    <Button variant={!!lastSelect ? 'danger' : 'disabled'} size="md" margin="0 20px 0 0"
                            children='Отменить приём'
                            loader={loader}
                            onClick={onClickCancellation}/>
                    {!lastSelect && (
                        <Typography variant="small"
                                    display="inline-block"
                                    fontStyle="italic"
                                    color="textDarkGray">
                            Выберите <Typography onClick={() => setState(prevState => ({...prevState, validate: true}))}
                                                 fontWeight="600"
                                                 as="span"
                                                 color="info"
                                                 isLink={true}
                                                 children="причину"/>
                        </Typography>
                    )}
                </Box>
            </Box>
        )
    } else {
        return <Loader visible={true}/>
    }
}
