import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useOptionsBranches} from "../hooks/useOptionsBranches";
import {setBranchesFilter} from "../../../../../redux/actions/scheduleActions";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import SelectCheckbox from "../../../../../components-ui/Inputs/SelectCheckbox/SelectCheckbox";

const SelectedBranches = () => {
    const dispatch = useDispatch();
    const branches = useSelector(state => state.schedule.filter.branches);
    const optionsBranches = useOptionsBranches()
    if (optionsBranches.length > 1) {
        return (
            <SelectCheckbox options={optionsBranches}
                            placeholder="Все филиалы"
                            selected={optionsBranches.filter(branch => !!branches && branches.includes(branch.value))}
                            margin="0 24px 0 0"
                            onChange={values => {
                                dispatch(setBranchesFilter(!!values && !!values.length ? values.map((value: OptionSelect) => +value.value) : []))
                            }}
            />
        );
    } else {
        return null
    }
};

export default SelectedBranches;
