import styled from "styled-components";
import {
    PropsSTable,
    PropsSTableCell,
    PropsSTableGroup,
    PropsSTableRow, PropsSTableTotalItem, PropsSTableTotalLabel,
    PropsSWrapTable
} from "../types/components";

const boxShadowWrapTable = (props: PropsSWrapTable) => {
    switch (props.variantTable) {
        case "outline":
            return 'none'
        default:
            return props.theme?.shadow.default
    }
}

export const SWrapTable = styled.div.attrs({className: 'SWrapTable'})<PropsSWrapTable>`
    position: relative;
    z-index: 2;
    background: ${props => props.theme.colors.white};
    padding-bottom: ${props => props.variantTable === 'outline' ? '0' : '10px' };
    ${props => !!props.width && `width: ${props.width};`};
    ${props => !!props.margin && `margin: ${props.margin};`};
    ${props => props.isWrap && `
        border: 1px solid ${props.theme.colors.borderGray};
        border-radius: 4px;
        box-shadow: ${boxShadowWrapTable(props)};
    `};
`
export const STable = styled.div.attrs({className: 'STable'})<PropsSTable>`
    overflow: hidden;
    position: relative;
    padding-bottom: 2px;
    ${props => !!props.height && `min-height: ${props.height};`};
    ${props => props.isWrap && `border-radius: ${props.isHeader ? '0 0 4px 4px' : '4px'};`};
`

const minHeightTableRow = (props: PropsSTableRow) => {
    switch (props.variantTable) {
        case "outline":
            return '20px'
        default:
            return '32px'
    }
}

const borderTopTableRow = (props: PropsSTableRow) => {
    switch (props.variantTable) {
        case "outline":
            return `1px solid ${props.theme?.colors.borderGray}`
        default:
            return 'none'
    }
}

const paddingTableRow = (props: PropsSTableRow) => {
    if (!props.customRowsCells) {
        switch (props.variantTable) {
            case "outline":
                return '10px 20px 8px 0'
            default:
                return '0'
        }
    } else {
        return '0'
    }
}

export const STableRow = styled.div.attrs({className: 'STableRow'})<PropsSTableRow>`
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    min-height: ${minHeightTableRow};
    padding: ${paddingTableRow};
    height: max-content;
    font-size: ${props => props.fontSizeRow || '16px'};
    ${props => props.isNested && `padding-left: 56px;`};
    ${props => props.isHovering && `
        transition: background-color .2s ease-in-out;
        cursor: pointer;
        &:hover {
            background-color: ${props.theme.colors.secondary};
        }
    `}
    &:not(:first-child) {
        border-top: ${borderTopTableRow};
    }
`

export const STableCell = styled.div.attrs({className: 'STableCell'})<PropsSTableCell>`
    padding: ${props => !props.customRowsCells ? props.isSort ? "2px 20px 3px 4px" : "2px 5px 3px 20px" : props.customPaddingCells};
    position: relative;
    width: ${props => props.width || '100%'};
    color: ${({color, theme}) => !!color ? theme.colors[color] : theme.colors.text};
    text-align: ${props => props.align};
    ${props => !!props.width && `flex-shrink: 0;`};
    ${props => !!props.minWidth && `min-width: ${props.minWidth};`};
`

const colorHeaderCell = (props: PropsSTableCell) => {
    switch (props.variantTable) {
        case "outline":
            return props.theme?.colors.text
        default:
            return props.theme?.colors.textGray
    }
}

const justifyContentHeaderCell = (props: PropsSTableCell) => {
    switch (props.align) {
        case "right":
            return 'flex-end'
        case "center":
            return 'center'
        default:
        case "left":
            return 'flex-start'
    }
}

export const STableHeaderCell = styled(STableCell)`
    display: flex;
    align-items: center;
    justify-content: ${justifyContentHeaderCell};
    color: ${colorHeaderCell};
    user-select: none;
    ${props => !!props.cursor && `cursor: ${props.cursor};`};
    ${props => props.variantTable === 'outline' && `
        min-height: 40px;
    `}
    ${props => props.variantTable !== 'outline' && `
        &:not(:last-child)::after {
            content: "";
            width: 1px;
            background-color: ${props.theme.colors.borderDark};
            height: 61%;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    `}
    svg {
        transition: .3s;
    }
`

const backgroundColorTableHeader = (props: PropsSTableRow) => {
    switch (props.variantTable) {
        case "outline":
            return props.theme?.colors.white
        default:
            return props.theme?.colors.secondary
    }
}
const borderBottomTableHeader = (props: PropsSTableRow) => {
    switch (props.variantTable) {
        case "outline":
            return `1px solid ${props.theme?.colors.borderGray}`
        default:
            return 'none'
    }
}

const marginBottomTableHeader = (props: PropsSTableRow) => {
    switch (props.variantTable) {
        case "outline":
            return '0'
        default:
            return '15px'
    }
}
const fontWeightTableHeader = (props: PropsSTableRow) => {
    switch (props.variantTable) {
        case "outline":
            return 'bold'
        default:
            return 'normal'
    }
}

const boxShadowHeader = (props: PropsSTableRow) => {
    switch (props.variantTable) {
        case "outline":
            if (props.isScrolling) {
                return '0 3px 13px -4px rgba(13, 28, 56, 0.25)'
            } else {
                return 'none'
            }
        default:
            return 'none'
    }
}

const paddingTableHeader = (props: PropsSTableRow) => {
    if (!props.customRowsCells) {
    switch (props.variantTable) {
        case "outline":
            return '2px 20px 1px 0'
        default:
            return '0'
    }
    } else {
        return '0'
    }
}

export const STableHeader = styled(STableRow)`
    padding: ${paddingTableHeader};
    margin-bottom: ${marginBottomTableHeader};
    background-color: ${backgroundColorTableHeader};
    align-items: center;
    border-bottom: ${borderBottomTableHeader};
    font-weight: ${fontWeightTableHeader};
    box-shadow: ${boxShadowHeader};
    transition: box-shadow .3s;
    z-index: 15;
    ${props => (props.isWrap && !props.isHeader) && `border-radius: 4px 4px 0 0;`};
`

export const STableGroup = styled(STableRow)<PropsSTableGroup>`
    margin: 4px 0 3px;
    font-weight: 600;
    ${props => props.isHighlighted && `font-style: italic`}
`

export const STableTotal = styled(STableRow)`
    border-top: 2px solid ${props => props.theme.colors.secondary};
    background-color: ${props => props.theme.colors.white};
    z-index: 15;
    cursor: default;
    justify-content: space-between;
    padding: 9px 8px 9px 0;
`

export const STableTotalsWrap = styled.div`
    display: flex;
    flex-direction: column;
`

const fontWeightTotal = (props: PropsSTableTotalItem) => {
    if (props.isHighlighted) {
        switch (props.variantTable) {
            case 'default':
                return '600'
            case 'outline':
                return 'bold'
            default:
                return '600'
        }
    } else {
        return 'normal'
    }
}

export const STableTotalItem = styled.div<PropsSTableTotalItem>`
    font-size: ${props => props.fontSizeRow || '16px'};
    font-weight: ${fontWeightTotal};
    position: relative;
    width: max-content;
    padding: 4px;
`

export const STableTotalLabel = styled.div<PropsSTableTotalLabel>`
    position: absolute;
    display: flex;
    right: 100%;
    width: ${props => props.width};
`
