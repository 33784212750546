import React from 'react';
import {TaskCallCenter} from "../../../../types/common";
import {CareData} from "../../../../types/taskData";
import TaskContent from "../TaskContent";
import {NoteDownButton, FinalButton, OpenPlanButton} from "../../../../components/Buttons";
import {UniquePatient, Services} from "../../../../components/Labels";
import {CommonTaskProps} from "../../types/common";


interface Props extends CommonTaskProps {
    task: TaskCallCenter<CareData>
}

export const Care = ({
                         task,
                         uniqPatient,
                         infoCallCenter,
                         methodsCallCenter,
                         onChangeSelectedScript,
                         ...props
                     }: Props) => {
    return (
        <TaskContent onChangeSelectedScript={onChangeSelectedScript}
                     buttons={<Buttons task={task} {...props} methodsCallCenter={methodsCallCenter}/>} task={task}>
            <UniquePatient task={task} uniqPatient={uniqPatient}/>
            <Services task={task} isSort={true}/>
        </TaskContent>
    );
};

const Buttons = ({
                     task,
                     validate,
                     blockedButtons,
                     methodsCallCenter
                 }: Omit<Props, 'uniqPatient' | 'infoCallCenter' | 'onChangeSelectedScript'>) => {
    return (
        <>
            <NoteDownButton validate={validate} task={task} blockedButtons={blockedButtons}
                            methodsCallCenter={methodsCallCenter}/>
            <FinalButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
            <OpenPlanButton task={task} methodsCallCenter={methodsCallCenter}/>
        </>
    )
}

