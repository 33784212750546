import React, {useContext, useState, createContext} from "react";
import useUpdateNewVisit from "./hooks/useUpdateNewVisit";
import Title from "./components/Title";
import BasicForm from "./containers/BasicForm";
import ExtraNewVisit from "./components/ExtraNewVisit";
import Duration from "./components/Duration";
import CommentNewVisit from "./components/CommentNewVisit";
import FooterNewVisit from "./containers/FooterNewVisit";
import SearchBar from "./components/SearchBar/SearchBar";
import {BoxBody} from "../../components-ui/styledComponent/common/wraps";
import Loader from "../../components-ui/Feedback/Loader/Loader";
import Paper from "../../containers/UI-Collection/Elements/Surfaces/Paper";
import Box from "../../components-ui/Layout/Box";
import useOtherPatientValue from "./hooks/useOtherPatientValue";
import useForm from "./hooks/useForm";
import useInitial from "./hooks/useInitial";
import useLoadingPatient from "./hooks/useLoadingPatient";
import {ContextNewVisit, PropsNewVisit} from "./types/common";
import useChangeOtherPersonalPhone from "./hooks/useChangeOtherPersonalPhone";
import useDuplicate from "../../containers/PatientField/hooks/useDuplicate";
import {basicFields, otherPatientFields} from "./helper/basicFields";
import {useFields, useShownFields} from "../../containers/PatientField";

const NewVisitFormContext = createContext({} as ContextNewVisit);
export const useNewVisitForm = () => useContext(NewVisitFormContext)

export default function NewVisitForm(props: PropsNewVisit) {
    const fields = useFields({})
    const [appointmentTime, setAppointmentTime] = useState('15')
    const [maxVisitDuration, setMaxVisitDuration] = useState<number | null>(null)
    const [isDateOtherPatient, setIsDateOtherPatient] = useState(false)
    const {
        initialForm,
        form,
        setForm,
        resetForm,
        onChangeFieldBasic,
        onChangeFieldRelation,
        onChangeFieldOtherPatient,
        onChangeValidationBasic,
        onChangeValidationOtherPatient,
        onChangeValidationRelation
    } = useForm({fields})
    const {loadingPatient, loaderPatient} = useLoadingPatient({setForm, fields})
    useInitial({props, setForm, setMaxVisitDuration, loadingPatient, setAppointmentTime})
    useUpdateNewVisit({form, props, initialForm});
    useChangeOtherPersonalPhone({form, setForm})
    const isOtherPatientValue = useOtherPatientValue({form});
    const duplicate = useDuplicate({
        form,
        isOtherPatientValue,
        loadingPatient,
        setIsDateOtherPatient,
        setForm,
        otherPatientFields,
        fields
    })


    const shownFields = useShownFields({
        currentPatient: form.currentPatient,
        formFields: basicFields,
        fields
    }).filter(fieldName => {
        if (fieldName === "hasMissingTeeth") {
            return true
        } else {
            return fields[fieldName].group === 'basic'
        }
    })

    if (!!maxVisitDuration) {
        return (
            <NewVisitFormContext.Provider value={{
                props,
                form,
                setForm,
                maxVisitDuration,
                resetForm,
                loadingPatient,
                appointmentTime,
                setAppointmentTime,
                isOtherPatientValue,
                onChangeFieldBasic,
                onChangeFieldRelation,
                onChangeFieldOtherPatient,
                onChangeValidationBasic,
                onChangeValidationOtherPatient,
                onChangeValidationRelation,
                isDateOtherPatient,
                setIsDateOtherPatient,
                ...duplicate,
                fields,
                shownFields
            }}>
                <Loader visible={loaderPatient}/>
                <Title/>
                <Paper isHidden={false}>
                    <SearchBar/>
                    <BoxBody>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                            <BasicForm/>
                            <Box display="flex" flexDirection="column" width="45.5%">
                                <ExtraNewVisit/>
                                <Duration/>
                                <CommentNewVisit/>
                            </Box>
                        </Box>
                    </BoxBody>
                    <FooterNewVisit/>
                </Paper>
            </NewVisitFormContext.Provider>
        );
    } else {
        return null
    }
}
