import React, {useState} from "react";
import TextField from "../../../../../../components-ui/Inputs/TextField/TextField";
import {Label} from "../../../../../../components-ui/DataDisplay/Label";

export default function Password() {

    const [value, setValue] = useState('');

    return (
        <div>
            <Label labelText="Password" align="top">
                <TextField value={value} onChange={(val:string) => setValue(val)} isPassword={true}/>
            </Label>

            <pre>
                {
                    `<Label labelText="Password" align="top">
                        <TextField value={value} onChange={(val) => setValue(val)} isPassword={true}/>
                    </Label>`
                }
            </pre>
        </div>
    )
}
