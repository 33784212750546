import React, {Dispatch, SetStateAction} from "react";
import Typography from "../../../../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../../../../../../containers/Tasks/data/commonPropsSComponent";
import {StateCallCenter} from "../../../../../types/components";
import {PlannedCall} from "../../../../../types/common";
import { STaskList } from "../../../../../../../containers/Tasks/style";

interface Props {
    setState: Dispatch<SetStateAction<StateCallCenter>>
    plannedCall: PlannedCall
}

const FooterExtendedCall = ({setState, plannedCall}: Props) => {
    return (
        <STaskList padding="8px 20px 11px">
            <Typography {...commonPropsTypography}
                        cursor="pointer"
                        color="info"
                        onClick={() => setState(prevState => ({...prevState, detail: plannedCall}))}
                        children="Детальная форма"/>
        </STaskList>
    )
}

export default FooterExtendedCall
