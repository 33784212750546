import React, {Dispatch, SetStateAction} from "react";
import Paper from "../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import GoToTaskList from "./components/GoToTaskList";
import {StateCallCenter} from "../../types/components";
import Scrollbar from "../../../../components-ui/DataDisplay/Scrollbar/Scrollbar";
import TabsHeader from "./components/TabsHeader";
import Header from "./containers/Header/Header";
import PatientsTabs from "./components/PatientsTabs";
import DetailedHistory from "./containers/DetailedHistory/DetailedHistory";
import ProgressCall from "./containers/ProgressCall/ProgressCall";
import PlannedCalls from "./containers/PlannedCalls/PlannedCalls";
import FooterDetail from "./containers/Footer/FooterDetail";
import useDetailed from "./hooks/useDetailed";
import {InfoCallCenter} from "../../types/hooks";
import {MethodsCallCenter, OnChangeSelectedScript} from "../../types/common";
import {TasksInfo} from "../../../../containers/Tasks";
import {SContainer} from "../../../../containers/Tasks/style";


interface Props {
    state: StateCallCenter
    setState: Dispatch<SetStateAction<StateCallCenter>>
    infoCallCenter: InfoCallCenter
    setScriptsType: Dispatch<SetStateAction<string | null>>
    methodsCallCenter: MethodsCallCenter
    tasksInfo: TasksInfo
    onChangeSelectedScript: OnChangeSelectedScript
}


const DetailedTask = (
    {
        state,
        setState,
        infoCallCenter,
        setScriptsType,
        methodsCallCenter,
        tasksInfo,
        onChangeSelectedScript
    }: Props) => {

    const {
        directionsNames,
        callerTypes,
        detailedState,
        setDetailedState,
        patient,
        futureComment,
        setFutureComment,
        loaderHistory,
        setLoaderHistory,
        isCallSubject,
        validate
    } = useDetailed({state, infoCallCenter, setScriptsType});

    return (
        <>
            <GoToTaskList state={state} setState={setState}/>
            <PatientsTabs detailedState={detailedState} setDetailedState={setDetailedState}/>
            <Scrollbar height="calc(100vh - 83px)">
                <SContainer padding='15px 8px 32px 20px'>
                    <Paper margin="0 0 12px" isHidden={false} zIndex={13}>
                        <TabsHeader state={state} detailedState={detailedState} setDetailedState={setDetailedState}
                                    setLoaderHistory={setLoaderHistory}/>
                        <Header state={state} detailedState={detailedState} methodsCallCenter={methodsCallCenter}
                                tasksInfo={tasksInfo}/>
                        {detailedState.headerTab === 'history' &&
                        <DetailedHistory detailedState={detailedState} loaderHistory={loaderHistory}/>}
                    </Paper>
                    {detailedState.headerTab !== 'history' && (
                        <>
                            {!!detailedState.progressCall.length &&
                            <ProgressCall detailedState={detailedState} setDetailedState={setDetailedState}
                                          methodsCallCenter={methodsCallCenter}
                                          state={state} patient={patient} validate={validate}
                                          futureComment={futureComment} setFutureComment={setFutureComment}
                                          directionsNames={directionsNames} callerTypes={callerTypes}/>}
                            <PlannedCalls infoCallCenter={infoCallCenter} methodsCallCenter={methodsCallCenter}
                                          tasksInfo={tasksInfo} validate={validate} state={state}
                                          detailedState={detailedState}
                                          onChangeSelectedScript={onChangeSelectedScript}/>
                            {!!state.detail && state.detail.canSaveResult &&
                            <FooterDetail detailedState={detailedState} setDetailedState={setDetailedState}
                                          state={state} patient={patient} validate={validate} setState={setState}
                                          futureComment={futureComment} isCallSubject={isCallSubject}/>}
                        </>
                    )}
                </SContainer>
            </Scrollbar>
        </>
    )
}

export default DetailedTask
