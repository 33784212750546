import PatientField from "./containers/PatientField";

export default PatientField

//helper
export {over14YearsOld} from './helper/over14YearsOld'
export {defaultOptionsPhoneOwner} from './helper/defaultOptionsPhoneOwner'

//hooks
export {useShownFields} from './hooks/useShownFields'
export {usePrepareStateForm} from './hooks/usePrepareStateForm'
export {usePrepareValidationFields} from './hooks/usePrepareValidationFields'
export {usePrepareValueField} from './hooks/usePrepareValueField'
export {useFields} from './hooks/useFields'

//types.ts
export type {ParentNamePatientField, PatientValues, StateBasicForm, IComposite} from './types/common'
// Patient
// PatientFieldsTypes
export type {
    IPatientField,
    PatientFields,
    PatientFieldType,
    PatientFieldKey,
    PatientFieldGroupCode,
    GroupFields,
    ValidationFields,
} from './types/fields'
// PatientRelationTypesTitles
export type {PatientRelationKey, PatientRelationTypesTitles, RelationType} from './types/relationTypesTitles'
