import styled from "styled-components";


export const SAnalytics = styled.div`
    .table__cell {
        cursor: default;
        &--patient {
            width: 20%;
        }
        &--recommendationdoctor {
            width: 40%;
        }
        &--remainingsum {
            width: 20%;
        }
        &--paidsum {
            width: 20%;
        }

    }
`;

export const AnalyticsFilter = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    & > div {
        margin-right: 20px;
    }
`;
