import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const LongArrowIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 11.88"} ref={ref}>
            <path d="M22.78,5.61,17.93,1.19a.39.39,0,0,0-.44-.06.37.37,0,0,0-.24.36V4.32H1.49a.39.39,0,0,0-.4.41V7.15a.4.4,0,0,0,.11.29.39.39,0,0,0,.29.11H17.25v2.83a.39.39,0,0,0,.24.37.38.38,0,0,0,.44-.08L22.78,6.2a.38.38,0,0,0,.13-.3A.37.37,0,0,0,22.78,5.61Z"/>
            <path d="M17.66,11.88a1.37,1.37,0,0,1-.59-.13h0a1.48,1.48,0,0,1-.87-1.36V8.65H1.49A1.5,1.5,0,0,1,.43,8.21,1.47,1.47,0,0,1,0,7.15V4.73A1.46,1.46,0,0,1,.43,3.67a1.5,1.5,0,0,1,1.06-.44H16.16V1.49A1.45,1.45,0,0,1,17.07.12a1.5,1.5,0,0,1,1.55.22l.05,0,4.87,4.44v0A1.48,1.48,0,0,1,24,5.9,1.51,1.51,0,0,1,23.55,7l0,0-4.86,4.48h0A1.5,1.5,0,0,1,17.66,11.88ZM2.18,6.46H18.34V8.81l3.15-2.9L18.34,3V5.41H2.18Z"/>
        </WrapIcon>
    );
});
