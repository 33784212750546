import React from 'react'
import PropTypes from 'prop-types'
import Group from './Group'
import Helper from "../../common/helpers/main";
import Scrollbars from "../../components-ui/Scrollbars/scrollbars";
import Element from "./Element";
import Checkbox from "../../components-ui/Inputs/Checkbox/Checkbox";

export default class GroupsTree extends React.Component {

    static propTypes = {
        groups: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        onSelect: PropTypes.func,
        selectedGroupId: PropTypes.number,
        selectedElementsIds: PropTypes.array,
        isFocused: PropTypes.bool,
        elements: PropTypes.array,
        getElementGroupId: PropTypes.func,
        scrollHeight: PropTypes.number.isRequired,
        isMultipleSelect: PropTypes.bool,
        withHeader: PropTypes.bool,
        dblclickElement: PropTypes.func,
        isCheckboxGroup: PropTypes.bool,
        checkCheckboxGroup: PropTypes.bool,
        onClickCheckboxGroup: PropTypes.func,
        sort: PropTypes.bool,
        isTemplate: PropTypes.bool,
        relatedTemplates: PropTypes.array,
        isAddNumburing: PropTypes.bool
    };

    static defaultProps = {
        elements: [],
        getElementGroupId: element => element.groupId,
        onSelect: () => {
        },
        dblclickElement: () => {
        },
        isFocused: false,
        isMultipleSelect: false,
        withHeader: true,
        isTemplate: false,
        sort: true
    };

    render() {
        let groups = Helper.clone(this.props.groups);
        let elements = Helper.clone(this.props.elements);
        if (Array.isArray(groups)) groups = Helper.Array.toObject(groups, 'id');
        this.addElementsInGroups(groups);
        groups = Object.values(groups);
        elements = elements.filter((element) => this.getElementGroupIds(element).includes(0));
        if (this.props.sort) {
            groups = this.sortElementsGroups(groups);
        }
        if (this.props.isAddNumburing) {
            this.addNumbering(groups, elements);
        }

        if (this.props.relatedTemplates !== undefined) {
            if (this.props.checkCheckboxGroup && this.props.isCheckboxGroup) {
                if (groups.length) {
                    this.filterTemplateElements(groups);
                    groups = this.filterTemplateGroups(groups);
                }
                if (elements.length) {
                    elements = elements.filter(element => this.props.relatedTemplates.some(related => related.id === element.id))
                }

            }
        }
        return (
            <div className="tree__wrapper">
                {this.props.withHeader &&
                <div className="tree__header">
                    Группа
                    {this.props.isCheckboxGroup &&
                    <Checkbox label="По диагнозам" onChange={this.props.onClickCheckboxGroup}
                              checked={this.props.checkCheckboxGroup}/>}
                </div>
                }
                <Scrollbars style={{height: this.props.scrollHeight}}>
                    <ul className="tree">
                        {groups.map(group => <Group group={group}
                                                    key={group.id}
                                                    onClick={this.props.onSelect}
                                                    selectedGroupId={this.props.selectedGroupId}
                                                    selectedElementsIds={this.props.selectedElementsIds}
                                                    isFocused={this.props.isFocused}
                                                    isMultipleSelect={this.props.isMultipleSelect}
                                                    dblclickElement={this.props.dblclickElement}
                        />)}
                        {elements.map(element => <Element
                                element={element}
                                key={`element-${element.id}`}
                                onClick={this.props.onSelect}
                                selectedElementsIds={this.props.selectedElementsIds}
                                isMultipleSelect={this.props.isMultipleSelect}
                                dblclick={this.props.dblclickElement}
                                isFocused={this.props.isFocused}
                            />
                        )}

                    </ul>
                </Scrollbars>
            </div>
        );
    }

    filterTemplateElements = (groups) => {
        groups.forEach(group => {
            if (group.elements !== undefined) {
                group.elements = group.elements.filter(element => this.props.relatedTemplates.some(related => related.id === element.id))
            }
            if (group.groups !== undefined) {
                this.filterTemplateElements(group.groups)
            }
        });
    };

    filterTemplateGroups = (groups) => {
        groups = groups.filter(group => {
            if (group.groups !== undefined) {
                group.groups = this.filterTemplateGroups(group.groups)
            }
            return (
                (group.elements !== undefined ? group.elements.length : false) ||
                (group.groups !== undefined ? group.groups.length : false)
            )
        });
        return groups;
    };

    addElementsInGroups(groups) {
        if (!this.props.elements.length) return;
        /** Объект, в котором ключи - id групп, а значения - массивы элементов этих групп
         * @type {{}} */
        let elementsGroupsMap = {};
        /* заполняем elementsGroupsMap */
        this.props.elements.forEach(element => {
            const elementGroupIds = this.getElementGroupIds(element);
            elementGroupIds.forEach(groupId => {
                if (!elementsGroupsMap[groupId]) elementsGroupsMap[groupId] = [];
                elementsGroupsMap[groupId].push(element);
            });
        });
        /* сохраняем элементы групп по ключу elements этих групп  */
        Helper.Group.forEach(groups, group => {
            if (elementsGroupsMap[group.id]) group.elements = elementsGroupsMap[group.id];   /// добавить проверку на уникальность элемента!!
        })
    }

    /**
     * Возвращает массив id групп переданного элемента
     * @param element
     */
    getElementGroupIds(element) {
        let groupIds = this.props.getElementGroupId(element);
        if (!Array.isArray(groupIds)) groupIds = [groupIds];
        return groupIds;
    }

    sortElementsGroups(groups) {
        if (groups.groups !== undefined) this.sortElementsGroups(groups.groups);
        return groups.sort((a, b) => a.name.localeCompare(b.name));
    }

    addNumbering = (groups, elements, prevGroup = {numbering: ''}) => {
        let numbering = 0;
        if (groups.length) {
            groups.forEach(group => {
                numbering += 1;
                group.numbering = prevGroup.numbering + String(numbering) + '.';
            });
        }
        if (elements !== undefined) {
            if (elements.length) {
                elements.forEach(element => {
                    numbering += 1;
                    element.numbering = prevGroup.numbering + String(numbering) + '.';
                });
            }
        }
        if (groups.length) {
            groups.forEach(group => {
                if (group.groups !== undefined) {
                    this.addNumbering(group.groups, group.elements, group);
                } else if (group.elements !== undefined) {
                    this.addNumbering([], group.elements, group);
                }
            })
        }
    };

}
