import {useEffect} from "react";
import Helper from "../../../../../common/helpers/main";
import {useSlideModal} from "../../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import _ from 'lodash'
import {PropsUseUpdatePreparation} from "../types/hooks";

export default function useUpdatePreparation({forms, props, initialForms} : PropsUseUpdatePreparation) {
    const {labelParams, removeMinimizeParams, setMinimizeParams } = useSlideModal();
    useEffect(() => {
        if (!_.isEqual(forms, initialForms)) {
            if (!labelParams) {
                setMinimizeParams({
                    icon: 'print',
                    title: 'Подготовка к приёму',
                    text: props.data.patient !== null ? Helper.getFio(props.data.patient) : '',
                    canBeDeleted: true,
                    keySlideModal: String(props.visitId)
                })
            }
        } else {
            if (!!labelParams) {
                removeMinimizeParams()
            }
        }
    }, [JSON.stringify(forms)])
}
