import React from "react";
import {usePatientField} from "../PatientField";
import EditComposite from "./containers/Edit/EditComposite";
import ViewComposite from "./containers/View/ViewComposite";

export default function Composite() {
    const {isEdit} = usePatientField()

    return (
        <>
            {isEdit ? <EditComposite/> : <ViewComposite/>}
        </>
    )
}
