import {useModal} from '../../../../../../containers/DialogsManager/containers/Modal/Modal';
import {IXRayPicturesRow} from '../../../XrayPicturesTable/types';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import {IStateXrayPicturesModal} from '../types';
import {OptionSelect} from '../../../../../../components-ui/Inputs/Select/typesSelect';
import ServerCommand from '../../../../../../common/server/server-command';
import {CreateXrayPictureParams, UpdateXrayPictureParams} from '../../../../types';
import useLoader from '../../../../../../components/Errors';

interface Props {
    xrayPicture?: IXRayPicturesRow;
    selectedBranch: number;
}

//TODO изменить все фильтры .deletedAt === null на функцию notTrashed()

const useXrayPicturesModalMark = ({xrayPicture, selectedBranch}: Props) => {

    const {closeModal} = useModal();

    const [loader, setLoader] = useLoader();

    const doctors = useSelector(state => state.common.doctors);

    const xrayPictureTypes = useSelector(state => state.common.xrayPictureTypes);

    const doctorColor = () => {
        const fixedDoctor = xrayPicture && doctors.find((doc) => doc.id === xrayPicture.doctorId);
        return fixedDoctor?.color;
    };

    const [state, setState] = useState<IStateXrayPicturesModal>({
        creator: xrayPicture &&
            {value: xrayPicture.creatorId, label: xrayPicture.creator},
        doctor: xrayPicture
            &&
            {
                value: xrayPicture.doctorId,
                label: xrayPicture.doctor,
                color: doctorColor(),
            }
        ,
        patientId: xrayPicture ? xrayPicture.patientId : undefined,
        qualitative: xrayPicture ? xrayPicture.qualitative : false,
        type: xrayPicture &&
            {value: xrayPicture.type, label: xrayPictureTypes[xrayPicture.type]},
        comment: xrayPicture ? xrayPicture.comment : '',
    });


    const handleCreator = (value: OptionSelect) => {
        setState(prevState =>
            ({...prevState, creator: value}),
        );
    };

    const handleDoctor = (value: OptionSelect) => {
        setState(prevState =>
            ({...prevState, doctor: value}),
        );
    };

    const handlePatient = (value: number | undefined) => {
        setState(prevState =>
            ({...prevState, patientId: value}),
        );
    };

    const handleType = (value: OptionSelect) => {
        setState(prevState =>
            ({...prevState, type: value}),
        );
    };

    const handleQualitative = (value: boolean) => {
        setState(prevState =>
            ({...prevState, qualitative: value}),
        );
    };

    const handleComment = (value: string) => {
        setState(prevState =>
            ({...prevState, comment: value}),
        );
    };

    const [validationCheck, setValidationCheck] = useState(false);

    const isValidComment = !state.qualitative ? !state.comment : false;

    const isValid =
        !!state.creator &&
        !!state.doctor &&
        !!state.patientId &&
        !!state.type &&
        !isValidComment;

    const params = () => {
        if (state.creator && state.doctor && state.type) {
            return xrayPicture ?
                {
                    id: xrayPicture.id,
                    creatorId: state.creator.value,
                    doctorId: state.doctor.value,
                    patientId: state.patientId,
                    type: state.type.value,
                    qualitative: state.qualitative,
                    comment: state.comment,
                } as UpdateXrayPictureParams
                :
                {
                    creatorId: state.creator.value,
                    doctorId: state.doctor.value,
                    patientId: state.patientId,
                    type: state.type.value,
                    qualitative: state.qualitative,
                    comment: state.comment,
                    branchId: selectedBranch,
                } as CreateXrayPictureParams;
        }

    };

    const saveXrayPicture = () => {
        setLoader(true);
        ServerCommand.post(`xray-picture/${xrayPicture ? 'update' : 'create'}`, params(), () => {
            closeModal();
        }).then();
    };

    return {
        state,
        handleComment,
        handleQualitative,
        handleCreator,
        handleType,
        handleDoctor,
        handlePatient,
        saveXrayPicture,
        validationCheck,
        setValidationCheck,
        isValid,
        loader,
    };
};

export default useXrayPicturesModalMark;
