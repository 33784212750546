import React from 'react';
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";
import Helper from "../../../../common/helpers/main";

interface Props {
    date: string
}

export const Date = ({date}: Props) => {

    if (!date) return null
    return (
        <LabelTask label="Дата"
                   children={Helper.Date.formatDate(date, 'DD MMM YYYYг.')}
                   alignItems="flex-start"
        />
    );
};
