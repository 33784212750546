import React from 'react'
import './Materials.scss'
import MaterialsList from "./SubComponents/MaterialsList";
import Invoices from "./SubComponents/Invoices/Invoices";
import WriteOff from "./SubComponents/WriteOff/WriteOff";
import Box from "../../components-ui/Wraps/old/Box";
import BoxBody from "../../components-ui/Wraps/old/BoxBody";
import Tabs from "../../components-ui/Navigation/Tabs/Tabs";


export default class Materials extends React.Component {
    tabs = [
        {name: 'Материалы', code: 'MATERIALS'},
        {name: 'Накладные', code: 'INVOICES'},
        {name: 'Списания', code: 'WRITEOFFS'},
        {name: 'Инвентаризация', code: 'INVENTORY'},
        {name: 'Движение материалов', code: 'MOVEMENT'}
    ];

    state = {
        activeTab: 'MATERIALS'
    };


    render() {
        let subStepComponent = null;
        let classNameBox = '';

        switch (this.state.activeTab) {
            case 'MATERIALS':
                subStepComponent = <MaterialsList/>;
                classNameBox = 'materials-list';
                break;
            case 'INVOICES':
                subStepComponent = <Invoices />;
                break;
            case 'WRITEOFFS':
                subStepComponent = <WriteOff />;
                break;
            default:
                break;
        }

        return (
            <Box modification="with-margin">
                <div className="materials">
                    {this.tabs.length > 1 &&
                    <Tabs
                        tabs={this.tabs}
                        activeTab={this.state.activeTab}
                        onChange={activeTab => this.setState({activeTab})}
                        borderBottom={true}
                    />}
                    <BoxBody modification={classNameBox}>
                        {subStepComponent}
                    </BoxBody>
                </div>
            </Box>
        )
    }
}
