import React from "react";
import PhoneHeader from "./components/PhoneHeader";
import CallDuration from "./components/CallDuration";
import PostponeCall from "../../../../components/PostponeCall/PostponeCall";
import {Divider} from "../../../../../../components-ui/DataDisplay/Divider";
import Helper from "../../../../../../common/helpers/main";
import Typography from "../../../../../../components-ui/DataDisplay/Typography/Typography";
import prepareContactPerson from "./helper/prepareContactPerson";
import prepareTimeToCall from "./helper/prepareTimeToCall";
import {commonPropsTypography} from "../../../../../../containers/Tasks/data/commonPropsSComponent";
import {useDialogsManager} from "../../../../../../containers/DialogsManager/DialogsManager";
import PatientCard from "../../../../../PatientCard/PatientCard";
import {StateCallCenter} from "../../../../types/components";
import {StateDetailed} from "../../hooks/useDetailed";
import {MethodsCallCenter} from "../../../../types/common";
import {TasksInfo} from "../../../../../../containers/Tasks";
import { STaskList, STaskItem } from "../../../../../../containers/Tasks/style";

interface Props {
    state: StateCallCenter
    detailedState: StateDetailed
    methodsCallCenter: MethodsCallCenter
    tasksInfo: TasksInfo
}

const Header = ({state, detailedState, methodsCallCenter, tasksInfo}: Props) => {
    const {detail, calls} = state
    const {headerTab, progressCall, plannedCalls} = detailedState
    const {showSlideModal} = useDialogsManager()
    if (!detail || headerTab !== 'task') return null
    const contactPerson = prepareContactPerson({detail, progressCall, plannedCalls});
    const timeToCall = prepareTimeToCall({progressCall, detail, calls});
    const propsContactPerson = () => {
        const res = {...commonPropsTypography} as any;
        if (!!contactPerson) {
            if (typeof contactPerson !== 'string') {
                res.cursor = 'pointer';
                res.color = 'info';
                res.onClick = () => {
                    showSlideModal(<PatientCard patientId={contactPerson.id}/>)
                }
            }
        }
        return res
    }
    return (
        <>
            <STaskList padding="12px 0 11px">
                <PhoneHeader state={state}/>
                <CallDuration state={state}/>
            </STaskList>
            {!!detail.tasks.length && <PostponeCall plannedCall={detail} isDetail={true} calls={calls} tasksInfo={tasksInfo}
                                                    postponeServerCommand={methodsCallCenter.postponeServerCommand}/>}
            {(!!contactPerson || !!timeToCall) && (
                <>
                    <STaskItem>
                        <Divider/>
                    </STaskItem>
                    <STaskList padding="12px 0 11px">
                        {!!contactPerson && (
                            <STaskItem margin="2px 0" isHighlighted={true}>
                                <Typography {...commonPropsTypography} children="Контактное лицо" width="38%"
                                            flexShrink={0}/>
                                <Typography {...propsContactPerson()}
                                            display="flex" alignItems="center" width="62%" flexShrink={0}
                                            textAlign="right" justifyContent="flex-end"
                                            children={typeof contactPerson === 'string' ?
                                                contactPerson : Helper.getFullName(contactPerson)}
                                />
                            </STaskItem>
                        )}
                        {!!timeToCall && (
                            <STaskItem margin="2px 0">
                                <Typography {...commonPropsTypography} children="Время звонка"/>
                                <Typography {...commonPropsTypography}
                                            children={Helper.Date.formatComingTime(timeToCall)}
                                />
                            </STaskItem>
                        )}
                    </STaskList>

                </>
            )}
        </>
    )
}

export default Header
