import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth13Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 6.55 24"} width={width || "12"} height={height || "44"} ref={ref}>
            <path d="M1.15,0h.08c.73.33,3,3.85,4.05,15.3a2.76,2.76,0,0,0-1.95-1.24,2.74,2.74,0,0,0-2.22,1A73.61,73.61,0,0,0,1,1.68C.83.49,1.06.06,1.15,0ZM3.91,24C3.27,24,.74,22.1.12,21c-.33-.57,0-3.3,1.11-5a2.38,2.38,0,0,1,1.93-1.28h.12c.88.06,1.62,1.06,2.18,2.25h0a16.82,16.82,0,0,1,1.09,3.23C6.42,22.61,4.51,24,3.92,24Z"/>
        </WrapIcon>
    );
});
