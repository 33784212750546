import React, {forwardRef} from 'react';
import { WrapIcon } from '../../../containers/WrapIcon';
import { IIcon } from '../../../types/common';

export const DocumentDeleteIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 20 24"} ref={ref}>
            <path d="M20,6,13.4,0H0V24H20ZM13.85,2.4l3.52,3.23H13.85Zm4.61,20.19H1.54V1.41H12.31V7h6.15Z"/>
            <polygon points="15 9.91 13.09 8 10 11.09 6.91 8 5 9.91 8.09 13 5 16.09 6.91 18 10 14.91 13.09 18 15 16.09 11.91 13 15 9.91"/>
        </WrapIcon>
    );
});
