import React, {Dispatch, SetStateAction} from 'react';
import Box from "../../../components-ui/Layout/Box";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import {Article, IDataPatientPriceList} from "../types";
import Button from "../../../components-ui/Inputs/Button/Button";
import PriceListTable from "../content/PriceListTable";
import {usePriceList} from "../hooks/usePriceList";
import {useDevice} from "../../../common/hooks/useDevice";

interface Props {
    data: IDataPatientPriceList
    setOtherArticles: Dispatch<SetStateAction<Article[]>>
}

const PriceList = ({data, setOtherArticles}: Props) => {

    const device = useDevice()

    const {search, setSearch, servicesGroups, onClickGroup, onResetTable} = usePriceList({data, setOtherArticles})

    return (
        <Box margin={device !== "mobile" ? "40px 0 0 0" : "20px 0 0 0"}>
            <Typography variant={device !== "mobile" ? "bigger-title" : "mobile-title"}>Прейскурант</Typography>
            <Box display="flex" justifyContent="space-between"
                 margin={device !== "mobile" ? "34px 0 0 0" : "20px 0 0 0"}>
                <TextField value={search}
                           onChange={setSearch}
                           placeholder="Поиск услуги"
                           isSearch={true}
                           widthInput={'100%'}
                           isReset={device === "mobile" && !!search.length}
                           onClickReset={onResetTable}
                />

                {device !== "mobile" &&
                <Button variant="standard" children="Сбросить" margin="0 0 0 15px" width="140px"
                        onClick={onResetTable}/>
                }
            </Box>
            <PriceListTable search={search} onClickGroup={onClickGroup} servicesGroups={servicesGroups}/>
        </Box>
    );
};

export default PriceList;