import React from "react";
import styled from "styled-components";
import {PropsBox} from "./types";
import {forwardRef} from "react";

const SBox = styled.div<PropsBox>`
    ${props => props.opacity !== undefined && `opacity: ${props.opacity};`};
    ${props => !!props.boxSizing && `box-sizing: ${props.boxSizing};`};
    ${props => !!props.height && `height: ${props.height};`};
    ${props => !!props.flexDirection && `flex-direction: ${props.flexDirection};`};
    ${props => !!props.display && `display: ${props.display};`};
    ${props => !!props.visibility && `visibility: ${props.visibility};`};
    ${props => !!props.justifyContent && `justify-content: ${props.justifyContent};`};
    ${props => !!props.alignItems && `align-items: ${props.alignItems};`};
    ${props => !!props.alignSelf && `align-self: ${props.alignSelf};`};
    ${props => !!props.padding && `padding: ${props.padding};`};
    ${props => !!props.margin && `margin: ${props.margin};`};
    ${props => !!props.position && `position: ${props.position};`};
    ${props => !!props.boxShadow && `box-shadow: ${props.theme.shadow[props.boxShadow]};`};
    ${props => !!props.borderRadius && `border-radius: ${props.borderRadius};`};
    ${props => !!props.minHeight && `min-height: ${props.minHeight};`};
    ${props => !!props.minWidth && `min-width: ${props.minWidth};`};
    ${props => !!props.maxWidth && `max-width: ${props.maxWidth};`};
    ${props => !!props.width && `width: ${props.width};`};
    ${props => props.zIndex !== undefined && `z-index: ${props.zIndex};`};
    ${props => !!props.cursor && `cursor: ${props.cursor};`};
    ${props => !!props.backgroundColor && `background: ${(props.backgroundColor[0] === '#' || props.backgroundColor[0] === 'r') ? props.backgroundColor : props.theme.colors[props.backgroundColor]};`};
    ${props => !!props.border && `border: ${props.borderWidth || '1px'} solid ${props.theme.colors[props.border]};`};
    ${props => !!props.borderBottom && `
         border-bottom: ${props.borderWidth || '1px'} solid ${!!props.borderBottom ? props.theme.colors[props.borderBottom] : props.theme.colors.borderGray};
    `};
    ${props => !!props.positionOffsets && `
        left: ${props.positionOffsets.left !== undefined ? props.positionOffsets.left : 'auto'};
        right: ${props.positionOffsets.right !== undefined ? props.positionOffsets.right : 'auto'};
        bottom: ${props.positionOffsets.bottom !== undefined ? props.positionOffsets.bottom : 'auto'};
        top: ${props.positionOffsets.top !== undefined ? props.positionOffsets.top : 'auto'};
        transform: translate(${!!props.positionOffsets.transformX ? props.positionOffsets.transformX : 0}, ${!!props.positionOffsets.transformY ? props.positionOffsets.transformY : 0});
    `};
    ${props => !!props.transform && `transform: ${props.transform};`};
    ${props => !!props.flexWrap && `flex-wrap: ${props.flexWrap};`};
    ${props => !!props.flexGrow && `flex-grow: ${props.flexGrow};`};
    ${props => props.flexShrink !== undefined && `flex-shrink: ${props.flexShrink};`};
    ${props => !!props.overflow && `overflow: ${props.overflow};`}
    ${props => !!props.gap && `gap: ${props.gap};`}
`

const Box = forwardRef(({as = 'div', ...props}: Omit<PropsBox, 'innerRef'>, ref: any) => {
    return (
        <SBox {...props} as={as} ref={ref}/>
    )
})

export default Box;
