import * as React from "react";
import Button from "../../../components-ui/Inputs/Button/Button";

export default function CallButtons({answered, answer, endCall}: Props) {
    return (
        <>
            {!answered && <Button variant="default" children="Поднять трубку" onClick={answer}/>}
            <Button variant="default" children="Завершить звонок" onClick={endCall}/>
        </>
    );
}

interface Props {
    answered: boolean
    answer: () => void
    endCall: () => void
}
