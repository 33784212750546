import styled from "styled-components";
import Helper from "../../../common/helpers/main";
import { IButton } from "./typesButton";

// variant
const borderColor = (props: IButton) => {
    const {theme, loader, noBorder} = props;
    if (noBorder) return 'transparent'
    if (loader) return theme?.colors.borderGray;
    switch (props.variant) {
        case "default" :
        case "disabled":
        case "outline--secondary":
        case "reset":
        case "standard":
            return theme?.colors.borderGray;
        case "info":
            return theme?.colors.info;
        case "info-outline":
            return theme?.colors.info05
        case "success":
            return theme?.colors.success;
        case "danger":
            return theme?.colors.danger;
        case "dark":
            return theme?.colors.bgDark;
        case "circle":
            return theme?.colors.secondary;
        default:
            return 'transparent';
    }
};

const borderColorHover = (props: IButton) => {
    const {theme, loader} = props;
    if (loader) return theme?.colors.borderGray;
    switch (props.variant) {
        case "default" :
        case "reset":
        case "outline--secondary":
        case "standard":
            return theme?.colors.borderGrayActive;
        case "info":
        case "info-outline":
            return theme?.colors.infoHover;
        case "success":
            return theme?.colors.successHover;
        case "danger":
            return theme?.colors.danger;
        case "dark":
            return theme?.colors.bgDarkHover;
        case "disabled":
            return theme?.colors.borderGray;
        case "circle":
            return theme?.colors.whiteDark;
        default:
            return 'transparent';
    }
};

const borderColorActive = (props: IButton) => {
    const {theme, loader} = props;
    if (loader) return theme?.colors.borderGray;
    switch (props.variant) {
        case "default" :
        case "reset":
        case "outline--secondary":
        case "circle":
        case "standard":
            return theme?.colors.borderGrayActive;
        case "info":
        case "info-outline":
            return theme?.colors.info;
        case "success":
            return theme?.colors.successActive;
        case "disabled":
            return theme?.colors.borderGray;
        case "danger":
            return theme?.colors.danger;
        case "dark":
            return theme?.colors.bgDarkActive;
        default:
            return 'transparent';
    }
};

const background = (props: IButton) => {
    const {theme, loader} = props;
    if (loader) return theme?.colors.secondary;
    switch (props.variant) {
        case 'default' :
            return theme?.gradient.default;
        case "info":
            return theme?.colors.info;
        case "success":
            return theme?.colors.success;
        case "disabled":
        case "secondary":
        case "outline--secondary":
        case "circle":
            return theme?.colors.secondary;
        case "warning":
            return `rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.1)`;
        case "danger":
            return theme?.gradient.danger;
        case "dark":
            return theme?.colors.bgDark;
        case "reset":
        case "standard":
        case "info-outline":
            return theme?.colors.white;
        default:
            return 'transparent';
    }
};

const backgroundHover = (props: IButton) => {
    const {theme, loader, noShadow} = props;
    if (loader) return theme?.colors.secondary;
    switch (props.variant) {
        case 'default' :
            return theme?.gradient.defaultHover;
        case "info":
            return theme?.colors.infoHover;
        case "success":
            return theme?.colors.successHover;
        case "secondary":
            if (noShadow) {
                return theme?.colors.secondaryHover;
            } else {
                return theme?.colors.secondary;
            }
        case "disabled":
        case "outline--secondary":
        case "transparent-success":
        case "transparent-danger":
        case "transparent-default":
            return theme?.colors.secondary;
        case "warning":
            return `rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.18)`;
        case "danger":
            return theme?.gradient.dangerHover;
        case "dark":
            return theme?.colors.bgDarkHover;
        case "reset":
        case "standard":
        case "info-outline":
            return theme?.colors.white;
        case "circle":
            return theme?.colors.whiteDark;
        default:
            return 'transparent';
    }
};

const backgroundActive = (props: IButton) => {
    const {theme, loader} = props;
    if (loader) return theme?.colors.secondary;
    switch (props.variant) {
        case 'default' :
        case "reset":
        case "standard":
            return theme?.colors.whiteDark;
        case "info":
            return theme?.colors.infoActive;
        case "info-outline":
            return theme?.colors.bgButtonGray;
        case "success":
            return theme?.colors.successActive;
        case "disabled":
        case "secondary":
        case "outline--secondary":
        case "transparent-success":
        case "transparent-danger":
        case "transparent-default":
            return theme?.colors.secondary;
        case "warning":
            return `rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.25)`;
        case "danger":
            return theme?.gradient.dangerActive;
        case "dark":
            return theme?.colors.bgDarkActive;
        case "circle":
            return theme?.colors.borderGrayActive;
        default:
            return false;
    }
};

const boxShadow = (props: IButton) => {
    const {theme, loader, noShadow} = props;
    if (loader || noShadow) return 'none';
    switch (props.variant) {
        case 'default' :
            return theme?.shadow.defaultButton;
        case "success":
            return theme?.shadow.successDefault;
        case "danger":
        case "info":
        case "reset":
        case "warning":
        case "secondary":
        case "outline--secondary":
        case "info-outline":
            return theme?.shadow.default;
        default:
            return 'none';
    }
};

const boxShadowHover = (props: IButton) => {
    const {theme, loader, noShadow} = props;
    if (loader || noShadow) return 'none';
    switch (props.variant) {
        case 'default' :
            return theme?.shadow.defaultButton;
        case "success":
            return theme?.shadow.successDefault;
        case "danger":
        case "info":
        case "reset":
        case "warning":
        case "secondary":
        case "outline--secondary":
        case "transparent-success":
        case "transparent-danger":
        case "transparent-default":
        case "info-outline":
            return theme?.shadow.default;
        default:
            return 'none';
    }
};

const boxShadowActive = (props: IButton) => {
    const {theme, loader, noShadow} = props;
    if (loader || noShadow) return 'none';
    switch (props.variant) {
        case 'default' :
            return theme?.shadow.defaultButtonActive;
        case "reset":
        case "secondary":
        case "outline--secondary":
        case "transparent-success":
        case "transparent-danger":
        case "transparent-default":
        case "info-outline":
            return theme?.shadow.defaultActive;
        case "success":
            return theme?.shadow.successActive;
        case "danger":
            return theme?.shadow.dangerActive;
        default:
            return 'none';
    }
};

const colorBtn = (props: IButton) => {
    const {theme, loader, colorText} = props;
    if (loader) return '#6c7783';
    if (!!colorText) return theme?.colors[colorText]
    switch (props.variant) {
        case "info":
        case "success":
        case "danger":
        case "dark":
            return theme?.colors.white;
        case "disabled":
            return '#6c7783';
        case "transparent-danger":
        case "warning":
            return theme?.colors.danger;
        case "default":
        case "standard":
            return theme?.colors.textDarkGray;
        case "transparent-success":
            return theme?.colors.success;
        case "info-outline":
            return theme?.colors.info
        default:
            return theme?.colors.text
    }
};

// size
const padding = (props: IButton) => {
    if (props.variant === 'circle' || props.isIcon) return "3px";
    switch (props.size) {
        case "sm":
            return "4px 9px 5px";
        case "sm-standard":
            return "1px 12px 3px"
        case "lg":
            return "29px 10px";
        case "long":
            return "6px 21px 8px";
        default:
            return "6px 20px 8px"
    }
};

const borderRadius = (props: IButton) => {
    if (props.isIcon) return '2px';
    switch (props.size) {
        case "sm":
            return "4px";
        case "lg":
            return "6px";
        case "long":
        default:
            if (props.variant === "circle") return "50%";
            return "3px"
    }
};

const fontSize = (props: IButton) => {
    if (!!props.fontSize) return props.fontSize;
    switch (props.size) {
        case "lg":
            return '1.2rem';
        case "sm":
            return '0.9rem';
        case "sm-standard":
        default:
            return '1rem';
    }
};

const minWidth = (props: IButton) => {
    if (props.isIcon) {
        return 'auto'
    } else {
        switch (props.size) {
            case "long":
                return '160px';
            case "md":
                return '180px';
            default:
                return 'auto';
        }
    }
};

const justifyContent = (props: IButton) => {
    switch (props.align) {
        case "left":
            return "flex-start";
        case "right":
            return "flex-end";
        default:
            return "center"
    }
};

const width = (props: IButton) => {
    if (!!props.width) return props.width;
    if (props.isIcon) {
        switch (props.size) {
            case "md":
                return "36px";
            case "lg":
                return "40px";
            case "sm":
            default:
                return "30px";
        }
    } else {
        switch (props.variant) {
            case "circle":
                return '28px';
            default:
                return 'auto';
        }
    }
};

const height = (props: IButton) => {
    if (!!props.height) return props.height;
    if (props.isIcon) {
        switch (props.size) {
            case "md":
                return "36px";
            case "lg":
                return "40px";
            case "sm":
            default:
                return "30px";
        }
    } else {
        switch (props.variant) {
            case "circle":
                return '28px';
            default:
                return 'auto';
        }
    }

};

export const SButton = styled.button.attrs((props) => {
    return {
        type: props.type ? props.type : 'button'
    }
})<IButton>`
    font-family: ${props => props.theme.fonts.join(', ')};
    display: inline-flex;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: ${borderRadius};
    padding: ${padding};
    font-size: ${fontSize};
    min-width: ${minWidth};
    line-height: 1.5;
    align-items: center;
    vertical-align: middle;
    justify-content: ${justifyContent};
    text-align: center;
    color: ${colorBtn};
    border: ${props => props.noBorder ? 'none' : ` 1px solid ${borderColor(props)}`};
    background: ${background};
    box-shadow: ${boxShadow};
    cursor: ${props => props.variant === 'disabled' || props.loader ? 'not-allowed' : 'pointer'} ;
    user-select: none;
    position: relative;
    width: ${width};
    height: ${height};
    margin: ${props => !!props.margin ? props.margin : 0};
    ${props => !!props.flexShrink && `flex-shrink: ${props.flexShrink};`};
    &:hover {
        background: ${backgroundHover};
        border-color: ${borderColorHover};
        box-shadow: ${boxShadowHover};
    }
    &:active {
        background: ${backgroundActive};
        border-color: ${borderColorActive};
        box-shadow: ${boxShadowActive};
    }
    &:focus {
        outline: none;
    }

    ${props => props.isIcon && `
        & > div {
        fill: ${colorBtn(props)};
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${!!props.widthIcon ? props.widthIcon : '12px'};
        height: ${!!props.heightIcon ? props.heightIcon : '12px'};
        & > div {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    `}

    ${props => !!props.startIcon && `
        .button-start-icon {
            & > div {
                fill: ${colorBtn(props)};
                margin-right: 13px;
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                & > div {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    & > svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    `}
    ${props => !!props.badge && `
        .button-badge {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            position: absolute;
            border-radius: 50%;
            right: -5px;
            top: -5px;
            width: 18px;
            height: 18px;
            color: ${props.theme.colors.white};
            background: ${props.theme.colors.offline};
        }
    `}

    & > .button-plus {
        width: 10px;
        height: 10px;
        color: ${props => props.theme.colors.text};
    }
    ${(props) => props.isNext && `
    &::before {
        content: '';
        position: absolute;
        height: 50%;
        width: 7px;
        background-color: ${background(props)};
        top: 0.5px;
        right: -5px;
        transform: rotate(-12deg);
    }
    &::after {
        content: '';
        position: absolute;
        height: 50%;
        width: 7px;
        background-color: ${background(props)};
        bottom: 1.5px;
        right: -5px;
        transform: rotate(12deg);
    }
    `}
`;

export const SArrowOptions = styled.span.attrs({className: 'SArrowOptions'})`
    position: absolute;
    bottom: -8px;
    right: 4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 16px 16px 16px;
    border-color: transparent transparent ${props => props.theme.colors.white} transparent;
    transform: rotate(-90deg);
    z-index: -1;
    &::before {
        content: '';
        position: absolute;
        transform: rotate(45deg);
        width: 22px;
        height: 38px;
        left: 2px;
        bottom: -21px;
        z-index: -1;
        box-shadow: ${props => props.theme.shadow.default};
    }
`


