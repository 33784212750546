import React, {Dispatch, SetStateAction, useState} from 'react';
import {AutocompleteButton} from "./styledAutocomplete";
import Paper from "../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import {List, ListItem} from "../../../../DataDisplay/List";
import SVG from "../../../../../common/helpers/SVG";
import {IAutocomplete} from "../../typesTextField";
import Box from "../../../../Layout/Box";
import Scrollbar from "../../../../DataDisplay/Scrollbar/Scrollbar";

interface Props {
    dataAutocomplete?: IAutocomplete[],
    widthAutocomplete?: string,
    heightAutocomplete?: string,
    onChange: (value: string) => void,
    setOpenPopup: Dispatch<SetStateAction<boolean>>
    openPopup: boolean
    onClickItemAutocomplete?: () => void,
}

const Autocomplete = ({
                                         dataAutocomplete,
                                         widthAutocomplete = '100%',
                                         heightAutocomplete,
                                         onChange,
                                         setOpenPopup,
                                         openPopup,
                                         onClickItemAutocomplete
                                     }: Props) => {

    if (!(!!dataAutocomplete)) return null

    return (
        <>
            <AutocompleteButton
                active={openPopup}
                onClick={() => setOpenPopup(!openPopup)}>
                <SVG name="arrow-select"/>
            </AutocompleteButton>
            {openPopup && (
                <Box
                    position="absolute"
                    positionOffsets={{top: "calc(100% + 8px)"}}
                    width={widthAutocomplete}
                    zIndex={15}
                >
                    <Paper isModal={true} minWidth="100%">
                        <List height={heightAutocomplete}>
                            <Scrollbar>
                                {dataAutocomplete.map(item => {
                                    return <ListItem children={item.value} key={item.code}
                                                     onClick={(e: any, val: string) => {
                                                         onChange(val);
                                                         if (openPopup) {
                                                             setOpenPopup(false);
                                                             if (!!onClickItemAutocomplete) onClickItemAutocomplete();
                                                         }

                                                     }}/>
                                })}
                            </Scrollbar>

                        </List>
                    </Paper>
                </Box>
            )}
        </>
    )
}

export default Autocomplete
