import React, {useState} from 'react';
import {GetTimesheetListResult} from '../../types';
import useTableFormatter from './hooks/useTableFormatter';
import Table from '../../../../components-ui/DataDisplay/Table';
import Loader from '../../../../components-ui/Feedback/Loader/Loader';
import Box from '../../../../components-ui/Layout/Box';
import {useDialogsManager} from '../../../../containers/DialogsManager/DialogsManager';
import Button from '../../../../components-ui/Inputs/Button/Button';
import ModalMarkEmployee from './modals/containers/ModalMarkEmployee';

interface Props {
    data: GetTimesheetListResult;
    selectedBranchId: number;
    loader: boolean;
}

const TableTimesheetList = ({data, selectedBranchId, loader}: Props) => {

    const {showModal} = useDialogsManager();

    const [openOptions, setOpenOptions] = useState(false);

    const {timesheetColumns, timesheetRows} = useTableFormatter({
        data,
        openOptions,
        setOpenOptions,
        selectedBranchId,
    });
    return (
        <>
            <Loader visible={loader} width={30} height={30} />
            <Button variant='default' children='Отметить сотрудника' onClick={() => showModal(
                <ModalMarkEmployee data={data}
                                   selectedBranchId={selectedBranchId}
                                   isComing={true}
                />,
            )} />
            <Box margin='24px 0 0 0'>
                <Table
                    onScroll={() => {setOpenOptions(false)}}
                    maxHeight='480px'
                    columns={timesheetColumns}
                    rows={timesheetRows}
                    variantTable='outline'
                    fontSizeRow="14px"
                    isHovering={false} />
            </Box>
        </>
    );
};

export default TableTimesheetList;