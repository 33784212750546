import React, {Dispatch, SetStateAction} from 'react';
import Helper from "../../../../common/helpers/main";
import {useDateUpdate} from "../../../../common/hooks";
import usePostponeTask from "./hooks/usePostponeTask";
import {ManagerTask} from "../../types/common";
import {TasksInfo} from "../../../../containers/Tasks";
import {STaskItem, STaskList} from "../../../../containers/Tasks/style";
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../../../containers/Tasks/data/commonPropsSComponent";
import DateTime from "../../../../components-ui/Inputs/DateTime/DateTime";
import getNewDate from "../../../../containers/Tasks/utils/getNewDate";
import Switch from "../../../../components-ui/Inputs/Switch/Switch";
import getMinMaxDateTime from "./utils/getMinMaxDateTime";
import {Divider} from "../../../../components-ui/DataDisplay/Divider";
import {PostponeServerCommand} from "../../types/components";

interface Props {
    task: ManagerTask
    tasksInfo: TasksInfo
    postponeServerCommand: PostponeServerCommand
    setZIndex?: Dispatch<SetStateAction<number>>
}

const PostponeTask = ({task, postponeServerCommand, tasksInfo, setZIndex}: Props) => {
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);
    const {date, setDate, switchValue, setSwitchValue, minMaxDateTime} = usePostponeTask({
        task,
        postponeServerCommand,
        tasksInfo
    })

    if (!tasksInfo[task.type].postponeFor) return null;

    return (
        <>
            <STaskList padding="12px 0 11px">
                <STaskItem>
                    <Typography {...commonPropsTypography} display="flex" alignItems="center">
                        <span>Отложить {date !== null ? 'до' : 'невозможно'}&nbsp;</span>
                        <DateTime selected={date}
                                  onChange={newDate => {
                                      const _date = getNewDate(newDate, getMinMaxDateTime(date, task, currentDate));
                                      if (switchValue) postponeServerCommand(task.id, _date);
                                      setDate(_date);
                                  }}
                                  isLink={true}
                                  onFocus={() => !!setZIndex && setZIndex(12)}
                                  onBlur={() => !!setZIndex && setZIndex(11)}
                                  showTimeSelect={true}
                                  dateFormat="d-MM-yyyy HH:mm"
                                  minDate={minMaxDateTime.minDate}
                                  minTime={minMaxDateTime.minTime}
                                  maxDate={minMaxDateTime.maxDate}
                                  maxTime={minMaxDateTime.maxTime}
                                  isNullText=" "
                                  position="center"/>
                    </Typography>
                    <Switch checked={switchValue}
                            onChange={setSwitchValue}
                            labelPosition="left"
                            isDisabled={!date}
                    />
                </STaskItem>
            </STaskList>
            <Divider/>
        </>
    );
};

export default PostponeTask;
