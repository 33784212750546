import React from "react";
import SVG from "../../../../../../../common/helpers/SVG";
import {TaskCallCenter} from "../../../../../types/common";
import Box from "../../../../../../../components-ui/Layout/Box";

interface Props {
    uniqType: TaskCallCenter[]
}

const IconsSimpleTask = ({uniqType}: Props) => {
    return (
        <>
            {uniqType.some(task => task.postponedTo !== null) && (
                <Box margin="3px 0 0" title="Звонок отложен">
                    <SVG name={`call-center/waiting`} className={`icon-call-center icon-call-center--waiting`}/>
                </Box>
            )}
            {uniqType.map(task => {
                let nameSvg = '';
                switch (task.type) {
                    case "confirm_visit":
                    case "missed_visit":
                    default:
                        return null;
                }
                return (
                    <SVG name={`call-center/${nameSvg}`} className={`icon-call-center icon-call-center--${nameSvg}`}/>
                )
            })}
        </>
    )
}

export default IconsSimpleTask
