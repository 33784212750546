import React, {useMemo} from 'react';
import {IServicesGroup} from "../types";
import ListItem from "./ListItem";
import Paper from "../../../containers/UI-Collection/Elements/Surfaces/Paper";
import _ from 'lodash';

interface Props {
    servicesGroups: IServicesGroup[]
    search: string
    onClickGroup: (id: number) => void
}

const PriceListTable = ({servicesGroups, search, onClickGroup}: Props) => {


    const filterServicesGroups = useMemo(() => {
        const recursionGroups = (groups: IServicesGroup[]) => {
            return groups.map((group: IServicesGroup) => {
                if (group.groups && group.groups.length) {
                    group.groups = recursionGroups(group.groups as IServicesGroup[])
                }
                return {
                    ...group,
                    services: group.services && group.services.filter(service => service.name.toLowerCase().includes(search.toLowerCase()))
                }
            }).filter(group => group.services && group.services.length || (group.groups && group.groups.length))
        }
        return recursionGroups(_.cloneDeep(servicesGroups))
    }, [search, servicesGroups])


    return (
        <Paper margin="16px 0 0 0">
            {filterServicesGroups.map((service) => <ListItem key={service.id} onClickGroup={onClickGroup}
                                                             item={service}/>)}
        </Paper>
    );
};

export default PriceListTable;
