import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const SortDesc = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 24"} ref={ref}>
            <path d="M0,17.14H19.64V13.71H0Zm0-6.85H15.27V6.86H0ZM0,24H24V20.57H0ZM0,3.43H10.91V0H0Z"/>
        </WrapIcon>
    );
});
