import {StagedPlanItem} from "../../containers/TreatmentPlan/types";
import {PlanItemsStatuses, ServiceGroup} from "../../containers/TreatmentPlansConstructor/types";
import {IPlanItem, ObjServicesGroup} from "./typesStages";
import _ from "underscore";
import {DropTargetMonitor, XYCoord} from "react-dnd";
import {DragStage} from "./elementes/ItemStages";

interface DoctorPlanItems {
    [key: string]: IPlanItem
}


export function preparePlanItems(planItems: StagedPlanItem[], doctor: any, servicesGroups: ObjServicesGroup) {
    const newPlanItems = {} as DoctorPlanItems;
    let sort = 0;
    let additionalServices = '';

    planItems.forEach((item, index) => {

        if (item.categoryId === 246) {
            additionalServices = item.performerId + '_' + item.categoryId;
        }

        if (newPlanItems[item.performerId + '_' + item.categoryId ] === undefined) {

            newPlanItems[item.performerId + '_' + item.categoryId ] = {
                doctor,
                groupName: servicesGroups[item.categoryId ].name,
                itemKey: item.performerId + '_' + item.categoryId ,
                performerId: item.performerId,
                serviceGroupId: item.serviceGroupId,
                categoryId: item.categoryId,
                planItems: [],
                totalPrice: 0,
                sort
            };
            sort += 100;
        }

        newPlanItems[item.performerId + '_' + item.categoryId ].planItems.push(item);
        newPlanItems[item.performerId + '_' + item.categoryId ].totalPrice += item.price;

        if (index === planItems.length - 1) {
            if (additionalServices !== '') {
                sort += 100;
                newPlanItems[additionalServices].sort = sort;
            }
        }

    })

    return Object.values(newPlanItems).sort((a, b) => a.sort > b.sort ? 1 : -1);
}

const findServicesGroup = (servicesGroups: ObjServicesGroup | any, groupId: number | string, newGroupIds: number[]) => {
    newGroupIds.push(servicesGroups[groupId].id)
    if (servicesGroups[groupId].groupId !== null) {
        findServicesGroup(servicesGroups, servicesGroups[groupId].groupId, newGroupIds)
    }
}

export function prepareCellDesc(itemsStatuses: PlanItemsStatuses | null) {
    if (itemsStatuses === null) {
        return [
            {
                name: 'Врач',
                code: 'performerId',
                width: '220px',
                numberOfCharacters: 22
            },
            {
                name: 'Услуга',
                code: 'service',
                width: 'calc(100% - 670px)',
                numberOfCharacters: 10000
            },
            {
                name: 'Зуб',
                code: 'target',
                width: '150px',
                numberOfCharacters: 14
            },
            {
                name: 'Количество',
                code: 'quantity',
                width: '150px',
                numberOfCharacters: 14
            },
            {
                name: 'Цена',
                code: 'price',
                width: '150px',
                numberOfCharacters: 14
            }
        ]
    } else {
        return [
            {
                name: 'Врач',
                code: 'performerId',
                width: '220px',
                numberOfCharacters: 22
            },
            {
                name: 'Услуга',
                code: 'service',
                width: 'calc(100% - 820px)',
                numberOfCharacters: 10000
            },
            {
                name: 'Зуб',
                code: 'target',
                width: '150px',
                numberOfCharacters: 14
            },
            {
                name: 'Количество',
                code: 'quantity',
                width: '150px',
                numberOfCharacters: 14
            },
            {
                name: 'Статус',
                code: 'status',
                width: '150px',
                numberOfCharacters: 14
            },
            {
                name: 'Цена',
                code: 'price',
                width: '150px',
                numberOfCharacters: 14
            }
        ]
    }
}

export function prepareServicesGroups(servicesGroups: ServiceGroup[]) {
    let newServicesGroups = {} as ObjServicesGroup;
    forEachServicesGroups(servicesGroups, newServicesGroups);
    return newServicesGroups;
}

const forEachServicesGroups = (servicesGroups: ServiceGroup[], newServicesGroups: ObjServicesGroup) => {
    servicesGroups.forEach(group => {
        newServicesGroups[group.id] = {
            id: group.id,
            name: group.name,
            groupId: group.groupId
        };
        if (group.groups !== undefined) {
            forEachServicesGroups(group.groups, newServicesGroups);
        }
    })
};
