import React from "react";
import Tabs from "../../../../../components-ui/Navigation/Tabs/Tabs";
import {tabsNameList} from "../helper/tabsNameList";
import Box from "../../../../../components-ui/Layout/Box";
import {useWAP} from "../WaitingAndPlanned";

export default function TabsWAP() {
    const {activeTab, setActiveTab} = useWAP();
    return (
        <Box position="absolute" positionOffsets={{top: '-16px', left: '50%', transformX: '-50%'}}>
            <Tabs tabs={tabsNameList} activeTab={activeTab} onChange={setActiveTab} variant="primary" size="sm" widthTab="142px"/>
        </Box>
    )
}
