import React from 'react';
import './NavPatientCard.scss';
import PropTypes from 'prop-types'

export default class NavPatientCard extends React.Component {
    static propTypes = {
        sectionNavPatientCard: PropTypes.array.isRequired,
        activeSectionNav: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    render() {

        return (
            <div className="popup-nav popup-nav--patient-card">
                {this.props.sectionNavPatientCard.map((section) => {
                    let activeSectionNavClass = (this.props.activeSectionNav === section.code) ? 'popup-nav__item popup-nav__item--active' : 'popup-nav__item';
                    return (
                        <div className={activeSectionNavClass} key={section.code} onClick={() => this.props.onChange(section.code)}>
                            {section.name}
                        </div>
                    )
                })}
            </div>
        )
    }

}