import React from 'react';
import {ManagerTask} from "../../types/common";
import {ReducedIncomingCallsEfficiencyPayload} from "../../types/taskData";
import {Date, EfficiencyPercent} from "../Labels";
import {STaskList} from "../../../../containers/Tasks/style";

interface Props {
    task: ManagerTask<ReducedIncomingCallsEfficiencyPayload>
    renderBranches: () => React.ReactNode | null
}

const ReducedIncomingCallsEfficiency = ({task, renderBranches}: Props) => {
    return (
        <STaskList padding="19px 0 16px">
            {renderBranches()}
            <Date date={task.payload.date}/>
            <EfficiencyPercent efficiencyPercent={task.payload.efficiencyPercent}/>
        </STaskList>
    );
};

export default ReducedIncomingCallsEfficiency;
