import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const PlayArrowIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 19 24"} ref={ref}>
            <path d="M.3.06A.53.53,0,0,1,.87.11l18,11.47A.53.53,0,0,1,19,12a.54.54,0,0,1-.21.42L.87,23.88A.5.5,0,0,1,.54,24L.3,24a.55.55,0,0,1-.3-.49V.54A.53.53,0,0,1,.3.06Z"/>
        </WrapIcon>
    );
});
