import styled from "styled-components";
import {PDentalCardInfo, PStatus} from "./typesDentalCardInfo";
import Helper from "../../../../common/helpers/main";
import {INITIAL_SURVEY, UNCONFIRMED_STATUS} from "../../../../common/constants/DentalRecords";

export const DentalCardInfoWrap = styled.div.attrs({className: 'DentalCardInfoWrap'})<PDentalCardInfo>`
    display: ${props => props.variant === INITIAL_SURVEY ? 'block' : 'flex'};
    margin-bottom: 24px;
`

export const StatusList = styled.div.attrs({className: 'StatusList'})`
    display: flex;
`

const borderColorStatus = (props: PStatus) => {
    const {status, theme} = props;
    switch (status) {
        case "SICK":
            return theme?.colors.danger
        case "CURED":
        case "UNCONFIRMED":
            return theme?.colors.purple
        case "HEALTHY":
        default:
            return theme?.colors.svgGray
    }
}

const backgroundColorStatus = (props: PStatus) => {
    const {status, theme} = props;

    switch (status) {
        case "HEALTHY":
            return theme?.colors.bgButtonGray
        case "SICK":
            return `rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.2)`
        case "CURED":
            return `rgba(${Helper.Color.hexToRgb(theme?.colors.purple)}, 0.2)`
        case "UNCONFIRMED":
        default:
            return theme?.colors.white
    }
}

export const Status = styled.div.attrs({className: 'Status'})<PStatus>`
    display: flex;
    align-items: center;
    margin-right: ${props => props.variant === INITIAL_SURVEY ? '24px' : '15px'} ;
    .status-color {
        display: block;
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        border-radius: 1px;
        border: solid ${props => props.status === UNCONFIRMED_STATUS ? `2px` : '1px'} ${borderColorStatus};
        background-color: ${backgroundColorStatus};
        margin-right: 10px;
    }
    .status-text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: ${props => props.theme.colors.textDarkGray}
    }
`

export const StatusMissing = styled.div.attrs({className: 'StatusMissing'})<any>`
    display: flex;
    align-items: center;
    margin-right: ${props => props.variant === INITIAL_SURVEY ? '0' : '15px'} ;
    .missing-svg {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: ${props => props.theme.colors.danger};
        margin-right: 10px;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    .status-text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: ${props => props.theme.colors.textDarkGray}
    }
`

export const HelpListWrap = styled.div.attrs({className: 'HelpList'})<PDentalCardInfo>`
    display: ${props => props.variant === INITIAL_SURVEY ? 'block' : 'flex'};
    margin-top: ${props => props.variant === INITIAL_SURVEY ? '8px' : 0};
`

export const HelpList = styled.div.attrs({className: 'HelpList'})`
    display: flex;
`

export const HelpItem = styled.div.attrs({className: 'HelpItem'})<PDentalCardInfo>`
    display: flex;
    align-items: center;
    margin-right: ${props => props.variant === INITIAL_SURVEY ? '24px' : 0} ;

    .help-ctrl {
        display: block;
        padding: 3px;
        border-radius: 2px;
        border: solid 1px ${props => props.theme.colors.textDarkGray};
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        line-height: 10px;
        color: ${props => props.theme.colors.textDarkGray};
        margin-right: 10px;
    }

    .help-mouse {
        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
                fill: ${props => props.theme.colors.textDarkGray};
            }
        }
    }

    .help-item-text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: ${props => props.theme.colors.textDarkGray}
    }
`
