import React from 'react';
import {Icon} from "../../Icons/Icons";
import Helper from "../../../common/helpers/main";

const RowTree = (props) => {
    const {children, onClickTree, rowCode, selectedTreeItems} = props;
    let classNameTree = 'row-tree__arrow';

    Helper.forEachObj(selectedTreeItems, (value, nameRow) => {
        if (String(rowCode) === nameRow) {
            if (value) {
                classNameTree += ' row-tree__arrow--active'
            }
        }
    });

    return (
        <div className='row-tree'>
            <div className={classNameTree} onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClickTree(rowCode)
            }}>
                <Icon icon="new-arrow"/>
            </div>
            {children}
        </div>
    )
};

export default RowTree;
