import React, {useEffect, useState} from "react";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import SaveButtons from "./SaveButtons";
import {useEditableField} from "../EditableField";
import Helper from "../../../common/helpers/main";
import ServerCommand from "../../../common/server/server-command";

interface SFieldFio {
    lastName: string,
    name: string,
    secondName: string,
    validLastName: boolean,
    validName: boolean
}

export default function FieldFIO() {
    const {field, patientId, setEditField} = useEditableField();
    const {values, onChange} = field;
    const [state, setState] = useState<SFieldFio>({
        lastName: values.lastName.value,
        name: values.name.value,
        secondName: values.secondName.value,
        validLastName: false,
        validName: false
    })

    useEffect(() => {
        if (values.lastName.validation || values.name.validation) {
            setState(prevState => ({
                ...prevState,
                validLastName: !!values.lastName.validation,
                validName: !!values.name.validation
            }))
        }
    }, [values.lastName.validation, values.name.validation])

    const onChangeField = (value: string, fieldName: keyof SFieldFio, validName: keyof SFieldFio) => {
        setState(prevState => {
            let newValid = prevState[validName];
            if (value.length) newValid = false;
            return {
                ...prevState,
                [fieldName]: Helper.String.ucFirst(value),
                [validName]: newValid
            }
        })
    }

    const onClickSave = () => {
        const {name, lastName, secondName} = state;

        if (!name.length || !lastName.length) {
            setState(prevState => {
                return {
                    ...prevState,
                    validLastName: !lastName.length,
                    validName: !name.length
                }
            })
            return;
        }
        const command = new ServerCommand('patient/edit', {id: patientId, name, lastName, secondName});
        command.exec().then(() => {
            onChange({lastName, name, secondName})
            setEditField(false);
        });
    }

    return (
        <div>
            <TextField onChange={value => onChangeField(value, 'lastName', 'validLastName')}
                       value={state.lastName}
                       autoFocus={true}
                       widthInput="290px"
                       margin="0 0 10px"
                       isError={state.validLastName}
            />
            <TextField onChange={value => onChangeField(value, 'name', 'validName')}
                       value={state.name}
                       widthInput="290px"
                       margin="0 0 10px"
                       isError={state.validName}
            />
            <TextField onChange={value => setState(prevState => ({...prevState, secondName: Helper.String.ucFirst(value)}))}
                       value={state.secondName}
                       widthInput="290px"
            />
            <SaveButtons onClickSave={onClickSave}/>
        </div>
    )
}
