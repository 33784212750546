import React, {useCallback, useState} from 'react';
import Modal from "../../../containers/DialogsManager/containers/Modal/Modal";
import {ErrorModalDesc, ErrorModalTitle, ErrorModalWrap} from "../style/styledErrorModal";
import Button from "../../../components-ui/Inputs/Button/Button";

export const useErrorModal = () => {
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorText, setErrorText] = useState('');
    const closeErrorModal = () => {
        setOpenErrorModal(false)
    }
    const handleError = useCallback((errorDesc: any) => {
        setErrorText(errorDesc);
        setOpenErrorModal(true);
    },[])
    const ErrorModal = (
        <Modal isOpen={openErrorModal} isIconClose={false} closeModal={closeErrorModal} >
            <ErrorModalWrap>
                <ErrorModalTitle>Ошибка!</ErrorModalTitle>
                <ErrorModalDesc>{errorText}</ErrorModalDesc>
                <Button children="Ок" onClick={closeErrorModal} variant="default" />
            </ErrorModalWrap>
        </Modal>
    )

    return {
        ErrorModal, handleError
    }

};
