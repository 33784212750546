import {useEffect} from "react";
import {PropsUseInitialWAP} from "../types/hooks";
import ServerCommand from "../../../../../common/server/server-command";
import _ from "underscore";

export default function useInitial({props, loadingPatient, setForm}: PropsUseInitialWAP) {

    useEffect(() => {
        ServerCommand.get('waiting-list/add-form').then(response => {
            setForm(prevState => {
                return {
                    ...prevState,
                    directionsNames: _.map(response.directionsNames, (label, value) => ({label, value}))
                }
            })
        })
    }, [])

    useEffect(() => {
        if (!!props.patient) {
            if (!!props.patient.id) {
                loadingPatient(props.patient)
            } else if (!!props.patient.personalPhone) {
                setForm(prevState => {
                    if (!!props.patient) {
                        prevState.basic.personalPhone = props.patient.personalPhone;
                        if (!!props.patient.lastName) prevState.basic.lastName = props.patient.lastName
                        if (!!props.patient.name) prevState.basic.name = props.patient.name
                        if (!!props.patient.secondName) prevState.basic.secondName = props.patient.secondName
                    }
                    return {
                        ...prevState
                    }
                })
            }
        }
    }, [])
}
