import React from 'react';
import ChartPie from "../../../../../../components-ui/Charts/Pie";
import Box from "../../../../../../components-ui/Layout/Box";
import usePieData from "../hooks/usePieData";
import useDirectionsColors from "../../../../../../common/hooks/useDirectionsColors";
import {ReceiptsReport} from "../../../../types";
import {HandlersReceipts, StateReceipts} from "../types";
import useUsersColor from "../../../../../../common/hooks/useUsersColor";

interface Props {
    report: ReceiptsReport
    state: StateReceipts
    handlers: HandlersReceipts
    stateKey: string
}

const PieWidget = ({report, state, handlers, stateKey}: Props) => {
    const pieData = usePieData({report, stateKey, state});
    const directionsColors = useDirectionsColors();
    const doctorsColors = useUsersColor();
    if (!!pieData) {
        return (
            <Box display="flex" justifyContent="center" width="100%">
                <ChartPie
                    data={pieData}
                    colors={state.currentTab === 'doctors' ? doctorsColors : directionsColors}
                    activeCell={state.selectedDirection}
                    hoverIndex={state.hoverIndex}
                    isClickable={state.currentTab !== 'doctors'}
                    setActiveCell={handlers.handleSelectedDirection}
                    setActiveIndex={handlers.handleHoverIndex}

                />
            </Box>
        );
    } else {
        return null
    }

};

export default PieWidget;
