import React from 'react'
import {Route, Switch} from 'react-router-dom';
import {useSelector} from "react-redux";
import Layout from "../Layout";
import Login from "../../pages/Login";
import PatientPriceList from "../../pages/PatientPriceList";

const Routers = () => {
    const commonData = useSelector(state => state.common);
    const {serverError, isDataLoaded, isLoggedIn} = commonData;

    if (isLoggedIn && !isDataLoaded && !!serverError) {
        return (
            <div>
                {`Ошибка соединения с сервером.${serverError.message ? ` ${serverError.message}` : ''}`}
            </div>
        )
    } else {
        return (
            <Switch>
                <Route path='/patient-pricelist' exact component={PatientPriceList}/>
                {isLoggedIn && isDataLoaded && <Layout />}
                {!isLoggedIn && <Route component={Login}/>}
            </Switch>
        )
    }
}

export default Routers
