import {useSelector} from "react-redux";
import _ from "lodash";
import {useMemo} from "react";
import {IDirectionsBalance} from "../../../types";
import {IDirectionField} from "../types";

interface Props {
    directionsBalance: IDirectionsBalance
}

const useDirectionsFields = ({directionsBalance}: Props): IDirectionField[] => {
    const directions = useSelector(state => state.common.directions)
    return useMemo(() => {
        let fields: IDirectionField[] = [];

        if (directionsBalance !== null) {
            fields = _.map(directions, (item) => {
                return {
                    name: item.name,
                    code: item.code,
                    pay: directionsBalance[item.code]
                }
            }).filter((item) => directionsBalance[item.code] !== undefined)
        } else {
            fields.push({
                name: 'Сумма',
                code: 'general'
            })
        }
        return fields
    }, [directionsBalance, directions])

};

export default useDirectionsFields;