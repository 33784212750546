import React from "react";
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import Box from "../../../../../components-ui/Layout/Box";
import TextField from "../../../../../components-ui/Inputs/TextField/TextField";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import useHelpTextNextVisit from "../hooks/useHelpTextNextVisit";
import {useChoiceOrCompletion} from "../ChoiceOrCompletion";
import {COMPLETION} from "../helper/constants";

export default function NextVisit() {
    const {form, onChangeComment, parentName} = useChoiceOrCompletion();
    const {comment, doctorId, timeStart} = form.nextVisit;
    return (
        <Label labelText="Комментарий к следующему приёму"
               widthLabel="180px" marginLabel="0 20px 0 0"
               widthLabelWrap="100%"
               align="leftTop"
        >
            <Box display="flex" flexDirection="column" width="100%">
                <TextField onChange={onChangeComment}
                           value={comment}
                           isTextArea={true}
                           minHeight="72px"
                           widthInput="100%"
                           isDisabled={parentName === COMPLETION && !form.nextVisit.isExists}
                />
                <Typography children={useHelpTextNextVisit({doctorId, timeStart})}
                            variant="small" color="textDarkGray"/>
            </Box>
        </Label>
    )
}
