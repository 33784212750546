import React from 'react';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import useTableFormatter from './useTableFormatter';
import {KkmList} from '../../types';
import Table from '../../../../../components-ui/DataDisplay/Table';
import { DebouncedFunc } from 'lodash';
import AddingEditingKkm from "../modals/AddingEditingKkm";

interface Props {
    data: KkmList[]
    refreshDataFromServer: () => void
}

const KkmTable = ({data, refreshDataFromServer}: Props) => {

    const {showSlideModal} = useDialogsManager();

    const {kkmColumn} = useTableFormatter()

    return (
            <Table
                variantTable="outline"
                maxHeight="520px"
                columns={kkmColumn}
                rows={data}
                onClickRow={(row)=> showSlideModal(<AddingEditingKkm kkmId={row.id} refreshDataFromServer={refreshDataFromServer}/>)}
            />

    );
};

export default KkmTable;
