import React, {Dispatch, SetStateAction, useMemo} from 'react';
import Box from '../../../../components-ui/Layout/Box';
import Select from '../../../../components-ui/Inputs/Select/Select';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {notTrashed} from '../../../../common/helpers/general-helper';
import {User} from '../../../../types';
import DateRange from '../../../../components-ui/Inputs/DateRange';

interface Props {
    startDate: Date,
    setStartDate: Dispatch<SetStateAction<Date>>
    endDate: Date,
    setEndDate: Dispatch<SetStateAction<Date>>
    optionsBranches: OptionSelect[]
    selectedBranch: OptionSelect
    setSelectedBranch: Dispatch<SetStateAction<OptionSelect>>
    creator: OptionSelect
    setCreator: Dispatch<SetStateAction<OptionSelect>>
}

const XrayPicturesFilter = ({
                        startDate,
                        setStartDate,
                        endDate,
                        setEndDate,
                        optionsBranches,
                        selectedBranch,
                        setSelectedBranch,
                        creator,
                        setCreator,
                    }: Props) => {

    const users = useSelector(state => state.common.users)

    const currentUser = useSelector(state => state.user)

    const onChangeSelectedBranch = (selectedValue: OptionSelect) => {
        setSelectedBranch(selectedValue)
        if (creator.value !== 0) {
            if (!users[creator.value].branches.includes(selectedValue.value)) {
                setCreator({value: 0, label: 'Все сотрудники'})

            }
        }
    }

    const optionsFiltredCreator = useMemo(() => {
        return _
            .chain(users)
            .filter(notTrashed)
            .filter((user: User) => user.branches.includes(selectedBranch.value) && user.role === "assistant")
            .map((user: User) => {
                return {value: user.id, label: user.fio};
            })
            .sortBy(user => user.label)
            .unshift({value: 0, label: 'Все сотрудники'})
            .value();
    }, [selectedBranch, users]);

    return (
        <Box display='flex' gap='0 16px' margin="22px 0">

            {optionsBranches.length > 1 &&
                <Select sizeSelect="sm" options={optionsBranches} value={selectedBranch} onChange={onChangeSelectedBranch} />
            }

            <Select sizeSelect="sm" options={optionsFiltredCreator} value={creator} onChange={setCreator}/>

            {currentUser.canViewAllXrayPictures &&
                <DateRange startDate={startDate}
                           endDate={endDate}
                           setStartDate={setStartDate}
                           setEndDate={setEndDate}
                           maxDate={new Date()} />
            }

        </Box>
    );
};

export default XrayPicturesFilter;