import React from 'react'
import ServerCommand from "../../common/server/server-command";
import Helper from "../../common/helpers/main";
import Printer from "../../common/server/printer";
import './CaseHistory.scss'
import BoxBody from "../../components-ui/Wraps/old/BoxBody";
import {
    addRequiredFields,
    filteredTemplates,
    filterValueDiagnosis, getFieldsValues, getFirstFieldName,
    getSnapshot,
    resultValidateFields,
    scrollingFirstField, sortItemsFields
} from './helperCaseHistory'
import {saveResponse} from "./saveResponse";
import ToothSelected from "./elements/ToothSelected";
import {
    IFieldsValues, IItemsFields, IRelatedTemplates,
    ISelectedDiagnosis, IValidateField,
    PCaseHistory,
    PSelectedCopy,
    ResponseTemplatesList,
    SCaseHistory
} from "./typesCaseHistory";
import ContentCaseHistory from "./elements/ContentCaseHistory";
import _ from 'lodash'
import Modal from "../DialogsManager/containers/Modal/Modal";
import ParametersContent from "../Parameters/ParametersContent";
import {IParametersText, IText} from "../Parameters/parametersInterfaces";
import {defineParameters} from "../Parameters/helperParameters";
import {customStyleModalParameters} from "../DialogsManager/style/modal";
import Conditions from "../Conditions/Conditions";

class CaseHistory extends React.Component<PCaseHistory, SCaseHistory> {

    static defaultProps: any;
    firstErrorField = null;

    state = {
        itemsFields: null,
        sanitationStages: null,
        fieldsValues: {},
        fieldsRequired: {},
        selectedStages: [],
        templatesList: null,
        templatesGroupsList: null,

        visitTarget: null,
        visitText: null,
        visitTargetSelected: [],

        planTarget: null,
        planText: null,
        planTargetSelected: [],

        teethStatuses: null,
        snapshot: {},
        selectedOption: null,
        // Шаблоны
        fieldName: 'diagnosis',

        needPrint: null,
        canPrint: true,
        fieldPopupList: [],
        selectedDiagnosis: {},
        relatedTemplates: {},
        groupsIdDiagnosis: {},
        targetsHistory: {},
        ctrlPressed: false,
        isOpenModalParameters: false,
        defineText: [],
        loaderPrint: false,
        isOpenModalConditions: false,
        conditions: [],
        diagnosisRelationParameters: [],
        onSubmitConditions: () => {}
    } as SCaseHistory;

    constructor(props: PCaseHistory) {
        super(props);
        this.firstErrorField = React.createRef() as any;
        this.handlerLoader = this.handlerLoader.bind(this)
    }

    componentDidMount() {
        this.setState(saveResponse(this.props.data));
        document.addEventListener('keyup', this.handleKeyUp);
        document.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('unhandledrejection', this.handlerLoader)
    }

    componentWillUnmount(): void {
        document.removeEventListener('keyup', this.handleKeyUp);
        document.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('unhandledrejection', this.handlerLoader)
    }

    componentDidUpdate(prevProps: Readonly<PCaseHistory>, prevState: Readonly<SCaseHistory>, snapshot?: any): void {
        if (!_.isEqual(prevState, this.state)) {
            const {fieldsProps} = this.propsContent();
            const {toothFieldsValues} = getFieldsValues(fieldsProps);
            if (Object.keys(toothFieldsValues).length > 0) {
                let newFieldName = this.state.fieldName;
                const stateItemsFields = this.state.itemsFields as any;
                const itemFields = sortItemsFields(Object.values(stateItemsFields));
                _.forEach(toothFieldsValues, (val: any, key: string) => {
                    if (newFieldName === key) {
                        if (val.disabled) {
                            const findIndex = itemFields.findIndex((item: any) => item.name === key) + 1;
                            if (itemFields[findIndex] !== undefined) {
                                newFieldName = itemFields[findIndex].name
                            } else {
                                newFieldName = null;
                            }
                        }
                    }
                });
                if (this.state.fieldName !== newFieldName) {
                    this.setState({fieldName: newFieldName});
                }
            }
        }
    }

    render() {
        const {itemsFields, visitTarget} = this.state;
        const {renderFooter} = this.props;
        if (itemsFields === null || visitTarget === null) return null;

        return (
            <>
                <BoxBody modification="case-history">
                    <div className="card case-history-wrap-content">
                        <ToothSelected {...this.propsToothSelected()}/>
                        <ContentCaseHistory {...this.propsContent()}/>
                    </div>
                </BoxBody>
                {renderFooter(this.footerOptions())}
                <Modal {...this.propsOptionsModalParameters()}/>
                <Modal {...this.propsConditionsModal()}/>
            </>
        )
    }

    /** Параметры для работы с полями */
    fieldsParams = () => {
        const {state} = this as any;
        const typeToothSelected = Helper.clone(state.visitTargetSelected).length > 0 ? 'visit' : 'plan';
        const targetSelected = state[typeToothSelected + 'TargetSelected'].map((selected: string) => typeToothSelected + '_' + selected);
        const fieldsValues = Helper.clone(state.fieldsValues);
        const itemsFields = Helper.clone(state.itemsFields);
        const fieldPopupList = Helper.clone(state.fieldPopupList);
        const relatedTemplates = Helper.clone(state.relatedTemplates);
        const selectedDiagnosis = Helper.clone(state.selectedDiagnosis);
        targetSelected.forEach((target: string) => {
            if (fieldsValues[target] === undefined) {
                fieldsValues[target] = {}
            }
        });
        return {
            fieldsValues, itemsFields, targetSelected, fieldPopupList, relatedTemplates, selectedDiagnosis
        }
    };

    handleToothSelect = (toothNumber: string, type: string) => {
        const newState = this.prepareStateToothSelect(toothNumber, type) as any;
        this.setState(newState);
    };

    /** переключение между зубами на компоненте ToothSelected*/
    prepareStateToothSelect = (toothNumber: string, type: string, isValidate: boolean = false) => {
        const {state} = this as any | SCaseHistory;
        const {ctrlPressed} = state;
        let itemsFields = Helper.clone(state.itemsFields);
        const fieldsRequired = Helper.clone(state.fieldsRequired);
        const typeReverse = type === 'visit' ? 'plan' : 'visit';
        let selectedOption = Helper.clone(state.selectedOption);
        if (state[type + 'TargetSelected'].length === 0) selectedOption = null;

        let typeTargetSelect = Helper.clone(state[type + 'TargetSelected']);

        if (isValidate) {
            typeTargetSelect = [toothNumber];
        } else {
            if (this.props.editMode) {
                typeTargetSelect = [toothNumber];
            } else {
                if (ctrlPressed) {
                    if (typeTargetSelect.indexOf(toothNumber) === -1) {
                        typeTargetSelect.push(toothNumber);
                    } else {
                        if (typeTargetSelect.length > 1) {
                            typeTargetSelect = typeTargetSelect.filter((select: string) => select !== toothNumber);
                        }
                    }
                } else {
                    typeTargetSelect = [toothNumber];
                }

            }
        }

        return {
            [type + 'TargetSelected']: typeTargetSelect,
            [typeReverse + 'TargetSelected']: [],
            itemsFields: addRequiredFields(itemsFields, fieldsRequired, type, toothNumber),
            selectedOption,
            fieldName: getFirstFieldName(itemsFields)
        }
    };

    resetToothSelected = (targetSelected: string[], typeSelected: string) => {
        const newState = {
            [typeSelected + 'TargetSelected']: []
        } as any;
        this.setState(newState)
    };

    /** Клик по кнопки копирование (ее может не быть в зависимости от условий)*/
    onClickButtonCopy = () => {
        const {fieldsValues, itemsFields, targetSelected, selectedDiagnosis, relatedTemplates} = this.fieldsParams();
        const {fields, diagnosis, value} = this.state.selectedOption;
        const selectedRelatedTemplates = relatedTemplates[value]
        targetSelected.forEach((target: string) => {
            fieldsValues[target] = fields;
            if (diagnosis !== undefined) selectedDiagnosis[target] = diagnosis;
            if (selectedRelatedTemplates !== undefined) relatedTemplates[target] = selectedRelatedTemplates;
            Object.keys(fieldsValues[target]).forEach(key => itemsFields[key].isError = false);
        });
        this.setState({fieldsValues, itemsFields, selectedDiagnosis, relatedTemplates})
    };

    /** Изменение в селекте копирования (его может не быть в зависимости от условий)*/
    onChangeSelectedCopy = (selectedOption: PSelectedCopy) => {
        this.setState({selectedOption});
    };

    /** клик по любому полю, необходимо для установки фокуса*/
    onClickField = (fieldName: string) => this.setState({fieldName});

    /** Изменения текста в полях ввода*/
    handleChangeField = (e: any, fieldName: string, diagnosisList = [] as ISelectedDiagnosis[]) => {
        let {itemsFields, fieldsValues, targetSelected, fieldPopupList} = this.fieldsParams();
        const {selectedDiagnosis} = this.state;
        let value = e.target.value;

        if (fieldName === 'diagnosis') {
            fieldPopupList = value.length ? diagnosisList.filter((item) => filterValueDiagnosis(item, value, targetSelected, selectedDiagnosis)) : [];
        }

        targetSelected.forEach((target: string) => fieldsValues[target][fieldName] = value);

        if (value.length) itemsFields[fieldName].isError = false;

        this.setState({fieldsValues, itemsFields, fieldPopupList});
    };

    loadClickDiagnosis = (item: ISelectedDiagnosis) => {
        const {fieldsValues, targetSelected, itemsFields, relatedTemplates, selectedDiagnosis} = this.fieldsParams();
        const fieldName = item.field;
        console.log(item)
        targetSelected.forEach((target: string) => {
            if (selectedDiagnosis[target] === undefined) selectedDiagnosis[target] = [];
            selectedDiagnosis[target].push(item);
            fieldsValues[target][fieldName] = '';
            this.addValueFromRelatedTemplate(item, fieldsValues, target, itemsFields, relatedTemplates);
        });
        this.setState({selectedDiagnosis, fieldsValues, fieldPopupList: [], relatedTemplates});
    }

    handleTemplateDiagnosis = (diagnosis: ISelectedDiagnosis, handler: (item: ISelectedDiagnosis) => void) => {
        if (diagnosis.relatedTemplates.some(related => related.conditions !== null)) {
            this.setState({
                isOpenModalConditions: true,
                conditions: diagnosis.relatedTemplates.filter(related => related.conditions !== null),
                fieldPopupList: [],
                onSubmitConditions: (suitableRelations) => {
                    const newDiagnosis = _.cloneDeep(diagnosis)
                    newDiagnosis.relatedTemplates = newDiagnosis.relatedTemplates.filter(related => related.conditions === null)
                    newDiagnosis.relatedTemplates.push(...suitableRelations);

                    this.setState({isOpenModalConditions: false})
                    handler(newDiagnosis)
                }
            })
            return false;
        } else {
            handler(diagnosis)
        }
    }

    /** клик по элементу выподающего списка диагноза*/
    onClickDiagnosis = (item: ISelectedDiagnosis) => {
        this.handleTemplateDiagnosis(item, this.loadClickDiagnosis)
    };

    /** клик по кресту item в поле диагноза*/
    deleteDiagnosis = (item: ISelectedDiagnosis) => {
        const {targetSelected, selectedDiagnosis, relatedTemplates} = this.fieldsParams();

        targetSelected.forEach((target: string) => {
            let allRelated = [] as any;
            selectedDiagnosis[target] = selectedDiagnosis[target].filter((diagnosis: ISelectedDiagnosis) => item.id !== diagnosis.id);
            selectedDiagnosis[target].forEach((diagnosis: ISelectedDiagnosis) => {
                if (diagnosis.relatedTemplates.length) allRelated = allRelated.concat(diagnosis.relatedTemplates);
            });
            if (!allRelated.length) {
                relatedTemplates[target] = {};
            } else {
                Helper.forEachObj(relatedTemplates[target], (related: ISelectedDiagnosis[], fieldName: string) => {
                    if (related.length) {
                        relatedTemplates[target][fieldName] = related.filter(template => allRelated.indexOf(template.id) !== -1);
                    }
                });
            }
        });

        this.setState({selectedDiagnosis, relatedTemplates});
    };

    /** удаляет выпадающий список если тот существует */
    resetFieldPopupList = () => this.setState({fieldPopupList: []});

    onClickParameters = (value: any[], fieldName: any) => {
        const defineText = {
            field: fieldName,
            text: value
        } as any;
        this.setState({
            isOpenModalParameters: true,
            defineText,
            fieldName
        })
    };

    /** Добавляет из шаблона в поле*/
    addFromTemplate = (element: ISelectedDiagnosis, isDefine: boolean = true) => {
        const {fieldName} = this.state;
        const {fieldsValues, selectedDiagnosis, relatedTemplates, targetSelected, itemsFields} = this.fieldsParams();
        const newState = {fieldsValues, itemsFields, selectedDiagnosis, relatedTemplates} as any;
        const field = element.field;
        const text = element.text;
        targetSelected.forEach((target: string) => {
            if (fieldName === 'diagnosis') {
                this.handleTemplateDiagnosis(element, (newElement) => {
                    if (newState.selectedDiagnosis[target] === undefined) newState.selectedDiagnosis[target] = [];
                    if (newState.selectedDiagnosis[target].some((item: ISelectedDiagnosis) => item.id === newElement.id)) return false;
                    newState.selectedDiagnosis[target].push(newElement);
                    newState.itemsFields[field].isError = false;
                    this.addValueFromRelatedTemplate(newElement, newState.fieldsValues, target, newState.itemsFields, relatedTemplates);
                })
            } else {
                if (isDefine) {
                    const defineText = _.cloneDeep(element) as any;
                    defineText.text = defineParameters(element.text);
                    if (defineText.text.length > 1) {
                        newState.isOpenModalParameters = true;
                        newState.defineText = defineText;
                    } else {
                        this.addValueFromTemplate(newState.fieldsValues, target, field, newState.itemsFields, text)
                    }
                } else {
                    this.addValueFromTemplate(newState.fieldsValues, target, field, newState.itemsFields, text);
                    newState.isOpenModalParameters = false;
                }

            }
        });

        this.setState(newState);
    };

    closeModalParameters = () => this.setState({isOpenModalParameters: false});
    closeModalConditions = () => this.setState({isOpenModalConditions: false});

    addValueFromTemplate = (fieldsValues: IFieldsValues, targetSelected: string, fieldName: string, itemsFields: IItemsFields, text: string | IParametersText, isRelated: boolean = false) => {
        const targetValues = fieldsValues[targetSelected] as any;

        if (targetValues !== undefined) {

            if (targetValues[fieldName] !== undefined) {

                if (targetValues[fieldName] === '') {
                    targetValues[fieldName] = text;
                } else {
                    if (!isRelated) {

                        if (typeof targetValues[fieldName] === 'string') {
                            if (!targetValues[fieldName].endsWith('.')) {
                                targetValues[fieldName] += '.'
                            }
                            if (typeof text === 'string') {
                                if (!targetValues[fieldName].includes(text)) {
                                    // удаляем лишние пробелы
                                    targetValues[fieldName] = targetValues[fieldName].trim().replace(/ {2,}/, ' ');

                                    targetValues[fieldName] += ' ' + text;
                                }
                            } else {
                                targetValues[fieldName] = {
                                    prev: targetValues[fieldName],
                                    next: text
                                }

                            }
                        } else {
                            if (typeof text === 'string') targetValues[fieldName] = text;
                        }
                    }

                }
            } else {
                targetValues[fieldName] = text;
            }

        } else {
            fieldsValues[targetSelected] = {};
            fieldsValues[targetSelected][fieldName] = text;
        }

        itemsFields[fieldName].isError = false;
    };

    addValueFromRelatedTemplate = (item: ISelectedDiagnosis, fieldsValues: IFieldsValues, targetSelected: string, itemsFields: IItemsFields, relatedTemplates: IRelatedTemplates) => {
        const {templatesList} = this.state as SCaseHistory | any;
        if (item.relatedTemplates.length) {
            if (relatedTemplates[targetSelected] === undefined) relatedTemplates[targetSelected] = {};
            Helper.forEachObj(filteredTemplates(templatesList, item), (element: ResponseTemplatesList[] | any, elementName: string) => {
                const defineText = _.cloneDeep(element[0]);
                defineText.text = defineParameters(element[0].text);
                if (defineText.text.length > 1) {
                    if (element.length === 1) this.addValueFromTemplate(fieldsValues, targetSelected, elementName, itemsFields, defineText.text, true);
                } else {
                    if (element.length === 1) this.addValueFromTemplate(fieldsValues, targetSelected, elementName, itemsFields, element[0].text, true);
                }

                if (relatedTemplates[targetSelected][elementName] === undefined) relatedTemplates[targetSelected][elementName] = [];
                relatedTemplates[targetSelected][elementName] = relatedTemplates[targetSelected][elementName].concat(element);
            });
        }
    };

    onClickCheckValidation = (validateFields: IValidateField[]) => {
        const type = validateFields[0].typeAndNumber.split('_')[0];
        let toothNumber = validateFields[0].typeAndNumber.split('_')[1] as string | number | any;
        const fieldName = validateFields[0].fieldName;
        const newState = this.prepareStateToothSelect(toothNumber, type, true) as any;
        newState.itemsFields[fieldName].isError = true;
        this.setState(newState);
        scrollingFirstField(document.getElementById(fieldName));
    };

    /* Клик по кнопке распечатать*/
    handlePrintButtonClick = () => {

        const validateFields = this.validateFields();

        if (validateFields.length) {
            this.onClickCheckValidation(validateFields);
            return false
        }

        this.setState({loaderPrint: true});

        const command = new ServerCommand('case-history/save', this.saveData());

        command.exec().then((response) => {
            Printer.print(response.documents).then(() => {
                this.setState({
                    snapshot: getSnapshot(this.state),
                    loaderPrint: false
                })
            });
        });
    };

    isDataChanged = () => !Helper.isEqual(this.state.snapshot, getSnapshot(this.state));

    validateFields = () => {
        const {fieldsValues, selectedDiagnosis} = this.fieldsParams();
        return resultValidateFields(Helper.clone(this.state.fieldsRequired), fieldsValues, selectedDiagnosis);
    };

    canceledValueFields = () => {
        const {snapshot} = this.state as any;
        if (snapshot.fieldsValues !== undefined) this.setState({fieldsValues: snapshot.fieldsValues});
    };

    saveData = () => {
        let data = {
            visitId: this.props.visitId,
            generateDocuments: true
        } as any;

        const {fieldsValues, selectedDiagnosis} = this.state;

        Helper.forEachObj(fieldsValues, (toothFieldsValues: any, targetName: any) => {
            const target = targetName.split('_');
            if (data[target[0] + 'CaseHistoryItems'] === undefined) data[target[0] + 'CaseHistoryItems'] = [];
            data[target[0] + 'CaseHistoryItems'].push(Object.assign({target: target[1]}, toothFieldsValues))
        });

        Helper.forEachObj(selectedDiagnosis, (diagnosisFieldsValues: any, targetName: any) => {
            if (diagnosisFieldsValues.length) {
                const target = targetName.split('_');
                const dataTarget = data[target[0] + 'CaseHistoryItems'];
                if (dataTarget === undefined) data[target[0] + 'CaseHistoryItems'] = [];
                const dataIndex = dataTarget.findIndex((dataTarget: any) => dataTarget.target === target[1]);
                const newDiagnosis = [];
                const diagnosesIds = [] as any;
                diagnosisFieldsValues.forEach((item: any) => {
                    diagnosesIds.push(item.id);
                });
                if (dataTarget[dataIndex].diagnosis !== undefined && dataTarget[dataIndex].diagnosis !== '') {
                    newDiagnosis.push(dataTarget[dataIndex].diagnosis);
                }
                if (newDiagnosis.length) data[target[0] + 'CaseHistoryItems'][dataIndex].diagnosis = newDiagnosis.join(' ');

                data[target[0] + 'CaseHistoryItems'][dataIndex].diagnosesIds = diagnosesIds;
            }

        });

        return data
    };

    /* Props and Options для компонентов*/
    propsContent = () => {
        const {
            itemsFields, visitTarget, fieldsValues, selectedOption, fieldName, visitTargetSelected, planTarget,
            planTargetSelected, fieldPopupList, selectedDiagnosis, templatesList, groupsIdDiagnosis, targetsHistory,
            templatesGroupsList
        } = this.state;
        const {editMode} = this.props;

        const fieldsProps = {
            selectedOption, editMode, fieldPopupList, fieldName, itemsFields, templatesList,
            groupsIdDiagnosis, targetsHistory, fieldsValues, visitTarget, planTarget, visitTargetSelected,
            planTargetSelected, selectedDiagnosis,
            validateFields: this.validateFields,
            onClickField: this.onClickField,
            onClickDiagnosis: this.onClickDiagnosis,
            deleteDiagnosis: this.deleteDiagnosis,
            handleChange: this.handleChangeField,
            resetFieldPopupList: this.resetFieldPopupList,
            onChangeSelectedCopy: this.onChangeSelectedCopy,
            onClickButtonCopy: this.onClickButtonCopy,
            onClickParameters: this.onClickParameters
        };
        const templatesProps = {
            editMode, templatesList, templatesGroupsList, fieldName,
            fieldsParams: this.fieldsParams,
            addFromTemplate: this.addFromTemplate
        };

        return {fieldsProps, templatesProps}
    };
    /* Props and Options для компонентов*/
    propsToothSelected = () => {
        const {
            visitTarget, fieldsRequired, fieldsValues, visitTargetSelected, planTarget, planTargetSelected,
            teethStatuses, selectedDiagnosis
        } = this.state;
        return {
            fieldsRequired, teethStatuses, fieldsValues, visitTarget, planTarget, visitTargetSelected,
            planTargetSelected, selectedDiagnosis,
            onSelect: this.handleToothSelect,
            handleResetClick: this.resetToothSelected
        }
    };
    /* Props and Options для компонентов*/
    footerOptions = () => {
        const {needPrint, canPrint, loaderPrint} = this.state;
        return {
            handlePrintButtonClick: this.handlePrintButtonClick,
            isDataChanged: this.isDataChanged,
            canceledValueFields: this.canceledValueFields,
            validateFields: this.validateFields(),
            needPrint,
            onClickCheckValidation: this.onClickCheckValidation,
            saveData: this.saveData,
            canPrint,
            loaderPrint
        }
    };
    /* Props and Options для компонентов*/
    propsOptionsModalParameters = () => {
        const {isOpenModalParameters, fieldName, itemsFields, defineText} = this.state as any;
        return {
            isOpen: isOpenModalParameters,
            closeModal: this.closeModalParameters,
            isIconClose: true,
            children: <ParametersContent field={itemsFields[fieldName]}
                                         text={defineText}
                                         saveText={this.addFromTemplate}
                                         closeModal={this.closeModalParameters}
            />,
            customStyles: customStyleModalParameters()
        }
    };

    propsConditionsModal = () => {
        const {isOpenModalConditions, conditions, diagnosisRelationParameters, onSubmitConditions} = this.state as any;

        return {
            isOpen: isOpenModalConditions,
            closeModal: this.closeModalConditions,
            isIconClose: false,
            children: <Conditions conditions={conditions}
                                  diagnosisRelationParameters={diagnosisRelationParameters}
                                  onSubmitConditions={onSubmitConditions}
            />,
            customStyles: customStyleModalParameters()
        }
    }

    handleKeyUp = (e: any) => {
        if (e.which === 17) this.setState({ctrlPressed: false});
    };

    handleKeyDown = (e: any) => {
        if (!this.state.ctrlPressed) {
            if (e.which === 17) this.setState({ctrlPressed: true});
        }
    };

    handlerLoader(event: any) {
        const reason = event.reason;
        if (reason.type === 'server_request_error') {
            this.setState({loaderPrint: false})
        }
    }

}

CaseHistory.defaultProps = {
    renderFooter: () => {
    }
};

export default CaseHistory;
