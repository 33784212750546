import {useSelector} from "react-redux";
import {useMemo} from "react";
import {StateManagerTasks} from "../types/components";

interface Props {
    state: StateManagerTasks
}

const useBranchesManagerTasks = ({state}: Props) => {
    const branches = useSelector(state1 => state1.user.branches)

    return useMemo(() => {
        const currentBranches = state.branches && state.branches.length ? state.branches : branches
        return state.tasks.filter(task => task.branches.some(branchId => currentBranches.includes(branchId)))
    }, [JSON.stringify(branches), JSON.stringify(state.branches), JSON.stringify(state.tasks)])
}

export default useBranchesManagerTasks
