import {useCallback, useEffect, useState} from 'react';
import {GetCashCloseResult, IStateCashClose} from "../types";
import useLoader from "../../../../components/Errors";
import useLoadData from "./useLoadData";
import useSubmit from "./useSubmit";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";
import Kkm from "../../../../common/server/kkm";
import {useSelector} from "react-redux";
import ServerCommand from "../../../../common/server/server-command";
import {KkmSetting} from "../../../../types";
import {KkmList} from "../../../../pages/Settings/Kkm/types";
import Helper from "../../../../common/helpers/main";

interface Props {
    fixedPatientId?: number
    loadingFromServer?: () => void
    discountTypeOptions: OptionSelect[]
    paymentMethodOptions: OptionSelect[]
    optionsBranches: OptionSelect[]
}

const useCashClose = ({fixedPatientId, loadingFromServer, discountTypeOptions, paymentMethodOptions, optionsBranches}: Props) => {

    const users = useSelector(state => state.common.users)
    const currentUser = useSelector(state => state.user)

    const [kkmError, setKkmError] = useState('')

    const [kkmOptions, setKkmOptions] = useState<OptionSelect[]>([])

    const [kkmSetting, setKkmSetting] = useState<KkmSetting>({
        user: null,
        url: null,
        password: null,
        numberDevice: null,
        cashierName: null,
        cashierINN: null
    })

    const [state, setState] = useState<IStateCashClose>({
        selectedPaymentMethod: paymentMethodOptions[0],
        selectedKkm: kkmOptions.length > 1 ? undefined : kkmOptions[0],
        kkmError: '',
        branch: optionsBranches.length > 1 ? undefined : optionsBranches[0],
        methodClose: null,
        cashboxSum: {},
        values: {},
        kkmList: [],
        notEncashment: false
    })

    useEffect(() => {
        const getCashier = (kkm: KkmList) => {

            if (kkm.cashierId) {
                return {
                    cashierName: users[kkm.cashierId].fio,
                    cashierINN: users[kkm.cashierId].inn
                }
            } else {
                return {
                    cashierName: currentUser.fio,
                    cashierINN: currentUser.inn
                }
            }

        }

        const kkm = state.kkmList.find(kkm => kkm.id == state.selectedKkm?.value)

        if (!!kkm) {
            setKkmSetting({
                user: kkm.login,
                url: kkm.url,
                password: kkm.password,
                numberDevice: kkm.numberDevice,
                ...getCashier(kkm)
            })
        } else {
            setKkmSetting({
                user: null,
                url: null,
                password: null,
                numberDevice: null,
                cashierName: null,
                cashierINN: null
            })
        }


    }, [state.selectedKkm])

    useEffect(() => {
        if (state.selectedKkm?.value) {
            if (!kkmSetting.cashierINN) {
                setKkmError('Не указан ИНН кассира')
            }

            const kkm = new Kkm(kkmSetting);
            kkm.getDataKKT().then((response) => {

                if (response?.SessionState == 1) {
                    setKkmError('Смена не открыта')
                } else
                if (response?.SessionState == 3) {
                    setState(prevState => ({
                        ...prevState,
                        notEncashment: true
                    }))
                    setKkmError('Смена не открыта или смена превысила 24 часа')
                }
            })
        } else {
            setKkmError('')
            setState(prevState => ({
                ...prevState,
                notEncashment: false
            }))
        }
    }, [kkmSetting])

    const [loader, setLoader] = useLoader();

    useLoadData({setState, setKkmOptions, branchId: state.branch?.value})

    const {handleXReport, handleOpenCash, handleCloseCash} = useSubmit({
        loadingFromServer,
        setLoader,
        setKkmError,
        state,
        kkmSetting
    })

    const validate = () => {
        let valid = state.branch !== undefined

        if (!state.notEncashment) {
            valid = valid && !kkmError

            Helper.forEachObj(state.cashboxSum, (item: number, key: string) => {
                valid = valid && (state.values[key] != undefined)
            })

        }

        if (kkmOptions.length > 0 && !state.selectedKkm) {
            valid = false
        }

        return valid
    }

    return {validate, kkmError, state, setState, handleXReport, handleOpenCash, handleCloseCash, loader, kkmOptions}

};

export default useCashClose;
