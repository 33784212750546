import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {GetTimesheetListResult} from "../../../types";
import {IColumn} from "../../../../../components-ui/DataDisplay/Table";
import {ITimesheetRow} from "../types";
import {useSelector} from "react-redux";
import Box from "../../../../../components-ui/Layout/Box";
import Icon from "../../../../../components-ui/DataDisplay/Icon";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import useSortRecords from "./useSortRecords";
import Tooltip from "../../../../../components-ui/DataDisplay/Tooltip/Tooltip";
import ButtonForOptions from '../../../../../components-ui/Inputs/ButtonForOptions/ButtonForOptions';
import useOptions from './useOptions';

interface Props {
    data: GetTimesheetListResult
    openOptions: boolean
    setOpenOptions: Dispatch<SetStateAction<boolean>>
    selectedBranchId: number
}

const useTableFormatter = ({data, openOptions, setOpenOptions, selectedBranchId}: Props) => {

    const buttonOptions = useOptions({data, selectedBranchId})

    const users = useSelector(state => state.common.users)

    const filteredRecords = useSortRecords({records: data.records})

    const timesheetColumns: IColumn<ITimesheetRow>[] = useMemo(() => [
        {
            field: 'fio',
            headerName: 'Сотрудник',
            width: '40.8vw',
            valueFormatter: ({row}) =>
                <Box display="flex" alignItems="center">
                    <Icon
                        boxSizing="content-box"
                        padding="6px 7px 4px"
                        width="8px"
                        height="10px"
                        name="employee"
                        color={row.endTime ? 'borderGray' : 'inc'}
                        data-for={row.fio}
                        data-tip={row.endTime ? 'Сотрудник ушёл' : 'Сотрудник находится в клинике'}
                    />
                    <Tooltip id={row.fio} effect="solid" place={'right'}/>
                    <Typography variant="small" children={row.fio}/>
                </Box>
        },
        {
            field: 'startTime',
            headerName: 'Время прихода',
            valueFormatter: ({row}) =>
                !!row.startTimeComment
                    ?
                    <Box display="flex" alignItems="center">
                    <Typography variant="small">
                        {row.startTime}
                    </Typography>
                    <Icon
                        boxSizing="content-box"
                        padding="6px 7px 4px"
                        width="12px"
                        height="10px"
                        name="comment"
                        color="borderGray"
                        data-for={row.startTime}
                        data-tip={row.startTimeComment}
                    />
                    <Tooltip id={row.startTime} effect="solid"/>
                </Box>
                    :
                    <Typography variant="small">
                        {row.startTime}
                    </Typography>
        },
        {
            field: 'endTime',
            headerName: 'Время ухода',
            valueFormatter: ({row}) =>
                !!row.endTimeComment && row.endTime
                    ?
                    <Box display="flex" alignItems="center">
                        <Typography variant="small">
                            {row.endTime}
                        </Typography>
                        <Icon
                            boxSizing="content-box"
                            padding="6px 7px 4px"
                            width="12px"
                            height="10px"
                            name="comment"
                            color="borderGray"
                            data-for={row.endTime}
                            data-tip={row.endTimeComment}
                        />
                        <Tooltip id={row.endTime} effect="solid"/>
                    </Box>
                    :
                    <Typography variant="small">
                        {row.endTime ? row.endTime : '-'}
                    </Typography>
        },
        {
            field: 'button',
            width: '50px',
            valueFormatter: ({row}) =>
             <>
                 <ButtonForOptions
                     outsideOpenOptions={openOptions}
                     setOutsideOpenOptions={setOpenOptions}
                     options={buttonOptions(row)}
                     position={row.index <= row.length/2 ?
                         'left-bottom' :
                         'left-top'
                 }
                 />
             </>
        }
    ], [openOptions])

    const [timesheetRows, setTimesheetRows] = useState<ITimesheetRow[]>([])

    useEffect(() => {
        setTimesheetRows(
            filteredRecords.map((record, idx) => {
                return ({
                    id: record.id,
                    employeeId: record.employeeId,
                    fio: users[record.employeeId].fio,
                    startTime: record.startTime,
                    startTimeComment: record.startTimeComment,
                    endTime: record.endTime,
                    endTimeComment: record.endTimeComment,
                    index: idx,
                    length: filteredRecords.length
                })
            })
        )
    }, [data.records, filteredRecords, users])

    return {timesheetColumns, timesheetRows}
};

export default useTableFormatter;