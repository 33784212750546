import {useMemo} from "react";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import _ from "underscore";
import {useSelector} from "react-redux";

export default function useOptionsRelation(personalGender: null | string) {
    const relationTypesTitles = useSelector(state => state.patient.relationTypesTitles)
    return useMemo(() => {
        let newOptionsRelations = [] as OptionSelect[]
        if (relationTypesTitles !== null) {
            let newPatientRelationTypesTitles = relationTypesTitles.general;
            if (personalGender !== null && personalGender !== '') {
                newPatientRelationTypesTitles = Object.assign({}, relationTypesTitles.general, relationTypesTitles[personalGender])
            }
            newOptionsRelations = _.map(newPatientRelationTypesTitles, (val, key) => ({
                value: key,
                label: val
            })) as any;
        }
        return newOptionsRelations
    }, [personalGender]) as OptionSelect[]
}
