import React from 'react';
import {useNewVisitForm} from "../NewVisitForm";
import Paper from "../../../containers/UI-Collection/Elements/Surfaces/Paper";
import {InfoBox, InfoBoxWrap} from "../../../components-ui/styledComponent/common/wraps";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";

export default function CommentNewVisit() {
    const {form, setForm} = useNewVisitForm();
    return (
        <Paper minWidth="100%">
            <InfoBox>
                <Typography variant="min-title" padding="25px 20px 11px">Комментарий к приему</Typography>
                <InfoBoxWrap>
                    <TextField onChange={value => setForm(prevState => ({...prevState, comment: value}))}
                               widthInput='100%'
                               value={form.comment}
                               autoFocus={false}
                               isTextArea={true} />
                </InfoBoxWrap>
            </InfoBox>
        </Paper>
    )
}
