import {Dispatch, SetStateAction, useEffect} from "react";
import {PropsError} from "../../../../../../components/Errors";

interface Props {
    setSubTable: Dispatch<SetStateAction<any>>
}

const useError = ({setSubTable}: Props) => {
    useEffect(() => {
        const handlerLoader = (event: any) => {
            const reason = event.reason as PropsError;
            if (reason.type === 'server_request_error') setSubTable(null)
        }
        window.addEventListener('unhandledrejection', handlerLoader)
        return () => window.removeEventListener('unhandledrejection', handlerLoader)
    }, [])
}

export default useError
