import {useMemo} from "react";
import {PropsSwirl} from "../../../../components/Swirl";
import _ from "lodash";
import {CallsEfficiencyReport} from "../../../../types";
import {StateCallsEfficiency} from "../types";
import {OptionSelect} from "../../../../../../components-ui/Inputs/Select/typesSelect";

interface Props {
    report: CallsEfficiencyReport
    state: StateCallsEfficiency
    optionsSelected: OptionSelect[]
}

const useTextSwirl = ({report, state, optionsSelected}: Props) => {
    return useMemo(() => {
        const result = {
            textUp: '',
            textDown: '',
            percentage: ''
        } as PropsSwirl;
        if (report.failedCalls.length && optionsSelected.some(option => option.value === state.currentSelected.value) && !!report.count[state.currentTab]) {
            if (state.currentSelected.value === 0) {
                result.textUp = report.count[state.currentTab].general
                result.textDown = _.sumBy(_.filter(report.count[state.currentTab], (value, key) => key !== 'general'), 'successful')
            } else {
                result.textUp = report.count[state.currentTab][state.currentSelected.value].general
                result.textDown = report.count[state.currentTab][state.currentSelected.value].successful
            }
            result.percentage = +result.textDown * 100 / +result.textUp
            result.percentage = isNaN(result.percentage) ? 0 : result.percentage
        }
        return result

    }, [JSON.stringify(report.count), state.currentSelected.value, state.currentTab])
}

export default useTextSwirl
