import styled from "styled-components";

export const WrapRecommendedServices = styled.div.attrs({className: 'WrapRecommendedServices'})`
    padding: 32px 30px 36px;
`

export const WrapServices = styled.div.attrs({className: 'WrapServices'})`
    border-radius: 4px;
    border: solid 1px #e2e6ed;
`
