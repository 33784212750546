import React from "react";
import {WrapContent} from "../../../styledUI";
import Avatar from "../../../../../components-ui/DataDisplay/Avatar";

export default function AvatarPage() {
    return (
        <>
            <h1>Аватар</h1>
            <WrapContent>
                <div>
                    <Avatar img="https://www.shareicon.net/data/256x256/2016/08/18/813848_people_512x512.png"
                            status="online" size="130px"/>
                    <pre>
                        {
                            '<Avatar img="https://www.shareicon.net/data/256x256/2016/08/18/813848_people_512x512.png" status="online" size="130px" />'
                        }
                    </pre>
                </div>
                <div>
                    <Avatar img="/images/user.jpg" status="offline"/>
                    <pre>
                        {
                            '<Avatar img="/images/user.jpg" status="offline" />'
                        }
                    </pre>
                </div>
                <div>
                    <Avatar bgColorTheme="primary" text="П" status="online"/>
                    <pre>
                        {
                            '<Avatar bgColorTheme="primary" text="П" status="online"/>'
                        }
                    </pre>
                </div>
                <div>
                    <Avatar bgColorTheme="purple" size="20px" text="Н"/>
                    <pre>
                        {
                            '<Avatar bgColorTheme="purple" size="20px" text="П"/>'
                        }
                    </pre>
                </div>
                <div>
                    <Avatar bgColor="#44da44" fontSizeText="18px" text="Д"/>
                    <pre>
                        {
                            '<Avatar bgColor="#44da44" fontSizeText="18px" text="Д"/>'
                        }
                    </pre>
                </div>
            </WrapContent>
        </>
    )
}
