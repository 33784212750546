import React, {useMemo} from 'react';
import {OptionSelect} from '../../../../../../components-ui/Inputs/Select/typesSelect';
import {useSelector} from 'react-redux';
import {notTrashed} from '../../../../../../common/helpers/general-helper';
import {User} from '../../../../../../types';
import {commonLabelOption, commonSelectOption} from '../data';
import Select from '../../../../../../components-ui/Inputs/Select/Select';
import { Label } from '../../../../../../components-ui/DataDisplay/Label';
import _ from 'lodash';
import {GetSalariesTimesheetResponce} from '../../../../types';


interface Props {
    data: GetSalariesTimesheetResponce
    value?: OptionSelect
    handleEmployee: (employe: OptionSelect) => void
    isError: boolean
    isDisabled?: boolean
    selectedBranchId: number
}

const SelectEmployee = ({data, value, handleEmployee, isError, isDisabled, selectedBranchId}: Props) => {

    const users = useSelector(state => state.common.users)

    const unrecognizedUsersOptions = useMemo(() => {
        return _
            .chain(users)
            .filter(notTrashed)
            .filter((user: User) => user.branches.includes(selectedBranchId))
            .reject((user: User) => data.excludeEmployeesRoles.includes(user.role))
            .map((user: User) => ({value: user.id, label: user.fio}))
            .sortBy(user => user.label)
            .value()
    }, [data.records, users])

    return (
        <Label labelText="Сотрудник" isRequired={true} {...commonLabelOption} >
            <Select {...commonSelectOption} colorSelect="white" isError={isError} isDisabled={isDisabled} value={value} options={unrecognizedUsersOptions} onChange={(value) => handleEmployee(value)}/>
        </Label>
    )

};

export default SelectEmployee;
