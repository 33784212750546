import styled from "styled-components";

export const WrapServiceTable = styled.div.attrs({className: ''})`
    display: flex;
    align-items: center;
`

const TextService = styled.div`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${props => props.theme.colors.text};
    padding: 7px 0 8px;
`

export const ServiceCode = styled(TextService).attrs({className: 'ServiceCode'})`
    width: 120px;
`

export const ServiceService = styled(TextService).attrs({className: 'ServiceService'})`
    width: 438px;
    padding-left: 20px;
    padding-right: 20px;
`

export const ServiceTarget = styled(TextService).attrs({className: 'ServiceTarget'})`
    width: 293px;
    padding-left: 20px;
    padding-right: 20px;
`

export const ServiceDoctor = styled(TextService).attrs({className: 'ServiceDoctor'})`
    padding-left: 20px;
    width: calc(100% - 906px);
    display: flex;
    align-items: center;
    position: relative;
`

interface PWrapCheckServiceDoctor {
    active: boolean
}

export const WrapCheckServiceDoctor = styled.div.attrs({className: 'WrapCheckServiceDoctor'})<PWrapCheckServiceDoctor>`
    position: absolute;
    top: 50%;
    right: calc(100% - 13px);
    transform: translateY(-50%);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0 8px 8px;
    border-radius: 4px;
    background: ${props => props.theme.colors.white};
    box-shadow: ${props => props.active ? props.theme.shadow.defaultModal : 'none'};
    & > div {
        margin-right: 10px;
    }
`

export const ServiceDoctorSelect = styled.div.attrs({className: 'ServiceDoctorSelect'})`
    width: calc(100% - 906px);
    .selected-recommended-services {
        .filter-select__control {
            width: 260px;
            height: 30px;
            background: #ffffff;
            padding: 0 20px 0 16px;
            min-height: 30px;
        }
        .filter-select__indicators {
            top: 11px;
        }

        .filter-select__menu {
            width: 260px;
        }
        &.colored-select .filter-select__single-value {
            margin-left: 10px;
        }
    }
`
