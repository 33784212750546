import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSpecialitiesFilter} from "../../../../../redux/actions/scheduleActions";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {useOptionsSpecialities} from "../hooks/useOptionsSpecialities";

const SelectedSpecialities = () => {
    const dispatch = useDispatch();
    const specialty = useSelector(state => state.schedule.filter.speciality);
    const optionsSpecialities = useOptionsSpecialities()
    return (
        <Select options={optionsSpecialities}
                placeholder="Все специальности"
                value={optionsSpecialities.find(option => option.value === specialty)}
                marginSelect="0 24px 0 0"
                onChange={value => {
                    dispatch(setSpecialitiesFilter(!!value ? value.value : ''))
                }}
        />
    );
};

export default SelectedSpecialities;
