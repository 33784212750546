import {useEffect} from "react";
import {PropsUseChangeIsPatient} from "../types/hooks";

export default function useChangeIsPatient(
    {
        currentPatient,
        setIsEdit,
        isViewMode,
        personalPhone,
        parentName
    }: PropsUseChangeIsPatient) {

    const deps = parentName === "PreparationPatient" ? [] : [currentPatient, currentPatient?.personalPhone !== personalPhone]

    useEffect(() => {
        if (!!currentPatient) {
            setIsEdit(!isViewMode)
        } else {
            setIsEdit(true)
        }
    }, deps)

}
