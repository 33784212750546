import {useMemo} from "react";
import _ from "lodash";
import {PlannedCall} from "../../types/common";
import {InfoCallCenter} from "../../types/hooks";
import {useSelector} from "react-redux";
import {StateCallCenter} from "../../types/components";

interface Props {
    state: StateCallCenter
    infoCallCenter: InfoCallCenter,
}

const useMarketingPlannedCalls = ({infoCallCenter, state}: Props) => {
    const {plannedCalls, isMarketing} = state;
    const user = useSelector(state => state.user);
    const marketingAdmin = user.role === 'marketing-admin';

    return useMemo(() => {
        let res = _.cloneDeep(plannedCalls) as PlannedCall[];
        if (!!Object.keys(infoCallCenter.types).length) {
            if (!marketingAdmin && !isMarketing) {
                res = res.map(plannedCall => {
                    plannedCall.tasks = plannedCall.tasks.filter(task => !!infoCallCenter.types[task.type] && !infoCallCenter.types[task.type].marketing)
                    return plannedCall
                }).filter(plannedCall => !!plannedCall.tasks.length)
            } else if (marketingAdmin && !isMarketing) {
                res = res.filter(plannedCall => {
                    return plannedCall.tasks.some(task => infoCallCenter.types[task.type].marketing)
                })
            }
        }

        return res
    }, [JSON.stringify(plannedCalls), isMarketing, infoCallCenter])
}

export default useMarketingPlannedCalls
