import React, {useEffect, useState} from 'react';
import ServerCommand from "../../../../../common/server/server-command";
import {DeletePaymentParams} from "../../../types";
import  {useModal} from '../../../../../containers/DialogsManager/containers/Modal/Modal';
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import PaymentDelete from "../../../../modals/PaymentDelete";
import {useSelector} from "react-redux";
import Kkm from "../../../../../common/server/kkm";
import KkmSelect from "../../../../modals/PaymentCreationForm/components/KkmSelect";
import {KkmResponse} from "../../../../../common/types/server";

interface Props {
    id: number
    refreshDataFromServer: () => void
    selectedBranch: OptionSelect
    price: number,
    isCheck: boolean
}

const ModalDeletePayment = ({id, refreshDataFromServer, selectedBranch, price, isCheck}: Props) => {

    const kkmSetting = {
        user: null,
        url: null,
        password: null,
        numberDevice: null,
        cashierName: null,
        cashierINN: null
    }

    const [kkmOptions, setKkmOptions] = useState<OptionSelect[]>([])
    const [selectedKkm, setSelectedKkm] = useState<OptionSelect | undefined>(undefined)

    useEffect(() => {
        const kkm = new Kkm(kkmSetting);
        kkm.getList().then((selectList: OptionSelect[]) => {
            const defaultOption = {value: 0, label: 'Не печатать'}
            selectList.unshift(defaultOption)
            setKkmOptions(selectList)
            setSelectedKkm(defaultOption)
        })
    }, [])

    const {closeModal} = useModal()

    const deletePayment = () => {
        ServerCommand.post('payment/delete', {
            id: id,
            branchId: selectedBranch.value
        } as DeletePaymentParams
        ).then(() => {

            if (selectedKkm?.value > 0) {
                const kkm = new Kkm(kkmSetting)
                // @ts-ignore
                kkm.registerCheck(1, true, price, 0).then((response: KkmResponse) => {
                    console.log(response)

                    if (response.Status == 0) {

                        const command = new ServerCommand('payment/save-print-check', {
                            checkNumber: response.CheckNumber,
                            sessionNumber: response.SessionNumber,
                            sessionCheckNumber: response.SessionCheckNumber,
                            url: response.URL,
                            idCommand: response.IdCommand,
                            paymentId: id
                        });
                        command.exec().then(() => {

                        })
                    } else {
                        console.log(response.Error)
                    }
                })
            }

            closeModal()
            refreshDataFromServer()
        })
    }
    return (
            <PaymentDelete
                selectedKkm={selectedKkm}
                setSelectedKkm={setSelectedKkm}
                kkmOptions={kkmOptions}
                onClose={closeModal}
                isCheck={isCheck}
                onSumbit={deletePayment}/>
    );
};

export default ModalDeletePayment;
