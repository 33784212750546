import React from "react";
import styled from "styled-components";

interface Props {
    children?: any
    as?: any,
    isScrollX?: boolean,
    height?: string,
    onScroll?: any
    width?: string
}

const Scrollbar = React.forwardRef(({children, as = 'div', isScrollX, height, onScroll, width}: Props, ref: any) => {

    return (
        <SScrollbar width={width} ref={ref} children={children} as={as} isScrollX={isScrollX} height={height}
                    onScroll={(e: any) => {
                        if (!!onScroll) {
                            onScroll(e)
                        }
                    }}/>
    )
})

const SScrollbar = styled.div<Props>`
  overflow-x: ${props => props.isScrollX ? 'scroll' : 'auto'};
  overflow-y: scroll;
  max-height: ${props => !!props.height ? props.height : 'max-content'};
  scroll-behavior: smooth;
  overflow: overlay;
  ${props => props.width && `width: ${props.width};`};

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    height: 6px;
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.textGray};
    min-height: 25px;
    border: 4px solid transparent;
    border-radius: 18px;
    background-clip: content-box;
    transition: .4s;

    &:hover {
      background-color: ${props => props.theme.colors.textDarkGray};
    }
  }

  &::-webkit-scrollbar-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
  }
`

export default Scrollbar;
