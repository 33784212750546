import React from "react";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";
import _ from "underscore";

interface PManyCheckbox {
    selectedRows: string[],
    onSelectMany: ((itemsCode: string) => void) | any,
    getRowCode: ((item: any) => void) | any,
    data: any[]
}

export default function ManyCheckbox({selectedRows, onSelectMany, getRowCode, data} : PManyCheckbox) {
    return (
        <Checkbox label=""
                  checked={selectedRows.length === data.length}
                  onChange={() => {
                      const newData = _.clone(data);
                      let newItemsCode = [];
                      if (selectedRows.length) {
                          if (selectedRows.length === newData.length) {
                              newItemsCode = newData.map(item => getRowCode(item))
                          } else {
                              newItemsCode = _.difference(newData.map(item => getRowCode(item)), selectedRows);
                          }
                      } else {
                          newItemsCode = newData.map(item => getRowCode(item))
                      }
                      onSelectMany(newItemsCode);
                  }}
        />
    )
};
