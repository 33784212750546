import React from "react";
import {tabs} from "../helper/tabs";
import Tabs from "../../../../../components-ui/Navigation/Tabs/Tabs";
import {useChoiceOrCompletion} from "../ChoiceOrCompletion";
import {CHOICE} from "../helper/constants";

export default function TabsChoiceOrCompletion() {
    const {activeTab, setActiveTab, parentName, completionHasTabs} = useChoiceOrCompletion();
    if (parentName === CHOICE || completionHasTabs) {
        return (
            <Tabs tabs={tabs}
                  activeTab={activeTab}
                  variant="primary"
                  align="center"
                  widthTab="165px"
                  margin="0 0 30px"
                  onChange={setActiveTab}
            />
        )
    } else {
        return null;
    }

}
