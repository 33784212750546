import React, {forwardRef} from 'react';
import {IIcon, NamesSvg} from "../types/common";
import {NAMES_SVG_OBJ} from '../types/data';
import {
    AddIcon,
    AnalyticsIcon,
    ArrowIcon,
    BackIcon,
    BasketIcon,
    BothJawIcon,
    CallCenterIcon,
    CardCatalogueIcon,
    CheckedBoldIcon,
    CheckedIcon,
    CheckedThinIcon,
    CloseIcon,
    ConfirmedByMessageIcon,
    DeleteIcon,
    DocumentDeleteIcon,
    DocumentDownloadIcon,
    DocumentIcon,
    XRayIcon,
    EditIcon,
    FullScreenIcon,
    HelpIcon,
    IncomingIcon,
    InformationIcon,
    KanbanIcon,
    LowerJawIcon,
    MaterialsIcon,
    MaximizeIcon,
    MinusBoldIcon,
    MinusIcon,
    MissedIcon,
    MouseRightIcon,
    NewVisitFormIcon,
    HiddenPassIcon,
    NotifyIcon,
    OptionsIcon,
    OralCavityIcon,
    OutgoingIcon,
    ToothOutlineIcon,
    PlusThinIcon,
    TimeIcon,
    PrepareVisitIcon,
    PrinterIcon,
    ProductIcon,
    InspectionIcon,
    SaveIcon,
    ScheduleIcon,
    SearchIcon,
    SegmentIcon,
    SmallScreenIcon,
    ToothHalfIcon,
    Tooth11Icon,
    Tooth12Icon,
    OutlineArrowDownIcon,
    PlayArrowIcon,
    UnconfirmedByMessageIcon,
    WarningIcon,
    ViewPassIcon,
    Tooth13Icon,
    Tooth14Icon,
    Tooth15Icon,
    Tooth16Icon,
    Tooth17Icon,
    Tooth18Icon,
    Tooth31Icon,
    Tooth32Icon,
    Tooth33Icon,
    Tooth34Icon,
    Tooth35Icon,
    Tooth36Icon,
    Tooth37Icon,
    Tooth38Icon,
    Tooth51Icon,
    Tooth52Icon,
    Tooth53Icon,
    Tooth54Icon,
    Tooth55Icon,
    Tooth56Icon,
    Tooth57Icon,
    Tooth58Icon,
    Tooth71Icon,
    Tooth72Icon,
    Tooth73Icon,
    Tooth74Icon,
    Tooth75Icon,
    Tooth76Icon,
    Tooth77Icon,
    Tooth78Icon,
    ToothIcon,
    UpperJawIcon,
    ArrowThinIcon,
    NotifyFilledIcon,
    DoubleFilledArrowDownIcon,
    FilledArrowDownIcon,
    NotifyOldIcon,
    CanceledIcon,
    CheckOldIcon,
    CloseThinIcon,
    ColorDocumentIcon,
    ColorFolderIcon,
    DotsIcon,
    FolderIcon,
    InstructionIcon,
    LowerJawFrontIcon,
    LongArrowIcon,
    LongArrowThinIcon,
    LowerJawOutlineIcon,
    MapIcon,
    PlusIcon,
    PlusBoldIcon,
    Printer3DIcon,
    PrinterOldIcon,
    DoubleSlashIcon,
    SlashIcon,
    TargetIcon,
    TenderIcon,
    TimeOutlineIcon,
    UpperJawOutlineIcon,
    UserFpIcon,
    WarningOutlineIcon,
    WtfIcon,
    Money,
    AverageCheck,
    LoadingTheClinic,
    AnalyticsCalls,
    CallEfficiency,
    EfficiencyOfVisits,
    Message,
    SortAsc,
    SortDesc,
    SortDefault,
    Credit,
    BigPriceIcon,
    Guarantee,
    LogoNs,
    Saving,
    TimeDotted,
    PlusRoundIcon,
    MinusRoundIcon,
    Crown,
    ToothRecovery,
    TaskClinic,
    PaymentsCheck,
    TimeSheetIcon,
    Employee,
    Comment,
    SalaryIcon,
    LaboratoryIcon,
    CashBoxIcon
} from "../components";

interface PropsIcon extends IIcon {
    name: NamesSvg

}

export const Icon = forwardRef(({name, ...props}: PropsIcon, ref) => {
    switch (name) {
        // arrows
        case NAMES_SVG_OBJ.ARROWS_arrow:
            return <ArrowIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ARROWS_arrowThin:

            return <ArrowThinIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ARROWS_longArrowThin:

            return <LongArrowThinIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ARROWS_filledArrowDown:
            return <FilledArrowDownIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ARROWS_doubleFilledArrowDown:
            return <DoubleFilledArrowDownIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ARROWS_backArrow:
            return <BackIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ARROWS_outlineArrowDown:
            return <OutlineArrowDownIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ARROWS_playArrow:
            return <PlayArrowIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ARROWS_longArrow:
            return <LongArrowIcon {...props} ref={ref}/>

        //checked
        case NAMES_SVG_OBJ.CHECKED_checkedThin:
            return <CheckedThinIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.CHECKED_checked:
            return <CheckedIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.CHECKED_checkedBold:
            return <CheckedBoldIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.CHECKED_checkOld:
            return <CheckOldIcon {...props} ref={ref}/>

        //cross
        case NAMES_SVG_OBJ.CROSS_closeThin:
            return <CloseThinIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.CROSS_close:
            return <CloseIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.CROSS_delete:
            return <DeleteIcon {...props} ref={ref}/>

        //plus
        case NAMES_SVG_OBJ.PLUS_plus:
            return <PlusIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.PLUS_plusThin:
            return <PlusThinIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.PLUS_plusBold:
            return <PlusBoldIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.PLUS_plusRound:
            return <PlusRoundIcon {...props} ref={ref}/>

        //minus
        case NAMES_SVG_OBJ.MINUS_minus:
            return <MinusIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.MINUS_minusBold:
            return <MinusBoldIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.MINUS_minusRound:
            return <MinusRoundIcon {...props} ref={ref}/>

        //slash
        case NAMES_SVG_OBJ.SLASH_slash:
            return <SlashIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.SLASH_doubleSlash:
            return  <DoubleSlashIcon {...props} ref={ref} />

        //screen
        case NAMES_SVG_OBJ.SCREEN_fullScreen:
            return <FullScreenIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.SCREEN_smallScreen:
            return <SmallScreenIcon {...props} ref={ref}/>

        //info
        case NAMES_SVG_OBJ.INFO_notify:
            return <NotifyIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.INFO_notifyFilled:
            return <NotifyFilledIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.INFO_notifyOld:
            return <NotifyOldIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.INFO_time:
            return <TimeIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.INFO_timeOutline:
            return <TimeOutlineIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.INFO_timeDotted:
            return <TimeDotted {...props} ref={ref}/>

        case NAMES_SVG_OBJ.INFO_information:
            return <InformationIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.INFO_instruction:
            return <InstructionIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.INFO_warning:
            return <WarningIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.INFO_warningOutline:
            return <WarningOutlineIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.INFO_help:
            return <HelpIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.INFO_mouseRight:
            return <MouseRightIcon {...props} ref={ref}/>

        //action
        case NAMES_SVG_OBJ.ACTION_basket:
            return <BasketIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ACTION_edit:
            return <EditIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ACTION_printer:
            return <PrinterIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ACTION_save:
            return <SaveIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ACTION_add:
            return <AddIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ACTION_search:
            return <SearchIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ACTION_maximize:
            return <MaximizeIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ACTION_printerOld:
            return <PrinterOldIcon {...props} ref={ref}/>

        //pass
        case NAMES_SVG_OBJ.PASS_viewPass:
            return <ViewPassIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.PASS_hiddenPass:
            return <HiddenPassIcon {...props} ref={ref}/>

        //scheduleCell
        case NAMES_SVG_OBJ.SCHEDULECELL_inspection:
            return <InspectionIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.SCHEDULECELL_confirmedByMessage:
            return <ConfirmedByMessageIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.SCHEDULECELL_unconfirmedByMessage:
            return <UnconfirmedByMessageIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.SCHEDULECELL_tender:
            return <TenderIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.SCHEDULECELL_userFp:
            return <UserFpIcon {...props} ref={ref}/>

        //menu
        case NAMES_SVG_OBJ.MENU_analytics:
            return <AnalyticsIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.MENU_cardCatalogue:
            return <CardCatalogueIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.MENU_schedule:
            return <ScheduleIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.MENU_materials:
            return <MaterialsIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.MENU_options:
            return <OptionsIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.MENU_document:
            return <DocumentIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.MENU_xray:
            return <XRayIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.MENU_salary:
            return <SalaryIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.MENU_laboratory:
            return <LaboratoryIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.MENU_cashbox:
            return <CashBoxIcon {...props} ref={ref}/>

        //callCenter
        case NAMES_SVG_OBJ.CALLCENTER_documentDelete:
            return <DocumentDeleteIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.CALLCENTER_documentDownload:
            return <DocumentDownloadIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.CALLCENTER_incoming:
            return <IncomingIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.CALLCENTER_missed:
            return <MissedIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.CALLCENTER_outgoing:
            return <OutgoingIcon {...props} ref={ref}/>

        //popupLabel
        case NAMES_SVG_OBJ.POPUPLABEL_callCenter:
            return <CallCenterIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.POPUPLABEL_kanban:
            return <KanbanIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.POPUPLABEL_newVisitForm:
            return <NewVisitFormIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.POPUPLABEL_prepareVisit:
            return <PrepareVisitIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.POPUPLABEL_paymentsCheck:
            return <PaymentsCheck {...props} ref={ref}/>

        case NAMES_SVG_OBJ.POPUPLABEL_timeSheet:
            return <TimeSheetIcon {...props} ref={ref}/>

        //rest
        case NAMES_SVG_OBJ.REST_product:
            return <ProductIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_colorDocument:
            return <ColorDocumentIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_colorFolder:
            return <ColorFolderIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_folder:
            return <FolderIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_canceled:
            return <CanceledIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_wtf:
            return <WtfIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_dots:
            return <DotsIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_target:
            return <TargetIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_map:
            return <MapIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_printer3D:
            return <Printer3DIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_sortAsc:
            return <SortAsc {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_sortDesc:
            return <SortDesc {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_sortDefault:
            return <SortDefault {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_credit:
            return <Credit {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_bigPrice:
            return <BigPriceIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_guarantee:
            return <Guarantee {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_logoNs:
            return <LogoNs {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_saving:
            return <Saving {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_taskClinic:
            return <TaskClinic {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_employee:
            return <Employee {...props} ref={ref}/>

        case NAMES_SVG_OBJ.REST_comment:
            return <Comment {...props} ref={ref}/>

        //teeth
        case NAMES_SVG_OBJ.TEETH_segment:
            return <SegmentIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_tooth:
            return <ToothIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_toothOutline:
            return <ToothOutlineIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_toothHalf:
            return <ToothHalfIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_oralCavity:
            return <OralCavityIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_bothJaw:
            return <BothJawIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_lowerJawFront:
            return <LowerJawFrontIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_lowerJaw:
            return <LowerJawIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_upperJaw:
            return <UpperJawIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_lowerJawOutline:
            return <LowerJawOutlineIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_upperJawOutline:
            return <UpperJawOutlineIcon {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_crown:
            return <Crown {...props} ref={ref}/>

        case NAMES_SVG_OBJ.TEETH_toothRecovery:
            return <ToothRecovery {...props} ref={ref}/>

        //analytics
        case NAMES_SVG_OBJ.ANALYTICS_money:
            return <Money {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ANALYTICS_averageCheck:
            return <AverageCheck {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ANALYTICS_loadingTheClinic:
            return <LoadingTheClinic {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ANALYTICS_analyticsCalls:
            return <AnalyticsCalls {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ANALYTICS_callEfficiency:
            return <CallEfficiency {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ANALYTICS_efficiencyOfVisits:
            return <EfficiencyOfVisits {...props} ref={ref}/>

        case NAMES_SVG_OBJ.ANALYTICS_message:
            return <Message {...props} ref={ref}/>
        // dental-card
        case NAMES_SVG_OBJ.DENTALCARD_tooth11:
            return <Tooth11Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth12:
            return <Tooth12Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth13:
            return <Tooth13Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth14:
            return <Tooth14Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth15:
            return <Tooth15Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth16:
            return <Tooth16Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth17:
            return <Tooth17Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth18:
            return <Tooth18Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth21:
            return <Tooth11Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth22:
            return <Tooth12Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth23:
            return <Tooth13Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth24:
            return <Tooth14Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth25:
            return <Tooth15Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth26:
            return <Tooth16Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth27:
            return <Tooth17Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth28:
            return <Tooth18Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth31:
            return <Tooth31Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth32:
            return <Tooth32Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth33:
            return <Tooth33Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth34:
            return <Tooth34Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth35:
            return <Tooth35Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth36:
            return <Tooth36Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth37:
            return <Tooth37Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth38:
            return <Tooth38Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth41:
            return <Tooth31Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth42:
            return <Tooth32Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth43:
            return <Tooth33Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth44:
            return <Tooth34Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth45:
            return <Tooth35Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth46:
            return <Tooth36Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth47:
            return <Tooth37Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth48:
            return <Tooth38Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth51:
            return <Tooth51Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth52:
            return <Tooth52Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth53:
            return <Tooth53Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth54:
            return <Tooth54Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth55:
            return <Tooth55Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth56:
            return <Tooth56Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth57:
            return <Tooth57Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth58:
            return <Tooth58Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth61:
            return <Tooth51Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth62:
            return <Tooth52Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth63:
            return <Tooth53Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth64:
            return <Tooth54Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth65:
            return <Tooth55Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth66:
            return <Tooth56Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth67:
            return <Tooth57Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth68:
            return <Tooth58Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth71:
            return <Tooth71Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth72:
            return <Tooth72Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth73:
            return <Tooth73Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth74:
            return <Tooth74Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth75:
            return <Tooth75Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth76:
            return <Tooth76Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth77:
            return <Tooth77Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth78:
            return <Tooth78Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth81:
            return <Tooth71Icon {...props} ref={ref}/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth82:
            return <Tooth72Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth83:
            return <Tooth73Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth84:
            return <Tooth74Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth85:
            return <Tooth75Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth86:
            return <Tooth76Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth87:
            return <Tooth77Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        case NAMES_SVG_OBJ.DENTALCARD_tooth88:
            return <Tooth78Icon {...props} ref={ref} transform="scale(-1, 1)"/>
        default:
            return null
    }
});

