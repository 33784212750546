import {Dispatch, SetStateAction, useEffect} from "react";
import ServerCommand from "../../../../common/server/server-command";
import {
    IStateCashCreate,
    GetDirectionsBalanceResult,
    IPaymentCreationFormValues,
    GetDirectionsBalanceParams
} from "../types";
import {ResponseKkmList} from "../../../../pages/Settings/Kkm/types";
import {IStateCashReturn} from "../../CashReturn/types";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";

interface Props {
    setState: Dispatch<SetStateAction<IStateCashCreate>>
    setData: Dispatch<SetStateAction<GetDirectionsBalanceResult | null>>
    setKkmOptions: Dispatch<SetStateAction<OptionSelect[]>>
    patientId: number | undefined
    branchId: number | undefined
}

const useLoadData = ({setState, setData, setKkmOptions, patientId, branchId}: Props) => {
    useEffect(() => {
        const newValues: IPaymentCreationFormValues = {};
        if (patientId !== undefined) {
            ServerCommand.get('patient/directions-balance', {id: patientId} as GetDirectionsBalanceParams).then((response: GetDirectionsBalanceResult) => {

                if (response.directionsBalance !== null) {
                    const keysDirectionsBalance = Object.keys(response.directionsBalance);
                    keysDirectionsBalance.forEach(direction => newValues[direction] = '');
                } else {
                    newValues.general = "";
                }
                setState(prevState => ({
                    ...prevState,
                    values: newValues,
                }))
                setData({...response});
            });
        } else {
            newValues.general = "";
        }
    }, [patientId]);

    useEffect(() => {
        if (!!branchId) {
            ServerCommand.get('kkm/list', {
                filter: {
                    branchId: branchId
                }
            }).then((response: ResponseKkmList) => {

                setState(prevState => ({
                    ...prevState,
                    kkmList: response.list,
                }))
                setKkmOptions(response.list.map((kkm) => {
                    return {
                        value: kkm.id,
                        label: kkm.name
                    }
                }))

            });
        }
    }, [branchId])

};

export default useLoadData;
