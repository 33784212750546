import React, {forwardRef} from 'react';
import {Props} from "./types";
import {SBadge, SWrapBadge} from './style'

export const Badge = forwardRef((
    {
        children,
        badgeContent,
        marginWrap,
        bg,
        color,
        showBadge = true,
        vertical = 'top',
        horizontal = 'right'
    }: Props, ref: any) => {

    return (
        <SWrapBadge ref={ref} margin={marginWrap}>
            {children}
            {showBadge &&
            <SBadge children={badgeContent} bg={bg} color={color} vertical={vertical} horizontal={horizontal}/>}
        </SWrapBadge>
    );
});


