import React from "react";
import {WrapUI} from "./styledUI";
import UINav from "./UINav";
import UIContent from "./UIContent";

export default function UI() {
    return (
        <WrapUI>
            <UINav/>
            <UIContent/>
        </WrapUI>
    )
};
