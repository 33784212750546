import {combineReducers} from "redux";
import scheduleReducer from "./modules/schedule";
import userReducer from "./modules/user";
import commonReducer from "./modules/common";
import doctorRoleReducer from "./modules/doctorRole";
import callCenterReducer from "./modules/callCenter";
import patientReducer from "./modules/patient";

export const rootReducer = combineReducers({
    user: userReducer,
    schedule: scheduleReducer,
    common: commonReducer,
    doctorRole: doctorRoleReducer,
    callCenter: callCenterReducer,
    patient: patientReducer,
});

