import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const CanceledIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M12,0A12,12,0,0,0,3.48,20.42a.08.08,0,0,1,0,0,0,0,0,0,1,0,0A12,12,0,1,0,12,0ZM1.68,12a10.33,10.33,0,0,1,17-7.86L4.14,18.65A10.25,10.25,0,0,1,1.68,12ZM12,22.3a10.3,10.3,0,0,1-6.68-2.46L19.84,5.33A10.29,10.29,0,0,1,12,22.3Z"/>
        </WrapIcon>
    );
});
