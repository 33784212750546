import {useSelector} from "react-redux";
import {OptionSelect} from "../../components-ui/Inputs/Select/typesSelect";
import {useMemo} from "react";

export const useOptionsSelectBranches = (): OptionSelect[] => {
    const branches = useSelector(state => state.common.branches);
    return useMemo(() => branches.map(branch => ({
        label: branch.name,
        value: branch.id
    })), [branches])
}