import React from "react";
import LabelTask from "../../../../../../../containers/Tasks/components/UI/LabelTask";
import Helper from "../../../../../../../common/helpers/main";
import useContactPersonName from "../../../hooks/useContactPersonName";
import Typography from "../../../../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../../../../../../containers/Tasks/data/commonPropsSComponent";
import {PlannedCall, TaskCallCenter} from "../../../../../types/common";
import { STaskList, STaskItem } from "../../../../../../../containers/Tasks/style";

interface Props {
    plannedCall: PlannedCall
    uniqPatient: TaskCallCenter[]
}

const InfoExtendedCall = ({plannedCall, uniqPatient}: Props) => {

    const isContactPersonName = useContactPersonName({plannedCall, uniqPatient});

    return (
        <STaskList padding="14px 0">
            <LabelTask label="Телефон" children={Helper.Phone.format(plannedCall.phone)}/>
            {isContactPersonName && (
                <STaskItem margin="2px 0" isHighlighted={true}>
                    <Typography {...commonPropsTypography} children="Контактное лицо" width="38%" flexShrink="0"/>
                    <Typography {...commonPropsTypography} display="flex" alignItems="center" width="62%" flexShrink="0"
                                textAlign="right" justifyContent="flex-end"
                                children={!!plannedCall.phoneOwner ? Helper.getFullName(plannedCall.phoneOwner) : 'не указано'}/>
                </STaskItem>
            )}
        </STaskList>
    )
}

export default InfoExtendedCall
