import {StateCallCenter} from "../../types/components";
import {InfoCallCenter} from "../../types/hooks";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import _ from "lodash";
import {callCenterTasksInfo} from "../../helper/getDataCallCenter";
import useBranchesPlannedCalls from "./useBranchesPlannedCalls";

interface Props {
    state: StateCallCenter
    infoCallCenter: InfoCallCenter,
}

const useNoticeQuantity = ({state, infoCallCenter}: Props) => {
    const isMarketingAdmin = useSelector(state => state.user.role) === 'marketing-admin';
    const {isMarketing} = state;

    const branchesPlannedCalls = useBranchesPlannedCalls({plannedCalls: state.plannedCalls, state})

    const {noticesQuantity, marketingNoticeQuantity, notMarketingNoticeQuantity} = useMemo(() => {

        const noticesQuantity = {
            all: 0,
        } as {[key: string]: number};

        let marketingNoticeQuantity = 0;
        let notMarketingNoticeQuantity = 0;

        if (!!Object.keys(infoCallCenter.types).length) {
            branchesPlannedCalls.forEach((plannedCall) => {
                if (!plannedCall.tasks.some(task => !!task.postponedTo)) {
                    noticesQuantity.all += 1;
                    const isMarketingTask = plannedCall.tasks.some(task => !!infoCallCenter.types[task.type] && infoCallCenter.types[task.type].marketing);
                    if (isMarketingAdmin) {
                        if (!isMarketingTask) marketingNoticeQuantity += 1;
                    } else {
                        if (plannedCall.tasks.some(task => !infoCallCenter.types[task.type].marketing)) notMarketingNoticeQuantity += 1;
                        if (isMarketingTask) marketingNoticeQuantity += 1;
                    }
                }
                _.forEach(callCenterTasksInfo, (label, typeCode) => {
                    if (plannedCall.tasks.some(task => task.type === typeCode)) {
                        if (noticesQuantity[typeCode] === undefined) noticesQuantity[typeCode] = 0;
                        if (!plannedCall.tasks.some(task => !!task.postponedTo)) noticesQuantity[typeCode] += 1;
                    }
                })
            })
        }

        return {noticesQuantity, marketingNoticeQuantity, notMarketingNoticeQuantity}
    }, [JSON.stringify(branchesPlannedCalls), infoCallCenter.types])

    const noticeQuantityTotal = useMemo(() => {
        if (isMarketing) {
            return noticesQuantity.all
        } else {
            if (!isMarketingAdmin) {
                return notMarketingNoticeQuantity
            } else {
                return noticesQuantity.all - marketingNoticeQuantity
            }
        }
    }, [noticesQuantity.all, marketingNoticeQuantity, isMarketing, notMarketingNoticeQuantity])

    return {
        noticesQuantity,
        marketingNoticeQuantity,
        notMarketingNoticeQuantity,
        noticeQuantityTotal
    }
}

export default useNoticeQuantity
