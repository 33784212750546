import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonSelectOption} from "../../data";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {IStateCashReturn} from "../../types";
import {useSelector} from "react-redux";

interface Props {
    state: IStateCashReturn
    setState: Dispatch<SetStateAction<IStateCashReturn>>
    kkmOptions: OptionSelect[]
}

const KkmSelect = ({state, setState, kkmOptions}: Props) => {

    const handleKkmSelect = useCallback((selectedKkm: OptionSelect) => {
        setState(prevState => ({...prevState, selectedKkm}))
    }, [setState])
    return (
        <Label labelText="ККМ" {...commonLabelOption} isRequired={true}>
            <Select
                {...commonSelectOption}
                options={kkmOptions}
                value={state.selectedKkm}
                onChange={handleKkmSelect}
            />
        </Label>
    );
};

export default KkmSelect;
