import React from 'react';
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";

interface Props {
    efficiencyPercent: number
}

export const Efficiency = ({efficiencyPercent}: Props) => {
    return (
        <LabelTask label="Эффективность"
                   children={`${efficiencyPercent}%`}
                   alignItems="flex-start"
        />
    );
};
