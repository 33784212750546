import React from 'react';
import {WrapTooltip, HeaderTooltip, ListTooltip, ItemTooltip} from "../../style/tooltip";
import Typography from "../../../DataDisplay/Typography/Typography";

interface Props {
    active?: any
    payload?: any
    label?: any
    formatterCode?: (code: string) => string
    formatterValue?: (value: number) => string
    formatterName?: (name: string) => string
    formatterSort?: (a: number, b: number) => any
    [key: string]: any
}

const CustomTooltip = ({active, label, payload, formatterCode, formatterValue, formatterName, formatterSort}: Props) => {
    if (active) {
        let items = payload;
        if (!!formatterSort) {
            items = items.sort((a:any, b: any) => formatterSort(a.value, b.value))
        }
        return (
            <WrapTooltip>
                <HeaderTooltip>
                    <Typography children={!!formatterCode ? formatterCode(label) : label}
                                color="white"
                                variant="min"
                    />
                </HeaderTooltip>
                <ListTooltip>
                    {items.map((item: any) => {
                        return (
                            <ItemTooltip>
                                <Typography children={!!formatterName ? formatterName(item.name) : item.name}
                                            variant="min"
                                            color="white"
                                            margin="0 16px 0 0"/>
                                <Typography children={!!formatterValue ? formatterValue(item.value) : item.value}
                                            variant="min"
                                            color="white"/>
                            </ItemTooltip>
                        )
                    })}
                </ListTooltip>
            </WrapTooltip>
        );
    } else {
        return null
    }
};

export default CustomTooltip;
