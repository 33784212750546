import React from 'react';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../../../components-ui/Layout/Box';
import MarkRate from './components/MarkRate';
import useXrayRateMark from './hooks/useXrayRateMark';
import SelectDate from './components/SelectDate';
import {commonLabelOption, commonSelectOption} from '../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import Button from '../../../../../components-ui/Inputs/Button/Button';
import {Label} from '../../../../../components-ui/DataDisplay/Label';

interface Props {
    employeeId: number;
    fio: string;
    rate: number;
    pictureName: string;
    pictureType: string;
    rateActiveFrom?: string;
    selectedBranch: number;
    refreshDataFromServer: () => void;
}

const XrayRateMark = ({
                          employeeId,
                          fio,
                          rate,
                          pictureType,
                          pictureName,
                          rateActiveFrom,
                          selectedBranch,
                          refreshDataFromServer,
                      }: Props) => {

    const {
        state,
        selectedMonth,
        setSelectedMonth,
        changeRate,
        loader,
        saveNewXrayRate,
    } = useXrayRateMark({
        employeeId,
        pictureType,
        rate,
        rateActiveFrom,
        selectedBranch,
        refreshDataFromServer,
    });

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        children={`Изменение ставки ${pictureName}`} textAlign='center' />
            <Box margin='12px 0 0 0'>
                <Typography variant='min-title' fontWeight='normal' textAlign='center'>
                    {fio}
                </Typography>
            </Box>
            <Box margin='39px 0 0 0'>
                <MarkRate rate={state.rate} changeRate={changeRate} />

                <SelectDate selectedMonth={selectedMonth}
                            setSelectedMonth={setSelectedMonth} />

                <Box margin='32px 0 0 0'>
                    <Label labelText='' {...commonLabelOption}>
                        <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                            <Button children='Сохранить'
                                    size='md'
                                    loader={loader}
                                    variant={state.rate ? 'success' : 'disabled'}
                                    onClick={saveNewXrayRate}
                            />
                        </Box>
                    </Label>
                </Box>
            </Box>
        </Box>
    );
};

export default XrayRateMark;
