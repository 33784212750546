import * as React from 'react'
import {Component} from 'react';
import Columns from "../Columns/Columns";
import TooltipAvatar from "../elements/TooltipAvatar";
import ContextMenu from "../elements/ContextMenu";
import {DataContextMenu, positionTooltip} from "../types/elements";
import {chair} from "../../../common/types/schedule";
import {sortName} from "../../../common/helpers/graph-helper";
import {useSelector} from "react-redux";
import {createRef} from "react";
import _ from 'underscore';
import { withoutTrashed } from '../../../common/helpers/Doctor';

export default class Graph extends Component<any, any> {
    private graph = createRef<any>();
    state = {
        positionTooltip: {
            x: 0,
            y: 0
        },
        textTooltip: null
    };

    componentDidMount(): void {
        this.graph.current.scrollLeft = this.props.scrollTo;
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {
            timeStartInterval, timeEndInterval, graphData, chairs, onScroll, handleResizeStop,
            handleClickCell, handleContextMenu, selectedCell, removeContextMenu, positionContextMenu,
            handleDoctor, deleteSchedule, typeContextMenu, showContextMenu, handleConflict, selectedBranch
        } = this.props;
        const {positionTooltip, textTooltip} = this.state;
        return (
            <>
                <div className="graph"
                     ref={this.graph}
                     onScroll={() => onScroll(this.graph.current.scrollLeft)}
                >
                    {chairs.map((chair: chair) => {
                        return <Columns
                            timeStartInterval={timeStartInterval}
                            timeEndInterval={timeEndInterval}
                            title={chair.name}
                            data={graphData[chair.id] === undefined ? [] : graphData[chair.id]}
                            key={chair.id}
                            handleTooltip={this.handleTooltip}
                            chairId={chair.id}
                            handleResizeStop={handleResizeStop}
                            selectedCell={selectedCell}
                            handleClickCell={handleClickCell}
                            handleContextMenu={handleContextMenu}
                            variant="graph"
                        />
                    })}
                </div>
                <TooltipAvatar text={textTooltip} position={positionTooltip}/>
                <ContextMenuWrap
                    selectedCell={selectedCell}
                    removeContextMenu={removeContextMenu}
                    positionContextMenu={positionContextMenu}
                    handleDoctor={handleDoctor}
                    deleteSchedule={deleteSchedule}
                    typeContextMenu={typeContextMenu}
                    showContextMenu={showContextMenu}
                    handleConflict={handleConflict}
                    selectedBranch={selectedBranch}
                />
            </>
        );
    }

    handleTooltip = (positionTooltip: positionTooltip, textTooltip: string | null | undefined): void => {
        this.setState({positionTooltip, textTooltip})
    };
}

function ContextMenuWrap({
                             selectedCell, removeContextMenu, positionContextMenu, selectedBranch,
                             handleDoctor, deleteSchedule, typeContextMenu, showContextMenu, handleConflict
                         }: any) {
    const doctors = _.clone(useSelector(state => state.common.doctors));
    if (!showContextMenu) return null;

    let isDisabledRemove = true;

    if (selectedCell !== null) {
        if (selectedCell.data.doctorId !== 0) {
            isDisabledRemove = false;
        }
    }

    const removeSchedule = {
        name: 'Удалить расписание',
        code: 'removeSchedule',
        isDisabled: isDisabledRemove,
        onClick: () => deleteSchedule(selectedCell)
    } as DataContextMenu;

    const propsContext = {
        remove: removeContextMenu,
        position: positionContextMenu,
        data: []
    } as any;

    if (typeContextMenu === 'default') {
        const currentDoctorId = selectedCell !== null ? selectedCell.data.doctorId : false;
        const doctorsList = withoutTrashed(doctors).filter(doctor => doctor.branches.includes(selectedBranch))
            .sort((a, b) => sortName(a.fio, b.fio))
            .map((doctor) => {
                return {
                    name: doctor.fio,
                    code: doctor.id,
                    isSelect: currentDoctorId === doctor.id,
                    onClick: () => handleDoctor(doctor)
                }
            }) as DataContextMenu[];

        const itemMenu = {
            name: 'Врач',
            code: 'doctor',
            list: doctorsList
        } as DataContextMenu;

        propsContext.data.push(itemMenu);
        propsContext.data.push(removeSchedule);
        return <ContextMenu {...propsContext}/>
    } else {
        const itemMenu = {
            name: 'Копировать расписание...',
            code: 'copy',
            list: [
                {
                    name: "Чётной недели в нечётную",
                    code: "even",
                    onClick: () => handleConflict('even')
                },
                {
                    name: "Нечётной недели в чётную",
                    code: "odd",
                    onClick: () => handleConflict('odd')
                },
            ]
        };
        propsContext.data.push(itemMenu);
        propsContext.data.push(removeSchedule);
        return <ContextMenu {...propsContext}/>
    }


}
