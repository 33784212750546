import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {ResponseUserDetail, setFnType} from '../types';
import CardForm from '../../../../../../../components-ui/DataDisplay/CardForm';
import Select from '../../../../../../../components-ui/Inputs/Select/Select';
import {HexColorPicker} from 'react-colorful';
import Typography from '../../../../../../../components-ui/DataDisplay/Typography/Typography';
import TextField from '../../../../../../../components-ui/Inputs/TextField/TextField';
import {OptionSelect} from '../../../../../../../components-ui/Inputs/Select/typesSelect';
import Tabs from '../../../../../../../components-ui/Navigation/Tabs/Tabs';
import Box from '../../../../../../../components-ui/Layout/Box';
import Button from '../../../../../../../components-ui/Inputs/Button/Button';
import {useDialogsManager} from '../../../../../../../containers/DialogsManager/DialogsManager';
import DetailRates from '../../DetailRates';
import {CardFormType} from '../../../../../../../components-ui/DataDisplay/CardForm/types';

interface Props {
    state: ResponseUserDetail;
    setState: Dispatch<SetStateAction<ResponseUserDetail>>;
    setDetailFn: (props: setFnType) => void;
    setSalaryFn: (props: setFnType) => void;
    rolesOptions: OptionSelect[];
    directionsOptions: OptionSelect[];
    handsetOptions: OptionSelect[];
    validationState: boolean;
}

const ProfessionInfo = ({
                            state,
                            setState,
                            setDetailFn,
                            setSalaryFn,
                            rolesOptions,
                            directionsOptions,
                            handsetOptions,
                            validationState,
                        }: Props) => {

    const {showModal} = useDialogsManager();

    const prevRateType = state.salary.working_days ?
        'working_days' : 'working_hours';

    const [rateTypeState, setRateTypeState] = useState<'working_days' | 'working_hours'>(prevRateType);

    const [rateValue, setRateValue] = useState(state.salary[rateTypeState]?.rate ?? '');

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            salary: {
                ...prevState.salary,
                [rateTypeState]: rateValue ? {
                    rate: rateValue,
                } : null,
            },
        }));
    }, [rateTypeState, rateValue]);

    const forms: CardFormType[] = [
        {
            isRequired: true,
            label: 'Роль',
            input: <Select variant='default'
                           isError={validationState && !state.detail.role}
                           options={rolesOptions}
                           value={rolesOptions.find(({value}) =>
                                   value === state.detail.role) ??
                               {value: 'unknown', label: 'Не выбрано'}}
                           onChange={(val) => setDetailFn({key: 'role', value: val.value})}
            />,

        },
        {
            isRequired: state.detail.role === 'doctor',
            label: 'Цвет',
            input: <>
                {state.detail.role === "doctor" &&
                    <Typography margin="0 0 15px 0" color={validationState && !state.detail.color ? "danger" : "text"}>
                        {state.detail.color ?? "Не выбрано"}
                    </Typography>
                }
                <HexColorPicker color={state.detail.color ?? '#fff'}
                                style={state.detail.role === 'doctor' ?
                                    {marginBottom: '22px'} :
                                    {filter: 'grayscale(1)', pointerEvents: 'none'}}
                                onChange={(value) => setDetailFn({key: 'color', value})} />

                {
                    state.detail.role !== 'doctor' &&
                    <Typography fontStyle='italic' color='textDarkGray'>
                        Цвет можно указать только у врачей
                    </Typography>
                }
            </>,
        },
        {
            label: 'Оклад',
            input: <TextField widthInput='100%'
                              isNumber
                              onChange={(value) => {
                                  setSalaryFn({key: 'fixed', value: {rate: value}});
                              }}
                              value={state.salary.fixed?.rate ?? ''} />,
        },
        {
            label: 'Ставка',
            input: <Box display='flex' gap='7px'>
                <TextField widthInput='189px'
                           isNumber
                           onChange={setRateValue}
                           value={rateValue} />
                <Tabs variant='primary'
                      size='lg'
                      widthTab='90px'
                      tabs={[
                          {code: 'working_hours', name: 'За час'},
                          {code: 'working_days', name: 'За смену'},
                      ]}
                      onChange={(value) => {
                          setRateTypeState(value);
                      }}
                      activeTab={rateTypeState}
                />
            </Box>,
        },
    ];

    if (state.detail.role === 'doctor') {
        forms.splice(2, 0, {
            isRequired: true,
            label: 'Специальность',
            input: <Select options={directionsOptions}
                           menuPlacement='top'
                           isError={validationState && !state.detail.specialities?.length}
                           value={directionsOptions.filter(option => state.detail.specialities?.includes(option.value))}
                           onChange={(val) => setDetailFn({
                               key: 'specialities',
                               value: val.map((value: OptionSelect) => value.value),
                           })}
                           widthSelect='260px'
                           placeholder='Выбрать специальность'
                           isMulti={true}
                           closeMenuOnSelect={false} />,
        });
        forms.push(
            {
                label: 'Ставки детально',
                input: <Button children='Изменить'
                               variant='default'
                               onClick={() => showModal(<DetailRates setGlobalState={setState}
                                                                     salary={state.salary} />)} />,
            },
        );
    }
    //если роль равно admin добавляем поле в массив forms с полем Select
    if (state.detail.role === 'admin' || state.detail.role === 'marketing-admin') {
        forms.push({
            label: 'Трубка',
            input: <Select options={handsetOptions}
                           menuPlacement='top'
                           value={handsetOptions.filter(({value}) => state.detail.handsets?.includes(value))}
                           onChange={(val) => setDetailFn({
                               key: 'handsets',
                               value: val.map((value: OptionSelect) => value.value),
                           })}
                           isMulti={true}/>
        })
    }

    return (
        <CardForm style={{margin: '0 0 16px 0'}} title='Профессии сотрудника' forms={forms} />
    );
};

export default ProfessionInfo;
