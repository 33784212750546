import {useEffect} from "react";
import ServerCommand from "../../../common/server/server-command";
import Helper from "../../../common/helpers/main";
import {PropsUseInitial} from "../types/hooks";
import {ResponseNewVisit} from "../types/response";

export default function useInitial(
    {
        props,
        setMaxVisitDuration,
        setForm,
        setAppointmentTime,
        loadingPatient
    }: PropsUseInitial) {

    useEffect(() => {
        ServerCommand.get('visit/add-form', {
            timeStart: props.date + ' ' + props.timeStart,
            chairId: props.chairId
        }).then((response: ResponseNewVisit) => {
            let timeStart = Helper.clone(props.timeStart).split(':');
            let timeEnd = Helper.clone(props.timeEnd).split(':');
            setMaxVisitDuration(response.maxVisitDuration)
            setAppointmentTime(String((+timeEnd[0] * 60 + +timeEnd[1]) - (+timeStart[0] * 60 + +timeStart[1])))

            if (!!props.copiedPatient) {
                setForm(prevState => ({
                    ...prevState,
                    basic: {
                        ...prevState.basic,
                        personalPhone: String(props.copiedPatient?.personalPhone),
                        name: !!props.copiedPatient ? !!props.copiedPatient.name ? props.copiedPatient.name : prevState.basic.name : prevState.basic.name
                    }

                }))
            }
        });

    }, []);

    useEffect(() => {
        if (!!props.copiedPatient?.id) {
            loadingPatient(props.copiedPatient as any)
        }
    }, [])

}
