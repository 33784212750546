import React from 'react';
import Paper from '../../../containers/UI-Collection/Elements/Surfaces/Paper';
import Typography from '../Typography/Typography';
import {Divider} from '../Divider';
import Box from '../../Layout/Box';
import { Label } from '../Label';
import {CardFormType} from './types';

interface CardFormProps {
    title: string,
    forms: CardFormType[]
    style?: {margin: string}
}

const CardForm = ({title, forms, style}: CardFormProps) => {
    return (
        <Paper margin={style?.margin} padding="0px 20px" width="100%">
            <Typography variant="min-title" margin="24px 0 0 0">{title}</Typography>
            <Divider margin="12px 0 0 0"/>
            <Box margin="24px" display="flex" gap="16px" flexDirection="column">
                {forms.map(({label, input, isRequired = false}) =>
                    <Box display="flex" justifyContent="space-between" alignItems="center" margin="0 -2px 0 0">
                        <Label labelText={label}
                               isRequired={isRequired}
                              // minWidth="160px"
                               //color="textGray"
                            >
                        </Label>
                        <Box minWidth="374px">
                            {input}
                        </Box>
                    </Box>
                )}
            </Box>
        </Paper>
    );
};

export default CardForm;
