import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth15Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 6.55 24"} width={width || "12"} height={height || "44"} ref={ref}>
            <path d="M1.4,2.89C1.31,1,1.73.08,1.89,0c0,0,1.91.8,3.18,15.75a3.73,3.73,0,0,0-1.87-1.1A2.07,2.07,0,0,0,1.5,15a2.75,2.75,0,0,0-.26.22A113.06,113.06,0,0,0,1.4,2.89ZM6.49,22.08A4.56,4.56,0,0,1,3.73,24C3.24,24,0,23.47,0,21.18c.12-1.23.72-4.53,2-5.47a1.19,1.19,0,0,1,1-.22c1.4.29,2.32,1.92,2.74,2.86.71,1.68.9,3.36.68,3.73Z"/>
        </WrapIcon>
    );
});
