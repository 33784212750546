import React, {forwardRef} from 'react';
import { WrapIcon } from '../../../containers/WrapIcon';
import { IIcon } from '../../../types/common';

export const OutgoingIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M6,0A1.34,1.34,0,0,1,7.33,1.33a15.21,15.21,0,0,0,.76,4.76,1.33,1.33,0,0,1-.33,1.36L4.83,10.39a20.19,20.19,0,0,0,8.78,8.78l2.94-2.93a1.32,1.32,0,0,1,1.36-.32,15.26,15.26,0,0,0,4.76.76A1.34,1.34,0,0,1,24,18v4.66A1.34,1.34,0,0,1,22.67,24,22.67,22.67,0,0,1,0,1.33,1.34,1.34,0,0,1,1.33,0ZM24,0V7.11H22.58V2.42l-8.24,8.25-1-1,8.25-8.24H16.89V0Z"/>
        </WrapIcon>
    );
});
