import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const SlashIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 4.8 24"} ref={ref}>
            <rect width="4.8" height="24" rx="1.2"/>
        </WrapIcon>
    );
});
