import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const ScheduleIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M22.82,6V3A1.78,1.78,0,0,0,21,1.18H3A1.78,1.78,0,0,0,1.18,3V6Zm0,1.2H1.18V21A1.78,1.78,0,0,0,3,22.82H21A1.78,1.78,0,0,0,22.82,21ZM3,0H21a3,3,0,0,1,3,3V21a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0ZM4,11.51a.6.6,0,0,1,0-1.2h3.5a.6.6,0,0,1,0,1.2Zm6.3,0a.6.6,0,0,1,0-1.2h3.5a.6.6,0,0,1,0,1.2Zm6.23,0a.6.6,0,0,1,0-1.2H20a.6.6,0,0,1,0,1.2ZM4,15.85a.6.6,0,0,1,0-1.2h3.5a.6.6,0,0,1,0,1.2Zm6.3,0a.6.6,0,0,1,0-1.2h3.5a.6.6,0,0,1,0,1.2ZM4,20.49a.59.59,0,0,1-.59-.6.59.59,0,0,1,.59-.6h3.5a.6.6,0,0,1,.59.6.59.59,0,0,1-.59.6Zm6.3,0a.59.59,0,0,1-.59-.6.59.59,0,0,1,.59-.6h3.5a.59.59,0,0,1,.59.6.59.59,0,0,1-.59.6Zm6.23,0a.59.59,0,0,1-.59-.6.59.59,0,0,1,.59-.6H20a.59.59,0,0,1,.59.6.59.59,0,0,1-.59.6Zm.32-5.91,1.21.78,1.72-1.77a.59.59,0,1,1,.85.82l-2.06,2.12a.59.59,0,0,1-.74.08l-1.62-1a.61.61,0,0,1-.18-.82A.6.6,0,0,1,16.8,14.58Z"/>
        </WrapIcon>
    );
});
