import {PatientValues} from "../../../../../containers/PatientField";
import ServerCommand from "../../../../../common/server/server-command";
import {ResponsePatientList} from "../../../../../containers/PatientField/types/common";
import prepareDataExistingPatient
    from "../../../../../containers/PatientField/helper/prepareDataExistingPatient";
import preparePersonalGender
    from "../../../../../containers/PatientField/helper/preparePersonalGender";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {useSelector} from "react-redux";
import {PropsUseLoadingPatient} from "../types/hooks";
import _ from 'lodash'
import useLoader from "../../../../../components/Errors";

export default function useLoadingPatient({setForms, fields, data}: PropsUseLoadingPatient) {
    const [loaderPatient, setLoaderPatient] = useLoader();
    const relationTypesTitles = useSelector(state => state.patient.relationTypesTitles)

    const loadingPatient = (patient: PatientValues, currentTab: string): void => {
        const select = _.uniq(_.flattenDeep(data.documents.map(document => _.values(document.usedFields)))).filter(item => item !== 'relation')
        setLoaderPatient(true);
        ServerCommand.get('patient/list', {
            filter: !patient.personalPhone ? {id: patient.id} : {personalPhone: patient.personalPhone},
            select: ['id', ...select],
            getRelationsToPatient: data.patient.id
        }).then((response: ResponsePatientList) => {
            const {
                filterPatientList,
                optionsPhoneOwner,
                phoneOwner,
                currentPatient
            } = prepareDataExistingPatient(response.list, patient)

            const personalGender = preparePersonalGender(currentPatient, fields)
            let relation = null as null | OptionSelect;
            if (!!currentPatient.relationType && !!relationTypesTitles) {
                let relationTypes = relationTypesTitles.general as any;
                if (!!currentPatient.personalGender) {
                    relationTypes = Object.assign({}, relationTypesTitles.general, relationTypesTitles[currentPatient.personalGender])
                }
                relation = {
                    value: currentPatient.relationType,
                    label: relationTypes[currentPatient.relationType]
                } as OptionSelect
            }

            const statePatient = {
                ..._.pick(currentPatient, select),
                personalGender,
                phoneOwner,
                relationsList: filterPatientList,
                relation
            } as any

            if (!!statePatient.passportIssuedDate) {
                statePatient.passportIssuedDate = statePatient.passportIssuedDate.split(' ')[0]
            }

            setForms(prevForm => {
                prevForm[currentTab] = {
                    ...prevForm[currentTab],
                    basic: {
                        ...prevForm[currentTab].basic,
                        ...statePatient,
                        optionsPhoneOwner,
                    },
                    relations: {
                        ...prevForm.relations,
                        relationsList: filterPatientList,
                    },
                    currentPatient: {
                        id: currentPatient.id,
                        ...statePatient
                    }
                }
                return {...prevForm}
            })
            setLoaderPatient(false);
        });
    };

    return {
        loaderPatient,
        loadingPatient
    }
}
