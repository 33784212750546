import React, {Dispatch, SetStateAction} from 'react';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import {useOptionsBranchesUser} from '../../../../common/hooks/useOptionsBranchesUser';
import SelectCheckbox from '../../../../components-ui/Inputs/SelectCheckbox/SelectCheckbox';
import Box from '../../../../components-ui/Layout/Box';
import {useSelector} from 'react-redux';
import Select from '../../../../components-ui/Inputs/Select/Select';
import TextField from '../../../../components-ui/Inputs/TextField/TextField';
import {months, PMonths} from '../../../../common/arrays/arrays';
import moment from 'moment';

interface Props {
    selectedBranches: number[];
    setSalariesBranchesFilter: Dispatch<SetStateAction<number[]>>;
    selectedMonth: {month: number, year: number};
    onChangeMonth: (value: string) => void;
    onChangeYear: (value: string) => void;
}

const SalariesFilter = ({
                            selectedBranches,
                            setSalariesBranchesFilter,
                            selectedMonth,
                            onChangeMonth,
                            onChangeYear,
                        }: Props) => {

    const userBranches = useSelector(state => state.user.branches);

    const optionsBranches = useOptionsBranchesUser();

    return (
        <Box display='flex' gap='0 16px' margin='22px 0'>
            {optionsBranches.length > 1 && <SelectCheckbox options={optionsBranches}
                                                           placeholder='Все филиалы'
                                                           variant="sm"
                                                           selected={optionsBranches.filter(branch => !!selectedBranches && selectedBranches.includes(branch.value))}
                                                           onChange={values => {
                                                               setSalariesBranchesFilter(!!values && !!values.length ? values.map((value: OptionSelect) => +value.value) : userBranches);
                                                           }}
            />}
            <Select value={months.find((month) => selectedMonth.month === +month.value)}
                    placeholder='Месяц'
                    sizeSelect="sm"
                    options={months.map(month => ({...month,
                        isDisabled: moment(`${selectedMonth.year}-${month.value}`, 'YYYY-MM').isAfter(new Date())
                    }))}
                    widthMenu='250px'
                    onChange={(option: PMonths) => onChangeMonth(option.value)} />
            <TextField value={selectedMonth.year}
                       placeholder='Год'
                       size="sm"
                       onChange={(val) => onChangeYear(val.replace(/[_]/gi, ''))}
                       textAlignInput='center'
                       widthInput="80px"
                       mask="9999"
            />
        </Box>
    );
};

export default SalariesFilter;
