import React from 'react';
import './PopupPhoto.scss';
import Button from "../../../../components-ui/OldButton/Button";
import {Icon} from "../../../../components/Icons/Icons";
import PropTypes from "prop-types";
import SelectCheckbox from "../../../../components-ui/Inputs/SelectCheckbox/SelectCheckbox";
import Helper from 'common/helpers/main'
import moment from 'moment/moment'
import ServerCommand from 'common/server/server-command'
import Tabs from "../../../../components-ui/Navigation/Tabs/Tabs";
import DateTime from "../../../../components-ui/Inputs/DateTime/DateTime";
import _ from 'lodash'

export default class PopupPhoto extends React.Component {

    static propTypes = {
        closePopup: PropTypes.func.isRequired,
        editPhoto: PropTypes.bool.isRequired,
        allTeeth: PropTypes.object.isRequired,
        allTypes: PropTypes.object.isRequired,
        date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        name: PropTypes.string,
        comment: PropTypes.string,
        type: PropTypes.string,
        teeth: PropTypes.array,
        loadedFileUrl: PropTypes.string,
        loadedFile: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        patientId: PropTypes.number,
    };

    static defaultProps = {
        name: '',
        date: new Date(),
        comment: '',
        teeth: []
    };

    tabsSelect = [
        {name: 'в.ч.', code: 'upper'},
        {name: 'н.ч.', code: 'lower'}
    ];

    state = {
        selectedDate: this.props.selectedDate,
        codeTabs: this.props.category,
        type: (this.props.type) ? this.props.type : Object.keys(this.props.allTypes)[0],
        date: new Date(this.props.date),
        name: this.props.name,
        comment: this.props.comment,
        teeth: !!this.props.teeth ? this.props.teeth.map(tooth => ({label: tooth, value: String(tooth)})) : [],
        deletePhoto: false,
        fullScreen: false,
        snapshot: null,
        btnDisabled: true,
        tabSelectedCheckbox: this.tabsSelect[0].code
    };

    componentDidMount() {
        this.setState({snapshot: this.getSnapshot()})
    }


    render() {

        let imagePreviewUrl = this.props.loadedFileUrl;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (
                <div className="popup-photo__photo" style={{backgroundImage: "url(" + imagePreviewUrl + ")"}}/>);
        }

        return (
            <div className={(this.state.fullScreen) ? "popup-photo popup-photo--full" : "popup-photo"}>
                <div
                    className={(this.state.deletePhoto) ? "popup-photo__wrap popup-photo__wrap--disabled" : "popup-photo__wrap"}>
                    {this.props.editPhoto &&
                    <div className="popup__close-btn"
                         onClick={(this.state.deletePhoto) ? this.deleteData.bind(this) : this.updateData.bind(this)}>
                        <Icon icon="new-close"/>
                    </div>
                    }
                    <div className="popup-photo__left">
                        {this.props.editPhoto &&
                        <div className="popup-photo__full-screen"
                             onClick={() => (this.state.fullScreen) ? this.setState({fullScreen: false}) : this.setState({fullScreen: true})}>
                            <Icon icon={(this.state.fullScreen) ? 'new-small-screen' : 'new-full-screen'}/>
                        </div>
                        }
                        {$imagePreview}
                        {this.bottomBtn()}
                    </div>
                    <div className="popup-photo__right">
                        <div className="popup-photo__label">
                            <span>Тип</span>
                            <Tabs
                                variant="primary"
                                tabs={Helper.mapObj(this.props.allTypes, (name, code) => ({name, code}))}
                                onChange={(type) => this.setState({type})}
                                activeTab={this.state.type}
                            />
                        </div>
                        <div className="popup-photo__label">
                            <span>Зуб</span>
                            <SelectCheckbox placeholder="Выберите зуб"
                                            selected={this.state.teeth}
                                            options={this.props.allTeeth[this.state.tabSelectedCheckbox].map(tooth => ({label: tooth, value: String(tooth)}))}
                                            headerMenu={(
                                                <Tabs
                                                    tabs={this.tabsSelect}
                                                    onChange={(tabSelectedCheckbox) => this.setState({tabSelectedCheckbox})}
                                                    activeTab={this.state.tabSelectedCheckbox}
                                                    variant="primary"
                                                    align="center"
                                                />
                                            )}
                                            onChange={(teeth) => this.setState({teeth})}
                                            widthOption="50%"
                                            flexDirectionMenu="row"
                            />
                        </div>
                        <label className="popup-photo__label choice__date-time choice__date">
                            <span>Дата</span>
                            <DateTime selected={this.state.date}
                                      onChange={(date) => this.setState({date})}/>
                        </label>
                        <label className="popup-photo__label">
                            <span>Название <span className='text-danger'>*</span></span>
                            <input autoFocus={true} className="input" type="text" value={this.state.name}
                                   onChange={(e) => {
                                       this.setState({
                                           name: e.target.value,
                                           btnDisabled: (e.target.value <= 1)
                                       });
                                   }}/>
                        </label>
                        <label className="popup-photo__label">
                            <span>Комментарий</span>
                            <textarea className="textarea" name="comment" value={this.state.comment}
                                      onChange={(e) => this.setState({comment: e.target.value})}/>
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    bottomBtn() {
        const {params} = this.props;
        let bottomBtnAdd = (
            <div className="popup-photo__bottom">
                <div className="popup-photo__txt">
                    Фотография загружена
                </div>
                <div className="popup-photo__buttons">
                    <Button text="Сохранить" variant={(this.state.btnDisabled) ? 'disabled' : 'dark'}
                            onClick={this.saveData.bind(this)}/>
                    <Button text="Отменить" variant="dark" onClick={() => {
                        this.props.closePopup();
                        this.setState({
                            name: '',
                            comment: '',
                            teeth: []
                        })
                    }}/>
                </div>
            </div>
        );
        let openDiactocatBtn = null;
        if (!!params) {
            if (!!params.dental_analysis_client_url) {
                openDiactocatBtn = <Button text="Перейти в Диагнокат" variant="link-white" onClick={() => {
                    window.open(params.dental_analysis_client_url, "_blank")
                }}/>
            }
        }
        let bottomBtnEdit = (
            <div className="popup-photo__bottom popup-photo__bottom--right">
                <div className="popup-photo__buttons">
                    {openDiactocatBtn}
                    {/*<Button text="Скачать" variant="link-white"/>
                    <Button text="Распечатать" variant="link-white"/>*/}
                    <Button text="Удалить" variant="link-white"
                            onClick={() => (this.state.deletePhoto) ? this.setState({deletePhoto: false}) : this.setState({deletePhoto: true})}/>
                </div>
            </div>
        );
        let bottomBtnDelete = (
            <div className="popup-photo__bottom">
                <div className="popup-photo__txt">
                    Фотография удалена
                </div>
                <div className="popup-photo__buttons">
                    <Button text="Восстановить" variant="dark"
                            onClick={() => (this.state.deletePhoto) ? this.setState({deletePhoto: false}) : this.setState({deletePhoto: true})}/>
                </div>
            </div>
        );

        if (this.props.editPhoto && !this.state.deletePhoto) {
            return bottomBtnEdit
        } else if (this.state.deletePhoto && this.props.editPhoto) {
            return bottomBtnDelete
        } else {
            return bottomBtnAdd
        }

    }

    saveData() {
        this.setState({btnDisabled: true});
        const command = new ServerCommand('patient/file/create', {
            date: moment(this.state.date).format('YYYY-MM-DD HH:mm:ss'),
            name: this.state.name,
            file: this.props.loadedFile,
            type: this.state.type,
            patientId: this.props.patientId,
            comment: this.state.comment,
            teeth: this.state.teeth.map(tooth => tooth.value)
        });

        command.exec().then(() => {
            this.props.closePopup();
            this.setState({btnDisabled: false});
        });
    }

    deleteData() {
        const command = new ServerCommand('patient/file/delete', {
            primaries: [this.props.id]
        });
        command.exec().then(this.props.closePopup)
    }

    updateData() {
        if (Helper.isEqual(this.state.snapshot, this.getSnapshot())) {
            this.props.closePopup();
            return
        }
        const command = new ServerCommand('patient/file/update', {
            id: this.props.id,
            date: moment(this.state.date).format('YYYY-MM-DD HH:mm:ss'),
            name: this.state.name,
            type: this.state.type,
            comment: this.state.comment,
            teeth: this.state.teeth.map(tooth => tooth.value)
        });
        command.exec().then(this.props.closePopup)
    }

    getSnapshot() {
        return Helper.clone({
            type: this.state.type,
            date: this.state.date,
            name: this.state.name,
            comment: this.state.comment,
            teeth: this.state.teeth
        })
    }
}
