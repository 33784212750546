import React from "react";
import { Divider } from "../../../../../components-ui/DataDisplay/Divider";
import { WrapContent } from "../../../styledUI";
import Box from "../../../../../components-ui/Layout/Box";

export default function DividerPage() {
    return (
        <>
            <h1>Divider</h1>
            <WrapContent>
                <div>
                    <Box margin="10px">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque magnam nostrum officiis pariatur repudiandae. Assumenda cum cumque illum impedit itaque minima neque nisi quas quod, similique sint soluta, tempore ullam?
                    </Box>
                    <Divider />
                    <Box margin="10px">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad assumenda cumque, dolor ea eaque earum, iste itaque modi nemo neque numquam sint sunt, voluptas. Alias cumque dolorem nihil perspiciatis vero?
                    </Box>

                    <pre>{'<Divider />'}</pre>
                </div>

                <div>
                    <Box margin="10px">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque magnam nostrum officiis pariatur repudiandae. Assumenda cum cumque illum impedit itaque minima neque nisi quas quod, similique sint soluta, tempore ullam?
                    </Box>
                    <Divider color="danger" />
                    <Box margin="10px">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad assumenda cumque, dolor ea eaque earum, iste itaque modi nemo neque numquam sint sunt, voluptas. Alias cumque dolorem nihil perspiciatis vero?
                    </Box>

                    <pre>{'<Divider color="danger" />'}</pre>
                </div>

            </WrapContent>

        </>
    )
}
