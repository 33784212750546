import React, {forwardRef} from 'react';
import {WrapIcon} from '../../containers/WrapIcon';
import {IIcon} from '../../types/common';

export const ToothIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M12,15.7c2.65,0,2.38,8.3,4.72,8.3s4.86-5.66,4.86-5.66,3.54-7.5,2-14.11S13.7.75,12,.75h-.09C10.25.75,1.89-2.4.38,4.23s2,14.11,2,14.11S4.74,24,7.21,24s2.1-8.3,4.72-8.3Z"/>
        </WrapIcon>
    );
});
