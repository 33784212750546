import {useMemo, useState} from "react";
import {StateConditions} from "../interfaces/common";
import _ from "lodash";
import {PropsInitialConditions} from "../interfaces/hooks";

export default function useInitialConditions({diagnosisRelationParameters, conditions, onSubmitConditions}: PropsInitialConditions) {
    const [state, setState] = useState<StateConditions>({})

    const keyParameters = useMemo(() => (_.keyBy(diagnosisRelationParameters, 'code')), [])

    const conditionsArr = useMemo(() => {
        let res = [] as string[];
        conditions.forEach(condition => {
            res = _.concat(res, _.keys(condition.conditions));
        })
        res = _.uniq(res);
        setState(prevState => {
            res.forEach(condition => prevState[condition] = keyParameters[condition].options.default)
            return {...prevState}
        })
        return res
    }, [])

    const submitConditions = () => {
        const cloneConditions = _.cloneDeep(conditions);
        cloneConditions.forEach((condition: any) => {
            _.forEach(state, (val, key) => {
                if (condition.conditions[key] === undefined) condition.conditions[key] = val
            })
        })
        const suitableRelations = cloneConditions.filter(condition => _.isEqual(condition.conditions, state))
        onSubmitConditions(suitableRelations)
    }

    return {
        state,
        setState,
        keyParameters,
        conditionsArr,
        submitConditions
    }
}
