import * as React from "react";
import Table from "../../../../components/Table/Table";
import TreatmentPlan from "../../../TreatmentPlan/TreatmentPlan";
import RecommendedVisitDecision from "./RecommendedVisitDecision";
import TotalSum from "../../../../components-ui/TotalSum/TotalSum";
import PaymentSchedule from "../../../PaymentSchedule/PaymentSchedule";
import PlanItemQuantity from "./PlanItemQuantity";
import PlanProcedureName from "./PlanProcedureName";
import Counter from "../../../../components-ui/Inputs/Counter/Counter";
import {PTablePlanEditor} from "../types";
import {getPlanItemDeletionTip} from "../helper";
import Helper from "../../../../common/helpers/main";
import _ from 'underscore';
import {Box, BoxBody} from "../../../../components-ui/styledComponent/common/wraps";
import {useSelector} from "react-redux";
import {useMemo} from "react";
import getDoctorsTabs from "../../../../common/TreatmentPlan/DoctorsTabs";
import {UsersMap} from "../../../../types";

export default function TablePlanEditor({data, handles}: PTablePlanEditor) {
    const {
        planTitle, plan, checkItemsStatuses, selectedDoctorId, isRecommended,
        servicesGroups, additionalServicesGroups,
        planItemDisabledTip, setFirstNotDividedItemRef, stateSelectedDoctorId, recommendedVisitsDecisions,
        paymentSchedule, onChangeSchedule, visit
    } = data;
    const reduxDoctors = useSelector(state => state.common.doctors);
    const doctors = useMemo(() => {
       return _.indexBy(reduxDoctors.map((doctor: any) => {
           doctor = _.clone(doctor);
           return doctor
       }), 'id')
    }, []) as UsersMap;

    const {handleSelectDoctor, handleAddDoctor, handleDeleteDoctor, handleReplaceDoctor, handleSelectRecommendedVisitDecision} = handles;

    let resultColumns = [
        {
            title: 'Услуга',
            code: 'name',
            containsGroupTitle: true
        },
        {
            title: 'Зуб',
            code: 'target'
        },
        {
            title: 'Количество',
            code: 'quantity',
        },
        {
            title: 'К оплате',
            code: 'sum',
            type: 'price'
        },
    ];

    if (checkItemsStatuses) resultColumns.splice(2, 0, {title: 'Статус', code: 'status'});

    const createdPlanToDoctors = plan.getCreatedPlanToDoctors();
    const planItems = plan.getItems();

    const doctorsTabs = getDoctorsTabs({
        planItems, doctors,
        replaceablePerformers: plan.getReplaceablePerformers(),
        addCreatorNote: true
    });

    const isSelectedRecommendedDoctor = plan.getRecommendedDoctors(createdPlanToDoctors).includes(selectedDoctorId);

    return (
        <div className="treatment-process-table-plan-editor">
            <Box>
                <BoxBody nonBorderBottom>
                    <p className="table__title">{planTitle}</p>
                    <div className="card">
                        <div className="treatment-plan__result-table">
                            <Table columns={resultColumns}
                                   data={plan.getItems()}
                                   selectMode='default'
                                   prepareDataItem={(params: any) => preparePlanItem(params, data)}
                                   prepareDataNestedItem={(params: any) => preparePlanItemProcedure(params, data.plan)}
                                   selectedNestedRows={getSelectedPlanProcedures(data.plan)}
                                   total={TreatmentPlan.getDoctorTotal(planItems, selectedDoctorId, isRecommended)}

                                   groups={servicesGroups}
                                   highlightedGroupsIds={additionalServicesGroups}
                                   isEditable={true}

                                   tabs={doctorsTabs}
                                   activeTab={selectedDoctorId}
                                   onTabChange={handleSelectDoctor}
                                   onTabAdd={handleAddDoctor}
                                   onTabDelete={handleDeleteDoctor}
                                   onTabReplace={handleReplaceDoctor}

                                   getRowCode={TreatmentPlan.getPlanItemCode}
                                   getRowGroupId={(planItem: any) => planItem.serviceGroupId}
                                   getDisabledTip={planItemDisabledTip}

                                   firstRefs={{'not_divided_quantity': setFirstNotDividedItemRef}}
                            />
                        </div>

                        {isSelectedRecommendedDoctor && isRecommended && (
                            <RecommendedVisitDecision selectedDoctorId={stateSelectedDoctorId}
                                                      recommendedVisitsDecisions={recommendedVisitsDecisions}
                                                      handleSelectRecommendedVisitDecision={handleSelectRecommendedVisitDecision}
                                                      visit={visit}
                            />
                        )}

                    </div>

                    <TotalSum sums={TreatmentPlan.getTotalSums(planItems, isRecommended)}/>
                    {/*{this.renderPriceChangeNotice()}*/}
                    {!isRecommended && <PaymentSchedule value={paymentSchedule} onChange={onChangeSchedule}/>}
                </BoxBody>
            </Box>
        </div>
    )
}

const preparePlanItem = ({item: planItem, isFocused}: any, props: any) => {
    const {plan, quantityDivideAttractTime, directions, checkItemsStatuses, planItemStatuses, services, directionsMap} = props;
    const clonePlanItem = Helper.clone(planItem);
    const itemCode = TreatmentPlan.getPlanItemCode(planItem);
    const actualDirections = plan.getItemActualDirections(itemCode);

    if (!checkItemsStatuses || !planItem.isBlocked) {
        planItem.onDelete = (itemCode: any) => plan.deleteItem(itemCode).save();
        const requiredForReasons = plan.getItemRequiredFor(itemCode);
        if (requiredForReasons) {
            planItem.isDeletionEnabled = false;
            planItem.deletionTip = getPlanItemDeletionTip(requiredForReasons, directionsMap, services)
        }
    }

    planItem.rowCode = itemCode;
    clonePlanItem.rowCode = itemCode;
    planItem.isPale = planItem.status === 'DONE';
    planItem.status = planItemStatuses[planItem.status];
    planItem.target = Array.isArray(planItem.target) ? planItem.target.join(', ') : planItem.target;
    planItem.sum = TreatmentPlan.getItemSum(planItem);
    planItem.name = plan.getItemName(itemCode);
    planItem.tab = planItem.performerId;

    const needDivideQuantity = plan.needDivideItemQuantity(itemCode, actualDirections);

    if (needDivideQuantity || isFocused(itemCode)) {
        planItem.quantity = <PlanItemQuantity planItem={clonePlanItem}
                                              actualDirections={actualDirections}
                                              isItemEnabled={plan.isItemEnabled(planItem)}
                                              plan={plan}
                                              quantityDivideAttractTime={quantityDivideAttractTime}
                                              directions={directions}
                                              key={clonePlanItem.serviceName + 'quantity'}/>;
    }

    if (needDivideQuantity) planItem.refsGroups = ['not_divided_quantity'];
    if (!planItem.isAdditional) planItem.nestedItems = planItem.procedures;
};


const preparePlanItemProcedure = ({item: procedure, parentItem: planItem, parentItemCode: planItemCode, isFocused}: any, plan: any) => {
    const cloneProcedure = Helper.clone(procedure);
    procedure.rowCode = TreatmentPlan.getProcedureCode(procedure, planItem);
    procedure.target = Array.isArray(procedure.target) ? procedure.target.join(', ') : procedure.target;
    procedure.sum = procedure.price * procedure.quantity;
    procedure.name = <PlanProcedureName plan={plan} planItem={planItem} procedure={cloneProcedure}/>;
    procedure.status = procedure.isDone ? 'выполнена' : 'не выполнена';
    procedure.isPale = procedure.isDone;

    if (isFocused(procedure.rowCode) && !planItem.isBlocked) {
        procedure.quantity = <Counter value={cloneProcedure.quantity}
                                      onChange={(quantity: any) => plan.setProcedureQuantity(planItemCode, procedure.rowCode, quantity).save()}
                                      disableInput={cloneProcedure.isDone}/>
    }
};

const getSelectedPlanProcedures = (plan: any) => {
    const selectedProcedures = plan.getItems().map((planItem: any) => {
        return planItem.procedures.filter((procedure: any) => procedure.isActive).map((procedure: any) => TreatmentPlan.getProcedureCode(procedure, planItem));
    });

    return selectedProcedures.flat();
};
