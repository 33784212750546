import React, {Dispatch, SetStateAction, useMemo} from 'react';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {notTrashed} from '../../../../common/helpers/general-helper';
import {User} from '../../../../types';
import Box from '../../../../components-ui/Layout/Box';
import Select from '../../../../components-ui/Inputs/Select/Select';
import DateRange from '../../../../components-ui/Inputs/DateRange';
import {GetSalariesTimesheetResponce} from '../../types';

interface Props {
    data: GetSalariesTimesheetResponce
    startDate: Date,
    setStartDate: Dispatch<SetStateAction<Date>>
    endDate: Date,
    setEndDate: Dispatch<SetStateAction<Date>>
    optionsBranches: OptionSelect[]
    selectedBranch: OptionSelect
    setSelectedBranch: Dispatch<SetStateAction<OptionSelect>>
    employee: OptionSelect
    setEmployee: Dispatch<SetStateAction<OptionSelect>>
}

const SalariesTimesheetFilter = ({
                                     data,
                                     startDate,
                                     setStartDate,
                                     endDate,
                                     setEndDate,
                                     optionsBranches,
                                     selectedBranch,
                                     setSelectedBranch,
                                     employee,
                                     setEmployee,
                                 }: Props) => {

    const users = useSelector(state => state.common.users);

    const currentUser = useSelector(state => state.user);

    const onChangeSelectedBranch = (selectedValue: OptionSelect) => {
        setSelectedBranch(selectedValue);
        if (employee.value !== 0) {
            if (!users[employee.value].branches.includes(selectedValue.value)) {
                setEmployee({value: 0, label: 'Все сотрудники'});

            }
        }
    };

    const optionsFiltredCreator = useMemo(() => {
        return _
            .chain(users)
            .filter(notTrashed)
            .filter((user: User) => user.branches.includes(selectedBranch.value))
            .reject((user: User) => data.excludeEmployeesRoles.includes(user.role))
            .map((user: User) => {
                return {value: user.id, label: user.fio};
            })
            .sortBy(user => user.label)
            .unshift({value: 0, label: 'Все сотрудники'})
            .value();
    }, [selectedBranch, users, data]);

    return (
        <Box display='flex' gap='0 16px' margin='22px 0'>

            {optionsBranches.length > 1 &&
                <Select sizeSelect='sm' options={optionsBranches} value={selectedBranch}
                        onChange={onChangeSelectedBranch} />
            }

            <Select sizeSelect='sm' options={optionsFiltredCreator} value={employee} onChange={setEmployee} />

            {currentUser.canViewAllXrayPictures &&
                <DateRange startDate={startDate}
                           endDate={endDate}
                           setStartDate={setStartDate}
                           setEndDate={setEndDate}
                           maxDate={new Date()} />
            }

        </Box>
    );
};

export default SalariesTimesheetFilter;
