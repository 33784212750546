import _ from "lodash";
import {dateStringFilled, phoneFilled, stringFilled} from "../../../common/helpers/validation";
import { PropsUseNewValidateForm } from "../types/hooks";

export default function useNewValidateForm({fields}: PropsUseNewValidateForm) {

    return (form: any) => {
        const result = {} as any;
        _.keys(form).filter(fieldName => !!fields[fieldName] && fields[fieldName].required).forEach(fieldName => {
            switch (fields[fieldName].type) {
                case "phone":
                    result[fieldName] = !phoneFilled(form[fieldName])
                    break;
                case "string":
                    result[fieldName] = !stringFilled(form[fieldName])
                    break;
                case "date":
                    result[fieldName] = !dateStringFilled(form[fieldName])
                    break;
                case "enum":
                case "boolean":
                    result[fieldName] = form[fieldName] === null
                    break;
                default:
                    result[fieldName] = false
                    break;
            }
        })
        return result
    }
}
