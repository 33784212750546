import React, {forwardRef} from 'react';
import {SLabel, SLabelText, SRequiredText} from "./style";
import {PropsLabel} from "./types";

export const Label = forwardRef((
    {
        align = "left",
        widthLabelWrap,
        minWidthLabel,
        colorLabel = "text",
        labelText,
        isRequired = false,
        children,
        marginLabel,
        marginWrap,
        cursor = 'default',
        onClick,
        onMouseLeave,
        onMouseEnter,
        onMouseMove,
        widthLabel,
        paddingLabel,
        as = 'div'
    }: PropsLabel, ref: any) => {
    return (
        <SLabel align={align}
                widthLabelWrap={widthLabelWrap}
                marginWrap={marginWrap}
                ref={ref}
                cursor={cursor}
                as={as}
                onClick={(e: any) => {
                    if (!!onClick) onClick(e);
                }}
                onMouseLeave={(e: any) => {
                    if (!!onMouseLeave) onMouseLeave(e)
                }}
                onMouseEnter={(e: any) => {
                    if (!!onMouseEnter) onMouseEnter(e)
                }}
                onMouseMove={(e: any) => {
                    if (!!onMouseMove) onMouseMove(e)
                }}
        >
            <SLabelText minWidthLabel={minWidthLabel} align={align} colorLabel={colorLabel} marginLabel={marginLabel}
                        widthLabel={widthLabel} paddingLabel={paddingLabel}>
                {labelText} {isRequired && <SRequiredText children="*"/>}
            </SLabelText>
            {children}
        </SLabel>
    )
});
