import React from 'react';
import PaymentFilter from "../../components/PaymentFilter";
import TablePayments from "../../components/TablePayments";
import AddPayment from "../../components/AddPayment";
import SuccessChecking from "../../components/SuccessChecking";
import Paper from "../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import usePayments from "../../hooks/usePayments";

const Payments = () => {

    const {
        optionsBranches,
        loader,
        selectedBranch,
        setSelectedBranch,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        params,
        paymentsResponse,
        refreshDataFromServer,
        needConfirmPayment,
        handlePaymentChecked,
        filters,
        setFilters
    } = usePayments()

    return (
        <>
            <PaymentFilter
                filters={filters}
                setFilters={setFilters}
                optionsBranches={optionsBranches}
                selectedBranch={selectedBranch}
                setSelectedBranches={setSelectedBranch}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
            <Paper padding="0" margin="28px 0" borderRadius="8px">
                <TablePayments
                    filters={filters}
                    selectedBranch={selectedBranch}
                    data={paymentsResponse}
                    refreshDataFromServer={refreshDataFromServer}
                    loader={loader}
                />
                <AddPayment refreshDataFromServer={refreshDataFromServer}/>
                {needConfirmPayment && <SuccessChecking handlePaymentChecked={handlePaymentChecked}  branchId={params.branchId}/>}
            </Paper>
        </>
    );
};

export default Payments;
