import Helper from "./main";

/** Переводит время в минуты
 * @param {string} time
 * @return {number}*/
export const splitTimes = (time) => (+time.split(':')[0] * 60) + +time.split(':')[1];


/** Преобразует из минут в объект часов и минут
 * @param {number} time
 * @return {{hours: *, minute: *}}*/
export const calcTimes = (time) => {
    let hours = String(Math.floor(time / 60));
    if (hours.length === 1) hours = '0' + hours;
    let minute = time % 60;
    if (minute === 0) {
        minute = '00';
    } else {
        minute = '30'
    }
    return {hours, minute}
};

/** Подготавливает время работы клиники с интервалом в 30 минут
 * @param {Object} clinicWorkingTime
 * @return {Array}*/
const preparedClinicWorkingTime = (clinicWorkingTime) => {
    const timeStart = splitTimes(clinicWorkingTime.timeStart);
    const timeEnd = splitTimes(clinicWorkingTime.timeEnd);
    let newTimeStart = timeStart;
    let newTimeEnd = timeStart + 30;
    const collRow = Math.floor((timeEnd - timeStart) / 30);
    const calcTimeStart = calcTimes(newTimeStart);
    const timeInterval = [calcTimeStart.hours + ':' + calcTimeStart.minute];
    for (let i = 0; i < collRow; i++) {
        const calcTimeEnd = calcTimes(newTimeEnd);
        timeInterval.push(calcTimeEnd.hours + ':' + calcTimeEnd.minute);
        newTimeEnd += 30;
    }
    return timeInterval
};

/** Объеденяет время со всех шаблонов и время работы клиники
 * @param {Object} template
 * @param {Object} clinicWorkingTime
 * @return {Array}*/
export const joinTimeInterval = (template, clinicWorkingTime) => {
    let timeInterval = [];
    Object.values(template).forEach(week => {
        Object.values(week).forEach(day => {
            Object.values(day).forEach(chair => {
                chair.forEach(item => {
                    timeInterval.push(item.timeStart);
                    timeInterval.push(item.timeEnd);
                })
            })
        })
    });
    timeInterval = timeInterval.concat(preparedClinicWorkingTime(clinicWorkingTime));
    timeInterval = timeInterval
        .filter((item, pos) => timeInterval.indexOf(item) === pos)
        .sort();
    return timeInterval;
};

/** Подготавливает колонку к соответствующему виду
 * @param {Object} objArguments
 * @return {Array}*/
export function prepareColumn (objArguments) {

    const {timeStartInterval, timeEndInterval, data} = objArguments;

    const dataObj = Helper.Array.toObject(data, 'timeStart');
    const cells = [];


    const resObj = {
        doctorTimeEnd: null,
        doctorId: null,
        index: null,
        prevDoctorId: null
    };

    timeStartInterval.forEach((time, index) => {
        let cellData = {
            timeStart: time,
            timeEndInterval: timeEndInterval[index],
            doctorId: 0,
        };
        resultCellData(time, index, resObj, cellData, dataObj, timeEndInterval);

        cells.push(cellData);
    });

    resObj.doctorTimeEnd = null;
    resObj.doctorId = null;
    resObj.index = null;

    return prepareCells(cells)

}


/**
 * @param {string} time
 * @param {number} index
 * @param {Object} resObj
 * @param {Object} cellData
 * @param {Object} dataObj
 * @param {string} timeEndInterval*/
const resultCellData = (time, index, resObj, cellData, dataObj, timeEndInterval) => {


    if (dataObj[time] !== undefined) {

        cellData.doctorId = dataObj[time].doctorId;
        cellData.doctorTime = {
            timeStart: dataObj[time].timeStart,
            timeEnd: dataObj[time].timeEnd
        };
        const indexTimeStart = timeEndInterval.indexOf(cellData.doctorTime.timeStart);
        const indexTimeEnd = timeEndInterval.indexOf(cellData.doctorTime.timeEnd);
        cellData.collCellDoctor = indexTimeEnd - indexTimeStart;
        resObj.doctorTimeEnd = dataObj[time].timeEnd;
        resObj.doctorId = dataObj[time].doctorId;
        if (dataObj[time].conflictDoctorsId !== undefined) {
            cellData.conflictDoctorsId = dataObj[time].conflictDoctorsId;
        }
    }

    if (resObj.doctorTimeEnd !== null) {
        if (time < resObj.doctorTimeEnd) {
            cellData.doctorId = resObj.doctorId;
        }
    }

    if (cellData.doctorId === 0) {
        resObj.doctorTimeEnd = null;
        resObj.doctorId = null;
    }

};

/** Подготовка ячеек
 * @param {Array} cells*/
const prepareCells = (cells) => {

    const calcObj = {
        doctorId: null,
        maxTop: 0,
        maxBottom: 0,
        idxCells: null,
        idxEndDoctor: 0,
        prevDoctor: false
    };

    cells.forEach((cell, index) => {
        maxBottomCell(cell, index, calcObj, cells);
        calcIdxCells(cell, index, calcObj);
        calcIdxEndDoctor(cell, index, calcObj);
        calcDoctorId(cell, index, calcObj);
        maxTopCell(cell, index, calcObj);
    });

    return cells;
};


/** Высчитывает максимальное количество свободных ячеек над ячейкой
 * @param {Object} cell
 * @param {number} index
 * @param {Object} calcObj*/
const maxTopCell = (cell, index, calcObj) => {
    if (cell.doctorId === 0) {
        cell.maxTop = calcObj.maxTop;
        calcObj.maxTop += 1;
    } else {
        if (calcObj.maxTop !== 0) {
            cell.maxTop = calcObj.maxTop;
        } else {
            cell.maxTop = 0;
        }
        calcObj.maxTop = 0;
    }
};

/** Высчитывает максимальное количество свободных ячеек под ячейкой
 * @param {Object} cell
 * @param {number} index
 * @param {Object} calcObj
 * @param {Array} cells*/
const maxBottomCell = (cell, index, calcObj, cells) => {
    if (cell.doctorId === 0) {
        calcObj.prevDoctor = true;
        if (calcObj.doctorId !== null) calcObj.maxBottom += 1;
        if (index === cells.length - 1) {
            if (calcObj.doctorId !== null) {
                cells[calcObj.idxCells].maxBottom = calcObj.maxTop + 1;
                for (let i = 0; i <= index - calcObj.idxEndDoctor; i++) {
                    cells[index - i].maxBottom = i;
                }
            } else {
                for (let i = 0; i <= index; i++) {
                    cells[index - i].maxBottom = i;
                }
            }
        }

    } else {
        cell.maxBottom = 0;
        if (calcObj.maxBottom !== 0) {
            cells[calcObj.idxCells].maxBottom = calcObj.maxBottom;
            calcObj.maxBottom = 0;
        }
        if (calcObj.maxTop !== 0) {
            for (let i = 0; i < calcObj.maxTop; i++) {
               cells[index - (i + 1)].maxBottom = i;
            }
        }
        calcObj.prevDoctor = false
    }

};

/** Находит индекс нужной ячейки
 * @param {Object} cell
 * @param {number} index
 * @param {Object} calcObj*/
const calcIdxCells = (cell, index, calcObj) => {
    if (cell.doctorId !== 0 && cell.doctorId !== calcObj.doctorId) {
        calcObj.idxCells = index;
    }
};

/** Находит индекс доктора в конце колонки
 * @param {Object} cell
 * @param {number} index
 * @param {Object} calcObj*/
const calcIdxEndDoctor = (cell, index, calcObj) => {
    if (cell.doctorId !== 0 ) calcObj.idxEndDoctor = index;
};

/** Находит id доктора
 * @param {Object} cell
 * @param {number} index
 * @param {Object} calcObj*/
const calcDoctorId = (cell, index, calcObj) => {
    if (cell.doctorId !== 0 ) calcObj.doctorId = cell.doctorId;
};

/* Сортировка по строке */
export const sortName = (a, b) => {
    const nameA = a.toLowerCase(), nameB = b.toLowerCase();
    if (nameA < nameB) //сортируем строки по возрастанию
        return -1;
    if (nameA > nameB)
        return 1;
    return 0 // Никакой сортировки
};
