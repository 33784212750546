import React from 'react';
import {commonLabelOption, commonTextFieldOption} from "../data";
import TextField from "../../../../../../../../components-ui/Inputs/TextField/TextField";
import {Label} from "../../../../../../../../components-ui/DataDisplay/Label";

interface Props {
    comment: string
    handleComment: (val: string) => void
}

const ModalComment = ({comment, handleComment}: Props) => {
    return (
        <Label {...commonLabelOption}
               labelText="Комментарий"
               align="leftTop"
               marginLabel="8px 10px 0 0">
            <TextField
                {...commonTextFieldOption}
                value={comment}
                onChange={handleComment}
                isTextArea={true}
            />
        </Label>
    );
};

export default ModalComment;