import React, {FC} from 'react';
import {OnChangeSelectedScript, TaskCallCenter} from "../../../types/common";
import {ScriptButton} from "../../../components/Buttons";
import { STaskButtons } from '../../../../../containers/Tasks/style';

interface PropsTaskButtons {
    task: TaskCallCenter
    onChangeSelectedScript: OnChangeSelectedScript
}

const TaskButtons: FC<PropsTaskButtons> = ({children, task, onChangeSelectedScript}) => {
    return (
        <STaskButtons margin="12px 0 16px">
            {children}
            <ScriptButton task={task} onChangeSelectedScript={onChangeSelectedScript}/>
        </STaskButtons>
    );
};

export default TaskButtons;
