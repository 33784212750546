import {useEffect, useState} from "react";
import ServerCommand from "../server/server-command";

interface Handset {
    id: number
    phoneNumber: string
    name: string
    userId: number
    branches: number[] | null
    currentUserId: number | null
}


export default function useHandsets() {
    const [handsets, setHandsets] = useState<Handset[]>([]);

    useEffect(() => {
        ServerCommand.get('call-center/handset/list').then(({handsets}) => setHandsets(handsets));
    }, [])

    return handsets;
}
