import * as types from './actionConstants';

export const loadVisits = (visits: any) => ({
    type: types.LOAD_VISITS,
    payload: visits
});

export const addVisit = (visit: any) => ({
    type: types.ADD_VISIT,
    payload: visit
});

export const removeVisit = (visitID: number) => ({
    type: types.REMOVE_VISIT,
    payload: visitID
});

export const startVisit = (visitID: number) => ({
    type: types.START_VISIT,
    payload: visitID
});
