import {useOptionsSelectHandsets} from "../../../../common/hooks/useOptionsSelectHandsets";
import {useEffect, useState} from "react";
import ServerCommand from "../../../../common/server/server-command";
import {useModal} from "../../../../containers/DialogsManager/containers/Modal/Modal";
import useHandsets from "../../../../common/hooks/useHandsets";
import {useSelector} from "react-redux";
import {User} from "../../../../types";

interface Props {
    userId: number
}

const useSetHandsetUser = ({userId} : Props) => {
    const handsets = useHandsets()

    const currentUser = useSelector(state => state.user)

    const users = useSelector(state => state.common.users)

    const handsetsOptions = useOptionsSelectHandsets().filter(handset => currentUser.handsets.includes(handset.value))

    if (handsets
        .filter(handset => currentUser.handsets.includes(handset.id))
        .filter(handset => handset.currentUserId != null)
        .length !== 0) {

        handsetsOptions.push({
            value: null,
            label: 'Без трубки'
        })
    }



    const [loader, setLoader] = useState(false)
    const {closeModal} = useModal()

    const [state, setState] = useState({
        handsetId: undefined,
        userId: userId
    })

    const [warning, setWarning] = useState('')

    useEffect(() => {
        //если пользователь уже назначен на трубку то выводим предупреждение
        const handset = handsets.find(handset => handset.id === state.handsetId)
        if (handset && handset.currentUserId) {
            setWarning('Пользователь '+users[handset.currentUserId]?.fio+' уже назначен на трубку')
        } else {
            setWarning('')
        }

    }, [state.handsetId])

    const handleSubmit = () => {
        const command = new ServerCommand('handset/set-user', {
            id: state.handsetId,
            userId: state.userId
        })
        setLoader(true)
        command.exec().then(() => {
            setLoader(false)
            closeModal()
        })
    }

    return {loader, warning, state, setState, handsetsOptions, handleSubmit}
}

export default useSetHandsetUser
