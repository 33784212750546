import React, {Dispatch, SetStateAction} from 'react';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import Select from '../../../../components-ui/Inputs/Select/Select';
import Box from '../../../../components-ui/Layout/Box';
import DateRange from '../../../../components-ui/Inputs/DateRange';

interface Props {
    startDate: Date,
    setStartDate: Dispatch<SetStateAction<Date>>
    endDate: Date,
    setEndDate: Dispatch<SetStateAction<Date>>
    optionsBranches: OptionSelect[]
    selectedBranch: OptionSelect
    doctor: OptionSelect
    setDoctor: Dispatch<SetStateAction<OptionSelect>>
    technic: OptionSelect
    setTechnic: Dispatch<SetStateAction<OptionSelect>>
    laboratory: OptionSelect
    setLaboratory: Dispatch<SetStateAction<OptionSelect>>
    optionsFilteredTechnic: OptionSelect[]
    optionsFilteredLaboratories: OptionSelect[]
    optionsFilteredDoctors: OptionSelect[]
    onChangeSelectedBranch: (selectedValue: OptionSelect) => void
}

const LaboratoryDoneServicesFilter = ({
                                          startDate,
                                          setStartDate,
                                          endDate,
                                          setEndDate,
                                          optionsBranches,
                                          selectedBranch,
                                          doctor,
                                          setDoctor,
                                          technic,
                                          setTechnic,
                                          laboratory,
                                          setLaboratory,
                                          optionsFilteredTechnic,
                                          optionsFilteredLaboratories,
                                          optionsFilteredDoctors,
                                          onChangeSelectedBranch,
                                      }: Props) => {

    return (
        <Box display='flex' gap='0 16px' margin='22px 0'>

            {optionsBranches.length > 1 &&
                <Select sizeSelect='sm' options={optionsBranches} value={selectedBranch}
                        onChange={onChangeSelectedBranch} />
            }
            <DateRange startDate={startDate}
                       endDate={endDate}
                       setStartDate={setStartDate}
                       setEndDate={setEndDate}
                       maxDate={new Date()} />

            <Select sizeSelect='sm'
                    variant='colored'
                    label="Врач: "
                    options={optionsFilteredDoctors}
                    value={doctor}
                    onChange={setDoctor} />

            {optionsFilteredTechnic.length > 1 &&
                <Select sizeSelect='sm'
                     label="Техник: "
                     options={optionsFilteredTechnic}
                     value={technic}
                     onChange={setTechnic} />
            }
            {
                optionsFilteredLaboratories.length > 1 &&
                <Select sizeSelect='sm'
                        options={optionsFilteredLaboratories}
                        value={laboratory}
                        onChange={setLaboratory} />

            }
        </Box>
    );
};

export default LaboratoryDoneServicesFilter;
