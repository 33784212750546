import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const PlusBoldIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M14.4,9.6h8.4A1.2,1.2,0,0,1,24,10.8v2.4a1.2,1.2,0,0,1-1.2,1.2H14.4v8.4A1.2,1.2,0,0,1,13.2,24H10.8a1.2,1.2,0,0,1-1.2-1.2V14.4H1.2A1.2,1.2,0,0,1,0,13.2V10.8A1.2,1.2,0,0,1,1.2,9.6H9.6V1.2A1.2,1.2,0,0,1,10.8,0h2.4a1.2,1.2,0,0,1,1.2,1.2Z"/>
        </WrapIcon>
    );
});
