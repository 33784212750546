import React, {useContext, useState} from "react";
import {SInitialSurvey, Snapshot, Values} from "./types";
import useValues, {HandlersValues} from "./helpers/useValues";
import useLoadData from "./helpers/useLoadData";
import useHelper, {SHelperValues} from "./helpers/useHelper";
import {TemplatesItem} from "../../common/types/templates";
import {TeethMap, Tooth} from "../../common/types/teethmap";
import Helper from "../../common/helpers/main";

export interface ValueProvider {

    state: SInitialSurvey,
    handlersHelper: any,
    valueHelper: SHelperValues,
    values: Values,
    handlersValues: HandlersValues,
    setSelectedFieldName: ((fieldName: string) => void) | any,
    selectedFieldName: string,
    openModalParameters: boolean,
    closeModalParameters: () => void,
    defineText: any,
    addFromTemplate: (template: TemplatesItem, isDefine: boolean) => void,
    onChangeTeethMap: (teethMap: TeethMap) => void

}

const InitialSurveyContext = React.createContext<ValueProvider | any>({});

export const useInitialSurvey = () => {
    return useContext(InitialSurveyContext)
}

interface InitialSurveyProvider {
    children: any,
    dateCreate?: any,
    loadData?: any,
    getLoadCommandData: any,
    sendInitialSurvey: any
}

export const InitialSurveyProvider = ({children, dateCreate, loadData, getLoadCommandData, sendInitialSurvey}: InitialSurveyProvider) => {
    const [state, setState] = useState<SInitialSurvey>({
        teethMap: null, // зубная карта
        fields: {}, // поля ввода
        templatesList: null, // список шаблонов для первичного осмотра
        templatesGroupsList: null, // список групп шаблонов
    });

    const [selectedFieldName, setSelectedFieldName] = useState('diagnosis'); // Имя поля которое находиться в фокусе.

    const [snapshot, setSnapshot] = useState<Snapshot | {}>({}); // снимок первичного осмотра, необоходим для сброса редактирования.

    const [{values, defineText, openModalParameters}, handlersValues] = useValues(snapshot, setState, state);
    const {addFromTemplate, closeModalParameters, setValues} = handlersValues;

    /*Загружает данные с сервера, делает snapshot*/
    useLoadData({dateCreate, loadData, getLoadCommandData}, {setState, setSnapshot, setValues});

    const [valueHelper, handlersHelper] = useHelper({
        state,
        values,
        snapshot,
        setSnapshot,
        setState,
        sendInitialSurvey
    });

    const valueProvider = {
        state,
        handlersHelper,
        valueHelper,
        values,
        handlersValues,
        setSelectedFieldName,
        selectedFieldName,
        openModalParameters,
        closeModalParameters,
        defineText,
        addFromTemplate,
        onChangeTeethMap: (teethMap: TeethMap) => setState(prevState => ({...prevState, teethMap}))
    }

    return (
        <InitialSurveyContext.Provider value={valueProvider}>
            {children}
        </InitialSurveyContext.Provider>
    )
}

