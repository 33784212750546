import styled from "styled-components";
import Helper from "../../../../common/helpers/main";
import {ITheme} from "../../../../common/Theme/Theme";

export const SWrapChangeConfig = styled.div.attrs({className: 'SWrapChangeConfig'})`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    border-radius: 4px;
    background: ${props => `rgba(${Helper.Color.hexToRgb(props.theme.colors.darkBlue)}, 0.65)`};;
`

export const STextChangeConfig = styled.div.attrs({className: 'STextChangeConfig'})`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    margin: 5px 0 15px;
    min-width: 445px;
`

export const SFooterChangeConfig = styled.div.attrs({className: 'SFooterChangeConfig'})`
    display: flex;
    align-items: center;
    justify-content: space-between;
    .checkbox-txt {
        font-size: 14px;
    }
`

interface PStatusText {
    theme?: ITheme,
    status: string
}

const colorStatusText = (props: PStatusText) => {
    const {theme, status} = props;
    switch (status) {
        case "CURED":
            return theme?.colors.purple
        case "SICK":
            return theme?.colors.danger
        case "MISSING":
            return theme?.colors.danger
        case "HEALTHY":
        default:
            return theme?.colors.text
    }
}

export const SStatusText = styled.span.attrs({className: 'SStatusText'})<PStatusText>`
    color: ${colorStatusText};
`
