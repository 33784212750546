import styled from "styled-components";

export const WrapTableRecommendedServices = styled.div.attrs({className: 'WrapTableRecommendedServices'})`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-radius: 4px;
    border: solid 1px #e2e6ed;
`

export const HeadTable = styled.div.attrs({className: 'HeadTable'})`
    display: flex;
    border-bottom: 1px solid #e2e6ed;
    width: 100%;
`

export const HeadGroupTable = styled.div.attrs({className: 'HeadGroupTable'})`
    width: calc(100% - 300px);
    padding-left: 38px;
`

export const HeadDoctorTable = styled.div.attrs({className: 'HeadGroupTable'})`
    width: 300px;
`

export const HeadText = styled.div.attrs({className: 'HeadText'})`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${props => props.theme.colors.text};
    padding: 16px 0 16px 18px;
`

export const ListTable = styled.div.attrs({className: 'ListTable'})<any>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: ${props => props.isRecommended ? 0 : '50px'};
`

export const Buttons = styled.div.attrs({className: 'Buttons'})`
    margin-top: 28px;
    & > span {
        margin-right: 24px;
        position: relative;
        .prompt {
            position: absolute;
            top: 46px;
            max-width: 410px;
            text-align: center;
            z-index: 11;
            left: 50%;
            font-size: 13px;
            transform: translateX(-50%);
        }
        .prompt__triangle {
            bottom: auto;
            top: -8px;
            transform: translateX(-50%) rotate(180deg);
        }
    }
    button {
        min-width: 180px;
    }
`


