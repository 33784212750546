import React from "react";
import {WrapContent} from "../../../styledUI";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";

export default function TypographyPage() {
    return (
        <>
            <h1>Typography</h1>
            <WrapContent>
                <h3>Из styled-components</h3>
            </WrapContent>
        </>
    )
}
