import {useState} from "react";
import {StateAverageCheck} from "../types";
import useHandlersAverageCheck from "./useHandlersAverageCheck";

const useAverageCheck = () => {
    const [state, setState] = useState<StateAverageCheck>({
        currentTab: 'directions',
        hoverIndex: -1
    })

    return {
        state,
        handlers: useHandlersAverageCheck({setState})
    }
}

export default useAverageCheck
