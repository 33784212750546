import React from 'react';
import {ClinicLoadingReport} from "../../../types";
import useData from "./hooks/useData";
import NoReport from "../../../components/NoReport";
import ContentWidgetWrap from '../../../components/ContentWidgetWrap';
import Legend from "./components/Legend";
import Helper from "../../../../../common/helpers/main";
import ChartLine from "../../../../../components-ui/Charts/ChartLine";
import {namesClinicLoading, colorsClinicLoading} from "./data/data";
import useFormatXDate from "../../../hooks/useFormatXDate";

interface Props {
    report: ClinicLoadingReport
    startDate: string
    endDate: string
}

const ClinicLoading = ({report, startDate, endDate}: Props) => {
    const data = useData({report, startDate, endDate})
    const formatXDate = useFormatXDate({startDate})
    if (!!data.length) {
        return (
            <ContentWidgetWrap>
                <Legend/>
                <ChartLine
                    data={data}
                    colors={colorsClinicLoading}
                    height={245}
                    width="100%"
                    formatterCode={formatXDate}
                    formatterCodeTooltip={code => Helper.Date.formatDate(code, 'LL')}
                    formatterValue={value => Helper.String.numberFormat(value, 0)}
                    formatterName={name => namesClinicLoading[name]}
                />
            </ContentWidgetWrap>
        );
    } else {
        return <NoReport/>
    }

};

export default ClinicLoading;
