import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const BasketIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 22.37 24"} ref={ref}>
            <polygon points="15.76 8.28 13.88 8.21 13.47 19.33 15.35 19.4 15.76 8.28"/>
            <rect x="10.25" y="8.24" width="1.88" height="11.12"/>
            <polygon points="8.9 19.33 8.49 8.21 6.61 8.27 7.02 19.4 8.9 19.33"/>
            <path d="M0,3.6V5.48H2L3.51,23.14a1,1,0,0,0,.94.86H17.89a1,1,0,0,0,.94-.86L20.38,5.48h2V3.6ZM17,22.12H5.31L3.85,5.48H18.49Z"/>
            <path d="M14.26,0H8.11A1.56,1.56,0,0,0,6.55,1.57v3H8.43V1.88h5.51V4.54h1.88v-3A1.56,1.56,0,0,0,14.26,0Z"/>
        </WrapIcon>
    );
});
