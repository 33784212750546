import React from "react";
import Box from "../../components-ui/Wraps/old/Box";
import {Alert} from "../../components-ui/DataDisplay/Alert";
import Modal from "../DialogsManager/containers/Modal/Modal";
import ParametersContent from "../Parameters/ParametersContent";
import {customStyleModalParameters} from "../DialogsManager/style/modal";
import {PInitialSurvey} from "./types";
import ContentInitialSurvey from "./elemenst/ContentInitialSurvey";
import {useInitialSurvey, ValueProvider} from "./InitialSurveyContext";
import {DentalRecords} from "../../components/DentalRecords/DentalRecords";
import {INITIAL_SURVEY} from "../../common/constants/DentalRecords";


export default function InitialSurvey({visitId, renderFooter, editField, viewAlert = true, cardNumber}: PInitialSurvey) {
    const {state, selectedFieldName, openModalParameters, closeModalParameters, defineText, addFromTemplate, onChangeTeethMap, handlersHelper} = useInitialSurvey() as ValueProvider;

    const {renderRefDentalRecords} = handlersHelper;

    if (state.teethMap === null) return null;

    const fields = state.fields as any;

    return (
        <>
            {viewAlert && <Alert children="Отметьте проблемные зубы и укажите общее состояниее полости рта"/>}
            <DentalRecords teethMap={state.teethMap}
                           parentComponentName={INITIAL_SURVEY}
                           onChangeTeethMap={onChangeTeethMap}
                           visitId={visitId}
                           cardNumber={cardNumber}
                           editField={editField}
                           renderRefDentalRecords={renderRefDentalRecords}/>
            <Box>
                <ContentInitialSurvey editField={editField} renderFooter={renderFooter}/>
            </Box>
            <Modal isOpen={openModalParameters}
                   isIconClose={false}
                   closeModal={closeModalParameters}
                   customStyles={customStyleModalParameters()}>
                <ParametersContent field={fields[selectedFieldName]}
                                   text={defineText}
                                   saveText={addFromTemplate}
                                   closeModal={closeModalParameters}
                />
            </Modal>
        </>
    )
}
