import styled from "styled-components";

export const Footer = styled.div.attrs({className: 'FooterNewVisit'})`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 25px;
`

export const FooterButtonWrap = styled.div.attrs({className: 'FooterButtonWrap'})`
    display: flex;
    align-items: center;
    margin-top: 25px;
`

export const LabelTextGray = styled.span.attrs({className: 'LabelTextGray'})`
    color: ${props => props.theme.colors.textGray};
`

export const WrapBasicForm = styled.div.attrs({className: 'WrapBasicWorm'})`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 52.5%;
    & > div {
        width: 100%;
    }
`
