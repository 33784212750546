import React, {Dispatch, SetStateAction} from 'react';
import Box from '../../../../../../components-ui/Layout/Box';
import SelectEmployee from '../../ModalTimesheetRecord/components/SelectEmployee';
import {Label} from '../../../../../../components-ui/DataDisplay/Label';
import {commonLabelOption} from '../../ModalTimesheetRecord/data';
import DateRange from '../../../../../../components-ui/Inputs/DateRange';
import TimeCounterNew from '../../../../../../components-ui/Inputs/TimeCounterNew/TimeCounterNew';
import Button from '../../../../../../components-ui/Inputs/Button/Button';
import {ModalAddTimesheetState} from '../../types';
import {GetSalariesTimesheetResponce} from '../../../../types';
import {OptionSelect} from '../../../../../../components-ui/Inputs/Select/typesSelect';

interface Props {
    state: ModalAddTimesheetState;
    data: GetSalariesTimesheetResponce;
    selectedBranchId: number;
    handleEmployee: (employee: OptionSelect) => void;
    handleStartDate: (startDate: Date) => void;
    handleEndDate: (endDate: Date) => void;
    handleDefaultStartTime: (defaultStartTime: string) => void;
    handleDefaultEndTime: (defaultEndTime: string) => void;
    setPage: Dispatch<SetStateAction<number>>;
}

const AddTimesheetCreate = ({
                                state,
                                data,
                                selectedBranchId,
                                handleEmployee,
                                handleStartDate,
                                handleEndDate,
                                handleDefaultStartTime,
                                handleDefaultEndTime,
                                setPage,
                            }: Props) => {
    return (
        <>
            <SelectEmployee
                isError={false}
                data={data}
                value={state.employee}
                handleEmployee={handleEmployee}
                selectedBranchId={selectedBranchId}
            />

            <Label labelText='Дата' isRequired={true} {...commonLabelOption} >
                <DateRange startDate={state.startDate}
                           endDate={state.endDate}
                           setStartDate={handleStartDate}
                           setEndDate={handleEndDate}
                />
            </Label>

            <Label labelText='Время' isRequired={true} {...commonLabelOption} >
                <Box display='flex' gap='16px'>
                    <TimeCounterNew value={state.defaultStartTime}
                                    maxValue={state.defaultEndTime}
                                    onChange={handleDefaultStartTime}
                                    step={5}
                    />
                    <TimeCounterNew value={state.defaultEndTime}
                                    minValue={state.defaultStartTime}
                                    onChange={handleDefaultEndTime}
                                    step={5}
                    />
                </Box>
            </Label>

            <Label labelText='' {...commonLabelOption}>
                <Button children='Далее'
                        size='md'
                        variant={'success'}
                        onClick={() => {
                            setPage(2);
                        }}
                />
            </Label>
        </>
    );
};

export default AddTimesheetCreate;
