import React from 'react';
import moment from "moment/moment";
import Button from "../../../components-ui/Inputs/Button/Button";
import KanbanCard from "../KanbanCard";
import {useDialogsManager} from "../../../containers/DialogsManager/DialogsManager";
import _ from 'lodash'
import PreparationForAdmission from "../Modal/PreparationForAdmission/PreparationForAdmission";

interface PropsPrepareKanbanCard{
    visit: any,
    doctor: any,
    isMinimized?: boolean,
    addBgColor?: any,
    removeBgColor?: any,
    setNextState?: any,
    stopScroll?: any
}

export default function PrepareKanbanCard(props: PropsPrepareKanbanCard) {
    const {slideModals, showSlideModal, openSlideModal} = useDialogsManager();

    const renderHeader = () => {
        return (
            <div className="kanban-card__header">
                Запись пациента на {moment(props.visit.timeStart).format("HH:mm")}
            </div>
        );
    };

    const renderButton = () => {
        return (
            <Button variant="default" children="Начать" size="sm-standard" onClick={() => {
                let popupId = null;
                _.each(slideModals, (slideModal, index) => {
                    if (!!slideModal.keySlideModal && slideModal.keySlideModal === props.visit.visitId) {
                        popupId = index;
                    }
                })

                if (!!popupId) {
                    openSlideModal(popupId)
                } else {
                    showSlideModal(<PreparationForAdmission
                        setNextState={props.setNextState}
                        visitId={+props.visit.id}
                        addBgColor={props.addBgColor}
                    />, `prepare-${props.visit.id}`)
                }

            }}/>
        );
    };

    return (
        <KanbanCard
            visit={props.visit}
            isMinimized={props.isMinimized}
            addBgColor={props.addBgColor}
            removeBgColor={props.removeBgColor}
            doctor={props.doctor}
            renderHeader={renderHeader}
            renderButton={renderButton}
            stopScroll={props.stopScroll}
        />
    );
}
