import * as React from "react";
import {Box, BoxBody} from "../../components-ui/styledComponent/common/wraps";
import {BoxTitle} from "../../components-ui/styledComponent/common/typography";
import {SAnalytics} from "./style";
import useReports from "./hooks/useReports";
import LoaderPage from "../../components/LoaderPage";
import Filter from "./components/Filter";
import Tables from "./components/Tables";


export default function Reports() {
    const {data, state, setState, loaderPage, loaderData} = useReports()

    if (!loaderPage && !!data) {
        return (
            <Box withMargin>
                <BoxBody>
                    <BoxTitle children="Отчёт по рекомендациям врачей"/>
                    <SAnalytics>
                        <Filter state={state} setState={setState} loaderData={loaderData}/>
                        <Tables data={data} selectedDoctor={state.selectedDoctor} loaderData={loaderData}/>
                    </SAnalytics>
                </BoxBody>
            </Box>
        );
    } else {
        return (
            <LoaderPage />
        )
    }


}
