import React, {useEffect, useState} from "react";
import Helper from "../../../../common/helpers/main";
import _ from "lodash";
import {months} from "../../../../common/arrays/arrays";
import useCurrentDate from "./useCurrentDate";
import {SCalendarField, HCalendarField} from "../typesCalendarField";
import getSplitValue from "./getSplitValue";

const initialState = {
    day: '',
    month: '',
    year: '',
    options: months
}

export const useCalendarField = (value: any, onChange: (value: any) => void) => {

    const currentDate = useCurrentDate();
    const [state, setState] = useState<SCalendarField>(_.cloneDeep(initialState));

    const monthRender = (splitMouth: string) => {
        let res = null as any;
        state.options.forEach((mouth) => {
            if (mouth.value === splitMouth) res = mouth;
        });
        return res
    }

    useEffect(() => {
        if (value !== '' && value !== undefined && value !== '-' && value !== null && value !== 'null' && value !== 'undefined' && value.length === 10) {
            setState((prevState) => {
                const splitValue = getSplitValue(value);
                return {
                    ...prevState,
                    day: splitValue.day,
                    month: monthRender(splitValue.month),
                    year: splitValue.year
                }
            })
        } else if (value === '') {
            setState(_.cloneDeep(initialState))
        }
    }, [value])

    const onChangeValue = (prevState: SCalendarField) => {
        onChange(`${prevState.year}-${prevState.month === '' ? prevState.month : prevState.month.value}-${prevState.day}`)
    }

    const handleLeapOptions = (newDays = 29) => {
        setState(prevState => {
            prevState.options = prevState.options.map((option, index) => {
                if (index === 1) option.days = newDays;
                return option;
            });
            return {
                ...prevState
            }
        });
    };

    const onChangeDay = (day: string, parentState: SCalendarField) => {
        setState(prevState => {
            const newState = !!parentState ? parentState : prevState;
            newState.day = day;
            if (newState.month === '') {
                if (+newState.day > 31) newState.day = '31';
            } else {
                if (+newState.year === currentDate.year && +newState.month.value === currentDate.month) {
                    if (+newState.day > currentDate.day) newState.day = '' + currentDate.day;
                } else {
                    if (+newState.day > newState.month.days) newState.day = '' + newState.month.days;
                }
            }
            if (newState.day === '00') newState.day = '01';
            onChangeValue(newState)
            return {
                ...newState
            }
        });
    };

    const onBlurDay = (e: React.FocusEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if (value !== '' && value.indexOf('_') !== -1) {
            setState(prevState => {
                prevState.day = Helper.Date.prepareUnambiguousDays(value);
                onChangeValue(prevState)
                return {
                    ...prevState
                }
            });
        }
    };

    const onChangeMonth = (newOption: any, parentState: SCalendarField) => {
        setState(prevState => {
            const newState = !!parentState ? parentState : prevState;
            newState.month = newOption;
            if (newState.day !== '') {
                if (+newState.day > newOption.days) newState.day = '' + newOption.days;
                onChangeDay(newState.day, newState)
            }
            onChangeValue(prevState);
            return {
                ...prevState
            }
        })
    };

    const onChangeYear = (year: string) => {
        setState(prevState => {
            prevState.year = year;
            if (+prevState.year >= currentDate.year) {
                prevState.year = '' + currentDate.year;
                let newMonth = prevState.month as any;
                prevState.options = prevState.options.map(option => {
                    if (+option.value > currentDate.month) {
                        option.isDisabled = true;
                    } else {
                        newMonth = option;
                    }
                    return option;
                });

                if (prevState.month !== '') {
                    if (+prevState.month.value > +newMonth.value) onChangeMonth(newMonth, prevState)
                }

            } else {
                if (prevState.options.some(option => option.isDisabled)) {
                    prevState.options = prevState.options.map(option => {
                        option.isDisabled = false;
                        return option;
                    })
                }
            }

            const leapCurrentYear = prevState.options[1].days === 29;

            if (prevState.year !== '' && !(prevState.year.indexOf('_') !== -1)) {
                const leapYear = new Date(prevState.year, 1, 29).getMonth() === 1;
                if (leapCurrentYear && !leapYear) {
                    handleLeapOptions(28);
                    if (prevState.month !== '') {
                        if (prevState.month.value === '02') {
                            if (+prevState.day > 28) prevState.day = '28';
                        }
                    }
                }
                if (!leapCurrentYear && leapYear) handleLeapOptions();
            } else {
                if (!leapCurrentYear) handleLeapOptions();
            }

            onChangeValue(prevState)
            return {
                ...prevState
            }
        });
    };

    return [state, {
        onChangeDay,
        onBlurDay,
        onChangeMonth,
        onChangeYear,
        setCalendarField: setState
    }] as [SCalendarField, HCalendarField]
}
