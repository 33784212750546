import React, {forwardRef} from 'react';
import { WrapIcon } from '../../../containers/WrapIcon';
import { IIcon } from '../../../types/common';

export const AddIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M2.4,7.2V21.6H16.8V24H2.4A2.41,2.41,0,0,1,0,21.6H0V7.2ZM21.6,0A2.41,2.41,0,0,1,24,2.4h0V16.8a2.41,2.41,0,0,1-2.4,2.4H7.2a2.41,2.41,0,0,1-2.4-2.4h0V2.4A2.41,2.41,0,0,1,7.2,0H21.6Zm0,2.4H7.2V16.8H21.6Zm-6,2.4V8.4h3.6v2.4H15.6v3.6H13.2V10.8H9.6V8.4h3.6V4.8Z"/>
        </WrapIcon>
    );
});
