import React from 'react';
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";
import {useElapsedTime} from "../../../../common/hooks";

interface Props {
    visitDelayStartTime: string
}

export const VisitDelayStartTime = ({visitDelayStartTime}: Props) => {
    const elapsedTime = useElapsedTime({startTime: visitDelayStartTime})

    return (
        <LabelTask label="Время ожидания"
                   children={elapsedTime}
                   alignItems="flex-start"
        />
    );
}
