import {useCallback} from "react";
import Helper from "../../../common/helpers/main";
import {StateLogin} from "../types";

interface Props {
    state: StateLogin
}

const useValidate = ({state}: Props) => {
    
    const {login, password, incorrectData} = state
    
    return useCallback(() => {
        const isLengthCorrect = (login.length > 0 && password.length > 0);
        const data = {login, password};
        const isDataCorrect = (!incorrectData) || !Helper.isEqual(data, incorrectData);

        return isLengthCorrect && isDataCorrect;
    }, [incorrectData, login, password])
}

export default useValidate