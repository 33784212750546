import {useEffect, useState} from "react";
import ServerCommand from "../server/server-command";
import { PatientValues, ResponsePatientList } from "../../containers/PatientField/types/common";
import { PropsSearchPatientList, DataPatientList } from "../../containers/PatientField/types/hooks";
import useLoader from "../../components/Errors";

export default function useSearchPatientList(props: PropsSearchPatientList): [PatientValues[] | null, boolean] {
    const {value, nameField, isFilter, parentPatientId, select} = props;
    const [patientList, setPatientList] = useState<PatientValues[] | null>(null);
    const [loaderPatientList, setLoaderPatientList] = useLoader();

    useEffect(() => {
        if (value.length >= 2) {
            setLoaderPatientList(true);
            let filterValue = `${value}%`;
            if (nameField === 'searchQuery') filterValue = value;
            const data = {
                filter: {[nameField]: filterValue},
                limit: 50,
                select: !!select ? select : ['personalPhone', 'name', 'lastName', 'secondName', 'id']
            } as DataPatientList;
            if (!!parentPatientId) data.getRelationsToPatient = parentPatientId;
            ServerCommand.get('patient/list', data).then((response: ResponsePatientList) => {
                if (response.list !== undefined) setPatientList(response.list);
                setLoaderPatientList(false)
            });
        } else {
            setPatientList(null);
        }
    }, [value])


    return [isFilter ? filterPatientList(patientList, value) : patientList, loaderPatientList];
}

const filterPatientList = (patientList: PatientValues[] | null, value: string) => {
    let newPatientList = null as PatientValues[] | null;
    if (!!patientList && !!patientList.length) {
        newPatientList = patientList.filter((item) => {
            const personalPhone = item.personalPhone !== null ? String(item.personalPhone) : '';
            const name = item.name !== null ? String(item.name) : '';
            const lastName = item.lastName !== null ? String(item.lastName) : '';
            const secondName = item.secondName !== null ? String(item.secondName) : '';
            const validSearch = (name: string) => name.toLowerCase().indexOf(String(value).toLowerCase()) !== -1;
            return validSearch(personalPhone) || validSearch(`${lastName} ${name} ${secondName}`)
        });
    }
    return newPatientList;
}
