import React, {useState} from "react";
import {FormRadio, InputRadio, LabelRadio} from "./styledRadio";

interface PRadio {
    value: string
    onClick?: (() => void) | any
    checked: boolean
    children: any,
    margin?: string,
    error?: boolean
}
export default function Radio({value, onClick, checked, children, margin, error}: PRadio) {
    const [hover, setHover] = useState(false);
    return (
        <FormRadio onMouseLeave={() => setHover(true)} onMouseEnter={() => setHover(false)}
                   onClick={onClick} margin={margin}>
            <InputRadio value={value} checked={checked} onChange={onClick} />
            <LabelRadio active={checked} isHover={hover} children={children} error={error} />
        </FormRadio>
    )
}
