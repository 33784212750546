import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const WtfIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 20.95"} ref={ref}>
            <path d="M12,21a4.61,4.61,0,1,1,4.6-4.6A4.62,4.62,0,0,1,12,21Zm0-7.56a3,3,0,1,0,2.95,3A3,3,0,0,0,12,13.39ZM19.4,9.2A4.6,4.6,0,1,1,24,4.6,4.6,4.6,0,0,1,19.4,9.2Zm0-7.55a2.95,2.95,0,1,0,3,2.95A2.95,2.95,0,0,0,19.4,1.65ZM4.6,9.2A4.6,4.6,0,1,1,9.2,4.6,4.6,4.6,0,0,1,4.6,9.2Zm0-7.55a2.95,2.95,0,1,0,3,2.95A2.95,2.95,0,0,0,4.6,1.65Z"/>
        </WrapIcon>
    );
});
