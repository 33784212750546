import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const MinusRoundIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 16 16"} ref={ref}>
            <g stroke="none" strokeWidth="1" fillRule="evenodd">
                <g transform="translate(-720.000000, -432.000000)" fill="#A3AEC5" fillRule="nonzero">
                    <g transform="translate(12.000000, 297.000000)">
                        <g transform="translate(0.000000, 123.000000)">
                            <g transform="translate(708.000000, 12.000000)">
                                <path d="M8,0 C12.4112,0 16,3.5888 16,8 C16,12.4112 12.4112,16 8,16 C3.5888,16 0,12.4112 0,8 C0,3.5888 3.5888,0 8,0 Z M8,1.6 C4.4712,1.6 1.6,4.4712 1.6,8 C1.6,11.5288 4.4712,14.4 8,14.4 C11.5288,14.4 14.4,11.5288 14.4,8 C14.4,4.4712 11.5288,1.6 8,1.6 Z M12,7.2 L12,8.8 L4,8.8 L4,7.2 L12,7.2 Z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </WrapIcon>
    );
});
