import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const ColorDocumentIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 20 24"} ref={ref}>
            <path fill="#a3aec5" d="M2,0h8L20,10V22a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"/>
            <path fill="#7a859e" d="M10,0,20,10H10Z"/>
        </WrapIcon>
    );
});
