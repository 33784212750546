import React from "react";
import {WrapButton} from "../style/common";
import SVG from "../../../common/helpers/SVG";
import Button from "../../../components-ui/Inputs/Button/Button";
import {usePatientField} from "../containers/PatientField";
import {PatientValues} from "../types/common";
import {IPatientField} from "../types/fields";

export default function ActionButton() {
    const {isEdit, setIsEdit, onChangeField, field, isMargin, currentPatient, composite} = usePatientField()
    return (
        <WrapButton isMargin={isMargin} isEdit={isEdit}>
            <Button isIcon={true}
                    size="md"
                    variant={isEdit ? "transparent-danger" : "transparent-default"}
                    children={<SVG name={isEdit ? "delete" : "edit"}/>}
                    onClick={() => {
                        if (isEdit) {
                            setIsEdit(false)
                            if (!!currentPatient) {
                                const prevValue = (value: any, prevField: IPatientField) => {
                                    let result = value;
                                    switch (prevField.type) {
                                        case "enum":
                                            const findVariant = prevField.variants?.find(variant => variant.value === value)
                                            if (!!findVariant) {
                                                result = findVariant
                                            }
                                            break;
                                        default:
                                            break
                                    }

                                    return result
                                }

                                if (field.type === 'composite' && !!field.subFields && composite) {
                                    field.subFields.forEach(subFieldName => {
                                        const newValue = prevValue(currentPatient[subFieldName as keyof PatientValues], composite[subFieldName].field)
                                        onChangeField(newValue, subFieldName)
                                    })
                                } else {
                                    const newValue = prevValue(currentPatient[field.name as keyof PatientValues], field)
                                    onChangeField(newValue, field.name)
                                }
                            }
                        } else {
                            setIsEdit(true)
                        }
                    }}/>
        </WrapButton>
    )
}
