import ServerCommand from "../../../../common/server/server-command";
import {Dispatch, SetStateAction, useCallback} from "react";
import {CreatePaymentParams, GetDirectionsBalanceResult, IStatePaymentCreationForm} from "../types";
import usePaymentSum from "./usePaymentSum";
import {useModal} from "../../../../containers/DialogsManager/containers/Modal/Modal";
import Kkm from "../../../../common/server/kkm";
import {useSelector} from "react-redux";
import {KkmResponse} from "../../../../common/types/server";
import {useErrorModal} from "../../../../components/Errors";
import {KkmSetting} from "../../../../types";

interface Props {
    patientId: number | undefined
    loadingFromServer?: () => void
    setLoader: Dispatch<SetStateAction<boolean>>
    setKkmError: Dispatch<SetStateAction<string>>
    state: IStatePaymentCreationForm
    data: GetDirectionsBalanceResult | null
    kkmSetting: KkmSetting
}

const useSubmit = ({
                       patientId,
                       loadingFromServer,
                       setLoader,
                       setKkmError,
                       state,
                       data,
                       kkmSetting
                   }: Props) => {

    const {closeModal} = useModal()

    const paymentSum = usePaymentSum({values: state.values})

    const paymentDistribution = Object.keys(state.values).map((key) => {
        return [key, +state.values[key]]
    })


    return useCallback(() => {
        if (patientId !== undefined) {
            setLoader(true);
            const params = {
                type: 'P',
                patientId,
                paymentSum: paymentSum - +state.discount - +state.insuranceSum,
                method: state.selectedPaymentMethod.value,
                cashbox: state.selectedCashbox.value,
                discount: {
                    sum: +state.discount,
                    type: state.selectedDiscountType.value
                },
                insuranceSum: +state.insuranceSum,
                branchId: state.branch?.value
            } as CreatePaymentParams;

            if (!!data && !!data.directionsBalance && Object.keys(data.directionsBalance).length > 1) {
                params.paymentDistribution = Object.fromEntries(paymentDistribution)
            }

            const command = new ServerCommand('payment/create', params);
            const kkm = new Kkm(kkmSetting)

            if (state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' && state.selectedKkm) {

                // @ts-ignore
                kkm.registerCheck(
                    0,
                    true,
                    paymentSum,
                    +state.discount,
                    state.selectedCashbox.value === 'MAIN_BOX_OFFICE').then((response: KkmResponse) => {

                    console.log(response)

                    if (response.Status == 0) {


                        command.exec().then((paymentId) => {
                            closeModal()
                            if (loadingFromServer) loadingFromServer()
                        });
                        // const command = new ServerCommand('payment/save-print-check', {
                        //     checkNumber: response.CheckNumber,
                        //     sessionNumber: response.SessionNumber,
                        //     sessionCheckNumber: response.SessionCheckNumber,
                        //     url: response.URL,
                        //     idCommand: response.IdCommand,
                        //     paymentId: paymentId
                        // });
                        // command.exec().then(() => {
                        //
                        // })
                    } else {
                        setLoader(false);
                        setKkmError(response.Error)
                        console.log(response.Error)
                    }
                }).catch((reason : string) => setKkmError(reason))
            } else {
                const command = new ServerCommand('payment/create', params);
                command.exec().then((paymentId) => {
                    closeModal()
                    if (loadingFromServer) loadingFromServer()
                });
            }

        }

    }, [
        patientId,
        loadingFromServer,
        setLoader,
        state,
        data,
        kkmSetting
    ])

};

export default useSubmit;
