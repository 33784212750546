import React from 'react';
import './Kanban.scss'
import ServerCommand from "../../common/server/server-command";
import Helper from "../../common/helpers/general-helper";
import PrepareKanbanCard from "./Cards/PrepareKanbanCard";
import WaitingKanbanCard from "./Cards/WaitingKanbanCard";
import TreatmentKanbanCard from "./Cards/TreatmentKanbanCard";
import CheckListKanbanCard from "./Cards/CheckListKanbanCard";
import VisitCompletedKanbanCard from "./Cards/VisitCompletedKanbanCard";
import {echo} from "../../common/server/echo";
import {SlideModalContext} from "../../containers/DialogsManager/containers/SlideModal/SlideModal";
import Box from "../../components-ui/Layout/Box";
import Select from "../../components-ui/Inputs/Select/Select";
import {connect} from "react-redux";
import Loader, {SWrapLoader} from "../../components-ui/Feedback/Loader/Loader";
import Scrollbar from "../../components-ui/DataDisplay/Scrollbar/Scrollbar";


class Kanban extends React.Component {

    static contextType = SlideModalContext;

    state = {
        doctors: null,
        states: null,
        visits: null,
        waitingLimit: null,
        rawData: 0,
        changeData: 0,
        isScroll: false,
        selectedBranch: this.props.user.branches[0],
        loaderBranch: false
    };

    componentDidMount() {
        this.loadData();
        this.echoPrivate();
    };

    componentWillUnmount() {
        echo.leaveChannel(`kanban.${this.state.selectedBranch}`);
    }

    render() {
        if (this.state.doctors === null) {
            return null
        }

        const states = this.getStates();

        const kanbanHeader = states.map(state =>
            <li className="kanban__header-item" key={state.code}>
                                    <span className="kanban__header-title">
                                        {state.name}
                                    </span>
                ({state.visits.length})
                {(state.isBgColor) ? <span className="kanban__header-info"/> : null}
            </li>
        );


        const kanbanCards = states.map(state =>
            <Scrollbar width="20%" height={(window.innerHeight - 125) + 'px'} key={state.code}>
                <div className="kanban__column">
                    {state.visits.map(visit => this.renderKanbanCard(visit))}
                </div>
            </Scrollbar>
        );

        return (
            <div className="kanban">
                <div className="kanban__header">
                    {this.props.user.branches.length > 1 && (
                        <Box display="flex" borderBottom="lightBlue" padding="12px 0 12px 35px">
                            <Select label="Филиал:" sizeSelect="sm" widthSelect="380px" onChange={this.onChangeBranch}
                                    options={this.getOptionsBranches()} value={this.getValueBranch()}/>
                            <Box position="relative" width="30px" height="30px" margin="0 0 0 15px">
                                <Loader visible={this.state.loaderBranch} isBackground={false} width={24} height={24} />
                            </Box>
                        </Box>
                    )}
                    <ul className="kanban__header-list">
                        {kanbanHeader}
                    </ul>
                </div>
                <SWrapLoader loader={this.state.loaderBranch} minHeight="100vh">
                    <div className="kanban__body">
                        {kanbanCards}
                    </div>
                </SWrapLoader>
            </div>
        )
    }

    renderKanbanCard(visit) {

        let component = null;
        switch (visit.state) {
            case 'PREPARE':
                component = PrepareKanbanCard;
                break;
            case "WAITING" :
                component = WaitingKanbanCard;
                break;
            case "TREATMENT" :
                component = TreatmentKanbanCard;
                break;
            case "CHECK_LIST" :
                component = CheckListKanbanCard;
                break;
            case "VISIT_COMPLETED" :
                component = VisitCompletedKanbanCard;
                break;
            default:
                break;
        }
        return React.createElement(component, {
            visit,
            doctor: this.state.doctors[visit.doctorId],
            setNextState: this.setNextState.bind(this),
            key: visit.id,
            isMinimized: this.context.isMinimized,
            removeBgColor: this.removeBgColor.bind(this),
            waitingLimit: this.state.waitingLimit,
            addBgColor: this.addBgColor.bind(this),
            stopScroll: (bool) => this.setState({isScroll: bool})
        });
    }

    echoPrivate = (branchId = this.state.selectedBranch) => {
        let changeData = Helper.clone(this.state.changeData);
        this.setMinimizeParams();
        echo.private(`kanban.${branchId}`)
            // Обработка нового визита для администратора
            .listen('VisitStarted', (visit) => {
                let visits = Helper.clone(this.state.visits);
                visits[visit.visitId] = visit;
                if (this.context.isMinimized) {
                    changeData++;
                }
                visits[visit.visitId].bgColor = true;
                this.setState({visits, changeData});
                this.setMinimizeParams();
            })
            // Обработка уже существующего визита для администратора
            .listen('VisitChangedState', (visit) => {
                let visits = Helper.clone(this.state.visits);

                if(!!visit) {

                    const isKnownState = this.state.states.some(state => state.code === visit.state);

                    if(isKnownState && !!visits[visit.visitId]) {
                        visits[visit.visitId].state = visit.state;
                        visits[visit.visitId].stateData = visit.stateData;
                        if (this.context.isMinimized) {
                            changeData += 1;
                        }
                        visits[visit.visitId].bgColor = true;
                    }
                    else {
                        delete visits[visit.visitId];
                    }
                }
                else {
                    delete visits[visit.visitId];
                }

                this.setState({visits, changeData});
                this.setMinimizeParams();
            });
    }

    echoLeave = (branchId) => {
        echo.leave('kanban.' + branchId);
    }

    /** Вывод информации в минимизированную версию Kanban*/
    setMinimizeParams() {

        let title = null;
        let text = null;

        if (this.state.rawData === 0 && this.state.changeData === 0) {
            title = 'Канбан приёмов';
            text = null;
        } else {
            title = 'Приёмы';
            text = `${this.state.rawData} необработанных, ${this.state.changeData} изменения`
        }
        if (!!this.context.setMinimizeParams) {
            this.context.setMinimizeParams({
                icon: 'kanban',
                title,
                text,
            })
        }
    }

    /** Удаляем прочитанный компонент KanbanCard из непрочитанных
     * @param id {number}*/
    removeBgColor(id) {
        let visits = Helper.clone(this.state.visits);
        delete visits[id].bgColor;
        this.setState({visits, rawData: 0, changeData: 0});
        this.setMinimizeParams();
    }

    /** Обходим цикл внутри цикла
     * @return {Array} states*/
    getStates = () => {
        let states = [];
        let statesMap = {};

        this.state.states.forEach((state, index) => {
            states.push({
                code: state.code,
                name: state.name,
                visits: []
            });

            statesMap[state.code] = index;
        });

        Helper.forEachObj(this.state.visits, (visit, visitId) => {
            visit = Helper.clone(visit);
            visit.id = visitId;

            const stateIndex = statesMap[visit.state];
            let state = states[stateIndex];

            if (visit.bgColor) {
                state.isBgColor = true;
            }

            state.visits.push(visit);
        });


        return states;
    };

    addBgColor(visitId) {
        let {visits} = this.state;

        if (visitId in visits) {
            visits = Helper.clone(this.state.visits);
            visits[visitId].bgColor = true;
            this.setState({visits});
        }
    }

    /*Загрузка данных с сервера*/
    loadData = (branchId = this.state.selectedBranch) => {
        ServerCommand.get('visit/kanban', {branchId}).then(response => {
            this.setState({
                doctors: response.doctors,
                states: response.states,
                visits: response.visits,
                waitingLimit: response.waitingLimit,
                loaderBranch: false
            });
        });
    }

    /** Переводит KanbanCard в следующее состояние
     * @param visitId {?number}*/
    setNextState(visitId = null) {
        if(visitId) {
            const command = new ServerCommand('visit/set-next-state', {visitId});
            command.exec().then(() => {
                this.loadData();
            })
        }
        else {
            this.loadData();
        }
    }

    getOptionsBranches = () => {
        return this.props.branches.filter(branch => this.props.user.branches.includes(branch.id)).map((branch) => ({
            value: branch.id,
            label: branch.name
        }))
    }

    getValueBranch = () => {
        return this.getOptionsBranches().find(option => option.value === this.state.selectedBranch)
    }

    onChangeBranch = (value) => {
        this.echoLeave(this.state.selectedBranch);
        const selectedBranch = value.value;
        this.setState({selectedBranch, loaderBranch: true})
        this.loadData(selectedBranch)
        this.echoPrivate(selectedBranch);
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    branches: state.common.branches
});

export default connect(mapStateToProps)(Kanban)
