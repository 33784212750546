import React, {useState} from "react";
import TextField from "../../../../../../components-ui/Inputs/TextField/TextField";

export default function LabelTextField() {

    const [value, setValue] = useState('');


    return (
        <div>
            <TextField onChange={setValue} value={value} label="Дом:"/>
        </div>

    )
}
