import React from 'react';
import LoaderPage from '../../../../../../components/LoaderPage';
import Typography from '../../../../../../components-ui/DataDisplay/Typography/Typography';
import PersonalInfo from './components/personalInfo';
import ContactInfo from './components/contactInfo';
import AccountInfo from './components/accountInfo';
import ProfessionInfo from './components/professionInfo';
import DocumentsInfo from './components/documentsInfo';
import AddressInfo from './components/addressInfo';
import Button from '../../../../../../components-ui/Inputs/Button/Button';
import useAddingEditingEmployee from './hooks/useAddingEditingEmployee';
import Box from '../../../../../../components-ui/Layout/Box';
import ValidationLinkInfo from '../../../../../../components/ValidationLinkInfo/ValidationLinkInfo';

interface AddingEditingEmployeeProps {
    userId?: number;
    refreshDataFromServer: () => void
}

const AddingEditingEmployee = ({userId, refreshDataFromServer}: AddingEditingEmployeeProps) => {

    const {
        loadingPage,
        state,
        setState,
        setDetailFn,
        setSalaryFn,
        directionsOptions,
        rolesOptions,
        handsetOptions,
        saveUser,
        passwordState,
        setPasswordState,
        loadingRequest,
        validationState,
        setValidationState,
        birthdayValid,
        branchesValid,
        seriesValid,
        issueDateValid,
        addresDateValid,
        passwordValueValid,
        fullValid,
        loginState
    } = useAddingEditingEmployee({userId, refreshDataFromServer});

    return (
        <>
            {loadingPage ? <LoaderPage /> :
                <>
                    <Typography variant='middle-title' fontWeight='bold'>
                        {`${userId ? 'Изменение' : 'Добавление'} сотрудника`}
                    </Typography>
                    <div style={{columnCount: 2, maxWidth: '1224px', marginTop: '15px'}}>
                        <PersonalInfo state={state} setDetailFn={setDetailFn}
                                      validationState={validationState}
                                      birthdayValid={birthdayValid}
                        />
                        <ContactInfo state={state} setDetailFn={setDetailFn} />
                        <AccountInfo state={state} setDetailFn={setDetailFn}
                                     passwordState={passwordState}
                                     setPasswordState={setPasswordState}
                                     userId={userId}
                                     validationState={validationState}
                                     passwordValueValid={passwordValueValid}
                                     branchesValid={branchesValid}
                                     loginState={loginState}
                        />
                        <ProfessionInfo state={state}
                                        setState={setState}
                                        setDetailFn={setDetailFn}
                                        setSalaryFn={setSalaryFn}
                                        directionsOptions={directionsOptions}
                                        rolesOptions={rolesOptions}
                                        handsetOptions={handsetOptions}
                                        validationState={validationState}
                        />
                        <DocumentsInfo state={state} setState={setState}
                                       validationState={validationState}
                                       seriesValid={seriesValid}
                                       issueDateValid={issueDateValid}
                                       addresDateValid={addresDateValid}/>
                        <AddressInfo state={state} setState={setState} />
                    </div>
                    <Box display='flex'
                         justifyContent='flex-end'
                         maxWidth='1224px'
                         gap='25px'
                         alignItems='center'
                         padding='50px 0 0 0'>
                        <ValidationLinkInfo validate={fullValid}
                                            onClickIsValidate={() => {
                                                setValidationState(true);
                                            }}
                        />
                        <Button children='Сохранить'
                                loader={loadingRequest}
                                size='md'
                                variant={fullValid ? 'success' : 'disabled'}
                                onClick={saveUser}
                        />
                    </Box>
                </>
            }
        </>
    );
};

export default AddingEditingEmployee;
