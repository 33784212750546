import React from 'react';
import {Icon} from "../../components/Icons/Icons";
import PropTypes from "prop-types";
import moment from "moment/moment";
import ServerCommand from "../../common/server/server-command";
import Select from "components-ui/Inputs/Select/Select";
import Button from "../../components-ui/Inputs/Button/Button";
import Helper from "../../common/helpers/general-helper";
import Checkbox from "../../components-ui/Inputs/Checkbox/Checkbox";
import DateTime from "../../components-ui/Inputs/DateTime/DateTime";
import DateHelper from "../../common/helpers/date-helper";

export default class PatientRecord extends React.Component {

    static propTypes = {
        patientId: PropTypes.number.isRequired,
        onClick: PropTypes.func
    };

    state = {
        selectedDate: null,
        freeDates: null,
        selectedTime: null,
        selectedDoctor: null,
        doctors: null,
        freeTime: null,
        maxVisitDuration: null,
        valueReception: '15',
        optionsDoctors: null,
        isSocial: false
    };

    componentDidMount() {
        const command = new ServerCommand('schedule/get-free-dates');
        command.exec().then((response) => {
            let maxVisitDuration = 0;
            response.freeTime[0].doctors.forEach(doctor => {
                if (+doctor.maxVisitDuration > maxVisitDuration) {
                    maxVisitDuration = doctor.maxVisitDuration
                }
            });
            let optionsDoctors = null;
            const valueReception = '15';


            if (response.doctors !== null) {
                optionsDoctors = this
                    .getFreeDoctors(response.freeTime, this.getTimeOption(response.freeTime[0]), valueReception)
                    .map((doctor) => this.getDoctorOption(doctor, response.doctors));
            }

            this.setState({
                freeDates: response.freeDates.map(date => new Date(date)),
                selectedDate: new Date(response.freeDates[0]),
                freeTime: response.freeTime,
                doctors: response.doctors,
                selectedTime: this.getTimeOption(response.freeTime[0]),
                selectedDoctor: this.getDoctorOption(response.freeTime[0].doctors[0], response.doctors),
                maxVisitDuration,
                optionsDoctors,
                valueReception
            });
        });
    }

    render() {
        if (this.state.freeDates === null) {
            return null
        }
        let optionsTime = null;
        if (this.state.freeTime !== null) {
            optionsTime = this.state.freeTime.map(this.getTimeOption.bind(this));
        }

        return (
                <div className="modal__wrap">
                    <div className="modal__close" onClick={this.props.onClick}>
                        <Icon icon="new-close"/>
                    </div>
                    <h2 className='modal__title'>Запись пациента на приём</h2>
                    <div className="modal__box">
                        <div className="modal__label">
                            <span>Дата</span>
                            <DateTime selected={this.state.selectedDate}
                                      includeDates={this.state.freeDates}
                                      onChange={this.handleChangeDate.bind(this)} />
                        </div>
                        <div className="modal__label modal__label--min">
                            <span>Прием</span>
                            <Select options={optionsTime}
                                    value={this.state.selectedTime}
                                    onChange={this.handleTimeChange.bind(this)}
                            />
                        </div>
                        <div className="modal__label modal__label--min">
                            <span>Длительность</span>
                            <div className="info-box__reception">
                                <input
                                    type="text"
                                    className="input input--reception"
                                    onChange={this.onChangeReception.bind(this)}
                                    value={this.showDuration()}
                                />
                                <div className="info-box__reception-btn">
                                    <button
                                        onClick={this.onClickReceptionUp.bind(this)}
                                    >
                                        <Icon icon="new-arrow" iconClass="new-arrow--up"/>
                                    </button>
                                    <button
                                        onClick={this.onClickReceptionDown.bind(this)}
                                    >
                                        <Icon icon="new-arrow"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal__label">
                            <span>Врач</span>
                            <Select options={this.state.optionsDoctors}
                                    variant="colored"
                                    value={this.state.selectedDoctor}
                                    onChange={(selectedDoctor) => this.setState({selectedDoctor})}
                            />
                        </div>
                        <div className="modal__label">
                            <span/>
                            <Checkbox
                                label="По соцзащите"
                                onChange={(isSocial) => this.setState({isSocial})}
                                checked={this.state.isSocial}
                            />
                        </div>
                    </div>
                    <Button variant='success' children='Записать' size="md" onClick={this.addVisit.bind(this)}/>
                </div>
        )
    }

    handleChangeDate(date) {
        this.setState({selectedDate: date});
        const command = new ServerCommand('schedule/get-free-time', {date: DateHelper.formatDateForServer(date)});
        command.exec().then((response) => {
            let maxVisitDuration = 0;
            response.freeTime[0].doctors.forEach(doctor => {
                if (+doctor.maxVisitDuration > maxVisitDuration) {
                    maxVisitDuration = doctor.maxVisitDuration
                }
            });
            let newState = {
                freeTime: response.freeTime,
                doctors: response.doctors,
                selectedTime: this.getTimeOption(response.freeTime[0]),
                selectedDoctor: this.getDoctorOption(response.freeTime[0].doctors[0], response.doctors),
                valueReception: '15',
                maxVisitDuration
            };
            newState.optionsDoctors = this
                .getFreeDoctors(response.freeTime, newState.selectedTime, newState.valueReception)
                .map((doctor) => this.getDoctorOption(doctor, response.doctors));
            this.setState(newState);
        });
    }

    handleTimeChange(selectedTime) {
        let newState = {selectedTime};

        const newDoctorsList = this.state.freeTime[selectedTime.index].doctors.filter(doctor => +doctor.maxVisitDuration >= +this.state.valueReception);

        newState.optionsDoctors = this
            .getFreeDoctors(this.state.freeTime, selectedTime, this.state.valueReception)
            .map((doctor) => this.getDoctorOption(doctor, this.state.doctors));

        /* если выбранного врача нет в списке врачей на выбранное время - в качестве врача устанавливаем первого из списка */
        if (!newDoctorsList.some(doctor => this.state.selectedDoctor.value === doctor.id)) {
            newState.selectedDoctor = this.getDoctorOption(newDoctorsList[0], this.state.doctors)
        }

        this.setState(newState)
    }

    getTimeOption(interval, index = 0) {
        return {
            value: interval.start,
            label: "c " + interval.start,
            index
        }
    }

    getDoctorOption(freeDoctor, doctors) {
        return {
            value: freeDoctor.id,
            chairId: freeDoctor.chairId,
            label: Helper.getFio(doctors[freeDoctor.id]),
            color: doctors[freeDoctor.id].color
        }
    }

    getFreeDoctors(freeTime, selectedTime, valueReception) {
        const timeStart = selectedTime.value;
        let freeDoctors = [];

        freeTime.forEach((interval) => {

            if (interval.start === timeStart) {
                interval.doctors.forEach(doctor => {
                    if (doctor.maxVisitDuration >= valueReception) {
                        freeDoctors.push(doctor);
                    }
                });
            }
        });

        return freeDoctors
    }

    addVisit() {
        const timeStart = `${moment(this.state.selectedDate).format('YYYY-MM-DD')} ${this.state.selectedTime.value}:00`;
        const timeEnd = `${moment(this.state.selectedDate).format('YYYY-MM-DD')} ${this.calculateTimeEnd()}:00`;
        const command = new ServerCommand('visit/add', {
            timeStart: timeStart,
            timeEnd: timeEnd,
            doctorId: this.state.selectedDoctor.value,
            workChairId: this.state.selectedDoctor.chairId,
            patientId: this.props.patientId,
            isSocial: this.state.isSocial
        });
        command.exec().then(this.props.onClick);
    }

    calculateTimeEnd() {

        const valueReception = Helper.clone(this.state.valueReception);
        let timeStart = Helper.clone(this.state.selectedTime.value).split(':');

        timeStart = +timeStart[0] * 60 + +timeStart[1] + +valueReception;

        let timeEndHours = Math.floor(timeStart / 60);
        let timeEndMinutes = timeStart % 60;

        if (String(timeEndHours).length === 1) {
            timeEndHours = '0' + String(timeEndHours)
        }

        if (timeEndMinutes === 0) {
            timeEndMinutes = '00'
        }

        return String(timeEndHours) + ':' + String(timeEndMinutes);
    }

    onChangeReception(e) {

        let val = e.target.value;
        const maxVisitDuration = Helper.clone(this.state.maxVisitDuration);

        if (val >= maxVisitDuration) {
            val = maxVisitDuration;
        }

        this.setState({valueReception: val});

    }

    onClickReceptionUp() {
        let newState = {};
        newState.valueReception = +Helper.clone(this.state.valueReception) + 15;
        const maxVisitDuration = Helper.clone(this.state.maxVisitDuration);
        if (newState.valueReception > maxVisitDuration) {
            return false
        }

        newState.optionsDoctors = this
            .getFreeDoctors(this.state.freeTime, this.state.selectedTime, newState.valueReception)
            .map((doctor) => this.getDoctorOption(doctor, this.state.doctors));


        let selectedDoctor = Helper.clone(this.state.selectedDoctor);

        newState.selectedDoctor = newState.optionsDoctors[0];

        newState.optionsDoctors.forEach(doctor => {
            if (selectedDoctor.label === doctor.label) {
                newState.selectedDoctor = selectedDoctor
            }
        });


        if (newState.valueReception >= maxVisitDuration) {
            newState.valueReception = maxVisitDuration;
        }
        this.setState(newState)

    }

    onClickReceptionDown() {
        let newState = {};
        newState.valueReception = +Helper.clone(this.state.valueReception) - 15;

        if (newState.valueReception < 15) {
            return false
        }

        newState.optionsDoctors = this
            .getFreeDoctors(this.state.freeTime, this.state.selectedTime, newState.valueReception)
            .map((doctor) => this.getDoctorOption(doctor, this.state.doctors));


        if (newState.valueReception <= 15) {
            newState.valueReception = 15;
        }
        this.setState(newState)
    }

    showDuration() {
        const valueReception = +Helper.clone(this.state.valueReception);

        let hours = '00';
        if (Math.floor(valueReception / 60) > 0) {
            hours = '0' + String(Math.floor(valueReception / 60));
        }

        let minutes = '00';
        if ((valueReception % 60) > 0) {
            minutes = String(valueReception % 60)
        }

        return hours + ':' + minutes
    }

}
