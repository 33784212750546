import React from 'react'
import PropTypes from 'prop-types'
import './CheckupStep.scss'
import ServerCommand from "../../../../common/server/server-command";
import InitialSurvey from "../../../../containers/InitialSurvey/InitialSurvey";
import StepFooter from "../../components/StepFooter/StepFooter";
import {InitialSurveyProvider} from "../../../../containers/InitialSurvey/InitialSurveyContext";

export default class CheckupStep extends React.PureComponent {

    constructor(props) {
        super(props);
        this.handlerLoader = this.handlerLoader.bind(this)
    }

    state = {
        loaderNextBtn: false
    }

    static propTypes = {
        goToNextStep: PropTypes.func,
        visitId: PropTypes.number.isRequired,
        cardNumber: PropTypes.string
    };

    render() {
        return (
            <InitialSurveyProvider
                getLoadCommandData={this.getLoadCommandData}
                sendInitialSurvey={this.sendInitialSurvey}>
                <InitialSurvey renderFooter={this.renderFooter}
                               visitId={this.props.visitId}
                               cardNumber={this.props.cardNumber}
                />
            </InitialSurveyProvider>

        )
    }

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.handlerLoader)
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.handlerLoader)
    }

    renderFooter = (options) => {
        const {isDataChanged, handlePrintButtonClick, teethMapUnconfirmed, validateFields, scrollToField, scrollToDentalCard, loaderPrint} = options;
        let printBtnLabel = 'Вклеить в карту';

        const linkErrorField = <span className="error-tips-initial-survey">
            Заполните <span className="link" onClick={scrollToField}>обязательные поля</span> первичного осмотра
        </span>

        const linkErrorDentalCard = <span className="error-tips-initial-survey">
            Подтвердите <span className="link" onClick={scrollToDentalCard}>неподтверждённые зубы</span>
        </span>

        if (!validateFields) {
            printBtnLabel = [linkErrorField];
        } else if (teethMapUnconfirmed) {
            printBtnLabel = [linkErrorDentalCard];
        }

        return (
            <StepFooter
                showPrintBtn={true}
                nextBtnName='К плану лечения'
                onNextButtonClick={this.handleNextButtonClick.bind(this)}
                nextBtnActive={!isDataChanged()}
                onPrintButtonClick={handlePrintButtonClick}
                printBtnLabel={printBtnLabel}
                printBtnActive={!teethMapUnconfirmed && validateFields}
                nextBtnLabel="Распечатайте первичный осмотр"
                loaderPrint={loaderPrint}
                loaderNextBtn={this.state.loaderNextBtn}
            />
        )
    };

    getLoadCommandData = () => ({visitId: this.props.visitId});

    /**
     * Отправляет данные первичного осмотра на сервер, и переходит на следующий шаг в случае успеха
     */
    sendInitialSurvey = (initialSurveyData) => {
        initialSurveyData.visitId = this.props.visitId;

        const command = new ServerCommand('initial-survey/save', initialSurveyData);
        return command.exec();
    };

    handleNextButtonClick = () => {
        this.setState({loaderNextBtn: true})
        const command = new ServerCommand('visit/set-next-state', {visitId: this.props.visitId});
        command.exec().then(() => {
            this.props.goToNextStep();
            this.setState({loaderNextBtn: false});
        });
    }
    handlerLoader(event) {
        const reason = event.reason;
        if (reason.type === 'server_request_error') {
            this.setState({loader: false})
        }
    }
}

