import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonSelectOption, commonTextFieldOption} from "../../data";
import TextField from "../../../../../components-ui/Inputs/TextField/TextField";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {IStatePaymentCreationForm} from "../../types";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {useSelector} from "react-redux";

interface Props {
    state: IStatePaymentCreationForm
    setState: Dispatch<SetStateAction<IStatePaymentCreationForm>>
    paymentSum: number
}

const Discount = ({state, setState, paymentSum}: Props) => {

    const discountTypeOptions = useSelector(state => state.common.discountTypeOptions);

    const handleDiscount = useCallback((discount: string) => {
        setState(prevState => ({...prevState, discount}))
    }, [setState])

    const handleDiscountType = useCallback((selectedDiscountType: OptionSelect) => {
        setState(prevState => ({...prevState, selectedDiscountType}))
    }, [setState])

    return (
        <>
            <Label labelText="Скидка" {...commonLabelOption}>
                <TextField
                    {...commonTextFieldOption}
                    value={state.discount}
                    isNumber={true}
                    min={0}
                    onChange={handleDiscount}
                    max={paymentSum - +state.insuranceSum}
                />
            </Label>

            <Label labelText="Тип скидки" {...commonLabelOption}>
                <Select
                    {...commonSelectOption}
                    options={discountTypeOptions}
                    value={state.selectedDiscountType}
                    onChange={handleDiscountType}
                />
            </Label>
        </>
    );
};

export default Discount;
