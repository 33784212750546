import {Dispatch, SetStateAction, useEffect, useState} from "react";
import ServerCommand from "../../../../common/server/server-command";
import {ResponseCallList} from "../../types/response";
import {InfoCallCenter} from "../../types/hooks";
import {StateCallCenter} from "../../types/components";
import {useSelector} from "react-redux";

interface Props {
    state: StateCallCenter
    setState: Dispatch<SetStateAction<StateCallCenter>>
}

const useInitialCallCenter = ({setState, state}: Props) => {
    const branches = useSelector(state1 => state1.common.allBranches);

    const [infoCallCenter, setInfoCallCenter] = useState<InfoCallCenter>({
        scripts: [],
        types: {},
        scriptsGroups: [],
        postponeTreatmentReasons: {
            reasons: {},
            inputs: {}
        },
        refuseTreatmentReasons: {
            reasons: {},
            inputs: {}
        },
        optionsBranches: state.branches.map(branchId => ({
            value: branchId,
            label: branches[branchId].name
        }))
    })

    const [loader, setLoader] = useState(false)

    const refreshDataFromServer = () => {
        setLoader(true)
        ServerCommand.get('call-center/call/list').then(({plannedCalls, calls, ...response}: ResponseCallList) => {
            setState(prevState => ({
                ...prevState,
                plannedCalls,
                calls: !!calls.length ? calls : prevState.calls
            }))
            setInfoCallCenter(prevState => ({...prevState, ...response}))
            setLoader(false)
        })
    }

    useEffect(() => {
        setLoader(true)
        ServerCommand.get('call-center/call/list').then(({plannedCalls, calls, ...response}: ResponseCallList) => {
            setState(prevState => ({
                ...prevState,
                plannedCalls,
                calls: !!calls.length ? calls : prevState.calls
            }))
            setInfoCallCenter(prevState => ({...prevState, ...response}))
            setLoader(false)
        })
    }, [JSON.stringify(state.branches)])

    return {infoCallCenter, refreshDataFromServer, loader}

}

export default useInitialCallCenter
