import * as React from "react"
import {PureComponent} from 'react';
import Helper from "../../../common/helpers/main";
import TreatmentPlan from "../../TreatmentPlan/TreatmentPlan";
import Arr from "../../../common/helpers/Arr";
import _ from 'underscore';
import ServicesListTitle from "./elements/ServicesListTitle";
import TablePlanEditor from "./elements/TablePlanEditor";
import {PPlanEditor, RecommendedServicesDoctorsMap, SPlanEditor} from "./types";
import {getDataTablePlanEditor} from "./helper";
import ModalAddDoctor from "./elements/ModalAddDoctor";
import {canPerformService} from "../../../common/helpers/Doctor";
import ModalChooseServiceDoctor from "./elements/ModalChooseServiceDoctor";
import {Service, Services} from "../types";
import RecommendedServices from "./elements/RecommendedServices/RecommendedServices";
import TargetFactory from "../../TreatmentPlan/Target/TargetFactory";
import {DentalRecords} from "../../../components/DentalRecords/DentalRecords";
import {TREATMENT_PLAN} from "../../../common/constants/DentalRecords";
import {DialogsManagerContext} from "../../DialogsManager/DialogsManager";

export default class PlanEditor extends PureComponent<PPlanEditor> {
    static defaultProps: { isServicesInitiallyHidden: boolean; checkItemsStatuses: boolean };
    static contextType = DialogsManagerContext

    state = {
        selectedGroupId: 0,
        activeTeeth: [], // зубы, выбранные в зубной карте
        isServicesHidden: this.props.isServicesInitiallyHidden.valueOf(),
        selectedDoctorId: null,
        addedDoctors: []
    } as SPlanEditor;

    tabs = [
        {name: 'Все услуги', code: 'services'},
        {name: 'Шаблоны', code: 'templates'}
    ];


    render() {
        const {
            directions, currentPlanType, plan, planItemDisabledTip, services, servicesGroups,
            servicesListTitle, teethMap, recommendedServices, showRecommendedServices, visit
        } = this.props;
        let {addedDoctors, activeTeeth, isServicesHidden} = this.state;

        const isRecommended = currentPlanType === 'recommended';

        /**
         * @type {TreatmentPlan}
         * */
        plan.setActiveTeeth(activeTeeth);
        const planItems = plan.getItems();

        const directionsMap = Arr.toObject(directions, 'code');

        const performers = this.getPerformers(planItems) as any;
        const selectedDoctorId = this.getSelectedDoctorId(performers);

        const servicesMap: Services = _.indexBy(services, 'id');

        const dataTablePlanEditor = {
            selectedDoctorId, isRecommended, addedDoctors,
            stateSelectedDoctorId: this.state.selectedDoctorId, directionsMap,
            services: servicesMap
        };

        const handlesTablePlanEditor = {
            handleSelectDoctor: this.handleSelectDoctor,
            handleAddDoctor: this.handleAddDoctor,
            handleDeleteDoctor: this.handleDeleteDoctor,
            handleReplaceDoctor: this.handleReplaceDoctor,
            handleSelectRecommendedVisitDecision: this.handleSelectRecommendedVisitDecision,
        };

        return (
            <>
                <ServicesListTitle servicesListTitle={servicesListTitle}
                                   handleServicesHidden={this.handleServicesHidden}/>
                {!isServicesHidden && (
                    <>
                        <DentalRecords teethMap={teethMap}
                                       onChangeActiveTeeth={(activeTeeth: number[]) => this.setState({activeTeeth})}
                                       visitId={visit ? visit.id : undefined}
                                       parentComponentName={TREATMENT_PLAN}
                                       processedTeeth={plan.getProcessedTargetUnits()}
                        />

                        <RecommendedServices
                            recommendedServices={recommendedServices}
                            services={servicesMap}
                            servicesGroups={servicesGroups}

                            servicesAll={services}
                            addToPlan={this.handleAddRecommendedServiceToPlan}
                            plan={plan}
                            directionsMap={directionsMap}
                            selectedDoctorId={selectedDoctorId}
                            planItemDisabledTip={planItemDisabledTip}
                            handleSelectService={this.handleSelectService}
                            startWithRecommendedServices={this.startWithRecommendedServices}
                            showRecommendedServices={showRecommendedServices }
                        />
                    </>
                )}
                <TablePlanEditor data={getDataTablePlanEditor(this.props, dataTablePlanEditor)}
                                 handles={handlesTablePlanEditor}/>
            </>
        )
    }

    /*renderPriceChangeNotice() {
        return (
            <Alert text={'Стоимость плана лечения увеличилась на х рублей, сообщите об этом пациенту' }
                   icon={'call'}
            />
        );
    }*/

    handleServicesHidden = () => this.setState({isServicesHidden: !this.state.isServicesHidden});

    handleAddRecommendedServiceToPlan = (recommendedServicesDoctors: RecommendedServicesDoctorsMap) => {
        const {plan, services, recommendedServices, deleteRecommendedServices} = this.props;
        const recommendedServicesIds: number[] = [];
        const servicesMap = _.indexBy(services, 'id');

        this.addDoctors(_.values(recommendedServicesDoctors));

        _.each(recommendedServicesDoctors, (performerId, recommendedServiceId: any) => {
            const {serviceId, target} = recommendedServices[recommendedServiceId];
            const teeth = TargetFactory.create(target, servicesMap[serviceId].measure).getTeeth();

            plan.addService(serviceId, performerId, teeth);

            recommendedServicesIds.push(Number(recommendedServiceId));
        });

        plan.save();

        deleteRecommendedServices(recommendedServicesIds);
    }

    handleSelectService = (selectedServicesIds: any, serviceId: any) => {
        const {services, plan, doctors} = this.props;
        const service = services.find((service: Service) => service.id === serviceId);
        if (!service) return;

        const selectedDoctorId = this.getSelectedDoctorId();
        const doctor = doctors[selectedDoctorId];

        if (selectedServicesIds.includes(serviceId) && !!doctor && !canPerformService(doctor, service, true)) {
            // если врачу по специализации не подходит добавляемая услуга - отображаем окно выбора врача
            this.props.showModal(<ModalChooseServiceDoctor
                service={service}
                selectedDoctorId={selectedDoctorId}
                chooseDoctor={doctorId => this.handleChooseServiceDoctor(serviceId, doctorId)}
            />)
        } else {
            // иначе добавляем услугу на текущего врача
            plan.toggleService(serviceId, selectedDoctorId).save();
        }
    };

    handleChooseServiceDoctor = (serviceId: number, doctorId: number) => {
        const {selectedDoctorId} = this.state;
        const {plan} = this.props;

        if (!plan.hasPerformer(doctorId)) {
            this.addDoctor(doctorId);
        } else if (selectedDoctorId !== doctorId) {
            this.setState({
                selectedDoctorId: doctorId
            });
        }

        // добавляем услугу, если она ещё не добавлена
        plan.addService(serviceId, doctorId).save();
    }

    handleSelectRecommendedVisitDecision = (decisionOption: any) => {
        const {setRecommendedVisitDecision} = this.props;
        const {selectedDoctorId} = this.state;

        setRecommendedVisitDecision(selectedDoctorId, decisionOption.value);
    };

    handleSelectDoctor = (selectedDoctorId: any) => this.setState({selectedDoctorId});

    handleAddDoctor = () => {
        this.props.showModal(<ModalAddDoctor doctors={this.getMissingDoctorsOptions()}
                                               addDoctor={this.addDoctor}
        />)
    };

    handleDeleteDoctor = (doctorId: number) => {
        const {plan} = this.props;

        const addedDoctors = this.state.addedDoctors.filter((id: number) => id !== doctorId);
        let selectedDoctorId;

        plan.deletePerformer(doctorId).save();

        if (addedDoctors.length) {
            selectedDoctorId = addedDoctors[0]
        } else {
            selectedDoctorId = this.getPerformers(plan.getItems())[0];
        }

        this.setState({addedDoctors, selectedDoctorId});
    };

    handleReplaceDoctor = (doctorId: number, newDoctorId: number) => {
        const {plan} = this.props;
        const {addedDoctors} = this.state;
        const selectedDoctorId = this.getSelectedDoctorId();

        plan.replacePerformer(doctorId, newDoctorId).save();

        const newState: any = {
            addedDoctors: addedDoctors.filter(id => id !== doctorId).concat(newDoctorId)
        };

        if (selectedDoctorId === doctorId) {
            newState.selectedDoctorId = newDoctorId;
        }

        this.setState(newState);
    };


    /**
     * Устанавливает текущий набор зубов, выбранных в зубной карте
     * @param activeTeeth
     */
    setActiveTeeth = (activeTeeth: any) => {
        activeTeeth = Helper.clone(activeTeeth).sort();
        this.setState({activeTeeth});
    };

    addDoctor = (doctorId: any) => {
        doctorId = Number(doctorId);
        const {plan} = this.props;

        this.addDoctors([doctorId]);

        this.setState({
            selectedDoctorId: doctorId
        });

        plan.save();
    };

    addDoctors = (doctorsIds: number[]) => {
        let {addedDoctors} = this.state;
        const {plan} = this.props;

        const notAddedDoctors = _.chain(doctorsIds)
            .uniq()
            .difference(addedDoctors)
            .reject(plan.hasPerformer)
            .value();

        notAddedDoctors.forEach(plan.prepareForDoctor);

        this.setState({
            addedDoctors: addedDoctors.concat(notAddedDoctors),
        });
    }

    getPlanTableTotal() {
        const {selectedDoctorId} = this.state;
        const {currentPlanType} = this.props;

        const planItems = this.props.plan.getItems();

        let paid = 0;
        let sum = 0;

        planItems.forEach((item: any) => {
            if (item.performerId === selectedDoctorId) {
                paid += item.paid;
                sum += TreatmentPlan.getItemSum(item);
            }
        });


        const total = {
            sum: [
                {label: 'Итого', value: sum, isHighlighted: true},
            ]
        } as any;

        if (currentPlanType === 'performed') {
            total.sum.push(
                {label: 'Оплачено', value: paid},
                {label: 'Осталось оплатить', value: sum - paid}
            );
        }

        return total;
    }

    getSelectedDoctorId(performers: any = null) {
        const {selectedDoctorId} = this.state;

        if (performers === null) {
            performers = this.getPerformers();
        }

        return (selectedDoctorId === null) ? Number(performers[0]) : selectedDoctorId;
    }

    getPerformers(planItems = null) {
        const {plan, doctors} = this.props;

        if (planItems === null) {
            planItems = plan.getItems();
        }

        return TreatmentPlan.getPerformers(planItems, doctors);
    }

    getMissingDoctorsOptions() {
        const performers = this.getPerformers();
        const {doctors} = this.props;
        const {addedDoctors} = this.state;

        const notDeletedDoctors = _.filter(doctors, (doctor: any) => doctor.deletedAt === null);

        return _.chain(notDeletedDoctors)
            .map((doctor: any) => doctor.id)
            .difference(performers, addedDoctors)
            .map((missingDoctorId: any) => ({
                label: Helper.getFio(doctors[missingDoctorId]),
                value: missingDoctorId
            }))
            .sortBy(option => option.label)
            .value();
    }

    startWithRecommendedServices = () => {
        const {recommendedServices, recommendedServicesViewed, showRecommendedServices} = this.props;
        return (showRecommendedServices && !recommendedServicesViewed && _.size(recommendedServices) > 0);
    }
}

PlanEditor.defaultProps = {
    isServicesInitiallyHidden: false,
    checkItemsStatuses: false
};
