import ServerCommand from "../../../common/server/server-command";
import {calculateTimeEnd} from "../helper/calculateTimeEnd";
import _ from "lodash";
import {DataHandleSubmit} from "../types/hooks";
import {useSlideModal} from "../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {useNewVisitForm} from "../NewVisitForm";
import useLoader from "../../../components/Errors";

export default function useSubmit() {
    const {form, props, appointmentTime, isOtherPatientValue} = useNewVisitForm()
    const {removeMinimizeParams, removeSlideModal} = useSlideModal();
    const [loaderSubmit, setLoaderSubmit] = useLoader();

    const addVisit = (patientId: number | null) => {

        ServerCommand.post('visit/add', {
            timeStart: props.date + ' ' + props.timeStart,
            timeEnd: props.date + ' ' + calculateTimeEnd(props.timeStart, appointmentTime),
            workChairId: props.chairId,
            patientId: patientId,
            isSocial: form.isSocial,
            comment: form.comment
        }).then(() => {
            setLoaderSubmit(false);
            removeMinimizeParams();
            removeSlideModal();
            props.onSuccessSubmit();
            if (!!props.onEndMemorized) props.onEndMemorized()
        })

    };

    const handleSubmit = () => {
        setLoaderSubmit(true)
        let data = _.pick(form.basic, 'name', 'lastName', 'secondName', 'personalPhone', 'personalBirthday') as DataHandleSubmit;
        if (!!form.basic.phoneOwner) data.phoneOwner = form.basic.phoneOwner.value;
        if (form.basic.hasMissingTeeth !== null) data.hasMissingTeeth = form.basic.hasMissingTeeth

        if (!!Object.keys(form.relations.relations).length) {
            _.forEach(form.relations.relations, (val, key) => {
                if (data.relations === undefined) data.relations = {};
                if (!!val) data.relations[key] = val.value;
            });
        }
        if (!!form.basic.personalGender) data.personalGender = form.basic.personalGender.value;
        if (!!form.basic.source) data.source = form.basic.source.value;

        if (!!form.currentPatient) {
            data.id = form.currentPatient.id as number;
            let isChanging = false;
            _.forEach(form.currentPatient, (value, key) => {
                if (!!form.basic[key] && form.basic[key] !== value) isChanging = true
            })
            if (isChanging || ((!!form.basic.phoneOwner && form.basic.phoneOwner.value === 'other') || isOtherPatientValue)) {
                if ((!!form.basic.phoneOwner && form.basic.phoneOwner.value === 'other') || isOtherPatientValue) {
                    if (form.otherPatient.isProvidedData) {
                        const otherData = _.pick(form.otherPatient, 'name', 'lastName', 'secondName', 'personalPhone') as DataHandleSubmit;
                        otherData.phoneOwner = 'self';
                        ServerCommand.post('patient/add', otherData).then(response => {
                            if (form.basic.personalPhone === form.otherPatient.personalPhone) data.phoneOwner = response.primary.id;
                            if (data.relations === undefined) data.relations = {};
                            data.relations[response.primary.id] = form.otherPatient.relation?.value;
                            ServerCommand.post('patient/edit', data).then(() => addVisit(data.id as number))
                        })
                    } else {
                        ServerCommand.post('patient/edit', data).then(() => addVisit(data.id as number))
                    }
                } else {
                    ServerCommand.post('patient/edit', data).then(() => addVisit(data.id as number))
                }

            } else {
                addVisit(form.currentPatient.id);
            }
        } else {
            if (!!form.basic.phoneOwner && form.basic.phoneOwner.value === 'other') {
                if (form.otherPatient.isProvidedData) {
                    const otherData = _.pick(form.otherPatient, 'name', 'lastName', 'secondName', 'personalPhone') as DataHandleSubmit;
                    otherData.phoneOwner = 'self';
                    ServerCommand.post('patient/add', otherData).then(response => {
                        data.phoneOwner = response.primary.id;
                        if (data.relations === undefined) data.relations = {};
                        data.relations[response.primary.id] = form.otherPatient.relation?.value;
                        ServerCommand.post('patient/add', data).then((response) => addVisit(response.primary.id))
                    })
                } else {
                    data.phoneOwner = null;
                    ServerCommand.post('patient/add', data).then((response) => addVisit(response.primary.id))
                }
            } else {
                ServerCommand.post('patient/add', data).then((response) => addVisit(response.primary.id))
            }
        }

    };

    return {
        loaderSubmit,
        handleSubmit
    }

}
