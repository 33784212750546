import React from 'react';
import {useModal} from '../../../../../../../containers/DialogsManager/containers/Modal/Modal';
import ServerCommand from '../../../../../../../common/server/server-command';
import {StandardDeletionParams} from '../../../../../../../types';
import ConfirmModal from '../../../../../../modals/ConfirmModal';

interface Props {
    id: number
    refreshDataFromServer: () => void;
}
const DeleteScript = ({id, refreshDataFromServer}: Props) => {
    const {closeModal} = useModal()


    const deleteScript = () => {
        ServerCommand.post('call-center/script/delete', {id: id} as StandardDeletionParams, () => {
            closeModal()
            refreshDataFromServer()
        }).then()
    }

    return (
        <ConfirmModal
            descrText="Вы точно хотите удалить скрипт?"
            submitText="Удалить скрипт"
            onClose={closeModal}
            onSumbit={deleteScript}/>
    );
};

export default DeleteScript;
