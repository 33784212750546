import React from 'react';
import PlanEditor from "./PlanEditor";
import {PPlanEditorWrap} from "./types";
import {useDialogsManager} from "../../DialogsManager/DialogsManager";

const PlanEditorWrap = ({
                            teethMap,
                            planTitle,
                            servicesListTitle,
                            isServicesInitiallyHidden,
                            currentPlanType,
                            services,
                            servicesGroups,
                            doctors,
                            directions,
                            plan,
                            paymentSchedule,
                            onChangeSchedule,
                            checkItemsStatuses,
                            planItemStatuses,
                            planItemDisabledTip,
                            additionalServicesGroups,
                            quantityDivideAttractTime,
                            setFirstNotDividedItemRef,
                            recommendedVisitsDecisions,
                            setRecommendedVisitDecision,
                            patientId,
                            visit,
                            recommendedServices,
                            deleteRecommendedServices,
                            showRecommendedServices,
                            recommendedServicesViewed
                        }: PPlanEditorWrap) => {
    const {showModal} = useDialogsManager()

    return (
        <PlanEditor
            showModal={showModal}
            teethMap={teethMap}
            planTitle={planTitle}
            servicesListTitle={servicesListTitle}
            isServicesInitiallyHidden={isServicesInitiallyHidden}
            currentPlanType={currentPlanType}
            services={services}
            servicesGroups={servicesGroups}
            doctors={doctors}
            directions={directions}
            plan={plan}
            paymentSchedule={paymentSchedule}
            onChangeSchedule={onChangeSchedule}
            checkItemsStatuses={checkItemsStatuses}
            planItemStatuses={planItemStatuses}
            planItemDisabledTip={planItemDisabledTip}
            additionalServicesGroups={additionalServicesGroups}
            quantityDivideAttractTime={quantityDivideAttractTime}
            setFirstNotDividedItemRef={setFirstNotDividedItemRef}
            recommendedVisitsDecisions={recommendedVisitsDecisions}
            setRecommendedVisitDecision={setRecommendedVisitDecision}
            patientId={patientId}
            visit={visit}
            recommendedServices={recommendedServices}
            deleteRecommendedServices={deleteRecommendedServices}
            showRecommendedServices={showRecommendedServices}
            recommendedServicesViewed={recommendedServicesViewed}
        />
    );
};

export default PlanEditorWrap;