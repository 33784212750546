import styled from "styled-components";
import {ITheme} from "../../../common/Theme/Theme";

interface IBoxTitle {
    center?: boolean
}

export const BoxTitle = styled.h3<IBoxTitle>`
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 26px;
    font-weight: normal;
    text-align: ${props => props.center ? 'center' : 'left'};
`;

export const ModalTitle = styled.h3<IBoxTitle>`
    font-size: 1.75rem;
    font-weight: 600;
    font-stretch: normal;
    margin-top: 0;
    margin-bottom: 17px;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: ${props => props.center ? 'center' : 'left'};
`


interface PParagraph {
    theme?: ITheme
    type?: 'standard' | 'info',
    size?: 'sm' | 'md' | 'lg',
    margin?: string,
    padding?: string
}

const fontSizeParagraph = ({size}: PParagraph) => {
    switch (size) {
        case "sm":
            return '14px';
        case "lg":
            return '18px';
        case "md":
        default:
            return '16px';
    }
}

const fontStyleParagraph = ({type}: PParagraph) => {
    switch (type) {
        case "info":
            return 'italic'
        case "standard":
        default:
            return 'normal'
    }
}

const colorParagraph = ({type, theme}: PParagraph) => {
    switch (type) {
        case "info":
            return theme?.colors.textGray
        case "standard":
        default:
            return theme?.colors.text
    }
}

export const Paragraph = styled.p<PParagraph>`
    font-size: ${fontSizeParagraph};
    font-weight: normal;
    font-stretch: normal;
    font-style: ${fontStyleParagraph};
    line-height: 1.5;
    letter-spacing: normal;
    color: ${colorParagraph};
    margin: ${({margin}) => !!margin ? margin : 0};
    padding: ${({padding}) => !!padding ? padding : 0};
`
