import {Dispatch, SetStateAction, useCallback} from "react";
import {StateCallCenter} from "../types/components";

interface Props {
    setState: Dispatch<SetStateAction<StateCallCenter>>
}

const useDeleteTaskCalls = ({setState}:Props) => {
    return useCallback((taskId: number | number[]) => {
        setState(prevState => {
            prevState.plannedCalls = prevState.plannedCalls.map(plannedCall => {
                plannedCall.tasks = plannedCall.tasks.filter(task => typeof taskId === 'number' ? task.id !== taskId : taskId.indexOf(task.id) === -1)
                return plannedCall
            }).filter(plannedCall => !!plannedCall.tasks.length)
            if (!!prevState.detail) {
                prevState.detail.tasks = prevState.detail.tasks.filter(task => typeof taskId === 'number' ? task.id !== taskId : taskId.indexOf(task.id) === -1);
                if (prevState.detail.tasks.length === 0 && !prevState.detail.canSaveResult) {
                    prevState.detail = null;
                }
            }
            return {...prevState}
        })
    }, [setState])
}

export default useDeleteTaskCalls
