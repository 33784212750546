import React from 'react';
import {STaskList, STaskItem} from '../../../../containers/Tasks/style';
import {Divider} from "../../../../components-ui/DataDisplay/Divider";
import SubTitleTask from "../../../../containers/Tasks/components/UI/SubTitleTask";
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";

interface Props {
    comment: string | null
}

export const UserComment = ({comment}: Props) => {
    if (!comment) return null;

    return (
        <>
            <Divider/>
            <STaskList padding="19px 0 16px">
                <SubTitleTask children="Комментарий администратора" margin="0 0 7px" />
                <STaskItem>
                    <Typography children={comment} fontSize="14px" />
                </STaskItem>
            </STaskList>
        </>
    );
};
