import {Props} from "../types";

export const getFontSize = (props: Props) => {
    const {variant, fontSize} = props;
    if (!!fontSize) {
        return fontSize
    } else {
        switch (variant) {
            case "large": //40px
                return "2.5rem"
            case "bigger-title": //32px
                return "2rem"
            case "big-title":
                return "1.75rem" //28px
            case "title":
                return "1.625rem" //26px
            case "mobile-title":
                return "1.5rem" // 24px
            case "middle-title":
                return "1.375rem" //22px
            case "small-title":
                return "1.25rem" //20px
            case "min-title":
                return "1.125rem" //18px
            case "small":
                return "0.875rem" //14px
            case "min":
                return "0.75rem" //12px
            case "caption":
                return "0.5rem" //8px
            case "middle":
            default:
                return '1rem' //16px
        }
    }
}
