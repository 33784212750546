import styled from "styled-components";
import {PSWrapDateTime} from "./typesDateTime";
import Helper from "../../../common/helpers/main";

const rgbaColorBoxShadow = (color: any) => `rgba(${Helper.Color.hexToRgb(color)}, 0.25)`;

const widthModal = (props: PSWrapDateTime) => {
    return !!props.widthModal ? props.widthModal : !!props.showTimeSelect ? '335px' : '250px'
}

const marginLeftModal = (props: PSWrapDateTime) => {
    const {position, isDateRange} = props;
    const width = widthModal(props)
    switch (position) {
        case "center":
            return isDateRange ? `calc(-${width} / 2 + 50%)` : '-100px'
        case "right":
            return `calc(-${width} + 100%)`
        case "left":
        default:
            return '0'
    }
}

export const SWrapDateTime = styled.div.attrs({className: 'SWrapDateTime'})<PSWrapDateTime>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    position: relative;
    user-select: none;
    * {
        font-family: ${props => props.theme.fonts.join(', ')};
    }

    &:last-child {
        ${props => !props.margin && 'margin-bottom: 0;'};
    }

    ${props => !!props.margin && `margin: ${props.margin};`};

    span {
        width: 100px;
        word-break: break-word;
        margin-right: 25px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: ${props => props.theme.colors.text};
    }


    input {
        width: ${props => !!props.widthInput ? props.widthInput : '250px'};
        height: 40px;
        border-radius: 3px;
        border: solid 1px ${props => props.isError ? props.theme.colors.danger : props.theme.colors.borderGray};
        background-image: ${props => props.isDateRange ? props.theme.colors.white: props.theme.gradient.default};
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        user-select: none;
        letter-spacing: normal;
        color: ${props => props.theme.colors.text};
        padding: 9px 12px 9px 16px;
        cursor: pointer;
        outline: none;
        transition: all .3s;
        box-shadow: ${props => props.isError ? `0 0 0 0.2rem ${rgbaColorBoxShadow(props.theme.colors.danger)}` : 'none'};

        &:focus {
            box-shadow: ${props => props.isError ? `0 0 0 0.2rem ${rgbaColorBoxShadow(props.theme.colors.danger)}` : 'none'};
            border: solid 1px ${props => props.isError ? props.theme.colors.dangerActive : props.theme.colors.borderGrayActive};
        }

        &:hover {
            border: solid 1px ${props => props.isError ? props.theme.colors.danger : props.theme.colors.borderGrayActive};
        }
    }

    ${props => props.isDateRange && `
        input {
            height: 32px;
            font-size: 14px;
            width: 110px;
            padding: 3px 0 5px;
            text-align: center;
        }
        &:first-child {
            input {
                border-radius: 3px 0 0 3px;
            }
        }
        &:last-child {
            input {
                border-left: none;
                border-radius: 0 3px 3px 0;
            }
        }
    `}

    select {
        width: 250px;
        height: 40px;
        border-radius: 3px;
        border: solid 1px ${props => props.theme.colors.borderGray};
        background-image: ${props => props.theme.gradient.default};
    }

    .react-datepicker, .react-datepicker-popper {
        width: 100%;
    }

    .react-datepicker-popper {
        z-index: 1200;
        width: ${widthModal};
        ${props => !!props.position && `margin-left: ${marginLeftModal(props)};`};
        ${props => props.isDownOpen && `transform: translate3d(0px, 22px, 0px) !important;`};
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
        display: none;
    }

    .react-datepicker {
        display: flex;
        border: none !important;
        border-radius: 4px !important;
        box-shadow: ${props => props.theme.shadow.default};
        flex-wrap: wrap;
    }

    .react-datepicker__month-container {
        width: ${props => props.showTimeSelect ? 'calc(100% - 85px)' : '100%'};
    }
    .prompt-date-picker {
        width: 100%;
        margin: 0 4px 20px;
        padding: 5px 12px 7px;
        border-radius: 2px;
        border: solid 1px ${props => props.theme.colors.warningDark};
        background-color: ${props => props.theme.colors.warning};
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: ${props => props.theme.colors.textDarkGray};
    }

    .react-datepicker__navigation {
        top: 32px !important;
        border: 4px solid transparent !important;
        height: 6px !important;
        width: 8px !important;
        outline: none;
    }

    .react-datepicker__header {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 0 !important;
        width: 100%;
        background-color: ${props => props.theme.colors.white} !important;
        border-bottom: none !important;
    }

    .react-datepicker__header--time {
        padding: 0;
        border-bottom: solid 1px ${props => props.theme.colors.borderGray} !important;
    }
    .react-datepicker-time__header {
        padding: 3px 0;
        font-size: 14px;
        line-height: normal;
        font-weight: 600;
    }

    .react-datepicker__day-name {
        font-family: OpenSans, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43 !important;
        letter-spacing: normal;
        color: ${props => props.theme.colors.textGray} !important;
    }

    .react-datepicker__current-month {
        background-color: ${props => props.theme.colors.secondary};
        font-family: OpenSans, sans-serif;
        font-size: 12px !important;
        font-weight: normal !important;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: ${props => props.theme.colors.textDarkGray} !important;
        text-transform: capitalize;
        padding: 3px 0 4px;
    }

    .react-datepicker__day {
        font-family: OpenSans, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: ${props => props.theme.colors.text} !important;
        outline: none;

        &:hover {
            border-radius: 50% !important;
            background-color: rgba(${props => props.theme.colors.primary}, 0.3) !important;
        }
    }

    .react-datepicker__day--today {
        color: ${props => props.theme.colors.danger} !important;
    }

    .react-datepicker__day--disabled, .react-datepicker__time-list-item--disabled {
        color: ${props => props.theme.colors.borderGray} !important;

        &:hover {
            background-color: transparent !important;
        }
    }

    .react-datepicker__day--in-range {
        background-color: ${props => `rgba(${Helper.Color.hexToRgb(props.theme.colors.primary)}, 0.10)`} !important;
    }

    .react-datepicker__day--in-selecting-range {
        background-color: ${props => `rgba(${Helper.Color.hexToRgb(props.theme.colors.primary)}, 0.25)`} !important;
    }

    .react-datepicker__day--selecting-range-end, .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end,
    .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start{
        border-radius: 50% !important;
        background-color: ${props => `rgba(${Helper.Color.hexToRgb(props.theme.colors.primary)}, 0.50)`} !important;
        color: ${props => props.theme.colors.white} !important;
    }

    .react-datepicker__day--selected, .react-datepicker__day--range-start, .react-datepicker__day--range-end,
    .react-datepicker__day--in-selecting-range.react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range.react-datepicker__day--range-start,
    .react-datepicker__day--in-selecting-range.react-datepicker__day--range-end{
        border-radius: 50% !important;
        background-color: ${props => props.theme.colors.primary} !important;
        color: ${props => props.theme.colors.white} !important;
    }

    .react-datepicker__navigation--next {
        border-left-color: ${props => props.theme.colors.textDarkGray} !important;
    }

    .react-datepicker__navigation--previous {
        border-right-color: ${props => props.theme.colors.textDarkGray} !important;
    }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 95px;
    }

    .react-datepicker__day--outside-month {
        opacity: 0;
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected {
        background-color: transparent;
    }

    .react-datepicker__day.conflict-date {
        color: ${props => props.theme.colors.danger} !important;
    }

    .date-time-arrow-select {
        position: absolute;
        bottom: 17px;
        right: 12px;
        width: 10px;
        height: 8px;
        fill: ${props => props.theme.colors.textDarkGray};
        display: ${props => props.isLink || props.isDateRange ? 'none' : 'flex'};
        justify-content: center;
        align-items: center;

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:nth-child(-n+${props => props.minTimesFilter}) {
        display: none;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:nth-child(n+${props => (24*60/30 + 2) - props.maxTimesFilter}) {
        display: none;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background: ${props => props.theme.colors.primary};
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        scroll-behavior: smooth;
        margin: 0 8px 0 0;
        outline: none;
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }

        &::-webkit-scrollbar-track {
            height: 6px;
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            height: 3px;
            width: 3px;
            border-radius: 4px;
            background-color: ${props => props.theme.colors.textGray};
            transition: .4s;

            &:hover {
                background-color: ${props => props.theme.colors.textDarkGray};
            }
        }

        &::-webkit-scrollbar-button {
            width: 0;
            height: 0;
            background-color: transparent;
            opacity: 0;
            visibility: hidden;
        }
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li {
        outline: none;
    }
`
