import Target from "./Target";
import Helper from "../../../common/helpers/main";
import TargetFactory from "./TargetFactory";
import Measure from "../../../common/Service/Measure";

export default class BothJaws extends Target {

    getProcedureTargets(procedureMeasureType, teethMap) {
        let procedureTargets = [];
        let allTeeth = [];
        let procedureMeasure = new Measure(procedureMeasureType);
        Helper.forEachObj(teethMap, jawTeeth => allTeeth = allTeeth.concat(jawTeeth));

        switch(procedureMeasureType) {
            case 'TOOTH':
                procedureTargets = allTeeth.map(tooth => TargetFactory.create(tooth.number, procedureMeasureType));
                break;

            case 'JAW':
                procedureTargets = Helper.mapObj(teethMap, (jawTeeth, jawCode) => {
                    return TargetFactory.create(Helper.TeethMap.getJawNameByCode(jawCode), procedureMeasureType);
                });
                break;

            case 'SEGMENT':
                procedureTargets = [
                    TargetFactory.create(allTeeth.map(tooth => tooth.number), procedureMeasureType)
                ];
                break;

            default:
                /* остальные единицы измерения не зависят от переданного зуба */
                procedureTargets = [
                    TargetFactory.create(procedureMeasure.getTargetUnitByTooth(null), procedureMeasureType)
                ];
        }

        return procedureTargets;
    }
}