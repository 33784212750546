import { useEffect } from "react";

const useCtrlDown = (callback: any) => {

    const handleKeyUp = (e: any) => {
        if (e.which === 17) callback(false);
    };

    const handleKeyDown = (e: any) => {
        if (e.which === 17) callback(true);
    };

    useEffect(() => {
        document.addEventListener('keyup', handleKeyUp);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keyup', handleKeyUp);
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);
};

export default useCtrlDown;
