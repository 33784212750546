import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth31Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 5.45 24"} width={width || "10"} height={height || "44"} ref={ref}>
            <path d="M3.73.13H3.06c-.26,0-.54,0-.84-.06C.89-.06.32,0,.17.49A11.2,11.2,0,0,0,.33,5,4.17,4.17,0,0,0,2,7.94a1.56,1.56,0,0,0,1.43.19c1.5-.74,2.08-4.65,2-6.49C5.42.12,5.13.12,3.74.13ZM1.8,19.88c.57,2.87,1,3.81,1.25,4.12a9.94,9.94,0,0,0,.6-3.77,56.09,56.09,0,0,1,.73-9.59c.12-.8.24-1.54.31-2.31a2.54,2.54,0,0,1-.77.6,2.15,2.15,0,0,1-1,.22A3,3,0,0,1,1.41,8.7a2.79,2.79,0,0,1-.64-.49c.19,2.95.57,9.39,1,11.68Z"/>
        </WrapIcon>
    );
});
