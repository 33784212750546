import React from "react";
import _ from "underscore";
import {PMultipleList} from "../parametersInterfaces";
import {ParameterInput, MultipleWrap} from "../styledParameters";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";

export default function MultipleList({item, checkboxesValues, setCheckboxesValues}: PMultipleList | any) {

    if (checkboxesValues[item.name] === undefined) return null;

    const onChangeInput = (e: any) => {
        const newCheckboxesValues = _.clone(checkboxesValues);
        newCheckboxesValues[item.name][newCheckboxesValues[item.name].length - 1].text = e.target.value;
        setCheckboxesValues(newCheckboxesValues);
    };

    const onClickInput = (e: any) => {
        const newCheckboxesValues = _.clone(checkboxesValues);
        newCheckboxesValues[item.name][newCheckboxesValues[item.name].length - 1].isDefault = true;
        newCheckboxesValues[item.name][newCheckboxesValues[item.name].length - 1].text = e.target.value;
        setCheckboxesValues(newCheckboxesValues);
    };

    return (
        <MultipleWrap>
            {item.options.filter((option: any) => !option.isInput).map((option: any, index: number) => {
                return (
                    <Checkbox label={option.text}
                              key={option.text}
                              checked={option.isDefault} onChange={(val: boolean) => {
                                    const newCheckboxesValues = _.clone(checkboxesValues);
                                    newCheckboxesValues[item.name][index].isDefault = val;
                                    setCheckboxesValues(newCheckboxesValues);
                              }}
                    />
                )
            })}
            <div>
                <Checkbox label={''}
                          checked={checkboxesValues[item.name][checkboxesValues[item.name].length - 1].isDefault}
                          onChange={(val: boolean) => {
                              const newCheckboxesValues = _.clone(checkboxesValues);
                              newCheckboxesValues[item.name][newCheckboxesValues[item.name].length - 1].isDefault = val;
                              setCheckboxesValues(newCheckboxesValues);
                          }}/>
                <ParameterInput value={checkboxesValues[item.name][checkboxesValues[item.name].length - 1].text}
                                onChange={onChangeInput}
                                onClick={onClickInput}
                                width={310}/>
            </div>
        </MultipleWrap>
    )
}
