import React from 'react';
import FilterWidgetWrap from "../../../../components/FilterWidgetWrap";
import Button from "../../../../../../components-ui/Inputs/Button/Button";
import Tabs from "../../../../../../components-ui/Navigation/Tabs/Tabs";
import {tabsConsultations, buttonsConsultations} from "../data";
import {HandlersConsultations, StateConsultations} from "../types";

interface Props {
    state: StateConsultations
    handlers: HandlersConsultations
}

const Filter = ({state, handlers}: Props) => {
    return (
        <FilterWidgetWrap>
            <Tabs tabs={tabsConsultations} activeTab={state.currentTab} onChange={handlers.handleCurrentTab}
                  variant="buttons" margin="0 16px 0 0"/>
            <Button children={buttonsConsultations.consultationsCount.toLowerCase()}
                    margin="0 0 0 16px"
                    fontSize="14px"
                    height="32px"
                    noShadow={true}
                    variant={state.currentButton === 'consultationsCount' ? 'info' : 'standard'}
                    onClick={() => handlers.handleButton('consultationsCount')}
            />
            <Button children={buttonsConsultations.plansSums.toLowerCase()}
                    margin="0 0 0 8px"
                    fontSize="14px"
                    height="32px"
                    noShadow={true}
                    variant={state.currentButton === 'plansSums' ? 'info' : 'standard'}
                    onClick={() => handlers.handleButton('plansSums')}
            />
        </FilterWidgetWrap>
    );
};

export default Filter;
