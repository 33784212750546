import {useEffect} from 'react';
import {useSlideModal} from "../../../containers/DialogsManager/containers/SlideModal/SlideModal";

interface Props {
    needConfirmPayment: boolean | undefined
}

const useMinimizeParams = ({needConfirmPayment}: Props) => {
    const {setMinimizeParams, isMinimized} = useSlideModal();
    useEffect(() => {
        if (!!setMinimizeParams) {
            setMinimizeParams({
                icon: 'paymentsCheck',
                title: 'Проверка оплат пациентов',
                text: '',
                canBeDeleted: false,
                keySlideModal: 'check-payments',
                badgeText: !!needConfirmPayment ? '!' : ''
            })
        }
    }, [needConfirmPayment])
    return {isMinimized}
};

export default useMinimizeParams;