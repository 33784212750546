import {TaskCallCenter} from "../types/common";
import {PatientValues} from "../../../containers/PatientField/types/common";

const preparePatientWAP = (task: TaskCallCenter, personalPhone: string) => {

    return {
        id: task.targetPerson.patientId,
        lastName: task.targetPerson.lastName,
        name: task.targetPerson.name,
        personalPhone,
        secondName: task.targetPerson.secondName,
    } as PatientValues;
}

export default preparePatientWAP
