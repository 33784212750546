import React from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption} from "../../data";
import Helper from "../../../../../common/helpers/main";
import {GetDirectionsBalanceResult} from "../../types";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import Box from "../../../../../components-ui/Layout/Box";

interface Props {
    data: GetDirectionsBalanceResult
    paymentSum: number
}

const AccountBalance = ({paymentSum, data}: Props) => {
    const totalSum = +data.account + paymentSum;
    return (
        <Box position="absolute" positionOffsets={{bottom: "30px"}}>
            <Label labelText="" {...commonLabelOption}>
                <Typography variant="small" fontStyle="italic" minWidth="356px">
                    Состояние счета после оплаты {Helper.String.numberFormat(totalSum)} руб.
                </Typography>
            </Label>
        </Box>
    );
};

export default AccountBalance;
