import React from "react";
import Box from "../../../../../components-ui/Layout/Box";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import ReasonInput from "./ReasonInput";
import {useChoiceOrCompletion} from "../ChoiceOrCompletion";

export default function Reason() {
    const {form, activeTab, onChangeSelectedReason} = useChoiceOrCompletion();
    if (activeTab === 'nextVisit') return null;
    const {reasons, inputs} = form[activeTab];
    const lastReason = reasons[reasons.length - 1].optionSelect
    return (
        <>
            <Label labelText="Причина" isRequired={true} align="leftTop" widthLabel="180px" widthLabelWrap="100%"
                   marginLabel="0 20px 0 0" marginWrap="20px 0 0">
                <Box display="flex" flexDirection="column">
                    {reasons.map((selectedReason, index) => {
                        return (
                            <Select options={selectedReason.options}
                                    value={selectedReason.optionSelect}
                                    placeholder="не выбрана"
                                    widthSelect="340px"
                                    widthMenu="340px"
                                    key={selectedReason.optionSelect?.value}
                                    onChange={(optionSelect) => onChangeSelectedReason(optionSelect, index, activeTab)}
                                    marginSelect="0 0 24px"/>
                        )
                    })}
                </Box>
            </Label>
            {(!!lastReason && !!lastReason.inputs) && lastReason.inputs.map((input: string) => {
                return (
                    <ReasonInput inputName={input}
                                 input={inputs[input]} />
                )
            })}
        </>
    )
}
