import React from 'react';
import {useNewVisitForm} from "../NewVisitForm";
import Paper from "../../../containers/UI-Collection/Elements/Surfaces/Paper";
import {WrapBasicForm} from '../style/common';
import {InfoBox, InfoBoxWrap} from "../../../components-ui/styledComponent/common/wraps";
import Relations from "../components/Relations";
import OtherPatient from "../components/OtherPatient";
import Button from "../../../components-ui/Inputs/Button/Button";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import PatientField, {usePrepareValueField} from "../../../containers/PatientField";

export default function BasicForm() {
    const {
        resetForm,
        form,
        loadingPatient,
        onChangeFieldBasic,
        setForm,
        onChangeValidationBasic,
        setPatientDuplicate,
        setOtherPatientDuplicate,
        setIsDateOtherPatient,
        fields,
        shownFields
    } = useNewVisitForm();


    const prepareValueField = usePrepareValueField({basic: form.basic, fields})

    return (
        <WrapBasicForm>
            <Paper isHidden={false} zIndex={13}>
                <InfoBox>
                    <Typography variant="min-title" padding="25px 20px 11px">Пациент</Typography>
                    <InfoBoxWrap>
                        {shownFields.map((fieldName, index) => {
                            const valueField = prepareValueField({fieldName})
                            if (valueField === null) return null
                            return (
                                <PatientField {...valueField}
                                              onChangeField={onChangeFieldBasic}
                                              currentPatient={form.currentPatient}
                                              loadingPatient={loadingPatient}
                                              setForm={setForm}
                                              autoFocus={index === shownFields.length - 1}
                                              personalPhone={form.basic.personalPhone}
                                              onChangeValidationItem={onChangeValidationBasic}
                                />
                            )
                        })}
                    </InfoBoxWrap>
                    <Relations/>
                    <OtherPatient/>
                </InfoBox>
            </Paper>
            <Button children="Сбросить форму" variant="danger" onClick={() => {
                resetForm()
                setPatientDuplicate(null)
                setOtherPatientDuplicate(null)
                setIsDateOtherPatient(false)
            }} margin="28px 0 0"/>
        </WrapBasicForm>
    )
}
