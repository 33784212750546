import React from "react";
import {TaskCallCenter} from "../../types/common";
import { STaskItem, SListCare, SItemCare } from "../../../../containers/Tasks/style";
import {useSelector} from "react-redux";

interface Props {
    task: TaskCallCenter,
    isSort?: boolean
}

export const Services = ({task, isSort = false}: Props) => {
    const servicesNames = useSelector(state => state.common.servicesNames)
    if (!!task.payload.servicesIds.length) {
        let services = task.payload.servicesIds.map((servicesId: number) => (servicesNames[servicesId])) as string[]
        if (isSort) {
            services = services.sort()
        }
        return (
            <STaskItem>
                {!!services.length && (
                    <SListCare>
                        {services.map(service => {
                            return (
                                <SItemCare key={service}>
                                    {service}
                                </SItemCare>
                            )
                        })}
                    </SListCare>
                )}
            </STaskItem>
        )
    } else {
        return null
    }

}
