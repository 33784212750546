import React from 'react'
import moment from 'moment'
import Helper from "common/helpers/main";
import './ScheduleDay.scss';
import _ from 'lodash';
import {connect} from "react-redux";
import DateHelper from "../../../../common/helpers/date-helper";
import TimeLine from "../../components/TimeLine";
import SheduleBrancheDay from "./SheduleBrancheDay";
import HighlightedCellsPopover from './highlightedCellsPopover';

class ScheduleDay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.timeLineNode = React.createRef();
    }

    getMoment = (time) => {
        return moment(this.props.date + ' ' + time);
    }

    state = {
        timeHighlight: {
            timeStart: null,
            timeEnd: null
        },
        topCurTime: 0,
        highlightedCells: {chairId: 0, date: "0", arrCells: []}
    };

    changeTimeHighlight = (timeStart, timeEnd) => {

        let timeHighlight = Helper.clone(this.state.timeHighlight);

        timeHighlight.timeStart = timeStart;
        timeHighlight.timeEnd = timeEnd;

        this.setState({timeHighlight})

    };
    /*обработка клика по ячейке, для множественного выделения*/
    handleCellClick = (selectedCell) => {
        if (new Date(this.props.date) < new Date(this.props.currentDate)) return

        if (
            this.props.date === this.props.currentDate &&
            selectedCell.timeStart < this.getCurServerMoment().format("HH:mm")
        ) return

        if(selectedCell.chairId !== this.state.highlightedCells.chairId ||
            selectedCell.date !== this.state.highlightedCells.date) {
            this.setState((prevState) =>
                ({...prevState,
                    highlightedCells: {
                        chairId: selectedCell.chairId,
                        date: selectedCell.date,
                        arrCells: [{timeStart: selectedCell.timeStart, timeEnd: selectedCell.timeEnd}]}
                })
            )
        }
        else if (this.state.highlightedCells.arrCells.some((cell) =>
            selectedCell.timeStart === cell.timeStart
        )) {
            this.setState((prevState) =>
                ({...prevState,
                    highlightedCells: {...prevState.highlightedCells, arrCells: prevState.highlightedCells.arrCells.filter(cell => selectedCell.timeStart !== cell.timeStart)}
                })
            )
        } else   {
            this.setState((prevState) =>
                ({...prevState,
                    highlightedCells: {
                        ...prevState.highlightedCells,
                        arrCells: [...prevState.highlightedCells.arrCells, {timeStart: selectedCell.timeStart, timeEnd: selectedCell.timeEnd}]}
                })
            )
        }
    }


    render() {
        this.doctors = this.getDoctors();
        const timeLine = this.props.timeLine;
        const schedule = this.props.schedule.filter(chairSchedule => {
            return !Helper.isEmptyObj(chairSchedule.cells) && (!this.props.filter.speciality || chairSchedule.mainDoctors.some((doctorId) => {
                return !!this.doctors[doctorId] && !!this.doctors[doctorId].specialities && this.doctors[doctorId].specialities.includes(this.props.filter.speciality)
            }))
        });
        const curServerMoment = this.getCurServerMoment();
        const currentTime = curServerMoment.format('HH:mm');

        const heightTop = 83;

        let shadowCurDate = <span className="table__cell--shadow" style={{height: this.state.topCurTime - heightTop}}/>;

        if (this.props.isCurDay) {

            const keysTimeLine = Object.keys(timeLine);

            if (keysTimeLine[0] > currentTime) {
                shadowCurDate = null
            }
            if (keysTimeLine[keysTimeLine.length - 1] < currentTime) {
                shadowCurDate = <span className="table__cell--shadow" style={{height: `calc(100% - ${heightTop}px)`}}/>
            }
        }

        const branchesSchedule = {};

        schedule.forEach(column => {
            if (branchesSchedule[column.chair.branchId] === undefined) {
                branchesSchedule[column.chair.branchId] = []
            }
            branchesSchedule[column.chair.branchId].push(column)
        })

        return (
            <div className="dayCalendar_body-wrap" key={JSON.stringify(this.props.selectedDates)}>
                {this.props.showLeftTimeLine && <TimeLine myClassName="dayCalendar_leftTl"
                                                          timeHighlight={this.state.timeHighlight}
                                                          timeLine={timeLine}
                />}
                <div className="dayCalendar_cont" onContextMenu={this.blockEvent}>
                    <div className="dayCalendar_header">
                        <span>{this.props.dateTitle}</span>
                    </div>

                    <div className="dayCalendar_body">
                        {_.map(branchesSchedule, (schdl, branchId) => {

                            return <SheduleBrancheDay update={this.props.update}
                                                      resizeVisit={this.props.resizeVisit}
                                                      currentDate={this.props.currentDate}
                                                      uniteInterval={this.props.uniteInterval}
                                                      filter={this.props.filter}
                                                      onCopyPatient={this.props.onCopyPatient}
                                                      patients={this.props.patients}
                                                      transferPatient={this.props.transferPatient}
                                                      date={this.props.date}
                                                      clearPatientsBuffer={this.props.clearPatientsBuffer}
                                                      branchId={branchId}
                                                      commands={this.props.commands}
                                                      branches={this.props.branches}
                                                      availableTimeUnite={this.props.availableTimeUnite}
                                                      splitInterval={this.props.splitInterval}
                                                      changeTimeHighlight={this.changeTimeHighlight}
                                                      isCurDay={this.props.isCurDay}
                                                      timeLine={this.props.timeLine}
                                                      schdl={schdl}

                                                      doctors={this.doctors}
                                                      getMoment={this.getMoment}
                                                      currentTime={currentTime}
                                                      timeLineNode={this.timeLineNode}
                                                      curServerMoment={curServerMoment}
                                                      getTopCurTime={this.getTopCurTime}
                                                      shadowCurDate={shadowCurDate}

                                                      handleCellClick={this.handleCellClick}
                                                      highlightedCells={this.state.highlightedCells}

                                                      key={"branch-" + branchId + JSON.stringify(this.props.selectedDates)}

                            />
                        })}
                    </div>

                </div>
                <TimeLine
                    myClassName={'dayCalendar_rightTl' + (this.props.centerRightTimeLine ? ' dayCalendar_tl--center' : '')}
                    timeHighlight={this.state.timeHighlight}
                    timeLine={timeLine}
                    ref={this.timeLineNode}
                />
                {this.state.highlightedCells.arrCells.length ?
                    <HighlightedCellsPopover
                        highlightedCells={this.state.highlightedCells}
                        setHighlightedCells={(cells)=>{this.setState((prevState)=>({...prevState, highlightedCells: cells}))}}/> : null}
            </div>
        )
    }

    /**
     * Получает объект врачей из массива (указывает id врачей в качестве ключей)
     */
    getDoctors() {
        let result = {};

        this.props.doctors.forEach(doctor => {
            result[doctor.id] = doctor;
        });

        return result;
    }

    blockEvent(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    getCurServerMoment() {
        return moment(DateHelper.currentTime(this.props.serverTimeDiff));
    }

    getTopCurTime = (topCurTime) => {
        this.setState({topCurTime})
    }
}

const mapStateToProps = ((state) => ({
    branches: state.common.branches,
    selectedDates: state.schedule.selectedDates
}));

export default connect(mapStateToProps)(ScheduleDay)
