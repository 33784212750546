import React from "react";
import Select from "../../../components-ui/Inputs/Select/Select";
import {usePatientField} from "../containers/PatientField";
import useWidthInput from "../hooks/useWidthInput";

export default function Enum() {
    const {isEdit, field, value, onChangeField, currentPatient, error, onChangeValidationItem} = usePatientField()
    const widthSelect = useWidthInput(field)
    let children = null;
    if (isEdit) {
        let isDisabled = false;
        if (field.name === 'phoneOwner') isDisabled = !!field.disabled
        children = <Select options={field.variants}
                           value={value}
                           widthSelect={widthSelect}
                           widthMenu={!!widthSelect && !!currentPatient ? `calc(36px + ${widthSelect})` : undefined}
                           placeholder={field.placeholder}
                           isDisabled={isDisabled}
                           isError={error as boolean}
                           onChange={(newValue: string) => {
                               onChangeField(newValue, field.name)
                               if (!!onChangeValidationItem) onChangeValidationItem(field.name)
                           }}/>
    } else {
        let enumValue = '-';
        if (!!value && !!field.variants) {
            const findVariant = field.variants.find(variant => {
                if (typeof value === "string") {
                    return variant.value === value
                } else {
                    return variant.value === value.value
                }
            })
            if (!!findVariant) enumValue = findVariant.label
        }
        children = enumValue
    }

    return (
        <>
            {children}
        </>
    )
}
