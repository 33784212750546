import React from "react";
import {BtnSeparator, WrapButtons} from "../styledEditableField";
import {PropsSaveButtons} from "../typesEditableField";
import {useEditableField} from "../EditableField";
import Button from "../../../components-ui/Inputs/Button/Button";
import SVG from "../../../common/helpers/SVG";

export default function SaveButtons({onClickSave, noCanceledButton = false}: PropsSaveButtons) {
    const {setEditField} = useEditableField();

    return (
        <WrapButtons noMargin>
            <Button isIcon={true}
                    size="md"
                    variant="transparent-success"
                    children={<SVG name="check" />}
                    onClick={onClickSave}
            />
            {!noCanceledButton && (
                <>
                    <BtnSeparator />
                    <Button isIcon={true}
                            size="md"
                            variant="transparent-danger"
                            children={<SVG name="delete" />}
                            onClick={() => setEditField(false)}
                    />
                </>
            )}
        </WrapButtons>
    )
}
