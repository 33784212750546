import {useEffect, useMemo, useState} from "react";
import {allAdmins} from "../../../../../PatientsList/data";
import {StateCallsEfficiency} from "../types";
import {CallsEfficiencyReport} from "../../../../types";
import {OptionSelect} from "../../../../../../components-ui/Inputs/Select/typesSelect";
import {useSelector} from "react-redux";
import {sortName} from "../../../../../../common/helpers/graph-helper";
import useHandlersCallsEfficiency from "./useHandlersCallsEfficiency";

interface Props {
    report: CallsEfficiencyReport
}

const useCallsEfficiency = ({report}: Props) => {
    const users = useSelector(state1 => state1.common.users);
    const [state, setState] = useState<StateCallsEfficiency>({
        currentTab: 'incoming',
        currentSelected: allAdmins
    })

    useEffect(() => setState(prevState => ({...prevState, currentSelected: allAdmins})),[state.currentTab])

    useEffect(() => {
        if (!!report.failedCalls.length) {
            if (state.currentTab === 'outgoing' && !report.count.outgoing) {
                setState(prevState => ({...prevState, currentTab: 'incoming'}))
            } else if (state.currentTab === 'incoming' && !report.count.incoming) {
                setState(prevState => ({...prevState, currentTab: 'outgoing'}))
            }
        }
    }, [JSON.stringify(report.count)])

    const optionsSelected = useMemo(() => {
        let res: OptionSelect[] = [allAdmins]
        if (!!report.count[state.currentTab]) {
            const optionsUsers: OptionSelect[] = Object.keys(report.count[state.currentTab]).filter(userId => userId !== 'general').map(userId => ({
                value: userId,
                label: users[+userId].fio
            })).sort((a, b) => sortName(a.label, b.label))
            res = res.concat(optionsUsers)
        }
        return res
    }, [JSON.stringify(report.count), state.currentTab])

    useEffect(() => {
        if (state.currentSelected.value !== allAdmins.value && !optionsSelected.some(option => option.value === state.currentSelected.value)) {
            setState(prevState => ({...prevState, currentSelected: allAdmins}))
        }
    }, [JSON.stringify(optionsSelected)])

    return {
        state,
        optionsSelected,
        handlers: useHandlersCallsEfficiency({setState})
    }

}

export default useCallsEfficiency
