import React, {useEffect, useState} from "react";
import {useDentalCard} from "../../DentalRecords";
import {DentalCardTooth, ItemChangeConfig, SDentalCard} from "../../types";
import { SWrapChangeConfig, STextChangeConfig, SFooterChangeConfig, SStatusText } from "./styledChangeConfig";
import Paper from "../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import SVG from "../../../../common/helpers/SVG";
import Checkbox from "../../../../components-ui/Inputs/Checkbox/Checkbox";
import Button from "../../../../components-ui/Inputs/Button/Button";
import _ from "underscore";
import changeItemConfig from "../../helper/changeItemConfig";

export default function ChangeConfig() {
    const {changeConfig, config, teethMap, setChangeConfig, setConfig, saveTeethMap} = useDentalCard() as SDentalCard;
    const {statusList, statusIndependentAttributes} = teethMap;
    const [check, setCheck] = useState(false);

    useEffect(() => {
        if (check) setCheck(false)
    }, [changeConfig])

    if (!changeConfig.length) return null;
    const newConfig = _.indexBy(_.flatten(_.values(_.clone(config))), 'number');
    const itemConfig = _.clone(changeConfig[0]);
    const currentItem = _.extend(itemConfig, statusList[itemConfig.status]);
    const prevItem = _.extend(newConfig[itemConfig.number], statusList[newConfig[itemConfig.number].status])

    const renderStatus = (item: any) => {
        let text = item.code;
        if (item.group === "HEALTHY") text = item.decode;
        return <SStatusText status={item.group} children={text}/>
    }

    const onChangeSetConfigCheck = (handler: (item: DentalCardTooth, objItem: ItemChangeConfig) => void) => {
        setConfig(prevState => {
            const objChangeConfig = _.indexBy(changeConfig, 'number');
            _.each(prevState, (jaw, jawName) => {
                jaw.forEach(item => {
                    const objItem = objChangeConfig[item.number];
                    if (objItem !== undefined) {
                        handler(item, objItem)
                    }
                })
            })
            return {
                ...prevState
            }
        });
    }

    const onChangeSetConfigNoCheck = (handler: (item: DentalCardTooth) => void) => {
        setConfig(prevState => {
            _.each(prevState, (jaw, jawName) => {
                jaw.forEach(item => {
                    if (item.number === currentItem.number) {
                        handler(item)
                    }
                })
            })
            return {
                ...prevState
            }
        });
    }

    const onClickChangeButton = () => {
        if (check) {
            onChangeSetConfigCheck((item, objItem) => changeItemConfig(item, objItem, statusIndependentAttributes))
            setChangeConfig([])
        } else {
            onChangeSetConfigNoCheck(item => changeItemConfig(item, currentItem, statusIndependentAttributes))
            setChangeConfig(_.rest(changeConfig))
        }
        saveTeethMap()
    }

    const onClickSkipButton = () => {
        const handlerSetConfig = (item: DentalCardTooth, nexItem: ItemChangeConfig) => {
            const itemStatus = statusList[item.status];
            const currentItemStatus = statusList[nexItem.status]
            if (itemStatus.group === currentItemStatus.group) {
                changeItemConfig(item, nexItem, statusIndependentAttributes)
            } else {
                if (!!nexItem.attributes) {
                    item.attributes = nexItem.attributes.filter((attribute: number) => statusIndependentAttributes.indexOf(attribute) !== -1)
                } else {
                    delete item.attributes
                }
            }
        }
        if (check) {
            onChangeSetConfigCheck((item, objItem) => {
                handlerSetConfig(item, objItem)
            })
            setChangeConfig([])
        } else {
            onChangeSetConfigNoCheck((item) => {
                handlerSetConfig(item, currentItem)
            })
            setChangeConfig(_.rest(changeConfig))
        }
        saveTeethMap()

    }

    return (
        <SWrapChangeConfig>
            <Paper padding="24px 24px 28px">
                <SVG name="warning" />
                <STextChangeConfig>
                    Текущий статус зуба <b>{currentItem.number}</b> - «{renderStatus(prevItem)}», из Диагнокат загружен статус «{renderStatus(currentItem)}».
                    <br/>
                    Заменить статус?
                </STextChangeConfig>
                <SFooterChangeConfig>
                    <Checkbox label="Для всех" checked={check} onChange={setCheck} />
                    <div>
                        <Button children="Заменить"
                                onClick={onClickChangeButton}
                                variant="success"
                                size="sm"
                                width="125px"
                                margin="0 15px"/>
                        <Button children="Пропустить"
                                onClick={onClickSkipButton}
                                variant="default"
                                size="sm"
                                width="125px" />
                    </div>
                </SFooterChangeConfig>
            </Paper>
        </SWrapChangeConfig>
    )
}
