import React from 'react';
import TaskContent from "../TaskContent";
import {TaskCallCenter} from "../../../../types/common";
import {WaitingListData} from "../../../../types/taskData";
import {FreedTime, PatientWishes, UniquePatient} from "../../../../components/Labels";
import {DeleteWLButton, NoteDownButton, RefuseButton} from "../../../../components/Buttons";
import {CommonTaskProps} from "../../types/common";

interface Props extends CommonTaskProps {
    task: TaskCallCenter<WaitingListData>
}

export const WaitingList = ({task, uniqPatient, infoCallCenter, onChangeSelectedScript, ...props}: Props) => {
    return (
        <TaskContent onChangeSelectedScript={onChangeSelectedScript} buttons={<Buttons task={task} {...props}/>}
                     task={task}>
            <UniquePatient task={task} uniqPatient={uniqPatient}/>
            <PatientWishes task={task}/>
            <FreedTime task={task}/>
        </TaskContent>
    );
};

const Buttons = ({
                     task,
                     validate,
                     blockedButtons,
                     methodsCallCenter
                 }: Omit<Props, 'uniqPatient' | 'infoCallCenter' | 'onChangeSelectedScript'>) => {

    return (
        <>
            <NoteDownButton validate={validate} task={task} blockedButtons={blockedButtons}
                            methodsCallCenter={methodsCallCenter}/>
            <RefuseButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
            <DeleteWLButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
        </>
    )
}
