import React from 'react';
import {ManagerTask} from "../../types/common";
import {VisitRefusalPayload} from "../../types/taskData";
import {STaskList} from '../../../../containers/Tasks/style';
import {Patients, TaskTypes, UserComment, AudioRecordButton, ContactPhone} from "../Labels";
import {ButtonsWrap, ExcludeFromStatistics} from "../Buttons";
import {SuccessfulCall} from "../Buttons/SuccessfulCall";

interface Props {
    task: ManagerTask<VisitRefusalPayload>
    renderBranches: () => React.ReactNode | null
}

const VisitRefusal = ({task, renderBranches}: Props) => {
    return (
        <>
            <STaskList padding="19px 0 16px">
                {renderBranches()}
                <TaskTypes tasksTypes={task.payload.call.tasksTypes}/>
                <Patients patients={task.payload.patients}/>
                {!task.payload.patients.length && <ContactPhone contactPhone={task.payload.call.contactPhone}/>}
                <AudioRecordButton recordLink={task.payload.call.recordLink}/>
            </STaskList>
            <UserComment comment={task.payload.call.comment}/>
            <ButtonsWrap>
                <ExcludeFromStatistics callId={task.payload.call.id}/>
                <SuccessfulCall callId={task.payload.call.id}/>
            </ButtonsWrap>
        </>

    );
};

export default VisitRefusal;
