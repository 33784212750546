import {StateCallCenter} from "../../../types/components";
import {InfoCallCenter} from "../../../types/hooks";
import useChangeScriptsVisibility from "./useChangeScriptsVisibility";
import useSearchScript from "./useSearchScript";
import useSelectedScript from "./useSelectedScript";

interface Props {
    state: StateCallCenter
    infoCallCenter: InfoCallCenter
}

export const useScripts = ({state, infoCallCenter}: Props) => {
    const {isMarketing, detail} = state;
    const {scriptsType, openScript, setScriptsType} = useChangeScriptsVisibility({isMarketing, detail})
    const {groupsScript, setSearchScript, searchScript} = useSearchScript({infoCallCenter, isMarketing})
    const {selectedScript, onChangeSelectedScript, setSelectedScript} = useSelectedScript({groupsScript})


    return {
        onChangeSelectedScript,
        setScriptsType,
        scriptsType,
        openScript,
        selectedScript,
        setSelectedScript,
        searchScript,
        setSearchScript,
        groupsScript
    }
}


