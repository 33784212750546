import {useMemo} from "react";
import {useNewVisitForm} from "../NewVisitForm";
import useValidateForm from "../../../containers/PatientField/hooks/useValidateForm";
import _ from 'lodash'

export default function useValidationOfFields() {
    const {form, isOtherPatientValue, isDateOtherPatient, fields, shownFields} = useNewVisitForm()
    const {basic, otherPatient, relations} = form
    const validate = useValidateForm({fields})

    return useMemo(() => {
        let relationValidate = true;
        if (Object.keys(relations.relations).length > 0) {
            Object.values(relations.relations).forEach(relation => {
                if (relation === null) relationValidate = false;
            })
        }

        let otherPatientForm = _.cloneDeep(otherPatient);
        if (!isDateOtherPatient) {
            delete otherPatientForm.personalBirthday
        }

        const otherPatientValidate = (((!!basic.phoneOwner && basic.phoneOwner.value === 'other') || isOtherPatientValue)
            && otherPatientForm.isProvidedData) ? validate(otherPatientForm, _.keys(otherPatientForm)) : true

        return validate(form.basic, shownFields) && relationValidate && otherPatientValidate


    }, [form, isOtherPatientValue, isDateOtherPatient])
}
