import React from 'react';
import PropTypes from "prop-types";
import Helper from "../../common/helpers/main";
import {formatFioAndCardNumber} from "../../common/helpers/Patient";

export default class KanbanCard extends React.Component {

    static propTypes = {
        visit: PropTypes.object.isRequired,
        doctor: PropTypes.object.isRequired,
        isMinimized: PropTypes.bool,
        addBgColor: PropTypes.func,
        removeBgColor: PropTypes.func,
        renderHeader: PropTypes.func,
        renderButton: PropTypes.func,
        renderModal: PropTypes.func,
        stopScroll: PropTypes.func,
    };

    static defaultProps = {
        renderHeader: () => null,
        renderButton: () => null,
        renderModal: () => null
    };


    render() {
        const visit = this.props.visit;
        const doctor = this.props.doctor;

        return (
            <>
                <div className={`kanban-card ${this.addClassCard()}`} onMouseOver={() => this.onMouseOver()}>
                    {this.props.renderHeader()}
                    <div className="kanban-card__content">
                        <div className="kanban-card__info-blocks">
                            <div className="kanban-card__info-block">
                                <p className="kanban-card__info-block-title">Пациент</p>
                                <p className="kanban-card__text">{formatFioAndCardNumber(visit.patient)}</p>
                            </div>
                            <div className="kanban-card__info-block">
                                <p className="kanban-card__info-block-title">Врач</p>
                                <p className="kanban-card__text">{Helper.getFio(doctor)}</p>
                            </div>
                        </div>
                        {this.props.renderButton()}
                        <div className="kanban-card__doc-status" style={{backgroundColor: doctor.color}}/>
                    </div>
                </div>
                {this.props.renderModal()}
            </>
        )
    }

    addClassCard() {
        let classCard = '';
        if (this.props.visit.bgColor !== undefined) {
            if (this.props.visit.bgColor) {
                classCard = 'kanban-card--active'
            }
        }
        return classCard
    }

    onMouseOver() {
        if (this.props.visit.bgColor !== undefined) {
            if (this.props.visit.bgColor && !this.props.isMinimized) {
                this.props.removeBgColor(+this.props.visit.id);
            }
        }
    }

}
