import React, {useEffect} from "react";
import Helper from "../../../common/helpers/main";
import ServerCommand from "../../../common/server/server-command";
import {Field, Fields, InitialSurveyResponse, PHandlers, PLoadData} from "../types";

/** Сохраняет начальные данные, полученные с сервера (данные зубной карты и набор полей первичного осмотра) в state*/
export default function useLoadData(props: PLoadData, {setState, setSnapshot, setValues} : PHandlers) {
    const {dateCreate, loadData, getLoadCommandData} = props;

    const saveResponse = (props: InitialSurveyResponse) => {
        const {initialSurvey, actualTeethMap, doneDuties, fields, templatesGroupsList, templatesList} = props;

        if (!!dateCreate && initialSurvey !== null) dateCreate(initialSurvey.dateCreate);

        let newState = {
            fields,
            teethMap: actualTeethMap,
            templatesList: templatesList,
            templatesGroupsList: templatesGroupsList
        } as any;

        /* преобразование списка статусов из массива в объект для удобства поиска конкретного статуса по id */
        newState.teethMap.statusList = Helper.Array.toObject(actualTeethMap.statusList.map((status, index) => {
            status.sort = 100 + index;
            return status;
        }), 'id');

        setState((prevState: any) => ({...prevState, ...newState}));

        /* если пришёл первичный осмотр, нужно загрузить его поля и зубную карту в state */
        if (!!initialSurvey) {
            let values = {} as Fields;
            /* копируем данные из первичного осмотра в fieldsValues */
            Helper.forEachObj(fields, (field: Field, fieldName: string) => values[fieldName] = initialSurvey[fieldName]);

            setValues(values)

            if (doneDuties !== undefined) {
                if (doneDuties.indexOf('print-initial-survey') > -1) newState.nextBtnActive = true;
            }

            setSnapshot({
                teethMap: Helper.clone(newState.teethMap.config),
                values
            })

        }
    };

    useEffect(() => {
        if (!!loadData) {
            saveResponse(loadData);
        } else {
            ServerCommand.get('initial-survey/save-form', getLoadCommandData()).then(saveResponse);
        }
    }, []);
}
