import React, {useMemo} from 'react';
import {SContainerItem} from '../../../../containers/Tasks/style';
import Select from "../../../../components-ui/Inputs/Select/Select";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";
import _ from 'lodash'
import Helper from "../../../../common/helpers/main";
import {allUser, clinicUser} from "../../helper/getDataManagerTasks";
import {ManagerTask} from "../../types/common";
import {useSelector} from "react-redux";

interface Props {
    tasks: ManagerTask[]
    selectedUser: OptionSelect
    handleSelectedUser: (selectedUser: OptionSelect) => void
}

const SelectedUser = ({selectedUser, handleSelectedUser, tasks}: Props) => {
    const users = useSelector(state => state.common.users)
    const options = useMemo(() => {
        const usersOptions = _.map(users, (user) => {
            return {
                label: Helper.getFio(user),
                value: user.id,
                color: user.color,
                user
            }
        }).filter(user => tasks.some(task => task.employeeId === user.value))
            .sort((a, b) => {
                const nameA = a.label.toLowerCase(), nameB = b.label.toLowerCase();
                if (nameA < nameB) //сортируем строки по возрастанию
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0 // Никакой сортировки
            });
        return [allUser, clinicUser].concat(usersOptions)
    }, [users, JSON.stringify(tasks)])
    return (
        <SContainerItem padding="7px 20px 8px">
            <Select widthSelect="100%"
                    height="30px"
                    label="Сотрудники:"
                    value={selectedUser}
                    onChange={handleSelectedUser}
                    widthLabel="86px"
                    options={options}
                    variant="colored"
                    sizeSelect="sm"
                    colorLabel="textGray"/>
        </SContainerItem>
    );
};

export default SelectedUser;
