import React from 'react'
import PropTypes from 'prop-types'
import './TreatmentStep.scss'
import TreatmentProcess from "./TreatmentProcess"
import ServerCommand from 'common/server/server-command'
import Helper from "common/helpers/main";
import IDS from "./IDS";
import {Alert} from "../../../../components-ui/DataDisplay/Alert";
import Box from "../../../../components-ui/Wraps/old/Box";
import Tabs from "../../../../components-ui/Navigation/Tabs/Tabs";
import Arr from "../../../../common/helpers/Arr";

export default class TreatmentStep extends React.Component {
    static propTypes = {
        goToNextStep: PropTypes.func.isRequired,
        visitId: PropTypes.number.isRequired,
        patientId: PropTypes.number.isRequired,
        nextStep: PropTypes.string.isRequired
    };

    state = {
        printBtn: true,
        servicesGroups: null,
        plan: null,
        materials: null,
        materialsGroups: null,
        currentSubTabCode: null,
        doctors: null,
        invoiceReadyDirections: null,
        hasInvoicesToPrint: false,
        createdInvoicesDirections: null
    };

    render() {
        if (this.state.plan === null) return null;

        let subStepComponent = null;
        const subTabs = this.getSubTabs();

        switch (this.state.currentSubTabCode) {
            case 'IDS':
                subStepComponent = <IDS
                    plan={this.state.plan}
                    goToNextSubStep={this.goToNextSubStep}
                    visitId={this.props.visitId}/>;

                break;

            case 'TREATMENT_PROCESS':
                subStepComponent = <TreatmentProcess
                    visitId={this.props.visitId}
                    materials={this.state.materials}
                    materialsGroups={this.state.materialsGroups}
                    planItems={this.state.plan.items}
                    planItemsStatuses={this.state.plan.itemsStatuses}
                    servicesGroups={this.state.servicesGroups}
                    goToNextSubStep={this.goToNextSubStep}
                    setPlanItems={this.setPlanItems}
                    nextStep={this.props.nextStep}
                    enableValidation={false}
                    doctors={this.state.doctors}
                    directions={this.state.directions}
                    proceduresCombinations={this.state.proceduresCombinations}
                    invoiceReadyDirections={this.state.invoiceReadyDirections}
                    hasInvoicesToPrint={this.state.hasInvoicesToPrint}
                    createdInvoicesDirections={this.state.createdInvoicesDirections}
                />;
                break;
            default:
                break;

        }
        return (
            <React.Fragment>
                <Alert children="Распечатайте необходимые документы для пациента. В процессе лечения, отметьте выполненные услуги."/>
                <Box>
                    {(subTabs.length > 1) && (
                        <Tabs
                            activeTab={this.state.currentSubTabCode}
                            tabs={subTabs}
                            onChange={(currentSubTabCode) => this.setState({currentSubTabCode})}
                            disabled={this.state.currentSubTabCode !== 'TREATMENT_PROCESS'}
                            borderBottom={true}
                        />
                    )}
                    {subStepComponent}
                </Box>
            </React.Fragment>
        )
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        ServerCommand.get('visit/treatment-step-form', {visitId: this.props.visitId}).then(response => {
            let newState = {
                plan: response.plan,
                servicesGroups: response.servicesGroups,
                /*materials: Arr.toObject(response.materials, 'id'),
                materialsGroups: response.materialsGroups,*/
                doctors: Arr.toObject(response.doctors, 'id'),
                directions: Arr.toObject(response.directions, 'code'),
                proceduresCombinations: Arr.toObject(response.proceduresCombinations, 'id'),
                invoiceReadyDirections: response.invoiceReadyDirections,
                hasInvoicesToPrint: response.hasInvoicesToPrint,
                createdInvoicesDirections: response.createdInvoicesDirections,
            };

            if(this.state.currentSubTabCode === null) {
                newState.currentSubTabCode = (newState.plan.availableConsents.length > 0) ? 'IDS' : 'TREATMENT_PROCESS';
            }

            this.setState(newState);
        });
    };

    handleUpdateEvent = (params) => {
        if(params.visitId === this.props.visitId) {
            this.loadData();
        }
    };

    goToNextSubStep = () => {
        let bWasCurrent = false;
        let newSubStepCode = null;

        this.getSubTabs().forEach(subStep => {
            if (bWasCurrent) {
                if (!newSubStepCode) {
                    newSubStepCode = subStep.code;
                }
            } else if (subStep.code === this.state.currentSubTabCode) {
                bWasCurrent = true;
            }
        });


        if (newSubStepCode) {
            this.setState({
                currentSubTabCode: newSubStepCode,
            });
        } else {
            this.props.goToNextStep();
        }
    };

    getSubTabs() {
        let subTabs = [];

        if (Object.keys(this.state.plan.availableConsents).length > 0) {
            subTabs.push({name: 'ИДС', code: 'IDS'});
        }

        subTabs.push({name: 'Лечение', code: 'TREATMENT_PROCESS'});

        return subTabs;
    }

    setPlanItems = planItems => {
        let plan = Helper.clone(this.state.plan);
        plan.items = planItems;
        this.setState({plan});
    };
}
