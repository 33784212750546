import {ActionsReducer, PatientStore} from "../types/common";
import {LOAD_PATIENT_DATA} from "../actions/actionConstants";

const initialState: PatientStore = {
    fields: {
        fields: {},
        group: []
    },
    relationTypesTitles: {}
}

export default function patientReducer(state: PatientStore = initialState, action: ActionsReducer) {
    switch (action.type) {
        case LOAD_PATIENT_DATA:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}
