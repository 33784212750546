import {
    ADD_MEMORIZED_PATIENT,
    LOAD_SCHEDULE,
    REMOVE_MEMORIZED_PATIENT,
    FILTER_SET_DOCTOR,
    FILTER_SET_RANGE,
    FILTER_SET_SWITCH,
    FILTER_RESET,
    LOAD_CALENDAR,
    CALENDAR_DAY_CLICK,
    UPDATE_SCHEDULE,
    UPDATE_SPLITTED_TIME,
    ADD_TRANSFER_PATIENT,
    REMOVE_TRANSFER_PATIENT,
    FILTER_SET_BRANCHES,
    LOAD_SCHEDULE_USER,
    FILTER_SET_SPECIALITIES
} from "../actions/actionConstants";
import {ICalendar, ISchedule} from "../../pages/Schedule/types";
import Helper from "../../common/helpers/main";
import {loadSplittedTime} from "./schduleHelperRedux";
import {ActionsReducer} from "../types/common";

interface IFilter {
    branches: null | number[]
    timeFrom: string
    timeTo: string
    doctorId: string | number
    speciality: string
}

export interface scheduleR {
    copiedPatient?: any,
    schedule?: ISchedule | any,
    calendar?: ICalendar | any,
    splittedTime?: any,
    transferPatient?: null | any,
    timeLine?: any,
    selectedDates: string | string[]
    switcher?: boolean,
    filter: IFilter,
    snapshotFilter: IFilter
    initialDate: string
}

const initialFilter: IFilter = {
    branches: [],
    timeFrom: '',
    timeTo: '',
    doctorId: 0,
    speciality: ''
}

const initialState: scheduleR = {
    copiedPatient: null,
    schedule: null,
    calendar: null,
    filter: initialFilter,
    snapshotFilter: initialFilter,
    splittedTime: loadSplittedTime(),
    transferPatient: null,
    timeLine: null,
    selectedDates: [],
    initialDate: ''
};

export default function scheduleReducer(state: scheduleR = initialState, action: ActionsReducer) {
    let newState = Helper.clone(state);
    switch (action.type) {
        case LOAD_SCHEDULE_USER:
            const {user, initialDate} = action.payload;
            const filter = {
                branches: user.branches,
                doctorId: user.role === 'doctor' ? user.doctorId : 0,
                speciality: ''
            }
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...filter
                },
                snapshotFilter: {
                    ...state.snapshotFilter,
                    ...filter
                },
                selectedDates: [initialDate],
                initialDate
            }
        case LOAD_SCHEDULE:
            const commonProps = {} as {[key: string]: any};
            if (action.payload.isLoadFilter) {
                const {user, schedule} = action.payload;
                const filter = {
                    ...state.filter,
                    branches: user.branches,
                    timeFrom: schedule.timeLimits.start,
                    timeTo: schedule.timeLimits.end,
                    doctorId: user.role === 'doctor' ? user.doctorId : 0,
                    speciality: ''
                }
                commonProps.filter = filter
                commonProps.snapshotFilter = filter
            }
            return {
                ...state,
                ...commonProps,
                schedule: action.payload.schedule,
                calendar: action.payload.calendar.data,
                selectedDates: action.payload.isLoadFilter ? [state.initialDate] : action.payload.selectedDates,
                switcher: false,
            };

        case UPDATE_SCHEDULE:
            return {...state, schedule: action.payload};

        case UPDATE_SPLITTED_TIME:
            return {...state, splittedTime: action.payload};

        case FILTER_SET_BRANCHES:
            return {
                ...state, filter: {
                    ...state.filter,
                    branches: action.payload
                }
            }
        case FILTER_SET_SPECIALITIES:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    speciality: action.payload
                }
            }
        case FILTER_SET_DOCTOR:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    doctorId: action.payload
                }
            };

        case FILTER_SET_RANGE:
            newState.filter.timeTo = action.payload[1];
            newState.filter.timeFrom = action.payload[0];
            return {...newState};

        case FILTER_SET_SWITCH:
            newState.switcher = !newState.switcher;
            return {...newState};

        case FILTER_RESET:
            newState.filter = newState.snapshotFilter;
            return {...newState};

        case LOAD_CALENDAR:
            return {...state, calendar: action.payload};

        case CALENDAR_DAY_CLICK:
            newState.selectedDates = action.payload;
            return {...newState};

        case ADD_MEMORIZED_PATIENT:
            return {...state, copiedPatient: action.payload};

        case REMOVE_MEMORIZED_PATIENT:
            return {...state, copiedPatient: null};

        case ADD_TRANSFER_PATIENT:
            return {...state, transferPatient: action.payload};

        case REMOVE_TRANSFER_PATIENT:
            return {...state, transferPatient: null};

        default:
            return state;
    }
}
