import React from "react";
import Checkbox from "../../../../../components-ui/Inputs/Checkbox/Checkbox";
import Paper from "../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import {InfoBox, InfoBoxWrap} from "../../../../../components-ui/styledComponent/common/wraps";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import {usePreparationForAdmission} from "../PreparationForAdmission";

export default function Documents() {
    const {props, onChangeCheckbox, state} = usePreparationForAdmission();

    const renderDocuments = props.data.documents
        .sort((a, b) => (a.isRequired === b.isRequired) ? 0 : a.isRequired ? -1 : 1)
        .map((document) => {
            let label = [document.typeName] as any[];
            if (state.documentRequired[document.type]) label.push(<span className="text-danger"> *</span>);
            return (
                <div style={{marginBottom: '15px'}} key={document.type}>
                    <Checkbox label={label}
                              onChange={(checked) => onChangeCheckbox(checked, document.type)}
                              checked={state.checkboxes[document.type]}
                    />
                </div>
            )
        });

    return (
        <Paper minWidth="100%" margin="0 0 24px">
            <InfoBox>
                <Typography variant="min-title" padding="25px 20px 11px">Документы для печати</Typography>
                <InfoBoxWrap>
                    {renderDocuments}
                </InfoBoxWrap>
            </InfoBox>
        </Paper>
    )
}
