import React, {useRef, useState} from 'react';
import {Icon} from "../../../components/Icons/Icons";
import Checkbox from "../Checkbox/Checkbox";
import {SWrapSelectCheckbox, SSelectCheckbox, SSelectCheckboxSpan, SSelectCheckboxMenu, SSelectCheckboxMenuHeader, SSelectCheckboxMenuContent} from './style'
import {PropsSelectCheckbox, ISelectCheckboxOption} from "./types";
import _ from 'lodash'
import useOutsideClick from "../../../common/hooks/useOutsideClick";
import {List, ListItem} from "../../DataDisplay/List";

const SelectCheckbox = (
    {
        options,
        selected,
        onChange,
        placeholder,
        headerMenu,
        widthOption = '100%',
        margin,
        flexDirectionMenu = 'column',
        width,
        height,
        variant = 'md'
    }: PropsSelectCheckbox) => {
    const [open, setOpen] = useState(false)
    const ref = useRef(null) as any;

    useOutsideClick(ref, () => setOpen(false))

    const labels = selected.map(select => (select.label));

    const handleCheckboxChange = (option: ISelectCheckboxOption, checked: boolean) => {
        option = {
            ...option,
            value: String(option.value)
        }

        let checkedOptions = _.cloneDeep(selected);

        if (!checked) {
            checkedOptions.splice(_.findIndex(checkedOptions, opt => String(opt.value) === String(option.value)), 1);
        } else {
            checkedOptions.push(option);
        }
        onChange(checkedOptions);
    }

    return (
        <SWrapSelectCheckbox ref={ref} margin={margin} width={width}>
            <SSelectCheckbox onClick={() => setOpen(!open)} width={width} height={height} variant={variant}>
                <SSelectCheckboxSpan active={!!labels.length}
                                     title={(labels.length > 5) && labels.join(', ')}
                                     children={!!labels.length ? labels.join(', ') : placeholder}
                />
                <Icon icon="new-arrow-select"/>
            </SSelectCheckbox>
            {open &&
            <SSelectCheckboxMenu width={width}>
                {!!headerMenu && <SSelectCheckboxMenuHeader children={headerMenu} />}
                    <List flexDirection={flexDirectionMenu}>
                        {options.map((option, index) => {
                            const checked = selected.some(select => select.value === option.value)
                            return (
                                <ListItem onClick={() => handleCheckboxChange(option, !checked)} width={widthOption} size={variant}>
                                    <Checkbox key={option.value} label={option.label} checked={checked} size={variant}/>
                                </ListItem>
                            )
                        })}
                    </List>
            </SSelectCheckboxMenu>
            }
        </SWrapSelectCheckbox>
    )
}

export default SelectCheckbox
