import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../../components-ui/OldButton/Button'
import './StepFooter.scss'


export default class StepFooter extends React.PureComponent {

    static propTypes = {
        nextBtnName: PropTypes.string.isRequired,
        printBtnName: PropTypes.string,
        showPrintBtn: PropTypes.bool,
        onPrintButtonClick: PropTypes.func,
        nextBtnActive: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        onNextButtonClick: PropTypes.func.isRequired,
        printBtnActive: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]) ,
        printBtnLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        nextBtnLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        showLabel: PropTypes.bool,
        variantDisabledClass: PropTypes.bool,
        disabledTip: PropTypes.string,
        printBtnDisabledTip: PropTypes.string,
        loaderPrint: PropTypes.bool,
        loaderNextBtn: PropTypes.bool
    };

    static defaultProps = {
        showPrintBtn: false,
        printBtnActive: true,
        nextBtnActive: true,
        showLabel: false,
        variantDisabledClass: false,
        printBtnName: 'Распечатать',
        loaderPrint: false,
        loaderNextBtn: false
    };

    render() {
        const nextBtnVariant = (this.props.nextBtnActive) ? 'success' : 'disabled';
        const printBtnVariant = (this.props.printBtnActive) ? 'success' : 'disabled';

        return (
            <div className="step-footer">
                {this.props.showPrintBtn &&
                <div className="step-footer__item">
                    <Button variant={printBtnVariant}
                            text={this.props.printBtnName}
                            action="print"
                            align="left"
                            onClick={this.props.onPrintButtonClick}
                            disabledTip={this.props.printBtnDisabledTip}
                            loader={this.props.loaderPrint}
                    />
                    {this.renderPrintBtnLabel()}
                </div>
                }
                <div className="step-footer__item">
                    <Button variant={nextBtnVariant}
                            text={this.props.nextBtnName}
                            onClick={this.props.onNextButtonClick}
                            disabledTip={this.props.disabledTip}
                            loader={this.props.loaderNextBtn}
                    />
                    {this.renderNextBtnLabel()}
                </div>
            </div>
        );
    }

    renderNextBtnLabel () {
        return (!this.props.nextBtnActive && !!this.props.nextBtnLabel) || (this.props.showLabel && this.props.nextBtnLabel) ? <p className="step-footer__btn-label">{this.props.nextBtnLabel}</p> : null
    }

    renderPrintBtnLabel () {
        return !!this.props.printBtnLabel ? <p className="step-footer__btn-label">{this.props.printBtnLabel}</p> : null
    }

}
