import {useEffect, useRef, useState} from 'react';
import useSearchPatientList from "./useSearchPatientList";
import useDebounce from "./useDebounce";
import useDisplayPatientList from "../../containers/PatientField/hooks/useDisplayPatientList";
import {PatientValues} from "../../containers/PatientField";
import Helper from "../helpers/main";

/**
 * set patientId to state
 */
type useLoadPatientProp =  (arg0: (number | undefined)) => void

interface Props {
    setPatientId: useLoadPatientProp
    initialPatient?: string
}

const useLoadPatient = ({setPatientId, initialPatient}: Props) => {
    const ref = useRef(null);
    const [patient, setPatient] = useState<string>('')
    const debouncedValue = useDebounce(patient, 500);
    const [displayPatientList, setDisplayPatientList] = useDisplayPatientList(ref);

    let [patientList, loaderPatientList] = useSearchPatientList({value: debouncedValue, nameField: 'searchQuery', isFilter: true});

    const onChangeSearchBar = (val: string): void => {
        if (val.length >= 2) {
            if (!displayPatientList) setDisplayPatientList(true)
        } else {
            if (displayPatientList) setDisplayPatientList(false)
        }
        setPatient(val)
    }

    const onResetSearch = () => {
        setPatientId(undefined)
        setPatient('')
    }

    const onClickPatient = (patient: PatientValues): void => {
        setPatientId(patient.id)
        setPatient(Helper.getFullName(patient))
        setDisplayPatientList(false)
    }

    useEffect(() => {
        if (initialPatient) {
            setPatient(initialPatient)
        }
    }, [])

    return {
        patient,
        setPatient,
        ref,
        patientList,
        displayPatientList,
        loaderPatientList,
        onChangeSearchBar,
        onClickPatient,
        onResetSearch
    }
};

export default useLoadPatient;