import React from 'react';
import Box from '../../../../components-ui/Layout/Box';
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import Button from "../../../../components-ui/Inputs/Button/Button";
import ServerCommand from "../../../../common/server/server-command";
import useLoader from "../../../../components/Errors";
import Tooltip from "../../../../components-ui/DataDisplay/Tooltip/Tooltip";

interface Props {
    callId: number
}

export const SuccessfulCall = ({callId}: Props) => {
    const [loader, setLoader] = useLoader()
    const dataFor = `excludeFromStatistics-${callId}`
    return (
        <Box className="button-task-content" >
            <Button {...commonPropsButton} variant="default"
                    loader={loader}
                    onClick={() => {
                        setLoader(true)
                        ServerCommand.post('call-center/completed-call/save-overseeing-result', {
                            id: callId,
                            purposesAchieved: true
                        });
                    }}
                    children="успешный звонок"
                    noShadow={false}
                    data-for={dataFor}
                    data-tip={"Отметить звонок как успешный<br/>и завершить задачу"}
            />
            <Tooltip id={dataFor} className="react-tooltip--small-text" delayShow={1000}/>
        </Box>
    );
};
