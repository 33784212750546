import React, {forwardRef} from 'react';
import {WrapIcon} from "../../containers/WrapIcon";
import {IIcon} from "../../types/common";

export const Crown = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref} viewBox={viewBox || "-3 -1 29 29"}>
            <g>
                <g>
                    <path d="M23.32 12.36h-5l-2.76-4.23H8.38l-2.74 4.23h-5l-.1-.43a16.69 16.69 0 0 1-.15-7.74C1.73-1.17 7.75-.06 10.64.47a10.13 10.13 0 0 0 1.29.2 9.13 9.13 0 0 0 1.38-.2c2.89-.53 8.9-1.65 10.24 3.72a16.7 16.7 0 0 1-.14 7.74zm-4.42-1.1h3.54a15.29 15.29 0 0 0 .05-6.8C21.39.1 16.26 1 13.51 1.55a9.72 9.72 0 0 1-1.51.22 9.27 9.27 0 0 1-1.58-.21C7.68 1 2.55.1 1.46 4.46a15.28 15.28 0 0 0 .05 6.8H5L7.78 7h8.37z"/>
                    <path d="M16.51 25.86c-1.46 0-2-1.9-2.57-3.91-.47-1.66-1-3.53-1.92-3.53s-1.54 1.88-2 3.53c-.57 2-1.11 3.91-2.57 3.91-2.61 0-4.84-4.82-5.09-5.37a25.18 25.18 0 0 1-1.82-5.25l-.14-.61H7l2.75-4.25h4.43l2.75 4.25h6.66l-.14.61a25.28 25.28 0 0 1-1.82 5.27c-.28.49-2.52 5.35-5.12 5.35zm-4.58-8.45c1.77 0 2.38 2.17 3 4.26.4 1.42.9 3.19 1.6 3.19 1.66 0 3.62-3.54 4.18-4.78a24.57 24.57 0 0 0 1.6-4.45h-5.96l-2.75-4.25h-3.34l-2.75 4.25H1.66a24.32 24.32 0 0 0 1.59 4.43c.84 1.87 2.71 4.8 4.19 4.8.7 0 1.2-1.77 1.6-3.19.6-2.09 1.21-4.26 2.89-4.26z"/>
                </g>
            </g>
        </WrapIcon>
    );
});
