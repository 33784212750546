import * as React from 'react'
import {Icon} from "../../components/Icons/Icons";
import Prompt from "../../components-ui/DataDisplay/Prompt/Prompt";
import {useState} from "react";

export default function RowDeleteButton(props: ButtonProps) {
    const {isEnabled, tip, onClick} = props;
    const [showPrompt, setShowPrompt] = useState(false);

    const handleMouseEnter = () => tip && setShowPrompt(true);
    const handleMouseLeave = () => tip && setShowPrompt(false);

    let buttonClassName = 'table__del-btn';
    if(!isEnabled) {
        buttonClassName += ' table__del-btn--disabled';
    }

    return (
        <div
            className={buttonClassName}
            onClick={() => isEnabled && onClick()}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Icon icon="new-close"/>
            {showPrompt && !!tip && <Prompt text={tip} isTriangle={true}/>}
        </div>
    );
}

type ButtonProps = {
    isEnabled: boolean,
    tip?: string,
    onClick: () => void,
}
