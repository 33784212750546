import React from 'react';
import {getFilledTeeth, types} from "../helperCaseHistory";
import ToothSelect from "./ToothSelect/ToothSelect";
import {HelperTextToothSelected, HelperWrapToothSelected} from "../styledCaseHistory";
import {HelpItem} from "../../../components/DentalRecords/elements/DentalCardInfo/styledDentalCardInfo";

const ToothSelected = (props: any) => {
        const {fieldsRequired, fieldsValues, teethStatuses, onSelect, selectedDiagnosis, handleResetClick} = props;
        const filledTeeth = getFilledTeeth(fieldsRequired, fieldsValues, selectedDiagnosis);
        return (
            <div className="case-history__tooth">
                {types.map(type => {
                    if (props[type + 'Target']  !== undefined) {
                        if (props[type + 'Target'].length) {
                            const title = type === 'visit' ? 'Терапевтические / Хирургические услуги' : 'Ортопедические / Ортодонтические услуги';
                            return (
                                <div className="case-history__tooth--box" key={type}>
                                    <span>{title}</span>
                                    <ToothSelect target={props[type + 'Target']}
                                                 teethStatuses={teethStatuses}
                                                 targetSelected={props[type + 'TargetSelected']}
                                                 typeSelected={type}
                                                 key={type}
                                                 onSelect={(toothNumber: any) => onSelect(toothNumber, type)}
                                                 filledTeeth={filledTeeth}
                                                 handleResetClick={handleResetClick}
                                    />
                                </div>
                            )
                        }
                    }
                    return null
                })}
                <HelperWrapToothSelected>
                    <HelperTextToothSelected>
                        *
                    </HelperTextToothSelected>
                    <HelpItem>
                        <span className="help-ctrl">
                            Ctrl
                        </span>
                        <span className="help-item-text">
                            Выбрать несколько
                        </span>
                    </HelpItem>
                </HelperWrapToothSelected>


            </div>
        )
};

export default ToothSelected;
