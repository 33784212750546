import {useEffect} from "react";
import {useSlideModal} from "../../../containers/DialogsManager/containers/SlideModal/SlideModal";

const useMinimizeParams = (noticesQuantityTotal: number) => {
    const {setMinimizeParams} = useSlideModal();
    useEffect(() => {
        if (!!setMinimizeParams) {
            setMinimizeParams({
                icon: 'taskClinic',
                title: 'Задачи управляющего',
                text: '',
                canBeDeleted: false,
                keySlideModal: 'manager-tasks',
                badgeText: noticesQuantityTotal
            })
        }
    }, [noticesQuantityTotal])
}

export default useMinimizeParams
