import {useState} from "react";
import {StateForm, StateOtherPatient} from "../types/form";
import {StateBasicForm} from "../../../containers/PatientField";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import useInitialStateForm from "./useInitialStateForm";
import _ from 'lodash'
import {PropsUseForm} from "../types/hooks";

export default function useForm({fields}: PropsUseForm) {
    const initialForm = useInitialStateForm({fields})
    const [form, setForm] = useState<StateForm>(_.cloneDeep(initialForm));

    const resetForm = () => setForm(_.cloneDeep(initialForm));

    const onChangeFieldBasic = (value: any, fieldName: keyof StateBasicForm) => {
        setForm(prevState => ({
            ...prevState,
            basic: {
                ...prevState.basic,
                [fieldName]: value
            }
        }))
    }

    const onChangeFieldOtherPatient = (value: any, fieldName: keyof StateOtherPatient) => {
        setForm(prevState => ({
            ...prevState,
            otherPatient: {
                ...prevState.otherPatient,
                [fieldName]: value
            }
        }))
    }

    const onChangeFieldRelation = (value: OptionSelect, fieldName: number) => {
        setForm(prevState => {
            prevState.relations.relations[String(fieldName)] = value;
            return {
                ...prevState
            }
        })
    };

    const onChangeValidationBasic = (fieldName: keyof StateBasicForm) => {
        setForm(prevState => ({
            ...prevState,
            basic: {
                ...prevState.basic,
                validationFields: {
                    ...prevState.basic.validationFields,
                    [fieldName]: false
                }
            }
        }))
    }

    const onChangeValidationOtherPatient = (fieldName: keyof StateOtherPatient) => {
        setForm(prevState => ({
            ...prevState,
            otherPatient: {
                ...prevState.otherPatient,
                validationFields: {
                    ...prevState.otherPatient.validationFields,
                    [fieldName]: false
                }
            }
        }))
    }

    const onChangeValidationRelation = (relation: string) => {
        setForm(prevState => ({
            ...prevState,
            relations: {
                ...prevState.relations,
                validationFields: {
                    ...prevState.relations.validationFields,
                    [relation]: false
                }
            }
        }))
    }

    return {
        initialForm,
        form,
        setForm,
        resetForm,
        onChangeFieldBasic,
        onChangeFieldOtherPatient,
        onChangeFieldRelation,
        onChangeValidationBasic,
        onChangeValidationOtherPatient,
        onChangeValidationRelation
    }
}
