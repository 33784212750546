import React from "react";
import {STableTotal, STableTotalsWrap, STableCell, STableTotalItem, STableTotalLabel} from '../style/common'
import prepareValueByType from "../helper/prepareValueByType";
import {PropsTotal, VariantTable} from "../types/components";
import {IColumn, IRow} from "../types/common";

interface Props {
    fontSizeRow?: string
    total?: PropsTotal[]
    columns: IColumn[]
    rows: IRow[]
    variantTable: VariantTable
}

export default function Total({fontSizeRow, total, columns, rows, variantTable}: Props) {

    if (!!total || columns.some(column => column.countTotal)) {
        return (
            <STableTotal variantTable={variantTable}>
                {columns.map(column => {
                    let content = null
                    if (!!total?.length) {
                       total.forEach((totalCell) => {
                               if (totalCell.fieldName === column.field) {
                                   content = (
                                       <STableTotalsWrap>
                                           {totalCell.totals.map(item => {
                                               let value = prepareValueByType(item.value, column.type);

                                               if (!!item.valueFormatter) value = item.valueFormatter();
                                               return (
                                                   <STableTotalItem variantTable={variantTable}
                                                                    fontSizeRow={fontSizeRow}
                                                                    isHighlighted={item.isHighlighted}>
                                                       <STableTotalLabel width={totalCell.labelWidth}
                                                                         children={item.label} />
                                                       <div children={value} />
                                                   </STableTotalItem>
                                               );
                                           })}
                                       </STableTotalsWrap>
                                   );
                               }
                           }
                       )
                    } else {
                        if (column.countTotal) {
                            let value = 0;
                            rows.forEach(row => {
                                let childrenCell = +row[column.field];
                                if (!!column.valueFormatter) childrenCell = +column.valueFormatter({
                                    childrenCell, row
                                });
                                if (!isNaN(childrenCell)) {
                                    value += childrenCell
                                }
                            })
                            value = prepareValueByType(value, column.type)
                            content = <STableTotalItem variantTable={variantTable} isHighlighted children={value}/>
                        }
                    }
                    return <STableCell variantTable={variantTable} children={content} key={column.field + 'total'}
                                       width={column.width} style={column.customStyles} align={column.align || 'left'} minWidth={column.minWidth}/>

                })}
            </STableTotal>
        )
    } else {
        return null
    }
}
