import React from "react";
import TextField from "../../../../components-ui/Inputs/TextField/TextField";
import { SContainerItem } from "../../../../containers/Tasks/style";

interface Props {
    search: string
    handleSearchFilter: (searchFilter: string) => void
}

const SearchFilter = ({handleSearchFilter, search}:Props) => {
    return (
        <SContainerItem padding="7px 20px 8px">
            <TextField onChange={handleSearchFilter}
                       value={search}
                       isSearch={true}
                       isReset={!!search.length}
                       onClickReset={() => handleSearchFilter('')}
                       size="sm"
                       placeholder="Поиск задачи по ФИО клиента"
                       widthInput="100%"/>
        </SContainerItem>
    )
}

export default SearchFilter
