import React from 'react';
import Box from "../../../components-ui/Layout/Box";
import {Link} from "../../../components-ui/Navigation/Link";
import {Article} from "../types";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";

interface Props {
    article: Article
}

const FixedArticleMobile = ({article}: Props) => {
    return (
        <Box padding="6px 15px 9px" position="relative">
            <Link notUnderline={true} href={article.link} color="info" children={
                <Typography variant="middle" as="span">{article.description}</Typography>
            }/>
            <Box width="90%" height="1px" position="absolute" backgroundColor="borderGray"
                 positionOffsets={{bottom: "-1px"}}/>
        </Box>

    );
};

export default FixedArticleMobile;