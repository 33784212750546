import styled from "styled-components";

export const SPasswordToggle = styled.button.attrs({type: 'button', className: 'SPasswordToggle'})`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    & > div {
        width: 18px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: ${props => props.theme.colors.textGray};
        transition: fill .2s;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    &:hover {
      & > div {
        fill: ${props => props.theme.colors.textDarkGray};
      }
    }
`;
