import React from "react";
import {useDentalCard} from "../../DentalRecords";
import {SDentalCard} from "../../types";
import {TREATMENT_PLAN} from "../../../../common/constants/DentalRecords";
import { CancelButton } from "./styledTreatmentPlanButton";

export default function TreatmentPlanButton() {
    const {parentComponentName, config, canceledButton} = useDentalCard() as SDentalCard;
    if (parentComponentName !== TREATMENT_PLAN) return null;
    const allConfig = config.upper.concat(config.lower);
    const selected = allConfig.some(tooth => tooth.selected);
    return (
        <CancelButton variant={selected ? 'default' : 'disabled'} size="sm" onClick={canceledButton}>
            Отменить выбранные
        </CancelButton>
    )
}
