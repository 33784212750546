import React from 'react';
import {HandsetList} from '../../types';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import {useSelector} from "react-redux";

const useTableFormatter = () => {

    const branches = useSelector(state => state.common.branches)

    const kkmColumn: IColumn<HandsetList>[] = [
        {
            headerName: "Номер телефона",
            field: "phoneNumber",
            valueFormatter: ({row}) => row.phoneNumber
        },
        {
            headerName: "Филиал",
            field: "branches",
            valueFormatter: ({row}) => branches.filter(branch => row.branches.includes(branch.id)).map(branch => branch.name).join(', ')
        },
    ]

    return {handsetColumn: kkmColumn}
};

export default useTableFormatter;
