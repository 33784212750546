import * as React from "react";
import Arr from "../../../../common/helpers/Arr";
import TableWithGroupsTree from "../../../../components/TableWithGroupsTree/TableWithGroupsTree";
import {getMeasureIcon} from "./measureIcon";
import {getPlanItemDeletionTip} from "../helper";
import {PServices} from "../types";
import Group from "../../../../common/helpers/group-helper";
import Helper from "../../../../common/helpers/main";
import SVG from "../../../../common/helpers/SVG";
import {useRef, useState} from "react";
import TurnkeyPrice from "../../../../components/Table/Elements/TurnkeyPrice";
import Checkbox from "../../../../components-ui/Inputs/Checkbox/Checkbox";

const servicesColumns = [
    {
        title: 'Услуга',
        code: 'name',
        searchable: true
    },
    {
        title: 'Код',
        code: 'code'
    },
    {
        title: 'Количество',
        code: 'quantity'
    },
    {
        title: 'Сумма',
        code: 'sum',
        type: 'price'
    },
];


export default function Services({plan, directionsMap, selectedDoctorId, servicesGroups, services, planItemDisabledTip, handleSelectService}: PServices) {
    const servicesIds = plan.getAppliedServicesIds(selectedDoctorId);
    const appliedServicesIdsMap = Arr.flip(servicesIds);

    const actualServices = services.filter((service: any) => !service.archive);
    const servicesMap = Arr.toObject(services, 'id');

    const actualGroups = Group.getActualGroups(servicesGroups);

    return (
        <div className="treatment-plan__service-tab">
            <div className="treatment-plan__service-table">
                <TableWithGroupsTree
                    groups={actualGroups}
                    columns={servicesColumns}
                    data={actualServices}
                    prepareDataItem={({item}: any) => prepareService({
                        service: item,
                        services: servicesMap,
                        appliedServicesIdsMap, directionsMap, plan, planItemDisabledTip
                    })}
                    prepareDataNestedItem={prepareServiceProcedure}
                    selectedRows={servicesIds}
                    onSelect={handleSelectService}
                    searchPlaceholder="Поиск услуги"
                    scrollHeight={260}
                    selectMode="default"
                    sortGroups={false}
                />
            </div>
        </div>
    )
}

const prepareService = ({service, appliedServicesIdsMap, directionsMap, plan, planItemDisabledTip, services}: any) => {
    const isApplied = service.id in appliedServicesIdsMap;
    let isEnabled = !plan.isServiceBlocked(service.id);

    service.rowCode = service.id;
    service.code = [getMeasureIcon(service.measure), service.code];
    service.sum = <TurnkeyPrice sum={service.price} turnkeyPrice={service.turnkeyPrice} turnkeyPriceParts={service.turnkeyPriceParts} services={services}/>;
    service.nestedItems = service.procedures;
    service.quantity = '-';

    if (isApplied && isEnabled) {
        const reasons = plan.getServiceRequiredFor(service.id);
        if (reasons) {
            service.disabledTip = getPlanItemDeletionTip(reasons, directionsMap, services);
            isEnabled = false;
        }
    }

    if (!service.disabledTip) {
        service.disabledTip = plan.isServiceBlocked(service.id, 'active_teeth') ? 'Выберите зуб' : planItemDisabledTip;
    }

    service.name =
        <ServiceName serviceName={service.name} serviceId={service.id} isApplied={isApplied} isEnabled={isEnabled}/>;
    service.isEnabled = isEnabled;
};

/**
 * Отоброжаем услугу в списке услуг. id и ids необходимы для checked;
 * @param serviceName
 * @param serviceId
 * @param isApplied
 * @param isEnabled
 * */
const ServiceName = ({serviceName, serviceId, isApplied, isEnabled}: any) => {
    return (
        <Checkbox
            label={serviceName}
            checked={isApplied}
            disabled={!isEnabled}
        />
    )
};

const prepareServiceProcedure = ({item: procedure}: any) => {
    procedure.code = [getMeasureIcon(procedure.measure), procedure.code];
    procedure.sum = procedure.price * procedure.quantity;
    procedure.isPale = !procedure.defaultActive;
};
