import {useEffect} from "react";
import {PropsUseChangeWantThisDoctor} from "../types/hooks";

export default function useChangeWantThisDoctor({form, setForm}: PropsUseChangeWantThisDoctor) {
    useEffect(() => {
        if (form.wantThisDoctor && form.validationFields.directions) {
            setForm(prevState => (
                {
                    ...prevState,
                    validationFields: {...prevState.validationFields, directions: false}
                }))
        }
    }, [form.wantThisDoctor])
}
