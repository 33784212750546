import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import DateTime from "../../../../../components-ui/Inputs/DateTime/DateTime";
import DateRange, {getStartDate} from "../../../../../components-ui/Inputs/DateRange";

export default function DateTimePage() {
    const [value, setValue] = useState<any>(new Date())
    const [startDate, setStartDate] = useState<any>(getStartDate())
    const [endDate, setEndDate] = useState<any>(new Date())
    return (
        <>
            <h1>DateTime</h1>
            <WrapContent>
                <div>
                    <DateTime selected={value} onChange={setValue}/>
                    <pre>
                        {
                            '<DateTime selected={value} onChange={setValue} />'
                        }
                    </pre>
                </div>
                <div>
                    <DateTime selected={value} onChange={setValue} minDate={new Date()}/>
                    <pre>
                        {
                            '<DateTime selected={value} onChange={setValue} minDate={new Date()} />'
                        }
                    </pre>
                </div>
                <div>
                    <DateTime selected={value} onChange={setValue} minDate={new Date()}
                              showTimeSelect={true} dateFormat="d MMMM yyyy HH:mm"
                              filterPassedTime={time => {
                                  const currentDate = new Date();
                                  const selectedDate = new Date(time);
                                  return currentDate.getTime() < selectedDate.getTime();
                              }}
                    />
                    <pre>
                        {
                            `<DateTime
selected={value} onChange={setValue}
minDate={new Date()}
showTimeSelect={true} dateFormat="d MMMM yyyy HH:mm"
filterPassedTime={time => {

          const currentDate = new Date();
          const selectedDate = new Date(time);

          return currentDate.getTime() < selectedDate.getTime();

      }}
/>`
                        }
                    </pre>
                </div>

                <div>
                    <DateTime selected={value} onChange={setValue} minDate={new Date()}
                              showTimeSelect={true} dateFormat="d-MM-yyyy HH:mm"
                              isLink={true}
                              filterPassedTime={time => {
                                  const currentDate = new Date();
                                  const selectedDate = new Date(time);
                                  return currentDate.getTime() < selectedDate.getTime();
                              }}
                    />
                    <pre>
                        {
                            `<DateTime
selected={value} onChange={setValue}
minDate={new Date()}
isLink={true}
showTimeSelect={true} dateFormat="d MMMM yyyy HH:mm"
filterPassedTime={time => {

          const currentDate = new Date();
          const selectedDate = new Date(time);

          return currentDate.getTime() < selectedDate.getTime();

      }}
/>`
                        }
                    </pre>
                </div>

                <div>
                    <DateRange startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}/>
                    <pre>
                        {
                            `const [startDate, setStartDate] = useState<any>(getStartDate());
    const [endDate, setEndDate] = useState<any>(new Date());

<DateRange startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}/>
                            `
                        }
                    </pre>
                </div>
            </WrapContent>
        </>
    )
}
