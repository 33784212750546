import {KkmSetting} from "../../types";
import {KkmResponse, ListUnit} from "../types/server";

export default class Kkm {

    protected UrlServer: string | null; // HTTP адрес сервера торгового оборудования, если пусто то локальный вызов
    protected User: string | null;      // Пользователь доступа к серверу торгового оборудования
    protected Password: string | null;  // Пароль доступа к серверу торгового оборудования
    protected NumDevice: number | null;
    protected CashierName: string | null;
    protected CashierINN: string | null;

    constructor(kkmSetting: KkmSetting) {
        this.UrlServer = kkmSetting.url
        this.User = kkmSetting.user
        this.Password = kkmSetting.password
        this.NumDevice = kkmSetting.numberDevice
        this.CashierName = kkmSetting.cashierName
        this.CashierINN = kkmSetting.cashierINN
    }

    protected ExecuteCommand(Data:any, FunSuccess:any = undefined, FunError:any = undefined, timeout:any = undefined) {
        return new Promise((resolve, reject) => {
            // Если данные - строка JSON конвентируем в объект
            if (typeof (Data) == "string") Data = JSON.parse(Data);
            try {
                // @ts-ignore
                if (window.KkmServer != undefined) {
                    // Выполняем команду через расширение
                    // @ts-ignore
                    window.KkmServer.Execute((result) => this.ExecuteSuccess(result, resolve), Data);
                    //Возврат - вызов по Http не нужен
                    return;
                }
            } catch { }
            // Если нет расширения - далее отправляем команду по http

            // Если не указана функция обработки ответа - назначаем функцию по умолчанию
            if (FunSuccess === undefined) {
                FunSuccess = this.ExecuteSuccess;
            }
            if (FunError === undefined) {
                FunError = this.ErrorSuccess;
            }
            if (timeout === undefined) {
                timeout = 60000; //Минута - некоторые драйверы при работе выполняют интерактивные действия с пользователем - тогда увеличте тайм-аут.
            }
            // Устанавливаем таймаут чуть больше чем в команде
            try {
                if (Data.Timeout != undefined && Data.Timeout > 60) {
                    timeout = (Data.Timeout + 20) * 1000
                }
            } catch (ex) {
            }

            // Отправляем данные по HTTP протоколу

            const Url = this.UrlServer + ((this.UrlServer == "") ? window.location.protocol + "//" + window.location.host + "/" : "/") + 'Execute';
            const HeaderAuthorization = (this.User !== '' || this.Password !== '') ? ('Basic ' + btoa(this.User + ':' + this.Password)) : '';
            let IsRerror = false;
            const response = fetch(
                Url,
                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    //credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': HeaderAuthorization
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *client
                    body: JSON.stringify(Data)
                }
            ).then(response => {
                console.log('response', response)

                if (response?.status == 200) {

                    response.json().then(resultJson => {
                        FunSuccess(resultJson, resolve)
                    })

                } else {
                    FunError('Ошибка: ' + response?.status + ' ' + response?.statusText, reject);
                }
            }).catch(Exception => {
                IsRerror = true;
                FunError('Ошибка: ' + Exception.message, reject);
            });
        });
    }

    // Функция вызываемая после обработки команды - обработка возвращаемых данных
    // Здесь можно посмотреть как получить возвращаемые данные
    protected ExecuteSuccess(result: KkmResponse, resolve: any) {
        console.log(result)
        //----------------------------------------------------------------------
        // ОБЩЕЕ
        //----------------------------------------------------------------------
        let MessageStatus = "";
        if (result.Status == 0) {
            MessageStatus = "Ok";
        } else if (result.Status == 1) {
            MessageStatus = "Выполняется";
        } else if (result.Status == 2) {
            MessageStatus = "Ошибка!";
        } else if (result.Status == 3) {
            MessageStatus = "Данные не найдены!";
        }
        // Текст ошибки
        let MessageError = result.Error;

        //----------------------------------------------------------------------
        // Фискальные регистраторы
        //----------------------------------------------------------------------
        // Номер чека
        let MessageCheckNumber = result.CheckNumber;
        // Номер смены
        let MessageSessionNumber = result.SessionNumber;
        // Количество символов в строке
        // let MessageLineLength = result.LineLength;
        // Сумма наличных в ККМ
        // let MessageAmount = result.Amount;
        resolve(result)
    }

    // Функция вызываемая при ошибке передачи данных
    protected ErrorSuccess(TextError: any, reject: any) {
        reject("Ошибка передачи данных по HTTP протоколу: " + TextError)
        console.log("Ошибка передачи данных по HTTP протоколу: " + TextError);
        // document.getElementById('Responce').innerHTML = "Ошибка передачи данных по HTTP протоколу: " + TextError;
    }

    // Герерация GUID
    protected guid() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }

    public RegisterCheck(NumDevice: number, TypeCheck: number, IsBarCode: boolean, Print: any, price: number, discount: number) {
        // Подготовка данных команды
        let Data = {
            // Команда серверу
            Command: "RegisterCheck",

            //***********************************************************************************************************
            // ПОЛЯ ПОИСКА УСТРОЙСТВА
            //***********************************************************************************************************
            // Номер устройства. Если 0 то первое не блокированное на сервере
            // Не рекомендовано для использования.
            // Рекомендуем для поиска ККТ задавать InnKkm и TaxVariant !!!!!!
            NumDevice: NumDevice,
            // ИНН ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
            // Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
            InnKkm: "",
            //---------------------------------------------
            // Заводской номер ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
            KktNumber: "",
            // **********************************************************************************************************

            // Время (сек) ожидания выполнения команды.
            //Если За это время команда не выполнилась в статусе вернется результат "NotRun" или "Run"
            //Проверить результат еще не выполненной команды можно командой "GetRezult"
            //Если не указано или 0 - то значение по умолчанию 60 сек.
            // Поле не обязательно. Это поле можно указывать во всех командах
            Timeout: 30,
            // Уникальный идентификатор команды. Любая строка из 40 символов - должна быть уникальна для каждой подаваемой команды
            // По этому идентификатору можно запросить результат выполнения команды
            // Поле не обязательно
            IdCommand: this.guid(),
            // Это фискальный или не фискальный чек
            IsFiscalCheck: true,
            // Тип чека, Тег 1054;
            // 0 – продажа/приход;                                      10 – покупка/расход;
            // 1 – возврат продажи/прихода;                             11 - возврат покупки/расхода;
            // 2 – корректировка продажи/прихода;                       12 – корректировка покупки/расхода;
            // 3 – корректировка возврата продажи/прихода; (>=ФФД 1.1)  13 – корректировка возврата покупки/расхода; (>=ФФД 1.1)
            TypeCheck: TypeCheck,
            // Не печатать чек на бумагу
            // - Но есть нюансы!!!:
            // -- Некоторые ККТ всегда печатают отчеты.
            // -- Некоторые ККТ всегда печатают чеки если ККТ не зарегистрирована на "Интернет продажи"
            // -- Некоторые ККТ всегда печатают чеки если в данных чека нет телефона или email-а клиента
            // -- А у некоторых это еще зависит и от версии прошивки.
            NotPrint: false, //true,
            // Количество копий документа
            NumberCopies: 0,
            // Продавец, Тег ОФД 1021
            CashierName: "Kазакова Н.А.",
            // ИНН продавца Тег ОФД 1203
            CashierVATIN: "430601071197",
            // Телефон или е-Майл покупателя, Тег ОФД 1008
            // Если чек не печатается (NotPrint = true) то указывать обязательно
            // Формат: Телефон +{Ц} или Email {С}@{C}
            ClientAddress: "sochi@papa.com",
            // Покупатель (клиент) - наименование организации или фамилия, имя, отчество (при наличии), серия и номер паспорта покупателя(клиента). Тег 1227
            // Только с использованием наличных / электронных денежных средств и при выплате выигрыша, получении страховой премии или при страховой выплате.
            ClientInfo: "Везучий В.В. РЕ-125486",
            // ИНН Организации или покупателя(клиента). Тег 1228
            // Только с использованием наличных / электронных денежных средств и при выплате выигрыша, получении страховой премии или при страховой выплате.
            ClientINN: "502906602876",
            // Aдрес электронной почты отправителя чека, Тег ОФД 1117 (если задан при регистрации можно не указывать)
            // Формат: Email {С}@{C}
            SenderEmail: "sochi@mama.com",
            // Адрес расчетов, Тег ОФД 1009 (если не задано - берется из регистрационных данных ККТ)
            AddressSettle: "Тестовый адрес",
            // Место расчетов, Тег ОФД 1187 (если не задано - берется из регистрационных данных ККТ)
            PlaceMarket: "kkmserver.ru",
            // Система налогообложения (СНО) применяемая для чека, Тег 1055
            // Если не указанно - система СНО настроенная в ККМ по умолчанию
            // 0: Общая ОСН
            // 1: Упрощенная УСН (Доход)
            // 2: Упрощенная УСН (Доход минус Расход)
            // 3: Единый налог на вмененный доход ЕНВД
            // 4: Единый сельскохозяйственный налог ЕСН
            // 5: Патентная система налогообложения
            // Комбинация разных СНО не возможна
            // Надо указывать если ККМ настроена на несколько систем СНО
            TaxVariant: "",

            // Строки чека
            CheckStrings: [
                {
                    Register: {
                        // Наименование товара 64 символа, Тег 1059
                        Name: "Стоматологические услуги" ,
                        // Количество товара (3 знака после запятой), Тег 1023
                        Quantity: 1,
                        // Цена за шт. без скидки (2 знака после запятой)
                        Price: price,
                        // Конечная сумма строки с учетом всех скидок /наценок; (2 знака после запятой), Из нее расчет тега 1079
                        Amount: price - discount,
                        // Отдел, по которому ведется продажа
                        Department: 0,
                        // НДС в процентах или ТЕГ НДС: 0 (НДС 0%), 10 (НДС 10%), 20 (НДС 20%), -1 (НДС не облагается), 120 (НДС 20 /120), 110 (НДС 10 /110), Тег 1043, Из нее расчет тега 1079
                        Tax: 20,
                        // Признак способа расчета. Тег ОФД 1214. Для ФФД.1.05 и выше обязательное поле
                        // 1: "ПРЕДОПЛАТА 100% (Полная предварительная оплата до момента передачи предмета расчета)"
                        // 2: "ПРЕДОПЛАТА (Частичная предварительная оплата до момента передачи предмета расчета)"
                        // 3: "АВАНС"
                        // 4: "ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)"
                        // 5: "ЧАСТИЧНЫЙ РАСЧЕТ И КРЕДИТ (Частичная оплата предмета расчета в момент его передачи с последующей оплатой в кредит )"
                        // 6: "ПЕРЕДАЧА В КРЕДИТ (Передача предмета расчета без его оплаты в момент его передачи с последующей оплатой в кредит)"
                        // 7: "ОПЛАТА КРЕДИТА (Оплата предмета расчета после его передачи с оплатой в кредит )"
                        SignMethodCalculation: 4,
                        // Признак предмета расчета. Тег ОФД 1212. Для ФФД.1.05 и выше обязательное поле
                        // 1: "ТОВАР (наименование и иные сведения, описывающие товар)"
                        // 2: "ПОДАКЦИЗНЫЙ ТОВАР (наименование и иные сведения, описывающие товар)"
                        // 3: "РАБОТА (наименование и иные сведения, описывающие работу)"
                        // 4: "УСЛУГА (наименование и иные сведения, описывающие услугу)"
                        // 5: "СТАВКА АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
                        // 6: "ВЫИГРЫШ АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
                        // 7: "ЛОТЕРЕЙНЫЙ БИЛЕТ (при осуществлении деятельности по проведению лотерей)"
                        // 8: "ВЫИГРЫШ ЛОТЕРЕИ (при осуществлении деятельности по проведению лотерей)"
                        // 9: "ПРЕДОСТАВЛЕНИЕ РИД (предоставлении прав на использование результатов интеллектуальной деятельности или средств индивидуализации)"
                        // 10: "ПЛАТЕЖ (аванс, задаток, предоплата, кредит, взнос в счет оплаты, пени, штраф, вознаграждение, бонус и иной аналогичный предмет расчета)"
                        // 11: "АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ (вознаграждение (банковского)платежного агента/субагента, комиссионера, поверенного или иным агентом)"
                        // 12: "СОСТАВНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, состоящем из предметов, каждому из которых может быть присвоено вышестоящее значение"
                        // 13: "ИНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, не относящемуся к предметам расчета, которым может быть присвоено вышестоящее значение"
                        // 14: "ИМУЩЕСТВЕННОЕ ПРАВО" (передача имущественных прав)
                        // 15: "ВНЕРЕАЛИЗАЦИОННЫЙ ДОХОД"
                        // 16: "СТРАХОВЫЕ ВЗНОСЫ" (суммы расходов, уменьшающих сумму налога (авансовых платежей) в соответствии с пунктом 3.1 статьи 346.21 Налогового кодекса Российской Федерации)
                        // 17: "ТОРГОВЫЙ СБОР" (суммы уплаченного торгового сбора)
                        // 18: "КУРОРТНЫЙ СБОР"
                        // 19: "ЗАЛОГ"
                        // 20: "РАСХОД" - суммы произведенных расходов в соответствии со статьей 346.16 Налогового кодекса Российской Федерации, уменьшающих доход
                        // 21: "ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ ПЕНСИОННОЕ СТРАХОВАНИЕ ИП" или "ВЗНОСЫ НА ОПС ИП"
                        // 22: "ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ ПЕНСИОННОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОПС"
                        // 23: "ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ МЕДИЦИНСКОЕ СТРАХОВАНИЕ ИП" или "ВЗНОСЫ НА ОМС ИП"
                        // 24: "ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ МЕДИЦИНСКОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОМС"
                        // 25: "ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ СОЦИАЛЬНОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОСС"
                        // 26: "ПЛАТЕЖ КАЗИНО" прием и выплата денежных средств при осуществлении казино и залами игровых автоматов расчетов с использованием обменных знаков игорного заведения
                        SignCalculationObject: 4,
                        // Единица измерения предмета расчета. Можно не указывать, Тег 1197
                        // MeasurementUnit: "пара" ,
                        // Цифровой код страны происхождения товара в соответствии с Общероссийским классификатором стран мира 3 симв. Тег 1230
                        // CountryOfOrigin: "156" ,
                        // Регистрационный номер таможенной декларации 32 симв. Тег 1231
                        // CustomsDeclaration: "54180656/1345865/3435625/23" ,
                        // Сумма акциза с учетом копеек, включенная в стоимость предмета расчета Тег 1229
                        // ExciseAmount: null, // Иначе сумма акциза
                        // КИЗ (контрольный идентификационный знак) товарной номенклатуры, Тег ОФД 1162 (честный знак), можно не указывать
                        //Описание применимых ШК
                        // GoodCodeData: {
                        //     // штрих-код маркировки товара со сканера (нужно настроить сканер так чтобы не проглатывал управляющие символы)
                        //     // Поддерживаются ШК:
                        //     // Без идентификатора экземпляра товара: EAN8, EAN13, ITF14
                        //     // С идентификатором экземпляра товара: GS1, ШК шуб, ШК табачной продукции., ЕГАИС-2, ЕГАИС-3
                        //     BarCode: "0104300943734342212413195240818240640291ffd092MDEwNDMwMDk0MzczNDM",
                        //     // Проверять содержит ли ШК кода маркировки идентификатор экземпляра товара (если вообще не указать - true)
                        //     // Для некоторых товаров нужно передавать ШК EAN-13, тогда это поле устанавливайте в 'false'
                        //     ContainsSerialNumber: true,
                        //     // Все равно регистрировать чек даже если код маркировки не прошел проверку
                        //     // Только для работы по ФФД 1.2
                        //     AcceptOnBad: true
                        // },
                        // Признак агента. Тег ОФД 1222. Поле не обязательное. Можно вообще не указывать.
                        // 0: "Банковский платежный агент:" Оказание услуг пользователем, являющимся банковским платежным агентом
                        // 1: "Банковский платежный субагент:" Оказание услуг пользователем, являющимся банковским платежным субагентом
                        // 2: "Платежный агент:" Оказание услуг пользователем, являющимся платежным агентом
                        // 3: "Платежный субагент:" Оказание услуг пользователем, являющимся платежным субагентом
                        // 4: "Поверенный:" Оказание услуг пользователем, являющимся поверенным
                        // 5: "Комиссионер:" Оказание услуг пользователем, являющимся комиссионером
                        // 6: "Агент:" Оказание услуг пользователем, являющимся агентом и не являющимся банковским платежным агентом (субагентом), платежным агентом (субагентом), поверенным, комиссионером
                        // AgentSign: 2,
                        // Данные агента. Тег ОД 1223.
                        // Поле не обязательное. Обязательно если установлено поле "AgentSign"
                        // Можно вообще не указывать.
                        // AgentData: {
                        //     // Операция платежного агента. Тег ОФД 1044
                        //     PayingAgentOperation: "95315" ,
                        //     // Телефон платежного агента. Тег ОФД 1073
                        //     PayingAgentPhone: "+79995554422" ,
                        //     // Телефон оператора по приему платежей. Тег ОФД 1074
                        //     ReceivePaymentsOperatorPhone: "+72223334455" ,
                        //     // Телефон оператора перевода. Тег ОФД 1075
                        //     MoneyTransferOperatorPhone: "+74447776655" ,
                        //     // Наименование оператора перевода. Тег ОФД 1026
                        //     MoneyTransferOperatorName: "ООО Рога и копыта" ,
                        //     // Адрес оператора перевода. Тег ОФД 1005
                        //     MoneyTransferOperatorAddress: "Москва, зубовский бульвар 44" ,
                        //     // ИНН оператора перевода. Тег ОФД 1016
                        //     MoneyTransferOperatorVATIN: "430601071197"
                        // },
                        // Данные поставщика платежного агента. Тег ОФД 1224
                        // Поле не обязательное. Обязательно если установлено поле "AgentSign"
                        // Можно вообще не указывать.
                        // PurveyorData: {
                        //     // Телефон поставщика. Тег ОД 1171
                        //     PurveyorPhone: "+76662229955" ,
                        //     // Наименование поставщика. Тег ОФД 1225
                        //     PurveyorName: "ООО 'Гаражик в деревне'" ,
                        //     // ИНН поставщика. Тег ОФД 1226
                        //     PurveyorVATIN: "430601071197"
                        // },
                        // Дополнительный реквизит предмета расчета тег 1191, Только для ФФД 1.1 !
                        // AdditionalAttribute: "Тест-доп.реквизит" ,
                    },
                    BarCode: {
                        // Тип штрих-кода: "EAN13" , "CODE39" , "CODE128" , "QR" , "PDF417" .
                        BarcodeType: "EAN13" ,
                        // Значение штрих-кода
                        Barcode: "1254789547853" ,
                    },
                },
                {
                    BarCode: {
                        // Тип штрих-кода: "EAN13" , "CODE39" , "CODE128" , "QR" , "PDF417" .
                        BarcodeType: "PDF417" ,
                        // Значение штрих-кода
                        Barcode: "12345DFG Proba pera, Print barcode 1234567890." ,
                    },
                },
            ],
            // Наличная оплата (2 знака после запятой), Тег 1031
            Cash: price - discount,
            // Сумма электронной оплаты (2 знака после запятой), Тег 1081
            ElectronicPayment: 0,
            // Сумма из предоплаты (зачетом аванса) (2 знака после запятой), Тег 1215
            AdvancePayment: 0,
            // Сумма постоплатой(в кредит) (2 знака после запятой), Тег 1216
            Credit: 0,
            // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой), Тег 1217
            CashProvision: 0,
        };

        //Если чек без ШК то удаляем строку с ШК
        if (IsBarCode == false) {
            //Data.Cash =100;
            for (let i=0; i < Data.CheckStrings.length; i++) {
                if (Data.CheckStrings[i] != undefined && Data.CheckStrings[i].BarCode != undefined) {
                    // @ts-ignore
                    Data.CheckStrings[i].BarCode = null;
                }
                // @ts-ignore
                if (Data.CheckStrings[i] != undefined && Data.CheckStrings[i].PrintImage != undefined) {
                    // @ts-ignore
                    Data.CheckStrings[i].PrintImage = null;
                }
            }
        }

        // Скидываем данные об агенте - т.к.у Вас невярнека ККТ не зарегистрирована как Агент.
        // @ts-ignore
        Data.AgentSign = null;
        // @ts-ignore
        Data.AgentData = null;
        // @ts-ignore
        Data.PurveyorData = null;
        for (let i = 0; i < Data.CheckStrings.length; i++) {
            if (Data.CheckStrings[i] != undefined && Data.CheckStrings[i].Register != undefined) {
                // @ts-ignore
                Data.CheckStrings[i].Register.AgentSign = null;
                // @ts-ignore
                Data.CheckStrings[i].Register.AgentData = null;
                // @ts-ignore
                Data.CheckStrings[i].Register.PurveyorData = null;
            }
        }

        // Вызов команды
        this.ExecuteCommand(Data);

        // Возвращается JSON:
        //{
        //    "CheckNumber": 3,           // Номер документа
        //    "SessionNumber": 1,         // Номер смены
        //    "SessionCheckNumber": 1,    // Номер чека в смене
        //    "URL": "https://ofd-ya.ru/getFiscalDoc?kktRegId=0000000000061716&fiscalSign=839499349",
        //    "QRCode": "t=20190101T195300&s=0.03&fn=9999078900002838&i=3&fp=839499349&n=1",
        //    "Command": "RegisterCheck",
        //    "Cash": 0, // Оплачено наличными
        //    "ElectronicPayment": 3.02, // Оплачено электронноо
        //    "AdvancePayment": 0, // Оплачено предоплатой (зачетом аванса)
        //    "Credit": 0, // постоплатой(в кредит)
        //    "CashProvision": 0, // встречным предоставлением (сертификаты, др. мат.ценности)
        //    "Error": "", // Текст ошибки если была - обязательно показать пользователю - по содержанию ошибки можно в 90% случаях понять как ее устранять
        //    "Message": "", // Сообщение пользователю - Если строка не пустая - ее нужно отобразить пользователю
        //    "Status": 0, // Ok = 0, Run(Запущено на выполнение) = 1, Error = 2, NotFound(устройство не найдено) = 3, NotRun = 4
        //    "IdCommand": "dd261969-4190-1125-26cd-aaf5c213c0e3",
        //    "NumDevice": 2
        //}

        // return this.result
    }

    public List() {
        // Подготовка данных команды
        var Data = {
            // Команда серверу
            Command: "List",
            // Отбор по номеру устройства. Число. Если 0 или не указано то с любым номером
            // NumDevice: 0,
            // // Отбор по ИНН. Строка. Если "" или не указано то первое не блокированное на сервере
            // InnKkm: "",
            // Отбор активных. Булево. Если null или не указано то активные и не активные
            Active: true,
            // Отбор выключенных-включенных
            OnOff: true,
            // // Отбор наличию ошибок ОФВ. Булево. Если null или не указано то с ошибками и без
            // OFD_Error: false,
            // // Все у которых дата не переданного док. в ОФД меньше указанной. Дата-время. Если null или не указано то любое
            // OFD_DateErrorDoc: '2100-01-01T00:00:00',
            // // Все у которых дата окончания работы ФН меньше указанной. Дата-время. Если null или не указано то любое
            // FN_DateEnd: '2100-01-01T00:00:00',
            // // Все у которых заканчивается память ФН; Булево. Если null или не указано то все
            // FN_MemOverflowl: false,
            // // Фискализованные или нет ФН; Булево. Если null или не указано то все
            // FN_IsFiscal: true,
        };
        // Вызов команды
        this.ExecuteCommand(Data);

        // Возвращается JSON массив из объектов:
        //{
        //    "ListUnit": [
        //          {
        //              "NumDevice": 2,
        //              "IdDevice": "6a6151a5-b352-485c-8f01-45da05d3df18",
        //              "OnOf": true,
        //              "Active": false,
        //              "TypeDevice": "Фискальный регистратор",
        //              "IdTypeDevice": "KkmStrihM",
        //              "IP": "192.168.137.111",
        //              "NameDevice": "<Не определено>",
        //              "KktNumber": "",
        //              "INN": "<Не определено>",
        //              "TaxVariant": "", // Описание смотри в команде KkmRegOfd
        //              "AddDate": "2017-01-13T14:55:16",
        //              "OFD_Error": "",
        //              "OFD_NumErrorDoc": 0,
        //              "OFD_DateErrorDoc": "0001-01-01T00:00:00",
        //              "FN_DateEnd": "0001-01-01T00:00:00",
        //              "FN_MemOverflowl": false,
        //              "FN_IsFiscal": false,
        //              "PaperOver": false
        //          },
        //    ],
        //    "Command": "List",
        //    "Error": "",  // Текст ошибки если была - обязательно показать пользователю - по содержанию ошибки можно в 90% случаях понять как ее устранять
        //    "Status": 0   // Ok = 0, Run(Запущено на выполнение) = 1, Error = 2, NotFound(устройство не найдено) = 3, NotRun = 4
        //}

        // return this.result
    }

    public registerCheck(typeCheck: number, isPrint: boolean, price: number, discount: number, isCash: boolean, clientAddress: any|null = null) {
        let data = {
            // Команда серверу
            Command: "RegisterCheck",
            NumDevice: this.NumDevice,
            InnKkm: "",
            KktNumber: "",
            Timeout: 30,
            IdCommand: this.guid(),
            IsFiscalCheck: true,
            // Тип чека, Тег 1054;
            // 0 – продажа/приход;                                      10 – покупка/расход;
            // 1 – возврат продажи/прихода;                             11 - возврат покупки/расхода;
            // 2 – корректировка продажи/прихода;                       12 – корректировка покупки/расхода;
            // 3 – корректировка возврата продажи/прихода; (>=ФФД 1.1)  13 – корректировка возврата покупки/расхода; (>=ФФД 1.1)
            TypeCheck: typeCheck,
            NotPrint: !isPrint,
            // Продавец, Тег ОФД 1021
            CashierName: this.CashierName,
            // ИНН продавца Тег ОФД 1203
            CashierVATIN: this.CashierINN,
            // Телефон или е-Майл покупателя, Тег ОФД 1008
            // Если чек не печатается (NotPrint = true) то указывать обязательно
            // Формат: Телефон +{Ц} или Email {С}@{C}
            ClientAddress: clientAddress,

            // Строки чека
            CheckStrings: [
                {
                    Register: {
                        Name: "Стоматологические услуги" ,
                        Quantity: 1,
                        Price: price,
                        Amount: price - discount,
                        Department: 0,
                        // НДС в процентах или ТЕГ НДС: 0 (НДС 0%), 10 (НДС 10%), 20 (НДС 20%), -1 (НДС не облагается), 120 (НДС 20 /120), 110 (НДС 10 /110), Тег 1043, Из нее расчет тега 1079
                        Tax: 0,
                        // Признак способа расчета. Тег ОФД 1214. Для ФФД.1.05 и выше обязательное поле
                        // 1: "ПРЕДОПЛАТА 100% (Полная предварительная оплата до момента передачи предмета расчета)"
                        // 2: "ПРЕДОПЛАТА (Частичная предварительная оплата до момента передачи предмета расчета)"
                        // 3: "АВАНС"
                        // 4: "ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)"
                        // 5: "ЧАСТИЧНЫЙ РАСЧЕТ И КРЕДИТ (Частичная оплата предмета расчета в момент его передачи с последующей оплатой в кредит )"
                        // 6: "ПЕРЕДАЧА В КРЕДИТ (Передача предмета расчета без его оплаты в момент его передачи с последующей оплатой в кредит)"
                        // 7: "ОПЛАТА КРЕДИТА (Оплата предмета расчета после его передачи с оплатой в кредит )"
                        SignMethodCalculation: 3,
                        // Признак предмета расчета. Тег ОФД 1212. Для ФФД.1.05 и выше обязательное поле
                        // 1: "ТОВАР (наименование и иные сведения, описывающие товар)"
                        // 2: "ПОДАКЦИЗНЫЙ ТОВАР (наименование и иные сведения, описывающие товар)"
                        // 3: "РАБОТА (наименование и иные сведения, описывающие работу)"
                        // 4: "УСЛУГА (наименование и иные сведения, описывающие услугу)"
                        // 5: "СТАВКА АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
                        // 6: "ВЫИГРЫШ АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
                        // 7: "ЛОТЕРЕЙНЫЙ БИЛЕТ (при осуществлении деятельности по проведению лотерей)"
                        // 8: "ВЫИГРЫШ ЛОТЕРЕИ (при осуществлении деятельности по проведению лотерей)"
                        // 9: "ПРЕДОСТАВЛЕНИЕ РИД (предоставлении прав на использование результатов интеллектуальной деятельности или средств индивидуализации)"
                        // 10: "ПЛАТЕЖ (аванс, задаток, предоплата, кредит, взнос в счет оплаты, пени, штраф, вознаграждение, бонус и иной аналогичный предмет расчета)"
                        // 11: "АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ (вознаграждение (банковского)платежного агента/субагента, комиссионера, поверенного или иным агентом)"
                        // 12: "СОСТАВНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, состоящем из предметов, каждому из которых может быть присвоено вышестоящее значение"
                        // 13: "ИНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, не относящемуся к предметам расчета, которым может быть присвоено вышестоящее значение"
                        // 14: "ИМУЩЕСТВЕННОЕ ПРАВО" (передача имущественных прав)
                        // 15: "ВНЕРЕАЛИЗАЦИОННЫЙ ДОХОД"
                        // 16: "СТРАХОВЫЕ ВЗНОСЫ" (суммы расходов, уменьшающих сумму налога (авансовых платежей) в соответствии с пунктом 3.1 статьи 346.21 Налогового кодекса Российской Федерации)
                        // 17: "ТОРГОВЫЙ СБОР" (суммы уплаченного торгового сбора)
                        // 18: "КУРОРТНЫЙ СБОР"
                        // 19: "ЗАЛОГ"
                        // 20: "РАСХОД" - суммы произведенных расходов в соответствии со статьей 346.16 Налогового кодекса Российской Федерации, уменьшающих доход
                        // 21: "ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ ПЕНСИОННОЕ СТРАХОВАНИЕ ИП" или "ВЗНОСЫ НА ОПС ИП"
                        // 22: "ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ ПЕНСИОННОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОПС"
                        // 23: "ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ МЕДИЦИНСКОЕ СТРАХОВАНИЕ ИП" или "ВЗНОСЫ НА ОМС ИП"
                        // 24: "ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ МЕДИЦИНСКОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОМС"
                        // 25: "ВЗНОСЫ НА ОБЯЗАТЕЛЬНОЕ СОЦИАЛЬНОЕ СТРАХОВАНИЕ" или "ВЗНОСЫ НА ОСС"
                        // 26: "ПЛАТЕЖ КАЗИНО" прием и выплата денежных средств при осуществлении казино и залами игровых автоматов расчетов с использованием обменных знаков игорного заведения
                        SignCalculationObject: 4,
                    },
                }
            ],
            // Наличная оплата (2 знака после запятой), Тег 1031
            Cash: (isCash ? (price - discount) : 0),
            // Сумма электронной оплаты (2 знака после запятой), Тег 1081
            ElectronicPayment: (!isCash ? (price - discount) : 0),
        };

        // @ts-ignore
        return this.ExecuteCommand(data).then((response: KkmResponse) => {
            // @ts-ignore
            return response
        });
    }

    public depositingCash(amount: number)
    {
        let data = {
            Command: "DepositingCash",
            NumDevice: this.NumDevice,
            InnKkm: "",
            CashierName: this.CashierName,
            // ИНН продавца тег ОФД 1203
            CashierVATIN: this.CashierINN,
            // Сумма внесения наличных
            // Сумма внесения наличных
            Amount: amount,
            IdCommand: this.guid(),
        }

        return this.ExecuteCommand(data).then(response => {
            // @ts-ignore
            return response
        });
    }

    public paymentCash(amount: number)
    {
        let data = {
            Command: "PaymentCash",
            NumDevice: this.NumDevice,
            InnKkm: "",
            CashierName: this.CashierName,
            // ИНН продавца тег ОФД 1203
            CashierVATIN: this.CashierINN,
            // Сумма внесения наличных
            // Сумма внесения наличных
            Amount: amount,
            IdCommand: this.guid(),
        }

        return this.ExecuteCommand(data).then(response => {
            // @ts-ignore
            return response
        });
    }

    public closeShift()
    {
        let data = {
            Command: "CloseShift",
            NumDevice: this.NumDevice,
            InnKkm: "",
            CashierName: this.CashierName,
            // ИНН продавца тег ОФД 1203
            CashierVATIN: this.CashierINN,
            IdCommand: this.guid(),
        }

        return this.ExecuteCommand(data).then(response => {
            // @ts-ignore
            return response
        });
    }

    public openShift()
    {
        let data = {
            Command: "OpenShift",
            NumDevice: this.NumDevice,
            InnKkm: "",
            CashierName: this.CashierName,
            // ИНН продавца тег ОФД 1203
            CashierVATIN: this.CashierINN,
            IdCommand: this.guid(),
        }

        return this.ExecuteCommand(data).then(response => {
            // @ts-ignore
            return response
        });
    }

    public xReport()
    {
        let data = {
            Command: "XReport",
            NumDevice: this.NumDevice,
            InnKkm: "",
            IdCommand: this.guid(),
        }

        return this.ExecuteCommand(data).then(response => {
            // @ts-ignore
            return response
        });
    }

    public getDataKKT()
    {
        let data = {
            Command: "GetDataKKT",
            Active: true,
            OnOff: true,
            NumDevice: this.NumDevice,
            IdCommand: this.guid(),
        }

        return this.ExecuteCommand(data).then(response => {
            // @ts-ignore
            return response.Info
        });
    }

    public getList()
    {
        let data = {
            Command: "List",
            Active: true,
            OnOff: true,
        }

         return this.ExecuteCommand(data).then((response) => {
            // @ts-ignore
             return response.ListUnit.map((item: ListUnit) => {
                return {
                    value: item.NumDevice,
                    label: item.NameDevice
                }
            })
        });


    }
}
