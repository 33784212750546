import ServerCommand from "../../common/server/server-command";
import {
    LOAD_COMMON_DATA,
    RESET_DATA_LOADED,
    SET_IS_SCROLL_AREA,
    SET_SERVER_ERROR,
    SIGN_IN,
    SIGN_UP
} from "../actions/actionConstants";
import {ActionsReducer, CommonStore} from "../types/common";
import _ from 'lodash'
import Helper from "../../common/helpers/main";
import { notTrashed } from "../../common/helpers/general-helper";


const initialState: CommonStore = {
    isLoggedIn: ServerCommand.isAuthorized(),
    doctors: [],
    initialDate: '',
    search: null,
    serverTimeDiff: 0,
    isDataLoaded: false,
    serverError: null,
    isScrollArea: false,
    users: {},
    directions: {},
    servicesNames: {},
    branches: [],
    allBranches: {},
    callerTypes: {},
    visitStatesNames: {},
    paymentMethods: {},
    cashbox: {},
    paymentModes: {},
    discountTypes: {},
    paymentMethodOptions: [],
    cashboxOptions: [],
    discountTypeOptions: [],
    expenditures: [],
    scheduleStartTime: '',
    scheduleEndTime: '',
    xrayPictureTypes: {},
    laboratories: [],
    roles: {},
    postponeTreatment: {},
    serviceTypes: {}
};

export default function commonReducer(state: CommonStore = initialState, action: ActionsReducer) {
    switch (action.type) {
        case SIGN_IN:
            return {...state, isLoggedIn: true};
        case SIGN_UP:
            return {...state, isLoggedIn: false};
        case LOAD_COMMON_DATA:
            const users = _.mapValues(_.keyBy(action.payload.users, 'id'), (user) => ({...user, fio: Helper.getFio(user)}))

            // определяем разницу со временем на сервере с учётом часового пояса
            const serverTimestamp = action.payload.serverTimestamp * 1000;
            const serverTimeString = new Date(serverTimestamp).toLocaleString("en-US", {timeZone: action.payload.serverTimezone})
            const serverTimeDiff = Date.now() - new Date(serverTimeString).getTime();

            return {
                ...state,
                isLoggedIn: true,
                doctors: _.chain(users).filter({'role': 'doctor'}).map().sortBy('fio').value(),
                initialDate: action.payload.initialDate,
                search: action.payload.search,
                serverTimeDiff,
                isDataLoaded: true,
                serverError: action.payload.serverError,
                users,
                directions: action.payload.directions,
                servicesNames: action.payload.servicesNames,
                branches: _.map(action.payload.branches, (branch) => branch).filter(notTrashed),
                allBranches: action.payload.branches,
                callerTypes: action.payload.callerTypes,
                visitStatesNames: action.payload.visitStatesNames,
                paymentMethods: action.payload.paymentMethods,
                cashbox: action.payload.cashbox,
                paymentModes: action.payload.paymentModes,
                discountTypes: action.payload.discountTypes,
                paymentMethodOptions: _.map(action.payload.paymentMethods, (value, key) => {
                    return {value: key, label: value}
                }),
                cashboxOptions: _.map(action.payload.cashbox, (value, key) => {
                    return {value: key, label: value}
                }),
                discountTypeOptions: _.map(action.payload.discountTypes, (value, key) => {
                    return {value: key, label: value}
                }),
                expenditures: _.map(action.payload.expenditures, (expenditure) => expenditure),
                scheduleStartTime: action.payload.scheduleStartTime,
                scheduleEndTime: action.payload.scheduleEndTime,
                xrayPictureTypes: action.payload.xrayPictureTypes,
                laboratories: action.payload.laboratories,
                roles: action.payload.roles,
                postponeTreatment: action.payload.postponeTreatment,
                serviceTypes: action.payload.serviceTypes
            };
        case RESET_DATA_LOADED:
            return {...state, isLoggedIn: false, isDataLoaded: false};
        case SET_SERVER_ERROR:
            return {...state, serverError: action.payload};
        case SET_IS_SCROLL_AREA:
            return {...state, isScrollArea: action.payload};
        default:
            return state;
    }
}
