import {useMemo} from 'react';
import {useSelector} from "react-redux";
import {useOptionsSelectBranches} from "./index";

export const useOptionsBranchesUser = () => {
    const branches = useOptionsSelectBranches()
    const userBranches = useSelector(state => state.user.branches)
    return useMemo(() => {
        return branches.filter(branch => userBranches.includes(branch.value))
    }, [branches, userBranches])
}