import {useMemo} from "react";
import {GroupsButtonName, NoticesQuantity} from "../types";

interface Props {
    noticesQuantity: NoticesQuantity
    groupsButtonName: GroupsButtonName
}


const useQuantityHiddenTask = ({noticesQuantity, groupsButtonName}: Props) => {
    return useMemo(() => {
        if (groupsButtonName.length > 3) {
            let res = 0;
            groupsButtonName.forEach((groups, index) => {
                if (index >= 3) {
                    groups.forEach(buttonName => {
                        res += noticesQuantity[buttonName]
                    })
                }
            })
            return res
        } else {
            return 0
        }
    }, [groupsButtonName, noticesQuantity])
}

export default useQuantityHiddenTask
