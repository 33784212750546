

export const getPlanItemDeletionTip = (reasons: any, allDirectionsMap: any, services: any) => {

    const mainReason = reasons[0];

    let reasonExplanation = 'данная услуга является обязательной';

    switch(mainReason.type) {
        case 'direction':
            if(mainReason.params && mainReason.params.directions) {
                const requiredForDirections = mainReason.params.directions;
                const alternatives = mainReason.params.alternatives;

                if(alternatives) {
                    const directionToExplain = Object.keys(alternatives)[0];
                    const alternativeServicesNames = alternatives[directionToExplain].map((altServiceId: any) => ` или услуга "${services[altServiceId].name}"`);

                    reasonExplanation = `данная услуга ${alternativeServicesNames.join()} обязательны для направления "${allDirectionsMap[directionToExplain].name}"`;
                } else {
                    const directionsNames = requiredForDirections.map((directionCode: any) => `"${allDirectionsMap[directionCode].name}"`).join(', ');
                    reasonExplanation += ' для направлений: ' + directionsNames;
                }
            }
            break;

        case 'service':
            if(mainReason.params && mainReason.params.servicesIds) {
                const requiredForServices = mainReason.params.servicesIds;
                reasonExplanation += ` для услуги "${services[requiredForServices[0]].name}"`;
            }
            break;
        default:
            break;
    }

    return 'Удаление запрещено - ' + reasonExplanation;
};

export const getDataTablePlanEditor = (props: any, dataTable: any) => {
    const {
        directions, doctors, additionalServicesGroups, recommendedVisitsDecisions, plan,
        planItemDisabledTip, servicesGroups, checkItemsStatuses,
        paymentSchedule, onChangeSchedule, planTitle, setFirstNotDividedItemRef, quantityDivideAttractTime,
        planItemStatuses, visit
    } = props;
    const {planItems, selectedDoctorId, isRecommended, performers, createdPlanToDoctors,
        stateSelectedDoctorId, isSelectedRecommendedDoctor, directionsMap, services} = dataTable;
    return {planTitle, plan, checkItemsStatuses, planItems, selectedDoctorId, isRecommended,
        servicesGroups, additionalServicesGroups, doctors, performers, createdPlanToDoctors,
        planItemDisabledTip, setFirstNotDividedItemRef, stateSelectedDoctorId, recommendedVisitsDecisions,
        isSelectedRecommendedDoctor, paymentSchedule, onChangeSchedule, quantityDivideAttractTime, directions,
        planItemStatuses, services, directionsMap, visit
    }
};


