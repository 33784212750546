import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const AverageCheck = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 24"} ref={ref}>
            <path d="M23.74,17.1A3.06,3.06,0,0,1,24,18.28C24,21.44,18.63,24,12,24S0,21.44,0,18.28A2.89,2.89,0,0,1,.26,17.1c1.14,2.59,6,4.53,11.74,4.53S22.59,19.69,23.74,17.1Zm0-4.24A3.06,3.06,0,0,1,24,14c0,3.16-5.37,5.72-12,5.72S0,17.2,0,14a2.89,2.89,0,0,1,.26-1.18C1.4,15.45,6.22,17.4,12,17.4S22.59,15.45,23.74,12.86Zm0-4.11A3.06,3.06,0,0,1,24,9.93c0,3.16-5.37,5.72-12,5.72S0,13.09,0,9.93A2.89,2.89,0,0,1,.26,8.75c1.14,2.59,6,4.54,11.74,4.54S22.59,11.34,23.74,8.75ZM12,0c6.63,0,12,2.56,12,5.72s-5.37,5.72-12,5.72S0,8.88,0,5.72,5.37,0,12,0Z"/>
        </WrapIcon>
    );
});
