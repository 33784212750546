import React from 'react';
import Box from "../../../../../../../components-ui/Layout/Box";
import {HandlersAverageCheck, StateAverageCheck} from "../../types";
import UserOpacity from './components/UserOpacity';
import useLegend from "./hooks/useLegend";
import AdditionalUsers from "./containers/AdditionalUsers";
import {BarDataItem} from "../../../../../../../components-ui/Charts/ChartBar";
import Item from "./containers/AdditionalUsers/components/Item";

interface Props {
    state: StateAverageCheck
    codes: string[]
    handlers: HandlersAverageCheck
    data: BarDataItem[]
}

const Legend = ({state, codes, handlers, data}: Props) => {
    const {refWrap, visibleCodes, setWidthItems} = useLegend({state, codes})

    return (
        <>
            {state.currentTab === 'doctors' && (
                <Box display="flex" overflow="hidden" height="1px" opacity={0}>
                    {codes.map(code => <UserOpacity key={`opacity-${code}`} code={code}
                                                    setWidthItems={setWidthItems}/>)}
                </Box>
            )}
            <Box display="flex"
                 padding="4px 0"
                 margin="0 0 38px"
                 alignItems="center"
                 width="100%"
                 ref={refWrap}
                 justifyContent="flex-start"
            >
                <Box display="flex">
                    {visibleCodes.codes.map((code, index) => {
                        return <Item code={code} index={index} state={state} handlers={handlers} data={data}
                                     key={code}/>
                    })}
                </Box>
                <AdditionalUsers additionalUsers={visibleCodes.additionalUsers} codes={visibleCodes.codes}
                                 state={state} handlers={handlers} data={data}/>
            </Box>
        </>

    );
};


export default Legend;
