import React from "react";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import {addMemorizedPatient} from "../../../../../redux/actions/scheduleActions";
import _ from "underscore";
import {useDispatch} from "react-redux";
import {useSlideModal} from "../../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {useChoiceOrCompletion} from "../ChoiceOrCompletion";
import {Label} from "../../../../../components-ui/DataDisplay/Label";

export default function RegisterButton() {
    const dispatch = useDispatch();
    const {closeSlideModal} = useSlideModal();
    const {patient, setForm, isSocialVisit} = useChoiceOrCompletion();

    return (
        <Label labelText="Запишите пациента на следующий приём"
               widthLabel="180px" marginLabel="0 20px 0 0"
               marginWrap="0 0 16px"
               widthLabelWrap="100%"
               align="leftTop"
        >
            <Button children="Записать"
                    variant="success"
                    width="185px"
                    margin="0 20px 0 0"
                    onClick={() => {
                        dispatch(addMemorizedPatient({
                            patient: {
                                ..._.pick(patient, 'lastName', 'name', 'secondName', 'id'),
                            } as any,
                            timeStart: '09:00',
                            timeEnd: '09:30',
                            isSocial: isSocialVisit,
                            onEndMemorized: () => {
                                setForm(prevState => {
                                    prevState.nextVisit.isExists = true
                                    return {
                                        ...prevState
                                    }
                                })
                            }
                        }))
                        closeSlideModal()
                        return false;
                    }}
            />
        </Label>
    )
}
