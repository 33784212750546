import React from 'react';
import {GetSalariesTimesheetResponce} from '../../../types';
import useModalAddTimeSheet from './hooks/useModalAddTimeSheet';
import AddTimesheetCreate from './components/AddTimesheetCreate';
import AddTimesheetMarkTime from './components/AddTimesheetMarkTime';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../../../components-ui/Layout/Box';

interface Props {
    data: GetSalariesTimesheetResponce;
    selectedBranchId: number;
    refreshDataFromServer: () => void;
}

const ModalAddTimeSheet = ({data, selectedBranchId, refreshDataFromServer}: Props) => {

    const {
        state,
        setState,
        page,
        setPage,
        loader,
        saveEmployeeSchedule,
        handleEmployee,
        handleStartDate,
        handleEndDate,
        handleDefaultStartTime,
        handleDefaultEndTime,
    } = useModalAddTimeSheet({selectedBranchId, refreshDataFromServer});

    const Step = () => {
        switch (page) {
            case 1:
                return <AddTimesheetCreate state={state}
                                           data={data}
                                           selectedBranchId={selectedBranchId}
                                           handleEmployee={handleEmployee}
                                           handleStartDate={handleStartDate}
                                           handleEndDate={handleEndDate}
                                           handleDefaultStartTime={handleDefaultStartTime}
                                           handleDefaultEndTime={handleDefaultEndTime}
                                           setPage={setPage}
                />
            case 2:
                return <AddTimesheetMarkTime state={state}
                                             setState={setState}
                                             setPage={setPage}
                                             loader={loader}
                                             saveEmployeeSchedule={saveEmployeeSchedule}
                />
            default: return <></>
        }
    }

    return (
        <Box padding='45px 20px 76px' minWidth='600px'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        children='Новый график' textAlign='center' />
            <Box margin='39px 0 0 0'>
                {Step()}
            </Box>
        </Box>
    )

};


export default ModalAddTimeSheet;
