import React, {useState} from 'react';
import Helper from "../../../../common/helpers/main";
import {HandlersAnalytics, StateAnalytics} from "../../types";
import WidgetWrap from "../../components/WidgetWrap";
import HeaderWidget from "../HeaderWidget";
import ContentWidget from "../ContentWidget";
import {WidgetInfo} from "../../data/widgetsInfo";

interface Props {
    widget: WidgetInfo
    index: number
    state: StateAnalytics
    handlers: HandlersAnalytics
    selectedBranches: number[]
}

const Widget = ({widget, index, state, handlers, selectedBranches}: Props) => {
    const [openDateRange, setOpenDateRange] = useState(false)
    const stateCode = Helper.getCamelCase(widget.code, false) as keyof StateAnalytics
    return (
        <WidgetWrap zIndex={openDateRange ? 11 : 10 - index}>
            <HeaderWidget iconName={widget.iconName}
                          title={widget.title}
                          startDate={state[stateCode].startDate}
                          setStartDate={(startDate) => handlers.handleWidgetStartDate(startDate, stateCode)}
                          endDate={state[stateCode].endDate}
                          setEndDate={(endDate) => handlers.handleWidgetEndDate(endDate, stateCode)}
                          loader={state[stateCode].loader}
                          setOpenDateRange={setOpenDateRange}
            />
            <ContentWidget stateWidget={state[stateCode]} code={widget.code} setOpenDateRange={setOpenDateRange}
                           selectedBranches={selectedBranches}/>
        </WidgetWrap>
    )
};

export default Widget;
