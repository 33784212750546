import styled from "styled-components";
import {IIcon} from "../types/common";
import {ReactChild, ReactNode} from "react";

interface PropsSSvg extends IIcon {
    children: ReactChild | ReactNode
    ref: any
}

export const SSvg = styled.svg.attrs(props => ({
    viewBox: props.viewBox || '0 0 24 24'
}))<PropsSSvg>`
    ${props => !!props.boxSizing && `box-sizing: ${props.boxSizing};`}
    fill: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.text};
    width: ${props => props.width || '1em'};
    height: ${props => props.height || '1em'};
    display: inline-block;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: ${props => props.fontSize || '16px'};
    flex-shrink: 0;
    user-select: none;
    ${props => !!props.transform && `transform: ${props.transform};`}
    ${props => !!props.margin && `margin: ${props.margin};`}
    ${props => !!props.padding && `padding: ${props.padding};`}
`
