import {Dispatch, SetStateAction} from "react";
import {StateAverageCheck} from "../types";

interface Props {
    setState: Dispatch<SetStateAction<StateAverageCheck>>
}

const useHandlersAverageCheck = ({setState}: Props) => {

    return {
        handleCurrentTab: (currentTab: string) => setState(prevState => ({...prevState, currentTab})),
        handleHoverIndex: (hoverIndex: number) => setState(prevState => ({...prevState, hoverIndex}))
    }
}

export default useHandlersAverageCheck
