import * as React from "react";
import {Component} from 'react';
import Helper from "../../../common/helpers/main";
import {sizeCell} from "../helper/constants";
import {ResizableProps, ResizableState} from "../types/elements";

const myStyle = {
    position: 'fixed',
    top: '-50vh',
    left: '-50vw',
    width: '150vw',
    height: '150vh',
    zIndex: '1000'
};

export default class Resizable extends Component<ResizableProps, ResizableState> {

    state = {
        styleResizable: {},
        start: 0,
        edit: false
    };
    componentDidMount() {
       this.loadState();
    }

    componentDidUpdate(prevProps: Readonly<ResizableProps>): void {
        if (!Helper.isEqual(prevProps.data, this.props.data)) {
            this.loadState();
        }
    }

    loadState = () => {
        const styleResizable = Helper.clone(this.state.styleResizable);
        const {typeResizable, data} = this.props;
        if (typeResizable === 'bottom') {
            const {height} = sizeCell;
            let heightWrap = height;
            if (data.collCellDoctor !== undefined) {
                heightWrap = data.collCellDoctor * (sizeCell.height - 1) + 1;
            }
            styleResizable.top = (heightWrap - 7) + 'px';
            this.setState({styleResizable});
        }
    };

    render() {
        const {typeResizable, onResize, onResizeStop, onResizeStart} = this.props;
        const {styleResizable, start, edit} = this.state;
        return (
            <span className={`graph__cell-${typeResizable}`}
                  key={typeResizable}
                  style={styleResizable}
                  onMouseDown={(e: React.MouseEvent) : void => {
                      onResizeStart();
                      this.setState({
                          styleResizable: myStyle,
                          start: e.clientY,
                          edit: true
                      });
                  }}
                  onMouseMove={(e: React.MouseEvent) : void => {
                      if (edit) {
                          onResize((e.clientY - start), typeResizable);
                          this.setState({
                              styleResizable: myStyle,
                              start: e.clientY
                          });
                      }
                  }}
                  onMouseUp={() : void => {
                      this.setState({
                          styleResizable: {},
                          edit: false
                      });
                      onResizeStop()
                  }}
            />
        )
    }
}
