import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth34Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 6.55 24"} width={width || "12"} height={height || "44"} ref={ref}>
            <path d="M3,0H3C2.59,0,1.65.64.12,2.44c-.22.25-.18,1.67.55,3.24C1,6.33,1.81,7.84,3,7.84c2.15,0,3.43-2.68,3.54-4.18C6.63,2.56,3.73.06,3.05,0Zm.27,20.3A37.84,37.84,0,0,1,4.8,24a4.91,4.91,0,0,0,.69-2.85c-.7-6.57-.61-7.23,0-11.48.08-.65.18-1.4.29-2.26A3.74,3.74,0,0,1,3,8.66,2.86,2.86,0,0,1,.88,7.56c.59,6.18,1.21,10.3,2.43,12.74h0Z"/>
        </WrapIcon>
    );
});
