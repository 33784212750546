import {useMemo} from "react";
import Helper from "../../../../../common/helpers/main";
import {useDateUpdate} from "../../../../../common/hooks";
import {TasksInfo} from "../../../../../containers/Tasks";
import {ManagerTask} from "../../../types/common";

interface Props {
    task: ManagerTask
    tasksInfo: TasksInfo
}

const useInitialDate = ({task, tasksInfo}: Props) => {
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);
    return useMemo(() => {
        const res = new Date(currentDate);
        res.setSeconds(0)
        switch (tasksInfo[task.type].postponeFor) {
            case 24:
                res.setDate(res.getDate() + 1)
                res.setHours(8)
                res.setMinutes(0)
                if (task.deadline !== null) {
                    let minDeadline = new Date(task.deadline as any)
                    if (minDeadline.getTime() <= new Date(res).getTime()) {
                        res.setDate(res.getDate() - 1);
                        res.setHours(21);
                        res.setMinutes(30);
                    }
                }
                break;
            case 1:
                if (res.getMinutes() > 30) {
                    res.setHours(res.getHours() + 2)
                    res.setMinutes(0)
                } else {
                    res.setHours(res.getHours() + 1)
                    res.setMinutes(30)
                }
                break;
            default:
                break;
        }
        return res;
    }, [])
}

export default useInitialDate
