import React from 'react';
import './TreatmentPlan.scss';
import ServerCommand from "../../../../common/server/server-command";
import PropTypes from "prop-types";
import Table from "../../../../components/Table/Table";
import Helper from "../../../../common/helpers/main";
import Button from "../../../../components-ui/OldButton/Button";
import DateHelper from "../../../../common/helpers/date-helper";
import EditPlan from "./EditPlan";
import Tabs from "../../../../components-ui/Navigation/Tabs/Tabs";
import TreatmentPlan from "../../../../containers/TreatmentPlan/TreatmentPlan";
import Arr from "../../../../common/helpers/Arr";
import TotalSum from "../../../../components-ui/TotalSum/TotalSum";
import {DentalRecords} from "../../../../components/DentalRecords/DentalRecords";
import {TREATMENT_PLAN} from "../../../../common/constants/DentalRecords";
import {Box} from "../../../../components-ui/styledComponent/common/wraps";
import getDoctorsTabs from "../../../../common/TreatmentPlan/DoctorsTabs";
import {DialogsManagerContext} from "../../../../containers/DialogsManager/DialogsManager";

export default class TreatmentPlanSection extends React.Component {
    static contextType = DialogsManagerContext;

    static propTypes = {
        patientId: PropTypes.number.isRequired,
        initialPlanType: PropTypes.string.isRequired
    };

    subTabs = [
        {name: 'Рекомендуемый', code: 'recommended'},
        {name: 'Согласованный', code: 'performed'},
    ];

    state = {
        currentSubTabCode: this.props.initialPlanType,
        visitsDates: null,
        serviceGroups: null,
        currentVisitId: 0,
        plans: null,
        teethStatuses: null,
        newPlanPopupId: null,
        promptNextBtn: '',
        planItemStatuses: null,
        selectedDoctorId: null,
        doctors: null,
        teethAttributesNames: null,
    };

    componentDidMount() {
        this.loadData()
    }

    render() {

        if (!this.state.plans) {
            return null
        }

        let hasPlans = !Helper.isEmptyObj(this.state.plans);

        const treatmentPlanIsPresentClass = (hasPlans ? 'box' : 'box box--min'),
            tabs = Helper.mapObj(this.state.visitsDates, (date, visitId) => ({
                name: date,
                code: visitId
            }));

        /* сортировка табов по убыванию даты */
        tabs.sort((tabA, tabB) => {
            if (tabA.name > tabB.name) {
                return -1;
            } else if (tabA.name < tabB.name) {
                return 1;
            } else return 0;
        });

        /* формирование даты */
        tabs.forEach(tab => {
            tab.name = DateHelper.formatDate(tab.name, 'DD.MM.YYYY', 'YYYY-MM-DD');
        });

        return (
            <>
                <div className="plans">
                    <Tabs
                        variant="classic"
                        tabs={tabs}
                        activeTab={this.state.currentVisitId}
                        onChange={this.handleTabClick}
                    />
                    <div className={`treatment-plan-patient-card ${treatmentPlanIsPresentClass}`}>

                        {!hasPlans &&
                        <div className="treatment-plan__info-tip">
                            <h2>Планов лечения пока нет</h2>
                            <p className="treatment-plan__info">Пациент не был на приеме у врача</p>
                        </div>
                        }

                        {hasPlans &&
                        <div>
                            <Tabs
                                activeTab={this.state.currentSubTabCode}
                                tabs={this.subTabs}
                                onChange={this.handleSelectPlanType}
                            />
                        </div>
                        }
                    </div>
                </div>
                {hasPlans && this.renderPlan()}
            </>
        )
    }

    renderPlan() {
        const actualPlans = this.state.plans[this.state.currentVisitId]

        if (!actualPlans) {
            return null
        }

        const isRecommended = this.state.currentSubTabCode === 'recommended';
        let currentPlan = isRecommended ? actualPlans.recommended : actualPlans.performed

        if (!currentPlan) {
            return (
                <div className="box-body">
                    <p>Согласованного плана лечения нет</p>
                </div>
            )
        }


        let lastVisitDateId = 0,
            lastVisitDate = '';

        Helper.forEachObj(this.state.visitsDates, (date, key) => {
            if (date > lastVisitDate) {
                lastVisitDate = date
                lastVisitDateId = Number(key)
            }
        });

        let tableClassName = 'treatment-plan__table';
        if (isRecommended) {
            tableClassName += ' treatment-plan__table--recommended';
        }

        return (
            <div className="patient-card__plans">
                <DentalRecords teethMap={{
                    config: actualPlans.teethMap.config,
                    statusList: this.state.teethStatuses,
                    attributesNames: this.state.teethAttributesNames,
                    servicesNames: actualPlans.teethMap.servicesNames
                }}
                               visitId={this.state.currentVisitId}
                               parentComponentName={TREATMENT_PLAN}
                               editField={true}/>
                <Box>
                    <div className="box-body">
                        <div className="card">
                            <div className={tableClassName}>
                                {this.renderTable(currentPlan)}
                            </div>
                        </div>
                        <TotalSum sums={TreatmentPlan.getTotalSums(currentPlan.items, isRecommended)}/>

                        {lastVisitDateId === currentPlan.visitId && this.state.currentSubTabCode !== 'recommended' &&
                        <div className="treatment-plan__btns">
                            <Button variant="default"
                                    text="Редактировать"
                                    onClick={this.handleEditBtnClick}/>
                        </div>
                        }
                    </div>
                </Box>

            </div>
        )
    }

    renderTable = (plan) => {
        const {selectedDoctorId, doctors} = this.state;

        let planColumns = [
            {
                title: 'Услуга',
                code: 'name',
                containsGroupTitle: true
            },
            {
                title: 'Зуб',
                code: 'target'
            },
            {
                title: 'Количество',
                code: 'quantity',
                type: 'number'
            },
            {
                title: 'К оплате',
                code: 'sum',
                countTotal: true,
                type: 'price'
            }
        ];

        if (!plan.isRecommended) {
            planColumns.splice(2, 0, {
                title: 'Статус',
                code: 'status'
            });
        }

        return (
            <Table columns={planColumns}
                   data={plan.items}
                   prepareDataItem={this.preparePlanItem}
                   prepareDataNestedItem={this.prepareProcedure}
                   groups={this.state.serviceGroups}
                   getRowGroupId={item => item.serviceGroupId}

                   tabs={getDoctorsTabs({planItems: plan.items, doctors, forceCurDoctorTab: false})}
                   activeTab={selectedDoctorId}
                   onTabChange={this.handleSelectDoctor}

                   total={TreatmentPlan.getDoctorTotal(plan.items, selectedDoctorId, plan.isRecommended)}
            />
        )
    };

    preparePlanItem = ({item: planItem}) => {
        planItem.rowCode = TreatmentPlan.getPlanItemCode(planItem);
        planItem.nestedItems = planItem.procedures.filter(procedure => procedure.isActive);
        planItem.price = TreatmentPlan.getItemPrice(planItem);
        planItem.sum = planItem.price * planItem.quantity;
        planItem.status = this.state.planItemStatuses[planItem.status];
        planItem.categoryCode = TreatmentPlan.getItemCategoryCode(planItem);
        planItem.tab = planItem.performerId;
    };

    prepareProcedure = ({item: procedure, parentItem: planItem}) => {
        procedure.rowCode = TreatmentPlan.getProcedureCode(procedure, planItem);
        procedure.sum = procedure.price * procedure.quantity;
        procedure.status = procedure.isDone ? 'выполнена' : 'не выполнена';
    };

    handleTabClick = (visitId) => {
        const {plans} = this.state;

        const newState = {
            currentVisitId: visitId
        };

        if (!!plans[visitId]) {
            newState.selectedDoctorId = this.getDefaultDoctor(plans[visitId].recommended.items);
        } else {
            this.loadVisitPlans(visitId)
        }

        this.setState(newState);
    };

    loadVisitPlans(visitId) {
        ServerCommand
            .get('treatment-plan/for-visit', {visitId: visitId, patientId: this.props.patientId})
            .then(plansForVisit => {
                let plans = Helper.clone(this.state.plans);
                plans[visitId] = this.preparePlans(plansForVisit);
                this.setState({
                    plans: plans,
                    selectedDoctorId: this.getDefaultDoctor(plans[visitId].recommended.items)
                })
            })
    }

    handleEditBtnClick = () => {
        const actualPlans = this.state.plans[this.state.currentVisitId];
        let currentPlan = this.state.currentSubTabCode === 'recommended' ? actualPlans.recommended : actualPlans.performed
        this.props.showSlideModal(
            <EditPlan
                teethMap={{
                    config: actualPlans.teethMap,
                    statusList: this.state.teethStatuses
                }}
                visitId={this.state.currentVisitId}
                patientId={this.props.patientId}
                plan={currentPlan}
                onDataSave={this.loadVisitPlans.bind(this, this.state.currentVisitId)}
            />
        )

    };

    handleSelectDoctor = selectedDoctorId => this.setState({selectedDoctorId});

    handleSelectPlanType = planType => {
        const {currentVisitId, plans} = this.state;
        const plan = plans[currentVisitId][planType];

        this.setState({
            currentSubTabCode: planType,
            selectedDoctorId: (plan !== null) ? this.getDefaultDoctor(plan.items) : null
        });
    };

    loadData = async () => {
        const response = await ServerCommand.get('treatment-plan/list-for-patient', {patientId: this.props.patientId});

        if (!!response.actualPlans) {
            let currentVisitId = response.actualPlans.recommended.visitId,
                plans = {};

            if (!!response.actualPlans.recommended) {
                plans[currentVisitId] = this.preparePlans(response.actualPlans);
            }

            const doctors = Arr.toObject(response.doctors, 'id');

            this.setState({
                visitsDates: response.visitsDates,
                serviceGroups: response.serviceGroups,
                currentVisitId: currentVisitId,
                plans,
                teethStatuses: Helper.Array.toObject(response.teethStatuses, 'id'),
                planItemStatuses: response.planItemStatuses,
                doctors,
                selectedDoctorId: this.getDefaultDoctor(response.actualPlans[this.state.currentSubTabCode].items, doctors),
                teethAttributesNames: response.teethAttributesNames
            })
        } else {
            this.setState({plans: {}});
        }
    };

    preparePlans(plans) {
        plans = Helper.clone(plans);
        plans.recommended.items = TreatmentPlan.squeezeItems(plans.recommended.items);

        if (plans.performed !== null) {
            plans.performed.items = TreatmentPlan.squeezeItems(plans.performed.items);
        }

        return plans;
    }

    getDefaultDoctor(planItems, doctors) {
        if (!doctors) {
            doctors = this.state.doctors;
        }

        const performers = TreatmentPlan.getPerformers(planItems, doctors, false);
        const curDoctorId = window.user.doctorId ? window.user.doctorId : 0;

        return performers.includes(curDoctorId) ? curDoctorId : Number(performers[0]);
    }
}

