import {useOptionsBranchesUser} from '../../../common/hooks/useOptionsBranchesUser';
import {useCallback, useEffect, useState} from 'react';
import {OptionSelect} from '../../../components-ui/Inputs/Select/typesSelect';
import useLoader from '../../../components/Errors';
import DateHelper from '../../../common/helpers/date-helper';
import ServerCommand from '../../../common/server/server-command';
import {GetSalariesTimesheetResponce} from '../types';


const useSalariesTimesheetPage = () => {

    const optionsBranches = useOptionsBranchesUser()

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [selectedBranch, setSelectedBranch] = useState<OptionSelect>(optionsBranches[0])
    const [employee, setEmployee] = useState<OptionSelect>({value: 0, label: 'Все сотрудники'})

    const [loaderPage, setLoaderPage] = useLoader(true);

    const [loaderTable, setLoaderTable] = useLoader(false)

    const [timesheetRecordsResponse, setTimesheetRecordsResponse] = useState<GetSalariesTimesheetResponce>({
        excludeEmployeesRoles: [],
        records: [],
        schedule: [],
        timesheetCheckDuration: ''
    })

    const params = {
        startDate: DateHelper.formatDateForServer(startDate),
        endDate: DateHelper.formatDateForServer(endDate),
        employeeId: employee.value || undefined,
        branchId: selectedBranch.value
    }

    useEffect(() => {
        setLoaderTable(true)
        ServerCommand
            .get('timesheet-record/list', params)
            .then((response: GetSalariesTimesheetResponce) => {
                setTimesheetRecordsResponse(response )
                setLoaderPage(false)
                setLoaderTable(false)
            })
    }, [startDate, endDate, employee, selectedBranch])

    const refreshDataFromServer = useCallback(() => {
        setLoaderTable(true)
        ServerCommand
            .get('timesheet-record/list', params)
            .then((response: GetSalariesTimesheetResponce) => {
                setTimesheetRecordsResponse(response )
                setLoaderPage(false)
                setLoaderTable(false)
            })
    }, [startDate, endDate, employee, selectedBranch])

    return {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        loaderPage,
        loaderTable,
        selectedBranch,
        setSelectedBranch,
        employee,
        setEmployee,
        timesheetRecordsResponse,
        optionsBranches,
        refreshDataFromServer
    }
};

export default useSalariesTimesheetPage;
