import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import Switch from "../../../../../components-ui/Inputs/Switch/Switch";

export default function SwitchPage() {
    const [value, setValue] = useState(false)
    return (
        <>
            <h1>SwitchPage</h1>
            <WrapContent>
                <div style={{background: '#fff'}}>
                    <Switch checked={value} onChange={setValue} children="Это наш переключатель" />
                    <pre>
                        {
                            `<Switch checked={value} onChange={setValue} children="Это наш переключатель" />`
                        }
                    </pre>
                </div>
                <div style={{background: '#fff'}}>
                    <Switch checked={false} isDisabled={true} onChange={() => console.log('')}
                            children="задизабленный" />
                    <pre>
                        {
                            `<Switch checked={false} isDisabled={true} onChange={() => console.log('')}
                            children="задизабленный" />`
                        }
                    </pre>
                </div>

            </WrapContent>
        </>
    )
}
