import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";

export const defaultOptionsPhoneOwner = [
    {
        label: 'Сам пациент',
        value: "self",
        sort: 0
    },
    {
        label: "Другой человек",
        value: 'other',
        sort: 9999
    }
] as OptionSelect[]
