import React, {useState} from "react";
import TextField from "../../../../../../components-ui/Inputs/TextField/TextField";
import {Label} from "../../../../../../components-ui/DataDisplay/Label";

export default function Mask() {
    const [value, setValue] = useState('');

    return (
        <div>
            <Label  labelText="Номер телефона" isRequired={true}>
                <TextField value={value} onChange={setValue} mask="+7 (999) 999-99-99" />
            </Label>

            <pre>
                {
                    ` <Label  labelText="Номер телефона" isRequired={true}>
                        <TextField value={value} onChange={setValue} mask="+7 (999) 999-99-99" />
                    </Label>`
                }
            </pre>
        </div>
    )
}
