import React from "react";
import SubTitleTask from "../../../../containers/Tasks/components/UI/SubTitleTask";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";
import _ from "lodash";
import DateHelper from "../../../../common/helpers/date-helper";
import {TaskCallCenter} from "../../types/common";
import {InfoCallCenter} from "../../types/hooks";

interface Props {
    parentName: 'postpone' | 'refuse',
    task: TaskCallCenter
    infoCallCenter: InfoCallCenter
}

export const Reasons = ({task, parentName, infoCallCenter}: Props) => {
    const fullInfoName = parentName + 'TreatmentReasons' as 'postponeTreatmentReasons' | 'refuseTreatmentReasons';
    const fullTaskName = parentName + 'TreatmentReason';

    return (
        <>
            <SubTitleTask
                children={parentName === 'postpone' ? "Причина откладывания лечения" : "Причина отказа от лечения"}
                margin="20px 0 11px"/>
            <LabelTask label="Причина"
                       children={!!task.payload[fullTaskName] ? infoCallCenter[fullInfoName].reasons[task.payload[fullTaskName].value].label : 'не указана'}/>
            {!!task.payload[fullTaskName] && !!task.payload[fullTaskName].inputsValues && (
                _.flatMap(task.payload[fullTaskName].inputsValues, (val, key) => {
                    const input = infoCallCenter[fullInfoName].inputs[key];
                    let valueInput = null as any;
                    switch (input.type) {
                        case "date":
                            valueInput = DateHelper.formatComingDays(val)
                            break;
                        case "text":
                            valueInput = val
                            break;
                        default:
                            break;
                    }
                    return <LabelTask key={key}
                                      label={input.name}
                                      children={valueInput}/>
                })
            )}
        </>
    )
}
