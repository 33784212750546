import {useSlideModal} from "../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {useEffect} from "react";

interface Props {
    timeSheetDialogBadgeText: number | undefined
    checkTimeCame: boolean
}

const useMinimizeParams = ({timeSheetDialogBadgeText, checkTimeCame}: Props) => {

    const {setMinimizeParams, isMinimized} = useSlideModal();
    useEffect(() => {
        if (!!setMinimizeParams) {
            setMinimizeParams({
                icon: 'timeSheet',
                title: 'Табель',
                text: '',
                badgeText: checkTimeCame ? timeSheetDialogBadgeText : undefined,
                canBeDeleted: false,
                keySlideModal: 'time-manager',
            })
        }
    }, [timeSheetDialogBadgeText, checkTimeCame])
    return {isMinimized}
};

export default useMinimizeParams;