import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const PlusThinIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M24,10.67H13.33V0H10.67V10.67H0v2.66H10.67V24h2.66V13.33H24Z"/>
        </WrapIcon>
    );
});
