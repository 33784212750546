export const namesVisits = {
    general: 'Записались',
    started: 'Пришли',
} as {
    [key: string]: string
}

export const colorsVisits = {
    general: '#447ce6',
    started: '#fdbc00',
} as {
    [key: string]: string
}
