import {Stage} from "../../../containers/TreatmentPlan/types";
import _ from "underscore";
import {SpanButton} from "../styledStages";
import SVG from "../../../common/helpers/SVG";
import * as React from "react";
import Button from "../../../components-ui/Inputs/Button/Button";

interface PButtons {
    hover: boolean,
    edit: boolean,
    setEdit: any,
    disabledDeleted: boolean,
    setStages: any,
    stages: Stage[],
    index: number,
}

const Buttons = ({hover, edit, setEdit, disabledDeleted, setStages, stages, index}: PButtons) => {
    if (!hover && !edit) return null;
    let buttons = [] as PStageButton[];

    const handleDelete = () => {
        let newStages = _.clone(stages);
        newStages = newStages.filter((stage: any, idx: number) => idx !== index);
        setStages(newStages);
    }

    const handleEdit = () => setEdit(true);

    const deletedBtn = {
        svgName: 'basket',
        hint: disabledDeleted ? 'Перенесите все услугу из данного этапа' : 'Удалить',
        variant: disabledDeleted ? 'disabled' : 'danger',
        onClick: handleDelete
    }

    if (edit) {
        buttons = [deletedBtn]
    } else {
        if (hover) {
            buttons = [
                {
                    svgName: 'edit',
                    hint: 'Редактировать',
                    variant: "default",
                    onClick: handleEdit
                },
                deletedBtn
            ]
        }
    }

    return (
        <>
            {buttons.map(btn => {
                return (
                    <StageButton key={btn.svgName} {...btn} />
                )
            })}
        </>
    )
}

interface PStageButton {
    svgName: string,
    hint: string,
    variant: string,
    onClick: any
}

const StageButton = ({svgName, onClick, hint, variant}: PStageButton) => {
    return (
        <SpanButton title={hint}>
            <Button isIcon variant={variant} onClick={onClick}>
                <SVG name={svgName}/>
            </Button>
        </SpanButton>
    )
}

export default Buttons;
