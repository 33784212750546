import _ from "underscore";
import {DentalCardTooth} from "../types";
import {TeethMap} from "../../../common/types/teethmap";
import Helper from "../../../common/helpers/main";


export const saveTeethMapConfig = (teethMap: TeethMap, config: {[key: string] : DentalCardTooth[]}, onChangeTeethMap?: (teethMap: TeethMap) => void) => {
    const newTeethMap = Helper.clone(teethMap);
    _.each(config, (teeth, nameConfig) => {
        teeth.forEach((tooth: DentalCardTooth, index) => {
            newTeethMap.config[nameConfig][index] = _.pick(tooth, 'number', 'status', 'gumDisease', 'attributes',
                'performedServices', 'diagnoses', 'unconfirmed', 'canals', 'roots', 'autoStatus')
        })
    });
    if (!!onChangeTeethMap) onChangeTeethMap(newTeethMap);
}
