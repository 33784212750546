import React, {useEffect, useState} from 'react';
import './PopupCaseHistory.scss';
import PropTypes from "prop-types";
import ServerCommand from 'common/server/server-command';
import moment from 'moment/moment';
import 'moment/min/locales.min'; // оставить для отображения русского языка
import Helper from "../../../../../common/helpers/main";
import CaseHistory from "../../../../../containers/CaseHistory/CaseHistory";
import Box from "../../../../../components-ui/Wraps/old/Box";
import InitialSurvey from "../../../../../containers/InitialSurvey/InitialSurvey";
import Tabs from "../../../../../components-ui/Navigation/Tabs/Tabs";
import {InitialSurveyProvider} from "../../../../../containers/InitialSurvey/InitialSurveyContext";
import SVG from "../../../../../common/helpers/SVG";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import {formatFioAndCardNumber} from "../../../../../common/helpers/Patient";


const PopupCaseHistoryImport = ({historyId, patient, date, loadData}) => {

    const subTabs = [
        {name: 'История болезни', code: 'caseHistory'}
    ];

    const [caseHistoryData, setCaseHistoryData] = useState(null);
    const [checkupData, setCheckupData] = useState(null);
    const [currentSubTabCode, setCurrentSubTabCode] = useState('caseHistory');

    useEffect(() => {
        ServerCommand
            .get('case-history/load-import', {
                historyId: historyId
            })
            .then(response => {
                const checkupDataResponse = {};
                Helper.forEachObj(response, (res, name) => {
                    if (name === 'initialSurvey' || name === 'doneDuties' || name === 'templatesList' || name === 'templatesGroupsList') {
                        checkupDataResponse[name] = res;
                    }
                    if (name === 'initialSurveyFields') {
                        checkupDataResponse.fields = res;
                    }
                    if (name === 'initialSurvey') {
                        checkupDataResponse.actualTeethMap = res.teethMap;
                    }
                });

                const caseHistoryDataResponse = response;

                if (caseHistoryDataResponse.initialSurvey !== undefined) {
                    delete caseHistoryDataResponse.initialSurvey
                }
                if (caseHistoryDataResponse.initialSurveyFields !== undefined) {
                    delete caseHistoryDataResponse.initialSurveyFields;
                }

                setCaseHistoryData(caseHistoryDataResponse);
                setCheckupData(checkupDataResponse);
            });
    }, [])

    const renderHeaderPopup = () => {

        return (
            <div className="header-popup">
                <div className="header-popup__left">
                    <div className="header-popup__patient">
                        <div className="header-popup__title">
                            {`${formatFioAndCardNumber(patient)} от ${moment(date).format('DD MMMM YYYY')} (импортирован, редактирование невозможно)`}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    if (!caseHistoryData) return null;

    return (
        <>
            {renderHeaderPopup()}
            <>
                <Tabs
                    tabs={subTabs}
                    activeTab={currentSubTabCode}
                    disabled={false}
                    borderBottom={true}
                />
                <Box>
                    <CaseHistory
                        hideHeader={true}
                        visitId={historyId}
                        data={caseHistoryData}
                        editMode={true}
                    />
                </Box>
            </>
        </>
    )
}

export default PopupCaseHistoryImport;

class PopupCaseHistoryImport1 extends React.Component {
    subTabs = [
        {name: 'Первичный осмотр', code: 'checkup'},
        {name: 'История болезни', code: 'caseHistory'}
    ];

    static propTypes = {
        visitId: PropTypes.number,
        patient: PropTypes.object.isRequired,
        date: PropTypes.string.isRequired,
        loadData: PropTypes.func
    };

    state = {
        currentSubTabCode: 'caseHistory',
        editMode: false,
        checkupData: null,
        caseHistoryData: null,
        date: null
    };

    componentDidMount() {
        moment.locale('ru');
        this.loadData();
    }

    render() {

        if (!this.state.checkupData) return null;

        const {editMode, currentSubTabCode} = this.state;

        return (
            <>
                {this.renderHeaderPopup()}
                <>
                    {(!editMode || currentSubTabCode === 'checkup') && (
                        <Tabs
                            tabs={this.subTabs}
                            activeTab={currentSubTabCode}
                            onChange={(subTab) => {
                                this.loadData();
                                this.setState({currentSubTabCode: subTab});
                            }}
                            disabled={editMode}
                            borderBottom={true}
                        />
                    )}
                    <div style={{marginTop: '20px'}}>
                        {(currentSubTabCode === 'checkup') ? this.renderCheckup() : this.renderCaseHistory()}
                    </div>
                </>
            </>
        )
    }

    renderHeaderPopup = () => {
        const {patient, date} = this.props;

        return (
            <div className="header-popup">
                <div className="header-popup__left">
                    <div className="header-popup__patient">
                        <div className="header-popup__title">
                            {`Прием ${formatFioAndCardNumber(patient)} от ${moment(date).format('DD MMMM YYYY')}`}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    renderCheckup() {
        const {patient, visitId} = this.props;
        const {checkupData, editMode} = this.state;
        return (
            <InitialSurveyProvider getLoadCommandData={() => ({patientId: patient.id})}
                                   sendInitialSurvey={this.sendInitialSurvey}
                                   loadData={checkupData}
                                   dateCreate={(date) => this.setState({date})}
            >
                <InitialSurvey renderFooter={this.renderFooter}
                               visitId={visitId}
                               editField={!editMode}
                               viewAlert={false}
                               cardNumber={patient.number}
                />
            </InitialSurveyProvider>

        );
    }

    renderCaseHistory() {
        const {visitId} = this.props;
        const {caseHistoryData, editMode} = this.state;

        return (
            <Box>
                <CaseHistory
                    visitId={visitId}
                    data={caseHistoryData}
                    renderFooter={this.renderFooter}
                    editMode={!editMode}
                />
            </Box>
        );
    }

    renderFooter = (options) => {
        const {editMode} = Helper.clone(this.state);
        const {validateFields, handlePrintButtonClick, isDataChanged, canceledValueFields, canPrint, scrollToField, scrollToDentalCard, teethMapUnconfirmed, loaderPrint} = options;

        let hint = null;
        const isCaseHistory = Array.isArray(validateFields) // приходит либо массив (из истории болезни) либо bool значение (из первичного осмотра)

        if (!isCaseHistory && editMode) {
            const linkErrorField = <span className="error-tips-initial-survey">
                Заполните <span className="link" onClick={scrollToField}>обязательные поля</span> первичного осмотра
            </span>

            const linkErrorDentalCard = <span className="error-tips-initial-survey">
                Подтвердите <span className="link" onClick={scrollToDentalCard}>неподтверждённые зубы</span>
            </span>

            if (!validateFields) {
                hint = linkErrorField;
            } else if (teethMapUnconfirmed) {
                hint = linkErrorDentalCard;
            }
        }

        let btnPrint = {
            text: 'Сохранить и распечатать',
            onClick: () => {
                let validate = isCaseHistory ? !validateFields.length : validateFields;
                if (validate) this.setState({editMode: false});
                handlePrintButtonClick()
            },
            variant: isCaseHistory ? "default" : !teethMapUnconfirmed && validateFields ? 'default' : 'disabled',
        };

        let btnCanceledAndEdit = {
            text: 'Отмена',
            onClick: this.onClickCanceled.bind(this, isDataChanged, canceledValueFields),
            variant: 'default',
        };

        if (!editMode) {
            btnPrint.text = 'Распечатать';
            btnPrint.variant = "default";
            btnCanceledAndEdit.text = 'Редактировать';
        }

        if (!canPrint) {
            btnPrint.text = 'Сохранить';
            btnPrint.variant = "disabled";
            if (editMode) {
                btnPrint.variant = "default";
            }
        }

        return (
            <div className="footer-case-history">
                <Button children={btnCanceledAndEdit.text}
                        variant={btnCanceledAndEdit.variant}
                        size="md"
                        onClick={btnCanceledAndEdit.onClick}/>
                <Button children={btnPrint.text}
                        startIcon={<SVG name="print"/>}
                        size="md"
                        loader={loaderPrint}
                        variant={btnPrint.variant}
                        onClick={btnPrint.onClick}/>
                {hint}
            </div>
        )
    }

    /**
     * Обновляем данные первичного осмотра на сервер
     */
    sendInitialSurvey = (initialSurveyData) => {
        const {visitId} = this.props;
        initialSurveyData.visitId = visitId;

        const command = new ServerCommand('initial-survey/save', initialSurveyData);
        return command.exec();
    };

    onClickCanceled = (isDataChanged, canceledValueFields) => {
        const {editMode} = Helper.clone(this.state);
        if (editMode) {
            if (isDataChanged()) {
                canceledValueFields()
            }
        }
        this.setState({editMode: !editMode})
    };

    loadData = () => {
        ServerCommand
            .get('case-history/save-form', {
                visitId: this.props.visitId,
                getInitialSurvey: true
            })
            .then(response => {
                const checkupData = {};
                Helper.forEachObj(response, (res, name) => {
                    if (name === 'initialSurvey' || name === 'doneDuties' || name === 'templatesList' || name === 'templatesGroupsList') {
                        checkupData[name] = res;
                    }
                    if (name === 'initialSurveyFields') {
                        checkupData.fields = res;
                    }
                    if (name === 'initialSurvey') {
                        checkupData.actualTeethMap = res.teethMap;
                    }
                });

                const caseHistoryData = response;

                if (caseHistoryData.initialSurvey !== undefined) {
                    delete caseHistoryData.initialSurvey
                }
                if (caseHistoryData.initialSurveyFields !== undefined) {
                    delete caseHistoryData.initialSurveyFields;
                }

                this.setState({caseHistoryData, checkupData})
            });
    };

}
