import OralCavity from "./OralCavity";
import BothJaws from "./BothJaws";
import Tooth from "./Tooth";
import Segment from "./Segment";
import Jaw from "./Jaw";
import Product from "./Product";
import Measure from "../../../common/Service/Measure";

export default class TargetFactory {
    /**
     * Создаёт цель указанного типа
     * @param targetData
     * @param measure
     * @return {Tooth|Jaw|BothJaws|OralCavity|Segment|Product}
     */
    static create(targetData, measure) {
        let target;
        const measureType = (measure instanceof Measure) ? measure.getType() : measure;
        switch (measureType) {
            case 'TOOTH':
                target = new Tooth(targetData, measureType);
                break;

            case 'JAW':
                target = new Jaw(targetData, measureType);
                break;

            case 'BOTH_JAWS':
                target = new BothJaws(targetData, measureType);
                break;

            case 'ORAL_CAVITY':
                target = new OralCavity(targetData, measureType);
                break;

            case 'SEGMENT':
                target = new Segment(targetData, measureType);
                break;

            case 'PRODUCT':
                target = new Product(targetData, measureType);
                break;

            default:
                target = null;
        }
        return target;
    }
}
