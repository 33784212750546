import styled from "styled-components";

export const SWrapChartBar = styled.span`
    .recharts-bar-rectangle {
        transition: fill .3s;
    }
    .recharts-bar-rectangle * {
        transition: fill .3s;
    }
`
