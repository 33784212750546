import Typography from "../../components-ui/DataDisplay/Typography/Typography";
import React from "react";

interface PropsValidationLinkInfo {
    validate: boolean,
    onClickIsValidate: () => void,
    currentPatient?: any,
    text?: string
    linkText?: string
}

export default function ValidationLinkInfo(
    {
        validate,
        onClickIsValidate,
        text = "Заполните",
        linkText = "обязательные поля"
    }: PropsValidationLinkInfo) {
    if (validate) return null
    return (
        <Typography variant="small"
                    display="inline-block"
                    fontStyle="italic"
                    color="textDarkGray">{text} <Typography onClick={onClickIsValidate}
                                                            fontWeight="600"
                                                            as="span"
                                                            color="info"
                                                            isLink={true}
                                                            children={linkText}/>
        </Typography>
    )
}
