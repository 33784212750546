import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const LoadingTheClinic = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 24"} ref={ref}>
            <path d="M12,19.2a2.4,2.4,0,1,1-2.4,2.4A2.39,2.39,0,0,1,12,19.2Zm6.79-2.81a2.4,2.4,0,1,1-2.4,2.4A2.4,2.4,0,0,1,18.79,16.39Zm-13.58,0a2.4,2.4,0,1,1-2.4,2.4A2.39,2.39,0,0,1,5.21,16.39ZM2.4,9.6A2.4,2.4,0,1,1,0,12,2.39,2.39,0,0,1,2.4,9.6Zm19.2,0A2.4,2.4,0,1,1,19.2,12,2.39,2.39,0,0,1,21.6,9.6ZM5.21,2.81a2.4,2.4,0,1,1-2.4,2.4A2.4,2.4,0,0,1,5.21,2.81Zm13.58,0a2.4,2.4,0,1,1-2.4,2.4A2.41,2.41,0,0,1,18.79,2.81ZM12,0A2.4,2.4,0,1,1,9.6,2.4,2.39,2.39,0,0,1,12,0Z"/>
        </WrapIcon>
    );
});
