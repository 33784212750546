import React from 'react';
import {Article} from "../types";
import Box from "../../../components-ui/Layout/Box";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import {Link} from "../../../components-ui/Navigation/Link";
import {Icon} from "../../../components-ui/DataDisplay/Icon/containers/Icon";

interface Props {
    article: Article
}

const FixedArticle = ({article}: Props) => {


    return (
        <Box display="flex" width="49.5%" padding="13px 12px 15px">
            <Box backgroundColor="primary" borderRadius="8px"
                 minWidth="44px"
                 height="44px"
                 padding="10px"
                 margin="0 16px 0 0"
            >
                <Icon name={article.icon} width="100%" height="100%" color="white"/>
            </Box>
            <Box maxWidth="248px" margin="1px 0 0 0">
                <Typography variant="middle" lineHeight="1.43">{article.description}</Typography>

                <Box>
                    <Link display="block" notUnderline={true} href={article.link} children={
                        <Typography variant="middle" as="span" margin="8px 0 0 0">Подробнее</Typography>
                    }/>
                </Box>

            </Box>

        </Box>
    );
};

export default FixedArticle;