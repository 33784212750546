import {useMemo} from "react";
import {usePatientField} from "../../PatientField";

export default function useSortSubFields() {
    const {composite} = usePatientField()
    return useMemo(() => {
        let res = [] as string[];
        if (!!composite) {
            res = Object.keys(composite).sort((a, b) => composite[a].field.sort - composite[b].field.sort)
        }
        return res
    }, [])
}
