import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const FilledArrowDownIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 12.18"} ref={ref}>
            <path d="M0,.35,11.82,12.18,24,0"/>
        </WrapIcon>
    );
});
