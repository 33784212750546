import React from "react";
import {formatFioAndCardNumber} from "../../../../../common/helpers/Patient";
import {usePreparationForAdmission} from "../PreparationForAdmission";

export default function HeaderPreparation() {
    const {state} = usePreparationForAdmission();
    if (!state.patient.id) return null;
    return (
        <>
            <h2 className='popup__title'>Подготовка к приёму</h2>
            <span>
                {formatFioAndCardNumber(state.patient)}, на счете {state.patient.account} руб.
            </span>
        </>
    )
}
