import React from 'react';
import PropTypes from 'prop-types';
import Helper from "../../../common/helpers/main";
import TextArea from "../../../components-ui/Inputs/TextArea";

export default class CheckupFields extends React.Component {

    static propTypes = {
        fields: PropTypes.object.isRequired,
        fieldsValues: PropTypes.any.isRequired,
        handleFieldChange: PropTypes.func.isRequired,
        validateFields: PropTypes.func.isRequired,
        editField: PropTypes.bool,
        fieldName: PropTypes.string.isRequired,
        dummySubmit: PropTypes.bool.isRequired,
        renderFirstErrorField: PropTypes.func.isRequired,
        handleCurrentFieldName: PropTypes.func.isRequired
    };

    state = {
        fieldName: this.props.fieldName
    };

    render() {

        let {
                validateFields, fields, fieldsValues,
                renderFirstErrorField, editField,
                handleFieldChange, dummySubmit,
                fieldName: currentFieldName, handleCurrentFieldName
            } = this.props,
            isFirstErrorField = true;

        return (
            <div className="card-body">
                <div className="wrap-half form-group">
                    {Helper.mapObj(fields, (field, fieldName) => {
                        let ref = () => null;
                        let autofocus = (fieldName === 'diagnosis');
                        let activeClass = (currentFieldName === fieldName) ? 'active' : '';
                        const fieldRequired = <span key={fieldName + 'req'}
                                                    className='form-group__field-required'>*</span>;

                        if (!validateFields()[fieldName] && isFirstErrorField) {
                            ref = (ref) => {
                                renderFirstErrorField(ref);
                            };
                            isFirstErrorField = false
                        }

                        if (editField) {
                            autofocus = false;
                            activeClass = '';
                        }

                        return (
                            <TextArea title={[field.title, (field.required) ? fieldRequired : '']}
                                      placeholder={field.placeholder}
                                      initialOpened={field.required}
                                      value={fieldsValues ? fieldsValues[fieldName] : ''}
                                      onChange={e => handleFieldChange(fieldName, e.target.value)}
                                      key={fieldName}
                                      onClick={() => handleCurrentFieldName(fieldName)}
                                      autoFocus={autofocus}
                                      activeClass={activeClass}
                                      variant={validateFields() ? '' : 'danger'}
                                /* add */
                                      isError={dummySubmit && !validateFields()[fieldName]}
                                      ref={ref}
                                      disabled={editField}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }


}
