import {useMemo, useState} from 'react';
import {GetActualRatesResult} from '../../../types';
import {LaboratoryService, LaboratoryServiceGroup} from '../../../../LaboratoryPricelistPage/types';
import _ from 'lodash';

interface Props {
    data: GetActualRatesResult;
}

export interface GroupServices extends LaboratoryServiceGroup {
    isOpen: boolean;
    services?: LaboratoryService[];
}

const useLaboratoryRatesTable = ({data}: Props) => {

    const [search, setSearch] = useState('');

    const resetSearch = () => setSearch('');

    const rootGroup: LaboratoryServiceGroup[] = [{
        id: 0,
        name: 'Все группы',
        groupId: null,
        deletedAt: null,
        groups: data.serviceGroups,
    }];

    const initialLaboratoryRatesGroups: GroupServices[] = useMemo(() => {
        const recursionMapGroups = (groups: LaboratoryServiceGroup[]): GroupServices[] => {
            return groups.map(group => {

                if (group.groups && group.groups.length) {
                    group.groups = recursionMapGroups(group.groups);
                }

                const findServices = data.services.filter(service => service.groupId === group.id);
                return {
                    ...group,
                    services: findServices,
                    isOpen: !group.id,
                };
            });
        };

        return recursionMapGroups(rootGroup);
    }, [data.services, data.serviceGroups]);

    const [servicesGroups, setServicesGroups] = useState<GroupServices[]>(_.cloneDeep(initialLaboratoryRatesGroups));

    const [selectedGroup, setSelectedGroup] = useState<GroupServices>(servicesGroups[0]);

    const [filtredSelectedGroup, setFiltredSelectedGroup] = useState<GroupServices | undefined>(selectedGroup);

    const filterServicesGroups = useMemo(() => {
        const recursionGroups = (groups: GroupServices[]) => {
            return groups.map((group: GroupServices) => {
                if (group.groups && group.groups.length) {
                    group.groups = recursionGroups(group.groups as GroupServices[]);
                }
                return {
                    ...group,
                    services: group.services && group.services.filter(service => service.name.toLowerCase().includes(search.toLowerCase())),
                };
            }).filter(group => group.services?.length || group.groups?.length);
        };
        /*
        если группа уже выбрана, то фильтруем услуги, по этой группе,
        если услуг никаких не нашлось, то
        если выбрана родительская "Все группы," то фильтруем вообще по всем услугам,
        е
        */
        setFiltredSelectedGroup(() => {
            if (selectedGroup.id) {
                const filteredServices = selectedGroup.services &&
                    selectedGroup.services.filter(service =>
                        service.name.toLowerCase().includes(search.toLowerCase()));

                if (filteredServices?.length) {
                    return {
                        ...selectedGroup,
                        services: filteredServices,
                    };
                } else {
                    onClickGroup(0);
                }

            } else if (search) {
                return {
                    ...selectedGroup,
                    services: data.services.filter(service =>
                        service.name.toLowerCase().includes(search.toLowerCase())),
                };
            }

        });
        return recursionGroups(_.cloneDeep(servicesGroups));
    }, [search, servicesGroups]);


    const onClickGroup = (id: number) => {
        setServicesGroups(prevState => {
            const recursionGroups = (groups: GroupServices[]) => {
                groups.forEach((group) => {
                    if (group.id === id) {
                        if (!!group.id) group.isOpen = !group.isOpen;
                        setSelectedGroup(group);
                    }

                    if (group.groups && group.groups.length) {
                        recursionGroups(group.groups as GroupServices[]);
                    }
                });
            };
            recursionGroups(prevState);

            return [...prevState];
        });
    };

    return {search, setSearch, resetSearch, filterServicesGroups, onClickGroup, filtredSelectedGroup};
};

export default useLaboratoryRatesTable;
