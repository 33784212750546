import React from 'react';
import {Label} from "../../../components-ui/DataDisplay/Label";
import {useNewVisitForm} from "../NewVisitForm";
import Paper from "../../../containers/UI-Collection/Elements/Surfaces/Paper";
import {InfoBox, InfoBoxWrap} from "../../../components-ui/styledComponent/common/wraps";
import TimeCounter from "../../../components-ui/Inputs/TimeCounter/TimeCounter";
import commonLabelOptions from "../../../containers/PatientField/helper/commonLabelOptions";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";

export default function Duration() {
    const {maxVisitDuration, appointmentTime, setAppointmentTime} = useNewVisitForm();

    const onChangeReception = (e: any) => {
        let val = e.target.value;
        if (!!maxVisitDuration) {
            if (val >= maxVisitDuration) val = maxVisitDuration;
        }
        setAppointmentTime(val);
    }

    const showDuration = () => {
        const hours = Math.floor(+appointmentTime / 60) > 0 ? '0' + String(Math.floor(+appointmentTime / 60)) : '00';
        const minutes = (+appointmentTime % 60) > 0 ? String(+appointmentTime % 60) : '00';
        return hours + ':' + minutes
    }

    const onClickReceptionUp = () => {
        let newReception = +appointmentTime + 15;
        if (!!maxVisitDuration) {
            if (newReception >= maxVisitDuration) newReception = maxVisitDuration;
        }
        setAppointmentTime(String(newReception));
    }

    const onClickReceptionDown = () => {
        let newReception = +appointmentTime - 15;
        if (newReception <= 15) newReception = 15
        setAppointmentTime(String(newReception));
    }

    return (
        <Paper minWidth="100%" margin="0 0 24px">
            <InfoBox>
                <Typography variant="min-title" padding="25px 20px 11px">Длительность приема</Typography>
                <InfoBoxWrap>
                    <Label labelText="Время"
                           {...commonLabelOptions(false)}>
                        <TimeCounter value={showDuration()}
                                     onChange={onChangeReception}
                                     onClickDown={onClickReceptionDown}
                                     onClickUp={onClickReceptionUp}/>
                    </Label>
                </InfoBoxWrap>
            </InfoBox>
        </Paper>
    )
}
