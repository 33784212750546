import React, {useEffect, useMemo, useState} from "react";
import CalendarField, {defaultValidateField, ValidateCalendarField} from "../../../components-ui/Inputs/CalendarField/CalendarField";
import ServerCommand from "../../../common/server/server-command";
import {useEditableField} from "../EditableField";
import SaveButtons from "./SaveButtons";
import {ValueEditableField} from "../typesEditableField";
import getSplitValue from "../../../components-ui/Inputs/CalendarField/helpers/getSplitValue";

export default function FieldDate() {

    const {field, patientId, setEditField} = useEditableField();
    const {onChange} = field;
    const values = Object.values(field.values)[0] as ValueEditableField;
    const [value, setValue] = useState(!!values.value ? values.value : '');
    const [valid, setValid] = useState<ValidateCalendarField>(defaultValidateField);

    const noCanceledButton = useMemo(() => {
        return values.required && (!!values.value && values.value.length !== 10)
    }, []);

    const prepareSplitValue = (val: string) => {
        const splitValue = val.split('-');
        let day = '';
        let month = '';
        let year = '';
        if (splitValue.length === 3) {
            day = splitValue[0];
            month = splitValue[1];
            year = splitValue[2];
            if (val.length === 10) {
                day = splitValue[2].length === 4 ? splitValue[0] : splitValue[2];
                year = splitValue[0].length === 4 ? splitValue[0] : splitValue[2];
            }
        }

        return  {day, month, year}
    }

    const noValid = () => {
        const {day, month, year} = prepareSplitValue(value)
        setValid(prevState => {
            return {
                ...prevState,
                day: day === '',
                month: month === '',
                year: year.indexOf('_') !== -1 || year === ''
            }
        });
    }

    useEffect(() => {
        setValid(prevState => {
            const {day, month, year} = prepareSplitValue(value)
            if (day !== '') prevState.day = false;
            if (month !== '') prevState.month = false;
            if (year.indexOf('_') !== -1 && year !== '') prevState.year = false;
            return {
                ...prevState,
            }
        });
    }, [value]);

    useEffect(() => {
        if (values.validation) noValid();
    }, [values.validation])

    const onClickSave = () => {
        if (value.length !== 10 || value.indexOf('_') !== -1) {
            noValid();
            return;
        }
        const splitValue = getSplitValue(value)
        const newValue = `${splitValue.year}-${splitValue.month}-${splitValue.day}`
        const command = new ServerCommand('patient/edit', {id: patientId, [values.name]: newValue});
        command.exec().then(() => {
            onChange(value);
            setEditField(false);
        });
    };

    return (
        <>
            <CalendarField value={value}
                           widthCalendar="290px"
                           onChange={valueField => {
                               setValue(valueField);
                               if (!!values.onResetValidation) values.onResetValidation()
                           }}
                           validateField={valid}/>
            <SaveButtons onClickSave={onClickSave} noCanceledButton={noCanceledButton}/>
        </>
    )
}
