import * as React from "react";
import {Row, WrapDesc} from "../styledStages";
import HeaderDescription from "./HeaderDescription";
import {Stage, StagedPlanItem} from "../../../containers/TreatmentPlan/types";
import {prepareCellDesc} from "../helperStages";
import {PlanItemsStatuses} from "../../../containers/TreatmentPlansConstructor/types";
import {ObjServicesGroup} from "../typesStages";
import {useSelector} from "react-redux";
import Performer from "./Performer";
import {useCallback, useState} from "react";
import _ from "lodash";
import update from "immutability-helper";

interface PItemDescription {
    active: boolean,
    planItems: StagedPlanItem[],
    itemsStatuses: PlanItemsStatuses | null,
    servicesGroups: ObjServicesGroup,
    stageId: number,
    performers: number[],
    stages: Stage[],
    stageIndex: number,
    setStages?: any
}

export default function ItemDescription({active, planItems, itemsStatuses, servicesGroups, stageId, performers, stages, stageIndex, setStages}: PItemDescription) {
    const doctors = useSelector(state => state.common.doctors);
    const [isDrag, setIsDrag] = useState(false);

    const movePerformer = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard = _.clone(performers)[dragIndex];
            stages[stageIndex].performers = update(_.clone(performers), {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            });
            setStages(stages);
        },
        [performers],
    )

    if (active && planItems.length) {
        const cellDesc = prepareCellDesc(itemsStatuses);

        return (
            <Row>
                <WrapDesc>
                    <HeaderDescription cellDesc={cellDesc}/>
                    {performers.map((performerId, doctorIndex) => {
                        const doctor = doctors.filter((doc) => doc.id === performerId)[0];
                        const doctorPlanItems = planItems.filter(item => item.performerId === performerId);
                        return <Performer planItems={doctorPlanItems}
                                          key={performerId}
                                          doctor={doctor}
                                          servicesGroups={servicesGroups}
                                          stageId={stageId}
                                          itemsStatuses={itemsStatuses}
                                          doctorIndex={doctorIndex}
                                          isDrag={isDrag}
                                          setIsDrag={setIsDrag}
                                          stageIndex={stageIndex}
                                          moveCard={movePerformer}/>
                    })}

                </WrapDesc>
            </Row>
        );
    } else {
        return null
    }
}

