import React, {Dispatch, SetStateAction} from "react";
import Box from "../../../../../components-ui/Layout/Box";
import Paper from "../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import Helper from "../../../../../common/helpers/main";
import SVG from "../../../../../common/helpers/SVG";
import {PlannedCall, Call} from "../../../types/common";
import {useSlideModal} from "../../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import { SBoxIncoming } from "../style";
import {StateCallCenter} from "../../../types/components";

interface Props {
    marketingPlannedCalls: PlannedCall[]
    panelCalls: Call[]
    setState: Dispatch<SetStateAction<StateCallCenter>>
}

const PanelCalls = ({panelCalls, setState, marketingPlannedCalls}: Props) => {
    const {maximizeSlideModal} = useSlideModal();
    const onClickIncomingCall = (panelCall: Call) => {
        setState(prevState => ({
            ...prevState,
            detail: marketingPlannedCalls.find(plannedCall => plannedCall.phone === panelCall.contactPhone) as PlannedCall,
            panelCalls: prevState.panelCalls.filter(prevPanelCall => prevPanelCall.contactPhone !== panelCall.contactPhone)
        }))
        setTimeout(() => {
            if (!!maximizeSlideModal) maximizeSlideModal();
        }, 100)
    }
    return (
        <Box position="fixed" positionOffsets={{right: '28px', top: "98px"}} zIndex={500} cursor="pointer">
            {panelCalls.map((call) => {
                return (
                    <Paper key={call.contactPhone} margin="0 0 14px" minWidth="300px">
                        <Box padding="16px 20px 17px" display="flex" onClick={() => onClickIncomingCall(call)}>
                            <SBoxIncoming>
                                <SVG name="call-center/incoming" className="incoming-call-panel-icon"/>
                            </SBoxIncoming>
                            <Box display="flex" flexDirection="column">
                                <Typography children={Helper.Phone.format(call.contactPhone)} margin="0 0 4px"/>
                                <Typography fontSize="12px" color="incomingColor" lineHeight="1.33"
                                            children={`${!!call.contactPerson ?
                                                Helper.getFullName(call.contactPerson) :
                                                call.contactPhoneRegistered ? 'Контактное лицо не указано' :
                                                    'Нет в базе'}`}/>
                            </Box>
                        </Box>
                    </Paper>
                )
            })}
        </Box>
    )
}

export default PanelCalls
