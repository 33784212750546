import React, {useState, useRef} from "react";
import SVG from "../../../../../common/helpers/SVG";
import {ButtonDel, WrapList, SWrapTab, STab, STabNote, STabChild, STabWarning, STabName, STabAuxiliary, STabAuxiliaryShadow} from "./styledTab";
import {PTab} from "./typesTab";
import {List, ListItem} from "../../../../DataDisplay/List";
import Scrollbars from "../../../../Scrollbars/scrollbars";
import {Option} from "../../../../../common/types/common";
import useOutsideClick from "../../../../../common/hooks/useOutsideClick";

export default function Tab(
    {
        tab,
        activeTab,
        onTabReplace,
        onChange,
        onTabDelete,
        variant,
        size,
        widthTab,
        isLastChild,
        isFirstChild,
        noLeftMargin,
        disabled,
        isNote
    }: PTab) {
    const [openReplace, setOpenReplace] = useState(false);
    const ref = useRef(null) as any;

    useOutsideClick(ref, () => setOpenReplace(false))

    const handleClickReplacementOption = (option: Option) => {
        if (onTabReplace) {
            onTabReplace(tab.code, option.value);
            setOpenReplace(false);
        }
    }

    let name = <STabName variant={variant} activeTab={activeTab} isNote={isNote} children={tab.name}/>

    if (tab.isReplaceable && tab.replacementOptions && tab.replacementOptions.length > 0) {
        name = (
            <STabName variant={variant} activeTab={activeTab} isNote={isNote}>
                {tab.name}
                <SVG name="arrow-select"
                     onClick={() => setOpenReplace(!openReplace)}
                     className={`tab-arrow-select ${openReplace && "tab-arrow-select--active"}`}/>
            </STabName>
        )
    }
    let content = name;

    if (tab.isDeletable && onTabDelete) content = (
        <STabChild>
            {name}
            <ButtonDel onClick={e => {
                e.stopPropagation();
                onTabDelete(tab.code)
            }}>
                <SVG name="close" />
            </ButtonDel>
        </STabChild>
    );

    return (
        <SWrapTab ref={ref} variant={variant}>
            {(tab.warning !== undefined ? tab.warning : false) && <STabWarning/>}
            {variant === 'list' && <STabAuxiliary variant={variant} activeTab={activeTab}/>}
            {variant === 'list' && !activeTab && <STabAuxiliaryShadow/>}
            <STab variant={variant}
                  widthTab={widthTab}
                  activeTab={activeTab}
                  isNote={isNote}
                  isFirstChild={isFirstChild}
                  isLastChild={isLastChild}
                  noLeftMargin={noLeftMargin}
                  disabled={disabled}
                  size={size}
                  onClick={() => !activeTab && onChange(tab.code)}
            >
                {content}
                {!!tab.note && <STabNote children={tab.note}/>}
                {(!!tab.isReplaceable && openReplace) && (
                    <WrapList>
                        <Scrollbars style={{height: 'max-content', maxHeight: '300px'}}>
                            <List>
                                {tab.replacementOptions?.map(item => (
                                    <ListItem key={item.value} onClick={() => handleClickReplacementOption(item)}>
                                        {!!item.color &&
                                        <span className="tab-replace-color" style={{background: item.color}}/>}
                                        <span>{item.label}</span>
                                    </ListItem>
                                ))}
                            </List>
                        </Scrollbars>
                    </WrapList>
                )}
            </STab>
        </SWrapTab>
    )
}
