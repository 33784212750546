import styled from "styled-components";
import {PSTabs, PSTabsWrap} from "./typesTabs";

const justifyContentTabs = (props: PSTabsWrap) => {
    switch (props.align) {
        case "center":
            return 'center'
        case "left":
        default:
            return 'flex-start'
    }
};

export const SWrapTabs = styled.div.attrs({className: 'SWrapTabs'})<PSTabsWrap>`
    display: flex;
    align-items: flex-start;
    justify-content: ${justifyContentTabs};
    width: ${props => (props.variant === 'default' && props.borderBottom) || props.align === 'center' ? '100%' : 'max-content'};
    border-bottom: ${props => props.variant === 'default' && props.borderBottom ? `1px solid ${props.theme.colors.borderGray}` : 'none'};
    & > button {
        padding: 0;
        width: 28px;
        height: 28px;
        margin-top: 12px;
        box-shadow: none;
        svg {
            width: 12px;
            height: 12px;
        }
    };
    ${props => !!props.margin && `margin: ${props.margin};`};
`

const paddingTabs = (props: PSTabs) => {
    switch (props.variant) {
        case "default":
            if (props.noLeftMargin) {
                return '0 18px 0 0';
            } else {
                return '0 18px'
            }
        case "buttons":
            return '2px'
        default:
            return '0'
    }
};

export const STabs = styled.div.attrs({className: 'STabs'})<PSTabs>`
    display: flex;
    ${props => props.variant === 'list' && `
        flex-direction: column;
        align-items: flex-end;
    `};
    padding: ${paddingTabs};
    overflow: ${props => props.variant === 'classic' ? 'hidden' : 'none'};
    user-select: none;
    ${props => props.variant === 'buttons' && `
        border: 1px solid ${props.theme.colors.borderGray};
        border-radius: 4px;
    `}
`
