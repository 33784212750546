import ServerCommand from "../../../../common/server/server-command";
import {Dispatch, SetStateAction, useCallback} from "react";
import {ReturnCashParams, GetDirectionsBalanceResult, IStateCashSalary} from "../types";
import {useModal} from "../../../../containers/DialogsManager/containers/Modal/Modal";
import Kkm from "../../../../common/server/kkm";
import {useSelector} from "react-redux";
import {KkmResponse} from "../../../../common/types/server";
import {useErrorModal} from "../../../../components/Errors";
import {CreateCashParams} from "../../CashCreate/types";
import {KkmSetting} from "../../../../types";

interface Props {
    paid:number
    userId: number | undefined
    loadingFromServer?: () => void
    setLoader: Dispatch<SetStateAction<boolean>>
    setKkmError: Dispatch<SetStateAction<string>>
    state: IStateCashSalary
    data: GetDirectionsBalanceResult | null
    kkmSetting: KkmSetting
}

const useSubmit = ({
                       paid,
                       userId,
                       loadingFromServer,
                       setLoader,
                       setKkmError,
                       state,
                       data,
                       kkmSetting
                   }: Props) => {

    const {closeModal} = useModal()

    return useCallback(() => {
        let params = {
            month: state.month,
            cashbox: state.selectedCashbox.value,
            employeeId: userId,
            branchId: state.branch?.value,
            sum: paid + +state.value,
        }

        if (params) {
            setLoader(true);

            const command = new ServerCommand('salary/payment/save', params);
            const kkm = new Kkm(kkmSetting)

            if (state.selectedKkm) {

                // @ts-ignore
                kkm.paymentCash(state.value).then((response: KkmResponse) => {
                    if (response.Status == 0) {
                        command.exec().then(() => {
                            closeModal()
                            if (loadingFromServer) loadingFromServer()
                        });
                    } else {
                        setLoader(false);
                        setKkmError(response.Error)
                        console.log(response.Error)
                    }
                }).catch(reason => setKkmError(reason))
            } else {
                command.exec().then(() => {
                    closeModal()
                    if (loadingFromServer) loadingFromServer()
                });
            }

        }

    }, [
        userId,
        loadingFromServer,
        setLoader,
        state,
        data,
        kkmSetting
    ])

};

export default useSubmit;
