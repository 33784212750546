import React from 'react';
import CustomTooltip from "../../../../../../../../components-ui/Charts/ChartBar/components/CustomTooltip";
import Helper from "../../../../../../../../common/helpers/main";
import Box from "../../../../../../../../components-ui/Layout/Box";
import {StateAverageCheck} from "../../../types";
import {BarDataItem} from "../../../../../../../../components-ui/Charts/ChartBar";
import useFormatterUser from "../../../hooks/useFormatterUser";
import {useSelector} from "react-redux";
import _ from 'lodash'
import {clinicInfo} from "../../../../../../data/widgetsInfo";

interface Props {
    index: number
    state: StateAverageCheck
    data: BarDataItem[]
    positionOffsets?: {
        top?: string
        left?: string
        right?: string
    }
}

const AverageTooltip = ({data, index, state, positionOffsets}: Props) => {
    const formatterUser = useFormatterUser()
    const directions = _.clone(useSelector(state1 => state1.common.directions))
    directions.clinic = clinicInfo;
    return (
        <Box position="absolute" positionOffsets={positionOffsets} zIndex={12}>
            <CustomTooltip payload={[{payload: data[index]}]}
                           active={true}
                           paddingTooltip={state.currentTab === 'directions' ? '6px 0' : '2px 0'}
                           formatterValue={value => Helper.String.numberFormat(value, 0)}
                           formatterNameTooltip={value => state.currentTab === 'directions' ? formatterUser(value) : directions[value].name}
                           formatterTitleTooltip={value => state.currentTab !== 'directions' ? formatterUser(value) : directions[value].name}
            />
        </Box>
    );
};

export default AverageTooltip;
