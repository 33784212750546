import React from 'react';
import TaskContent from "../TaskContent";
import {TaskCallCenter} from "../../../../types/common";
import {LongAbsenceData} from "../../../../types/taskData";
import {ServicesWithTeeth, UniquePatient} from "../../../../components/Labels";
import {AgainstCallsButton, FinalButton, NoteDownButton, OpenPlanButton} from "../../../../components/Buttons";
import {CommonTaskProps} from "../../types/common";

interface Props extends CommonTaskProps {
    task: TaskCallCenter<LongAbsenceData>
}

export const LongAbsence = ({task, uniqPatient, onChangeSelectedScript, ...props}: Props) => {
    return (
        <TaskContent onChangeSelectedScript={onChangeSelectedScript} buttons={<Buttons task={task} {...props}/>}
                     task={task}>
            <UniquePatient task={task} uniqPatient={uniqPatient}/>
            <ServicesWithTeeth task={task}/>
        </TaskContent>
    );
};

const Buttons = ({
                     task,
                     validate,
                     blockedButtons,
                     methodsCallCenter
                 }: Omit<Props, 'uniqPatient' | 'onChangeSelectedScript'>) => {
    return (
        <>
            <NoteDownButton validate={validate} task={task} blockedButtons={blockedButtons}
                            methodsCallCenter={methodsCallCenter}/>
            <AgainstCallsButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
            <FinalButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
            <OpenPlanButton task={task} methodsCallCenter={methodsCallCenter}/>
        </>
    )
}
