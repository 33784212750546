import React from "react";
import {WrapTopSearch} from "../../style/topSearch";
import TextField from "../../../../components-ui/Inputs/TextField/TextField";
import {BoxBody} from "../../../../components-ui/styledComponent/common/wraps";
import ResultSearchPatient from "../../../../components/ResultSearchPatient/ResultSearchPatient";
import Button from "../../../../components-ui/Inputs/Button/Button";
import useSearchBar from "./hooks/useSearchBar";

export default function SearchBar() {
    const {
        value, resetTopSearch, onChangeSearchBar, onClickPatient, patientList, loaderPatientList,
        displayPatientList, ref
    } = useSearchBar();

    return (
        <BoxBody>
            <WrapTopSearch>
                <TextField isSearch={true}
                           value={value}
                           autoFocus={false}
                           onChange={onChangeSearchBar}
                           placeholder="Поиск пациента по ФИО и телефону"
                           widthInput="100%"/>
                <Button variant="standard"
                        onClick={resetTopSearch}
                        children="Сбросить"/>
                <ResultSearchPatient patientList={patientList}
                                     displayPatientList={displayPatientList}
                                     loaderPatientList={loaderPatientList}
                                     onClickPatient={onClickPatient}
                                     ref={ref}/>
            </WrapTopSearch>
        </BoxBody>

    )
}
