import React from "react";
import SubTitleTask from "../../../../containers/Tasks/components/UI/SubTitleTask";
import Helper from "../../../../common/helpers/main";
import {ServiceWithTeeth} from "../../types/taskData";
import {TaskCallCenter} from "../../types/common";
import { STaskItem, SListCare, SItemCare } from "../../../../containers/Tasks/style";
import {useSelector} from "react-redux";

interface Props {
    task: TaskCallCenter
}

export const ServicesWithTeeth = ({task}: Props) => {
    const servicesNames = useSelector(state => state.common.servicesNames)

    const services = (!!task.payload.doneServices ? task.payload.doneServices : task.payload.undoneServices) as ServiceWithTeeth[];
    return (
        <>
            <SubTitleTask children={!!task.payload.doneServices ? 'Выполненные услуги' : 'Невыполненные услуги'}/>
            <STaskItem>
                {!!services.length && (
                    <SListCare>
                        {services.map(service => {
                            const children = [servicesNames[service.serviceId]]
                            if (!!service.teeth) {
                                children.push(`(зубы: ${Helper.sequenceToRange(service.teeth)})`)
                            }
                            return (
                                <SItemCare key={service.serviceId}>
                                    {children.join(' ')}
                                </SItemCare>
                            )
                        })}
                    </SListCare>
                )}
            </STaskItem>
        </>
    )
}
