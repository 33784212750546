import React, {useRef, useState} from 'react'
import PatientCard from "../../../content/PatientCard/PatientCard";
import {HeaderSearch} from "../styledHeader";
import useDebounce from "../../../common/hooks/useDebounce";
import useSearchPatientList from "../../../common/hooks/useSearchPatientList";
import ResultSearchPatient from "../../../components/ResultSearchPatient/ResultSearchPatient";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import useDisplayPatientList from "../../PatientField/hooks/useDisplayPatientList";
import {useDialogsManager} from "../../DialogsManager/DialogsManager";
import {PatientValues} from "../../PatientField";

export default function Search() {
    const ref = useRef(null);
    const [value, setValue] = useState('');
    const debouncedValue = useDebounce(value, 500);
    const [displayPatientList, setDisplayPatientList] = useDisplayPatientList(ref);
    const {showSlideModal} = useDialogsManager();

    const [patientList, loaderPatientList] = useSearchPatientList({value: debouncedValue, nameField: 'searchQuery', isFilter: true});

    const openPatientCard = (patient: PatientValues) => {
        showSlideModal(<PatientCard
            patientId={patient.id}/>)
        setValue('');
        setDisplayPatientList(false)
    };


    return (
        <HeaderSearch ref={ref}>
            <TextField onChange={(value: any) => {
                if (value.length >= 2) {
                    if (!displayPatientList) setDisplayPatientList(true)
                } else {
                    if (displayPatientList) setDisplayPatientList(false)
                }
                setValue(value);
            }}
                       placeholder="Поиск пациента по ФИО и телефону"
                       value={value}
                       isSearch={true}
                       widthInput="100%"
            />
            <ResultSearchPatient patientList={patientList}
                                 displayPatientList={displayPatientList}
                                 loaderPatientList={loaderPatientList}
                                 onClickPatient={openPatientCard} ref={ref}
                                 topPosition={62} />
        </HeaderSearch>
    )
}
