import {Dispatch, SetStateAction, useEffect} from "react";
import {echo} from "../../../common/server/echo";
import {StateManagerTasks} from "../types/components";
import {ManagerTaskCreatedEvent, ManagerTaskDeletedEvent, ManagerTaskUpdatedEvent} from "../types/eventParams";
import usePrevious from "../../../common/hooks/usePrevious";
import {useSelector} from "react-redux";

interface Props {
    setState: Dispatch<SetStateAction<StateManagerTasks>>
    state: StateManagerTasks
}

const useEchoManagerTasks = ({setState, state}: Props) => {
    const user = useSelector(state1 => state1.user);
    const prevStateBranches = usePrevious(state.branches);

    useEffect(() => {

        const taskCreatedHandler = (params: ManagerTaskCreatedEvent) => {
            setState(prevState => {
                prevState.tasks.push(params.task)
                return {...prevState}
            })
        }

        const taskUpdatedHandler = (params: ManagerTaskUpdatedEvent) => {
            setState(prevState => {
                const findIndexTask = prevState.tasks.findIndex(task => task.id === params.task.id)
                if (findIndexTask !== -1) prevState.tasks[findIndexTask] = params.task
                return {...prevState}
            })
        }

        const taskDeletedHandler = (params: ManagerTaskDeletedEvent) => {
            setState(prevState => {
                prevState.tasks = prevState.tasks.filter(task => task.id !== params.taskId)
                return {...prevState}
            })
        }

        user.branches.forEach(branchId => {
            echo.private(`manager.${branchId}`)
                .listen('Manager\\ManagerTaskCreated', taskCreatedHandler)
                .listen('Manager\\ManagerTaskUpdated', taskUpdatedHandler)
                .listen('Manager\\ManagerTaskDeleted', taskDeletedHandler)
        })

        return () => {
            user.branches.forEach(branchId => {
                echo.private(`manager.${branchId}`)
                    .stopListening('Manager\\ManagerTaskCreated')
                    .stopListening('Manager\\ManagerTaskUpdated')
                    .stopListening('Manager\\ManagerTaskDeleted')
            })
        }
        
    }, [prevStateBranches, setState, state.branches, user.branches])
}

export default useEchoManagerTasks
