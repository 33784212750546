import React from 'react';
import {useModal} from '../../../../../containers/DialogsManager/containers/Modal/Modal';
import ServerCommand from '../../../../../common/server/server-command';
import {StandardDeletionParams} from '../../../../../types';
import ConfirmModal from '../../../../../content/modals/ConfirmModal';

interface Props {
    id: number
    refreshDataFromServer: () => void
}

const ModalDeleteDoneEmployeeService = ({id, refreshDataFromServer}: Props) => {

    const {closeModal} = useModal()

    const deleteService = () => {
        ServerCommand.post('laboratory/done-employee-service/delete', {id: id} as StandardDeletionParams, () => {
            closeModal()
            refreshDataFromServer()
        }).then()
    }

    return (
        <ConfirmModal
            descrText="Вы точно хотите удалить услугу?"
            submitText="Удалить"
            onClose={closeModal}
            onSumbit={deleteService}/>
    );
};

export default ModalDeleteDoneEmployeeService;
