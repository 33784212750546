import React from 'react';
import {Label} from '../../../../../../components-ui/DataDisplay/Label';
import {commonLabelOption, commonTextFieldOption} from '../../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import TextField from '../../../../../../components-ui/Inputs/TextField/TextField';

interface Props {
    comment: string | null
    handleComment: (val: string) => void
    isRequired: boolean
    isValid: boolean
}

const MarkComment = ({comment, handleComment, isRequired, isValid}: Props) => {
    return (
        <Label {...commonLabelOption}
               isRequired={isRequired}
               labelText="Комментарий"
               align="leftTop"
               marginLabel="8px 10px 0 0">
            <TextField
                {...commonTextFieldOption}
                value={comment ? comment : ''}
                onChange={handleComment}
                isTextArea={true}
                isError={isValid}
            />
        </Label>
    );
};

export default MarkComment;
