import React from "react";
import {PropsConditions} from "./interfaces/common";
import Box from "../../components-ui/Layout/Box";
import Typography from "../../components-ui/DataDisplay/Typography/Typography";
import {Label} from "../../components-ui/DataDisplay/Label";
import Button from "../../components-ui/Inputs/Button/Button";
import useInitialConditions from "./hooks/useInitialConditions";
import ContentConditions from "./components/ContentConditions";

export default function Conditions({conditions, diagnosisRelationParameters, onSubmitConditions}: PropsConditions) {
    const {keyParameters, state, setState, conditionsArr, submitConditions} = useInitialConditions(
        {conditions, diagnosisRelationParameters, onSubmitConditions})

    return (
        <Box padding="20px 20px 25px">
            <Typography children="Параметры автоподстановки" variant="bigger-title" alignItems="center" margin="0 0 28px"/>
            {conditionsArr.map(condition => {
                return (
                    <Label labelText={keyParameters[condition].name}
                           key={condition}
                           widthLabel="190px"
                           marginLabel="0 20px 0 0"
                           marginWrap="0 0 18px">
                        <ContentConditions state={state}
                                           setState={setState}
                                           keyParameters={keyParameters}
                                           condition={condition} />
                    </Label>
                )
            })}
            <Button children="Готово" variant="success" size="long" margin="20px 0 0" onClick={submitConditions}/>
        </Box>
    )
}
