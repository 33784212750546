import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {useLazyLoading} from "../../../common/hooks/useLazyLoading";

interface Props {
    refScrollList: any
    filterItems: any[]
}

const useAppendItems = ({refScrollList, filterItems}: Props) => {
    const [items, setItems] = useState<any[]>([]);

    useEffect(() => {
        setItems(_.take(filterItems, 20))
    }, [JSON.stringify(filterItems)])

    const appendItems = useCallback(() => {
        setItems(prevState => {
            const restItems = _.drop(filterItems, items.length)
            return [
                ...prevState,
                ..._.take(restItems, 20)
            ]
        });
    }, [JSON.stringify(items), setItems]);

    const onScroll = useLazyLoading({
        onIntersection: appendItems,
        containerRef: refScrollList
    })

    return [items, onScroll]
}

export default useAppendItems
