import React, {Dispatch, SetStateAction} from 'react';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import Select from '../../../../components-ui/Inputs/Select/Select';
import Box from '../../../../components-ui/Layout/Box';

interface Props {
    selectedBranch: OptionSelect
    setSelectedBranch: Dispatch<SetStateAction<OptionSelect>>
    optionsBranches: OptionSelect[]
}

const LaboratoriesServiceRateFilter = ({selectedBranch, setSelectedBranch, optionsBranches}: Props) => {

    return (
        <Box display='flex' gap='0 16px' margin="22px 0">
           {optionsBranches.length > 1 &&
               <Select sizeSelect="sm" options={optionsBranches} value={selectedBranch} onChange={setSelectedBranch} />
           }
       </Box>
    );
};

export default LaboratoriesServiceRateFilter;
