import React from 'react';
import DateRange from "../../../components-ui/Inputs/DateRange";
import Box from "../../../components-ui/Layout/Box";
import Loader from "../../../components-ui/Feedback/Loader/Loader";
import {HandlersAnalytics, StateAllDateRange} from "../types";
import SelectCheckbox from "../../../components-ui/Inputs/SelectCheckbox/SelectCheckbox";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";

interface Props {
    allDateRange: StateAllDateRange
    handlers: HandlersAnalytics
    optionsBranches: OptionSelect[]
    selectedBranches: number[]
}

const Header = ({allDateRange, handlers, optionsBranches, selectedBranches}: Props) => {
    return (
        <Box display="flex">
            {optionsBranches.length > 1 && (
                <SelectCheckbox options={optionsBranches}
                                placeholder="Все доступные филиалы"
                                selected={optionsBranches.filter(branch => !!selectedBranches && selectedBranches.includes(branch.value))}
                                onChange={handlers.handleBranches}
                                width="380px"
                                variant="sm"
                                margin="0 16px 0 0"
                />
            )}
            <DateRange startDate={allDateRange.startDate}
                       endDate={allDateRange.endDate}
                       setStartDate={handlers.handleAllStartDate}
                       setEndDate={handlers.handleAllEndDate} margin="0 0 28px"
                       maxDate={new Date()}/>
            <Box position="relative" width="32px" height="32px" margin="0 0 0 15px">
                <Loader visible={allDateRange.loader} isBackground={false} width={28} height={28} />
            </Box>
        </Box>
    );
};

export default Header;
