import React from 'react';
import {ManagerTask} from "../../types/common";
import {ReducedIncomingCallsCountPayload} from "../../types/taskData";
import {Date, IncomingCallsCount} from "../Labels";
import {STaskList} from "../../../../containers/Tasks/style";

interface Props {
    task: ManagerTask<ReducedIncomingCallsCountPayload>
    renderBranches: () => React.ReactNode | null
}

const ReducedIncomingCallsCount = ({task, renderBranches}: Props) => {
    return (
        <STaskList padding="19px 0 16px">
            {renderBranches()}
            <Date date={task.payload.date}/>
            <IncomingCallsCount incomingCallsCount={task.payload.incomingCallsCount} norm={task.payload.norm}/>
        </STaskList>
    );
};

export default ReducedIncomingCallsCount;
