import {useMemo, useRef} from "react";
import {GroupsButtonName} from "../types";

interface Props {
    groupsButtonName: GroupsButtonName
    showHiddenButtons: boolean
}

const useHeightsComponents = ({groupsButtonName, showHiddenButtons}: Props) => {

    const refHeader = useRef<HTMLElement>(null);
    const refFooter = useRef<HTMLElement>(null);
    const refTop = useRef<HTMLElement>(null)

    const heightContainerButtons = useMemo(() => {
        if (groupsButtonName.length > 3) {
            if (showHiddenButtons) {
                return groupsButtonName.length * 34 + 15
            } else {
                return 3 * 34 + 15
            }
        } else {
            return groupsButtonName.length * 34 + 15
        }
    }, [showHiddenButtons, groupsButtonName])

    const heightHeaderAndFooter = useMemo(() => {
        let heightHeader = 63;
        let heightFooter = 48;
        let heightTop = 0;
        if (!!refHeader.current && !!refFooter.current && !!refTop.current) {
            heightHeader = refHeader.current.getBoundingClientRect().height;
            heightFooter = refFooter.current.getBoundingClientRect().height;
            heightTop = refTop.current.getBoundingClientRect().height;
        }
        return heightHeader + heightFooter + heightTop
    }, [refHeader.current, refFooter.current, refTop.current])

    const heightFilter = useMemo(() => {
        let heightButtons = heightContainerButtons + 8;
        if (groupsButtonName.length > 3) heightButtons += 25
        return heightButtons + heightHeaderAndFooter;
    }, [showHiddenButtons, groupsButtonName, heightHeaderAndFooter, heightContainerButtons])

    return {
        heightContainerButtons,
        heightFilter,
        refHeader,
        refFooter,
        refTop
    }
}

export default useHeightsComponents
