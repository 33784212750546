import React, {FC} from 'react';
import Box from "../../../components-ui/Layout/Box";

const ContentWidgetWrap: FC = ({children}) => {
    return (
        <Box margin="41px 0 0" padding="0 0 36px">
            {children}
        </Box>
    );
};

export default ContentWidgetWrap;
