import styled from "styled-components";
import {PItemGroup} from "./typesItemTable";


export const ItemTableWrap = styled.div.attrs({className: 'ItemTable'})`
    display: flex;
    border-bottom: 1px solid #e2e6ed;
    align-items: center;
`

export const WrapCheckbox = styled.div.attrs({className: 'WrapCheckbox'})`
    padding: 18px 8px 17px 20px;
    flex-shrink: 0;
`

export const WrapNameGroup = styled.div.attrs({className: 'WrapNameGroup'})<PItemGroup>`
    display: flex;
    padding: 13px 0 14px;
    align-items: center;
    cursor: pointer;
    width: 849px;
    flex-shrink: 0;
    .group-triangle {
        width: 10px;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: ${props => props.theme.colors.info};
        margin-right: 10px;
        transform: rotate(${props => props.openContent ? '180deg' : 0});
        transition: .3s;
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`

export const TextGroup = styled.div.attrs({className: 'TextGroup'})`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => props.theme.colors.info};
`

export const DoctorGroup = styled.div.attrs({className: 'DoctorGroup'})`
    width: calc(100% - 905px);
    padding-left: 20px;
    flex-shrink: 0;
`

export const DoctorSelectGroup = styled.div.attrs({className: 'DoctorSelectGroup'})`
    width: calc(100% - 905px);
    flex-shrink: 0;
`

export const WrapServices = styled.div.attrs({className: 'WrapServices'})`
    border-bottom: 1px solid #e2e6ed;
`
