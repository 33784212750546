export const WIDTH_TABLE_COLUMNS = {
    date: '132px',
    type: '220px',
    fio: '320px',
    sum: '160px',
    paymentMethod: '208px',
    paymentMethodCovered: '200px',
    services: '21vw',
    invoice: '143px',
    doctors: '176px',
    balance: '117px',
    trueBalance: '235px'
}
