import React from "react";
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {buttonTitles} from "../../helper/getDataCallCenter";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Box from "../../../../components-ui/Layout/Box";
import {CommonButtonProps} from "../../types/components";

interface Props extends CommonButtonProps {
    blockedButtons: boolean
}

export const FinalButton = ({task, blockedButtons, margin, methodsCallCenter}: Props) => {
    const {deleteVisit} = methodsCallCenter;
    return (
        <Box className="button-task-content" margin={margin}>
            <Button {...commonPropsButton} variant={blockedButtons ? 'disabled' : "danger"}
                    children="завершить"
                    title={blockedButtons ? buttonTitles.blocked : buttonTitles.final}
                    onClick={() => deleteVisit(task.id)}/>
        </Box>
    )
}
