import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const CheckOldIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 23.87 18"} ref={ref}>
            <polygon points="11.67 18 0 9.01 2.88 5.27 10.91 11.46 20.21 0 23.88 2.97 11.67 18"/>
        </WrapIcon>
    );
});
