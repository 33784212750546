import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth55Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 18.37 23.97"} width={width || "12"} height={height || "16"} ref={ref}>
            <path d="M18.21,18.59C16.65,21.26,12.39,24,10.46,24,9.09,24,.05,22.5,0,16.05.34,12.62,2,3.33,5.71.71A3.4,3.4,0,0,1,8.53.08c3.92.83,6.5,5.4,7.69,8,2,4.7,2.53,9.42,1.91,10.47Z"/>
        </WrapIcon>
    );
});
