import React, {Dispatch, SetStateAction} from "react";
import Button from "../../../../../../../components-ui/Inputs/Button/Button";
import {callCenterTasksInfo} from "../../../../../helper/getDataCallCenter";
import Box from "../../../../../../../components-ui/Layout/Box";
import useTypeTasks from "../hooks/useTypeTasks";
import {allTypesOfTasks} from "../../../../../../../containers/Tasks/data/allTypesOfTasks";
import {StateDetailed} from "../../../hooks/useDetailed";
import { SBoxFlex } from "../../../../../../../containers/Tasks/style";

interface PropsFilterHistory {
    detailedState: StateDetailed
    selectedFilter: string,
    setSelectedFilter: Dispatch<SetStateAction<string>>
}

const FilterHistory = ({selectedFilter, setSelectedFilter, detailedState}: PropsFilterHistory) => {
    const typeTasks = useTypeTasks({detailedState});
    return (
        <Box boxShadow="callFilter">
            <SBoxFlex padding="20px">
                <Button variant={selectedFilter === 'all' ? 'info' : 'info-outline'}
                        noShadow={true}
                        size="sm-standard"
                        fontSize="14px"
                        children={allTypesOfTasks.name}
                        margin="0 4px 6px 0"
                        onClick={() => {
                            if ('all' !== selectedFilter) setSelectedFilter('all')
                        }}
                />
                {typeTasks.map(item => {
                    let variant = 'info-outline'
                    if (selectedFilter === item) variant = 'info';
                    return (
                        <Button variant={variant}
                                noShadow={true}
                                size="sm-standard"
                                fontSize="14px"
                                children={callCenterTasksInfo[item].name}
                                margin="0 4px 6px 0"
                                onClick={() => {
                                    if (item !== selectedFilter) setSelectedFilter(item)
                                }}
                        />
                    )
                })}
            </SBoxFlex>
        </Box>
    )
}

export default FilterHistory
