import {useMemo} from "react";
import {IncomingCallPatient} from "../../../types/taskData";
import {StateDetailed} from "./useDetailed";

interface PropsIncomingPatient {
    detailedState: StateDetailed
}
const useProgressCallPatient = ({detailedState}: PropsIncomingPatient) => {
    const {tabPatient, progressCall} = detailedState;
    return useMemo(() => {
        if (!!progressCall.length) {
            if (!!tabPatient) {
                return progressCall[progressCall.length - 1].payload.patients.find((item: any) => item.id === +tabPatient) as IncomingCallPatient
            } else {
                return !!progressCall[progressCall.length - 1].payload.patients.length ? progressCall[progressCall.length - 1].payload.patients[0] : null
            }
        } else {
            return null
        }
    }, [tabPatient, JSON.stringify(progressCall)])
}

export default useProgressCallPatient
