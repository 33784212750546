import {Dispatch, SetStateAction, useEffect} from "react";
import ServerCommand from "../../../../common/server/server-command";
import {
    IStateCashSalary,
    GetDirectionsBalanceResult,
    GetDirectionsBalanceParams, GetCashSumResult, GetCashSumParams
} from "../types";
import {KkmList, ResponseKkmList} from "../../../../pages/Settings/Kkm/types";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";

interface Props {
    setState: Dispatch<SetStateAction<IStateCashSalary>>
    setData: Dispatch<SetStateAction<GetDirectionsBalanceResult | null>>
    setKkmOptions: Dispatch<SetStateAction<OptionSelect[]>>
    branchId: number | undefined
}

const useLoadData = ({setState, setData, setKkmOptions, branchId}: Props) => {

    useEffect(() => {
        if (!!branchId) {
            ServerCommand.get('cash/sum', {branchId: branchId} as GetCashSumParams).then((response: GetCashSumResult) => {

                setState(prevState => ({
                    ...prevState,
                    cashboxSum: response,
                }))
            });
        }
    }, [branchId])

    useEffect(() => {
        if (!!branchId) {
            ServerCommand.get('kkm/list', {
                filter: {
                    branchId: branchId
                }
            }).then((response: ResponseKkmList) => {

                setState(prevState => ({
                    ...prevState,
                    kkmList: response.list,
                }))
                setKkmOptions(response.list.map((kkm) => {
                    return {
                        value: kkm.id,
                        label: kkm.name
                    }
                }))

            });
        }
    }, [branchId])

};

export default useLoadData;
