import styled from "styled-components";

interface IStandardModal {
    positionX?: 'flex-start' | 'center' | 'flex-end'
    positionY?: 'flex-start' | 'center' | 'flex-end'
}


export const StandardModal = styled.div<IStandardModal>`
    display: flex;
    flex-direction: column;
    justify-content: ${props => !!props.positionX ? props.positionX : 'center'};
    align-items: ${props => !!props.positionY ? props.positionY : 'center'};
    padding: 45px 30px;
    min-width: 400px;
`;

interface IStandardModalTitle {
    position?: 'left' | 'center' | 'right'
}

export const StandardModalTitle = styled.h3<IStandardModalTitle>`
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: ${props => !!props.position ? props.position : 'center'};
    margin-top: 0;
    padding-left: 23px;
    padding-right: 23px;
    max-width: max-content;
    margin-bottom: 45px;
`;
