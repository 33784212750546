import React from 'react';
import useTimeSheet from "../hooks/useTimeSheet";
import FilterBranches from "../components/FilterBranches";
import TableTimesheetList from "../components/TableTimesheetList";
import Paper from '../../../containers/UI-Collection/Elements/Surfaces/Paper';

const TimeSheetList = () => {

    const {
        optionsBranches,
        selectedBranch,
        setSelectedBranch,
        data,
        loader,
    } = useTimeSheet()

    return (
        <>
            {optionsBranches.length > 1 ?
                <FilterBranches optionsBranches={optionsBranches} selectedBranch={selectedBranch}
                                setSelectedBranches={setSelectedBranch}/>
                :
                null
            }

            <Paper padding="24px 24px 52px 24px" margin={`${optionsBranches.length > 1 ? '28px' : '32px'} 0`} borderRadius="8px">
                <TableTimesheetList data={data}
                                    selectedBranchId={selectedBranch.value}
                                    loader={loader}
                />
            </Paper>
        </>
    );
};

export default TimeSheetList;