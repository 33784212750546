import React from 'react';
import {useModal} from '../../../../../containers/DialogsManager/containers/Modal/Modal';
import ServerCommand from '../../../../../common/server/server-command';
import ConfirmModal from '../../../../../content/modals/ConfirmModal';
import {StandardDeletionParams} from '../../../../../types';

interface Props {
    id: number
}

const XrayPicturesModalDelete = ({id}: Props) => {

    const {closeModal} = useModal()

    const deleteXRay = () => {
        ServerCommand.post('xray-picture/delete', {id: id} as StandardDeletionParams, () => {
            closeModal()
        }).then()
    }

    return (
        <ConfirmModal
            descrText="Вы точно хотите удалить снимок?"
            submitText="Удалить снимок"
            onClose={closeModal}
            onSumbit={deleteXRay}/>
    );
};

export default XrayPicturesModalDelete;
