import styled from "styled-components";
import {PopupDesc} from "../../../components-ui/styledComponent/common/commonStyle";

export const WrapAddModal = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    & > span {
        margin-bottom: 10px;
    }
    button {
        margin-left: 0;
        margin-top: 32px;
    }
`;

export const WrapDecision = styled.div`
    display: flex;
    button {
         margin-left: 20px;
         padding: 6px 20px 8px;
    }
`;

export const ServiceDesc = styled(PopupDesc)`
    margin-bottom: 16px;
    max-width: 480px;
`;

export const DoctorDesc = styled(PopupDesc)`
    margin-bottom: 28px;
    max-width: 480px;
`;

export const CheckedWrapModal = styled.div`
    display: flex;
    margin-bottom: 10px;
`

export const CheckedNameModal = styled.div`
    margin-right: 24px;
`
