import React from "react";
import {useDentalCard} from "../../DentalRecords";
import ToothItem from "../ToothItem/ToothItem";
import {SDentalCard} from "../../types";
import {JawList} from "./styledJaw";
import ButtonJaw from "../ButtonJaw/ButtonJaw";
import { PJaw } from "./typesJaw";


export default function Jaw({jaw = 'lower'}: PJaw) {
    const {currentJaw} = useDentalCard() as SDentalCard;
    return (
        <JawList>
            <ButtonJaw jaw={jaw}/>
            {currentJaw(jaw).map((tooth, index) => <ToothItem key={tooth.number}
                                                              tooth={tooth}
                                                              jaw={jaw}
                                                              toothIndex={index}/>)}
        </JawList>
    )
}
