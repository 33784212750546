import React from "react";
import { STextPhone } from "../../PatientField/style/phoneView";

interface PTextEmail {
    email?: string,
}

export default function TextEmail({email}: PTextEmail) {
    if (!email) return <>-</>;
    return <STextPhone href={`mailto:${email}`}
                       onClick={e => e.stopPropagation()}>
        {email}
    </STextPhone>
}
