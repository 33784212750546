import React from "react";
import LoaderSpinner from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {PLoader} from "./typesLoader";
import { LoaderWrap } from "./styledLoader";

export default function Loader({visible} : PLoader) {
    return (
        <LoaderWrap>
            <LoaderSpinner visible={visible}
                           type={'Oval'}
                           color={'#00d6d6'}
                           height={20}
                           width={20}
            />
        </LoaderWrap>

    )
}
