import React from "react";
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {buttonTitles} from "../../helper/getDataCallCenter";
import preparePatientWAP from "../../helper/preparePatientWAP";
import SVG from "../../../../common/helpers/SVG";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Box from "../../../../components-ui/Layout/Box";
import {CommonButtonProps} from "../../types/components";

interface Props extends CommonButtonProps{
    personalPhone: string,
    blockedButtons: boolean,
}

export const AddWLButton = ({task, personalPhone, blockedButtons, margin, methodsCallCenter}: Props) => {
    const {WAPVisit, deleteVisit} = methodsCallCenter;
    return (
        <Box className="button-task-content" margin={margin}>
            <Button {...commonPropsButton} variant={blockedButtons ? "disabled" : "default"}
                    title={blockedButtons ? buttonTitles.blocked : buttonTitles.addWL}
                    onClick={() => {
                        const isConfirm = task.type === 'confirm_visit';
                        WAPVisit(preparePatientWAP(task, personalPhone), false, isConfirm ? undefined : () => {
                            deleteVisit(task.id)
                        })
                    }}
                    children={<SVG name="call-center/download" className="call-center-download-button" />}/>
        </Box>

    )
}
