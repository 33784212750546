import React, {useMemo} from "react";
import Button from "../../../components-ui/Inputs/Button/Button";
import Helper from "../../../common/helpers/main";
import {Label} from "../../../components-ui/DataDisplay/Label";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import {commonLabelOption} from "./data";
import PaymentMethod from "./components/PaymentMethod";
import AccountBalance from "./components/AccountBalance";
import {useSelector} from "react-redux";
import {useOptionsBranchesUser} from "../../../common/hooks/useOptionsBranchesUser";
import FilterBranches from "./components/FilterBranches";
import Box from "../../../components-ui/Layout/Box";
import FilterPatient from "./components/FilterPatient";
import useCashReturn from "./hooks/useCashReturn";
import KkmSelect from "./components/KkmSelect";
import ReturnSum from "./components/ReturnSum";
import Operation from "./components/Operation";
import Comment from "./components/Comment";
import Expenditure from "./components/Expenditure";
import FilterUser from "./components/FilterUser";
import CashBox from "./components/CashBox";
import ValidationLinkInfo from "../../../components/ValidationLinkInfo/ValidationLinkInfo";

interface Props {
    patientId?: number
    loadingFromServer: () => void
}

const CashReturn = ({patientId: fixedPatientId, loadingFromServer}: Props) => {

    const discountTypeOptions = useSelector(state => state.common.discountTypeOptions);
    const paymentMethodOptions = useSelector(state => state.common.paymentMethodOptions);
    const cashboxOptions = useSelector(state => state.common.cashboxOptions);
    const operationOptions = [
        {value: 'P', label: 'Вернуть аванс'},
        {value: 'U', label: 'Выдать ДС сотруднику'},
        {value: 'C', label: 'Выдать ДС контрагенту'}
    ]
    const optionsBranches = useOptionsBranchesUser()

    const {
        kkmError,
        data,
        patientId,
        setPatientId,
        userId,
        setUserId,
        state,
        setState,
        handleSubmit,
        loader,
        maxSum,
        kkmOptions
    } = useCashReturn({
        fixedPatientId,
        loadingFromServer,
        discountTypeOptions,
        paymentMethodOptions,
        cashboxOptions,
        operationOptions,
        optionsBranches
    })

    if (fixedPatientId !== undefined && data === null) {
        return null
    }

    const validate = () => {
        let valid = true
        valid = valid && !!state.value
        valid = valid && state.branch !== undefined
        switch (state.type) {
            case 'P':
                valid = valid && !!patientId
                break
            case 'U':
                valid = valid && !!userId
                valid = valid && !!state.selectedExpenditure?.value
                break
            case 'C':
                valid = valid && !!state.selectedExpenditure?.value
                break
            default:
                return valid = false
        }


        if (!!state.selectedKkm) {
            valid = valid && !kkmError
        }
        if (!state.cashboxSum[state.selectedCashbox.value]) {
            valid = false
        } else if (state.cashboxSum[state.selectedCashbox.value] < state.value) {
            valid = false
        }
        if (state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' && kkmOptions.length > 0 && !state.selectedKkm) {
            valid = false
        }

        return valid
    }

    return (
        <Box padding="30px 27px 69px" minWidth="549px" width="549px">
            <Typography children="Возврат оплаты" textAlign="center" variant="bigger-title" margin="25px 0 59px"/>
            {(state.type == 'P' && data && patientId) &&
                <Box position="absolute" positionOffsets={{top: "110px"}}>
                    <Label labelText="" {...commonLabelOption}>
                        <span>
                            На счёте пациента {Helper.Price.format(data.account)} руб.
                        </span>
                    </Label>
                </Box>
            }

            <Operation state={state} setState={setState} operationOptions={operationOptions}/>

            {state.type == 'P' && fixedPatientId === undefined &&
                <FilterPatient setPatientId={setPatientId}/>
            }

            {(state.type == 'U' || state.type == 'C') &&
            <Comment state={state} setState={setState}/>
            }

            {(state.type == 'U' || state.type == 'C') &&
            <Expenditure state={state} setState={setState}/>
            }

            {state.type == 'U' &&
            <FilterUser setUserId={setUserId}/>
            }

            {optionsBranches.length > 1
                ?
                <FilterBranches state={state} setState={setState}/>
                :
                null
            }

            <CashBox state={state} setState={setState}/>

            {state.type == 'P' && state.selectedCashbox.value == 'ADDITIONAL_BOX_OFFICE' &&
                <PaymentMethod state={state} setState={setState}/>
            }

            {state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' && kkmOptions.length > 0 &&
                <KkmSelect state={state} setState={setState} kkmOptions={kkmOptions}/>
            }

            <ReturnSum state={state} setState={setState} maxSum={maxSum}/>

            <Label labelText="" {...commonLabelOption}>
                <Button
                    variant={(validate()) ? 'success' : 'disabled'}
                    children='Выдать'
                    loader={loader}
                    size="md" onClick={handleSubmit}/>
            </Label>

            {(data && patientId) &&
                <AccountBalance data={data} returnSum={+state.value}/>
            }
            <ValidationLinkInfo validate={!kkmError} onClickIsValidate={() => {}}
                                text={kkmError} linkText={""}/>
        </Box>
    )
};

export default CashReturn;
