import styled from "styled-components";

export const Link = styled.a`
    display: flex;
    width: max-content;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    border-bottom: 1px dashed rgba(${props => props.theme.colors.text}, 0.3);
    cursor: pointer;
    color: ${props => props.theme.colors.info};
    border-bottom: 1px dashed rgba(68, 124, 230, 0.3);
    &:hover {
        color: ${props => props.theme.colors.danger};
        border-bottom: 1px dashed rgba(255, 62, 127, 0.3);
    }
`
