import * as React from "react";
import {Component} from 'react';
import {AvatarProps, positionTooltip} from "../types/elements";
import {IStyle} from "../../../common/types/shared";

export default class Avatar extends Component<AvatarProps> {

    render() {
        const {doctor, handleTooltip} = this.props;
        let avatar: any = doctor.fio.slice(0, 1);
        let style: IStyle = {};
        if (doctor.photo !== undefined) {
            avatar = <img src={doctor.photo} alt={doctor.fio}/>
        } else {
            if (!!doctor.color) style.backgroundColor = doctor.color;
        }

        return (
            <span className="cell__doctor"
                  style={style}
                  onMouseEnter={(e) => {
                      const bounding = e.currentTarget.getBoundingClientRect();
                      const position: positionTooltip = {
                          x: bounding.x,
                          y: bounding.y
                      };
                      handleTooltip(position, doctor.fio)
                  }}
                  onMouseLeave={() => {
                      handleTooltip({x: 0, y: 0}, null);
                  }}
            >
                        {avatar}
            </span>
        )
    }
}
