import React, {useMemo} from 'react';
import {ResponseUserDetail, setFnType} from '../types';
import CardForm from '../../../../../../../components-ui/DataDisplay/CardForm';
import TextField from '../../../../../../../components-ui/Inputs/TextField/TextField';

interface Props {
    state: ResponseUserDetail;
    setDetailFn: (props: setFnType) => void;
}

const ContactInfo = ({state, setDetailFn}: Props) => {
    return useMemo(() =>
            <CardForm style={{margin: '0 0 16px 0'}} title='Контактная информация' forms={[
                {
                    label: 'Моб. телефон',
                    input: <TextField widthInput='100%'
                                      mask='+7 (999) 999-99-99'
                                      onChange={(value) => setDetailFn({key: 'phone', value: value.replace(/[^0-9]/g, '')})}
                                      value={state.detail.phone ?? ''} />,
                },
                {
                    label: 'Дом. телефон',
                    input: <TextField widthInput='100%'
                                      mask='+7 (999) 999-99-99'
                                      onChange={(value) => setDetailFn({
                                          key: 'additionalPhone',
                                          value: value.replace(/[^0-9]/g, ''),
                                      })}
                                      value={state.detail.additionalPhone ?? ''} />,
                },
                {
                    label: 'E-mail',
                    input: <TextField widthInput='100%'
                                      onChange={(value) => setDetailFn({key: 'email', value})}
                                      value={state.detail.email ?? ''} />,
                },
            ]} />
        , [
            state.detail.email,
            state.detail.additionalPhone,
            state.detail.phone,
        ]);
};

export default ContactInfo;
