import * as React from "react";
import Obj from "../../../../common/helpers/object";
import MultipleCounter from "../../../../components-ui/Inputs/MultipleCounter/MultipleCounter";
import Counter from "../../../../components-ui/Inputs/Counter/Counter";
import ServiceDirectionName from "../../../../components/ServiceDirectionName/ServiceDirectionName";
import {PPlanItemQuantity} from "../types";
import _ from 'underscore';

export default function PlanItemQuantity({planItem, actualDirections, isItemEnabled, plan, quantityDivideAttractTime, directions}: PPlanItemQuantity) {

    const itemCode = planItem.rowCode;

    if((actualDirections.length !== 1) || (Obj.length(planItem.directionsQuantity) > 1)) {
        const counterTitles = {} as any;
        directions.forEach((direction: any) => {
            counterTitles[direction.code] = direction.name;
        });

        const directionsForDivide = actualDirections.length ? actualDirections : _.pluck(directions, 'code')

        return (
            <MultipleCounter
                itemCode={itemCode}
                values={plan.getItemDirectionsQuantity(itemCode, directionsForDivide)}
                min={0}
                minGeneral={1}
                titles={counterTitles}
                renderTitle={(directionCode) => <ServiceDirectionName direction={directions.find((direction: any) => direction.code === directionCode)}/>}
                note="Распределите количество по направлениям"
                generalValue={planItem.quantity}
                disableCountersInputs={!isItemEnabled}
                attract={quantityDivideAttractTime}
                onChange={(values, generalQuantity) => plan.setItemQuantity(itemCode, generalQuantity, values).save()}
            />
        );
    } else {
        return (
            <Counter
                value={planItem.quantity}
                onChange={(quantity: any) => plan.setItemQuantity(itemCode, quantity).save()}
                disableInput={!isItemEnabled}
            />
        );
    }
};
