import React, {useEffect, useState} from "react";
import ServerCommand from "../../../../common/server/server-command";
import {PropsCancellation, RejectFormResponse, StateCancellation} from "../typesCancellation";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";
import WaitingAndPlanned from "../../../CallCenter/containers/WaitingAndPlanned/WaitingAndPlanned";
import {useModal} from "../../../../containers/DialogsManager/containers/Modal/Modal";
import {useDialogsManager} from "../../../../containers/DialogsManager/DialogsManager";
import useLoader from "../../../../components/Errors";

export default function useCancellation(props: PropsCancellation) {
    const {visitId, handleOnClose} = props;
    const [state, setState] = useState<StateCancellation>({
        comment: '',
        patient: null,
        selectedReasons: [{options: [], optionSelect: null}],
        validate: false,
        waitingList: true
    })
    const {showModal} = useDialogsManager()
    const {closeModal} = useModal();
    const [loader, setLoader] = useLoader()


    useEffect(() => {
        ServerCommand.get('visit/reject-form', {visitId})
            .then((response: RejectFormResponse) => {
                setState(prevState => ({
                    ...prevState,
                    patient: response.patient,
                    selectedReasons: [{
                        optionSelect: null,
                        options: response.cancellationReasons
                    }]
                }))
            })
    }, [])

    const onChangeSelectedReason = (optionSelect: OptionSelect, index: number) => {
        setState(prevState => {
            prevState.selectedReasons[index] = {
                options: prevState.selectedReasons[index].options,
                optionSelect
            };
            if (index <= prevState.selectedReasons.length - 1) {
                prevState.selectedReasons = prevState.selectedReasons.filter((item, idx) => idx <= index)
            }
            if (!!optionSelect.subReasons) {
                prevState.selectedReasons.push({
                    optionSelect: null,
                    options: optionSelect.subReasons
                })
            }
            return {...prevState, validate: false}
        })
    }

    const onClickCancellation = (e: any) => {
        e.stopPropagation();
        setLoader(true)
        const lastSelect = state.selectedReasons[state.selectedReasons.length - 1].optionSelect
        const command = new ServerCommand('visit/reject', {
            visitId,
            comment: state.comment,
            reason: lastSelect?.value
        });
        command.exec().then(() => {
            closeModal();
            if (!!handleOnClose) handleOnClose();
            if (!!lastSelect && !!lastSelect.waitingList && state.waitingList) {
                showModal(<WaitingAndPlanned patient={state.patient as any} />, {isIconClose: false})
            }
        });

    }
    return {
        state, setState, onClickCancellation, onChangeSelectedReason, loader
    }
}
