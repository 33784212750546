import React from "react";
import Button from "../../../../components-ui/OldButton/Button";
import ServerCommand from "../../../../common/server/server-command";
import TableWithGroupsTree from "../../../../components/TableWithGroupsTree/TableWithGroupsTree";
import Helper from "../../../../common/helpers/main";
import Table from "../../../../components/Table/Table";
import PropTypes from "prop-types";
import moment from "moment";
import {SlideModalContext} from "../../../../containers/DialogsManager/containers/SlideModal/SlideModal";

export default class PopupWriteOff extends React.Component {
    static contextType = SlideModalContext;

    servicesColumns = [
        {
            title: 'Код',
            code: 'code'
        },
        {
            title: 'Материал',
            code: 'name',
            searchable: true
        },
        {
            title: 'Остаток',
            code: 'quantity'
        },
        {
            title: 'Ед. измерения',
            code: 'measureShortName'
        }
    ];

    resultColumns = [
        {
            title: 'Материал',
            code: 'name',
            containsGroupTitle: true
        },
        {
            title: 'Количество',
            code: 'count',
            type: 'number',
            editable: true,
        },
        {
            title: 'Ед. измерения',
            code: 'measureShortName'
        },
        {
            title: 'Причина',
            code: 'reason',
            type: 'components.ui.Select.Select.js',
            editable: true
        }
    ];

    static propTypes = {
        reload: PropTypes.func.isRequired,
        materials: PropTypes.array.isRequired,
        materialsGroups: PropTypes.object.isRequired,
        detail: PropTypes.object,
        viewReload: PropTypes.func,
        reasons: PropTypes.object
    };

    state = {
        selectedGroupId: 0,
        chosenServiceId: 0,
        searchQuery: '',
        addedMaterials: {},
        reasons: null,
        sentData: {}
    };


    componentDidMount() {

        let newState = {};

        /* Подготавливаем селект для таблицы */
        newState.reasons = this.prepareReasons();

        /* Если редактирование списание, добавляем в таблицу нужные элементы */
        if (!!this.props.detail) {
            newState.addedMaterials = this.addedMaterialsPreparation();
            newState.sentData = this.getSnapshot(newState);
        }

        this.setState(newState);
    }


    render() {

        if (this.props.materials === null) {
            return null
        }

        let date = moment().format('DD MMMM YYYY');
        let title = 'Списание материалов';

        if (!!this.props.detail) {
            date = moment(this.props.detail.date).format('DD MMMM YYYY');
            title = `Редактировние списания материалов (№ ${this.props.detail.id}) от ${date}`;
        }


        return (
            <div className="new-invoices view-write-off">
                <div className="header-popup__title">{title}</div>
                <div className="box">
                    <div className="box-body">
                        <h4>Список материалов</h4>
                        <div className="box">
                            <TableWithGroupsTree
                                groups={this.props.materialsGroups}
                                columns={this.servicesColumns}
                                data={this.props.materials}
                                getRowCode={(service) => service.id}
                                selectedRows={Object.keys(this.state.addedMaterials).map(serviceId => Number(serviceId))}
                                onSelect={(selectedServicesIds, serviceId) => this.toggleMaterialAdd(serviceId, this.props.materials)}
                                searchPlaceholder="Поиск услуги"
                                scrollHeight={260}
                            />
                        </div>
                        <h4>Материалы на списание</h4>
                        <div className="box">
                            <div className="treatment-plan__result-table">
                                <Table columns={this.resultColumns}
                                       data={Object.values(this.state.addedMaterials)}
                                       selectMode="single"
                                       isEditable={true}
                                       selectedRows={[this.state.chosenServiceId]}
                                       onSelect={this.handleResultTableSelect.bind(this)}
                                       getRowCode={item => item.id}
                                       onChange={this.handleChangeResultTable.bind(this)}
                                       onChangeSelectTable={this.onChangeReason.bind(this)}
                                       onRowDelete={serviceId => this.toggleMaterialAdd(serviceId)}
                                       groups={this.props.materialsGroups}
                                />
                            </div>
                        </div>
                        <div className="box__btns">
                            <Button variant={this.disableBtn() ? 'disabled' : 'success'} text="Сохранить"
                                    size="long" onClick={() => this.changeWriteOff()}/>
                            <Button variant="default" text="Отменить" size="long"
                                    onClick={() => this.context.removeSlideModal()}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /* Подготавливаем селект для таблицы */
    prepareReasons() {
        const reasons = Helper.mapObj(this.props.reasons, (reason, key) => {
            return {
                label: reason,
                value: key
            }
        });
        const noReason = [{
            label: 'не выбрана',
            value: 'noReason'
        }];

        return noReason.concat(reasons);
    }

    /* Если редактирование списание, добавляет в таблицу нужные элементы */
    addedMaterialsPreparation() {
        let addedMaterials = {};
        if (this.props.detail.items !== null) {
            this.props.detail.items.forEach((item) => {
                addedMaterials[item.materialId] = {
                    id: item.materialId,
                    count: item.quantity,
                    reason: {
                        current: {
                            label: this.props.reasons[item.reason],
                            value: item.reason
                        },
                        options: this.prepareReasons()
                    }
                }
            });
            this.props.materials.forEach((material) => {
                if (addedMaterials[material.id] !== undefined) {
                    addedMaterials[material.id].name = material.name;
                    addedMaterials[material.id].groupId = material.groupId;
                    addedMaterials[material.id].measureShortName = material.measureShortName;
                }
            });
        }
        return addedMaterials;
    }

    /** Изменяет состояние селекта в таблицы
     * @param materialId {number}
     * @param selectedCode {string}
     * @param selectedValue {Object} */
    onChangeReason(materialId, selectedCode, selectedValue) {

        if (selectedCode === "reason") {
            let addedMaterials = Helper.clone(this.state.addedMaterials);
            addedMaterials[materialId].reason = {
                current: selectedValue,
                options: this.state.reasons
            };

            this.setState({addedMaterials});
        }

    }

    /**
     * Добавляет / удаляет услугу из накладной
     * @param serviceId {number}
     * @param newMaterial {Object}
     */
    toggleMaterialAdd(serviceId, newMaterial) {
        let addedMaterials = Helper.clone(this.state.addedMaterials);
        let newState = {};

        if (!!addedMaterials[serviceId]) {

            delete addedMaterials[serviceId];

            if (serviceId === this.state.chosenServiceId) {
                newState.chosenServiceId = 0;
            }

        } else {

            let addedMaterial = Helper.clone(Helper.Array.toObject(newMaterial, 'id')[serviceId]);
            addedMaterial.count = 1;
            addedMaterial.reason = {
                current: {
                    label: 'не выбрана',
                    value: 'noReason'
                },
                options: this.state.reasons
            };

            addedMaterials[serviceId] = addedMaterial;
        }

        newState.addedMaterials = addedMaterials;

        this.setState(newState);

    }

    /** При изменение количества добавляется счетчик
     * @param serviceId {number}
     * @param fieldCode {string}
     * @param fieldValue {number} */
    handleChangeResultTable(serviceId, fieldCode, fieldValue) {
        if (fieldCode === "count") {
            this.setServiceCount(serviceId, fieldValue)
        }
    }

    /** Вспомогательная функция для handleChangeResultTable
     * @param serviceId {number}
     * @param count {number} */
    setServiceCount(serviceId, count) {
        const materials = Helper.Array.toObject(this.props.materials, 'id');
        if (count <= materials[serviceId].quantity) {
            let addedMaterials = Helper.clone(this.state.addedMaterials);
            addedMaterials[serviceId].count = count;
            this.setState({addedMaterials});
        }

    }

    /** Добавляет выделение в таблицу
     * @param serviceId {number}
     * @param selectedServiceIds */
    handleResultTableSelect(selectedServiceIds, serviceId) {
        const selectedService = this.state.addedMaterials[serviceId];

        this.toggleChosenService(selectedService)
    }

    /** Вспомогательная функция для handleResultTableSelect
     * @param service */
    toggleChosenService(service) {
        let newState = {};

        if (this.state.chosenServiceId === service.id) {
            newState.chosenServiceId = 0
        } else {
            newState.chosenServiceId = service.id;
        }

        this.setState(newState)
    }

    /* Валидация кнопки сохранить */
    disableBtn() {
        const addedMaterials = Object.values(this.state.addedMaterials);
        let success = addedMaterials.length > 0;
        if (addedMaterials.length > 0) {
            if (!!this.props.detail) {
                success = !Helper.isEqual(this.state.sentData, this.getSnapshot())
            }
            Object.values(addedMaterials).forEach((item) => {
                if (item.reason.current.value === 'noReason') {
                    return success = false;
                }
            });
        }
        return !success
    }

    getSnapshot(state = null) {
        if (!state) {
            state = this.state;
        }
        return Helper.clone(state.addedMaterials);
    }

    /** Удаляем или редактируем Список материалов */
    changeWriteOff() {

        const writeOff = {
            items: Helper.mapObj(this.state.addedMaterials, (item) => {
                return {
                    materialId: item.id,
                    quantity: item.count,
                    reason: item.reason.current.value
                }
            })
        };

        let nameCommand = 'material/add-write-off';

        if (!!this.props.detail) {
            nameCommand = 'material/update-write-off';
            writeOff.id = this.props.detail.id
        } else {
            writeOff.date = moment().format('YYYY-MM-DD');
        }


        const command = new ServerCommand(nameCommand, writeOff);
        command.exec().then(() => {
            this.context.removeSlideModal();
            this.props.reload();
            if (!!this.props.detail) {
                this.props.viewReload()
            }
        });
    }


}
