import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonSelectOption} from "../../data";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {IStateCashCreate} from "../../types";
import {useSelector} from "react-redux";

interface Props {
    state: IStateCashCreate
    setState: Dispatch<SetStateAction<IStateCashCreate>>
    operationOptions: OptionSelect[]
}

const Operation = ({state, setState, operationOptions}: Props) => {


    const handleOperation = useCallback((selectedOperation: OptionSelect) => {
        setState(prevState => ({...prevState, selectedOperation, type: selectedOperation.value}))
    }, [setState])
    return (
        <Label labelText="Операция" {...commonLabelOption} >
            <Select
                {...commonSelectOption}
                options={operationOptions}
                value={state.selectedOperation}
                onChange={handleOperation}
            />
        </Label>
    );
};

export default Operation;
