import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const SearchIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M23.71,22.29l-6.83-6.82a9.52,9.52,0,1,0-1.41,1.41l6.82,6.83a1,1,0,0,0,1.42,0A1,1,0,0,0,23.71,22.29ZM9.5,17A7.5,7.5,0,1,1,17,9.5,7.5,7.5,0,0,1,9.5,17Z"/>
        </WrapIcon>
    );
});
