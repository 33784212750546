import styled from "styled-components";
import {SButton} from "../../../../components-ui/Inputs/Button/styledButton";


export const Notify = styled.div.attrs({className: 'notify'})`
    position: fixed;
    z-index: 10;
    min-width: 446px;
    bottom: 38px;
    right: 12px;
`;

export const Item = styled.div.attrs({className: 'notify-item'})`
    position: relative;
    margin: 0 auto 3px;
    font-size: 0.688rem;
    color: #ffffff;
    background-color: rgba(36, 41, 47, 0.908);
    border-radius: 4px;
    transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s, filter ease-in-out 0.1s;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0;
`;

export const Info = styled.div.attrs({className: 'notify-info'})`
    padding: 16px 15px 18px 14px;
`;

export const InfoHeader = styled.div.attrs({className: 'notify-header'})`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const InfoStatus = styled.span.attrs({className: 'notify-status'})`
    font-size: 0.813rem;
`;

export const Content = styled.div.attrs({className: 'notify-content'})`
    display: flex;
    align-items: center;
`;

export const Signal = styled.div.attrs({className: 'notify-signal'})`
    margin-top: 3px;
    width: 7px;
    height: 7px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #8db62a;
`;

export const PatientName = styled.span.attrs({className: 'notify-patient-name'})`
    margin-right: 11px;
    padding-left: 8px;
    padding-right: 7px;
    padding-top: 1px;
    border-radius: 2px;
    font-family: "Open Sans", "Arial", sans-serif;
    font-style: normal;
    font-size: 0.813rem;
    font-weight: 400;
`;

export const Time = styled.span.attrs({className: 'notify-time'})`
    font-size: 0.688rem;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1px 7px;
    border-radius: 2px;
`;

export const NotifyButton = styled(SButton).attrs({className: 'notify-button', variant: 'success'})`
    display: inline-block;
    background-color: #8db62a;
    font-size: 1rem;
    width: 189px;
    border: none;
    font-weight: 500;
    border-radius: 0 4px 4px 0;
    &:hover{
       background-color: #97c42e;
    }
    &:active {
      background-color: #85ab27;
    }
`;
