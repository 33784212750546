import styled from "styled-components";
import {ToothPrompt} from "./elements/ToothSelect/styledToothSelect";
import {BoxTitle} from "../../components-ui/styledComponent/common/typography";

interface IWrapperField {
    isMargin?: boolean
}

export const WrapperField = styled.div<IWrapperField>`
    position: relative;
    margin-bottom: ${props => props.isMargin ? '30px' : 0};
`;

export const PromptField = styled(ToothPrompt)`
    max-width: 544px;
    top: 105px;
`;

export const HelperWrapToothSelected = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: 10px;
    margin-left: 20px;
`

export const HelperTextToothSelected = styled.div`
    color: ${props => props.theme.colors.textGray};
    font-size: 13px;
    margin-right: 10px;
`;

export const TitleParameters = styled(BoxTitle)`
    font-weight: 600;
    margin-bottom: 33px;
`;
export const WrapParameters = styled.div`
    width: 716px;
`;

export const TextParameters = styled.p`
    font-size: 16px;
    line-height: 1.5;
    color: ${props => props.theme.colors.text};
    border-bottom: 1px solid ${props => props.theme.colors.borderGray};
    padding-bottom: 30px;
`;

interface ILinkParameters {
    active?: boolean
}

export const LinkParameters = styled.a<ILinkParameters>`
    text-decoration: none;
    color: ${props => props.active ? props.theme.colors.danger : props.theme.colors.info};
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.colors.danger};
    }
`;

export const WrapperParameters = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

export const Parameter = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
`;

export const ParameterTitle = styled.h4<ILinkParameters>`
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${props => props.active ? props.theme.colors.info : props.theme.colors.text};
    cursor: pointer;
    &:first-letter {
       text-transform: uppercase;
    }
`;

export const ParametersButtons = styled.div`
    display: flex;
    padding: 50px 0;
    & > button {
        margin-right: 16px;
    }
`;
