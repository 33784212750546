import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const WarningOutlineIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 21.33"} ref={ref}>
            <path d="M12,0a4.22,4.22,0,0,1,3.7,2.09h0l7.73,13a4,4,0,0,1,0,4.14,4.28,4.28,0,0,1-3.71,2.09H4.27a4.24,4.24,0,0,1-3.68-2,4,4,0,0,1,0-4.17h0l7.74-13A4.23,4.23,0,0,1,12,0Zm0,1.88A2.29,2.29,0,0,0,10,3h0L2.25,16a2.22,2.22,0,0,0,0,2.29,2.32,2.32,0,0,0,2,1.12H19.7a2.35,2.35,0,0,0,2-1.15,2.18,2.18,0,0,0,0-2.26h0L14,3A2.32,2.32,0,0,0,12,1.88ZM12,15a1.2,1.2,0,1,1,0,2.4A1.2,1.2,0,1,1,12,15Zm-.31-8.42a1.25,1.25,0,0,1,1.43.68,1.49,1.49,0,0,1,.11.6c0,.6-.08,1.2-.11,1.8,0,.92-.11,1.85-.17,2.77a8.72,8.72,0,0,0,0,.88.91.91,0,0,1-.92.87.89.89,0,0,1-.92-.84h0L10.83,9l-.09-1.14A1.32,1.32,0,0,1,11.69,6.57Z"/>
        </WrapIcon>
    );
});
