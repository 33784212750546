import {activeTab} from "../types/common";

interface TabChoice {
    name: string,
    code: activeTab
}

export const tabs = [
    {name: 'Согласен', code: 'nextVisit'},
    {name: 'Откладывает', code: 'postponeTreatmentReasons'},
    {name: 'Отказ', code: 'refuseTreatmentReasons'}
] as TabChoice[]
