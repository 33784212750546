import {useSlideModal} from '../../../../../../../containers/DialogsManager/containers/SlideModal/SlideModal';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {RequestKkmAdd, RequestKkmEdit, ResponseKkmDetail, setFnType} from '../types';
import ServerCommand from '../../../../../../../common/server/server-command';
import {OptionSelect} from "../../../../../../../components-ui/Inputs/Select/typesSelect";
import Kkm from "../../../../../../../common/server/kkm";
import {useOptionsBranchesUser} from "../../../../../../../common/hooks/useOptionsBranchesUser";
import Helper from "../../../../../../../common/helpers/main";
import {User} from "../../../../../../../types";

interface Props {
    kkmId?: number;
    refreshDataFromServer: () => void;
}

const useAddingEditingEmployee = ({kkmId, refreshDataFromServer}: Props) => {

    const {closeSlideModal} = useSlideModal();

    const [kkmOptions, setKkmOptions] = useState<OptionSelect[]>([])

    const optionsBranches = useOptionsBranchesUser()

    const [loadingPage, setloadingPage] = useState(false);

    const [loadingRequest, setLoadingRequest] = useState(false);

    const optionsCashier = useSelector(state => {
        let options: OptionSelect[] = [
            {
                label: 'Текущий пользователь',
                value: null
            }
        ]
        Helper.forEachObj(state.common.users, (user: User) => {
            if (user.inn) {
                options.push({
                    label: user.fio,
                    value: user.id
                })
            }
        })

        return options
    })

    const [state, setState] = useState<ResponseKkmDetail>({
        id: 0,
        numberDevice: null,
        name: null,
        login: 'User',
        password: null,
        url: 'http://localhost:5893',
        branchId: optionsBranches.length > 1 ? null : optionsBranches[0].value,
        selectedKkm: undefined,
        selectedBranch: optionsBranches.length > 1 ? undefined : optionsBranches[0],
        cashierId: optionsCashier.length > 1 ? null : optionsCashier[0].value,
        selectedCashier: optionsCashier[0]
    });

    const [validationState, setValidationState] = useState(false);

    useEffect(() => {
        const kkm = new Kkm({
            url: state.url,
            user: state.login,
            password: state.password,
            numberDevice: null,
            cashierName: null,
            cashierINN: null
        });
        kkm.getList().then((selectList: OptionSelect[]) => {
            setKkmOptions(selectList)
            setState(prevState => ({
                ...prevState,
                selectedKkm: selectList.find(option => option.value == state.numberDevice)
            }));
        }).catch(reason => {
            setKkmOptions([])
            setState(prevState => ({
                ...prevState,
                selectedKkm: undefined
            }));
        })
    }, [state.login, state.password, state.url])

    useEffect(() => {
        if (kkmId) {
            setloadingPage(true);
            ServerCommand.get('kkm/detail', {id: kkmId}).then((response) => {
                setState(prevState => ({
                    ...prevState,
                    ...response,
                    selectedKkm: kkmOptions.find(option => option.value == response.numberDevice),
                    selectedBranch: optionsBranches.find(option => option.value == response.branchId),
                    selectedCashier: optionsCashier.find(option => option.value == response.cashierId)
                }));

                setloadingPage(false);
            });

        }
    }, []);

    const validate = () => {
        return !!state.name && !!state.numberDevice && !!state.login && !!state.branchId
    }

    const saveKkm = () => {
        setLoadingRequest(true);

        const data = {
            id: state.id,
            numberDevice: state.numberDevice,
            name: state.name,
            login: state.login,
            password: state.password,
            url: state.url,
            branchId: state.branchId,
            cashierId: state.cashierId
        } as RequestKkmEdit;
        ServerCommand.post(
            `kkm/${kkmId ? 'edit' : 'add'}`,
            data,
            () => {
                closeSlideModal();
                refreshDataFromServer();
            }).finally(() => {
            setLoadingRequest(false);
        });
    };

    return {
        loadingPage,
        kkmOptions,
        optionsBranches,
        optionsCashier,
        state,
        setState,
        validate,
        saveKkm,
        loadingRequest,
        validationState,
        setValidationState,
    };
};

export default useAddingEditingEmployee;
