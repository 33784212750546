import React from 'react'
import PropTypes from 'prop-types'
import Helper from "../../common/helpers/main";
import Group from 'common/helpers/group-helper';
import './Table.scss'
import TableRow from "./TableRow";
import {Icon} from '../Icons/Icons';
import Scrollbars from "../../components-ui/Scrollbars/scrollbars";
import Obj from "../../common/helpers/object";
import Tabs from "../../components-ui/Navigation/Tabs/Tabs";
import ManyCheckbox from "./Elements/ManyCheckbox";
import _ from "underscore";
import Tooltip from "../../components-ui/DataDisplay/Tooltip/Tooltip";

let nextTipId = 1;

export default class Table extends React.Component {
    static propTypes = {
        columns: PropTypes.array.isRequired,
        data: PropTypes.array.isRequired,
        selectMode: PropTypes.oneOf(['none', 'multiple', 'single', 'status', 'default']),
        selectModeForNested: PropTypes.oneOf(['none', 'multiple', 'single']),
        selectedRows: PropTypes.any,
        selectedNestedRows: PropTypes.any,
        groups: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        highlightedGroupsIds: PropTypes.array,
        categories: PropTypes.array,

        tabs: PropTypes.array,
        activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        onTabChange: PropTypes.func,
        onTabAdd: PropTypes.func,
        onTabDelete: PropTypes.func,

        checkColumnTitle: PropTypes.any,
        isEditable: PropTypes.bool,
        scrollHeight: PropTypes.number,
        scrollWidth: PropTypes.number,
        withHeader: PropTypes.bool,
        planItemsStatuses: PropTypes.array,
        total: PropTypes.object,
        focusedElement: PropTypes.object,
        sortBy: PropTypes.string,
        firstRefs: PropTypes.object,

        getRowGroupId: PropTypes.func,
        getRowCode: PropTypes.func,
        getNestedRowCode: PropTypes.func,
        getRowEnabled: PropTypes.func,
        getDisabledTip: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

        onSelect: PropTypes.func,
        onSelectNested: PropTypes.func,
        onSelectMany: PropTypes.func,
        onChange: PropTypes.func,
        onReorder: PropTypes.func,
        onChangeNested: PropTypes.func,
        onChangeSelectTable: PropTypes.func,

        onRowDelete: PropTypes.func,
        inputChangeNumber: PropTypes.func,
        modifyCellContent: PropTypes.func,
        onScrollEnd: PropTypes.func,
        prepareDataItem: PropTypes.func,
        prepareDataNestedItem: PropTypes.func,
        onFocus: PropTypes.func,

        counterMin: PropTypes.number,
        draggable: PropTypes.bool,
    };

    static defaultProps = {
        onSelect: () => null,
        onSelectNested: () => null,
        onChange: () => null,
        onChangeNested: () => null,
        onRowDelete: null,
        modifyCellContent: (columnCode, content) => content,
        getRowGroupId: item => item.groupId,
        checkColumnTitle: <Icon icon="new-check"/>,
        getRowCode: item => item.id,
        getNestedRowCode: nestedItem => nestedItem.id,
        selectedRows: [],
        selectedNestedRows: [],
        selectModeForNested: 'none',
        getRowEnabled: () => true,
        prepareDataItem: () => null,
        prepareDataNestedItem: () => null,
        withHeader: true,
    };

    state = {
        tableBodyHeight: 0,
        focusedElement: null,
        selectedTreeItems: {},
        mousePositionY: 0,
        currentDropCode: null,
        activeTab: null
    };

    tipId = 0;

    constructor(props) {
        super(props);
        this.tipId = String(nextTipId++);
    }

    componentDidMount() {
        //console.log(JSON.stringify(this.props.columns))
        // const newData = this.props.data.map(item => {
        //     delete item.doctors;
        //     delete item.phone;
        //     delete item.address;
        //     delete item.fullName;
        //     delete item.age;
        //     return item
        // })
        //console.log(JSON.stringify(newData))
        //console.log(JSON.stringify(this.props.groups))
        //console.log(JSON.stringify(this.props.tabs))
        if (this.props.scrollHeight) this.setState({
            tableBodyHeight: this.tableBody.clientHeight,
            activeTab: this.getActiveTab()
        });
        if (this.props.draggable) this.props.onReorder(this.getRowOrder());
    }

    render() {
        const {selectMode, tabs} = this.props;

        let columns = Helper.clone(this.props.columns),
            data = this.getData(),
            className = "table";

        if ((selectMode === 'multiple') && !this.useNestedRows()) {
            let unshiftColumns = {
                title: this.props.checkColumnTitle,
                code: 'check'
            }
            if (!!this.props.onSelectMany) {
                let newSelectedRows = _.clone(this.props.selectedRows);
                let newData = _.clone(this.props.data);
                if (this.props.tabs.length > 1) {
                    newSelectedRows = newSelectedRows.filter(row => +row.split('_')[2] === this.getActiveTab());
                    newData = newData.filter(item => item.performerId === this.getActiveTab())
                }

                unshiftColumns.title = <ManyCheckbox selectedRows={newSelectedRows}
                                                     onSelectMany={this.props.onSelectMany}
                                                     getRowCode={this.props.getRowCode}
                                                     data={newData}/>;
            }
            columns.unshift(unshiftColumns);
            data.forEach((row) => {
                row.cells.unshift({
                    code: 'check',
                    content: true
                })
            })
        }

        if (selectMode === 'none') {
            className += " table--disabled"
        }
        if (selectMode === 'status') {
            className += " table--statuses"
        }

        if (!this.props.withHeader) {
            className += " table--without-header"
        }

        if (!this.isScrolling()) {
            className += " table--noscroll"
        }

        let columnsObj = Helper.Array.toObject(columns, 'code');

        return (
            <>
                {tabs && tabs.length && (
                    <div className="table-tabs">
                        <Tabs
                            tabs={tabs}
                            activeTab={this.getActiveTab()}
                            onChange={this.handleTabClick}
                            onTabAdd={this.props.onTabAdd}
                            onTabDelete={this.props.onTabDelete}
                            onTabReplace={this.props.onTabReplace}
                        />
                    </div>
                )}
                <div className={className} ref={(ref) => this.tableBody = ref}>
                    {this.props.withHeader &&
                    <div className="table__row table__row--header">
                        {columns.map(cell => (
                            <div className={`table__cell table__cell--${cell.code.toLowerCase()} ${cell.className || ''}`} key={cell.code}>
                                {cell.title}
                            </div>
                        ))}
                    </div>
                    }
                    {this.renderTableBody(data, columnsObj, columns)}
                    {this.renderTotalRow(data, columnsObj)}
                </div>
            </>
        )
    }

    renderTableBody = (data, columnsObj, columns) => {
        let prevCategoryName = null;
        let prevGroupName = null;
        const isEditable = this.isEditable();
        let isTableNested = false;
        let hiddenTooltip = true;
        data.forEach(row => {
            if (row.nestedRows.length) isTableNested = true;
            if (!row.isEnabled) hiddenTooltip = false;
        });

        let tableBody = (
            <React.Fragment>
                {data.map((row, key) => {
                    let result = [];
                    row.isTableNested = isTableNested;

                    if (!!row.category && (prevCategoryName !== row.category.name)) {
                        result.push(
                            this.renderCategoryName(row.category, columns)
                        );
                    }

                    if (!!row.group && (prevGroupName !== row.group.name)) {
                        result.push(
                            this.renderGroupName(row.group, columns)
                        )
                    }

                    const argTableRow = {
                        row, columnsObj, key,
                        isEditable,
                        isNested: false
                    };

                    result.push(this.renderTableRow(argTableRow));

                    result = result.concat(
                        row.nestedRows.map(nestedRow => {
                            argTableRow.isNested = true;
                            argTableRow.row = nestedRow;
                            return this.renderTableRow(argTableRow)
                        })
                    );

                    prevCategoryName = !!row.category ? row.category.name : null;
                    prevGroupName = !!row.group ? row.group.name : null;
                    return <React.Fragment key={'table-row' + row.code}>
                        {result}
                    </React.Fragment>
                })}
                {!hiddenTooltip && (
                    <Tooltip place="top"
                             effect="float"
                             id={this.tipId}
                             key={this.props.data.map(row => row.code).join('_')}/>
                )}
            </React.Fragment>
        );

        if (this.isScrolling()) {
            tableBody = <Scrollbars style={{height: this.props.scrollHeight, width: this.props.scrollWidth}}
                                    onScrollEnd={this.props.onScrollEnd}
            >
                {tableBody}
            </Scrollbars>
        }


        return tableBody
    };

    renderTableRow(paramTableRow) {
        const {row, columnsObj, isEditable, isNested} = paramTableRow;
        const isSelected = this.getSelectedCodes(isNested).includes(row.code);
        const isActive = (this.getSelectMode(isNested) === 'multiple') && isSelected;

        /*--------- Отоброжать ли подпункты древовидной таблицы ----------*/

        const {selectedTreeItems} = this.state;
        if (isNested && !selectedTreeItems[row.parentRowCode]) return null;

        /*------ --------*/

        const key = 'row_' + (row.code === undefined ? row.parentRowCode : row.code) +
            '_' + isNested;

        let additionalRowProps = {};

        if (row.refs && row.refs.length) {
            additionalRowProps.ref = ref => this.setRowRefs(row.refs, ref);
        }

        return (
            <TableRow
                columns={columnsObj}
                row={row}
                key={key}
                onClick={this.handleClickRow}
                selectMode={isNested ? this.props.selectModeForNested : this.props.selectMode}
                onChange={isNested ? this.props.onChangeNested : this.props.onChange}
                onChangeSelectTable={this.props.onChangeSelectTable}
                isEditable={isEditable}
                isNested={isNested}
                isSelected={isSelected}
                isActive={isActive}
                isFocused={this.isFocusedRow(row.code, isNested)}
                inputChangeNumber={this.props.inputChangeNumber}
                planItemsStatuses={this.props.planItemsStatuses}
                counterMin={this.props.counterMin}
                tipId={this.tipId}
                onClickTree={this.handleClickTree}
                selectedTreeItems={this.state.selectedTreeItems}
                onDrag={this.handleDrag}
                onDrop={this.handleDrop}
                draggable={this.props.draggable}
                mousePositionY={this.state.mousePositionY}
                handlePositionY={this.handlePositionY}
                {...additionalRowProps}
            />
        );
    }

    renderTotalRow(data, columns) {
        const totalItems = this.getTotalItems(data, columns);
        if (!totalItems) return null;

        const columnsList = Object.values(columns);

        return (
            <div className="table__row table__row--total">

                {totalItems.map((columnTotalItems, index) => {
                    const column = columnsList[index];

                    return (
                        <div className={`table__cell table__cell--${column.code.toLowerCase()} ${column.code.className || ''}`} key={column.code}>
                            {columnTotalItems ? this.renderTotalColumn(columnTotalItems, column) : null}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderTotalColumn(totalItems, column) {
        return (
            <div className="table__total-column">
                <div className="table__total-labels">
                    {totalItems.map(totalItem => {
                        let labelClass = 'table__total-label';
                        if (totalItem.isHighlighted) {
                            labelClass += ' table__total-label--highlighted';
                        }

                        return (
                            <span key={totalItem.label + '_' + totalItem.value} className={labelClass}>
                                {totalItem.label}
                            </span>
                        );
                    })}
                </div>

                <div className="table__total-values">
                    {totalItems.map(totalItem => {
                        let valueClass = 'table__total-value';
                        if (totalItem.isHighlighted) {
                            valueClass += ' table__total-value--highlighted';
                        }

                        return (
                            <span key={totalItem.label + '_' + totalItem.value} className={valueClass}>
                                {(column.type === 'price') ? Helper.Price.format(totalItem.value) : totalItem.value}
                            </span>
                        )
                    })}
                </div>
            </div>
        );
    }

    renderCategoryName(category, columns) {
        return (
            <div className="table__row table__row--category" key={'category_' + category.code}>
                {columns.map(cell => (
                    <div className={`table__cell table__cell--${cell.code.toLowerCase()}`} key={cell.code}>
                        {cell.containsGroupTitle ? category.name : ''}
                    </div>
                ))}
            </div>
        )
    }

    renderGroupName(group, columns) {
        let groupClass = 'table__row table__row--group';
        if (group.isHighlighted) {
            groupClass += ' table__row--highlighted';
        }

        return (
            <div className={groupClass} key={'group_' + group.id}>
                {columns.map(cell => (
                    <div className={`table__cell table__cell--${cell.code.toLowerCase()}`} key={cell.code}>
                        {cell.containsGroupTitle ? group.name : ''}
                    </div>
                ))}
            </div>
        )
    }


    /**
     * Возвращает id корневой группы по id её дочерней группы
     * @param groupId
     * @param groups - расправленное дерево групп
     * @returns {*}
     */
    getRootParentGroup(groupId, groups) {
        let result = null;
        const group = groups[groupId];

        if (!!group) {
            if (group.groupId) {
                result = this.getRootParentGroup(group.groupId, groups);
            } else {
                result = group;
            }
        }

        return result;
    }


    getRawData = () => {
        return Array.isArray(this.props.data) ? this.props.data : Object.values(this.props.data);
    };

    getData() {
        const {groups} = this.props;
        const activeTab = this.getActiveTab();
        const expandedGroups = !!groups ? Group.expandTree(groups) : null;
        const categories = !!this.props.categories ? Helper.Array.toObject(this.props.categories, 'code') : null;

        let getDisabledTip = null;
        let rawData = this.getRawData();

        if (typeof this.props.getDisabledTip !== 'undefined') {
            getDisabledTip = (typeof this.props.getDisabledTip === 'function') ? this.props.getDisabledTip : () => this.props.getDisabledTip;
        }


        let rows = rawData.map(item => this.getRow(item, expandedGroups, categories, getDisabledTip));
        if (activeTab !== null) {
            rows = rows.filter(row => row.tab === activeTab);
        }

        this.sortRows(rows);
        this.defineRowsRefs(rows);

        return rows;
    }

    getRow = (item, groups, categories, getDisabledTip, parentItem = null, parentItemCode = null) => {
        const preparedItem = Helper.clone(item);
        const {highlightedGroupsIds} = this.props;
        const {selectedTreeItems} = this.state;

        item = Helper.clone(item);

        const prepareDataArgs = {
            item: preparedItem,
            parentItem,
            parentItemCode,
            isFocused: rowCode => this.isFocusedRow(rowCode, !!parentItem),
            handleClickTree: rowCode => this.handleClickTree(rowCode, true),
            openedItems: selectedTreeItems
        };

        if (!!parentItem) {
            this.props.prepareDataNestedItem(prepareDataArgs);
        } else {
            this.props.prepareDataItem(prepareDataArgs);
        }

        let rowCode;
        if ('rowCode' in preparedItem) {
            rowCode = preparedItem.rowCode;
        } else {
            rowCode = !!parentItem ? this.props.getNestedRowCode(item, parentItem) : this.props.getRowCode(item);
        }

        let disabledTip = preparedItem.disabledTip;
        if (!disabledTip && getDisabledTip) {
            disabledTip = getDisabledTip(item);
        }

        let row = {
            cells: this.props.columns.map(column => {
                let content = preparedItem[column.code];
                return {
                    content: this.props.modifyCellContent(column.code, content, preparedItem, this.state.selectedTreeItems),
                    code: column.code,
                    className: column.className
                }
            }),
            code: rowCode,
            isEnabled: ('isEnabled' in preparedItem) ? preparedItem.isEnabled : this.props.getRowEnabled(item),
            onDelete: this.props.onRowDelete ? this.props.onRowDelete : preparedItem.onDelete,
            isAdditional: !!preparedItem.isAdditional,
            refsGroups: preparedItem.refsGroups,
            isPale: !!preparedItem.isPale,
            isDeletionEnabled: ('isDeletionEnabled' in preparedItem) ? preparedItem.isDeletionEnabled : true,
            deletionTip: preparedItem.deletionTip,
            tab: preparedItem.tab,
            disabledTip,
        };

        /* --- информация о группах и категориях --- */

        if (groups !== null) {
            const groupId = this.props.getRowGroupId(preparedItem);
            let group = this.getRootParentGroup(groupId, groups);

            if (!group) {
                group = {
                    name: 'Без группы',
                    sort: -1
                };
            }

            row.group = {
                id: groupId,
                name: group.name,
                sort: group.sort ? group.sort : 0,
                isHighlighted: !!highlightedGroupsIds ? highlightedGroupsIds.includes(groupId) : false
            };
        }

        if (!!categories) {
            row.category = categories[preparedItem.categoryCode];
            if (!row.category) {
                row.category = {
                    code: '',
                    name: 'Без категории',
                    sort: -1
                };
            }
        }

        /* --------------- обработка вложенных элементов ---------------- */

        if (preparedItem.nestedItems) {
            row.nestedRows = preparedItem.nestedItems.map(nestedItem => {
                const nestedRow = this.getRow(nestedItem, groups, categories, getDisabledTip, item, row.code);
                nestedRow.parentRowCode = row.code;
                if (!row.isEnabled) {
                    nestedRow.isEnabled = false;
                }

                return nestedRow;
            });
        } else {
            row.nestedRows = [];
        }

        /* --- */

        return row;
    };

    handleClickRow = row => {
        const isNested = !!row.parentRowCode;
        const selectMode = this.getSelectMode(isNested);

        /* подготовка данных для обработчика выбора строки */
        if (selectMode !== 'none' && row.isEnabled) {
            const selectHandler = isNested ? this.props.onSelectNested : this.props.onSelect;
            let selectedCodes = Helper.clone(this.getSelectedCodes(isNested));
            const selectedCodeIndex = selectedCodes.indexOf(row.code);

            if (selectedCodeIndex > -1) {
                selectedCodes.splice(selectedCodeIndex, 1);
            } else {
                if (selectMode === 'multiple') {
                    selectedCodes.push(row.code)
                } else {
                    selectedCodes = [row.code]
                }
            }

            selectHandler(selectedCodes, row.code, row.parentRowCode);
        }

        /* фокусировка */

        const useInternalFocus = typeof this.props.onFocus === 'undefined';

        if (row.isEnabled && (!useInternalFocus || (this.isEditable() && (selectMode !== 'multiple')))) {
            const focusedElement = {
                code: row.code,
                isNested
            };

            if (useInternalFocus) {
                this.setState({focusedElement});
            } else {
                this.props.onFocus(focusedElement);
            }
        }
    };

    /**
     * Клик по стрелке в древовидной таблице
     * @param rowCode {string, number} - код строки
     * @param fromOutside {boolean} - для проверки клика из вне*/
    handleClickTree = (rowCode, fromOutside = false) => {
        let selectedTreeItems = Helper.clone(this.state.selectedTreeItems);
        if (selectedTreeItems[rowCode] === undefined) {
            selectedTreeItems[rowCode] = true;
        } else {
            selectedTreeItems[rowCode] = !selectedTreeItems[rowCode];
        }
        if (fromOutside) {
            selectedTreeItems[rowCode] = true;
        }
        this.setState({selectedTreeItems});
    };

    isEditable() {
        return this.props.isEditable || this.props.columns.some(column => column.editable);
    }

    isScrolling() {
        return !!this.props.scrollHeight && !!this.tableBody && this.tableBody.clientHeight > this.props.scrollHeight
    }

    /**
     * Определяет, используются ли в таблице вложенность строк
     * @return {*}
     */
    useNestedRows() {
        return this.getRawData().some(item => 'nestedItems' in item)
    }

    getSelectedCodes(isNested) {
        return isNested ? this.props.selectedNestedRows : this.props.selectedRows
    };

    getSelectMode(isNested) {
        return isNested ? this.props.selectModeForNested : this.props.selectMode;
    }

    isFocusedRow = (rowCode, isNested) => {
        return Helper.isEqual(this.getFocusedElement(), {code: rowCode, isNested})
    };

    getFocusedElement = () => {
        const focusedElement = this.props.onFocus ? this.props.focusedElement : this.state.focusedElement;
        if (!!focusedElement && !('isNested' in focusedElement)) {
            focusedElement.isNested = false;
        }

        return focusedElement;
    };

    sortRows(rows) {
        const {sortBy, groups, categories} = this.props;
        const useGroups = !!groups;
        const useCategories = !!categories && (categories.length > 0);

        const compareSections = (sectionA, sectionB) => {
            if (sectionA.sort - sectionB.sort) {
                return sectionA.sort - sectionB.sort;
            } else {
                return sectionA.name.localeCompare(sectionB.name);
            }
        };

        rows.sort((rowA, rowB) => {

            let compareResult;

            if (useCategories) {
                compareResult = compareSections(rowA.category, rowB.category);
                if (compareResult) {
                    return compareResult;
                }
            }

            if (useGroups) {
                if (rowA.group.isHighlighted - rowB.group.isHighlighted) {
                    return rowA.group.isHighlighted - rowB.group.isHighlighted;
                }

                compareResult = compareSections(rowA.group, rowB.group);
                if (compareResult) {
                    return compareResult;
                }
            }

            if (sortBy) {
                return rowA[sortBy] - rowB[sortBy];
            }

            return 0;
        });
    }

    /**
     * Распределяет массивы рефов по строкам таблицы (для передачи в родительский компонент рефа первой строки для каждой группы рефов)
     */
    defineRowsRefs(rows) {
        const firstRefs = Helper.clone(this.props.firstRefs);
        if (!firstRefs) return;

        rows.forEach(row => {
            if (!row.refsGroups) return;

            row.refsGroups.forEach(refsGroup => {
                // назначаем строке реф, только если она идёт первой в своей группе рефов
                // это используется для скролла к первой строке таблицы, удовлетворяющей определённому условию
                if (!!firstRefs[refsGroup]) {
                    if (!row.refs) {
                        row.refs = [];
                    }

                    row.refs.push(firstRefs[refsGroup]);
                    delete firstRefs[refsGroup];
                }
            });
        });

        // в нераспределённые рефы отправяем null
        Obj.forEach(firstRefs, firstRef => firstRef(null));
    }

    handlePositionY = (mousePositionY) => this.setState({mousePositionY});

    getTotalItems(data, columns) {
        let foundCountTotalColumn = false;
        const {total} = this.props;

        /* --- проверка, требуется ли выводить итоговые значения --- */

        Helper.forEachObj(columns, column => {
            if (column.countTotal) {
                foundCountTotalColumn = true
            }
        });

        if (!total && !foundCountTotalColumn) {
            return null
        }

        /* --- подсчёт суммы по столбцам --- */

        let columnsSum = {};

        if (foundCountTotalColumn) {
            data.forEach(row => {
                row.cells.forEach(cell => {

                    const column = columns[cell.code];
                    if (column.countTotal && column.countTotal !== 'last') {
                        if (!columnsSum[cell.code]) {
                            columnsSum[cell.code] = 0
                        }
                        columnsSum[cell.code] += Number(cell.content)
                    }
                    if (column.countTotal === 'last') {
                        columnsSum[cell.code] = cell.content
                    }
                })
            });
        }

        /* --- подготовка массивов названий и значений строк вывода итоговых значений --- */

        return Helper.mapObj(columns, column => {

            let columnTotalItems = null;

            if (!!total && (column.code in total)) {

                let columnTotalData = total[column.code];

                if (typeof columnTotalData === 'object') {
                    if (Array.isArray(columnTotalData)) {
                        columnTotalItems = columnTotalData;
                    } else {
                        columnTotalItems = [columnTotalData];
                    }
                } else {
                    columnTotalItems = [{
                        label: '',
                        value: columnTotalData,
                        isHighlighted: true,
                    }];
                }
            } else if (column.countTotal) {
                columnTotalItems = [{
                    label: '',
                    value: columnsSum[column.code],
                    isHighlighted: true
                }];
            }

            return columnTotalItems;
        });
    }

    getRowOrder = () => {
        return Helper.clone(this.props.data).map((row, index) => {
            return {
                code: this.props.getRowCode(row),
                order: index
            }
        });
    };

    getActiveTab() {
        const {tabs} = this.props;
        let activeTab = (typeof this.props.activeTab !== 'undefined') ? this.props.activeTab : this.state.activeTab;

        if (tabs && tabs.length) {
            activeTab = (activeTab === null) ? tabs[0].code : activeTab;
        } else {
            activeTab = null;
        }

        return activeTab;
    }

    setRowRefs = (rowRefs, refValue) => rowRefs.forEach(rowRef => rowRef(refValue));

    handleDrag = (rowCode) => {
        const {currentDropCode} = this.state;
        const currentDragCode = +rowCode.split('_')[1];
        let rows = this.getRowOrder();

        if (currentDropCode !== null) {
            if (currentDragCode !== currentDropCode) {

                rows.forEach((row, index) => {
                    if (currentDragCode > currentDropCode) { // dnd вверх

                        if (index === currentDragCode) row.order = currentDropCode;
                        if (index >= currentDropCode && index < currentDragCode) row.order += 1;

                    } else { // dnd вниз

                        if (index > currentDragCode && index <= currentDropCode) row.order -= 1;
                        if (index === currentDragCode) row.order = currentDropCode;

                    }

                });
                rows = rows.sort((a, b) => a.order - b.order);
                this.props.onReorder(rows);
            }
        }
        this.setState({currentDropCode: null});
    };

    handleDrop = rowCode => this.setState({currentDropCode: +rowCode.split('_')[1]});

    handleTabClick = activeTab => {
        const {onTabChange} = this.props;
        if (onTabChange) {
            onTabChange(activeTab);
        } else {
            this.setState({activeTab});
        }
    }
}
