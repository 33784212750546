import React, {forwardRef, ReactChild, ReactNode} from 'react';
import { SWrapAlert } from './style';
import Icon from '../Icon';
import {NamesSvg} from '../Icon/types/common';

export interface Props {
    children?: ReactChild | ReactNode | any
    iconName?: NamesSvg
    margin?: string
    padding?: string
    width?: string
    borderRadius?: string
    fontSize?: string
}

export const Alert = forwardRef(({iconName = 'instruction', children, ...props}: Props, ref) => {
    return (
        <SWrapAlert {...props} ref={ref}>
            <Icon name={iconName} width="20px" height="20px" margin="0 12px 0 0" />
            {children}
        </SWrapAlert>
    );
});
