import React from 'react';
import Typography from '../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../components-ui/Layout/Box';
import XrayRateFilter from './components/XrayRateFilter';
import useXrayRate from './hooks/useXrayRate';
import XrayRateTable from './components/XrayRateTable';
import LoaderPage from '../../components/LoaderPage';

const XrayRatePage = () => {

    const {
        optionsBranches,
        selectedBranch,
        setSelectedBranch,
        loaderPage,
        loaderTable,
        xrayRateResponse,
        refreshDataFromServer
    } = useXrayRate()

    return (
        <Box padding="62px 20px 80px 28px">
            <Typography variant="title" fontWeight="bold">Ставки за снимки</Typography>
            <XrayRateFilter selectedBranch={selectedBranch}
                            setSelectedBranch={setSelectedBranch}
                            optionsBranches={optionsBranches}/>
            {!loaderPage
                ?
                <XrayRateTable selectedBranch={selectedBranch.value}
                               refreshDataFromServer={refreshDataFromServer}
                               xrayRateResponse={xrayRateResponse}
                               loader={loaderTable}/>
                :
                <LoaderPage height={338}/>}
        </Box>
    );
};

export default XrayRatePage;
