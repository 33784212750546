import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import Table from "../../../../../components-ui/DataDisplay/Table";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import {rowsOne, rowsTwo, rowsThree, newTable} from "./helper/rows";
import {tabs, tabsTwo} from "./helper/tabs";
import Tabs from "../../../../../components-ui/Navigation/Tabs/Tabs";
import Box from "../../../../../components-ui/Layout/Box";
import {groups} from "./helper/groups";
import useColumns from "./helper/useColumns";
import {useDialogsManager} from "../../../../../containers/DialogsManager/DialogsManager";
import PatientCard from "../../../../../content/PatientCard/PatientCard";
import TreatmentPlan from "../../../../../containers/TreatmentPlan/TreatmentPlan";

export default function TablePage() {
    const [activeTab, setActiveTab] = useState(tabs[0].code)
    const [activeTab2, setActiveTab2] = useState(tabsTwo[0].code)
    const {columnsTwo, columnsOne, columnsThree, columnsNew} = useColumns();
    const {showSlideModal} = useDialogsManager()
    return (
        <>
            <h1>Table</h1>
            <WrapContent>
                <Table columns={columnsNew}
                       rows={newTable}
                       height="400px"
                       isHovering={false}
                       fontSizeRow="14px"
                       variantTable="outline"
                       />
                <br/>
                <br/>
                <Table key={activeTab}
                       columns={columnsThree}
                       rows={rowsThree.filter(row => +row.performerId === +activeTab)}
                       groups={groups}
                       fieldNameGroupId="serviceGroupId"
                       fieldNameNested="procedures"
                       total={[{
                           fieldName: 'sum',
                           labelWidth: '200px',
                           totals: TreatmentPlan.getDoctorTotal(rowsTwo, activeTab, false).sum
                       }]}
                       header={<Box padding="0 20px">
                           <Tabs tabs={tabsTwo} activeTab={activeTab2} onChange={setActiveTab2} noLeftMargin={true}/>
                       </Box>}/>
                <br/>
                <br/>
                <Table key={activeTab}
                       columns={columnsTwo}
                       rows={rowsTwo.filter(row => +row.performerId === +activeTab)}
                       groups={groups}
                       fieldNameGroupId="serviceGroupId"
                       fieldNameNested="procedures"
                       total={[{
                           fieldName: 'sum',
                           labelWidth: '80px',
                           totals: TreatmentPlan.getDoctorTotal(rowsTwo, activeTab, true).sum
                       }]}
                       header={<Box padding="0 20px">
                           <Tabs tabs={tabs} activeTab={activeTab} onChange={setActiveTab} noLeftMargin={true}/>
                       </Box>}/>
                <br/>
                <br/>
                <Table columns={columnsOne}
                       rows={rowsOne}
                       height="566px"
                       onClickRow={(row) => showSlideModal(<PatientCard patientId={row.id} />)}
                       header={<Typography
                           children="header={<Typography children='...' padding='30px 20px 40px' variant='min-title' />}"
                           padding="30px 20px 40px" as="h3"/>}/>
                <br/>
                <br/>
            </WrapContent>
        </>
    )
}
