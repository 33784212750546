import React from 'react';
import {VisitsReport} from "../../../types";
import useData from "./hooks/useData";
import NoReport from "../../../components/NoReport";
import ContentWidgetWrap from '../../../components/ContentWidgetWrap';
import DoubleBar from "../../../../../components-ui/Charts/DoubleBar";
import Helper from "../../../../../common/helpers/main";
import Legend from "./components/Legend";
import useFormatXDate from "../../../hooks/useFormatXDate";

interface Props {
    report: VisitsReport
    startDate: string
    endDate: string
}

const Visits = ({report, startDate, endDate}: Props) => {
    const data = useData({report, startDate, endDate})
    const formatXDate = useFormatXDate({startDate})
    if (!!data.length) {
        return (
            <ContentWidgetWrap>
                <Legend/>
                <DoubleBar data={data} width="100%" height={245}
                           formatterValue={value => Helper.String.numberFormat(value, 0)}
                           formatterCode={formatXDate}
                           formatterTitleTooltip={code => Helper.Date.formatDate(code, 'LL')}
                           formatterNameTooltip={code => code === 'barOne' ? 'Записались' : 'Пришли'}/>
            </ContentWidgetWrap>
        )
    } else {
        return <NoReport/>
    }
};

export default Visits;
