import {useMemo, useState} from "react";
import {ScriptGroupList} from "../../../types/common";
import {InfoCallCenter} from "../../../types/hooks";
import {useSelector} from "react-redux";

interface Props {
    infoCallCenter: InfoCallCenter
    isMarketing: boolean
}

const useSearchScript = ({infoCallCenter, isMarketing}: Props) => {
    const user = useSelector(state => state.user);
    const marketingAdmin = user.role === 'marketing-admin';

    const [searchScript, setSearchScript] = useState('');

    const scripts = useMemo(() => {
        return infoCallCenter.scripts.sort((scriptA, scriptB) => {
            const priorityA = !!scriptA.priority ? scriptA.priority : 0;
            const priorityB = !!scriptB.priority ? scriptB.priority : 0;
            return priorityB - priorityA;
        })
    }, [infoCallCenter.scripts])

    const searchScripts = useMemo(() => {
        return scripts.filter(script => {
            let res = script.name.toLowerCase().indexOf(searchScript.toLowerCase()) !== -1
            if (!marketingAdmin && !isMarketing) {
                res = res && !script.marketing
            }
            return res
        })
    }, [searchScript, JSON.stringify(scripts), isMarketing])

    const groupsScript = useMemo(() => {
        let noMarketing = [] as ScriptGroupList[];
        let marketing = [] as ScriptGroupList[];
        if (!!infoCallCenter.scriptsGroups.length) {
            const groupScript = infoCallCenter.scriptsGroups.map((scriptGroup) => {
                return {
                    ...scriptGroup,
                    scriptList: searchScripts.filter(script => script.groupId === scriptGroup.id)
                }
            })
            noMarketing = groupScript.filter(group => group.scriptList.some(script => !script.marketing))
            marketing = groupScript.filter(group => !group.scriptList.some(script => !script.marketing))
        }
        return {
            noMarketing,
            marketing
        }
    }, [JSON.stringify(searchScripts), JSON.stringify(infoCallCenter.scriptsGroups)])

    return {
        searchScript,
        setSearchScript,
        searchScripts,
        groupsScript
    }
}

export default useSearchScript
