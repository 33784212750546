import {useEffect} from "react";
import {echo} from "../../../common/server/echo";
import {resetDataLoaded} from "../../../redux/actions/commonActions";
import ServerCommand from "../../../common/server/server-command";
import {useDispatch, useSelector} from "react-redux";

const useEcho = () => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        echo.private('user.' + user.id).listen('Logout', () => {
            dispatch(resetDataLoaded());
            ServerCommand.logout();
            window.location.reload();
        })
        return () => {
            echo.leaveChannel('doctor-visits.' + user.id);
            echo.leaveChannel('user.' + user.id);
        }
    }, [dispatch, user.id, user.role])
}

export default useEcho
