import {useMemo} from "react";
import {CallsReport} from "../../../../types";
import _ from 'lodash'
import Helper from "../../../../../../common/helpers/main";

interface Props {
    report: CallsReport
    startDate: string
    endDate: string
}

const useData = ({report, startDate, endDate}: Props) => {
    return useMemo(() => {
        let res = {
            incoming:{},
            outgoing: {}
        } as {[key: string]: {[key: string]: number}}

        _.forEach(report, (values, code) => {
            _.forEach(values, (value, name) => {
                if (res[name] === undefined) res[name] = {}
                res[name][code] = (value as number)
            })
        })

        return _.map(res, (values, name) => {
            return {
                name,
                data: Helper.Date.getDaysArray(startDate, endDate).map(code => {
                    let res = {code} as any
                    if (!!values[code]) {
                        res.value = values[code]
                    } else {
                        res.value = 0
                    }
                    return res
                })
            }
        });
    }, [JSON.stringify(report), startDate, endDate])
}

export default useData
