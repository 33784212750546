import React from 'react';
import {Switcher, SwitcherInput, SwitcherText} from "./styledSwitch";

interface ISwitch {
    checked: boolean,
    onChange: (checked: boolean) => void,
    children?: any,
    isDisabled?: boolean,
    labelPosition?: 'left' | 'right',
    fontSize?: string
}

export default function Switch(
    {
        checked,
        onChange,
        children,
        isDisabled = false,
        labelPosition = 'right',
        fontSize
    }: ISwitch) {
    return (
        <Switcher isDisabled={isDisabled} labelPosition={labelPosition}>
            <SwitcherInput
                checked={checked}
                onChange={() => {
                    if (!isDisabled) {
                        onChange(!checked)
                    }
                }}
            />
            <SwitcherText active={checked}
                          isDisabled={isDisabled}
                          labelPosition={labelPosition}
                          fontSize={fontSize}
            >
                {children}
            </SwitcherText>
        </Switcher>
    )
}
