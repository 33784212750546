import React, {useState} from 'react';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import {LaboratoryService} from '../../../../LaboratoryPricelistPage/types';
import {GetActualRatesResult} from '../../../types';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {OptionSelect} from '../../../../../components-ui/Inputs/Select/typesSelect';
import {notTrashed} from '../../../../../common/helpers/general-helper';
import Box from '../../../../../components-ui/Layout/Box';
import Icon from '../../../../../components-ui/DataDisplay/Icon';
import Tooltip from '../../../../../components-ui/DataDisplay/Tooltip/Tooltip';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import DateHelper from '../../../../../common/helpers/date-helper';
import {EmployeeRates} from '../../../../XrayRatePage/types';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import LaboratoryRatesMark from '../modals/LaboratoryRatesMark';
import ButtonForOptions from '../../../../../components-ui/Inputs/ButtonForOptions/ButtonForOptions';
import {
    DEFAULT_PADDING_CELLS,
    DEFAULT_PADDING_HEADER_CELLS,
} from '../../../../../components-ui/DataDisplay/Table/common';
import TableScrollHeaderComponent
    from '../../../../Salaries/components/SalariesTable/components/TableScrollHeaderComponent';
import {
    LeftShadowSide,
    SInnerTable,
} from '../../../../../components-ui/DataDisplay/Table/style/scrollStyledComponents';

interface Props {
    rates: GetActualRatesResult['rates'];
    selectedBranch: OptionSelect;
    refreshDataFromServer: () => void;
}

const useTableFormatter = ({rates, selectedBranch, refreshDataFromServer}: Props) => {

    const users = useSelector(state => state.common.users);

    const usersTechnicians = _.chain(users)
        .filter((user) => user.role === 'dental-technician' &&
            user.branches.includes(selectedBranch.value) &&
            notTrashed(user),
        )
        .sortBy('fio')
        .value();

    const {showModal} = useDialogsManager();

    const scrollClassName = `tableTechnicianScrollCmponent`;

    const handleScroll = (scrollPosition: number) => {
        document.querySelectorAll(`.${scrollClassName}`).forEach((item) => {
            item.scrollLeft = scrollPosition;
        });
    };

    const [scrollState, setScrollState] = useState<boolean>(false);

    const technicansColums: IColumn<LaboratoryService>[] = _.map(usersTechnicians, (user) => ({
        field: user.fio,
        headerName: user.fio,
        width: '230px',
        valueFormatter: ({row}) => {
            const standardRates = rates.standard?.[row.id];
            let employeeRates = rates[+user.id]?.[row.id] as EmployeeRates | undefined;

            if (!employeeRates) {
                employeeRates = standardRates;
            }

            const standardActualRate = standardRates?.actual?.sum ?? 0;
            const employeeActualRate = employeeRates?.actual?.sum ?? standardActualRate;

            return <Box display='flex' alignItems='center' gap='5px' position='relative'>
                <Box position='absolute' positionOffsets={{left: '-30px'}}>
                    <ButtonForOptions
                        options={[{
                            value: 'change',
                            label: 'Изменить',
                            onClick: () => showModal(
                                <LaboratoryRatesMark rate={String(employeeActualRate)}
                                                     employee={user}
                                                     service={row}
                                                     branchId={selectedBranch.value}
                                                     refreshDataFromServer={refreshDataFromServer}
                                />,
                            ),
                        }]}
                        position='left-bottom'
                        outsideOpenOptions={scrollState}
                        setOutsideOpenOptions={setScrollState}
                    />
                </Box>
                {(employeeActualRate !== standardActualRate) ?
                    employeeActualRate > standardActualRate ?
                        <>
                            <Icon width='14px'
                                  height='14px'
                                  name='longArrowThin'
                                  color='inc'
                                  data-for={String(row.id)}
                                  data-tip={'Ставка больше стандартной'}
                            />
                            <Tooltip id={String(row.id)} effect='solid' />
                        </>
                        :
                        <>
                            <Icon width='14px'
                                  height='14px'
                                  name='longArrowThin'
                                  color='dec'
                                  transform='scale(-1)'
                                  data-for={String(row.id)}
                                  data-tip={'Ставка меньше стандартной'}
                            />
                            <Tooltip id={String(row.id)} effect='solid' />
                        </>
                    : ''
                }
                <Typography variant='middle'>{employeeActualRate}</Typography>
                {employeeRates?.future && employeeRates?.future !== standardRates?.future &&
                    <Typography variant='min' fontStyle='italic'>
                        {
                            `(${employeeRates.future?.sum} c
                                        ${DateHelper.formatDate(
                                employeeRates.future?.activeFrom,
                                'DD MMM YYYY').slice(2)})`
                        }
                    </Typography>
                }
            </Box>;
        },
    }));


    const columns: IColumn<LaboratoryService>[] = [
        {
            field: 'name',
            headerName: 'Услуга',
            width: '460px',
            paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
            paddingCell: DEFAULT_PADDING_CELLS,
            valueFormatter: ({childrenCell}) => <Typography variant='middle'>{childrenCell}</Typography>,
        },
        {
            field: 'standartRate',
            headerName: 'Стандартная ставка',
            width: '230px',
            paddingHeaderCell: '12px 5px 11px 30px',
            paddingCell: '12px 5px 11px 30px',
            valueFormatter: ({row}) => {
                const standardRates = rates.standard?.[row.id];
                const standardActualRate = standardRates?.actual?.sum ?? 0;

                return <Box display='flex' alignItems='center' gap='5px' position='relative'>
                    <Box position='absolute' positionOffsets={{left: '-30px'}}>
                        <ButtonForOptions
                            options={[{
                                value: 'change',
                                label: 'Изменить',
                                onClick: () => showModal(
                                    <LaboratoryRatesMark rate={String(standardActualRate)}
                                                         employee={null}
                                                         service={row}
                                                         branchId={selectedBranch.value}
                                                         refreshDataFromServer={refreshDataFromServer}
                                    />,
                                ),
                            }]}
                            position='left-bottom'
                            outsideOpenOptions={scrollState}
                            setOutsideOpenOptions={setScrollState}
                        />
                    </Box>
                    <Typography variant='middle'>{standardActualRate}</Typography>
                    {standardRates?.future &&
                        <Typography variant='min' fontStyle='italic'>
                            {
                                `(${standardRates.future?.sum} c
                                        ${DateHelper.formatDate(
                                    standardRates.future?.activeFrom,
                                    'DD MMM YYYY').slice(2)})`
                            }
                        </Typography>
                    }
                </Box>;
            },
        },
        {
            field: 'technicans',
            paddingHeaderCell: '',
            paddingCell: '',
            width: '40px',
            customStyles: {flexGrow: 1},
            headerFormatter: () => <Box margin='0 0 0 30px' width="100%">
                <TableScrollHeaderComponent
                    handleScroll={handleScroll}
                    scrollClassName={scrollClassName}
                    textVariant='small'
                    columns={technicansColums.map(col =>
                        ({name: col.headerName, width: String(col.width)}))}
                />
            </Box>,
            valueFormatter: ({row, childrenCell}) =>
                <SInnerTable className={scrollClassName}
                             onScroll={(element) => handleScroll(element.currentTarget.scrollLeft)}>
                    <LeftShadowSide />
                    <Box margin='0 0 0 30px' display='flex' width='max-content'>
                        {technicansColums.map((col, index, cols) => col.valueFormatter &&
                            <Box width={index !== cols.length - 1 ? String(col.width) : "200px"} padding={DEFAULT_PADDING_CELLS}>
                                {col.valueFormatter({row, childrenCell})}
                            </Box>,
                        )}
                    </Box>
                </SInnerTable>,
        },
    ];


    return {columns, setScrollState};
};

export default useTableFormatter;
