import React, {FC} from 'react';
import { STaskButtons } from '../../../../containers/Tasks/style';
import {Divider} from "../../../../components-ui/DataDisplay/Divider";

export const ButtonsWrap: FC = ({children}) => {
    return (
        <>
            <Divider/>
            <STaskButtons padding="11px 20px 0">
                {children}
            </STaskButtons>
        </>
    );
};
