import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";

export const patientsColumns = [
    {
        title: 'Номер карты',
        code: 'number'
    },
    {
        title: 'Врачи',
        code: 'doctors'
    },
    {
        title: 'Пациент',
        code: 'fullName',
    },
    {
        title: 'Телефон',
        code: 'phone',
    },
    {
        title: 'Адрес',
        code: 'address',
    },
    {
        title: 'Возраст',
        code: 'age',
    },
];

export const searchable = ['lastName', 'name', 'secondName', 'number', 'personalPhone', 'phone']; // порядок не менять

export const pageSize = 50;

export const allDoctors: OptionSelect = {
    value: 0,
    label: 'Все'
}

export const allAdmins: OptionSelect = {
    value: 0,
    label: 'Все администраторы'
}
