import _ from "lodash";
import Helper from "../../../../../common/helpers/main";
import {sortName} from "../../../../../common/helpers/graph-helper";
import {ResponseAnalyticsDoctorsRecommendations} from "../../../types";
import {useSelector} from "react-redux";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {useMemo} from "react";

interface Props {
    data: ResponseAnalyticsDoctorsRecommendations
    selectedDoctor: OptionSelect
}

export const useTables = ({data, selectedDoctor}: Props) => {
    const doctors = useSelector(state => state.common.doctors)
    const {recommendations, patients} = data;

    return useMemo(() => {
        let tables = _.map(recommendations, (values, doctorId) => {
            const tableData = values.map((value: any) => {
                const findRecommendedDoctor = doctors.find(doctor => +doctor.id === +value.recommendedDoctorId)
                return {
                    patient: Helper.getFio(patients[value.patientId]),
                    recommendationDoctor: findRecommendedDoctor?.fio,
                    remainingSum: value.remainingSum,
                    paidSum: value.paidSum,
                    patientId: value.patientId
                }
            }).sort((a, b) => sortName(a.patient, b.patient));

            const findDoctor = doctors.find(doctor => +doctor.id === +doctorId)

            return {
                doctorId,
                fio: findDoctor?.fio,
                tableData
            }
        });
        if (selectedDoctor.value !== 0) tables = tables.filter((item) => +item.doctorId === +selectedDoctor.value);
        tables = tables.sort((a, b) => sortName(a.fio, b.fio));

        return tables
    }, [recommendations, selectedDoctor.value, doctors, patients])
}
