import Helper from "../helpers/main";
import {
    Service,
    ServiceGroup,
    ServiceGroupsMap,
    Services,
    TurnkeyPriceParts,
    Price,
    PriceType
} from "../../containers/TreatmentPlansConstructor/types";
import {GroupsCategoriesMap, Procedure, Rules, ServicesQuantity} from "./interfaces";
import _ from 'underscore'
import {ServiceDirection} from "../types/ServicesDirections";
import RelationsResolver from "./RelationsResolver";
import Group from "../helpers/group-helper";

/**
 * Выполняет подготовку массива услуг, полученных с сервера - вычисляет цены и направления, дополняет поля процедур, привязанных к услугам
 * @param services
 * @param servicesGroups
 * @param procedures
 * @param directions
 * @param relationsRules
 */
export function prepareServicesFromServer(services: Service[], servicesGroups: ServiceGroup[], procedures: Procedure[], directions: ServiceDirection[], relationsRules: Rules, priceType: PriceType[]): Service[] {
    const proceduresMap = _.indexBy(procedures, 'id');
    const servicesMap = _.indexBy(services, 'id');
    const groupsMap = Group.expandTree(servicesGroups);

    const defaultDirectionCode = getDefaultDirectionCode(directions);

    /* --- копирование информации о процедурах в услуги --- */

    services.forEach(service => {
        service.procedures.forEach(serviceProcedure => {
            const procedure = proceduresMap[serviceProcedure.procedureId];
            _.assign(serviceProcedure, _.pick(procedure, 'name', 'code', 'price', 'prices', 'measure'));
        });

        service.price = getServicePrice(service);
        service.directions = getDirections(service, groupsMap, defaultDirectionCode);

        service.prices = priceType.map((priceType) : Price => {
            const procedures = service.procedures.filter(procedure => procedure.defaultActive);

            return {
                priceTypeId: priceType.id,
                price: procedures.reduce((servicePrice, procedure) => servicePrice + (procedure.prices.find(price => price.priceTypeId === priceType.id)?.price ?? 0) * procedure.quantity, 0)
            };
        })
    });

    /* --- вычисление цены под ключ --- */

    const relationsResolver = new RelationsResolver(relationsRules, servicesMap);
    const commonRequiredServices = relationsResolver.getCommonRequiredServices();

    services.forEach(service => {
        if (!service.archive && !service.isAdditional) {
            addTurnkeyPriceData(service, relationsResolver, servicesMap, commonRequiredServices);
        }
    });

    return services;
}

/**
 * Выполняет подготовку дерева групп услуг - определяет направления групп (подставляет код направления по умолчанию)
 * @param servicesGroups
 * @param directions
 */
export function prepareServicesGroupsFromServer(servicesGroups: ServiceGroup[], directions: ServiceDirection[]): ServiceGroup[] {
    const defaultDirectionCode = getDefaultDirectionCode(directions);
    if (!defaultDirectionCode) {
        throw new Error('Default direction code is not defined');
    }

    Group.forEach(servicesGroups, (group: ServiceGroup) => {
        if (!group.directions || group.directions.length === 0) {
            group.directions = [defaultDirectionCode];
        }
    });

    return servicesGroups;
}

/**
 * Составляет карту категорий (групп первого уровня) для групп услуг
 * @param groups
 * @return объект, в котором ключи - id групп, а значения - id соответствующих категорий
 */
export function getGroupsCategoriesMap(groups: ServiceGroup[]) {
    const groupsCategoriesMap: GroupsCategoriesMap = {};

    groups.forEach(category => {
        getNestedGroupsIds(category).forEach(nestedGroupId => {
            groupsCategoriesMap[nestedGroupId] = category.id;
        })
    });

    return groupsCategoriesMap;
}

/**
 * Получает направления для переданной услуги
 * @param service - объект услуги
 * @param groups - список групп с id в качестве ключей
 * @param defaultDirection - код направления по умолчанию
 */
function getDirections(service: Service, groups: ServiceGroupsMap, defaultDirection: string | null): string[] {
    let directions;

    if (service.directions) {
        directions = service.directions;
    } else if (service.groupId) {
        directions = groups[service.groupId].directions;
    }

    directions = Helper.clone(directions);

    if (!directions) {
        directions = [defaultDirection];
    }

    return directions;
}

function getDefaultDirectionCode(directions: ServiceDirection[]): string|null {
    const defaultDirection = directions.find(direction => direction.default);
    return !!defaultDirection ? defaultDirection.code : null;
}

function getServicePrice(service: Service): number {
    const procedures = service.procedures.filter(procedure => procedure.defaultActive);
    return procedures.reduce((servicePrice, procedure) => servicePrice + procedure.price * procedure.quantity, 0);
}

function addTurnkeyPriceData(service: Service, relationsResolver: RelationsResolver, servicesMap: Services, commonRequiredServices: ServicesQuantity): void {
    service.turnkeyPriceParts = _.chain({})
        .extend(commonRequiredServices, relationsResolver.getRelatedServices(service.id))
        .pick((quantity, relatedServiceId) => servicesMap[relatedServiceId].price > 0)
        .value() as TurnkeyPriceParts;


    service.turnkeyPrice = service.price + _.reduce(service.turnkeyPriceParts, (sum, quantity, relatedServiceId) => {
        return sum + servicesMap[relatedServiceId].price * quantity;
    }, 0);
}

function getNestedGroupsIds(group: ServiceGroup): number[] {
    const nestedGroupsIds: number[] = [];

    if (group.groups) {
        group.groups.forEach(group => {
            nestedGroupsIds.push(group.id, ...getNestedGroupsIds(group));
        });
    }

    return nestedGroupsIds;
}
