import {useSlideModal} from '../../../../../../../containers/DialogsManager/containers/SlideModal/SlideModal';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {ResponseUserDetail, setFnType} from '../types';
import ServerCommand from '../../../../../../../common/server/server-command';
import useValidate from './useValidate';
import {useOptionsSelectHandsets} from "../../../../../../../common/hooks/useOptionsSelectHandsets";

interface Props {
    userId?: number;
    refreshDataFromServer: () => void;
}

const useAddingEditingEmployee = ({userId, refreshDataFromServer}: Props) => {

    const {closeSlideModal} = useSlideModal();

    const roles = useSelector(state => state.common.roles);

    const directions = useSelector(state => state.common.directions);

    const handsetOptions = useOptionsSelectHandsets();

    const rolesOptions = Object.entries(roles)
        .map(([key, value]) => ({
            value: key, label: value,
        }));

    const directionsOptions = Object.values(directions)
        .map(value => ({value: value.code, label: value.name}));

    const [loadingPage, setloadingPage] = useState(false);

    const [loadingRequest, setLoadingRequest] = useState(false);

    const [state, setState] = useState<ResponseUserDetail>({
        detail: {
            additionalPhone: null,
            birthday: null,
            branches: null,
            email: null,
            color: null,
            comments: null,
            document: null,
            gender: null,
            id: 0,
            lastName: '',
            login: '',
            name: '',
            personal: null,
            phone: null,
            photo: null,
            role: null,
            secondName: null,
            specialities: null,
            active: true,
            handsets: null,
        },
        salary: {},
    });

    const [passwordState, setPasswordState] = useState<{
        value?: string,
        confirm?: string
    }>({value: '', confirm: ''});

    const [validationState, setValidationState] = useState(false);

    const [loginState, setLoginState] = useState<string>()

    useEffect(() => {
        if (userId) {
            setloadingPage(true);
            ServerCommand.get('user/detail', {id: userId}).then((response) => {
                setState(prevState => ({...prevState, ...response}));
                setloadingPage(false);
                setLoginState(response.detail.login)
            });

        }
    }, []);

    useEffect(() => {

        if (state.detail.role !== 'admin' && state.detail.role !== 'marketing-admin') {
            setState(prevState => ({...prevState, detail: {...prevState.detail, handsets: null}}));
        }

    }, [state.detail.role])

    const setDetailFn = ({key, value}: setFnType) => {
        setState(prevState => ({...prevState, detail: {...prevState.detail, [key]: value}}));
    };

    const setSalaryFn = ({key, value}: setFnType) => {
        setState(prevState => ({...prevState, salary: {...prevState.salary, [key]: value}}));

    };

    const {
        birthdayValid,
        branchesValid,
        seriesValid,
        issueDateValid,
        addresDateValid,
        passwordValueValid,
        fullValid,
    } = useValidate({state, passwordState, userId})

    const saveUser = () => {
        setLoadingRequest(true);

        const data = {
            ...state.detail,
            salary: state.salary,
            gender: state.detail.gender === 'unknown' ? null : state.detail.gender,
            password: passwordState?.value,
            passwordConfirmation: passwordState?.confirm,
        };
        ServerCommand.post(
            `user/${userId ? 'edit' : 'add'}`,
            data,
            () => {
                closeSlideModal();
                refreshDataFromServer();
            }).finally(() => {
            setLoadingRequest(false);
        });
    };

    return {
        loadingPage,
        state,
        setState,
        setDetailFn,
        setSalaryFn,
        directionsOptions,
        rolesOptions,
        handsetOptions,
        saveUser,
        passwordState,
        setPasswordState,
        loadingRequest,
        validationState,
        setValidationState,
        birthdayValid,
        branchesValid,
        seriesValid,
        issueDateValid,
        addresDateValid,
        passwordValueValid,
        fullValid,
        loginState
    };
};

export default useAddingEditingEmployee;
