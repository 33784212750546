import useHandsets from "./useHandsets";
import {useMemo} from "react";
import {OptionSelect} from "../../components-ui/Inputs/Select/typesSelect";

export const useOptionsSelectHandsets = ():OptionSelect[] => {
    const handsets = useHandsets()
    return useMemo(() => handsets.map(handset => ({
        label: handset.phoneNumber + ' ' + handset.name,
        value: handset.id
    })), [handsets])
}
