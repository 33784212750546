import React from 'react';
import './InvoiceList.scss';
import ServerCommand from "../../../../common/server/server-command";
import PropTypes from "prop-types";
import Table from "../../../../components/Table/Table";
import Invoice from "./Invoice";
import Arr from "../../../../common/helpers/Arr";
import DateHelper from "../../../../common/helpers/date-helper";
import ServiceDirectionName from "../../../../components/ServiceDirectionName/ServiceDirectionName";
import {DialogsManagerContext} from "../../../../containers/DialogsManager/DialogsManager";
import InvoiceImport from "./InvoiceImport";
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import Tabs from "../../../../components-ui/Navigation/Tabs/Tabs";

export default class InvoiceList extends React.Component {
    static contextType = DialogsManagerContext;
    static propTypes = {
        patientId: PropTypes.number.isRequired
    };

    state = {
        activeTab: 'invoices',
        invoices: null,
        invoiceImports: null,
        servicesGroups: null
    };

    render() {
        const {invoices, invoiceImports} = this.state;

        if (invoices === null) return null;

        const hasInvoices = invoices.length > 0 || invoiceImports.length > 0;
        const invoicesIsPresentClass = (hasInvoices ? 'box' : 'box box--min');

        return (
            <div className="plans">
                <div className={`invoice-patient-card ${invoicesIsPresentClass}`}>
                    {hasInvoices ? this.renderInvoicesTable() : this.renderInvoicesAbsentMessage()}
                </div>
            </div>
        )
    }

    renderInvoicesTable() {
        const {invoices, invoiceImports, activeTab} = this.state;

        let tabs = [];
        if (invoices.length > 0) {
            tabs.push({
                name: 'Счета',
                code: 'invoices',
            });
        }
        if (invoiceImports.length > 0) {
            tabs.push({
                name: 'Импортированные счета',
                code: 'invoiceImports',
            });
        }

        return (
            <div className="box-body">
                <Tabs tabs={tabs}
                      activeTab={activeTab}
                      onChange={(code) => {this.setState({activeTab: code})}}
                />
                <div className="card invoice-list__table">
                    <Table data={activeTab === 'invoices' ? invoices : invoiceImports}
                           columns={activeTab === 'invoices' ? invoiceColumns : invoiceImportColumns}
                           onSelect={activeTab === 'invoices' ? this.handleInvoiceClick : this.handleInvoiceImportClick}
                           prepareDataItem={this.prepareInvoice}
                           scrollHeight={567}
                           total={{
                               sum: activeTab === 'invoices' ? this.getInvoiceTotal('sum') : this.getInvoiceImportTotal('sum'),
                               paidSum: this.getInvoiceTotal('paidSum'),
                           }}
                    />
                </div>
            </div>
        )
    }

    renderInvoicesAbsentMessage() {
        return (
            <div>
                <h2>Счетов пока нет</h2>
                <p>Пациенту не выставлялись счета на оплату</p>
            </div>
        );
    }

    renderDirectionName(direction) {
        return (
            <ServiceDirectionName direction={direction}/>
        );
    }

    prepareInvoice = ({item: invoice}) => {
        const {directions} = this.state;

        invoice.rowCode = invoice.id;
        invoice.title = `Счет №${invoice.id}`;
        invoice.createdAt = DateHelper.formatDate(invoice.createdAt, 'DD ru_month_gen YYYY');
        invoice.direction = this.renderDirectionName(directions[invoice.direction]);

        invoice.canceled = invoice.isCanceled ? 'Да' : 'Нет';
        invoice.isPale = invoice.isCanceled;
        invoice.paidSum = invoice.isCanceled ? null : invoice.paidSum;

        invoice.autopaymentReason = null;

        if(invoice.params && invoice.params['paid_by']) {
            const paidBy = invoice.params['paid_by'];
            if(autopaymentReasonsTitles[paidBy]) {
                invoice.autopaymentReason = autopaymentReasonsTitles[paidBy];
            }
        }
     };

    componentDidMount() {
        ServerCommand
            .get('invoice/list-for-patient', {
                patientId: this.props.patientId,
            })
            .then(response => this.setState({
                invoices: response.invoices,
                invoiceImports: response.invoiceImports,
                servicesGroups: response.servicesGroups,
                directions: Arr.toObject(response.directions, 'code'),
                activeTab: response.invoices.length > 0 ? 'invoices' : 'invoiceImports',
            }));
    }

    getInvoiceTotal(fieldName) {
        const {invoices} = this.state;
        let total = 0;

        invoices.forEach(invoice => {
            if(!invoice.isCanceled) {
                total += invoice[fieldName];
            }
        });

        return total;
    }

    getInvoiceImportTotal(fieldName) {
        const {invoiceImports} = this.state;
        let total = 0;

        invoiceImports.forEach(invoice => {
            total += invoice[fieldName];
        });

        return total;
    }

    handleInvoiceClick = (invoicesIds, invoiceId) => {
        const invoice = this.state.invoices.find(invoice => invoice.id === invoiceId);
        this.props.showSlideModal(<div>
            <Invoice
                invoiceId={invoice.id}
                servicesGroups={this.state.servicesGroups}
            />
        </div>)
    };

    handleInvoiceImportClick = (invoicesIds, invoiceId) => {
        const invoice = this.state.invoiceImports.find(invoice => invoice.id === invoiceId);
        this.props.showSlideModal(<div>
            <InvoiceImport
                invoiceId={invoice.id}
                servicesGroups={this.state.servicesGroups}
            />
        </div>)
    };
}

const invoiceColumns = [
    {
        title: 'Дата',
        code: 'createdAt'
    },
    {
        title: 'Счёт',
        code: 'title'
    },
    {
        title: 'Направление',
        code: 'direction'
    },
    {
        title: 'Отменён',
        code: 'canceled'
    },
    {
        title: 'Сумма',
        code: 'sum',
        type: 'price'
    },
    {
        title: 'Оплачено',
        code: 'paidSum',
        type: 'price'
    },
];

const invoiceImportColumns = [
    {
        title: 'Дата',
        code: 'createdAt'
    },
    {
        title: 'Счёт',
        code: 'title'
    },
    {
        title: 'Отменён',
        code: 'canceled'
    },
    {
        title: 'Сумма',
        code: 'sum',
        type: 'price'
    },
];


const autopaymentReasonsTitles = {
    'social_visit': 'Социальный приём',
    'netting': 'Взаимозачёт',
};
