import React, {useMemo} from 'react';
import Select from "../../../../../../../../components-ui/Inputs/Select/Select";
import {OptionSelect} from "../../../../../../../../components-ui/Inputs/Select/typesSelect";
import {commonLabelOption, commonSelectOption} from "../data";
import {Label} from "../../../../../../../../components-ui/DataDisplay/Label";
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {User} from '../../../../../../../../types';
import {GetTimesheetListResult} from '../../../../../../types';
import {notTrashed} from '../../../../../../../../common/helpers/general-helper';

interface Props {
    data: GetTimesheetListResult
    value?: OptionSelect
    handleEmployee: (employe: OptionSelect) => void
    isError: boolean
    selectedBranchId: number
}

const SelectEmployee = ({data, value, handleEmployee, isError, selectedBranchId}: Props) => {

    const users = useSelector(state => state.common.users)

    const unrecognizedUsersOptions = useMemo(() => {
        return _
            .chain(users)
            .omit(_.map(data.records, 'employeeId'))
            .filter(notTrashed)
            .filter((user: User) => user.branches.includes(selectedBranchId))
            .reject((user: User) => data.excludeEmployeesRoles.includes(user.role))
            .map((user: User) => {return {value: user.id, label: user.fio}})
            .sortBy(user => user.label)
            .value()
    }, [data.records, users])

    return (
        <Label labelText="Сотрудник" isRequired={true} {...commonLabelOption} >
            <Select {...commonSelectOption} colorSelect="white" isError={isError} value={value} options={unrecognizedUsersOptions} onChange={(value) => handleEmployee(value)}/>
        </Label>
    )

};

export default SelectEmployee;
