import React from 'react';
import Typography from '../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../components-ui/Layout/Box';
import Paper from '../../containers/UI-Collection/Elements/Surfaces/Paper';
import TableWithGroupsTree from '../../components/TableWithGroupsTree/TableWithGroupsTree';
import useLaboratoryPricelistPage from './hooks/useLaboratoryPricelistPage';
import LoaderPage from '../../components/LoaderPage';
import {useSelector} from 'react-redux';

const LaboratoryPricelistPage = () => {

    const user = useSelector(state => state.user);

    const {
        loaderPage,
        laboratoryPricesResponse,
        columns,
        methods,
        refreshDataFromServer,
        servicesCases,
        prepareDataItem,
        itemFields
    } = useLaboratoryPricelistPage();

   return (
        <Box padding='62px 20px 80px 28px'>
            <Typography variant='title' fontWeight='bold'>Прейскурант лаборатории</Typography>
            {!loaderPage ?
                <Paper margin='28px 32px 48px 0'>
                    <div className='box-body'>
                        <div className='pricelist'>
                            <div className='card'>

                                <div className='pricelist__service-tab'>
                                    <div className='pricelist__service-table'>
                                        <TableWithGroupsTree
                                            columns={columns}
                                            data={laboratoryPricesResponse?.services}
                                            groups={[{
                                                code: 'ALL',
                                                groupId: 0,
                                                groups: laboratoryPricesResponse?.serviceGroups,
                                                id: null,
                                                isOpened: true,
                                                name: 'Все группы',
                                            }]}
                                            groupFields={[laboratoryPricesResponse?.serviceGroupFields.name]}
                                            editMode={user.canUpdateLaboratoryPricelist}
                                            methods={methods}
                                            selectMode='single'
                                            cases={servicesCases}
                                            updateData={refreshDataFromServer}
                                            itemFields={itemFields}
                                            onAfterSave={() => {}}
                                            prepareDataItem={prepareDataItem}
                                            searchPlaceholder="Поиск услуги"
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Paper>
                :
                <LoaderPage height={185} />
            }

        </Box>

    );
};

export default LaboratoryPricelistPage;
