import React from "react";
import SVG from "../../../../../common/helpers/SVG";
import {SearchIconWrap} from "./styledSearchIcon";

interface Props {
    isSearch?: boolean
}

export default function SearchIcon({isSearch}: Props) {
    if (!isSearch) return null;
    return (
        <SearchIconWrap>
            <SVG name="search" />
        </SearchIconWrap>
    )
}
