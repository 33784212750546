import React from 'react';
import Box from '../../../../../components-ui/Layout/Box';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import {
    tableHandleScroll,
    TableHeader,
} from '../../../../../components-ui/DataDisplay/Table/style/scrollStyledComponents';
import {WIDTH_TABLE_COLUMNS} from '../common';

interface Props {
    handleScroll: tableHandleScroll
    scrollClassName: string
}

const TableScrollHeaderComponent = ({handleScroll, scrollClassName}: Props) => {

    return (
            <TableHeader handleScroll={handleScroll} scrollClassName={scrollClassName}>
                <Box padding="0 5px 0 25px"
                     display="flex"
                     minWidth={`calc(${WIDTH_TABLE_COLUMNS.paymentMethod} + ${WIDTH_TABLE_COLUMNS.invoice} + ${WIDTH_TABLE_COLUMNS.services} + ${WIDTH_TABLE_COLUMNS.doctors});`}
                     height="inherit"
                     justifyContent="space-between"
                     boxSizing="content-box"
                     alignItems="center">
                    <Typography variant="small" fontWeight="bold" width={WIDTH_TABLE_COLUMNS.paymentMethod}>Способ оплаты</Typography>
                    <Typography variant="small" padding="0 0 0 17px" fontWeight="bold" width={WIDTH_TABLE_COLUMNS.invoice}>Счёт</Typography>
                    <Box minWidth={WIDTH_TABLE_COLUMNS.services}
                         flexGrow={1}
                    >
                        <Typography variant="small" padding="0 0 0 17px" fontWeight="bold">Услуга</Typography>
                    </Box>
                    <Typography variant="small" padding="0 0 0 17px" fontWeight="bold" width={WIDTH_TABLE_COLUMNS.doctors}>Врач</Typography>
                </Box>
            </TableHeader>
    );
};

export default TableScrollHeaderComponent;
