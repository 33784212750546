import {TimesheetRecord} from "../../../types";
import {useSelector} from "react-redux";
import {useMemo} from "react";

interface Props {
    records: TimesheetRecord[]
}

const useSortRecords = ({records}: Props) => {

    const users = useSelector(state => state.common.users)

    return useMemo(() => {
        const recordSort = (record1: TimesheetRecord, record2: TimesheetRecord) => users[record1.employeeId].fio > users[record2.employeeId].fio ? 1 : -1
        const onlineSort = records.filter(record => !!record.endTime).sort(recordSort)
        const offlineSort = records.filter(record => !record.endTime).sort(recordSort)

        return [...offlineSort, ...onlineSort]
    }, [records, users])
};

export default useSortRecords;