export const NAMES_SVG_OBJ = {

    ARROWS_arrow: 'arrow',
    ARROWS_arrowThin: 'arrowThin',
    ARROWS_longArrowThin: 'longArrowThin',
    ARROWS_filledArrowDown: 'filledArrowDown',
    ARROWS_doubleFilledArrowDown: 'doubleFilledArrowDown',
    ARROWS_longArrow: 'longArrow',
    ARROWS_backArrow: 'backArrow',
    ARROWS_outlineArrowDown: 'outlineArrowDown',
    ARROWS_playArrow: 'playArrow',

    CHECKED_checkedThin: 'checkedThin',
    CHECKED_checked: 'checked',
    CHECKED_checkedBold: 'checkedBold',
    CHECKED_checkOld: 'checkOld',

    CROSS_closeThin: 'closeThin',
    CROSS_close: 'close',
    CROSS_delete: 'delete',

    PLUS_plusThin: 'plusThin',
    PLUS_plus: 'plus',
    PLUS_plusBold: 'plusBold',
    PLUS_plusRound: 'plusRound',

    MINUS_minus: 'minus',
    MINUS_minusBold: 'minusBold',
    MINUS_minusRound: 'minusRound',

    SLASH_slash: 'slash',
    SLASH_doubleSlash: 'doubleSlash',

    SCREEN_fullScreen: 'fullScreen',
    SCREEN_smallScreen: 'smallScreen',

    INFO_notify: 'notify',
    INFO_notifyFilled: 'notifyFilled',
    INFO_notifyOld: 'notifyOld',
    INFO_time: 'time',
    INFO_timeOutline: 'timeOutline',
    INFO_timeDotted: 'timeDotted',
    INFO_information: 'information',
    INFO_instruction: 'instruction',
    INFO_warning: 'warning',
    INFO_warningOutline: 'warningOutline',
    INFO_help: 'help',
    INFO_mouseRight: 'mouseRight',

    ACTION_basket: 'basket',
    ACTION_edit: 'edit',
    ACTION_printer: 'printer',
    ACTION_save: 'save',
    ACTION_add: 'add',
    ACTION_search: 'search',
    ACTION_maximize: 'maximize',
    ACTION_printerOld: 'printerOld',

    PASS_viewPass: 'viewPass',
    PASS_hiddenPass: 'hiddenPass',

    SCHEDULECELL_inspection: 'inspection',
    SCHEDULECELL_confirmedByMessage: 'confirmedByMessage',
    SCHEDULECELL_unconfirmedByMessage: 'unconfirmedByMessage',
    SCHEDULECELL_tender: 'tender',
    SCHEDULECELL_userFp: 'userFp',

    MENU_analytics: 'analytics',
    MENU_cardCatalogue: 'cardCatalogue',
    MENU_schedule: 'schedule',
    MENU_materials: 'materials',
    MENU_options: 'options',
    MENU_document: 'document',
    MENU_xray: 'xray',
    MENU_salary: 'salary',
    MENU_laboratory: 'laboratory',
    MENU_cashbox: 'cashbox',

    CALLCENTER_documentDelete: 'documentDelete',
    CALLCENTER_documentDownload: 'documentDownload',
    CALLCENTER_incoming: 'incoming',
    CALLCENTER_missed: 'missed',
    CALLCENTER_outgoing: 'outgoing',

    POPUPLABEL_callCenter: 'callCenter',
    POPUPLABEL_kanban: 'kanban',
    POPUPLABEL_newVisitForm: 'newVisitForm',
    POPUPLABEL_prepareVisit: 'prepareVisit',
    POPUPLABEL_paymentsCheck: 'paymentsCheck',
    POPUPLABEL_timeSheet: 'timeSheet',

    REST_product: 'product',
    REST_colorDocument: 'colorDocument',
    REST_colorFolder: 'colorFolder',
    REST_folder: 'folder',
    REST_canceled: 'canceled',
    REST_wtf: 'wtf',
    REST_dots: 'dots',
    REST_target: 'target',
    REST_map: 'map',
    REST_printer3D: 'printer3D',
    REST_sortAsc: 'sortAsc',
    REST_sortDesc: 'sortDesc',
    REST_sortDefault: 'sortDefault',
    REST_credit: 'credit',
    REST_bigPrice: 'bigPrice',
    REST_guarantee: 'guarantee',
    REST_logoNs: 'logoNs',
    REST_saving: 'saving',
    REST_taskClinic: 'taskClinic',
    REST_employee: 'employee',
    REST_comment: 'comment',

    TEETH_segment: 'segment',
    TEETH_tooth: 'tooth',
    TEETH_toothOutline: 'toothOutline',
    TEETH_toothHalf: 'toothHalf',
    TEETH_oralCavity: 'oralCavity',
    TEETH_bothJaw: 'bothJaw',
    TEETH_lowerJawFront: 'lowerJawFront',
    TEETH_lowerJaw: 'lowerJaw',
    TEETH_upperJaw: 'upperJaw',
    TEETH_lowerJawOutline: 'lowerJawOutline',
    TEETH_upperJawOutline: 'upperJawOutline',
    TEETH_crown: 'crown',
    TEETH_toothRecovery: 'toothRecovery',

    ANALYTICS_money: 'money',
    ANALYTICS_averageCheck: 'averageCheck',
    ANALYTICS_loadingTheClinic: 'loadingTheClinic',
    ANALYTICS_analyticsCalls: 'analyticsCalls',
    ANALYTICS_callEfficiency: 'callEfficiency',
    ANALYTICS_efficiencyOfVisits: 'efficiencyOfVisits',
    ANALYTICS_message: 'message',

    DENTALCARD_tooth11: 'tooth11',
    DENTALCARD_tooth12: 'tooth12',
    DENTALCARD_tooth13: 'tooth13',
    DENTALCARD_tooth14: 'tooth14',
    DENTALCARD_tooth15: 'tooth15',
    DENTALCARD_tooth16: 'tooth16',
    DENTALCARD_tooth17: 'tooth17',
    DENTALCARD_tooth18: 'tooth18',
    DENTALCARD_tooth21: 'tooth21',
    DENTALCARD_tooth22: 'tooth22',
    DENTALCARD_tooth23: 'tooth23',
    DENTALCARD_tooth24: 'tooth24',
    DENTALCARD_tooth25: 'tooth25',
    DENTALCARD_tooth26: 'tooth26',
    DENTALCARD_tooth27: 'tooth27',
    DENTALCARD_tooth28: 'tooth28',
    DENTALCARD_tooth31: 'tooth31',
    DENTALCARD_tooth32: 'tooth32',
    DENTALCARD_tooth33: 'tooth33',
    DENTALCARD_tooth34: 'tooth34',
    DENTALCARD_tooth35: 'tooth35',
    DENTALCARD_tooth36: 'tooth36',
    DENTALCARD_tooth37: 'tooth37',
    DENTALCARD_tooth38: 'tooth38',
    DENTALCARD_tooth41: 'tooth41',
    DENTALCARD_tooth42: 'tooth42',
    DENTALCARD_tooth43: 'tooth43',
    DENTALCARD_tooth44: 'tooth44',
    DENTALCARD_tooth45: 'tooth45',
    DENTALCARD_tooth46: 'tooth46',
    DENTALCARD_tooth47: 'tooth47',
    DENTALCARD_tooth48: 'tooth48',
    DENTALCARD_tooth51: 'tooth51',
    DENTALCARD_tooth52: 'tooth52',
    DENTALCARD_tooth53: 'tooth53',
    DENTALCARD_tooth54: 'tooth54',
    DENTALCARD_tooth55: 'tooth55',
    DENTALCARD_tooth56: 'tooth56',
    DENTALCARD_tooth57: 'tooth57',
    DENTALCARD_tooth58: 'tooth58',
    DENTALCARD_tooth61: 'tooth61',
    DENTALCARD_tooth62: 'tooth62',
    DENTALCARD_tooth63: 'tooth63',
    DENTALCARD_tooth64: 'tooth64',
    DENTALCARD_tooth65: 'tooth65',
    DENTALCARD_tooth66: 'tooth66',
    DENTALCARD_tooth67: 'tooth67',
    DENTALCARD_tooth68: 'tooth68',
    DENTALCARD_tooth71: 'tooth71',
    DENTALCARD_tooth72: 'tooth72',
    DENTALCARD_tooth73: 'tooth73',
    DENTALCARD_tooth74: 'tooth74',
    DENTALCARD_tooth75: 'tooth75',
    DENTALCARD_tooth76: 'tooth76',
    DENTALCARD_tooth77: 'tooth77',
    DENTALCARD_tooth78: 'tooth78',
    DENTALCARD_tooth81: 'tooth81',
    DENTALCARD_tooth82: 'tooth82',
    DENTALCARD_tooth83: 'tooth83',
    DENTALCARD_tooth84: 'tooth84',
    DENTALCARD_tooth85: 'tooth85',
    DENTALCARD_tooth86: 'tooth86',
    DENTALCARD_tooth87: 'tooth87',
    DENTALCARD_tooth88: 'tooth88',
} as const
