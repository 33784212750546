import React from "react";
import Box from "../../../../components-ui/Layout/Box";
import Button from "../../../../components-ui/Inputs/Button/Button";
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {buttonTitles} from "../../helper/getDataCallCenter";
import ServerCommand from "../../../../common/server/server-command";
import useLoader from "../../../../components/Errors";
import {CommonButtonProps} from "../../types/components";

interface Props extends CommonButtonProps {
    blockedButtons: boolean
}

export const AgainstCallsButton = ({task, blockedButtons, margin, methodsCallCenter}: Props) => {
    const {deleteVisit} = methodsCallCenter;
    const [loader, setLoader] = useLoader();
    return (
        <Box className="button-task-content" margin={margin}>
            <Button {...commonPropsButton} variant={blockedButtons ? 'disabled' : "danger"}
                    children="против звонков"
                    title={blockedButtons ? buttonTitles.blocked : buttonTitles.againstCalls}
                    loader={loader}
                    onClick={() => {
                        setLoader(true)
                        ServerCommand.post('patient/edit', {
                            id: task.targetPerson.patientId,
                            refusedMarketingCalls: true
                        }).then(() => deleteVisit(task.id))
                    }}/>
        </Box>
    )
}
