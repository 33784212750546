import styled from "styled-components";
import {PropsSCounterInput, PropsSCounterWrap} from "./typesCounter";

const borderRadius = (props: PropsSCounterWrap) => {
    const {size} = props;
    switch (size) {
        case "md":
            return '4px'
        case "sm":
        default:
            return '1px'
    }
}

export const SCounterWrap = styled.div.attrs({className: 'SCounterWrap'})<PropsSCounterWrap>`
    display: flex;
    border: 1px solid ${props => props.theme.colors.borderGray};
    border-radius: ${borderRadius};
    width: max-content;
    ${props => !!props.margin && `
        margin: ${props.margin}
    `}

`

export const SCounterInput = styled.input.attrs({className: 'SCounterInput', type: 'text'})<PropsSCounterInput>`
    font-size: 1rem;
    width: 35px;
    height: ${props => props.height};
    border: none;
    border-right: 1px solid ${props => props.theme.colors.borderGray};
    border-left: 1px solid ${props => props.theme.colors.borderGray};
    text-align: center;
    flex-shrink: 0;
    outline: none;
`
