import React from "react";
import {useDispatch} from "react-redux";
import ServerCommand from "../../../common/server/server-command";
import {PlannedCall, TaskCallCenter} from "../types/common";
import {addMemorizedPatient, addTransferPatient} from "../../../redux/actions/scheduleActions";
import preparePatient from "../helper/preparePatien";
import Cancellation from "../../modals/Cancellation/Cancellation";
import {PatientValues} from "../../../containers/PatientField";
import WaitingAndPlanned
    from "../containers/WaitingAndPlanned/WaitingAndPlanned";
import {PatientTransferAndMemorized} from "../types/hooks";
import {useSlideModal} from "../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {useDialogsManager} from "../../../containers/DialogsManager/DialogsManager";
import PatientCard from "../../PatientCard/PatientCard";

const useMethodsCallCenter = (deleteTaskCalls: (taskId: number | number[]) => void) => {
    const {closeSlideModal} = useSlideModal();
    const dispatch = useDispatch();
    const {showModal, showSlideModal} = useDialogsManager();
    const confirmVisit = (visitId: number, taskId: number) => {
        ServerCommand.post('visit/confirm', {visitId}).then(() => deleteTaskCalls(taskId));
    }

    const transferVisit = (patient: PatientTransferAndMemorized) => {
        dispatch(addTransferPatient(patient))
        closeSlideModal()
    }

    const memorizedVisit = (task: TaskCallCenter, personalPhone?: string | number, cellName?: string) => {
        const patient = preparePatient(task, personalPhone, cellName);
        patient.onEndMemorized = () => deleteVisit(task.id);
        patient.isOpenNewVisitForm = true;
        dispatch(addMemorizedPatient(patient))
        closeSlideModal()
    }

    const cancelVisit = (visitId: number) => {
        showModal(<Cancellation visitId={visitId}/>)
    }

    const deleteVisit = (taskId: number | number[]) => {
        ServerCommand.post('call-center/task/complete', {
            tasksIds: typeof taskId === 'number' ? [taskId] : taskId
        }).then(() => deleteTaskCalls(taskId))
    }

    const postponeServerCommand = (plannedCall: PlannedCall, date?: any) => {
        const tasksIds = plannedCall.tasks.map(task => task.id)
        ServerCommand.post('call-center/task/postpone', {
            tasksIds,
            postponeTo: !!date ? date : null
        }).then(() => {})
    }

    const WAPVisit = (patient: PatientValues, isPlanTab?: boolean, onEndSaveWap?: () => void) => {
        showModal(<WaitingAndPlanned isPlanTab={isPlanTab} patient={patient} onEndSaveWap={onEndSaveWap}/>, {isIconClose: false})
    }

    const refuseVisit = (recordId: number) => {
        ServerCommand.post('waiting-list/refuse-record', {id: recordId})
    }

    const deleteWaitingList = (recordId: number) => {
        ServerCommand.post('waiting-list/delete-record', {id: recordId})
    }

    const openPlan = (patientId: number, planType: string) => {
        showSlideModal(<PatientCard patientId={patientId} activeSectionNav="TREATMENT_PLAN" treatmentPlanType={planType}/>)
    }

    return {
        confirmVisit,
        transferVisit,
        memorizedVisit,
        cancelVisit,
        deleteVisit,
        postponeServerCommand,
        WAPVisit,
        refuseVisit,
        deleteWaitingList,
        openPlan
    }
}

export default useMethodsCallCenter
