import ServerCommand from "./server-command";
import moment from "moment";

export default class CallFaker {
    protected contactPhone: string;
    protected companyPhone: string|null = null;

    protected timezoneShift: number;
    protected recordLink?: string;

    constructor(contactPhone: string, companyPhone?: string|null) {
        this.contactPhone = contactPhone.replaceAll(/\D/g, '');

        if (companyPhone) {
            this.companyPhone = companyPhone.replaceAll(/\D/g, '');
        }

        this.timezoneShift = Number(process.env.REACT_APP_CALL_FAKER_TIMEZONE_SHIFT);
        this.recordLink = process.env.REACT_APP_CALL_FAKER_RECORD_LINK;
    }

    public startOutgoingCall(handsetPhoneNumber: string) {
        this.sendEvent('outgoing-start', {
            startTime: this.time(),
            employeePhoneNumber: handsetPhoneNumber
        });

        return this;
    }

    public startIncomingCall() {
        this.sendEvent('incoming-start', {
            startTime: this.time(),
            virtualPhoneNumber: this.companyPhone
        });

        return this;
    }

    public answer(handsetPhoneNumber: string) {
        this.sendEvent('answer', {
            employeePhoneNumber: handsetPhoneNumber,
            answerTime: this.time()
        });

        return this;
    }

    public endCall(answered: boolean) {
        this.sendEvent('end', {
            endTime: this.time(),
            recordLink: (answered && this.recordLink) ? this.recordLink : ''
        });

        return this;
    }

    protected sendEvent(eventCode: string, additionalData: {[key: string]: any} = {}) {
        const data = Object.assign({
            apiToken: process.env.REACT_APP_CALL_FAKER_TOKEN,
            contactPhoneNumber: this.contactPhone
        }, additionalData);

        ServerCommand.get('call-center/call/register-' + eventCode, data);
    }

    protected time() {
        return moment().add(this.timezoneShift, 'h').format('YYYY-MM-DD HH:mm:ss');
    }
}
