import {useMemo} from "react";
import Helper from "../../../../../../common/helpers/main";
import {IColumn, IRow} from "../../../../../../components-ui/DataDisplay/Table";
import {useSelector} from "react-redux";
import {ReceiptsReport} from "../../../../types";
import {StateReceipts} from "../types";
import {tabsReceipts} from "../data/tabsReceipts";
import {Tab} from "../../../../../../components-ui/Navigation/Tabs/elements/Tab/typesTab";
import _ from 'lodash'

interface Props {
    report: ReceiptsReport
    state: StateReceipts
}

const useTableReceipts = ({state, report}: Props) => {
    const servicesNames = useSelector(state1 => state1.common.servicesNames)
    const users = useSelector(state1 => state1.common.users)

    const columns = useMemo(() => {
        if (!!state.currentTab) {
            const currentTab = tabsReceipts.find(tab => tab.code === state.currentTab) as Tab
            return [
                {
                    headerName: currentTab.name[0].toUpperCase() + currentTab.name.slice(1),
                    field: currentTab.code,
                    valueFormatter: ({row}) => currentTab.code === 'services' ? servicesNames[row.id] : Helper.getFio(users[row.id]),
                    sortable: true,
                    sortType: 'string',
                    sortPosition: 'right',
                    sortFormatter: ({row}) => currentTab.code === 'services' ? servicesNames[row.id] : Helper.getFio(users[row.id])
                },
                {
                    headerName: 'Количество услуг',
                    field: 'quantity',
                    valueFormatter: ({childrenCell}) => Helper.String.numberFormat(childrenCell, 0),
                    width: '155px',
                    sortable: true,
                    sortType: 'number',
                    align: 'right',
                    sortPosition: 'newColumn',
                },
                {
                    headerName: 'Сумма, руб.',
                    field: 'price',
                    valueFormatter: ({childrenCell}) => Helper.String.numberFormat(childrenCell, 0),
                    width: '140px',
                    sortable: true,
                    sortType: 'number',
                    align: 'right',
                    sortPosition: 'newColumn',
                    defaultSort: true
                }
            ] as IColumn[]
        } else {
            return [] as IColumn[]
        }
    }, [state.currentTab])

    const rows = useMemo(() => {
        const directions = report.current.directions as any;
        const general = report.current.general as any;

        if (!!Object.keys(directions).length && !!state.selectedDirection && !!directions[state.selectedDirection]
            && !!state.currentTab && !!directions[state.selectedDirection][state.currentTab]) {

            return _.map(directions[state.selectedDirection][state.currentTab], (price, id) => ({
                id,
                quantity: price.quantity,
                price: price.sum
            })).sort((a, b) => b.price - a.price) as IRow[]
        } else if (!!state.selectedDirection && state.selectedDirection === 'all' && !!state.currentTab && !!general[state.currentTab]) {
            return _.map(general[state.currentTab], (price, id) => ({
                id,
                quantity: price.quantity,
                price: price.sum
            })).sort((a, b) => b.price - a.price) as IRow[]
        } else {
            return [] as IRow[]
        }
    }, [state.currentTab, state.selectedDirection, JSON.stringify(report)])

    return {
        columns,
        rows
    }
}

export default useTableReceipts
