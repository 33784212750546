import React, {useCallback, useEffect} from 'react'
import NotificationVisit from "./components/NotificationVisit";
import {useDialogsManager} from "../DialogsManager/DialogsManager";
import {addVisit, removeVisit} from "../../redux/actions/doctorRoleActions";
import Steps from "../../content/Steps/Steps";
import {startVisit} from "../../redux/actions/doctorRoleActions";
import {echo} from "../../common/server/echo";
import {useDispatch, useSelector} from "react-redux";
import SetHandsetUser from "../../content/modals/SetHandsetUser";

export default function Notification() {

    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const {showSlideModal} = useDialogsManager();

    const addWaitingVisit = useCallback(({visit}) => {
        dispatch(addVisit(visit))
    }, [dispatch])
    const visits = useSelector(state => state.doctorRole.visits);

    const showSteps = (visitId: number) => {
        showSlideModal( <Steps visitId={visitId}/>)
        dispatch(startVisit(visitId));
    };

    const handleVisitUpdate = useCallback(({visit}) => {
        if ((visit.STATUS === 'CANCELED') || (visit.STATUS === 'CANCELED_IN_ADVANCE')) {
            dispatch(removeVisit(visit.id))
        }
    }, [dispatch])

    const handleVisitRevived = useCallback(({visitId}: {visitId: number}) => {
        showSlideModal(<Steps visitId={visitId}/>, undefined, true)
    }, [])

    useEffect(() => {
        if (user.role === 'doctor') {
            echo.private('doctor-visits.' + user.id)
                .listen('VisitWaiting', addWaitingVisit)
                .listen('VisitUpdated', handleVisitUpdate)
                .listen('VisitRevived', handleVisitRevived)
                .listen('VisitWaitingIsOver', ({visitId}: any) => showSteps(visitId))
        }
        echo.private('doctor-visits.' + user.id)


        return () => {
            echo.leaveChannel('doctor-visits.' + user.id)
        }
    }, [dispatch, addWaitingVisit, handleVisitRevived, handleVisitUpdate, user.id])


    const {showModal} = useDialogsManager();

    useEffect(() => {
        if (user.showSelectedHandset) {
            showModal(<SetHandsetUser userId={user.id}/>, {isIconClose: false, isNotClose: true})
        }
    }, [])

    return (
      <NotificationVisit/>
    )
}
