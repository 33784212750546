import React, {useRef} from "react";
import {SDentalCard} from "../../types";
import {ToothWrap, ButtonTooth, GumDiseaseWrap, GumDiseaseItem} from "./styledToothItem";
import SVG from "../../../../common/helpers/SVG";
import {useDentalCard} from "../../DentalRecords";
import {PTooth} from "./typesToothItem";
import Helper from "../../../../common/helpers/main";
import {INITIAL_SURVEY, TREATMENT_PLAN} from "../../../../common/constants/DentalRecords";

export default function ToothItem({tooth, jaw, toothIndex}: PTooth) {
    const {onClickToothButton, onContextMenuToothButton, refWrapDentalCard, teethMap, onClickButtonToothChild,
        parentComponentName, processedTeeth, editField} = useDentalCard() as SDentalCard;
    const ref = useRef(null) as any;

    const {statusList} = teethMap;
    const groupStatus = statusList[tooth.status].group;

    const onClickTooth = (e: any) => {
        e.preventDefault();
        onClickToothButton(jaw, tooth, toothIndex);
    }

    const onContextMenuTooth = (e: any) => {
        e.preventDefault();
        if (!tooth.selected) onClickToothButton(jaw, tooth, toothIndex);
        const parent = refWrapDentalCard.current.getBoundingClientRect();
        const element = ref.current.getBoundingClientRect();

        const x = element.left - parent.left + (element.width / 2);
        let y = element.top - parent.top + element.height;
        if (jaw === "lower") y -= 15;

        onContextMenuToothButton({x, y})
    }

    const isChildTooth = Helper.TeethMap.isChildTooth(tooth.number)

    const isToothExists = (((tooth.number % 10) > 5) && isChildTooth)

    const gumDisease = [];
    if (tooth.gumDisease !== undefined) {
        for (let i = 0; i < tooth.gumDisease; i++) gumDisease.push(i);
    }

    const isProcessed = () => {
        let result = false;
        if (parentComponentName === TREATMENT_PLAN) {
            result = !!processedTeeth?.some(process => process + '' === tooth.number + '')
        }
        return result;
    }

    return (
        <ToothWrap jaw={jaw} ref={ref} editField={editField}>
            <ButtonTooth jaw={jaw}
                         selected={tooth.selected}
                         onClick={onClickTooth}
                         onContextMenu={onContextMenuTooth}
                         status={groupStatus}
                         disabled={isToothExists}
                         unconfirmed={tooth.unconfirmed}
                         wisdom={+(tooth.number + '')[1] === 8}
            >
                <SVG name={`teeth/tooth-${groupStatus === 'MISSING' ? 'MISSING' : tooth.number}`} className="tooth-card-svg"/>
                <div className="tooth-card-text">
                    {tooth.number} {tooth.status !== 1 && statusList[tooth.status].code}
                </div>
                {!!gumDisease.length && (
                    <GumDiseaseWrap>
                        {gumDisease.map(gum => <GumDiseaseItem key={'gum' + gum}
                                                               selected={tooth.selected}/>)}
                    </GumDiseaseWrap>
                )}
            </ButtonTooth>
            {parentComponentName === INITIAL_SURVEY && (
                <SVG name={`teeth/${!isChildTooth ? 'sm-' : ''}tooth-standard`}
                     onClick={() => onClickButtonToothChild(jaw, tooth, toothIndex)}
                     className="tooth-standard"/>
            )}

            {isProcessed() && (
                <SVG name={'check'}
                     className="tooth-check"/>
            )}

        </ToothWrap>
    )
}
