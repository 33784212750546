import React from 'react';
import Typography from "../../components-ui/DataDisplay/Typography/Typography";
import {Alert} from "../../components-ui/DataDisplay/Alert";
import TimeSheetList from "./containers/TimeSheetList";

const TimeSheet = () => {
    return (
        <>
            <Typography as="h1" variant="title" fontWeight="bold" margin="30px 0">Табель учёта рабочего времени</Typography>
            <Alert
                children="В течение дня отмечайте время прихода и ухода сотрудников клиники"
                margin="auto"/>
            <TimeSheetList/>
        </>
    );
};

export default TimeSheet;