import {NoticesQuantity} from "../types";

interface Props {
    noticesQuantity: NoticesQuantity
    noticeQuantityTotal: number
}

const useCountNotice = ({noticesQuantity, noticeQuantityTotal}: Props) => {
    return (noticeName: string) => {
        let badge = undefined;
        if (!!noticesQuantity[noticeName]) {
            if (noticeName === 'all') {
                badge = noticeQuantityTotal
            } else {
                badge = noticesQuantity[noticeName]
            }
        }
        return badge
    }
}

export default useCountNotice
