import React, {useState} from 'react';
import {useModal} from '../../../../../containers/DialogsManager/containers/Modal/Modal';
import useLoader from '../../../../../components/Errors';
import {SelectedMonth} from '../../../../Salaries/types';
import ServerCommand from '../../../../../common/server/server-command';
import moment from 'moment';
import DateHelper from '../../../../../common/helpers/date-helper';
import {SaveRateParams} from '../../../types';
import {LaboratoryService} from '../../../../LaboratoryPricelistPage/types';
import {User} from '../../../../../types';
import Box from '../../../../../components-ui/Layout/Box';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import {Label} from '../../../../../components-ui/DataDisplay/Label';
import {
    commonLabelOption, commonSelectOption,
    commonTextFieldOption,
} from '../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import TextField from '../../../../../components-ui/Inputs/TextField/TextField';
import Select from '../../../../../components-ui/Inputs/Select/Select';
import {months, PMonths} from '../../../../../common/arrays/arrays';
import Button from '../../../../../components-ui/Inputs/Button/Button';

interface Props {
    rate: string
    service: LaboratoryService
    employee: User | null
    branchId: number
    refreshDataFromServer: () => void;
}

const LaboratoryRatesMark = ({rate, service, employee, branchId, refreshDataFromServer}: Props) => {

    const {closeModal} = useModal();

    const [loader, setLoader] = useLoader();

    const [laboratoryServiceRate, setLaboratoryServiceRate] = useState(rate);

    const [selectedMonth, setSelectedMonth] = useState<SelectedMonth>({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
    });

    const saveLaboratoryServiceRate = () => {
        setLoader(true);
        ServerCommand.post('laboratory/service/rate/save', {
            serviceId: service.id,
            employeeId: employee?.id,
            branchId,
            rate: +laboratoryServiceRate,
            activeFrom: String(moment(`${selectedMonth.year}-${selectedMonth.month}`).format(DateHelper.FORMAT_SERVER_MONTH)),
        } as SaveRateParams).then(() => {
            refreshDataFromServer();
            closeModal();
        });

    };

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
                <Typography as='h1' variant='middle-title' fontWeight='600'
                            textAlign='center' >
                    {`Изменение ${!employee?.fio ? 'стандартной' : ''} ставки`}
                </Typography>
                <Box margin='12px 0 0 0'>
                    <Typography variant='min-title' fontWeight='normal' textAlign='center'>
                        {`${service.name}${employee?.fio ? ' - ' + employee.fio : ''}`}
                    </Typography>
                </Box>
            <Box margin='39px 0 0 0'>
                <Label {...commonLabelOption}
                       labelText='Ставка'
                       align='leftTop'
                       isRequired
                       marginLabel='8px 10px 0 0'>

                    <TextField
                        {...commonTextFieldOption}
                        value={laboratoryServiceRate}
                        widthInput='250px'
                        isNumber
                        onChange={setLaboratoryServiceRate}

                    />
                </Label>
                <Label {...commonLabelOption}
                       isRequired={true}
                       labelText='Месяц применения'
                       align='leftTop'
                       marginLabel='8px 10px 0 0'
                >
                    <Select value={months.find((month) => selectedMonth.month === +month.value)}
                            placeholder='Месяц'
                            options={months}
                            onChange={(option: PMonths) => setSelectedMonth(prevState => (
                                {...prevState, month: +option.value}
                            ))
                            }
                    />
                    <TextField value={selectedMonth.year}
                               placeholder='Год'
                               onChange={(value: string) => {
                                   value.length < 5 && setSelectedMonth(prevState => (
                                       {...prevState, year: +value.replace(/[_]/gi, '')}
                                   ));
                               }
                               }
                               textAlignInput='center'
                               widthInput='80px'
                               mask='9999'
                               margin='0 0 0 15px'
                    />
                </Label>

            </Box>
            <Label labelText='' {...commonLabelOption}>
                <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                    <Button children='Сохранить'
                            size='md'
                            loader={loader}
                            variant={
                                laboratoryServiceRate && String(selectedMonth.year).length === 4 ?
                                    'success' :
                                    'disabled'}
                            onClick={saveLaboratoryServiceRate}
                    />
                </Box>
            </Label>
        </Box>
    );
};

export default LaboratoryRatesMark;
