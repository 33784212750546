import * as React from 'react';
import Avatar from "../elements/Avatar";
import {positionTooltip} from "../types/elements";

interface PDoctorWrap {
    data?: any,
    doctors?: any,
    handleTooltip?: (() => void) | any,
    selectedCell?: any,
    chairId?: any,
    heightWrap?: any,
    transformWrap?: any,
    renderResizable?: any
}

const DoctorWrap = (props: PDoctorWrap) => {
    const {data, doctors, handleTooltip, selectedCell, chairId, heightWrap, transformWrap, renderResizable} = props;
    let activeClass = false;
    let children = null;
    let className = '';

    if (selectedCell !== null && data.doctorId !== 'conflict') {
        activeClass = (selectedCell.chairId + '_' + selectedCell.data.timeStart === (chairId + '_' + data.timeStart));
    }

    if (data.doctorTime !== undefined && data.doctorId !== 'cookie') {
        if (data.doctorId !== 'conflict') {
            children = <Avatar doctor={doctors[data.doctorId]} handleTooltip={handleTooltip}/>;
            className = `${activeClass && 'cell__doctor-wrap--active'}`;
        } else {
            children = <ConflictChildren data={data} doctors={doctors} handleTooltip={handleTooltip}/>;
            className = 'cell__doctor-wrap--conflict';
        }
    } else {
        return null;
    }

    return (
        <>
            <span className={`cell__doctor-wrap ${className}`}
                  style={{
                      height: heightWrap + 'px',
                      transform: `translateY(${transformWrap}px)`
                  }}
                  children={children}
            />
            {activeClass && renderResizable()}
        </>
    )
};

const ConflictChildren = ({data, doctors, handleTooltip}: any) => {
    const avatars = <div className="avatars">
        {data.conflictDoctorsId.map((doctorId: any) => {
            return <Avatar doctor={doctors[doctorId]} handleTooltip={handleTooltip}/>
        })}
    </div>;

    const onMouseEnter = (e: any) => {
        const bounding = e.currentTarget.getBoundingClientRect();
        const position: positionTooltip = {
            x: bounding.x,
            y: bounding.y
        };
        const text = ['Чтобы изменить график этого кресла переключитесь на чётную или нечётную неделю'];
        handleTooltip(position, text)
    };

    const onMouseLeave = () => handleTooltip({x: 0, y: 0}, null);

    return (
        <>
            <div className="cell__conflict-desc">
                Расписание чётной и нечётной недели отличается
                <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        ?
                </span>
            </div>
            {avatars}
        </>
    )
};

export default DoctorWrap;
