import React, {useMemo, useRef} from "react";
import {prepareCellDesc, preparePlanItems} from "../helperStages";
import _ from "underscore";
import PlanItemDescription from "./PlanItemDescription";
import {StagedPlanItem} from "../../../containers/TreatmentPlan/types";
import {ObjServicesGroup} from "../typesStages";
import {PlanItemsStatuses} from "../../../containers/TreatmentPlansConstructor/types";
import {PerformerWrap} from "../styledStages";
import {DropTargetMonitor, useDrop, XYCoord} from "react-dnd";
import {DragStage} from "./ItemStages";

interface PPerformer {
    planItems: StagedPlanItem[],
    doctor: any,
    servicesGroups: ObjServicesGroup,
    stageId: number,
    itemsStatuses: PlanItemsStatuses | null,
    doctorIndex: number,
    moveCard?: ((dragIndex: number, hoverIndex: number) => void) | any,
    isDrag?: boolean,
    setIsDrag?: any,
    stageIndex: number
}

export default function Performer({planItems, doctor, servicesGroups, stageId, itemsStatuses, doctorIndex, moveCard, isDrag, setIsDrag, stageIndex} : PPerformer) {
    const ref = useRef<HTMLDivElement>(null);
    let doctorId = null as any;
    const cellDesc = prepareCellDesc(itemsStatuses);

    const [, drop] = useDrop({
        accept: 'card',
        hover(elem: DragStage, monitor) {
            if (stageIndex !== elem.index) return;
            if (elem.typeDrag === 'doctor' && !!moveCard) {
                const dragIndex = elem.doctorIndex as number;
                const hoverIndex = doctorIndex as number;
                // Не заменяем предметы собой
                if (dragIndex === hoverIndex) return;
                // Определяем прямоугольник на экране
                const hoverBoundingRect = ref.current?.getBoundingClientRect() as any;
                // Получаем вертикальную середину
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                // Определяем положение мыши
                const clientOffset = monitor.getClientOffset();
                // Выводим пиксели наверх
                const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
                // Выполнять перемещение только тогда, когда мышь пересекла половину высоты элемента
                // При перетаскивании вниз двигайтесь только тогда, когда курсор находится ниже 50%
                // При перетаскивании вверх двигайтесь только тогда, когда курсор находится выше 50%
                //
                // Перетаскивание вниз
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
                // Перетаскивание вверх
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
                // Время на самом деле выполнить действие
                moveCard(dragIndex, hoverIndex);
                // Примечание: здесь мы изменяем элемент монитора!
                // Обычно лучше избегать мутаций,
                // но здесь полезно для повышения производительности,
                // чтобы избежать дорогостоящего поиска по индексу.
                elem.doctorIndex = hoverIndex;
            }
        }
    });

    const memoPlanItems = useMemo(() => preparePlanItems(planItems, doctor, servicesGroups), [planItems]);

    return <PerformerWrap ref={drop}>
        <span ref={ref}>
            {memoPlanItems.map(item => {
                const doctor = _.clone(item.doctor);
                doctor.isHidden = true;
                if (doctorId !== doctor.id) {
                    doctor.isHidden = false;
                    doctorId = item.doctor.id;
                }

                item.doctor = doctor;
                return <PlanItemDescription item={item}
                                            cellDesc={cellDesc}
                                            key={item.itemKey}
                                            stageId={stageId}
                                            itemsStatuses={itemsStatuses}
                                            isDrag={isDrag}
                                            setIsDrag={setIsDrag}
                                            stageIndex={stageIndex}
                                            doctorIndex={doctorIndex}/>
            })}
        </span>
    </PerformerWrap>
}
