import React, {useMemo} from 'react';
import {setDoctorFilter} from "../../../../../redux/actions/scheduleActions";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {useOptionsDoctors} from "../hooks/useOptionsDoctors";
import {useDispatch, useSelector} from "react-redux";

const SelectedDoctors = () => {
    const dispatch = useDispatch();
    const filter = useSelector(state => state.schedule.filter);
    const optionsDoctors = useOptionsDoctors();
    const valueDoctor = useMemo(() => {
        const result = optionsDoctors.find((doctor: any) => doctor.value === filter.doctorId);
        if (!result) {
            dispatch(setDoctorFilter(optionsDoctors[0].value))
        }
        return !!result ? result : optionsDoctors[0]
    }, [JSON.stringify(filter.branches), filter.doctorId, filter.speciality])
    return (
        <Select options={optionsDoctors}
                variant="colored"
                placeholder="Врач"
                value={valueDoctor}
                onChange={(e: any) => dispatch(setDoctorFilter(e.value))}
                isDisabled={optionsDoctors.length <= 2}
        />
    );
};

export default SelectedDoctors;
