import React from "react";
import {ReactSVG} from "react-svg";

interface PropsSVG {
    name: string
    wrapper?: 'div' | 'span',
    onClick?: any
    className?: string
}


/**
 * @deprecated Используй Icon
 */
export default function SVG({name, wrapper = 'div', onClick, className}: PropsSVG) {
    const defaultProps = {wrapper} as PropsSVG;
    if (!!onClick) defaultProps.onClick = onClick;
    if (!!className) defaultProps.className = className;

    return <ReactSVG src={`/svg/${name}.svg`} {...defaultProps}/>
}
