import React, {useState} from 'react';
import {Editor} from '@tiptap/core';
import Button from '../../../../../../components-ui/Inputs/Button/Button';

interface Props {
    editor: Editor
}
const BubbleMenuInsideComponent = ({editor}: Props) => {
    const [isEditTableVisible, setIsEditTableVisible] = useState(false);

    return <>
        {!isEditTableVisible ?
            <Button isIcon variant='default'
                    onClick={()=> setIsEditTableVisible(true)}
            >
                <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 44 44'
                     fill='none'>
                    <g>
                        <path
                            d='M16.4999 12.8334H10.9999C10.0275 12.8334 9.09483 13.2197 8.40719 13.9073C7.71956 14.5949 7.33325 15.5276 7.33325 16.5V33C7.33325 33.9725 7.71956 34.9051 8.40719 35.5928C9.09483 36.2804 10.0275 36.6667 10.9999 36.6667H27.4999C28.4724 36.6667 29.405 36.2804 30.0926 35.5928C30.7803 34.9051 31.1666 33.9725 31.1666 33V27.5'
                            stroke='black' strokeWidth='2.75' strokeLinecap='round'
                            strokeLinejoin='round' />
                        <path
                            d='M19 24H23.7025L37.0261 9.97512C37.6497 9.31872 38 8.42844 38 7.50015C38 6.57185 37.6497 5.68157 37.0261 5.02517C36.4025 4.36876 35.5567 4 34.6749 4C33.793 4 32.9472 4.36876 32.3236 5.02517L19 19.05V24Z'
                            stroke='black' strokeWidth='2.75' strokeLinecap='round'
                            strokeLinejoin='round' />
                        <path d='M30 8L34 12' stroke='black' strokeWidth='2.75'
                              strokeLinecap='round' strokeLinejoin='round' />
                        <path d='M14 14V35' stroke='black' strokeWidth='2.75'
                              strokeLinecap='round' strokeLinejoin='round' />
                        <path d='M8 20H13' stroke='black' strokeWidth='2.75' strokeLinecap='round'
                              strokeLinejoin='round' />
                    </g>

                </svg>
            </Button>
            :
            <>
                <Button variant='standard' size='sm-standard'
                        onClick={() => editor.chain().focus().addColumnBefore().run()}>
                    Добавить колонку перед
                </Button>
                <Button variant='standard' size='sm-standard'
                        onClick={() => editor.chain().focus().addColumnAfter().run()}>Добавить колонку
                    после
                </Button>
                <Button variant='standard' size='sm-standard'
                        onClick={() => editor.chain().focus().deleteColumn().run()}>Удалить колонку</Button>
                <Button variant='standard' size='sm-standard'
                        onClick={() => editor.chain().focus().addRowBefore().run()}>Добавить строку перед
                </Button>
                <Button variant='standard' size='sm-standard'
                        onClick={() => editor.chain().focus().addRowAfter().run()}>Добавить строку после
                </Button>
                <Button variant='standard' size='sm-standard' onClick={() => editor.chain().focus().deleteRow().run()}>Удалить
                    строку</Button>
                <Button variant='standard' size='sm-standard'
                        onClick={() => editor.chain().focus().deleteTable().run()}>Удалить таблицу</Button>
                <Button variant='standard' size='sm-standard'
                        onClick={() => editor.chain().focus().toggleHeaderColumn().run()}>
                    Сделать колонку заглавной
                </Button>
                <Button variant='standard' size='sm-standard'
                        onClick={() => editor.chain().focus().toggleHeaderRow().run()}>
                    Сделать строку заглавной
                </Button>
                {/*<button onClick={() => editor.chain().focus().toggleHeaderCell().run()}>
                            toggleHeaderCell
                        </button>*/}
                <Button variant='standard' size='sm-standard'
                        onClick={() => editor.chain().focus().mergeOrSplit().run()}>
                    Соединить / разъеденить ячейки
                </Button>
                {/*<button onClick={() => editor.chain().focus().setCellAttribute('colspan', 2).run()}>
                            setCellAttribute
                        </button>*/}
                {/* <button onClick={() => editor.chain().focus().fixTables().run()}>fixTables</button>*/}
            </>
        }
    </>;
};

export default BubbleMenuInsideComponent;
