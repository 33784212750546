import React from 'react'
import PropTypes from 'prop-types'
import GroupsTree from "./GroupsTree";
import Table from "../Table/Table";
import Helper from "common/helpers/main";
import './TableWithGroupsTree.scss'
import GroupPopup from "./GroupPopup";
import ItemPopup from "./ItemPopup";
import Modal from "../../containers/DialogsManager/containers/Modal/Modal";
import DeletePopup from "./DeletePopup"
import ButtonGroup from "./ButtonsGroup";
import Filter from "./Filter";

export default class TableWithGroupsTree extends React.Component {
    static propTypes = {
        groups: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        columns: PropTypes.array.isRequired,
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
        selectedRows: PropTypes.array,
        sortGroups: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
        searchPlaceholder: PropTypes.string,
        selectMode: PropTypes.string,
        editMode: PropTypes.bool,
        measureVariants: PropTypes.object,
        withFilter: PropTypes.bool,
        filterDate: PropTypes.object,
        scrollHeight: PropTypes.number,
        cases: PropTypes.object,
        itemFields: PropTypes.array,
        groupFields: PropTypes.array,
        itemGroup: PropTypes.array,
        nestedItemFields: PropTypes.array,
        methods: PropTypes.object,
        getDisabledTip: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

        onSelect: PropTypes.func,
        modifyCellContent: PropTypes.func,
        prepareDataItem: PropTypes.func,
        prepareDataNestedItem: PropTypes.func,
        getRowGroupId: PropTypes.func,
        getRowCode: PropTypes.func,
        getNestedRowCode: PropTypes.func,
        getRowEnabled: PropTypes.func,

        clearSelection: PropTypes.func,
        updateData: PropTypes.func,
        onFilterDateChange: PropTypes.func,
        onFilterReset: PropTypes.func,
        prepareClonedItem: PropTypes.func
    };

    static defaultProps = {
        selectMode: 'multiple',
        editMode: false,
        getRowCode: item => item.id,
        getNestedRowCode: nestedItem => nestedItem.id,
        sortGroups: true,
        withFilter: false,
        onSelect: () => null
    };

    state = {
        searchQuery: '',
        selectedGroupId: 0,
        openedPopupType: '',
        popupCode: '',
        focusedElement: null,
        clonedRow: null
    };


    render() {

        let foundItems = null,
            shownItems = null,
            data = Helper.clone(this.props.data);

        data = Array.isArray(data) ? data : Object.values(data);

        /* --- фильтрация элементов по выбранной группе и поисковой фразе --- */

        if (this.state.searchQuery.length > 0) {
            foundItems = this.filterRowsBySearch(data);

            shownItems = this.state.selectedGroupId === 0 ? foundItems : foundItems.filter((foundItem) => {
                return foundItem.groupId === this.state.selectedGroupId
            });
        } else {
            foundItems = data;
            shownItems = foundItems.filter((foundItem) => {
                return foundItem.groupId === this.state.selectedGroupId
            })
        }

        return (
            <div className="table-with-groups">
                <div className="table-with-groups__header">
                    {this.state.openedPopupType && this.renderPopup()}
                    <Filter withFilter={this.props.withFilter}
                            onFilterDateChange={this.props.onFilterDateChange}
                            filterDate={this.props.filterDate}
                            searchPlaceholder={this.props.searchPlaceholder}
                            searchQuery={this.state.searchQuery}
                            onChangeSearchQuery={this.handleSearchQuery}
                            handleResetBtn={this.handleResetBtn}/>
                </div>
                <div className="table-with-groups__wrapper">
                    <GroupsTree
                        isFocused={!!this.state.focusedElement && this.state.focusedElement.type === 'group'}
                        groups={this.getGroupsList(foundItems)}
                        onSelect={this.handleSelectGroup}
                        selectedGroupId={this.state.selectedGroupId}
                        scrollHeight={this.props.scrollHeight}
                        sort={this.props.sortGroups}
                    />
                    <Table columns={this.props.columns}
                           data={shownItems}
                           selectMode={this.props.selectMode}
                           selectedRows={this.props.selectedRows}
                           isEditable={true}
                           scrollHeight={this.props.scrollHeight - 20}
                           focusedElement={this.getTableFocusedElement()}

                           modifyCellContent={this.props.modifyCellContent}
                           prepareDataItem={this.props.prepareDataItem}
                           prepareDataNestedItem={this.props.prepareDataNestedItem}
                           getRowGroupId={this.props.getRowGroupId}
                           getRowCode={this.props.getRowCode}
                           getNestedRowCode={this.props.getNestedRowCode}
                           getRowEnabled={this.props.getRowEnabled}
                           getDisabledTip={this.props.getDisabledTip}
                           onSelect={this.handleRowClick}
                           onFocus={this.handleFocusItem}
                    />
                </div>
                <ButtonGroup editMode={this.props.editMode}
                             selectMode={this.props.selectMode}
                             btnVariant={!!this.state.focusedElement ? "default" : "disabled"}
                             cases={this.props.cases}
                             handleAddGroupBtn={this.handleAddGroupBtn}
                             handleAddItemBtn={this.handleAddItemBtn}
                             isEnabledUpdateBtn={this.isEnabledUpdateBtn}
                             handleUpdateBtn={this.handleUpdateBtn}
                             isEnabledCopyBtn={this.isEnabledCopyBtn}
                             handleCloneBtn={this.handleCloneBtn}
                             clonedRow={this.state.clonedRow}
                             handlePasteBtn={this.handlePasteBtn}
                             isEnabledDeleteBtn={this.isEnabledDeleteBtn}
                             handleDelBtn={this.handleDelBtn}/>
            </div>
        )
    }

    renderPopup() {
        const {cases, itemFields, groupFields, methods} = this.props;
        const {selectedGroupId} = this.state;
        let popup = null;
        const selectedGroup = this.getGroup(selectedGroupId);

        switch (this.state.openedPopupType) {
            case 'group':
                popup = <GroupPopup type={this.state.popupCode}
                                    closePopup={this.closePopup}
                                    updateData={this.props.updateData}
                                    selectedGroup={selectedGroup}
                                    cases={cases}
                                    fieldsValues={this.getPopupFieldsValues()}
                                    fields={groupFields}
                                    methods={this.props.methods}
                />;
                break;

            case 'item':
                popup = <ItemPopup type={this.state.popupCode}
                                   closePopup={this.closePopup}
                                   updateData={this.props.updateData}
                                   selectedGroup={selectedGroup}
                                   fieldsValues={this.getPopupFieldsValues()}
                                   cases={cases}
                                   fields={itemFields}
                                   methods={methods}
                />;
                break;

            case 'delete':
                popup = this.renderDeletePopup();
                break;
            default:
                break;
        }

        return (
            <Modal
                isOpen={true}
                closeModal={() => this.setState({openedPopupType: ''})}
                isIconClose={true}
            >
                {popup}
            </Modal>
        )
    }

    renderDeletePopup() {

        const {focusedElement} = this.state;
        const {methods} = this.props;

        let targetType;
        switch (this.state.focusedElement.type) {
            case 'group':
                targetType = 'группу';
                break;

            case 'item':
                targetType = this.props.cases.accusative;
                break;

            default:
                break;
        }

        const targetFields = this.getPopupFieldsValues();

        return (
            <DeletePopup
                targetId={targetFields.id}
                targetName={targetFields.name}
                targetAccusativeType={targetType}
                deleteMethod={(focusedElement.type === 'group') ? methods.deleteGroup : methods.deleteItem}
                close={this.closePopup}
                updateData={this.props.updateData}
            />
        );
    }

    handleSearchQuery = (value) => {
        this.setState({searchQuery: value, selectedGroupId: 0})
    }

    handleAddGroupBtn = () => {
        if (!this.state.openedPopupType) {
            this.setState({openedPopupType: 'group', popupCode: 'new'})
        }
    };

    handleAddItemBtn = () => {
        if (!this.state.openedPopupType) {
            this.setState({openedPopupType: 'item', popupCode: 'new'})
        }
    };

    isEnabledUpdateBtn = () => {
        return !!this.state.focusedElement && !this.state.focusedElement.isNested
    };

    isEnabledCopyBtn = () => {
        const {focusedElement} = this.state;
        return !!focusedElement && !focusedElement.isNested && focusedElement.type === 'item';
    };

    isEnabledDeleteBtn = () => {
        let {focusedElement} = this.state;
        return !!focusedElement && !focusedElement.isNested && (this.state.selectedGroupId || focusedElement.type === 'item');
    };

    handleUpdateBtn = () => {
        const {focusedElement} = this.state;
        this.setState({openedPopupType: focusedElement.type, popupCode: 'edit'});
    };

    getGroupsList(rows) {
        let groups = {};
        let rowsGroupsIds = {};

        rows.forEach((row) => rowsGroupsIds[row.groupId] = true);

        if (this.state.searchQuery.length) {
            groups = this.filterRowGroup(Helper.clone(Object.values(this.props.groups)), (group) => rowsGroupsIds[group.id] === true)
        } else {
            groups = Helper.clone(this.props.groups);
        }
        return groups;
    }

    getTableFocusedElement = () => {
        const {focusedElement} = this.state;

        if (!!focusedElement && (focusedElement.type === 'item')) {
            return {
                code: focusedElement.code,
                isNested: focusedElement.isNested
            };
        }

        return null;
    };

    filterRowGroup(groups, handler) {

        return groups.filter((group) => {
            if (group.groups) {
                group.groups = Helper.clone(this.filterRowGroup(group.groups, handler))
            }

            return (group.groups && group.groups.length > 0) || handler(group)
        })
    }

    filterRowsBySearch(data) {
        let searchQuery = this.state.searchQuery.toLowerCase();

        return data.filter((item) => {
            let isSuitable = false;

            this.props.columns.forEach(column => {
                if (column.searchable) {
                    if (String(item[column.code]).toLowerCase().indexOf(searchQuery) > -1) {
                        isSuitable = true
                    }
                }
            });
            return isSuitable
        });
    }

    handleRowClick = (selectedRows, selectedRow) => {
        this.props.onSelect(selectedRows, selectedRow)
    };

    getPopupFieldsValues() {
        const {focusedElement, selectedGroupId, openedPopupType} = this.state;

        if (focusedElement == null || this.state.popupCode === 'new') {
            return {}
        }
        if (this.state.popupCode === 'copy') {
            return this.state.clonedRow
        }
        if (openedPopupType === 'group' || (openedPopupType === 'delete' && focusedElement.type === 'group')) {
            return this.getGroup(selectedGroupId)
        } else {
            return this.getFocusedItem();
        }
    }

    getFocusedItem() {
        if (!this.state.focusedElement) return null;
        const {code} = this.state.focusedElement;

        const itemsMap = Helper.Array.toObject(this.props.data, this.props.getRowCode);
        return itemsMap[code];
    }

    handleCloneBtn = () => {
        const {prepareClonedItem} = this.props;
        const clonedRow = Helper.clone(this.getFocusedItem());

        if (prepareClonedItem) {
            prepareClonedItem(clonedRow);
        }

        this.setState({clonedRow})
    };

    handlePasteBtn = () => {
        if (!this.state.isPopupOpen) {
            this.setState({openedPopupType: 'item', popupCode: 'copy'})
        }
    };

    handleDelBtn = () => {
        if (!this.state.isPopupOpen) {
            this.setState({openedPopupType: 'delete', popupCode: 'delete'})
        }
    };

    handleResetBtn = () => {
        if (this.props.withFilter) {
            this.props.onFilterReset();
        }
        this.setState({searchQuery: ''})
    };

    handleSelectGroup = group => {
        this.setState({
            selectedGroupId: group.id,
            focusedElement: {
                type: 'group',
                code: group.id,
            },
            selectedGroup: group
        });

        if (!!this.props.clearSelection && this.props.selectMode === 'single') {
            this.props.clearSelection()
        }
    };

    handleFocusItem = ({code, isNested}) => {
        this.setState({
            focusedElement: {
                type: 'item',
                code,
                isNested,
            }
        });
    };

    getGroup(groupId) {
        let result = null;

        Helper.Group.forEach(this.props.groups, (group) => {
            if (groupId === group.id) {
                result = group;
                return false;
            }
        });

        return result
    }

    closePopup = () => {
        this.setState({openedPopupType: ''});
    }
}
