import React from 'react';
import TaskContent from "../TaskContent";
import {TaskCallCenter} from "../../../../types/common";
import {PlannedVisitData} from "../../../../types/taskData";
import {PatientWishes, UniquePatient} from "../../../../components/Labels";
import {FinalButton, NoteDownButton} from "../../../../components/Buttons";
import {CommonTaskProps} from "../../types/common";

interface Props extends CommonTaskProps {
    task: TaskCallCenter<PlannedVisitData>
}

export const PlannedVisit = ({task, uniqPatient, infoCallCenter, onChangeSelectedScript, ...props}: Props) => {
    return (
        <TaskContent onChangeSelectedScript={onChangeSelectedScript} buttons={<Buttons task={task} {...props}/>}
                     task={task}>
            <UniquePatient task={task} uniqPatient={uniqPatient}/>
            <PatientWishes task={task} />
        </TaskContent>
    );
};

const Buttons = ({
                     task,
                     validate,
                     blockedButtons,
                     methodsCallCenter
                 }: Omit<Props, 'uniqPatient' | 'infoCallCenter' | 'onChangeSelectedScript'>) => {
    return (
        <>
            <NoteDownButton validate={validate} task={task} blockedButtons={blockedButtons}
                            methodsCallCenter={methodsCallCenter}/>
            <FinalButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
        </>
    )
}
