import React from "react";
import {InputWrap, SLabelTextField} from "./textFieldStyled";
import {StyledInput} from "../styledInput";
import {ITextField} from "./typesTextField";
import PasswordToggle from "./elements/PasswordToggle/PasswordToggle";
import SearchIcon from "./elements/SearchIcon/SearchIcon";
import Autocomplete from "./elements/Autocomplete/Autocomplete";
import ResetIcon from "./elements/ResetIcon/ResetIcon";
import useTextField from "./hooks/useTextField";

const TextField = React.forwardRef((
    {
        isPassword = false,
        isSearch,
        isReset,
        isNumber,
        isDisabled = false,
        isTextArea = false,
        isFilter,
        autoFocus = false,
        min,
        max,
        size = 'md',
        autoComplete = "disabled",
        onClickReset,
        dataAutocomplete,
        widthAutocomplete,
        heightAutocomplete,
        onClickItemAutocomplete,
        onChange,
        placeholder,
        value,
        label,
        nameInput,
        onFocus,
        mask,
        margin,
        widthInput,
        setFocus,
        additionalContent,
        ...props
    }: ITextField, ref: any) => {

    const propsUseTextField = {
        setFocus, widthInput, nameInput, onFocus, isNumber, isTextArea, mask, dataAutocomplete, min, max, onChange
    }

    const {wrapRef, handleChange, onClickInputWrap, inputProps, password, popup} = useTextField(propsUseTextField)

    return (
        <InputWrap widthInput={widthInput} ref={wrapRef} margin={margin}>
            {!!label && <SLabelTextField children={label}/>}
            <StyledInput {...inputProps}
                         {...props}
                         size={size}
                         autoFocus={autoFocus}
                         autoComplete={autoComplete}
                         placeholder={isNumber && typeof placeholder === 'undefined' ? '0 руб.' : placeholder}
                         type={password.passwordVisible ? 'password' : 'text'}
                         ref={ref}
                         disabled={isDisabled}
                         isSearch={isSearch}
                         isTextArea={isTextArea}
                         isAutoComplete={!!dataAutocomplete}
                         value={
                             isNumber
                                 ? String(value).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
                                 : value
                         }
                         isLabel={!!label}
                         onChange={handleChange}
                         onClick={onClickInputWrap}/>
            <PasswordToggle isPassword={isPassword} {...password}/>
            <SearchIcon isSearch={isSearch}/>
            <ResetIcon isReset={isReset} onClickReset={onClickReset}/>
            {!!additionalContent && additionalContent}
            <Autocomplete dataAutocomplete={dataAutocomplete}
                          widthAutocomplete={widthAutocomplete}
                          heightAutocomplete={heightAutocomplete}
                          onClickItemAutocomplete={onClickItemAutocomplete}
                          {...popup} onChange={onChange}
            />
        </InputWrap>
    )
})

export default TextField;
