import React from 'react';
import {useSelector} from "react-redux";
import Switch from "../../../../components-ui/Inputs/Switch/Switch";

interface PropsSwitch {
    isMarketing: boolean
    handleIsMarketing: (isMarketing: boolean) => void
    marketingNoticeQuantity: number
}

export const SwitchMarketing = ({isMarketing, handleIsMarketing, marketingNoticeQuantity}: PropsSwitch) => {
    const user = useSelector(state => state.user);
    const isMarketingAdmin = user.role === 'marketing-admin';
    return (
        <Switch checked={isMarketing}
                onChange={handleIsMarketing}
                fontSize="15px"
                children={`${isMarketingAdmin ? "Не маркетинговые" : "Маркетинговые"} (${marketingNoticeQuantity})`}
                labelPosition="left"/>
    )
}

