import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {IModalEditScript} from '../../../types';
import {servicesTypeForLongAbsenceArr} from '../constansts';
import {callCenterTasksInfo} from '../../../../../../../helper/getDataCallCenter';
import {useModal} from '../../../../../../../../../containers/DialogsManager/containers/Modal/Modal';
import useLoader from '../../../../../../../../../components/Errors';
import ServerCommand from '../../../../../../../../../common/server/server-command';
import {ScriptGroupList, ScriptType} from '../../../../../../../types/common';
import formatSelectionRules from './formatSelectionRules';
import {OptionSelect} from '../../../../../../../../../components-ui/Inputs/Select/typesSelect';

interface Props {
    selectedScript: ScriptType | null;
    text: string;
    groupsScript: {[p: string]: ScriptGroupList[]};
    refreshData: () => void;
}


const useModalEditScript = ({selectedScript, text, groupsScript, refreshData}: Props) => {

    const directions = useSelector(state => state.common.directions);

    const serviceTypes = useSelector(state => state.common.serviceTypes);

    const postponeTreatment = useSelector(state => state.common.postponeTreatment);

    const directionsOptions = _.map(directions, ({name, code}) => ({value: code, label: name}));

    const serviceTypesOptions = _.map(serviceTypes, ({name, id}) => ({value: id, label: name}));

    const postponeTreatmentOptions = _.map(postponeTreatment, ({value, label}) => ({value, label}));

    const [validationCheck, setValidationCheck] = useState(false);

    const groupsOptions = _.map([...groupsScript.marketing, ...groupsScript.noMarketing], (group) => ({
        value: group.id, label: group.name,
    }));

    const taskTypesOptions = _.map(callCenterTasksInfo, ({name}, key) => ({value: key, label: name}));

    const {closeModal} = useModal();

    const [loader, setLoader] = useLoader();


    const [state, setState] = useState<IModalEditScript>({
        name: selectedScript?.name || '',
        text,
        marketing: selectedScript?.marketing || false,
        groupId: selectedScript?.groupId || 0,
        priority: selectedScript?.priority || null,
        taskTypes: null,
        selectionRules: null,
    });

    const [servicesForLongAbsence, setServicesForLongAbsence] = useState<string>(servicesTypeForLongAbsenceArr[0]);

    useEffect(() => {
        if (selectedScript?.id)
            ServerCommand
                .get('call-center/script/detail', {id: selectedScript.id})
                .then(({taskTypes, selectionRules}) => {
                    const prevSelectionRules = formatSelectionRules({
                        selectionRules,
                        postponeTreatmentOptions,
                        serviceTypesOptions,
                        directionsOptions,
                    });
                    setState(prevState => ({
                        ...prevState,
                        taskTypes,
                        selectionRules: prevSelectionRules,
                    }));
                    setServicesForLongAbsence(servicesTypeForLongAbsenceArr[
                        !!Object.keys(selectionRules || {}).find(val => val === 'undoneServices') ? 1 : 0
                        ]);
                });
    }, []);

    const data = () => {
        const formattedSelectionRules = _.mapValues(state.selectionRules, (val, key) => {
            switch (key) {
                case 'undoneServices':
                case 'doneServices':
                    return _.mapValues(val, (val) => val.map((value: OptionSelect) => value.value));
                case 'postponeTreatmentReason':
                    return val.value
                case 'hasUndoneServices':
                    return val
                default:
                    return val.map((value: OptionSelect) => value.value);
            }
        });

        if (selectedScript) return {...state, id: selectedScript.id, selectionRules: formattedSelectionRules};
        return {
            ...state, selectionRules: formattedSelectionRules,
        };
    };

    const onClickSave = () => {
        setLoader(true);
        ServerCommand.post(`call-center/script/${selectedScript ? 'edit' : 'add'}`, data(), () => {
            closeModal();
            refreshData();
        }).then();
    };

    return {
        directionsOptions,
        serviceTypesOptions,
        postponeTreatmentOptions,
        state,
        setState,
        servicesForLongAbsence,
        setServicesForLongAbsence,
        validationCheck,
        setValidationCheck,
        groupsOptions,
        taskTypesOptions,
        loader,
        onClickSave,
    };
};

export default useModalEditScript;
