import React from 'react';
import Box from "../../../components-ui/Layout/Box";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";

const NoReport = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="70px">
            <Typography variant="middle-title" children="За указанный период данные отсутствуют" />
        </Box>
    );
};

export default NoReport;
