import {Dispatch, SetStateAction, useCallback} from "react";
import {StateReports} from "../../../types";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";

interface Props {
    setState: Dispatch<SetStateAction<StateReports>>
}

const useHandlers = ({setState}: Props) => {
    return {
        handleStartDate: useCallback((startDate: string | Date) => {
            setState(prevState => ({...prevState, startDate}))
        }, [setState]),

        handleEndDate:  useCallback((endDate: string | Date) => {
            setState(prevState => ({...prevState, endDate}))
        }, [setState]),

        handleDoctor: useCallback((selectedDoctor: OptionSelect) => {
            setState(prevState => ({...prevState, selectedDoctor}))
        }, [setState]),

        handleBranch: useCallback((selectedBranch: OptionSelect) => {
            setState(prevState => ({...prevState, selectedBranch}))
        }, [setState])
    }
};

export default useHandlers;