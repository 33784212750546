import {useMemo, useRef} from "react";

const useHeightHeader = () => {
    const refHeader = useRef<HTMLElement>(null);

    const heightHeader = useMemo(() => {
        if (!!refHeader.current) {
            return Math.round(refHeader.current.getBoundingClientRect().height) + 'px'
        } else {
            return '45px'
        }
    }, [refHeader.current])

    return {
        refHeader,
        heightHeader
    }

}

export default useHeightHeader
