import styled from "styled-components";
import {PButton} from "./types";

export const DentalRecordsWrap = styled.div.attrs({className: 'DentalRecords-wrap'})`
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 24px;
    justify-content: space-between;
    & > * {
        flex-shrink: 0;
    }
`;

export const WrapButton = styled.div.attrs({className: 'wrap-button-dental-records'})<PButton>`
    display: flex;
    flex-direction: ${props => props.jaw === 'upper' ? 'column-reverse' : 'column'};
    height: 105px;
    width: 34px;
    align-items: center;
`

export const Button = styled.button.attrs({type: "button", className: 'button-dental-records'})<PButton>`
    display: flex;
    flex-direction: ${(props) => props.jaw === 'upper' ? 'column' : 'column-reverse'};
    justify-content: center;
    align-items: flex-start;
    width: 34px;
    height: 84px;
    border-radius: 6px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: solid 1px;

    &:focus {
        outline: none;
    }

    .tooth-card-svg {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: ${(props) => props.jaw === 'upper' ? 'flex-end' : 'flex-start'};
            & > svg {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .tooth-card-text {
        width: 100%;
        height: 16px;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: center;
        margin-top: ${(props) => props.jaw !== 'upper' ? '7px' : '1px'};
        margin-bottom: ${(props) => props.jaw !== 'upper' ? '1px' : '7px'};
    };

`

