import React, {forwardRef} from 'react';
import {WrapIcon} from '../../../containers/WrapIcon';
import {IIcon} from '../../../types/common';

export const NotifyIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 19.64 24"} ref={ref}>
            <path
                d="M18.88,18.88a3.56,3.56,0,0,1-1.13-1.49,17,17,0,0,1-1.17-6.84v0a6.61,6.61,0,0,0-4-6V3A2.64,2.64,0,0,0,9.93.36H9.71A2.63,2.63,0,0,0,7.05,3V4.53a6.6,6.6,0,0,0-4,6,17,17,0,0,1-1.18,6.84A3.56,3.56,0,0,1,.75,18.88a.67.67,0,0,0-.37.76.71.71,0,0,0,.7.53H6.21a3.61,3.61,0,0,0,7.21,0h5.13a.72.72,0,0,0,.71-.53A.68.68,0,0,0,18.88,18.88ZM8.45,3A1.24,1.24,0,0,1,9.71,1.73h.22A1.25,1.25,0,0,1,11.19,3V4.09A6.92,6.92,0,0,0,9.82,4a6.84,6.84,0,0,0-1.37.14V3ZM9.82,22.27a2.19,2.19,0,0,1-2.21-2.1H12a2.18,2.18,0,0,1-2.2,2.1Zm2.82-3.46h-10q.18-.28.36-.63a16.83,16.83,0,0,0,1.42-7.63A5.3,5.3,0,0,1,9.82,5.32a5.3,5.3,0,0,1,5.36,5.23v0a16.92,16.92,0,0,0,1.41,7.61c.12.23.25.44.37.63Z"/>
            <path
                d="M9.82,24a4,4,0,0,1-3.95-3.47H1.08A1.08,1.08,0,0,1,0,19.72a1,1,0,0,1,.59-1.17h0a3.45,3.45,0,0,0,1-1.32,16.43,16.43,0,0,0,1.14-6.68,7,7,0,0,1,4-6.25V3a3,3,0,0,1,3-3h.22a3,3,0,0,1,3,3V4.3a7,7,0,0,1,4,6.22,16.63,16.63,0,0,0,1.14,6.71,3.7,3.7,0,0,0,1,1.34,1,1,0,0,1,.55,1.15,1.07,1.07,0,0,1-1.06.81H13.76A4,4,0,0,1,9.82,24ZM9.71.73A2.27,2.27,0,0,0,7.41,3V4.77l-.21.09a6.24,6.24,0,0,0-3.78,5.69,17.31,17.31,0,0,1-1.21,7A4,4,0,0,1,1,19.19c-.18.09-.25.22-.21.37a.35.35,0,0,0,.34.25H6.57v.35a3.24,3.24,0,0,0,6.48,0v-.35h5.49a.35.35,0,0,0,.35-.25.3.3,0,0,0-.17-.35l0,0a3.93,3.93,0,0,1-1.26-1.64,17.15,17.15,0,0,1-1.22-7,6.27,6.27,0,0,0-3.77-5.69l-.22-.09V3A2.26,2.26,0,0,0,9.93.73Zm.11,21.9a2.56,2.56,0,0,1-2.57-2.45v-.37h5.15v.37A2.57,2.57,0,0,1,9.82,22.63ZM8,20.53a1.85,1.85,0,0,0,3.58,0Zm9.6-1.36H2l.37-.56a5.59,5.59,0,0,0,.35-.6A16.53,16.53,0,0,0,4.1,10.55,5.65,5.65,0,0,1,9.82,5a5.66,5.66,0,0,1,5.72,5.59A16.62,16.62,0,0,0,16.91,18c.12.22.24.42.35.6ZM3.32,18.44h13l-.05-.09a17.35,17.35,0,0,1-1.46-7.78,4.94,4.94,0,0,0-5-4.89,4.94,4.94,0,0,0-5,4.87,17.32,17.32,0,0,1-1.45,7.8A.41.41,0,0,0,3.32,18.44ZM8.09,4.53V3A1.6,1.6,0,0,1,9.71,1.37h.22A1.61,1.61,0,0,1,11.55,3V4.53l-.43-.08a6.33,6.33,0,0,0-2.6,0Zm1.73-.94a6.73,6.73,0,0,1,1,.07V3a.88.88,0,0,0-.89-.87H9.71a.89.89,0,0,0-.9.87v.7A6.86,6.86,0,0,1,9.82,3.59Z"/>
        </WrapIcon>
    );
});
