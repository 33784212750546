import React from "react";
import CallsList from "./containers/CallsList/CallsList";
import DetailedTask from "./containers/DetailedTask/DetailedTask";
import {SwitchMarketing} from "./components/SwitchMarketing";
import SearchFilter from "./components/SearchFilter";
import Scripts from "./components/Scripts";
import Filter from "../../containers/Tasks/containers/Filter/containers/Filter";
import useCalls from "./hooks/useCalls/useCalls";
import IncomingCallPanel from "./containers/IncomingCallPanel";
import LoaderPage from '../../components/LoaderPage';


const CallCenter = () => {
    const {
        refScrollList,
        state,
        setState,
        tasksInfo,
        infoCallCenter,
        marketingPlannedCalls,
        marketingNoticeQuantity,
        noticesQuantity,
        noticeQuantityTotal,
        groupsButtonName,
        propsScripts,
        methodsCallCenter,
        handlersState,
        refreshDataFromServer,
        loader
    } = useCalls()
    const {scriptsType, openScript, ..._propsScript} = propsScripts

    if (loader) return <LoaderPage/>

    if (Object.keys(infoCallCenter.types).length === 0) return null;


    return (
        <>
            {(!!scriptsType && openScript) && <Scripts {..._propsScript} refreshDataFromServer={refreshDataFromServer}/>}
            {!state.detail && (
                <Filter groupsButtonName={groupsButtonName}
                        noticesQuantity={noticesQuantity}
                        noticeQuantityTotal={noticeQuantityTotal}
                        tasksInfo={tasksInfo}
                        handleHeightFilter={handlersState.handleHeightFilter}
                        selectedFilter={state.selectedFilter}
                        handleSelectedFilter={handlersState.handleSelectedFilter}
                        headerRightComponent={<SwitchMarketing isMarketing={state.isMarketing}
                                                               handleIsMarketing={handlersState.handleIsMarketing}
                                                               marketingNoticeQuantity={marketingNoticeQuantity}/>}
                        footerComponent={<SearchFilter search={state.searchFilter}
                                                       handleSearchFilter={handlersState.handleSearchFilter}/>}
                        isMarketing={state.isMarketing}
                        optionsBranches={infoCallCenter.optionsBranches}
                        selectedBranches={state.branches}
                        handleBranches={handlersState.handleBranches}
                />
            )}
            {!state.detail && <CallsList state={state} setState={setState} methodsCallCenter={methodsCallCenter}
                                         infoCallCenter={infoCallCenter} tasksInfo={tasksInfo}
                                         marketingPlannedCalls={marketingPlannedCalls} refScrollList={refScrollList}
                                         onChangeSelectedScript={_propsScript.onChangeSelectedScript}/>}
            {!!state.detail && <DetailedTask state={state} setState={setState} methodsCallCenter={methodsCallCenter}
                                             infoCallCenter={infoCallCenter} tasksInfo={tasksInfo}
                                             setScriptsType={_propsScript.setScriptsType}
                                             onChangeSelectedScript={_propsScript.onChangeSelectedScript}/>}
            <IncomingCallPanel marketingPlannedCalls={marketingPlannedCalls} setState={setState}
                               panelCalls={state.panelCalls}/>
        </>

    )
}

export default CallCenter
