import React from 'react';
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import Box from "../../../components-ui/Layout/Box";
import {Label} from "../../../components-ui/DataDisplay/Label";
import Select from "../../../components-ui/Inputs/Select/Select";
import Button from "../../../components-ui/Inputs/Button/Button";
import useSetHandsetUser from "./hooks/useSetHandsetUser";
import {commonLabelOption} from "./data";
import ValidationLinkInfo from "../../../components/ValidationLinkInfo/ValidationLinkInfo";

interface Props {
    userId: number
}

const SetHandsetUser = ({userId} : Props) => {

    const {
        loader,
        warning,
        state,
        setState,
        handsetsOptions,
        handleSubmit
    } = useSetHandsetUser({userId})

    return (
        <Box padding="30px 27px 69px" minWidth="549px">
            <Typography children="Выберите используемую трубку" textAlign="center" variant="bigger-title" margin="25px 0 59px"/>

            <Label labelText="Трубка" {...commonLabelOption}>
                <Select
                    options={handsetsOptions}
                    value={handsetsOptions.find(({value}) => state.handsetId === value)}
                    onChange={(value) => setState(prevState => ({
                        ...prevState,
                        handsetId: value.value
                    }))}
                />
            </Label>

            <Label labelText='' {...commonLabelOption}>
                <Button
                    variant={state.handsetId !== undefined ? 'success': 'disabled'}
                    children='Выбрать'
                    loader={loader}
                    size="md" onClick={handleSubmit}/>
            </Label>

            <ValidationLinkInfo validate={!warning} onClickIsValidate={() => {}}
                                text={warning} linkText={""}/>
        </Box>
    )
}
export default SetHandsetUser
