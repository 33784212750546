import styled from "styled-components";
import {PSTab, PSTabAuxiliary, PSTabName, PSTabWrap} from "./typesTab";

export const SWrapTab = styled.span.attrs({className: 'SWrapTab'})<PSTabWrap>`
    position: relative;
    ${props => props.variant === 'list' && `
        width: 100%;
        margin-bottom: 4px;
        height: max-content;
        display: flex;
    `}
`

const paddingTab = (props: PSTab) => {
    const {variant, isNote, size} = props;
    switch (variant) {
        case "list":
            return '3px 25px 5px 12px'
        case "primary":
            switch (size) {
                case "lg":
                    return '2px 6px 6px';
                case "sm":
                    return '3px 10px 7px'
                case "default":
                default:
                    return '6px 34px 10px';
            }
        case "classic":
            return '6px 25px';
        case "buttons":
            return "3px 12px 5px"
        case "default":
        default:
            if (isNote) {
                return '16px 0 13px';
            } else {
                return '16px 0';
            }
    }
}

const marginTab = (props: PSTab) => {
    const {variant, noLeftMargin, isLastChild} = props;
    switch (variant) {
        case "default":
            if (noLeftMargin) {
                return '0 24px 0 0';
            } else {
                return '0 12px';
            }
        case "classic":
            if (isLastChild) {
                return '0'
            } else {
                return '0 8px 0 0'
            }
        case "primary":
        default:
            return '0';

    }
}

const colorTab = (props: PSTab) => {
    const {theme, variant, disabled, activeTab} = props;
    switch (variant) {
        case "list":
            if (activeTab) {
                return theme?.colors.tabListColor
            } else {
                return theme?.colors.textDarkGray
            }
        case "classic":
            return theme?.colors.textDarkGray;
        case "buttons":
            if (activeTab) {
                return theme?.colors.white;
            } else {
                return theme?.colors.info;
            }
        case "primary":
            if (activeTab) {
                return theme?.colors.white;
            } else {
                return theme?.colors.text;
            }
        case "default":
        default:
            if (activeTab || disabled) {
                return theme?.colors.text;
            } else {
                return theme?.colors.info;
            }
    }
}

const hoverColorTab = (props: PSTab) => {
    const {theme, variant, disabled, activeTab} = props;
    switch (variant) {
        case "list":
            if (activeTab) {
                return theme?.colors.tabListColor
            } else {
                return theme?.colors.textDarkGray;
            }
        case "classic":
            return theme?.colors.textDarkGray;
        case "buttons":
            if (activeTab) {
                return theme?.colors.white;
            } else {
                return theme?.colors.info;
            }
        case "primary":
            if (activeTab) {
                return theme?.colors.white;
            } else {
                return theme?.colors.text;
            }
        case "default":
        default:
            if (activeTab || disabled) {
                return theme?.colors.text;
            } else {
                return theme?.colors.danger;
            }
    }
}

const backgroundTab = (props: PSTab) => {
    const {theme, variant, activeTab} = props;
    switch (variant) {
        case "list":
            if (activeTab) {
                return theme?.colors.tabListBg
            } else {
                return theme?.colors.white
            }
        case "classic":
            if (activeTab) {
                return theme?.colors.white;
            } else {
                return theme?.gradient.default;
            }
        case "buttons":
            if (activeTab) {
                return theme?.colors.info;
            } else {
                return theme?.colors.white;
            }
        case "primary":
            if (activeTab) {
                return theme?.colors.info;
            } else {
                return theme?.gradient.default;
            }
        case "default":
        default:
            return 'transparent'
    }
}

const hoverBackgroundTab = (props: PSTab) => {
    const {theme, variant, disabled, activeTab} = props;
    switch (variant) {
        case "list":
            if (activeTab) {
                return theme?.colors.tabListBg
            } else {
                return theme?.colors.white
            }
        case "classic":
            if (activeTab) {
                return theme?.colors.white;
            } else {
                return theme?.gradient.defaultHover;
            }
        case "buttons":
            if (activeTab) {
                return theme?.colors.info;
            } else {
                return theme?.colors.whiteDark;
            }
        case "primary":
            if (activeTab) {
                return theme?.colors.info;
            } else {
                return theme?.gradient.defaultHover;
            }
        case "default":
        default:
            return 'transparent'
    }
}

const boxShadowTab = (props: PSTab) => {
    const {theme, variant, activeTab} = props;
    if (variant === "classic") {
        if (activeTab) {
            return 'none'
        } else {
            return theme?.shadow.tabShadow;
        }
    } else {
        return 'none'
    }
};

const minWidthTab = (props: PSTab) => {
    const {variant, widthTab} = props;
    if (variant === "primary") {
        if (!!widthTab) {
            return widthTab;
        } else {
            return '125px'
        }
    } else {
        return 'auto'
    }
};

const borderRadiusTab = (props: PSTab) => {
    const {variant, isLastChild, isFirstChild} = props;
    switch (variant) {
        case "list":
            return '4px 0 0 4px'
        case "classic":
            return '4px 4px 0 0';
        case "primary":
            if (isFirstChild) {
                return '3px 0 0 3px';
            } else if (isLastChild) {
                return '0 3px 3px 0';
            } else {
                return '0';
            }
        case "buttons":
            return '2px'
        case "default":
        default:
            return '0';
    }
};

const borderColorTab = (props: PSTab) => {
    const {variant, activeTab, theme} = props;
    if (variant === 'primary') {
        if (activeTab) {
            return '#366ed8'
        } else {
            return theme?.colors.borderGray;
        }
    } else {
        return 'none'
    }
};

const fontSize = (props: PSTab) => {
    const {variant, size} = props;
    if (variant === 'list') {
        return '12px'
    } else if(variant === 'buttons') {
        return '14px'
    } else {
        switch (size) {
            case "lg":
                return '15px'
            default:
                return '16px'
        }
    }
}

const lineHeight = (props: PSTab) => {
    const {variant} = props;
    switch (variant) {
        case "default":
            return '1.25'
        case "list":
            return '1.33'
        case "buttons":
            return '1.43'
        default:
            return 'normal'
    }
}

const widthTab = (props: PSTab) => {
    const {widthTab, variant} = props;
    if (!!widthTab) {
        return widthTab
    } else {
        if (variant === "list") {
            return '100%'
        } else {
            return 'max-content'
        }
    }
}

export const STab = styled.div.attrs({className: 'STab'})<PSTab>`

display: inline-block;
font-size: ${fontSize};
font-weight: ${props => props.variant === 'default' ? '600' : 'normal'};
font-style: normal;
font-stretch: normal;
line-height: ${lineHeight};
letter-spacing: normal;
padding: ${paddingTab};
margin: ${marginTab};
cursor: ${props => props.activeTab ? 'default' : 'pointer'};
pointer-events: ${props => props.disabled ? 'none' : 'auto'};
text-align: ${props => props.variant === 'list' ? 'left' : 'center'};
color: ${colorTab};
background: ${backgroundTab};
box-shadow: ${boxShadowTab};
min-width: ${minWidthTab};
width: ${widthTab};
outline: none;
border: ${props => props.variant === 'primary' ? '1px solid' : 'none'};
border-left: ${props => props.variant === 'primary' && props.isFirstChild ? '1px solid' : 'none'};
border-radius: ${borderRadiusTab};
border-color: ${borderColorTab};
${props => props.variant === 'list' && `
position: relative;
z-index: 1;
transition: transform .2s;
&::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 100%;
    }
`}

&:hover {
    color: ${hoverColorTab};
    background: ${hoverBackgroundTab};
    ${props => props.variant === 'list' && !props.activeTab && `
        transform: translateX(-10px);
        &::before{
        right: -10px;
        }
    `}
}

&:focus {
    outline: none;
}
`;

export const STabAuxiliary = styled.span.attrs({className: 'STabAuxiliary'})<PSTabAuxiliary>`
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 100%;
    background: ${(props: any) => backgroundTab(props)};
    z-index: 0;
`
export const STabAuxiliaryShadow = styled.span.attrs({className: 'STabAuxiliaryShadow'})`
    width: 7px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -7px;
    background: transparent;
    box-shadow: -4px 0 3px -3px rgba(72, 85, 110, 0.4);
    z-index: 2;
`

export const STabNote = styled.div.attrs({className: 'STabNote'})`
font-size: 12px;
font-weight: normal;
font-stretch: normal;
font-style: italic;
line-height: 1.33;
letter-spacing: normal;
margin-top: 3px;
text-align: left;
`;

export const STabChild = styled.div.attrs({className: 'STabChild'})`
    display: flex;
    align-items: center;
`;

export const STabWarning = styled.span.attrs({className: 'STabWarning'})`
display: inline-block;
background-color: ${props => props.theme.colors.danger};
width: 6px;
height: 6px;
border-radius: 50%;
margin-left: 10px;
margin-top: 23px;
`

export const STabName = styled.span.attrs({className: 'STabName'})<PSTabName>`
${props => props.variant === 'default' && `
        position: relative;
        display: flex;
        align-items: center;
        &::before {
            content: '';
            position: absolute;
            bottom: ${props.isNote ? '-31px' : '-17px'} ;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: ${props.activeTab ? props.theme.colors.info : 'transparent'} ;
        }
`}
.tab-arrow-select {
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    cursor: pointer;
    fill: ${props => props.theme.colors.textDarkGray};
    transition: all .3s;
    &:hover {
        fill: ${props => props.theme.colors.text};
    }
    & > div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > svg {
            width: 100%;
            height: 100%;
        }
    }
    &--active {
        & {
            transform: rotate(-180deg);
        }
    }
}
`

export const ButtonDel = styled.button.attrs({type: "button"})`
    width: 12px;
    height: 12px;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    margin-left: 12px;
    svg {
        width: 8px;
        height: 8px;
        fill: ${props => props.theme.colors.danger};
    }
`;

export const WrapList = styled.div.attrs({className: 'WrapList'})`
    position: absolute;
    top: 110%;
    left: 0;
    z-index: 11;
    min-width: 250px;
    box-shadow: ${props => props.theme.shadow.defaultModalLight};
    .scrollarea {
        border-radius: 3px;
    }
    .tab-replace-color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 15px;
    }
    ul > li {
        border-bottom: 1px solid ${props => props.theme.colors.borderGray};
        &:last-child {
            border-bottom: none;
        }
    }
    ul > li > a {
        display: flex;
        align-items: center;
    }
`;
