import React from "react";
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {buttonTitles} from "../../helper/getDataCallCenter";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Box from "../../../../components-ui/Layout/Box";
import {CommonButtonProps} from "../../types/components";

interface Props extends CommonButtonProps {
    blockedButtons: boolean
}

export const RefuseButton = ({task, blockedButtons, margin, methodsCallCenter}: Props) => {
    const {refuseVisit} = methodsCallCenter
    return (
        <Box className="button-task-content" margin={margin}>
            <Button {...commonPropsButton} variant={blockedButtons ? 'disabled' : "danger"}  children="отказ"
                    title={blockedButtons ? buttonTitles.blocked : buttonTitles.refuse}
                    onClick={() => refuseVisit(task.payload.recordId)}/>
        </Box>
    )
}
