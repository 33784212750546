import React from 'react';
import {commonLabelOption, commonTextFieldOption,} from '../../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import TextField from '../../../../../../components-ui/Inputs/TextField/TextField';
import { Label } from '../../../../../../components-ui/DataDisplay/Label';

interface Props {
    rate: string
    changeRate: (val: string) => void
}

const MarkRate = ({rate, changeRate}: Props) => {
    return (
        <Label {...commonLabelOption}
               isRequired={true}
               labelText="Ставка"
               align="leftTop"
               marginLabel="8px 10px 0 0">
            <TextField{...commonTextFieldOption}
                      isNumber={true}
                      min={0}
                      isError={!rate}
                      value={rate}
                      onChange={changeRate}
                />
        </Label>
    );
};

export default MarkRate;
