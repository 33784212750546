import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";

export const columns = [
    {
        title: 'Пациент',
        code: 'patient',
        containsGroupTitle: true
    },
    {
        title: 'Рекомендованный врач',
        code: 'recommendationDoctor',
    },
    {
        title: 'Сколько осталось',
        code: 'remainingSum',
        type: 'price'
    },
    {
        title: 'Оплачено',
        code: 'paidSum',
        type: 'price',
        countTotal: true
    }
];

export const allDoctors: OptionSelect = {
    value: 0,
    label: 'Все врачи',
    color: '#fff'
}
