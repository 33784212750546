import React from "react"
import PropTypes from 'prop-types'
import Button from "../../components-ui/OldButton/Button";
import ServerCommand from "../../common/server/server-command";
import Counter from "../../components-ui/Inputs/Counter/Counter";
import Checkbox from "../../components-ui/Inputs/Checkbox/Checkbox";
import MultipleEnumField from "./MultipleEnumField";

export default class GroupPopup extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        closePopup: PropTypes.func,
        updateData: PropTypes.func.isRequired,
        selectedGroup: PropTypes.object,
        cases: PropTypes.object,
        fields: PropTypes.array,
        fieldsValues: PropTypes.object.isRequired,
        methods: PropTypes.object
    }

    state = {
        selectedGroup: null,
        values: this.getInitialValues(),
    }

    render() {
        let btnText = 'Добавить',
            btnHandle = this.saveNewGroup.bind(this),
            popupHeader = 'Новая группа',
            popupSubheader = ['Размещение новой группы в группу', <br/>, `«${this.props.selectedGroup.name}»`]

        if (this.props.type === 'edit') {
            btnText = 'Сохранить'
            btnHandle = this.editGroup.bind(this)
            popupHeader = 'Редактирование группы'
        }

        const fields = this.getFields();

        return (
            <div className="table-with-groups__popup">
                <p className="table-with-groups__popup-header">{popupHeader}</p>
                <div className="popup__content">
                    <div className="popup__add-group">
                        {this.props.type === 'new' &&
                        <p className="popup__subheader">{popupSubheader}</p>
                        }

                        {fields.map(field => this.renderFieldInput(field))}

                        <div className="popup__btns">
                            <Button variant={this.validate(fields) ? 'success' : 'disabled'}
                                    size="long"
                                    text={btnText}
                                    onClick={btnHandle}/>
                            <Button variant="default"
                                    size="long"
                                    text="Отменить"
                                    onClick={this.props.closePopup}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    saveNewGroup() {
        const data = Object.assign({
            groupId: this.props.selectedGroup.id
        }, this.state.values);

        ServerCommand
            .post(this.props.methods.addGroup, data)
            .then(this.props.closePopup)
            .then(this.props.updateData);
    }

    editGroup() {
        const data = Object.assign({
            id: this.props.fieldsValues.id
        }, this.state.values);

        ServerCommand
            .post(this.props.methods.updateGroup, data)
            .then(this.props.closePopup)
            .then(this.props.updateData);
    }

    renderFieldInput(field) {
        const value = this.state.values[field.name];

        if (field.render) {
            return field.render({
                value,
                onChange: value => this.saveFieldValue(field, value)
            })
        }

        let input;
        let displaySeparateLabel = true;

        switch (field.type) {
            case 'string':
                input = (
                    <input className="popup__input"
                           value={value}
                           onChange={e => this.saveFieldValue(field, e.target.value)}
                    />
                );
                break;

            case 'integer':
            case 'smallint':
                input = (
                    <Counter
                        value={value}
                        onChange={newValue => this.saveFieldValue(field, newValue)}
                    />
                );
                break;

            case 'boolean':
                input = (
                    <div className="popup__input-wrapper">
                        <Checkbox label={field.title}
                                  checked={value}
                                  onChange={newValue => this.saveFieldValue(field, newValue)}
                        />
                    </div>
                );
                displaySeparateLabel = false;
                break;

            case 'multiple_enum':
                input = (
                    <MultipleEnumField
                        value={value}
                        options={field.variants}
                        onChange={selectedValues => this.saveFieldValue(field, selectedValues)}
                    />
                );
                break;

            default: {
                input = null;
            }
        }

        if (input && displaySeparateLabel && !field.dontWrap) {
            return (
                <label className="popup__input-wrapper">
                    <div className="popup__label">{field.title}</div>
                    {input}
                </label>
            )
        } else {
            return input;
        }
    }

    saveFieldValue(field, value) {
        this.setState(state => {
            state.values[field.name] = value;
            return state;
        })
    }

    validate(fields) {
        const {values} = this.state;

        return fields.every(field => {
            if (!field.required) return true;

            return !!values[field.name];
        });
    }

    getInitialValues() {
        const values = {};
        const {fieldsValues} = this.props;

        this.getFields().forEach(field => {
            let value;

            if (field.name in fieldsValues) {
                value = fieldsValues[field.name];
            } else {
                value = field.defaultValue;
            }

            values[field.name] = value;
        })

        return values;
    }

    getFields() {
        let {fields} = this.props;

        if (!fields) {
            fields = [{name: 'name', title: 'Название', type: 'string', required: true}];
        }

        return fields;
    }
}
