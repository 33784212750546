import React, {forwardRef} from 'react';
import { WrapIcon } from '../../../containers/WrapIcon';
import { IIcon } from '../../../types/common';

export const HelpIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M9.16,0a9.18,9.18,0,0,1,9.16,8.77A7.77,7.77,0,0,1,24,16.24a7.69,7.69,0,0,1-1.08,4L24,24l-3.78-1a7.69,7.69,0,0,1-4,1.08,7.77,7.77,0,0,1-7.47-5.68,9.22,9.22,0,0,1-4.26-1.26L0,18.29l1.24-4.47A9.17,9.17,0,0,1,9.16,0Zm9.11,10.22a9.2,9.2,0,0,1-8,8,6.36,6.36,0,0,0,9.48,3.3L20,21.4,22,22l-.55-2,.17-.26a6.36,6.36,0,0,0-3.3-9.48ZM9.16,1.41A7.77,7.77,0,0,0,2.62,13.34l.17.26-.74,2.68,2.68-.74.26.17A7.76,7.76,0,1,0,9.16,1.41ZM9.87,12.7v1.41H8.46V12.7ZM9.16,4.22a2.81,2.81,0,0,1,1.9,4.89L9.87,10.2v1.1H8.46V9.58l1.65-1.51a1.38,1.38,0,0,0,.46-1A1.41,1.41,0,1,0,7.76,7H6.35A2.81,2.81,0,0,1,9.16,4.22Z"/>
        </WrapIcon>
    );
});
