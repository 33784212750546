import styled from "styled-components";
import {PropsTag} from "./Tag";

export const STag = styled.div.attrs({className: 'STag'})<PropsTag>`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    padding: 1px 12px 3px;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.textDarkGray};
    width: max-content;
    margin: ${props => !!props.margin ? props.margin : 0};
`
