import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Money = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 18"} ref={ref}>
            <path d="M1.2,17.5a.66.66,0,0,1-.7-.62V1.13A.66.66,0,0,1,1.2.5H22.8a.66.66,0,0,1,.7.63V16.88a.66.66,0,0,1-.7.62Zm.7-4.62h.5a3,3,0,0,1,3.1,2.87v.5h13v-.5a3,3,0,0,1,3.1-2.87h.5V5.13h-.5a3,3,0,0,1-3.1-2.88v-.5H5.5v.5A3,3,0,0,1,2.4,5.13H1.9ZM12,13A4.16,4.16,0,0,1,7.7,9,4.16,4.16,0,0,1,12,5a4.16,4.16,0,0,1,4.3,4A4.16,4.16,0,0,1,12,13Zm0-6.75A2.83,2.83,0,0,0,9.1,9,2.83,2.83,0,0,0,12,11.75,2.83,2.83,0,0,0,14.9,9,2.83,2.83,0,0,0,12,6.25Z"/>
            <path d="M22.8,1c.12,0,.2.07.2.13V16.88c0,.05-.08.12-.2.12H1.2c-.12,0-.2-.07-.2-.12V1.13c0-.06.08-.13.2-.13H22.8M1.4,13.38h1A2.5,2.5,0,0,1,5,15.75v1H19v-1a2.5,2.5,0,0,1,2.6-2.37h1V4.63h-1A2.5,2.5,0,0,1,19,2.25v-1H5v1A2.5,2.5,0,0,1,2.4,4.63h-1v8.75M12,5.5A3.66,3.66,0,0,1,15.8,9,3.66,3.66,0,0,1,12,12.5,3.66,3.66,0,0,1,8.2,9,3.66,3.66,0,0,1,12,5.5m0,6.75A3.34,3.34,0,0,0,15.4,9,3.34,3.34,0,0,0,12,5.75,3.34,3.34,0,0,0,8.6,9,3.34,3.34,0,0,0,12,12.25M22.8,0H1.2A1.16,1.16,0,0,0,0,1.13V16.88A1.16,1.16,0,0,0,1.2,18H22.8A1.16,1.16,0,0,0,24,16.88V1.13A1.16,1.16,0,0,0,22.8,0ZM6,15.75a3.49,3.49,0,0,0-3.6-3.37V5.63A3.5,3.5,0,0,0,6,2.25H18a3.5,3.5,0,0,0,3.6,3.38v6.75A3.49,3.49,0,0,0,18,15.75ZM12,4.5A4.66,4.66,0,0,0,7.2,9,4.66,4.66,0,0,0,12,13.5,4.66,4.66,0,0,0,16.8,9,4.66,4.66,0,0,0,12,4.5Zm0,6.75A2.33,2.33,0,0,1,9.6,9,2.33,2.33,0,0,1,12,6.75,2.33,2.33,0,0,1,14.4,9,2.33,2.33,0,0,1,12,11.25Z"/>
        </WrapIcon>
    );
});
