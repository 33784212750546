import React, {Dispatch, forwardRef, SetStateAction, useRef} from 'react';
import Helper from "../../../../../../../common/helpers/main";
import {
    GetPostponedTreatmentConsultationsParams, GetPostponedTreatmentConsultationsResult,
    StateConsultations
} from "../../types";
import ServerCommand from "../../../../../../../common/server/server-command";
import Box from "../../../../../../../components-ui/Layout/Box";
import Paper from "../../../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Table from "../../../../../../../components-ui/DataDisplay/Table";
import Typography from "../../../../../../../components-ui/DataDisplay/Typography/Typography";
import useOutsideClick from "../../../../../../../common/hooks/useOutsideClick";
import useColumnsFailed from "./hooks/useColumnsFailed";
import Loader from "../../../../../../../components-ui/Feedback/Loader/Loader";
import {useDialogsManager} from "../../../../../../../containers/DialogsManager/DialogsManager";
import useError from "../../hooks/useError";
import getPositionOffsets from "../../utils/getPositionOffsets";
import Tooltip from "../../../../../../../components-ui/DataDisplay/Tooltip/Tooltip";
import {useSelector} from "react-redux";

interface Props {
    childrenCell: string | number
    refRow: any
    refTable: any
    state: StateConsultations
    row: {
        [key: string]: any
    }
    startDate: string
    endDate: string
    setSubTable: Dispatch<SetStateAction<any>>
    selectedBranches: number[]
}

const LedToTreatment = ({childrenCell, refTable, refRow, state, row, startDate, endDate, setSubTable, selectedBranches}: Props) => {
    const userBranches = useSelector(state1 => state1.user.branches)
    const refSubTable = useRef(null) as any;
    const {slideModals} = useDialogsManager()

    useOutsideClick(refSubTable, () => {
        if (!Object.values(slideModals).some(modal => !modal.isMinimized)) {
            setSubTable(null)
        }
    }, [slideModals])

    const subColumns = useColumnsFailed()

    useError({setSubTable})

    return (
        <>
            <Typography children={row.postponed}
                        as="span"
                        cursor="pointer"
                        color="info"
                        variant="small"
                        isLink={true}
                        data-for={`row-tooltip-${row.name}-${childrenCell}`}
                        data-tip="Количество пациентов, отложивших лечение"
                        onClick={() => {
                            if (!!refRow && !!refRow.current && !!refTable && !!refTable.current) {
                                const positionOffsets = getPositionOffsets(refRow, refTable)
                                const data = {
                                    startDate: Helper.Date.formatDateForServer(startDate),
                                    endDate: Helper.Date.formatDateForServer(endDate),
                                    branches: !!selectedBranches.length ? selectedBranches : userBranches
                                } as GetPostponedTreatmentConsultationsParams
                                if (state.currentTab === 'doctors') {
                                    data.doctorId = row.name
                                } else {
                                    data.direction = row.name
                                }

                                setSubTable((
                                    <ContentLedToTreatment positionOffsets={positionOffsets} ref={refSubTable}>
                                        <Loader visible={true} />
                                    </ContentLedToTreatment>
                                ))


                                ServerCommand.get('analytics/postponed-treatment-consultations', data).then((response: GetPostponedTreatmentConsultationsResult) => {
                                    setSubTable((
                                        <ContentLedToTreatment positionOffsets={positionOffsets} ref={refSubTable}>
                                            <Table columns={subColumns}
                                                   rows={response.postponedTreatmentConsultations}
                                                   variantTable="outline"
                                                   maxHeight="343px"
                                                   isHovering={false}
                                                   fontSizeRow="14px" />
                                        </ContentLedToTreatment>
                                    ))
                                })
                            }
                        }}
            />
            <Tooltip id={`row-tooltip-${row.name}-${childrenCell}`} place="bottom"
                     effect="float" isArrow={true}/>
        </>

    );
};

interface PropsContent {
    positionOffsets: any
    children: any
}

const ContentLedToTreatment = forwardRef(({positionOffsets, children}: PropsContent, ref: any) => {

    return (
        <Box position="absolute" zIndex={17} positionOffsets={positionOffsets} ref={ref}>
            <Paper minWidth="780px" isHidden={false} padding="24px" minHeight={85}>
                {children}
            </Paper>
        </Box>
    )
})

export default LedToTreatment;
