import React from "react";
import {WrapContent} from "../../../styledUI";
import {Chip} from "../../../../../components-ui/DataDisplay/Chip";

export default function ChipPage() {
    return (
        <>
            <h1>Chip</h1>
            <WrapContent>
                <div>
                    <Chip onClickIcon={() => console.log('deleted')} children="Средний кариес зуба (К02.1)" />
                    <pre>
                        {
                            '<Chip onDeleted={() => console.log(\'deleted\')} children="Средний кариес зуба (К02.1)" />'
                        }
                    </pre>
                </div>
                <div>
                    <Chip children="Средний кариес зуба (К02.1)" bg="purple" color="white" />
                    <pre>
                        {
                            '<Chip children="Средний кариес зуба (К02.1)" bg="purple" color="white" />'
                        }
                    </pre>
                </div>
                <div>
                    <Chip onClickIcon={() => console.log('ok')} children="Средний кариес зуба (К02.1)" iconName="checked" colorIcon="success" />
                    <pre>
                        {
                            '<Chip onClickIcon={() => console.log(\'ok\')} children="Средний кариес зуба (К02.1)" iconName="checked" colorIcon="success" />'
                        }
                    </pre>
                </div>
            </WrapContent>
        </>
    )
}
