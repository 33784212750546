import React, {useEffect, useRef, useState} from "react";
import TextField from "../../../../../../../components-ui/Inputs/TextField/TextField";
import {usePatientField} from "../../../../PatientField";
import {useComposite} from "../EditComposite";
import Helper from "../../../../../../../common/helpers/main";
import useWidthInput from "../../../../../hooks/useWidthInput";
import Checkbox from "../../../../../../../components-ui/Inputs/Checkbox/Checkbox";
import {IPatientField} from "../../../../../types/fields";

export default function PhoneEdit() {
    const {composite, onChangeField, onChangeValidationItem, value} = usePatientField()
    const {subField, index} = useComposite()
    const field = !!composite ? composite[subField].field : {} as IPatientField
    const widthSelect = useWidthInput(field)
    const [check, setCheck] = useState(!!value.additionalPhone)

    if (!!composite) {
        return (
            <>
                {field.name === 'additionalPhone' && (
                    <Checkbox label="Дополнительный номер"
                              checked={check}
                              margin={check ? "5px 0 10px" : "5px 0 0"}
                              onChange={checked => {
                                  setCheck(checked)
                                  if (!checked) {
                                      onChangeField('', 'additionalPhone')
                                  }
                              }}/>
                )}
                {(field.name !== 'additionalPhone' || check) && (
                    <TextField value={value[subField]}
                               autoFocus={index === 0}
                               isError={composite[subField].error}
                               mask={field.mask}
                               widthInput={widthSelect}
                               nameInput={composite[subField].field.name}
                               onChange={value => {
                                   onChangeField(Helper.Phone.formatServer(value), subField)
                                   if (!!onChangeValidationItem) onChangeValidationItem(subField)
                               }}
                    />
                )}
            </>
        )
    } else {
        return null
    }
}
