import React, {Dispatch, SetStateAction} from "react";
import {Divider} from "../../../../../../components-ui/DataDisplay/Divider";
import useAllDeadline from "../../../../hooks/useAllDeadline";
import HeaderExtendedCall from "./components/HeaderExtendedCall";
import InfoExtendedCall from "./components/InfoExtendedCall";
import FooterExtendedCall from "./components/FooterExtendedCall";
import PostponeCall from "../../../../components/PostponeCall/PostponeCall";
import TasksContent from "../../../TasksContent/TasksContent";
import {Call, MethodsCallCenter, OnChangeSelectedScript, PlannedCall, TaskCallCenter} from "../../../../types/common";
import {StateCallCenter} from "../../../../types/components";
import {TasksInfo} from "../../../../../../containers/Tasks";
import {SContainerWrap} from "../../../../../../containers/Tasks/style";
import {InfoCallCenter} from "../../../../types/hooks";

interface Props {
    plannedCall: PlannedCall
    setState: Dispatch<SetStateAction<StateCallCenter>>
    uniqType: TaskCallCenter[]
    tasksInfo: TasksInfo
    uniqPatient: TaskCallCenter[]
    setZIndex: Dispatch<SetStateAction<number>>
    methodsCallCenter: MethodsCallCenter
    calls: Call[]
    infoCallCenter: InfoCallCenter
    onChangeSelectedScript: OnChangeSelectedScript
    callIndex: number
}

const ExtendedCall = (
    {
        plannedCall,
        uniqPatient,
        uniqType,
        setZIndex,
        setState,
        tasksInfo,
        methodsCallCenter,
        calls,
        infoCallCenter,
        onChangeSelectedScript,
        callIndex
    }: Props) => {

    const allDeadline = useAllDeadline(plannedCall.tasks);
    const isPostponedTo = plannedCall.tasks.some(task => !!task.postponedTo);

    return (
        <SContainerWrap isDeadline={allDeadline} isPostponedTo={isPostponedTo} isOutgoing={plannedCall.outgoing}>
            <HeaderExtendedCall plannedCall={plannedCall} uniqType={uniqType} uniqPatient={uniqPatient}/>
            <Divider/>
            <InfoExtendedCall plannedCall={plannedCall} uniqPatient={uniqPatient}/>
            <Divider/>
            <PostponeCall plannedCall={plannedCall} setZIndex={setZIndex} calls={calls} callIndex={callIndex}
                          postponeServerCommand={methodsCallCenter.postponeServerCommand} tasksInfo={tasksInfo}/>
            <Divider/>
            <TasksContent tasks={plannedCall.tasks} personalPhone={plannedCall.phone} uniqPatient={uniqPatient}
                          allDeadline={allDeadline} methodsCallCenter={methodsCallCenter}
                          infoCallCenter={infoCallCenter} onChangeSelectedScript={onChangeSelectedScript}/>
            <Divider/>
            <FooterExtendedCall plannedCall={plannedCall} setState={setState}/>
        </SContainerWrap>
    )
}

export default ExtendedCall
