import React from "react";
import {useWAP} from "../../WaitingAndPlanned";
import DoctorFormWAP from "./components/DoctorFormWAP";
import DirectionsFormWAP from "./components/DirectionsFormWAP";
import CommentFormWAP from "./components/CommentFormWAP";
import TimeFormWAP from "./components/TimeFormWAP";
import DateFormWAP from "./components/DateFormWAP";
import BranchesWAP from "./components/BranchesWAP";
import {useSelector} from "react-redux";

export default function FormWAP() {
    const {activeTab} = useWAP()
    const branches = useSelector(state => state.common.branches)
    return (
        <>
            {(activeTab === "waiting-list" && branches.length > 1) && <BranchesWAP/>}
            <DoctorFormWAP/>
            <DirectionsFormWAP/>
            {activeTab === "waiting-list" ? <TimeFormWAP/> : <DateFormWAP/>}
            <CommentFormWAP/>
        </>
    )
}
