import {Props} from "../types";

export const getLineHeight = (props: Props) => {
    const {lineHeight, currentAs} = props;
    if (!!lineHeight) {
        return lineHeight
    } else {
        switch (currentAs) {
            case "span":
                return 'inherit'
            case "h1":
            case "h3":
                return '1.2'
            case "h2":
                return '1.14'
            case "h4":
                return '1.33'
            default:
                return 'normal'
        }
    }
}
