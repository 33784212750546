import React, {Dispatch, SetStateAction} from "react";
import TopProgressCall from "./components/TopProgressCall";
import ThemeProgressCall from "./components/ThemeProgressCall";
import InfoProgressCall from "./components/InfoProgressCall";
import RelativesProgressCall from "./components/RelativesProgressCall";
import FooterProgressCall from "./components/FooterProgressCall";
import Paper from "../../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import {StateCallCenter} from "../../../../types/components";
import {IncomingCallPatient} from "../../../../types/taskData";
import {StateDetailed} from "../../hooks/useDetailed";
import {OptionSelect} from "../../../../../../components-ui/Inputs/Select/typesSelect";
import {MethodsCallCenter} from "../../../../types/common";
import {SContainerWrap} from "../../../../../../containers/Tasks/style";

interface Props {
    state: StateCallCenter
    patient: IncomingCallPatient | null
    detailedState: StateDetailed
    setDetailedState: Dispatch<SetStateAction<StateDetailed>>
    callerTypes: OptionSelect[]
    directionsNames: OptionSelect[]
    methodsCallCenter: MethodsCallCenter
    futureComment: string
    setFutureComment: Dispatch<SetStateAction<string>>
    validate: boolean
}

const ProgressCall = ({
                          state,
                          patient,
                          detailedState,
                          callerTypes,
                          setDetailedState,
                          directionsNames,
                          methodsCallCenter,
                          futureComment,
                          setFutureComment,
                          validate,
                      }: Props) => {
    return (
        <Paper margin="0 0 12px" isHidden={false} zIndex={12}>
            <SContainerWrap>
                <TopProgressCall detailedState={detailedState} state={state} patient={patient} callerTypes={callerTypes}
                                 setDetailedState={setDetailedState}/>
                <ThemeProgressCall detailedState={detailedState} directionsNames={directionsNames}
                                   setDetailedState={setDetailedState}/>
                <InfoProgressCall methodsCallCenter={methodsCallCenter} patient={patient} futureComment={futureComment}
                                  setFutureComment={setFutureComment} validate={validate}/>
                <RelativesProgressCall patient={patient}/>
                <FooterProgressCall methodsCallCenter={methodsCallCenter} patient={patient} state={state}/>
            </SContainerWrap>
        </Paper>
    )
}

export default ProgressCall
