import React, {useMemo} from 'react';
import {Article} from "../types";
import FixedArticle from "../content/FixedArticle";
import Box from "../../../components-ui/Layout/Box";
import useWindowScroll from "../../../common/hooks/useWindowScroll";
import {useDevice} from "../../../common/hooks/useDevice";
import FixedArticleMobile from "../content/FixedArticleMobile";
import {FixedArticlesWrapper} from "../style";

export interface Props {
    articles: Article[]
}

const FixedArticles = ({articles}: Props) => {

    const device = useDevice()

    const scroll = useWindowScroll(170)

    return useMemo(() => {
        return (
            scroll ?
                <Box position="fixed"
                     width="100vw"
                     zIndex={1000}
                     positionOffsets={{top: "0", left: "0"}}
                >
                    <FixedArticlesWrapper>
                        {articles.map((article, key) => device !== "mobile" ?
                            <FixedArticle key={article.id + key} article={article}/>
                            :
                            <FixedArticleMobile key={article.id + key} article={article}/>
                        )}
                    </FixedArticlesWrapper>
                </Box> :
                <></>
        )

    }, [scroll])

};

export default FixedArticles;