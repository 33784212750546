import React from 'react';
import {ManagerTask} from "../../types/common";
import {ReducedConsultationsEfficiencyPayload} from "../../types/taskData";
import {Date, Efficiency} from "../Labels";
import {STaskList} from "../../../../containers/Tasks/style";

interface Props {
    task: ManagerTask<ReducedConsultationsEfficiencyPayload>
    renderBranches: () => React.ReactNode | null
}

const ReducedConsultationsEfficiency = ({task, renderBranches}: Props) => {
    return (
        <STaskList padding="19px 0 16px">
            {renderBranches()}
            <Date date={task.payload.date}/>
            <Efficiency efficiencyPercent={task.payload.efficiencyPercent} />
        </STaskList>
    );
};

export default ReducedConsultationsEfficiency;
