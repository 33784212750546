import {SelectionRulesType} from '../../../types';
import _ from 'lodash';
import {OptionSelect} from '../../../../../../../../../components-ui/Inputs/Select/typesSelect';

interface Props {
    selectionRules?: SelectionRulesType;
    directionsOptions: OptionSelect[];
    serviceTypesOptions: OptionSelect[];
    postponeTreatmentOptions: OptionSelect[];
}

const formatSelectionRules = ({
                                  selectionRules,
                                  postponeTreatmentOptions,
                                  directionsOptions,
                                  serviceTypesOptions,
                              }: Props) => {
    if (!selectionRules) return null;

    return _.mapValues(selectionRules, (val, key) => {
        switch (key) {
            case 'targetNot':
                return val.map((value: number) => ({value, label: value}));
            case 'notServicesDoneAfter':
                return directionsOptions.filter(({value}) => val.includes(value));
            case 'serviceTypes':
                return serviceTypesOptions.filter(({value}) => val.includes(value) || val.includes(String(value)));
            case 'serviceDirections':
                return directionsOptions.filter(({value}) => val.includes(value));

            case 'postponeTreatmentReason':
                return postponeTreatmentOptions.filter(({value}) => val === value);
            case 'hasUndoneServices':
                return val;
            case 'undoneServices':
            case 'doneServices':
                return _.mapValues(val, (val, key) => {
                    switch (key) {
                        case 'targetNot':
                            return val.map((val: number) => ({value: val, label: val}));
                        case 'notServicesDoneAfter':
                            return directionsOptions.filter(({value}) => val.includes(value));
                        case 'serviceTypes':
                            return serviceTypesOptions.filter(({value}) => val.includes(value) || val.includes(String(value)));
                        case 'serviceDirections':
                            return directionsOptions.filter(({value}) => val.includes(value));
                    }
                });
        }
    });
};

export default formatSelectionRules;
