import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth71Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 15.92 24"} width={width || "10"} height={height || "16"} ref={ref}>
            <path d="M10.89.38h-2A22.4,22.4,0,0,1,6.47.19C2.61-.19.94-.11.51,1.42-.67,6.15.51,12.71,1,14.56c1,4.51,2.53,7.33,4.8,8.61s3.75.76,4.18.55c4.37-2.14,6.07-13.56,6-18.93C15.81.35,15,.35,10.91.37h0Z"/>
        </WrapIcon>
    );
});
