import React from "react";
import {usePatientField} from "../containers/PatientField";
import CalendarField from "../../../components-ui/Inputs/CalendarField/CalendarField";
import useWidthInput from "../hooks/useWidthInput";
import { preparePersonalBirthday } from "../../EditableField/helper/prepareTextField";

export default function Date() {
    const {field, onChangeField, value, isEdit, error, onChangeValidationItem} = usePatientField();
    const widthCalendar = useWidthInput(field)
    let children = null;
    if (isEdit) {
        const validateCalendarField = {
            day: !!error,
            month: !!error,
            year: !!error,
        }
        children = <CalendarField value={value}
                                  widthCalendar={widthCalendar}
                                  validateField={validateCalendarField}
                                  autoFocus={false}
                                  onChange={valueField => {
                                      onChangeField(valueField, field.name)
                                      if (!!onChangeValidationItem) onChangeValidationItem(field.name)
                                  }}
                                  />
    } else {
        children = preparePersonalBirthday(value, field)
    }

    return (
        <>
            {children}
        </>
    )
}
