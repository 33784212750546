import React from 'react';
import Filter from "./components/Filter";
import ContentWidgetWrap from "../../../components/ContentWidgetWrap";
import {ReceiptsReport} from "../../../types";
import useReceipts from "./hooks/useReceipts";
import useSumsCodes from "./hooks/useSumsCodes";
import NoReport from "../../../components/NoReport";
import TwoColumnsWrap from "../../../components/TwoColumnsWrap";
import useTableReceipts from "./hooks/useTableReceipts";
import Table from "../../../../../components-ui/DataDisplay/Table";
import PieWidget from "./components/PieWidget";
import ChartWidget from "./components/ChartWidget";
import TotalSum from "./components/TotalSum";
import Sums from "./containers/Sums";
import Box from "../../../../../components-ui/Layout/Box";
import useDirectionsCodes from "./hooks/useDirectionsCodes";

interface Props {
    report: ReceiptsReport
    startDate: string
    endDate: string
}

const Receipts = ({report, startDate, endDate}: Props) => {
    const {state, handlers} = useReceipts({report});
    const sumsCodes = useSumsCodes({report, state});
    const directionsCodes = useDirectionsCodes({report});
    const {columns, rows} = useTableReceipts({state, report});

    if (!!sumsCodes.length) {
        return (
            <>
                <Filter state={state} directionsCodes={directionsCodes} handlers={handlers}/>
                <ContentWidgetWrap>
                    <TwoColumnsWrap
                        firstColumn={(
                            <>
                                <Box position="relative" zIndex={12}>
                                    <PieWidget report={report} state={state} handlers={handlers} stateKey={startDate + endDate}/>
                                    <ChartWidget report={report} state={state} startDate={startDate} endDate={endDate}/>
                                </Box>
                                <Box display="flex" flexDirection="column">
                                    <TotalSum report={report} state={state}/>
                                    <Sums sumsCodes={sumsCodes} handlers={handlers} state={state} report={report} />
                                </Box>
                            </>
                        )}
                        lastColumn={<Table columns={columns}
                                           rows={rows}
                                           key={String(state.currentTab) + String(state.selectedDirection)}
                                           variantTable="outline"
                                           maxHeight="466px"
                                           isHovering={false}
                                           fontSizeRow="14px" />}
                    />
                </ContentWidgetWrap>
            </>
        );
    } else {
        return <NoReport/>
    }


};

export default Receipts;
