import React from "react";
import {IColumn} from "../../../../../../components-ui/DataDisplay/Table";
import {useSelector} from "react-redux";
import Box from "../../../../../../components-ui/Layout/Box";
import styled from "styled-components";
import Helper from "../../../../../../common/helpers/main";
import TreatmentPlan from "../../../../../../containers/TreatmentPlan/TreatmentPlan";

const WrapDoctors = styled(Box)`
    display: flex;

    & > div {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }
`

export default function useColumns() {
    const servicesNames = useSelector(state => state.common.servicesNames)
    const doctors = useSelector(state => state.common.doctors)

    const columnsOne = [
        {
            headerName: "Номер карты",
            field: "number",
            width: '145px'
        },
        {
            headerName: "Врачи",
            field: "doctors",
            width: '138px',
            valueFormatter: ({row}) => {
                return (
                    <WrapDoctors>
                        {
                            row.doctorsIds.map((doctorId: number) => {
                                const findDoctor = doctors.find(doctor => doctor.id === doctorId);
                                if (!!findDoctor) {
                                    return (
                                        <Box backgroundColor={findDoctor.color as string}/>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </WrapDoctors>
                )
            }
        },
        {
            headerName: "Пациент",
            field: "fullName",
            width: '206px',
            valueFormatter: ({row}) => Helper.getFio(row),
            sortable: true,
            sortPosition: 'right'
        },
        {
            headerName: "Телефон",
            field: "phone",
            width: '186px',
            valueFormatter: ({row}) => Helper.Phone.format(row.personalPhone)
        },
        {
            headerName: "Адрес",
            field: "address",
            valueFormatter: ({row}) => Helper.getAddress(row)
        },
        {
            headerName: "Возраст",
            field: "age",
            width: '221px',
            valueFormatter: ({row}) => !!row.personalBirthday ? Helper.Date.getAge(row.personalBirthday) : null
        }
    ] as IColumn[]

    const columnsTwo = [
        {
            headerName: "Услуга",
            field: "name",
        },
        {
            headerName: "Зуб",
            field: "target",
            width: '100px',
            valueFormatter: ({childrenCell}) => Helper.sequenceToRange(childrenCell.split(', '))
        },
        {
            headerName: "Количество",
            field: "quantity",
            width: '135px',
        },
        {
            headerName: "К оплате",
            field: "sum",
            width: '190px',
            valueFormatter: ({row, isNested}) => isNested ? row.price : TreatmentPlan.getItemPrice(row) * row.quantity,
            color: (defaultColor, isNested) => isNested ? 'borderDark' : defaultColor,
            type: "price"
        }
    ] as IColumn[]

    const columnsThree = [
        {
            headerName: "Услуга",
            field: "name",
        },
        {
            headerName: "Зуб",
            field: "target",
            width: '100px',
            valueFormatter: ({childrenCell}) => Helper.sequenceToRange(childrenCell.split(', '))
        },
        {
            headerName: "Статус",
            field: "status",
            width: "150px",
            valueFormatter: ({row}) => row.isDone ? 'выполнена' : 'не выполнена'
        },
        {
            headerName: "Количество",
            field: "quantity",
            width: '135px'
        },
        {
            headerName: "К оплате",
            field: "sum",
            width: "190px",
            valueFormatter: ({row, isNested}) => isNested ? row.price : TreatmentPlan.getItemPrice(row) * row.quantity,
            color: (defaultColor, isNested) => isNested ? 'borderDark' : defaultColor,
            type: "price"
        }
    ] as IColumn[]

    const columnsNew = [
        {
            headerName: 'Услуги',
            field: 'service',
            valueFormatter: ({row}) => servicesNames[row.serviceId],
            sortable: true,
            sortType: 'string',
            sortPosition: 'right'
        },
        {
            headerName: 'Сумма, руб.',
            field: 'price',
            valueFormatter: ({childrenCell}) => Helper.Price.format(childrenCell),
            width: '140px',
            sortable: true,
            sortType: 'number',
            align: 'right',
            sortPosition: 'newColumn',
            defaultSort: true
        }
    ] as IColumn[]

    return {
        columnsTwo, columnsOne, columnsThree, columnsNew
    }
}
