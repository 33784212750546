import React, {Dispatch, SetStateAction} from 'react';
import Button from "../../../../../components-ui/Inputs/Button/Button";
import useCountNotice from "../hooks/useCountNotice";
import {SWrapperButton, SContainerButtons, SWrapButton} from '../style';
import ShowButton from "./ShowButton";
import {TasksInfo} from "../../../types";
import {GroupsButtonName, NoticesQuantity} from "../types";

interface Props {
    groupsButtonName: GroupsButtonName
    tasksInfo: TasksInfo
    selectedFilter: string
    handleSelectedFilter: (selectedFilter: string) => void
    noticesQuantity: NoticesQuantity
    noticeQuantityTotal: number
    showHiddenButtons: boolean
    setShowHiddenButtons: Dispatch<SetStateAction<boolean>>
    heightContainerButtons: number
}

const Buttons = ({
                     groupsButtonName,
                     tasksInfo,
                     selectedFilter,
                     handleSelectedFilter,
                     noticesQuantity,
                     noticeQuantityTotal,
                     showHiddenButtons,
                     setShowHiddenButtons,
                     heightContainerButtons
                 }: Props) => {

    const countNotice = useCountNotice({noticesQuantity, noticeQuantityTotal});

    return (
        <SWrapperButton>
            <SContainerButtons openButtons={showHiddenButtons} heightContainer={heightContainerButtons}>
                {groupsButtonName.map((groups, index) => {
                    return (
                        <SWrapButton key={`wrap-filter-button ${index}`} openButtons={showHiddenButtons}>
                            {groups.map(buttonCode => {
                                return (
                                    <Button variant={selectedFilter === buttonCode ? 'info' : 'info-outline'}
                                            noShadow={true}
                                            size="sm-standard"
                                            badge={countNotice(buttonCode)}
                                            fontSize="14px"
                                            children={tasksInfo[buttonCode].name}
                                            height="26px"
                                            onClick={() => {
                                                if (buttonCode !== selectedFilter) handleSelectedFilter(buttonCode)
                                            }}
                                    />
                                )
                            })}
                        </SWrapButton>
                    )
                })}
            </SContainerButtons>
            {groupsButtonName.length > 3 &&
            <ShowButton groupsButtonName={groupsButtonName} setShowHiddenButtons={setShowHiddenButtons}
                        showHiddenButtons={showHiddenButtons} noticesQuantity={noticesQuantity}/>}
        </SWrapperButton>
    )
};

export default Buttons;
