import React from 'react'
import Helper from "../../../../common/helpers/main";
import {ToothSelectWrap} from './styledToothSelect';
import ItemTooth from "./elements/ItemTooth";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Icon from "../../../../components-ui/DataDisplay/Icon";

interface PToothSelect {
    target: any[],
    targetSelected: any,
    teethStatuses: any,
    // teeth tabs
    onSelect: any,
    filledTeeth?: any[],
    handleResetClick?: any,
    typeSelected: string
}

export default function ToothSelect({
                                        target,
                                        targetSelected,
                                        teethStatuses,
                                        onSelect,
                                        filledTeeth = [],
                                        handleResetClick,
                                        typeSelected
                                    }: PToothSelect) {

    const newTeethStatuses = Array.isArray(teethStatuses) ? Helper.Array.toObject(teethStatuses, 'id') : teethStatuses;
    const newFilledTeeth = Helper.Array.flip(filledTeeth);
    return (
        <ToothSelectWrap selectedtooth={targetSelected}>
            {
                target.map((targetItem) => {
                    return <ItemTooth teethStatuses={newTeethStatuses}
                                      targetItem={targetItem}
                                      targetSelected={targetSelected}
                                      onSelect={onSelect}
                                      filledTeeth={newFilledTeeth}
                                      key={targetItem.name}
                                      typeSelected={typeSelected}
                    />
                })
            }
            {targetSelected.length > 1 &&
                <Button
                    isIcon
                    variant="default"
                    height="84px"
                    onClick={() => handleResetClick(targetSelected, typeSelected)}
                    children={
                        <Icon
                            width="8px"
                            color="textDarkGray"
                            name="delete"
                        />
                    }/>
            }
        </ToothSelectWrap>
    )
};
