import React, {Dispatch, SetStateAction, useMemo} from 'react';
import {ReportCode, StateWidget} from "../../types";
import Receipts from "../VariantsWidget/Receipts";
import AverageCheck from "../VariantsWidget/AverageCheck";
import ClinicLoading from "../VariantsWidget/ClinicLoading";
import Calls from "../VariantsWidget/Calls";
import CallsEfficiency from "../VariantsWidget/CallsEfficiency";
import NoReport from "../../components/NoReport";
import Visits from "../VariantsWidget/Visits";
import Consultations from "../VariantsWidget/Consultations";
import {SWrapLoader} from "../../../../components-ui/Feedback/Loader/Loader";

interface Props {
    stateWidget: StateWidget,
    code: ReportCode,
    setOpenDateRange: Dispatch<SetStateAction<boolean>>
    selectedBranches: number[]
}


const ContentWidget = ({stateWidget, code, setOpenDateRange, selectedBranches}: Props) => {
    return useMemo(() => {
        if (!!stateWidget.report) {
            let content = null;
            switch (code) {
                case "receipts":
                    content = <Receipts report={stateWidget.report} startDate={stateWidget.startDate}
                                        endDate={stateWidget.endDate}/>
                    break;
                case "average_check":
                    content = <AverageCheck report={stateWidget.report}
                                            stateKey={stateWidget.startDate + stateWidget.endDate}/>
                    break;
                case "clinic_loading":
                    content = <ClinicLoading report={stateWidget.report} startDate={stateWidget.startDate}
                                             endDate={stateWidget.endDate}/>
                    break;
                case "calls":
                    content = <Calls report={stateWidget.report} startDate={stateWidget.startDate}
                                     endDate={stateWidget.endDate}/>
                    break;
                case "calls_efficiency":
                    content = <CallsEfficiency report={stateWidget.report}/>
                    break;
                case "visits":
                    content = <Visits report={stateWidget.report} startDate={stateWidget.startDate}
                                      endDate={stateWidget.endDate}/>
                    break;
                case "consultations":
                    content = <Consultations report={stateWidget.report} startDate={stateWidget.startDate}
                                             endDate={stateWidget.endDate} setOpenDateRange={setOpenDateRange}
                                             selectedBranches={selectedBranches}/>
                    break;
                default:
                    break;
            }
            return (
                <SWrapLoader loader={stateWidget.loader}>
                    {content}
                </SWrapLoader>
            )
        } else {
            return <NoReport/>
        }

    }, [JSON.stringify(stateWidget.report), stateWidget.loader])
};

export default ContentWidget;
