import {PropsCallHistory, StateCallHistory} from "../types/common";
import {useEffect, useState} from "react";
import ServerCommand from "../../../../../common/server/server-command";
import useLoader from "../../../../../components/Errors";

export default function useInitialCallHistory({personalPhone}:PropsCallHistory) {
    const [historyData, setHistoryData] = useState<StateCallHistory>({
        callerTypes: {}, completedCalls: null, directionsNames: {}, users: []
    });
    const [loader, setLoader] = useLoader()
    useEffect(() => {
        setLoader(true)
        ServerCommand.get('call-center/completed-call/list', {
            phoneNumber: personalPhone,
            withCallerTypes: true,
            withDirectionsNames: true
        })
            .then(response => {
                setHistoryData({
                    callerTypes: response.callerTypes,
                    completedCalls: response.completedCalls,
                    directionsNames: response.directionsNames,
                    users: response.users
                })
                setLoader(false)
            })
    }, [])

    return {
        historyData,
        loader
    }
}
