import React from 'react';
import {GetActualRatesResult} from '../../types';
import Paper from '../../../../containers/UI-Collection/Elements/Surfaces/Paper';
import GroupsTree from '../../../../components-ui/GroupsTree';
import TextField from '../../../../components-ui/Inputs/TextField/TextField';
import Box from '../../../../components-ui/Layout/Box';
import Button from '../../../../components-ui/Inputs/Button/Button';
import useLaboratoryRatesTable from './hooks/useLaboratoryRatesTable';
import useTableFormatter from './hooks/useTableFormatter';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import Loader from '../../../../components-ui/Feedback/Loader/Loader';
import Table from '../../../../components-ui/DataDisplay/Table';
import Typography from '../../../../components-ui/DataDisplay/Typography/Typography';
import {Divider} from '../../../../components-ui/DataDisplay/Divider';

interface Props {
    data: GetActualRatesResult;
    loaderTable: boolean;
    selectedBranch: OptionSelect;
    refreshDataFromServer: () => void;
}

const LaboratoryRatesTable = ({data, loaderTable, selectedBranch, refreshDataFromServer}: Props) => {

    const {
        search,
        setSearch,
        resetSearch,
        filterServicesGroups,
        onClickGroup,
        filtredSelectedGroup,
    } = useLaboratoryRatesTable({data});

    const {columns, setScrollState} = useTableFormatter(
        {
            rates: data.rates,
            selectedBranch,
            refreshDataFromServer,
        },
    );

    return (
        <Paper padding='22px 34px 22px'>
            <Loader visible={loaderTable} />
            <Box display='flex' margin='5px 5px 16px' gap='10px'>
                <TextField onChange={setSearch}
                           value={search}
                           isSearch
                           placeholder='Поиск услуги'
                           widthInput='100%' />
                <Button variant='reset' onClick={resetSearch}>Сбросить</Button>
            </Box>
            <Paper margin='5px'>
                <Box padding='13px 19px 11px'>
                    <Typography variant='small' fontWeight='bold'>Группа</Typography>
                </Box>
                <Divider />
            <GroupsTree servicesGroups={filterServicesGroups}
                        onClickGroup={onClickGroup}
                        selectedGroup={filtredSelectedGroup}
            />
            </Paper>
            {
                <Box margin='15px 5px 5px'>
                    <Table columns={columns}
                           rows={filtredSelectedGroup?.services || []}
                           variantTable='outline'
                           isHovering={false}
                           fontSizeRow='14px'
                           maxHeight='255px'
                           customRowsCells={true}
                           onScroll={() => setScrollState(false)}
                    />

                </Box>
            }
        </Paper>
    );
};

export default LaboratoryRatesTable;
