import React from 'react';
import Box from "../../../components-ui/Layout/Box";

interface Props {
    firstColumn: any
    lastColumn: any
}

const TwoColumnsWrap = ({firstColumn, lastColumn}: Props) => {
    return (
        <Box display="flex" justifyContent="space-between" width="100%">
            <Box children={firstColumn} width="404px" margin="0 22px 0 0" display="flex" flexDirection="column" alignItems="space-between" />
            <Box children={lastColumn} width="calc(100% - 426px)"/>
        </Box>
    );
};

export default TwoColumnsWrap;
