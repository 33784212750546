import React, {Dispatch, SetStateAction} from "react";
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../../../containers/Tasks/data/commonPropsSComponent";
import DateTime from "../../../../components-ui/Inputs/DateTime/DateTime";
import Switch from "../../../../components-ui/Inputs/Switch/Switch";
import {Call, PlannedCall, PostponeServerCommand} from "../../types/common";
import getNewDate from "../../../../containers/Tasks/utils/getNewDate";
import usePostponeCall from "./hooks/usePostponeCall";
import {Divider} from "../../../../components-ui/DataDisplay/Divider";
import {useSelector} from "react-redux";
import Helper from "../../../../common/helpers/main";
import {useDateUpdate} from "../../../../common/hooks";
import {TasksInfo} from "../../../../containers/Tasks";
import {SContainerItem, STaskItem, STaskList} from "../../../../containers/Tasks/style";
import getMinMaxDateTime from "./helper/getMinMaxDateTime";

interface Props {
    plannedCall: PlannedCall,
    isDetail?: boolean
    setZIndex?: Dispatch<SetStateAction<number>>
    postponeServerCommand: PostponeServerCommand
    calls: Call[]
    tasksInfo: TasksInfo
    callIndex?: number
}

const PostponeCall = ({
                          plannedCall,
                          isDetail,
                          setZIndex,
                          postponeServerCommand,
                          calls,
                          tasksInfo,
                          callIndex
                      }: Props) => {
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);
    const {date, setDate, switchValue, setSwitchValue, minMaxDateTime} = usePostponeCall({
        plannedCall,
        isDetail,
        postponeServerCommand,
        calls,
        tasksInfo
    })
    const user = useSelector((state) => state.user);
    if (plannedCall.tasks.some(task => task.type === 'incoming_call')) return null;
    const isBlocked = !user.ignoreCallTasksBlocking && !switchValue && plannedCall.tasks.every(task => task.isBlocked);
    return (
        <>
            {isDetail && (
                <SContainerItem>
                    <Divider/>
                </SContainerItem>
            )}
            <STaskList padding="12px 0 11px">
                <STaskItem>
                    <Typography {...commonPropsTypography} display="flex" alignItems="center">
                        <span>Отложить {date === null ? 'невозможно' : isBlocked ? 'можно только при звонке' : 'до'}&nbsp;</span>
                        {!isBlocked && (
                            <DateTime selected={date}
                                      onChange={newDate => {
                                          const _date = getNewDate(newDate, getMinMaxDateTime(newDate, plannedCall, currentDate));
                                          if (switchValue) postponeServerCommand(plannedCall, _date);
                                          setDate(_date);
                                      }}
                                      isLink={true}
                                      onFocus={() => !!setZIndex && setZIndex(12)}
                                      onBlur={() => !!setZIndex && setZIndex(11)}
                                      showTimeSelect={true}
                                      isDownOpen={callIndex === 0}
                                      dateFormat="d-MM-yyyy HH:mm"
                                      minDate={minMaxDateTime.minDate}
                                      minTime={minMaxDateTime.minTime}
                                      maxDate={minMaxDateTime.maxDate}
                                      maxTime={minMaxDateTime.maxTime}
                                      isNullText=" "
                                      position="center"/>
                        )}
                    </Typography>
                    <Switch checked={switchValue} onChange={setSwitchValue} labelPosition="left"
                            isDisabled={!date || isBlocked}/>
                </STaskItem>
            </STaskList>
        </>
    )
}

export default PostponeCall
