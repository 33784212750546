import React from 'react';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import {Laboratory} from '../../../types';
import Box from '../../../../../components-ui/Layout/Box';
import ButtonForOptions from '../../../../../components-ui/Inputs/ButtonForOptions/ButtonForOptions';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import LaboratoryDelete from '../../modals/LaboratoryDelete';
import LaboratoryMark from '../../modals/LaboratoryMark';

interface Props {
    refreshDataFromServer: () => void
}

const useTableFormatter = ({refreshDataFromServer}: Props) => {

    const {showModal} = useDialogsManager();

    const columns: IColumn<Laboratory>[] = [
        {
            field: 'name',
            headerName: 'Название',
            width: '200px',
        },
        {
            field: 'active',
            headerName: 'Активна',
            width: '200px',
            valueFormatter: ({childrenCell}) => <Box>{childrenCell ? 'Да' : 'Нет'}</Box>,
        },
        {
            field: 'menuButton',
            width: '45px',
            valueFormatter: ({row}) => <ButtonForOptions
                options={[
                    {
                        value: '',
                        label: 'Изменить',
                        onClick: () => showModal(<LaboratoryMark laboratory={row} refreshDataFromServer={refreshDataFromServer}/>),
                    },
                    {
                        value: '',
                        label: 'Удалить',
                        onClick: () => showModal(<LaboratoryDelete id={row.id} refreshDataFromServer={refreshDataFromServer}/>),
                    },
                ]}
                position='left-bottom' />,
        },
    ];

    return {columns};

};

export default useTableFormatter;
