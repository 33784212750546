import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const EditIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M14.92,4l4.89,4.9L7.45,21.31,2.57,16.4Zm8.59-1.18L21.33.63a2.15,2.15,0,0,0-3,0h0l-2.09,2.1,4.89,4.9,2.43-2.44A1.68,1.68,0,0,0,23.51,2.82ZM0,23.29A.56.56,0,0,0,.69,24l5.44-1.33-4.88-4.9Z"/>
        </WrapIcon>
    );
});
