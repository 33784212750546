import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth75Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 18.84 24"} width={width || "12"} height={height || "16"} ref={ref}>
            <path d="M13.77,1.71C11.6.33,9.82-.1,9.32,0,7.19.59,2,2.19.5,4.87c-.79,1.4-.94,8,1.78,13.35C4.12,21.76,6.75,23.7,10.13,24a4.72,4.72,0,0,0,3.6-1.21c4.16-3.64,5-13.68,5.11-15.06-.44-2.08-2.33-4.27-5.05-6h0Z"/>
        </WrapIcon>
    );
});
