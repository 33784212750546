import React from "react";
import {WrapButtons} from "../styledEditableField";
import {useEditableField} from "../EditableField";
import Button from "../../../components-ui/Inputs/Button/Button";
import SVG from "../../../common/helpers/SVG";

export default function EditableFieldButtons() {

    const {variant, editField, setEditField, hover, onClickDeleted} = useEditableField();

    if (variant === "noEditable") return null;
    if (!editField && !hover) return null;

    let content = null;

    switch (variant) {
        case "deleted":
            if (hover) content = <Button isIcon={true}
                                         size="md"
                                         variant="transparent-danger"
                                         onClick={onClickDeleted}
                                         children={<SVG name="basket"/>}/>;
            break;
        case "editable":
            if (!editField && hover) content = <Button isIcon={true}
                                                       size="md"
                                                       variant="transparent-default"
                                                       children={<SVG name="edit"/>}
                                                       onClick={() => setEditField(true)}/>
            break;
        default:
            content = null;
            break;
    }

    return (
        <WrapButtons>
            {content}
        </WrapButtons>
    )
}
