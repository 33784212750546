import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth78Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 15.73"} width={width || "22"} height={height || "14"} ref={ref}>
            <path d="M.42,7.53c.14.72.24,1.39.33,2,.5,3.41.62,4.21,5.84,3.76a9.3,9.3,0,0,1,4.66,1,15.21,15.21,0,0,0,8.69,1.27c3.78-.42,4.16-5.77,4-8.91-.07-2.07-.4-3.43-1-4.05a1.94,1.94,0,0,0-1.56-.52H20.7A4.52,4.52,0,0,1,18,1.66C13.9-.8,12.08-.06,10.56.91c-1.07.7-2.66.36-4.52,0C4,.48,1.63,0,.61,1.24-.11,2.17-.2,4.35.39,7.52Z"/>
        </WrapIcon>
    );
});
