import React, {Dispatch, SetStateAction, useMemo} from 'react';
import {ResponseUserDetail, setFnType} from '../types';
import TextField from '../../../../../../../components-ui/Inputs/TextField/TextField';
import CalendarField from '../../../../../../../components-ui/Inputs/CalendarField/CalendarField';
import CardForm from '../../../../../../../components-ui/DataDisplay/CardForm';
import Button from '../../../../../../../components-ui/Inputs/Button/Button';
import Icon from '../../../../../../../components-ui/DataDisplay/Icon';
import Box from '../../../../../../../components-ui/Layout/Box';

interface Props {
    state: ResponseUserDetail;
    setState: Dispatch<SetStateAction<ResponseUserDetail>>;
    validationState: boolean;
    seriesValid: boolean;
    issueDateValid: boolean;
    addresDateValid: boolean;
}

const DocumentsInfo = ({state, setState, validationState, seriesValid, issueDateValid, addresDateValid}: Props) => {

        const setDocumentValue = ({key, value}: setFnType) => {
            const passport = state.detail.document?.passport ?? {
                series: '',
                issuedBy: '',
                issuedDate: '',
                birthplace: '',
                address: '',
                addressDate: '',
                inn: '',
                policy: '',
            };
            setState(prevState => (
                {
                    ...prevState,
                    detail: {
                        ...prevState.detail,
                        document: {
                            ...prevState.detail.document, passport: {
                                ...passport,
                                [key]: value,
                            },
                        },
                    },
                }
            ));
        };

        return useMemo(() =>
                <CardForm style={{margin: '0 0 16px 0'}} title='Документы'
                          forms={[
                              {
                                  label: 'Паспорт',
                                  input: <></>,
                              },
                              {
                                  label: 'Серия и номер',
                                  input: <TextField widthInput='100%'
                                                    isError={validationState && !seriesValid}
                                                    mask='99 99 999999'
                                                    onChange={(value) => {
                                                        setDocumentValue({key: 'series', value});
                                                    }}
                                                    value={state.detail.document?.passport.series ?? ''} />,
                              },
                              {
                                  label: 'Выдан',
                                  input: <Box display='flex' gap='5px' alignItems='center'>
                                      <CalendarField
                                          validateField={validationState && !issueDateValid ? {
                                              day: true,
                                              month: true,
                                              year: true,
                                          } : undefined}
                                          onChange={(value) => {
                                              setDocumentValue({key: 'issuedDate', value});
                                          }}
                                          value={state.detail.document?.passport.issuedDate ?? ''} />
                                      <Button isIcon onClick={() => {
                                          setDocumentValue({key: 'issuedDate', value: null});
                                      }}>
                                          <Icon name='close' color='error' />
                                      </Button>
                                  </Box>,
                              },
                              {
                                  label: 'Кем выдан',
                                  input: <TextField isTextArea
                                                    widthInput='100%'
                                                    onChange={(value) => {
                                                        setDocumentValue({key: 'issuedBy', value});
                                                    }}
                                                    value={state.detail.document?.passport.issuedBy ?? ''} />,

                              },
                              {
                                  label: 'Место рождения',
                                  input: <TextField widthInput='100%'
                                                    onChange={(value) => {
                                                        setDocumentValue({key: 'birthplace', value});
                                                    }}
                                                    value={state.detail.document?.passport.birthplace ?? ''} />,
                              },
                              {
                                  label: 'Адрес регистрации',
                                  input: <TextField widthInput='100%'
                                                    onChange={(value) => {
                                                        setDocumentValue({key: 'address', value});
                                                    }}
                                                    value={state.detail.document?.passport.address ?? ''} />,
                              },
                              {
                                  label: 'Дата регистрации',
                                  input: <Box display='flex' gap='5px' alignItems='center'>
                                      <CalendarField
                                          validateField={validationState && !addresDateValid ? {
                                              day: true,
                                              month: true,
                                              year: true,
                                          } : undefined}
                                          menuPlacement='top'
                                          onChange={(value) => {
                                              setDocumentValue({key: 'addressDate', value});

                                          }}
                                          value={state.detail.document?.passport.addressDate ?? ''} />
                                      <Button isIcon onClick={() => {
                                          setDocumentValue({key: 'addressDate', value: null});
                                      }}>
                                          <Icon name='close' color='error' />
                                      </Button>
                                  </Box>,
                              },
                              {
                                  label: 'ИНН',
                                  input: <TextField widthInput='100%'
                                                    onChange={(value) => {
                                                        setDocumentValue({key: 'inn', value});
                                                    }}
                                                    value={state.detail.document?.passport.inn ?? ''} />,
                              },
                              {
                                  label: 'СНИЛС',
                                  input: <TextField widthInput='100%'
                                                    onChange={(value) => {
                                                        setDocumentValue({key: 'policy', value});
                                                    }}
                                                    value={state.detail.document?.passport.policy ?? ''} />,
                              },
                          ]} />
            , [state.detail.document,
                validationState,
                seriesValid,
                issueDateValid,
                addresDateValid,
            ],
        );
    }
;

export default DocumentsInfo;
