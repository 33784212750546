import {LOAD_USER} from "../actions/actionConstants";
import {CurrentUser} from "../../types";
import {ActionsReducer} from "../types/common";


export default function userReducer(state: CurrentUser = {} as CurrentUser, action: ActionsReducer) {
    switch (action.type) {
        case LOAD_USER:
            return {...action.payload};
        default:
            return state;
    }
}
