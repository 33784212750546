import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const OptionsIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path
                d="M22.43,9.39H20.48a8.63,8.63,0,0,0-.64-1.54l1.38-1.38a1.57,1.57,0,0,0,0-2.22L19.75,2.78a1.61,1.61,0,0,0-2.22,0L16.15,4.16a8.63,8.63,0,0,0-1.54-.64V1.57A1.57,1.57,0,0,0,13,0H11A1.57,1.57,0,0,0,9.39,1.57v2a8.63,8.63,0,0,0-1.54.64L6.47,2.78a1.58,1.58,0,0,0-2.22,0L2.78,4.25a1.58,1.58,0,0,0,0,2.22L4.16,7.85a8.63,8.63,0,0,0-.64,1.54H1.57A1.57,1.57,0,0,0,0,11V13a1.57,1.57,0,0,0,1.57,1.57h2a8.63,8.63,0,0,0,.64,1.54L2.78,17.53a1.58,1.58,0,0,0,0,2.22l1.47,1.47a1.58,1.58,0,0,0,2.22,0l1.38-1.38a8.63,8.63,0,0,0,1.54.64v1.95A1.57,1.57,0,0,0,11,24H13a1.57,1.57,0,0,0,1.57-1.57V20.48a8.63,8.63,0,0,0,1.54-.64l1.38,1.38a1.61,1.61,0,0,0,2.22,0l1.47-1.47a1.57,1.57,0,0,0,0-2.22l-1.38-1.38a8.63,8.63,0,0,0,.64-1.54h1.95A1.57,1.57,0,0,0,24,13V11A1.57,1.57,0,0,0,22.43,9.39ZM23,13a.53.53,0,0,1-.53.53H20.09a.53.53,0,0,0-.51.39,7.75,7.75,0,0,1-.83,2,.5.5,0,0,0,.08.63l1.65,1.66a.51.51,0,0,1,0,.74L19,20.49a.55.55,0,0,1-.74,0l-1.66-1.66a.52.52,0,0,0-.63-.08,7.75,7.75,0,0,1-2,.83.53.53,0,0,0-.39.51v2.34A.53.53,0,0,1,13,23H11a.53.53,0,0,1-.53-.53V20.09a.53.53,0,0,0-.39-.51,7.75,7.75,0,0,1-2-.83.5.5,0,0,0-.26-.08.52.52,0,0,0-.37.16L5.73,20.49a.53.53,0,0,1-.74,0L3.51,19a.53.53,0,0,1,0-.74l1.66-1.66A.52.52,0,0,0,5.25,16a7.75,7.75,0,0,1-.83-2,.53.53,0,0,0-.51-.39H1.57A.53.53,0,0,1,1,13V11a.53.53,0,0,1,.53-.53H3.91A.53.53,0,0,0,4.42,10a7.75,7.75,0,0,1,.83-2,.5.5,0,0,0-.08-.63L3.51,5.73a.53.53,0,0,1,0-.74L5,3.51a.53.53,0,0,1,.74,0L7.39,5.17A.5.5,0,0,0,8,5.25a7.75,7.75,0,0,1,2-.83.53.53,0,0,0,.39-.51V1.57A.53.53,0,0,1,11,1H13a.53.53,0,0,1,.53.53V3.91a.53.53,0,0,0,.39.51,7.75,7.75,0,0,1,2,.83.5.5,0,0,0,.63-.08l1.66-1.66a.55.55,0,0,1,.74,0L20.49,5a.53.53,0,0,1,0,.74L18.83,7.39a.5.5,0,0,0-.08.63,7.75,7.75,0,0,1,.83,2,.53.53,0,0,0,.51.39h2.34A.53.53,0,0,1,23,11Z"/>
            <path d="M12,7.3A4.7,4.7,0,1,0,16.7,12,4.71,4.71,0,0,0,12,7.3Zm0,8.35A3.65,3.65,0,1,1,15.65,12,3.66,3.66,0,0,1,12,15.65Z"/>
        </WrapIcon>
    );
});
