import * as React from "react";
import {Component} from 'react';
import * as ReactDOM from 'react-dom';
import {Icon} from "../../Icons/Icons";
import ScrollbarsTs from "../../../components-ui/Scrollbars/ScrollbarsTs";
import {ContextMenuProps} from "../types/elements";


export default class ContextMenu extends Component<ContextMenuProps> {

    constructor(props: ContextMenuProps) {
        super(props);
        this.removeContextMenu = this.removeContextMenu.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.removeContextMenu);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.removeContextMenu);
    }

    state = {
      isHover: false
    };

    render() {
        const {position, data} = this.props as any;

        let style = {
            top: position.top + 'px',
            left: position.left + 'px'
        };

        if (+position.left > window.innerWidth / 2 - 140) {
            style.left = (+position.left - 250) + 'px'
        }

        return (
            <ul className="context-menu" style={style}>
                {data.map((item: any) => {
                    let children = null;
                    let className = '';

                    if (+position.left > window.innerWidth / 2 ) {
                        className = ' context-menu__item--left'
                    }

                    if (item.list !== undefined) {
                        className += " context-menu__item--list"
                        if (this.state.isHover) {
                            children = this.renderChildren(item);
                        }
                    }


                    if (item.isDisabled) {
                        className += ' context-menu__item--disabled';
                    }

                    return (
                        <li className={"context-menu__item" + className}
                            key={item.code}
                            onClick={(e) => {
                                e.preventDefault();
                                if (!!item.onClick) item.onClick();
                            }}
                            onMouseLeave={() => this.setState({isHover: false})}
                            onMouseEnter={() => {
                                if (item.list !== undefined) {
                                    this.setState({isHover: true})
                                }
                            }}
                        >
                            <span>{item.name}</span>
                            {children}
                        </li>
                    )
                })}
            </ul>
        )
    }

    renderChildren = (item: any) => {
        return (
            <ScrollbarsTs style={{height: 'max-content', maxHeight: '340px'}}
                          className="context-menu-list"
                          color='gray'>
                <ul>
                    {item.list.map((element: any) => {
                        let className = 'context-menu__item';
                        if (element.isSelect) {
                            className += ' context-menu__item--active';
                        }
                        return (
                            <li className={className} key={element.code} onClick={(e) => {
                                e.preventDefault();
                                if (!!element.onClick) element.onClick();
                            }}>
                                <span>{element.name}</span>
                                {element.isSelect && <Icon icon="new-check" />}
                            </li>
                        )
                    })}
                </ul>
            </ScrollbarsTs>
        )
    };



    removeContextMenu = (e: any) => {
        const node = ReactDOM.findDOMNode(this);
        if (node && !node.contains(e.target)) {
            this.props.remove();
        }
    };


}
