import React from 'react';
import PropTypes from 'prop-types';
import './SelectCheckbox.scss';
import {Icon} from "../../../components/Icons/Icons";
import ReactDOM from "react-dom";
import Prompt from "../../../components-ui/DataDisplay/Prompt/Prompt.tsx";
import Scrollbars from "../../../components-ui/Scrollbars/scrollbars";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";

export default class SelectCheckbox extends React.Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired
    };

    state = {
        openPopup: false,
        prompt: false
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleOutsideClick = (e) => {
        const node = ReactDOM.findDOMNode(this);
        if (node && !node.contains(e.target)) {
            this.setState({openPopup: false});
        }
    };

    render() {
        const {openPopup, prompt} = this.state;
        const controlTextParts = this.getControlTextParts();
        const controlText = controlTextParts.join(', ');

        return (
            <div
                className="select-checkbox"
                onClick={(e) => e.stopPropagation()}
            >
                <div
                    className="select-checkbox__select"
                    onClick={this.handleInputClick}
                    onMouseEnter={() => this.handleControlMouseEnter(controlTextParts)}
                    onMouseLeave={this.handleControlMouseLeave}
                >
                    <span
                        className={`select-checkbox__span ${controlTextParts.length >= 1 ? '' : 'select-checkbox__span--gray'}`}
                    >
                        {(controlTextParts.length >= 1) ? controlText : 'не выбраны'}
                    </span>
                    <Icon icon="new-arrow"/>
                </div>
                {openPopup && this.selectMenu()}
                {prompt && <Prompt text={controlText}/>}
            </div>
        )
    }

    selectMenu = () => {
        const {options} = this.props;

        return (
                <div className="select-checkbox__wrap">
                    <Scrollbars color='black' style={{height: 'max-content', maxHeight: '340px'}}>
                    {options.map((option) => {
                        return (
                            <Checkbox key={option.value} label={option.label} checked={option.checked}
                                      onChange={(checked) => this.handleSelectOption(option, checked)} />
                        )
                    })}
                    </Scrollbars>
                </div>

        );
    };

    handleInputClick = () => {
        this.setState(state => {
            return {
                openPopup: !state.openPopup,
                prompt: false
            };
        });
    };

    handleControlMouseEnter = controlTextParts => {
        const {openPopup} = this.state;
        if (controlTextParts.length >= 1 && !openPopup) {
            this.setState({prompt: true});
        }
    };

    handleControlMouseLeave = () => {
        this.setState({prompt: false});
    };

    handleSelectOption = (option, checked) => {
        this.props.onChange(option, checked)
    };

    getControlTextParts() {
        return this.props.options.filter(option => option.checked).map(option => option.label);
    }
}
