import React from 'react';
import Typography from '../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../components-ui/Layout/Box';
import useSettingsKkm from './hooks/useSettingsKkm';
import KkmTable from './components/KkmTable';
import Button from '../../../components-ui/Inputs/Button/Button';
import AddingEditingEmployee from './components/modals/AddingEditingKkm';
import Paper from '../../../containers/UI-Collection/Elements/Surfaces/Paper';
import {useDialogsManager} from '../../../containers/DialogsManager/DialogsManager';
import LoaderPage from '../../../components/LoaderPage';
import AddingEditingKkm from "./components/modals/AddingEditingKkm";

const SettingsKkmPage = () => {

    const {
        state,
        setState,
        refreshDataFromServer
    } = useSettingsKkm();

    const {showSlideModal} = useDialogsManager();
    return (
        <Box padding='62px 20px 80px 28px'>
            <Typography variant='title' fontWeight='bold'>Список ККМ</Typography>
            <Paper padding='24px' minHeight={600}>
                {state.kkmList
                    ?
                    <KkmTable data={state.kkmList}
                              refreshDataFromServer={refreshDataFromServer}
                    />
                    :
                    <LoaderPage height={400}/>
                }
                <Button margin='24px 0 0 0'
                        variant='default'
                        size='md'
                        children='Добавить'
                        onClick={() => showSlideModal(<AddingEditingKkm refreshDataFromServer={refreshDataFromServer}/>)}
                />
            </Paper>
        </Box>
    );
};

export default SettingsKkmPage;
