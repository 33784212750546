import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import {TasksInfo} from "../../../containers/Tasks";

export const managerTasksInfo = {
    check_caller_type: {
        name: 'Проверка типа звонящего',
        title: null,
        postponeFor: 1,
        widthButton: 207
    },
    check_waiting_list: {
        name: 'Проверка записи в лист ожидания',
        title: null,
        postponeFor: 1,
        widthButton: 262
    },
    empty_performed_plan: {
        name: 'Не согласован план',
        title: null,
        postponeFor: 1,
        widthButton: 162
    },
    empty_recommended_plan: {
        name: 'Не составлен рекомендуемый план',
        title: null,
        postponeFor: 1,
        widthButton: 270
    },
    incomplete_recommended_plan: {
        name: 'Неполный рекомендуемый план',
        title: null,
        postponeFor: 1,
        widthButton: 251
    },
    insufficient_workload: {
        name: 'Недостаточная загрузка клиники',
        title: null,
        postponeFor: 1,
        widthButton: 253
    },
    long_visit_waiting: {
        name: 'Долгое ожидание приёма',
        title: null,
        postponeFor: 0,
        widthButton: 100
    },
    not_changed_plan_in_survey: {
        name: 'Не изменен план при проф осмотре',
        title: null,
        postponeFor: 1,
        widthButton: 278
    },
    not_recommended_other_doctor: {
        name: 'Не рекомендован другой врач',
        title: null,
        postponeFor: 1,
        widthButton: 236
    },
    reduced_consultations_efficiency: {
        name: 'Снижение эффективности по консультациям',
        title: null,
        postponeFor: 1,
        widthButton: 334
    },
    reduced_incoming_calls_count: {
        name: 'Снижение количества входящих звонков',
        title: null,
        postponeFor: 1,
        widthButton: 310
    },
    reduced_incoming_calls_efficiency: {
        name: 'Снижение эффективности по входящим звонкам',
        title: null,
        postponeFor: 1,
        widthButton: 362
    },
    unfinished_visit: {
        name: 'Не завершён приём',
        title: null,
        postponeFor: 1,
        widthButton: 164
    },
    visit_refusal: {
        name: 'Отказ от записи',
        title: null,
        postponeFor: 1,
        widthButton: 136
    },
    invoices_inconsistency: {
        name: 'Несоответствие счетов',
        title: null,
        postponeFor: 1,
        widthButton: 183
    },
    not_checked_payments: {
        name: 'Не проверены оплаты',
        title: null,
        postponeFor: 1,
        widthButton: 180
    }
} as TasksInfo


export const allUser = {
    value: 'all',
    label: 'все'
} as OptionSelect

export const clinicUser = {
    value: 'clinic',
    label: 'клиника'
} as OptionSelect
