
const getPositionOffsets = (refRow: any, refTable: any) => {
    const rowTop = refRow.current.getBoundingClientRect().top
    const tableTop = refTable.current.getBoundingClientRect().top
    const tableHeight = refTable.current.getBoundingClientRect().height
    const rowHeight = refRow.current.getBoundingClientRect().height
    const topPosition = rowTop - tableTop;

    const positionOffsets = {
        right: '20px'
    } as any;

    if (topPosition + rowHeight > (tableHeight / 2)) {
        positionOffsets.bottom = `${tableHeight - topPosition}px`
    } else {
        positionOffsets.top = `${topPosition + rowHeight}px`
    }

    return positionOffsets
}

export default getPositionOffsets
