import { useEffect } from "react";

const useTabDown = (callback: any) => {

    const handleKeyDown = (e: any) => {
        if (e.which === 9) callback();
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);
};

export default useTabDown;
