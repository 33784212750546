import React from 'react';
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import Helper from "../../../../../common/helpers/main";
import Box from "../../../../../components-ui/Layout/Box";
import {ManagerTask} from "../../../types/common";
import SVG from "../../../../../common/helpers/SVG";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {useSelector} from "react-redux";

interface Props {
    task: ManagerTask
    handleSelectedUser: (selectedUser: OptionSelect) => void
}

const Title = ({task, handleSelectedUser}: Props) => {
    const users = useSelector(state => state.common.users)
    return (
        <Box display="flex">
            {task.postponedTo !== null && (
                <Box margin="3px 0 0" title="Звонок отложен">
                    <SVG name={`call-center/waiting`} className={`icon-call-center icon-call-center--waiting`}/>
                </Box>
            )}
            <Typography display="block" variant="small" fontWeight="600" lineHeight="1.71" margin="0 0 17px">
                {task.employeeId === null && <Typography as="span" variant="small" children="Клиника" />}
                {task.employeeId !== null && <Typography as="span"
                                                         variant="small"
                                                         cursor="pointer"
                                                         onClick={() => {
                                                             handleSelectedUser({
                                                                 value: task.employeeId,
                                                                 label: Helper.getFio(users[task.employeeId as number]),
                                                                 color: users[task.employeeId as number].color
                                                             })
                                                         }}
                                                         color='info'>
                    {Helper.getFullName(users[task.employeeId])}
                </Typography>}
            </Typography>
        </Box>
    );
};

export default Title;
