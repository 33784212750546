import React from "react";
import Jaw from "../Jaw/Jaw";
import {useDentalCard} from "../../DentalRecords";
import {SDentalCard} from "../../types";
import {DentalCardWrap, DentalCardJawWrap} from "./styledDentalCard";
import DentalCardInfo from "../DentalCardInfo/DentalCardInfo";
import LoadDiacnocatButton from "../LoadDiactocatButton/LoadDiactocatButton";
import TreatmentPlanButton from "../TreatmentPlanButton/TreatmentPlanButton";


export default function DentalCard() {
    const {keysJaws} = useDentalCard() as SDentalCard;
    return (
        <DentalCardWrap>
            <DentalCardJawWrap>
                {keysJaws.map(jaw => <Jaw jaw={jaw} key={jaw}/>)}
            </DentalCardJawWrap>
            <DentalCardInfo/>
            <LoadDiacnocatButton />
            <TreatmentPlanButton />
        </DentalCardWrap>
    )
}


