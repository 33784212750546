import React from 'react';
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import Helper from "../../../../common/helpers/main";
import PatientCard from "../../../PatientCard/PatientCard";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";
import {PatientMinimalData} from "../../../../containers/PatientField/types/common";
import {useDialogsManager} from "../../../../containers/DialogsManager/DialogsManager";
import Box from "../../../../components-ui/Layout/Box";

interface Props {
    patients: PatientMinimalData[]
    colorPatient?: 'text' | 'info'
    activeSectionNav?: "TREATMENT_PLAN" | "INVOICES"
    treatmentPlanType?: 'recommended' | 'performed'
}

export const Patients = ({patients, colorPatient = 'text', activeSectionNav, treatmentPlanType}: Props) => {
    const {showSlideModal} = useDialogsManager();

    if (!patients.length || !patients[0]) return null;

    const commonPropsPatientCard = {} as { [key: string]: any };
    if (!!activeSectionNav) commonPropsPatientCard.activeSectionNav = activeSectionNav
    if (!!treatmentPlanType) commonPropsPatientCard.treatmentPlanType = treatmentPlanType

    return (
        <LabelTask label={"ФИО " + (patients.length > 1 ? "пациентов" : "пациента")}
                   alignItems="flex-start"
                   children={(
                       <Box display="flex" flexDirection="column" alignItems="flex-end">
                           {patients.map((patient, index) => {
                               return <Typography key={patient.id}
                                                  children={Helper.getFio(patient)}
                                                  isLink={true}
                                                  variant="small"
                                                  color={colorPatient}
                                                  margin={index !== patients.length - 1 ? '0 0 5px' : '0'}
                                                  onClick={() => showSlideModal(<PatientCard
                                                      patientId={patient.id} {...commonPropsPatientCard}/>)}
                               />
                           })}
                       </Box>
                   )}
        />
    );
};
