import React, {useMemo} from 'react';
import Helper from '../../../common/helpers/main';
import {useSelector} from 'react-redux';
import {NamesSvg} from '../../../components-ui/DataDisplay/Icon/types/common';
import {useDialogsManager} from "../../DialogsManager/DialogsManager";
import PaymentCreationForm from "../../../content/modals/PaymentCreationForm";
import CashReturn from "../../../content/modals/CashReturn";
import CashClose from "../../../content/modals/CashClose";
import CashCreate from "../../../content/modals/CashCreate";

interface Link {
    link?: string;
    icon: NamesSvg;
    name: string;
    code: string;
    isHidden?: boolean;
    subsNav?: SubLink[];
    component?: any
}

interface SubLink extends Omit<Link, 'icon'> {
    link: string;
}

const useLinks = (): Link[] => {
    const user = useSelector(state => state.user);

    return useMemo(() => {
        return [
            {
                link: '/',
                icon: 'schedule',
                name: 'Расписание',
                code: 'schedule',
            },
            {
                link: '/patients-list',
                icon: 'cardCatalogue',
                name: 'Картотека',
                code: 'cardCatalogue',
            },
            {
                link: '/pricelist',
                icon: 'document',
                name: 'Прейскурант',
                code: 'priceList',
                isHidden: !user.canViewPriceList
            },
            // {
            //     link: '/materials',
            //     icon: 'drugs',
            //     name: 'Материалы',
            //     code: 'materials'
            // },
            {
                link: '/graph',
                icon: 'schedule',
                name: 'График',
                code: 'graph',
                isHidden: !user.canViewScheduleTemplate,
            },
            {
                icon: 'analytics',
                name: 'Аналитика',
                code: 'wrap-analytics',
                subsNav: [
                    {
                        link: '/analytics',
                        name: 'Рабочий стол директора',
                        code: 'analytics',
                        isHidden: !user.canViewAllReports,
                    },
                    {
                        link: '/reports',
                        name: 'Отчёт по рекомендациям',
                        code: 'reports',
                        isHidden: !user.canViewDoctorsRecommendations,
                    },
                ],
            },
            {
                icon: 'xray',
                name: 'Рентген',
                code: 'x-ray',
                isHidden: !user.canViewTodayXrayPictures,
                subsNav: [
                    {
                        link: '/xray/pictures',
                        name: 'Снимки',
                        code: 'xrayPictures',
                        isHidden: !user.canViewTodayXrayPictures,
                    },
                    {
                        link: '/xray/rate',
                        name: 'Ставки за снимки',
                        code: 'xrayRate',
                        isHidden: !user.canViewXrayPictureRates,
                    },
                ],
            },
            {
                link: '/test',
                icon: 'analytics',
                name: 'Тестирование',
                code: 'test',
                isHidden: !Helper.isEnabledTestingDashboard(),
            },
            {
                icon: 'salary',
                name: 'Зарплаты',
                code: 'salaries',
                isHidden: !(user.canViewSalaries || user.canViewFullTimesheet),
                subsNav: [
                    {
                        link: '/salaries',
                        name: 'Расчёт зарплаты',
                        code: 'salaries',
                        isHidden: !user.canViewSalaries
                    },
                    {
                        link: '/salaries/timesheet',
                        name: 'Табель',
                        code: 'salariesTimesheet',
                        isHidden: !user.canViewFullTimesheet
                    }
                ]
            },
            {
                icon: 'laboratory',
                name: 'Лаборатория',
                code: 'laboratory',
                isHidden:
                    !(user.canEditLaboratories ||
                        user.canViewLaboratoryPricelist ||
                        user.canViewLaboratoryServiceRates ||
                        user.canViewLaboratoryDoneServices
                    ),
                subsNav: [
                    {
                        link: '/laboratories',
                        name: 'Лаборатории',
                        code: 'laboratories',
                        isHidden: !user.canEditLaboratories,
                    },
                    {
                        link: '/laboratories/pricelist',
                        name: 'Прейскурант лаборатории',
                        code: 'laboratoryPricelist',
                        isHidden: !user.canViewLaboratoryPricelist,
                    },
                    {
                        link: '/laboratories/service-rates',
                        name: 'Ставки техников',
                        code: 'laboratoryServiceRates',
                        isHidden: !user.canViewLaboratoryServiceRates,
                    },
                    {
                        link: '/laboratories/done-services',
                        name: 'Выполненные услуги',
                        code: 'laboratoryServiceRates',
                        isHidden: !user.canViewLaboratoryDoneServices,
                    },
                ],
            },
            {
                icon: 'cashbox',
                name: 'Касса',
                code: 'kkm',
                isHidden: !user.canCashPayments,
                subsNav: [
                    {
                        icon: '',
                        link: '',
                        component: <CashCreate loadingFromServer={() => {}}/>,
                        name: 'Внесение ДС',
                        code: 'pay',
                        isHidden: false
                    },
                    {
                        icon: '',
                        link: '',
                        component: <CashReturn loadingFromServer={() => {}}/>,
                        name: 'Выдать ДС',
                        code: 'return',
                        isHidden: false
                    },
                    {
                        icon: '',
                        link: '',
                        component: <CashClose loadingFromServer={() => {}}/>,
                        name: 'Закрыть кассу',
                        code: 'close',
                        isHidden: false
                    },
                    {
                        link: '/payment-report',
                        name: 'Движение ДС',
                        code: 'paymentReport',
                        isHidden: !user.canCashReport,
                    },
                ]
            },
            {
                link: '/ui',
                icon: 'help',
                name: 'ui',
                code: 'ui',
                isHidden: !Helper.isEnabledTestingDashboard(),
            },
        ].map(link => {
            if (!!link.subsNav) link.subsNav = link.subsNav.filter(subNav => !subNav.isHidden);
            return link;
        }).filter(link => !link.isHidden) as Link[];
    }, []);
};

export default useLinks;
