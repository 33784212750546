import React from "react";
import {WrapContent} from "../../../styledUI";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import SVG from "../../../../../common/helpers/SVG";
import {OptionButton} from "../../../../../components-ui/Inputs/Button/typesButton";
import useLoader from "../../../../../components/Errors";

const optionsButton = [
    {
        value: '1',
        label: 'Какой нибудь текст для кнопки',
        onClick: () => console.log('click')
    },
    {
        value: '2',
        label: 'Второй вариант кнопки',
        onClick: () => console.log('click2')
    },
    {
        value: '3',
        label: 'Еще одна кнопка',
        onClick: () => console.log('click3')
    }
] as OptionButton[]

export default function ButtonPage() {
    const [loader, setLoader] = useLoader();
    return (
        <>
            <h1>Button</h1>
            <WrapContent>
                <div>
                    <h4>Options Button</h4>
                    <div>
                        <Button variant="success" options={optionsButton} children="Это кнопка options"/>
                        <pre>
                        {
                            '<Button variant="standard" children="Это кнопка options" options={optionsButton}/>'
                        }
                    </pre>
                    </div>
                    <h4>variant</h4>
                    <div>
                        <Button variant="standard" children="Это кнопка standard"/>
                        <pre>
                        {
                            '<Button variant="standard" children="Это кнопка standard"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="default" children="Это кнопка default"/>
                        <pre>
                        {
                            '<Button variant="default" children="Это кнопка default"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="secondary" children="Это кнопка secondary"/>
                        <pre>
                        {
                            '<Button variant="secondary" children="Это кнопка secondary"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="success" children="Это кнопка success"/>
                        <pre>
                        {
                            '<Button variant="success" children="Это кнопка success"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="info" children="Это кнопка info"/>
                        <pre>
                        {
                            '<Button variant="info" children="Это кнопка info"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="info-outline" children="Это кнопка info-outline"/>
                        <pre>
                        {
                            '<Button variant="info-outline" children="Это кнопка info-outline"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="info" noShadow={true} size="sm-standard" badge={4} fontSize="14px" children="Это кнопка info"/>
                        <pre>
                        {
                            '<Button variant="info" noShadow={true} size="sm-standard" badge={4} fontSize="14px" children="Это кнопка info"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="info-outline" noShadow={true} size="sm-standard" badge={2} fontSize="14px" children="Это кнопка info-outline"/>
                        <pre>
                        {
                            '<Button variant="info-outline" noShadow={true} size="sm-standard" badge={2} fontSize="14px" children="Это кнопка info-outline"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="disabled" children="Это кнопка disabled"/>
                        <pre>
                        {
                            '<Button variant="disabled" children="Это кнопка disabled"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="outline--secondary" children="Это кнопка outline--secondary"/>
                        <pre>
                        {
                            '<Button variant="outline--secondary" children="Это кнопка outline--secondary"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="warning" children="Это кнопка warning"/>
                        <pre>
                        {
                            '<Button variant="warning" children="Это кнопка warning"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="danger" children="Это кнопка danger"/>
                        <pre>
                        {
                            '<Button variant="danger" children="Это кнопка danger"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="dark" children="Это кнопка dark"/>
                        <pre>
                        {
                            '<Button variant="dark" children="Это кнопка dark"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="reset" children="Это кнопка reset"/>
                        <pre>
                        {
                            '<Button variant="reset" children="Это кнопка reset"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="standard" children="Это кнопка standard"/>
                        <pre>
                        {
                            '<Button variant="standard" children="Это кнопка standard"/>'
                        }
                    </pre>
                    </div>
                    <h4>size</h4>
                    <div>
                        <Button variant="dark" size="sm" children="Это кнопка sm"/>
                        <pre>
                        {
                            '<Button variant="dark" size="sm" children="Это кнопка sm"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="default" size="sm-standard" children="Sm-standard"/>
                        <pre>
                        {
                            '<Button variant="default" size="sm-standard" children="Sm-standard"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="default" size="md" children="Это кнопка md"/>
                        <pre>
                        {
                            '<Button variant="default" size="md" children="Это кнопка md"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="default" size="lg" children="Это кнопка lg"/>
                        <pre>
                        {
                            '<Button variant="default" size="lg" children="Это кнопка lg"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="default" size="long" children="Это кнопка long"/>
                        <pre>
                        {
                            '<Button variant="default" size="long" children="Это кнопка long"/>'
                        }
                    </pre>
                    </div>
                    <h4>align</h4>
                    <div>
                        <Button variant="default" size="md" align="left" children="Это кнопка left"/>
                        <pre>
                        {
                            '<Button variant="default" size="md" align="left" children="Это кнопка left"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="default" size="md" align="center" children="Это кнопка center"/>
                        <pre>
                        {
                            '<Button variant="default" size="md" align="center" children="Это кнопка center"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="default" size="md" align="right" children="Это кнопка right"/>
                        <pre>
                        {
                            '<Button variant="default" size="md" align="right" children="Это кнопка right"/>'
                        }
                    </pre>
                    </div>
                    <h4>StartIcon</h4>
                    <div>
                        <Button variant="default" startIcon={<SVG name="print"/>} children="Это кнопка StartIcon"/>
                        <pre>
                        {
                            '<Button variant="default" startIcon={<SVG name="print" />} children="Это кнопка StartIcon"/>'
                        }
                    </pre>
                    </div>
                    <div>
                        <Button variant="dark" startIcon={<SVG name="information"/>}
                                children="Это кнопка StartIconDark"/>
                        <pre>
                        {
                            '<Button variant="dark" startIcon={<SVG name="information" />} children="Это кнопка StartIconDark"/>'
                        }
                    </pre>
                    </div>
                    <h4>isIcon</h4>
                    <div style={{background: '#fff'}}>
                        <Button isIcon variant="danger" children={<SVG name="basket"/>}/>
                        <span style={{display: 'inline-block', width: '20px'}} />
                        <Button isIcon variant="default" children={<SVG name="edit"/>}/>
                        <span style={{display: 'inline-block', width: '20px'}} />
                        <Button isIcon variant="disabled" children={<SVG name="save"/>}/>
                        <span style={{display: 'inline-block', width: '20px'}} />
                        <Button isIcon variant="transparent-default" size="md" children={<SVG name="edit"/>}/>
                        <span style={{display: 'inline-block', width: '20px'}} />
                        <Button isIcon variant="transparent-danger" size="md" children={<SVG name="delete"/>}/>
                        <span style={{display: 'inline-block', width: '20px'}} />
                        <Button isIcon variant="transparent-success" size="md" children={<SVG name="check"/>}/>
                        <span style={{display: 'inline-block', width: '20px'}} />
                        <Button isIcon variant="transparent-danger" size="md" children={<SVG name="basket"/>}/>
                        <span style={{display: 'inline-block', width: '20px'}} />
                        <Button isIcon variant="success" size="lg" children={<SVG name="close"/>}/>
                        <span style={{display: 'inline-block', width: '20px'}} />
                        <Button isIcon variant="secondary" noShadow={true} height="26px" width="32px"
                                widthIcon="10px" heightIcon="10px" children={<SVG name="maximizeList"/>}/>
                        <pre>
                            {
                                '<Button isIcon variant="danger" children={<SVG name="basket" />}/>'
                            }
                        </pre>
                        <pre>
                             {
                                 '<Button isIcon variant="default" children={<SVG name="edit" />}/>'
                             }
                        </pre>
                        <pre>
                             {
                                 '<Button isIcon variant="disabled" children={<SVG name="save" />}/>'
                             }
                        </pre>
                        <pre>
                            {
                                '<Button isIcon variant="transparent-default" size="md" children={<SVG name="edit"/>}/>'
                            }
                        </pre>
                        <pre>
                             {
                                 '<Button isIcon variant="transparent-danger" size="md" children={<SVG name="delete"/>}/>'
                             }
                        </pre>
                        <pre>
                             {
                                 '<Button isIcon variant="transparent-success" size="md" children={<SVG name="check"/>}/>'
                             }
                        </pre>
                        <pre>
                             {
                                 '<Button isIcon variant="transparent-danger" size="md" children={<SVG name="basket"/>}/>'
                             }
                        </pre>
                        <pre>
                             {
                                 '<Button isIcon variant="success" size="lg" children={<SVG name="close"/>}/>'
                             }
                        </pre>
                        <pre>
                             {
                                 ` <Button isIcon variant="secondary" noShadow={true} height="26px" width="32px"
                                widthIcon="10px" heightIcon="10px" children={<SVG name="maximizeList"/>}/>`
                             }
                        </pre>
                    </div>
                    <h4>Loading</h4>
                    <div>
                        <Button variant="default" children="Это кнопка loading" loader={true}/>
                        <pre>
                        {
                            '<Button variant="default" children="Это кнопка loading" loader={true}/>'
                        }
                    </pre>
                    </div>
                </div>

            </WrapContent>
        </>
    )
}
