import * as React from "react";
import {Component} from 'react';
import {sizeCell} from '../helper/constants'
import {TimeIntervalProps} from "../types/elements";

export default class TimeInterval extends Component<TimeIntervalProps> {

    render() {

        const {timeInterval, selectedCell} = this.props;

        let timeEnd: any = null;

        if (selectedCell !== null) {
            timeEnd = selectedCell.data.timeEndInterval;
            if (selectedCell.data.doctorTime !== undefined) {
                timeEnd = selectedCell.data.doctorTime.timeEnd;
            }
            if (selectedCell.data.timeEnd !== undefined) {
                timeEnd = selectedCell.data.timeEnd;
            }
        }

        return (
            <div className="time-interval">
                {timeInterval.map(time => {
                    const style: any = {
                        height: sizeCell.height
                    };
                    if (timeEnd !== null) {
                        if (time >= selectedCell.data.timeStart && time < timeEnd) {
                            style.color = '#447ce6';
                        }
                    }
                    return <div className="time"
                                key={time}
                                style={style}
                    >
                        {time}
                    </div>
                })}
            </div>
        )
    }

}
