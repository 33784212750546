
export const dataBarOne = [
    {
        code: '2614',
        value: 39426,
        dataTooltip: [{value:64485, code: 'implantology'}, {value:4421, code: 'surgery' }, {value:49371, code: 'orthopedics' }]
    },
    {
        code: '2924',
        value: 7360,
        dataTooltip: [{value:9478, code: 'therapy'}, {value:9923, code: 'orthopedics' }, {value:2680, code: 'surgery' }]
    },
    {
        code: '3382',
        value: 7380,
        dataTooltip: [{value:7380, code: 'therapy'}]
    },
    {
        code: '6461',
        value: 11617,
        dataTooltip: [{value:5806, code: 'therapy'}, {value:10525, code: 'surgery' }, {value:18520, code: 'orthopedics' }]
    },
    {
        code: '12230',
        value: 17622,
        dataTooltip: [{value:32227, code: 'orthopedics'}, {value:3017, code: 'therapy' }]
    },
    {
        code: '13447',
        value: 5252,
        dataTooltip: [{value:9050, code: 'therapy'}, {value:1453, code: 'children' }]
    },
    {
        code: '13641',
        value: 2325,
        dataTooltip: [{value:2870, code: 'children'}, {value:2904, code: 'surgery' }, {value:1200, code: 'therapy' }]
    },
    {
        code: '13644',
        value: 8326,
        dataTooltip: [{value:8326, code: 'therapy'}, {value:1453, code: 'children' }]
    },
    {
        code: '13655',
        value: 3238,
        dataTooltip: [{value:3193, code: 'children'}, {value:4890, code: 'therapy' }, {value:1630, code: 'surgery' }]
    },
    {
        code: '13657',
        value: 3090,
        dataTooltip: [{value:3090, code: 'orthopedics'}]
    },
    {
        code: '13658',
        value: 2400,
        dataTooltip: [{value:2400, code: 'orthopedics'}]
    },
    {
        code: '13659',
        value: 1600,
        dataTooltip: [{value:1600, code: 'therapy'}]
    },
    {
        code: '13660',
        value: 5852,
        dataTooltip: [{value:5852, code: 'surgery'}]
    }
] as {code: string, value: number, dataTooltip: {value: number, code: string}[]}[]

export const dataBarTwo = [
    {
        code: 'children',
        value: 2505,
        dataTooltip: [{value:1453, code: '13447'}, {value:2870, code: '13641'}, {value:3193, code: '13655'}]
    },
    {
        code: 'implantology',
        value: 64485,
        dataTooltip: [{value:64485, code: '2614'}]
    },
    {
        code: 'orthopedics',
        value: 19255,
        dataTooltip: [{value:49371, code: '2614'}, {value:9923, code: '2924'}, {value:18520, code: '6461'}, {value:32227, code: '12230'}, {value:3090, code: '13657'}, {value:2400, code: '13658'}]
    },
    {
        code: 'surgery',
        value: 4669,
        dataTooltip: [{value:4421, code: '2614'}, {value:2680, code: '2924'}, {value:10525, code: '6461'}, {value:2904, code: '13641'}, {value:1630, code: '13655'}, {value:5852, code: '13660'}]
    },
    {
        code: 'therapy',
        value: 5639,
        dataTooltip: [{value:4421, code: '2614'}, {value:2680, code: '2924'}, {value:10525, code: '6461'}, {value:2904, code: '13641'}, {value:1630, code: '13655'}, {value:5852, code: '13660'}]
    }
] as {code: string, value: number, dataTooltip: {value: number, code: string}[]}[]
