import {dateStringFilled, phoneFilled, stringFilled, maskFilled} from "../../../common/helpers/validation";
import {over14YearsOld} from "../helper/over14YearsOld";
import {PropsUseValidateForm} from "../types/hooks";

export default function useValidateForm({fields}: PropsUseValidateForm) {
    return (form: any, showFields: string[]) => {
        return showFields
            .filter(fieldName => !!fields[fieldName] && fields[fieldName].required)
            .every(fieldName => {
                const value = form[fieldName]
                switch (fields[fieldName].type) {
                    case "phone":
                        return phoneFilled(value)
                    case "date":
                        return dateStringFilled(value)
                    case "enum":
                        if (fieldName === 'phoneOwner') {
                            return !!form.currentPatient ? (value !== null || value?.value === 'other') : value !== null
                        } else {
                            return value !== null
                        }
                    case "string":
                        if (!!fields[fieldName].mask) {
                            return stringFilled(value) && maskFilled(value)
                        } else {
                            return stringFilled(value)
                        }
                    case "boolean":
                        if (fieldName === 'hasMissingTeeth') {
                            return over14YearsOld(form.personalBirthday) ? value !== null : true
                        } else {
                            return value !== null
                        }
                    default:
                        return true
                }
            });
    }
}
