import React, {useEffect, useState} from 'react';
import {SPopupBtns, SPopupContent, SWrapConfirmDelete} from "./style";
import Button from "../../../components-ui/Inputs/Button/Button";

interface Props {
    descrText: string
    submitText: string
    onClose: () => void
    onSumbit: () => void
}

const ConfirmModal = ({descrText, submitText, onClose, onSumbit}: Props) => {

    const [loader, setLoader] = useState(false)

    const submitHandler = () => {
        setLoader(true)
        onSumbit()
    }
    useEffect(() => {
        return () => {setLoader(true)}
    })

    return (
        <SWrapConfirmDelete>
            <SPopupContent>
                <p>{descrText}</p>
            </SPopupContent>
            <SPopupBtns>
                <Button variant="danger"
                        size="long"
                        children={submitText}
                        loader={loader}
                        onClick={submitHandler}/>
                <Button variant="default"
                        size="long"
                        children="Отменить"
                        onClick={onClose}/>
            </SPopupBtns>
        </SWrapConfirmDelete>

    );
};

export default ConfirmModal;