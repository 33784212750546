import {useMemo} from "react";
import {VisitsReport} from "../../../../types";
import {DoubleBarDataItem} from "../../../../../../components-ui/Charts/DoubleBar";
import Helper from "../../../../../../common/helpers/main";

interface Props {
    report: VisitsReport
    startDate: string
    endDate: string
}

const useData = ({report, startDate, endDate}: Props) => {
    return useMemo(() => {
        return Helper.Date.getDaysArray(startDate, endDate).map(code => {
            return {
                code,
                barOne: !!report[code] ? !!report[code].general ? report[code].general : 0 : 0,
                barTwo: !!report[code] ? !!report[code].started ? report[code].started : 0 : 0,
            }
        }) as DoubleBarDataItem[]
    }, [JSON.stringify(report), startDate, endDate])
}

export default useData
