import {TasksInfo} from "../types";

interface Props {
    cloneAmount: string[]
    tasksInfo: TasksInfo
}

const prepareGroupsButtonName = ({cloneAmount, tasksInfo}: Props) => {
    const res = [] as string[][];

    let prevWidth = 0;
    let prevKey = '';
    cloneAmount
        .filter(amountName => amountName === 'all' || !!tasksInfo[amountName])
        .forEach((key, index) => {
        if (prevKey === '') {
            prevKey = key;
            prevWidth += tasksInfo[key].widthButton;
        } else if (prevWidth + tasksInfo[key].widthButton <= 368) {
            res.push([prevKey, key])
            prevKey = '';
            prevWidth = 0;
        } else if (prevWidth + tasksInfo[key].widthButton > 368) {
            res.push([prevKey])
            prevKey = key;
            prevWidth = tasksInfo[key].widthButton;
        }
        if (index === cloneAmount.length - 1) {
            if (!!res.length) {
                if (!res[res.length - 1].some(keys => keys.includes(key))) {
                    res.push([key])
                }
            } else {
                res.push([key])
            }
        }
    })

    return res;
}

export default prepareGroupsButtonName
