import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonSelectOption} from "../../data";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {IStateCashReturn} from "../../types";
import {useSelector} from "react-redux";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import Box from "../../../../../components-ui/Layout/Box";
import Helper from "../../../../../common/helpers/main";

interface Props {
    state: IStateCashReturn
    setState: Dispatch<SetStateAction<IStateCashReturn>>
}

const CashBox = ({state, setState}: Props) => {

    const cashboxOptions = useSelector(state => state.common.cashboxOptions);

    const handleCashbox = useCallback((selectedCashbox: OptionSelect) => {
        setState(prevState => ({...prevState, selectedCashbox}))
    }, [setState])
    return (
        <Label labelText="Касса" {...commonLabelOption} >
            <Box>
                <Select
                    {...commonSelectOption}
                    options={cashboxOptions}
                    value={state.selectedCashbox}
                    onChange={handleCashbox}
                />
                {!!state.cashboxSum[state.selectedCashbox.value] &&
                    <Typography variant="small" fontStyle="italic" minWidth="356px" margin={'5px 0 0 0'}>
                    Остаток: {Helper.String.numberFormat(state.cashboxSum[state.selectedCashbox.value])} руб.
                    </Typography>
                }
            </Box>
        </Label>
    );
};

export default CashBox;
