import React, {useEffect, useMemo, useState} from "react";
import {useEditableField} from "../EditableField";
import ServerCommand from "../../../common/server/server-command";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import SaveButtons from "./SaveButtons";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import { defaultOptionsPhoneOwner } from "../../PatientField";
import preparePhoneOwnerName from "../../PatientField/helper/preparePhoneOwnerName";
import Select from "../../../components-ui/Inputs/Select/Select";
import {Label} from "../../../components-ui/DataDisplay/Label";
import _ from 'underscore';
import {ResponsePatientList} from "../../PatientField/types/common";

interface SFieldPhone {
    personalPhone: string,
    phoneOwner: null | OptionSelect,
    additionalPhone: string,
    addPhone: boolean,
    validPersonalPhone: boolean,
    validPhoneOwner: boolean,
    samePhonePatients: any
}

export default function FieldPhone() {
    const {field, patientId, setEditField} = useEditableField();
    const {values, onChange} = field;

    const [state, setState] = useState<SFieldPhone>({
        personalPhone: _.clone(values.personalPhone.value),
        additionalPhone: values.additionalPhone.value,
        addPhone: !!values.additionalPhone.value,
        validPersonalPhone: false,
        validPhoneOwner: false,
        phoneOwner: null,
        samePhonePatients: values.samePhonePatients.value
    })

    const getOptionsPhoneOwner = (samePhonePatients: any) => {
        const optionsPatients = samePhonePatients.map((patient: any, index: number) => ({
            label: preparePhoneOwnerName(patient),
            value: patient.id,
            sort: index + 2
        }))
        return defaultOptionsPhoneOwner.concat(optionsPatients).sort((a: any, b: any) => a.sort > b.sort ? 1 : -1)
    }

    const optionsPhoneOwner = useMemo(() => {
        return getOptionsPhoneOwner(state.samePhonePatients)
    }, [state.samePhonePatients])

    useEffect(() => {
        if (!!values.phoneOwner.value) {
            setState(prevState => {
                const phoneOwner = optionsPhoneOwner.find(option => option.value === values.phoneOwner.value);
                return {
                    ...prevState,
                    phoneOwner: !!phoneOwner ? phoneOwner : null
                }
            })
        }
    }, [])

    useEffect(() => {
        if (values.personalPhone.validation) {
            setState(prevState => ({
                ...prevState,
                validPersonalPhone: !!values.personalPhone.validation
            }))
        }
    }, [values.personalPhone.validation])

    useEffect(() => {
        if (state.personalPhone.length !== 11) {
            setState(prevState => ({...prevState, phoneOwner: null}))
        } else {
            if (state.personalPhone === values.personalPhone.value) {
                const newOptions = getOptionsPhoneOwner(values.samePhonePatients.value);
                setState(prevState => {
                    const phoneOwner = newOptions.find(option => option.value === values.phoneOwner.value);
                    return {
                        ...prevState,
                        phoneOwner: !!phoneOwner ? phoneOwner : null,
                        samePhonePatients: values.samePhonePatients.value
                    }
                })
            } else {
                const data = {
                    filter: {personalPhone: state.personalPhone},
                    limit: 50,
                    select: ['personalPhone', 'name', 'lastName', 'secondName', 'id', 'personalBirthday', 'phoneOwner'],
                    getRelationsToPatient: patientId
                } as any;
                ServerCommand.get('patient/list', data).then((response: ResponsePatientList) => {
                    const newSamePhonePatients = !!response.list ? response.list : [] as any;
                    const newOptions = getOptionsPhoneOwner(newSamePhonePatients);
                    const findSelfPatient = newSamePhonePatients.find((patient: any) => patient.phoneOwner === 'self');
                    let phoneOwner = null as null | OptionSelect;
                    if (!!findSelfPatient) phoneOwner = newOptions.find(option => option.value === findSelfPatient.id) as OptionSelect;
                    setState(prevState => ({
                        ...prevState,
                        phoneOwner: !!phoneOwner ? phoneOwner : null,
                        samePhonePatients: newSamePhonePatients
                    }))
                });
            }
        }
    }, [state.personalPhone])

    const onChangePersonalPhone = (value: string) => {
        setState(prevState => {
            let newValid = prevState.validPersonalPhone;
            if (value.length) newValid = false;
            return {
                ...prevState,
                personalPhone: value.replace(/[-+_()\s]/g, ''),
                validPersonalPhone: newValid
            }
        })
    }

    const onChangeAdditionalPhone = (value: string) => {
        setState(prevState => {
            return {
                ...prevState,
                additionalPhone: value.replace(/[-+_()\s]/g, '')
            }
        })
    }

    const onClickSave = () => {
        const {personalPhone, additionalPhone, phoneOwner} = state;

        if (personalPhone.length !== 11 || !phoneOwner) {
            setState(prevState => {
                return {
                    ...prevState,
                    validPersonalPhone: personalPhone.length !== 11,
                    validPhoneOwner: !phoneOwner
                }
            })
            return;
        }

        const data = {id: patientId, personalPhone, additionalPhone, phoneOwner: phoneOwner.value}

        if (additionalPhone.length !== 11 || !state.addPhone) data.additionalPhone = ''

        const command = new ServerCommand('patient/edit', data);
        command.exec().then(() => {
            onChange({personalPhone, additionalPhone: data.additionalPhone, phoneOwner: phoneOwner.value})
            setEditField(false);
        });
    }

    return (
        <div>
            <TextField onChange={onChangePersonalPhone}
                       value={state.personalPhone}
                       autoFocus={true}
                       mask="+7 (999) 999-99-99"
                       widthInput="290px"
                       margin="0 0 10px"
                       isError={state.validPersonalPhone}
            />
            <Label labelText="Владелец телефона"
                   isRequired={true}
                   colorLabel="textGray"
                   align="topLeft"
                   marginWrap="0 0 15px"
                   widthLabelWrap="290px"
            >
                <Select options={optionsPhoneOwner}
                        value={state.phoneOwner}
                        widthSelect="290px"
                        widthMenu="290px"
                        isDisabled={state.personalPhone.length !== 11}
                        placeholder="Не выбран"
                        isError={state.validPhoneOwner}
                        onChange={value => {
                            setState(prevState => ({...prevState, phoneOwner: value, validPhoneOwner: false}))
                        }}
                />
            </Label>

            <Checkbox label="Дополнительный номер"
                      checked={state.addPhone}
                      onChange={(addPhone) => setState(prevState => ({...prevState, addPhone}))}/>
            {state.addPhone && (
                <TextField onChange={onChangeAdditionalPhone}
                           value={state.additionalPhone}
                           mask="+7 (999) 999-99-99"
                           widthInput="290px"
                           margin="16px 0 0px"
                />
            )}
            <SaveButtons onClickSave={onClickSave}/>
        </div>
    )
}
