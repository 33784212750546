import React from 'react';
import {DoneEmployeeService, GetDoneServicesResult} from '../../../types';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import {useSelector} from 'react-redux';
import ButtonForOptions from '../../../../../components-ui/Inputs/ButtonForOptions/ButtonForOptions';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import DateHelper from '../../../../../common/helpers/date-helper';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import ModalDeleteDoneEmployeeService from '../modals/ModalDeleteDoneEmployeeService';
import {OptionSelect} from '../../../../../components-ui/Inputs/Select/typesSelect';
import ModalMarkDoneEmployeeService from '../modals/ModalMarkDoneEmployeeService';
import {IDoneEmployeeServiceRow} from '../types';

interface Props {
    services: GetDoneServicesResult['services'];
    refreshDataFromServer: () => void;
    selectedBranch: OptionSelect
    doneEmployeeServices: DoneEmployeeService[]
}

const useTableFormatter = ({services, refreshDataFromServer, selectedBranch, doneEmployeeServices}: Props) => {

    const {showModal} = useDialogsManager();

    const users = useSelector(state => state.common.users);

    const columns: IColumn<IDoneEmployeeServiceRow>[] = [
        {
            field: 'date',
            headerName: 'Дата',
            valueFormatter: ({childrenCell}) => <Typography>
                {DateHelper.formatDate(childrenCell, 'DD MMM YYYY')}
            </Typography>,
        },
        {
            field: 'technic',
            headerName: 'Техник',
            valueFormatter: ({row}) => <>
                {row.employee.label}
            </>,
        },
        {
            field: 'doctor',
            headerName: 'Врач',
            valueFormatter: ({row}) => <>
                {row.doctor.label}
            </>,
        },
        {
            field: 'service',
            headerName: 'Услуга',
            valueFormatter: ({row}) => <>
                {row.service.label}
            </>,
        },
        {
            field: 'quantity',
            headerName: 'Количество',
        },
        {
            field: 'buttonForOptions',
            width: "50px",
            valueFormatter: ({row}) =>
                <ButtonForOptions position='left-bottom'
                                  options={[
                                      {
                                          value: 'Изменить',
                                          label: 'Изменить',
                                          onClick: () => showModal(
                                              <ModalMarkDoneEmployeeService
                                                  service={row}
                                                  refreshDataFromServer={refreshDataFromServer}
                                                  selectedBranch={selectedBranch}
                                                  services={services}
                                              />,
                                          ),
                                      },
                                      {
                                          value: 'Удалить',
                                          label: 'Удалить',
                                          onClick: () => showModal(
                                              <ModalDeleteDoneEmployeeService
                                                  id={row.id}
                                                  refreshDataFromServer={refreshDataFromServer} />,
                                          ),
                                      },
                                  ]} />,
        },
    ];

    const rows = doneEmployeeServices.map(row => ({
        id: row.id,
        date: row.date,
        service: {value: row.serviceId, label: services.find(service => service.id === row.serviceId)?.name},
        employee: {value: row.employeeId, label: users[row.employeeId].fio},
        doctor: {
            value: row.doctorId,
            label: users[row.doctorId].fio,
            color: users[row.doctorId].color
        },
        quantity: row.quantity
    } as IDoneEmployeeServiceRow))

    return {columns, rows};
};

export default useTableFormatter;
