import {useCallback, useEffect, useRef, useState} from 'react';
import ServerCommand from '../../../../common/server/server-command';
import {EmployeesFilter, StateUserList, UserList} from '../types';
import {pageSize} from '../../../PatientsList/data';
import {useLazyLoading} from '../../../../common/hooks/useLazyLoading';

const useSettingsEmployees = () => {

    const refScrollList = useRef(null) as any

    const [state, setState] = useState<StateUserList>({
        usersList: null,
        page: 0,
        isAllDataLoaded: false,
        searchQuery: '',
        activeFilter: true,
        roleFilter: "",
        serverFilter: null
    })
    const loadData = (nextPage: boolean = false) => {
        /*Если все подгрузки были не завершены или
        * если текущий фильтр не равен серверному фильтру и
        * новый фильтр не равен старому фильтру*/
        if (
            !state.isAllDataLoaded ||
            state.serverFilter?.searchQuery !== (!!state.searchQuery ? state.searchQuery : undefined) ||
            state.serverFilter?.active !== state.activeFilter ||
            state.serverFilter?.role !==  (!!state.roleFilter ? state.roleFilter : undefined)
        ) {
            const currentFilter: EmployeesFilter = {};
            if (!!state.searchQuery) currentFilter.searchQuery = state.searchQuery;
            currentFilter.active = state.activeFilter;
            if (state.roleFilter) {
                currentFilter.role = state.roleFilter;
            }

            const page = nextPage ? state.page + 1 : 0;  // Количество страниц
            const data = {
                offset: page * pageSize,  // лимит умноженный на количество страниц
                limit: pageSize, // лимит данных для загрузки
                filter: currentFilter,
            };

            ServerCommand.get("user/list", data).then((response) => {
                setState(prevState => {
                    let usersList: UserList[] = []
                    if (nextPage) {
                        let prevUsersList: UserList[] = [];
                        if (!!prevState.usersList) prevUsersList = prevState.usersList
                        usersList = [...prevUsersList, ...response.list]
                    } else {
                        usersList = response.list
                    }

                    return {
                        ...prevState,
                        usersList,
                        page,
                        isAllDataLoaded: response.count === usersList.length,
                        serverFilter: currentFilter
                    }
                })
            });
        }

    };

    const refreshDataFromServer = useCallback(() => loadData(), [
        state.activeFilter,
        state.searchQuery,
        state.roleFilter
    ])

    useEffect(() => loadData(), [
        state.activeFilter,
        state.searchQuery,
        state.roleFilter
    ])

    const onScroll = useLazyLoading({
        onIntersection: () => loadData(true),
        containerRef: refScrollList
    })

    return {
        state,
        setState,
        refScrollList,
        onScroll,
        refreshDataFromServer
    }
};

export default useSettingsEmployees;
