import * as types from './actionConstants';
import {loadUser} from "./userActions";
import {loadVisits} from "./doctorRoleActions";
import {loadPatientData} from "./patientActions";
import {loadScheduleUser} from "./scheduleActions";

export const loadData = (response: any) => {
    return (dispatch: any) => {
        dispatch(loadPatientData(response.patient))
        dispatch(loadUser(response.user));
        dispatch(loadVisits(response.visits));
        dispatch(loadScheduleUser(response.user, response.initialDate))
        dispatch({
            type: types.LOAD_COMMON_DATA,
            payload: response
        });
    }
};

export const singIn = () => ({
    type: types.SIGN_IN
});

export const singUp = () => ({
    type: types.SIGN_UP
});

export const resetDataLoaded = () => {
    return {
        type: types.RESET_DATA_LOADED
    }
};

export const setServerError = (error: any) => ({
    type: types.SET_SERVER_ERROR,
    payload: error
});

export const setIsScrollArea = (visible: boolean) => {
    return {
        type: types.SET_IS_SCROLL_AREA,
        payload: visible
    }
};
