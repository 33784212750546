import React from 'react';
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";
import Helper from "../../../../common/helpers/main";

interface Props {
    contactPhone: string
}

export const ContactPhone = ({contactPhone}: Props) => {
    if (!contactPhone) return null
    return (
        <LabelTask label="Номер телефона"
                   children={Helper.Phone.format(contactPhone)}
                   alignItems="flex-start"
        />
    );
};
