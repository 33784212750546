import React from 'react';
import './Photo.scss';
import {Icon} from "../../../../components/Icons/Icons";
import PopupPhoto from "./PopupPhoto";
import ServerCommand from 'common/server/server-command';
import PropTypes from "prop-types";
import Helper from 'common/helpers/main';
import Tabs from "../../../../components-ui/Navigation/Tabs/Tabs";
import TextField from "../../../../components-ui/Inputs/TextField/TextField";
import moment from "moment/moment";
import {LoadingTheClinic} from "../../../../components-ui/DataDisplay/Icon";

export default class Photo extends React.Component {

    static propTypes = {
        patientId: PropTypes.number.isRequired,
    };

    state = {
        photos: null,
        teeth: null,
        types: null,
        selectedGroupId: 0,
        openPopup: false,
        loadedFile: '',
        loadedFileUrl: '',
        selectedDate: new Date(),
        openPhotoId: null,
        editPhoto: false,
        term: '',
        currentSubTabCode: 'allPhoto'
    };

    componentDidMount() {
        this.loadingFromServer();
    }

    render() {

        if (this.state.photos === null) {
            return null
        }

        return (
            <div>
                {this.state.openPopup && this.renderPopup()}
                {(Object.keys(this.state.photos).length > 0) ? this.renderListPhoto() : this.renderAddPhoto()}
            </div>


        )
    }

    renderAddPhoto() {
        return (
            <div className="box box-photo">
                <h2>Добавление фотографий</h2>
                {/*<div className="box-photo__dnd">*/}
                {/*<span>Перетащите фото сюда</span>*/}
                {/*/!*</div>*!/  Нужно оставить так как тут потом будет dnd*/}
                <span>прикрепите с компьютера</span>
                <label className="btn btn-size-md btn-variant-success">
                    <input className="hidden-input" type="file" multiple={true} onChange={(e) => {
                        if (e.target.files.length > 1) {
                            this.handleImagesMultiChange(e)
                        } else {
                            this.handleImageChange(e)
                        }
                    }}/>
                    <span>Прикрепить</span>
                </label>
            </div>
        )
    }

    renderListPhoto() {
        let photos = Helper.clone(Object.values(this.state.photos));
        let term = this.state.term.toLowerCase();
        photos = photos.filter((photo) => {  // фильтрация по типу
            const isSuitableByType = (this.state.currentSubTabCode === photo.type || this.state.currentSubTabCode === 'allPhoto');
            return (isSuitableByType && this.isMatchingSearch(photo, term))
        });

        let subTabs = [{
            name: 'Все',
            code: 'allPhoto'
        }];
        subTabs = subTabs.concat(Helper.mapObj(this.state.types, (name, code) => ({name, code})));


        return (
            <div className="box box-photos">
                <Tabs
                    activeTab={this.state.currentSubTabCode}
                    tabs={subTabs}
                    onChange={(subTab) => this.setState({currentSubTabCode: subTab})}
                    borderBottom={true}
                />
                <div className="search">
                    <TextField onChange={(value) => this.setState({term: value})}
                               isSearch={true}
                               widthInput="100%"
                               placeholder={"Поиск по зубу"}
                               value={this.state.term}/>
                </div>
                <div className="box-body">
                    <div className="photo-list">
                        <div className="photo-item photo-item--add">
                            <div className="photo-item__wrap">
                                <input className="photo-item__input" type="file" multiple={true}
                                       onChange={(e) => {
                                           if (e.target.files.length > 1) {
                                               this.handleImagesMultiChange(e)
                                           } else {
                                               this.handleImageChange(e)
                                           }
                                       }}/>
                                <Icon icon="new-plus"/>
                            </div>
                        </div>
                        {photos.map((photo) => {
                            let resizedSrc = photo.file.resizedSrc;
                            let src = photo.file.src;

                            return (
                                <div className="photo-item" key={photo.id} onClick={() => this.setState({
                                    editPhoto: true,
                                    openPhotoId: photo.id,
                                    loadedFileUrl: src,
                                    openPopup: true
                                })}>
                                    <div className="photo-item__wrap">
                                        {!photo.file.load ? (
                                            <LoadingTheClinic/>
                                        ) : (
                                            <img src={resizedSrc} alt={photo.name}/>
                                        )}
                                    </div>
                                    <div className="photo-item__text-wrap">
                                        <div className="photo-item__text-teeth" title={photo.teeth.join(', ')}>
                                            {photo.teeth.join(', ')}
                                        </div>
                                        <div className="photo-item__text-date">
                                            {Helper.Date.formatDate(photo.date, 'll')}
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    isMatchingSearch(photo, term) {
        let result = false;
        if (term === '') {
            return true
        }
        photo.teeth.forEach((tooth) => {
            if (String(tooth).toLowerCase().indexOf(term) > -1) {
                result = true
            }
        });
        return result
    }

    handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let loadedFile = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                loadedFile: loadedFile,
                loadedFileUrl: reader.result
            });
        };

        reader.readAsDataURL(loadedFile);

        this.setState({
            editPhoto: false,
            openPopup: true
        });
    }

    handleImagesMultiChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let loadedFiles = e.target.files;

        const command = new ServerCommand('patient/file/createMulti', {
            file: [...loadedFiles],
            patientId: this.props.patientId,
        });

        command.exec().then(() => {
            this.loadingFromServer();
        });
    }

    renderPopup() {
        let photo = (this.state.openPhotoId !== null) ? this.state.photos[this.state.openPhotoId] : {};
        return (
            <PopupPhoto
                closePopup={this.closePopup.bind(this)}
                loadedFileUrl={this.state.loadedFileUrl}
                loadedFile={this.state.loadedFile}
                patientId={this.props.patientId}
                allTeeth={this.state.teeth}
                allTypes={this.state.types}
                editPhoto={this.state.editPhoto}
                date={this.state.selectedDate}
                type={this.state.currentSubTabCode !== "OTHER" ? 'XRAY' : 'OTHER'}
                {...photo}
            />
        )
    }

    closePopup() {
        this.setState({
            openPopup: false,
            openPhotoId: null,
            loadedFile: '',
            loadedFileUrl: '',
            editPhoto: false,
        });
        this.loadingFromServer();
    }

    loadingFromServer() {
        ServerCommand.get('patient/file/list', {patientId: this.props.patientId}).then((response) => {
            const photos = (response.list) ? Helper.Array.toObject(response.list, 'id') : {};

            if (!Helper.isEmptyObj(photos)) {
                Helper.forEachObj(photos, photo => {
                    if (!photo.teeth) {
                        photo.teeth = [];
                    }
                    if (!photo.file.load) {
                        this.loadFromYandex(photo.id);
                    }
                });
            }

            this.setState({
                photos,
                teeth: response.teeth,
                types: response.variantsTitles.type,
            })
        });
    }

    loadFromYandex(fileId) {
        ServerCommand.get('patient/file/yandex', {fileId}).then((response) => {
            let photo = response.data;
            if (!photo.teeth) {
                photo.teeth = [];
            }
            let prevPhoto = Helper.clone(this.state.photos);
            prevPhoto[fileId] = photo;

            this.setState({
                photos: prevPhoto
            })
        })
    }

}
