import styled from "styled-components";
import {Button, WrapButton} from "../../styledComponents";
import {PJawButton} from "./typesButtonJaw";

export const JawButtonWrap = styled(WrapButton)`
    margin-right: 16px;
    .tooth-standard {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: ${props => props.jaw === 'upper' ? '7px' : '0'};
        margin-top: ${props => props.jaw === 'upper' ? '0' : '7px'};
        stroke-width: 1px;
        stroke: ${props => props.theme.colors.svgGray};
        fill: ${props => props.theme.colors.svgGray};
        cursor: ${props => props.editField ? 'default' : 'pointer'};
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }
    }
`

export const JawButton = styled(Button)<PJawButton>`
    border-color: ${props => props.theme?.colors.borderGray};
    background: ${props => props.theme?.gradient.default};
    box-shadow: ${props => props.theme?.shadow.default};
    &:focus {
        background: ${props =>props.theme?.gradient.defaultHover};
        border-color: ${props => props.theme?.colors.borderGrayActive};
        box-shadow: ${props => props.theme?.shadow.default};
    }
    &:hover {
        background: ${props => props.theme?.gradient.defaultHover};
        border-color: ${props => props.theme?.colors.borderGrayActive};
        box-shadow: ${props => props.theme?.shadow.default};
    }
    &:active {
        background: ${props => props.theme?.colors.whiteDark};
        border-color: ${props => props.theme?.colors.borderGrayActive};
        box-shadow: ${props => props.theme?.shadow.defaultActive};
    }
    .tooth-card-svg {
        stroke: ${props => props.theme.colors.svgGray};
        height: 48px;
    }

    .tooth-card-text {
        color: ${props => props.theme?.gradient.text};;
    };
`
