import React, {Dispatch, SetStateAction} from 'react';
import {NamesSvg} from "../../../../components-ui/DataDisplay/Icon/types/common";
import Box from "../../../../components-ui/Layout/Box";
import {SWrapIcon} from './style';
import Icon from "../../../../components-ui/DataDisplay/Icon";
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import DateRange from "../../../../components-ui/Inputs/DateRange";
import Loader from "../../../../components-ui/Feedback/Loader/Loader";

interface Props {
    iconName: NamesSvg
    title: string
    startDate: any
    setStartDate: Dispatch<SetStateAction<any>>
    endDate: any
    setEndDate: Dispatch<SetStateAction<any>>
    loader: boolean
    setOpenDateRange: Dispatch<SetStateAction<boolean>>
}

const HeaderWidget = (
    {
        iconName,
        title,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        loader,
        setOpenDateRange
    }: Props) => {

    return (
        <Box display="flex" width="100%" alignItems="center" justifyContent="space-between" margin="0 0 16px">
            <Box display="flex" alignItems="center">
                <SWrapIcon>
                    <Icon name={iconName} width="16px" height="16px" color="white"/>
                </SWrapIcon>
                <Typography children={title} fontWeight="800"/>
            </Box>
            <Box display="flex">
                <Box position="relative" width="32px" height="32px" margin="0 15px 0 0">
                    <Loader visible={loader} isBackground={false} width={28} height={28}/>
                </Box>
                <DateRange startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}
                           position="right" maxDate={new Date()} handleOpenDateRange={() => setOpenDateRange(true)}
                           handleCloseDateRange={() => setOpenDateRange(false)}/>
            </Box>

        </Box>
    );
};

export default HeaderWidget;
