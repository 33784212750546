import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const MaterialsIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M20.78,12.16l1.37-1.36a6.33,6.33,0,0,0-8.95-9L1.85,13.2a6.33,6.33,0,0,0,9,9l1.36-1.37a6.33,6.33,0,1,0,8.62-8.62Zm-9.41,5.48a6.47,6.47,0,0,0,.23,1.71L9.83,21.12a4.93,4.93,0,1,1-7-7L8,9l4.71,4.72A6.36,6.36,0,0,0,11.37,17.64ZM17,22.57a4.89,4.89,0,0,1,0-9.71Zm-3.31-9.71L9,8.13l5.2-5.25a4.86,4.86,0,0,1,6.93,0,5,5,0,0,1,0,7l-1.76,1.78a6.35,6.35,0,0,0-1.7-.24A6.2,6.2,0,0,0,13.69,12.86Zm4.6,9.71V12.86a4.89,4.89,0,0,1,0,9.71Z"/>
        </WrapIcon>
    );
});
