import styled from "styled-components";
import Helper from "../../../../common/helpers/main";
import {PToothButton} from "../../../../components/DentalRecords/elements/ToothItem/typesToothItem";

interface IToothSelectWrap {
    selectedtooth?: any
}

export const ToothSelectWrap = styled.div.attrs({className: 'ToothSelectWrap'})<IToothSelectWrap>`
    display: flex;
    padding: 10px 20px 0;
    .icon-check {
        position: absolute;
        bottom: 10px;
        width: 10px;
        height: 8px;
        fill: #00d6d6;
    }
`;

export const ToothItemWrap = styled.div.attrs({className: 'ToothItemWrap'})`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 8px;
`;

interface IToothItem {
    active?: boolean
}

export const ToothItem = styled.div.attrs({className: 'ToothItem'})<IToothItem>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 84px;
    min-width: 36px;
    position: relative;
    padding: 0 1px 0;
    border-radius: 6px;
    border: solid 1px ${props => props.active ? props.theme.colors.info : props.theme.colors.secondary};
    cursor: pointer;
    user-select: none;
    background: ${props => props.active ? props.theme.colors.info : props.theme.colors.white};
    margin-bottom: 32px;
`;

const fillIcon = (props: any) => {
    const {theme, measure, active, status} = props;
    if (measure === 'JAW') {
        return 'none'
    } else {
        if (active) return theme.colors.white;
        switch (status) {
            case "CURED":
                return theme?.colors.purple
            case "SICK":
                return theme?.colors.danger
            case "HEALTHY":
            default:
                return theme?.colors.svgGray
        }
    }
}

const strokeIcon = (props: any) => {
    const {theme, measure, active, status} = props;
    if (measure !== 'JAW') {
        return 'none'
    } else {
        if (active) return theme.colors.white;
        switch (status) {
            case "CURED":
                return theme?.colors.purple
            case "SICK":
                return theme?.colors.danger
            case "HEALTHY":
            default:
                return theme?.colors.svgGray
        }
    }
}

export const ToothIconWrap = styled.span.attrs({className: 'ToothIconWrap'})<any>`
    width: 100%;
    height: calc(100% - 25px);
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
        width: 80%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                width: 100%;
                height: 100%;
                max-width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                fill: ${fillIcon};
                stroke: ${strokeIcon};
            }
        }
    }
`;

export const ToothText = styled.span.attrs({className: 'ToothText'})<IToothItem>`
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    height: 25px;
    padding-bottom: 7px;
    color: ${props => props.active ? props.theme.colors.white : props.theme.colors.text};
`;

export const ToothPrompt = styled.span.attrs({className: 'ToothPrompt'})`
    display: flex;
    width: max-content;
    padding: 12px 12px 19px;
    position: absolute;
    top: 91px;
    background-color: #313a45;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #ffffff;
    border-radius: 3px;
    max-width: 150px;
    cursor: default;
    z-index: 12;
    &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: 50%;
        width: 10px;
        height: 10px;
        background-color: #313a45;
        transform: translateX(-50%) rotate(45deg);
    }
`;

const rgba = (props: any) => {
  const rgb = Helper.Color.hexToRgb(props.theme.colors.textGray);
  return `rgba(${rgb}, 0.8)`
};

export const ToothSegment = styled.div.attrs({className: 'ToothSegment'})<any>`
    display: block;
    width: 100%;
    height: 25px;
    position: relative;
    padding-bottom: 7px;
    &::before {
        content: '?';
        color: ${props => props.active ? props.theme.colors.white : props.theme.colors.textGray};
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 10px;
    }
    &::after {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        border: 1px solid ${props => !props.active ? rgba(props) : props.theme.colors.white};
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

