import React from "react";
import Tabs from "../../../../../../components-ui/Navigation/Tabs/Tabs";
import {InfoBoxWrap} from "../../../../../../components-ui/styledComponent/common/wraps";
import Paper from "../../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Box from "../../../../../../components-ui/Layout/Box";
import NoDocumentsSelected from "./components/NoDocumentsSelected";
import Patient from "./components/Patient";
import {usePreparationForAdmission} from "../../PreparationForAdmission";

export default function PatientData() {
    const {state, onChangeTab} = usePreparationForAdmission();
    const {checkboxes, tabs, currentTab} = state;

    if (!Object.keys(checkboxes).length) return null;

    const isCheckboxes = Object.values(checkboxes).some(checkbox => checkbox)

    return (
        <Paper isHidden={false}>
            <Box padding="0 0 45px">
                <Box display="flex" height="60px" alignItems="flex-end">
                    {isCheckboxes && tabs.length > 0 && <Tabs
                        tabs={tabs}
                        activeTab={currentTab as string}
                        onChange={onChangeTab}
                        align="left"/>}
                </Box>
                <InfoBoxWrap>
                    <Box display={isCheckboxes ? 'block' : 'none'}>
                        <Patient/>
                    </Box>
                    <Box display={!isCheckboxes ? 'block' : 'none'}>
                        <NoDocumentsSelected/>
                    </Box>
                </InfoBoxWrap>
            </Box>
        </Paper>
    )
}
