import * as React from "react";
import {Component, createRef} from 'react';
import {TooltipAvatarProps, TooltipAvatarState} from "../types/elements";

export default class TooltipAvatar extends Component<TooltipAvatarProps, TooltipAvatarState> {

    state = {
        doctorHoverWidth: 0
    };

    private doctor = createRef<any>();

    componentDidUpdate(prevProps: TooltipAvatarProps) {

        if (prevProps.text !== this.props.text) {
            if (this.props.text !== null) {
                if (this.doctor !== null) {
                    const doctorHoverWidth = this.doctor.current.offsetWidth;
                    this.setState({doctorHoverWidth});
                }
            }
        }
    }

    render() {

        const {position, text} = this.props;
        const {doctorHoverWidth} = this.state;

        const style = {
            top: position.y - 55,
            left: position.x - (doctorHoverWidth / 2 - 10)
        };

        if (text === null) return null;

        let className = '';
        if (typeof text !== 'string') {
            className = 'big-tooltip'
        }

        return (
            <span className={"cell__doctor-hover " + className} ref={this.doctor} style={style}>
                {text}
                <span className="cell__doctor-hover-triangle" />
            </span>
        )
    }


}
