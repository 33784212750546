import {useMemo, useState} from "react";
import {initialDateRangeState} from "../utils";
import {StateAnalytics, StateAllDateRange} from "../types";
import useHandlersState from "./useHandlersState";
import useError from "./useError";
import useLoadingReports from "./useLoadingReports";
import {useSelector} from "react-redux";

const useAnalytics = () => {
    const userBranches = useSelector(state1 => state1.user.branches)
    const allBranches = useSelector(state1 => state1.common.allBranches)
    const [allDateRange, setAllDateRange] = useState<StateAllDateRange>({
        ...initialDateRangeState(),
        loader: false
    })

    const [selectedBranches, setSelectedBranches] = useState<number[]>(userBranches)

    const optionsBranches = useMemo(() => {
        return userBranches.map(branchId => ({
            value: branchId,
            label: allBranches[branchId].name
        }))
    }, [userBranches, allBranches])

    const [state, setState] = useState<StateAnalytics>({
        receipts: {
            ...initialDateRangeState(),
            loader: false
        },
        averageCheck: {
            ...initialDateRangeState(),
            loader: false
        },
        clinicLoading: {
            ...initialDateRangeState(),
            loader: false
        },
        calls: {
            ...initialDateRangeState(),
            loader: false
        },
        callsEfficiency: {
            ...initialDateRangeState(),
            loader: false
        },
        visits: {
            ...initialDateRangeState(),
            loader: false
        },
        consultations: {
            ...initialDateRangeState(),
            loader: false
        }
    })

    const {loadingReports, loaderPage, setLoaderPage} = useLoadingReports({setState, state, allDateRange, setAllDateRange, selectedBranches})

    useError({setState, setAllDateRange, setLoaderPage})


    return {
        allDateRange,
        state,
        handlers: useHandlersState({setAllDateRange, setState, loadingReports, setSelectedBranches}),
        loaderPage,
        selectedBranches,
        optionsBranches
    }

}

export default useAnalytics
