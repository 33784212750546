import {Variant} from "../../../components-ui/DataDisplay/Typography/types";

export const commonPropsTypography = {
    variant: 'small',
    lineHeight: '1.43'
} as {
    variant: Variant
    lineHeight: string
}

export const commonPropsButton = {
    size: 'sm-standard',
    fontSize: '12px',
    noShadow: true
} as any
