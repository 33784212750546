import React from 'react';
import {Label} from '../../../../../../../../components-ui/DataDisplay/Label';
import {commonLabelOption} from '../data';
import TimeCounterNew from '../../../../../../../../components-ui/Inputs/TimeCounterNew/TimeCounterNew';

interface Props {
    time: string;
    handleTime: (newTime: string) => void;
    maxValue: string;
    minValue?: string;
}

const ArrivalTimeCounter = ({time, handleTime, maxValue, minValue}: Props) => {

    return (
        <Label labelText='Время' isRequired={true} {...commonLabelOption} >
            <TimeCounterNew value={time}
                            onChange={handleTime}
                            step={5}
                            maxValue={maxValue}
                            minValue={minValue}
            />
        </Label>
    );
};

export default ArrivalTimeCounter;