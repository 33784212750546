import React from 'react'
import './Steps.scss'
import HeaderAppt from './components/HeaderAppt/HeaderAppt'
import NavAppt from '../../components-ui/Navigation/NavMenu/NavAppt/NavAppt'
import CheckupStep from "./containers/Checkup/CheckupStep"
import ServerCommand from '../../common/server/server-command';
import PropTypes from 'prop-types';
import TreatmentCourse from './containers/CaseHistory/CaseHistory';
import TreatmentStep from './containers/Treatment/TreatmentStep';
import Guarantees from "./containers/Guarantees/Guarantees";
import Helper from "../../common/helpers/main";
import TreatmentPlanStep from "./containers/TreatmentPlan/TreatmentPlanStep";
import {SlideModalContext} from "../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {formatCardNumber} from "../../common/helpers/Patient";
import ChoiceOrCompletion from "./containers/ChoiceOrCompletion/ChoiceOrCompletion";
import {CHOICE, COMPLETION} from "./containers/ChoiceOrCompletion/helper/constants";

export default class Steps extends React.Component {
    static contextType = SlideModalContext;
    state = {
        currentStep: null,
        steps: null,
        visit: null,
    };

    static propTypes = {
        visitId: PropTypes.number,
    };


    componentDidMount() {
        ServerCommand.get('visit/treatment-form', {visitId: this.props.visitId}).then(response => {
            this.setState({
                currentStep: response.currentStep,
                steps: response.steps,
                visit: response.visit
            });

            this.context.setMinimizeParams({
                icon: 'toothOutline',
                title: 'Приём пациента',
                text: `${Helper.getFio(response.visit.patient)} ${formatCardNumber(response.visit.patient.cardNumber)}`,
            })
        });
    }

    render() {
        if (!this.state.steps) return null;

        const {visit} = this.state;
        let content = null;

        switch (this.state.currentStep) {
            case "INITIAL_SURVEY":
                content = <CheckupStep goToNextStep={this.goToNextStep}
                                       visitId={this.props.visitId}
                                       cardNumber={this.state.visit.patient.cardNumber}/>;
                break;

            case "TREATMENT_PLAN":
                content = (
                    <TreatmentPlanStep
                        goToNextStep={this.goToNextStep}
                        visitId={this.props.visitId}
                        patientId={visit.patient.id}
                        isPrimaryVisit={visit.isPrimary}
                        isSocialVisit={visit.isSocial}
                        isDoctorPrimaryVisit={visit.isPrimaryForDoctor}
                        nextStep={this.getNextStep('TREATMENT_PLAN')}
                        visit={visit}
                    />
                );
                break;
            case "CHOICE":
                content = <ChoiceOrCompletion goToNextStep={this.goToNextStep}
                                              visitId={this.props.visitId}
                                              parentName={CHOICE}
                                              patient={visit.patient}
                                              isSocialVisit={visit.isSocial}/>;
                break;
            case "TREATMENT":
                content = <TreatmentStep patientId={visit.patient.id}
                                         goToNextStep={this.goToNextStep}
                                         visitId={this.props.visitId}
                                         nextStep={this.getNextStep('TREATMENT')}/>;
                break;
            case "CASE_HISTORY":
                content =
                    <TreatmentCourse goToNextStep={this.goToNextStep} visitId={this.props.visitId}/>;
                break;
            case "WARRANTIES":
                content = <Guarantees goToNextStep={this.goToNextStep} visitId={this.props.visitId}/>;
                break;
            case "TREATMENT_COMPLETION":
                content = <ChoiceOrCompletion parentName={COMPLETION}
                                              goToNextStep={this.goToNextStep}
                                              visitId={this.props.visitId}
                                              patient={visit.patient}
                                              isSocialVisit={visit.isSocial}
                />;
                break;
            default:
                break;
        }

        return (
            <React.Fragment>
                <HeaderAppt
                    visit={visit}
                />
                <NavAppt steps={this.state.steps} currentStep={this.state.currentStep}
                         onPassedItemClick={this.goToPrevStep}/>
                {content}
            </React.Fragment>
        )
    }

    goToNextStep = () => {
        this.setState({currentStep: this.getNextStep()});
    };

    goToPrevStep = (stepCode) => {
        const commandData = {
            visitId: this.props.visitId,
            state: stepCode
        };

        ServerCommand.run('visit/set-prev-state', commandData).then(() => {
            this.setState({currentStep: stepCode});
        });
    };

    getNextStep(stepCode = null) {
        if (stepCode === null) {
            stepCode = this.state.currentStep;
        }

        let wasDesiredStep = false;
        let nextStep = null;

        this.state.steps.forEach(step => {
            if (wasDesiredStep) {
                if (!nextStep) {
                    nextStep = step.code;
                }
            } else if (step.code === stepCode) {
                wasDesiredStep = true;
            }
        });

        return nextStep;
    }

}
