import React from "react";
import {WrapContent} from "../../../styledUI";
import {Alert} from "../../../../../components-ui/DataDisplay/Alert";

export default function AlertPage() {
    return (
        <>
            <h1>Alert</h1>
            <WrapContent>
                <pre>{'<Alert children="..." />'}</pre>
                <Alert>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem consequuntur corporis eum
                    facilis, laudantium magni maiores modi nam optio praesentium quisquam rem sequi, suscipit veritatis
                    vitae voluptatum? Quibusdam, voluptates.
                </Alert>
                <pre>{'<Alert iconName="warningOutline" children="..." />'}</pre>
                <Alert iconName="warningOutline">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem consequuntur corporis eum
                    facilis, laudantium magni maiores modi nam optio praesentium quisquam rem sequi, suscipit veritatis
                    vitae voluptatum? Quibusdam, voluptates.
                </Alert>
            </WrapContent>
        </>
    )
}
