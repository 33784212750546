import useValidateForm from "../../../../../containers/PatientField/hooks/useValidateForm";
import {useMemo} from "react";
import _ from 'lodash'
import {usePreparationForAdmission} from "../PreparationForAdmission";

export default function useValidationOfFields() {
    const {fields, forms, state} = usePreparationForAdmission()
    const validate = useValidateForm({fields})
    const {checkboxes} = state;

    return useMemo(() => {
        let result = {} as {[key: string]: boolean}

        _.forEach(forms, (form, formName) => {
            result[formName] = validate(form.basic, form.visibleFields)
        })

        return {
            isChangedBirthday: forms.patient.basic.personalBirthday !== forms.patient.currentPatient?.personalBirthday,
            validateForm: !Object.values(result).some(item => !item),
            isCheckboxes: Object.values(checkboxes).some(check => check)
        }

    }, [forms, JSON.stringify(checkboxes)])
}
