import React from 'react';
import LoaderPage from '../../../../../../components/LoaderPage';
import Typography from '../../../../../../components-ui/DataDisplay/Typography/Typography';
import Button from '../../../../../../components-ui/Inputs/Button/Button';
import useAddingEditingHandset from './hooks/useAddingEditingHandset';
import Box from '../../../../../../components-ui/Layout/Box';
import TextField from "../../../../../../components-ui/Inputs/TextField/TextField";
import Select from "../../../../../../components-ui/Inputs/Select/Select";
import CardForm from "../../../../../../components-ui/DataDisplay/CardForm";
import {commonSelectOption} from "../../../../../../content/modals/CashCreate/data";
import {HandsetDetail} from "./types";

interface AddingEditingHandsetProps {
    handsetId?: number;
    detail?: HandsetDetail
    refreshDataFromServer: () => void
}

const AddingEditingKkm = ({handsetId, detail, refreshDataFromServer}: AddingEditingHandsetProps) => {

    const {
        loadingPage,
        optionsBranches,
        state,
        setState,
        validate,
        saveHandset,
        deleteHandset,
        loadingRequest,
        validationState,
        setValidationState,
    } = useAddingEditingHandset({handsetId, refreshDataFromServer, detail});

    return (
        <>
            {loadingPage ? <LoaderPage /> :
                <>
                    <Typography variant='middle-title' fontWeight='bold'>
                        {`${handsetId ? 'Изменение' : 'Добавление'} трубки`}
                    </Typography>
                    <div style={{columnCount: 1, maxWidth: '1224px', marginTop: '15px'}}>
                        <CardForm style={{margin: '0 0 16px 0'}} title='Данные' forms={[
                            {
                                label: 'Номер телефона',
                                isRequired: true,
                                input: <TextField
                                    value={state.phoneNumber ?? ''}
                                    isTextArea={true}
                                    onChange={(value => {
                                        setState(prevState => ({
                                            ...prevState,
                                            phoneNumber: value,
                                        }))
                                    })}
                                />,
                            },
                            {
                                label: 'Филиал',
                                isRequired: true,
                                input: <Select
                                    options={optionsBranches}
                                    value={state.selectedBranch}
                                    menuPlacement="top"
                                    isMulti={true}
                                    onChange={(value => {
                                        setState(prevState => ({
                                            ...prevState,
                                            selectedBranch: value,
                                            branches: value.map((item: any) => item.value),
                                        }))
                                    })}
                                />,
                            }
                        ]} />
                    </div>
                    <Box display='flex'
                         justifyContent='flex-end'
                         maxWidth='1224px'
                         gap='25px'
                         alignItems='center'
                         padding='50px 0 0 0'>
                        {handsetId &&
                            <Button children='удалить'
                                    size='md'
                                    variant={'warning'}
                                    onClick={deleteHandset}
                            />
                        }
                        <Button children='Сохранить'
                                loader={loadingRequest}
                                size='md'
                                variant={validate() ? 'success' : 'disabled'}
                                onClick={saveHandset}
                        />
                    </Box>
                </>
            }
        </>
    );
};

export default AddingEditingKkm;
