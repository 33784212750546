import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const InspectionIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <circle cx="11.99" cy="11.99" r="11.99"/>
            <path fill="#fff"
                  d="M12,6c4.32,0,7.81,4.11,8.79,5.37a1,1,0,0,1,0,1.25C19.8,13.88,16.31,18,12,18s-7.8-4.11-8.78-5.37a1,1,0,0,1,0-1.25C4.19,10.11,7.68,6,12,6Zm0,1.65A4.27,4.27,0,0,0,7.8,12,4.28,4.28,0,0,0,12,16.34,4.28,4.28,0,0,0,16.19,12,4.28,4.28,0,0,0,12,7.65ZM12,9A3,3,0,1,1,9.1,12,2.95,2.95,0,0,1,12,9Zm-.21,1.11a1.67,1.67,0,0,0-1.62,1.68h1a.68.68,0,0,1,.66-.68h0Z"/>
        </WrapIcon>
    );
});
