import {Call, PlannedCall, PostponeServerCommand} from "../../../types/common";
import {useEffect, useMemo, useState} from "react";
import useInitialDate from "./useInitialDate";
import getMinMaxDateTime from "../helper/getMinMaxDateTime";
import {useSlideModal} from "../../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {useSelector} from "react-redux";
import Helper from "../../../../../common/helpers/main";
import {useDateUpdate} from "../../../../../common/hooks";
import {TasksInfo} from "../../../../../containers/Tasks";

interface Props {
    plannedCall: PlannedCall,
    isDetail?: boolean
    postponeServerCommand: PostponeServerCommand
    calls: Call[]
    tasksInfo: TasksInfo
}

const usePostponeCall = ({plannedCall, calls, postponeServerCommand, isDetail, tasksInfo}: Props) => {
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);
    const {setIsClose} = useSlideModal()
    const [date, setDate] = useState<any>(useInitialDate({plannedCall, tasksInfo}))
    const [switchValue, setSwitchValue] = useState(false);
    const minMaxDateTime = useMemo(() => ({...getMinMaxDateTime(date, plannedCall, currentDate)}), [date, plannedCall, currentDate])
    const user = useSelector(state => state.user);

    const filterMissedCall = () => {
        return plannedCall.tasks.filter(task => task.type !== 'missed_call')
    }

    const minTaskTime = (name: 'deadline' | 'postponedTo') => {
        const tasksFilterMissedCall = plannedCall.tasks.length === 1 && name === 'postponedTo' ? plannedCall.tasks : filterMissedCall();
        const filterTask = tasksFilterMissedCall.filter(task => task[name] !== null);
        let min = new Date(filterTask[0][name] as any)
        filterTask.forEach(task => min = new Date(task[name] as any) < min ? new Date(task[name] as any) : min)
        return min
    }

    const deadlineChange = () => {
        const tasksFilterMissedCall = filterMissedCall();
        if (tasksFilterMissedCall.some(task => task.deadline !== null)) {
            const minDeadline = minTaskTime('deadline')
            if (new Date(currentDate).getTime() >= minDeadline.getTime()) {
                setDate(null)
                setSwitchValue(false)
            } else if (date === null) {
                setDate(minDeadline)
            } else if (minDeadline.getTime() < date.getTime()) {
                setDate(minDeadline)
            }
        }
    }

    useEffect(() => {
        const oneTaskMissedCall = plannedCall.tasks.length === 1 && plannedCall.tasks[0].type === 'missed_call'
        const tasksFilterMissedCall = oneTaskMissedCall ? plannedCall.tasks : filterMissedCall();
        let newSwitchValue = switchValue;
        if (tasksFilterMissedCall.length) {
            if (tasksFilterMissedCall[0].postponedTo !== null) {
                if (!switchValue) {
                    newSwitchValue = true;
                    setSwitchValue(newSwitchValue)
                    setDate(new Date(plannedCall.tasks[0].postponedTo as any))
                }
            } else {
                if (switchValue) {
                    setSwitchValue(false)
                }
                deadlineChange()
            }
        }
        if (calls.some(phoneCall => phoneCall.contactPhone === plannedCall.phone) && newSwitchValue) {
            setIsClose(true)
        }
    }, [plannedCall])

    useEffect(() => {
        if (switchValue && plannedCall.tasks[0].postponedTo === null) {
            const timerId = setTimeout(() => {
                if (calls.some(call => call.contactPhone === plannedCall.phone && !!call.answerTime)) {
                    setIsClose(true)
                }
                postponeServerCommand(plannedCall, date)
            }, isDetail ? 0 : 3000)
            return () => clearTimeout(timerId)
        } else if (!switchValue && plannedCall.tasks[0].postponedTo !== null) {
            const timerId = setTimeout(() => {
                if (calls.some(call => {
                    return  call.contactPhone === plannedCall.phone
                        && !!call.answerTime
                        && call.userId === user.id
                })) {
                    setIsClose(false)
                }
                postponeServerCommand(plannedCall)
            }, isDetail ? 0: 3000)
            return () => clearTimeout(timerId)
        }
    }, [switchValue])

    return {
        date, setDate, switchValue, setSwitchValue, minMaxDateTime
    }

}

export default usePostponeCall
