import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth58Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 18.3"} width={width || "20"} height={height || "16"} ref={ref}>
            <path d="M22.06,17.85c-1.57,1.1-4.32,0-6.74-1a12.52,12.52,0,0,0-4.22-1.24,9,9,0,0,0-3.38,1c-1.72.77-3.51,1.56-4.83.87C1.75,16.94.94,15.33.36,12.78-.79,7.84.81,4.09,5,1.68a13.42,13.42,0,0,1,13.27.15C22,4,24,7.06,24,10.93c0,3.64-.66,6.06-1.94,6.92Z"/>
        </WrapIcon>
    );
});
