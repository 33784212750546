import React from "react";
import {Icon} from "../../../../components/Icons/Icons";


export const getMeasureIcon = (measure: string) => {
    let icon;

    switch (measure) {
        case 'JAW':
            icon = <Icon icon="new-jaw"/>;
            break;
        case 'BOTH_JAWS':
            icon = <Icon icon="new-both_jaw"/>;
            break;
        case 'ORAL_CAVITY':
            icon = <Icon icon="new-oral_cavity"/>;
            break;
        case 'PRODUCT':
            icon = <Icon icon="new-product"/>;
            break;
        case 'SEGMENT':
            icon = <Icon icon="new-segment"/>;
            break;
        default:
        case 'TOOTH':
            icon = <Icon icon="new-tooth"/>;
            break;
    }

    return icon;
};
