import React, {useCallback, useMemo, useState} from 'react';
import {CartesianGrid, ResponsiveContainer, Tooltip, YAxis, BarChart, Bar, Cell} from "recharts";
import CustomTooltip from "./components/CustomTooltip";
import CustomTick from "../components/CustomTick";
import Helper from "../../../common/helpers/main";
import _ from 'lodash'
import {SWrapChartBar} from "./style";

export interface BarDataItem {
    code: string
    value: number
    dataTooltip?: {value: number, code: string}[]
}

interface Props {
    data: BarDataItem[]
    colors?: { [key: string]: string }
    activeCell?: string | number | null
    hoverIndex?: number
    setActiveIndex?: (index: number) => void
    formatterValue?: (value: number) => string
    formatterTitleTooltip?: (value: string) => any
    formatterNameTooltip?: (name: any) => any
    width: string | number
    height: string | number
    paddingTooltip?: string
}


const ChartBar = (
    {
        data,
        colors,
        width,
        height,
        formatterValue,
        formatterTitleTooltip,
        hoverIndex,
        formatterNameTooltip,
        setActiveIndex,
        paddingTooltip
    }: Props) => {

    const [showTooltip, setShowTooltip] = useState(false)

    const onPieEnter = useCallback(
        (_, index) => {
            if (!!setActiveIndex) setActiveIndex(index);
            setShowTooltip(true)
        },
        [setActiveIndex]
    );

    const maxValue = _.maxBy(data, 'value')
    const commonProps = {} as any;
    if (!!maxValue) {
        commonProps.domain = [0, maxValue.value]
    }

    const widthYAxis = useMemo(() => {
        if (!!maxValue) {
            const max = Math.ceil(maxValue.value / 10) * 10;
            const formattedValue = !!formatterValue ? formatterValue(max) : String(max)
            return !!formattedValue ? formattedValue.length * 7.5 : 7.5
        } else {
            return 9.5
        }

    }, [JSON.stringify(data)])

    return (
        <SWrapChartBar>
            <ResponsiveContainer width={width} height={height}>
                <BarChart data={data} onMouseLeave={() => {
                    !!setActiveIndex && setActiveIndex(-1)
                    setShowTooltip(false)
                }}>
                    <CartesianGrid vertical={false}/>
                    <YAxis dataKey="value"
                           {...commonProps}
                           tick={<CustomTick type="y" textAnchor="end" propsX={-1} propsY={4} formatterValue={formatterValue}/>}
                           axisLine={false}
                           tickLine={false}
                           tickFormatter={value => !!formatterValue ? formatterValue(value) : value}
                           width={widthYAxis}
                    />
                    {showTooltip && (
                        <>
                            <Tooltip content={<CustomTooltip formatterTitleTooltip={formatterTitleTooltip}
                                                             formatterValue={formatterValue}
                                                             formatterNameTooltip={formatterNameTooltip}
                                                             paddingTooltip={paddingTooltip}/>}
                                     cursor={{fill: 'transparent'}}
                                     isAnimationActive={false}
                            />
                        </>
                    )}

                    <Bar dataKey="value"
                         fill='#447ce6'
                         onMouseEnter={onPieEnter}
                         onMouseLeave={() => {
                             setShowTooltip(false)
                             if (!!setActiveIndex) setActiveIndex(-1);
                         }}
                         radius={[2, 2, 0, 0]}
                    >
                        {data.map((item, index) => {
                            let currentFill = '#447ce6';
                            if (!!colors && !!colors[item.code]) currentFill = colors[item.code];
                            let strokeWidth = 0;
                            if (hoverIndex !== -1) {
                                if (hoverIndex === index) {
                                    strokeWidth = 4;
                                } else {
                                    currentFill = `rgba(${Helper.Color.hexToRgb(currentFill)}, 0.5)`
                                }
                            }

                            return (
                                <Cell key={`cell-${index}`} fill={currentFill} strokeWidth={strokeWidth}
                                      stroke={`rgba(${Helper.Color.hexToRgb(currentFill)}, 0.5)`}/>
                            )
                        })}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </SWrapChartBar>
    );
};

export default ChartBar;
