import React from 'react';
import {setSwitchFilter} from "../../../../../redux/actions/scheduleActions";
import Switch from "../../../../../components-ui/Inputs/Switch/Switch";
import {useDispatch, useSelector} from "react-redux";

const NonWorkingChairs = () => {
    const dispatch = useDispatch();
    const userRole = useSelector(state => state.user.role);
    const switcher = useSelector(state => state.schedule.switcher);
    if (userRole === 'doctor') return null
    return (
        <Switch onChange={() => dispatch(setSwitchFilter())}
                checked={switcher}
                children="Нерабочие кресла"/>
    );
};

export default NonWorkingChairs;
