import React, {Dispatch, SetStateAction} from "react";
import TitleTask from "../../../../components/TitleTask";
import TagsSimpleTask from "./components/TagsSimpleTask";
import useDeadlineSimpleTask from "../../hooks/useDeadlineSimpleTask";
import {PlannedCall, TaskCallCenter} from "../../../../types/common";
import {StateCallCenter} from "../../../../types/components";
import {TasksInfo} from "../../../../../../containers/Tasks";
import {SContainerWrap, SContainerItem, SBoxFlex} from "../../../../../../containers/Tasks/style";
import {useDialogsManager} from "../../../../../../containers/DialogsManager/DialogsManager";

interface Props {
    plannedCall: PlannedCall
    setState: Dispatch<SetStateAction<StateCallCenter>>
    uniqType: TaskCallCenter[]
    tasksInfo: TasksInfo
    uniqPatient: TaskCallCenter[]
}

const SimpleCall = ({setState, plannedCall, uniqType, tasksInfo, uniqPatient}: Props) => {
    const allDeadline = useDeadlineSimpleTask({plannedCall});
    const isPostponedTo = plannedCall.tasks.some(task => !!task.postponedTo);
    return (
        <SContainerWrap isDeadline={allDeadline} isPostponedTo={isPostponedTo}
                        isOutgoing={plannedCall.outgoing} onClick={() => setState(prevState => ({...prevState, detail: plannedCall}))}>
            <SBoxFlex padding="11px 0 17px">
                <SContainerItem>
                    <TagsSimpleTask uniqType={uniqType} tasksInfo={tasksInfo}/>
                    <TitleTask plannedCall={plannedCall} uniqPatient={uniqPatient} uniqType={uniqType} noMargin={true} />
                </SContainerItem>
            </SBoxFlex>
        </SContainerWrap>
    )
}

export default SimpleCall
