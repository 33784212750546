import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';
import {SalaryReport, SavePayment, SelectedMonth} from '../../../types';
import Table, {IColumn, IRow} from '../../../../../components-ui/DataDisplay/Table';
import {useSelector} from 'react-redux';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import {
    DEFAULT_PADDING_CELLS,
    DEFAULT_PADDING_HEADER_CELLS,
} from '../../../../../components-ui/DataDisplay/Table/common';
import TextField from '../../../../../components-ui/Inputs/TextField/TextField';
import ServerCommand from '../../../../../common/server/server-command';
import _ from 'lodash';
import moment from 'moment';
import DateHelper from '../../../../../common/helpers/date-helper';
import Box from '../../../../../components-ui/Layout/Box';
import ButtonForOptions from '../../../../../components-ui/Inputs/ButtonForOptions/ButtonForOptions';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import SalariesModalMarkComment from '../modals/SalariesModalMarkComment';
import formulaTypesColumns from '../formulaTypesColumns';
import PriceHelper from '../../../../../common/helpers/price-helper';
import Icon from '../../../../../components-ui/DataDisplay/Icon';
import Tooltip from '../../../../../components-ui/DataDisplay/Tooltip/Tooltip';
import { CustomCell } from '../../../../../components-ui/DataDisplay/Table/style/scrollStyledComponents';
import {WIDTH_TABLE_COLUMNS} from '../common';
import CashReturn from "../../../../../content/modals/CashReturn";
import CashSalary from "../../../../../content/modals/CashSalary";

interface Props {
    salaryReport: SalaryReport;
    selectedMonth: SelectedMonth;
    setSalaryReport: Dispatch<SetStateAction<SalaryReport>>;
    selectedBranches: number[];
    loadingFromServer: () => void;
}

const useTablesFormatter = ({salaryReport, selectedMonth, setSalaryReport, selectedBranches, loadingFromServer}: Props) => {

    const users = useSelector(state => state.common.users);

    const branches = useSelector(state => state.common.allBranches);

    const directions = useSelector(state => state.common.directions);

    const services = useSelector(state => state.common.servicesNames);

    const xrayPictureTypes = useSelector(state => state.common.xrayPictureTypes);

    const [showDetail, setShowDetail] = useState(null);

    const {showModal} = useDialogsManager();

    const sendActualParams = (row: IRow, branchId: number, value: string, type: 'paid' | 'premium') => {
        !isNaN(+value) &&
        ServerCommand
            .post(`salary/${type === 'paid' ? 'payment' : type}/save`, {
                month: String(moment(`${selectedMonth.year}-${selectedMonth.month}`).format(DateHelper.FORMAT_SERVER_MONTH)),
                employeeId: row.id,
                branchId,
                sum: +value,
            } as SavePayment).then();
    };

    const sendDebounced = useCallback(_.debounce(sendActualParams, 3000), [selectedMonth]);

    const onChangeActualParams = (row: IRow, branchId: number, value: string, type: 'paid' | 'premium') => {
        setSalaryReport(prevState => ({
            categories: prevState.categories.map(category =>
                ({
                    ...category,
                    employees: category.employees.map(employee =>
                        employee.employeeId !== row.id ? employee :
                            {
                                ...employee,
                                branches: {
                                    ...employee.branches,
                                    [branchId]: {...employee.branches[branchId], [type]: value},
                                },

                            },
                    ),
                }),
            ),
        }));
        sendDebounced(row, branchId, value, type);
    };

    const changeComment = (row: IRow, branchId: number, value: string | null) => {
        setSalaryReport(prevState => ({
            categories: prevState.categories.map(category =>
                ({
                    ...category,
                    employees: category.employees.map(employee =>
                        employee.employeeId !== row.id ? employee :
                            {
                                ...employee,
                                branches: {
                                    ...employee.branches,
                                    [branchId]: {...employee.branches[branchId], premiumComment: value},
                                },

                            },
                    ),
                }),
            ),
        }));
    };

    const showRowDetail = (row: IRow, branchId: number, isShow: boolean) => {
        setSalaryReport(prevState => ({
            categories: prevState.categories.map(category =>
                ({
                    ...category,
                    employees: category.employees.map(employee =>
                        employee.employeeId !== row.id ? employee :
                            {
                                ...employee,
                                branches: {
                                    ...employee.branches,
                                    [branchId]: {...employee.branches[branchId], showDetail: isShow},
                                },

                            },
                    ),
                }),
            ),
        }));
    };

    const SalaryTables = useMemo(() => {
        return salaryReport.categories.map(category => {
            let columns: IColumn[] = [
                {
                    field: category.code,
                    headerName: category.name,
                    paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
                    paddingCell: DEFAULT_PADDING_CELLS,
                    width: WIDTH_TABLE_COLUMNS.category,
                },
                {
                    field: 'salarySum',
                    headerName: 'Зарплата',
                    paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
                    width: WIDTH_TABLE_COLUMNS.salary,
                    valueFormatter: ({row}) =>
                        <CustomCell width='100%' padding='0 0 0 1px'>
                            {Object.keys(row.branches).map(branchId =>
                                <Typography >
                                    {PriceHelper.format(row.branches[branchId].salarySum)}
                                </Typography>,
                            )}
                        </CustomCell>,
                },
                {
                    field: 'balance',
                    headerName: 'Остаток',
                    paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
                    width: WIDTH_TABLE_COLUMNS.balance,
                    valueFormatter: ({row}) =>
                        <CustomCell width='100%' padding='0 0 0 1px'>
                            {Object.keys(row.branches).map(branchId =>
                                <Typography >
                                    {PriceHelper.format(row.branches[branchId].balance)}
                                </Typography>,
                            )}
                        </CustomCell>,
                },
                {
                    field: 'premium',
                    headerName: 'Премия / Штраф',
                    paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
                    width: WIDTH_TABLE_COLUMNS.premium,
                    valueFormatter: ({row}) =>
                        <CustomCell width='100%' padding='0 0 0 1px'>
                            {Object.keys(row.branches).map(branchId =>
                                <Box>
                                    <Box display='flex' alignItems='center' height='max-content'>
                                        <TextField
                                            widthInput='80px'
                                            size='min'
                                            isNumber={true}
                                            value={row.branches[branchId].premium !== null ? row.branches[branchId].premium : ''}
                                            onChange={(value) => onChangeActualParams(row, +branchId, value, 'premium')}
                                        />
                                        {row.branches[branchId].premiumComment &&
                                            <>
                                                <Icon width='14px'
                                                      height='14px'
                                                      margin='0 0 0 5px'
                                                      name='comment'
                                                      color='borderGray'
                                                      data-for={String(row.id)}
                                                      data-tip={row.branches[branchId].premiumComment}
                                                />
                                                <Tooltip id={String(row.id)} effect='solid' />
                                            </>
                                        }
                                    </Box>
                                </Box>
                            )}
                        </CustomCell>,
                },
                {
                    field: 'toBePaid',
                    headerName: 'К выплате',
                    paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
                    width: WIDTH_TABLE_COLUMNS.toBePaid,
                    valueFormatter: ({row}) =>
                        <CustomCell width='100%' padding='0'>
                            {Object.keys(row.branches).map(branchId =>
                                <Typography >
                                    {PriceHelper.format(
                                        row.branches[branchId].salarySum +
                                        row.branches[branchId].balance +
                                        (!!+row.branches[branchId].premium ?
                                                +row.branches[branchId].premium : 0
                                        ),
                                    )
                                    }
                                </Typography>,
                            )}
                        </CustomCell>,
                },
                {
                    field: 'paid',
                    headerName: 'Выплачено',
                    paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
                    width: WIDTH_TABLE_COLUMNS.paid,
                    valueFormatter: ({row}) =>
                        <CustomCell width='100%' padding='0'>
                            {Object.keys(row.branches).map(branchId =>
                                <Box>
                                    <Box display='flex' justifyContent='space-around' alignItems='center' height='max-content'>
                                        <TextField
                                            widthInput='110px'
                                            size='min'
                                            isNumber={true}
                                            min={0}
                                            value={+row.branches[branchId].paid}
                                            isDisabled={true}
                                            onChange={(value) => onChangeActualParams(row, +branchId, value, 'paid')}
                                        />
                                        <ButtonForOptions
                                            position='left-bottom'
                                            options={[
                                                {
                                                    value: '',
                                                    label: 'Выплатить',
                                                    onClick: () => showModal(
                                                        <CashSalary userId={row.id}
                                                                    month={String(moment(`${selectedMonth.year}-${selectedMonth.month}`).format(DateHelper.FORMAT_SERVER_MONTH))}
                                                                    paid={+row.branches[branchId].paid}
                                                                    salarySum={
                                                                        row.branches[branchId].salarySum +
                                                                        row.branches[branchId].balance +
                                                                        (!!+row.branches[branchId].premium ?
                                                                                +row.branches[branchId].premium : 0
                                                                        )
                                                                    }
                                                                    loadingFromServer={loadingFromServer}/>,
                                                    ),
                                                },
                                                {
                                                    value: '',
                                                    label: 'Комментарий к премии / штрафу',
                                                    onClick: () => showModal(
                                                        <SalariesModalMarkComment
                                                            comment={row.branches[branchId].premiumComment}
                                                            row={row}
                                                            branchId={+branchId}
                                                            selectedMonth={selectedMonth}
                                                            changeComment={changeComment}
                                                        />,
                                                    ),
                                                },
                                            ]}
                                        />
                                    </Box>
                                </Box>,
                            )}
                        </CustomCell>,

                },

            ];
            const rows = category.employees.map(employee => ({
                id: employee.employeeId,
                code: category.code,
                [category.code]: users[employee.employeeId].fio,
                branches: employee.branches,
            }));

            columns.splice(1, 0, formulaTypesColumns({category, directions, services, xrayPictureTypes, showRowDetail}));

            if (selectedBranches.length > 1) columns.splice(1, 0,
                {
                    field: 'branches',
                    headerName: 'Филиал',
                    width: '170px',
                    paddingHeaderCell: DEFAULT_PADDING_HEADER_CELLS,
                    valueFormatter: ({row}) =>
                        <CustomCell width='100%' padding='0 0 0 1px'>
                            {Object.keys(row.branches).map(branchId =>
                                <Typography>{branches[branchId].name}</Typography>,
                            )}
                        </CustomCell>,
                });

            const totalSalarySum = category.employees
                .reduce((accumulatedEmployees, currentEmployee) =>
                        accumulatedEmployees + Object.keys(currentEmployee.branches)
                            .map(branchId => currentEmployee.branches[+branchId].salarySum)
                            .reduce((accumulatedSum, currentSum) => accumulatedSum + currentSum, 0)
                    , 0)

            const totaltoBePaid = category.employees
                .reduce((accumulatedEmployees, currentEmployee) =>
                        accumulatedEmployees + Object.keys(currentEmployee.branches)
                            .map(branchId => currentEmployee.branches[+branchId])
                            .reduce((accumulatedValues, currentValue) =>
                                accumulatedValues +
                                currentValue.salarySum +
                                +currentValue.balance +
                                +currentValue.premium
                                , 0)
                    , 0)

            const totalPaid = category.employees
                .reduce((accumulatedEmployees, currentEmployee) =>
                        accumulatedEmployees + Object.keys(currentEmployee.branches)
                            .map(branchId => +currentEmployee.branches[+branchId].paid)
                            .reduce((accumulatedToBePaid, currentToBePaid) => accumulatedToBePaid + currentToBePaid, 0)
                    , 0)

            return <Table
                variantTable='outline'
                isHovering={false}
                customRowsCells={true}
                columns={columns}
                rows={rows}
                total={[
                    {
                        fieldName: category.code,
                        labelWidth: '0',
                        totals: [{label: '', value: 'Итого:', isHighlighted: true}],
                    },
                    {
                        fieldName: 'salarySum',
                        labelWidth: '0',
                        totals: [{label: '', value: PriceHelper.format(totalSalarySum), isHighlighted: true}]
                    },
                    {
                        fieldName: 'toBePaid',
                        labelWidth: '0',
                        totals: [{label: '', value: PriceHelper.format(totaltoBePaid), isHighlighted: true}]
                    },
                    {
                        fieldName: 'paid',
                        labelWidth: '0',
                        totals: [{label: '', value: PriceHelper.format(totalPaid), isHighlighted: true}]
                    },
                ]}
            />;
        });
    }, [salaryReport, selectedBranches, selectedMonth]);

    return {SalaryTables};
};

export default useTablesFormatter;
