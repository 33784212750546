import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const MinusIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 2.66"} ref={ref}>
            <path d="M24,0H0V2.66H24Z"/>
        </WrapIcon>
    );
});
