import {useEffect, useState} from "react";
import ServerCommand from "../../../../../common/server/server-command";
import {ResponsePreparationForAdmission} from "../types/response";
import {PropsUseInitial} from "../types/hooks";
import _ from 'lodash'

/**
 * Загружает с сервера данные для отображения формы подготовки к приёму
 * @param props
 */
export default function useInitial({visitId}: PropsUseInitial) {
    const [data, setData] = useState<null|ResponsePreparationForAdmission>(null)

    const load = () => {
        ServerCommand.get('visit/prepare-form', {visitId}).then((response: ResponsePreparationForAdmission) => {
            setData( _.pick(response, ['data', 'documents', 'paymentSchedule', 'patient']))
        })
    }
    useEffect(() => {
        load();
    }, [])

    return {
        data,
        reloadData: load
    }
}
