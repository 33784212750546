import * as React from "react";
import Checkbox from "../../../../components-ui/Inputs/Checkbox/Checkbox";
import TreatmentPlan from "../../../TreatmentPlan/TreatmentPlan";
import {PPlanProcedureName} from "../types";
import {isSingleActiveProcedure} from "../../../../common/helpers/treatment-plan-item";

export default function PlanProcedureName({procedure, planItem, plan}: PPlanProcedureName) {

    const isProcedureDisabled = procedure.isBlocked || isSingleActiveProcedure(procedure, planItem, true);
    let tip = '';

    if (planItem.isBlocked) {
        tip = 'Услуга уже выполнена';
    } else if (isProcedureDisabled) {
        tip = procedure.isBlocked ? 'Процедура уже выполнена' : 'Нельзя сделать неактивными все процедуры в услуге';
    }

    return (
        <Checkbox
            label={procedure.name}
            checked={procedure.isActive}
            onChange={() => handleCheckPlanProcedure(procedure, planItem, plan)}
            disabled={isProcedureDisabled}
            tooltip={tip}
        />
    );
}

const handleCheckPlanProcedure = (procedure: any, planItem: any, plan: any) => {
    const planItemCode = TreatmentPlan.getPlanItemCode(planItem);
    const procedureCode = TreatmentPlan.getProcedureCode(procedure, planItem);

    plan.toggleItemProcedureActive(planItemCode, procedureCode).save();
};
