import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonSelectOption} from "../../data";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {IStateCashReturn} from "../../types";
import {useSelector} from "react-redux";
import {useOptionsSelectExpenditures} from "../../../../../common/hooks/useOptionsSelectExpenditures";

interface Props {
    state: IStateCashReturn
    setState: Dispatch<SetStateAction<IStateCashReturn>>
}

const Expenditure = ({state, setState}: Props) => {

    const expenditureOptions = useOptionsSelectExpenditures();

    const handleExpenditure = useCallback((selectedExpenditure: OptionSelect) => {
        setState(prevState => ({...prevState, selectedExpenditure}))
    }, [setState])
    return (
        <Label labelText="Причина" isRequired={true} {...commonLabelOption} >
            <Select
                {...commonSelectOption}
                options={expenditureOptions}
                value={state.selectedExpenditure}
                onChange={handleExpenditure}
            />
        </Label>
    );
};

export default Expenditure;
