import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {ConsultationsReport} from "../../../types";
import useConsultations from "./hooks/useConsultations";
import NoReport from "../../../components/NoReport";
import ContentWidgetWrap from '../../../components/ContentWidgetWrap';
import Filter from "./components/Filter";
import useTextSwirl from "./hooks/useTextSwirl";
import TwoColumnsWrap from "../../../components/TwoColumnsWrap";
import SwirlWrap from "../../SwirlWrap/SwirlWrap";
import Table from "../../../../../components-ui/DataDisplay/Table";
import {legendSwirlConsultations, legendSwirlPlans} from "./data";
import useTableConsultations from "./hooks/useTableConsultations";

interface Props {
    report: ConsultationsReport
    startDate: string
    endDate: string
    setOpenDateRange: Dispatch<SetStateAction<boolean>>
    selectedBranches: number[]
}

const Consultations = ({report, startDate, endDate, setOpenDateRange, selectedBranches}: Props) => {
    const [subTable, setSubTable] = useState<any>(null)
    const refTable = useRef(null) as any;
    const {state, handlers} = useConsultations()
    const textSwirl = useTextSwirl({state, report})
    const {columns, rows} = useTableConsultations({state, report, refTable, startDate, endDate, setSubTable, selectedBranches})
    useEffect(() => setOpenDateRange(!!subTable), [subTable])

    if (Object.keys(report.consultationsCount.doctors).length
        || Object.keys(report.consultationsCount.directions).length
        || Object.keys(report.plansSums.doctors).length
        || Object.keys(report.plansSums.directions).length) {
        return (
            <>
                <Filter state={state} handlers={handlers}/>
                <ContentWidgetWrap>
                    <TwoColumnsWrap
                        firstColumn={<SwirlWrap textSwirl={textSwirl}
                                                legends={state.currentButton === 'consultationsCount' ? legendSwirlConsultations : legendSwirlPlans}
                        />}
                        lastColumn={<Table columns={columns}
                                           rows={rows}
                                           key={String(state.currentTab) + String(state.currentButton)}
                                           variantTable="outline"
                                           maxHeight="397px"
                                           isHovering={false}
                                           ref={refTable}
                                           subTable={subTable}
                                           fontSizeRow="14px" />}/>
                </ContentWidgetWrap>
            </>
        );
    } else {
        return <NoReport/>
    }

};

export default Consultations;
