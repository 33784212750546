import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../data/commonPropsSComponent";
import React from "react";
import { STaskItem } from "../../style";

interface Props {
    label?: string,
    children?: React.ReactNode,
    isHighlighted?: boolean
    alignItems?: 'flex-start' | 'center' | 'flex-end'
}

export default function LabelTask({label, children, isHighlighted = false, alignItems}: Props) {

    return (
        <STaskItem margin="2px 0" isHighlighted={isHighlighted} alignItems={alignItems}>
            <Typography {...commonPropsTypography} children={label} flexShrink={0} padding="0 8px 0 0"/>
            <Typography {...commonPropsTypography} display="flex" alignItems="center" children={children} />
        </STaskItem>
    )
}
