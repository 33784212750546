import {useEffect, useState} from "react";
import {IColumn} from "../types/common";
import _ from 'lodash'

export interface StateSortTable {
    [key: string]: boolean | null
}

interface Props {
    columns: IColumn[]
}

const useSortTable = ({columns}: Props) => {
    const [stateColumnSort, setStateColumnsSort] = useState<StateSortTable>({})

    useEffect(() => {
        const res: StateSortTable = {};
        columns.forEach(column => {
            if (column.sortable) res[column.field] = column.defaultSort !== undefined ? column.defaultSort : null;
        })
        setStateColumnsSort(res)
    }, [])

    const handleStateColumnSort = (fieldName: string) => {
        setStateColumnsSort(prevState => {
            _.forEach(prevState, (value, key) => {
                if (key !== fieldName) prevState[key] = null
            })
            const columnFind = columns.find(column => column.field === fieldName);
            return {
                ...prevState,
                [fieldName]: prevState[fieldName] === null ? !!columnFind && columnFind.defaultSort !== undefined ? columnFind.defaultSort : false : !prevState[fieldName]
            }
        })
    }

    return {
        stateColumnSort,
        handleStateColumnSort
    }

}

export default useSortTable
