import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const SegmentIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M5.47,3.77C6.67,3.77,6.54,0,7.61,0S9.82,2.57,9.82,2.57,11.43,6,10.74,9s-4.51,1.58-5.27,1.58h0C4.66,10.57.86,12,.17,9s.9-6.42.9-6.42S2.15,0,3.28,0s1,3.77,2.14,3.77Z"/><path d="M5.47,20.23c1.2,0,1.07,3.77,2.14,3.77s2.21-2.57,2.21-2.57S11.43,18,10.74,15s-4.51-1.58-5.27-1.58h0C4.66,13.43.86,12,.17,15s.9,6.42.9,6.42S2.15,24,3.28,24s1-3.77,2.14-3.77Z"/><path d="M18.56,3.77C19.76,3.77,19.63,0,20.7,0s2.21,2.57,2.21,2.57S24.52,6,23.83,9s-4.51,1.58-5.27,1.58h0C17.75,10.57,14,12,13.26,9s.9-6.42.9-6.42S15.24,0,16.37,0s.95,3.77,2.14,3.77Z"/><path d="M18.56,20.23c1.2,0,1.07,3.77,2.14,3.77s2.21-2.57,2.21-2.57S24.52,18,23.83,15s-4.51-1.58-5.27-1.58h0C17.75,13.43,14,12,13.26,15s.9,6.42.9,6.42S15.24,24,16.37,24s.95-3.77,2.14-3.77Z"/>
        </WrapIcon>
    );
});
