import {Icon, IconTooth} from "../../../../../components/Icons/Icons";
import React from "react";
import SVG from "../../../../../common/helpers/SVG";

export default function TargetIcon({targetItem}: any) {
    switch (targetItem.measure) {
        case 'JAW':
            return <SVG name="teeth/lower-jaw"/>;
        case 'BOTH_JAWS':
            return <SVG name="teeth/both_jaw"/>;
        case 'ORAL_CAVITY':
            return <SVG name="teeth/oral_cavity"/>;
        case 'PRODUCT':
            return <SVG name="product"/>;
        case 'SEGMENT':
            return <SVG name="teeth/segment"/>;
        default:
        case 'TOOTH':
            return <SVG name={`teeth/tooth-${targetItem.name}`}/>;
    }
}
