import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const CheckedThinIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 19.2"} ref={ref}>
            <path d="M21.37,0,8.57,14,2.39,8.28,0,10.93,8.87,19.2,24,2.27Z"/>
        </WrapIcon>
    );
});
