import {useSelector} from "react-redux";
import {useMemo} from "react";


const useDoctorsColors = () => {
    const doctors = useSelector(state => state.common.doctors);

    return useMemo(() => {
        let res: {[key: string]: string} = {}
        doctors.forEach(doctor => {
            if(!!doctor.color) res[doctor.id] = doctor.color
        })

        return res;
    }, [JSON.stringify(doctors)])
}

export default useDoctorsColors
