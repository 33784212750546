import React, {useState, useRef, useMemo} from "react";
import {IButton} from "./typesButton";
import {SButton, SArrowOptions} from "./styledButton";
import Loader from "../../Feedback/Loader/Loader";
import useOutsideClick from "../../../common/hooks/useOutsideClick";
import {List, ListItem} from "../../DataDisplay/List";
import Box from "../../Layout/Box";

export default function Button(props: IButton) {
    const [openOptions, setOpenOptions] = useState(false);
    const ref = useRef(null) as any;
    useOutsideClick(ref, () => openOptions && setOpenOptions(false))
    const sizeLoader = useMemo(() => {
        switch (props.size) {
            case "sm-standard":
            case "sm":
                return 18
            default:
                return 25
        }
    }, [props.size])
    return (
        <SButton {...props} ref={ref} onClick={(event: any) => {
            if (props.variant !== 'disabled' && !props.loader) {
                if (!!props.onClick) props.onClick(event)
                if (!!props.options) setOpenOptions(!openOptions)
            }
        }}>
            {!!props.startIcon && <div className="button-start-icon">{props.startIcon}</div>}
            {!!props.children ? props.children : !!props.options ? '...' : ''}
            <Loader visible={props.loader} width={sizeLoader} height={sizeLoader} />
            {!!props.badge && <div className="button-badge">{props.badge}</div>}
            {!!props.options && openOptions && (
                <Box position="absolute" width="max-content" boxShadow="default" borderRadius="4px"
                       positionOffsets={{bottom: 'calc(100% + 20px)', right: '0'}}>
                    <SArrowOptions/>
                    <List>
                        {props.options.map(option => {
                            return (
                                <ListItem key={option.value} onClick={() => option.onClick()}>
                                    {option.label}
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            )}
        </SButton>
    )
}
