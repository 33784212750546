import React from 'react';
import Helper from "../../../../../../common/helpers/main";
import ChartLine from "../../../../../../components-ui/Charts/ChartLine";
import useLineData from "../hooks/useLineData";
import {ReceiptsReport} from "../../../../types";
import {StateReceipts} from "../types";
import useDirectionsColors from "../../../../../../common/hooks/useDirectionsColors";
import {useSelector} from "react-redux";
import useFormatXDate from "../../../../hooks/useFormatXDate";
import useUsersColor from "../../../../../../common/hooks/useUsersColor";
import useFormatterUser from "../../AverageCheck/hooks/useFormatterUser";

interface Props {
    report: ReceiptsReport
    state: StateReceipts
    startDate: string
    endDate: string
}

const ChartWidget = ({report, state, startDate, endDate}: Props) => {
    const directions = useSelector(state => state.common.directions)
    const lineData = useLineData({report, startDate, endDate, state});
    const directionsColors = useDirectionsColors()
    const doctorsColors = useUsersColor()
    const formatXDate = useFormatXDate({startDate, isDay: false})
    const formatterUser = useFormatterUser()
    if (!!lineData) {
        return (
            <ChartLine
                data={lineData}
                activeCell={state.selectedDirection}
                hoverIndex={state.hoverIndex}
                colors={state.currentTab === 'doctors' ? doctorsColors : directionsColors}
                height={180}
                width="100%"
                formatterSort={(a, b) => b - a}
                formatterCode={formatXDate}
                formatterCodeTooltip={code => Helper.Date.formatDate(code, 'MMMM YYYYг.')}
                formatterValue={value => Helper.String.numberFormat(value, 0)}
                formatterName={name => state.currentTab === 'doctors' ? formatterUser(name) : directions[name].name}
                isWidthYAxis={false}
            />
        );
    } else {
        return null
    }
};

export default ChartWidget;
