import React, {useState} from "react";
import ReactDOM from "react-dom";
import Helper from "../../../common/helpers/main";
import {SInitialSurvey, Values} from "../types";
import * as _ from "underscore";
import Printer from "../../../common/server/printer";
import {TeethMapConfig, Tooth} from "../../../common/types/teethmap";
import useLoader from "../../../components/Errors";

interface PHelper {
    state: SInitialSurvey,
    values: Values,
    setState: ((state: SInitialSurvey) => void) | any,
    sendInitialSurvey: any,
    snapshot: any,
    setSnapshot: any
}

export interface SHelperValues {
    validate: boolean,
    isDataChanged: () => boolean,
    teethMapUnconfirmed: boolean,
    loaderPrint: boolean
}

export interface SHelperHandlers {
    submitInitialSurvey: (() => void) | any,
    getValidateField: () => never,
    renderFirstErrorField: (() => void) | any,
    renderRefDentalRecords: (() => void) | any,
    scrollToField: (() => void) | any,
    scrollToDentalCard: (() => void) | any
}

let firstErrorField = null as any; // Становиться ref елементом при ошибки или использования полей values
let refDentalRecords = null as any;

export default function useHelper({state, values, setState, sendInitialSurvey, snapshot, setSnapshot}: PHelper) {

    const [validate, setValidate] = useState(false); // Валидация
    const [loaderPrint, setLoaderPrint] = useLoader();

    const getSnapshot = () => ({
        //@ts-ignore
        teethMap: state.teethMap?.config,
        values
    });

    const renderFirstErrorField = (refElement: any) => firstErrorField = refElement; // Присваиваем новый ref поля, переменной firsErrorField
    const renderRefDentalRecords = (refElement: any) => refDentalRecords = refElement;

    let allConfig = [] as Tooth[];
    if (state.teethMap !== null) allConfig = state.teethMap?.config.upper.concat(state.teethMap?.config.lower);
    const teethMapUnconfirmed = allConfig.some(tooth => tooth.unconfirmed);

    const isDataChanged = () => (!Helper.isEqual(snapshot, getSnapshot())) || teethMapUnconfirmed;

    /** Прокрутка*/
    const scrollToElement = (fieldNode: any = firstErrorField) => {
        let firstErrorFieldNode = ReactDOM.findDOMNode(fieldNode) as any;
        if (!!firstErrorFieldNode) {
            fieldNode.focus({preventScroll: true});
            firstErrorFieldNode.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        }
    };

    const scrollToField = () => {
        setValidate(true);
        scrollToElement(firstErrorField);
    }
    const scrollToDentalCard = () => scrollToElement(refDentalRecords);

    /** Получает список полей для валидации */
    const getValidateField = () => {
        let validateField = {} as any;
        _.each(state.fields, ({name, required}) => {
            const value = values[name];
            validateField[name] = !(required && (value === undefined || value.length <= 1))
        });
        return validateField
    };

    /**
     * Обходит все зубы в текущей конфигурации зубной карты, сохраняет изменения, внесённые функцией-обработчиком
     * @param handler - функция-обработчик, в которую будет передаваться каждый зуб из конфигурации зубной карты в качестве первого аргумента
     */
    const forEachTooth = (handler: any) => {
        let teethMap = _.clone(state.teethMap) as any;
        _.each(teethMap.config, (jawConfig: TeethMapConfig, jawCode) => {
            _.each(jawConfig, (tooth) => {
                handler(tooth, jawCode);
            });
        });

        setState((prev: SInitialSurvey) => ({...prev, teethMap}));
    };

    const submitInitialSurvey = () => {
        return new Promise((resolve, reject) => {
            //массив значений полей:
            let fieldsValidationValues = Object.values(getValidateField());
            let newValidate = false;
            let fieldsFilled = fieldsValidationValues.every(isFilled => isFilled === true);

            //Проверка заполненных полей
            if (fieldsFilled && !teethMapUnconfirmed) {
                setLoaderPrint(true)
                //подготовка данных для команды отправки на сервер
                let initialSurveyData = Helper.clone(values);
                initialSurveyData.teethMap = [];

                forEachTooth((tooth: any) => {
                    if (Helper.TeethMap.isToothExists(tooth.number)) {
                        initialSurveyData.teethMap.push(
                            _.pick(tooth, 'number', 'status', 'gumDisease', 'attributes', 'roots', 'canals', 'autoStatus')
                        );
                    }
                });
                initialSurveyData.teethMap.forEach((tooth: any) => {
                    if (!!tooth.attributes && tooth.attributes.length === 0) {
                        delete tooth.attributes
                    }
                })

                let snapshot = Helper.clone(getSnapshot());

                //отправка на сервер
                sendInitialSurvey(initialSurveyData).then((response: any) => {
                    setLoaderPrint(false)
                    Printer.print([response.document]).then(() => setSnapshot(snapshot));
                    resolve();
                });
            } else {
                newValidate = true
            }
            setValidate(newValidate)
            scrollToElement(firstErrorField);
        })
    };

    return [
        {validate, isDataChanged, teethMapUnconfirmed, loaderPrint},
        {submitInitialSurvey, getValidateField, renderFirstErrorField, renderRefDentalRecords, scrollToField, scrollToDentalCard}
    ] as [SHelperValues, SHelperHandlers]
}
