import styled from "styled-components";

interface PropsSScriptItem {
    active?: boolean,
    lastItem: boolean,
    open: boolean
}

export const SBoxScript = styled.div.attrs({className: 'SBoxScript'})`
  padding: 0 20px 0 0;

  .ProseMirror {
    outline: none;

    .is-empty:before {
      content: attr(data-placeholder);
      float: left;
      color: #ced4da;
      pointer-events: none;
      height: 0;
    }

    h1 {
      font-size: 1.25rem;
      margin: 0 0 14px;
    }
  }
  div[data-tippy-root] {
    bottom: 10px;
  }
  .resize-cursor {
    cursor: col-resize;
  }

  table {
    width: 100%;
    border: 1px solid ${props => props.theme.colors.borderGray}; /* граница всей таблицы */
    border-collapse: collapse;

    p {
      margin: 0;
    }

    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px
    }
  }

  td, th {
    position: relative;
    padding: 4px 5px 6px;
    border: 1px solid ${props => props.theme.colors.borderGray}; /* границы между столбцами */
    color: ${props => props.theme.colors.text};
  }

  th {
    font-size: 14px;
    text-align: left;
    background: ${props => props.theme.colors.secondary};
    font-weight: normal;
  }

  tr {
    border: 1px solid ${props => props.theme.colors.borderGray}; /* границы между строками */

    &:first-child {
      background: ${props => props.theme.colors.secondary};

      td, th {
        color: ${props => props.theme.colors.text};
        font-weight: normal;
        font-size: 14px;
      }
    }
  }

  h3 {
    font-size: 17px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 3px 0 5px 20px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 13px;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: ${props => props.theme.colors.primary};
      }

      p {
        margin: 0;
      }
    }

    ul {
      li {
        padding-left: 40px;

        &::before {
          left: 20px;
          background: transparent;
          border: 1px solid ${props => props.theme.colors.primary};
        }
      }
    }
  }

  u {
    text-decoration: none;
    border-bottom: 1px solid;
  }

  .blank, .choice {
    font-style: italic;
  }

  .choice {
    text-decoration: underline;
  }

  .blank {
    display: inline-block;
    text-decoration: underline;
  }

  .note {
    font-style: italic;
  }

  .accent {
    font-weight: bold;
  }
`

export const SScriptItem = styled.span.attrs({className: 'SScriptItem'})<PropsSScriptItem>`
    display: ${props => props.open ? 'block' : 'none'};
    padding: 5px 26px 6px 12px;
    width: 100%;
    border-radius: 4px;
    transition: background-color .3s;
    cursor: default;
    ${props => props.lastItem && `margin-bottom: 11px;`};
    ${props => !props.active && `
        cursor: pointer;
        &:hover {
            background-color: ${props.theme.colors.scriptItemBg};
        }
    `}

`

export const SScriptGroupItem = styled.span.attrs({className: 'SScriptGroupItem'})`
    padding: 8px 26px 12px 12px;
    width: 100%;
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
  button {
    opacity: 0;
    transition: .2s;
    pointer-events: none;
  }
  &:hover {
    button {
      opacity: 1;
      transition: .2s;
      pointer-events: auto;
    }
  }
`

export const SScriptBtnDots = styled.div.attrs({className: 'SScriptBtnDots'})`
    width: 100%;
    height: 40px;
    .react-tooltip {
        top: -40px !important;
        left: 164px !important;
        &::before {
            border-top: none !important;
            border-bottom: none !important;
            right: auto !important;
            top: auto !important;
            margin-top: 0 !important;
            border-left: 10px solid transparent !important;
            border-right: 10px solid transparent !important;
            bottom: -8px !important;
            left: 90% !important;
            margin-left: -10px !important;
        }
        &.place-left::after {
            border-top: 6px solid #222 !important;
            border-bottom: none !important;
            right: auto !important;
            top: auto !important;
            margin-top: 0 !important;

            border-left: 8px solid #222 !important;
            border-right: 8px solid transparent !important;
            bottom: -6px !important;
            left: 90% !important;
            margin-left: -8px !important;
        }
        &.react-tooltip--dark.place-left::after {
            border-left-color: transparent !important;
            border-top-color: rgba(49, 58, 69, 0.95) !important;
        }
    }
`

export const SWrapScriptButton = styled.div.attrs({className: 'WrapScriptButton'})`
    position: absolute;
    top: 0;
    left: -56px;
    width: 56px;
    height: 56px;
    background: ${props => props.theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px 0 0 9px;
    box-shadow: 0 3px 23px 0 rgba(46, 47, 48, 0.25);
    cursor: pointer;
    & > div {
        width: 40px;
        height: 40px;
        padding: 8px;
        border-radius: 9px;
        box-shadow: ${props => props.theme.shadow.successDefault};
        border: solid 1px ${props => props.theme.colors.successActive};
        background-color: ${props => props.theme.colors.success};
        display: flex;
        justify-content: center;
        align-items: center;
        fill: ${props => props.theme.colors.white};
        transition: background-color .3s;
        & > div {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    &:hover {
        & > div {
            background-color: ${props => props.theme.colors.successHover};
        }
    }
    &:active {
        & > div {
            background-color: ${props => props.theme.colors.successActive};
        }
    }
`

export const SButtonAuxiliaryShadow = styled.span.attrs({className: 'STabAuxiliaryShadow'})`
    width: 7px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -7px;
    background: transparent;
    box-shadow: -4px 0 3px -3px rgba(72, 85, 110, 0.4);
    z-index: 2;
`
