import * as React from "react";
import {Box, BoxBody} from "../../components-ui/styledComponent/common/wraps";
import {BoxTitle} from "../../components-ui/styledComponent/common/typography";
import Button from "../../components-ui/Inputs/Button/Button";
import {runCallTasksCreation, runManagerTasksCreation} from "./utils/commands";
import CallFakerPanel from "./components/CallFakerPanel";
import {Label} from "../../components-ui/DataDisplay/Label";
import {useState} from "react";
import DateTime from "../../components-ui/Inputs/DateTime/DateTime";
import {onChangeDateTime} from "../../components-ui/Inputs/DateTime/typesDateTime";

export default () => {
    const [careDate, setCareDate] = useState<Date>(new Date());
    const [surveyInviteDate, setSurveyInviteDate] = useState<Date>(new Date());
    const [leadPlanDate, setLeadPlaneDate] = useState<Date>(new Date());

    return (
        <Box withMargin>
            <BoxBody>
                <BoxTitle children="Панель тестирования"/>

                <h3>Запуск создания задач по обзвону</h3>
                <Label labelText="Дата проверки задач по заботе" marginWrap="0 0 14px">
                    <DateTime selected={careDate} onChange={setCareDate as onChangeDateTime}/>
                </Label>

                <Label labelText="Дата проверки задач по приглашению на осмотр" marginWrap="0 0 14px">
                    <DateTime selected={surveyInviteDate} onChange={setSurveyInviteDate as onChangeDateTime}/>
                </Label>

                <Label labelText="Дата проверки задач по ведению плана" marginWrap="0 0 14px">
                    <DateTime selected={leadPlanDate} onChange={setLeadPlaneDate as onChangeDateTime}/>
                </Label>

                <Button variant="default" onClick={() => runCallTasksCreation(careDate, surveyInviteDate, leadPlanDate)}>
                    Запустить
                </Button>

                <h3>Запуск создания задач управляющего</h3>

                <Button variant="default" onClick={() => runManagerTasksCreation()}>
                    Запустить
                </Button>

                <CallFakerPanel/>
            </BoxBody>
        </Box>
    );
}
