import React from 'react'
import PropTypes from 'prop-types'
import '../TextArea.scss';
import './ContentEditable.scss';
import Textarea from "react-textarea-autosize";
import Scrollbars from "../../Scrollbars/scrollbars";
import {Icon} from "../../../components/Icons/Icons";
import ReactDOM from "react-dom";
import {Chip} from "../../DataDisplay/Chip";

export default class ContentEditable extends React.Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick);
    };

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    };


    static propTypes = {
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        placeholder: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        autoFocus: PropTypes.bool,
        activeClass: PropTypes.string,
        isError: PropTypes.bool,
        popupList: PropTypes.array,
        onClickPopupItem: PropTypes.func,
        selectedItems: PropTypes.array,
        deleteItem: PropTypes.func,
        resetPopupList: PropTypes.func,
        fieldName: PropTypes.string,
        groupsIdDiagnosis: PropTypes.object
    };

    static defaultProps = {
        placeholder: '',
        value: '',
        disabled: false,
        autoFocus: false,
        activeClass: ''
    };

    state = {
        height: 92,
    };

    fieldNode = null;

    render() {

        const {disabled, isError, value, popupList, activeClass, title, fieldName} = this.props;

        let formControlClass = (disabled) ? 'form-control--disabled' : '';

        const styleTextArea = {};
        const styleFormControl = {};

        if (activeClass.length === 0 && value.length === 0) {
            styleTextArea.maxHeight = 0;
            styleTextArea.position = 'absolute';
            if (popupList.length) {
                styleFormControl.paddingBottom = '6px'
            }
        }

        return (
            <div
                className={`textarea-wrap ${(isError) ? `textarea-variant--danger` : ``}`}
                ref={this.textInput}
                id={fieldName}
            >

                <label className="textarea-title">{title}</label>

                <div className={`form-control form-control--editable ${formControlClass} ${activeClass}`}
                     onClick={this.onClick}
                >
                    {this.renderSelectedItems()}
                    <Textarea name="text-area"
                              value={value}
                              className={`form-control__textarea`}
                              onChange={this.onChangeText}
                              inputRef={ref => this.fieldNode = ref}
                              style={styleTextArea}
                    />

                </div>
                {this.renderPopupList()}
            </div>
        )
    }

    renderSelectedItems = () => {
        const {selectedItems, deleteItem} = this.props;
        if (selectedItems === undefined) return null;
        if (selectedItems.length === 0) return null;

        return selectedItems.map(item => <Chip onClickIcon={() => deleteItem(item)} children={item.text.slice(0, -1)} />)
    };

    renderPopupList = () => {
        const {popupList, value, groupsIdDiagnosis} = this.props;
        if (popupList === undefined) return null;
        if (popupList.length === 0 || value.length <= 1) return null;
        const {height} = this.state;

        return <Scrollbars style={{height: 'max-content', maxHeight: '340px', top: height + 12 + 'px'}}
                           className="info-box__group-scrollbar"
                           color='black'>
            <ul className="info-box__group-list">
                {popupList.map(item => {
                    let groupsId = [];
                    if (item.groupId !== 0) groupsId = groupsIdDiagnosis[item.groupId].groupsId;
                    return (
                        <li key={item.id}>
                        <span onClick={() => this.onClickPopupItem(item)}>
                            <span className="info-box__group-list-title">
                                {this.prepareText(item, value).map(txt => {
                                    if (txt.toLowerCase() === value.toLowerCase()) txt = <b>{txt}</b>;
                                    return txt
                                })}
                            </span>
                            {groupsId.length > 0 &&
                            <span className="info-box__group-list-tree">
                                {groupsId.map((id, index) => {
                                    let arrow = null;
                                    if (index > 0) {
                                        arrow = <Icon icon="new-long-arrow" />
                                    }
                                    return (
                                        <span>{arrow}{groupsIdDiagnosis[id].name}</span>)

                                })}
                            </span>}
                        </span>
                        </li>
                    )
                })}
            </ul>
        </Scrollbars>

    };

    prepareText = (item, value) => {
        let textArray = item.text.toLowerCase().split(value.toLowerCase());
        let newText = [];
        let newLength = 0;
        let prevLength = 0;
        textArray.forEach((txt, index) => {
            newLength += txt.length;
            newText.push(item.text.substr(prevLength, newLength));
            if (index !== textArray.length - 1) {
                if (index === 0 && txt.length === 0) {
                    newText.push(value[0].toUpperCase() + value.slice(1));
                } else {
                    newText.push(value);
                }
            }
            prevLength += value.length + txt.length;
            newLength += value.length;
        });

        return newText;
    };

    onChangeText = (e) => {
        const {onChange} = this.props;
        const height = this.textInput.current.getBoundingClientRect().height;
        this.setState({height});
        onChange(e);
    };

    onClick = () => {
        if (this.props.onClick) this.props.onClick();
        this.fieldNode.focus();
    };

    onClickPopupItem = (item) => {
        this.props.onClickPopupItem(item);
        this.fieldNode.focus();
    };


    focus(params) {
        this.fieldNode.focus(params);
    }

    handleOutsideClick(e) {
        const node = ReactDOM.findDOMNode(this);
        if (node && !node.contains(e.target)) this.props.resetPopupList();
    };

}
