import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth32Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 5.45 24"} width={width || "10"} height={height || "44"} ref={ref}>
            <path d="M5.45,1.47C5.34.07,4.87.08,3.47.08H2.88a3.34,3.34,0,0,1-.61,0C1.66,0,.84-.07.51.2-.78,1.28.66,6.39,1.71,7.49c.41.42,1,.89,1.47.74,1.52-.46,2.3-5.3,2.27-6.77Zm-2,20.64a73.13,73.13,0,0,1,.72-10.76c.11-.87.21-1.64.27-2.4a2.07,2.07,0,0,1-.9.58A1.59,1.59,0,0,1,3,9.61,2.82,2.82,0,0,1,1,8.6c.29,13,1.41,15.06,1.77,15.4a5,5,0,0,0,.61-1.89Z"/>
        </WrapIcon>
    );
});
