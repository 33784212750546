import Box from "../../../components-ui/Layout/Box";
import Helper from "../../../common/helpers/main";
import {IColumn} from "../../../components-ui/DataDisplay/Table";
import React from "react";
import { WrapDoctors } from "../style";
import {useSelector} from "react-redux";

const useColumns = () => {
    const doctors = useSelector(state => state.common.doctors)
    const user = useSelector(state => state.user)

    let column = [
        {
            headerName: "Номер карты",
            field: "number",
            width: '145px'
        },
        {
            headerName: "Врачи",
            field: "doctors",
            width: '138px',
            valueFormatter: ({row}) => {
                return (
                    <WrapDoctors>
                        {
                            row.doctorsIds.map((doctorId: number) => {
                                const findDoctor = doctors.find(doctor => doctor.id === doctorId);
                                if (!!findDoctor) {
                                    return (
                                        <Box backgroundColor={findDoctor.color as string} flexShrink={0} margin="2px 0"/>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </WrapDoctors>
                )
            }
        },
        {
            headerName: "Пациент",
            field: "fullName",
            width: '206px',
            valueFormatter: ({row}) => Helper.getFio(row)
        },
    ] as IColumn[]

    if (user.canPatientPhone) {
        column = column.concat([
            {
                headerName: "Телефон",
                field: "phone",
                width: '186px',
                valueFormatter: ({row}) => Helper.Phone.format(row.personalPhone)
            },
        ])
    }

    column = column.concat([
        {
            headerName: "Адрес",
            field: "address",
            valueFormatter: ({row}) => Helper.getAddress(row)
        },
        {
            headerName: "Возраст",
            field: "age",
            width: '221px',
            valueFormatter: ({row}) => !!row.personalBirthday ? Helper.Date.getAge(row.personalBirthday) : null
        }
    ])

    return column
}

export default useColumns
