import React, {useState} from 'react';
import {useModal} from '../../../../../containers/DialogsManager/containers/Modal/Modal';
import useLoader from '../../../../../components/Errors';
import ServerCommand from '../../../../../common/server/server-command';
import moment from 'moment';
import DateHelper from '../../../../../common/helpers/date-helper';
import {SelectedMonth} from '../../../../Salaries/types';
import Box from '../../../../../components-ui/Layout/Box';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import {Label} from '../../../../../components-ui/DataDisplay/Label';
import {
    commonLabelOption, commonSelectOption,
    commonTextFieldOption,
} from '../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import TextField from '../../../../../components-ui/Inputs/TextField/TextField';
import Button from '../../../../../components-ui/Inputs/Button/Button';
import {Laboratory} from '../../../../LaboratoryPage/types';
import {TableWidthGroupTreeCell} from '../../../../../components/TableWithGroupsTree/types';
import {SavePriceParams} from '../../../types';
import Select from '../../../../../components-ui/Inputs/Select/Select';
import {months, PMonths} from '../../../../../common/arrays/arrays';

interface Props {
    service: TableWidthGroupTreeCell;
    price: string;
    lab: Laboratory;
    refreshDataFromServer: () => void;
}

const ChangeLaboratoryService = ({service, lab, price, refreshDataFromServer}: Props) => {

    const {closeModal} = useModal();

    const [loader, setLoader] = useLoader();

    const [laboratoryServicePrice, setLaboratoryServicePrice] = useState(price);

    const [selectedMonth, setSelectedMonth] = useState<SelectedMonth>({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
    });

    const saveLaboratoryServicePrice = () => {
        setLoader(true);
        ServerCommand.post('laboratory/service/save-price', {
            serviceId: service.id,
            laboratoryId: lab.id,
            price: +laboratoryServicePrice,
            activeFrom: String(moment(`${selectedMonth.year}-${selectedMonth.month}`).format(DateHelper.FORMAT_SERVER_MONTH)),
        } as SavePriceParams).then(() => {
            refreshDataFromServer();
            closeModal();
        });

    };

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Box maxWidth='fit-content'>
                <Typography as='h1' variant='middle-title' fontWeight='600'
                            children={`${service.name} в ${lab.name}`} textAlign='center' />
            </Box>
            <Box margin='39px 0 0 0'>
                <Label {...commonLabelOption}
                       labelText='Цена'
                       align='leftTop'
                       isRequired
                       marginLabel='8px 10px 0 0'>

                    <TextField
                        {...commonTextFieldOption}
                        value={laboratoryServicePrice}
                        widthInput='250px'
                        isNumber
                        onChange={setLaboratoryServicePrice}

                    />
                </Label>
                <Label {...commonLabelOption}
                       isRequired={true}
                       labelText='Месяц применения'
                       align='leftTop'
                       marginLabel='8px 10px 0 0'
                >
                    <Select value={months.find((month) => selectedMonth.month === +month.value)}
                            placeholder='Месяц'
                            options={months}
                            onChange={(option: PMonths) => setSelectedMonth(prevState => (
                                {...prevState, month: +option.value}
                            ))
                            }
                    />
                    <TextField value={selectedMonth.year}
                               placeholder='Год'
                               onChange={(value: string) => {
                                   value.length < 5 && setSelectedMonth(prevState => (
                                       {...prevState, year: +value.replace(/[_]/gi, '')}
                                   ));
                               }
                               }
                               textAlignInput='center'
                               widthInput='80px'
                               mask='9999'
                               margin='0 0 0 15px'
                    />
                </Label>

            </Box>
            <Label labelText='' {...commonLabelOption}>
                <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                    <Button children='Сохранить'
                            size='md'
                            loader={loader}
                            variant={
                                laboratoryServicePrice && String(selectedMonth.year).length === 4 ?
                                    'success' :
                                    'disabled'}
                            onClick={saveLaboratoryServicePrice}
                    />
                </Box>
            </Label>
        </Box>
    );
};

export default ChangeLaboratoryService;
