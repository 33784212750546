import {useState} from 'react';
import {OptionSelect} from '../../../../../../components-ui/Inputs/Select/typesSelect';
import {ModalAddTimesheetState} from '../../types';
import {useSelector} from 'react-redux';
import {useModal} from '../../../../../../containers/DialogsManager/containers/Modal/Modal';
import {SaveEmployeeScheduleParams} from '../../../../types';
import DateHelper from '../../../../../../common/helpers/date-helper';
import useLoader from '../../../../../../components/Errors';
import ServerCommand from '../../../../../../common/server/server-command';

interface Props {
    selectedBranchId: number
    refreshDataFromServer: () => void
}

const useModalAddTimeSheet = ({selectedBranchId, refreshDataFromServer}: Props) => {

    const {closeModal} = useModal();

    const [loader, setLoader] = useLoader();

    const scheduleStartTime = useSelector(state => state.common.scheduleStartTime)

    const scheduleEndTime = useSelector(state => state.common.scheduleEndTime)

    const [page, setPage] = useState(1)

    const [state, setState] = useState<ModalAddTimesheetState>({
        branchId: 0,
        employee: undefined,
        startDate: new Date(),
        endDate: new Date(),
        defaultStartTime: scheduleStartTime,
        defaultEndTime: scheduleEndTime,
        schedule: [{
            date: new Date(),
            startTime: scheduleStartTime,
            endTime: scheduleEndTime,
            isNotWorking: false
        }],
    })

    const fillingSchedule = ({
                                 startDate = state.startDate,
                                 endDate = state.endDate,
                                 defaultStartTime = state.defaultStartTime,
                                 defaultEndTime = state.defaultEndTime
    }) => {

        let arr: ModalAddTimesheetState["schedule"] = []

        for(let date = new Date(startDate); date <= new Date(endDate); date.setDate(date.getDate()+1)){
            arr.push({
                date: new Date(date),
                startTime: defaultStartTime,
                endTime: defaultEndTime,
                isNotWorking: false
            });
        }
        return arr
    }

    const handleEmployee = (employee: OptionSelect) => {
        setState(prevState => {
            return {...prevState, employee}
        })
    }

    const handleStartDate = (startDate: Date) => {
        setState(prevState => {
            return {...prevState, schedule: fillingSchedule({startDate}), startDate}
        })
    }

    const handleEndDate = (endDate: Date) => {
        setState(prevState => {
            return {...prevState, schedule: fillingSchedule({endDate}), endDate}
        })
    }

    const handleDefaultStartTime = (defaultStartTime: string) => {
        setState(prevState => {
            return {
                ...prevState,
                defaultStartTime,
                schedule: fillingSchedule({defaultStartTime})
            }
        })
    }

    const handleDefaultEndTime = (defaultEndTime: string) => {
        setState(prevState => {
            return {
                ...prevState,
                defaultEndTime,
                schedule: fillingSchedule({defaultEndTime})
            }
        })
    }

    const params: SaveEmployeeScheduleParams = {
        employeeId: state.employee?.value,
        branchId: selectedBranchId,
        startDate: DateHelper.formatDateForServer(state.startDate),
        endDate: DateHelper.formatDateForServer(state.endDate),
        schedule: state.schedule
            .filter(timesheet => !timesheet.isNotWorking)
            .map(timesheet => ({...timesheet, date: DateHelper.formatDateForServer(timesheet.date)}))
    }

    const saveEmployeeSchedule = () => {
        setLoader(true);
        ServerCommand.post("employee-schedule/save", params, () => {
            closeModal();
            refreshDataFromServer()
        }).then();
    }

    return {
        state,
        setState,
        page,
        setPage,
        loader,
        saveEmployeeSchedule,
        handleEmployee,
        handleStartDate,
        handleEndDate,
        handleDefaultStartTime,
        handleDefaultEndTime,
    }
};

export default useModalAddTimeSheet;
