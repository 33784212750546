import {useEffect, useState} from "react";

/*
 * Получает обновленное время каждые 30 минут по часам.
 * Пример (если время 11:43, следующее обновление будет в 12:00, следующее в 12:30 и т.д.)*/

export const useDateUpdate = () => {
    const [dateTime, setDateTime] = useState(new Date())
    const [firstUpdate, setFirstUpdate] = useState(false)

    /*Первое обновление. Вычесляется время округленное вверх и вычетается от текущеего времени*/
    useEffect(() => {
        const currentTime = new Date().getTime();
        const ceilClockTime = new Date();
        if (ceilClockTime.getMinutes() > 30) {
            ceilClockTime.setHours(ceilClockTime.getHours() + 1)
            ceilClockTime.setMinutes(0)
        } else {
            ceilClockTime.setMinutes(30)
        }
        const timeoutId = setTimeout(() => {
            setFirstUpdate(true)
            setDateTime(new Date())
        }, ceilClockTime.getTime() - currentTime)
        return () => clearTimeout(timeoutId)
    }, [])

    /*После того как произошло первое обновление, начинает происходить обновление по заданному интервалу*/
    useEffect(() => {
        if (firstUpdate) {
            const intervalId = setInterval(() => setDateTime(new Date()), 1000 * 60 * 30);
            return () => clearInterval(intervalId)
        }
    }, [firstUpdate])

    return dateTime
}
