import * as React from 'react'
import Checkbox from "../../components-ui/Inputs/Checkbox/Checkbox";
import Counter from "../../components-ui/Inputs/Counter/Counter";

export default function CareCallDelayField({value, onChange}: Props) {

    return (
        <>
            <div className="popup__input-wrapper popup__input-wrapper--separate-label">
                <Checkbox
                    label="Переопределить количество дней до звонка по заботе"
                    checked={value !== null}
                    onChange={override => {
                        onChange(override ? 0 : null);
                    }}
                />
            </div>

            {value !== null && (
                <div className="popup__input-wrapper">
                    <Counter value={value} min={0} onChange={onChange}/>
                </div>
            )}

        </>
    );
}

interface Props {
    value: number|null
    onChange: (value: any) => void
}
