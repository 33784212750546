import React from 'react';
import {GetDoneServicesResult} from '../../types';
import useTableFormatter from './hooks/useTableFormatter';
import Table from '../../../../components-ui/DataDisplay/Table';
import Button from '../../../../components-ui/Inputs/Button/Button';
import {useDialogsManager} from '../../../../containers/DialogsManager/DialogsManager';
import Box from '../../../../components-ui/Layout/Box';
import Typography from '../../../../components-ui/DataDisplay/Typography/Typography';
import Loader from '../../../../components-ui/Feedback/Loader/Loader';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import ModalMarkDoneEmployeeService from './modals/ModalMarkDoneEmployeeService';

interface Props {
    doneEmployeeServices: GetDoneServicesResult['doneEmployeeServices'];
    services: GetDoneServicesResult['services'];
    loaderTable: boolean;
    refreshDataFromServer: () => void;
    selectedBranch: OptionSelect;
}

const DoneEmployeeServicesTable = ({
                                       doneEmployeeServices,
                                       services,
                                       loaderTable,
                                       refreshDataFromServer,
                                       selectedBranch,
                                   }: Props) => {

    const {showModal} = useDialogsManager();

    const {columns, rows} = useTableFormatter(
        {
            services,
            refreshDataFromServer,
            selectedBranch,
            doneEmployeeServices
        });

    return (
        <Box>
            <Typography fontWeight='800' variant='min-title' margin='0 0 12px'>
                Выполненные услуги внутренней лаборатории
            </Typography>
            <Box position='relative'>
                <Loader visible={loaderTable} />
                <Table columns={columns}
                       rows={rows}
                       variantTable='outline'
                       isHovering={false}
                       fontSizeRow='14px'
                />
            </Box>
            <Button margin='12px 0 0 0'
                    width='180px'
                    variant='default'
                    size='md'
                    children='Добавить'
                    onClick={() => showModal(
                        <ModalMarkDoneEmployeeService
                            services={services}
                            refreshDataFromServer={refreshDataFromServer}
                            selectedBranch={selectedBranch}
                        />)}
            />
        </Box>
    );
};

export default DoneEmployeeServicesTable;
