import {Dispatch, SetStateAction, useEffect} from "react";
import {ScriptType, ScriptGroupList} from "../../../types/common";

interface Props {
    groupsScript: {
        [key: string]: ScriptGroupList[]
    }
    setSelectedScript: Dispatch<SetStateAction<ScriptType | null>>
}

const useInitialScripts = ({groupsScript, setSelectedScript}: Props) => {
    useEffect(() => {
        const initialSelectedScript = () => {
            if (!!groupsScript.marketing.length && !!groupsScript.marketing[0].scriptList.length) {
                return groupsScript.marketing[0].scriptList[0]
            } else if (!!groupsScript.noMarketing.length && !!groupsScript.noMarketing[0].scriptList.length) {
                return groupsScript.noMarketing[0].scriptList[0]
            } else {
                return null
            }
        }
        setSelectedScript(initialSelectedScript())
    }, [])
}

export default useInitialScripts
