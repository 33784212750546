import {useWAP} from "../WaitingAndPlanned";
import useValidateForm from "../../../../../containers/PatientField/hooks/useValidateForm";
import {useMemo} from "react";
import _ from 'lodash'

export default function useValidationOfFields() {
    const {form, activeTab, fields} = useWAP()
    const validate = useValidateForm({fields})

    return useMemo(() => {
        return validate(form.basic, _.keys(form.basic))
            && (activeTab !== 'waiting-list' || form.wantThisDoctor || !!form.directions?.length)
            && (activeTab !== 'scheduled-appointments' || form.date !== null)
    }, [form])
}
