export const newTable = [
    {
        serviceId: 1,
        price: 50000
    },
    {
        serviceId: 30,
        price: 100001
    },
    {
        serviceId: 34,
        price: 400002
    },
    {
        serviceId: 35,
        price: 50001
    },
    {
        serviceId: 36,
        price: 100003
    },
    {
        serviceId: 22,
        price: 400004
    },
    {
        serviceId: 44,
        price: 50005
    },
    {
        serviceId: 45,
        price: 600006
    },
    {
        serviceId: 70,
        price: 100007
    },
    {
        serviceId: 74,
        price: 400008
    },
    {
        serviceId: 75,
        price: 50009
    },
    {
        serviceId: 76,
        price: 100010
    },
    {
        serviceId: 80,
        price: 100011
    },
    {
        serviceId: 84,
        price: 400012
    },
    {
        serviceId: 85,
        price: 50013
    },
    {
        serviceId: 86,
        price: 100014
    },
]

export const rowsOne = [
    {
        number: 79511,
        personalBirthday: "1978-11-21",
        personalCity: "New-York",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79996664453",
        personalApartment: "",
        lastName: "Кларк",
        name: "Эмилия",
        secondName: "Петровна",
        id: 1,
        doctorsIds: [2614, 2623, 2624, 2924, 4335, 12655]
    }, {
        number: 90,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79021702005",
        personalApartment: "",
        lastName: "Алхунсаева",
        name: ".",
        secondName: ".",
        id: 2,
        doctorsIds: []
    }, {
        number: 94,
        personalBirthday: "2008-01-11",
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79149175153",
        personalApartment: "",
        lastName: "Аманов",
        name: ".",
        secondName: ".",
        id: 3,
        doctorsIds: []
    }, {
        number: 210,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79021737829",
        personalApartment: "",
        lastName: "Ахминеева",
        name: ".",
        secondName: ".",
        id: 4,
        doctorsIds: []
    }, {
        number: 4890,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "",
        personalApartment: "",
        lastName: "Власов",
        name: ".",
        secondName: ".",
        id: 5,
        doctorsIds: []
    }, {
        number: 828,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79500845240",
        personalApartment: "",
        lastName: "Ворогушин",
        name: ".",
        secondName: ".",
        id: 6,
        doctorsIds: []
    }, {
        number: 944,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79086658497",
        personalApartment: "",
        lastName: "Гончарова",
        name: ".",
        secondName: ".",
        id: 7,
        doctorsIds: []
    }, {
        number: 1032,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79642689116",
        personalApartment: "",
        lastName: "Григорян",
        name: ".",
        secondName: ".",
        id: 8,
        doctorsIds: []
    }, {
        number: 1152,
        personalBirthday: "1990-01-11",
        personalCity: "asda",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79246199983",
        personalApartment: "",
        lastName: "Демидова",
        name: "Hgf",
        secondName: ".",
        id: 9,
        doctorsIds: []
    }, {
        number: 1222,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "",
        personalApartment: "",
        lastName: "Донченко",
        name: ".",
        secondName: ".",
        id: 10,
        doctorsIds: []
    }, {
        number: 1428,
        personalBirthday: "2004-01-31",
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79149428888",
        personalApartment: "",
        lastName: "Забавский",
        name: ".",
        secondName: ".",
        id: 11,
        doctorsIds: []
    }, {
        number: 3334,
        personalBirthday: "1970-02-27",
        personalCity: "Гор. Тулун",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79500993248",
        personalApartment: "",
        lastName: "Игумнов",
        name: "Андрей",
        secondName: "Дмитриевич",
        id: 12,
        doctorsIds: []
    }, {
        number: 1820,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79149211953",
        personalApartment: "",
        lastName: "Ке",
        name: ".",
        secondName: ".",
        id: 13,
        doctorsIds: []
    }, {
        number: 2562,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79086530999",
        personalApartment: "",
        lastName: "Малакшинова",
        name: ".",
        secondName: ".",
        id: 14,
        doctorsIds: []
    }, {
        number: 2596,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "",
        personalApartment: "",
        lastName: "Мамедова",
        name: ".",
        secondName: ".",
        id: 16,
        doctorsIds: []
    }, {
        number: 2760,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79086413302",
        personalApartment: "",
        lastName: "Михайлов",
        name: ".",
        secondName: ".",
        id: 17,
        doctorsIds: []
    }, {
        number: 2762,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79041144945",
        personalApartment: "",
        lastName: "Михайлова",
        name: ".",
        secondName: ".",
        id: 18,
        doctorsIds: []
    }, {
        number: 3382,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "",
        personalApartment: "",
        lastName: "Потапова",
        name: ".",
        secondName: ".",
        id: 19,
        doctorsIds: []
    }, {
        number: 3388,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79501257998",
        personalApartment: "",
        lastName: "Почкин",
        name: ".",
        secondName: ".",
        id: 20,
        doctorsIds: []
    }, {
        number: 3532,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "",
        personalApartment: "",
        lastName: "Рудых",
        name: ".",
        secondName: ".",
        id: 21,
        doctorsIds: []
    }, {
        number: 3716,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79086647856",
        personalApartment: "",
        lastName: "Сидловская",
        name: ".",
        secondName: ".",
        id: 22,
        doctorsIds: []
    }, {
        number: 4142,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79501133006",
        personalApartment: "",
        lastName: "Торхаев",
        name: ".",
        secondName: ".",
        id: 24,
        doctorsIds: []
    }, {
        number: 4164,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79246025988",
        personalApartment: "",
        lastName: "Тропов",
        name: ".",
        secondName: ".",
        id: 25,
        doctorsIds: []
    }, {
        number: 4446,
        personalBirthday: "1992-05-04",
        personalCity: "Иркутск",
        personalHome: "164/3",
        personalHousing: "",
        personalStreet: "Дальневосточная",
        personalPhone: "79041555550",
        personalApartment: "3",
        lastName: "Хомченко",
        name: "Екатерина",
        secondName: "Игоревна",
        id: 26,
        doctorsIds: []
    }, {
        number: 4460,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79500635757",
        personalApartment: "",
        lastName: "Храмова",
        name: ".",
        secondName: ".",
        id: 27,
        doctorsIds: []
    }, {
        number: 4550,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79041135068",
        personalApartment: "",
        lastName: "Четверякова",
        name: ".",
        secondName: ".",
        id: 28,
        doctorsIds: []
    }, {
        number: 4568,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79526252953",
        personalApartment: "",
        lastName: "Чубарова",
        name: ".",
        secondName: ".",
        id: 29,
        doctorsIds: []
    }, {
        number: 2332,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79645440122",
        personalApartment: "",
        lastName: "Левинец",
        name: ".",
        secondName: "Виталий",
        id: 30,
        doctorsIds: []
    }, {
        number: 1010,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79149205042",
        personalApartment: "",
        lastName: "Гречкин",
        name: ".",
        secondName: "Кирилл",
        id: 31,
        doctorsIds: []
    }, {
        number: 1643,
        personalBirthday: "1980-12-30",
        personalCity: "",
        personalHome: "248",
        personalHousing: "",
        personalStreet: "Баумана",
        personalPhone: "79501374708",
        personalApartment: "26",
        lastName: "Байбородин",
        name: "Леонид",
        secondName: "Александрович",
        id: 32,
        doctorsIds: [2924]
    }, {
        number: 1962,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79526244470",
        personalApartment: "",
        lastName: "Колюбаева",
        name: ".",
        secondName: "Татьяна",
        id: 33,
        doctorsIds: []
    }, {
        number: 1000,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "",
        personalApartment: "",
        lastName: "Горячкина",
        name: "Ада",
        secondName: "Владимировна",
        id: 34,
        doctorsIds: []
    }, {
        number: 4626,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79246255515",
        personalApartment: "",
        lastName: "Шарипов",
        name: "Адис",
        secondName: ".",
        id: 35,
        doctorsIds: []
    }, {
        number: 1184,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "",
        personalApartment: "",
        lastName: "Джумян",
        name: "Аида",
        secondName: ".",
        id: 36,
        doctorsIds: []
    }, {
        number: 3074,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "",
        personalApartment: "",
        lastName: "Ожигова",
        name: "Аида",
        secondName: "Вячеславовна",
        id: 37,
        doctorsIds: []
    }, {
        number: 4436,
        personalBirthday: "1990-02-11",
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79501418706",
        personalApartment: "",
        lastName: "Ходаковская",
        name: "Аксинья",
        secondName: "Олеговна",
        id: 38,
        doctorsIds: [2924]
    }, {
        number: 204,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79642200133",
        personalApartment: "",
        lastName: "Ахматгалиев",
        name: "Алекс",
        secondName: ".",
        id: 39,
        doctorsIds: []
    }, {
        number: 1270,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79025135600",
        personalApartment: "",
        lastName: "Дунаев",
        name: "Александр",
        secondName: ".",
        id: 40,
        doctorsIds: []
    }, {
        number: 1750,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "",
        personalApartment: "",
        lastName: "Калмыков",
        name: "Александр",
        secondName: ".",
        id: 41,
        doctorsIds: []
    }, {
        number: 1836,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "",
        personalApartment: "",
        lastName: "Кинозеров",
        name: "Александр",
        secondName: ".",
        id: 42,
        doctorsIds: []
    }, {
        number: 1904,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79086476716",
        personalApartment: "",
        lastName: "Козаков",
        name: "Александр",
        secondName: ".",
        id: 43,
        doctorsIds: []
    }, {
        number: 2988,
        personalBirthday: "1111-01-11",
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79149085024",
        personalApartment: "",
        lastName: "Никитин",
        name: "Александр",
        secondName: ".",
        id: 44,
        doctorsIds: []
    }, {
        number: 3081,
        personalBirthday: "1984-12-25",
        personalCity: "Саха Якутия ",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79142458880",
        personalApartment: "",
        lastName: "Шмаков",
        name: "Александр",
        secondName: "",
        id: 47,
        doctorsIds: []
    }, {
        number: 4752,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79149213568",
        personalApartment: "",
        lastName: "Шпальберг",
        name: "Александр",
        secondName: ".",
        id: 48,
        doctorsIds: []
    }, {
        number: 4812,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79243640660",
        personalApartment: "",
        lastName: "Щербинин",
        name: "Александр",
        secondName: ".",
        id: 49,
        doctorsIds: []
    }, {
        number: 18,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79500626244",
        personalApartment: "",
        lastName: "Аверинский",
        name: "Александр",
        secondName: "Александрович",
        id: 50,
        doctorsIds: []
    }, {
        number: 154,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "",
        personalApartment: "",
        lastName: "Арендт",
        name: "Александр",
        secondName: "Александрович",
        id: 51,
        doctorsIds: []
    }, {
        number: 3292,
        personalBirthday: null,
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79834036485",
        personalApartment: "",
        lastName: "Плотников",
        name: "Александр",
        secondName: "Александрович",
        id: 52,
        doctorsIds: []
    }, {
        number: 4762,
        personalBirthday: "1111-01-11",
        personalCity: "",
        personalHome: "",
        personalHousing: "",
        personalStreet: "",
        personalPhone: "79149362960",
        personalApartment: "",
        lastName: "Шуберт",
        name: "Александр",
        secondName: "Александрович",
        id: 53,
        doctorsIds: []
    }, {
        number: 248,
        personalBirthday: "1993-11-30",
        personalCity: "",
        personalHome: "239",
        personalHousing: "",
        personalStreet: "Карла Либнехта ",
        personalPhone: "79025106562",
        personalApartment: "17",
        lastName: "Баймаков",
        name: "Александр",
        secondName: "Алексеевич",
        id: 54,
        doctorsIds: []
    }]

export const rowsTwo = [
    {
        planId: 8836,
        serviceId: 327,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2924,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Индивидуальный набор инструментов",
        procedures: [{
            id: 185360,
            planItemId: 87929,
            procedureId: 282,
            target: "шт",
            isActive: true,
            price: 320,
            doneVisitId: null,
            name: "Индивидуальный набор инструментов",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Индивидуальный набор инструментов",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87929, direction: "therapy"}],
        directions: ["therapy"],
        directionsQuantity: {therapy: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 328,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2924,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Консультация",
        procedures: [{
            id: 185361,
            planItemId: 87930,
            procedureId: 283,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Консультация",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Консультация",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87930, direction: "therapy"}],
        directions: ["therapy"],
        directionsQuantity: {therapy: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 327,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2614,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Индивидуальный набор инструментов",
        procedures: [{
            id: 185362,
            planItemId: 87931,
            procedureId: 282,
            target: "шт",
            isActive: true,
            price: 320,
            doneVisitId: null,
            name: "Индивидуальный набор инструментов",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Индивидуальный набор инструментов",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 3,
        fieldsStack: [{id: 87931, direction: "orthopedics"}, {id: 87932, direction: "orthopedics"}, {
            id: 87933,
            direction: "orthopedics"
        }],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 3},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 328,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2614,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Консультация",
        procedures: [{
            id: 185365,
            planItemId: 87934,
            procedureId: 283,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Консультация",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Консультация",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87934, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 331,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2614,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "ОПГ снимок",
        procedures: [{
            id: 185366,
            planItemId: 87935,
            procedureId: 286,
            target: "шт",
            isActive: true,
            price: 500,
            doneVisitId: null,
            name: "ОПГ снимок",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "ОПГ снимок",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87935, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 333,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2614,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Использование оптрагейт",
        procedures: [{
            id: 185367,
            planItemId: 87936,
            procedureId: 288,
            target: "шт",
            isActive: true,
            price: 500,
            doneVisitId: null,
            name: "Использование оптрагейт",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Использование оптрагейт",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: false,
        quantity: 3,
        fieldsStack: [{id: 87936, direction: "orthopedics"}, {id: 87937, direction: "orthopedics"}, {
            id: 87938,
            direction: "orthopedics"
        }],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 3},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 336,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2614,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Получение слепка силиконовым  материалом",
        procedures: [{
            id: 185370,
            planItemId: 87939,
            procedureId: 291,
            target: "в.ч., н.ч.",
            isActive: true,
            price: 600,
            doneVisitId: null,
            name: "Получение слепка силиконовым  материалом",
            measure: "BOTH_JAWS",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Получение слепка силиконовым  материалом",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 2,
        fieldsStack: [{id: 87939, direction: "orthopedics"}, {id: 87940, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 2},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 120,
        measure: "TOOTH",
        target: "22",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2614,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: {"1": [{"151": true, "262": true}, {"261": true, "262": true}]},
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Восстановление зуба металлокерамической коронкой (опорный зуб)",
        procedures: [{
            id: 185372,
            planItemId: 87941,
            procedureId: 262,
            target: "22",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Препарирование (использование ретракционной нити)",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185373,
            planItemId: 87941,
            procedureId: 117,
            target: "22",
            isActive: true,
            price: 1000,
            doneVisitId: null,
            name: "Пластмассовая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185375,
            planItemId: 87941,
            procedureId: 150,
            target: "22",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Фиксация на временный материал Temp-Bond",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185376,
            planItemId: 87941,
            procedureId: 120,
            target: "22",
            isActive: true,
            price: 6740,
            doneVisitId: null,
            name: "Металлокерамическая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185377,
            planItemId: 87941,
            procedureId: 293,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Примерка каркаса",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185378,
            planItemId: 87941,
            procedureId: 261,
            target: "шт",
            isActive: true,
            price: 660,
            doneVisitId: null,
            name: "Фиксация на постоянный материал (Fuji)",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Восстановление зуба металлокерамической коронкой",
        serviceGroupId: 284,
        isAdditional: false,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87941, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 120,
        measure: "TOOTH",
        target: "23",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2614,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: {"1": [{"151": true, "262": true}, {"261": true, "262": true}]},
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Восстановление зуба металлокерамической коронкой (опорный зуб)",
        procedures: [{
            id: 185379,
            planItemId: 87942,
            procedureId: 262,
            target: "23",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Препарирование (использование ретракционной нити)",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185380,
            planItemId: 87942,
            procedureId: 117,
            target: "23",
            isActive: true,
            price: 1000,
            doneVisitId: null,
            name: "Пластмассовая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185382,
            planItemId: 87942,
            procedureId: 150,
            target: "23",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Фиксация на временный материал Temp-Bond",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185383,
            planItemId: 87942,
            procedureId: 120,
            target: "23",
            isActive: true,
            price: 6740,
            doneVisitId: null,
            name: "Металлокерамическая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185384,
            planItemId: 87942,
            procedureId: 293,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Примерка каркаса",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185385,
            planItemId: 87942,
            procedureId: 261,
            target: "шт",
            isActive: true,
            price: 660,
            doneVisitId: null,
            name: "Фиксация на постоянный материал (Fuji)",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Восстановление зуба металлокерамической коронкой",
        serviceGroupId: 284,
        isAdditional: false,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87942, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 120,
        measure: "TOOTH",
        target: "24",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2614,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: {"1": [{"151": true, "262": true}, {"261": true, "262": true}]},
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Восстановление зуба металлокерамической коронкой (опорный зуб)",
        procedures: [{
            id: 185386,
            planItemId: 87943,
            procedureId: 262,
            target: "24",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Препарирование (использование ретракционной нити)",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185387,
            planItemId: 87943,
            procedureId: 117,
            target: "24",
            isActive: true,
            price: 1000,
            doneVisitId: null,
            name: "Пластмассовая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185389,
            planItemId: 87943,
            procedureId: 150,
            target: "24",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Фиксация на временный материал Temp-Bond",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185390,
            planItemId: 87943,
            procedureId: 120,
            target: "24",
            isActive: true,
            price: 6740,
            doneVisitId: null,
            name: "Металлокерамическая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185391,
            planItemId: 87943,
            procedureId: 293,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Примерка каркаса",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185392,
            planItemId: 87943,
            procedureId: 261,
            target: "шт",
            isActive: true,
            price: 660,
            doneVisitId: null,
            name: "Фиксация на постоянный материал (Fuji)",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Восстановление зуба металлокерамической коронкой",
        serviceGroupId: 284,
        isAdditional: false,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87943, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 120,
        measure: "TOOTH",
        target: "25",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2614,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: {"1": [{"151": true, "262": true}, {"261": true, "262": true}]},
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Восстановление зуба металлокерамической коронкой (опорный зуб)",
        procedures: [{
            id: 185393,
            planItemId: 87944,
            procedureId: 262,
            target: "25",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Препарирование (использование ретракционной нити)",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185394,
            planItemId: 87944,
            procedureId: 117,
            target: "25",
            isActive: true,
            price: 1000,
            doneVisitId: null,
            name: "Пластмассовая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185396,
            planItemId: 87944,
            procedureId: 150,
            target: "25",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Фиксация на временный материал Temp-Bond",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185397,
            planItemId: 87944,
            procedureId: 120,
            target: "25",
            isActive: true,
            price: 6740,
            doneVisitId: null,
            name: "Металлокерамическая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185398,
            planItemId: 87944,
            procedureId: 293,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Примерка каркаса",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185399,
            planItemId: 87944,
            procedureId: 261,
            target: "шт",
            isActive: true,
            price: 660,
            doneVisitId: null,
            name: "Фиксация на постоянный материал (Fuji)",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Восстановление зуба металлокерамической коронкой",
        serviceGroupId: 284,
        isAdditional: false,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87944, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 120,
        measure: "TOOTH",
        target: "26",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2614,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: {"1": [{"151": true, "262": true}, {"261": true, "262": true}]},
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Восстановление зуба металлокерамической коронкой (опорный зуб)",
        procedures: [{
            id: 185400,
            planItemId: 87945,
            procedureId: 262,
            target: "26",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Препарирование (использование ретракционной нити)",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185401,
            planItemId: 87945,
            procedureId: 117,
            target: "26",
            isActive: true,
            price: 1000,
            doneVisitId: null,
            name: "Пластмассовая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185403,
            planItemId: 87945,
            procedureId: 150,
            target: "26",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Фиксация на временный материал Temp-Bond",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185404,
            planItemId: 87945,
            procedureId: 120,
            target: "26",
            isActive: true,
            price: 6740,
            doneVisitId: null,
            name: "Металлокерамическая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185405,
            planItemId: 87945,
            procedureId: 293,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Примерка каркаса",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185406,
            planItemId: 87945,
            procedureId: 261,
            target: "шт",
            isActive: true,
            price: 660,
            doneVisitId: null,
            name: "Фиксация на постоянный материал (Fuji)",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Восстановление зуба металлокерамической коронкой",
        serviceGroupId: 284,
        isAdditional: false,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87945, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 120,
        measure: "TOOTH",
        target: "27",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2614,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: {"1": [{"151": true, "262": true}, {"261": true, "262": true}]},
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Восстановление зуба металлокерамической коронкой (опорный зуб)",
        procedures: [{
            id: 185407,
            planItemId: 87946,
            procedureId: 262,
            target: "27",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Препарирование (использование ретракционной нити)",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185408,
            planItemId: 87946,
            procedureId: 117,
            target: "27",
            isActive: true,
            price: 1000,
            doneVisitId: null,
            name: "Пластмассовая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185410,
            planItemId: 87946,
            procedureId: 150,
            target: "27",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "Фиксация на временный материал Temp-Bond",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185411,
            planItemId: 87946,
            procedureId: 120,
            target: "27",
            isActive: true,
            price: 6740,
            doneVisitId: null,
            name: "Металлокерамическая коронка",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185412,
            planItemId: 87946,
            procedureId: 293,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Примерка каркаса",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185413,
            planItemId: 87946,
            procedureId: 261,
            target: "шт",
            isActive: true,
            price: 660,
            doneVisitId: null,
            name: "Фиксация на постоянный материал (Fuji)",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Восстановление зуба металлокерамической коронкой",
        serviceGroupId: 284,
        isAdditional: false,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87946, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 333,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2924,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Использование оптрагейт",
        procedures: [{
            id: 185414,
            planItemId: 87947,
            procedureId: 288,
            target: "шт",
            isActive: true,
            price: 500,
            doneVisitId: null,
            name: "Использование оптрагейт",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Использование оптрагейт",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: false,
        quantity: 1,
        fieldsStack: [{id: 87947, direction: "therapy"}],
        directions: ["therapy"],
        directionsQuantity: {therapy: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 339,
        measure: "TOOTH",
        target: "23",
        status: "NOT_STARTED",
        creatorId: 2924,
        performerId: 2924,
        nextVisitLast: null,
        createdVisit: 21905,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Депульпирование по ортопедическим показаниям",
        procedures: [{
            id: 185415,
            planItemId: 87948,
            procedureId: 52,
            target: "шт",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "R - снимок",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 2
        }, {
            id: 185417,
            planItemId: 87948,
            procedureId: 16,
            target: "23",
            isActive: true,
            price: 2352,
            doneVisitId: null,
            name: "Лечение глубокого  кариеса",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185418,
            planItemId: 87948,
            procedureId: 267,
            target: "23",
            isActive: true,
            price: 550,
            doneVisitId: null,
            name: "Механическая и антисептическая обработка корневых каналов",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185419,
            planItemId: 87948,
            procedureId: 266,
            target: "шт",
            isActive: false,
            price: 900,
            doneVisitId: null,
            name: "Извлечение дентикля",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185420,
            planItemId: 87948,
            procedureId: 24,
            target: "23",
            isActive: true,
            price: 1628,
            doneVisitId: null,
            name: "Обработка корн. канала  с помощью эндодон. наконечника  NSK - 1-е посещение",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185421,
            planItemId: 87948,
            procedureId: 29,
            target: "23",
            isActive: true,
            price: 400,
            doneVisitId: null,
            name: "Пломбирование корневого канала зуба (гуттаперчивые штифты + АН-plus,канасон)",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185422,
            planItemId: 87948,
            procedureId: 265,
            target: "шт",
            isActive: true,
            price: 625,
            doneVisitId: null,
            name: "Пломбировочный материал Призмафил",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185423,
            planItemId: 87948,
            procedureId: 301,
            target: "шт",
            isActive: false,
            price: 50,
            doneVisitId: null,
            name: "Временная пломба (Дентин паста)",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Депульпирование по ортопедическим показаниям",
        serviceGroupId: 248,
        isAdditional: false,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87948, direction: "therapy"}],
        directions: ["therapy"],
        directionsQuantity: {therapy: 1},
        paid: null,
        creatorsIds: [2924],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 327,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 13654,
        performerId: 13654,
        nextVisitLast: null,
        createdVisit: 21904,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Индивидуальный набор инструментов",
        procedures: [{
            id: 185425,
            planItemId: 87950,
            procedureId: 282,
            target: "шт",
            isActive: true,
            price: 320,
            doneVisitId: null,
            name: "Индивидуальный набор инструментов",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Индивидуальный набор инструментов",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 5,
        fieldsStack: [{id: 87950, direction: "orthopedics"}, {id: 87951, direction: "orthopedics"}, {
            id: 87952,
            direction: "orthopedics"
        }, {id: 87953, direction: "orthopedics"}, {id: 87954, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 5},
        paid: null,
        creatorsIds: [13654],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 328,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 13654,
        performerId: 13654,
        nextVisitLast: null,
        createdVisit: 21904,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Консультация",
        procedures: [{
            id: 185430,
            planItemId: 87955,
            procedureId: 283,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Консультация",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Консультация",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87955, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [13654],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 337,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 13654,
        performerId: 13654,
        nextVisitLast: null,
        createdVisit: 21904,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Получение слепка альгинатным  материалом",
        procedures: [{
            id: 185431,
            planItemId: 87956,
            procedureId: 292,
            target: "в.ч., н.ч.",
            isActive: true,
            price: 500,
            doneVisitId: null,
            name: "Получение слепка альгинатным  материалом",
            measure: "BOTH_JAWS",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Получение слепка альгинатным  материалом",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87956, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [13654],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 131,
        measure: "SEGMENT",
        target: "14, 15, 16, 17, 18, 22, 24, 25, 26, 28",
        status: "NOT_STARTED",
        creatorId: 13654,
        performerId: 13654,
        nextVisitLast: null,
        createdVisit: 21904,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Протезирование съемным протезом на ацеталовом базисе dentalD",
        procedures: [{
            id: 185432,
            planItemId: 87957,
            procedureId: 140,
            target: "шт",
            isActive: true,
            price: 500,
            doneVisitId: null,
            name: "Изготовление прикусного валика",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185433,
            planItemId: 87957,
            procedureId: 138,
            target: "шт",
            isActive: true,
            price: 700,
            doneVisitId: null,
            name: "Изготовление индивидуальной ложки",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185434,
            planItemId: 87957,
            procedureId: 141,
            target: "шт",
            isActive: true,
            price: 1200,
            doneVisitId: null,
            name: "Определение  центрального соотношения челюстей (высота прикуса)",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185435,
            planItemId: 87957,
            procedureId: 293,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Примерка каркаса",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185436,
            planItemId: 87957,
            procedureId: 294,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Проверка постановки искусственных зубов",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185437,
            planItemId: 87957,
            procedureId: 131,
            target: "14, 15, 16, 17, 18, 22, 24, 25, 26, 28",
            isActive: true,
            price: 15900,
            doneVisitId: null,
            name: "Съемный протез на ацеталовом базисе Dental Di",
            measure: "SEGMENT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185438,
            planItemId: 87957,
            procedureId: 208,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Коррекция зубного протеза",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 5
        }],
        serviceName: "Протезирование съемным протезом на ацеталовом базисе dentalD",
        serviceGroupId: 290,
        isAdditional: false,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87957, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [13654],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 336,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 13654,
        performerId: 13654,
        nextVisitLast: null,
        createdVisit: 21904,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Получение слепка силиконовым  материалом",
        procedures: [{
            id: 185443,
            planItemId: 87958,
            procedureId: 291,
            target: "в.ч., н.ч.",
            isActive: true,
            price: 600,
            doneVisitId: null,
            name: "Получение слепка силиконовым  материалом",
            measure: "BOTH_JAWS",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Получение слепка силиконовым  материалом",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87958, direction: "orthopedics"}],
        directions: ["orthopedics"],
        directionsQuantity: {orthopedics: 1},
        paid: null,
        creatorsIds: [13654],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 327,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 13654,
        performerId: 13641,
        nextVisitLast: null,
        createdVisit: 21904,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Индивидуальный набор инструментов",
        procedures: [{
            id: 185468,
            planItemId: 87972,
            procedureId: 282,
            target: "шт",
            isActive: true,
            price: 320,
            doneVisitId: null,
            name: "Индивидуальный набор инструментов",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Индивидуальный набор инструментов",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87972, direction: "surgery"}],
        directions: ["surgery"],
        directionsQuantity: {surgery: 1},
        paid: null,
        creatorsIds: [13654],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 328,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 13654,
        performerId: 13641,
        nextVisitLast: null,
        createdVisit: 21904,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Консультация",
        procedures: [{
            id: 185469,
            planItemId: 87973,
            procedureId: 283,
            target: "шт",
            isActive: true,
            price: 0,
            doneVisitId: null,
            name: "Консультация",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Консультация",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87973, direction: "surgery"}],
        directions: ["surgery"],
        directionsQuantity: {surgery: 1},
        paid: null,
        creatorsIds: [13654],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 332,
        measure: "PRODUCT",
        target: "шт",
        status: "NOT_STARTED",
        creatorId: 13654,
        performerId: 13641,
        nextVisitLast: null,
        createdVisit: 21904,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Анестезия - Septanest",
        procedures: [{
            id: 185470,
            planItemId: 87974,
            procedureId: 287,
            target: "шт",
            isActive: true,
            price: 350,
            doneVisitId: null,
            name: "Инъекционное введение лекарственных средств в слизистую оболочку полости  рта (Septanest)",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Анестезия - Septanest",
        serviceGroupId: 246,
        isAdditional: true,
        isSeparate: false,
        quantity: 1,
        fieldsStack: [{id: 87974, direction: "surgery"}],
        directions: ["surgery"],
        directionsQuantity: {surgery: 1},
        paid: null,
        creatorsIds: [13654],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }, {
        planId: 8836,
        serviceId: 164,
        measure: "TOOTH",
        target: "22",
        status: "NOT_STARTED",
        creatorId: 13654,
        performerId: 13641,
        nextVisitLast: null,
        createdVisit: 21904,
        doneInVisit: null,
        doneTime: null,
        statusHistory: null,
        printedConsents: null,
        proceduresCombinationsConditions: null,
        careCallMade: false,
        surveyInviteMade: false,
        params: null,
        isDone: false,
        isBlocked: false,
        name: "Удаление 1-корневого зуба",
        procedures: [{
            id: 185471,
            planItemId: 87975,
            procedureId: 164,
            target: "22",
            isActive: true,
            price: 1725,
            doneVisitId: null,
            name: "Удаление 1-корневого зуба",
            measure: "TOOTH",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }, {
            id: 185472,
            planItemId: 87975,
            procedureId: 52,
            target: "шт",
            isActive: true,
            price: 300,
            doneVisitId: null,
            name: "R - снимок",
            measure: "PRODUCT",
            isBlocked: false,
            isDone: false,
            quantity: 1
        }],
        serviceName: "Удаление 1-корневого зуба",
        serviceGroupId: 3,
        isAdditional: false,
        isSeparate: true,
        quantity: 1,
        fieldsStack: [{id: 87975, direction: "surgery"}],
        directions: ["surgery"],
        directionsQuantity: {surgery: 1},
        paid: null,
        creatorsIds: [13654],
        stages: {},
        confirmedStages: [],
        manuallyChangedStages: []
    }]

export const rowsThree = [
    {
    planId: 12068,
    serviceId: 327,
    measure: "PRODUCT",
    target: "шт",
    status: "DONE",
    creatorId: 2614,
    performerId: 2614,
    nextVisitLast: null,
    createdVisit: 34061,
    doneInVisit: 34061,
    doneTime: "2021-04-05T18:02:33.000000Z",
    statusHistory: null,
    printedConsents: null,
    proceduresCombinationsConditions: null,
    careCallMade: false,
    surveyInviteMade: false,
    params: null,
    isDone: true,
    isBlocked: false,
    name: "Индивидуальный набор инструментов",
    paid: 800,
    procedures: [{
        id: 322029,
        planItemId: 149165,
        procedureId: 282,
        target: "шт",
        isActive: true,
        price: 400,
        doneVisitId: 34061,
        name: "Индивидуальный набор инструментов",
        measure: "PRODUCT",
        isBlocked: false,
        isDone: true,
        quantity: 1
    }],
    serviceName: "Индивидуальный набор инструментов",
    serviceGroupId: 246,
    isAdditional: true,
    isSeparate: true,
    quantity: 2,
    fieldsStack: [{id: 149165, direction: "surgery"}, {id: 149166, direction: "therapy"}],
    directions: ["surgery", "therapy"],
    directionsQuantity: {surgery: 1, therapy: 1},
    creatorsIds: [2614],
    stages: {"6187": {surgery: 1}, "6188": {therapy: 1}},
    confirmedStages: [6187, 6188],
    manuallyChangedStages: []
}, {
    planId: 12068,
    serviceId: 328,
    measure: "PRODUCT",
    target: "шт",
    status: "DONE",
    creatorId: 2614,
    performerId: 2614,
    nextVisitLast: null,
    createdVisit: 34061,
    doneInVisit: 34061,
    doneTime: "2021-04-05T18:02:33.000000Z",
    statusHistory: null,
    printedConsents: null,
    proceduresCombinationsConditions: null,
    careCallMade: false,
    surveyInviteMade: false,
    params: null,
    isDone: true,
    isBlocked: false,
    name: "Консультация",
    paid: 0,
    procedures: [{
        id: 322031,
        planItemId: 149167,
        procedureId: 283,
        target: "шт",
        isActive: true,
        price: 0,
        doneVisitId: 34061,
        name: "Консультация",
        measure: "PRODUCT",
        isBlocked: false,
        isDone: true,
        quantity: 1
    }],
    serviceName: "Консультация",
    serviceGroupId: 246,
    isAdditional: true,
    isSeparate: true,
    quantity: 2,
    fieldsStack: [{id: 149167, direction: "surgery"}, {id: 149168, direction: "therapy"}],
    directions: ["surgery", "therapy"],
    directionsQuantity: {surgery: 1, therapy: 1},
    creatorsIds: [2614],
    stages: {"6187": {surgery: 1}, "6188": {therapy: 1}},
    confirmedStages: [6187, 6188],
    manuallyChangedStages: []
}, {
    planId: 12068,
    serviceId: 332,
    measure: "PRODUCT",
    target: "шт",
    status: "DONE",
    creatorId: 2614,
    performerId: 2614,
    nextVisitLast: null,
    createdVisit: 34061,
    doneInVisit: 34061,
    doneTime: "2021-04-05T18:02:33.000000Z",
    statusHistory: null,
    printedConsents: null,
    proceduresCombinationsConditions: null,
    careCallMade: false,
    surveyInviteMade: false,
    params: null,
    isDone: true,
    isBlocked: false,
    name: "Анестезия - Septanest",
    paid: 400,
    procedures: [{
        id: 322033,
        planItemId: 149169,
        procedureId: 287,
        target: "шт",
        isActive: true,
        price: 400,
        doneVisitId: 34061,
        name: "Инъекционное введение лекарственных средств в слизистую оболочку полости  рта (Septanest)",
        measure: "PRODUCT",
        isBlocked: false,
        isDone: true,
        quantity: 1
    }],
    serviceName: "Анестезия - Septanest",
    serviceGroupId: 246,
    isAdditional: true,
    isSeparate: false,
    quantity: 1,
    fieldsStack: [{id: 149169, direction: "surgery"}],
    directions: ["surgery"],
    directionsQuantity: {surgery: 1},
    creatorsIds: [2614],
    stages: {"6187": {surgery: 1}},
    confirmedStages: [6187],
    manuallyChangedStages: []
}, {
    planId: 12068,
    serviceId: 165,
    measure: "TOOTH",
    target: "14",
    status: "DONE",
    creatorId: 2614,
    performerId: 2614,
    nextVisitLast: null,
    createdVisit: 34061,
    doneInVisit: 34061,
    doneTime: "2021-04-05T18:02:33.000000Z",
    statusHistory: null,
    printedConsents: null,
    proceduresCombinationsConditions: null,
    careCallMade: false,
    surveyInviteMade: true,
    params: null,
    isDone: true,
    isBlocked: false,
    name: "Удаление 2-корневого зуба",
    paid: 2175,
    procedures: [{
        id: 322034,
        planItemId: 149170,
        procedureId: 165,
        target: "14",
        isActive: true,
        price: 1875,
        doneVisitId: 34061,
        name: "Удаление 2-корневого зуба",
        measure: "TOOTH",
        isBlocked: false,
        isDone: true,
        quantity: 1
    }, {
        id: 322035,
        planItemId: 149170,
        procedureId: 52,
        target: "шт",
        isActive: true,
        price: 300,
        doneVisitId: 34061,
        name: "R - снимок",
        measure: "PRODUCT",
        isBlocked: false,
        isDone: true,
        quantity: 1
    }],
    serviceName: "Удаление 2-корневого зуба",
    serviceGroupId: 3,
    isAdditional: false,
    isSeparate: true,
    quantity: 1,
    fieldsStack: [{id: 149170, direction: "surgery"}],
    directions: ["surgery"],
    directionsQuantity: {surgery: 1},
    creatorsIds: [2614],
    stages: {"6187": {surgery: 1}},
    confirmedStages: [6187],
    manuallyChangedStages: []
}, {
    planId: 12068,
    serviceId: 333,
    measure: "PRODUCT",
    target: "шт",
    status: "DONE",
    creatorId: 2614,
    performerId: 2614,
    nextVisitLast: null,
    createdVisit: 34061,
    doneInVisit: 34061,
    doneTime: "2021-04-05T18:02:33.000000Z",
    statusHistory: null,
    printedConsents: null,
    proceduresCombinationsConditions: null,
    careCallMade: false,
    surveyInviteMade: false,
    params: null,
    isDone: true,
    isBlocked: false,
    name: "Использование оптрагейт",
    paid: 500,
    procedures: [{
        id: 322036,
        planItemId: 149171,
        procedureId: 288,
        target: "шт",
        isActive: true,
        price: 500,
        doneVisitId: 34061,
        name: "Использование оптрагейт",
        measure: "PRODUCT",
        isBlocked: false,
        isDone: true,
        quantity: 1
    }],
    serviceName: "Использование оптрагейт",
    serviceGroupId: 246,
    isAdditional: true,
    isSeparate: false,
    quantity: 1,
    fieldsStack: [{id: 149171, direction: "therapy"}],
    directions: ["therapy"],
    directionsQuantity: {therapy: 1},
    creatorsIds: [2614],
    stages: {"6188": {therapy: 1}},
    confirmedStages: [6188],
    manuallyChangedStages: []
}, {
    planId: 12068,
    serviceId: 224,
    measure: "TOOTH",
    target: "44",
    status: "DONE",
    creatorId: 2614,
    performerId: 2614,
    nextVisitLast: null,
    createdVisit: 34061,
    doneInVisit: 34061,
    doneTime: "2021-04-05T18:02:33.000000Z",
    statusHistory: null,
    printedConsents: null,
    proceduresCombinationsConditions: null,
    careCallMade: false,
    surveyInviteMade: true,
    params: null,
    isDone: true,
    isBlocked: false,
    name: "Лечение среднего кариеса с использованием пломбировочного материала \"ESTHET X\"",
    paid: 5320,
    procedures: [{
        id: 322037,
        planItemId: 149172,
        procedureId: 221,
        target: "44",
        isActive: true,
        price: 2130,
        doneVisitId: 34061,
        name: "Лечение среднего кариеса",
        measure: "TOOTH",
        isBlocked: false,
        isDone: true,
        quantity: 1
    }, {
        id: 322038,
        planItemId: 149172,
        procedureId: 224,
        target: "44",
        isActive: true,
        price: 3190,
        doneVisitId: 34061,
        name: "Пломбировочный материал \"ESTHET X\"",
        measure: "TOOTH",
        isBlocked: false,
        isDone: true,
        quantity: 1
    }],
    serviceName: "Лечение среднего кариеса с использованием пломбировочного материала \"ESTHET X\"",
    serviceGroupId: 100,
    isAdditional: false,
    isSeparate: true,
    quantity: 1,
    fieldsStack: [{id: 149172, direction: "therapy"}],
    directions: ["therapy"],
    directionsQuantity: {therapy: 1},
    creatorsIds: [2614],
    stages: {"6188": {therapy: 1}},
    confirmedStages: [6188],
    manuallyChangedStages: []
}, {
    planId: 12068,
    serviceId: 221,
    measure: "TOOTH",
    target: "44",
    status: "DONE",
    creatorId: 2614,
    performerId: 2614,
    nextVisitLast: null,
    createdVisit: 34061,
    doneInVisit: 34061,
    doneTime: "2021-04-05T18:02:33.000000Z",
    statusHistory: null,
    printedConsents: null,
    proceduresCombinationsConditions: null,
    careCallMade: false,
    surveyInviteMade: true,
    params: null,
    isDone: true,
    isBlocked: false,
    name: "Лечение среднего кариеса с использованием пломбировочного материала \"CHARISMA\"",
    paid: 2943,
    procedures: [{
        id: 322039,
        planItemId: 149173,
        procedureId: 221,
        target: "44",
        isActive: true,
        price: 2130,
        doneVisitId: 34061,
        name: "Лечение среднего кариеса",
        measure: "TOOTH",
        isBlocked: false,
        isDone: true,
        quantity: 1
    }, {
        id: 322040,
        planItemId: 149173,
        procedureId: 263,
        target: "шт",
        isActive: true,
        price: 813,
        doneVisitId: 34061,
        name: "Пломбировочный материал \"CHARISMA\"",
        measure: "PRODUCT",
        isBlocked: false,
        isDone: true,
        quantity: 1
    }],
    serviceName: "Лечение среднего кариеса с использованием пломбировочного материала \"CHARISMA\"",
    serviceGroupId: 100,
    isAdditional: false,
    isSeparate: true,
    quantity: 1,
    fieldsStack: [{id: 149173, direction: "therapy"}],
    directions: ["therapy"],
    directionsQuantity: {therapy: 1},
    creatorsIds: [2614],
    stages: {"6188": {therapy: 1}},
    confirmedStages: [6188],
    manuallyChangedStages: []
}, {
    planId: 12068,
    serviceId: 317,
    measure: "SEGMENT",
    target: "44",
    status: "DONE",
    creatorId: 2614,
    performerId: 2614,
    nextVisitLast: null,
    createdVisit: 34061,
    doneInVisit: 34061,
    doneTime: "2021-04-05T18:02:33.000000Z",
    statusHistory: null,
    printedConsents: null,
    proceduresCombinationsConditions: null,
    careCallMade: false,
    surveyInviteMade: true,
    params: null,
    isDone: true,
    isBlocked: false,
    name: "Профессиональное отбеливание « Opalescence Extra Boost»",
    paid: 9900,
    procedures: [{
        id: 322041,
        planItemId: 149174,
        procedureId: 273,
        target: "44",
        isActive: true,
        price: 9900,
        doneVisitId: 34061,
        name: "Отбеливание",
        measure: "SEGMENT",
        isBlocked: false,
        isDone: true,
        quantity: 1
    }],
    serviceName: "Профессиональное отбеливание « Opalescence Extra Boost»",
    serviceGroupId: 212,
    isAdditional: false,
    isSeparate: true,
    quantity: 1,
    fieldsStack: [{id: 149174, direction: "therapy"}],
    directions: ["therapy"],
    directionsQuantity: {therapy: 1},
    creatorsIds: [2614],
    stages: {"6188": {therapy: 1}},
    confirmedStages: [6188],
    manuallyChangedStages: []
}]
