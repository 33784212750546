import {PatientValues} from "../../../../../containers/PatientField";
import ServerCommand from "../../../../../common/server/server-command";
import {ResponsePatientList} from "../../../../../containers/PatientField/types/common";
import prepareDataExistingPatient
    from "../../../../../containers/PatientField/helper/prepareDataExistingPatient";
import preparePersonalGender
    from "../../../../../containers/PatientField/helper/preparePersonalGender";
import {PropsUseLoadingPatient} from "../types/hooks";
import useLoader from "../../../../../components/Errors";
import {useSelector} from "react-redux";

export default function useLoadingPatient({setForm, fields}: PropsUseLoadingPatient) {
    const [loaderPatient, setLoaderPatient] = useLoader();
    const branches = useSelector(state => state.common.allBranches);

    const loadingPatient = (patient: PatientValues) => {
        setLoaderPatient(true);
        ServerCommand.get('patient/list', {
            filter: !patient.personalPhone ? {id: patient.id} : {personalPhone: patient.personalPhone},
            select: ['personalPhone', 'name', 'lastName', 'secondName', 'id', 'phoneOwner', 'personalBirthday', 'personalGender', 'branches'],
            getRelationsToPatient: patient.id
        })
            .then((response: ResponsePatientList) => {
                setForm(prevState => {
                    const {
                        currentPatient,
                        optionsPhoneOwner,
                        phoneOwner
                    } = prepareDataExistingPatient(response.list, patient)
                    const statePatient = {
                        lastName: !!currentPatient.lastName ? currentPatient.lastName : '',
                        name: !!currentPatient.name ? currentPatient.name : '',
                        secondName: !!currentPatient.secondName ? currentPatient.secondName : '',
                        personalBirthday: !!currentPatient.personalBirthday ? currentPatient.personalBirthday : '',
                        personalGender: preparePersonalGender(currentPatient, fields),
                        personalPhone: !!currentPatient.personalPhone ? currentPatient.personalPhone : '',
                        phoneOwner,
                    } as any
                    return {
                        ...prevState,
                        basic: {
                            ...prevState.basic,
                            ...statePatient,
                            optionsPhoneOwner
                        },
                        currentPatient: {
                            id: currentPatient.id,
                            ...statePatient
                        },
                        branches: !!currentPatient.branches && !!currentPatient.branches.length
                            ? currentPatient.branches.map(branchId => ({
                                label: branches[branchId].name,
                                value: branches[branchId].id
                            }))
                            : []
                    }
                })
                setLoaderPatient(false);
            });
    };

    return {
        loaderPatient,
        loadingPatient
    }
}
