import React, {useRef} from "react";
import {useDentalCard} from "../../DentalRecords";
import {SDentalCard} from "../../types";
import {ContextMenuWrap, ContextMenuButton} from "./styledContextMenu";
import { ToothStatus } from "../../../../common/types/teethmap";
import SVG from "../../../../common/helpers/SVG";
import useOutsideClick from "../../../../common/hooks/useOutsideClick";


export default function ContextMenu() {
    const {positionContextMenu, teethMap, openContextMenu, closeContextMenu, onClickButtonContextMenu} = useDentalCard() as SDentalCard;
    const ref = useRef(null);
    useOutsideClick(ref, () => closeContextMenu());

    if (!openContextMenu) return null;

    const {statusList} = teethMap;

    const statuses = Object.values(statusList).sort((a: any, b: any) => a.sort > b.sort ? 1 : -1);

    return (
        <ContextMenuWrap position={positionContextMenu} ref={ref}>
            {statuses.map((status: ToothStatus) => {
                return (
                    <ContextMenuButton isSelected={false}
                                       group={status.group}
                                       key={status.code + status.id}
                                       onClick={(e) => {
                                           e.preventDefault();
                                           onClickButtonContextMenu(status.id);
                                           closeContextMenu();
                                       }}
                    >
                        <span className="tooth-status-code">
                            {status.code === 'G' ? <SVG name="teeth/tooth-standard" /> : status.code}
                        </span>
                        <span className="tooth-status-text">
                            {status.decode}
                        </span>
                    </ContextMenuButton>
                )
            })}
        </ContextMenuWrap>
    )
}
