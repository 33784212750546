import React from 'react';

interface Props {
    formatterCode?: (code: any) => string
    formatterValue?: (value: any) => string
    type?: string
    textAnchor?: string
    propsX?: number
    propsY?: number

    [key: string]: any
}

const CustomTick = (
    {
        formatterCode,
        formatterValue,
        type = 'x',
        propsY = 11,
        propsX = 0,
        textAnchor = 'middle',
        x,
        y,
        payload
    }: Props) => {

    let content = '';

    if (type === 'x') {
        content = !!formatterCode ? formatterCode(payload.value) : payload.value
    } else {
        content = !!formatterValue ? formatterValue(payload.value) : payload.value
    }

    return (
        <g transform={`translate(${x}, ${y})`}>
            <text x={propsX} y={propsY}
                  textAnchor={payload.index === 0 ? 'start' : textAnchor}
                  fill="#6c7783"
                  fontSize="12px"
                  fontFamily="Open Sans, Arial, sans-serif"
                  fontWeight="600"
            >
                {content}
            </text>
        </g>
    )
};

export default CustomTick;
