import {ScriptType} from "../../../types/common";
import React, {Dispatch, SetStateAction, useEffect, useRef} from "react";
import {SScriptItem} from "../style";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";

interface Props {
    script: ScriptType,
    setSelectedScript: Dispatch<SetStateAction<ScriptType | null>>
    selectedScript: ScriptType | null,
    lastItem: boolean
    open: boolean,
    setRefItem: Dispatch<SetStateAction<any>>
}

const ScriptListItem = ({script, setSelectedScript, selectedScript, lastItem, open, setRefItem}: Props) => {
    const ref = useRef(null) as any

    useEffect(() => {
        if (!!ref.current && selectedScript?.id === script.id) {
            setRefItem(ref.current)
        }
    }, [selectedScript])

    return (
        <SScriptItem onClick={() => setSelectedScript(script)}
                     active={!!selectedScript && script.id === selectedScript.id}
                     lastItem={lastItem}
                     open={open}
                     ref={ref}>
            <Typography children={script.name}
                        textDecoration={!!selectedScript && script.id === selectedScript.id ? 'underline' : 'none'}
            />
        </SScriptItem>
    )

}

export default ScriptListItem
