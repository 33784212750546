import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonTextFieldOption} from "../../data";
import TextField from "../../../../../components-ui/Inputs/TextField/TextField";
import {IStateCashSalary} from "../../types";

interface Props {
    state: IStateCashSalary
    setState: Dispatch<SetStateAction<IStateCashSalary>>
    minSum: number
    maxSum: number
}

const ReturnSum = ({state, setState, maxSum, minSum}: Props) => {
    const handleReturnSum = useCallback((value: string) => {
        setState(prevState => ({...prevState, value}))
    }, [setState])

    return (
        <Label labelText="Сумма" {...commonLabelOption}>
            <TextField
                {...commonTextFieldOption}
                value={state.value}
                isNumber={true}
                min={minSum}
                onChange={handleReturnSum}
                //todo
                // max={maxSum}
            />
        </Label>
    );
};

export default ReturnSum;
