import React, {useEffect, useState} from 'react';
import {EmployeeScheduleInterval, TimesheetRecord} from '../../../../../content/TimeSheet/types';
import {GetSalariesTimesheetResponce} from '../../../types';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import {useSelector} from 'react-redux';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import DateHelper from '../../../../../common/helpers/date-helper';
import Box from '../../../../../components-ui/Layout/Box';
import Icon from '../../../../../components-ui/DataDisplay/Icon';
import Tooltip from '../../../../../components-ui/DataDisplay/Tooltip/Tooltip';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import ButtonForOptions from '../../../../../components-ui/Inputs/ButtonForOptions/ButtonForOptions';
import ModalDeleteTimeSheetRecord
    from '../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalDeleteTimeSheetRecord';
import {OptionSelect} from '../../../../../components-ui/Inputs/Select/typesSelect';
import ModalTimesheetRecord from '../../modals/ModalTimesheetRecord';
import _ from 'lodash';

interface Props {
    data: GetSalariesTimesheetResponce;
    selectedBranch: OptionSelect;
    refreshDataFromServer: () => void;
}

interface TimesheetRow {
    date: string;
    employeeId: number;
    timesheetRecord?: TimesheetRecord;
    scheduleInterval?: EmployeeScheduleInterval;
}

const useTableFormatter = ({data, selectedBranch, refreshDataFromServer}: Props) => {

    const {showModal} = useDialogsManager();

    const users = useSelector(state => state.common.users);

    let rowsMap: {[key: string]: TimesheetRow} = {};

    const [rows, setRows] = useState<TimesheetRow[]>([]);

    const diffScheduleAndRecord = (startTime: string | undefined, endTime: string | undefined) => {
        if (startTime && endTime) {
            const diff = DateHelper.getDurationString(
                startTime,
                endTime,
            );
            if (diff[0] !== '-') {
                return diff;
            }
        }
        return null;
    };

    useEffect(() => {
        //TODO сливает в один объект данные из записи (record) и графика (schedule)
        rowsMap = {};

        data.records.forEach(record => {
            rowsMap[record.date + String(record.employeeId)] = {
                date: record.date,
                employeeId: record.employeeId,
                timesheetRecord: record,
            };
        });

        if (data.schedule) {
            data.schedule.forEach(interval => {
                const rowKey = interval.date + String(interval.employeeId);
                const row = rowsMap[rowKey] ?? {
                    date: interval.date,
                    employeeId: interval.employeeId,
                };

                row.scheduleInterval = interval;
                rowsMap[rowKey] = row;
            });
        }
        const rowsSorted = _.chain(Object.values(rowsMap))
            .sortBy((row) => users[row.employeeId].fio)
            .sortBy('date')
            .value();

        setRows(rowsSorted);
    }, [data, users]);


    const columns: IColumn<TimesheetRow>[] = [
        {
            field: 'date',
            headerName: 'Дата',
            valueFormatter: ({childrenCell}) => <Typography>
                {DateHelper.formatDate(childrenCell, 'DD MMM YYYY')}
            </Typography>,
        },
        {
            field: 'employee',
            headerName: 'Сотрудник',
            valueFormatter: ({row}) =>
                row.timesheetRecord && DateHelper.formatDateForServer(row.date) ===
                DateHelper.formatDateForServer(new Date()) ?
                    <>
                        <Box position='absolute' positionOffsets={{left: '-5px'}}>
                            <Icon
                                boxSizing='content-box'
                                padding='6px 7px 4px'
                                width='8px'
                                height='10px'
                                name='employee'
                                color={row.timesheetRecord?.endTime ? 'borderGray' : 'inc'}
                                data-for={String(row.employeeId)}
                                data-tip={row.timesheetRecord?.endTime ? 'Сотрудник ушёл' : 'Сотрудник находится в клинике'}
                            />
                        </Box>
                        <Tooltip id={String(row.employeeId)} effect='solid' place={'left'} />
                        <Typography children={users[row.employeeId].fio} />
                    </>
                    :
                    <>{users[row.employeeId].fio} </>,

        },
        {
            field: 'timesheetRecord',
            headerName: 'График',
            valueFormatter: ({row}) => <>
                {
                    row.scheduleInterval?.startTime && row.scheduleInterval?.endTime &&
                    `с ${row.scheduleInterval.startTime} до ${row.scheduleInterval.endTime}`
                }
            </>,
        },
        {
            field: 'arrivalTime',
            headerName: 'Время прихода',
            valueFormatter: ({row}) =>
                <Box display='flex' alignItems='center' height='max-content'>
                    {row.timesheetRecord?.startTime}
                    {row.timesheetRecord?.startTimeComment &&
                        <>
                            <Icon width='14px'
                                  height='14px'
                                  margin='0 0 0 5px'
                                  name='comment'
                                  color='borderGray'
                                  data-for={String(row.date + row.employeeId + row.timesheetRecord?.startTimeComment)}
                                  data-tip={row.timesheetRecord?.startTimeComment}
                            />
                            <Tooltip id={String(row.date + row.employeeId + row.timesheetRecord?.startTimeComment)}
                                     effect='solid' />
                        </>
                    }
                </Box>,
        },
        {
            field: 'departureTime',
            headerName: 'Время ухода',
            valueFormatter: ({row}) =>
                <Box display='flex' alignItems='center' height='max-content'>
                    {row.timesheetRecord?.endTime}
                    {row.timesheetRecord?.endTimeComment &&
                        <>
                            <Icon width='14px'
                                  height='14px'
                                  margin='0 0 0 5px'
                                  name='comment'
                                  color='borderGray'
                                  data-for={String(row.date + row.employeeId + row.timesheetRecord?.endTimeComment)}
                                  data-tip={row.timesheetRecord?.endTimeComment}
                            />
                            <Tooltip id={String(row.date + row.employeeId + row.timesheetRecord?.endTimeComment)}
                                     effect='solid' />
                        </>
                    }
                </Box>,
        },
        {
            field: 'lateArrival',
            headerName: 'Опоздание',
            valueFormatter: ({row}) => {
                const diff = diffScheduleAndRecord(
                    row.scheduleInterval?.startTime,
                    row.timesheetRecord?.startTime,
                );
                return <Typography color={diff ? 'dec' : 'text'}>
                    {diff || '-'}
                </Typography>;
            }
            ,
        },
        {
            field: 'leavingTime',
            headerName: 'Ранний уход',
            valueFormatter: ({row}) => {
                const diff = diffScheduleAndRecord(
                    row.timesheetRecord?.endTime || undefined,
                    row.scheduleInterval?.endTime,
                );
                return <Typography color={diff ? 'dec' : 'text'}>
                    {diff || '-'}
                </Typography>;
            },
        },
        {
            field: 'button',
            width: '45px',
            valueFormatter: ({row}) =>
                row.timesheetRecord && <ButtonForOptions position='left-bottom'
                                                         options={[
                                                             {
                                                                 value: 'Изменить время',
                                                                 label: 'Изменить время',
                                                                 onClick: () => {
                                                                     showModal(<ModalTimesheetRecord
                                                                         definitiveTimesheet={
                                                                             {
                                                                                 employee: {
                                                                                     value: row.employeeId,
                                                                                     label: users[row.employeeId].fio,
                                                                                 },
                                                                                 // @ts-ignore
                                                                                 timesheetRecord: row.timesheetRecord,
                                                                             }
                                                                         }
                                                                         data={data}
                                                                         selectedBranchId={selectedBranch.value}
                                                                         refreshDataFromServer={refreshDataFromServer} />);
                                                                 },
                                                             },
                                                             {
                                                                 value: 'Удалить запись табеля',
                                                                 label: 'Удалить запись табеля',
                                                                 onClick: () => {
                                                                     showModal(<ModalDeleteTimeSheetRecord
                                                                         id={row.timesheetRecord?.id || 0}
                                                                         refreshDataFromServer={refreshDataFromServer}
                                                                     />);
                                                                 },
                                                             },
                                                         ]} />,


        },
    ];

    return {rows, columns};
};

export default useTableFormatter;
