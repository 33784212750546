import React from "react";
import {UIContentWrap} from "./styledUI";
import {Route} from "react-router";
import AvatarPage from "./Pages/DataDisplay/AvatarPage/AvatarPage";
import BadgePage from "./Pages/DataDisplay/BadgePage/BadgePage";
import ChipPage from "./Pages/DataDisplay/ChipPage/ChipPage";
import DividerPage from "./Pages/DataDisplay/DividerPage/DividerPage";
import IconsPage from "./Pages/DataDisplay/IconsPage/IconsPage";
import ListPage from "./Pages/DataDisplay/ListPage/ListPage";
import TablePage from "./Pages/DataDisplay/TablePage/TablePage";
import TooltipPage from "./Pages/DataDisplay/TooltipPage/TooltipPage";
import TypographyPage from "./Pages/DataDisplay/TypographyPage/TypographyPage";
import BackdropPage from "./Pages/Feedback/BackdropPage/BackdropPage";
import DialogPage from "./Pages/Feedback/DialogPage/DialogPage";
import ProgressPage from "./Pages/Feedback/ProgressPage/ProgressPage";
import SnackbarPage from "./Pages/Feedback/SnackbarPage/SnackbarPage";
import ButtonPage from "./Pages/Inputs/ButtonPage/ButtonPage";
import CalendarFieldPage from "./Pages/Inputs/CalendarFieldPage/CalendarFieldPage";
import CheckboxPage from "./Pages/Inputs/CheckboxPage/CheckboxPage";
import DateTimePage from "./Pages/Inputs/DateTimePage/DateTimePage";
import RadioPage from "./Pages/Inputs/RadioPage/RadioPage";
import SelectPage from "./Pages/Inputs/SelectPage/SelectPage";
import SliderPage from "./Pages/Inputs/SliderPage/SliderPage";
import SwitchPage from "./Pages/Inputs/SwitchPage/SwitchPage";
import TextFieldPage from "./Pages/Inputs/TextFieldPage/TextFieldPage";
import TimeCounterPage from "./Pages/Inputs/TimeCounterPage/TimeCounterPage";
import AboutTheLabPage from "./Pages/Lab/AboutTheLabPage/AboutTheLabPage";
import AutocompletePage from "./Pages/Lab/AutocompletePage/AutocompletePage";
import PaginationPage from "./Pages/Lab/PaginationPage/PaginationPage";
import RatingPage from "./Pages/Lab/RatingPage/RatingPage";
import SkeletonPage from "./Pages/Lab/SkeletonPage/SkeletonPage";
import SpeedDialPage from "./Pages/Lab/SpeedDialPage/SpeedDialPage";
import ToggleButtonPage from "./Pages/Lab/ToggleButtonPage/ToggleButtonPage";
import TreeViewPage from "./Pages/Lab/TreeViewPage/TreeViewPage";
import BottomNavigationPage from "./Pages/Navigation/BottomNavigationPage/BottomNavigationPage";
import BreadcrumbsPage from "./Pages/Navigation/BreadcrumbsPage/BreadcrumbsPage";
import DrawerPage from "./Pages/Navigation/DrawerPage/DrawerPage";
import LinkPage from "./Pages/Navigation/LinkPage/LinkPage";
import MenuPage from "./Pages/Navigation/MenuPage/MenuPage";
import StepperPage from "./Pages/Navigation/StepperPage/StepperPage";
import TabsPage from "./Pages/Navigation/TabsPage/TabsPage";
import AppBarPage from "./Pages/Surfaces/AppBarPage/AppBarPage";
import CardPage from "./Pages/Surfaces/CardPage/CardPage";
import ExpansionPanelPage from "./Pages/Surfaces/ExpansionPanelPage/ExpansionPanelPage";
import PaperPage from "./Pages/Surfaces/PaperPage/PaperPage";
import ClickAwayListenerPage from "./Pages/Utils/ClickAwayListenerPage/ClickAwayListenerPage";
import CssBaselinePage from "./Pages/Utils/CssBaselinePage/CssBaselinePage";
import ModalPage from "./Pages/Utils/ModalPage/ModalPage";
import NoSSRPage from "./Pages/Utils/NoSSRPage/NoSSRPage";
import PopoverPage from "./Pages/Utils/PopoverPage/PopoverPage";
import PopperPage from "./Pages/Utils/PopperPage/PopperPage";
import PortalPage from "./Pages/Utils/PortalPage/PortalPage";
import TextareaAutosizePage from "./Pages/Utils/TextareaAutosizePage/TextareaAutosizePage";
import TransitionsPage from "./Pages/Utils/TransitionsPage/TransitionsPage";
import UseMediaQueryPage from "./Pages/Utils/UseMediaQueryPage/UseMediaQueryPage";
import CounterPage from "./Pages/Inputs/CounterPage/CounterPage";
import TagPage from "./Pages/DataDisplay/TagPage/TagPage";
import BoxPage from "./Pages/Layout/BoxPage/BoxPage";
import AlertPage from "./Pages/DataDisplay/AlertPage/AlertPage";
import LabelPage from "./Pages/DataDisplay/LabelPage/LabelPage";
import PiePage from "./Pages/Charts/PiePage/PiePage";
import LinePage from "./Pages/Charts/LinePage/LinePage";
import BarPage from "./Pages/Charts/BarPage/BarPage";
import DoubleBarPage from "./Pages/Charts/DoubleBarPage/DoubleBarPage";
import ButtonForOptionsPage from './Pages/Inputs/ButtonForOptionsPage/ButtonForOptionsPage';

export default function UIContent () {

    return (
        <UIContentWrap>
            <>
                <Route path='/ui/box' component={BoxPage}/>
                <Route path='/ui/chart-pie' component={PiePage}/>
                <Route path='/ui/chart-line' component={LinePage}/>
                <Route path='/ui/chart-bar' component={BarPage}/>
                <Route path='/ui/double-bar' component={DoubleBarPage}/>
                <Route path='/ui/alert' component={AlertPage}/>
                <Route path='/ui/avatar' component={AvatarPage}/>
                <Route path='/ui/badge' component={BadgePage}/>
                <Route path='/ui/chip' component={ChipPage}/>
                <Route path='/ui/divider' component={DividerPage}/>
                <Route path='/ui/icons' component={IconsPage}/>
                <Route path='/ui/label' component={LabelPage}/>
                <Route path='/ui/list' component={ListPage}/>
                <Route path='/ui/table' component={TablePage}/>
                <Route path='/ui/tooltip' component={TooltipPage}/>
                <Route path='/ui/typography' component={TypographyPage}/>
                <Route path='/ui/tag' component={TagPage}/>
                <Route path='/ui/backdrop' component={BackdropPage}/>
                <Route path='/ui/dialog' component={DialogPage}/>
                <Route path='/ui/progress' component={ProgressPage}/>
                <Route path='/ui/snackbar' component={SnackbarPage}/>
                <Route path='/ui/button' component={ButtonPage}/>
                <Route path='/ui/buttonForOptions' component={ButtonForOptionsPage}/>
                <Route path='/ui/calendarField' component={CalendarFieldPage}/>
                <Route path='/ui/counter' component={CounterPage}/>
                <Route path='/ui/checkbox' component={CheckboxPage}/>
                <Route path='/ui/dateTime' component={DateTimePage}/>
                <Route path='/ui/radio' component={RadioPage}/>
                <Route path='/ui/select' component={SelectPage}/>
                <Route path='/ui/slider' component={SliderPage}/>
                <Route path='/ui/switch' component={SwitchPage}/>
                <Route path='/ui/textField' component={TextFieldPage}/>
                <Route path='/ui/timeCounter' component={TimeCounterPage}/>
                <Route path='/ui/aboutTheLab' component={AboutTheLabPage}/>
                <Route path='/ui/autocomplete' component={AutocompletePage}/>
                <Route path='/ui/pagination' component={PaginationPage}/>
                <Route path='/ui/rating' component={RatingPage}/>
                <Route path='/ui/skeleton' component={SkeletonPage}/>
                <Route path='/ui/speedDial' component={SpeedDialPage}/>
                <Route path='/ui/toggleButton' component={ToggleButtonPage}/>
                <Route path='/ui/treeView' component={TreeViewPage}/>
                <Route path='/ui/bottomNavigation' component={BottomNavigationPage}/>
                <Route path='/ui/breadcrumbs' component={BreadcrumbsPage}/>
                <Route path='/ui/drawer' component={DrawerPage}/>
                <Route path='/ui/link' component={LinkPage}/>
                <Route path='/ui/menu' component={MenuPage}/>
                <Route path='/ui/stepper' component={StepperPage}/>
                <Route path='/ui/tabs' component={TabsPage}/>
                <Route path='/ui/appBar' component={AppBarPage}/>
                <Route path='/ui/card' component={CardPage}/>
                <Route path='/ui/expansionPanel' component={ExpansionPanelPage}/>
                <Route path='/ui/paper' component={PaperPage}/>
                <Route path='/ui/clickAwayListener' component={ClickAwayListenerPage}/>
                <Route path='/ui/cssBaseline' component={CssBaselinePage}/>
                <Route path='/ui/modal' component={ModalPage}/>
                <Route path='/ui/noSSR' component={NoSSRPage}/>
                <Route path='/ui/popover' component={PopoverPage}/>
                <Route path='/ui/popper' component={PopperPage}/>
                <Route path='/ui/portal' component={PortalPage}/>
                <Route path='/ui/textareaAutosize' component={TextareaAutosizePage}/>
                <Route path='/ui/transitions' component={TransitionsPage}/>
                <Route path='/ui/useMediaQuery' component={UseMediaQueryPage}/>
            </>
        </UIContentWrap>
    )
}
