import {Props} from "../types";

export const getCursorTypography = (props: Props) => {
    const {cursor, isLink} = props;
    if (!!cursor) {
        return cursor
    } else {
        if (isLink) {
            return 'pointer'
        } else {
            return 'inherit'
        }
    }
}
