import styled from "styled-components";

export const SWrapConfirmDelete = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 34px 32px 52px 32px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
`;

export const SPopupContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    max-width: 340px;
    text-align: center;
    margin: 0 0 27px 0;
  }
`;

export const SPopupBtns = styled.div`
  margin-left: 0;
  border-top: 1px solid ${props => props.theme.colors.lightBlue};
  padding-top: 27px;
  
  button:not(:last-of-type) {
    margin-right: 16px;
  }
`;