import React from "react";
import {IColors} from "../../../common/Theme/Theme";
import styled from "styled-components";

interface Props {
    color?: keyof IColors
    margin?: string
    width?: string
}

const SDivider = styled.div<Props>`
    display: block;
    width: ${props => props.width || '100%'};
    border: none;
    border-bottom: 1px solid ${props => !!props.color ? props.theme.colors[props.color] : props.theme.colors.lightBlue};
    margin: 0;
    flex-shrink: 0;
    padding: 0;
    height: 1px;
    outline: none;
    ${props => !!props.margin && `margin: ${props.margin};`};
`

export const Divider = (props: Props) => <SDivider {...props}/>

