import useLoader from '../../../components/Errors';
import {useCallback, useEffect, useState} from 'react';
import ServerCommand from '../../../common/server/server-command';
import {GetLaboratoriesResult} from '../types';

const useLaboratoryPage = () => {

    const [loaderPage, setLoaderPage] = useLoader(true);

    const [laboratoriesResponse, setLaboratoriesResponse] = useState<GetLaboratoriesResult>({laboratories: []})

    useEffect(() => {
        ServerCommand
            .get('laboratory/list')
            .then((response: GetLaboratoriesResult) => {
                setLaboratoriesResponse(response)
                setLoaderPage(false)
            })
    }, [])

    const refreshDataFromServer = useCallback(() => {
        ServerCommand
            .get('laboratory/list')
            .then((response: GetLaboratoriesResult) => {
                setLaboratoriesResponse(response)
                setLoaderPage(false)
            })
    }, [])

    return {loaderPage, laboratoriesResponse, refreshDataFromServer}
};

export default useLaboratoryPage;
