import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import {useSelector} from "react-redux";
import useDirectionsColors from "../../../../../common/hooks/useDirectionsColors";
import Helper from "../../../../../common/helpers/main";
import ChartBar from "../../../../../components-ui/Charts/ChartBar";
import { dataBarOne, dataBarTwo } from "./data/data";
import Box from "../../../../../components-ui/Layout/Box";
import Avatar from "../../../../../components-ui/DataDisplay/Avatar";
import useFormatterUser from "../../../../Analytics/containers/VariantsWidget/AverageCheck/hooks/useFormatterUser";

export default function BarPage() {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [activeCell, setActiveCell] = useState<string | number | null>('therapy');
    const [activeIndex2, setActiveIndex2] = useState(-1);
    const [activeCell2, setActiveCell2] = useState<string | number | null>('therapy');
    const directions = useSelector(state => state.common.directions)
    const directionsColors = useDirectionsColors()
    const formatterUser = useFormatterUser()
    return (
        <>
            <h1>Bar</h1>
            <WrapContent>
                <Box position="relative" zIndex={15}>
                    <ChartBar
                        data={dataBarOne}
                        activeCell={activeCell}
                        hoverIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        formatterValue={value => Helper.String.numberFormat(value, 0)}
                        formatterNameTooltip={name => directions[name].name}
                        formatterTitleTooltip={formatterUser}
                        height={400}
                        width="90%"
                    />
                </Box>

                <br/>
                <br/>
                <ChartBar
                    data={dataBarTwo}
                    activeCell={activeCell2}
                    hoverIndex={activeIndex2}
                    setActiveIndex={setActiveIndex2}
                    paddingTooltip="6px 0"
                    formatterValue={value => Helper.String.numberFormat(value, 0)}
                    formatterTitleTooltip={name => directions[name].name}
                    formatterNameTooltip={formatterUser}
                    height={400}
                    width="90%"
                    colors={directionsColors}
                />
            </WrapContent>
        </>
    )
}
