import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const CloseThinIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M.35.35l23.3,23.3m0-23.3L.35,23.65"/>
            <polygon points="23.3 24 12 12.7 0.7 24 0 23.3 11.3 12 0 0.7 0.7 0 12 11.3 23.3 0 24 0.7 12.7 12 24 23.3 23.3 24"/>
        </WrapIcon>
    );
});
