import React, {useRef, useState} from 'react';
import Helper from "../../../common/helpers/general-helper";
import ServerCommand from "../../../common/server/server-command";
import Box from "../../../components-ui/Layout/Box";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import {Label} from "../../../components-ui/DataDisplay/Label";
import Button from "../../../components-ui/Inputs/Button/Button";
import {PropsTransfer} from "./typesTransfer";
import Loader from "../../../components-ui/Feedback/Loader/Loader";
import {useModal} from "../../../containers/DialogsManager/containers/Modal/Modal";
import {formatCardNumber} from "../../../common/helpers/Patient";

export default function Transfer({patient, date, timeStart, chairId, visitId, onTransferDone = () => null}: PropsTransfer) {
    const ref = useRef(null) as any
    const {closeModal} = useModal();
    const [state, setState] = useState({
        reason: '',
        isFocusPrompt: true,
        btnDisabled: false
    })

    const onClickCancellation = (e: any) => {
        e.stopPropagation();

        if (state.reason.length === 0) {
            if (!!ref.current._ref) ref.current._ref.focus()
            return false
        }
        setState(prevState => ({...prevState, btnDisabled: true}))

        const command = new ServerCommand('visit/postpone', {
            visitId, date, reason: state.reason, timeStart, chairId
        });

        command.exec().then(() => {
            closeModal();
            if (!!onTransferDone) onTransferDone()
        });

    }

    if (!!patient) {
        const variantBtn = state.btnDisabled ? 'disabled' : 'danger';
        return (
            <Box width="560px" padding="45px 30px">
                <Typography variant="bigger-title" textAlign="center" margin="0 0 17px" children="Перенос приёма"/>
                <Typography textAlign="center" margin="0 0 34px"
                            children={`${Helper.getFio(patient)} ${formatCardNumber(patient.cardNumber)}`}/>
                <Label labelText="Комментарий" marginLabel="0 15px 0 0"
                       widthLabelWrap="90%" minWidthLabel="110px"
                       children={<TextField onChange={value => setState(prevState => ({...prevState, reason: value}))}
                                            value={state.reason}
                                            autoFocus={state.isFocusPrompt}
                                            isTextArea={true}
                                            widthInput="340px"
                                            ref={ref}
                       />}/>
                <Button variant={variantBtn} children='Перенести приём' margin="23px 0 0 125px"
                        onClick={onClickCancellation}/>
            </Box>
        )
    } else {
        return <Loader visible={true} />
    }
}
