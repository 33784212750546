import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Box from "../../../components-ui/Layout/Box";
import Paper from "../../../containers/UI-Collection/Elements/Surfaces/Paper";
import {StatePatientList} from "../types/common";
import DateTime from "../../../components-ui/Inputs/DateTime/DateTime";
import {allDoctors} from "../data";
import Button from "../../../components-ui/Inputs/Button/Button";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";

interface Props {
    state: StatePatientList
    setState: Dispatch<SetStateAction<StatePatientList>>
    isReset: boolean
    close: Dispatch<any>
}
const Filter = ({state, setState, isReset, close} : Props) => {

    const [tempState, setTempState] = useState<StatePatientList>(state)
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // background-color: rgba(#1b2530, 0.65);
    // z-index: 31;

    useEffect(() => {
        if (tempState.patientDebt && tempState.patientPrepayment) {
            setTempState(prevState => ({...prevState, patientPrepayment: false}))
        }
    }, [tempState.patientDebt]);

    useEffect(() => {
        if (tempState.patientPrepayment && tempState.patientDebt) {
            setTempState(prevState => ({...prevState, patientDebt: false}))
        }
    }, [tempState.patientPrepayment]);

    return (
        <Box position='fixed'
             positionOffsets={{top: '0', left: '0', right: '0', bottom: '0'}}
             zIndex={31}
        >
            <Box positionOffsets={{top: '0', left: '0', right: '0', bottom: '0'}}
                 width='100%'
                 height='100%'
                 backgroundColor='#1b253080'
                 onClick={close}
            />
            <Box position='fixed'
                 height='400px'
                 zIndex={1}
                 positionOffsets={{top: '50%', left: '50%', transformY: '-50%', transformX: '-50%'}}>
                <Paper height='100%'>
                    <Box display='flex' height='100%'>
                        <Box display='flex' flexDirection='column' width='358px' backgroundColor='secondary'
                             height='100%' padding='30px 27px'>
                            <DateTime onChange={birthday => setTempState(prevState => ({...prevState, birthday}))}
                                      selected={tempState.birthday}
                                      placeholderText="День рождения"
                                      dateFormat="d MMMM"
                                      margin="0 0 20px 0"
                            />
                            <Checkbox label="Пациенты с задолжностью"
                                      checked={tempState.patientDebt ?? false}
                                      onChange={(patientDebt) => setTempState(prevState => ({...prevState, patientDebt}))}
                                      margin="0 0 20px 0"
                            />
                            <Checkbox label="Пациенты с предоплатой"
                                      checked={tempState.patientPrepayment ?? false}
                                      onChange={(patientPrepayment) => setTempState(prevState => ({...prevState, patientPrepayment}))}
                                      margin="0 0 20px 0"
                            />
                            <Button variant={'success'}
                                    children='Применить'
                                    size="md"
                                    onClick={() => {
                                        setState(tempState)
                                        close(null)
                                    }}
                                    margin="20px 0 0 0"
                            />
                            <Button variant={isReset ? 'standard' : 'disabled'}
                                    children='Сбросить'
                                    size="md"
                                    margin="10px 0 0 0"
                                    onClick={() => {
                                        setState(prevState => ({...prevState, doctor: allDoctors, birthday: null, searchQuery: '', patientDebt: false, patientPrepayment: false}))
                                        close(null)
                                    }}/>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default Filter
