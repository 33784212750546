import styled from "styled-components";

export const Nav = styled.div.attrs({className: 'nav'})`
    position: fixed;
    z-index: 14;
    display: flex;
    flex-direction: column;
    width: 64px;
    height: 100vh;
    background-color: #4f5965;
    padding-top: 84px;
`;

interface ILinkNav {
    active?: boolean,
    isLink: boolean
}

export const Link = styled.div.attrs({className: 'nav-link'})<ILinkNav>`
    position: relative;
    width: 100%;
    height: 76px;
    cursor: ${props => props.isLink ? 'pointer' : 'default'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.active ? '#313a45' : 'transparent'};
    transition: background-color 0.3s ease-in-out;
    &:hover {
        background-color: #313a45;
        svg {
            fill: ${props => props.theme.colors.primary};
        }
        .wrap-subs-nav {
            display: flex;
        }
    }
`;

export const LinkTitle = styled.span.attrs({className: 'nav-title'})`
    font-size: 0.5rem;
    color: #cacdd0;
`;

export const LinkOption = styled(Link)`
    margin-top: auto;
    border-top: 1px solid #616a75;
`;

export const WrapSubsNav = styled.div.attrs({className: 'wrap-subs-nav'})`
    display: none;
    background-color: #4f5965;
    flex-direction: column;
    padding: 12px 8px 8px;
    position: absolute;
    top: 0;
    left: 100%;
`

interface PropsSubNav {
    active: boolean
}

export const SubNav = styled.div<PropsSubNav>`
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 7px 16px 9px 8px;
    color: ${({theme}) => theme.colors.white};
    font-size: 14px;
    line-height: 1.14;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
    cursor: ${({active}) => !active ? 'pointer' : 'default'};
    margin-bottom: 4px;
    width: max-content;
    &:hover {
        ${props => !props.active && `background-color: ${props.theme.colors.primary};`}
    }
    ${({active}) => active && `background-color: #313a45;`};
`
