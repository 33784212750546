import styled from "styled-components";
import Helper from "../../../common/helpers/main";

export const ErrorModalWrap = styled.div.attrs({className: 'ErrorModalWrap'})`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 45px 32px 68px;

    & > button {
        width: 152px;
    }

    & .link {
        color: ${props => props.theme.colors.info};
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        display: block;
        text-align: center;
        margin: 10px auto 0;
        border-bottom-color: ${props => `rgba(${Helper.Color.hexToRgb(props.theme.colors.info)}, 0.3)`};
        &:hover {
            border-bottom-color: ${props => `rgba(${Helper.Color.hexToRgb(props.theme.colors.info)}, 0.6)`};
        }
    }
`

export const ErrorModalTitle = styled.div.attrs({className: 'ErrorModalTitle'})`
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.colors.text};
    margin-bottom: 17px;
`

export const ErrorModalDesc = styled.p.attrs({className: 'ErrorModalDesc'})`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.colors.text};
    margin: 0 0 30px;
    max-width: 478px;
`
