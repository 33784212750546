import React, {ReactChild, ReactNode, useEffect, useState} from 'react';
import {GroupsButtonName, NoticesQuantity} from "./types";
import {TasksInfo} from "../../types";
import useHeightsComponents from "./hooks/useHeightsComponents";
import _ from "lodash";

interface Props {
    groupsButtonName: GroupsButtonName
    noticesQuantity: NoticesQuantity
    isMarketing?: boolean
    noticeQuantityTotal: number
    tasksInfo: TasksInfo
    noticeName?: string
    headerRightComponent?: ReactChild | ReactNode
    footerComponent?: ReactChild | ReactNode
    handleHeightFilter: (heightFilter: number) => void
    selectedFilter: string
    handleSelectedFilter: (selectedFilter: string) => void
}

export const useFilter = ({groupsButtonName, noticesQuantity, isMarketing, handleHeightFilter, selectedFilter, handleSelectedFilter}: Props) => {
    const [showHiddenButtons, setShowHiddenButtons] = useState(false);

    const {heightContainerButtons, heightFilter, refFooter, refHeader, refTop} = useHeightsComponents({
        groupsButtonName,
        showHiddenButtons
    })

    useEffect(() => handleHeightFilter(heightFilter), [heightFilter])

    useEffect(() => {
        if (selectedFilter !== 'all') {
            if (!Object.keys(noticesQuantity).some(keyType => selectedFilter === keyType)) {
                handleSelectedFilter('all')
            } else if (isMarketing !== undefined && !isMarketing) {
                const flattenSortTypeTasks = _.flatten(groupsButtonName)
                if (!flattenSortTypeTasks.some(keyType => selectedFilter === keyType)) {
                    handleSelectedFilter('all')
                }
            }
        }
    }, [noticesQuantity, isMarketing])



    return {
        heightFilter,
        showHiddenButtons,
        setShowHiddenButtons,
        heightContainerButtons,
        refFooter,
        refHeader,
        refTop
    }
};
