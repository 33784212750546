import React from 'react'
import {Background, InputGroup, LoginForm} from './style';
import {BoxTitle} from "../../components-ui/styledComponent/common/typography";
import TextField from "../../components-ui/Inputs/TextField/TextField";
import Paper from "../../containers/UI-Collection/Elements/Surfaces/Paper";
import Button from "../../components-ui/Inputs/Button/Button";
import {Label} from "../../components-ui/DataDisplay/Label";
import ErrorForm from "./components/ErrorForm";
import useStateLogin from "./hooks/useStateLogin";
import useSubmit from "./hooks/useSubmit";
import useValidate from "./hooks/useValidate";

const Login = () => {
    const {state, handlers} = useStateLogin()
    const handleSubmit = useSubmit({state, handlers})
    const validate = useValidate({state});

    return (
        <Background>
            <Paper margin="88px auto 0" maxWidth="420px" minHeight={441} padding="45px 30px 42px">
                <LoginForm onSubmit={handleSubmit}>
                    <BoxTitle center children="Вход в ERP"/>
                    <InputGroup>
                        <Label align="top" labelText="Логин" >
                            <TextField nameInput="login" widthInput={180} value={state.login}
                                       onChange={handlers.setLogin} textAlignInput="center"/>
                        </Label>

                        <Label align="top" labelText="Пароль" >
                            <TextField nameInput="password" isPassword={true} textAlignInput="center" value={state.password}
                                       onChange={handlers.setPassword} widthInput={180} />
                        </Label>
                    </InputGroup>
                    <Button type="submit" children="Войти" variant={validate() ? "success" : "disabled"}/>
                </LoginForm>
                {!!state.errorCode && <ErrorForm errorCode={state.errorCode}/>}
            </Paper>
        </Background>
    )
}

export default Login