import {useMemo, useRef, useState} from "react";
import {StateManagerTasks} from "../types/components";
import {allUser, managerTasksInfo} from "../helper/getDataManagerTasks";
import _ from "lodash";
import {allTypesOfTasks} from "../../../containers/Tasks/data/allTypesOfTasks";
import useInitialManagerTasks from "./useInitialManagerTasks";
import useNoticeQuantity from "./useNoticeQuantity";
import useMinimizeParams from "./useMinimizeParams";
import useMethodsManagerTasks from "./useMethodsManagerTasks";
import useHandlersState from "./useHandlersState";
import useEchoManagerTasks from "./useEchoManagerTasks";
import useGroupsButtonName from "./useGroupsButtonName";
import {useSelector} from "react-redux";

const useManagerTasks = () => {
    const user = useSelector(state => state.user);

    const refScrollList = useRef(null) as any;

    const [state, setState] = useState<StateManagerTasks>({
        heightFilter: 0,
        selectedFilter: 'all',
        selectedUser: allUser,
        tasks: [],
        branches: user.branches
    });

    const tasksInfo = useMemo(() => _.assign(_.cloneDeep(managerTasksInfo), {all: allTypesOfTasks}), [])

    const infoManagerTasks = useInitialManagerTasks({setState, state})

    const noticesQuantity = useNoticeQuantity({state, infoManagerTasks})

    const groupsButtonName = useGroupsButtonName({infoManagerTasks, tasksInfo, noticesQuantity})

    const methodsManagerTasks = useMethodsManagerTasks({setState})

    const handlersState = useHandlersState({setState})

    useEchoManagerTasks({setState, state})

    useMinimizeParams(noticesQuantity.all)

    return {
        state,
        setState,
        refScrollList,
        tasksInfo,
        infoManagerTasks,
        noticesQuantity,
        groupsButtonName,
        methodsManagerTasks,
        handlersState
    }
}

export default useManagerTasks
