import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth14Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 7.64 24"} width={width || "14"} height={height || "44"} ref={ref}>
            <path d="M2.15.13a.77.77,0,0,1,.79,0c1.53.7,3.18,5.07,3.38,15.13A4.7,4.7,0,0,0,3.79,14a3.27,3.27,0,0,0-2.52.58l-.14.11c.05-.89.09-1.69.13-2.42a46.43,46.43,0,0,0-.15-9.28,3,3,0,0,1,1-2.81ZM7.5,21.65c-.81,1.13-2.83,2.5-3.44,2.34l-.43-.1C2.48,23.63.33,23.15,0,21.28c0-1.17.35-4.36,1.88-5.5a2.22,2.22,0,0,1,1.74-.39,4.64,4.64,0,0,1,3.23,2.68c.81,1.57.93,3.19.65,3.57Z"/>
        </WrapIcon>
    );
});
