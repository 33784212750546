import Measure from "common/Service/Measure";

export default class Target {

    targetUnits = null;
    /**
     * @type {Measure}
     */
    measure = null;

    constructor(targetData, measureType) {
        this.measure = new Measure(measureType);

        if(targetData === null) {
            targetData = this.measure.getTargetUnitByTooth(null);
        } else if ((typeof targetData === 'string') && (measureType === 'SEGMENT')) {
            targetData = targetData.split(', ');
        }

        this.targetUnits = Array.isArray(targetData) ? targetData : [targetData];
    }

    /**
     * Из указанного списка зубов возвращает те зубы, которые попадают под данную цель
     * @param teethNumbers
     * @return {*}
     */
    matchTeeth(teethNumbers) {
        return teethNumbers;
    }

    usesFullServiceApplyMode() {
        return this.measure.usesFullServiceApplyMode();
    }

    getData(bArray = false) {
        return (bArray || this.measure.isComposite()) ? this.targetUnits : this.targetUnits[0];
    }

    /**
     *
     * @param procedureMeasureType
     * @param teethMap
     * @return {Target[]}
     */
    getProcedureTargets(procedureMeasureType, teethMap) {
        return [];
    }

    getTeeth() {
        return this.targetUnits;
    }
}
