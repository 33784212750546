import {useMemo} from "react";
import {dateStringFilled, maskFilled, stringFilled} from "../../../common/helpers/validation";
import {PropsUseIsViewMode} from "../types/hooks";
import _ from 'lodash'
import Helper from "../../../common/helpers/main";
import {PatientValues} from "../types/common";
import {IPatientField} from "../types/fields";

export default function useIsViewMode(
    {
        currentPatient,
        field,
        personalPhone,
        composite,
        parentName
    }: PropsUseIsViewMode) {
    const deps = parentName === "PreparationPatient" ? [] : [currentPatient, currentPatient?.personalPhone !== personalPhone]

    return useMemo(() => {
        let res = true;
        const prepareRes = (currentField: IPatientField) => {
            if (!!currentPatient) {
                const currentValue = currentPatient[currentField.name as keyof PatientValues] as any
                if (!!currentValue) {
                    switch (currentField.type) {
                        case "phone":
                            res = stringFilled(Helper.Phone.formatServer(currentValue))
                            break;
                        case "string":
                            if (!!currentField.mask) {
                                res = stringFilled(currentValue) && maskFilled(currentValue)
                            } else {
                                res = stringFilled(currentValue)
                            }
                            break;
                        case "date":
                            res = dateStringFilled(currentValue)
                            break;
                        case "enum":
                            res = !!currentValue
                            if (currentField.name === 'phoneOwner') {
                                if (currentPatient?.personalPhone !== personalPhone) {
                                    res = false;
                                }
                            }
                            break;
                        case "boolean":
                            res = currentValue !== null
                            break;
                        default:
                            break;
                    }
                } else {
                    res = false;
                }

            }

        }
        if (!!currentPatient && field.required && !composite) {
            prepareRes(field)
        }
        if (!!currentPatient && !!composite) {
            _.forEach(composite, (item, fieldName) => {
                if (item.field.required && res) {
                    prepareRes(item.field)
                }
            })
        }
        return res
    }, deps)
}
