import useInitialStateForm from "./useInitialStateForm";
import {useState} from "react";
import {StateForm} from "../types/form";
import _ from "lodash";
import {PropsUseForm} from "../types/hooks";
import {StateBasicForm} from "../../../../../containers/PatientField";

export default function useForm({fields}: PropsUseForm) {
    const initialForm = useInitialStateForm({fields})
    const [form, setForm] = useState<StateForm>(_.cloneDeep(initialForm))

    const resetForm = () => setForm(prevState => ({
        ..._.cloneDeep(initialForm),
        directionsNames: prevState.directionsNames
    }));

    const onChangeFieldBasic = (value: any, fieldName: keyof StateBasicForm) => {
        setForm(prevState => ({
            ...prevState,
            basic: {
                ...prevState.basic,
                [fieldName]: value
            }
        }))
    }

    const onChangeField = (value: any, fieldName: keyof StateForm) => {
        setForm(prevState => ({...prevState, [fieldName]: value}))
    }

    const onChangeValidationBasic = (fieldName: keyof StateBasicForm) => {
        setForm(prevState => ({
            ...prevState,
            basic: {
                ...prevState.basic,
                validationFields: {
                    ...prevState.basic.validationFields,
                    [fieldName]: false
                }
            }
        }))
    }

    const onChangeValidationField = (fieldName: keyof StateForm) => {
        setForm(prevState => ({
            ...prevState,
            validationFields: {
                ...prevState.validationFields,
                [fieldName]: false
            }
        }))
    }

    return {
        form,
        setForm,
        resetForm,
        onChangeFieldBasic,
        onChangeField,
        onChangeValidationBasic,
        onChangeValidationField
    }

}
