import GeneralHelper from './general-helper.js';
import moment from 'moment'
import 'moment/min/locales.min';

let ruMonthsGen = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
let ruWeekdays = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

let DateHelper = {

    /**
     * Формат времени с точностью до секунды
     */
    FORMAT_TIME: 'HH:mm:ss',

    /**
     * Формат времени с точностью до минуты
     */
    FORMAT_TIME_MINUTES: 'HH:mm',

    /**
     * Формат даты, который используется при запросах на сервер
     */
    FORMAT_SERVER_DATE: 'YYYY-MM-DD',

    /**
     * Формат даты, который используется при запросах на сервер
     */
    FORMAT_SERVER_MONTH: 'YYYY-MM',

    /**
     * Формат даты со временем, который используется при запросах на сервер
     */
    FORMAT_SERVER_DATETIME: 'YYYY-MM-DD HH:mm:ss',

    /**
     * Возвращает время в формате HH:mm
     * @param time
     * @return {string}
     */
    formatTime: function(time)
    {
        return moment(time).format(this.FORMAT_TIME_MINUTES);
    },

    /**
     * Форматирует время, заданное через количество минут в формат HH:mm
     * @param minutes
     * @return {*}
     */
    formatMinutes: function(minutes)
    {
        var hours = Math.floor(minutes / 60);
        var time = ((hours < 10) ? '0' + hours : hours);
        minutes -= hours * 60;
        time += ':' + ((minutes < 10) ? '0' + minutes : minutes);
        return time;
    },

    /**
     * Форматирует время
     * @param {undefined | string} date - при undefined возвращает текущую дату
     * @param {string} formatTo - формат, к которому нужно преобразовать время. Доступны дополнительные макросы - ru_month_gen
     * (название месяца на русском в родительном падеже) и ru_weekday (название дня недели на русском в родительном падеже)
     * @param {?string} formatFrom - формат, в котором представлена дана
     * @return {string}
     */
    formatDate: function(date, formatTo = this.FORMAT_SERVER_DATETIME, formatFrom = null)
    {
        var dateMoment = moment(date, formatFrom).locale("ru");
        formatTo = formatTo.replace('ru_month_gen', ruMonthsGen[dateMoment.get('month')]).replace('ru_weekday', ruWeekdays[dateMoment.get('weekday')]);
        return dateMoment.format(formatTo);
    },

    /**
     * Возвращает дату в формате сервера (YYYY-MM-DD)
     * @param date
     * @return {string}
     */
    formatDateForServer: function (date) {
        return moment(date).format(this.FORMAT_SERVER_DATE)
    },

    formatDateTimeForServer: date => moment(date).format(),

    /**
     * Определяет, являются ли даты одинаковыми (с точностью до дня)
     * @param date1
     * @param date2
     * @return {boolean}
     */
    areEqualDates: (date1, date2) => moment(date1).isSame(date2, 'day'),

    /**
     * Определяет, является ли дата сегодняшней
     * @param date
     * @return {boolean}
     */
    isToday: date => DateHelper.areEqualDates(date, new Date()),

    /**
     * Получает минуты из времени
     * @param {string} time
     * @return {number}
     */
    getMinutesByTime: function(time)
    {
        var timeParts = time.split(':');
        var hours = Number(timeParts[0]);
        var minutes = Number(timeParts[1]);
        return hours * 60 + minutes;
    },

    /**
     * Плучает длительность интервала времени (в формате 1 час 12 минут)
     * @param intervalStart
     * @param intervalEnd
     * @return {string}
     */
    getDurationString: function(intervalStart, intervalEnd)
    {
        if((typeof intervalStart === 'string') || (typeof intervalEnd === 'string'))
        {
            let curMoment = moment();
            let strDate = curMoment.format('YYYY-MM-DD');

            if(typeof intervalStart === 'string')
            {
                intervalStart = moment(strDate + ' ' + intervalStart);
            }

            if(typeof intervalEnd === 'string')
            {
                intervalEnd = moment(strDate + ' ' + intervalEnd);
            }
        }

        let diff = intervalEnd.diff(intervalStart);

        let minutes = Math.floor(diff / 60000);
        let hours = Math.floor(minutes / 60);
        minutes -= hours * 60;

        let result = '';

        if(hours)
        {
            result += GeneralHelper.getCountString(hours, ['час', 'часа', 'часов']);
        }

        if(minutes)
        {
            result += ' ' + GeneralHelper.getCountString(minutes, ['минута', 'минуты', 'минут']);
        }

        return result;
    },

    /**
     * Получает возвраст по дате рождения (в числовом формате)
     * @param birthday
     * @return {*|number}
     */
    getAgeNumber: function (birthday) {
        return moment().diff(moment(birthday), 'y')
    },

    /**
     * Получает возвраст по дате рождения (в формате 25 лет)
     * @param birthday
     * @return {*|string}
     */
    getAge: function(birthday)
    {
        return GeneralHelper.getCountString(this.getAgeNumber(birthday), ['год', 'года', 'лет']);
    },

    getStandardIntervalTime: function(intervalTime)
    {
        let intervalMoment = moment(intervalTime, this.FORMAT_TIME_MINUTES);
        if(intervalMoment.get('m') % 30 === 15)
        {
            intervalMoment.add(-15, 'm');
        }

        return intervalMoment.format(this.FORMAT_TIME_MINUTES);
    },
    prepareUnambiguousDays: function(value)
    {
        value = value.split('')[0];
        if (+value === 0) value = '1';
        return '0' + value;
    },

    midnight: function (date) {
        return moment(date).startOf('day');
    },

    /**
     * Получаем ближайшие дни в тексте (вчера, сегодня, завтра) или в формате "DD MMM YYYY"
     * @param date
     * @return {string}
     */
    formatComingDays: function (date) {
        const dateMidnight = DateHelper.midnight(date);
        const diff = dateMidnight.diff(DateHelper.midnight(), 'day');

        if (diff === 0) {
            return 'сегодня'
        } else if (diff === 1) {
            return 'завтра'
        } else if (diff === -1) {
            return 'вчера'
        } else {
            return dateMidnight.locale('ru').format("DD MMM YYYY")
        }
    },
    /**
     * Получаем ближайшее время в тексте (вчера, HH:mm, сегодня, HH:mm, завтра, HH:mm) или в формате "DD MMM YYYY, HH:mm
     * @param date
     * @return {string}"
     */
    formatComingTime: function (date) {
        return this.formatComingDays(date) + ', ' + moment(date).locale('ru').format("HH:mm")
    },

    /**
     * Получаем массив дат между двумя датами
     * @param startDate {string}
     * @param endDate {string}
     * @param dateFormat {('days' | 'month')}
     * @return {string[]}
     */
    getDaysArray: function (startDate, endDate, dateFormat = 'days') {
        const dateArray = [];
        let formatTo = 'YYYY-MM-DD';
        switch (dateFormat) {
            case "month":
                formatTo = 'YYYY-MM'
                break;
            case "days":
            default:
                break;
        }
        let currentDate = moment(startDate);
        const stopDate = moment(endDate);
        while (currentDate <= stopDate) {
            dateArray.push(this.formatDate(currentDate, formatTo))
            currentDate = moment(currentDate).add(1, dateFormat);
        }
        return dateArray;
    },

    /**
     * Возвращает текущее время на сервере
     * @param serverTimeDiff
     */
    currentTime: function (serverTimeDiff) {
        return Date.now() - serverTimeDiff;
    }
};

export default DateHelper
