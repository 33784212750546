import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const CallEfficiency = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 24"} ref={ref}>
            <path d="M22.8,0H4.8A2.41,2.41,0,0,0,2.4,2.4V6H0V8.4H2.4v2.4H0v2.4H2.4v2.4H0V18H2.4v3.6A2.41,2.41,0,0,0,4.8,24h18A1.2,1.2,0,0,0,24,22.8V1.2A1.2,1.2,0,0,0,22.8,0ZM13.2,3.6A3.6,3.6,0,1,1,9.6,7.2,3.61,3.61,0,0,1,13.2,3.6Zm7.2,15.6H6v-.9c0-2.66,3.25-5.4,7.2-5.4s7.2,2.74,7.2,5.4Z"/>
        </WrapIcon>
    );
});
