
export const calculateTimeEnd = (timeStart: string, valueReception: string) => {
    let splitTimeStart = timeStart.split(':');

    const newTimeStart = +splitTimeStart[0] * 60 + +splitTimeStart[1] + +valueReception;

    let timeEndHours = Math.floor(newTimeStart / 60) as string | number;
    let timeEndMinutes = newTimeStart % 60 as string | number;

    if (String(timeEndHours).length === 1) timeEndHours = '0' + String(timeEndHours);

    if (timeEndMinutes === 0) timeEndMinutes = '00';

    return String(timeEndHours) + ':' + String(timeEndMinutes);
};
