import {useEffect} from "react";
import {PropsUseChangeActiveTab} from "../types/hooks";

export default function useChangeActiveTab({activeTab, setForm}: PropsUseChangeActiveTab) {
    useEffect(() => {
        if (activeTab === 'scheduled-appointments') {
            setForm(prevState => ({
                ...prevState,
                validationFields: {
                    ...prevState.validationFields,
                    directions: false
                }
            }))
        }
    }, [activeTab])
}
