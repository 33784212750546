import {Legend} from "../../../../types/common";

export const namesCalls = {
    incoming: 'Входящие',
    outgoing: 'Исходящие',
} as {
    [key: string]: string
}

export const legendSwirl = [
    {
        code: 'total-calls',
        name: 'Всего звонков'
    },
    {
        code: 'sign-up',
        name: 'Записались'
    }
] as Legend[]
