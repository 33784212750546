import React, {useEffect, useRef} from 'react';
import Box from "../../../components-ui/Layout/Box";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import {Divider} from "../../../components-ui/DataDisplay/Divider";
import Button from "../../../components-ui/Inputs/Button/Button";
import {useSelector} from "react-redux";
import {IBranch} from "../../../types";
import {useModal} from "../../../containers/DialogsManager/containers/Modal/Modal";
import useOutsideClick from "../../../common/hooks/useOutsideClick";

interface Props {
    selectedBranch: number
    newSelectedBranch?: number
    onClickBtnSave: (branchId: number, onCloseBtnSave?: () => void, onCloseBtnCancel?: () => void) => void
    loadForm?: (branchId: number) => void
    onChangeLocation?: () => void
    didMount?: () => void
    onCloseCancelSaveModal?: (selectedBranch: number) => void
}

const OfferToSave = ({selectedBranch, onClickBtnSave, newSelectedBranch, loadForm, onChangeLocation, didMount, onCloseCancelSaveModal}: Props) => {
    const branches = useSelector(state => state.common.branches);
    const findBranch = branches.find(branch => branch.id === selectedBranch) as IBranch;
    const {closeModal} = useModal()
    const ref = useRef(null) as any

    const onClose = () => {
        if (newSelectedBranch !== undefined && !!loadForm) {
            loadForm(newSelectedBranch)
        }

        if (!!onChangeLocation) {
            onChangeLocation()
        }
    }

    useEffect(() => {
        if (didMount) didMount()
    }, [])

    useOutsideClick(ref, onClose)

    const onCloseCancel = () => {
        if (!!onCloseCancelSaveModal) {
            onCloseCancelSaveModal(selectedBranch)
        }
    }

    return (
        <Box padding="34px 16px 51px" display="flex" flexDirection="column" alignItems="center" ref={ref}>
            <Typography children={`График работы врачей в филиале “${findBranch.name}” был изменён.`} lineHeight="1.5" />
            <Typography children="Сохранить изменения?" lineHeight="1.5"/>
            <Divider margin="26px 0"/>
            <Box display="flex">
                <Button children="Не сохранять" variant="danger" margin="0 23px 0 0" width="160px" onClick={() => {
                    onClose()
                    closeModal()
                }}/>
                <Button children="Сохранить..." variant="success" width="152px" onClick={() => {
                    closeModal();
                    onClickBtnSave(selectedBranch, onClose, onCloseCancel)
                }}/>
            </Box>
        </Box>
    );
};

export default OfferToSave;
