import React from 'react';
import {GetXrayPicturesListResult} from '../../types';
import Paper from '../../../../containers/UI-Collection/Elements/Surfaces/Paper';
import Loader from '../../../../components-ui/Feedback/Loader/Loader';
import useTableFormatter from './hooks/useTableFormatter';
import Table from '../../../../components-ui/DataDisplay/Table';
import Button from '../../../../components-ui/Inputs/Button/Button';
import {useDialogsManager} from '../../../../containers/DialogsManager/DialogsManager';
import XrayPicturesModalMark from '../modals/XrayPicturesModalMark';

interface Props {
    loader: boolean
    xRayPicturesResponse:  GetXrayPicturesListResult
    selectedBranch: number
}

const XrayPicturesTable = ({xRayPicturesResponse, loader, selectedBranch}: Props) => {

    const {showModal} = useDialogsManager();

    const {xrayPicuresRows, xrayPicturesColumns} = useTableFormatter({xRayPicturesResponse, selectedBranch})

    return (
        <Paper padding="24px" minHeight={688}>
            <Loader visible={loader}/>
            {!!xrayPicuresRows &&
                <Table columns={xrayPicturesColumns}
                       rows={xrayPicuresRows}
                       variantTable="outline"
                       isHovering={false}
                       fontSizeRow="14px"
                       maxHeight="500px"
                />}
            <Button margin="24px 0 0 0"
                    variant="default"
                    size="md"
                    children="Добавить"
                    onClick={() => showModal(<XrayPicturesModalMark selectedBranch={selectedBranch}/>)}/>
        </Paper>
    );
};

export default XrayPicturesTable;
