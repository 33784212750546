import ServerCommand from "../../../../common/server/server-command";
import {Dispatch, SetStateAction, useCallback} from "react";
import {CreateCashParams, GetDirectionsBalanceResult, IStateCashCreate} from "../types";
import usePaymentSum from "./usePaymentSum";
import {useModal} from "../../../../containers/DialogsManager/containers/Modal/Modal";
import Kkm from "../../../../common/server/kkm";
import {useSelector} from "react-redux";
import {KkmResponse} from "../../../../common/types/server";
import {useErrorModal} from "../../../../components/Errors";
import {KkmSetting} from "../../../../types";

interface Props {
    patientId: number | undefined
    userId: number | undefined
    loadingFromServer?: () => void
    setLoader: Dispatch<SetStateAction<boolean>>
    setKkmError: Dispatch<SetStateAction<string>>
    state: IStateCashCreate
    data: GetDirectionsBalanceResult | null
    kkmSetting: KkmSetting
}

const useSubmit = ({
                       patientId,
                       userId,
                       loadingFromServer,
                       setLoader,
                       setKkmError,
                       state,
                       data,
                       kkmSetting
                   }: Props) => {

    const {closeModal} = useModal()

    const paymentSum = usePaymentSum({values: state.values})

    const paymentDistribution = Object.keys(state.values).map((key) => {
        return [key, +state.values[key]]
    })

    return useCallback(() => {
        let params = null
        switch (state.type) {
            case 'P':
                if (patientId == undefined) {
                    return;
                }
                params = {
                    type: state.type,
                    patientId,
                    paymentSum: paymentSum - +state.discount - +state.insuranceSum,
                    method: state.selectedPaymentMethod.value,
                    cashbox: state.selectedCashbox.value,
                    discount: {
                        sum: +state.discount,
                        type: state.selectedDiscountType.value
                    },
                    insuranceSum: +state.insuranceSum,
                    branchId: state.branch?.value
                } as CreateCashParams;

                if (!!data && !!data.directionsBalance && Object.keys(data.directionsBalance).length > 1) {
                    params.paymentDistribution = Object.fromEntries(paymentDistribution)
                }
                break;
            case 'U':
                if (userId == undefined) {
                    return;
                }
                params = {
                    type: state.type,
                    userId,
                    expenditureId: state.selectedExpenditure?.value,
                    comment: state.comment,
                    paymentSum: paymentSum,
                    cashbox: state.selectedCashbox.value,
                    branchId: state.branch?.value
                } as CreateCashParams;
                break;
            case 'C':
                params = {
                    type: state.type,
                    expenditureId: state.selectedExpenditure?.value,
                    comment: state.comment,
                    paymentSum: paymentSum,
                    cashbox: state.selectedCashbox.value,
                    branchId: state.branch?.value
                } as CreateCashParams;
                break;
        }

        if (params) {
            setLoader(true);

            const command = new ServerCommand('payment/create', params);
            const kkm = new Kkm(kkmSetting)

            if (state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' && state.selectedKkm) {

                switch (state.type) {
                    case 'P':
                        // @ts-ignore
                        kkm.registerCheck(
                            0,
                            true,
                            paymentSum,
                            +state.discount,
                            state.selectedCashbox.value === 'MAIN_BOX_OFFICE').then((response: KkmResponse) => {
                            console.log(response)

                            if (response.Status == 0) {
                                command.exec().then((paymentId) => {
                                    closeModal()
                                    if (loadingFromServer) loadingFromServer()
                                });

                                // const command = new ServerCommand('payment/save-print-check', {
                                //     checkNumber: response.CheckNumber,
                                //     sessionNumber: response.SessionNumber,
                                //     sessionCheckNumber: response.SessionCheckNumber,
                                //     url: response.URL,
                                //     idCommand: response.IdCommand,
                                //     paymentId: paymentId
                                // });
                                // command.exec().then(() => {
                                //
                                // })
                            } else {
                                setLoader(false);
                                setKkmError(response.Error)
                                console.log(response.Error)
                            }
                        }).catch((reason : string) => setKkmError(reason))
                        break
                    case 'U':
                    case 'C':
                        // @ts-ignore
                        kkm.depositingCash(paymentSum).then((response: KkmResponse) => {
                            if (response.Status == 0) {
                                command.exec().then((paymentId) => {
                                    closeModal()
                                    if (loadingFromServer) loadingFromServer()
                                });
                            } else {
                                setLoader(false);
                                setKkmError(response.Error)
                                console.log(response.Error)
                            }
                        }).catch(reason => setKkmError(reason))
                        break
                }
            } else {

                command.exec().then((paymentId) => {
                    closeModal()
                    if (loadingFromServer) loadingFromServer()
                });
            }

        }

    }, [
        userId,
        patientId,
        loadingFromServer,
        setLoader,
        state,
        data,
        kkmSetting
    ])

};

export default useSubmit;
