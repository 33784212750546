import React, {useState} from 'react';
import Typography from '../../../../../../../components-ui/DataDisplay/Typography/Typography';
import {Label} from '../../../../../../../components-ui/DataDisplay/Label';
import {
    commonLabelOption, commonSelectOption,
    commonTextFieldOption,
} from '../../../../../../TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import TextField from '../../../../../../../components-ui/Inputs/TextField/TextField';
import Box from '../../../../../../../components-ui/Layout/Box';
import Button from '../../../../../../../components-ui/Inputs/Button/Button';
import ServerCommand from '../../../../../../../common/server/server-command';
import useLoader from '../../../../../../../components/Errors';
import {useModal} from '../../../../../../../containers/DialogsManager/containers/Modal/Modal';

interface Props {
    refreshData: () => void
}

const AddGroupScript = ({refreshData}: Props) => {

    const [name , setName] = useState("")
    const [loader, setLoader] = useLoader();
    const {closeModal} = useModal();
    const onClickSave = () => {
        setLoader(true);
        ServerCommand.post(`call-center/script-group/add`, {name}, () => {
            closeModal();
            refreshData();
        }).then();
    };

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        children="Добавление группы скриптов" textAlign='center' />
            <Box margin='39px 0 0 0'>
                <Label {...commonLabelOption}
                       isRequired={true}
                       labelText='Название'
                       align='leftTop'
                       marginLabel='8px 10px 0 0'>
                    <TextField
                        {...commonTextFieldOption}
                        value={name}
                        onChange={setName}
                    />
                </Label>
                <Label labelText='' {...commonLabelOption}>
                    <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                        <Button children='Сохранить'
                                size='md'
                                loader={loader}
                                variant={!!name ? 'success' : 'disabled'}
                                onClick={onClickSave}
                        />
                    </Box>
                </Label>
            </Box>
        </Box>
    );
};

export default AddGroupScript;
