import {useMemo} from "react";
import _ from "lodash";
import {PlannedCall} from "../../../types/common";
import Helper from "../../../../../common/helpers/main";
import {useDateUpdate} from "../../../../../common/hooks";
import {TasksInfo} from "../../../../../containers/Tasks";

interface Props {
    plannedCall: PlannedCall
    tasksInfo: TasksInfo
}

const useInitialDate = ({plannedCall, tasksInfo}: Props) => {
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);
    return useMemo(() => {
        const res = new Date(currentDate);
        res.setSeconds(0)
        const uniqType = _.uniqBy(plannedCall.tasks, task => task.type).map(task => task.type) as string[];
        const minPostponeFor = _.minBy(uniqType, type => tasksInfo[type].postponeFor) as string
        switch (tasksInfo[minPostponeFor].postponeFor) {
            case 24:
                res.setDate(res.getDate() + 1)
                res.setHours(8)
                res.setMinutes(0)

                const tasksFilterMissedCall = plannedCall.tasks.filter(task => task.type !== 'missed_call');
                const notNullDeadline = tasksFilterMissedCall.some(task => task.deadline !== null);
                if (notNullDeadline) {
                    const filterTask = tasksFilterMissedCall.filter(task => task.deadline !== null);
                    let minDeadline = new Date(filterTask[0].deadline as any)
                    filterTask.forEach(task => {
                        minDeadline = new Date(task.deadline as any) < minDeadline ? new Date(task.deadline as any) : minDeadline
                    })
                    if (minDeadline.getTime() <= new Date(res).getTime()) {
                        res.setDate(res.getDate() - 1);
                        res.setHours(21);
                        res.setMinutes(30);
                    }
                }
                break;
            case 1:
                if (res.getMinutes() > 30) {
                    res.setHours(res.getHours() + 2)
                    res.setMinutes(0)
                } else {
                    res.setHours(res.getHours() + 1)
                    res.setMinutes(30)
                }
                break;
            default:
                break;
        }
        return res;
    }, [])
}

export default useInitialDate
