import {useEffect, useState} from "react";
import {Handset} from "../types/common";
import ServerCommand from "../../../common/server/server-command";

export default function useHandsets() {
    const [handsets, setHandsets] = useState<Handset[]|null>(null);

    useEffect(() => {
        ServerCommand.get('call-center/handset/list').then(({handsets}) => setHandsets(handsets));
    }, [])

    return handsets;
}
