import React from "react";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Table from "../../../../components/Table/Table";
import ServerCommand from "../../../../common/server/server-command";
import Helper from "../../../../common/helpers/main";
import moment from "moment/moment"
import "moment/min/locales.min"
import PopupInvoices from "./PopupInvoices";
import ViewInvoice from "./ViewInvoice";
import TextField from "../../../../components-ui/Inputs/TextField/TextField";
import {DialogsManagerContext} from "../../../../containers/DialogsManager/DialogsManager";

const patientsColumns = [
    {
        title: '№',
        code: 'number',
        searchable: true
    },
    {
        title: 'Дата',
        code: 'date'
    },
    {
        title: 'Поставщик',
        code: 'providers',
        searchable: true
    },
    {
        title: 'Итого',
        code: 'sum',
    }
];

export default class Invoices extends React.Component {

    static contextType = DialogsManagerContext;

    state = {
        list: [],
        providers: null,
        materials: null,
        materialsGroups: null,
        searchQuery: ''
    };

    componentDidMount() {

        const command = new ServerCommand('material/get-waybill-list', {
            getSum: true,
            getProviders: true,
            getMaterials: true,
            getMaterialsGroups: true,
            getMeasureShortName: true
        });

        command.exec().then(response => {
            const list = (response.list.length > 0) ? response.list : null;
            this.setState({
                list,
                providers: response.providers,
                materials: response.materials,
                materialsGroups: Helper.Array.toObject(response.materialsGroups, 'id')
            });
        });
        moment.locale('ru');

    }


    render() {

        let data = [];

        /* Модифицируем state.list для таблицы, также в случае поиска по фильтру фильтруем */

        if (this.state.list !== null && this.state.list.length > 0) {
            const providers = Helper.Array.toObject(this.state.providers, 'id');

            data = this.state.list.map(item => {
                return {
                    id: item.id,
                    number: item.id,
                    date: moment(item.date).format('DD MMMM YYYY'),
                    providers: providers[item.providerId].name,
                    sum: String(item.sum).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
                }
            });

            if (this.state.searchQuery.length > 0) {
                data = data.filter((item) =>
                    this.state.searchQuery === String(item.id) ||
                    item.providers.toLowerCase().indexOf(this.state.searchQuery.toLowerCase()) !== -1
                )
            }

        }

        return (
            <React.Fragment>
                <div className="box">
                    <div className="box__header-search">
                        <TextField onChange={(value) => this.setState({searchQuery: value})}
                                   isSearch={true}
                                   widthInput="100%"
                                   placeholder="Поиск накладной по номеру или поставщику"
                                   value={this.state.searchQuery}
                        />
                        <Button variant="standard" children="Сбросить" size="md" margin="0 0 0 20px"/>
                    </div>
                    <Table
                        columns={patientsColumns}
                        data={data}
                        selectMode="single"
                        getRowCode={(invoices) => invoices.id}
                        onSelect={(selectedIds, selectedId) => this.context.showSlideModal(<ViewInvoice
                            id={selectedId}
                            reloading={this.loadData.bind(this)}
                            providers={this.state.providers}
                            materials={this.state.materials}
                            materialsGroups={this.state.materialsGroups}
                        />)}
                        scrollHeight={260}
                    />
                </div>
                <div className="materials__wrap-btn">
                    <Button variant="success" size="long" children="Добавить" onClick={() =>
                        this.context.showSlideModal(<PopupInvoices
                            reloading={this.loadData.bind(this)}
                            providers={this.state.providers}
                            materials={this.state.materials}
                            materialsGroups={this.state.materialsGroups}
                        />)
                    }/>
                </div>
            </React.Fragment>
        )
    }

    /** При обновление данных на сервере, обновляем только необходимое */
    loadData() {
        const command = new ServerCommand('material/get-waybill-list', {getSum: true});
        command.exec().then(response => {
            this.setState({list: (response.list.length > 0) ? response.list : null});
        });
    }


}
