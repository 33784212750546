import React from 'react';
import {WrapIcon} from "../../../components-ui/DataDisplay/Icon";
import Box from "../../../components-ui/Layout/Box";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import Helper from "../../../common/helpers/main";

export interface PropsSwirl {
    textUp: string | number
    textDown: string | number
    percentage: string | number
}

const Swirl = ({textDown, textUp}: Omit<PropsSwirl, 'percentage'>) => {
    const textTwo = Helper.String.numberFormat(+textDown, 0)
    return (
        <Box position="relative">
            <WrapIcon viewBox="0 0 340 316" width="100%" height="310px">
                <path fill="#aa70cb"
                      d="M173,167.33h-7q-58.2,0-97.8-1l61,143.92Q134.3,316,170,316h.86q34.89-.08,40-5.72l61-143.92Q231.9,167.32,173,167.33Z"/>
                <path fill="#447ce6"
                      d="M173,10.1h-5.56Q38.13,10.06,0,5.59L64.47,157.68q41.32,1.09,105.54,1.07,63.66,0,105.52-1.05L340,5.59Q301.9,10,173,10.1Z"/>
                <ellipse fill="#2a59b3" cx="170.5" cy="2.79" rx="156.5" ry="2.79"/>
            </WrapIcon>
            <Box position="absolute" positionOffsets={{top: '0', left: '0'}} width="100%" height="50%" display="flex" justifyContent="center" alignItems="center">
                <Typography color="white" fontSize="28px" fontWeight="bold" children={Helper.String.numberFormat(+textUp, 0)} />
            </Box>
            <Box position="absolute" positionOffsets={{top: '50%', left: '0'}} width="100%" height="50%" display="flex" justifyContent="center" alignItems="center">
                <Typography color="white" fontSize={textTwo.length >= 10 ? "24px" : "28px"} fontWeight="bold" children={textTwo} />
            </Box>
        </Box>
    );
};

export default Swirl;
