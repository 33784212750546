import React from 'react';
import Icon from '../DataDisplay/Icon';
import Typography from '../DataDisplay/Typography/Typography';
import {SGroupItem} from './styles';
import {
    GroupServices,
} from '../../pages/LaboratoryServiceRatesPage/containers/LaboratoryRatesTable/hooks/useLaboratoryRatesTable';
import Box from '../Layout/Box';

interface Props {
    group: GroupServices;
    selectedGroup?: GroupServices;
    onClickGroup: (id: number) => void;
    showServices: boolean;
}

const GroupTreeItem = ({group, selectedGroup, onClickGroup, showServices}: Props) => {
    return (
        <>
            <SGroupItem
                isActive={group.id === selectedGroup?.id}
                onClick={() => {
                    onClickGroup(group.id);
                }
                }>
                {(!!group.groups || (showServices && group.services)) && <Icon name='playArrow'
                                                                               width='8px'
                                                                               transform={group.isOpen ? 'rotate(90deg)' : ''}
                                                                               color='textDarkGray' />}
                <Icon name='colorFolder' />

                <Typography>{group.name}</Typography>

            </SGroupItem>
            {!!group.groups && group.isOpen &&
                <Box display='flex' flexDirection='column' padding='0 0 0 50px'>
                    {group.groups.map((innerGroup) =>

                        <GroupTreeItem
                            group={innerGroup as GroupServices}
                            selectedGroup={selectedGroup}
                            onClickGroup={onClickGroup}
                            showServices={showServices}
                        />,
                    )}
                </Box>
            }
            {group.services && showServices && group.isOpen &&
                group.services.map(service =>
                    <Box display='flex' flexDirection='column' padding='0 0 0 50px'>
                        <SGroupItem
                            isActive={false}
                        >
                            <Typography>{service.name}</Typography>
                        </SGroupItem>
                    </Box>,
                )
            }
        </>
    );
};

export default GroupTreeItem;
