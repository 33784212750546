import React from 'react';
import useManagerTasks from "./hooks/useManagerTasks";
import Filter from "../../containers/Tasks/containers/Filter/containers/Filter";
import SelectedUser from "./components/SelectedUser";
import TasksList from "./containers/TasksList";

const ManagerTasks = () => {
    const {
        state,
        tasksInfo,
        infoManagerTasks,
        methodsManagerTasks,
        handlersState,
        noticesQuantity,
        refScrollList,
        groupsButtonName
    } = useManagerTasks()

    if (Object.keys(infoManagerTasks.types).length === 0) return null;

    return (
        <>
            <Filter groupsButtonName={groupsButtonName}
                    noticesQuantity={noticesQuantity}
                    noticeQuantityTotal={noticesQuantity.all}
                    tasksInfo={tasksInfo}
                    handleHeightFilter={handlersState.handleHeightFilter}
                    selectedFilter={state.selectedFilter}
                    handleSelectedFilter={handlersState.handleSelectedFilter}
                    noticeName="Задачи"
                    footerComponent={<SelectedUser selectedUser={state.selectedUser}
                                                   tasks={state.tasks}
                                                   handleSelectedUser={handlersState.handleSelectedUser}/>}
                    optionsBranches={infoManagerTasks.optionsBranches}
                    selectedBranches={state.branches}
                    handleBranches={handlersState.handleBranches}
            />
            <TasksList state={state} infoManagerTasks={infoManagerTasks} refScrollList={refScrollList}
                       tasksInfo={tasksInfo} handleSelectedUser={handlersState.handleSelectedUser}
                       methodsManagerTasks={methodsManagerTasks}/>
        </>
    );
};

export default ManagerTasks;
