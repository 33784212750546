import {useMemo} from "react";
import _ from "underscore";
import {StateDetailed} from "../../../hooks/useDetailed";

interface Props {
    detailedState: StateDetailed
}

const useTypeTasks = ({detailedState}: Props) => {
    const {historyData} = detailedState;
    return useMemo(() => {
        let res = [] as string[];
        historyData.completedCalls.forEach((call) => {
            res = res.concat(call.tasksTypes)
        })
        return _.uniq(res);
    }, [JSON.stringify(historyData.completedCalls)])
}

export default useTypeTasks
