export const columnTable = [
    {
        title: 'Дата и время',
        code: 'date'
    },
    {
        title: 'Тип',
        code: 'type'
    },
    {
        title: 'Статус',
        code: 'status'
    },
    {
        title: 'Администратор',
        code: 'user'
    },
    {
        title: 'Задачи',
        code: 'tasks'
    },
    {
        title: 'Комментарий',
        code: 'comment'
    },
    {
        title: 'Тип звонящего',
        code: 'callerType'
    },
    {
        title: 'Тема',
        code: 'topics'
    },
];
