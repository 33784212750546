import styled from "styled-components";
import {PContextMenuWrap, PContextMenuButton} from "./typesContextMenu";


export const ContextMenuWrap = styled.div<PContextMenuWrap>`
    border-radius: 1px;
    box-shadow: 0 3px 13px 0 #b8c0d0;
    background-color: ${props => props.theme.colors.white};
    padding: 5px 0;
    position: absolute;
    top: ${props => props.position.y + 15}px;
    left: ${props => props.position.x}px;
    transform: translateX(-50%);
    z-index: 30;
    display: flex;
    flex-direction: column;
    width: 250px;
    &::after {
        content: '';
        position: absolute;
        top: -5px;
        left: 50%;
        width: 10px;
        height: 10px;
        transform: translate(-50%) rotate(45deg);
        background-color: ${props => props.theme.colors.white};
        z-index: -1;
    }
`

const colorStatus = (props: PContextMenuButton) => {
    switch (props.group) {
        case "SICK" :
            return props.theme?.colors.danger;
        case "CURED" :
            return props.theme?.colors.purple;
        default:
            return props.theme?.colors.svgGray;
    }
};

export const ContextMenuButton = styled.button.attrs({type: 'button'})<PContextMenuButton>`
    padding: 8px 16px;
    border: none;
    width: 100%;
    background-color: ${props => props.isSelected ? props.theme.colors.secondary : props.theme.colors.white};
    cursor: pointer;
    transition: background-color .3s;
    display: flex;
    align-items: center;
    outline: none;
    position: relative;
    z-index: 1;
    .tooth-status-code {
        width: 22px;
        height: 20px;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${colorStatus};
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > div {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                & > svg {
                     fill: ${colorStatus};
                     width: 100%;
                     height: 100%;
                }
            }
        }
    }
    .tooth-status-text {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: ${props => props.theme.colors.text};
        margin-left: 12px;
    }
    &:hover {
        background-color: ${props => props.theme.colors.blue};
    }
`
