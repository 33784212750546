import React, {Dispatch, SetStateAction} from 'react';
import Typography from '../../../../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../../../../components-ui/Layout/Box';
import {ResponseUserDetail, Salary} from '../AddingEditingEmployee/types';
import Button from '../../../../../../components-ui/Inputs/Button/Button';
import Tabs from '../../../../../../components-ui/Navigation/Tabs/Tabs';
import Icon from '../../../../../../components-ui/DataDisplay/Icon';
import Select from '../../../../../../components-ui/Inputs/Select/Select';
import useDetailRates from './hooks/useDetailRates';
import DirectionsTable from './components/DirectionsTable';
import ServicesTable from './components/ServicesTable';
import Paper from '../../../../../../containers/UI-Collection/Elements/Surfaces/Paper';
import LoaderPage from '../../../../../../components/LoaderPage';
import Scrollbar from '../../../../../../components-ui/DataDisplay/Scrollbar/Scrollbar';
import {useModal} from '../../../../../../containers/DialogsManager/containers/Modal/Modal';
import {useDialogsManager} from '../../../../../../containers/DialogsManager/DialogsManager';
import SavePattern from './components/SavePattern';
import Helper from '../../../../../../common/helpers/main';
import _ from 'lodash';

interface Props {
    setGlobalState: Dispatch<SetStateAction<ResponseUserDetail>>;
    salary?: Salary;
}

const DetailRates = ({setGlobalState, salary}: Props) => {

    const {
        dataResponse,
        rates,
        setRates,
        switchType,
        setSwitchType,
        patterns,
        pattern,
        setPattern,
        refreshDataFromServer,
    } = useDetailRates({salary});

    const {closeModal} = useModal();

    const {showModal} = useDialogsManager();
    return (
        <Box padding='45px 30px 76px' minWidth='1000px'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        children='Ставка заработной платы' />
            <Box margin='39px 0 0 0'>
                <Box display='flex' gap='20px' alignItems='center'>
                    <Button variant='default' isIcon onClick={() => {
                        showModal(<SavePattern pattern={pattern} rates={rates} switchType={switchType}
                                               refreshDataFromServer={refreshDataFromServer} />);
                    }}>
                        <Icon name='save' color='textDarkGray' />
                    </Button>
                    <Select variant='default' sizeSelect='sm-md' placeholder='Шаблон'
                            value={{value: pattern.id, label: pattern.name}}
                            onChange={(value) => {
                                setPattern(patterns?.filter(pattern => pattern.id === value.value)[0]);
                            }}
                            options={patterns?.map(item => ({value: item.id, label: item.name}))}
                    />
                    <Tabs
                        variant='primary'
                        size='sm'
                        tabs={[
                            {code: 'services', name: 'По направлениям'},
                            {code: 'group_services', name: 'По услугам'},
                        ]}
                        activeTab={switchType}
                        onChange={setSwitchType}
                    />
                </Box>
                <Paper padding='0px' margin='15px 0 0 0'>
                    {dataResponse.directions.length ?
                        <Scrollbar height='600px'>
                            {dataResponse.directions && switchType === 'services' &&
                                <DirectionsTable dataResponse={dataResponse} setRates={setRates} rates={rates} />
                            }
                            {dataResponse.services && dataResponse.servicesGroups && switchType === 'group_services' &&
                                <ServicesTable
                                    rates={rates}
                                    setRates={setRates}
                                    // @ts-ignore
                                    dataResponse={dataResponse} />
                            }
                        </Scrollbar>
                        : <LoaderPage height={625} />}
                </Paper>
                <Box display='flex' gap='20px' margin='15px 0 0 0'>
                    <Button children='Отменить' variant='default' onClick={() => {
                        closeModal();
                    }} />
                    <Button children='Сохранить' variant='success' onClick={() => {
                        let filtredRates = {};
                        // @ts-ignore
                        if (rates[switchType] && Object.keys(rates[switchType]).some(val => Object.keys(val).length)) {
                            filtredRates = switchType === 'services' ?
                                {
                                    [switchType]: {
                                        directions:
                                        // @ts-ignore
                                            Helper.filterObj(rates[switchType].directions, (val) => !!val.ratePercent),
                                    },
                                } :
                                {
                                    [switchType]: {
                                        groups:
                                        // @ts-ignore
                                            Helper.filterObj(rates[switchType].groups, (val) =>
                                                Object.values(val).every(val => !!val),
                                            ),
                                        // @ts-ignore
                                        services: Helper.filterObj(rates[switchType].services, (val) =>
                                            Object.values(val).every(val => !!val),
                                        ),
                                    },
                                };
                        }
                        setGlobalState(prevState => {

                            const salary = _.omit(prevState.salary, switchType === 'services' ? 'group_services' : 'services');

                            return ({
                                ...prevState,
                                salary: {
                                    ...salary,
                                    ...filtredRates,
                                },

                            });
                        });
                        closeModal();
                    }} />
                </Box>
            </Box>
        </Box>
    );
};

export default DetailRates;
