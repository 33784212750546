import {Dispatch, SetStateAction, useEffect} from "react";
import {useSlideModal} from "../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {StateCallCenter} from "../../types/components";

interface PropsUseChangeRaisedCalls {
    state: StateCallCenter
    setState: Dispatch<SetStateAction<StateCallCenter>>
}

const useChangeRaisedCalls = ({state, setState}: PropsUseChangeRaisedCalls) => {
    const {raisedCalls, plannedCalls} = state
    const {setIsClose} = useSlideModal();
    useEffect(() => {
        if (!!raisedCalls.length) {
            raisedCalls.forEach(raisedCall => {
                if (raisedCall.isRaised) {
                    const findPlannedCall = plannedCalls.find(plannedCall => plannedCall.phone === raisedCall.contactPhone);
                    if (!!findPlannedCall && (!!findPlannedCall.tasks[0].postponedTo || !findPlannedCall.canSaveResult)) {
                        setIsClose(true);
                        setState(prevState => ({
                            ...prevState,
                            raisedCalls: prevState.raisedCalls.filter(prevItem => prevItem.contactPhone !== raisedCall.contactPhone)
                        }))
                    }
                }
            })
        }
    }, [JSON.stringify(raisedCalls)])

}

export default useChangeRaisedCalls
