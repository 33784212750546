import React, {Dispatch, SetStateAction, useMemo} from 'react';
import Select from "../../../../components-ui/Inputs/Select/Select";
import {AnalyticsFilter} from "../../style";
import {useOptionsSelectDoctors} from "../../../../common/hooks/useOptionsSelectDoctors";
import {StateReports} from "../../types";
import DateRange from "../../../../components-ui/Inputs/DateRange";
import useHandlers from "./hooks/useHandlers";
import {allDoctors} from "../../data";
import {useSelector} from "react-redux";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";
import Box from "../../../../components-ui/Layout/Box";
import Loader from "../../../../components-ui/Feedback/Loader/Loader";

interface Props {
    state: StateReports
    setState: Dispatch<SetStateAction<StateReports>>
    loaderData: boolean
}

const Filter = ({state, setState, loaderData}: Props) => {

    const userBranches = useSelector(state1 => state1.user.branches)
    const allBranches = useSelector(state1 => state1.common.allBranches)

    const doctors = useOptionsSelectDoctors({isColor: true, isBranches: true, isFilterDeletedAt: true})

    const optionsBranches: OptionSelect[] = useMemo(() => {
        return userBranches.map(branchId => ({
            value: branchId,
            label: allBranches[branchId].name
        }))
    }, [userBranches, allBranches])

    const optionsDoctors = useMemo(() => {
        let result = doctors.filter(doctor => !!doctor.branches && doctor.branches.includes(+state.selectedBranch.value));
        result.unshift(allDoctors)
        return result
    }, [doctors, state.selectedBranch.value])

    const {handleBranch, handleEndDate, handleStartDate, handleDoctor} = useHandlers({setState})

    return (
        <AnalyticsFilter>
            {optionsBranches.length > 1 && (
                <Select options={optionsBranches}
                        sizeSelect="sm"
                        placeholder="Все выбранные филиалы"
                        value={state.selectedBranch}
                        onChange={handleBranch}
                />
            )}
            <DateRange startDate={state.startDate}
                       endDate={state.endDate}
                       setStartDate={handleStartDate}
                       setEndDate={handleEndDate} margin="0 0 28px"
                       maxDate={new Date()}/>
            <Select options={optionsDoctors}
                    variant="colored"
                    sizeSelect="sm"
                    placeholder="Врач"
                    value={state.selectedDoctor}
                    onChange={handleDoctor}
            />
            <Box position="relative" width="32px" height="32px" margin="0 0 0 15px">
                <Loader visible={loaderData} isBackground={false} width={28} height={28} />
            </Box>
        </AnalyticsFilter>
    );
};

export default Filter;