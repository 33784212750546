import {useMemo} from "react";
import {AverageCheckReport} from "../../../../types";
import {StateAverageCheck} from "../types";
import {useSelector} from "react-redux";
import _ from "lodash";
import {sortName} from "../../../../../../common/helpers/graph-helper";

interface Props {
    report: AverageCheckReport
    state: StateAverageCheck
    stateKey: string
}

const useData = ({report, state, stateKey}: Props) => {
    const users = useSelector(state1 => state1.common.users)
    const directions = useSelector(state1 => state1.common.directions)
    return useMemo(() => {
        if (Object.keys(report.directions).length) {
            if (state.currentTab === 'directions') {
                return [
                    {
                        code: 'clinic',
                        value: report.general
                    },
                    ..._.map(report.directions, (direction, code) => {
                        return {
                            code,
                            value: direction.general,
                            dataTooltip: _.map(direction.doctors, (value, code) => ({value, code})).sort((a, b) => b.value - a.value)
                        }
                    }).sort((a, b) => directions[a.code].sort - directions[b.code].sort)
                ]
            } else {
                return _.map(report.doctors, (value, code) => {
                    return {
                        code,
                        value: value.general,
                        dataTooltip: _.map(value.directions, (value, code) => ({value, code})).sort((a, b) => b.value - a.value)
                    }
                }).sort((a, b) => sortName(users[+a.code].lastName, users[+b.code].lastName))
            }
        } else {
            return null;
        }
    }, [JSON.stringify(report), state.currentTab, stateKey])
}

export default useData
