import React, {Dispatch, SetStateAction, useMemo} from "react";
import SVG from "../../../../../common/helpers/SVG";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import {useSlideModal} from "../../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import Box from "../../../../../components-ui/Layout/Box";
import {StateCallCenter} from "../../../types/components";
import { SBoxFlexCenter } from "../../../../../containers/Tasks/style";

interface Props {
    state: StateCallCenter
    setState: Dispatch<SetStateAction<StateCallCenter>>
}

const GoToTaskList = ({state, setState}: Props) => {
    const {isClose} = useSlideModal();
    const isGoToTaskList = useMemo(() => {
        if (!isClose) {
            return !state.calls.some(call => call.contactPhone === state.detail?.phone);
        } else {
            return true
        }
    }, [isClose])

    if (!isGoToTaskList) {
        return (
            <Box height="53px" display="block"/>
        )
    } else {
        return (
            <SBoxFlexCenter padding="13px 0 20px 20px"
                            onClick={() => setState(prevState => ({...prevState, detail: null}))}>
                <SVG name="arrow-select" className="call-center-arrow-select"/>
                <Typography isLink={true} variant="small" children="К списку задач"/>
            </SBoxFlexCenter>
        )
    }

}

export default GoToTaskList
