import React, {forwardRef} from 'react';
import { WrapIcon } from '../../../containers/WrapIcon';
import { IIcon } from '../../../types/common';

export const TimeIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm1,12a1,1,0,0,1-1,1H8a1,1,0,0,1,0-2h3V5a1,1,0,0,1,2,0v7Z"/>
        </WrapIcon>
    );
});
