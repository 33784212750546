import React, {useMemo} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import {PDateTime} from "./typesDateTime";
import {SWrapDateTime} from "./styledDateTime";
import SVG from "../../../common/helpers/SVG";
import Typography from "../../DataDisplay/Typography/Typography";
import Helper from "../../../common/helpers/main";

const timeFrom = '08:00'
const timeTo = '21:30'


export default function DateTime(
    {
        selected,
        onChange,
        dateFormat = 'd MMMM yyyy',
        margin,
        widthInput,
        widthModal,
        maxDate,
        minDate,
        includeDates,
        dayClassName,
        calendarClassName,
        children,
        placeholderText,
        showTimeSelect,
        filterPassedTime,
        isLink = false,
        position = 'left',
        minTime,
        maxTime,
        isNullText,
        isError = false,
        shouldCloseOnSelect = true,
        onFocus,
        onBlur,
        isDateRange = false,
        isDownOpen = false,
        ...props
    }: PDateTime) {

    let propsDatePicker = {} as any;
    if (!!includeDates) propsDatePicker.includeDates = includeDates;
    if (!!dayClassName) propsDatePicker.dayClassName = dayClassName;
    if (!!calendarClassName) propsDatePicker.calendarClassName = calendarClassName;
    if (!!children) propsDatePicker.children = children;
    if (!!placeholderText) propsDatePicker.placeholderText = placeholderText;
    if (!!maxDate) propsDatePicker.maxDate = maxDate;
    if (!!minDate) propsDatePicker.minDate = minDate;
    if (!!minDate && !!maxDate && !!minTime && !!maxTime) {
        propsDatePicker.minTime = minTime
        propsDatePicker.maxTime = maxTime
    }

    const openingTimes = useMemo(() => {
        const minTimeSplit = timeFrom.split(':')
        const maxTimeSplit = timeTo.split(':')
        return {
            min: (+minTimeSplit[0] * 60 + +minTimeSplit[1]) / 30,
            max: ((24 * 60) - (+maxTimeSplit[0] * 60 + +maxTimeSplit[1])) / 30,
        }
    }, [])

    return (
        <SWrapDateTime margin={margin}
                       widthInput={widthInput}
                       widthModal={widthModal}
                       showTimeSelect={showTimeSelect}
                       minTimesFilter={openingTimes.min}
                       maxTimesFilter={openingTimes.max}
                       isLink={isLink}
                       position={position}
                       isError={isError}
                       isDateRange={isDateRange}
                       isDownOpen={isDownOpen}

        >
            <DatePicker locale={ru}
                        dateFormat={dateFormat}
                        dropdownMode="select"
                        selected={selected}
                        showTimeSelect={showTimeSelect}
                        timeCaption="Время"
                        timeFormat="HH:mm"
                        onCalendarOpen={onFocus}
                        onCalendarClose={onBlur}
                        filterTime={filterPassedTime}
                        shouldCloseOnSelect={shouldCloseOnSelect}
                        customInput={isLink ? <ExampleCustomLink isNullText={isNullText}/> : <ExampleCustomInput placeholderText={placeholderText} />}
                        onChange={(value, event) => {
                            if (!!onChange) onChange(value, event)
                        }}
                        {...props}
                        {...propsDatePicker}
            />
            <SVG name="arrow-select" className="date-time-arrow-select"/>
        </SWrapDateTime>
    )
}

const ExampleCustomInput = (props: any) => {
    const { value, onClick, placeholderText } = props;
    return (
        <input type="text" value={value} onClick={onClick} readOnly={true} placeholder={placeholderText} />
    );
}

const ExampleCustomLink = ({ value, onClick, isNullText }: any) => {
    const valueSplit = value.split(' ')
    const dateValue = valueSplit[0]
    const tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1)
    if (!!value) {
        if (dateValue === Helper.Date.formatDate(tommorow as any, 'D-MM-YYYY')) {
            if (!!valueSplit[1]) {
                value = 'завтра ' + valueSplit[1]
            } else {
                value = 'завтра'
            }
        } else if (dateValue === Helper.Date.formatDate(new Date() as any, 'D-MM-YYYY')) {
            if (!!valueSplit[1]) {
                value = valueSplit[1]
            } else {
                value = 'сегодня'
            }
        } else {
            const newSplit = value.split('-');
            const newValue = newSplit[1] + '-' + newSplit[0] + '-' + newSplit[2];
            value = Helper.Date.formatDate(newValue as any, 'DD MMM YYYY, HH:mm')
        }

    }
    return (
        <Typography variant="small" children={!!value ? value : !!isNullText ? isNullText : 'не указано'}
                    isLink={true} color="info" onClick={onClick} />
    )
}
