import {useMemo} from "react";
import {AverageCheckReport} from "../../../../types";
import {useSelector} from "react-redux";
import {StateAverageCheck} from "../types";
import {sortName} from "../../../../../../common/helpers/graph-helper";
import _ from 'lodash';
import {clinicInfo} from "../../../../data/widgetsInfo";

interface Props {
    report: AverageCheckReport
    state: StateAverageCheck
}

const useDirectionsCodes = ({report, state}: Props) => {
    const directions = _.clone(useSelector(state1 => state1.common.directions))
    const users = useSelector(state1 => state1.common.users)
    directions.clinic = clinicInfo;
    return useMemo(() => {
        if (state.currentTab === 'directions') {
            return ['clinic', ...Object.keys(report.directions)].sort((a, b) => directions[a].sort - directions[b].sort)
        } else {
            return Object.keys(report.doctors).sort((a, b) => sortName(users[+a].lastName, users[+b].lastName))
        }
    }, [JSON.stringify(report), state.currentTab]);
}

export default useDirectionsCodes
