import React from 'react';
import Typography from '../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../components-ui/Layout/Box';
import useLaboratoryServiceRates from './hooks/useLaboratoryServiceRates';
import LoaderPage from '../../components/LoaderPage';
import LaboratoriesServiceRateFilter from './components/Filter';
import LaboratoryRatesTable from './containers/LaboratoryRatesTable';

const LaboratoryServiceRatesPage = () => {

    const {
        loaderPage,
        loaderTable,
        laboratoryServiceRatesResponse,
        selectedBranch,
        setSelectedBranch,
        optionsBranches,
        refreshDataFromServer
    } = useLaboratoryServiceRates()

    return (
        <Box padding="62px 20px 80px 28px">
            <Typography variant="title" fontWeight="bold">Ставки техников</Typography>
            <LaboratoriesServiceRateFilter
                selectedBranch={selectedBranch}
                setSelectedBranch={setSelectedBranch}
                optionsBranches={optionsBranches}
            />
            {!loaderPage ?
               <LaboratoryRatesTable
                   selectedBranch={selectedBranch}
                   loaderTable={loaderTable}
                   data={laboratoryServiceRatesResponse}
                   refreshDataFromServer={refreshDataFromServer}
               />
                :
                <LoaderPage height={338}/>
            }
        </Box>
    );
};

export default LaboratoryServiceRatesPage;
