import React, {useState} from 'react';
import Typography from '../../../../../../components-ui/DataDisplay/Typography/Typography';
import {Label} from '../../../../../../components-ui/DataDisplay/Label';
import {
    commonLabelOption, commonSelectOption,
    commonTextFieldOption,
} from '../../../../../TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import TextField from '../../../../../../components-ui/Inputs/TextField/TextField';
import Box from '../../../../../../components-ui/Layout/Box';
import Button from '../../../../../../components-ui/Inputs/Button/Button';
import ValidationLinkInfo from '../../../../../../components/ValidationLinkInfo/ValidationLinkInfo';
import {useModal} from '../../../../../../containers/DialogsManager/containers/Modal/Modal';
import useLoader from '../../../../../../components/Errors';
import ServerCommand from '../../../../../../common/server/server-command';
interface Props {
    id: number
    name: string
    refreshDataFromServer: () => void
}
const EditGroup = ({id, refreshDataFromServer, name}: Props) => {

    const [state, setState] = useState(name)

    const {closeModal} = useModal();

    const [loader, setLoader] = useLoader();

    const [validationCheck, setValidationCheck] = useState(false);

    const changeGroupName = () => {
        setLoader(true);
        ServerCommand.post(`call-center/script-group/edit`, {id, name: state}, () => {
            closeModal();
            refreshDataFromServer();
        }).then();
    };

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        children="Изменение группы скриптов" textAlign='center' />
            <Box margin='39px 0 0 0'>
                <Label {...commonLabelOption}
                       isRequired={true}
                       labelText="Название"
                       align="leftTop"
                       marginLabel="8px 10px 0 0">
                    <TextField
                        {...commonTextFieldOption}
                        value={state}
                        onChange={setState}
                        isError={!state && validationCheck}
                    />
                </Label>
                <Label labelText='' {...commonLabelOption}>
                    <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                        <Button children='Сохранить'
                                size='md'
                                loader={loader}
                                variant={state ? 'success' : 'disabled'}
                                onClick={changeGroupName}
                        />
                        <Box maxWidth='172px' margin='0 0 0 16px'>
                            <ValidationLinkInfo validate={!!state}
                                                onClickIsValidate={() => setValidationCheck(true)
                                                } />
                        </Box>
                    </Box>
                </Label>
            </Box>
        </Box>
    );
};

export default EditGroup;
