import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth72Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 15.85 24"} width={width || "10"} height={height || "16"} ref={ref}>
            <path d="M15.84,4.26c-.32-4-1.69-4-5.77-4H8.37A11.18,11.18,0,0,1,6.6.12C4.83,0,2.44-.2,1.48.58c-3.75,3.15.45,18,3.5,21.18C6.15,23,7.76,24.34,9.23,23.92c4.42-1.34,6.7-15.4,6.61-19.67Z"/>
        </WrapIcon>
    );
});
