import {useMemo} from "react";
import {usePreparationForAdmission} from "../PreparationForAdmission";

export default function useSubmitValidation() {
    const {state} = usePreparationForAdmission();

    return useMemo(() => {
        return !Object.values(state.documentRequired).some(check => check)
    }, [JSON.stringify(state.documentRequired)])
}
