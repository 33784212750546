import {As, Variant} from "../types";

export const getAsTypography = (as?: As, variant?: Variant) => {
    if (!!as) {
        return as
    } else {
        switch (variant) {
            case "title":
                return 'h1'
            case "bigger-title":
            case "big-title":
                return 'h2'
            case "middle-title":
            case "small-title":
                return 'h3'
            case "min-title":
                return 'h4'
            case "middle":
            case "min":
            case "small":
                return 'p'
            case "large":
            case "caption":
            default:
                return 'div'
        }
    }
}
