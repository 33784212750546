import React from 'react';
import Typography from "../../components-ui/DataDisplay/Typography/Typography";
import {Alert} from "../../components-ui/DataDisplay/Alert";
import Payments from "./containers/Payments";


const CheckPayments = () => {

    return (
        <>
            <Typography as="h1" variant="title" fontWeight="bold" margin="30px 0">Проверка оплат пациентов</Typography>
            <Alert
                children="Проверьте, что сегодня правильно внесены все оплаты. Добавьте недостающие и отмените неправильные. Укажите фактический баланс пациента, если он отличается от баланса в программе."
                margin="auto"/>
            <Payments/>
        </>
    );
};

export default CheckPayments;
