import React, {useEffect, useState} from "react";
import {Top, Bottom} from "./styledScrollArea";
import {useSelector} from "react-redux";
import {useDrop} from "react-dnd";
import _ from "underscore";

export default function ScrollArea() {
    const isScrollArea = useSelector(state => state.common.isScrollArea);
    if (!isScrollArea) return null;

    const popups = document.getElementsByClassName('s-slide-modal-wrap');
    let zIdx = 0;
    let newIndex = 0;
    _.each(popups, (elem, index) => {
        const idx = elem.getAttribute('data-idx') as any;
        if (+idx > zIdx) {
            newIndex = index;
            zIdx = +idx;
        }
    });
    const popupScrollArea = popups[newIndex].children[0].children[1];

    return (
        <>
            <Wrap popupScrollArea={popupScrollArea} variant="top" />
            <Wrap popupScrollArea={popupScrollArea} variant="bottom" />
        </>

    )
}

interface Item {
    popupScrollArea: any,
    variant: 'top' | 'bottom'
}

const Wrap = ({popupScrollArea, variant}: Item) => {

    const [over, setOver] = useState(false);

    useEffect(() => {
        let timeId = setTimeout(function setTime() {
            const scrollTopPopup = popupScrollArea.scrollTop;
            popupScrollArea.scrollTo(0, variant === 'top' ? scrollTopPopup - 3 : scrollTopPopup + 3);
            if (over) timeId = setTimeout(setTime, 1)
        }, 1);
        return () => {
            clearTimeout(timeId)
        }
    }, [over])

    const [, drop] = useDrop({
        accept: 'card',
        collect: (monitor) => {
            if (monitor.isOver()) {
                setOver(true);
            } else {
                setOver(false)
            }
        },
    });

    if (variant === "top") {
        return <Top ref={drop}/>
    } else {
        return <Bottom ref={drop}/>
    }

}


