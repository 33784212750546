import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {IStateCashSalary} from "../../types";
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonSelectOption} from "../../data";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {useOptionsBranchesUser} from "../../../../../common/hooks/useOptionsBranchesUser";

interface Props {
    state: IStateCashSalary
    setState: Dispatch<SetStateAction<IStateCashSalary>>
}

const FilterBranches = ({state, setState}: Props) => {
    const optionsBranches = useOptionsBranchesUser()
    const handlePaymentMethod = useCallback((branch: OptionSelect) => {
        setState(prevState => ({...prevState, branch}))
    }, [setState])
    return (
        <Label labelText="Филиал" isRequired={true} {...commonLabelOption} >
            <Select
                {...commonSelectOption}
                options={optionsBranches}
                value={state.branch}
                onChange={handlePaymentMethod}
            />
        </Label>
    );
};

export default FilterBranches;
