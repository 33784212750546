import React from "react";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import Box from "../../../../../components-ui/Layout/Box";

interface PropsHeaderModal {
    children?: any,
    padding?: string
}

export default function HeaderModal({children, padding}: PropsHeaderModal) {

    return (
        <Box padding={!!padding ? padding : '9px 24px 11px'} borderRadius="4px 4px 0 0" backgroundColor="bgLightBlue" borderBottom="borderDark">
            <Typography variant="small" fontWeight="600">
                {children}
            </Typography>
        </Box>
    )
}
