import styled from "styled-components";

export const WrapButtons = styled.div.attrs({className: 'WrapButtonsEditableField'})<any>`
    position: absolute;
    right: -5px;
    bottom: ${props => !!props.noMargin ? '1px' : '-5px'};
    display: flex;
    align-items: center;
`;

export const BtnSeparator = styled.div.attrs({className: 'BtnSeparator'})`
   width: 1px;
   height: 20px;
   background: ${props => props.theme.colors.borderGray};
`
