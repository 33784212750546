import React from "react";
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {buttonTitles} from "../../helper/getDataCallCenter";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Box from "../../../../components-ui/Layout/Box";
import {CommonButtonProps} from "../../types/components";

interface Props extends CommonButtonProps {
    tabTreatmentPlan?: 'recommended' | 'performed'
}

export const OpenPlanButton = ({task, tabTreatmentPlan = "performed", margin, methodsCallCenter}: Props) => {
    const {openPlan} = methodsCallCenter
    return (
        <Box className="button-task-content" margin={margin}>
            <Button {...commonPropsButton} variant={"default"} children="открыть план"
                    title={buttonTitles[tabTreatmentPlan]}
                    onClick={() => openPlan(task.targetPerson.patientId as number, tabTreatmentPlan)}/>
        </Box>
    )
}
