import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface SCustomCellProps {
    width: string
    padding?: string
    isGrow?: boolean
    isContainerCell?: boolean
}

export type tableHandleScroll = (scrollPosition: number) => void

interface TableScrollComponents {
    children: ReactNode,
    handleScroll: tableHandleScroll
    scrollClassName: string
}

export const CustomCell = styled.div<SCustomCellProps> `
  ${props => `width: ${props.width};`}
  ${props => !!props.isGrow && `flex-grow: 1;`};
  height: 100%;
  display: grid;
  grid-auto-rows: ${props => !props.isContainerCell ? 'minmax(48px, auto)' : '1fr' };
  padding: ${props => !!props.padding ? props.padding : '0 1px'};
  background: ${props => props.theme.colors.borderGray};
  grid-gap: 1px;
  & > div, & > p {
    padding: ${props => props.isContainerCell ? '0' : '12px 5px 10px 20px'};
    background: ${props => !props.isContainerCell && props.theme.colors.white};
  }
`

export const STableHeader = styled.div `
  height: 53px;
  width: 100%;
  overflow: scroll hidden;
  &::-webkit-scrollbar { height: 14px; }
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 18px;
    background-clip: content-box;
    background-color: ${props => props.theme.colors.textGray};
    transition: .4s;

    &:hover {
      background-color: ${props => props.theme.colors.textDarkGray};
    }
  }
`
export const LeftShadowSide = styled.div`
  position: absolute;
  background: transparent;
  left: -5px;
  top: 0;
  display: block;
  content: '';
  width: 3px;
  height: 100%;
  box-shadow: 4px 0 4px 1px rgb(0 0 0 / 10%);
`
export const RightShadowSide = styled.div `
  position: absolute;
  background: transparent;
  right: -5px;
  top: 0;
  display: block;
  content: '';
  width: 3px;
  height: 100%;
  box-shadow: -4px 0 4px 1px rgb(0 0 0 / 10%);
`

export const SInnerTable = styled.div `
  width: 100%;
  height: 100%;
  overflow: scroll hidden;
  &::-webkit-scrollbar { height: 0; }
`

export const InnerTable = ({children, scrollClassName, handleScroll}: TableScrollComponents) =>
    <SInnerTable className={scrollClassName} onScroll={(element) => handleScroll(element.currentTarget.scrollLeft)}>
        <LeftShadowSide/>
        {children}
        <RightShadowSide/>
    </SInnerTable>

export const TableHeader = ({children, scrollClassName, handleScroll}: TableScrollComponents) =>
    <STableHeader className={scrollClassName} onScroll={(element) => handleScroll(element.currentTarget.scrollLeft)}>
        <LeftShadowSide/>
        {children}
        <RightShadowSide/>
    </STableHeader>
