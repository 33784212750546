import styled from "styled-components";

export const WrapTimeCounter = styled.div.attrs({className: 'WrapInputNumber'})`
    display: flex;
    position: relative;
    width: max-content;
    user-select: none;
    & > input {
        width: 100px;
        pointer-events: none;
        user-select: none;
        &:focus {
            outline: none;
        }
    }
    .input-number-svg {
        height: 19px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: solid 1px ${props => props.theme.colors.borderGray};
        background: ${props => props.theme.gradient.default};
        position: absolute;
        right: 0;
        border-right: solid 1px ${props => props.theme.colors.borderGray};
        fill: ${props => props.theme.colors.text};
        cursor: pointer;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                height: 4px;
                width: 6px;
            }
        }
        &:hover {
            background: ${props => props.theme.gradient.defaultHover};
        }
        &:active {
            box-shadow: ${props => props.theme.shadow.defaultActive};
            background: #fbfbfd;
        }
    }
    .input-number-svg-up {
        top: 1px;
        border-radius: 0 2px 0 0;
        border-bottom: solid 1px ${props => props.theme.colors.borderGray};
        & > div {
            transform: rotate(-90deg);
        }
    }
    .input-number-svg-down {
        top: 20px;
        border-radius: 0 0 2px 0;
         & > div {
            transform: rotate(90deg);
        }
    }
`
