import React, {useEffect, useState} from "react";
import SaveButtons from "./SaveButtons";
import {useEditableField} from "../EditableField";
import {ValueEditableField} from "../typesEditableField";
import ServerCommand from "../../../common/server/server-command";
import YesAndNoRadio from "../../../components-ui/Inputs/Radio/components/YesAndNoRadio";

export default function FieldRadio() {
    const {field, patientId, setEditField} = useEditableField();
    const values = Object.values(field.values)[0] as ValueEditableField;
    const [value, setValue] = useState(values.value);
    const [isError, setError] = useState(values.validation);

    useEffect(() => {
        if (values.validation) {
            setError(true);
        }
    },[values.validation]);

    const onClickSave = () => {
        if (value !== null) {
            const command = new ServerCommand('patient/edit', {id: patientId, [values.name]: value});
            command.exec().then(() => {
                field.onChange(value);
                setEditField(false);
            });
            setError(false)
        } else {
            setError(true)
        }

    }
    return (
        <>
            <YesAndNoRadio checked={value} onChange={val => {setValue(val); setError(false)}} isError={isError} />
            <SaveButtons onClickSave={onClickSave} noCanceledButton={values.value === null}/>
        </>
    )
}
