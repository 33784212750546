import React from 'react';
import useLoadPatient from '../../../../../../common/hooks/useLoadPatient';
import {Label} from '../../../../../../components-ui/DataDisplay/Label';
import {commonLabelOption, commonTextFieldOption} from '../../../../../../content/modals/PaymentCreationForm/data';
import {WrapPaymentCreationForm} from '../../../../../../content/PatientCard/Section/Patient/styledPatient';
import Box from '../../../../../../components-ui/Layout/Box';
import TextField from '../../../../../../components-ui/Inputs/TextField/TextField';
import ResultSearchPatient from '../../../../../../components/ResultSearchPatient/ResultSearchPatient';

interface Props {
    fixedPatient?: string
    setPatientId: (val: number | undefined) => void
    isValid: boolean
}

const SelectPatient = ({setPatientId, fixedPatient, isValid}: Props) => {

    const {
        patient,
        ref,
        patientList,
        displayPatientList,
        loaderPatientList,
        onChangeSearchBar,
        onClickPatient,
        onResetSearch
    } = useLoadPatient({setPatientId, initialPatient: fixedPatient})


    return (
        <Label labelText="Пациент" isRequired={true} {...commonLabelOption}>
            <WrapPaymentCreationForm>
                <Box position="relative">
                    <TextField
                        {...commonTextFieldOption}
                        value={patient}
                        placeholder="Поиск пациента по ФИО и телефону"
                        isSearch={true}
                        isError={isValid}
                        onChange={onChangeSearchBar}
                        isReset={!!patient}
                        onClickReset={onResetSearch}
                    />
                    <ResultSearchPatient patientList={patientList}
                                         displayPatientList={displayPatientList}
                                         loaderPatientList={loaderPatientList}
                                         onClickPatient={onClickPatient}
                                         minWidth="500px"
                                         ref={ref}/>
                </Box>

            </WrapPaymentCreationForm>
        </Label>
    )
};

export default SelectPatient;