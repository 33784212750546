import React, {useMemo} from "react";
import Button from "../../../components-ui/Inputs/Button/Button";
import Helper from "../../../common/helpers/main";
import {Label} from "../../../components-ui/DataDisplay/Label";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import {commonLabelOption, commonTextFieldOption, commonLabelGroup} from "./data";
import {useSelector} from "react-redux";
import {useOptionsBranchesUser} from "../../../common/hooks/useOptionsBranchesUser";
import FilterBranches from "./components/FilterBranches";
import Box from "../../../components-ui/Layout/Box";
import useCashClose from "./hooks/useCashClose";
import KkmSelect from "./components/KkmSelect";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import MethodFields from "./components/MethodFields";
import ValidationLinkInfo from "../../../components/ValidationLinkInfo/ValidationLinkInfo";
import {CashBoxFields} from "./components/CashBoxFields";

interface Props {
    patientId?: number
    loadingFromServer: () => void
}

const CashClose = ({patientId: fixedPatientId, loadingFromServer}: Props) => {

    const discountTypeOptions = useSelector(state => state.common.discountTypeOptions);
    const paymentMethodOptions = useSelector(state => state.common.paymentMethodOptions);
    const optionsBranches = useOptionsBranchesUser()

    const {
        validate,
        kkmError,
        state,
        setState,
        handleXReport,
        handleOpenCash,
        handleCloseCash,
        loader,
        kkmOptions
    } = useCashClose({
        fixedPatientId,
        loadingFromServer,
        discountTypeOptions,
        paymentMethodOptions,
        optionsBranches
    })

    return (
        <Box padding="30px 27px 69px" minWidth="549px" width="549px">
            <Typography children="Закрыть кассу" textAlign="center" variant="bigger-title" margin="25px 0 59px"/>

            {false && !state.notEncashment &&
                <CashBoxFields values={state.values} setState={setState} cashBoxBalance={state.cashboxSum}/>
            }

            {optionsBranches.length > 1
                ?
                <FilterBranches state={state} setState={setState}/>
                :
                null
            }

            {kkmOptions.length > 0 &&
                <KkmSelect state={state} setState={setState} kkmOptions={kkmOptions}/>
            }

            <Label labelText="" {...commonLabelOption}>
                <Button
                    variant={(!!state.selectedKkm) ? 'success' : 'disabled'}
                    children='X-отчет'
                    loader={loader}
                    size="md" onClick={handleXReport}/>
            </Label>
            <Label labelText="" {...commonLabelOption}>
                <Button
                    variant={(!!state.selectedKkm) ? 'success' : 'disabled'}
                    children='Открыть'
                    loader={loader}
                    size="md" onClick={handleOpenCash}/>
            </Label>
            <Label labelText="" {...commonLabelOption}>
                <Button
                    variant={(!!state.selectedKkm) ? 'success' : 'disabled'}
                    children='Закрыть'
                    loader={loader}
                    size="md" onClick={handleCloseCash}/>
            </Label>

            <ValidationLinkInfo validate={!kkmError} onClickIsValidate={() => {}}
                                text={kkmError} linkText={""}/>

        </Box>
    )
};

export default CashClose;
