import React from "react";
import SubTitleTask from "../../../../containers/Tasks/components/UI/SubTitleTask";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {TaskCallCenter} from "../../types/common";
import {Doctor} from "./Doctor";
import { STaskItem } from "../../../../containers/Tasks/style";
import {useSelector} from "react-redux";

interface Props {
    task: TaskCallCenter
}

export const PatientWishes = ({task}: Props) => {
    const directions = useSelector(state => state.common.directions)
    const allBranches = useSelector(state => state.common.allBranches)
    const activeBranches = useSelector(state => state.common.branches)

    const isBranches = !!task.payload.branches
    const isDoctor = !!task.payload.doctorId;
    const isDirections = !!task.payload.directions;
    const isComment = !!task.payload.comment
    const isWishes = isDoctor || isDirections || isComment;

    return (
        <>

            {isWishes && <SubTitleTask children="Пожелания пациента"/>}
            {(isBranches && activeBranches.length > 1) && task.payload.branches.map((branchId: number, index: number) => (
                <LabelTask label={index === 0 ? "Филиалы" : ""}
                           key={branchId}
                           children={allBranches[branchId].name}/>
            ))}
            {isDoctor && (
                <>
                    <Doctor doctorId={task.payload.doctorId}/>
                    <LabelTask label="Только к этому врачу" children={task.payload.thisDoctorExactly ? 'да' : 'нет'}/>
                </>
            )}
            {isDirections && <LabelTask label="Направление"
                                        children={!!task.payload.directions && task.payload.directions
                                            .map((direction: string) => directions[direction].name).join(', ')}
            />}
            {isComment && (
                <>
                    <STaskItem margin="2px 0">
                        <Typography {...commonPropsTypography} children="Комментарий:"/>
                    </STaskItem>
                    <STaskItem margin="2px 0">
                        <Typography {...commonPropsTypography} fontStyle="italic" children={task.payload.comment}/>
                    </STaskItem>
                </>
            )}
        </>
    )
}
