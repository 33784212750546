import React from 'react';
import './Prompt.scss';

interface Props {
    text: string,
    isTriangle?: boolean,
}

const Prompt = ({text, isTriangle = false}: Props) => {
    return (
        <div className="prompt">
            {text}
            {isTriangle && <span className="prompt__triangle" />}
        </div>
    )
};

export default Prompt;
