import React, {useMemo} from 'react';
import {PropsPatient} from "./typesPatient";
import _ from 'underscore';
import {
    filterAddressName,
    filterFioName,
    filterPhoneName,
    fieldFIO,
    fieldAddress,
    fieldPhone
} from "../../../../containers/EditableField/helper/combinedFields";
import addSortField from "../../../../containers/EditableField/helper/addSortField";
import {TwoBoxes, InfoBoxWrap, BoxColumn} from '../../../../components-ui/styledComponent/common/wraps';
import Paper from "../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import EditableField from "../../../../containers/EditableField/EditableField";
import {
    PEditableField,
    ValueEditableField,
    variantEditableField
} from "../../../../containers/EditableField/typesEditableField";
import {
    pickEditableField,
    pickEditableFieldValues
} from "../../../../containers/EditableField/helper/pickEditableField";
import Helper from "../../../../common/helpers/main";
import {FieldsPatient} from "../../../../common/types/patient";
import {
    prepareAddress,
    prepareDate,
    prepareFio,
    preparePersonalBirthday, prepareEnum
} from "../../../../containers/EditableField/helper/prepareTextField";
import TextPhone from "../../../../containers/EditableField/elements/TextPhone";
import Button from "../../../../components-ui/Inputs/Button/Button";
import PaymentCreationForm from "../../../modals/PaymentCreationForm";
import TextEmail from "../../../../containers/EditableField/elements/TextEmail";
import TextRelations from "../../../../containers/EditableField/elements/TextRelations";
import RelationPatient from "./elements/RelationPatient";
import AddRelation from "./elements/AddRelation";
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import {useDialogsManager} from "../../../../containers/DialogsManager/DialogsManager";
import {useSelector} from "react-redux";

export default function Patient(
    {
        fields,
        fieldsGroups,
        loadingFromServer,
        patient,
        relatives,
        relationTypesTitles,
        samePhonePatients
    }: PropsPatient) {

    const {showModal} = useDialogsManager();

    const user = useSelector(state => state.user);

    const groups = useMemo(() => {
        const newFieldsGroups = _.clone(fieldsGroups)
        const valuesFields = _.values(_.clone(fields))
            .filter(field => filterFioName(field.name) && filterPhoneName(field.name) && filterAddressName(field.name))
            .concat([fieldFIO, fieldAddress, fieldPhone])
            .map(addSortField)
            .sort((a: any, b: any) => a.sort > b.sort ? 1 : -1);
        newFieldsGroups.forEach(group => group.fields = valuesFields.filter(field => field.group === group.code))
        return _.chunk(newFieldsGroups, 4)
    }, [])

    const prepareValueField = (fieldName: any) => {
        let res = Object.assign({value: patient[fieldName as keyof FieldsPatient]}, pickEditableFieldValues(fields[fieldName])) as ValueEditableField;
        switch (fieldName) {
            case "source":
            case 'personalGender':
                if (!!res.value) res.value = res.variants?.find(variant => variant.value === res.value);
                break;
            case 'branches':
                if (!!res.value) {
                    res.value = res.value.map((item: string | number) => String(item))
                    res.value = res.variants?.filter(variant => res.value.includes(String(variant.value)))
                }
                break;
            case 'passportIssuedDate':
                res.value = Helper.Date.formatDate(patient[fieldName as keyof FieldsPatient], 'DD-MM-YYYY');
                break;
            case 'passportSn':
                res.mask = '9999 999999'
                break;
            case 'inn':
                res.mask = '9999999999'
                break;
            default:
                break;
        }
        return res;
    }


    return (
        <>
            <TwoBoxes>
                {groups.map((childGroup, index) => {
                    return (
                        <BoxColumn key={"patient-group" + index}>
                            <Paper isHidden={false} minWidth="100%">
                                {childGroup.map(group => {
                                    return (
                                        <div key={group.code} style={{paddingBottom: '26px'}}>
                                            <Typography variant="min-title" padding="25px 20px 11px" children={group.name}/>
                                            <InfoBoxWrap>
                                                {group.fields.map(field => {
                                                    const editableField = {
                                                        ...pickEditableField(field),
                                                        onChange: value => loadingFromServer(),
                                                        values: {
                                                            [field.name]: prepareValueField(field.name)
                                                        },
                                                        text: !!patient[field.name as keyof FieldsPatient] ? patient[field.name as keyof FieldsPatient] : '-'
                                                    } as PEditableField;
                                                    let variant = 'editable' as variantEditableField;

                                                    switch (field.name) {
                                                        case 'fio':
                                                            editableField.text = prepareFio(patient);
                                                            editableField.values = {
                                                                lastName: prepareValueField('lastName'),
                                                                name: prepareValueField('name'),
                                                                secondName: prepareValueField('secondName')
                                                            }
                                                            break;
                                                        case 'email':
                                                            editableField.text = <TextEmail email={patient.email}/>
                                                            break;
                                                        case 'hasMissingTeeth':
                                                            editableField.text = patient[field.name] === null
                                                                ? 'Не указано' : patient[field.name] ? 'Да' : 'Нет';
                                                            break;
                                                        case 'refusedMarketingCalls':
                                                        case 'smsNotice':
                                                            editableField.text = patient[field.name] ? 'Да' : 'Нет';
                                                            break;
                                                        case 'phone':
                                                            editableField.text =
                                                                <TextPhone personalPhone={patient.personalPhone}
                                                                           phoneOwner={patient.phoneOwner as string | number | null}
                                                                           additionalPhone={patient.additionalPhone}/>
                                                            editableField.values = {
                                                                personalPhone: prepareValueField('personalPhone'),
                                                                phoneOwner: prepareValueField('phoneOwner'),
                                                                additionalPhone: prepareValueField('additionalPhone'),
                                                                samePhonePatients: {
                                                                    value: samePhonePatients,
                                                                    name: 'samePhonePatients',
                                                                    required: false
                                                                }
                                                            }
                                                            break;
                                                        case 'personalBirthday':
                                                            editableField.text = preparePersonalBirthday(patient.personalBirthday);
                                                            break;
                                                        case 'passportIssuedDate':
                                                            editableField.text = prepareDate(patient.passportIssuedDate as string);
                                                            break;
                                                        case 'personalGender' :
                                                        case 'source':
                                                            editableField.text = prepareEnum(patient[field.name], field)
                                                            break;
                                                        case 'address':
                                                            editableField.text = prepareAddress(patient)
                                                            editableField.values = {
                                                                personalCity: prepareValueField('personalCity'),
                                                                personalStreet: prepareValueField('personalStreet'),
                                                                personalHome: prepareValueField('personalHome'),
                                                                personalHousing: prepareValueField('personalHousing'),
                                                                personalApartment: prepareValueField('personalApartment'),
                                                            }
                                                            break;
                                                        case 'account':
                                                            variant = 'noEditable';
                                                            editableField.text =
                                                                <span style={{display: 'flex', alignItems: 'center'}}>
                                                        {Helper.Price.format(patient.account as any)} руб.
                                                        <Button size="sm"
                                                                variant="default"
                                                                children="Пополнить"
                                                                width="100px"
                                                                height="24px"
                                                                margin="0 0 0 24px"
                                                                onClick={() => showModal(<PaymentCreationForm patientId={patient.id} loadingFromServer={loadingFromServer}/>)}
                                                        />
                                                    </span>
                                                            break;
                                                        case 'firstVisit':
                                                            variant = 'noEditable';
                                                            editableField.text = prepareDate(patient.firstVisit as string);
                                                            break;
                                                        case 'familyId':
                                                            variant = 'noEditable';
                                                            editableField.text = <TextRelations relations={relatives}/>
                                                            break;
                                                        case 'discount':
                                                            if (!user.canDirector) {
                                                                variant = 'noEditable';
                                                            }
                                                            break;
                                                        case 'doctorsId':
                                                            variant = 'noEditable';
                                                            break;
                                                        case 'branches':
                                                            const values = patient[field.name] as any[] | undefined;
                                                            editableField.text = !!values
                                                                ? values.map((item: string| number) => {
                                                                    const findVariant = field.variants?.find(variant => String(variant.value) === String(item));
                                                                    return !!findVariant ? String(findVariant.label) : '-'
                                                                }).join(', ')
                                                                : '-'
                                                            break;
                                                        default:
                                                            break;
                                                    }

                                                    if (!user.canPatientPhone && field.name === 'phone') {
                                                        return null
                                                    }

                                                    if ((field.type === 'enum' || field.type === 'multiple_enum') && !!field.variants && field.variants.length < 2) {
                                                        return null
                                                    }

                                                    return <EditableField field={editableField}
                                                                          key={field.name}
                                                                          variant={variant}
                                                                          patientId={patient.id}/>
                                                })}
                                            </InfoBoxWrap>
                                        </div>
                                    )
                                })}
                            </Paper>
                            {(index === 1) && (
                                <Paper isHidden={false} minWidth="100%" margin="24px 0 0">
                                    <div style={{paddingBottom: '26px'}}>
                                        <Typography variant="min-title" padding="25px 20px 11px" children="Родственники"/>
                                        <InfoBoxWrap>
                                            {relatives.map(relation => {
                                                return <RelationPatient patient={relation}
                                                                        relationTypesTitles={relationTypesTitles}
                                                                        currentPatientId={patient.id}
                                                                        loadingFromServer={loadingFromServer}
                                                                        key={relation.id + 'relationPatient'}/>
                                            })}
                                            <Button children="Добавить"
                                                    variant="default"
                                                    margin="23px 0"
                                                    onClick={() => showModal(<AddRelation parentPatientId={patient.id}
                                                                                          relationTypesTitles={relationTypesTitles}
                                                                                          loadingFromServer={loadingFromServer}/>)}
                                            />
                                        </InfoBoxWrap>
                                    </div>
                                </Paper>
                            )}
                        </BoxColumn>
                    )
                })}
            </TwoBoxes>
        </>
    )
}


