import {useEffect, useState} from 'react';
import {GetDirectionsBalanceResult, IStateCashSalary} from "../types";
import useLoader from "../../../../components/Errors";
import useLoadData from "./useLoadData";
import useSubmit from "./useSubmit";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";
import Kkm from "../../../../common/server/kkm";
import {useSelector} from "react-redux";
import {KkmSetting} from "../../../../types";
import {KkmList} from "../../../../pages/Settings/Kkm/types";

interface Props {
    month: string
    paid: number
    salarySum: number
    fixedUserId?: number
    loadingFromServer?: () => void
    cashboxOptions: OptionSelect[]
    optionsBranches: OptionSelect[]
}

const useCashSalary = ({month, paid, salarySum, fixedUserId, loadingFromServer, cashboxOptions, optionsBranches}: Props) => {

    const users = useSelector(state => state.common.users)
    const currentUser = useSelector(state => state.user)

    const [data, setData] = useState<GetDirectionsBalanceResult | null>(null);

    const [kkmError, setKkmError] = useState('')

    const [userId, setUserId] = useState<number | undefined>(fixedUserId)

    const [kkmOptions, setKkmOptions] = useState<OptionSelect[]>([])

    const [kkmSetting, setKkmSetting] = useState<KkmSetting>({
        user: null,
        url: null,
        password: null,
        numberDevice: null,
        cashierName: null,
        cashierINN: null
    })

    const [state, setState] = useState<IStateCashSalary>({
        month: month,
        selectedCashbox: cashboxOptions[0],
        selectedKkm: kkmOptions.length > 1 ? undefined : kkmOptions[0],
        value: salarySum - paid,
        branch: optionsBranches.length > 1 ? undefined : optionsBranches[0],
        cashboxSum: {},
        kkmList: []
    })

    const [loader, setLoader] = useLoader();

    useLoadData({setState, setData, setKkmOptions, branchId: state.branch?.value})

    useEffect(() => {
        const getCashier = (kkm: KkmList) => {

            if (kkm.cashierId) {
                return {
                    cashierName: users[kkm.cashierId].fio,
                    cashierINN: users[kkm.cashierId].inn
                }
            } else {
                return {
                    cashierName: currentUser.fio,
                    cashierINN: currentUser.inn
                }
            }

        }

        const kkm = state.kkmList.find(kkm => kkm.id == state.selectedKkm?.value)

        !!kkm && setKkmSetting({
            user: kkm.login,
            url: kkm.url,
            password: kkm.password,
            numberDevice: kkm.numberDevice,
            ...getCashier(kkm)
        })

    }, [state.selectedKkm])

    useEffect(() => {
        if (state.selectedKkm) {
            if (!kkmSetting.cashierINN) {
                setKkmError('Не указан ИНН кассира')
            }
        } else {
            setKkmError('')
        }
    }, [kkmSetting])

    const handleSubmit = useSubmit({
        paid,
        userId,
        loadingFromServer,
        setLoader,
        setKkmError,
        state,
        data,
        kkmSetting
    })

    const maxSum = data?.account ?? 0;

    return {kkmError, data, userId, setUserId, state, setState, handleSubmit, loader, maxSum, kkmOptions}

};

export default useCashSalary;
