import React from "react";
import {TaskCallCenter, TasksDefault} from "../../types/common";
import Helper from "../../../../common/helpers/main";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";

interface Props {
    uniqPatient?: TasksDefault,
    task: TaskCallCenter
}
export const UniquePatient = ({uniqPatient, task}: Props) => {
    if (!!uniqPatient && uniqPatient.length > 1) {
        return (
            <LabelTask label="Пациент" children={Helper.getFullName(task.targetPerson)}/>
        )
    } else {
        return null
    }
}
