import {useEffect, useRef, useState} from "react";
import useOutsideClick from "../../../../common/hooks/useOutsideClick";
import Textarea from "react-textarea-autosize";
import InputMask from "react-input-mask";
import {IAutocomplete} from "../typesTextField";

interface Props {
    setFocus?: any,
    widthInput?: number | string,
    nameInput?: string,
    onFocus?: (value: string) => void | any,
    isNumber?: boolean,
    isTextArea?: boolean,
    mask?: string,
    dataAutocomplete?: IAutocomplete[],
    min?: number,
    max?: number,
    onChange: (value: string) => void,
}

const useTextField = (
    {
        setFocus,
        widthInput,
        nameInput,
        onFocus,
        isNumber,
        isTextArea,
        mask,
        dataAutocomplete,
        min,
        max,
        onChange
    }: Props) => {

    const wrapRef = useRef();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

    useEffect(() => {
        if (!!setFocus) setFocus(openPopup)
    }, [openPopup])

    useOutsideClick(wrapRef, () => setOpenPopup(false));

    const inputProps = {} as any

    if (!!widthInput) inputProps.width = widthInput;
    if (!!nameInput) inputProps.name = nameInput;
    if (!!onFocus) inputProps.onFocus = onFocus;
    if (isNumber) inputProps.onKeyDown = (evt: any) => {
        if (!(
            (evt.keyCode >= 48 && evt.keyCode <= 57) ||
            (evt.keyCode >= 96 && evt.keyCode <= 105) ||
            evt.keyCode === 9 || // tab
            evt.keyCode === 8 || // backspace
            evt.keyCode === 46 || // delete
            evt.keyCode === 27 || // ecs
            evt.ctrlKey === true || // все что вместе с ctrl
            evt.metaKey === true ||
            evt.altKey === true || // все что вместе с alt
            evt.shiftKey === true || // все что вместе с shift
            (evt.keyCode >= 112 && evt.keyCode <= 123) || // F1 - F12
            (evt.keyCode >= 35 && evt.keyCode <= 39) ||
            (evt.metaKey === true && evt.keyCode === 86) ||
            (evt.ctrlKey === true && evt.keyCode === 86) || // Ctrl+V | Shift+insert
            (evt.shiftKey === true && evt.keyCode === 45) ||
            evt.keyCode === 190 ||
            evt.keyCode === 189 ||
            evt.keyCode === 109
        )) {
            evt.preventDefault();
        }
    }
    if (isTextArea) {
        inputProps.as = Textarea;
        inputProps.name = 'text-area';
    }
    if (!!mask) {
        inputProps.as = InputMask;
        inputProps.mask = mask;
    }

    const onClickInputWrap = () => {
        if (!!dataAutocomplete) setOpenPopup(true);
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        let newValue = e.target.value;
        if (isNumber) {
            // Очистка введённой строки, чтобы в результате получилось корректное число.
            newValue = e.target.value
                // Удаляются все символы, кроме цифр, точки и минуса.
                .replace(/[^-\d.]/g, '')
                // Точка позволяется только одна. Минус тоже позволяется только один и только перед числом.
                .replace(/((-?\d*\.?\d*)(.*)?$)/, "$2")

            // изменение значения поля при условии того, что оно либо является пустым,
            // либо не выходит за пределы минимума и максимума
            if (
                newValue === '' || (
                    (min === undefined || +newValue >= min)
                    && (max === undefined || +newValue <= max)
                )
            ) {
                onChange(newValue)
            }

        } else {
            onChange(newValue);
        }
        if (!!dataAutocomplete) setOpenPopup(true);
    }

    return {
        wrapRef, inputProps, onClickInputWrap, handleChange,
        popup: {
            openPopup, setOpenPopup
        },
        password: {
            passwordVisible, setPasswordVisible
        }
    }
}

export default useTextField
