import React from "react";
import {NavLink} from 'react-router-dom';
import {WrapUINav} from "./styledUI";

const links = [
    {
        name: 'Layout',
        code: 'Layout',
        child: [
            {
                link: 'box',
                name: 'Box',
                code: 'Box'
            }
        ]
    },
    {
        name: 'Charts',
        code: 'Charts',
        child: [
            {
                link: 'chart-pie',
                name: 'Pie',
                code: 'chartPie'
            },
            {
                link: 'chart-line',
                name: 'Line',
                code: 'chartLine'
            },
            {
                link: 'chart-bar',
                name: 'Bar',
                code: 'chartBar'
            },
            {
                link: 'double-bar',
                name: 'DoubleBar',
                code: 'doubleBar'
            }
        ]
    },
    {
        name: 'Inputs',
        code: 'Inputs',
        child: [
            {
                link: 'button',
                name: 'Button',
                code: 'Button'
            },
            {
                link: 'buttonForOptions',
                name: 'ButtonForOptions',
                code: 'ButtonForOptions'
            },
            {
                link: 'checkbox',
                name: 'Checkbox',
                code: 'Checkbox'
            },
            {
                link: 'dateTime',
                name: 'DateTime',
                code: 'DateTime'
            },
            {
                link: 'radio',
                name: 'Radio',
                code: 'Radio'
            },
            {
                link: 'select',
                name: 'Select',
                code: 'Select'
            },
            {
                link: 'slider',
                name: 'Slider',
                code: 'Slider'
            },
            {
                link: 'switch',
                name: 'Switch',
                code: 'Switch'
            },
            {
                link: 'textField',
                name: 'TextField',
                code: 'TextField'
            },
            {
                link: 'timeCounter',
                name: 'TimeCounter',
                code: 'TimeCounter'
            },
            {
                link: 'calendarField',
                name: 'CalendarField',
                code: 'CalendarField'
            },
            {
                link: 'counter',
                name: 'Counter',
                code: 'Counter'
            },
        ]
    },
    {
        name: 'Navigation',
        code: 'Navigation',
        child: [
            {
                link: 'bottomNavigation',
                name: 'BottomNavigation',
                code: 'BottomNavigation'
            },
            {
                link: 'breadcrumbs',
                name: 'Breadcrumbs',
                code: 'Breadcrumbs'
            },
            {
                link: 'drawer',
                name: 'Drawer',
                code: 'Drawer'
            },
            {
                link: 'link',
                name: 'Link',
                code: 'Link'
            },
            {
                link: 'menu',
                name: 'Menu',
                code: 'Menu'
            },
            {
                link: 'stepper',
                name: 'Stepper',
                code: 'Stepper'
            },
            {
                link: 'tabs',
                name: 'Tabs',
                code: 'Tabs'
            },
        ]
    },
    {
        name: 'Surfaces',
        code: 'Surfaces',
        child: [
            {
                link: 'appBar',
                name: 'AppBar',
                code: 'AppBar'
            },
            {
                link: 'card',
                name: 'Card',
                code: 'Card'
            },
            {
                link: 'expansionPanel',
                name: 'ExpansionPanel',
                code: 'ExpansionPanel'
            },
            {
                link: 'paper',
                name: 'Paper',
                code: 'Paper'
            },
        ]
    },
    {
        name: 'Feedback',
        code: 'Feedback',
        child: [
            {
                link: 'backdrop',
                name: 'Backdrop',
                code: 'Backdrop'
            },
            {
                link: 'dialog',
                name: 'Dialog',
                code: 'Dialog'
            },
            {
                link: 'progress',
                name: 'Progress',
                code: 'Progress'
            },
            {
                link: 'snackbar',
                name: 'Snackbar',
                code: 'Snackbar'
            },
        ]
    },
    {
        name: 'DataDisplay',
        code: 'DataDisplay',
        child: [
            {
                link: 'alert',
                name: 'Alert',
                code: 'Alert'
            },
            {
                link: 'avatar',
                name: 'Avatar',
                code: 'Avatar'
            },
            {
                link: 'badge',
                name: 'Badge',
                code: 'Badge',
                disabled: true
            },
            {
                link: 'chip',
                name: 'Chip',
                code: 'Chip'
            },
            {
                link: 'divider',
                name: 'Divider',
                code: 'Divider'
            },
            {
                link: 'icons',
                name: 'Icons',
                code: 'Icons'
            },
            {
                link: 'label',
                name: 'Label',
                code: 'Label'
            },
            {
                link: 'list',
                name: 'List',
                code: 'List'
            },
            {
                link: 'table',
                name: 'Table',
                code: 'Table'
            },
            {
                link: 'tooltip',
                name: 'Tooltip',
                code: 'Tooltip'
            },
            {
                link: 'typography',
                name: 'Typography',
                code: 'Typography'
            },
            {
                link: 'tag',
                name: 'Tag',
                code: 'Tag'
            },
        ]
    },
    {
        name: 'Utils',
        code: 'Utils',
        child: [
            {
                link: 'clickAwayListener',
                name: 'ClickAwayListener',
                code: 'ClickAwayListener'
            },
            {
                link: 'cssBaseline',
                name: 'CssBaseline',
                code: 'CssBaseline'
            },
            {
                link: 'modal',
                name: 'Modal',
                code: 'Modal'
            },
            {
                link: 'noSSR',
                name: 'NoSSR',
                code: 'NoSSR'
            },
            {
                link: 'popover',
                name: 'Popover',
                code: 'Popover'
            },
            {
                link: 'popper',
                name: 'Popper',
                code: 'Popper'
            },
            {
                link: 'portal',
                name: 'Portal',
                code: 'Portal'
            },
            {
                link: 'textareaAutosize',
                name: 'TextareaAutosize',
                code: 'TextareaAutosize'
            },
            {
                link: 'transitions',
                name: 'Transitions',
                code: 'Transitions'
            },
            {
                link: 'useMediaQuery',
                name: 'UseMediaQuery',
                code: 'UseMediaQuery'
            },
        ]
    },
    {
        name: 'Lab',
        code: 'Lab',
        child: [
            {
                link: 'aboutTheLab',
                name: 'AboutTheLab',
                code: 'AboutTheLab'
            },
            {
                link: 'autocomplete',
                name: 'Autocomplete',
                code: 'Autocomplete'
            },
            {
                link: 'pagination',
                name: 'Pagination',
                code: 'Pagination'
            },
            {
                link: 'rating',
                name: 'Rating',
                code: 'Rating'
            },
            {
                link: 'skeleton',
                name: 'Skeleton',
                code: 'Skeleton'
            },
            {
                link: 'speedDial',
                name: 'SpeedDial',
                code: 'SpeedDial'
            },
            {
                link: 'toggleButton',
                name: 'ToggleButton',
                code: 'ToggleButton'
            },
            {
                link: 'treeView',
                name: 'TreeView',
                code: 'TreeView'
            },
        ]
    },
];

export default function UINav() {
    return (
        <WrapUINav>
            {links.map((item: any) => {
                return (
                    <li key={item.code}>
                        <span>{item.name}</span>
                        <ul>
                            {item.child.map((elem: any) => {
                                return (
                                    <li key={elem.code}>
                                        <NavLink
                                            exact
                                            to={'/ui/' + elem.link}
                                            activeClassName="active"
                                        >
                                            <span>{elem.name}</span>
                                        </NavLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </li>
                )
            })}
        </WrapUINav>
    )
}
