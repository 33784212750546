import React from 'react';
import PropTypes from 'prop-types';
import './Templates.scss';
import Button from "../../components-ui/Inputs/Button/Button";
import Helper from "../../common/helpers/main";
import GroupsTree from "../../components/TableWithGroupsTree/GroupsTree";
import {defineParameters} from "../Parameters/helperParameters";
import TextField from "../../components-ui/Inputs/TextField/TextField";

export default class Templates extends React.Component {
    static propTypes = {
        elementsList: PropTypes.array,
        groups: PropTypes.array,
        selectedField: PropTypes.string,
        addFromTemplate: PropTypes.func,
        relatedTemplates: PropTypes.object,
        visible: PropTypes.bool
    };

    state = {
        searchQuery: '',
        selectedElementId: 0,
        focusedElement: null,
        elementsList: this.props.elementsList,
        element: null,
        isRelated: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedField, relatedTemplates} = this.props;
        if (prevProps.selectedField !== selectedField) {
            let isRelated = false;
            if (relatedTemplates !== undefined) {
                if (relatedTemplates[selectedField] !== undefined) {
                    if (relatedTemplates[selectedField].length) {
                        isRelated = true;
                    }
                }
            }
            this.setState({isRelated});
        }
    }

    render() {
        const {groups, selectedField, relatedTemplates, visible} = this.props;
        const {element} = this.state;
        if (groups === null || !visible) return null;

        let nameGroup = null;
        let isElement = !!Helper.clone(element);
        let elementsList = this.state.elementsList.filter(template => template.field === selectedField);
        let newText = [];

        if (element !== null) {
            this.forEachGroup(groups, group => {
                if (group.id === element.groupId) nameGroup = group.name;
            });

            newText = defineParameters(element.text).map((item) => {
                if (typeof item === "string") {
                    return item
                } else {
                    return <a key={item.name}>_ _ _ _ </a>
                }
            });

            isElement = elementsList.some(template => element.id === template.id);
        }

        // const isCheckboxGroup = !!relatedTemplates ? !!relatedTemplates[selectedField] ? !!relatedTemplates[selectedField].length : false : false;
        let isCheckboxGroup = false;

        if (!!relatedTemplates) {
            if (!!relatedTemplates[selectedField]) {
                if (!!relatedTemplates[selectedField].length) {
                    isCheckboxGroup = true;
                }
            }
        }
        return (
            <div className="templates--sticky-wrap">
                <div className="card__box templates__wrap">
                    <div className="card-body card-body--history">
                        <span className="card__subtitle">Шаблоны</span>
                        <div className="box">
                            <div className="box__row">
                                <TextField onChange={this.handleChangeTemplates.bind(this)}
                                           placeholder='Поиск шаблона'
                                           value={this.state.searchQuery}
                                           isSearch={true}
                                           widthInput="100%"

                                />
                                <Button variant='standard'
                                        size="md"
                                        margin="0 0 0 20px"
                                        children="Сбросить"
                                        onClick={() => {
                                            this.setState({
                                                searchQuery: '',
                                                elementsList: this.props.elementsList,
                                                element: null,
                                                selectedElementId: 0
                                            })
                                        }}/>
                            </div>
                            <GroupsTree groups={this.getGroupsList(elementsList)}
                                        isFocused={!!this.state.focusedElement && this.state.focusedElement.type === 'group'}
                                        scrollHeight={260}
                                        selectedElementsIds={[this.state.selectedElementId]}
                                        elements={elementsList}
                                        isCheckboxGroup={isCheckboxGroup}
                                        checkCheckboxGroup={isCheckboxGroup ? this.state.isRelated : false}
                                        onClickCheckboxGroup={this.onChangeCheckboxRelated}
                                        onSelect={this.onSelectGroup}
                                        isTemplate={true}
                                        dblclickElement={selectedItem => this.props.addFromTemplate(selectedItem)}
                                        relatedTemplates={!!relatedTemplates ? relatedTemplates[selectedField] : []}
                                        sort={false}
                                        isAddNumburing={true}
                            />
                            {isElement &&
                            <div className="box-templates">
                                <h4>{nameGroup}</h4>
                                <div className="box-templates__element"
                                     onClick={() => this.props.addFromTemplate(this.state.element)}>
                                <span>
                                    {newText}
                                </span>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onChangeCheckboxRelated = (isRelated) => this.setState({isRelated});

    onSelectGroup = (selectedItem, selectedItemsIds, thisIsElement) => {
        if (thisIsElement) {
            this.setState({
                selectedElementId: selectedItem.id,
                focusedElement: {
                    type: 'group',
                    code: selectedItem.id
                },
                element: selectedItem
            });
        }
    };

    handleChangeTemplates(value) {
        let elementsList = Helper.clone(this.props.elementsList);
        if (value.length > 0) {
            elementsList = elementsList.filter(element => {
                if (element.name !== undefined) {
                    return (String(element.name).toLowerCase().indexOf(value.toLowerCase()) !== -1)
                }
                return null
            });
            elementsList.forEach(element => {
                if (element.name.toLowerCase() === value.toLowerCase()) {
                    return this.setState({selectedElementId: element.id, element});
                } else {
                    this.setState({selectedElementId: 0, element: null})
                }
            });
        }

        this.setState({searchQuery: value, elementsList})
    }

    forEachGroup(groups, handler) {
        groups.forEach(group => {
            handler(group);
            if (!!group.groups) {
                this.forEachGroup(group.groups, handler);
            }
        })
    }

    getGroupsList(rows) {
        const rowsGroupsIds = {};
        rows.forEach((row) => rowsGroupsIds[row.groupId] = true);
        return this.filterRowGroup(Helper.clone(Object.values(this.props.groups)), (group) => rowsGroupsIds[group.id] === true);
    }

    filterRowGroup(groups, handler) {
        return groups.filter((group) => {
            if (group.groups) group.groups = Helper.clone(this.filterRowGroup(group.groups, handler));
            return (group.groups && group.groups.length > 0) || handler(group)
        })
    }

}
