import {Service, Services} from "../../containers/TreatmentPlansConstructor/types";
import {Rules, ServicesQuantity} from "./interfaces";
import _ from "underscore";
import Obj from "../helpers/object";

export default class RelationsResolver {
    protected rules: Rules;
    protected services: Services;

    constructor(rules: Rules, services: Services|Service[]) {
        this.rules = rules;
        this.services = Array.isArray(services) ? _.indexBy(services, 'id'): services;
    }

    /**
     * Возвращает услуги, привязанные к указанной услуге
     * @param serviceId
     * @return объект с id привязанных услуг и их неоходимым количеством
     */
    public getRelatedServices(serviceId: number): ServicesQuantity {
        const service = this.services[serviceId];

        if (service.fillerRule) {
            const quantityRule = this.rules.quantityRules[service.fillerRule];
            return quantityRule.config.quantity;
        }

        const relatedServices = [];

        if (service.requiredAdditionalServices) {
            relatedServices.push(...service.requiredAdditionalServices)
        }

        if (service.relatedAdditionalServices) {
            relatedServices.push(...service.relatedAdditionalServices)
        }

        if (service.directions.length > 0) {
            const direction = service.directions[0];

            if (this.rules.directionsRequired) {
                const requiredForDirection = this.rules.directionsRequired[direction];
                if (requiredForDirection) {
                    relatedServices.push(...requiredForDirection);
                }
            }

            if (this.rules.directionsRelated) {
                const requiredForDirection = this.rules.directionsRelated[direction];
                if (requiredForDirection) {
                    relatedServices.push(...requiredForDirection);
                }
            }
        }

        // Очищаем relatedServices от массивов (с id услуг, привязанных по логике ИЛИ).
        // От каждого массива берём первый элемент (как приоритетный)
        const relatedServicesIds = relatedServices.map((service: number|number[]) => {
            return Array.isArray(service) ? service[0] : service;
        });

        return Obj.fill(relatedServicesIds, 1) as ServicesQuantity;
    }

    public getCommonRequiredServices(): ServicesQuantity {
        const requiredServices = this.rules.required ? this.rules.required : [];
        return Obj.fill(requiredServices, 1) as ServicesQuantity;
    }
}
