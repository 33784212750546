import React, {useEffect, useMemo} from 'react';
import ReactSelect, {components} from 'react-select';
import {PSelect} from './typesSelect';
import styled, {CSSProperties, useTheme} from 'styled-components';
import {IColors, ITheme} from '../../../common/Theme/Theme';
import SVG from '../../../common/helpers/SVG';
import Helper from '../../../common/helpers/main';
import Box from '../../Layout/Box';

const DropdownIndicator = (props: any) => {
    let content = <SVG name='arrow-select' />;

    if (props.selectProps.isFixIndicator) {
        content = (
            <Box position='absolute' width='100%' height='100%' positionOffsets={{top: '0', left: '0'}} display='flex'
                 justifyContent='flex-end' alignItems='center'>
                <Box padding='0 10px 0 0'>
                    <SVG name='arrow-select' />
                </Box>
            </Box>
        );
    }

    return (
        <components.DropdownIndicator {...props}>
            {content}
        </components.DropdownIndicator>
    );
};

const OptionColor = styled.div.attrs({className: 'OptionColor'})<any>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  flex-shrink: 0;
  background: ${props => props.bgColor};
  margin-right: 10px;
`;

const Option = (props: any) => {
    return (
        <components.Option {...props}>
            <OptionColor bgColor={props.data.color} />
            {props.children}
        </components.Option>
    );
};

const ValueContainer = (props: any) => {
    const value = props.getValue()[0];
    return (
        <components.ValueContainer {...props}>
            {!!value && <OptionColor bgColor={value.color} />}
            {props.children}
        </components.ValueContainer>
    );
};

const LabelSelect = styled.div.attrs({className: 'LabelSelect'})<{colorLabel?: keyof IColors}>`
  margin-right: 10px;
  color: ${props => props.theme.colors[props.colorLabel || 'text']}
`;

const LabelContainer = (props: any, propsSelect: PSelect) => (
    <components.ValueContainer {...props}>
        <LabelSelect colorLabel={propsSelect.colorLabel}>
            {propsSelect.label}
        </LabelSelect>
        {props.children}
    </components.ValueContainer>
);

const Select = React.forwardRef((props: PSelect, ref: any) => {
    const {
        variant = 'default',
        placeholder = 'Выберите из списка...',
        widthSelect = '250px',
        colorSelect = 'default',
        sizeSelect = 'md',
    } = props;
    const theme = useTheme() as ITheme;
    const customStyles = useMemo(() => {
        return {
            container: (provided: any, state: any) => {
                const commonStyle = {} as {[key: string]: any};
                if (!!props.minWidth) commonStyle.minWidth = props.minWidth;
                if (!!props.maxWidth) commonStyle.maxWidth = props.maxWidth;
                return {
                    ...provided,
                    ...commonStyle,
                    zIndex: state.isFocused ? '14' : '2',
                    width: variant === 'link' || variant === 'string' ? 'max-content' : widthSelect,
                    margin: !!props.marginSelect ? props.marginSelect : '0',
                };
            },
            menuPortal: (provided: any) => ({
                ...provided,
                zIndex: props.isPortalled ? '300' : '1',
            }),
            control: (provided: any, state: any) => {
                const height = () => {
                    if (!!props.height) {
                        return props.height;
                    } else {
                        switch (sizeSelect) {
                            case 'sm':
                                return '28px';
                            case 'sm-md':
                                return '30px';
                            case 'md':
                            default:
                                return '38px';
                        }
                    }
                };
                const background = () => {
                    if (state.isDisabled) return theme.colors.secondary;
                    switch (colorSelect) {
                        case 'white':
                            return theme.colors.white;
                        case 'default':
                        default:
                            return theme.gradient.default;
                    }
                };
                const borderColor = () => {
                    if (state.selectProps.isError) {
                        return theme.colors.danger;
                    } else if (state.isFocused) {
                        return theme.colors.borderGrayActive;
                    } else {
                        return theme.colors.borderGray;
                    }
                };
                const fontSize = () => {
                    switch (sizeSelect) {
                        case 'sm':
                        case 'sm-md':
                            return '14px';
                        default:
                            return provided.fontSize;
                    }
                };

                const commonStyle = {} as {[key: string]: any};
                if (!!props.minWidth) commonStyle.minWidth = props.minWidth;
                if (!!props.maxWidth) commonStyle.maxWidth = props.maxWidth;
                return {
                    ...provided,
                    ...commonStyle,
                    width: variant === 'link' || variant === 'string' ? 'max-content' : widthSelect,
                    fontFamily: '"Open Sans", "Arial", sans-serif',
                    borderColor: borderColor(),
                    cursor: 'pointer',
                    height: variant === 'link' || variant === 'string' ? 'auto' : state.isMulti ? 'auto' : height(),
                    paddingBottom: '0',
                    boxSizing: 'content-box',
                    fontSize: fontSize(),
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: '1.5',
                    letterSpacing: 'normal',
                    border: variant === 'link' || variant === 'string' ? 'none' : provided.border,
                    background: variant === 'link' || variant === 'string' ? 'none' : background(),
                    boxShadow: state.selectProps.isError ? `0 0 0 0.2rem rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.25)` : 'none',
                    minHeight: variant === 'link' || variant === 'string' ? 'auto' : height(),
                    '&:hover': {
                        borderColor: theme.colors.borderGrayActive,
                    },
                };
            },
            valueContainer: (provided: any, state: any) => {
                return {
                    ...provided,
                    padding: variant === 'link' || variant === 'string' ? '0' : '0 0 0 18px',
                    whiteSpace: state.isMulti ? 'pre-wrap' : 'nowrap',
                    flexWrap: state.isMulti ? 'wrap' : 'nowrap',
                };
            },
            singleValue: (provided: any) => {
                const fontSize = () => {
                    switch (sizeSelect) {
                        case 'sm':
                        case 'sm-md':
                            return '14px';
                        default:
                            return provided.fontSize;
                    }
                };
                const commonProps = {} as {[key: string]: any};
                if (!!props.maxWidth) commonProps.maxWidth = props.maxWidth;
                return {
                    ...provided,
                    ...commonProps,
                    position: 'relative',
                    transform: 'translate(0)',
                    top: '0',
                    display: 'inline',
                    verticalAlign: 'middle',
                    margin: '0',
                    minWidth: variant === 'link' || variant === 'string' ? 'max-content' : !!props.minWidth ? `calc(${props.minWidth} - 50px)` : `calc(${widthSelect} - 50px)`,
                    color: variant === 'link' ? theme.colors.info : provided.color,
                    borderBottom: variant === 'link' ? `1px dashed ${theme.colors.info05}` : provided.borderBottom,
                    fontSize: fontSize(),
                    '&:hover': {
                        color: variant === 'link' ? theme.colors.infoHover : provided.color,
                        borderColor: variant === 'link' ? theme.colors.infoHover : provided.borderColor,
                    },
                };
            },
            placeholder: (provided: any) => {
                return {
                    ...provided,
                    color: theme.colors.textGray,
                    left: !!props.label ? props.widthLabel || '55px' : 'auto',
                    whiteSpace: 'nowrap',
                    width: !!props.label ? `calc(100% - 25px - ${props.widthLabel || '55px'})` : 'calc(100% - 25px)',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                };
            },
            indicatorSeparator: (provided: CSSProperties) => {
                return {
                    ...provided,
                    display: 'none',
                };
            },
            dropdownIndicator: (provided: CSSProperties) => {
                return {
                    ...provided,
                    color: theme.colors.textDarkGray,
                    padding: '0 12px 0 0',
                    '& > div > div': {
                        display: 'flex',
                        '& svg': {
                            fill: theme.colors.textDarkGray,
                            width: '10px',
                            height: '8px',
                        },
                    },
                };
            },
            menu: (provided: CSSProperties) => {
                const commonStyle = {} as {[key: string]: any};
                if (!!props.minWidth) commonStyle.minWidth = props.minWidth;
                if (!!props.maxWidth) commonStyle.maxWidth = props.maxWidth;
                return {
                    ...provided,
                    ...commonStyle,
                    width: !!props.widthMenu ? props.widthMenu : widthSelect,
                    boxShadow: theme.shadow.defaultModalLight,
                    right: variant === 'link' || variant === 'string' ? '0' : 'auto',
                };
            },
            menuList: (provided: CSSProperties) => {
                return {
                    ...provided,
                    padding: '8px 0',
                };
            },
            option: (provided: CSSProperties, state: any) => {
                const bgColor = () => {
                    if (state.isDisabled) {
                        return theme.colors.whiteDark;
                    } else if (state.isSelected) {
                        return theme.colors.blue;
                    } else if (state.isFocused) {
                        return theme.colors.secondary;
                    } else {
                        return theme.colors.white;
                    }
                };
                const fontSize = () => {
                    switch (sizeSelect) {
                        case 'sm':
                        case 'sm-md':
                            return '14px';
                        default:
                            return provided.fontSize;
                    }
                };
                return {
                    ...provided,
                    padding: '6px 12px 10px 16px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
                    color: state.isDisabled ? theme.colors.textGray : theme.colors.text,
                    background: bgColor(),
                    fontSize: fontSize(),
                    '&:not(:last-of-type)': {
                        borderBottom: `1px solid ${theme.colors.borderGray}`,
                    },
                    '&:active': {
                        background: bgColor(),
                    },
                };
            },
            multiValueLabel: (provider: any) => {
                return {
                    ...provider,
                    background: theme.colors.bgLightBlue,
                };
            },
            multiValueRemove: (provider: any) => {
                return {
                    ...provider,
                    background: theme.colors.bgLightBlue,
                    '&:hover': {
                        background: theme.colors.bgLightBlue,
                        color: theme.colors.danger,
                    },
                };
            },
        };
    }, [props.options]);
    const components = useMemo(() => {
        const commonComponents = {DropdownIndicator: variant === 'link' ? null : DropdownIndicator};
        const colorComponents = variant === 'colored' ? {Option, ValueContainer} : {};
        const labelComponents = !!props.label ? {ValueContainer: (state: any) => LabelContainer(state, props)} : {};
        return Object.assign(commonComponents, colorComponents, labelComponents);
    }, []);

    useEffect(() => {

    }, []);

    return <ReactSelect {...props}
                        menuPortalTarget={props.isPortalled ? document.body : undefined}
                        //menuIsOpen
        //@ts-ignore
                        styles={customStyles}
                        components={components}
                        noOptionsMessage={() => 'Нет вариантов'}
                        placeholder={placeholder}
                        ref={ref}
                        isSearchable={false} />;
});

export default Select;
