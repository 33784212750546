import React from "react";
import {JawButtonWrap, JawButton} from "./styledButtonJaw";
import SVG from "../../../../common/helpers/SVG";
import { PButtonJaw } from "./typesButtonJaw";
import {useDentalCard} from "../../DentalRecords";
import {SDentalCard} from "../../types";
import Helper from "../../../../common/helpers/main";
import {INITIAL_SURVEY} from "../../../../common/constants/DentalRecords";

export default function ButtonJaw({jaw} : PButtonJaw) {
    const {onClickButtonJaw, config, onClickButtonJawChild, parentComponentName, editField} = useDentalCard() as SDentalCard;
    const isChildToothJaw = config[jaw].some(tooth => !Helper.TeethMap.isChildTooth(tooth.number));
    return (
        <JawButtonWrap jaw={jaw} editField={editField}>
            <JawButton jaw={jaw} onClick={() => onClickButtonJaw(jaw)}>
                <SVG name={`teeth/${jaw}-jaw`} className="tooth-card-svg" />
                <div className="tooth-card-text">
                    {jaw === 'upper' ? 'в.ч.' : 'н.ч.'}
                </div>
            </JawButton>
            {parentComponentName === INITIAL_SURVEY && (
                <SVG name={`teeth/${isChildToothJaw ? 'sm-' : ''}tooth-standard`}
                     onClick={() => onClickButtonJawChild(jaw)}
                     className="tooth-standard"/>
            )}
        </JawButtonWrap>
    )
}
