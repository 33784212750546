import React, {forwardRef} from "react";
import styled from "styled-components";
import {PropsListItem} from "./types";

interface PropsList {
    flexDirection?: 'column' | 'row'
    height?: string
}

export const List = styled.ul<PropsList>`
  padding: 8px 0;
  display: flex;
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
  list-style: none;
  margin: 0;
  background-color: ${props => props.theme.colors.white};
  border-radius: inherit;
  width: 100%;
  flex-wrap: wrap;
  ${props => !!props.height &&
          `height: ${props.height};
          flex-wrap: nowrap;
          `};
`;

const Item = styled.li<PropsListItem>`
  display: flex;
  position: relative;
  ${props => !!props.width && `width: ${props.width};`};
`;

const fontSizeItem = (props: PropsListItem) => {
    switch (props.size) {
        case "sm":
            return '14px'
        case "md":
        default:
            return '16px'
    }
}
const paddingItem = (props: PropsListItem) => {
    switch (props.size) {
        case "sm":
            return '3px 16px 5px'
        case "md":
        default:
            return '7px 16px 8px'
    }
}

const ItemSpan = styled.span<PropsListItem>`
  display: block;
  padding: ${paddingItem};
  width: 100%;
  font-size: ${fontSizeItem};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  transition: .15s;
  cursor: ${props => props.cursor};
  background-color: ${props => props.active ? props.theme.colors.secondary : props.isHover ? props.theme.colors.blue : 'transparent'};
  ${props => !!props.onClick && `
        color: ${props.theme.colors.text};
        text-align: left;
        &:hover {
            background-color: ${props.theme.colors.blue};
        }
        &:active {
            background-color: ${props.theme.colors.secondary};
        }
    `}
`;


export const SNoResultListItem = styled.span.attrs({className: 'SNoResultListItem'})`
  color: ${props => props.theme.colors.textGray}
`

export const ListItem = forwardRef(({
                                        size,
                                        onClick,
                                        children,
                                        active,
                                        cursor = 'pointer',
                                        isHover,
                                        ...props
                                    }: PropsListItem, ref: any) => {
    return (
        <Item {...props} ref={ref}>
            <ItemSpan isHover={isHover} cursor={cursor} size={size} active={active} children={children}
                      onClick={(e: any) => {
                          if (!!onClick) onClick(e, children)
                      }}/>
        </Item>
    )
})
