import React from "react";
import {WrapContent} from "../../../styledUI";
import Box from "../../../../../components-ui/Layout/Box";

export default function BoxPage() {
    return (
        <>
            <h1>Box</h1>
            <WrapContent>
                <Box children="sasa" as="span" />
            </WrapContent>
        </>
    )
}
