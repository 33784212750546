import React, {Dispatch, SetStateAction} from 'react';
import Helper from "../../../../../common/helpers/main";
import useQuantityHiddenTask from "../hooks/useQuantityHiddenTask";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import { SContainerItem } from '../../../style';
import {GroupsButtonName, NoticesQuantity} from "../types";

interface Props {
    showHiddenButtons: boolean
    setShowHiddenButtons: Dispatch<SetStateAction<boolean>>
    noticesQuantity: NoticesQuantity
    groupsButtonName: GroupsButtonName
}

const ShowButton = ({setShowHiddenButtons, showHiddenButtons, noticesQuantity, groupsButtonName}: Props) => {
    const quantityHiddenTask = useQuantityHiddenTask({noticesQuantity, groupsButtonName});
    const tasksWord = Helper.getCountString(quantityHiddenTask, ['задачу', 'задачи', 'задач']);
    return (
        <SContainerItem margin="0 0 8px">
            <Button variant="secondary" noShadow={true} height="24px" width="100%"
                    size="sm-standard"
                    fontSize="14px"
                    onClick={() => setShowHiddenButtons(!showHiddenButtons)}
                    colorText="textDarkGray"
                    children={showHiddenButtons ? "Скрыть задачи" : `Показать ещё ${tasksWord}`}/>
        </SContainerItem>
    );
};

export default ShowButton;
