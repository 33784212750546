import {Dispatch, SetStateAction} from "react";
import {ButtonConsultations, StateConsultations, TabConsultations} from "../types";

interface Props {
    setState: Dispatch<SetStateAction<StateConsultations>>
}

const useHandlersConsultations = ({setState}: Props) => {
    return {
        handleCurrentTab: (currentTab: TabConsultations) => setState(prevState => ({...prevState, currentTab})),
        handleButton: (currentButton: ButtonConsultations) => setState(prevState => ({...prevState, currentButton})),
    }
}

export default useHandlersConsultations
