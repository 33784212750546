import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth53Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 16.98 24"} width={width || "12"} height={height || "17"} ref={ref}>
            <path d="M10.14,24C8.48,24,1.92,19.06.31,16.27c-.84-1.48,0-8.56,2.88-12.94C4.66,1.12,6.34,0,8.2,0h.31c2.28.15,4.19,2.76,5.65,5.83v0A43.13,43.13,0,0,1,17,14.26C16.66,20.39,11.69,24,10.16,24Z"/>
        </WrapIcon>
    );
});
