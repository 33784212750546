import React, {useCallback} from 'react';
import {PieChart, Pie, Cell} from 'recharts';
import Helper from "../../../common/helpers/main";

const defaultColors = ['#447ce6', '#fdbc00', '#aa70cb']

export interface PieDataItem {
    code: string,
    value: any
}

interface Props {
    data: PieDataItem[]
    colors?: { [key: string]: string }
    activeCell: string | number | null
    setActiveCell: (activeCell: string | number) => void
    hoverIndex: number
    setActiveIndex: (activeIndex: number) => void,
    isClickable?: boolean
}

const ChartPie = (
    {
        data,
        activeCell,
        setActiveCell,
        setActiveIndex,
        hoverIndex,
        colors,
        isClickable = true,
    }: Props) => {

    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    const onPieLeave = useCallback(() => {
        setActiveIndex(-1)
    }, [setActiveIndex])

    return (
        <span onMouseLeave={onPieLeave} >
            <PieChart width={240} height={180}>
                <Pie data={data}
                     dataKey="value"
                     activeIndex={hoverIndex}
                     onMouseEnter={onPieEnter}
                     cx="50%"
                     cy="50%"
                     innerRadius={75}
                     outerRadius={90}
                     startAngle={90}
                     endAngle={-270}
                     fill="#82ca9d"
                     paddingAngle={data.length > 1 ? 2 : 0}>
                {data.map((entry, index) => {
                    const isActiveCell = activeCell === entry.code
                    let fill = defaultColors[index % defaultColors.length];
                    if (!!colors && !!colors[entry.code]) fill = colors[entry.code];

                    return <Cell key={`cell-${index}`}
                                 fill={fill}
                                 onClick={() => {
                                     if (isClickable) setActiveCell(entry.code)
                                 }}
                                 cursor={isActiveCell ? "default" : "pointer"}/>
                })}
            </Pie>
            <Pie data={data}
                 dataKey="value"
                 activeIndex={hoverIndex}
                 onMouseEnter={onPieEnter}
                 cx="50%"
                 cy="50%"
                 startAngle={90}
                 endAngle={-270}
                 outerRadius={73}
                 paddingAngle={data.length > 1 ? 2 : 0}
                 fill="#8884d8">
                {data.map((entry, index) => {
                    let fill = 'transparent'
                    const isActiveCell = activeCell === entry.code
                    let currentFill = defaultColors[index % defaultColors.length];
                    if (!!colors && !!colors[entry.code]) currentFill = colors[entry.code];
                    if (isActiveCell) {
                        fill = `rgba(${Helper.Color.hexToRgb(currentFill)}, 0.75)`
                    } else {
                        if (hoverIndex === index) fill = `rgba(${Helper.Color.hexToRgb(currentFill)}, 0.5)`
                    }
                    return <Cell key={`cell2-${index}`}
                                 fill={fill}
                                 cursor={isClickable ? isActiveCell ? "default" : "pointer" : 'default'}
                                 onClick={() => {
                                     if (isClickable) setActiveCell(entry.code)
                                 }}/>
                })}
            </Pie>
        </PieChart>
        </span>

    );
};

export default ChartPie;
