import {useEffect, useRef, useState} from 'react';
import useSearchUserList from "./useSearchUserList";
import useDebounce from "./useDebounce";
import useDisplayPatientList from "../../containers/PatientField/hooks/useDisplayPatientList";
import {PatientValues} from "../../containers/PatientField";
import Helper from "../helpers/main";

/**
 * set patientId to state
 */
type useLoadPatientProp =  (arg0: (number | undefined)) => void

interface Props {
    setUserId: useLoadPatientProp
    initialUser?: string
}

const useLoadUser = ({setUserId, initialUser}: Props) => {
    const ref = useRef(null);
    const [user, setUser] = useState<string>('')
    const debouncedValue = useDebounce(user, 500);
    const [displayPatientList, setDisplayPatientList] = useDisplayPatientList(ref);

    let [patientList, loaderPatientList] = useSearchUserList({value: debouncedValue, nameField: 'searchQuery', isFilter: true});

    const onChangeSearchBar = (val: string): void => {
        if (val.length >= 2) {
            if (!displayPatientList) setDisplayPatientList(true)
        } else {
            if (displayPatientList) setDisplayPatientList(false)
        }
        setUser(val)
    }

    const onResetSearch = () => {
        setUserId(undefined)
        setUser('')
    }

    const onClickPatient = (patient: PatientValues): void => {
        setUserId(patient.id)
        setUser(Helper.getFullName(patient))
        setDisplayPatientList(false)
    }

    useEffect(() => {
        if (initialUser) {
            setUser(initialUser)
        }
    }, [])

    return {
        user,
        setUser,
        ref,
        patientList,
        displayPatientList,
        loaderPatientList,
        onChangeSearchBar,
        onClickPatient,
        onResetSearch
    }
};

export default useLoadUser;
