import React from "react";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Table from "../../../../components/Table/Table";
import ServerCommand from "../../../../common/server/server-command";
import Helper from "../../../../common/helpers/main";
import moment from "moment/moment"
import "moment/min/locales.min"
import PopupWriteOff from "./PopupWriteOff";
import ViewWriteOff from "./ViewWriteOff";
import TextField from "../../../../components-ui/Inputs/TextField/TextField";
import {DialogsManagerContext} from "../../../../containers/DialogsManager/DialogsManager";

const patientsColumns = [
    {
        title: '№',
        code: 'id',
        searchable: true
    },
    {
        title: 'Дата',
        code: 'date'
    },
    {
        title: 'Причина',
        code: 'reasons',
    }
];

export default class WriteOff extends React.Component {
    static contextType = DialogsManagerContext;

    state = {
        list: [],
        materials: null,
        materialsGroups: null,
        searchQuery: '',
        reasons: null
    };

    componentDidMount() {
        this.loadData();
        moment.locale('ru');
    }


    render() {

        let list = [];
        if (this.state.list !== null && this.state.list.length > 0) {
            list = Helper.clone(this.state.list);

            if (this.state.searchQuery.length > 0) {
                list = list.filter((item) =>
                    this.state.searchQuery === String(item.id)
                )
            }

        }

        return (
            <React.Fragment>
                <div className="box">
                    <div className="box__header-search">
                        <TextField onChange={(value) => this.setState({searchQuery: value})}
                                   isSearch={true}
                                   widthInput="100%"
                                   placeholder="Поиск списания по номеру"
                                   value={this.state.searchQuery}
                        />
                        <Button variant="standard" children="Сбросить" size="md" margin="0 0 0 20px"/>
                    </div>
                    <Table
                        columns={patientsColumns}
                        data={list}
                        scrollHeight={260}
                        selectMode="single"
                        getRowCode={(writeOff) => writeOff.id}
                        modifyCellContent={this.modifyCellContent.bind(this)}
                        onSelect={(selectedIds, selectedId) => this.context.showSlideModal(<ViewWriteOff
                            id={selectedId}
                            reload={this.loadData.bind(this)}
                            materials={this.state.materials}
                            materialsGroups={this.state.materialsGroups}
                            reasons={this.state.reasons}
                        />)}
                    />
                </div>
                <div className="materials__wrap-btn">
                    <Button variant="success" size="long" children="Начать" onClick={() =>
                        this.context.showSlideModal(<PopupWriteOff
                            reload={this.loadData.bind(this)}
                            materials={this.state.materials}
                            materialsGroups={this.state.materialsGroups}
                            reasons={this.state.reasons}
                        />)
                    }/>
                </div>
            </React.Fragment>
        )
    }

    /** Загрузка данных с сервера и в случаи необходимости обновление данных */
    loadData() {
        const command = new ServerCommand('material/get-write-off-list', {
            getReasons: true,
            getMaterials: true,
            getMaterialsGroups: true,
            getMeasureShortName: true
        });

        command.exec().then(response => {
            this.setState({
                list: (response.list.length > 0) ? response.list : null,
                materials: response.materials,
                materialsGroups: Helper.Array.toObject(response.materialsGroups, 'id'),
                reasons: response.reasons
            });
        });
    }

    /** Модифицируем state.list для таблицы
     * @param columnCode {string}
     * @param content {*}
     * @return {*} */
    modifyCellContent(columnCode, content) {
        if (columnCode === 'date') {
            content = moment(content).format('DD MMMM YYYY');
        } else if (columnCode === 'reasons') {
            content = content.map((reason) => this.state.reasons[reason]).join(', ');
        }
        return content
    }


}
