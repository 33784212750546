import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const SortAsc = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 24"} ref={ref}>
            <path d="M0,6.86H19.64v3.43H0Zm0,6.85H15.27v3.43H0ZM0,0H24V3.43H0ZM0,20.57H10.91V24H0Z"/>
        </WrapIcon>
    );
});
