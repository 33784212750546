import {useNewVisitForm} from "../../../NewVisitForm";
import {useRef, useState} from "react";
import useDebounce from "../../../../../common/hooks/useDebounce";
import useDisplayPatientList from "../../../../../containers/PatientField/hooks/useDisplayPatientList";
import useSearchPatientList from "../../../../../common/hooks/useSearchPatientList";
import { PatientValues } from "../../../../../containers/PatientField";

export default function useSearchBar() {
    const {loadingPatient} = useNewVisitForm();
    const ref = useRef(null);
    const [value, setValue] = useState('');
    const debouncedValue = useDebounce(value, 500);
    const [displayPatientList, setDisplayPatientList] = useDisplayPatientList(ref);
    let [patientList, loaderPatientList] = useSearchPatientList({value: debouncedValue, nameField: 'searchQuery', isFilter: true});

    const onClickPatient = (patient: PatientValues): void => {
        loadingPatient(patient);
        setValue('');
        setDisplayPatientList(false)
    }

    const onChangeSearchBar = (val: string): void => {
        if (val.length >= 2) {
            if (!displayPatientList) setDisplayPatientList(true)
        } else {
            if (displayPatientList) setDisplayPatientList(false)
        }
        setValue(val)
    }
    const resetTopSearch = (): void => setValue('')

    return {
        value,
        onClickPatient,
        onChangeSearchBar,
        resetTopSearch,
        patientList,
        loaderPatientList,
        displayPatientList,
        ref
    }

}
