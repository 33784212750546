import React, {useEffect, useMemo, useState} from "react";
import Select from "../../../components-ui/Inputs/Select/Select";
import {useEditableField} from "../EditableField";
import {ValueEditableField} from "../typesEditableField";
import SaveButtons from "./SaveButtons";
import ServerCommand from "../../../common/server/server-command";

export default function Relation() {
    const {field, patientId, setEditField} = useEditableField();
    const values = Object.values(field.values)[0] as ValueEditableField;
    const [selected, setSelected] = useState(values.value.selected);
    const [valid, setValid] = useState(false);
    const patient = values.value.patient;

    const noCanceledButton = useMemo(() => {
        return values.value.selected === null || values.value.selected === undefined
    }, []);

    const onClickSave = () => {
        if (selected === null || selected === undefined) {
            setValid(true)
            return;
        }
        const data = {id: patientId} as any;
        if (values.isRelationTo) {
            data.relationsTo = {[patient.id]: selected.value}
        } else {
            data.relations = {[patient.id]: selected.value}
        }

        const command = new ServerCommand('patient/edit', data);

        command.exec().then(() => {
            field.onChange(selected);
            setEditField(false);
        });
    }

    useEffect(() => {
        if (values.validation) setValid(true);
    }, [values.validation])

    return (
        <>
            <Select value={selected}
                    options={!!values.variants ? values.variants : []}
                    isError={valid}
                    placeholder="Не выбрана"
                    onChange={(selectedEnum) => {
                        setSelected(selectedEnum)
                        if (!!values.onResetValidation) {
                            setValid(false);
                            values.onResetValidation();
                        }
                    }}/>
            <SaveButtons onClickSave={onClickSave} noCanceledButton={noCanceledButton}/>
        </>
    )
}
