import {useEffect} from "react";
import {PropsUseChangeDoctor} from "../types/hooks";

export default function useChangeDoctor({form, setForm}: PropsUseChangeDoctor) {

    useEffect(() => {
        if (form.doctor === null && form.wantThisDoctor) setForm(prevState => ({
            ...prevState,
            wantThisDoctor: false
        }))
    }, [form.doctor])
}
