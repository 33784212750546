import React from "react";
import {useWAP} from "../WaitingAndPlanned";
import PatientField, {usePrepareValueField, useShownFields} from "../../../../../containers/PatientField";
import {basicFields} from "../helper/basicFields";

export default function BasicFormWAP() {
    const {form, onChangeFieldBasic, setForm, loadingPatient, onChangeValidationBasic, fields} = useWAP();
    const shownFields = useShownFields({currentPatient: form.currentPatient, formFields: basicFields, fields})
    const prepareValueField = usePrepareValueField({basic: form.basic, fields})
    return (
        <>
            {shownFields.map((fieldName, index) => {
                const valueField = prepareValueField({fieldName})
                if (valueField === null) return null
                return (
                    <PatientField {...valueField}

                                  onChangeField={onChangeFieldBasic}
                                  currentPatient={form.currentPatient}
                                  loadingPatient={loadingPatient}
                                  setForm={setForm}
                                  autoFocus={index === shownFields.length - 1}
                                  personalPhone={form.basic.personalPhone}
                                  parentName="WAP"
                                  onChangeValidationItem={onChangeValidationBasic}
                    />
                )
            })}
        </>
    )
}
