import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const MapIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 18.23 24"} ref={ref}>
            <path d="M9.12,0A9.12,9.12,0,0,0,0,9.12C0,14.15,4.27,20.9,9.12,24c4.43-3.2,9.11-9.85,9.11-14.88A9.11,9.11,0,0,0,9.12,0Zm0,11.72A2.68,2.68,0,1,1,11.79,9,2.68,2.68,0,0,1,9.12,11.72Z"/>
        </WrapIcon>
    );
});
