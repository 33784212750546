import React from "react";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";
import Counter from "../../../components-ui/Inputs/Counter/Counter";
import {PropsContentConditions} from "../interfaces/common";
import _ from 'lodash'

export default function ContentConditions({keyParameters, condition, state, setState}: PropsContentConditions) {
    switch (keyParameters[condition].type) {
        case "boolean" :
            return <Checkbox label=""
                                checked={state[condition]}
                                onChange={value => setState(prevState => {
                                    prevState[condition] = value
                                    return {...prevState}
                                })} />
        case "integer" :
            const optionMin = keyParameters[condition].options.min;
            const optionMax = keyParameters[condition].options.max;
            const min = typeof optionMin === 'string' ? +state[optionMin] : optionMin;
            const max = typeof optionMax === 'string' ? +state[optionMax] : optionMax;
            return <Counter value={state[condition]}
                               min={min}
                               max={max}
                               onChange={value => setState(prevState => {
                                   prevState[condition] = value
                                   _.keys(prevState).forEach(prevCondition => {
                                       const maxName = keyParameters[prevCondition].options.max
                                       if (typeof maxName === 'string' && maxName === condition) {
                                          if (+prevState[prevCondition] > +value) {
                                              prevState[prevCondition] = value
                                          }
                                       }
                                   })
                                   return {...prevState}
                               })} />
        default:
            return null
    }
}
