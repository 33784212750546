import {useEffect, useState} from "react";
import ServerCommand from "../../../../../common/server/server-command";
import {GetPatientPaymentsResult} from "../types";

interface Props {
    patientId: number
}

const usePaymentsData = ({patientId}: Props) => {

    const [paymentResponse, setPaymentResponse] = useState<GetPatientPaymentsResult>()

    const params = {patientId};

    useEffect(() => {
        ServerCommand.get('patient/payments', params).then(response => {
            setPaymentResponse(response)
        })
    }, [patientId])

    return {paymentResponse}
};

export default usePaymentsData;