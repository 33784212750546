import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../OldButton/Button'

export default class ButtonGroup extends React.PureComponent {

    static propTypes = {
        topButtons: PropTypes.array,
        buttons: PropTypes.array.isRequired,
    };

    static defaultProps = {
        topButtons: []
    };

    render() {

        let {buttons, topButtons} = this.props;

        return (
            <div className="step-footer">
                {topButtons.length > 0 &&
                    <div className="step-footer__item">
                        {this.renderButtons(topButtons)}
                    </div>
                }
                <div className="step-footer__item">
                    {this.renderButtons(buttons)}
                </div>
            </div>
        );
    }

    renderButtons = (buttons) => {
        return buttons.map(button => {

            let info = null;

            if (button.info) {
                info = <p className="step-footer__btn-label"> {button.info} </p>
            }

            let variant = button.variant;

            if (!button.variant) {
                variant = 'success'
            }

            if (button.disabled) {
                variant = 'disabled'
            }

            return (
                <span className="step-footer__btn-wrap" key={button.code}>
                    <Button
                        variant={variant}
                        text={button.text}
                        key={button.code}
                        onClick={button.onClick}
                        action={button.action}
                        align={button.align}
                        loader={button.loader}
                    />
                    {info}
                </span>
            )
        });
    };

}
