import React, {forwardRef} from 'react';
import { WrapIcon } from '../../../containers/WrapIcon';
import { IIcon } from '../../../types/common';

export const NewVisitFormIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M12,24a12,12,0,1,1,8.49-3.51A12,12,0,0,1,12,24ZM12,2A10,10,0,0,0,4.93,19.07,10,10,0,1,0,19.07,4.93,9.93,9.93,0,0,0,12,2Z"/>
            <path d="M17.9,8.37,16.79,9.48,14.52,7.21l-.86.86,2.27,2.27-5.57,5.57L8.09,13.64l-.86.86L9.5,16.77l-.56.56h0a.45.45,0,0,1-.28.2L6.54,18h-.1a.44.44,0,0,1-.31-.13A.45.45,0,0,1,6,17.46l.47-2.11a.45.45,0,0,1,.2-.28h0l9-9a.34.34,0,0,1,.48,0L17.9,7.89A.34.34,0,0,1,17.9,8.37Z"/>
        </WrapIcon>
    );
});
