import {SaveCallOverseeingResultParams} from "../../../../types/response";
import ServerCommand from "../../../../../../common/server/server-command";
import {ManagerTask} from "../../../../types/common";
import {CheckCallerTypePayload} from "../../../../types/taskData";
import {MethodsManagerTasks} from "../../../../types/components";
import {useEffect, useState} from "react";
import {OptionSelect} from "../../../../../../components-ui/Inputs/Select/typesSelect";
import {useSelector} from "react-redux";

interface Props {
    task: ManagerTask<CheckCallerTypePayload>
    methodsManagerTasks: MethodsManagerTasks
}

interface StateCheckCallerType {
    callerType: OptionSelect
    excludeFromReports?: boolean
    purposesAchieved?: boolean
}

export const useCheckCallerType = ({task, methodsManagerTasks}: Props) => {
    const callerTypes = useSelector(state1 => state1.common.callerTypes)

    const [state, setState] = useState<StateCheckCallerType>({
        callerType: {
            value: task.payload.callerType,
            label: callerTypes[task.payload.callerType]
        },
    })

    useEffect(() => {
        if ((state.callerType.value === 'new_patient' || state.callerType.value === 'existing_patient')
            && state.excludeFromReports === undefined) {
            setState(prevState => ({
                ...prevState,
                excludeFromReports: false
            }))
        } else if (state.callerType.value !== 'new_patient' && state.callerType.value !== 'existing_patient') {
            setState(prevState => ({
                ...prevState,
                excludeFromReports: undefined,
                purposesAchieved: undefined
            }))
        }
    }, [state.callerType])

    useEffect(() => {
        if (state.excludeFromReports !== undefined && state.purposesAchieved === undefined) {
            setState(prevState => ({
                ...prevState,
                purposesAchieved: true
            }))
        } else {
            setState(prevState => ({
                ...prevState,
                purposesAchieved: undefined
            }))
        }
    }, [state.excludeFromReports])


    const taskComplete = (taskId: number | number[]) => {
        return new Promise(resolve => {
            methodsManagerTasks.taskComplete(taskId).then(() => {

                const parametrs: SaveCallOverseeingResultParams = {
                    id: task.payload.completedCallId,
                    callerType: state.callerType.value
                }

                if (state.excludeFromReports !== undefined) {
                    parametrs.excludeFromReports = state.excludeFromReports
                }

                if (state.purposesAchieved !== undefined) {
                    parametrs.purposesAchieved = state.purposesAchieved
                }

                ServerCommand.post('call-center/completed-call/save-overseeing-result', parametrs).then(() => {
                    resolve()
                })
            })
        })
    }

    const onChangeCallerType = (callerType: OptionSelect) => {
        setState(prevState => ({
            ...prevState,
            callerType
        }))
    }

    const onChangeExcludeFromReports = (excludeFromReports: boolean) => {
        setState(prevState => ({
            ...prevState,
            excludeFromReports
        }))
    }

    const onChangePurposesAchieved = (purposesAchieved: boolean) => {
        setState(prevState => ({
            ...prevState,
            purposesAchieved
        }))
    }

    return {
        taskComplete,
        state,
        onChangeCallerType,
        onChangeExcludeFromReports,
        onChangePurposesAchieved
    }
}