import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const Printer3DIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path fill="#d4d6d5" strokeWidth="0.1" stroke="#000"
                  d="M5,14.13l12.07,7V14.68L5,7.72Zm17.08-4h0L19,12,9.68,6.6l3.11-1.81ZM12.79,4.79,11.43,4M22.1,10.17l1.4.8v6.41M17.09,21.1l6.4-3.71"/>
            <path fill="#999" strokeWidth="0.1" stroke="#000"
                  d="M6.41,14.93,0,18.64,9.24,24l6.41-3.71M10,17,3.59,20.71m8-2.78L5.2,21.64m11.89-7L23.49,11M5,7.72,11.43,4m1.38.77h0m2.9,9.09h0m-9.28,1V11l9.24,5.32v3.93M6.41,13l9.24,5.32m.14-16.58L14.53,1,12.79,4.79m9.3,5.38,1.74-3.81-1.29-.75M20.69,9.28,23.26,3.8,16.68,0,14.11,5.48"/>
            <path stroke="#60ae00" strokeLinecap="round" strokeLinejoin="round" d="M15.65,12.28l.67.39"/>
        </WrapIcon>
    );
});
