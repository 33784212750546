import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import TimeRange from "../../../../../components-ui/Inputs/Slider/TimeRange/TimeRange";

export default function SliderPage() {
    const [value, setValue] = useState(["08:00", "21:30"])
    return (
        <>
            <h1>SliderPage</h1>
            <WrapContent>
                <div style={{background: '#fff'}}>
                    <TimeRange onChange={setValue} value={value} step="15" timeEnd="21:30" timeStart="08:00" onAfterChange={() => console.log('все ок')}/>
                    <pre>
                        {
                            `<TimeRange onChange={setValue} value={value} step="15" timeEnd="21:30" timeStart="08:00" onAfterChange={() => console.log('все ок')}/>`
                        }
                    </pre>
                </div>

            </WrapContent>
        </>
    )
}
