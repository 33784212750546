import {useEffect, useRef, useState} from "react";

const useChangeSelectedScript = () => {
    const refList = useRef(null) as any
    const [refItem, setRefItem] = useState<any>(null)

    useEffect(() => {
        if (!!refItem && !!refList.current) {
            const listTop = refList.current.getBoundingClientRect().top
            const itemTop = refItem.getBoundingClientRect().top
            const listBottom = refList.current.getBoundingClientRect().bottom
            if (itemTop < listTop) {
                let newScroll = itemTop < 0 ? (itemTop * -1) + listTop : listTop - itemTop
                refList.current.scrollTo(0, refList.current.scrollTop - newScroll)
            } else if (itemTop > listBottom) {
                const listHeight = refList.current.getBoundingClientRect().height
                refList.current.scrollTo(0, refList.current.scrollTop + (itemTop - listBottom) + listHeight)
            }
        }
    }, [refItem])

    return {
        refList,
        setRefItem
    }

}

export default useChangeSelectedScript
