import {Dispatch, SetStateAction, useEffect, useMemo} from "react";
import {updateSavePatientCallCenter} from "../../../../redux/actions/callCenterActions";
import {Call, PlannedCall} from "../../types/common";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {useSlideModal} from "../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import usePrevious from "../../../../common/hooks/usePrevious";
import {StateCallCenter} from "../../types/components";

interface Props {
    state: StateCallCenter
    setState: Dispatch<SetStateAction<StateCallCenter>>
    marketingPlannedCalls: PlannedCall[]
    refScrollList: any
    onChangeSelectedScript: (scriptId: number) => void
}

const useChangeCalls = (
    {
        marketingPlannedCalls,
        refScrollList,
        onChangeSelectedScript,
        state,
        setState
    }: Props) => {

    const {calls} = state;
    const {maximizeSlideModal, isMinimized} = useSlideModal();
    const savePatientCallCenter = useSelector(state => state.callCenter?.savePatient) as any;
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const prevCalls = usePrevious(calls) as Call[];

    const userCalls = useMemo(() => {
        return {
            calls: calls.filter(call => call.userId === user.id),
            prevCalls: prevCalls ? prevCalls.filter(call => call.userId === user.id) : []
        }
    }, [JSON.stringify(calls)])

    useEffect(() => {
        const firstCall = userCalls.calls[0];
        if (!!userCalls.calls.length && userCalls.calls.some(call => !!call.answerTime)) {
            const filterPhoneCalls = userCalls.calls.filter(call => !!call.answerTime);
            const savePatient = {
                ...filterPhoneCalls[filterPhoneCalls.length - 1].contactPerson,
                personalPhone: filterPhoneCalls[filterPhoneCalls.length - 1].contactPhone
            }
            dispatch(updateSavePatientCallCenter(savePatient))
        } else if (!savePatientCallCenter) {
            dispatch(updateSavePatientCallCenter(undefined))
        }
        if (!!userCalls.prevCalls && userCalls.prevCalls.length > userCalls.calls.length) {
            const mapCalls = userCalls.calls.map(call => call.contactPhone);
            const findPrevCall = userCalls.prevCalls.find(prevCall => !mapCalls.includes(prevCall.contactPhone))
            if (!!findPrevCall) {
                setState(prevState => {
                    const findIndex = prevState.plannedCalls.findIndex(prevCall => prevCall.phone === findPrevCall.contactPhone)
                    if (findIndex !== -1) {
                        const result = prevState.plannedCalls[findIndex].result
                        if (!!result && !!result.callerType) {
                            prevState.plannedCalls[findIndex].canSaveResult = false
                        }
                    }
                    if (!!prevState.detail) {
                        if (prevState.detail.phone === findPrevCall.contactPhone && (!!prevState.detail.result && !!prevState.detail.result.callerType)) {
                            prevState.detail.canSaveResult = false;
                        }
                    }
                    return {...prevState}
                })
            }
        }

        if (!!firstCall && firstCall.userId === user.id) {
            const findPlannedCall = state.plannedCalls.find(plannedCall => plannedCall.phone === firstCall.contactPhone)
            if (!!findPlannedCall) {
                const findPlannedCallTaskScript = findPlannedCall.tasks.find(task => !!task.scriptId);
                if (!!findPlannedCallTaskScript) {
                    onChangeSelectedScript(findPlannedCallTaskScript.scriptId as number)
                }
            }
        }

        if (userCalls.calls.length) {
            if (userCalls.calls.length === 1 || (!state.detail || (state.detail.phone !== firstCall.contactPhone))) {
                if (!firstCall.incoming
                    && marketingPlannedCalls.some(plannedCall => plannedCall.phone === firstCall.contactPhone)
                    && firstCall.userId === user.id) {
                    const findCall = marketingPlannedCalls.find(plannedCall => plannedCall.phone === firstCall.contactPhone) as PlannedCall;
                    const uniqType = _.uniqBy(findCall.tasks, 'type');
                    if (uniqType.length > 1 || uniqType.some(plannedCall => plannedCall.type === 'missed_call')) {
                        setState(prevState => ({
                            ...prevState,
                            detail: findCall
                        }))
                    }
                    if (isMinimized && !!maximizeSlideModal) {
                        maximizeSlideModal()
                    }
                    const timiId = setTimeout(() => {
                        if (!!refScrollList && refScrollList.current) refScrollList.current.scrollTo(0, 0);
                    }, 100)
                    return () => clearTimeout(timiId)
                } else if (firstCall.incoming && !!firstCall.answerTime && firstCall.userId === user.id) {
                    const findCall = marketingPlannedCalls.find(plannedCall => plannedCall.phone === firstCall.contactPhone) as PlannedCall;
                    setState(prevState => ({
                        ...prevState,
                        detail: findCall
                    }))
                    if (isMinimized && !!maximizeSlideModal) {
                        maximizeSlideModal()
                    }
                    const timiId = setTimeout(() => {
                        if (!!refScrollList && refScrollList.current) refScrollList.current.scrollTo(0, 0);
                    }, 100)
                    return () => clearTimeout(timiId)
                }
            }

        }



    }, [JSON.stringify(userCalls)])
}

export default useChangeCalls
