import React from "react";
import PropTypes from "prop-types";
import Table from "../../../../components/Table/Table";
import Button from "../../../../components-ui/OldButton/Button";
import ServerCommand from "../../../../common/server/server-command";
import moment from "moment";
import Helper from "../../../../common/helpers/main";
import PopupInvoices from "./PopupInvoices";
import {DialogsManagerContext} from "../../../../containers/DialogsManager/DialogsManager";


export default class ViewInvoice extends React.Component {
    static contextType = DialogsManagerContext;

    resultColumns = [
        {
            title: 'Материал',
            code: 'name',
            containsGroupTitle: true
        },
        {
            title: 'Цена',
            code: 'price',
        },
        {
            title: 'Количество',
            code: 'quantity',
        },
        {
            title: 'Ед. измерения',
            code: 'measureShortName',
        },
        {
            title: 'К оплате',
            code: 'sum',
            type: 'price',
        },
    ];

    static propTypes = {
        id: PropTypes.number.isRequired,
        reloading: PropTypes.func,
        providers: PropTypes.object,
        materials: PropTypes.array,
        materialsGroups: PropTypes.array
    };

    state = {
        detail: null,
        chosenServiceId: 0
    };

    componentDidMount() {
        const command = new ServerCommand('material/get-waybill', {
            primary: this.props.id,
            getItems: true,
        });

        command.exec().then(response => {
            this.setState({
                detail: response.detail
            })
        });
    }


    render() {

        if (this.state.detail === null || this.props.materials === null) {
            return null
        }

        const resultTableData = Object.values(this.createsTableData());

        let provider = null;

        this.props.providers.forEach((res) => {
           if (res.id === this.state.detail.providerId) {
               provider = res.name
           }
        });

        return (
            <div className="new-invoices">
                <div className="header-popup__title">Накладная (№ {this.state.detail.id}) от {moment(this.state.detail.date).format('DD MMMM YYYY')}, поставщик «{provider}»</div>
                <div className="box">
                    <div className="box-body">

                        <div className="box">
                            <div className="treatment-plan__result-table">
                                <Table columns={this.resultColumns}
                                       data={resultTableData}
                                       selectMode="none"
                                />
                            </div>
                        </div>
                        <div className="box__btns">
                            <Button variant='default' text="Редактировать" size="long" onClick={ () =>
                                this.context.showSlideModal(<PopupInvoices
                                    reloading={this.props.reloading}
                                    providers={this.props.providers}
                                    materials={this.props.materials}
                                    materialsGroups={this.props.materialsGroups}
                                    createInvoice={true}
                                    detail={this.state.detail}
                                    viewReloading={this.loadData.bind(this)}
                                />)
                            }/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createsTableData() {
        let data = {};
        const materials = Helper.clone(this.props.materials);

        if (this.state.detail.items !== null) {
            this.state.detail.items.forEach((item) => {
                data[item.materialId] = item;
                data[item.materialId].sum = item.quantity * item.price;
            });
        }

        materials.forEach((material) => {
            if (data[material.id] !== undefined ) {
                data[material.id].name = material.name;
                data[material.id].groupId = material.groupId;
            }
        });

        return data
    }

    loadData() {
        const command = new ServerCommand('material/get-waybill', { primary: this.props.id, getItems: true });
        command.exec().then(response => this.setState({ detail: response.detail }));
    }


}
