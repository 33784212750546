import React from 'react';
import Typography from '../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../components-ui/Layout/Box';
import useLaboratoryPage from './hooks/useLaboratoryPage';
import LoaderPage from '../../components/LoaderPage';
import LaboratoriesTable from './components/LaboratoriesTable';

const LaboratoryPage = () => {

    const {loaderPage, laboratoriesResponse, refreshDataFromServer} = useLaboratoryPage()

    return (
        <Box padding="62px 20px 80px 28px">
            <Typography variant="title" fontWeight="bold">Лаборатории</Typography>
            {!loaderPage ?
            <LaboratoriesTable refreshDataFromServer={refreshDataFromServer} data={laboratoriesResponse}/> :
                <LoaderPage height={185}/>
            }
        </Box>
    );
};

export default LaboratoryPage;
