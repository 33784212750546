import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const ProductIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M12.55.14,22.64,4.89,12,10.11,1.27,4.89,11.36.14A1.31,1.31,0,0,1,12.55.14Zm.72,12.21L24,7.14v11a1.36,1.36,0,0,1-.8,1.16L13.27,24ZM0,7.14l10.64,5.21V24L.74,19.33A1.3,1.3,0,0,1,0,18.17Z"/>
        </WrapIcon>
    );
});
