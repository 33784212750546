import React, {useMemo} from "react";
import Helper from "../../../../../../common/helpers/main";
import {IColumn, IRow} from "../../../../../../components-ui/DataDisplay/Table";
import {CallsEfficiencyReport} from "../../../../types";
import {StateCallsEfficiency} from "../types";
import Box from "../../../../../../components-ui/Layout/Box";
import {User} from "../../../../../../types";
import Typography from "../../../../../../components-ui/DataDisplay/Typography/Typography";
import {useDialogsManager} from "../../../../../../containers/DialogsManager/DialogsManager";
import PatientCard from "../../../../../../content/PatientCard/PatientCard";
import {useSelector} from "react-redux";
import {Icon} from "../../../../../../components-ui/DataDisplay/Icon/containers/Icon";
import {Link} from "../../../../../../components-ui/Navigation/Link";

interface Props {
    report: CallsEfficiencyReport
    state: StateCallsEfficiency
}

const useTableEfficiency = ({state, report}: Props) => {
    const directions = useSelector(state1 => state1.common.directions)
    const users = useSelector(state1 => state1.common.users)
    const {showSlideModal} = useDialogsManager()
    const columns: IColumn[] = [
        {
            headerName: 'Пациент',
            field: 'patient',
            width: '200px',
            valueFormatter: ({childrenCell}) => {
                if (!!childrenCell) {
                    return (
                        <Box display="flex" flexDirection="column">
                            {childrenCell.map((patient: User, index: number) => {
                                return (
                                    <Typography variant="small"
                                                color="info"
                                                key={patient.id}
                                                cursor="pointer"
                                                margin={index !== 0 ? '5px 0 0' : '0'}
                                                children={Helper.getFio(patient)}
                                                onClick={() => showSlideModal(<PatientCard patientId={patient.id} />)}
                                    />
                                )
                            })}
                        </Box>
                    )
                } else {
                    return 'нет в базе'
                }
            }
        },
        {
            headerName: 'Тема разговора',
            field: 'topic',
            valueFormatter: ({childrenCell}) => {
                if (!!childrenCell) {
                    return childrenCell.map((topic: string) => directions[topic].name).join(', ')
                } else {
                    return ''
                }
            }
        },
        {
            headerName: 'Администратор',
            field: 'user',
            width: '170px',
            valueFormatter: ({childrenCell}) => users[childrenCell].fio
        },
        {
            headerName: 'Аудио',
            field: 'audio',
            align: 'right',
            width: '48px',
            valueFormatter: ({childrenCell}) => {
                if (!!childrenCell) {
                    return (
                        <Box flexShrink={0} width="16px" height="16px">
                            <Link href={childrenCell}
                                  isCircle={true}
                                  outline={true}
                                  padding="2px 1px 2px 3px"
                                  children={<Icon name="playArrow" width="8px" height="8px" color="info"/>}/>
                        </Box>
                    )
                } else {
                    return null
                }
            }
        }
    ]

    const rows = useMemo(() => {
        if (report.failedCalls.length) {
            return report.failedCalls
                .filter(failedCall => {
                    return (state.currentTab === 'incoming' && failedCall.incoming) ||
                        (state.currentTab === 'outgoing' && !failedCall.incoming)
                })
                .filter(failedCall => !!state.currentSelected.value ? +failedCall.userId === +state.currentSelected.value : true)
                .map(failedCall => {
                    return {
                        patient: report.phonesPatients[failedCall.contactPhone],
                        topic: failedCall.topics,
                        user: failedCall.userId,
                        audio: failedCall.recordLink
                    }
                }) as IRow[]
        } else {
            return [] as IRow[]
        }

    }, [JSON.stringify(report), state.currentTab, state.currentSelected.value])

    return {
        columns,
        rows
    }
}

export default useTableEfficiency
