import React from 'react'
import Time from './elements/Time'
import User from './elements/User'
import Search from "./elements/Search";
import {HeaderWrap, Controls} from "./styledHeader";

export default function Header() {
    return (
        <HeaderWrap>
            <Controls>
                <Search/>
                <Time />
            </Controls>
            <User />
        </HeaderWrap>
    )
}
