import React from "react";
import {LabelTextGray} from "../style/common";
import {InfoBoxWrap} from "../../../components-ui/styledComponent/common/wraps";
import {Label} from "../../../components-ui/DataDisplay/Label";
import {useNewVisitForm} from "../NewVisitForm";
import preparePhoneOwnerName from "../../../containers/PatientField/helper/preparePhoneOwnerName";
import useOptionsRelation from "../hooks/useOptionsRelation";
import _ from 'lodash';
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import PatientField, {IPatientField, PatientValues} from "../../../containers/PatientField";

const field = {
    defaultValue: null,
    name: 'relation',
    title: 'Степень родства',
    required: true,
    group: 'basic',
    type: 'enum',
    placeholder: 'Не выбрана'
} as IPatientField

export default function Relations() {
    const {form} = useNewVisitForm();
    if (Object.keys(form.relations.relations).length === 0) return null;

    return (
        <>
            <Typography variant="min-title" padding="25px 20px 11px">Пациенты с тем же телефоном</Typography>
            <InfoBoxWrap>
                {form.relations.relationsList.map(patient => <Relation patient={patient} key={patient.id}/>)}
            </InfoBoxWrap>
        </>
    )
}

const Relation = ({patient}: { patient: PatientValues }) => {
    const {form, onChangeFieldRelation, onChangeValidationRelation} = useNewVisitForm()
    const cloneField = _.clone(field)
    cloneField.name = String(patient.id)
    cloneField.variants = useOptionsRelation(patient.personalGender)
    return (
        <span>
            <Label colorLabel="textGray"
                   widthLabelWrap="100%"
                   align="leftTop"
                   minWidthLabel="37%"
                   marginWrap="0 0 10px"
                   labelText=""
                   children={<LabelTextGray children={preparePhoneOwnerName(patient)}/>}/>
            <PatientField field={cloneField}
                          currentPatient={form.currentPatient}
                          value={form.relations.relations[patient.id as number]}
                          onChangeField={onChangeFieldRelation}
                          error={form.relations.validationFields[patient.id as number]}
                          onChangeValidationItem={onChangeValidationRelation}
            />
        </span>
    )
}
