import styled from "styled-components";
import Box from "../../../components-ui/Layout/Box";

export const WrapDoctors = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 4px 0 0;
    min-height: 21.6px;

    & > div {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }
`
