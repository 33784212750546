import React, {useRef, useState} from "react";
import Helper from "../../../common/helpers/main";
import SVG from "../../../common/helpers/SVG";
import _ from 'underscore';

interface PTurnkeyPrice {
    sum: number,
    turnkeyPrice?: number,
    turnkeyPriceParts?: {
        [key: string] : number
    },
    services: {
        [key: string]: any
    }
}

export default function TurnkeyPrice({sum, turnkeyPrice, turnkeyPriceParts, services} : PTurnkeyPrice) {
    const [hover, setHover] = useState(false);
    const [position, setPosition] = useState({x: 500, y: 400})
    const ref = useRef(null) as any;
    if (!!turnkeyPrice) {
        return (
            <div className="turnkey-price-wrap">
                <span>{Helper.Price.format(sum)}</span>
                <div className="turnkey-price-info-wrap"
                     ref={ref}
                     onMouseLeave={() => {
                         setHover(false)
                     }}
                     onMouseEnter={(e) => {
                         const pos = ref.current.getBoundingClientRect()
                         setPosition({x: pos.x, y: pos.y})
                         setHover(true)
                     }}>
                    <SVG name="information" className="turnkey-price-info"/>
                    {hover && (
                        <div className="turnkey-price" style={{top: position.y + 20, right: `calc(100% - ${position.x + 20}px)`}}>
                            <div className="turnkey-price-title">
                                под ключ
                            </div>
                            <div className="turnkey-price-sum">
                                {Helper.Price.format(turnkeyPrice)}
                            </div>
                            {!!turnkeyPriceParts &&
                            Object.keys(turnkeyPriceParts).length > 0
                            && _.map(turnkeyPriceParts, (val, key) => {
                                const currentServiceParts = services[key];
                                return (
                                    <span key={key} className="turnkey-price-parts">
                                        <div className="turnkey-price-subtitle">{currentServiceParts.name}</div>
                                        <div className="turnkey-price-desc">
                                            <span>{val} шт.</span>
                                            <span>{Helper.Price.format(currentServiceParts.price)} руб.</span>
                                        </div>
                                    </span>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        )
    } else {
        return (
            <>
                {Helper.Price.format(sum)}
            </>
        )
    }
}
