import {ActionsReducer} from "../types/common";
import {UPDATE_SAVE_PATIENT_CALL_CENTER} from "../actions/actionConstants";

export interface CallCenterReducer {
    savePatient?: {
        [key: string]: any
    } | undefined
}

const initialState = {
    savePatient: undefined
};

export default function callCenterReducer(state: any = initialState, action: ActionsReducer) {
    switch (action.type) {
        case UPDATE_SAVE_PATIENT_CALL_CENTER:
            return {
                ...state,
                savePatient: action.payload
            }
        default:
            return state;
    }
}
