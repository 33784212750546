import React from 'react';
import Typography from '../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../components-ui/Layout/Box';
import useSalariesTimesheetPage from './hooks/useSalariesTimesheetPage';
import SalariesTimesheetFilter from './components/Filter';
import LoaderPage from '../../components/LoaderPage';
import SalariesTimesheetTable from './components/Table';

const SalariesTimeSheetPage = () => {

    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        loaderPage,
        loaderTable,
        selectedBranch,
        setSelectedBranch,
        employee,
        setEmployee,
        timesheetRecordsResponse,
        optionsBranches,
        refreshDataFromServer,
    } = useSalariesTimesheetPage();

    return (
        <Box padding='62px 20px 80px 28px'>
            <Typography variant='title' fontWeight='bold'>Табель учёта рабочего времени</Typography>
            <SalariesTimesheetFilter
                data={timesheetRecordsResponse}
                optionsBranches={optionsBranches}
                selectedBranch={selectedBranch}
                setSelectedBranch={setSelectedBranch}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                employee={employee}
                setEmployee={setEmployee}
            />
            {!loaderPage && timesheetRecordsResponse ?
                <SalariesTimesheetTable loaderTable={loaderTable}
                                        data={timesheetRecordsResponse}
                                        selectedBranch={selectedBranch}
                                        refreshDataFromServer={refreshDataFromServer}
                />
                :
                <LoaderPage height={338} />
            }

        </Box>
    );
};

export default SalariesTimeSheetPage;
