import React, {useMemo, useRef, useState} from "react";
import {BoxBody} from "../../../../../components-ui/styledComponent/common/wraps";
import TextField from "../../../../../components-ui/Inputs/TextField/TextField";
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import useDebounce from "../../../../../common/hooks/useDebounce";
import useSearchPatientList from "../../../../../common/hooks/useSearchPatientList";
import {PatientRelationTypesTitles} from "../../../../../common/types/patientCreationForm";
import ResultSearchPatient from "../../../../../components/ResultSearchPatient/ResultSearchPatient";
import useDisplayPatientList from "../../../../../containers/PatientField/hooks/useDisplayPatientList";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import _ from "underscore";
import preparePhoneOwnerName from "../../../../../containers/PatientField/helper/preparePhoneOwnerName";
import ServerCommand from "../../../../../common/server/server-command";
import { PatientValues } from "../../../../../containers/PatientField";
import useLoader from "../../../../../components/Errors";
import {useModal} from "../../../../../containers/DialogsManager/containers/Modal/Modal";

interface PropsAddRelation {
    parentPatientId: number,
    loadingFromServer: () => void,
    relationTypesTitles: PatientRelationTypesTitles,
}

export default function AddRelation({parentPatientId, loadingFromServer, relationTypesTitles}: PropsAddRelation) {
    const {closeModal} = useModal()
    const [value, setValue] = useState('');
    const [currentPatient, setCurrentPatient] = useState<null | PatientValues>(null)
    const [relation, setRelation] = useState<null | OptionSelect>(null)
    const [loader, setLoader] = useLoader();
    const debouncedValue = useDebounce(value, 500);
    const ref = useRef(null) as any;
    const [displayPatientList, setDisplayPatientList] = useDisplayPatientList(ref);
    const [patientList, loaderPatientList] = useSearchPatientList({
        value: debouncedValue,
        nameField: 'searchQuery',
        isFilter: true,
        parentPatientId,
        select: ['personalPhone', 'name', 'lastName', 'secondName', 'id', 'personalBirthday', 'personalGender', 'phoneOwner']
    });

    const prepareRelationTypesTitles = (patient: PatientValues | null) => {
        let newRelationTypesTitles = relationTypesTitles.general;
        if (!!patient && !!patient.personalGender) {
            newRelationTypesTitles = Object.assign({}, relationTypesTitles.general, relationTypesTitles[patient.personalGender])
        }
        return newRelationTypesTitles;
    }

    const optionRelation = useMemo(() => {
        return _.map(prepareRelationTypesTitles(currentPatient), (val, key) => ({
            value: key,
            label: val
        })) as any;
    }, [currentPatient])

    const onChangeSearch = (val: string) => {
        if (val.length >= 2) {
            if (!displayPatientList) setDisplayPatientList(true)
        } else {
            if (displayPatientList) setDisplayPatientList(false)
        }
        setValue(val);
        setCurrentPatient(null);
        setRelation(null);
    }

    const onClickReset = () => {
        setValue('')
        setDisplayPatientList(false);
        setCurrentPatient(null);
        setRelation(null)
    }

    const onClickPatient = (patient: PatientValues) => {
        if (!!patient.relationType) {
            const titles = prepareRelationTypesTitles(patient) as any;
            setRelation({
                value: patient.relationType,
                label: titles[patient.relationType]
            })
        }
        setCurrentPatient(patient);
        setValue(preparePhoneOwnerName(patient))
        setDisplayPatientList(false)
    }

    const onClickSave = () => {
        if (!!currentPatient) {
            setLoader(true);
            ServerCommand.post('patient/edit', {id: parentPatientId, relations: {[currentPatient.id as number]: relation?.value}}).then(() => {
                loadingFromServer();
                closeModal();
                setLoader(false);
            })
        }
    }

    return (
        <BoxBody nonBorderBottom>
            <h2 className='modal__title modal__title--margin-top'>Добавление родственника</h2>
            <TextField onChange={onChangeSearch}
                       value={value}
                       isSearch={true}
                       isReset={!!value.length}
                       onClickReset={onClickReset}
                       widthInput="478px"
                       margin="30px 0"
                       placeholder="Поиск в базе пациентов"
                       additionalContent={<ResultSearchPatient patientList={patientList}
                                                               displayPatientList={displayPatientList}
                                                               loaderPatientList={loaderPatientList}
                                                               minWidth='470px'
                                                               ref={ref}
                                                               onClickPatient={onClickPatient}/>}/>
            <Label labelText="Степень родства"
                   align="topLeft"
                   marginWrap="0 0 48px">
                <Select isDisabled={currentPatient === null}
                        placeholder="Не выбрана"
                        value={relation}
                        options={optionRelation}
                        onChange={setRelation}
                />
            </Label>
            <Button variant={relation === null ? 'disabled' : 'success'}
                    size="md"
                    loader={loader}
                    children="Добавить"
                    onClick={onClickSave}
                    margin="0 0 50px"/>
        </BoxBody>
    )

}
