import {useState} from "react";

interface Props {
    onScroll?: any
}

const useIsScrolling = ({onScroll}: Props) => {
    const [isScrolling, setIsScrolling] = useState(false)

    const _onScroll = (event: any) => {
        if (!isScrolling && !!event.target.scrollTop) {
            setIsScrolling(true)
        }
        if (isScrolling && !event.target.scrollTop) {
            setIsScrolling(false)
        }
        if (!!onScroll) onScroll()
    }

    return {
        isScrolling,
        _onScroll
    }
}

export default useIsScrolling
