import {useMemo} from "react";
import {searchable} from "../data";
import {StatePatientList} from "../types/common";

interface Props {
    state: StatePatientList
}

const useRows = ({state}: Props) => {

    return useMemo(() => {
        let result = state.patientsList;

        if (!!state.doctor.value && !!result) {
            result = result.filter(patient => patient.doctorsIds.some(doctorId => doctorId === state.doctor.value))
        }

        if (!!state.searchQuery.length && !!result) {
            result = result.filter((patient: {[key: string]: any}) => {
                let isSuitable = false;
                let value = '';
                searchable.forEach(search => value += ' ' + String(patient[search]).toLowerCase());
                if (value.indexOf(state.searchQuery.toLowerCase()) !== -1) isSuitable = true;
                return isSuitable
            }).sort((a, b) => {
                const nameA = a.lastName.toLowerCase();
                const nameB = b.lastName.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0
            })
        }

        return result;
    }, [state.patientsList, state.doctor, state.searchQuery])
}

export default useRows
