import {ADD_VISIT, LOAD_VISITS, REMOVE_VISIT, START_VISIT} from "../actions/actionConstants";
import {ActionsReducer} from "../types/common";
import Helper from "../../common/helpers/main";
import {IVisit} from "../../common/types/common";

export interface loginR {
    visits: any | {
        waiting?: {
            [key: string] : IVisit
        } | any,
        inProgress?: {
            [key: string] : IVisit
        } | any
    },
}

const initialState: loginR = {
    visits: {}
};

export default function doctorRoleReducer(state: loginR = initialState, action: ActionsReducer) {
    const visits = Helper.clone(state.visits);
    switch (action.type) {
        case LOAD_VISITS:
            return {...state, visits: {
                    waiting: Helper.Array.toObject(action.payload.waiting, 'id'),
                    inProgress: Helper.Array.toObject(action.payload.inProgress, 'id')
                }};
        case ADD_VISIT:
            visits.waiting[action.payload.id] = action.payload;
            return {...state, visits};
        case REMOVE_VISIT:
            delete visits.waiting[action.payload];
            return {...state, visits};
        case START_VISIT:
            visits.inProgress[action.payload] = visits.waiting[action.payload];
            delete visits.waiting[action.payload];
            return {...state, visits};
        default:
            return state;
    }
}
