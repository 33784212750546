import Helper from "../../common/helpers/main";

export const months = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
export const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const getColor = (engagedTime: any, generalTime: any) => {
    let resultColor = null as any;
    const defaultColor = {text: 'fff'};
    const engagePercent = (engagedTime / generalTime) * 100;

    const colorsScheme = {
        notWorking: {background: '454545'}, // чёрный - нерабочий день
        completelyFree: {background: 'eaeaea', text: '454545'}, // светло-серый - полностью свободный день
        engageDepended: [
            {boundPercent: 100, background: 'ff3758'},    // красный (100% занятость)
            {boundPercent: 66, background: 'ffb637'},     // оранжевый (>=66% занятость)
            {boundPercent: 33, background: 'eaed14'},     // жёлтый (>=33% занятость)
            {boundPercent: 0, background: '73cc00'}       // зелёный (>=0% занятость)
        ]
    };

    colorsScheme.engageDepended.sort((colorA, colorB) => colorB.boundPercent - colorA.boundPercent);

    if (!generalTime) {
        resultColor = colorsScheme.notWorking;
    } else if (!engagedTime) {
        resultColor = colorsScheme.completelyFree;
    } else {
        colorsScheme.engageDepended.forEach(color => {
            if (!resultColor && (engagePercent >= color.boundPercent)) {
                color = Helper.clone(color);
                delete color.boundPercent;

                resultColor = color;
                return false;
            }
        })
    }

    return Object.assign(defaultColor, resultColor)
};
