import {DentalCardTooth} from "../types";
import _ from "underscore";
import Helper from "../../../common/helpers/main";
import {childrenNotTeeth} from "./childrenNotTeeth";

export const selectedJaw = (config: {[key: string] : DentalCardTooth[]}, jaw: string) => {
    let configJaw = _.clone(config[jaw]);
    const isChildToothJaw = configJaw.some(tooth => Helper.TeethMap.isChildTooth(tooth.number));
    if (isChildToothJaw) configJaw = configJaw.filter(tooth => childrenNotTeeth.indexOf(tooth.number) === -1);
    return !configJaw.some(tooth => !tooth.selected);
}
