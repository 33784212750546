import * as React from 'react';
import {render} from 'react-dom';
import {compose, createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import thunk from 'redux-thunk';
import {BrowserRouter as Router} from 'react-router-dom'
import './index.scss';
import './assets/styles/vendor/jquery.mCustomScrollbar.css';
import App from './containers/App';
import ServerCommand from './common/server/server-command.js'
import Printer from './common/server/printer.js'
import moment from 'moment'
// @ts-ignore
import Helper from "common/helpers/main"
import {rootReducer} from "./redux/reducer";
import _ from 'lodash';
import {echo} from "./common/server/echo";
import CallFaker from "./common/server/CallFaker";


const win = window as any;

const store = createStore(rootReducer, compose(
    applyMiddleware(thunk),
    //win.__REDUX_DEVTOOLS_EXTENSION__ && win.__REDUX_DEVTOOLS_EXTENSION__()
));

const app = (
    <Provider store={store}>
        <Router>
            <App/>
        </Router>
    </Provider>
);

render(app, document.getElementById('root'));

win.ServerCommand = ServerCommand;
win.moment = moment;
win.Helper = Helper;
win.Printer = Printer;
win._ = _;
win.echo = echo;
win.CallFaker = CallFaker;

//server
/*window.React =  React;
window.ReactDOM =  ReactDOM;
window.App = App;
serviceWorker.unregister();*/
