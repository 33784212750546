import React from 'react'
import PropTypes from 'prop-types'

export default class InputText extends React.Component {

    static propTypes = {
        cell: PropTypes.object.isRequired,
        row: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired
    };

    render() {
        return (
            <span onClick={(e) => e.stopPropagation()}>
                    <input
                        type='text'
                        className="input table__input"
                        value={this.props.cell.content}
                        onChange={(e) => this.props.onChange(this.props.row.code, this.props.cell.code, e.target.value)}
                    />
                </span>
        )
    }
}