import {useSelector} from "react-redux";
import {useMemo} from "react";

export const defaultColor = '#447ce6';

const useDirectionsColors = () => {
    const users = useSelector(state => state.common.users);
    const usersCodes = Object.keys(users)
    return useMemo(() => {
        const usersColors: { [key: string]: string } = {};
        usersCodes.forEach((userId) => {
            const userColor = users[+userId].color
            usersColors[userId] = !!userColor ? userColor : defaultColor
        })
        return usersColors;
    }, [])
}

export default useDirectionsColors
