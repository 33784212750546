import React from "react";
import Helper from "../../../../common/helpers/main";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";

interface Props {
    timeStart: string
    timeEnd: string
}

export const VisitTime = ({timeStart, timeEnd}: Props) => {
    return (
        <LabelTask label="Время приёма"
                   children={`с ${Helper.Date.formatTime(timeStart)} до ${Helper.Date.formatTime(timeEnd)}`}/>
    )
}
