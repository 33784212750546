import React from 'react'
import PropTypes from 'prop-types'
import GroupsTree from "../../components/TableWithGroupsTree/GroupsTree";
import Table from "../../components/Table/Table";
import Helper from "common/helpers/main";

const materialResultColumns = [
    {
        title: 'Название',
        code: 'name'
    },
    {
        title: 'Количество',
        code: 'quantity',
        editable: true,
        type: 'number'
    }
];

export default class ProcedureMaterialsField extends React.Component {
    static propTypes = {
        value: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        materials: PropTypes.array.isRequired,
        groups: PropTypes.array.isRequired
    }

    state = {
        selectedMaterialId: 0
    }

    render() {
        const materials = Helper.Array.toObject(this.props.materials, 'id')

        let resultTableData = this.props.value.map((material) => {
            let attachedMaterial = Helper.clone(materials[material.id])
            attachedMaterial.quantity = material.quantity

            return attachedMaterial
        });

        return (
            <div className="popup__list">
                <GroupsTree groups={this.props.groups}
                            isMultipleSelect={true}
                            scrollHeight={224}
                            elements={this.props.materials}
                            getElementGroupId={procedure => procedure.groupId}
                            selectedElementsIds={this.props.value.map(material => material.id)}
                            onSelect={this.handleMaterialSelect}
                            withHeader={false}/>
                <div className="popup__result-list">
                    <div className="popup__result-table">
                        <Table data={resultTableData}
                               columns={materialResultColumns}
                               isEditable={true}
                               selectMode="single"
                               onSelect={(materialIds, selectedMaterialId) => this.setState({selectedMaterialId})}
                               selectedRows={[this.state.selectedMaterialId]}
                               onChange={(materialId, columnCode, quantity) => this.setMaterialQuantity(materialId, quantity)}
                               onRowDelete={materialId => this.setMaterialQuantity(materialId, 0)}
                               withHeader={false}/>
                    </div>
                </div>
            </div>
        )
    }

    setMaterialQuantity(materialId, quantity) {
        let newValue = [],
            bAdd = true

        this.props.value.forEach((material, index) => {
            if (material.id === materialId) {
                if (quantity > 0) {
                    newValue.push({id: materialId, quantity: quantity})
                }
                bAdd = false

            } else {
                newValue.push(material)
            }
        })

        if (bAdd) {
            newValue.push({id: materialId, quantity: quantity})
        }

        this.props.onChange(newValue)
    }

    handleMaterialSelect = (selectedItem, selectedItemsIds, thisIsElement) => {
        if (thisIsElement) {
            this.setMaterialQuantity(selectedItem.id, 1)
        }
    }
}
