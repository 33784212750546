import React from 'react';
import Box from '../Layout/Box';
import {
    GroupServices,
} from '../../pages/LaboratoryServiceRatesPage/containers/LaboratoryRatesTable/hooks/useLaboratoryRatesTable';
import GroupTreeItem from './GroupTreeItem';

interface Props {
    servicesGroups: GroupServices[];
    onClickGroup: (id: number) => void;
    selectedGroup?: GroupServices;
    showServices?: boolean
}

const GroupsTree = ({servicesGroups, onClickGroup, selectedGroup, showServices = false}: Props) => {

    return (
        <Box padding='9px 0 13px' display='flex' flexDirection='column' justifyContent='stretch'>
            {
                servicesGroups.map(group => {
                    return <GroupTreeItem group={group}
                                          onClickGroup={onClickGroup}
                                          selectedGroup={selectedGroup}
                                          showServices={showServices}
                    />;
                })
            }
        </Box>
    );

};

export default GroupsTree;
