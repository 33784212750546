import {useEffect, useState} from 'react';
import useLoader from '../../../components/Errors';
import {GetSalaryReportParams, SalaryReport, SelectedMonth} from '../types';
import {useSelector} from 'react-redux';
import ServerCommand from '../../../common/server/server-command';
import moment from 'moment';
import DateHelper from '../../../common/helpers/date-helper';

const useSalariesPage = () => {

    const [loaderPage, setLoaderPage] = useLoader(true);
    const [loaderTable, setLoaderTable] = useLoader(false)

    const branches = useSelector(state => state.user.branches)

    const [selectedMonth, setSelectedMonth] = useState<SelectedMonth>({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    })
    const [selectedBranches, setSelectedBranches] = useState<number[]>(branches)

    const [salaryReport, setSalaryReport] = useState<SalaryReport>({categories: []})

    const onChangeMonth = (value: string) => setSelectedMonth((prevState) => (
        {...prevState, month: +value}
    ))
    const onChangeYear = (value: string) => {value.length < 5 && setSelectedMonth((prevState) => (
        {...prevState, year: +value}
    ))}

    const params = {
        month: String(moment(`${selectedMonth.year}-${selectedMonth.month}`).format(DateHelper.FORMAT_SERVER_MONTH)),
        branches: selectedBranches
    } as GetSalaryReportParams

    const getResponse = () => {
        setLoaderTable(true)
        ServerCommand
            .get('salary/report', params)
            .then((response: SalaryReport) => {
                setSalaryReport(response)
                setLoaderPage(false)
                setLoaderTable(false)
            })
    }

    useEffect(() => {
        String(selectedMonth.year).length === 4 && getResponse()

    }, [selectedMonth, selectedBranches])


    return {
        getResponse,
        loaderPage,
        loaderTable,
        selectedMonth,
        selectedBranches,
        salaryReport,
        setSalaryReport,
        onChangeMonth,
        onChangeYear,
        setSelectedBranches
    }
};

export default useSalariesPage;
