import {TaskCallCenter} from "../types/common";

const prepareIsDeadline = (allDeadline: boolean, task: TaskCallCenter, currentDate: any) => {
    let isDeadline = false;
    if (!allDeadline) {
        isDeadline = new Date(task.deadline as any).getTime() <= new Date(currentDate).getTime()
    }
    return isDeadline
}

export default prepareIsDeadline
