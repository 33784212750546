import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import ChartLine from "../../../../../components-ui/Charts/ChartLine";
import { dataLine } from "./data/data";
import {useSelector} from "react-redux";
import useDirectionsColors from "../../../../../common/hooks/useDirectionsColors";
import Helper from "../../../../../common/helpers/main";

export default function LinePage() {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [activeCell, setActiveCell] = useState<string | number | null>('therapy');
    const directions = useSelector(state => state.common.directions)
    const directionsColors = useDirectionsColors()
    return (
        <>
            <h1>Line</h1>
            <WrapContent>
                <ChartLine
                    data={dataLine
                        .sort((a, b) => directions[a.name].sort - directions[b.name].sort)
                        }
                    activeCell={activeCell}
                    hoverIndex={activeIndex}
                    colors={directionsColors}
                    height={180}
                    width={404}
                    formatterCode={code => Helper.Date.formatDate(code, 'MM/YY')}
                    formatterValue={value => Helper.String.numberFormat(value, 0)}
                    formatterName={name => directions[name].name}
                />
                <pre>
                    {
                        `
const [activeIndex, setActiveIndex] = useState(-1);
const [activeCell, setActiveCell] = useState<string | number | null>('therapy');
const directions = useSelector(state => state.common.directions)
const directionsColors = useDirectionsColors()

<ChartLine
    data={dataLine
        .sort((a, b) => directions[a.name].sort - directions[b.name].sort)
        }
    activeCell={activeCell}
    setActiveIndex={setActiveIndex}
    hoverIndex={activeIndex}
    setActiveCell={setActiveCell}
    colors={directionsColors}
    height="300px"
    width="90%"
    aspect={3.3}
    formatterCode={code => Helper.Date.formatDate(code, 'MMM YYг.')}
    formatterValue={value => Helper.String.numberFormat(value, 0)}
    formatterName={name => directions[name].name}
/>
                        `
                    }
                </pre>
            </WrapContent>
        </>
    )
}
