
export const  graphWeeks: GraphDay[] = [
    {name: 'Еженедельно', code: 'all'},
    {name: 'Четная неделя', code: 'even'},
    {name: 'Нечетная', code: 'odd'}
];

export const  graphDays: GraphDay[] = [
    {name: 'Пн', code: '1'},
    {name: 'Вт', code: '2'},
    {name: 'Ср', code: '3'},
    {name: 'Чт', code: '4'},
    {name: 'Пт', code: '5'},
    {name: 'Сб', code: '6'},
    {name: 'Вс', code: '7'},
];

export interface GraphDay {
    name: string,
    code: string
}
