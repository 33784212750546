import React from 'react';
import TaskContent from "../TaskContent";
import {TaskCallCenter} from "../../../../types/common";
import {NoteDownButton, AddWLButton, FinalButton} from "../../../../components/Buttons";
import {CommonTaskProps} from "../../types/common";
import {BackCallData} from "../../../../types/taskData";
import LabelTask from "../../../../../../containers/Tasks/components/UI/LabelTask";


interface Props extends CommonTaskProps {
    task: TaskCallCenter<BackCallData>
}

export const BackCall = ({task, onChangeSelectedScript, ...props}: Props) => {
    return (
        <TaskContent onChangeSelectedScript={onChangeSelectedScript}
                     buttons={<Buttons task={task} {...props} />}
                     task={task}>
            {!!task.payload.doctorName && (
                <LabelTask label="Врач">
                    <span>{task.payload.doctorName}</span>
                </LabelTask>
            )}
        </TaskContent>
    );
};

const Buttons = ({task, validate, blockedButtons, personalPhone, methodsCallCenter}: Omit<Props, 'onChangeSelectedScript'>) => {
    return (
        <>
            <NoteDownButton validate={validate} task={task} blockedButtons={blockedButtons}
                            personalPhone={personalPhone} methodsCallCenter={methodsCallCenter}/>
            <AddWLButton task={task} personalPhone={personalPhone} blockedButtons={blockedButtons}
                         methodsCallCenter={methodsCallCenter}/>
            <FinalButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
        </>
    )
}
