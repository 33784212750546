import React, {forwardRef, useMemo} from "react";
import styled from "styled-components";
import Helper from "../../../common/helpers/main";
import {Props} from "./types";
import {getAsTypography} from "./utils/getAsTypography";
import {getFontSize} from "./utils/getFontSize";
import {getFontWeight} from "./utils/getFontWeight";
import {getLineHeight} from "./utils/getLineHeight";
import {getFontStyle} from "./utils/getFontStyle";
import {getColorStyle} from "./utils/getColorStyle";
import {getCursorTypography} from "./utils/getCursorTypography";
import {getDisplayTypography} from "./utils/getDisplayTypography";
import {getTextDecorationTypography} from "./utils/getTextDecorationTypography";

const Typography = forwardRef(({as, variant, onClick, ...props}: Props, ref: any) => {
    const currentAs = useMemo(() => getAsTypography(as, variant), [])
    return (
        <STypography {...props} as={currentAs} currentAs={currentAs} variant={variant}
                     ref={ref}
                     onClick={((e: any) => {
                         if (!!onClick) onClick(e)
                     })}
        />
    )
})

const STypography = styled.div<Props>`
  font-size: ${getFontSize};
  font-weight: ${getFontWeight};
  font-stretch: normal;
  line-height: ${getLineHeight};
  font-style: ${getFontStyle};
  letter-spacing: normal;
  color: ${getColorStyle};
  display: ${getDisplayTypography};
  cursor: ${getCursorTypography};
  transition: all .15s;
  margin: ${props => !!props.margin ? props.margin : '0'};
  padding: ${props => !!props.padding ? props.padding : '0'};
  ${props => !!props.textTransform && `text-transform: ${props.textTransform};`};
  ${props => !!props.whiteSpace && `white-space: ${props.whiteSpace};`};
  ${props => !!props.textAlign && `text-align: ${props.textAlign};`};
  ${props => !!props.width && `width: ${props.width};`};
  ${props => !!props.height && `height: ${props.height};`};
  ${props => !!props.minWidth && `min-width: ${props.minWidth};`};
  ${props => props.flexShrink !== undefined && `flex-shrink: ${props.flexShrink};`};
  ${props => !!props.justifyContent && `justify-content: ${props.justifyContent};`};
  ${props => !!props.alignItems && `align-items: ${props.alignItems};`};
  ${props => !!props.textDecoration && getTextDecorationTypography(props)}
  ${props => !!props.borderName && `
        text-decoration: underline;
        text-decoration-color: rgba(${Helper.Color.hexToRgb(getColorStyle(props))}, 0.3);
        text-decoration-style: ${props.borderName};
        text-decoration-thickness: from-font;
        text-underline-offset: 3px;
        &:hover {
            text-decoration-color: rgba(${Helper.Color.hexToRgb(getColorStyle(props))}, 0.6);
        }
    `}
  ${props => !!props.isLink && `
        text-decoration: underline;
        text-decoration-color: rgba(${Helper.Color.hexToRgb(getColorStyle(props))}, 0.3);
        text-decoration-style: dashed;
        text-decoration-thickness: from-font;
        text-underline-offset: 3px;
        &:hover {
            text-decoration-color: rgba(${Helper.Color.hexToRgb(getColorStyle(props))}, 0.6);
        }
    `};
  ${props => !!props.unSelectable && `
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `};
  ${props => !!props.borderedColor && `
        border: 1px solid rgba(${
          Helper.Color.hexToRgb(props.theme?.colors[props.borderedColor])}, 1);
        background: rgba(${
          Helper.Color.hexToRgb(props.theme?.colors[props.borderedColor])}, 0.3);
        padding: 0 5px;
        border-radius: 5px;
   `};
  ${props => !!props.isPartHidden && `
        white-space: nowrap;
        overflow: hidden; 
        text-overflow: ellipsis;
  `};
`

export default Typography
