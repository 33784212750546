import React, {Dispatch, SetStateAction} from "react";
import Tabs from "../../../../../components-ui/Navigation/Tabs/Tabs";
import {headerTabsDetail} from "../../../helper/getDataCallCenter";
import {Divider} from "../../../../../components-ui/DataDisplay/Divider";
import ServerCommand from "../../../../../common/server/server-command";
import {StateCallCenter} from "../../../types/components";
import {StateDetailed} from "../hooks/useDetailed";
import { SContainerItem } from "../../../../../containers/Tasks/style";

interface Props {
    state: StateCallCenter
    detailedState: StateDetailed
    setLoaderHistory: Dispatch<SetStateAction<boolean>>
    setDetailedState: Dispatch<SetStateAction<StateDetailed>>
}

const TabsHeader = ({state, detailedState, setLoaderHistory, setDetailedState}: Props) => {
    const {detail} = state;
    const onChangeTabDetail = (headerTab: string | number | any) => {
        setDetailedState(prevState => ({...prevState, headerTab}))
        if (headerTab === 'history') {
            setLoaderHistory(true)
            ServerCommand.get('call-center/completed-call/list', {phoneNumber: detail?.phone})
                .then(response => {
                    setDetailedState(prevState => ({
                        ...prevState,
                        historyData: {
                            ...prevState.historyData,
                            ...response
                        }
                    }))
                    setLoaderHistory(false)
                })
        }
    }
    return (
        <>
            <SContainerItem>
                <Tabs tabs={headerTabsDetail} activeTab={detailedState.headerTab} onChange={onChangeTabDetail}
                      noLeftMargin={true}/>
            </SContainerItem>
            <Divider/>
        </>
    )
}

export default TabsHeader
