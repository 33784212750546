import React from 'react';
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";

interface Props {
    workloadPercent: number
}

export const WorkloadPercent = ({workloadPercent}: Props) => {

    return (
        <LabelTask label="Загруженность"
                   children={`${workloadPercent}%`}
                   alignItems="flex-start"
        />
    );
};
