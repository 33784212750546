import React, {forwardRef} from 'react';
import {SWrapChip, STextChip, SWrapIcon} from './style'
import {Props} from "./types";
import Icon from "../Icon";

export const Chip = forwardRef((
    {
        children,
        onClickIcon,
        color,
        bg,
        iconName = 'close',
        margin,
        colorIcon = 'danger'
    }: Props, ref: any) => {
    return (
        <SWrapChip ref={ref} bg={bg} margin={margin}>
            <STextChip children={children} color={color}/>
            {!!onClickIcon && (
                <SWrapIcon onClick={onClickIcon}>
                    <Icon name={iconName} color={colorIcon} width="8px" height="8px"/>
                </SWrapIcon>
            )}
        </SWrapChip>
    );
});
