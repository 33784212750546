import React from "react";
import Helper from "../../../../../../common/helpers/main";
import {usePatientField} from "../../../PatientField";
import PhoneView from "./components/PhoneView";
import useSortSubFields from "../../hooks/useSortSubFields";

export default function ViewComposite() {
    const {field, value} = usePatientField()
    let children = null
    const subFields = useSortSubFields()
    switch (field.name) {
        case 'fio':
            children = Helper.getFullName(value)
            break;
        case 'phone':
            children = <PhoneView/>
            break;
        case 'address':
        default:
            children = subFields.map(fieldName => value[fieldName]).filter(fieldValue => !!fieldValue).join(', ');
            break;
    }
    return (
        <>
            {children}
        </>
    )
}
