import {useMemo} from "react";
import {InfoManagerTasks} from "../types/components";
import {NoticesQuantity, TasksInfo} from "../../../containers/Tasks";
import _ from "lodash";
import prepareGroupsButtonName from "../../../containers/Tasks/utils/prepareGroupsButtonName";

interface Props {
    infoManagerTasks: InfoManagerTasks
    tasksInfo: TasksInfo,
    noticesQuantity: NoticesQuantity
}

const useGroupsButtonName = ({infoManagerTasks, tasksInfo, noticesQuantity}: Props) => {
    return useMemo(() => {
        const allTypes = _.cloneDeep(infoManagerTasks.types);
        allTypes['all'] = {
            priority: 1000000,
            code: 'all'
        }
        const cloneAmount = Object.keys(noticesQuantity).filter(item => !!allTypes[item]).sort((a, b) => allTypes[b].priority - allTypes[a].priority);

        return prepareGroupsButtonName({cloneAmount, tasksInfo})
    }, [Object.keys(noticesQuantity).length])
}

export default useGroupsButtonName
