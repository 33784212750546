import {useMemo} from "react";
import {IColumn, IGroup, IRow} from "../types/common";
import _ from "lodash";
import Group from "../../../../common/helpers/group-helper";
import {StateSortTable} from "./useSortTable";

interface Props {
    rows: IRow[]
    groups?: IGroup[]
    fieldNameGroupId?: string
    highlightedGroupsIds?: (number|string)[]
    sortBy?: string
    stateColumnSort: StateSortTable
    columns: IColumn[]
    isNested?: boolean
}

function compareAsc(a: any, b: any) {
    if (a > b) {
        return -1;
    }
    if (a < b) {
        return 1;
    }
    return 0;
}

function compareDesc(a: any, b: any) {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}

export default function usePrepareRows({rows, groups, fieldNameGroupId, highlightedGroupsIds, sortBy, stateColumnSort, columns, isNested}: Props) {
    return useMemo(() => {
        const nameGroupId = fieldNameGroupId || 'groupId'
        let objGroups = (!!groups ? Group.expandTree(groups) : {}) as {[key: string]: IGroup};

        const getRootParentGroup = (groupId: number, groups: {[key: string]: IGroup}) : IGroup | null => {
            let result = null;
            const group = groups[groupId];

            if (!!group) {
                if (group.groupId) {
                    result = getRootParentGroup(group.groupId, groups);
                } else {
                    result = group;
                }
            }
            return result;
        }

        const compareSections = (a: any, b: any) => {
            if (a.sort - b.sort) {
                return a.sort - b.sort;
            } else {
                return a.name.localeCompare(b.name);
            }
        };

        let mapRows = rows.map(row => {
            if (!!groups) {
                row[nameGroupId] = getRootParentGroup(row[nameGroupId], objGroups)?.id
                row.isHighlighted = !!highlightedGroupsIds ? highlightedGroupsIds.includes(row[nameGroupId]) : false
            }
            return row
        })

        const valuesStateColumnSort = Object.values(stateColumnSort)

        if (!!valuesStateColumnSort.length && valuesStateColumnSort.some(value => value !== null)) {
            _.forEach(stateColumnSort, (value, key) => {
                if (value !== null) {
                    mapRows = mapRows.map(row => {
                        columns.forEach(column => {
                            let childrenCell = row[column.field];
                            if (!!column.sortFormatter) {
                                childrenCell = column.sortFormatter({
                                    childrenCell, row, isNested
                                });
                                if (!!column.sortType) {
                                    if (column.sortType === "number" && typeof childrenCell === "string") {
                                        childrenCell = +childrenCell.replace(/\s+/g, '')
                                    }
                                    if (column.sortType === 'string') {
                                        childrenCell = String(childrenCell).toUpperCase()
                                    }
                                }
                            }
                            if (typeof childrenCell === 'string' || typeof childrenCell === 'number') {
                                row[column.field + 'Formatter'] = childrenCell
                            } else {
                                row[column.field + 'Formatter'] = row[column.field]
                            }
                        })
                        return row
                    })
                    mapRows = mapRows.sort((a, b) => value ? compareAsc(a[key + 'Formatter'], b[key + 'Formatter']) : compareDesc(a[key + 'Formatter'], b[key + 'Formatter']))
                }
            })
        }



        mapRows = mapRows.sort((rowA, rowB) => {
            let compareResult;
            if (!!groups) {
                if (rowA.isHighlighted - rowB.isHighlighted) {
                    return rowA.isHighlighted - rowB.isHighlighted;
                }

                compareResult = compareSections(objGroups[rowA[nameGroupId]], objGroups[rowB[nameGroupId]]);
                if (compareResult) {
                    return compareResult;
                }
            }
            if (sortBy) {
                return rowA[sortBy] - rowB[sortBy];
            }
            return 0;
        })



        if (!!groups) {
            return _.uniqBy(mapRows, nameGroupId).map(uniqRow => {
                const group = getRootParentGroup(uniqRow[nameGroupId], Group.expandTree(groups))
                return {
                    group: !!group ? group : {
                        name: 'Без группы',
                        sort: -1
                    },
                    rows: rows.filter(row => row[nameGroupId] === uniqRow[nameGroupId])
                }
            })
        } else {
            return [{rows: mapRows}]
        }
    }, [JSON.stringify(rows), stateColumnSort]) as {rows: IRow[], group?: IGroup}[]
}
