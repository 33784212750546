import React from "react";
import commonLabelOptions from "../../../../../../../containers/PatientField/helper/commonLabelOptions";
import Select from "../../../../../../../components-ui/Inputs/Select/Select";
import {Label} from "../../../../../../../components-ui/DataDisplay/Label";
import {useWAP} from "../../../WaitingAndPlanned";
import { timesSelectedList } from "../../../helper/timesSelectedList";
import Counter from "../../../../../../../components-ui/Inputs/Counter/Counter";

export default function TimeFormWAP() {
    const {form, onChangeField} = useWAP()
    const {timeSelected, timeCounter} = form;
    return (
        <Label labelText='Время'
               {...commonLabelOptions(true, 'WAP')}>
            <Select options={timesSelectedList}
                    value={timeSelected}
                    widthSelect="334px"
                    onChange={value => onChangeField(value, 'timeSelected')}/>
            {timeSelected.value === 'other' && (
                <Counter value={timeCounter} onChange={value => onChangeField(value, 'timeCounter')}
                         min={1} max={28} margin="0 0 0 20px" size="md"/>
            )}

        </Label>
    )
}
