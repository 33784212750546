import {NamesSvg} from "../../../components-ui/DataDisplay/Icon/types/common";
import {ReportCode} from "../types";
import {Direction} from "../../../types";

export interface WidgetInfo {
    iconName: NamesSvg
    title: string
    code: ReportCode
}

export const widgetsInfo: WidgetInfo[] = [
    {
        iconName: 'money',
        title: 'Поступление денег в клинику',
        code: 'receipts',
    },
    {
        iconName: 'averageCheck',
        title: 'Средний чек',
        code: 'average_check',
    },
    {
        iconName: 'loadingTheClinic',
        title: 'Загрузка клиники',
        code: 'clinic_loading',
    },
    {
        iconName: 'analyticsCalls',
        title: 'Звонки',
        code: 'calls'
    },
    {
        iconName: 'callEfficiency',
        title: 'Эффективность звонков',
        code: 'calls_efficiency'
    },
    {
        iconName: 'efficiencyOfVisits',
        title: 'Эффективность посещений',
        code: 'visits'
    },
    {
        iconName: 'message',
        title: 'Эффективность консультаций и лечения',
        code: 'consultations'
    }
]

export const clinicInfo: Direction = {
    code: 'clinic',
    default: false,
    name: 'Клиника',
    payMode: 'after_performing',
    sort: 1,
    tip: ''
}
