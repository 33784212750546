import _ from "lodash";
import useNewValidateForm from "../../../../../containers/PatientField/hooks/useNewValidateForm";
import {usePreparationForAdmission} from "../PreparationForAdmission";

export default function useClickIsValidate() {
    const {setForms, fields, setState} = usePreparationForAdmission();
    const prepareNewValidationFields = useNewValidateForm({fields})

    const onClickLinkValidateForm = () => {
        setForms(prevForms => {
            const isValid = {} as {[key: string]:boolean}
            _.forEach(prevForms, (form, formName) => {
                const validationFields = prepareNewValidationFields(form.basic)
                prevForms[formName].basic.validationFields = validationFields
                isValid[formName] = !_.values(validationFields).some(fieldName => fieldName)
            })

            setState(prevState => {
                if (!!prevState.currentTab && isValid[prevState.currentTab]) {
                    _.forEach(isValid, (value, tabName) => {
                        if (!value) prevState.currentTab = tabName
                    })
                }
                return {
                    ...prevState
                }
            })

            return {
                ...prevForms
            }
        })
    }

    const onClickLinkValidateCheckboxes = () => {
        setState(prevState => {
            _.forEach(prevState.documentRequired, (value, checkboxName) => {
                if (value) {
                    prevState.checkboxes[checkboxName] = value
                }
            })
            return {
                ...prevState
            }
        })
    }

    return {
        onClickLinkValidateForm,
        onClickLinkValidateCheckboxes
    }
}
