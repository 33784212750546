import styled from "styled-components";


interface IAutocompleteButton {
    active: boolean
}

export const AutocompleteButton = styled.button.attrs({type: "button", className: 'AutocompleteButton'})<IAutocompleteButton>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    & > div {
        width: 10px;
        height: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        fill: ${props => props.theme.colors.textGray};
        transition: fill .2s;
        transform: rotate(${props => props.active ? '180deg' : 0});
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    &:hover {
      & > div {
        fill: ${props => props.theme.colors.textDarkGray};
      }
    }
`
