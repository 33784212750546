import React, {Dispatch, SetStateAction, useState} from 'react';
import {SalaryReportCategory} from '../../types';
import {IColumn, IRow} from '../../../../components-ui/DataDisplay/Table';
import Box from '../../../../components-ui/Layout/Box';
import Typography from '../../../../components-ui/DataDisplay/Typography/Typography';
import {Directions, ServicesNames} from '../../../../types';
import {CustomCell, InnerTable} from '../../../../components-ui/DataDisplay/Table/style/scrollStyledComponents';
import TableScrollHeaderComponent from './components/TableScrollHeaderComponent';
import PriceHelper from '../../../../common/helpers/price-helper';
import {WIDTH_INNER_TABLE_COLUMNS} from './common';

interface Props {
    category: SalaryReportCategory;
    directions: Directions;
    services: ServicesNames;
    xrayPictureTypes: {[key: string]: string};
    showRowDetail: (row: IRow, branchId: number, isShow: boolean) => void
}

const formulaTypesColumns = ({category, directions, services, xrayPictureTypes, showRowDetail}: Props) => {

    // const [showDetail, setShowDetail] = useState(null);
    // const showDetail = false;

    const uniqColumns: IColumn[] = [];

    const scrollClassName = `${category.code}scrollCmponent`;

    const handleScroll = (scrollPosition: number) => {
        document.querySelectorAll(`.${scrollClassName}`).forEach((item) => {
            item.scrollLeft = scrollPosition;
        });
    }
        category.formulaTypes.forEach(type => {
            switch (type) {
                case 'working_days':
                    uniqColumns.push(
                        {
                            field: 'working_days_rate',
                            headerName: 'Ставка за рабочий день',
                            width: WIDTH_INNER_TABLE_COLUMNS.working_days_rate,
                            valueFormatter: ({row}) =>
                                <CustomCell width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return formulaResult ? (
                                            <Typography >
                                                {PriceHelper.format(formulaResult.details.rate)}
                                            </Typography>
                                        ) : <Box />
                                    })}
                                </CustomCell>,

                        },
                        {
                            field: 'working_days_sum',
                            headerName: 'Сумма за рабочие дни',
                            width: WIDTH_INNER_TABLE_COLUMNS.working_days_sum,
                            valueFormatter: ({row}) =>
                                <CustomCell width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return formulaResult ? (
                                            <Typography >
                                                {PriceHelper.format(
                                                    formulaResult.details.workingDays * formulaResult.details.rate
                                                )}
                                            </Typography>
                                        ) : <Box />
                                    })}
                                </CustomCell>,

                        },
                    );
                    break;
                case 'working_hours':
                    uniqColumns.push(
                        {
                            field: 'working_hours_rate',
                            headerName: 'Часовая ставка',
                            width: WIDTH_INNER_TABLE_COLUMNS.working_hours_rate,
                            valueFormatter: ({row}) =>
                                <CustomCell width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return (
                                            formulaResult ? (
                                                <Typography>
                                                    {PriceHelper.format(formulaResult.details.rate)}
                                                </Typography>
                                            ) : <Box />
                                        )
                                    })}
                                </CustomCell>,

                        },
                        {
                            field: 'working_hours_count',
                            headerName: 'Отработано часов',
                            width: WIDTH_INNER_TABLE_COLUMNS.working_hours_count,
                            valueFormatter: ({row}) =>
                                <CustomCell width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return (
                                            formulaResult ? (
                                                <Typography >
                                                    {formulaResult.details.workingHours}
                                                </Typography>
                                            ) : <Box />
                                        )
                                    })}
                                </CustomCell>,

                        },
                    );
                    break;
                case 'fixed':
                    uniqColumns.push(
                        {
                            field: 'fixed',
                            headerName: 'Оклад',
                            width: WIDTH_INNER_TABLE_COLUMNS.fixed,
                            valueFormatter: ({row}) =>
                                <CustomCell width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return (
                                            formulaResult ? (
                                                <Typography >
                                                    {PriceHelper.format(formulaResult.salarySum)}
                                                </Typography>
                                            ) : <Box />
                                        )
                                    })}
                                </CustomCell>,

                        },
                    );
                    break;
                case 'services':
                    uniqColumns.push(
                        {
                            field: 'services',
                            headerName: 'Направление',
                            width: WIDTH_INNER_TABLE_COLUMNS.services,
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return (
                                            <CustomCell width="100%">
                                                {formulaResult ? Object.keys(formulaResult.details).map(direction =>
                                                    formulaResult.details[direction] ?
                                                        <Typography >
                                                            {directions[direction].name}
                                                        </Typography> : <Box/>,
                                                ) : <Box />}
                                            </CustomCell>
                                        );
                                    })}
                                </CustomCell>,

                        },
                        {
                            field: 'services_rate_percent',
                            headerName: 'Ставка за услуги',
                            width: WIDTH_INNER_TABLE_COLUMNS.services_rate_percent,
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return (
                                            <CustomCell width='100%' padding='0'>
                                                {formulaResult ? Object.keys(formulaResult.details).map(direction =>
                                                    formulaResult.details[direction] ?
                                                        <Typography >
                                                            {formulaResult.details[direction].ratePercent}%
                                                        </Typography> : <Box />,
                                                ) : <Box />}
                                            </CustomCell>
                                        )
                                    })}
                                </CustomCell>,

                        },
                        {
                            field: 'services_sum',
                            headerName: 'Сумма услуг',
                            width: WIDTH_INNER_TABLE_COLUMNS.services_sum,
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return (
                                            <CustomCell width='100%' padding='0'>
                                                {formulaResult ? Object.keys(formulaResult.details).map(direction =>
                                                    formulaResult.details[direction] ?
                                                        <Typography >
                                                            {PriceHelper.format(formulaResult.details[direction].servicesSum)}
                                                        </Typography> : <Box />,
                                                ) : <Box />}
                                            </CustomCell>
                                        )
                                    })}
                                </CustomCell>,

                        },
                        {
                            field: 'services_costs',
                            headerName: 'Расходы',
                            width: WIDTH_INNER_TABLE_COLUMNS.services_costs,
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return (
                                            <CustomCell width='100%' padding='0'>
                                                {formulaResult ? Object.keys(formulaResult.details).map(direction =>
                                                    formulaResult.details[direction] ?
                                                        <Typography >
                                                            {PriceHelper.format(formulaResult.details[direction].costs)}
                                                        </Typography> : <Box />,
                                                ) : <Box />}
                                            </CustomCell>
                                        )
                                    })}
                                </CustomCell>,

                        },
                    );
                    break;
                case 'group_services':
                    uniqColumns.push(
                        {
                            field: 'services',
                            headerName: 'Услуга',
                            width: WIDTH_INNER_TABLE_COLUMNS.servicesName,
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return (
                                            <CustomCell width="100%">
                                                {formulaResult ? (
                                                    !!row.branches[branchId].showDetail ? Object.keys(formulaResult.details).map(service =>
                                                        formulaResult.details[service] ?
                                                            <Typography isPartHidden={true} title={services[parseInt(service)]}>
                                                                {services[parseInt(service)]}
                                                            </Typography> : <Box/>,
                                                    ) : (
                                                        <Typography isLink={true} color={'info'} onClick={() => showRowDetail(row, parseInt(branchId), true)}>Подробнее</Typography>
                                                    )
                                                ) : <Box />}
                                            </CustomCell>
                                        )
                                    })}

                                </CustomCell>,

                        },
                        {
                            field: 'services_rate_percent',
                            headerName: 'Ставка за услуги',
                            width: WIDTH_INNER_TABLE_COLUMNS.services_rate_percent,
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return (
                                            <CustomCell width='100%' padding='0'>
                                                {(formulaResult && !!row.branches[branchId].showDetail) ? Object.keys(formulaResult.details).map(service =>
                                                    formulaResult.details[service] ?
                                                        <Typography>
                                                            {formulaResult.details[service].ratePercent ?
                                                                formulaResult.details[service].ratePercent + '%'
                                                                :
                                                                formulaResult.details[service].rate + ''
                                                            }
                                                        </Typography> : <Box/>,
                                                ) : <Box/>}
                                            </CustomCell>
                                        )
                                    })}
                                </CustomCell>,

                        },
                        {
                            field: 'services_sum',
                            headerName: 'Сумма услуг',
                            width: WIDTH_INNER_TABLE_COLUMNS.services_sum,
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return (
                                            <CustomCell width='100%' padding='0'>
                                                {(formulaResult && !!row.branches[branchId].showDetail) ? Object.keys(formulaResult.details).map(service =>
                                                    formulaResult.details[service] ?
                                                        <Typography>
                                                            {PriceHelper.format(formulaResult.details[service].serviceSum)}
                                                        </Typography> : <Box/>,
                                                ) : <Box/>}
                                            </CustomCell>
                                        )
                                    })}
                                </CustomCell>,

                        },
                        {
                            field: 'services_costs',
                            headerName: 'Расходы',
                            width: WIDTH_INNER_TABLE_COLUMNS.services_costs,
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId => {
                                        const formulaResult = row.branches[branchId].formulas[type];

                                        return (
                                            <CustomCell width='100%' padding='0'>
                                                {(formulaResult && !!row.branches[branchId].showDetail) ? Object.keys(formulaResult.details).map(direction =>
                                                    formulaResult.details[direction] ?
                                                        <Typography>
                                                            {PriceHelper.format(formulaResult.details[direction].costs)}
                                                        </Typography> : <Box/>,
                                                ) : <Box/>}
                                            </CustomCell>
                                        )
                                    })}
                                </CustomCell>,

                        },
                    );
                    break;
                case 'doctor_recommendations':
                    uniqColumns.push(
                        {
                            field: 'doctor_recommendations_rate',
                            headerName: 'Ставка за рекомендации',
                            width: WIDTH_INNER_TABLE_COLUMNS.doctor_recommendations_rate,
                            valueFormatter: ({row}) =>
                                <CustomCell width='100%' padding='0 0 0 1px'>
                                    {Object.keys(row.branches).map(branchId =>
                                        row.branches[branchId].formulas[type]?.details?.ratePercent ?
                                            <Typography >
                                                {row.branches[branchId].formulas[type].details.ratePercent}%
                                            </Typography> : <Box />,
                                    )}
                                </CustomCell>,

                        },
                        {
                            field: 'doctor_recommendations_sum',
                            headerName: 'Сумма по рекомендациям',
                            width: WIDTH_INNER_TABLE_COLUMNS.doctor_recommendations_sum,
                            valueFormatter: ({row}) =>
                                <CustomCell width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId =>
                                        row.branches[branchId].formulas[type]?.salarySum ?
                                            <Typography >
                                                {PriceHelper.format(row.branches[branchId].formulas[type].salarySum)}
                                            </Typography> : <Box />,
                                    )}
                                </CustomCell>,

                        },
                    );
                    break;
                case 'xray_pictures':
                    uniqColumns.push(
                        {
                            field: 'xray_pictures_type',
                            width: WIDTH_INNER_TABLE_COLUMNS.xray_pictures_type,
                            headerName: 'Тип снимка',
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0 0 0 1px'>
                                    {Object.keys(row.branches).map(branchId =>
                                        <CustomCell width='100%' padding='0'>
                                            {
                                                row.branches[branchId].formulas[type] ?
                                                    Object.keys(row.branches[branchId].formulas[type].details).map(xRayType =>
                                                        <Typography >
                                                            {xrayPictureTypes[xRayType]}
                                                        </Typography>,
                                                    ) : <Box />
                                            }
                                        </CustomCell>,
                                    )}
                                </CustomCell>,

                        },
                        {
                            field: 'xray_pictures_rate',
                            headerName: 'Ставка за снимок',
                            width: WIDTH_INNER_TABLE_COLUMNS.xray_pictures_rate,
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId =>
                                        <CustomCell width='100%' padding='0'>
                                            {
                                                row.branches[branchId].formulas[type] ?
                                                    Object.keys(row.branches[branchId].formulas[type].details).map(xRayType =>
                                                        <Typography >
                                                            {PriceHelper.format(row.branches[branchId].formulas[type].details[xRayType].rate)}
                                                        </Typography>,
                                                    ) : <Box />
                                            }
                                        </CustomCell>,
                                    )}
                                </CustomCell>,

                        },
                        {
                            field: 'xray_pictures_count',
                            headerName: 'Кол-во снимков',
                            width: WIDTH_INNER_TABLE_COLUMNS.xray_pictures_count,
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId =>
                                        <CustomCell width='100%' padding='0'>
                                            {
                                                row.branches[branchId].formulas[type] ?
                                                    Object.keys(row.branches[branchId].formulas[type].details).map(xRayType =>
                                                        <Typography >
                                                            {row.branches[branchId].formulas[type].details[xRayType].count}
                                                        </Typography>,
                                                    ) : <Box />
                                            }
                                        </CustomCell>,
                                    )}
                                </CustomCell>,

                        },
                        {
                            field: 'xray_pictures_sum',
                            headerName: 'Сумма за снимки',
                            width: WIDTH_INNER_TABLE_COLUMNS.xray_pictures_sum,
                            valueFormatter: ({row}) =>
                                <CustomCell isContainerCell={true} width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId =>
                                        <CustomCell width='100%' padding='0'>
                                            {
                                                row.branches[branchId].formulas[type] ?
                                                    Object.keys(row.branches[branchId].formulas[type].details).map(xRayType =>
                                                        <Typography >
                                                            {PriceHelper.format(
                                                                row.branches[branchId].formulas[type].details[xRayType].rate *
                                                                row.branches[branchId].formulas[type].details[xRayType].count
                                                            )}
                                                        </Typography>,
                                                    ) : <Box />
                                            }
                                        </CustomCell>,
                                    )}
                                </CustomCell>,

                        },
                    );
                    break;
                case 'receipts':
                    uniqColumns.push(
                        {
                            field: 'receipts_rate',
                            headerName: 'Ставка от оборота',
                            width: WIDTH_INNER_TABLE_COLUMNS.receipts_rate,
                            valueFormatter: ({row}) =>
                                <CustomCell width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId =>
                                        !!row.branches[branchId].formulas[type] ?
                                            <Typography >
                                                {row.branches[branchId].formulas[type].details.ratePercent}%
                                            </Typography> : <Box />,
                                    )}
                                </CustomCell>,

                        },
                        {
                            field: 'receipts_sum',
                            headerName: 'Сумма за оборот',
                            width: WIDTH_INNER_TABLE_COLUMNS.receipts_sum,
                            valueFormatter: ({row}) =>
                                <CustomCell width='100%' padding='0'>
                                    {Object.keys(row.branches).map(branchId =>
                                        !!row.branches[branchId].formulas[type] ?
                                            <Typography >
                                                {PriceHelper.format(row.branches[branchId].formulas[type].salarySum)}
                                            </Typography> : <Box />,
                                    )}
                                </CustomCell>,

                        },
                    );
            }
        });

        const column: IColumn = {
            field: 'customRows',
            paddingHeaderCell: '',
            paddingCell: '',
            width: '19vw',
            customStyles: {flexGrow: 1},
            headerFormatter: () => <TableScrollHeaderComponent
                columns={uniqColumns.map(col => ({name: col.headerName, width: String(col.width)}))}
                scrollClassName={scrollClassName}
                handleScroll={handleScroll} />,
            valueFormatter: ({row, childrenCell}) => {
                return <InnerTable scrollClassName={scrollClassName} handleScroll={handleScroll}>
                    <Box display="flex" width="max-content" minWidth="100%">
                        {uniqColumns.map((col, index, cols) => col.valueFormatter &&
                            <Box width={String(col.width)} flexGrow={index + 1 === cols.length ? 1 : undefined}>
                                {col.valueFormatter({row, childrenCell})}
                            </Box>
                        )}
                    </Box>
                </InnerTable>
            }
        };

        return column;

};
export default formulaTypesColumns;
