import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const CashBoxIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={'0 0 64 64'} ref={ref}>
            <path fillRule="evenodd" clipRule="evenodd" d="M16 18C16 16.8954 16.8954 16 18 16H22.5V18L18 18V60H46V18H39.5V16H46C47.1046 16 48 16.8954 48 18V60C48 61.1046 47.1046 62 46 62H18C16.8954 62 16 61.1046 16 60V18Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M42 24H22V30H42V24ZM21 23V31H43V23H21Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26 34H22V38H26V34ZM22 33C21.4477 33 21 33.4477 21 34V38C21 38.5523 21.4477 39 22 39H26C26.5523 39 27 38.5523 27 38V34C27 33.4477 26.5523 33 26 33H22Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26 42H22V46H26V42ZM22 41C21.4477 41 21 41.4477 21 42V46C21 46.5523 21.4477 47 22 47H26C26.5523 47 27 46.5523 27 46V42C27 41.4477 26.5523 41 26 41H22Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26 50H22V54H26V50ZM22 49C21.4477 49 21 49.4477 21 50V54C21 54.5523 21.4477 55 22 55H26C26.5523 55 27 54.5523 27 54V50C27 49.4477 26.5523 49 26 49H22Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M34 34H30V38H34V34ZM30 33C29.4477 33 29 33.4477 29 34V38C29 38.5523 29.4477 39 30 39H34C34.5523 39 35 38.5523 35 38V34C35 33.4477 34.5523 33 34 33H30Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M34 42H30V46H34V42ZM30 41C29.4477 41 29 41.4477 29 42V46C29 46.5523 29.4477 47 30 47H34C34.5523 47 35 46.5523 35 46V42C35 41.4477 34.5523 41 34 41H30Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M34 50H30V54H34V50ZM30 49C29.4477 49 29 49.4477 29 50V54C29 54.5523 29.4477 55 30 55H34C34.5523 55 35 54.5523 35 54V50C35 49.4477 34.5523 49 34 49H30Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M42 34H38V38H42V34ZM38 33C37.4477 33 37 33.4477 37 34V38C37 38.5523 37.4477 39 38 39H42C42.5523 39 43 38.5523 43 38V34C43 33.4477 42.5523 33 42 33H38Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M42 42H38V46H42V42ZM38 41C37.4477 41 37 41.4477 37 42V46C37 46.5523 37.4477 47 38 47H42C42.5523 47 43 46.5523 43 46V42C43 41.4477 42.5523 41 42 41H38Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M42 50H38V54H42V50ZM38 49C37.4477 49 37 49.4477 37 50V54C37 54.5523 37.4477 55 38 55H42C42.5523 55 43 54.5523 43 54V50C43 49.4477 42.5523 49 42 49H38Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27 3H45.1691V4C45.1691 4.58803 44.9746 5.56678 44.505 6.8147C44.0263 8.08684 43.2402 9.69605 42.0011 11.5547C40.1588 14.3182 39.9056 17.0297 40.0246 18.5H41.1691V20.5H21V18.5H21.8511C21.729 16.5733 22.1273 13.5062 24.1679 10.4453C25.3288 8.70395 26.0428 7.22983 26.4641 6.1103C26.8945 4.96656 27 4.24531 27 4V3ZM23.8555 18.5H38.0202C37.8981 16.5733 38.2964 13.5062 40.337 10.4453C41.4979 8.70395 42.2119 7.22983 42.6332 6.1103C42.7954 5.67923 42.9115 5.30818 42.9931 5H28.8787C28.7739 5.50725 28.5977 6.11917 28.3359 6.8147C27.8572 8.08684 27.0712 9.69605 25.8321 11.5547C23.9897 14.3182 23.7365 17.0297 23.8555 18.5Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M29.7112 11.5H34.5V12C34.5 12.3418 34.3119 13.0955 33.7248 14.0654C33.2353 14.8739 33.2639 15.66 33.316 15.8893L33.4546 16.5H28.6404L28.552 16.1107C28.4479 15.6518 28.4452 14.5971 29.0805 13.5475C29.3442 13.112 29.5043 12.7466 29.5975 12.4738C29.6441 12.3373 29.6734 12.2257 29.6907 12.1417C29.6993 12.0997 29.7047 12.066 29.7078 12.0407C29.7093 12.0281 29.7102 12.0183 29.7107 12.0111L29.7112 12.0028L29.7112 12V11.5ZM30.6335 12.5C30.5313 12.8942 30.3182 13.434 29.936 14.0654C29.608 14.6073 29.5126 15.1391 29.5011 15.5H32.2897C32.3014 14.9712 32.4391 14.2582 32.8693 13.5475C33.1246 13.1258 33.2828 12.7698 33.3772 12.5H30.6335Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M32.5 26.5H23.5V25.5H32.5V26.5ZM39 26.5H35V25.5H39V26.5Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30 27.5H39V28.5H30V27.5ZM23.5 27.5H27.5V28.5H23.5V27.5Z" />
        </WrapIcon>
    );
});
