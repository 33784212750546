import {useEffect, useState} from "react";
import ServerCommand from "../../../common/server/server-command";
import {IDataPatientPriceList, PatientPriceListResponse} from "../types";
import useLoader from "../../../components/Errors";

export const useLoadData = () => {
    const [loaderPage, setLoaderPage] = useLoader(true)
    const [data, setData] = useState<IDataPatientPriceList>({
        importantArticles: [],
        otherArticles: [],
        inVisitPrice: [],
        services: [],
        servicesGroups: [],
    })

    useEffect(() => {
        ServerCommand.get('service/patient-price-list').then((response: PatientPriceListResponse) => {
            setData(prevState => {
                return {
                    ...prevState,
                    importantArticles: response.articles.filter(item => item.important),
                    otherArticles: response.articles.filter(item => !item.important),
                    inVisitPrice: response.services.filter(item => item.showInVisitPrice),
                    services: response.services,
                    servicesGroups: response.servicesGroups
                }
            })

            setLoaderPage(false)
        })
    }, [])


    return {data, setData, loaderPage}
}