import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth36Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 13.09 24"} width={width || "24"} height={height || "44"} ref={ref}>
            <path d="M11.9,3.1c-.14-1.07-.35-1.44-.51-1.56a.37.37,0,0,0-.25-.07l-.34,0a1.7,1.7,0,0,1-1.25-.16C7.35,0,6.75.07,5,.92A1.78,1.78,0,0,1,3.11.54,2,2,0,0,0,.48.47C-.18.89-.09,2.91.33,4.67A3.45,3.45,0,0,0,.89,6c.6.72,1.67.73,4,.56a3.76,3.76,0,0,1,1.87.34,6.37,6.37,0,0,0,3.47.37,1.42,1.42,0,0,0,1-.58,4.91,4.91,0,0,0,.71-3.6ZM1.28,15c.58,5.52,2.73,7.57,3.78,7.87a.55.55,0,0,0,.58-.06c.14-.17.11-.62-.24-1.25A7.57,7.57,0,0,1,4.65,15a1,1,0,0,1,.64-.66c1.16-.21,2,1.65,2.91,3.63.19.42.38.85.56,1.19l.05.1c2.34,4.76,3.64,4.89,4.1,4.73s0-1.53-.46-3A30.88,30.88,0,0,1,11,14.11a50.43,50.43,0,0,1-.07-5.5,1.92,1.92,0,0,1-.58.16c-.48,0-.9.06-1.28.06a6.11,6.11,0,0,1-2.6-.49,2.84,2.84,0,0,0-1.49-.28,9.18,9.18,0,0,1-3.75-.18,34.72,34.72,0,0,1,.09,4.89c0,1-.05,1.83,0,2.27Z"/>
        </WrapIcon>
    );
});
