import {Props} from "../types";
import {getColorStyle} from "./getColorStyle";

export const getTextDecorationTypography = (props: Props) => {
    const {textDecoration} = props
    switch (textDecoration) {
        case "underline":
            return `
                text-decoration: underline;
                text-decoration-thickness: from-font;
                text-underline-offset: 2px;
                text-decoration-color: ${getColorStyle(props)};
            `
        case "none":
        default:
            return 'none'
    }
}
