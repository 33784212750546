import styled from "styled-components";
import {StyledInput} from "../../components-ui/Inputs/styledInput";

export const ParameterInput = styled(StyledInput)`
    padding: 4px 12px 6px;
    line-height: 1.5;
`;

export const MultipleWrap = styled.div`
    & > .SCheckboxLabel {
        padding-bottom: 10px;
        display: block;
    }
    & > div {
    display: flex;
    align-items: center;
        .SCheckboxLabel {
            span {
                min-height: 24px;
            }
        }
    }
`
