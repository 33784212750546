import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import ServerCommand from "../../../../../common/server/server-command";
import _ from "underscore";
import {addMemorizedPatient} from "../../../../../redux/actions/scheduleActions";
import PatientCard from "../../../../PatientCard/PatientCard";
import {OptionButton} from "../../../../../components-ui/Inputs/Button/typesButton";
import {useSlideModal} from "../../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {useDialogsManager} from "../../../../../containers/DialogsManager/DialogsManager";
import useLoader from "../../../../../components/Errors";
import {StateCallCenter} from "../../../types/components";
import {StateDetailed} from "./useDetailed";
import {IncomingCallPatient} from "../../../types/taskData";

interface Props {
    state: StateCallCenter
    setState: Dispatch<SetStateAction<StateCallCenter>>
    detailedState: StateDetailed
    setDetailedState: Dispatch<SetStateAction<StateDetailed>>
    isCallSubject: boolean
    patient: IncomingCallPatient | null
    futureComment: string
}

const useFooterDetailed = (
    {
        state,
        setDetailedState,
        detailedState,
        setState,
        patient,
        futureComment,
        isCallSubject
    }: Props) => {
    const {closeSlideModal, setIsClose} = useSlideModal();
    const {showSlideModal} = useDialogsManager();
    const [comment, setComment] = useState('');
    const dispatch = useDispatch();
    const {detail, calls} = state;
    const [loader, setLoader] = useLoader();

    useEffect(() => {
        if (!!detail && !!detail.result && !!detail.result.comment) {
            setComment(detail.result.comment)
        }
    }, [detail?.result])

    const prepareSaveCallResult = () => {
        let data = {
            phoneNumber: detail?.phone,
            comment
        } as any;
        if (!!detailedState.selectedType) data.callerType = detailedState.selectedType.value
        if (isCallSubject && !!detailedState.selectedTheme) data.topics = detailedState.selectedTheme.map(selected => selected.value);

        const result = {
            topics: !!data.topics ? data.topics : null,
            callerType: !!data.callerType ? data.callerType : null,
            comment: !!data.comment ? data.comment : null
        }
        ServerCommand.post('call-center/call/save-result', data).then(() => {
            const callCompleted = !calls.some(call => call.contactPhone === detail?.phone)
            setState(prevState => {
                const findIndex = prevState.plannedCalls.findIndex(prevCall => prevCall.phone === detail?.phone)
                if (findIndex !== -1) {
                    if (callCompleted) {
                        prevState.plannedCalls[findIndex].canSaveResult = false
                    } else {
                        prevState.plannedCalls[findIndex].result = result
                    }
                }
                if (!!prevState.detail && !!prevState.detail.tasks.length) {
                    if (callCompleted) {
                        prevState.detail.canSaveResult = false
                    } else {
                        prevState.detail.result = result
                    }
                }
                return {...prevState}
            })

            setLoader(false)
            setIsClose(true)
        })
    }

    const saveCallResult = () => {
        setLoader(true)
        setDetailedState(prevState => ({...prevState, validationAfterSave: false}))
        if (!!futureComment && (!!patient && patient.futureVisit)) {
            ServerCommand.post('visit/update-comment', {
                visitId: patient.futureVisit.id,
                comment: futureComment
            })
                .then(() => prepareSaveCallResult())
        } else {
            prepareSaveCallResult();
        }
    }
    const saveAndExisting = () => {
        saveCallResult()
        const data = {
            patient: {
                ..._.pick(patient, 'lastName', 'name', 'secondName', 'id'),
                cellName: 'позвонившего пациента',
                personalPhone: detail?.phone
            } as any,
            timeStart: '09:00',
            timeEnd: '09:30',
            isOpenNewVisitForm: !!patient?.id
        }
        dispatch(addMemorizedPatient(data))
        closeSlideModal();
    }

    const saveAndOpenCard = () => {
        setLoader(true)
        closeSlideModal()
        setTimeout(() => {
            showSlideModal(<PatientCard patientId={!!patient ? patient.id : detail?.phoneOwner?.id}/>)
            saveCallResult();
        }, 500)
    }
    const optionsButton = [
        {
            value: 'optionsButton1',
            label: isCallSubject ? 'Сохранить результат звонка' : 'Сохранить и записать',
            onClick: () => {
                if (isCallSubject) {
                    saveCallResult()
                } else {
                    saveAndExisting()
                }
            }
        }
    ] as OptionButton[]
    if (!!patient || (!!detail && !!detail.phoneOwner)) {
        optionsButton.push({
            value: 'optionsButton2',
            label: 'Сохранить и открыть карту пациента',
            onClick: () => saveAndOpenCard()
        })
    }

    return {
        comment,
        setComment,
        optionsButton,
        saveCallResult,
        saveAndExisting,
        loader
    }
}

export default useFooterDetailed
