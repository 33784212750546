import ColorHelper from './color-helper';
import CookieHelper from './cookie-helper';
import DateHelper from './date-helper';
import GeneralHelper from './general-helper';
import PhoneHelper from './phone-helper';
import StringHelper from './string-helper';
import Arr from './Arr';
import PriceHelper from "./price-helper";
import TeethMapHelper from "./teethmap";
import Group from "./group-helper";

let Helper = Object.assign({
    Color: ColorHelper,
    Cookie: CookieHelper,
    Date: DateHelper,
    Phone: PhoneHelper,
    String: StringHelper,
    Array: Arr,
    Price: PriceHelper,
    TeethMap: TeethMapHelper,
    Group,
}, GeneralHelper);

export default Helper;
