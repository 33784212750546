import React from 'react';
import Box from '../../../../components-ui/Layout/Box';
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import Button from "../../../../components-ui/Inputs/Button/Button";
import ServerCommand from "../../../../common/server/server-command";
import useLoader from "../../../../components/Errors";
import Tooltip from "../../../../components-ui/DataDisplay/Tooltip/Tooltip";

interface Props {
    visitId: number
}

export const VisitWaitingIsOver = ({visitId}: Props) => {
    const [loader, setLoader] = useLoader()
    const dataFor = `excludeFromStatistics-${visitId}`
    return (
        <Box className="button-task-content" >
            <Button {...commonPropsButton} variant="default"
                    loader={loader}
                    onClick={() => {
                        setLoader(true)
                        ServerCommand.post('visit/set-next-state', {visitId});
                    }}
                    children="пациент принят"
                    noShadow={false}
                    data-for={dataFor}
                    data-tip={"Перевести прием на следующий<br/>шаг и завершить задачу"}
            />
            <Tooltip id={dataFor} className="react-tooltip--small-text" delayShow={1000}/>
        </Box>
    );
};
