import {ResponseUserDetail} from '../types';

interface Props {
    state: ResponseUserDetail
    passwordState: {
        value?: string,
        confirm?: string
    }
    userId?: number
}
const useValidate = ({state, passwordState, userId}: Props) => {
    const birthdayValid = (!state.detail.birthday?.replace(/[\s_-]/g, '') || state.detail.birthday.replace(/[\s_-]/g, '').length === 8);

    const seriesValid = (!state.detail.document?.passport.series?.replace(/[\s_]/g, '') || state.detail.document?.passport.series.replace(/[\s_]/g, '').length === 10);

    const issueDateValid = (!state.detail.document?.passport.issuedDate?.replace(/[\s_-]/g, '') || state.detail.document.passport.issuedDate.replace(/[\s_-]/g, '').length === 8);

    const addresDateValid = (!state.detail.document?.passport.addressDate?.replace(/[\s_-]/g, '') || state.detail.document.passport.addressDate.replace(/[\s_-]/g, '').length === 8);

    const passwordValueValid = userId ? !passwordState.value || passwordState.value.length > 5 : !!passwordState.value && passwordState.value.length > 5;

    const branchesValid = state.detail.role === "director" || !!state.detail.branches?.length

    const standartValid = !!state.detail.name &&
        !!state.detail.login &&
        !!state.detail.lastName &&
        !!state.detail.role &&
        branchesValid &&
        birthdayValid &&
        seriesValid &&
        issueDateValid &&
        addresDateValid &&
        passwordValueValid &&
        passwordState.confirm === passwordState.value

    const userValid = userId ?
        standartValid :
        standartValid &&
        !!state.detail.login

    const doctorValid = !!state.detail.color && !!state.detail.specialities?.length;

    const fullValid = state.detail.role === 'doctor' ? userValid && doctorValid : userValid;

    return {
        birthdayValid,
        branchesValid,
        seriesValid,
        issueDateValid,
        addresDateValid,
        passwordValueValid,
        fullValid,
    }
};

export default useValidate;
