import {useMemo} from "react";
import _ from "lodash";
import {useSelector} from "react-redux";

export const useOptionsDoctors = () => {
    const doctors = useSelector(state => state.common.doctors);
    const filterBranches = useSelector(state => state.schedule.filter.branches);
    const speciality = useSelector(state => state.schedule.filter.speciality);
    return useMemo(() => {
        const filterDoctors = _.cloneDeep(doctors).filter(doctor => !doctor.deletedAt)
        const branchesDoctors = filterBranches.length ? filterDoctors.filter(doctor => doctor.branches.some(branch => filterBranches.includes(branch))) : filterDoctors;
        const specialitiesDoctors = !!speciality ? branchesDoctors.filter(doctor => !!doctor.specialities && doctor.specialities.includes(speciality)) : branchesDoctors;
        const newDoctors = specialitiesDoctors.map(doctor => {
            return {
                value: doctor.id,
                label: doctor.fio,
                color: doctor.color,
                branches: doctor.branches
            };
        })
        newDoctors.unshift({
            value: 0,
            label: 'Все врачи',
            color: '#fff',
            branches: []
        });
        return newDoctors
    }, [JSON.stringify(filterBranches), speciality])
}
