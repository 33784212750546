import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import Tabs from "../../../../../components-ui/Navigation/Tabs/Tabs";
import {Divider} from "../../../../../components-ui/DataDisplay/Divider";
import Box from "../../../../../components-ui/Layout/Box";

const tabs = [
    {name: 'Еженедельно', code: 'all'},
    {name: 'Четная неделя', code: 'even'},
    {name: 'Нечетная', code: 'odd'}
];

const tabsWarning = [
    {name: 'Обязательно 1', code: 'ob1', warning: true},
    {name: 'Обязательно 2', code: 'ob2', warning: true},
    {name: 'Обязательно 3', code: 'ob3', warning: true}
]

const replacementOptions = [
    {value: '1', label: 'Doctor', color: '#be2e2e'},
    {value: '2', label: 'Doctor2', color: '#48be2e'},
    {value: '3', label: 'Doctor3', color: '#2e57be'},
]

const tabsDoctors = [
    {name: 'Doctor1', code: 'Doctor1', replacementOptions, isReplaceable: true, note: 'Подсказка'},
    {name: 'Doctor2', code: 'Doctor2', replacementOptions, isReplaceable: true, note: 'Подсказка побольше чем нужно', isDeletable: true},
    {name: 'Doctor3', code: 'Doctor3', replacementOptions, isReplaceable: true, isDeletable: true}
];

const tabsPatients = [
    {name: 'Иванов И.И.', code: 123},
    {name: 'Иванова Ю.Ю.', code: 124},
    {name: 'Старк А.Э.', code: 125},
    {name: 'Прокопьев А.С.', code: 126},
]

const tabsButton = [
    {name: 'врачи', code: 'doctors'},
    {name: 'направления', code: 'services'}
]


export default function TabsPage() {
    const [value, setValue] = useState<any>(tabs[0].code)
    const [ob, setOb] = useState<any>(tabsWarning[0].code)
    const [doctor, setDoctor] = useState<any>(tabsDoctors[0].code)
    const [patient, setPatient] = useState<any>(tabsPatients[0].code)
    const [button, setButton] = useState<any>(tabsButton[0].code)
    return (
        <>
            <h1>Tabs</h1>
            <WrapContent>
                <div>
                    <Tabs tabs={tabsButton} variant="buttons" size="sm" activeTab={button} onChange={setButton} />
                    <pre>
                        {
                            '<Tabs tabs={tabsButton} variant="buttons" size="sm" activeTab={button} onChange={setButton} />'
                        }
                    </pre>
                </div>
                <br/>
                <div style={{background: '#f1f3f7', position: 'relative', width: '800px', height: '300px'}}>
                    <Box position="absolute" positionOffsets={{top: '0', right: '0'}}>
                        <Tabs tabs={tabsPatients} variant="list"  activeTab={patient} onChange={setPatient} />
                    </Box>
                    <pre>
                        {
                            `<Box position="absolute" positionOffsets={{top: '0', right: '0'}}>
<Tabs tabs={tabsPatients} variant="list"  activeTab={patient} onChange={setPatient} />
</Box>`
                        }
                    </pre>
                </div>
                <Box margin="15px 0" display="block">
                    <Divider/>
                </Box>
                <div>
                    <Tabs tabs={tabsWarning} activeTab={ob} onChange={setOb} />
                    <pre>
                        {
                            `<Tabs tabs={tabsWarning} activeTab={value} onChange={setValue} />
"tabsWarning = ["
    "{name: 'Обязательно 1', code: 'ob1', warning: true},"
    "{name: 'Обязательно 2', code: 'ob2', warning: true},"
    "{name: 'Обязательно 3', code: 'ob3', warning: true}"
"]"`
                        }
                    </pre>
                </div>
                <Box margin="15px 0" display="block">
                    <Divider/>
                </Box>
                <div>
                    <Tabs tabs={tabs} variant="primary" activeTab={value} onChange={setValue} />
                    <pre>
                        {
                            '<Tabs tabs={tabs} variant="primary" activeTab={value} onChange={setValue} />'
                        }
                    </pre>
                </div>
                <Box margin="15px 0" display="block">
                    <Divider/>
                </Box>
                <div>
                    <Tabs tabs={tabs} variant="classic" activeTab={value} onChange={setValue} />
                    <pre>
                        {
                            '<Tabs tabs={tabs} variant="classic" activeTab={value} onChange={setValue} />'
                        }
                    </pre>
                </div>
                <Box margin="15px 0" display="block">
                    <Divider/>
                </Box>
                <div>
                    <Tabs tabs={tabs} disabled={true} activeTab={value} onChange={setValue} />
                    <pre>
                        {
                            '<Tabs tabs={tabs} disabled={true} activeTab={value} onChange={setValue} />'
                        }
                    </pre>
                </div>
                <Box margin="15px 0" display="block">
                    <Divider/>
                </Box>
                <div style={{background: '#fff'}}>
                    <Tabs tabs={tabsDoctors} borderBottom={true} activeTab={doctor}
                          onChange={setDoctor} onTabAdd={() => console.log('addTab')}
                          onTabReplace={() => console.log('onTabReplace')}
                          onTabDelete={() => console.log('onTabDelete')}
                    />
                    <pre>
                        {
                            ` <Tabs tabs={tabsDoctors} borderBottom={true} activeTab={doctor}
                          onChange={setDoctor} onTabAdd={() => console.log('addTab')}
                          onTabReplace={() => console.log('onTabReplace')}
                          onTabDelete={() => console.log('onTabDelete')}
                    />

                 const replacementOptions = [
    {value: '1', label: 'Doctor', color: '#be2e2e'},
    {value: '2', label: 'Doctor2', color: '#48be2e'},
    {value: '3', label: 'Doctor3', color: '#2e57be'},
]

const tabsDoctors = [
    {name: 'Doctor1', code: 'Doctor1', replacementOptions, isReplaceable: true, note: 'Подсказка'},
    {name: 'Doctor2', code: 'Doctor2', replacementOptions, isReplaceable: true, note: 'Подсказка побольше чем нужно', isDeletable: true},
    {name: 'Doctor3', code: 'Doctor3', replacementOptions, isReplaceable: true, isDeletable: true}
];
                    `
                        }
                    </pre>
                </div>
                <Box margin="15px 0" display="block">
                    <Divider/>
                </Box>
                <div>
                    <Tabs tabs={tabs} variant="primary" align="center" activeTab={value} onChange={setValue} />
                    <pre>
                        {
                            '<Tabs tabs={tabs} variant="primary" align="center" activeTab={value} onChange={setValue} />'
                        }
                    </pre>
                </div>
                <Box margin="15px 0" display="block">
                    <Divider/>
                </Box>
                <div>
                    <Tabs tabs={tabs} variant="primary" size="lg" widthTab="250px" activeTab={value} onChange={setValue} />
                    <pre>
                        {
                            '<Tabs tabs={tabs} variant="primary" size="lg" widthTab="250px" activeTab={value} onChange={setValue} />'
                        }
                    </pre>
                </div>
                <Box margin="15px 0" display="block">
                    <Divider/>
                </Box>
                <div>
                    <Tabs tabs={tabs} variant="primary" size="sm" widthTab="200px" activeTab={value} onChange={setValue} />
                    <pre>
                        {
                            '<Tabs tabs={tabs} variant="primary" size="sm" widthTab="200px" activeTab={value} onChange={setValue} />'
                        }
                    </pre>
                </div>

            </WrapContent>
        </>
    )
}
