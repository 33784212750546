import React, {useState} from 'react';
import Box from "../../../../components-ui/Layout/Box";
import Button from "../../../../components-ui/Inputs/Button/Button";
import ServerCommand from "../../../../common/server/server-command";
import {SavePaymentsCheckedParams} from "../../types";
import {Divider} from "../../../../components-ui/DataDisplay/Divider";

interface Props {
    handlePaymentChecked: (paymentsChecked: boolean) => void
    branchId: number
}

const SuccessChecking = ({handlePaymentChecked, branchId}: Props) => {

    const [loader, setLoader] = useState<boolean>(false)

    const sendSuccessChecking = () => {
        setLoader(true)
            ServerCommand
                .post('payment/checked', {branchId} as SavePaymentsCheckedParams)
                .then(() => {
                    setLoader(false)
                    handlePaymentChecked(true)
                })

    }

    return (
        <>
            <Divider />
            <Box margin="23px 0 52px 30px">
                <Button
                    variant="success"
                    children="Оплаты проверены"
                    loader={loader}
                    onClick={sendSuccessChecking}
                />
            </Box>
        </>
    );
};

export default SuccessChecking;