import {Tab} from "../../../../../../components-ui/Navigation/Tabs/elements/Tab/typesTab";
import {TabConsultations} from "../types";
import {Legend} from "../../../../types/common";

export const tabsConsultations = [
    {
        code: 'doctors',
        name: 'врачи'
    },
    {
        code: 'directions',
        name: 'направления'
    }
] as Tab<TabConsultations>[]

export const buttonsConsultations = {
    consultationsCount: 'консультации',
    plansSums: 'планы лечения'
}

export const legendSwirlConsultations = [
    {
        code: 'primary-patients',
        name: 'Первичных пациентов'
    },
    {
        code: 'started-treatment',
        name: 'Начавших лечение'
    }
] as Legend[]

export const legendSwirlPlans = [
    {
        code: 'primary-patients',
        name: 'Сумма рекомендуемых планов'
    },
    {
        code: 'started-treatment',
        name: 'Сумма выполняемых планов'
    }
] as Legend[]
