import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const PrinterOldIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 23.48 24"} ref={ref}>
            <polygon points="18.43 8.06 17.57 8.06 17.57 0.86 5.92 0.86 5.92 8.06 5.06 8.06 5.06 0 18.43 0 18.43 8.06"/>
            <path d="M23.47,20.88H18V20h4.62V10a.88.88,0,0,0,0-.23,1.43,1.43,0,0,0-1.53-1.32H2.43A1.45,1.45,0,0,0,.86,9.81V20H5.48v.86H0V10c0-.07,0-.18,0-.29A2.29,2.29,0,0,1,.79,8.17a2.26,2.26,0,0,1,1.67-.55H21.05a2.29,2.29,0,0,1,2.42,2.12,1.71,1.71,0,0,1,0,.32Z"/>
            <path d="M18.43,24H5.06V16.59H18.43ZM5.92,23.14H17.57V17.45H5.92Zm1.77-10.2H5.49v-.86h2.2Z"/>
        </WrapIcon>
    );
});
