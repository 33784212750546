import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const MaximizeIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M2.4,0h12L0,14.4V2.4A2.41,2.41,0,0,1,2.4,0Z"/>
            <path d="M21.6,24H9.6L24,9.6v12A2.41,2.41,0,0,1,21.6,24Z"/>
        </WrapIcon>
    );
});
