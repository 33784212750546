import React, {useMemo} from "react";
import Button from "../../../components-ui/Inputs/Button/Button";
import Helper from "../../../common/helpers/main";
import {Label} from "../../../components-ui/DataDisplay/Label";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import {DirectionsFields} from "./components/DirectionsFields";
import {commonLabelOption} from "./data";
import Discount from "./components/Discount";
import PaymentMethod from "./components/PaymentMethod";
import InsuranceSum from "./components/InsuranceSum";
import AccountBalance from "./components/AccountBalance";
import {useSelector} from "react-redux";
import {useOptionsBranchesUser} from "../../../common/hooks/useOptionsBranchesUser";
import FilterBranches from "./components/FilterBranches";
import Box from "../../../components-ui/Layout/Box";
import FilterPatient from "./components/FilterPatient";
import usePaymentCreationForm from "./hooks/usePaymentCreationForm";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import Kkm from "../../../common/server/kkm";
import KkmSelect from "./components/KkmSelect";
import ValidationLinkInfo from "../../../components/ValidationLinkInfo/ValidationLinkInfo";
import CashBox from "./components/CashBox";

interface Props {
    patientId?: number
    loadingFromServer: () => void
}

const PaymentCreationForm = ({patientId: fixedPatientId, loadingFromServer}: Props) => {

    const discountTypeOptions = useSelector(state => state.common.discountTypeOptions);
    const paymentMethodOptions = useSelector(state => state.common.paymentMethodOptions);
    const cashboxOptions = useSelector(state => state.common.cashboxOptions);
    const optionsBranches = useOptionsBranchesUser()

    const {
        kkmError,
        data,
        patientId,
        setPatientId,
        state,
        setState,
        handleSubmit,
        loader,
        paymentSum,
        kkmOptions
    } = usePaymentCreationForm({
        fixedPatientId,
        loadingFromServer,
        discountTypeOptions,
        paymentMethodOptions,
        cashboxOptions,
        optionsBranches
    })

    if (fixedPatientId !== undefined && data === null) {
        return null
    }

    return (
        <Box padding="30px 27px 69px" minWidth="549px">
            <Typography children="Добавление оплаты" textAlign="center" variant="bigger-title" margin="25px 0 59px"/>
            {(data && patientId) &&
                <Box position="absolute" positionOffsets={{top: "110px"}}>
                    <Label labelText="" {...commonLabelOption}>
                        <span>
                            На счёте пациента {Helper.Price.format(data.account)} руб.
                        </span>
                    </Label>
                </Box>
            }

            {fixedPatientId === undefined &&
                <FilterPatient setPatientId={setPatientId}/>
            }

            {optionsBranches.length > 1
                ?
                <FilterBranches state={state} setState={setState}/>
                :
                null
            }

            <PaymentMethod state={state} setState={setState}/>

            <CashBox state={state} setState={setState}/>

            {state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' &&
                <KkmSelect state={state} setState={setState} kkmOptions={kkmOptions}/>
            }

            <DirectionsFields
                values={state.values} setState={setState}
                directionsBalance={!!data ? data.directionsBalance : null}/>

            <Discount state={state} setState={setState} paymentSum={paymentSum}/>

            <InsuranceSum state={state} setState={setState} paymentSum={paymentSum}/>

            <Label labelText="" {...commonLabelOption}>
                <Button
                    variant={(state.values[Object.keys(state.values)[0]] !== '' && state.branch !== undefined && patientId) ? 'success' : 'disabled'}
                    children='Внести'
                    loader={loader}
                    size="md" onClick={handleSubmit}/>
            </Label>

            {(data && patientId) &&
                <AccountBalance data={data} paymentSum={paymentSum}/>
            }
            <ValidationLinkInfo validate={!kkmError} onClickIsValidate={() => {}}
                                text={kkmError} linkText={""}/>
        </Box>
    )
};

export default PaymentCreationForm;
