import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const ViewPassIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 17.45"} ref={ref}>
            <path d="M24,8.73q-4.29,8.73-12.13,8.72T0,8.73Q4,0,11.87,0T24,8.73ZM12,14.18A5.46,5.46,0,1,0,6.55,8.73,5.45,5.45,0,0,0,12,14.18ZM12,12a3.28,3.28,0,0,1,0-6.55h0A3.28,3.28,0,0,1,12,12Z"/>
        </WrapIcon>
    );
});
