import React from "react";
import { HeadServiceTable, HeadCode, HeadService, HeadTarget, HeadDoctor, ButtonGroupActions } from "./styledHeaderServiceTable";

interface PropsHeaderServiceTable {
    groupActions: boolean,
    setGroupActions: (groupActions: boolean) => void,
    services: any[]
}

export default function HeaderServiceTable({groupActions, setGroupActions, services}: PropsHeaderServiceTable) {
    return (
        <HeadServiceTable>
            <HeadCode>
                Код
            </HeadCode>
            <HeadService>
                Услуга
            </HeadService>
            <HeadTarget>
                Зуб
            </HeadTarget>
            <HeadDoctor>
                <span>Врач</span>
                {services.length > 1 && (
                    <ButtonGroupActions active={groupActions}
                                        onClick={() => setGroupActions(!groupActions)}>
                        Групповые действия
                    </ButtonGroupActions>
                )}
            </HeadDoctor>
        </HeadServiceTable>
    )
}
