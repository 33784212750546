import React, {useMemo} from 'react';
import Box from "../../../../../../../components-ui/Layout/Box";
import {ReceiptsReport} from "../../../../../types";
import {HandlersReceipts, StateReceipts} from "../../types";
import useAllTotalsSum from "./hooks/useAllTotalsSum";
import Sum from './components/Sum';
import TooltipPercentage from "../../components/TooltipPercentage";
import Scrollbar from "../../../../../../../components-ui/DataDisplay/Scrollbar/Scrollbar";

export interface PropsDirections {
    report: ReceiptsReport
    state: StateReceipts
    handlers: HandlersReceipts
    sumsCodes: string[]
}

const heightDirection = 43.2

const Sums = ({sumsCodes, ...props}: PropsDirections) => {
    const allTotalsSum = useAllTotalsSum({sumsCodes, report: props.report, state: props.state})
    const minHeight = useMemo(() => {
        let result = 3;
        if (sumsCodes.length > 6) {
            result += (heightDirection * 6)
        } else {
            result += (sumsCodes.length * heightDirection)
        }
        return result
    }, [sumsCodes.length])
    return (
        <Box position="relative" minHeight={minHeight + 'px'}>
            <Box position="absolute" positionOffsets={{top: '0', left: '-4px',}} width="calc(100% + 16px)">
                <Scrollbar height={minHeight + 'px'}>
                    <Box display="flex" flexDirection="column" margin="3px 0 0"
                         onMouseLeave={() => props.handlers.handleHoverIndex(-1)}
                         width="calc(100% - 16px)" transform="translateX(4px)">

                        {sumsCodes.map((code, index) => <Sum index={index} key={code + props.state.selectedDirection} code={code}
                                                             allTotalsSum={allTotalsSum} {...props}/>)}
                    </Box>
                </Scrollbar>
            </Box>
            {sumsCodes.map((code) => <TooltipPercentage key={code + props.state.selectedDirection} code={code} report={props.report} state={props.state}/>)}
        </Box>

    );
};


export default Sums;
