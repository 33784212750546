import React, {useState} from "react";
import {ToothIconWrap, ToothItem, ToothItemWrap} from "../styledToothSelect";
import ToothContent from "./ToothContent";
import {Icon} from "../../../../../components/Icons/Icons";
import TargetIcon from "./TargetIcon";
import Prompt from "./Prompt";
import {
    GumDiseaseItem,
    GumDiseaseWrap
} from "../../../../../components/DentalRecords/elements/ToothItem/styledToothItem";


export default function ItemTooth({teethStatuses, targetItem, targetSelected, onSelect, filledTeeth, typeSelected}: any) {
    let status = teethStatuses[targetItem.toothStatus];
    let active = false;
    if (targetSelected !== null) active = targetSelected.indexOf(targetItem.name) !== -1;

    const [hoverSegment, setHoverSegment] = useState(false);

    const gumDisease = [];
    if (targetItem.gumDisease !== undefined) {
        for (let i = 0; i < targetItem.gumDisease; i++) gumDisease.push(i);
    }


    return (
        <ToothItemWrap>
            <ToothItem active={active} onClick={() => onSelect(targetItem.name)}>
                <ToothIconWrap active={active} measure={targetItem.measure}
                               status={!!status ? status.group : 'HEALTHY'}
                               children={<TargetIcon targetItem={targetItem}/>}/>
                <ToothContent active={active}
                              targetItem={targetItem}
                              setHoverSegment={setHoverSegment}
                              status={status}
                />
                {!!gumDisease.length && (
                    <GumDiseaseWrap>
                        {gumDisease.map(gum => <GumDiseaseItem key={'gum' + gum}
                                                               selected={active}/>)}
                    </GumDiseaseWrap>
                )}
                <Prompt hover={hoverSegment} name={targetItem.name}/>
            </ToothItem>
            {(typeSelected + '_' + targetItem.name in filledTeeth) ? <Icon icon="new-done" iconClass="icon-check"/> : null}
        </ToothItemWrap>
    )
}
