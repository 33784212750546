import React, {Dispatch, SetStateAction} from 'react';
import Loader from '../../../../components-ui/Feedback/Loader/Loader';
import {SalaryReport, SelectedMonth} from '../../types';
import useTablesFormatter from './hooks/useTablesFormatter';
import Box from '../../../../components-ui/Layout/Box';
import Paper from '../../../../containers/UI-Collection/Elements/Surfaces/Paper';
import Typography from '../../../../components-ui/DataDisplay/Typography/Typography';

interface Props {
    loader: boolean
    salaryReport: SalaryReport
    setSalaryReport: Dispatch<SetStateAction<SalaryReport>>
    selectedMonth: SelectedMonth
    selectedBranches: number[]
    loadingFromServer: () => void
}

const SalariesTables = ({loader, salaryReport, setSalaryReport, selectedMonth, selectedBranches, loadingFromServer}: Props) => {

    const {SalaryTables} = useTablesFormatter({salaryReport, setSalaryReport, selectedMonth, selectedBranches, loadingFromServer})

    return (
       <Box position='relative'>
            <Loader visible={loader}/>
            <Box display="flex" gap="24px" flexDirection="column" padding='0 33px 0 0'>
                {salaryReport.categories.length ? SalaryTables :
                    <Paper width="100%" padding="64px 24px">
                        <Typography variant="middle-title" textAlign="center" children="За указанный период данные отсутствуют" />
                    </Paper>
                }
            </Box>
       </Box>
    );
};

export default SalariesTables;
