import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const ColorFolderIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 19.2"} ref={ref}>
            <path fill="#00a3a3"
                  d="M1.6,0H10L11.2,1.6H22.4A1.6,1.6,0,0,1,24,3.2V17.6a1.6,1.6,0,0,1-1.6,1.6H1.6A1.6,1.6,0,0,1,0,17.6V1.6A1.6,1.6,0,0,1,1.6,0Z"/>
            <rect fill="#00d6d6" y="4.8" width="24" height="14.4" rx="1.6"/>
        </WrapIcon>
    );
});
