import Helper from "../../common/helpers/main";
import {
    addRequiredFields,
    getFirstFieldName,
    getSnapshot,
    isCaseHistorySaved,
    prepareTargetTooth,
    types
} from "./helperCaseHistory";
import {
    RelatedTemplate,
    ResponseCaseHistory,
    ResponseRecords, ResponseTemplatesGroupsList,
    ResponseTemplatesList, ResponseTypeCaseHistory,
    SCaseHistory,
} from "./typesCaseHistory";
import _ from "underscore";

/** Сохронение данных с сервера
 *@param response */
export const saveResponse = (response: ResponseCaseHistory | any) => {
    let newState = {
        teethStatuses: Helper.Array.toObject(response.teethStatuses, 'id'),
        templatesList: response.templatesList,
        templatesGroupsList: response.templatesGroupsList,
        visitTarget: prepareTargetTooth(response.visitCaseHistory),
        planTarget: prepareTargetTooth(response.planCaseHistory),
        fieldsValues: {},
        fieldsRequired: {},
        needPrint: response.needPrint,
        canPrint: response.canPrint,
        selectedDiagnosis: {},
        relatedTemplates: {},
        targetsHistory: {},
        diagnosisRelationParameters: response.diagnosisRelationParameters
    } as SCaseHistory | any;

    newState.templatesList.forEach((template: ResponseTemplatesList) => {
        if (template.text[template.text.length - 1] !== '.') {
            template.text += '.'
        }
    });

    let areCaseHistoriesSaved = true;
    const diagnosisTemplatesList = newState.templatesList.filter((template: ResponseTemplatesList) => template.field === 'diagnosis');

    types.forEach(type => {
        const caseHistory = response[type + 'CaseHistory'] as ResponseTypeCaseHistory | any;
        if (caseHistory === null) return;
        const targetsHistoryNames = Object.keys(caseHistory.targetsHistory);
        if (targetsHistoryNames.length) {
            targetsHistoryNames.forEach(targetName => {
                if (newState.targetsHistory[type + "_" + targetName] === undefined) {
                    newState.targetsHistory[type + "_" + targetName] = caseHistory.targetsHistory[targetName];
                }
            })
        }

        caseHistory.records.forEach((record: ResponseRecords) => {
            const target = type + '_' + record.target.name;
            Helper.forEachObj(record.textFields, (fieldValue: string, fieldName: string) => {
                if (fieldValue.length) {
                    if (newState.fieldsValues[target] === undefined) newState.fieldsValues[target] = {};
                    newState.fieldsValues[target][fieldName] = fieldValue;
                }
                if (fieldName === 'diagnosis' && !!record.diagnosesIds) {
                    if(!record.diagnosesIds) record.diagnosesIds = [];

                    let newSelected = diagnosisTemplatesList.filter((template: ResponseTemplatesList) => record.diagnosesIds.includes(template.id));

                    newSelected = newSelected.sort((a: ResponseTemplatesList, b: ResponseTemplatesList) => record.diagnosesIds.indexOf(a.id) - record.diagnosesIds.indexOf(b.id));
                    if (newSelected.length) {
                        if (newState.selectedDiagnosis[target] === undefined) newState.selectedDiagnosis[target] = [];
                        newState.selectedDiagnosis[target] = newSelected;

                        let allRelated = [] as any;
                        newState.selectedDiagnosis[target].forEach((selected: any) => {
                            if (selected.relatedTemplates.length) allRelated = allRelated.concat(selected.relatedTemplates);
                        });
                        if (!allRelated.length) {
                            newState.relatedTemplates[target] = {};
                        } else {
                            allRelated.forEach((relatedTemplate: RelatedTemplate) => {
                                const relatedTemplates = newState.templatesList.find((template: any) => template.id === relatedTemplate.templateId);
                                if (newState.relatedTemplates[target] === undefined) newState.relatedTemplates[target] = {};
                                if (newState.relatedTemplates[target][relatedTemplates.field] === undefined) {
                                    newState.relatedTemplates[target][relatedTemplates.field] = []
                                }
                                newState.relatedTemplates[target][relatedTemplates.field].push(relatedTemplates);
                            });
                        }

                        if (fieldValue.length) {
                            let newValue = fieldValue.split('. ');
                            newValue = newValue.map((value: any) => {
                                if (value[value.length - 1] !== '.') {
                                    value += '.';
                                }
                                return value;
                            });
                            newValue = newValue.filter((value: string) => !newSelected.some((selected: any) => selected.text === value));
                            newState.fieldsValues[target][fieldName] = newValue.join(' ');
                        }

                    }
                }
            });

            if (newState.fieldsRequired[target] === undefined) {
                newState.fieldsRequired[target] = {}
            }
            newState.fieldsRequired[target] = record.requiredFields;
        });

        if (!isCaseHistorySaved(caseHistory)) {
            areCaseHistoriesSaved = false;
        }
    });


    if (newState.visitTarget.length) {   // выделяем нужный зуб в ToothSelect
        const itemsFields = addRequiredFields(response.itemsFields, newState.fieldsRequired, 'visit', newState.visitTarget[0].name);

        newState.visitTargetSelected = [newState.visitTarget[0].name];
        newState.itemsFields = itemsFields;
        newState.fieldName = getFirstFieldName(itemsFields);

    } else if (newState.planTarget.length) {   // если обычной истории болезни зубов нет то выделяем первый ортопедический зуб
        const itemsFields = addRequiredFields(response.itemsFields, newState.fieldsRequired, 'plan', newState.planTarget[0].name);

        newState.planTargetSelected = [newState.planTarget[0].name];
        newState.itemsFields = itemsFields;
        newState.fieldName = getFirstFieldName(itemsFields);
    }

    if (areCaseHistoriesSaved) {
        newState.snapshot = getSnapshot(newState);
    }

    const diagnosisGroupsList = Helper.clone(newState.templatesGroupsList).filter((item: ResponseTemplatesGroupsList) => item.field === 'diagnosis');
    const groupsIdDiagnosis = {};
    addGroupsIdDiagnosis(diagnosisGroupsList, groupsIdDiagnosis);
    newState.groupsIdDiagnosis = groupsIdDiagnosis;

    let newTargetsHistory = {} as any;

    _.each(_.clone(newState.targetsHistory), (history: any, target) => {
        if (newTargetsHistory[target] === undefined) newTargetsHistory[target] = {};
        _.each(history, (values: any, fieldName) => {
            if (newTargetsHistory[target][fieldName] === undefined) newTargetsHistory[target][fieldName] = values.map((elem: any) => {
                elem.date = elem.date.slice(0, -3);
                return elem;
            });
        })
    });

    newState.targetsHistory = newTargetsHistory;

    return newState;

};

const addGroupsIdDiagnosis = (groups: ResponseTemplatesGroupsList[], groupsIdDiagnosis: any) => {
    groups.forEach((group: any) => {
        if (group.groupsId === undefined) group.groupsId = [];
        if (group.groupId !== 0) group.groupsId = Helper.clone(groupsIdDiagnosis[group.groupId].groupsId);
        group.groupsId.push(group.id);
        groupsIdDiagnosis[group.id] = group;
        if (group.groups !== undefined) {
            addGroupsIdDiagnosis(group.groups, groupsIdDiagnosis)
        }

        delete groupsIdDiagnosis[group.id].groups;
        delete groupsIdDiagnosis[group.id].doctorId;
        delete groupsIdDiagnosis[group.id].groupId;
        delete groupsIdDiagnosis[group.id].sort;
        delete groupsIdDiagnosis[group.id].field;
        delete groupsIdDiagnosis[group.id].id;
    });
};
