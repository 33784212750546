import {useMemo} from "react";
import {TasksDefault} from "../types/common";
import Helper from "../../../common/helpers/main";
import {useDateUpdate} from "../../../common/hooks";

const useAllDeadline = (tasks: TasksDefault) => {
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);

    return useMemo(() => {
        let isDeadline = false;
        const filterNullDeadLine = tasks.filter(task => task.deadline !== null);
        if (!!filterNullDeadLine.length) {
            isDeadline = !filterNullDeadLine.some(task => new Date(task.deadline as any).getTime() > new Date(currentDate).getTime())
        }

        return isDeadline;
    }, [JSON.stringify(tasks), currentDate])
}

export default useAllDeadline
