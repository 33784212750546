import React from "react";
import {InfoSubtitle, InfoText, InfoTextList} from "../styledInfo";
import {PAttributes} from "../typesInfo";

export default function Attributes({attributes, attributesNames, toothNumber}: PAttributes) {
    if (!attributes || !attributes.length) return null
    return (
        <>
            <InfoSubtitle children="Диагнокат" />
            <InfoTextList>
                {attributes.map(attr => {
                    return (
                        <InfoText key={'info-text' + attr + toothNumber}>
                            {attributesNames[attr]}
                        </InfoText>
                    )
                })}
            </InfoTextList>
        </>
    )
}
