import React from "react";
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {buttonTitles} from "../../helper/getDataCallCenter";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Box from "../../../../components-ui/Layout/Box";
import {CommonButtonProps} from "../../types/components";

interface Props extends CommonButtonProps {
    blockedButtons: boolean
}

export const ConfirmButton = ({task, blockedButtons, margin, methodsCallCenter}: Props) => {
    const {confirmVisit} = methodsCallCenter
    return (
        <Box className="button-task-content" margin={margin}>
            <Button {...commonPropsButton} variant={blockedButtons ? "disabled" : "success"} children="подвердить"
                    title={blockedButtons ? buttonTitles.blocked : buttonTitles.confirm}
                    onClick={() => confirmVisit(task.payload.visitId, task.id)}/>
        </Box>
    )
}
