import React, {useContext, useEffect, useState} from "react";
import {CEditableField, PropsEditableField} from "./typesEditableField";
import EditableFieldButtons from "./elements/EditableFieldButtons";
import FieldFIO from "./elements/FieldFIO";
import FieldDate from "./elements/FieldDate";
import FieldEnum from "./elements/FieldEnum";
import PhoneOwner from "./elements/PhoneOwner";
import Relation from "./elements/Relation";
import FieldDefault from "./elements/FieldDefault";
import FieldPhone from "./elements/FieldPhone";
import FieldAddress from "./elements/FieldAddress";
import FieldCheckbox from "./elements/FieldCheckbox";
import FieldRadio from "./elements/FieldRadio";
import {Label} from "../../components-ui/DataDisplay/Label";

const EditableFieldContext = React.createContext({} as CEditableField);
export const useEditableField = () => useContext(EditableFieldContext)

export default function EditableField({field, patientId, variant = "editable", parentName = "patientInfo", isEditField = false, parentNameBasicForm}: PropsEditableField) {
    const [editField, setEditField] = useState<boolean>(isEditField);
    const [hover, setHover] = useState(false);
    useEffect(() => setHover(false), [editField]);
    useEffect(() => {
        setEditField(isEditField)
    }, [isEditField])

    const onClickDeleted = () => {};

    let editItemField = null;

    switch (field.type) {
        case "FIO":
            editItemField = <FieldFIO />
            break;
        case "date":
            editItemField = <FieldDate />
            break;
        case "relation":
            editItemField = <Relation />
            break;
        case "phoneOwner":
            editItemField = <PhoneOwner />
            break;
        case "enum":
        case "multipleenum":
        case "multiple_enum":
            editItemField = <FieldEnum />
            break;
        case "phone":
            editItemField = <FieldPhone />
            break;
        case "address":
            editItemField = <FieldAddress />
            break;
        case "boolean":
            if (field.nullable) {
                editItemField = <FieldRadio/>
            } else {
                editItemField = <FieldCheckbox/>
            }
            break;
        default:
            editItemField = <FieldDefault />
            break;
    }


    return (
        <EditableFieldContext.Provider value={{
            field,
            patientId,
            variant,
            setEditField,
            hover,
            onClickDeleted,
            editField
        }}>
            <Label labelText={field.title}
                   isRequired={field.required}
                   colorLabel="textGray"
                   align="leftTop"
                   widthLabelWrap="100%"
                   cursor={variant === 'editable' && !editField ? "pointer" : 'default'}
                   marginWrap={parentName === 'patientInfo' ? '0 0 12px 0' : '0 0 28px 0'}
                   minWidthLabel={parentNameBasicForm  === "WAP" ? "26%" : parentNameBasicForm === "NewVisitForm" ? "35%" : "33%"}
                   onClick={() => {
                       if (variant === 'editable' && !editField) setEditField(true);
                   }}
                   onMouseLeave={() => {
                       if (!editField) setHover(false)
                   }}
                   onMouseEnter={() => {
                       if (!editField) setHover(true)
                   }}
                   onMouseMove={() => {
                       if (!hover && !editField) setHover(true);
                   }}
            >
                {editField ? editItemField : field.text}
                <EditableFieldButtons  />
            </Label>
        </EditableFieldContext.Provider>
    )
};
