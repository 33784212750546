import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const DotsIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 5.39 24"} ref={ref}>
            <circle cx="2.7" cy="21.3" r="2.7"/>
            <circle cx="2.7" cy="12" r="2.7"/>
            <circle cx="2.7" cy="2.7" r="2.7"/>
        </WrapIcon>
    );
});
