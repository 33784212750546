import React, {Dispatch, SetStateAction} from "react";
import Box from "../../../Layout/Box";
import Icon from "../../Icon";

interface PropsNestedArrowIcon {
    openNested: boolean
    setOpenNested: Dispatch<SetStateAction<boolean>>
    childrenCell: any
}

const NestedArrowIcon = ({setOpenNested, openNested, childrenCell}: PropsNestedArrowIcon) => {

    return (
        <Box display="flex" position="relative" padding="0 0 0 25px">
            <Box display="flex" position="absolute" positionOffsets={{top: '0', left: '0'}} height="100%" width="25px" onClick={() => setOpenNested(!openNested)}>
                <Icon name="playArrow" width="8px" height="8px" margin="7px 0 0" transform={openNested ? "rotate(90deg)" : ""} color="textDarkGray"/>
            </Box>
            {childrenCell}
        </Box>
    )
}

export default NestedArrowIcon;
