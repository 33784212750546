import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Message = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 24"} ref={ref}>
            <path d="M16.8,4.78a2.4,2.4,0,0,1,2.4,2.39h0v9.56a2.4,2.4,0,0,1-2.4,2.39H10.92L4.8,24V19.12H2.4A2.4,2.4,0,0,1,0,16.73H0V7.17A2.4,2.4,0,0,1,2.4,4.78H16.8ZM21.6,0A2.4,2.4,0,0,1,24,2.39h0V12a2.4,2.4,0,0,1-2.4,2.39h0V4.78a2.4,2.4,0,0,0-2.4-2.39H4.8A2.4,2.4,0,0,1,7.2,0H21.6Z"/>
        </WrapIcon>
    );
});
