import React from 'react';
import {KkmList} from '../../types';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import {useSelector} from "react-redux";

const useTableFormatter = () => {

    const branches = useSelector(state => state.common.branches)

    const kkmColumn: IColumn<KkmList>[] = [
        {
            headerName: "Номер устройства",
            field: "numberDevice",
            valueFormatter: ({row}) => row.numberDevice
        },
        {
            headerName: "Название",
            field: "name",
            valueFormatter: ({row}) => row.name
        },
        {
            headerName: "Логин",
            field: "login",
            valueFormatter: ({row}) => row.login,
        },
        {
            headerName: "URL сервера",
            field: "url",
            valueFormatter: ({row}) => row.url
        },
        {
            headerName: "Филиал",
            field: "branchId",
            valueFormatter: ({row}) => branches.find(branch => branch.id == row.branchId)?.name
        }
    ]

    return {kkmColumn}
};

export default useTableFormatter;
