import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ServerCommand from "../../../common/server/server-command";
import {loadData, resetDataLoaded, setServerError} from "../../../redux/actions/commonActions";
import {ErrorDescription, networkTroubles, PropsError, useErrorModal} from "../../../components/Errors";

const useApp = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.common.isLoggedIn);
    const {ErrorModal, handleError} = useErrorModal()

    useEffect(() => {
        if (isLoggedIn) {
            ServerCommand.get('general/index-data').then(
                response => {
                    if (response.user.role === 'tablet' && document.location.pathname.indexOf('/cabinet/') === -1) {
                        (document as any).location = '/cabinet/';
                    }
                    (window as any).user = response.user;
                    response.patient.fields.fields.additionalPhone.sort = 3
                    response.patient.fields.fields.phoneOwner.sort = 2
                    response.patient.fields.fields.personalCity.label = 'н.п.'
                    response.patient.fields.fields.personalStreet.label = 'ул.'
                    response.patient.fields.fields.personalHome.label = 'д.'
                    response.patient.fields.fields.personalHousing.label = 'кор.'
                    response.patient.fields.fields.personalApartment.label = 'кв.'
                    dispatch(loadData({...response}));
                }
            );
        }

        ServerCommand.onAuthLoss(() => dispatch(resetDataLoaded()))

        window.addEventListener('unhandledrejection', event => {
            const reason = event.reason as PropsError;

            if (reason.type === 'server_request_error') {

                console.log('show error modal!'); // нужно отобразить окно с ошибкой

                if (reason.status === 0) {
                    handleError(networkTroubles)
                    console.log('network troubles'); // проблемы с сетью (запрос не дошёл до сервака)
                } else {
                    handleError(ErrorDescription(reason))
                    console.log('client or server error'); // неправильный запрос (ошибка на фронте) или ошибка на серваке
                }

                // перенёс из обработки ошибок при запросе general/index-data
                // не знаю, нужно ли это здесь
                dispatch(setServerError({message: reason.description}));
            }
        })
    }, []);

    return ErrorModal
}

export default useApp