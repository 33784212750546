import React, {Dispatch, SetStateAction} from 'react';
import {resetFilter} from "../../../../../redux/actions/scheduleActions";
import {Icon} from "../../../../../components/Icons/Icons";
import Helper from "../../../../../common/helpers/main";
import {useDispatch, useSelector} from "react-redux";

interface Props {
    setTime: Dispatch<SetStateAction<(string | null)[]>>
}

const ResetFilter = ({setTime}: Props) => {
    const dispatch = useDispatch();
    const filter = useSelector(state => state.schedule.filter);
    const snapshot = useSelector(state => state.schedule.snapshotFilter);
    if (!Helper.isEqual(filter, snapshot)) {
        return (
            <div className="schedule-filter-buttons">
                        <span className="shld_btn_dcl" onClick={() => {
                            setTime([snapshot.timeFrom, snapshot.timeTo]);
                            dispatch(resetFilter())
                        }}>
                            <Icon icon="new-close"/>
                        </span>
            </div>
        );
    } else {
        return null
    }

};

export default ResetFilter;
