import {useSelector} from "react-redux";
import {useMemo} from "react";
import Helper from "../../../../../common/helpers/main";
import {PropsUseHelpTextNextVisit} from "../types/hooks";
import {User} from "../../../../../types";

export default function useHelpTextNextVisit({doctorId, timeStart}: PropsUseHelpTextNextVisit) {

    const doctors = useSelector(state => state.common.doctors);
    return useMemo(() => {
        if (!!doctorId) {
            const findDoctor = doctors.find((doctor: any) => doctor.id === doctorId) as User;
            return `Следующий прием ${Helper.Date.formatComingTime(timeStart)}, ${findDoctor.fio}`
        } else {
            return 'Пациент еще не записан на следующий прием. Запишите пациента.'
        }
    }, [doctorId])
}
