import React, {Dispatch, SetStateAction} from 'react';
import {ManagerTask} from "../../../types/common";
import VisitRefusal from "../../../components/Variants/VisitRefusal";
import CheckWaitingList from "../../../components/Variants/CheckWaitingList";
import LongVisitWaiting from "../../../components/Variants/LongVisitWaiting";
import CheckCallerType from "../../../components/Variants/CheckCallerType";
import BadTreatmentPlan from "../../../components/Variants/BadTreatmentPlan";
import UnfinishedVisit from "../../../components/Variants/UnfinishedVisit";
import InsufficientWorkload from "../../../components/Variants/InsufficientWorkload";
import ReducedIncomingCallsEfficiency from "../../../components/Variants/ReducedIncomingCallsEfficiency";
import ReducedIncomingCallsCount from "../../../components/Variants/ReducedIncomingCallsCount";
import ReducedConsultationsEfficiency from "../../../components/Variants/ReducedConsultationsEfficiency";
import {MethodsManagerTasks} from "../../../types/components";
import InvoicesInconsistency from "../../../components/Variants/InvoicesInconsistency";
import NotCheckedPayments from "../../../components/Variants/NotCheckedPayments";

interface Props {
    task: ManagerTask
    renderBranches: () => React.ReactNode | null
    methodsManagerTasks: MethodsManagerTasks
    setZIndex?: Dispatch<SetStateAction<number>>
}

const TaskContent = ({methodsManagerTasks, setZIndex, ...props}: Props) => {
    switch (props.task.type) {
        case "check_caller_type":
            return <CheckCallerType methodsManagerTasks={methodsManagerTasks} setZIndex={setZIndex} {...props}/>
        case "check_waiting_list":
            return <CheckWaitingList {...props}/>
        case "empty_performed_plan":
        case "empty_recommended_plan":
        case "incomplete_recommended_plan":
        case "not_changed_plan_in_survey":
        case "not_recommended_other_doctor":
            return <BadTreatmentPlan {...props}/>
        case "long_visit_waiting":
            return <LongVisitWaiting {...props}/>
        case "insufficient_workload":
            return <InsufficientWorkload {...props}/>
        case "reduced_consultations_efficiency":
            return <ReducedConsultationsEfficiency {...props}/>
        case "reduced_incoming_calls_count":
            return <ReducedIncomingCallsCount {...props} />
        case "reduced_incoming_calls_efficiency":
            return <ReducedIncomingCallsEfficiency {...props}/>
        case "unfinished_visit":
            return <UnfinishedVisit {...props}/>
        case "visit_refusal":
            return <VisitRefusal {...props}/>
        case "invoices_inconsistency":
            return <InvoicesInconsistency {...props}/>
        case "not_checked_payments":
            return <NotCheckedPayments {...props}/>
        default:
            return null
    }
};

export default TaskContent;
