import React from "react";
import {Divider} from "../../../../../../../components-ui/DataDisplay/Divider";
import Box from "../../../../../../../components-ui/Layout/Box";
import SubTitleTask from "../../../../../../../containers/Tasks/components/UI/SubTitleTask";
import LabelTask from "../../../../../../../containers/Tasks/components/UI/LabelTask";
import Helper from "../../../../../../../common/helpers/main";
import Typography from "../../../../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../../../../../../containers/Tasks/data/commonPropsSComponent";
import PatientCard from "../../../../../../PatientCard/PatientCard";
import {useDialogsManager} from "../../../../../../../containers/DialogsManager/DialogsManager";
import {IncomingCallPatient} from "../../../../../types/taskData";
import { STaskList, STaskItem } from "../../../../../../../containers/Tasks/style";
import {useSelector} from "react-redux";

interface Props {
    patient: IncomingCallPatient | null
}

const RelativesProgressCall = ({patient}: Props) => {
    const {showSlideModal} = useDialogsManager();
    const patientRelationTypesTitles = useSelector(state => state.patient.relationTypesTitles)
    if (!patient) return null
    if (!patient.relatives || !patient.relatives.length) return null;

    return (
        <>
            <Divider/>
            <STaskList padding="14px 0">
                {patient.relatives.map(relation => {
                    let relationTypes = patientRelationTypesTitles.general;
                    if (!!relation.personalGender) {
                        relationTypes = Object.assign({}, patientRelationTypesTitles.general, patientRelationTypesTitles[relation.personalGender])
                    }
                    return (
                        <Box key={relation.id} margin="0 0 8px">
                            <SubTitleTask margin="0 0 2px" children={relationTypes[relation.relationType]} />
                            <STaskItem margin="2px 0">
                                <Typography {...commonPropsTypography} children="ФИО"/>
                                <Typography {...commonPropsTypography}
                                            display="flex"
                                            alignItems="center"
                                            cursor="pointer"
                                            color="info"
                                            onClick={() => {
                                                showSlideModal(<PatientCard patientId={relation.id}/>)
                                            }}
                                            children={Helper.getFullName(relation)} />
                            </STaskItem>
                            {!!relation.personalBirthday && (
                                <LabelTask label="Возраст" children={Helper.Date.getAge(relation.personalBirthday)}/>
                            )}
                        </Box>
                    )
                })}
            </STaskList>
        </>
    )
}

export default RelativesProgressCall
