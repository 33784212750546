import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import React from "react";
import IconsSimpleTask from "../containers/CallsList/containers/SimpleCall/components/IconsSimpleTask";
import Helper from "../../../common/helpers/main";
import Box from "../../../components-ui/Layout/Box";
import PatientCard from "../../PatientCard/PatientCard";
import {useDialogsManager} from "../../../containers/DialogsManager/DialogsManager";
import {PlannedCall, TaskCallCenter} from "../types/common";

interface Props {
    noMargin?: boolean
    isLink?: boolean
    uniqPatient: TaskCallCenter[]
    uniqType: TaskCallCenter[]
    plannedCall: PlannedCall
}

export const TitleTask = ({noMargin = false, isLink, uniqPatient, uniqType, plannedCall}: Props) => {
    const {showSlideModal} = useDialogsManager()
    const noName = uniqPatient.filter(task => !!task.targetPerson.name).length === 0;
    const isLinkName = noName || !uniqPatient[0].targetPerson.patientId ? false : isLink;
    return (
        <Box display="flex">
            <IconsSimpleTask uniqType={uniqType}/>
            <Typography display="block" variant="small" fontWeight="600" fontStyle={noName ? 'italic' : 'normal'}
                        lineHeight="1.71" margin={noMargin ? '0' : "0 0 17px"}>
                {(noName && !plannedCall.phoneOwner) && <Typography as="span" variant="small" children="имя не указано" />}
                {!noName && uniqPatient.map((task, index) => {
                    const coma = index !== uniqPatient.length - 1 ? ', ' : null;
                    return (
                        <Typography as="span"
                                    cursor={"pointer"}
                                    variant="small"
                                    onClick={() => {
                                        if (isLinkName) {
                                            showSlideModal(<PatientCard patientId={task.targetPerson.patientId}/>)
                                        }
                                    }}
                                    color={isLinkName ? 'info' : 'text'}>
                            {Helper.getFullName(task.targetPerson)}{coma}
                        </Typography>
                    )
                })}
                {(noName && !!plannedCall.phoneOwner) && (
                    <Typography as="span"
                                cursor={"pointer"}
                                variant="small"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    showSlideModal(<PatientCard patientId={plannedCall.phoneOwner?.id}/>)
                                }}
                                color={'info'}>
                        {Helper.getFullName(plannedCall.phoneOwner)}
                    </Typography>
                )}
            </Typography>
        </Box>

    )
}

export default TitleTask
