import * as React from 'react'
import {StagesEditorProps} from "./typesStages";
import StagesTable from "./elementes/StagesTable";
import {BoxBody} from "../../components-ui/styledComponent/common/wraps";
import {useState} from "react";
import {LineStages, StagedLink} from './styledStages';
import {prepareServicesGroups} from "./helperStages";

export default function StagesEditor({stagedPlan, stages, setStages, servicesGroups, itemsStatuses, requiredDurationStages, initiallyShow}: StagesEditorProps) {

    const [active, setActive] = useState(initiallyShow);

    if (stages.length === 0) return null;

    const planAddition = stages.find((elem) => elem.isPlanAddition)

    return (
        <>
            <BoxBody nonBorderBottom>
                <StagedLink children="Этапы плана лечения" onClick={() => setActive(!active)}/>
                {active && <StagesTable stages={stages}
                                        stagedPlan={stagedPlan}
                                        itemsStatuses={itemsStatuses}
                                        servicesGroups={prepareServicesGroups(servicesGroups)}
                                        setStages={setStages}
                                        planAddition={planAddition}
                                        requiredDurationStages={requiredDurationStages}
                />}
            </BoxBody>
            <LineStages/>
        </>
    );
}
