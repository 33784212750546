import React from 'react';
import {UserList} from '../../types';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import GeneralHelper from '../../../../../common/helpers/general-helper';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import DateHelper from '../../../../../common/helpers/date-helper';
import PhoneHelper from '../../../../../common/helpers/phone-helper';

const useTableFormatter = () => {

    const employeesColumn: IColumn<UserList>[] = [
        {
            headerName: "Сотрудник",
            field: "fullName",
            valueFormatter: ({row}) => <Typography>{GeneralHelper.getFio(row)}</Typography>
        },
        {
            headerName: "Мобильный телефон",
            field: "phone",
            valueFormatter: ({childrenCell}) => childrenCell ? PhoneHelper.format(childrenCell) : "-"
        },
        {
            headerName: "Дата рождения",
            field: "birthday",
            valueFormatter: ({row}) => row.birthday ? DateHelper.formatDate(row.birthday, 'DD MMM YYYY') : "-",
        },
        {
            headerName: "Возраст",
            field: "age",
            valueFormatter: ({row}) => row.birthday ? DateHelper.getAgeNumber(row.birthday) : "-"
        },
        {
            headerName: "Пол",
            field: "gender",
            valueFormatter: ({childrenCell}) => GeneralHelper.getGender(childrenCell) ? GeneralHelper.getGender(childrenCell) : "-"
        },
        {
            headerName: "Профессия",
            field: "roleName"
        }
    ]

    return {employeesColumn}
};

export default useTableFormatter;
