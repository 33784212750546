import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import Box from "../../../components-ui/Layout/Box";
import Select from "../../../components-ui/Inputs/Select/Select";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import Button from "../../../components-ui/Inputs/Button/Button";
import {StatePatientList} from "../types/common";
import {useSelector} from "react-redux";
import Helper from "../../../common/helpers/main";
import {allDoctors} from "../data";
import useDebounce from "../../../common/hooks/useDebounce";
import {withoutTrashed} from "../../../common/helpers/Doctor";
import Filter from "./Filter";
import {FilterIcon} from "../../../components-ui/DataDisplay/Icon/components/standard/action/FilterIcon";

interface Props {
    state: StatePatientList
    setState: Dispatch<SetStateAction<StatePatientList>>
    isReset: boolean
}

const Header = ({state, setState, isReset}: Props) => {
    const [showFilter, setShowFilter] = useState(false);
    const [search, setSearch] = useState(state.searchQuery);
    const debounceSearch = useDebounce(search, 500)
    useEffect(() => {
        setState(prevState => ({...prevState, searchQuery: debounceSearch}))
    }, [debounceSearch])

    const doctors = useSelector(state => state.common.doctors);
    const options = useMemo(() => {
        return [allDoctors, ...withoutTrashed(doctors).map(doctor => {
            return {
                value: doctor.id,
                label: Helper.getFio(doctor),
                color: doctor.color
            }
        })]
    }, [])

    return (
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center"
             padding="16px 28px 16px 20px">
            <Select placeholder="Все"
                    label='Врач:'
                    variant="colored"
                    value={state.doctor}
                    options={options}
                    onChange={doctor => setState(prevState => ({...prevState, doctor}))}
            />
            <TextField onChange={setSearch}
                       isSearch={true}
                       widthInput="100%"
                       margin="0 20px"
                       placeholder="Поиск пациента по номеру карты, ФИО или телефону"
                       value={search}/>
            <Button variant={'default'}
                    size="sm-standard"
                    onClick={() => {
                        setShowFilter(true)
                    }}
                    height="40px"
                    width="40px"
                    margin="0 20px 0 0"
                    children={(
                        <FilterIcon width="24px" height="24px"/>
                    )}
            />
            <Button variant={isReset ? 'standard' : 'disabled'}
                    children='Сбросить'
                    size="md"
                    onClick={() => {
                        setState(prevState => ({...prevState, doctor: allDoctors, birthday: null, searchQuery: '', patientDebt: false, patientPrepayment: false}))
                        setSearch('')
                    }}/>
            {showFilter && (
                <Filter state={state} setState={setState} isReset={isReset} close={() => setShowFilter(false)}/>
            )}
        </Box>
    );
};

export default Header;
