import {useCallback, useEffect, useRef, useState} from 'react';
import ServerCommand from '../../../../common/server/server-command';
import {StateHandsetList, HandsetList} from '../types';
import {pageSize} from '../../../PatientsList/data';
import {useLazyLoading} from '../../../../common/hooks/useLazyLoading';

const useSettingsHandset = () => {

    const refScrollList = useRef(null) as any

    const [state, setState] = useState<StateHandsetList>({
        handsetList: null,
    })
    const loadData = (nextPage: boolean = false) => {
        ServerCommand.get("handset/list").then((response) => {
            setState({handsetList: response.list})
        });
    };

    const refreshDataFromServer = useCallback(() => loadData(), [])

    useEffect(() => loadData(), [])

    return {
        state,
        setState,
        refreshDataFromServer
    }
};

export default useSettingsHandset;
