import Helper from "./main";
import {PatientCancellation} from "../../content/modals/Cancellation/typesCancellation";
import {PatientValues} from "../../containers/PatientField";

/**
 * Возвращает номер карты пациента в формате "(№ 99999)"
 * @param cardNumber
 */
export function formatCardNumber(cardNumber: string|number|null): string {
    return (cardNumber !== null) ? `(№ ${cardNumber})` : '';
}

/**
 * Возвращает ФИО и номер карты в формате "Фамилия И. О. (№ 99999)"
 * @param patient
 */
export function formatFioAndCardNumber(patient: PatientCancellation|PatientValues): string {
    let result = Helper.getFio(patient);

    if (!!patient.number) {
        result += ' ' + formatCardNumber(patient.number);
    }

    return result;
}
