import {useState} from 'react';
import {IStateXrayRateModal} from '../types';
import useLoader from '../../../../../../components/Errors';
import {useModal} from '../../../../../../containers/DialogsManager/containers/Modal/Modal';
import ServerCommand from '../../../../../../common/server/server-command';
import {SaveRateParams} from '../../../../types';
import DateHelper from '../../../../../../common/helpers/date-helper';
import {SelectedMonth} from '../../../../../Salaries/types';
import moment from 'moment';

interface Props {
    rate: number
    rateActiveFrom?: string
    selectedBranch: number
    pictureType: string
    employeeId: number
    refreshDataFromServer: () => void
}

const useXrayRateMark = ({rate, rateActiveFrom, selectedBranch, pictureType, employeeId, refreshDataFromServer}: Props) => {

    const [loader, setLoader] = useLoader();

    const {closeModal} = useModal();

    const [state, setState] = useState<IStateXrayRateModal>({
        rate: String(rate),
    })

    const [selectedMonth, setSelectedMonth] = useState<SelectedMonth>({
        month: new Date(rateActiveFrom || '').getMonth() + 1,
        year: new Date(rateActiveFrom || '').getFullYear(),
    });

    const changeRate = (rate: string) => {setState(prevState => ({...prevState, rate}))}

    const params: SaveRateParams = {
        branchId: selectedBranch,
        employeeId: !!employeeId ? employeeId : null,
        pictureType,
        rate: +state.rate,
        activeFrom: String(moment(`${selectedMonth.year}-${selectedMonth.month}`).format(DateHelper.FORMAT_SERVER_MONTH))
    }

    const saveNewXrayRate = () => {
        setLoader(true);
        ServerCommand.post('xray-picture/rate/save', params, () => {
            closeModal()
        }).then(() => {
            refreshDataFromServer()
        })
    }

    return {
        state,
        selectedMonth,
        setSelectedMonth,
        changeRate,
        loader,
        saveNewXrayRate
    }
};

export default useXrayRateMark;
