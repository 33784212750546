import TreatmentPlan from "./TreatmentPlan";
import _ from 'underscore';
import Obj from "../../common/helpers/object";
import Arr from "../../common/helpers/Arr";
import {ExpandedPlanItem, PlanItem} from "./plan-types";
import {DefaultStage, Stage} from "./types";
import {IdGenerator} from "../../common/IdGenerator";

/**
 * Автоматически распределяет услуги плана лечения по этапам
 */
export default class StageBuilder {
    /**
     * Этапы плана лечения
     */
    public stages: Stage[];

    protected stagesNamesMap: StagesNamesMap|null = null;

    /**
     * Этапы по умолчанию, на основе которых создаются этапы плана лечения
     */
    private readonly defaultStages: DefaultStage[];

    /**
     * План лечения
     */
    private readonly plan: TreatmentPlan;

    private planDirectionsMap: PlanDirectionsMap | null = null;

    private stageIdGenerator: IdGenerator | null = null;

    constructor(stages: Stage[], defaultStages: DefaultStage[], plan: TreatmentPlan) {
        this.stages = stages;
        this.defaultStages = defaultStages;

        this.connectToPlan(plan);
        this.plan = plan;
    }

    /**
     * Подключает текущий объект строителя этапов к указанному плану лечения (регистрирует обработчки событий плана)
     * @param plan
     */
    connectToPlan(plan: TreatmentPlan) {
        plan.listen('expand_item', StageBuilder.expandItemStages);

        plan.listen('saving', () => {
            if (plan.isDirty) {
                this.rebuild();
            }
        });
    }

    static squeezeItemStages = (expandedItems: ExpandedPlanItem[], squeezedItem: PlanItem) => {
        expandedItems = expandedItems.filter(expandedItem => typeof expandedItem.stageId === 'number');

        /* --- собираем информацию об этапах (stages) --- */

        squeezedItem.stages = {};

        _.chain(expandedItems)
            .filter(expandedItem => !!expandedItem.direction)
            .groupBy('stageId')
            .each((stageItems, stageId: string) => {
                (squeezedItem.stages as any)[stageId] = _.countBy(stageItems, 'direction');
            });

        delete (squeezedItem as any).stageId;

        /* --- собираем информацию о подтвержённых этапах (confirmedStages)  --- */

        squeezedItem.confirmedStages = _.chain(expandedItems)
            .filter(item => typeof item.isStageConfirmed === 'undefined' || item.isStageConfirmed)
            .pluck('stageId')
            .unique()
            .value() as any;

        delete (squeezedItem as any).isStageConfirmed;

        /* --- собираем информацию об этапах, изменённых вручную (manuallyChangedStages)  --- */

        squeezedItem.manuallyChangedStages = _.chain(expandedItems)
            .where({isStageManuallyChanged: true})
            .pluck('stageId')
            .unique()
            .value() as any;

        delete (squeezedItem as any).isStageManuallyChanged;
    }

    static expandItemStages = (originalItem: PlanItem, expandedItems: ExpandedPlanItem[]) => {
        if (typeof originalItem.stages !== 'undefined') {
            const directionsExpandedItems = _.groupBy(expandedItems, 'direction');

            /* --- распаковка stageId --- */

            _.each(originalItem.stages, (stageDirectionsQuantity, stageId) => {
                _.each(stageDirectionsQuantity, (quantity, direction) => {
                    if (directionsExpandedItems[direction]) {
                        directionsExpandedItems[direction].splice(0, quantity).forEach(expandedItem => expandedItem.stageId = Number(stageId));
                    }
                });
            });

            /* --- распаковка isStageConfirmed и isStageManuallyChanged --- */

            const confirmedStages: {[stageId: number]: number} = originalItem.confirmedStages ? Arr.flip(originalItem.confirmedStages) : {};
            const manuallyChangesStages: {[stageId: number]: number} = originalItem.manuallyChangedStages ? Arr.flip(originalItem.manuallyChangedStages): {};

            expandedItems.forEach(item => {
                item.isStageConfirmed = (item.stageId as number) in confirmedStages;
                item.isStageManuallyChanged = (item.stageId as number) in manuallyChangesStages;

                delete (item as any).confirmedStages;
                delete (item as any).manuallyChangedStages;
                delete (item as any).stages;
            });
        }
    }

    /**
     * "Перестраивает" этапы - меняет список этапов, распределяет по ним услуги
     */
    rebuild = () => {
        const expandedPlanItems = this.plan.expandItems();
        const movablePlanItems = this.filterMovableItems(expandedPlanItems);

        const defaultStages = this.defaultStages.filter(this.isDefaultStageAllowed);

        /* --- распределяем конкретные услуги по этапам  --- */
        // применение правила content.services, в котором указаны id услуг, которые должны быть включены в этап

        const itemsServicesMap: { [serviceId: number]: ExpandedPlanItem[] } = _.groupBy(movablePlanItems, 'serviceId');

        /**
         * Карта распределения направлений по этапам, используется для распределения доп услуг
         */
        const directionsStages: DirectionsStages = {};

        defaultStages
            .filter(defaultStage => defaultStage.content.services)
            .forEach(defaultStage => {
                (defaultStage.content.services as number[]).forEach(serviceId => {
                    if (!itemsServicesMap[serviceId]) return;

                    const allBoundServices: number[] = [];
                    let direction: string|null = null;
                    let stageId: number|null = null;
                    const requiredServices = this.plan.filler.getServiceRequiredServices(serviceId);

                    itemsServicesMap[serviceId].forEach(item => {
                        stageId = this.setItemStage(item, defaultStage);

                        // вычисляем услуги, "связанные" с данной услугой - их нужно перенести в тот же этап.
                        // связанными считаются доп услуги, обязательные для данной услуги

                        if (requiredServices) {
                            const requiredServicesIds = requiredServices.filter((serviceId: number|number[]) => typeof serviceId === 'number') as number[];
                            allBoundServices.push(...requiredServicesIds);
                        }

                        direction = item.direction;
                    });

                    // убираем услугу из карты, чтобы она не была перераспределена по следующему правилу
                    delete itemsServicesMap[serviceId];

                    // перемещаем связанные услуги в тот же этап, убираем их из карты
                    _.unique(allBoundServices).forEach(boundServiceId => {
                        if (boundServiceId in itemsServicesMap) {
                            const boundServiceItems = itemsServicesMap[boundServiceId];
                            // Сначала пытаемся взять доп услугу, которая уже находится в нужном этапе.
                            // Это нужно, чтобы не происходила "подмена" доп услуг, при которой они становятся неподтверждёнными
                            let boundItemIndex = boundServiceItems.findIndex(item => (item.direction === direction) && (item.stageId === stageId));
                            if (boundItemIndex === -1) {
                                // иначе берём первую услугу с подходящим направлением
                                boundItemIndex = boundServiceItems.findIndex(item => item.direction === direction);
                            }

                            if (boundItemIndex !== -1) {
                                const boundItem = itemsServicesMap[boundServiceId].splice(boundItemIndex, 1)[0];
                                this.setItemStage(boundItem, defaultStage);
                            }
                        }
                    });

                    // заполняем directionsStages
                    if (direction !== null && stageId !== null) {
                        if (!directionsStages[direction]) {
                            directionsStages[direction] = [];
                        }

                        directionsStages[direction].push(stageId);
                    }
                });
            });


        let unmovedItems = _.values(itemsServicesMap).flat();

        if (unmovedItems.every(item => item.isAdditional) && !_.isEmpty(directionsStages)) {
            unmovedItems = this.distributeFreeAdditionalItems(unmovedItems, directionsStages);
        }

        /* --- распределяем оставшиеся услуги по этапам на основе направления --- */
        // применение правила content.directions, в котором указаны коды направлений, которые должны быть включены в этап

        const itemsDirectionsMap: { [direction: string]: ExpandedPlanItem[] } = _.groupBy(unmovedItems, 'direction');

        defaultStages
            .filter(defaultStage => defaultStage.content.directions)
            .forEach(defaultStage => {
                (defaultStage.content.directions as string[]).forEach(direction => {
                    if (direction in itemsDirectionsMap) {
                        itemsDirectionsMap[direction].forEach(item => this.setItemStage(item, defaultStage));
                        // удаляем направление из карты, чтобы далее определить нераспределённые услуги
                        delete itemsDirectionsMap[direction];
                    }
                });
            });

        /* --- перемещаем услуги в этап "Дополнение плана" --- */

        this.fillPlanAdditionStage(expandedPlanItems);

        /* --- удаляем пустые этапы --- */

        this.deleteEmptyStages(expandedPlanItems);

        /* --- обновляем список врачей для этапов --- */

        this.updateStagesPerformers(expandedPlanItems);

        /* --- сохраняем в пункты плана лечения новые этапы --- */
        // для этого нужно "сжать" информацию об этапах, тк пункты плана лечения хранятся в сжатом (squeezed) состоянии.

        Arr.squeeze(expandedPlanItems, TreatmentPlan.getPlanItemCode, {
            handler: (expandedItems: ExpandedPlanItem[], squeezedItem: PlanItem, itemCode: string) => {
                StageBuilder.squeezeItemStages(expandedItems, squeezedItem);
                const item = (this.plan.getItem(itemCode) as PlanItem);
                item.stages = squeezedItem.stages;
                item.confirmedStages = squeezedItem.confirmedStages
            }
        });
    }

    /**
     * Определяет, разрешён ли указанный этап по условиям применения этого этапа (conditions)
     * @param defaultStage
     */
    isDefaultStageAllowed = (defaultStage: DefaultStage): boolean => {
        const conditions = defaultStage.conditions;

        if (!conditions) {
            return true;
        }

        const actualDirectionsMap = this.getPlanDirectionsMap();

        if (conditions.hasDirections) {
            return _.every(conditions.hasDirections, direction => direction in actualDirectionsMap);
        } else if (conditions.hasDirectionsBesides) {
            return _.chain(actualDirectionsMap)
                .keys()
                .difference(conditions.hasDirectionsBesides)
                .value().length > 0;
        }

        return true;
    }

    /**
     * Получает карту актуальных направлений плана
     */
    getPlanDirectionsMap(): PlanDirectionsMap {
        if (this.planDirectionsMap === null) {
            this.planDirectionsMap = Obj.fill(this.plan.defineDirections(null, true), true) as PlanDirectionsMap;
        }

        return this.planDirectionsMap;
    }

    /**
     * Фильтрует переданный массив услуг плана лечения, оставляя только те услуги, которые можно перемещать по этапам
     * @param items
     */
    filterMovableItems = (items: ExpandedPlanItem[]): ExpandedPlanItem[] => {
        // услугу можно перемещать только если этап ещё не определён,
        // либо если в текущий этап услуга была определена автоматически, а не вручную,
        items = items.filter(item => typeof item.stageId !== 'number' || !item.isStageManuallyChanged);

        const planAdditionStage = this.getPlanAdditionStage();

        // если есть этап дополнения плана - перемещать можно только те услуги, которые не находятся в этом этапе
        if (planAdditionStage) {
            const cratedPlanAdditionStage = this.stages.find(stage => stage.name === planAdditionStage.name);

            if (!!cratedPlanAdditionStage) {
                items = items.filter(item => item.stageId !== cratedPlanAdditionStage.id);
            }
        }

        return items;
    }

    deleteEmptyStages(planItems: ExpandedPlanItem[]) {
        const itemsWithStages = planItems.filter(item => typeof item.stageId === 'number');
        const usedStagesIds = Arr.flip(_.pluck(itemsWithStages, 'stageId') as number[]);

        const defaultStagesMap: { [stageName: string]: DefaultStage } = _.indexBy(this.defaultStages, 'name');

        this.stages = this.stages.filter(stage => {
            return !(stage.name in defaultStagesMap) || stage.id in usedStagesIds;
        });
    }

    updateStagesPerformers(planItems: ExpandedPlanItem[]) {
        const stagesItems = _.groupBy(planItems, 'stageId')

        this.stages.forEach(stage => {
            const stageItems = stagesItems[stage.id];
            if (stageItems) {
                const stageItemsPerformers = _.unique(_.pluck(stageItems, 'performerId'));
                // удаляем лишних врачей из performers (оставляем только тех, которые есть в stageItems)
                stage.performers = _.intersection(stage.performers, stageItemsPerformers);
                // добавляем новых врачей в конец массива
                stage.performers.push(..._.difference(stageItemsPerformers, stage.performers))
            }
        });
    }

    /**
     * Создаёт новый этап на основе этапа по умолчанию
     * @param templateStage
     */
    createStage(templateStage: DefaultStage): Stage {
        const newStage = {
            id: this.getNewStageId(),
            name: templateStage.name,
            duration: ((templateStage.duration !== null) ? templateStage.duration : '') as string,
            durationVariants: templateStage.durationVariants,
            isPlanAddition: !!templateStage.content.planAddition,
            isNew: true,
            performers: []
        };

        /* --- вставляем новый этап в массив этапов --- */

        if (newStage.isPlanAddition) {
            // дополнение плана всегда вставляем последним
            this.stages.push(newStage);
        } else {
            // остальные этапы вставляем с учётом их сортировки
            const existedStagesNames = Arr.flip(_.pluck(this.stages, 'name'));
            const implementedDefaultStages = this.defaultStages.filter(defaultStage => {
                return (defaultStage.name in existedStagesNames) || defaultStage.name === templateStage.name;
            });

            let prevStage: DefaultStage|null = null;

            implementedDefaultStages.forEach(defaultStage => {
                if (defaultStage.name === templateStage.name) {
                    if (prevStage === null) {
                        this.stages.unshift(newStage)
                    } else {
                        const prevStageIndex = this.stages.findIndex(stage => stage.name === (prevStage as DefaultStage).name);
                        this.stages.splice(prevStageIndex + 1, 0,  newStage);
                    }
                }

                prevStage = defaultStage;
            });
        }

        this.stagesNamesMap = null;

        return newStage;
    }

    /**
     * Распределяет "свободные" доп услуги по этапам. Свободными считаются доп услуги, которые остались нераспределёнными после применения
     * правила content.services, если все основные услуги были распределены
     * @param items
     * @param directionsStages
     *
     * @return {ExpandedPlanItem[]} нераспределённые услуги
     */
    distributeFreeAdditionalItems(items: ExpandedPlanItem[], directionsStages: DirectionsStages): ExpandedPlanItem[] {

        const directionsItems: { [direction: string]: ExpandedPlanItem[] } = _.groupBy(items, 'direction');

        /* --- вычисляем карту равномерного распределения свободных доп услуг по этапам --- */

        let countMap: {
            [direction: string]: {
                [stageId: number]: number
            }
        } = {};

        _.each(directionsItems, (directionItems, direction) => {
            if (!directionsStages[direction]) return;

            const stagesCount = directionsStages[direction].length;
            const averageCount =  Math.floor(directionItems.length / stagesCount);
            const residualCount = directionItems.length % stagesCount;

            const directionCountMap: { [stageId: string]: number } = {};

            directionsStages[direction].forEach((stageId, index) => {
                directionCountMap[stageId] = averageCount;

                if (index < residualCount) {
                    directionCountMap[stageId]++;
                }
            });

            countMap[direction] = directionCountMap;
        });

        /* --- распределяем пункты плана по этапам согласно countMap --- */

        return items.filter(item => {
            if (item.direction in countMap) {
                const stageId = Number(_.keys(countMap[item.direction])[0]);
                this.setItemStage(item, stageId);

                countMap[item.direction][stageId]--;
                if (countMap[item.direction][stageId] === 0) {
                    delete countMap[item.direction][stageId];
                }

                if (_.isEmpty(countMap[item.direction])) {
                    delete countMap[item.direction];
                }

                return false;
            }

            return true;
        });
    }

    fillPlanAdditionStage(items: ExtendedPlanItem[]) {
        const planAdditionStage = this.getPlanAdditionStage();
        if (!planAdditionStage) return;

        /* --- определяем список закрытых этапов --- */

        // этап считается закрытым, в нем выполнены все услуги, которые находились в этом этапе до текущего
        // перераспределения по этапам (rebuild), и если в следующем этапе есть хотя бы одна такая выполненная услуга

        const itemsStageMap: { [stageId: number]: ExtendedPlanItem[] } = _.groupBy(items, 'stageId');
        const closedStages: Stage[] = [];

        // фильтруем пустые этапы
        const stages = this.stages.filter(stage => !stage.isPlanAddition && (stage.id in itemsStageMap));

        stages.forEach((stage, index) => {
            if (itemsStageMap[stage.id].every(item => item.isStageRecentlyChanged || item.isDone || item.isAdditional)) {
                const nextStage = stages[index + 1];

                if (nextStage && itemsStageMap[nextStage.id].some(item => item.isDone && !item.isAdditional)) {
                    closedStages.push(stage);
                }
            }
        });

        /* --- перемещаем услуги из закрытых этапов в этап дополнения плана --- */

        closedStages.forEach(stage => {
            const stageItems = itemsStageMap[stage.id];

            if (stageItems) {
                stageItems.forEach(item => {
                    if (item.isStageRecentlyChanged) {
                        this.setItemStage(item, planAdditionStage);
                    }
                })
            }
        });
    }

    /**
     * Привязывает услугу плана лечения к указанному этапу
     * @param item - услуга плана лечения
     * @param defaultStage - объект этапа по умолчанию, либо id конкретного этапа, к которому нужно привязать услугу
     * плана лечения
     *
     * @return number - id этапа, к которому была привязана услуга плана лечения
     */
    setItemStage(item: ExtendedPlanItem, defaultStage: DefaultStage|number): number {
        const stagesNamesMap = this.getStagesNamesMap();
        let stageId: number;

        if (typeof defaultStage === 'number') {
            stageId = defaultStage;
        } else {
            // если вторым аргументом передан DefaultStage, то
            // находим этап с нужным именем, либо создаём, если его ещё нет

            let stage;

            if (defaultStage.name in stagesNamesMap) {
                stage = stagesNamesMap[defaultStage.name];
            } else {
                stage = this.createStage(defaultStage);
            }

            stageId = stage.id;
        }

        // привязываем этап к услуге плана лечения
        if (item.stageId !== stageId) {
            item.stageId = stageId;
            item.isStageConfirmed = false;
            item.isStageRecentlyChanged = true;
        }

        return stageId;
    }

    getPlanAdditionStage(): DefaultStage|undefined {
        return this.defaultStages.find(defaultStage => defaultStage.content.planAddition);
    }

    getNewStageId() {
        if (this.stageIdGenerator === null) {
            this.stageIdGenerator = new IdGenerator(_.pluck(this.stages, 'id'));
        }

        return this.stageIdGenerator.generate();
    }

    getStagesNamesMap(): StagesNamesMap {
        if (this.stagesNamesMap === null) {
            this.stagesNamesMap = _.indexBy(this.stages, 'name')
        }

        return this.stagesNamesMap;
    }
}

type PlanDirectionsMap = {
    [directionCode: string]: boolean
}

type DirectionsStages = {
    [directionCode: string]: number[]
}

interface ExtendedPlanItem extends ExpandedPlanItem {
    isStageRecentlyChanged?: boolean;
}

interface StagesNamesMap {
    [stageName: string]: Stage
}
