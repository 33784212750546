import React from "react";
import commonLabelOptions from "../../../../../../../containers/PatientField/helper/commonLabelOptions";
import Select from "../../../../../../../components-ui/Inputs/Select/Select";
import {Label} from "../../../../../../../components-ui/DataDisplay/Label";
import {useWAP} from "../../../WaitingAndPlanned";

export default function DirectionsFormWAP() {
    const {form, activeTab, setForm} = useWAP()
    const {directionsNames, directions, validationFields, wantThisDoctor} = form;
    return (
        <Label labelText='Направление'
               isRequired={!wantThisDoctor && activeTab === 'waiting-list'}
               {...commonLabelOptions(true, 'WAP')}>
            <Select options={directionsNames}
                    value={directions}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    widthSelect="334px"
                    isError={validationFields.directions}
                    onChange={value => setForm(prevState => ({
                        ...prevState,
                        directions: value,
                        validationFields: {
                            ...prevState.validationFields,
                            directions: false
                        }
                    }))}
                    placeholder='не выбрано'/>
        </Label>
    )
}
