import _ from "lodash";

export const customStyleModalStandard = (customStyles: {overlay?: any, content?: any}) => {
    const styles = {
        overlay: {
            zIndex: '220',
            backgroundColor: 'rgba(27, 37, 48, 0.65)',
            overflow: 'auto',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            overflow: 'visible',
            maxHeight: '100vh',
            border: 'none',

        }
    }
    return _.merge(styles, customStyles)
};

export const customStyleModalParameters = () => {
    return {
        overlay: {
            zIndex: '100',
            backgroundColor: 'rgba(27, 37, 48, 0.65)',
            overflow: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        content: {
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            width: 'max-content',
            height: 'max-content',
            maxHeight: '100vh',
            marginRight: 'auto',
            transform: 'none',
            padding: 'auto',
            overflow: 'auto',
            border: 'auto',
        }
    }
};
