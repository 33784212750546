import styled from "styled-components";

interface FilterButtons {
    openButtons: boolean,
    heightContainer?: number
}


export const SWrapTitle = styled.div`
    display: flex;
    align-items: center;
    & > div {
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`

export const SContainerHeader = styled.div`
    padding: 24px 20px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const SContainerButtons = styled.div<FilterButtons>`
    position: relative;
    padding: 15px 20px 0px 20px;
    overflow: hidden;
    transition: height 0.6s ease-in-out;
    height: ${props => (props.heightContainer + 'px')};
`

export const SWrapButton = styled.div<FilterButtons>`
    margin-bottom: 8px;
    &:nth-child(n+3) {
        margin-bottom: ${props => props.openButtons ? '8px' : '12px'};
    }
    transition-property: margin-bottom;
    ${props => !props.openButtons && `
        transition-delay: .6s;
    `};
    button:first-child {
        margin-right: 11px;
    }
`

export const SWrapperButton = styled.div`
    padding: 0 0 8px;
`
