import React from 'react';
import {GetActualRatesResult} from '../../types';
import useTableFormatter from './hooks/useTableFormatter';
import Table from '../../../../components-ui/DataDisplay/Table';
import Loader from '../../../../components-ui/Feedback/Loader/Loader';
import Paper from '../../../../containers/UI-Collection/Elements/Surfaces/Paper';

interface Props {
    loader: boolean
    xrayRateResponse: GetActualRatesResult
    selectedBranch: number
    refreshDataFromServer: () => void
}

const XrayRateTable = ({xrayRateResponse, loader, selectedBranch, refreshDataFromServer}: Props) => {
    const {
        xrayRateRows,
        xrayRateColumns,
        XrayRowTotal
    } = useTableFormatter({xrayRateResponse, selectedBranch, refreshDataFromServer})
    return (
        <Paper padding="24px" minHeight={688}>
            <Loader visible={loader}/>
            {xrayRateRows &&
                <Table columns={xrayRateColumns}
                       rows={xrayRateRows}
                       variantTable="outline"
                       isHovering={false}
                       fontSizeRow="14px"
                       maxHeight="500px"
                       total={XrayRowTotal()}
                />
            }
        </Paper>
    );
};

export default XrayRateTable;
