import React, {Dispatch, SetStateAction, useMemo} from 'react';
import {ResponseUserDetail} from '../types';
import CardForm from '../../../../../../../components-ui/DataDisplay/CardForm';
import TextField from '../../../../../../../components-ui/Inputs/TextField/TextField';

interface Props {
    state: ResponseUserDetail
    setState: Dispatch<SetStateAction<ResponseUserDetail>>
}
const AddressInfo = ({state, setState}: Props) => {
    return useMemo(() =>
            <CardForm style={{margin: '0 0 16px 0'}} title='Фактический адрес проживания' forms={[
                {
                    label: 'Город',
                    input: <TextField widthInput='100%'
                                      onChange={(value) => {
                                          const personal = state.detail.personal ?? {
                                              city: '',
                                              street: '',
                                              home: '',
                                              housing: '',
                                              apartment: '',
                                          };
                                          setState(prevState => (
                                              {
                                                  ...prevState,
                                                  detail: {
                                                      ...prevState.detail,
                                                      personal: {
                                                          ...personal,
                                                          city: value,
                                                      },
                                                  }
                                              }
                                          ));
                                      }}
                                      value={state.detail.personal?.city ?? ''} />,
                },
                {
                    label: 'Улица',
                    input: <TextField widthInput='100%'
                                      onChange={(value) => {
                                          const personal = state.detail.personal ?? {
                                              city: '',
                                              street: '',
                                              home: '',
                                              housing: '',
                                              apartment: '',
                                          };
                                          setState(prevState => (
                                              {
                                                  ...prevState,
                                                  detail: {
                                                      ...prevState.detail,
                                                      personal: {
                                                          ...personal,
                                                          street: value,
                                                      },
                                                  }
                                              }
                                          ));
                                      }}
                                      value={state.detail.personal?.street ?? ''} />,
                },
                {
                    label: 'Дом',
                    input: <TextField widthInput='100%'
                                      onChange={(value) => {
                                          const personal = state.detail.personal ?? {
                                              city: '',
                                              street: '',
                                              home: '',
                                              housing: '',
                                              apartment: '',
                                          };
                                          setState(prevState => (
                                              {
                                                  ...prevState,
                                                  detail: {
                                                      ...prevState.detail,
                                                      personal: {
                                                          ...personal,
                                                          home: value,
                                                      },
                                                  }
                                              }
                                          ));
                                      }}
                                      value={state.detail.personal?.home ?? ''} />,
                },
                {
                    label: 'Корпус',
                    input: <TextField widthInput='100%'
                                      onChange={(value) => {
                                          const personal = state.detail.personal ?? {
                                              city: '',
                                              street: '',
                                              home: '',
                                              housing: '',
                                              apartment: '',
                                          };
                                          setState(prevState => (
                                              {
                                                  ...prevState,
                                                  detail: {
                                                      ...prevState.detail,
                                                      personal: {
                                                          ...personal,
                                                          housing: value,
                                                      },
                                                  }
                                              }
                                          ));
                                      }}
                                      value={state.detail.personal?.housing ?? ''} />,
                },
                {
                    label: 'Квартира',
                    input: <TextField widthInput='100%'
                                      onChange={(value) => {
                                          const personal = state.detail.personal ?? {
                                              city: '',
                                              street: '',
                                              home: '',
                                              housing: '',
                                              apartment: '',
                                          };
                                          setState(prevState => (
                                              {
                                                  ...prevState,
                                                  detail: {
                                                      ...prevState.detail,
                                                      personal: {
                                                          ...personal,
                                                          apartment: value,
                                                      },
                                                  }
                                              }
                                          ));
                                      }}
                                      value={state.detail.personal?.apartment ?? ''} />,
                },
            ]} />
        ,[state.detail.personal]);
};

export default AddressInfo;
