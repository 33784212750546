import styled from "styled-components";
import {PropsPriceListItem} from "../types/style";

export const PagePriceList = styled.div`
  background: ${props => props.theme.colors.secondary};
  max-width: 768px;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  margin: 0 auto;
  padding: 32px 12px 80px;
  @media (max-width: 600px) {
    padding: 32px 14px 80px;
  }
`;

export const PriceListItem = styled.div<PropsPriceListItem>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  cursor: pointer;
  ${props => !!props.backgroundColor && `background: ${(props.backgroundColor[0] === '#' || props.backgroundColor[0] === 'r') ? props.backgroundColor : props.theme.colors[props.backgroundColor]};`};

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -50%;
    width: 100%;
    height: 100%;
    z-index: -1;
    ${props => !!props.backgroundColor && `background: ${(props.backgroundColor[0] === '#' || props.backgroundColor[0] === 'r') ? props.backgroundColor : props.theme.colors[props.backgroundColor]};`};
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -50%;
    bottom: 0;
    width: 200%;
    height: 1px;
    background: ${props => props.theme.colors.borderGray};
  }

  @media (max-width: 600px) {
    padding: 12px 13px 12px 0;
  }
`

export const PriceListTree = styled.div`
  padding: 0 0 0 27px;
  @media (max-width: 600px) {
    padding: 0 0 0 12px;
  }
`

export const PriceListElements = styled.div`
  position: relative;
  padding: 5px 20px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -50%;
    bottom: 0;
    width: 200%;
    height: 1px;
    background: ${props => props.theme.colors.borderGray};
  }

  @media (max-width: 600px) {
    padding: 5px 12px;
  }
`

export const InfoBox = styled.div`
  max-width: 410px;
  @media (max-width: 600px) {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -21px;
      width: 100%;
      height: 1px;
      background: ${props => props.theme.colors.borderGray};
    }
  }
`

export const InfoIcon = styled.div`
  position: relative;
  width: 253px;
  margin: 0 19px 0 0;
  @media (max-width: 600px) {
    width: 61%;
    margin: auto;
    bottom: 17px;
  }
`

export const FixedArticlesWrapper = styled.div`
  max-width: 768px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  background-color: ${props => props.theme.colors.white};
  overflow: hidden;
  box-shadow: 0 3px 13px -4px rgba(13, 28, 56, 0.25);
  @media (max-width: 600px) {
    flex-direction: column;
  }
`