import _ from "lodash";
import ServerCommand from "../../../../../common/server/server-command";
import Printer from "../../../../../common/server/printer";
import {usePreparationForAdmission} from "../PreparationForAdmission";
import useLoader from "../../../../../components/Errors";

export default function usePrintDocument() {

    const {state, props, forms, fields, setState, setForms} = usePreparationForAdmission();
    const [loaderPrint, setLoaderPrint] = useLoader();
    const [loaderSave, setLoaderSave] = useLoader();

    const savePatient = async () => {
        for (let formName in forms) {
            const form = forms[formName];

            if (form.visibleFields.length === 0) {
                continue;
            }

            const data = _.pick(form.basic, form.visibleFields) as any;

            _.forEach(data, (fieldValue, fieldName) => {
                switch (fields[fieldName].type) {
                    case "enum":
                        data[fieldName] = data[fieldName].value
                        break;
                    default:
                        break
                }
            })

            if (!!form.currentPatient) {
                data.id = form.currentPatient.id;
                if (state.tabs.length > 1) {
                    if (formName === 'patient') {
                        if (!!forms.representative && forms.representative.visibleFields && !!forms.representative.currentPatient) {
                            data.representativeId = forms.representative.currentPatient.id
                            if (!!forms.representative.basic.relation) {
                                data.relationsTo = {
                                    [forms.representative.currentPatient.id]: forms.representative.basic.relation.value
                                }
                            }
                        }
                    }
                }

                await ServerCommand.post('patient/edit', data);
            } else {
                data.representativeFor = forms.patient.currentPatient?.id;

                const response = await ServerCommand.post('patient/add', data);
                setForms(prevForms => ({
                    ...prevForms,
                    [formName]: {
                        ...prevForms[formName],
                        currentPatient: {
                            id: response.primary.id,
                            ...data
                        }
                    }
                }))
                await ServerCommand.post('patient/edit', {
                    id: forms.patient.currentPatient?.id,
                    relationsTo: {
                        [response.primary.id]: form.basic.relation.value
                    }
                });
            }
        }
    }

    const handleSaveBtn = () => {
        setLoaderSave(true)
        savePatient().then(() => {
            props.reloadData()
            setLoaderSave(false)
        })
    }

    const handlePrintBtn = () => {
        setLoaderPrint(true)
        savePatient().then(() => {
            const documents = _.keys(state.checkboxes).filter(checkboxName => state.checkboxes[checkboxName]);
            const command = new ServerCommand('visit/generate-initial-documents', {
                visitId: props.visitId, documents
            });
            command.exec().then(response => {
                Printer.print(response.documents).then(() => {
                    setState(prevState => {
                        _.forEach(prevState.checkboxes, (value, checkboxName) => {
                            if (value && prevState.documentRequired[checkboxName]) {
                                prevState.documentRequired[checkboxName] = false
                            }
                            prevState.checkboxes[checkboxName] = false
                        })
                        return {
                            ...prevState
                        }
                    })
                    setLoaderPrint(false)
                });
            });
        })
    }

    return {
        loaderSave,
        loaderPrint,
        handleSaveBtn,
        handlePrintBtn
    }
}
