import React from "react";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import {useChoiceOrCompletion} from "../ChoiceOrCompletion";
import {COMPLETION} from "../helper/constants";

export default function SubTitle() {
    const {completionHasTabs, parentName, form} = useChoiceOrCompletion();
    if (!completionHasTabs) return null;
    if (parentName === COMPLETION) {
        if (!!form.isPlanFulfilled) {
            return (
                <Typography variant="min-title"
                            children={"Услуги из согласованного плана лечения выполнены"}
                            margin="0 0 32px" textAlign="center"/>
            )
        } else {
            return null
        }
    } else {
        return null
    }
}
