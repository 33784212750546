import React from 'react'
import PropTypes from 'prop-types'
import Helper from "../../common/helpers/main";
import './Table.scss'
import Counter from "../../components-ui/Inputs/Counter/Counter";
import {Icon} from '../Icons/Icons';
import InputText from "./Elements/InputText";
import TableSelect from "./Elements/TableSelect";
import RowTree from "./Elements/RowTree";
import RowDeleteButton from "./RowDeleteButton";


export default class TableRow extends React.Component {
    static propTypes = {
        row: PropTypes.object.isRequired,
        selectMode: PropTypes.string,
        columns: PropTypes.object.isRequired,
        isEditable: PropTypes.bool,
        isNested: PropTypes.bool,
        isSelected: PropTypes.bool,
        isActive: PropTypes.bool,
        isFocused: PropTypes.bool,
        tipId: PropTypes.string,

        onClick: PropTypes.func,
        onChange: PropTypes.func,
        onChangeSelectTable: PropTypes.func,
        inputChangeNumber: PropTypes.func,
        onClickTree: PropTypes.func,
        getDisabledTip: PropTypes.func,

        counterMin: PropTypes.number,
        selectedTreeItems: PropTypes.object,
        draggable: PropTypes.bool,
        onDrag: PropTypes.func,
        onDrop: PropTypes.func,
        mousePositionY: PropTypes.number,
        handlePositionY: PropTypes.func
    };

    state = {
        treeActive: false
    };

    render() {
        const {row, isActive, isFocused, draggable, isNested} = this.props;

        let tableRow = "table__row",
            tipProps = {
                'data-tip': row.disabledTip,
                'data-for': this.props.tipId,
                'data-tip-disable': true
            };

        if (isFocused) tableRow += " table__row--chosen";

        if (isActive) tableRow += " table__row--active";

        if (isNested) tableRow += ' table__row--nested';

        if (row.isPale) tableRow += ' table__row--pale';

        if (!row.isEnabled) {
            tableRow += " table__row--disabled";
            tipProps['data-tip-disable'] = false;
        }

        if (row.isTableNested) tableRow += " table__row--subgroup";

        return (
            <div className={tableRow}
                 key={'row_' + row.code}
                 onClick={this.handleClick}
                 draggable={draggable}
                 onDragStart={this.handleDragStart}
                 onDragOver={this.handleDragOver}
                 onDragLeave={this.handleDragLeave}
                 onDragEnd={this.handleDragEnd}
                 onDrop={this.handleDrop}
                 {...tipProps}>
                {row.cells.map((cell, cellIndex) => {
                        return (
                            <div className={`table__cell table__cell--${cell.code.toLowerCase()} ${cell.className || ''}`}
                                 key={'cell_' + cell.code + '_' + row.code}>
                                {this.renderCell(cell, cellIndex)}
                            </div>
                        )
                    }
                )}
            </div>
        )
    }

    renderCell(cell, cellIndex) {
        let result = [];
        const column = this.props.columns[cell.code];
        const {row, isFocused, selectMode, isNested} = this.props;

        /* добавляем чекбокс, отображающий, выбрана строка или нет */
        if ((selectMode === 'multiple') && (cellIndex === 0)) {
            result.push(
                <div className="table__checkbox" key={cellIndex + 'checkbox'}>
                    <Icon icon="new-check"/>
                </div>
            );
        }

        if (row.isEnabled && column.editable && (isFocused || (selectMode === 'multiple' && this.props.isSelected))) {
            result.push(this.renderEditableCell(column.type, cell, row));
        } else {
            let cellContent;

            if (cell.content !== null) {
                switch (column.type) {
                    case 'price' :
                        cellContent = (typeof cell.content !== 'object') ? Helper.Price.format(cell.content) : cell.content;
                        break;
                    case 'components.ui.Select.Select.js' :
                        cellContent = cell.content.current.label;
                        break;
                    case 'changePrice' :
                        cellContent = <input
                            type='text'
                            className="input"
                            value={cell.content}
                            onChange={(e) => {
                                let value = e.target.value
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
                                this.props.inputChangeNumber(row.code, cell.code, value);
                            }}
                            key={cellIndex + 'changePrice'}
                        />;
                        break;
                    default :
                        cellContent = cell.content;
                        if (row.nestedRows !== undefined) {
                            if (row.nestedRows.length && cellIndex === 0 && !isNested) {
                                cellContent = <RowTree
                                    children={cell.content}
                                    onClickTree={this.props.onClickTree}
                                    key={cellIndex + 'rowTree'}
                                    selectedTreeItems={this.props.selectedTreeItems}
                                    rowCode={row.code}
                                />;
                            }
                        }

                        break;
                }
            } else {
                cellContent = '-';
            }

            result.push(cellContent);
        }

        /* кнопка удаления */
        if (this.props.isEditable && row.onDelete && (cellIndex === row.cells.length - 1) && isFocused) {
            result.push(
                <RowDeleteButton
                    isEnabled={row.isDeletionEnabled}
                    tip={row.deletionTip}
                    onClick={this.handleDelete}
                />
            );
        }

        return result
    }

    renderEditableCell(type, cell, row) {
        let result = null;

        switch (type) {
            case 'number' :
                result = <Counter
                    value={cell.content}
                    onChange={(value) => this.props.onChange(row.code, cell.code, value, row.parentRowCode)}
                    min={this.props.counterMin}
                />;
                break;
            case 'components.ui.Select.Select.js' :
                result = <TableSelect cell={cell} row={row} onChange={this.props.onChangeSelectTable}/>;
                break;

            case 'text' :
                result = <InputText cell={cell} row={row} onChange={this.props.onChange}/>;
                break;

            default:
                break;
        }

        return result
    }

    handleClick = () => this.props.onClick(this.props.row);

    handleDragStart = (e) => this.props.handlePositionY(e.clientY);

    handleDragOver = (e) => {
        if (e.preventDefault) e.preventDefault();
        const {mousePositionY} = this.props;
        e.currentTarget.classList.add('over');
        if (mousePositionY >= e.clientY) {
            e.currentTarget.classList.add('over--top');
            e.currentTarget.classList.remove('over--bottom');
        } else {
            e.currentTarget.classList.add('over--bottom');
            e.currentTarget.classList.remove('over--top');
        }

        return false;
    };

    handleDragLeave = (e) => {
        e.currentTarget.classList.remove('over');
        e.currentTarget.classList.remove('over--top');
        e.currentTarget.classList.remove('over--bottom');
    };

    handleDragEnd = () => {
        const {onDrag, row, handlePositionY} = this.props;
        let items = document.querySelectorAll('.table__row');
        onDrag(row.code);
        [].forEach.call(items, (item) => {
            item.classList.remove('over');
            item.classList.remove('over--top');
            item.classList.remove('over--bottom');
        });
        handlePositionY(0);
    };

    handleDrop = (e) => {
        if (e.stopPropagation) e.stopPropagation();
        const {onDrop, row} = this.props;
        onDrop(row.code);
        return false;
    };

    handleDelete = () => {
        const {row} = this.props;
        row.onDelete(row.code);
    }
}
