import React from 'react';
import Tooltip from "../../../../../../components-ui/DataDisplay/Tooltip/Tooltip";
import {ReceiptsReport} from "../../../../types";
import useTotalSumDirection from "../containers/Sums/hooks/useTotalSumDirection";
import {StateReceipts} from "../types";

interface Props  {
    code: string
    report: ReceiptsReport
    state: StateReceipts
}

const TooltipPercentage = ({code, report, state}: Props) => {
    const sums = useTotalSumDirection({report, code, state})
    return (
        <>
            {sums.previousPercentage !== null && (
                <Tooltip id={`previousPercentage-${code}`} place="bottom" effect="solid" isArrow={true}/>
            )}
            {sums.previousYearPercentage !== null && !!report.previousYear && (
                <Tooltip id={`previousYearPercentage-${code}`} place="bottom" effect="solid" isArrow={true}/>
            )}
        </>

    );
};

export default TooltipPercentage;
