import React, {Dispatch, SetStateAction} from 'react';
import {ManagerTask} from "../../../types/common";
import {CheckCallerTypePayload} from "../../../types/taskData";
import {STaskList} from '../../../../../containers/Tasks/style';
import {AudioRecordButton, ContactPhone} from "../../Labels";
import ButtonComplete from "../../../containers/TaskItem/components/ButtonComplete";
import {MethodsManagerTasks} from "../../../types/components";
import {useCheckCallerType} from "./hooks/useCheckCallerType";
import LabelTask from "../../../../../containers/Tasks/components/UI/LabelTask";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {useOptionsCallerTypes} from "../../../../../common/hooks/useOptionsCallerTypes";
import {Divider} from "../../../../../components-ui/DataDisplay/Divider";
import Checkbox from "../../../../../components-ui/Inputs/Checkbox/Checkbox";

interface Props {
    task: ManagerTask<CheckCallerTypePayload>
    renderBranches: () => React.ReactNode | null
    methodsManagerTasks: MethodsManagerTasks
    setZIndex?: Dispatch<SetStateAction<number>>
}

const CheckCallerType = ({task, renderBranches, methodsManagerTasks, setZIndex}: Props) => {
    const {
        taskComplete,
        state,
        onChangeCallerType,
        onChangeExcludeFromReports,
        onChangePurposesAchieved
    } = useCheckCallerType({task, methodsManagerTasks})
    const options = useOptionsCallerTypes()

    return (
        <>
            <STaskList padding="19px 0 16px">
                {renderBranches()}
                <ContactPhone contactPhone={task.payload.contactPhone}/>
                <AudioRecordButton recordLink={task.payload.recordLink}/>
            </STaskList>
            <Divider/>
            <STaskList padding="19px 0 16px">
                <LabelTask label="Тип звонящего"
                           children={<Select sizeSelect="sm"
                                             widthSelect="230px"
                                             value={state.callerType}
                                             onChange={onChangeCallerType}
                                             options={options}
                                             onMenuOpen={() => !!setZIndex && setZIndex(12)}
                                             onMenuClose={() => !!setZIndex && setZIndex(11)}

                           />}
                           alignItems="center"
                />
                {state.excludeFromReports !== undefined && (
                    <LabelTask label="Исключить из статистики"
                               children={<Checkbox label={''} checked={state.excludeFromReports}
                                                   onChange={onChangeExcludeFromReports}/>}
                               alignItems="center"
                    />
                )}
                {state.purposesAchieved !== undefined && (
                    <LabelTask label="Успешный звонок"
                               children={<Checkbox label={''} checked={state.purposesAchieved}
                                                   onChange={onChangePurposesAchieved}/>}
                               alignItems="center"
                    />
                )}
            </STaskList>
            <ButtonComplete taskId={task.id} taskComplete={taskComplete}/>
        </>

    );
};

export default CheckCallerType;
