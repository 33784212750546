import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const DoubleFilledArrowDownIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 23.73 23.91"} ref={ref}>
            <path d="M0,.35,11.69,12l12-12"/>
            <path d="M0,12.22,11.69,23.91l12-12"/>
        </WrapIcon>
    );
});
