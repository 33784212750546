import React from 'react';
import {ManagerTask} from "../../types/common";
import {UnfinishedVisitPayload} from "../../types/taskData";
import {Patients, VisitState} from "../Labels";
import {STaskList} from "../../../../containers/Tasks/style";

interface Props {
    task: ManagerTask<UnfinishedVisitPayload>
    renderBranches: () => React.ReactNode | null
}

const UnfinishedVisit = ({task, renderBranches}: Props) => {
    return (
        <STaskList padding="19px 0 16px">
            {renderBranches()}
            <Patients patients={[task.payload.patient]} colorPatient="info"/>
            <VisitState visitState={task.payload.visitState}/>
        </STaskList>
    );
};

export default UnfinishedVisit;
