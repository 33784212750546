import React from "react";
import PropTypes from "prop-types";
import Helper from "common/helpers/main";
import Button from "../../../../components-ui/Inputs/Button/Button";
import ServerCommand from "../../../../common/server/server-command";
import TreatmentPlansConstructor from "../../../../containers/TreatmentPlansConstructor/TreatmentPlansConstructor";
import SaveButtonLabel from "../../../../containers/TreatmentPlansConstructor/SaveButtonLabel";
import SVG from "../../../../common/helpers/SVG";
import {SlideModalContext} from "../../../../containers/DialogsManager/containers/SlideModal/SlideModal";

export default class EditPlan extends React.Component {
    static contextType = SlideModalContext;
    static propTypes = {
        teethMap: PropTypes.object.isRequired,
        plan: PropTypes.object.isRequired,
        visitId: PropTypes.number.isRequired,
        patientId: PropTypes.number.isRequired,

        onDataSave: PropTypes.func.isRequired
    }

    state = {
        selectedServiceId: 0,
        target: [],
        loaderPrint: false,
    }

    render() {
        return (
            <>
                <p className="treatment-plan__header">Редактирование плана лечения "Согласованный"</p>
                <TreatmentPlansConstructor getLoadCommandData={this.getLoadCommandData}
                                           renderFooter={this.renderFooter}
                                           sendPlan={this.sendPlan}
                                           performedOnly={true}
                                           checkItemsStatuses={true}
                                           patientId={this.props.patientId}
                />
            </>
        )
    }

    renderFooter = ({isRecommended, savePlan, validation, attractToQuantityDivide}) => {
        const saveBtnVariant = validation.isSuccess() ? 'success' : 'disabled';
        return (
            <div className="treatment-plan__footer">
                <Button variant="default"
                        children="Отменить"
                        size="md"
                        onClick={this.context.closeSlideModal}/>
                <Button variant={saveBtnVariant}
                        size="md"
                        startIcon={<SVG name="print" />}
                        children="Сохранить и распечатать"
                        loader={this.state.loaderPrint}
                        onClick={() => this.handleSaveBtnClick(savePlan)}/>

                <p className="treatment-plan__btn-label">
                    <SaveButtonLabel
                        validation={validation}
                        attractToQuantityDivide={attractToQuantityDivide}
                    />
                </p>

            </div>)
    };


    handleSaveBtnClick = (savePlan) => {
        this.setState({loaderPrint: true})
        savePlan().then(() => {
            this.props.onDataSave();
            this.context.closeSlideModal();
        })
    };

    sendPlan = (planData, planId) => {
        planData = Helper.clone(planData);
        planData.id = planId;

        const command = new ServerCommand('treatment-plan/save', planData);
        return command.exec();
    };

    getLoadCommandData = () => ({
        visitId: this.props.visitId,
        patientId: this.props.patientId,
        isCurrentVisit: false
    });
}
