import React from 'react';
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";

interface Props {
    incomingCallsCount: number
    norm: number
}

export const IncomingCallsCount = ({incomingCallsCount, norm}: Props) => {
    const normPercent = Math.round(100 * incomingCallsCount / norm);

    return (
        <LabelTask label="Количество входящих звонков"
                   children={`${incomingCallsCount} (${normPercent}% от нормы)`}
                   alignItems="flex-start"
        />
    );
};
