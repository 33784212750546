import React, {useRef, useState} from 'react';
import './User.scss';
import Helper from "../../../common/helpers/main";
import {useSelector} from "react-redux";
import useOutsideClick from "../../../common/hooks/useOutsideClick";
import UserMenu from "./UserMenu";
import {HeaderUser, UserData, UserInfo, UserName, UserRole, UserArrow} from "../styledHeader";
import SVG from "../../../common/helpers/SVG";
import Avatar from "../../../components-ui/DataDisplay/Avatar";

export default function User() {
    const ref = useRef();
    const [submenuIsVisible, setSubmenuIsVisible] = useState(false);
    const user = useSelector(state => state.user);

    useOutsideClick(ref, () => {
        setSubmenuIsVisible(false);
    });

    return (
        <HeaderUser active={submenuIsVisible}
                    onClick={() => setSubmenuIsVisible(!submenuIsVisible)}
                    ref={ref}>
            <Avatar img="https://www.shareicon.net/data/256x256/2016/08/18/813848_people_512x512.png" status="online" />
            <UserData>
                <UserInfo>
                    <UserName children={Helper.getFio(user)} />
                    <UserRole children={user.roleName} />
                </UserInfo>
                <UserArrow active={submenuIsVisible}>
                    <SVG name="arrow-select" />
                </UserArrow>
            </UserData>

            <UserMenu submenuIsVisible={submenuIsVisible} />

        </HeaderUser>
    )
}



