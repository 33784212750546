import React from 'react'
import Cell from './Cell'
import GeneralHelper from '../../../../common/helpers/general-helper'

export default class Column extends React.PureComponent
{
    render()
    {
        const cells = this.props.cells;
        const doctors = this.props.doctors;
        const mainDoctors = this.props.mainDoctors;
        const isOneMainDoctor = (mainDoctors[0]) && (mainDoctors[0] === mainDoctors[1]);

        return (
            <div className="dayCalendar_column">
                <div className="dayCalendar_roomName">{this.props.chair.name}</div>

                {mainDoctors.map((mainDoctorId, index) =>
                    <div className={'dayCalendar_doctor ' + (isOneMainDoctor ? 'sameD' : '') + (mainDoctorId ? '' : ' emptyD')}
                         style={mainDoctorId ? {backgroundColor: doctors[mainDoctorId].color} : {}}
                         key={index}>
                        {mainDoctorId ? doctors[mainDoctorId].fio : ''}
                    </div>
                )}

                {this.renderCells(cells)}

            </div>
        );
    }

    renderCells(cells)
    {
        let result = [];

        GeneralHelper.forEachObj(this.props.timeLine, (timeLineItem, time) =>
        {
            if(!cells[time]) return;

            let cellProps = Object.assign({}, cells[time]);

            cellProps.doctor = (cellProps.doctorId ? this.props.doctors[cellProps.doctorId] : null);
            cellProps.patient = (cellProps.patientId ? this.props.patients[cellProps.patientId] : null);
            cellProps.isMainDoctor = (this.props.mainDoctors[cellProps.halfDayNum] === cellProps.doctorId);

            cellProps.key = cellProps.timeStart;
            cellProps.commands = this.props.commands;
            cellProps.date = this.props.date;
            cellProps.chairId = this.props.chairId;
            cellProps.onUpdate = this.props.update;
            cellProps.height = timeLineItem.height;
            cellProps.timeLine = this.props.timeLine;
            cellProps.splitInterval = this.props.splitInterval;
            cellProps.uniteInterval = this.props.uniteInterval;
            cellProps.availableTimeUnite = this.props.availableTimeUnite;
            cellProps.maxHeightBottom = this.getMaxHeightBottom(cellProps.timeStart, cellProps.doctorId);
            cellProps.maxHeightTop = this.getMaxHeightTop(cellProps.timeStart, cellProps.doctorId);
            cellProps.timeTo = this.props.timeTo;
            cellProps.currentTime = this.props.currentTime;
            cellProps.resizeVisit = this.props.resizeVisit;
            cellProps.currentDate = this.props.currentDate;
            cellProps.onCopyPatient = this.props.onCopyPatient;
            cellProps.changeTimeHighlight = this.props.changeTimeHighlight;
            cellProps.transferPatient = this.props.transferPatient;
            cellProps.clearPatientsBuffer = this.props.clearPatientsBuffer;

            cellProps.handleCellClick = this.props.handleCellClick;
            cellProps.highlightedCells = this.props.highlightedCells

            result.push(
                <Cell {...cellProps}/>
            );
        });

        return result;
    }

    getMaxHeightBottom(timeStart, doctorId) {
        let height = 0;
        let newTime = null;
        const {cells, timeLine, timeTo} = this.props;
        GeneralHelper.forEachObj(cells, (cell, cellTime) => {
            if (cellTime >= timeStart) {
                if ((cell.patientId !== undefined && cellTime !== timeStart) || (cell.doctorId !== doctorId)) {
                    newTime = cellTime;
                    return false
                }
            }
        });

        if (newTime === null) {
            newTime = timeTo;
        }

        GeneralHelper.forEachObj(timeLine, (value, cellTime) => {
           if (cellTime >= timeStart && cellTime < newTime) {
               if (value.height === null) {
                   height += 24
               } else {
                   height += value.height + 2;
               }
           }
        });



        return {
            maxHeight: height - 1,
            newTime
        }
    }
    getMaxHeightTop(timeStart, doctorId) {
        let height = 0;
        let newTime = null;
        let {timeLine} = this.props;

        const cells = Object.keys(this.props.cells).reverse();
        const newCell = {};


        cells.forEach(cell => {
           newCell[cell] = this.props.cells[cell];
        });


        GeneralHelper.forEachObj(newCell, (cell, cellTime) => {
            if (cellTime <= timeStart) {
                if ((cell.patientId !== undefined && cellTime !== timeStart) || (cell.doctorId !== doctorId)) {
                    newTime = cell.timeEnd;
                    return false
                }
                newTime = cell.timeStart
            }
        });

        GeneralHelper.forEachObj(timeLine, (value, cellTime) => {
            if (cellTime >= newTime && cellTime < timeStart) {
                if (value.height === null) {
                    height += 24
                } else {
                    height += value.height + 2;
                }
            }
        });

        return {
            maxHeight: height - 1,
            newTime
        }
    }
}
