import React from 'react';
import Box from '../../components-ui/Layout/Box';
import Typography from '../../components-ui/DataDisplay/Typography/Typography';
import useXrayPictures from './hooks/useXrayPictures';
import XrayPicturesFilter from './components/XrayPicturesFilter';
import XrayPicturesTable from './components/XrayPicturesTable';
import LoaderPage from '../../components/LoaderPage';

const XrayPicturesPage = () => {

    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        loaderPage,
        loaderTable,
        selectedBranch,
        setSelectedBranch,
        creator,
        setCreator,
        xRayPicturesResponse,
        optionsBranches
    } = useXrayPictures()

    return (
        <Box padding="62px 20px 80px 28px">
            <Typography variant="title" fontWeight="bold">Снимки</Typography>

            <XrayPicturesFilter optionsBranches={optionsBranches}
                                selectedBranch={selectedBranch}
                                setSelectedBranch={setSelectedBranch}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                creator={creator}
                                setCreator={setCreator}
            />
            {!loaderPage ?
                <XrayPicturesTable xRayPicturesResponse={xRayPicturesResponse}
                                   selectedBranch={selectedBranch.value}
                                   loader={loaderTable}
                />
                :
                <LoaderPage height={338}/>
            }
           </Box>
    );
};

export default XrayPicturesPage;