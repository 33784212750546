import React from 'react';
import Swirl, {PropsSwirl} from "../../components/Swirl";
import Box from "../../../../components-ui/Layout/Box";
import {Legend} from "../../types/common";
import Avatar from "../../../../components-ui/DataDisplay/Avatar";
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";

interface Props {
    textSwirl: PropsSwirl
    legends: Legend[]
}

const SwirlWrap = ({textSwirl, legends}: Props) => {
    return (
        <>
            <Swirl {...textSwirl}/>
            <Box display="flex" alignItems="center" justifyContent="center" margin="28px 0 0">
                <Typography color="primary" lineHeight="1"
                            fontSize="28px" fontWeight="bold" children={Math.round(+textSwirl.percentage) + '%'}/>
            </Box>
            <Box display="flex" margin="28px 0 0" flexWrap="wrap">
                {legends.map((legend, index) => {
                    return (
                        <Box key={legend.code} display="flex" alignItems="center"
                             margin={index === 0 ? '0 30px 8px 0' : '0 0 8px'}>
                            <Avatar bgColor={index === 0 ? "#447ce6" : '#aa70cb'} size="8px" margin="0 12px 0 0"/>
                            <Typography variant="small" children={legend.name}/>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" alignItems="center">
                <Avatar bgColorTheme="primary" size="8px" margin="0 12px 0 0"/>
                <Typography variant="small" children="Эффективность"/>
            </Box>
        </>

    );
};

export default SwirlWrap;
