import _ from 'lodash'
const TeethMapHelper = {
        isToothExists: function (toothNumber) {
            return !(((toothNumber % 10) > 5) && this.isChildTooth(toothNumber))
        },

        isChildTooth: (toothNumber) => (toothNumber > 50),

        getJawCode: toothNumber => {
            const toothPosition = (toothNumber > 50) ? (toothNumber - 40) : toothNumber;
            return toothPosition < 30 ? 'upper' : 'lower';
        },

        getJawName: function (toothNumber) {
            return this.getJawNameByCode(this.getJawCode(toothNumber));
        },

        getJawCodeByName: function (jawName) {
            return jawName === 'н.ч.' ? 'lower' : 'upper';
        },

        getJawNameByCode: function (jawCode) {
            return jawCode === 'lower' ? 'н.ч.' : 'в.ч.';
        }
    }
;

export default TeethMapHelper
