import Target from "./Target";
import Helper from "../../../common/helpers/main";
import TargetFactory from "./TargetFactory";

export default class Jaw extends Target {
    matchTeeth(teethNumbers) {
        const jawTeeth = teethNumbers.filter(toothNumber => this.targetUnits[0] === Helper.TeethMap.getJawName(toothNumber));
        return jawTeeth.length ? jawTeeth : false;
    }

    getProcedureTargets(procedureMeasureType, teethMap) {
        const jawCode = Helper.TeethMap.getJawCodeByName(this.targetUnits[0]);
        const jawTeeth = teethMap[jawCode].filter(tooth => Helper.TeethMap.isToothExists(tooth.number));
        let childTargets;

        switch(procedureMeasureType) {

            case 'TOOTH':
                /* процедуры с единицей измерения "зуб" применяем ко всем зубам челюсти по отдельности */
                childTargets = jawTeeth.map(tooth => TargetFactory.create(tooth.number, procedureMeasureType));
                break;

            case 'SEGMENT':
                /* процедуры с единицей измерения "сегмент" применяем ко всем зубам челюсти разом */
                childTargets = [
                    TargetFactory.create(jawTeeth.map(tooth => tooth.number), procedureMeasureType)
                ];
                break;

            default:
                /* остальные единицы измерения не зависят от переданного зуба */
                childTargets = [
                    TargetFactory.create(this.targetUnits[0], procedureMeasureType)
                ];
        }

        return childTargets;
    }
}
