import Target from "./Target";
import TargetFactory from "./TargetFactory";
import Measure from "../../../common/Service/Measure";

export default class Tooth extends Target {
    matchTeeth(teethNumbers) {
        // если список зубов пункта плана содержит все зубы, выбранные в карте зубов, значит услуга данного пункта плана выбрана
        return teethNumbers.includes(this.targetUnits[0]) ? this.targetUnits : false;
    }

    getProcedureTargets(procedureMeasureType, teethMap) {
        let procedureMeasure = new Measure(procedureMeasureType);
        return [
            TargetFactory.create(procedureMeasure.getTargetUnitByTooth(this.targetUnits[0]), procedureMeasure)
        ];
    }
}