import React, {forwardRef} from 'react';
import {WrapIcon} from '../../containers/WrapIcon';
import {IIcon} from '../../types/common';

export const ToothHalfIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path
                d="M22.94,6.42a11.29,11.29,0,0,0-.3-2C22.1,2,20.48,1,17.78,1a18.16,18.16,0,0,0-3.34.36c-.28,0-1.18.22-1.34.26A6.65,6.65,0,0,1,12,1.76h-.1a7.36,7.36,0,0,1-1.06-.14L9.52,1.36A18.14,18.14,0,0,0,6.2,1C3.52,1,1.92,2,1.36,4.44a14.57,14.57,0,0,0-.3,1.94A17,17,0,0,0,1.14,10a23.69,23.69,0,0,0,1,4.84,22.81,22.81,0,0,0,.78,2.26c.16.4.28.68.36.86A20.23,20.23,0,0,0,4.7,20.64C5.66,22.12,6.6,23,7.22,23c.26,0,.68-.88,1.3-3.26l0-.14c1-3.66,1.54-4.9,3.36-4.9H12c1.84,0,2.48,1.3,3.44,4.9l.1.34c.58,2.2,1,3.06,1.18,3.06a.58.58,0,0,0,.18,0,5.67,5.67,0,0,0,2.28-2.34A19.83,19.83,0,0,0,21,17.08c.28-.68.54-1.44.8-2.26a24.69,24.69,0,0,0,1-4.82A18,18,0,0,0,22.94,6.42ZM2.16,10a15.16,15.16,0,0,1-.1-3.54,10.56,10.56,0,0,1,.28-1.8C2.76,2.8,3.88,2,6.2,2h.06a16.65,16.65,0,0,1,3.08.34l1.34.26a7.32,7.32,0,0,0,1.38.14,5.64,5.64,0,0,0,1.2-.14l1.36-.26A17.76,17.76,0,0,1,17.78,2c2.34,0,3.46.8,3.9,2.66a11.64,11.64,0,0,1,.26,1.84,16,16,0,0,1-.1,3.5Z"/>
            <path
                d="M23.94,6.34a15.51,15.51,0,0,0-.32-2.12C23,1.42,21.08,0,17.8,0a18.28,18.28,0,0,0-3.54.38L12.88.64a6.62,6.62,0,0,1-1,.12,6.6,6.6,0,0,1-.86-.12L9.7.38A20.19,20.19,0,0,0,6.18,0C2.92,0,1,1.4.38,4.22A17.66,17.66,0,0,0,.06,6.28,19.42,19.42,0,0,0,.12,10h0a6,6,0,0,0,.08.84s0,.06,0,.08c.06.42.12.84.2,1.24,0,.2.08.4.12.58.08.34.16.68.24,1a29.78,29.78,0,0,0,1.14,3.58.29.29,0,0,0,0,.14,2.35,2.35,0,0,0,.1.22s0,.06,0,.08l.18.4a1.57,1.57,0,0,1,.08.18s.24.56.62,1.32c.08.14.18.3.26.46.2.34.4.7.62,1.06a8.38,8.38,0,0,0,.74,1,4.63,4.63,0,0,0,.4.48,3.77,3.77,0,0,0,1.76,1.26,1.82,1.82,0,0,0,.46.06,1,1,0,0,0,.24,0,.84.84,0,0,0,.2-.08,1,1,0,0,0,.54-.44,3,3,0,0,0,.34-.56c.08-.18.16-.4.26-.64.18-.48.36-1.08.56-1.84s.4-1.5.62-2.2c0-.1.06-.18.08-.26.58-1.82,1-2.18,1.62-2.24a1,1,0,0,1,.24,0H12a1,1,0,0,1,.24,0c.6,0,1,.38,1.56,1.88.24.68.46,1.48.68,2.26l.08.34.12.42c.08.26.14.52.22.74.12.42.24.76.34,1a5.5,5.5,0,0,0,.4.84c0,.08.1.14.14.22a.78.78,0,0,0,.52.44.32.32,0,0,0,.16.06.55.55,0,0,0,.26,0,.34.34,0,0,0,.14,0l.32-.06a.88.88,0,0,0,.32-.12,3,3,0,0,0,.34-.2,4.32,4.32,0,0,0,.62-.48,10.19,10.19,0,0,0,1.56-2,8,8,0,0,0,.54-.88c.62-1.06,1-1.94,1-1.94a.28.28,0,0,0,0-.12c.12-.24.24-.5.34-.76s.28-.72.42-1.12v0c.14-.38.28-.8.4-1.22.06-.18.1-.36.14-.52s.12-.4.16-.6a21.7,21.7,0,0,0,.76-4A17.4,17.4,0,0,0,23.94,6.34Zm-3.06,8.18c-.24.8-.5,1.54-.76,2.2a19.92,19.92,0,0,1-1.76,3.36,8.74,8.74,0,0,1-1.24,1.58c-.14-.38-.34-1-.6-2l-.1-.34c-.9-3.42-1.7-5.64-4.4-5.64h-.1c-2.66,0-3.42,2.14-4.38,5.8a19.79,19.79,0,0,1-.68,2.24A8.76,8.76,0,0,1,5.54,20.1a19.55,19.55,0,0,1-1.36-2.56c-.08-.18-.18-.44-.34-.8-.26-.66-.5-1.4-.74-2.2A23.56,23.56,0,0,1,2.16,10a15.16,15.16,0,0,1-.1-3.54,10.56,10.56,0,0,1,.28-1.8C2.76,2.8,3.88,2,6.2,2h.06a16.65,16.65,0,0,1,3.08.34l1.34.26a7.32,7.32,0,0,0,1.38.14,5.64,5.64,0,0,0,1.2-.14l1.36-.26A17.76,17.76,0,0,1,17.78,2c2.34,0,3.46.8,3.9,2.66a11.64,11.64,0,0,1,.26,1.84,16,16,0,0,1-.1,3.5A23.8,23.8,0,0,1,20.88,14.52Z"/>
            <path
                d="M23.86,10a26.87,26.87,0,0,1-.76,4c0,.2-.1.4-.16.6q-.24.87-.54,1.74v0c-.24.64-.48,1.26-.76,1.88a.28.28,0,0,1,0,.12s-.4.88-1,1.94a8,8,0,0,1-.54.88,10.19,10.19,0,0,1-1.56,2,4.32,4.32,0,0,1-.62.48,3,3,0,0,1-.34.2.88.88,0,0,1-.32.12l-.32.06a.34.34,0,0,1-.14,0,.55.55,0,0,1-.26,0,.32.32,0,0,1-.16-.06,1.49,1.49,0,0,1-.52-.44c0-.08-.1-.14-.14-.22a5.5,5.5,0,0,1-.4-.84c-.1-.28-.22-.62-.34-1-.08-.22-.14-.48-.22-.74s-.12-.5-.2-.76c-.22-.78-.44-1.58-.68-2.26-.4-1-.88-1.74-1.56-1.88a1,1,0,0,0-.24,0h-.1a1,1,0,0,0-.24,0c-.76.16-1.24,1.08-1.62,2.24a1.86,1.86,0,0,0-.08.26c-.22.7-.42,1.46-.62,2.2S9,21.78,8.8,22.26c-.1.24-.18.46-.26.64a3,3,0,0,1-.34.56,1.39,1.39,0,0,1-.54.44.84.84,0,0,1-.2.08,1,1,0,0,1-.24,0,1.82,1.82,0,0,1-.46-.06A3.77,3.77,0,0,1,5,22.68a4.63,4.63,0,0,1-.4-.48c-.26-.32-.5-.68-.74-1s-.42-.72-.62-1.06c-.08-.16-.18-.32-.26-.46-.38-.76-.62-1.32-.62-1.32a1.57,1.57,0,0,0-.08-.18l-.18-.4s0,0,0-.08a3.42,3.42,0,0,1-.14-.36A29.78,29.78,0,0,1,.8,13.74c-.08-.32-.16-.66-.24-1,0-.18-.08-.38-.12-.58-.08-.4-.14-.82-.2-1.24,0,0,0,0,0-.08,0-.28-.06-.56-.1-.84Z"/>
        </WrapIcon>
    );
});
