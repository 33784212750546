import React, {forwardRef} from 'react';
import {WrapIcon} from "../../containers/WrapIcon";
import {IIcon} from "../../types/common";

export const LowerJawFrontIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 10.56"} ref={ref}>
            <path d="M10.88,0a.77.77,0,0,1,.71.82,1.1,1.1,0,0,1,0,.18L11,3.77a1.18,1.18,0,0,1-1.11,1,1.19,1.19,0,0,1-1.11-1L8.29,1a1.09,1.09,0,0,1,0-.18A.77.77,0,0,1,9,0Zm11,0a.45.45,0,0,1,.41.48V2.4A1.35,1.35,0,0,1,21.1,3.84,1.36,1.36,0,0,1,19.86,2.4V.48A.46.46,0,0,1,20.28,0ZM7,0a.46.46,0,0,1,.42.48V2.4A1.36,1.36,0,0,1,6.21,3.84,1.35,1.35,0,0,1,5,2.4V.48A.45.45,0,0,1,5.38,0Zm8,0a.77.77,0,0,1,.7.82,1.09,1.09,0,0,1,0,.18l-.53,2.77a1.11,1.11,0,0,1-2.22,0L12.43,1a1.1,1.1,0,0,1,0-.18A.77.77,0,0,1,13.12,0ZM3.72,0a.46.46,0,0,1,.42.48V2.4A1.36,1.36,0,0,1,2.9,3.84,1.35,1.35,0,0,1,1.66,2.4V.48A.45.45,0,0,1,2.07,0Zm14.9,0A.45.45,0,0,1,19,.48V2.4a1.35,1.35,0,0,1-1.24,1.44A1.36,1.36,0,0,1,16.55,2.4V.48A.46.46,0,0,1,17,0ZM24,6.65a.92.92,0,0,1,0,.29,1,1,0,0,1-.7,1L23.18,8S19.23,10.56,12,10.56.86,8,.82,8L.7,7.93a1,1,0,0,1-.7-1,.92.92,0,0,1,0-.29L1,3.2a2.12,2.12,0,0,0,2,1.6,2,2,0,0,0,1.65-1,2,2,0,0,0,1.66,1A2,2,0,0,0,8,3.67L8,4A2,2,0,0,0,9.93,5.76,2,2,0,0,0,11.85,4L12,3.17l.15.81a2,2,0,0,0,1.92,1.78A2,2,0,0,0,16,4L16,3.67A2,2,0,0,0,17.79,4.8a2,2,0,0,0,1.66-1,2,2,0,0,0,1.65,1,2.12,2.12,0,0,0,1.95-1.6L24,6.65Z"/>
        </WrapIcon>
    );
});
