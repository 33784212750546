let StringHelper = {

    /**
     * Вставляет подстроку в строку в указанную позицию
     * @param {string} substr
     * @param {string} target
     * @param {number} pos
     * @return {string}
     */
    insert: function(substr, target, pos)
    {
        return target.substr(0, pos) + substr + target.substr(pos);
    },

    ucFirst: function (value) {
        if (value.length) value = value[0].toUpperCase() + value.slice(1);
        return value;
    },

    /***
     * Форматирует число
     * @param {number} number - число
     * @param {number} decimals - количество знаков после запятой
     * @param {string} dec_point - десятичный разделитель
     * @param {string} thousands_sep - разделитель тысяч
     * @return {string}
     */
    numberFormat: (number, decimals = 2, dec_point = '.', thousands_sep = ' ') =>
    {
        // Strip all characters but numerical ones.
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }
};

export default StringHelper
