import React, {useContext} from 'react';
import HeaderPreparation from "./components/HeaderPreparation";
import {TwoBoxes, BoxColumn} from '../../../../components-ui/styledComponent/common/wraps';
import Documents from "./components/Documents";
import PaymentSchedule from "./components/PaymentSchedule";
import ButtonsPreparation from "./components/ButtonsPreparation";
import PatientData from "./containers/PatientData/PatientData";
import {PropsPreparationForAdmission, PropsPreparationForAdmissionLoaded, ContextPreparationForAdmission} from './types/common';
import useInitial from "./hooks/useInitial";
import useForms from "./hooks/useForms";
import useLoadingPatient from "./hooks/useLoadingPatient";
import {useFields} from "../../../../containers/PatientField";
import useUpdatePreparation from "./hooks/useUpdatePreparation";

const PreparationForAdmissionContext = React.createContext({} as ContextPreparationForAdmission);
export const usePreparationForAdmission = () => useContext(PreparationForAdmissionContext)

const PreparationForAdmissionLoaded = (props: PropsPreparationForAdmissionLoaded) => {
    const fields = useFields({})
    const {
        forms,
        state,
        onChangeValidation,
        onChangeField,
        onChangeCheckbox,
        onChangeTab,
        resetRepresentative,
        setForms,
        setState,
        initialState
    } = useForms({data: props.data, fields})
    const {
        loadingPatient,
        loaderPatient
    } = useLoadingPatient({setForms, fields, data: props.data})
    useUpdatePreparation({forms, props, initialForms: initialState.forms})


    return (
        <PreparationForAdmissionContext.Provider value={{
            props,
            forms,
            state,
            onChangeValidation,
            onChangeField,
            onChangeCheckbox,
            onChangeTab,
            resetRepresentative,
            setForms,
            loadingPatient,
            loaderPatient,
            fields,
            setState
        }}>
            <HeaderPreparation/>
            <TwoBoxes margin="45px 0 0">
                <BoxColumn>
                    <Documents/>
                    <PaymentSchedule/>
                    <ButtonsPreparation/>
                </BoxColumn>
                <PatientData/>
            </TwoBoxes>
        </PreparationForAdmissionContext.Provider>
    )
}

export default function PreparationForAdmission(props: PropsPreparationForAdmission) {
    const {data, reloadData} = useInitial({visitId: props.visitId})
    if (!!data) {
        return <PreparationForAdmissionLoaded data={data} reloadData={reloadData} {...props} />
    } else {
        return null
    }
}
