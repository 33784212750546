import Helper from "../../../common/helpers/main";

export const over14YearsOld = (personalBirthday: string) => {
        let res = true;
        if (!!personalBirthday && personalBirthday.length === 10 && personalBirthday.indexOf('_') === -1) {
            let correctDate = personalBirthday.split('-');
            if (correctDate[0].length === 2) {
                correctDate = correctDate.reverse()
            }
            res = +Helper.Date.getAgeNumber(correctDate.join('-')) > 14;
        }
        return res;
}
