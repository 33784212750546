import {useMemo} from "react";
import {usePatientField} from "../containers/PatientField";
import {IPatientField} from "../types/fields";

export default function useWidthInput(field: IPatientField) {
    const {parentName, currentPatient} = usePatientField()
    return useMemo(() => {
        const defaultWidth = () => {
            if (!!currentPatient) {
                return '326px'
            } else {
                return parentName === "WAP" ? "458px" : "335px"
            }
        }
        switch (field.type) {
            case "phone":
                return '180px'
            case "enum":
                if (field.name === 'phoneOwner') {
                    return parentName === "WAP" ? "335px" : defaultWidth()
                } else {
                    return undefined
                }
            case "date":
                return parentName === "WAP" ? "335px" : defaultWidth()
            default:
               return defaultWidth()
        }
    }, [currentPatient])
}
