import {Dispatch, SetStateAction, useEffect} from "react";
import {PropsError} from "../../../components/Errors";
import _ from "lodash";
import {StateAllDateRange, StateAnalytics} from "../types";

interface Props {
    setAllDateRange: Dispatch<SetStateAction<StateAllDateRange>>
    setState: Dispatch<SetStateAction<StateAnalytics>>
    setLoaderPage: Dispatch<SetStateAction<boolean>>
}

const useError = ({setState, setAllDateRange, setLoaderPage}: Props) => {
    useEffect(() => {
        const handlerLoader = (event: any) => {
            const reason = event.reason as PropsError;
            if (reason.type === 'server_request_error') {
                setState(prevState => {
                    _.forEach(prevState, (widget, widgetCode) => {
                        prevState[widgetCode as keyof StateAnalytics].loader = false;
                    })
                    return {...prevState}
                })
                setAllDateRange(prevState => ({...prevState, loader: false}))
                setLoaderPage(false)
            }
        }
        window.addEventListener('unhandledrejection', handlerLoader)
        return () => window.removeEventListener('unhandledrejection', handlerLoader)
    }, [])
}

export default useError
