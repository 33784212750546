import {Dispatch, SetStateAction, useCallback} from "react";
import {IDirectionField} from "../types";
import {IStatePaymentCreationForm} from "../../../types";

interface Props {
    field: IDirectionField
    setState: Dispatch<SetStateAction<IStatePaymentCreationForm>>
}

const useChangeField = ({field, setState}: Props) => {
    return useCallback((value: string) => {
        setState(prevState => ({
            ...prevState,
            values: {
                ...prevState.values,
                [field.code]: value
            }
        }))
    }, [field, setState])
};

export default useChangeField;