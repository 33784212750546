import React, {useMemo} from 'react';
import TextField from '../../../../../../../components-ui/Inputs/TextField/TextField';
import CalendarField from '../../../../../../../components-ui/Inputs/CalendarField/CalendarField';
import Tabs from '../../../../../../../components-ui/Navigation/Tabs/Tabs';
import CardForm from '../../../../../../../components-ui/DataDisplay/CardForm';
import {ResponseUserDetail, setFnType} from '../types';
import Box from '../../../../../../../components-ui/Layout/Box';
import Button from '../../../../../../../components-ui/Inputs/Button/Button';
import Icon from '../../../../../../../components-ui/DataDisplay/Icon';

interface Props {
    state: ResponseUserDetail;
    setDetailFn: (props: setFnType) => void;
    validationState: boolean;
    birthdayValid: boolean;
}

const PersonalInfo = ({state, setDetailFn, validationState, birthdayValid}: Props) => {
    return useMemo(() =>
            <CardForm style={{margin: '0 0 16px 0'}} title='Персональная информация' forms={[
                {
                    isRequired: true,
                    label: 'Фамилия',
                    input: <TextField widthInput='100%'
                                      isError={validationState && !state.detail.lastName}
                                      onChange={(value) => setDetailFn({key: 'lastName', value})}
                                      value={state.detail.lastName ?? ''}
                    />,
                },
                {
                    isRequired: true,
                    label: 'Имя',
                    input: <TextField widthInput='100%'
                                      isError={validationState && !state.detail.name}
                                      onChange={(value) => setDetailFn({key: 'name', value})}
                                      value={state.detail.name ?? ''}
                    />,
                },
                {
                    label: 'Отчество',
                    input: <TextField widthInput='100%'
                                      onChange={(value) => setDetailFn({key: 'secondName', value})}
                                      value={state.detail.secondName ?? ''}
                    />,
                },
                {
                    label: 'Пол',
                    input: <Tabs variant='primary'
                                 widthTab='36px'
                                 size='lg'
                                 tabs={[
                                     {code: 'MAN', name: 'М'},
                                     {code: 'WOMAN', name: 'Ж'},
                                     {code: 'unknown', name: '?'},
                                 ]}
                                 onChange={(value) => setDetailFn({key: 'gender', value})}
                                 activeTab={state.detail.gender ?? 'unknown'}
                    />,
                },
                {
                    label: 'Дата рождения',
                    input: <Box display='flex' gap='5px' alignItems='center'>
                        <CalendarField
                            validateField={validationState && !birthdayValid ? {
                                day: true,
                                month: true,
                                year: true,
                            } : undefined}
                            menuPlacement='top'
                            value={state.detail.birthday ?? ''}
                            onChange={(value) => setDetailFn({key: 'birthday', value})}
                        />
                        <Button isIcon onClick={() => {
                            setDetailFn({key: 'birthday', value: null});
                        }}>
                            <Icon name='close' color='error' />
                        </Button>
                    </Box>,
                },
                {
                    label: 'Комментарий',
                    input: <TextField isTextArea
                                      widthInput='100%'
                                      value={state.detail.comments ?? ''}
                                      onChange={(value: string) => setDetailFn({key: 'comments', value})} />,
                },
            ]} />
        , [state.detail.lastName,
            state.detail.name,
            state.detail.secondName,
            state.detail.gender,
            state.detail.birthday,
            state.detail.comments, validationState,
            birthdayValid]);
};

export default PersonalInfo;
