import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {ValueProvider} from "../../containers/InitialSurvey/InitialSurveyContext";
import DentalCard from "./elements/DentalCard/DentalCard";
import {
    DentalCardTooth,
    GeneratedTeethMapConfig,
    ItemChangeConfig,
    PDentalRecords,
    SConfig,
    SDentalCard
} from "./types";
import Paper from "../../containers/UI-Collection/Elements/Surfaces/Paper";
import Info from "./elements/Info/Info";
import {DentalRecordsWrap} from "./styledComponents";
import useCtrlDown from "../../common/hooks/useCtrlDown";
import ContextMenu from "./elements/ContextMenu/ContextMenu";
import {saveTeethMapConfig} from "./helper/saveTeethMapConfig";
import {handleToothButton} from "./helper/handleToothButton";
import {handleButtonContextMenu} from "./helper/handleButtonContextMenu";
import {componentDidMount} from "./helper/componentDidMount";
import Helper from "../../common/helpers/main";
import _ from 'underscore';
import {getJawButtonStatus} from "./helper/getJawButtonStatus";
import {selectedJaw} from "./helper/selectedJaw";
import {childrenNotTeeth} from "./helper/childrenNotTeeth";
import {TeethMapConfig} from "../../common/types/teethmap";
import {INITIAL_SURVEY} from "../../common/constants/DentalRecords";
import ChangeConfig from "./elements/ChangeConfig/ChangeConfig";
import {changeAttributesConfig, changeGumDiseaseConfig} from "./helper/changeItemConfig";
import {useErrorModal} from "../Errors";


const DentalCardContext = React.createContext({} as SDentalCard | any);
const InfoContext = React.createContext<ValueProvider | any>({});
export const useDentalCard = () => useContext(DentalCardContext);
export const useInfo = () => useContext(InfoContext);

export const DentalRecords = (
    {
        teethMap, parentComponentName, onChangeTeethMap, visitId, cardNumber, renderRefDentalRecords, onChangeActiveTeeth,
        processedTeeth, editField
    }: PDentalRecords) => {


    const [config, setConfig] = useState<SConfig>({});
    const [snapshotConfig, setSnapshotConfig] = useState<TeethMapConfig>({})
    const [ctrlPressed, setCtrlPressed] = useState(false);
    const [openContextMenu, setOpenContextMenu] = useState(false);
    const [positionContextMenu, setPositionContextMenu] = useState({x: 0, y: 0});
    const {ErrorModal, handleError} = useErrorModal()
    const [dataLoaded, setDataLoaded] = useState(false);
    const [changeConfig, setChangeConfig] = useState<ItemChangeConfig[]>([])
    const refWrapDentalCard = useRef(null) as any;

    const {statusList, statusIndependentAttributes, rootsMap, canalsMap} = teethMap;

    useCtrlDown((value: boolean) => setCtrlPressed(value));

    useEffect(() => componentDidMount(teethMap, setConfig, setSnapshotConfig), []);

    useEffect(() => {
        if (!!onChangeActiveTeeth) {
            if (Object.values(config).length) {
                const allConfig = config.upper.concat(config.lower);
                const newActiveTeeth = allConfig.filter(tooth => tooth.selected).map(tooth => tooth.number)
                onChangeActiveTeeth(newActiveTeeth);
            }
        }
    }, [config]);

    useEffect(() => {
        if (editField && openContextMenu) {
            setOpenContextMenu(false);
        }
    }, [editField])

    useEffect(() => {
        if (!!teethMap.isDentalAnalysisLoaded) setDataLoaded(true);
    }, [])

    const keysJaws = useMemo(() => Object.keys(teethMap.config), []);

    const newServicesNames = useMemo(() => {
        let res = {} as {[key: string] : string[]}
        _.each(teethMap.servicesNames, (text, serviceId) => {
            const textSplit = text.split(' ');
            let newContent = '';
            let newText = [] as string[];
            textSplit.forEach((txt, index) => {
                newContent += txt + ' ';
                if (newContent.length >= 40 || index === textSplit.length - 1) {
                    newText.push(newContent)
                    newContent = ''
                }
            })
            res[serviceId] = newText;
        })
        return res
    }, [])

    const saveTeethMap = () => saveTeethMapConfig(teethMap, config, onChangeTeethMap)

    const currentJaw = (jaw: string) => config[jaw] as DentalCardTooth[];

    const onClickToothButton = (jaw: string, toothItem: DentalCardTooth, indexTooth: number) => {
        handleToothButton({jaw, toothItem, indexTooth, ctrlPressed, setConfig});
        if (openContextMenu) setOpenContextMenu(false);
    };

    const onContextMenuToothButton = (position: { x: number, y: number }) => {
        if (parentComponentName === INITIAL_SURVEY && !editField) {
            setOpenContextMenu(true);
            setPositionContextMenu(position);
        }
    };

    const closeContextMenu = () => setOpenContextMenu(false);

    const onClickButtonContextMenu = (statusId: number) => handleButtonContextMenu({
        statusId,
        setConfig,
        saveTeethMap,
        teethMap
    });

    const onClickButtonJaw = (jaw: string) => {
        setConfig(prevState => {
            const selected = selectedJaw(config, jaw);
            const reverseJaw = jaw === 'upper' ? 'lower' : 'upper';
            if (!ctrlPressed) prevState[reverseJaw].forEach(tooth => tooth.selected = false);
            prevState[jaw].forEach(tooth => {
                if (childrenNotTeeth.indexOf(tooth.number) === -1) {
                    tooth.selected = !selected;
                } else {
                    tooth.selected = false;
                }
            })
            return {
                ...prevState
            }
        });
        if (openContextMenu) setOpenContextMenu(false);
    };

    const onClickButtonJawChild = (jaw: string) => {
        if (!editField) {
            setConfig(prevState => {
                const switchToChild = getJawButtonStatus(config[jaw])
                prevState[jaw].forEach(tooth => {
                    const numberDiff = switchToChild ? 40 : -40;
                    const toothStatus = statusList[tooth.status];
                    if (switchToChild !== Helper.TeethMap.isChildTooth(tooth.number)) tooth.number += numberDiff;
                    if (switchToChild) {
                        delete tooth.canals;
                        delete tooth.roots;
                    } else {
                        if (toothStatus.withRootsAndCanals) {
                            if (!tooth.canals) tooth.canals = canalsMap[tooth.number]
                            if (!tooth.roots) tooth.roots = rootsMap[tooth.number]
                        }
                    }
                    tooth.selected = false;
                });
                return {
                    ...prevState
                }
            });
            if (openContextMenu) setOpenContextMenu(false);
            saveTeethMap();
        }
    };

    const onClickButtonToothChild = (jaw: string, toothItem: DentalCardTooth, indexTooth: number) => {
        if (!editField) {
            setConfig(prevState => {
                const numberDiff = Helper.TeethMap.isChildTooth(toothItem.number) ? -40 : 40;
                prevState[jaw][indexTooth].number += numberDiff;
                prevState[jaw][indexTooth].selected = false;
                const toothStatus = statusList[prevState[jaw][indexTooth].status];
                if (numberDiff === 40) {
                    delete prevState[jaw][indexTooth].canals;
                    delete prevState[jaw][indexTooth].roots;
                } else {
                    if (toothStatus.withRootsAndCanals) {
                        if (!prevState[jaw][indexTooth].canals) {
                            prevState[jaw][indexTooth].canals = canalsMap[prevState[jaw][indexTooth].number]
                        }
                        if (!prevState[jaw][indexTooth].roots) {
                            prevState[jaw][indexTooth].roots = rootsMap[prevState[jaw][indexTooth].number]
                        }
                    }
                }
                return {
                    ...prevState
                }
            });
            if (openContextMenu) setOpenContextMenu(false);
            saveTeethMap()
        }
    };

    const onChangeConfig = (generatedTeethMapConfig: GeneratedTeethMapConfig) => {

        setConfig(prevState => {
            _.each(prevState, (jaw, jawName) => {
                jaw.forEach((tooth, index) => {
                    tooth.selected = false;
                    if (Helper.TeethMap.isChildTooth(tooth.number)) tooth.number -= 40;
                    const snapshotTooth = snapshotConfig[jawName][index];
                    const generatedTooth = generatedTeethMapConfig[tooth.number];
                    const currentStatus = statusList[tooth.status];
                    const generatedStatus = statusList[generatedTooth.status];
                    tooth.autoStatus = generatedTooth.status;

                    _.extend(tooth, _.pick(generatedTooth, 'canals', 'roots', 'attributes', 'gumDisease'));

                    if (snapshotTooth.status === tooth.status) {
                        if (tooth.status !== generatedTooth.status) {
                            tooth.status = generatedTooth.status;
                            tooth.unconfirmed = true;
                        }
                    } else {
                        if (generatedStatus.group !== currentStatus.group) {
                            if (!!tooth.attributes) {
                                tooth.attributes = tooth.attributes.filter(attribute => statusIndependentAttributes.indexOf(attribute) !== -1)
                            }
                        }
                    }

                    if (generatedStatus.withRootsAndCanals) {
                        const isChildTooth = Helper.TeethMap.isChildTooth(tooth.number);
                        if (!isChildTooth) {
                            if (!tooth.roots) tooth.roots = rootsMap[tooth.number];
                            if (!tooth.canals) tooth.canals = canalsMap[tooth.number];
                        }
                    } else {
                        if (!!tooth.roots) delete tooth.roots;
                        if (!!tooth.canals) delete tooth.canals;
                    }

                })
            })
            return {
                ...prevState
            }
        });
        saveTeethMap();
    }

    const onNewLoadDiacnocat = (generatedTeethMapConfig: GeneratedTeethMapConfig) => {
        const newConfig = Helper.clone(config) as SConfig;
        const newChangeConfig = [] as ItemChangeConfig[];
        _.each(newConfig, (jaw, jawName) => {
            jaw.forEach(item => {
                const changeItem = generatedTeethMapConfig[item.number] as ItemChangeConfig;
                if (item.status !== changeItem.status) {
                    newChangeConfig.push({
                        number: item.number,
                        ..._.pick(changeItem, 'status', 'attributes', 'gumDisease')
                    })
                } else {
                    changeAttributesConfig(item, changeItem, statusIndependentAttributes)
                    changeGumDiseaseConfig(item, changeItem)
                }
            })
        })
        if (!!newChangeConfig.length) {
            setChangeConfig(newChangeConfig);
            setConfig(newConfig);
        } else {
            onChangeConfig(generatedTeethMapConfig)
        }
    };

    const onLoadDiacnocat = (generatedTeethMapConfig: GeneratedTeethMapConfig) => {
        if (dataLoaded) {
            onNewLoadDiacnocat(generatedTeethMapConfig)
            return false;
        }
        onChangeConfig(generatedTeethMapConfig)
    };

    const onClickConfirmButton = () => {
        setConfig(prevState => {
            _.each(prevState, (jaw) => {
                jaw.forEach(tooth => {
                    if (tooth.selected) tooth.unconfirmed = false;
                })
            });
            return {
                ...prevState
            }
        });
        saveTeethMap();
    };

    const onClickConfirmAllButton = () => {
        setConfig(prevState => {
            _.each(prevState, (jaw) => {
                jaw.forEach(tooth => tooth.unconfirmed = false)
            });
            return {
                ...prevState
            }
        });
        saveTeethMap();
    };

    const canceledButton = () => {
        setConfig(prevState => {
            _.each(prevState, (jaw, jawCode) => {
                jaw.forEach(tooth => tooth.selected = false)
            })
            return {
                ...prevState
            }
        });
    }

    if (Object.keys(config).length === 0) return null;


    return (
        <DentalRecordsWrap ref={(ref: any) => {
            if (!!renderRefDentalRecords) {
                renderRefDentalRecords(ref)
            }
        }}>
            <DentalCardContext.Provider value={{
                teethMap,
                keysJaws,
                currentJaw,
                parentComponentName,
                onClickToothButton,
                openContextMenu,
                positionContextMenu,
                onContextMenuToothButton,
                closeContextMenu,
                refWrapDentalCard,
                onClickButtonContextMenu,
                onClickButtonJaw,
                visitId,
                config,
                onClickButtonJawChild,
                onClickButtonToothChild,
                onLoadDiacnocat,
                onClickConfirmButton,
                onClickConfirmAllButton,
                handleError,
                cardNumber,
                renderRefDentalRecords,
                canceledButton,
                processedTeeth,
                editField,
                dataLoaded,
                setDataLoaded,
                changeConfig,
                setChangeConfig,
                setConfig,
                saveTeethMap
            }}>
                <div style={{position: 'relative'}} ref={refWrapDentalCard}>
                    <Paper padding="32px 30px 36px" maxWidth="774px" margin="0" isHidden={false}>
                        <DentalCard/>
                    </Paper>
                    <ContextMenu/>
                    <ChangeConfig/>
                </div>
            </DentalCardContext.Provider>
            <InfoContext.Provider value={{
                teethMap,
                config,
                newServicesNames
            }}>
                <Info/>
            </InfoContext.Provider>
            {ErrorModal}
        </DentalRecordsWrap>

    )
}
