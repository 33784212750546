import styled from 'styled-components';
import Helper from '../../common/helpers/main';
import {IColors, ITheme} from "../../common/Theme/Theme";

const rgbaColorBoxShadow = (color: any) => `rgba(${Helper.Color.hexToRgb(color)}, 0.25)`;

type color = keyof IColors;

export interface IInput {
    theme?: ITheme,
    placeholder?: string,
    required?: boolean,
    type?: string,
    onChange?: any,
    value?: string,
    width?: number | string,
    name?: string,
    onFocus?: any,
    as?: any,
    textAlignInput?: 'left' | 'center' | 'right',
    size?: 'sm' | 'md' | 'min',
    isSearch?: boolean,
    mask?: string,
    isError?: boolean,
    onBlur?: any,
    disabled?: boolean,
    isLabel?: boolean,
    onKeyDown?: any,
    isTextArea?: boolean,
    minHeight?: string,
    backgroundColor?: color,
    color?: color
    isPartHidden?: boolean
    isAutoComplete?: boolean
}

const borderColor = (props: IInput) => {
    const {isError, theme} = props;
    if (isError) {
        return theme?.colors.danger;
    } else {
        return theme?.colors.borderGray;
    }
}

const borderColorHover = (props: IInput) => {
    const {isError, theme} = props;
    if (isError) {
        return theme?.colors.dangerActive;
    } else {
        return theme?.colors.borderGrayActive;
    }
}

const borderColorFocus = (props: IInput) => {
    const {isError, theme} = props;
    if (isError) {
        return theme?.colors.danger;
    } else {
        return theme?.colors.info;
    }
};

const borderColorFocusHover = (props: IInput) => {
    const {isError, theme} = props;
    if (isError) {
        return theme?.colors.dangerActive;
    } else {
        return theme?.colors.info;
    }
};

const boxShadow = (props: IInput) => {
    const {isError, theme} = props;
    if (isError) {
        return `0 0 0 0.2rem ${rgbaColorBoxShadow(theme?.colors.danger)}`;
    } else {
        return 'none';
    }
};

const boxShadowFocus = (props: IInput) => {
    const {isError, theme} = props;
    if (isError) {
        return rgbaColorBoxShadow(theme?.colors.danger);
    } else {
        return rgbaColorBoxShadow(theme?.colors.info);
    }
};

const padding = (props: IInput) => {
    switch (props.size) {
        case 'sm':
            if (props.isSearch) {
                return '4px 12px 6px 36px';
            } else if (props.isLabel) {
                return '4px 12px 6px 56px';
                }
            else if (props.isAutoComplete) {
                        return '4px 24px 6px 12px';
            } else {
                return '4px 12px 6px';
            }
        case "min":
            if (props.isSearch) {
                return '0px 12px 0px 36px';
            } else if (props.isLabel) {
                return '0px 12px 0px 56px';
            } else {
                return '0px 12px 0px';
            }
        case 'md':
        default:
            if (props.isSearch) {
                return '5px 16px 9px 40px';
            } else if (props.isLabel) {
                return '5px 16px 9px 60px';
            } else {
                return '5px 16px 9px';
            }
    }
};

const width = (props: IInput) => {
    if (!!props.width) {
        if (typeof props.width === 'string') {
            return props.width;
        } else {
            return props.width + 'px';
        }
    } else {
        return 'auto';
    }
};

export const StyledInput = styled.input<IInput>`
  border-radius: 3px;
  border: solid 1px ${borderColor};
  background-color: ${props => !!props.backgroundColor ?
          props.theme.colors[props.backgroundColor] :
          props.disabled ? props.theme.colors.secondary : props.theme.colors.white
  };
  font-family: 'Open Sans', sans-serif;
  font-size: ${props => props.size === 'sm' ? '14px' : '16px'};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => !!props.color ?
          props.theme.colors[props.color]
          :
          props.disabled ? props.theme.colors.textGray : props.theme.colors.text
  };
  padding: ${padding};
  outline: none;
  width: ${width};
  resize: none;
  text-align: ${props => props.textAlignInput};
  min-height: ${props => !!props.minHeight ? props.minHeight : 'auto'};
  box-shadow: ${boxShadow};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  &:hover {
    border-color: ${borderColorHover};
  }

  &:focus {
    border-color: ${borderColorFocus};
    box-shadow: 0 0 0 0.2rem ${boxShadowFocus};

    &:hover {
      border-color: ${borderColorFocusHover};
    }
  }

  &::placeholder {
    color: ${props => props.theme.colors.textGray};
    font-style: italic;
  }

  ${props => !!props.isTextArea && `
         overflow: hidden;
    `}
  ${props => !!props.isPartHidden && `
        white-space: nowrap;
        overflow: hidden; 
        text-overflow: ellipsis;
  `};
`;
