import React from "react";
import FieldsWrap from "./FieldsWrap";
import {Alert} from "../../../components-ui/DataDisplay/Alert";
import Templates from "../../Templates/Templates";
import _ from "underscore";


export default function ContentCaseHistory({fieldsProps, templatesProps}: any) {
    const {visitTargetSelected, planTargetSelected} = fieldsProps;
    const {editMode, templatesList, templatesGroupsList, fieldName, fieldsParams, addFromTemplate} = templatesProps;
    const {relatedTemplates, targetSelected, selectedDiagnosis} = fieldsParams();
    let templatesRelated = relatedTemplates[targetSelected[0]];

    if (targetSelected.length > 1) {
        let diagnosis = selectedDiagnosis[targetSelected[0]];
        if (diagnosis !== undefined) {
            targetSelected.forEach((target: any) => {
                if (templatesRelated !== undefined) {
                    if (!_.isEqual(selectedDiagnosis[target], diagnosis)) {
                        templatesRelated = undefined;
                    }
                }

            });
        } else {
            templatesRelated = undefined;
        }
    }

    let content = null;
    if (visitTargetSelected.length !== 0 || planTargetSelected.length !== 0) {
        content = (
            <>
                <FieldsWrap {...fieldsProps}/>
                <Templates elementsList={templatesList}
                           groups={templatesGroupsList}
                           addFromTemplate={addFromTemplate}
                           selectedField={fieldName}
                           relatedTemplates={templatesRelated}
                           visible={!editMode}
                />
            </>
        )
    } else {
        content = <Alert iconName="warningOutline" children="Выберите зубы" margin="auto"/>
    }
    return (
        <div className="card__wrap">
            {content}
        </div>
    )
}
