import React, {ReactChild, ReactNode} from 'react';
import SVG from "../../../../../common/helpers/SVG";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import { SContainerHeader, SWrapTitle } from '../style';

interface Props {
    noticeName?: string
    noticeQuantityTotal: number
    headerRightComponent?: ReactChild | ReactNode
}

const Header = ({noticeName = "Звонки", noticeQuantityTotal, headerRightComponent}: Props) => {
    return (
        <SContainerHeader>
            <SWrapTitle>
                <SVG name="call-center/notice"/>
                <Typography fontWeight="bold"
                            lineHeight="1.25"
                            fontSize="15px"
                            as="span"
                            children={`${noticeName} (${noticeQuantityTotal})`} />
            </SWrapTitle>
            {!!headerRightComponent && headerRightComponent}
        </SContainerHeader>
    );
};

export default Header;
