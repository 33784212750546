import React, {useMemo} from 'react';
import {EmployeeRates, GetActualRatesResult} from '../../../types';
import {useSelector} from 'react-redux';
import {IColumn} from '../../../../../components-ui/DataDisplay/Table';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import _ from 'lodash';
import DateHelper from '../../../../../common/helpers/date-helper';
import Box from '../../../../../components-ui/Layout/Box';
import Icon from '../../../../../components-ui/DataDisplay/Icon';
import ButtonForOptions from '../../../../../components-ui/Inputs/ButtonForOptions/ButtonForOptions';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import XrayRateMark from '../../modals/XrayRateMark';
import {PropsTotal} from '../../../../../components-ui/DataDisplay/Table/types/components';
import Tooltip from '../../../../../components-ui/DataDisplay/Tooltip/Tooltip';
import {User} from '../../../../../types';
import PriceHelper from '../../../../../common/helpers/price-helper';

interface Props {
    xrayRateResponse: GetActualRatesResult;
    selectedBranch: number;
    refreshDataFromServer: () => void;
}

const useTableFormatter = ({xrayRateResponse, selectedBranch, refreshDataFromServer}: Props) => {

    const users = useSelector(state => state.common.users);

    const {showModal} = useDialogsManager();

    const xrayPictureTypes = useSelector(state => state.common.xrayPictureTypes);

    const xrayRateRows = useMemo(() =>
        _.chain(users)
            .filter((user) => user.role === 'assistant' && user.branches.includes(selectedBranch))
            .sortBy('fio')
            .value()
        , [xrayRateResponse])
    const xrayRateColumns = useMemo<IColumn<User>[]>(() => {
        const columns: IColumn<User>[] = [
            {
                field: 'employee',
                headerName: 'Сотрудник',
                valueFormatter: ({row}) =>
                    <Typography variant='small'>
                        {users[+row.id].fio}
                    </Typography>,
            },
        ];
        _.forEach(xrayPictureTypes, (name, type) => {
            columns.push({
                field: type,
                headerName: name,
                width: '300px',
                valueFormatter: ({row}) => {
                    const standardRates = xrayRateResponse.rates.standard?.[type];
                    let employeeRates = xrayRateResponse.rates[+row.id]?.[type] as EmployeeRates | undefined;

                    if (!employeeRates) {
                        employeeRates = standardRates;
                    }

                    const standardActualRate = standardRates?.actual?.sum ?? 0;
                    const employeeActualRate = employeeRates?.actual?.sum ?? standardActualRate;

                    return <Box display='flex' alignItems='center' gap='5px' position='relative'>
                        <Box position='absolute' positionOffsets={{left: '-35px'}}>
                            <ButtonForOptions
                                options={[{
                                    value: 'change',
                                    label: 'Изменить',
                                    onClick: () => showModal(
                                        <XrayRateMark fio={users[+row.id].fio}
                                                      employeeId={+row.id}
                                                      rate={employeeActualRate}
                                                      pictureName={name}
                                                      pictureType={type}
                                                      rateActiveFrom={employeeRates?.actual?.activeFrom}
                                                      selectedBranch={selectedBranch}
                                                      refreshDataFromServer={refreshDataFromServer}
                                        />,
                                    ),
                                }]}
                                position='left-bottom'
                            />
                        </Box>
                        {(employeeActualRate !== standardActualRate) ?
                            employeeActualRate > standardActualRate ?
                                <>
                                    <Icon width='14px'
                                          height='14px'
                                          name='longArrowThin'
                                          color='inc'
                                          data-for={String(row.id)}
                                          data-tip={'Ставка больше стандартной'}
                                    />
                                    <Tooltip id={String(row.id)} effect='solid' />
                                </>
                                :
                                <>
                                    <Icon width='14px'
                                          height='14px'
                                          name='longArrowThin'
                                          color='dec'
                                          transform='scale(-1)'
                                          data-for={String(row.id)}
                                          data-tip={'Ставка меньше стандартной'}
                                    />
                                    <Tooltip id={String(row.id)} effect='solid' />
                                </>
                            : ''
                        }
                        {PriceHelper.format(employeeActualRate)}
                        {employeeRates?.future &&
                            <Typography variant='min' fontStyle='italic'>
                                {
                                    `(${employeeRates.future?.sum} c
                                        ${DateHelper.formatDate(
                                        employeeRates.future?.activeFrom,
                                        'DD MMM YYYY').slice(2)})`
                                }
                            </Typography>
                        }
                    </Box>;
                },
            });
        });

        return columns;
    }, [xrayRateResponse]);

    const XrayRowTotal = () => {
            const totals: PropsTotal[] = [
                {
                    labelWidth: '0',
                    fieldName: 'employee',
                    totals: [{
                        label: '',
                        value: 'Стандартная ставка',
                        valueFormatter: () => <Typography variant='small' fontStyle='italic'>
                            Стандартная ставка:
                        </Typography>,
                    }],
                },
            ];
            _.forEach(xrayPictureTypes, (name, type) => {
                const standardRates = xrayRateResponse.rates.standard?.[type];
                const standardActualRate = standardRates?.actual?.sum ?? 0;
                totals.push({
                    labelWidth: '0',
                    fieldName: type,
                    totals: [{
                        label: '',
                        value: name,
                        valueFormatter: () =>
                            <Box display='flex' alignItems='center' gap='5px' position='relative' margin='0 -16px'>
                                <Box position='absolute' positionOffsets={{left: '-35px'}}>
                                    <ButtonForOptions
                                        options={[{
                                            value: 'change',
                                            label: 'Изменить',
                                            onClick: () => showModal(
                                                <XrayRateMark fio='Стандартная ставка'
                                                              employeeId={0}
                                                              rate={standardActualRate}
                                                              pictureName={name}
                                                              pictureType={type}
                                                              rateActiveFrom={standardRates?.actual?.activeFrom}
                                                              selectedBranch={selectedBranch}
                                                              refreshDataFromServer={refreshDataFromServer}
                                                />,
                                            ),
                                        }]}
                                        position='left-bottom'
                                    />
                                </Box>
                                {PriceHelper.format(standardActualRate)}
                                {standardRates?.future &&
                                    <Typography variant='min' fontStyle='italic'>
                                        {
                                            `(${standardRates?.future?.sum} c
                                        ${DateHelper.formatDate(
                                                standardRates?.future?.activeFrom,
                                                'DD MMM YYYY')
                                                /*иначе подставляет неправильные окончания*/
                                                .slice(2)})`
                                        }
                                    </Typography>
                                }
                            </Box>,
                    }],
                });
            });
            return totals;
        }


    return {
        xrayRateRows,
        xrayRateColumns,
        XrayRowTotal,
    };
};
export default useTableFormatter;
