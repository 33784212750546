import React from "react";
import {SLabelsWrap} from "../../style/labels";
import Label from "./components/Label";
import {useDialogsManager} from "../../DialogsManager";
import _ from 'lodash'

export default function Labels() {
    const {slideModals, _maximizeSlideModal, _removeSlideModal} = useDialogsManager();
    if (Object.values(slideModals).some(slideModal => (slideModal.keySlideModal === 'call-center' || slideModal.keySlideModal === 'manager-tasks') && !slideModal.isMinimized)) {
        return null
    }
    return (
        <SLabelsWrap>
            {_.map(slideModals, (slideModal, idModal) => {
                if (!slideModal.isMinimized || !slideModal.labelParams) return null;
                return (
                    <Label idModal={idModal}
                           key={"slideModal-label" + idModal}
                           maximizeSlideModal={() => _maximizeSlideModal(idModal)}
                           removeSlideModal={_removeSlideModal}
                           params={slideModal.labelParams}
                           canBeDeleted={slideModal.canBeDeleted}
                    />
                )
            })}
        </SLabelsWrap>
    )

}
