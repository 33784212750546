import React from "react";
import {useSelector} from "react-redux";
import Helper from "../../../../common/helpers/main";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";
import {User} from "../../../../types";
import { SDoctorColor } from "../../../../containers/Tasks/style";

interface Props {
    doctorId: any
}

export const Doctor = ({doctorId}: Props) => {
    const doctor = useSelector(state => state.common.doctors).find((doctor: any) => doctor.id === doctorId) as User

    return (
        <LabelTask label="Врач">
            <SDoctorColor color={doctor.color as string}/>
            <span>{Helper.getFio(doctor)}</span>
        </LabelTask>
    )
}
