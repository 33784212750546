import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const OralCavityIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M15.46,0a1.27,1.27,0,0,0-.28,0,4.66,4.66,0,0,0-1.77.58,5.21,5.21,0,0,1-.66.28A2.66,2.66,0,0,1,12,.94H12a4.06,4.06,0,0,1-.71-.08c-.12,0-.41-.17-.66-.28A4.67,4.67,0,0,0,8.82,0a1.27,1.27,0,0,0-.28,0C7.12.34,0,2.44,0,13.29c0,4.23,1.71,7.39,5,9.15A14.34,14.34,0,0,0,11.51,24h1a14.34,14.34,0,0,0,6.56-1.56C22.29,20.68,24,17.52,24,13.29,24,2.44,16.88.34,15.46,0Zm3.29,17.3a32.28,32.28,0,0,1-6.24,1V15.76c4.57.05,6.24.8,6.85,1.32A3.71,3.71,0,0,1,18.75,17.33Zm-7.26-1.57v2.58a32.28,32.28,0,0,1-6.24-1A3.66,3.66,0,0,1,4.58,17C5.29,16.52,7.06,15.81,11.49,15.76Zm8.72.71a3.21,3.21,0,0,0-.75-.57,2.2,2.2,0,0,0,.19-.3,6.07,6.07,0,0,0,0-3.61c-.59-3-3.73-3.88-5.55-4.15h-.22a1.53,1.53,0,0,0-1.51,1.54,1.25,1.25,0,0,0,.52,1.07c.19.17.38.32.38,1.2a1.28,1.28,0,1,1-2.56,0c0-.88.19-1,.38-1.2a1.25,1.25,0,0,0,.52-1.07,1.53,1.53,0,0,0-1.51-1.54H9.89C8.07,8.11,4.93,9,4.34,12a6.07,6.07,0,0,0,0,3.61c.05.1.11.19.16.27a3.35,3.35,0,0,0-.78.54,6.56,6.56,0,0,1-1.59-3.18A9.83,9.83,0,0,1,4.61,8.1C6.74,5.8,11.75,5.64,12,5.63s5.26.17,7.39,2.47a9.89,9.89,0,0,1,2.47,5.13A6.42,6.42,0,0,1,20.21,16.47Z"/>
        </WrapIcon>
    );
});
