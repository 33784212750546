import React from "react";
import {WrapContent} from "../../../styledUI";
import Search from "./elements/Search";
import AlignLabel from "./elements/AlignLabel";
import TextArea from "./elements/TextArea";
import Password from "./elements/Password";
import Autocomplete from "./elements/Autocomplete";
import Mask from "./elements/Mask";
import LabelTextField from "./elements/LabelTextField";
import NumberTextField from "./elements/NumberTextField";

export default function TextFieldPage() {
    return (
        <>
            <h1>TextField</h1>
            <WrapContent>
                <div>
                    <h4>Search</h4>
                    <Search/>
                    <h4>NumberTextField</h4>
                    <NumberTextField />
                    <h4>alignLabel</h4>
                    <AlignLabel />
                    <h4>TextArea</h4>
                    <TextArea />
                    <h4>Password</h4>
                    <Password/>
                    <h4>Autocomplete</h4>
                    <Autocomplete/>
                    <h4>Mask</h4>
                    <Mask/>
                    <h4>LabelTextField</h4>
                    <LabelTextField />
                </div>
            </WrapContent>
        </>
    )
}
