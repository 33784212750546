import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import Checkbox from "../../../../../components-ui/Inputs/Checkbox/Checkbox";

export default function CheckboxPage() {
    const [value, setValue] = useState(false);
    return (
        <>
            <h1>Checkbox</h1>
            <WrapContent>
                <div>
                    <Checkbox label="" checked={value} onChange={value => setValue(value)} />
                    <pre>
                        {
                            '<Checkbox label="" checked={value} onChange={(value) => setValue(value)} />'
                        }
                    </pre>
                </div>
                <div>
                    <Checkbox label="Пример чекбокса" checked={value} onChange={value => setValue(value)} />
                    <pre>
                        {
                            '<Checkbox label="Пример чекбокса" checked={value} onChange={(value) => setValue(value)} />'
                        }
                    </pre>
                </div>
                <div>
                    <Checkbox label="Задизабленный чекбокс" disabled={true} />
                    <pre>
                        {
                            '<Checkbox label="Задизабленный чекбокс" disabled={true} />'
                        }
                    </pre>
                </div>
                <div>
                    <Checkbox label="Задизабленный чекбокс 2" checked={true} disabled={true} />
                    <pre>
                        {
                            '<Checkbox label="Задизабленный чекбокс 2" checked={true} disabled={true} />'
                        }
                    </pre>
                </div>
                <div>
                    <Checkbox label="Чекбокск с подсказкой" checked={value} onChange={value => setValue(value)} tooltip="Подсказка какая то" />
                    <pre>
                        {
                            '<Checkbox label="Чекбокск с подсказкой" checked={value} onChange={value => setValue(value)} tooltip="Подсказка какая то" />'
                        }
                    </pre>
                </div>
            </WrapContent>
        </>
    )
}
