import React from 'react';
import {useSelector} from "react-redux";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";

interface Props {
    branchId: number
}

const VisitBranch = ({branchId}: Props) => {

    const branches = useSelector(state => state.common.branches)
    const allBranches = useSelector(state => state.common.allBranches)

    if (branches.length <= 1) {
        return null
    }

    return (
        <LabelTask label="Филиал">{allBranches[branchId].name}</LabelTask>
    );
};

export default VisitBranch;
