import {useMediaQuery} from "react-responsive";

export type Device = "desktop" | "minDesctop" | "tablet" | "mobile"

export const useDevice = (): Device => {

    const deviceParams = {
        isDesktop: useMediaQuery({minWidth: 1751}),
        isMinDesktop: useMediaQuery({maxWidth: 1751, minWidth: 800}),
        isMobile: useMediaQuery({maxWidth: 600})
    }

    if (deviceParams.isDesktop) {
        return "desktop"
    } else if (deviceParams.isMinDesktop) {
        return "minDesctop"
    } else if (deviceParams.isMobile) {
        return "mobile"
    } else {
        return "tablet"
    }
}