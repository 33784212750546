import {ScriptType, ScriptGroupList} from '../../../types/common';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {SScriptGroupItem} from '../style';
import SVG from '../../../../../common/helpers/SVG';
import ScriptListItem from './ScriptListItem';
import Box from '../../../../../components-ui/Layout/Box';
import Icon from '../../../../../components-ui/DataDisplay/Icon';
import Button from '../../../../../components-ui/Inputs/Button/Button';
import DeleteGroup from './ModalGroups/DeleteGroup';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import EditGroup from './ModalGroups/EditGroup';
import {useSelector} from "react-redux";

interface Props {
    scriptGroup: ScriptGroupList,
    setSelectedScript: Dispatch<SetStateAction<ScriptType | null>>,
    selectedScript: ScriptType | null
    setRefItem: Dispatch<SetStateAction<any>>
    refreshDataFromServer: () => void
}

const ScriptGroup = ({scriptGroup, selectedScript, setSelectedScript, setRefItem, refreshDataFromServer}: Props) => {
    const [open, setOpen] = useState(false);

    const {showModal} = useDialogsManager();

    const user = useSelector(state => state.user);

    useEffect(() => {
        if (selectedScript?.groupId === scriptGroup.id) {
            setOpen(true);
        }
    }, [selectedScript]);
    return (
        <>
            <SScriptGroupItem onClick={() => setOpen(!open)}>
                {scriptGroup.name}
                <Box display="flex" position="absolute" positionOffsets={{right: "0", top: "5px"}} gap="0" className="buttons-script">
                    {user.canEditCallScript && (
                        <>
                            <Button isIcon variant="secondary" noShadow={true} height="26px" width="32px"
                                    widthIcon="10px" heightIcon="10px"
                                    children={<Icon name="edit" width="12px" height="12px"/>}
                                    onClick={() => showModal(<EditGroup id={scriptGroup.id} name={scriptGroup.name} refreshDataFromServer={refreshDataFromServer}/>)}
                            />
                            <Button isIcon variant="secondary" noShadow={true} height="26px" width="32px"
                                    widthIcon="10px" heightIcon="10px"
                                    children={<Icon name="close" width="12px" height="12px"/>}
                                    onClick={() => showModal(<DeleteGroup id={scriptGroup.id} name={scriptGroup.name} refreshDataFromServer={refreshDataFromServer}/>)}
                            />
                        </>
                    )}
                {
                    scriptGroup.scriptList.length ?
                        <Button isIcon variant="secondary" noShadow={true} height="26px" width="32px"
                                widthIcon="10px" heightIcon="10px"
                                children={<SVG name={open ? 'minimizeList' : 'maximizeList'}/>}
                        />
                        :
                        null
                }
                </Box>
            </SScriptGroupItem>
            {scriptGroup.scriptList.map((script, index) => (<ScriptListItem key={script.id}
                                                                            setRefItem={setRefItem}
                                                                            script={script}
                                                                            open={open}
                                                                            lastItem={index === scriptGroup.scriptList.length - 1}
                                                                            setSelectedScript={setSelectedScript}
                                                                            selectedScript={selectedScript} />))}
        </>
    );
};

export default ScriptGroup;
