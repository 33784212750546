import React from 'react';
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {callCenterTasksInfo} from "../../../CallCenter/helper/getDataCallCenter";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";
import Box from '../../../../components-ui/Layout/Box';

interface Props {
    tasksTypes: string[]
}

export const TaskTypes = ({tasksTypes}: Props) => {
    return (
        <LabelTask label={(tasksTypes.length > 1 ? "Типы задач" : "Тип задачи") + " по обзвону"}
                   children={<Typography {...commonPropsTypography}
                                         display="flex"
                                         justifyContent="flex-end"
                                         alignItems="flex-start"
                                         textAlign="right"
                                         children={(
                                             <Box display="flex" flexDirection="column" alignItems="flex-end">
                                                 {tasksTypes.map(type => {
                                                     return (
                                                         <Typography children={callCenterTasksInfo[type].name} as="span"
                                                                     variant="small"/>
                                                     )
                                                 })}
                                             </Box>
                                         )}/>}
                   alignItems="flex-start"
        />
    );
};
