import Helper from "../../common/helpers/main";
import Target from "./Target/Target";
import Measure from "../../common/Service/Measure";
import TargetFactory from "./Target/TargetFactory";
import Arr from 'common/helpers/Arr'
import Group from "../../common/helpers/group-helper";
import Obj from "../../common/helpers/object";
import PlanFiller from "./PlanFiller";
import _ from 'underscore';
import StageBuilder from "./StageBuilder";
import {getDirectionsQuantity} from "../../common/helpers/treatment-plan-item";

export default class TreatmentPlan {
    servicesRequiredForMap = null;

    listeners = {};

    isDirty = false;

    definedDirections = null;

    constructor(params) {
        this.services = Arr.toObject(params.services, 'id');
        this.servicesGroups = this.constructor.prepareServicesGroups(params.servicesGroups);
        this.checkStatuses = params.checkStatuses;
        this.teethMap = params.teethMap;
        this.directions = Arr.toObject(params.directions, 'code');
        this.proceduresCombinations = params.proceduresCombinations;
        this.forceDirection = params.forceDirection;
        this.currentVisitId = params.currentVisitId;

        this.filler = new PlanFiller(params.servicesRelationsRules, this.services, this.getServiceDirection);

        const planItems = Helper.clone(params.planItems);
        planItems.forEach(planItem => this.constructor.prepareItem(planItem));
        this.planItems = Arr.toObject(planItems, this.constructor.getPlanItemCode);
    }

    setActiveTeeth(activeTeeth) {
        this.activeTeeth = activeTeeth;
    }

    /**
     * Подготавливает объект пункта плана лечения к использованию внутри класса
     * @param {object} planItem
     * @return {*}
     */
    static prepareItem(planItem) {
        if (!(planItem.target instanceof Target)) {
            planItem.target = TargetFactory.create(planItem.target, planItem.measure);
        }

        if (!(planItem.measure instanceof Measure)) {
            planItem.measure = new Measure(planItem.measure);
        }

        return planItem;
    }

    /**
     * Подготавливает элементы плана, загруженные с сервера (сжимает, указывает актуальные направления)
     * @param items
     * @param services
     * @param servicesGroups
     * @param directions
     * @param planParams
     */
    static prepareItemsFromServer(items, services, servicesGroups, directions, planParams) {
        items = this.squeezeItems(items);
        services = Arr.toObject(services, 'id');

        /* --- получаем возможные направления для пунктов плана лечения  --- */

        items.forEach(item => {
            const service = services[item.serviceId];

            /* добавляем актуальный массив направлений */
            if (!planParams || !planParams['force_direction']) {
                item.directions = Arr.merge(item.directions, service.directions);
            }

            /* добавляем в процедуры комбинации */
            if (item.proceduresCombinations) {
                item.procedures.forEach(procedure => {
                    const procedureCombinations = item.proceduresCombinations[procedure.procedureId];
                    procedure.combinations = procedureCombinations ? procedureCombinations : null;
                });
            }

            /* подготовка target */
            const target = TargetFactory.create(item.target, item.measure);
            item.target = target.getData();
        });

        return items;
    }

    static prepareServicesGroups(servicesGroups) {
        return Group.expandTree(servicesGroups)
    }

    /**
     * Производит "упрощение" массива пунктов плана лечения - переводит экземпляры классов к простым типам переменных
     * @param {object} planItems
     * @return {object}
     */
    static simplifyItems(planItems) {
        planItems = Helper.clone(planItems);
        Helper.forEachObj(planItems, planItem => {
            planItem.target = planItem.target.getData();
            planItem.measure = planItem.measure.getType();
        });

        return planItems;
    }

    /**
     * Получает id услуг, применённых к активным зубам в плане лечения
     * @var {number} performerId
     * @var {number[]} teeth
     * @return {number[]}
     */
    getAppliedServicesIds(performerId, teeth = null) {
        let result = [];

        let serviceTargetMatches = {};

        if (teeth === null) {
            teeth = this.activeTeeth;
        }

        Helper.forEachObj(this.planItems, planItem => {
            if (planItem.performerId !== performerId) return;

            const matchedTeeth = planItem.target.matchTeeth(teeth);
            if (matchedTeeth) {
                if (planItem.target.usesFullServiceApplyMode()) {
                    if (!(planItem.serviceId in serviceTargetMatches)) {
                        serviceTargetMatches[planItem.serviceId] = [];
                    }

                    serviceTargetMatches[planItem.serviceId] = serviceTargetMatches[planItem.serviceId].concat(matchedTeeth);
                } else {
                    result.push(planItem.serviceId);
                }
            }
        });

        Helper.forEachObj(serviceTargetMatches, (matchedTeeth, serviceId) => {
            if (Arr.unique(matchedTeeth).length === this.activeTeeth.length) {
                result.push(Number(serviceId));
            }
        });

        return Arr.unique(result);
    }

    getServiceDirection = service => {
        return this.forceDirection ? [this.forceDirection] : service.directions;
    };

    getServiceSingleDirection(serviceId) {
        const directions = this.getServiceDirection(this.services[serviceId]);
        return (directions.length === 1) ? directions[0] : null;
    }

    isServiceApplied = (serviceId, performerId, teeth = null) => {
        return this.getAppliedServicesIds(performerId, teeth).includes(serviceId);
    }

    /**
     * Применяет указанную услугу к активным зубам, если данная услуга к ним уже не применена, в противном случае отменяет услугу
     * для активных зубов
     * @param {number} serviceId
     * @param {number} performerId
     * @return {TreatmentPlan}
     */
    toggleService = (serviceId, performerId) => {
        if (this.isServiceApplied(serviceId, performerId)) {
            const service = this.services[serviceId];

            // если услуга уже применена - удаляем её из плана
            this.getActiveTargets(serviceId).forEach(target => {
                const itemCodeToDelete = TreatmentPlan.getPlanItemCode(this.constructor.prepareItem({
                    serviceId,
                    target,
                    performerId,
                    measure: service.measure,
                    status: 'NOT_STARTED',
                }, this.services));

                this.deleteItem(itemCodeToDelete);
            })
        } else {
            // если услуга не применена - добавляем её в план вместе со связанными услугами
            this.addService(serviceId, performerId, null, false);
        }

        return this;
    };

    /**
     * Добавляет в план услугу на указанные зубы
     * @param serviceId - id услуги
     * @param performerId - id врача, на которого нужно добавить услугу
     * @param teeth - зубы, на которые нужно добавить услугу. По умолчанию - активные зубы (выбранные в зубной формуле)
     * @param checkApplied - нужно ли проверить наличие услуги в плане
     */
    addService(serviceId, performerId, teeth = null, checkApplied = true) {
        if (checkApplied && this.isServiceApplied(serviceId, performerId, teeth)) {
            return this;
        }

        this.filler.addRelatedServices(
            serviceId,
            this.getUsedServices(performerId),
            this.changeServiceQuantity.bind(this, performerId, this.getServiceSingleDirection(serviceId))
        );

        const targets = this.getActiveTargets(serviceId, teeth);

        targets.forEach(target => this.addItem(serviceId, target, {performerId}));

        return this;
    }

    /**
     * Для указанной услуги получает набор целей, попадающих под набор активных зубов
     * @param {number} serviceId
     * @param {number[]|null} teeth
     * @return {Array}
     */
    getActiveTargets(serviceId, teeth = null) {
        let activeTargets = [];
        const service = this.services[serviceId];
        const serviceMeasure = new Measure(service.measure);

        if (teeth === null) {
            teeth = this.activeTeeth;
        }

        if (teeth.length) {
            let compositeTarget = [];

            teeth.forEach(toothNumber => {
                const targetUnit = serviceMeasure.getTargetUnitByTooth(toothNumber);

                if (serviceMeasure.isComposite()) {
                    compositeTarget.push(targetUnit);
                } else {
                    activeTargets.push(targetUnit);
                }
            });

            if (serviceMeasure.isComposite() && compositeTarget.length) {
                activeTargets.push(compositeTarget);
            }
        } else {
            activeTargets.push(serviceMeasure.getTargetUnitByTooth(null));
        }

        return activeTargets;
    }

    /**
     * Возвращает составляющие целей пунктов плана лечения, к которым применены услуги
     * @return {*}
     */
    getProcessedTargetUnits() {
        const targetUnits = Object.values(this.planItems).reduce((result, planItem) => result.concat(planItem.target.getData(true)), []);
        return Arr.unique(targetUnits);
    }

    /**
     * Возвращает пункт плана с указанным кодом
     * @param {string} code
     */
    getItem(code) {
        return this.planItems[code];
    }

    /**
     * Добавляет в план пункт с указанной услугой, целью и количеством
     * @param {number|object} service
     * @param {array|string|number|Target} target
     * @param {object} additionalFields
     */
    addItem = (service, target = null, additionalFields = {}) => {
        if (typeof service === 'number') {
            service = this.services[service];
        }

        const curDoctorId = window.user.doctorId;

        let newItem = Object.assign({
            target,
            serviceId: service.id,
            serviceName: service.name,
            serviceGroupId: service.groupId,
            measure: service.measure,
            status: 'NOT_STARTED',
            isAdditional: service.isAdditional,
            directions: this.getServiceDirection(service),
            proceduresCombinationsConditions: this.getServiceProceduresCombinationsConditions(service),
            params: {
                'required_additional_services': service.requiredAdditionalServices,
                'related_additional_services': service.relatedAdditionalServices,
            },
            quantity: 1,
            paid: 0,
            creatorsIds: [curDoctorId],
            performerId: curDoctorId,
            createdVisit: this.currentVisitId
        }, additionalFields);

        newItem = this.constructor.prepareItem(newItem, this.services);
        newItem.procedures = !!newItem.procedures ? Helper.clone(newItem.procedures) : this.getItemProcedures(newItem, service.procedures);

        /* сохраняем конфигурации процедур */

        if (!this.hasItem(newItem)) {
            this.planItems[this.constructor.getPlanItemCode(newItem)] = newItem;
            this.isDirty = true;
        }
    };

    addProcedure(itemCode, procedureId, target, fields = {}) {
        const procedure = Object.assign({
            isActive: true,
            isBlocked: false,
            isDone: false,
            procedureId,
            target,
            quantity: 1
        }, fields);

        const planItem = this.planItems[itemCode];
        planItem.procedures.push(procedure);
    }

    changeServiceQuantity = (performerId, direction, serviceId, quantity) => {
        if (quantity === 0) return;

        const notStartedItem = _.findWhere(this.planItems, {serviceId, performerId, status: 'NOT_STARTED'});
        if (!!notStartedItem) {
            const notStartedItemCode = TreatmentPlan.getPlanItemCode(notStartedItem);
            const actualDirections = this.getItemActualDirections(notStartedItem);
            const directionsQuantity = !!notStartedItem.directionsQuantity ? Helper.clone(notStartedItem.directionsQuantity) : {};

            if (direction) {
                if (direction in directionsQuantity) {
                    directionsQuantity[direction] += quantity;
                } else {
                    directionsQuantity[direction] = quantity;
                }
            }

            if (quantity > 0) {
                const planHasMainServices = _.some(this.planItems, item => !item.isAdditional && item.performerId === performerId);
                let notDividedQuantity = this.getItemNotDividedQuantity(notStartedItemCode);

                if(notDividedQuantity && direction && (actualDirections.length === 1) && (actualDirections[0] === direction)) {
                    directionsQuantity[direction] += notDividedQuantity;
                }

                if (!planHasMainServices) {
                    if (!!notStartedItem.directionsQuantity && direction && direction in notStartedItem.directionsQuantity) {
                        const newDirectionQuantity = Math.max(notStartedItem.directionsQuantity[direction], quantity);
                        quantity -= directionsQuantity[direction] - newDirectionQuantity;
                        directionsQuantity[direction] = newDirectionQuantity;
                    }

                    if (notDividedQuantity > 0) {
                        // при добавлении услуг разрешён "захват" нераспределённого количества доп услуг и доп услуг по текущему направлению,
                        // чтобы корректно работало изменение количества при наличии обязательных доп услуг в плане
                        // (добавляемых автоматически изначально). "Захват" разрешён только если в плане нет основных услуг
                        quantity -= notDividedQuantity;

                        notDividedQuantity = (quantity < 0) ? -quantity : 0;
                    }

                    quantity = (quantity > 0) ? quantity : 0;
                }

                if (notDividedQuantity && direction && (actualDirections.length === 1) && (actualDirections[0] !== direction)) {
                    directionsQuantity[actualDirections[0]] = notDividedQuantity;
                }

                this.setItemQuantity(notStartedItemCode, '+=' + quantity, directionsQuantity);
            } else if (-quantity >= notStartedItem.quantity) {
                this.deleteItem(notStartedItemCode);
            } else {
                this.setItemQuantity(notStartedItemCode, '-=' + -quantity, directionsQuantity);
            }
        } else if (quantity > 0) {
            const additionalFields = {quantity, performerId};
            if (direction) {
                additionalFields.directionsQuantity = {[direction]: quantity};
            }

            this.addItem(serviceId, null, additionalFields);
        }
    }

    getItemProcedures(item, serviceProcedures) {
        let itemProcedures = [];

        serviceProcedures.forEach(serviceProcedure => {
            item.target.getProcedureTargets(serviceProcedure.measure, this.teethMap).forEach(childTarget => {
                let itemProcedure = Helper.clone(serviceProcedure);
                itemProcedure.target = childTarget.getData();
                itemProcedure.isActive = serviceProcedure.defaultActive;

                itemProcedures.push(itemProcedure);
            })
        });

        return itemProcedures;
    }

    /**
     * Удаляет из плана лечения пункт с указанным кодом
     * @param {string} itemCode
     * @return {TreatmentPlan}
     */
    deleteItem(itemCode) {
        const item = this.planItems[itemCode];
        delete this.planItems[itemCode];

        this.filler.deleteRelatedServices(
            item.serviceId,
            this.getUsedServices(item.performerId),
            this.changeServiceQuantity.bind(this, item.performerId, this.getServiceSingleDirection(item.serviceId))
        );

        this.isDirty = true;

        return this;
    }

    getItemRequiredFor(itemCode) {
        const item = this.planItems[itemCode];
        return this.getServiceRequiredFor(item.serviceId, item.performerId);
    }

    getServiceRequiredFor(serviceId, performerId = null) {
        if (!performerId) {
            performerId = window.user.doctorId;
        }

        if (!this.servicesRequiredForMap || !this.servicesRequiredForMap[performerId]) {

            if (!this.servicesRequiredForMap) {
                this.servicesRequiredForMap = {};
            }

            this.servicesRequiredForMap[performerId] = this.filler.getRequiredForMap(this.getUsedServices(performerId), this.defineDirections(performerId));
        }

        return (serviceId in this.servicesRequiredForMap[performerId]) ? this.servicesRequiredForMap[performerId][serviceId] : null;
    }

    /**
     * Проверяет, есть ли в плане лечения указанный пункт лечения
     * @param {object} desiredItem
     * @return {*}
     */
    hasItem(desiredItem) {
        const desiredItemCode = (typeof desiredItem === 'object') ? TreatmentPlan.getPlanItemCode(desiredItem) : desiredItem;
        return desiredItemCode in this.planItems;
    }

    hasService(serviceId, performerId = null) {
        if (performerId === null) {
            performerId = window.user.doctorId;
        }

        if (!this.usedServicesMap) {
            const curDoctorItems = Obj.filter(this.planItems, item => item.performerId === performerId);
            this.usedServicesMap = Arr.flip(Obj.pluck(curDoctorItems, 'serviceId'));
        }

        return serviceId in this.usedServicesMap;
    }

    /**
     * Устанавливает количество для пункта плана лечения с указанным кодом
     * @param {string} itemCode
     * @param {string|number} quantity
     * @param {object|null?} directionsQuantity
     * @param {boolean} addDirectionQuantity
     * @return {TreatmentPlan}
     */
    setItemQuantity(itemCode, quantity, directionsQuantity = null, addDirectionQuantity = false) {
        const planItem = this.planItems[itemCode];
        if (!planItem) return this;

        if ((typeof quantity === 'string')) {
            if (quantity.startsWith('+=')) {
                quantity = planItem.quantity + Number(quantity.substr(2));
            } else if (quantity.startsWith('-=')) {
                quantity = planItem.quantity - Number(quantity.substr(2));
            }
        }

        if (!!directionsQuantity && addDirectionQuantity) {
            directionsQuantity = Obj.mergeSum(getDirectionsQuantity(planItem), directionsQuantity);
        }


        if (planItem.status !== 'NOT_STARTED') {
            if (planItem.quantity < quantity) {
                // проверяем, есть ли уже в плане соответствующий пункт со статусом NOT_STARTED
                const notStartedItemTemplate = Helper.clone(planItem);
                notStartedItemTemplate.status = 'NOT_STARTED';
                const notStartedItemCode = TreatmentPlan.getPlanItemCode(notStartedItemTemplate);

                const notStartedItem = this.planItems[notStartedItemCode];
                const directionsQuantityIncrement = TreatmentPlan.getDirectionsQuantityIncrement(directionsQuantity, planItem.directionsQuantity);

                if (!!notStartedItem) {
                    let newDirectionsQuantity;

                    if (directionsQuantity) {
                        newDirectionsQuantity = notStartedItem.directionsQuantity ? Obj.mergeSum(notStartedItem.directionsQuantity, directionsQuantityIncrement) : directionsQuantityIncrement;
                    } else {
                        newDirectionsQuantity = null;
                    }

                    this.setItemQuantity(notStartedItemCode, '+=' + (quantity - planItem.quantity), newDirectionsQuantity);
                } else {
                    // если пункт плана блокирован по статусу - добавляем новый пункт плана лечения со статусом "Не начат" с той же услугой и целью,
                    this.addItem(this.services[planItem.serviceId], planItem.target, {
                        directionsQuantity: directionsQuantityIncrement,
                        performerId: planItem.performerId
                    });
                }
            }
        } else {
            planItem.quantity = quantity;

            if (directionsQuantity) {
                planItem.directionsQuantity = directionsQuantity;
            } else if (planItem.directions.length === 1) {
                if (!planItem.directionsQuantity) {
                    planItem.directionsQuantity = {};
                }
                planItem.directionsQuantity[planItem.directions[0]] = quantity;
            } else if (!!planItem.directionsQuantity && (Obj.length(planItem.directionsQuantity) === 1)) {
                const actualDirections = this.getItemActualDirections(itemCode);
                if (actualDirections.length === 1) {
                    planItem.directionsQuantity[actualDirections[0]] = quantity;
                }
            }
        }

        planItem.creatorsIds = [window.user.doctorId ? window.user.doctorId : 0];

        this.isDirty = true;

        return this;
    }

    setProcedureQuantity(itemCode, procedureCode, quantity) {
        const procedure = this.getProcedure(itemCode, procedureCode);

        if ((typeof quantity === 'string')) {
            if (quantity.startsWith('+=')) {
                quantity = procedure.quantity + Number(quantity.substr(2));
            } else if (quantity.startsWith('-=')) {
                quantity = procedure.quantity - Number(quantity.substr(2));
            }
        }


        if (procedure.isDone) {
            if (procedure.quantity < quantity) {
                // проверяем, есть ли у данного пункта плана такая же процедура с невыполненным статусом
                const notStartedProcedureTemplate = Helper.clone(procedure);
                notStartedProcedureTemplate.isDone = false;

                const notStartedProcedureCode = TreatmentPlan.getProcedureCode(notStartedProcedureTemplate, itemCode);
                const notStartedProcedure = this.getProcedure(itemCode, notStartedProcedureCode);

                if (!!notStartedProcedure) {
                    this.setProcedureQuantity(itemCode, notStartedProcedureCode, '+=' + (quantity - procedure.quantity));
                } else {
                    // добавляем процедуру
                    const newProcedureFields = _.pick(procedure, 'measure', 'name', 'price');
                    newProcedureFields.quantity = quantity - procedure.quantity;

                    this.addProcedure(itemCode, procedure.procedureId, procedure.target, newProcedureFields);
                }
            }
        } else {
            this.getProcedure(itemCode, procedureCode).quantity = quantity;
        }

        return this;
    }

    getProcedure(itemCode, procedureCode) {
        const procedures = Arr.toObject(this.planItems[itemCode].procedures, procedure => this.constructor.getProcedureCode(procedure, itemCode));
        return procedures[procedureCode];
    }

    toggleItemProcedureActive(itemCode, procedureCode) {
        const itemProcedure = this.getProcedure(itemCode, procedureCode);
        itemProcedure.isActive = !itemProcedure.isActive;
        return this;
    }

    /**
     * Проверяет, доступен ли для изменения указанный пункт плана лечения
     * @param {object} item
     * @return {boolean}
     */
    isItemEnabled = (item) => {
        return !this.checkStatuses || item.procedures.every(procedure => !procedure.isBlocked);
    };

    /**
     * Проверяет, возможно ли применение/отмена указанной услуги по отношению к текущей комбинации активных зубов
     * @param {number} serviceId
     * @param {?string} reason - может принимать значения 'active_teeth' и 'status'. По умолчанию проверяется блокировка по
     * всем указанным причинам
     * @return {*}
     */
    isServiceBlocked = (serviceId, reason = null) => {
        let isBlockedByActiveTeeth = false;
        let isBlockedByStatus = false;

        if ((reason === null) || (reason === 'active_teeth')) {
            const measure = new Measure(this.services[serviceId].measure);
            isBlockedByActiveTeeth = !this.activeTeeth.length && measure.requiresActiveTeeth();
        }

        if ((reason === null) || (reason === 'status')) {
            const planItems = Object.values(this.planItems);
            const servicePlanItems = planItems.filter(item => (item.serviceId === serviceId) && this.isCurDoctorItem(item));

            isBlockedByStatus = this.checkStatuses && servicePlanItems.some(item => {
                const hasBlockedProcedures = item.procedures.some(procedure => procedure.isBlocked);
                return hasBlockedProcedures && item.target.matchTeeth(this.activeTeeth);
            });
        }

        return isBlockedByActiveTeeth || isBlockedByStatus;
    };

    /**
     * Получает конфигурации комбинаций процедур переданной услуги
     * @param service
     * @return {*}
     */
    getServiceProceduresCombinationsConditions(service) {
        if (service.proceduresCombinations) {
            const combinationsConfig = {};

            service.proceduresCombinations.forEach(combinationId => {
                const combination = this.proceduresCombinations[combinationId];
                combinationsConfig[combinationId] = combination.conditions;
            });

            return combinationsConfig;
        }

        return null;
    }

    /**
     * Проверяет, какие комбинации процедур применены для указанного пункта плана лечения
     * @param {object} planItem
     * @return {object}
     */
    static checkItemProceduresCombinations(planItem) {
        if (!planItem.proceduresCombinationsConditions) return {};

        /* --- составляем карту активности процедур --- */

        const proceduresActiveMap = {};

        planItem.procedures.forEach(procedure => {
            if (procedure.isActive) {
                proceduresActiveMap[procedure.procedureId] = true;
            }
        });

        /* --- определяем применённые конфигурации --- */

        const combinationsApplyingMap = {};

        Obj.forEach(planItem.proceduresCombinationsConditions, (conditions, combinationId) => {
            combinationsApplyingMap[combinationId] = conditions.some(condition => {
                return Obj.every(condition, (mustBeActive, procedureId) => {
                    return proceduresActiveMap[procedureId] === mustBeActive;
                })
            });
        });

        return combinationsApplyingMap;
    }

    /**
     * Возвращает копию пунктов плана лечения
     * @return {Array}
     */
    getItems() {
        return Object.values(this.constructor.simplifyItems(this.planItems));
    }

    /**
     * Сохраняет план лечения
     */
    save() {
        this.clearDirectionsCache();
        this.fireEvent('saving');
        const itemsToSave = Object.values(this.constructor.simplifyItems(this.planItems));
        this.fireEvent('save', itemsToSave);
    }

    /**
     * Получает код пункта плана лечения
     * @param {object} planItem
     * @param {boolean} withStatus
     * @param {boolean} withPerformer
     * @return {string}
     */
    static getPlanItemCode = (planItem, withStatus = true, withPerformer = true) => {
        const target = (planItem.target instanceof Target) ? planItem.target : TargetFactory.create(planItem.target, planItem.measure);
        let result = planItem.serviceId + '_' + target.getData(true).join(',');

        if (withPerformer) {
            result += '_' + planItem.performerId;
        }

        if (withStatus) {
            result += '_' + planItem.status;
        }

        return result;
    };

    static getProcedureCode = (procedure, planItem) => {
        const planItemCode = (typeof planItem === 'string') ? planItem : TreatmentPlan.getPlanItemCode(planItem);

        return planItemCode + '_' + procedure.procedureId + '_' + procedure.target + '_' + procedure.isDone;
    };

    static getItemPrice(planItem, procedureFilter = null) {
        const activeProcedures = planItem.procedures.filter(procedure => (!procedureFilter || procedureFilter(procedure)) && procedure.isActive);
        return activeProcedures.reduce((sum, procedure) => {
            const procedureSum = ('quantity' in procedure) ? procedure.price * procedure.quantity : procedure.price;
            return sum + procedureSum;
        }, 0);
    };

    static getItemSum(planItem) {
        return this.getItemPrice(planItem) * planItem.quantity;
    };

    /***
     * Сжимает пункты плана лечения - сливает одинаковые пункты и процедуры в один пункт / процедуру с подсчётом количества
     * @param items
     * @param handler
     * @return {*}
     */
    static squeezeItems = (items, handler = null) => {
        items = Helper.clone(items);

        items = Arr.squeeze(items, TreatmentPlan.getPlanItemCode, {
            stackFields: ['id', 'direction'],
            handler: (originalItems, squeezedItem, itemCode) => {
                /* подсчёт количества по направлениям */
                const directions = originalItems.map(originalItem => originalItem.direction);
                squeezedItem.directions = Arr.unique(directions);
                squeezedItem.directionsQuantity = Arr.countValues(directions);
                squeezedItem.paid = originalItems.reduce((paid, item) => paid + item.paid, 0);
                squeezedItem.creatorsIds = _.uniq(_.pluck(originalItems, 'creatorId'));

                /* сжатие процедур */
                squeezedItem.procedures = Arr.squeeze(squeezedItem.procedures, ['procedureId', 'target', 'isDone']);

                //TODO убрать эту костылину - сделать событием, как в expandItems
                StageBuilder.squeezeItemStages(originalItems, squeezedItem);

                if (handler !== null) {
                    handler(originalItems, squeezedItem, itemCode);
                }
            }
        });

        return items;
    };

    /**
     * "Расправляет" пункты плана - для каждого пункта создаёт количество копий,
     * равное числу в поле quantity (считая исходный элемент), восстанавливает id и направление
     * @return {*|Array}
     */
    expandItems = () => {
        return Arr.expand(this.getItems(), (originalItem, expandedItems) => {

            /* --- распределяем направления по "расправленным" пунктам плана на основе распределённого количества --- */

            const definedDirections = this.defineDirections(originalItem.performerId);
            const prevalentDirection = (definedDirections.length === 1) ? definedDirections[0] : null;

            let directionsQuantity = Helper.clone(originalItem.directionsQuantity);

            if (!!directionsQuantity) {
                directionsQuantity = Helper.filterObj(directionsQuantity, quantity => quantity > 0);
            } else {
                directionsQuantity = {};
                let onlyDirection = null;

                if (originalItem.directions.length === 1) {
                    onlyDirection = originalItem.directions[0];
                } else if (prevalentDirection) {
                    onlyDirection = prevalentDirection;
                } else {
                    const actualDirections = this.getItemActualDirections(originalItem);
                    if (actualDirections.length === 1) {
                        onlyDirection = actualDirections[0];
                    }
                }

                if (onlyDirection) {
                    directionsQuantity[onlyDirection] = originalItem.quantity;
                }
            }

            expandedItems.forEach(expandedItem => {
                let curDirection;

                if (expandedItem.direction && directionsQuantity[expandedItem.direction]) {
                    // направление, восстановленное из стека (связанное с id), в приоритете
                    curDirection = expandedItem.direction;
                } else {
                    curDirection = Obj.keys(directionsQuantity)[0];
                    expandedItem.direction = curDirection;
                }

                directionsQuantity[curDirection]--;
                if (!directionsQuantity[curDirection]) {
                    delete directionsQuantity[curDirection];
                }

                expandedItem.procedures = Arr.expand(expandedItem.procedures);
            });

            this.fireEvent('expand_item', originalItem, expandedItems);
        });
    };

    /**
     * Осуществляет предварительное заполнение плана (доп услугами) для текущего врача
     */
    preFill({planType, savedInCurrentVisit}) {
        const doctorId = window.user.doctorId;

        if ((planType === 'recommended') && !savedInCurrentVisit && this.isFulfilledForDoctor(doctorId)) {
            this.prepareForDoctor(doctorId);
        }

        return this;
    }

    /**
     * Подготавливает план для врача - добавляет обязательные доп услуги
     * @param doctorId
     */
    prepareForDoctor = doctorId => {
        this.filler.addInitialServices(this.getUsedServices(doctorId), this.changeServiceQuantity.bind(this, doctorId, null));
        return this;
    }

    /**
     * Проверяет, является ли план выполненным для текущего врача
     * @var {number} doctorId
     * @return {boolean}
     */
    isFulfilledForDoctor(doctorId) {
        let isFulfilled = false;

        if (window.user.role === 'doctor') {
            isFulfilled = Object.values(this.planItems).every(item => item.isDone || (item.performerId !== doctorId));
        }

        return isFulfilled;
    }

    isCurDoctorItem(item) {
        return item.performerId === window.user.doctorId;
    }

    /**
     * Определяет направления плана лечения (по добавленным основным услугам)
     * @param {number|null} performerId
     * @param {boolean} updateCache
     * @return {array}
     */
    defineDirections(performerId = null, updateCache = false) {
        if (updateCache || !this.definedDirections) {
            this.definedDirections = {};

            Obj.forEach(this.planItems, item => {
                if (!item.isAdditional && (item.directions.length === 1)) {
                    if (!this.definedDirections[item.performerId]) {
                        this.definedDirections[item.performerId] = [];
                    }

                    this.definedDirections[item.performerId].push(item.directions[0]);
                }
            });

            Obj.forEach(this.definedDirections, (directions, performerId) => this.definedDirections[performerId] = Arr.unique(directions));
        }

        let directions;

        if (performerId !== null) {
            directions = this.definedDirections[performerId] ? this.definedDirections[performerId] : [];
        } else {
            directions = _.unique(Object.values(this.definedDirections).flat());
        }

        return directions;
    }

    clearDirectionsCache() {
        this.definedDirections = null;
    }

    /**
     * Получает актуальные направления для пункта плана лечения (направления, по которым требуется распределить количество)
     * @param item
     * @return {*}
     */
    getItemActualDirections(item) {
        if (typeof item === 'string') {
            item = this.planItems[item];
        }

        return (item.directions.length > 1)
            ? Arr.intersect(this.defineDirections(item.performerId), item.directions)
            : item.directions;
    }

    /**
     * Для пункта плана лечения получает объект количества, распределённое по направлениям
     * @param {string} itemCode
     * @param {array} actualDirections
     * @return {object}
     */
    getItemDirectionsQuantity(itemCode, actualDirections) {
        const item = this.planItems[itemCode];
        return Obj.fillEmpty(actualDirections, 0, item.directionsQuantity);
    }

    /**
     * Определяет, необходимо ли для пункта плана разделить количество по направлениям
     * @param itemCode
     * @param actualDirections
     * @return {boolean}
     */
    needDivideItemQuantity(itemCode, actualDirections = null) {
        const item = this.planItems[itemCode];

        if (!actualDirections) {
            actualDirections = this.getItemActualDirections(itemCode);
        }

        return (actualDirections.length !== 1) && (!item.directionsQuantity || item.quantity > Obj.sum(item.directionsQuantity));
    }

    getItemNotDividedQuantity(itemCode) {
        const item = this.planItems[itemCode];
        const directionsQuantitySum = !!item.directionsQuantity ? Obj.sum(item.directionsQuantity) : 0;
        return item.quantity - directionsQuantitySum;
    }

    hasPerformer = doctorId => {
        return _.some(this.planItems, item => item.performerId === doctorId);
    }

    deletePerformer(doctorId) {
        this.planItems = Helper.filterObj(this.planItems, item => item.performerId !== doctorId);
        this.isDirty = true;

        return this;
    }

    getReplaceablePerformers() {
        const replaceablePerformers = [];

        _.chain(this.planItems)
            .groupBy('performerId')
            .each((performerItems, performerId) => {
                if (performerItems.every(item => item.status === 'NOT_STARTED')) {
                    replaceablePerformers.push(performerId);
                }
            });

        return replaceablePerformers;
    }

    replacePerformer(doctorId, newDoctorId) {
        const itemsToReplace = Obj.filter(this.planItems, item => item.performerId === doctorId);
        const curDoctorId = window.user.doctorId;

        this.planItems = _.omit(this.planItems, ..._.keys(itemsToReplace));

        _.each(itemsToReplace, item => {
            item.performerId = newDoctorId;

            const newItemCode = TreatmentPlan.getPlanItemCode(item);
            this.planItems[newItemCode] = item;
        });

        if (doctorId === curDoctorId) {
            this.prepareForDoctor(doctorId);
        }

        this.isDirty = true;

        return this;
    }

    /**
     * Возвращает врачей, которым текущий врач составил план лечения в текущем приёме
     * @return {Array}
     */
    getCreatedPlanToDoctors() {
        const curDoctorId = window.user.doctorId;
        if (!curDoctorId || !this.currentVisitId) return [];

        const result = [];

        _.chain(this.planItems)
            .groupBy('performerId')
            .each((performerItems, performerId) => {
                performerId = Number(performerId);

                if (!performerId || performerId === curDoctorId) return;

                const everyItemCreatedByCurDoctor = performerItems.every(item => {
                    return (item.createdVisit === this.currentVisitId)
                        && (item.creatorsIds.length === 1)
                        && (item.creatorsIds[0] === curDoctorId);
                });

                if (everyItemCreatedByCurDoctor) {
                    result.push(performerId);
                }
            });

        return result;
    }

    getRecommendedDoctors(createdPlanToDoctors = null) {
        if (createdPlanToDoctors === null) {
            createdPlanToDoctors = this.getCreatedPlanToDoctors();
        }

        if (!createdPlanToDoctors.length) return [];

        const doctorsWithMainItems = {};

        _.each(this.planItems, item => {
            if (!item.isAdditional) {
                doctorsWithMainItems[item.performerId] = item.performerId;
            }
        });

        return _.intersection(createdPlanToDoctors, Object.values(doctorsWithMainItems));
    }

    getUsedServices(performerId) {
        const usedServices = _.chain(this.planItems)
            .filter(item => item.performerId === performerId)
            .map(item => {
                const service = this.services[item.serviceId];
                return {
                    id: item.serviceId,
                    isAdditional: item.isAdditional,
                    isEnabled: this.isItemEnabled(item),
                    requiredAdditionalServices: !!item.params ? item.params['required_additional_services'] : null,
                    relatedAdditionalServices: !!item.params ? item.params['related_additional_services'] : null,
                    fillerRule: !!service.fillerRule ? service.fillerRule : null,
                    quantity: item.quantity,
                };
            })
            .value();

        return _.indexBy(Arr.squeeze(usedServices, ['id']), 'id');
    }

    getDoctorItems() {
        const curDoctorId = window.user.doctorId;

        return curDoctorId ? Obj.filter(this.planItems, item => item.performerId === curDoctorId) : this.planItems;
    }

    isEmpty() {
        return _.isEmpty(this.getDoctorItems());
    }

    needDivideQuantity() {
        return Obj.keys(this.planItems).some(itemCode => this.needDivideItemQuantity(itemCode));
    }

    needAddAdditionalServices() {
        if (Helper.isDisableAddedAdditionalService()) {
            return false;
        }
        return !Obj.some(this.getDoctorItems(), item => item.isAdditional);
    }

    getNotDecidedPerformers(recommendedVisitsDecisions) {
        if (!recommendedVisitsDecisions) {
            recommendedVisitsDecisions = {};
        }

        const filledVisitDecisionFor = [];

        _.each(recommendedVisitsDecisions, (decisionCode, performerId) => {
            if (decisionCode !== null) {
                filledVisitDecisionFor.push(Number(performerId));
            }
        });

        return _.difference(this.getRecommendedDoctors(), filledVisitDecisionFor);
    }

    /**
     * Возвразает код категории пункта плана лечения (id врача исполнителя)
     * @param planItem
     * @return {number}
     */
    static getItemCategoryCode(planItem) {
        return Number(planItem.performerId);
    }

    getItemName(itemCode) {
        const item = this.planItems[itemCode];
        return this.constructor.getItemName(item, this.proceduresCombinations, this.directions, this.defineDirections(item.performerId));
    }

    static getItemName(planItem, proceduresCombinations, directions = null, definedDirections = null) {
        let name = planItem.serviceName;

        /* --- добавляем названия комбинаций процедур --- */

        const combinationsApplyingMap = this.checkItemProceduresCombinations(planItem);

        if (Obj.length(combinationsApplyingMap)) {

            const combinationsDescription = Obj.map(combinationsApplyingMap, (isApplied, combinationId) => {
                const combination = proceduresCombinations[combinationId];

                if (isApplied) {
                    return combination.name;
                } else {
                    return combination.oppositeName ? combination.oppositeName : '';
                }
            });

            name += ' (' + combinationsDescription.join(', ') + ')';
        }


        /* --- добавляем названия направлений --- */

        if (!!directions && (planItem.directions.length > 1)) {
            let directionsQuantity = planItem.directionsQuantity;

            if (!directionsQuantity || Obj.empty(directionsQuantity)) {
                if (definedDirections && (definedDirections.length === 1)) {
                    directionsQuantity = {
                        [definedDirections[0]]: planItem.quantity
                    };
                }
            }

            let directionsDescriptions = [];

            Obj.forEach(directionsQuantity, (directionQuantity, directionCode) => {
                if (directionQuantity > 0) {
                    const directionName = directions[directionCode].name;

                    directionsDescriptions.push(`${directionName.toLowerCase()} ${directionQuantity} шт`);
                }
            });

            if (directionsDescriptions.length) {
                name += ' (' + directionsDescriptions.join(', ') + ')';
            }
        }

        return name;
    }

    static getDirectionsQuantityIncrement(newDirectionsQuantity, oldDirectionsQuantity) {
        if (!oldDirectionsQuantity) return newDirectionsQuantity;
        if (!newDirectionsQuantity) return null;

        const increment = {};

        Obj.forEach(newDirectionsQuantity, (newQuantity, directionCode) => {
            const oldQuantity = (directionCode in oldDirectionsQuantity) ? oldDirectionsQuantity[directionCode] : 0;
            if (newQuantity > oldQuantity) {
                increment[directionCode] = newQuantity - oldQuantity;
            }
        });

        return Obj.empty(increment) ? null : increment;
    }



    static getPerformers(planItems, doctorsMap, forceCurDoctorTab = true) {
        const otherDoctors = {};
        const currentDoctorId = window.user.doctorId;
        let hasGeneralItems = false;

        /* --- составляем карту "остальных", всех врачей, кроме текущего, у которых есть услуги в плане --- */

        planItems.forEach(item => {
            if (item.performerId) {
                otherDoctors[item.performerId] = item.performerId;
            } else {
                hasGeneralItems = true;
            }
        });

        if (forceCurDoctorTab) {
            delete otherDoctors[currentDoctorId];
        }

        /* --- добавляем ФИО врачей в otherDoctors --- */

        _.each(otherDoctors, doctorId => otherDoctors[doctorId] = Helper.getFio(doctorsMap[doctorId]));

        /* --- формируем полный отсортированный массив id врачей-исполнителей  --- */

        let performers = [];

        // если пользователь - врач, первым всегда идёт таб текущего врача
        // если установлен флаг forceCurDoctorTab этот таб отображается даже если у этого врача нет услуг в плане
        if (forceCurDoctorTab && currentDoctorId) {
            performers.push(currentDoctorId);
        }

        // следом идут табы остальных врачей, отсортированные по ФИО
        const otherPerformersIds = _.chain(otherDoctors)
            .keys()
            .map(performerId => Number(performerId))
            .sortBy(performerId => otherDoctors[performerId])
            .value();

        performers = performers.concat(otherPerformersIds);

        // последним идёт таб "Общее"
        if (hasGeneralItems) {
            performers.push(0);
        }

        return performers;
    }

    static getDoctorTotal(planItems, doctorId, isRecommendedPlan) {
        let paid = 0;
        let sum = 0;

        planItems.forEach(item => {
            if (item.performerId === doctorId) {
                paid += item.paid;
                sum += TreatmentPlan.getItemSum(item);
            }
        });


        const total = {
            sum: [
                {label: 'Итого', value: sum, isHighlighted: true},
            ]
        };

        if (!isRecommendedPlan) {
            total.sum.push(
                {label: 'Оплачено', value: paid},
                {label: 'Осталось оплатить', value: sum - paid}
            );
        }

        return total;
    }

    static getTotalSums(planItems, isRecommended) {
        let total = 0;
        let paid = 0;

        planItems.forEach(item => {
            total += TreatmentPlan.getItemSum(item);

            if (!isRecommended) {
                paid += item.paid;
            }
        });

        const totalSums = [
            {title: 'Итого по плану лечения', value: total}
        ];

        if (!isRecommended) {
            totalSums.push(
                {title: 'Оплачено', value: paid},
                {title: 'Осталось оплатить', value: total - paid}
            );
        }

        return totalSums;
    }

    listen(eventCode, handler) {
        if (!this.listeners[eventCode]) {
            this.listeners[eventCode] = [];
        }

        this.listeners[eventCode].push(handler);

        return this;
    }

    fireEvent(eventCode, ...args) {
        if (this.listeners[eventCode]) {
            this.listeners[eventCode].forEach(handler => handler(...args))
        }
    }
}
