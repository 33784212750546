import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const ArrowThinIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 13.21 24"} ref={ref}>
            <polygon points="11.98 24 0 11.65 12.04 0 13.21 1.21 2.38 11.68 13.19 22.83 11.98 24"/>
        </WrapIcon>
    );
});
