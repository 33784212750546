import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {commonLabelOption, commonSelectOption} from "../../data";
import Select from "../../../../../components-ui/Inputs/Select/Select";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";
import {IStateCashReturn} from "../../types";
import {useSelector} from "react-redux";

interface Props {
    state: IStateCashReturn
    setState: Dispatch<SetStateAction<IStateCashReturn>>
}

const PaymentMethod = ({state, setState}: Props) => {

    const paymentMethodOptions = useSelector(state => state.common.paymentMethodOptions);

    const handlePaymentMethod = useCallback((selectedPaymentMethod: OptionSelect) => {
        setState(prevState => ({...prevState, selectedPaymentMethod}))
    }, [setState])
    return (
        <Label labelText="Способ оплаты" {...commonLabelOption} >
            <Select
                {...commonSelectOption}
                options={paymentMethodOptions}
                value={state.selectedPaymentMethod}
                onChange={handlePaymentMethod}
            />
        </Label>
    );
};

export default PaymentMethod;
