import {Stage} from "../../../containers/TreatmentPlan/types";
import _ from "underscore";
import {LinkTable, RowDate, RowName} from "../styledStages";
import SVG from "../../../common/helpers/SVG";
import * as React from "react";
import {useEffect, useState} from "react";
import useDebounce from "../../../common/hooks/useDebounce";
import TextField from "../../../components-ui/Inputs/TextField/TextField";


interface PName {
    name: string,
    edit: boolean,
    handleLink: any,
    active: boolean,
    setStages: any,
    stages: Stage[],
    index: number
}

export const Name = ({name, active, edit, handleLink, stages, setStages, index}: PName) => {

    const [value, setValue] = useState(name);

    const debouncedValue = useDebounce(value, 500);

    useEffect(() => {
        const newStages = _.clone(stages);
        newStages[index].name = debouncedValue;
        setStages(newStages);
    }, [debouncedValue])

    let content;
    if (edit) {
        content = <TextField size="sm" widthInput={340} value={value} onChange={setValue}/>;
    } else {
        content = (
            <LinkTable active={active} onClick={handleLink}>
                <SVG name="triangle"/>
                {name}
            </LinkTable>
        )
    }
    return (
        <RowName children={content}/>
    )
}

interface PDuration {
    stage: Stage,
    edit: boolean,
    setFocus: any,
    setStages: any,
    stages: Stage[],
    index: number,
    closeEditForm: () => void
}

export const Duration = ({stage, edit, setFocus, setStages, stages, index, closeEditForm}: PDuration) => {

    const [value, setValue] = useState<string | any>(stage.duration);

    const debouncedValue = useDebounce(value, 500);

    useEffect(() => {
        const newStages = _.clone(stages);
        newStages[index].duration = debouncedValue;
        setStages(newStages);
    }, [debouncedValue])

    let content;

    if (edit) {
        if (stage.durationVariants === null) {
            content = (
                <TextField size="sm"
                           widthInput={180}
                           value={value}
                           onChange={setValue}/>
            )
        } else {
            const dataAutocomplete = stage.durationVariants.map(mapDurationVariants);
            content = (
                <>
                    <TextField size="sm"
                               widthInput={260}
                               value={value}
                               dataAutocomplete={dataAutocomplete}
                               setFocus={setFocus}
                               onClickItemAutocomplete={closeEditForm}
                               onChange={setValue}/>
                </>
            )
        }
    } else {
        content = stage.duration;
    }

    return <RowDate children={content}/>
}

const mapDurationVariants = (item: string) => {
    return {
        code: item,
        value: item
    }
};

