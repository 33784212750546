import Helper from "../../../../../common/helpers/main";
import {useMemo} from "react";
import {PlannedCall} from "../../../types/common";
import {useDateUpdate} from "../../../../../common/hooks";

interface Props {
    plannedCall: PlannedCall
}

const useDeadlineSimpleTask = ({plannedCall}: Props) => {

    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);

    return useMemo(() => {
        let isDeadline = false;
        plannedCall.tasks.forEach(task => {
            if (!!task.deadline) {
                const deadlineDate = Helper.Date.formatDate(task.deadline as any)
                if (currentDate >= deadlineDate) {
                    isDeadline = true;
                }
            }
        })
        return isDeadline
    }, [plannedCall, currentDate])
}

export default useDeadlineSimpleTask
