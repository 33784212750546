import {useCallback, useEffect, useState} from 'react';
import DateHelper from "../../../common/helpers/date-helper";
import {GetPaymentsCheckFormParams, GetPaymentsCheckFormResult, IPaymentsFilters} from '../types';
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import {useLoader} from "../../../components/Errors/hooks/useLoader";
import ServerCommand from "../../../common/server/server-command";
import {useOptionsBranchesUser} from "../../../common/hooks/useOptionsBranchesUser";
import useMinimizeParams from "./useMinimizeParams";
import useNeedConfirmPayment from "./useNeedConfirmPayment";
import moment from "moment";



const usePayments = () => {

    const [paymentsResponse, setPaymentsResponse] = useState<GetPaymentsCheckFormResult>({
        startSum: {},
        endSum: {},
        inflow: [],
        outflow: [],
        patients: {},
        payments: [],
        paymentsCheckTime: '',
        paymentsChecked: false,
        servicesGroups: []
    })

    const [filters, setFilters] = useState<IPaymentsFilters>({
        service: '',
        paymentMethod: {value: 0, label: 'Все способы оплаты'},
        doctor: {value: 0, label: 'Все врачи', color: '#dedede'},
        patient: ''
    })

    const {needConfirmPayment} = useNeedConfirmPayment(paymentsResponse)

    const {isMinimized} = useMinimizeParams({needConfirmPayment})

    const optionsBranches = useOptionsBranchesUser()


    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [selectedBranch, setSelectedBranch] = useState<OptionSelect>(optionsBranches[0])

    const [loader, setLoader] = useLoader(true)


    const params = {
            startDate: DateHelper.formatDateForServer(startDate),
            endDate: DateHelper.formatDateForServer(endDate),
            branchId: selectedBranch.value
        } as GetPaymentsCheckFormParams


    const refreshDataFromServer = useCallback(() => {
        ServerCommand
            .get('cash/report', params,
                ).then((response: GetPaymentsCheckFormResult) => {
            setPaymentsResponse(response)
        })
    }, [startDate, endDate, selectedBranch])

    useEffect(() => {
        setLoader(true)
        ServerCommand
            .get('cash/report', params,
                ).then((response: GetPaymentsCheckFormResult) => {
            setPaymentsResponse(response)
            setLoader(false)
        })
    }, [startDate, endDate, selectedBranch, isMinimized])

    const handlePaymentChecked = (paymentsChecked: boolean) => {
        setPaymentsResponse(prevState => {
            return {...prevState, paymentsChecked}
        })
    }

    return {
        optionsBranches,
        loader,
        selectedBranch,
        setSelectedBranch,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        params,
        paymentsResponse,
        setLoader,
        refreshDataFromServer,
        needConfirmPayment,
        handlePaymentChecked,
        filters,
        setFilters
    }
};

export default usePayments;
