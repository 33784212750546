import React from 'react';
import {GetPaymentsCheckFormResult, IPaymentsFilters} from '../../types';
import Table from '../../../../components-ui/DataDisplay/Table';
import useTableFormatter from './hooks/useTableFormatter';
import Box from '../../../../components-ui/Layout/Box';
import Loader from '../../../../components-ui/Feedback/Loader/Loader';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import PriceHelper from '../../../../common/helpers/price-helper';
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import {WIDTH_TABLE_COLUMNS} from "./common";
import {DEFAULT_PADDING_HEADER_CELLS} from "../../../../components-ui/DataDisplay/Table/common";

interface Props {
    data: GetPaymentsCheckFormResult,
    refreshDataFromServer: () => void,
    loader: boolean
    selectedBranch: OptionSelect
    filters: IPaymentsFilters
}

const TablePayments = ({data, refreshDataFromServer, loader, selectedBranch, filters}: Props) => {

    const {inflowRows, outflowRows, paymentRows, paymentColumns} = useTableFormatter({data, selectedBranch, filters, refreshDataFromServer});

    let inflowTotal = {
        main: 0,
        additional: 0
    };
    inflowRows
        .map((day) => {
            {
                day.payment.map((payment) => {
                    inflowTotal.main += payment.cashboxMain
                    inflowTotal.additional += payment.cashboxAdditional
                })
            }
        })

    let outflowTotal = {
        main: 0,
        additional: 0
    };
    outflowRows
        .map((day) => {
            {
                day.payment.map((payment) => {
                    outflowTotal.main += payment.cashboxMain
                    outflowTotal.additional += payment.cashboxAdditional
                })
            }
        })

    return (
        <Box margin='24px 24px 0 24px'>
            <Loader visible={loader} width={30} height={30} />

            <Box display='flex' justifyContent='space-between'>
                <Box>
                    Остаток на начало периода
                </Box>
                <Box display='flex'>
                    <Box width={WIDTH_TABLE_COLUMNS.sum} padding={DEFAULT_PADDING_HEADER_CELLS}>
                        <Typography variant="small" fontWeight='bold'>Основная</Typography>
                        <Typography variant="small" width={WIDTH_TABLE_COLUMNS.sum}>{PriceHelper.format(data.startSum?.MAIN_BOX_OFFICE)}</Typography>
                    </Box>
                    <Box width={WIDTH_TABLE_COLUMNS.sum} padding={DEFAULT_PADDING_HEADER_CELLS}>
                        <Typography variant="small" fontWeight='bold'>Дополнительная</Typography>
                        <Typography variant="small" width={WIDTH_TABLE_COLUMNS.sum}>{PriceHelper.format(data.startSum?.ADDITIONAL_BOX_OFFICE)}</Typography>
                    </Box>
                </Box>
            </Box>

            <Box display='flex' justifyContent='space-between'>
                <Box>
                    Остаток на конец периода
                </Box>
                <Box display='flex'>
                    <Box width={WIDTH_TABLE_COLUMNS.sum} padding={DEFAULT_PADDING_HEADER_CELLS}>
                        <Typography variant="small" fontWeight='bold'>Основная</Typography>
                        <Typography variant="small" width={WIDTH_TABLE_COLUMNS.sum}>{PriceHelper.format(data.endSum?.MAIN_BOX_OFFICE)}</Typography>
                    </Box>
                    <Box width={WIDTH_TABLE_COLUMNS.sum} padding={DEFAULT_PADDING_HEADER_CELLS}>
                        <Typography variant="small" fontWeight='bold'>Дополнительная</Typography>
                        <Typography variant="small" width={WIDTH_TABLE_COLUMNS.sum}>{PriceHelper.format(data.endSum?.ADDITIONAL_BOX_OFFICE)}</Typography>
                    </Box>
                </Box>
            </Box>

            <Typography as="h4" variant="min-title" fontWeight="bold" margin="30px 0">Приход денежных средств</Typography>
            <Table customRowsCells={true}
                   maxHeight='450px'
                   columns={paymentColumns}
                   rows={inflowRows}
                   variantTable='outline'
                   isHovering={false}
                   fontSizeRow='14px'
                   total={!!inflowRows.length ?
                       [{
                           fieldName: 'date',
                           labelWidth: '0',
                           totals: [{label: '', value: 'Итого:', isHighlighted: true}],
                       }, {
                           fieldName: 'cashboxMain',
                           labelWidth: '0',
                           totals: [{label: '', value: PriceHelper.format(inflowTotal.main), isHighlighted: true}],
                       }, {
                           fieldName: 'cashboxAdditional',
                           labelWidth: '0',
                           totals: [{label: '', value: PriceHelper.format(inflowTotal.additional), isHighlighted: true}],
                       }] :
                       undefined
                   }
            />

            <Typography as="h4" variant="min-title" fontWeight="bold" margin="30px 0">Расход денежных средств</Typography>
            <Table customRowsCells={true}
                   maxHeight='450px'
                   columns={paymentColumns}
                   rows={outflowRows}
                   variantTable='outline'
                   isHovering={false}
                   fontSizeRow='14px'
                   total={!!outflowRows.length ?
                       [{
                           fieldName: 'date',
                           labelWidth: '0',
                           totals: [{label: '', value: 'Итого:', isHighlighted: true}],
                       }, {
                           fieldName: 'cashboxMain',
                           labelWidth: '0',
                           totals: [{label: '', value: PriceHelper.format(outflowTotal.main), isHighlighted: true}],
                       }, {
                           fieldName: 'cashboxAdditional',
                           labelWidth: '0',
                           totals: [{label: '', value: PriceHelper.format(outflowTotal.additional), isHighlighted: true}],
                       }] :
                       undefined
                   }
            />
        </Box>

    );
};

export default TablePayments;
