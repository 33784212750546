import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const SmallScreenIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M24,6V8H16V0h2V6ZM0,6H6V0H8V8H0ZM24,16v2H18v6H16V16ZM0,16H8v8H6V18H0Z"/>
        </WrapIcon>
    );
});
