import {Dispatch, SetStateAction, useEffect, useMemo} from "react";
import {PlannedCall} from "../../types/common";
import {useSlideModal} from "../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {useSelector} from "react-redux";
import {StateCallCenter} from "../../types/components";

interface Props {
    state: StateCallCenter
    setState: Dispatch<SetStateAction<StateCallCenter>>
    marketingPlannedCalls: PlannedCall[]
}

const useCallRecovery = ({state, marketingPlannedCalls, setState}: Props) => {
    const {calls} = state;
    const {maximizeSlideModal, setIsClose} = useSlideModal();
    const user = useSelector(state => state.user);
    const userCalls = useMemo(() => {
        return calls.filter(call => call.userId === user.id)
    }, [JSON.stringify(calls)])
    useEffect(() => {
        if (!!userCalls.length) {
            const findCall = userCalls.find(call => !!call.answerTime && call.userId === user.id)
            if (!!findCall) {
                const findPlannedCall = marketingPlannedCalls.find(plannedCall => plannedCall.phone === findCall.contactPhone);
                if (!!findPlannedCall && (!state.detail || state.detail.phone !== findPlannedCall.phone)) {
                    if (findCall.incoming) {
                        setState(prevState => ({...prevState, detail: findPlannedCall}))
                    } else {
                        if (findPlannedCall.tasks.some(task => task.type === 'incoming_call' || task.type === 'missed_call')) {
                            setState(prevState => ({...prevState, detail: findPlannedCall}))
                        }
                    }

                    maximizeSlideModal()

                    if (!findCall.resultFilled && findPlannedCall.canSaveResult) {
                        setIsClose(false)
                    }
                }
            }
        }
    }, [JSON.stringify(userCalls)])
}

export default useCallRecovery
