import React from 'react';
import Helper from "../../../common/helpers/main";
import {useNewVisitForm} from "../NewVisitForm";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";

export default function Title() {
    const {props} = useNewVisitForm()
    return (
        <Typography variant="title" margin="0 0 30px">
            Запись пациента на {Helper.Date.formatDate(props.date, 'DD MMMM (dddd)')}, {props.timeStart}
        </Typography>
    )
}
