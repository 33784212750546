import * as React from 'react'
import {useCallback, useEffect, useRef, useState} from 'react'
import {Stage, StagedPlanItem} from "../../../containers/TreatmentPlan/types";
import {Row, RowButton, RowItem, RowNumber, RowSpan} from "../styledStages";
import ItemDescription from "./ItemDescription";
import {PlanItemsStatuses} from "../../../containers/TreatmentPlansConstructor/types";
import {ObjServicesGroup} from "../typesStages";
import {DropTargetMonitor, useDrag, useDrop, XYCoord} from "react-dnd";
import StagedPlan from "../../../containers/TreatmentPlan/StagedPlan";
import Buttons from "./Buttons";
import {Duration, Name} from "./StageContent";
import useOutsideClick from "../../../common/hooks/useOutsideClick";
import _ from "underscore";
import {useDispatch} from "react-redux";
import {setIsScrollArea} from '../../../redux/actions/commonActions';

interface IItemStages {
    stage: Stage,
    stages: Stage[],
    index: number,
    planItems: StagedPlanItem[],
    itemsStatuses: PlanItemsStatuses | null,
    servicesGroups: ObjServicesGroup,
    moveCard: (dragIndex: number, hoverIndex: number) => void,
    stagedPlan: StagedPlan,
    setStages: any,
    requiredDurationStage: boolean
}

export interface DragStage {
    index?: number,
    name?: string,
    type: string,
    itemCode?: string,
    typeDrag: "drag" | "service" | "serviceGroup" | "doctor",
    filter: {
        [key: string]: any
    },
    doctorIndex?: number
}


export default function ItemStages({stage, stages, index, planItems, itemsStatuses, servicesGroups, moveCard, stagedPlan, setStages, requiredDurationStage}: IItemStages) {
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const refWrap = useRef<HTMLDivElement>(null);
    const [hover, setHover] = useState(false);
    const [active, setActive] = useState(false);
    const [edit, setEdit] = useState(false);
    const [focus, setFocus] = useState(false);

    const valid = stage.name.length && (requiredDurationStage ? stage.duration.length : true);

    useEffect(() => {
        if (!valid) setEdit(true);
    }, []);

    useEffect(() => {
        if (planItems.some(item => !item.isStageConfirmed)) setActive(true);
    }, [planItems.length])

    const closeEditForm = () => {
        const newStage = _.clone(stage);
        newStage.name = newStage.name.trim();
        newStage.duration = newStage.duration.trim();
        if (edit && (newStage.name.length && (requiredDurationStage ? newStage.duration.length : true))) setEdit(false);
    }

    useOutsideClick(refWrap, closeEditForm)

    const handleLink = (e: React.MouseEvent) => {
        e.preventDefault();
        setActive(!active)
    };

    let numberRow = index + 1;

    const [, drop] = useDrop({
        accept: 'card',
        hover(elem: DragStage, monitor: DropTargetMonitor) {
            if (!refWrap.current) return;
            if (elem.typeDrag === 'drag') {
                const dragIndex = elem.index as number;
                const hoverIndex = index as number;
                // Не заменяем предметы собой
                if (dragIndex === hoverIndex) return;
                // Определяем прямоугольник на экране
                const hoverBoundingRect = refWrap.current?.getBoundingClientRect();
                // Получаем вертикальную середину
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                // Определяем положение мыши
                const clientOffset = monitor.getClientOffset();
                // Выводим пиксели наверх
                const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
                // Выполнять перемещение только тогда, когда мышь пересекла половину высоты элемента
                // При перетаскивании вниз двигайтесь только тогда, когда курсор находится ниже 50%
                // При перетаскивании вверх двигайтесь только тогда, когда курсор находится выше 50%
                //
                // Перетаскивание вниз
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
                // Перетаскивание вверх
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
                // Время на самом деле выполнить действие
                moveCard(dragIndex, hoverIndex);
                // Примечание: здесь мы изменяем элемент монитора!
                // Обычно лучше избегать мутаций,
                // но здесь полезно для повышения производительности,
                // чтобы избежать дорогостоящего поиска по индексу.
                elem.index = hoverIndex;
            }

        },
        drop(elem) {
            if (elem.index === index) return;
            switch (elem.typeDrag) {
                case "service":
                    const itemCode = elem.itemCode + '' as string;
                    stagedPlan.setItemStage(itemCode, stage.id)
                    break;
                case "serviceGroup":
                case "doctor":
                    stagedPlan.setItemsStage(elem.filter, stage.id)
                    break;
                default:
                    break;
            }
            return {moved: true};
        }
    });

    const [{isDragging}, drag] = useDrag({
        item: {
            type: 'card',
            typeDrag: 'drag',
            name: stage.name,
            index
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        begin: () => {
            setTimeout(() => {
                dispatch(setIsScrollArea(true));
            }, 500)
        },
        end: () => {
            dispatch(setIsScrollArea(false));
        }
    });

    let newEdit = edit;
    if (!valid) newEdit = true;

    const opacity = isDragging ? 0 : 1;
    drag(ref);
    const propsRowItem = {
        onMouseLeave: () => setHover(false),
        onMouseEnter: () => setHover(true),
        isFocus: focus,
        isHover: hover || newEdit,
        edit: !newEdit,
        opacity
    } as any;

    if (!newEdit) propsRowItem.ref = ref;

    return (
        <RowSpan ref={drop}>
            <span ref={refWrap}>
                <Row>
                    <RowNumber children={numberRow}/>
                    <RowItem {...propsRowItem}>
                        <Name name={stage.name}
                              index={index}
                              setStages={setStages}
                              stages={stages}
                              edit={newEdit}
                              handleLink={handleLink}
                              active={active}/>
                        <Duration stage={stage}
                                  edit={newEdit}
                                  setStages={setStages}
                                  stages={stages}
                                  index={index}
                                  closeEditForm={closeEditForm}
                                  setFocus={setFocus}/>
                        <RowButton>
                            <Buttons hover={hover}
                                     edit={newEdit}
                                     setEdit={setEdit}
                                     setStages={setStages}
                                     index={index}
                                     stages={stages}
                                     disabledDeleted={!!planItems.length}
                            />
                        </RowButton>
                    </RowItem>
                </Row>
                <ItemDescription active={active}
                                 planItems={planItems}
                                 itemsStatuses={itemsStatuses}
                                 servicesGroups={servicesGroups}
                                 performers={stage.performers}
                                 stageId={stage.id}
                                 stageIndex={index}
                                 stages={stages}
                                 setStages={setStages}/>
             </span>
        </RowSpan>

    )
}


