import {useMemo} from "react";
import Helper from "../../../common/helpers/main";
import {PlannedCall, TaskCallCenter} from "../types/common";
import _ from 'lodash';
import sortTasks from "../../../containers/Tasks/utils/sortTasks";
import useDebounce from "../../../common/hooks/useDebounce";
import {StateCallCenter} from "../types/components";
import {InfoCallCenter} from "../types/hooks";
import {useDateUpdate} from "../../../common/hooks";
import useAppendItems from "../../../containers/Tasks/hooks/useAppendItems";
import {TasksInfo} from "../../../containers/Tasks";
import useBranchesPlannedCalls from "./useCalls/useBranchesPlannedCalls";

interface Props {
    state: StateCallCenter
    marketingPlannedCalls: PlannedCall[]
    infoCallCenter: InfoCallCenter
    refScrollList: any
    tasksInfo: TasksInfo
}

interface PreparedCall extends PlannedCall {
    priority: number
    isCritical: boolean
    isPostponed: boolean
    outgoing: boolean
}


const useFilterPlannedCalls = ({state, marketingPlannedCalls, infoCallCenter, refScrollList, tasksInfo}: Props) => {
    const {searchFilter, calls, raisedCalls, selectedFilter} = state;
    const searchDebounce = useDebounce(searchFilter, 300)
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);

    const branchesPlannedCalls = useBranchesPlannedCalls({plannedCalls: marketingPlannedCalls, state})

    const sortPlannedCalls = useMemo(() => {
        let res = _.cloneDeep(branchesPlannedCalls) as PreparedCall[];
        if (!!branchesPlannedCalls.length) {
            res.forEach(plannedCall => {
                const maxPriorityTask = _.maxBy(plannedCall.tasks, task => {
                    return infoCallCenter.types[task.type].priority
                }) as TaskCallCenter;
                plannedCall.priority = infoCallCenter.types[maxPriorityTask.type].priority as number;

                plannedCall.isCritical = false;
                const filterNullDeadLine = _.clone(plannedCall.tasks).filter(task => task.deadline !== null);
                if (!!filterNullDeadLine.length) {
                    plannedCall.isCritical = filterNullDeadLine.some(task => new Date(task.deadline as any).getTime() <= new Date(currentDate).getTime())
                }

                plannedCall.isPostponed = plannedCall.tasks.some(task => task.postponedTo !== null);

                plannedCall.outgoing = false;
                const findPhoneCall = calls.find(call => call.contactPhone === plannedCall.phone);
                if (!!findPhoneCall && !findPhoneCall.incoming) plannedCall.outgoing = true;
                if (!plannedCall.outgoing) {
                    if (!plannedCall.tasks[0].postponedTo && plannedCall.canSaveResult) {
                        plannedCall.outgoing = raisedCalls.some(call => call.contactPhone === plannedCall.phone)
                    }
                }

                plannedCall.tasks = sortTasks(plannedCall.tasks, currentDate, infoCallCenter.types) as TaskCallCenter[]
            })
            res = res.sort((callA, callB) => callB.priority - callA.priority)
                .sort((callA, callB) => +callB.isCritical - +callA.isCritical)
                .sort((callA, callB) => +callA.isPostponed - +callB.isPostponed)
                .sort((callA, callB) => +callB.outgoing - +callA.outgoing)
                .map(call => {
                    return _.omit(call, 'priority', 'isCritical', 'isPostponed') as PlannedCall
                }) as any
        }
        return res
    }, [JSON.stringify(branchesPlannedCalls), JSON.stringify(calls)])

    const filterItems = useMemo(() => {
        let res = sortPlannedCalls as PlannedCall[]
        if (selectedFilter !== 'all') {
            res = res.filter(plannedCall => plannedCall.tasks.some(task => task.type === selectedFilter))
        }
        if (!!searchDebounce) {
            res = res.filter(plannedCall => plannedCall.tasks.some(task => {
                let fullName = Helper.getFullName(task.targetPerson);
                if (!fullName) {
                    fullName = 'имя не указано'
                }
                return fullName.toLowerCase().indexOf(searchDebounce.toLowerCase()) !== -1;
            }))
        }
        return res.map(plannedCall => {
            return {
                ...plannedCall,
                tasks: plannedCall.tasks.filter(task => !!tasksInfo[task.type])
            }
        }).filter(plannedCall => !!plannedCall.tasks.length)
    }, [JSON.stringify(sortPlannedCalls), selectedFilter, searchDebounce, JSON.stringify(calls)])

    return useAppendItems({refScrollList, filterItems}) as [PlannedCall[], any]
}

export default useFilterPlannedCalls
