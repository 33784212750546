import React from 'react';
import usePortal from 'react-useportal';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../../../components-ui/Layout/Box';
import Icon from '../../../../../components-ui/DataDisplay/Icon';
import Button from '../../../../../components-ui/Inputs/Button/Button';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import HighlightedCellsModal from './modal';

const HighlightedCellsPopover = ({highlightedCells, setHighlightedCells}) => {

    const {showModal} = useDialogsManager();

    const {Portal} = usePortal();

    const suffixes = {
        1: 'ячейка',
        4: 'ячейки',
        1000: 'ячеек',
    };

    const word = () => {
        return Object.keys(suffixes).map(num => {
            if (highlightedCells.arrCells.length <= num) {
                return suffixes[num];
            }
        }).filter(word => !!word)[0];
    };

    const removeHighlitedCells = () => {
        setHighlightedCells({chairId: 0, date: '0', arrCells: []});
    };

    return (
        <Portal>
            <Box
                position='absolute'
                minWidth='250px'
                display='flex'
                flexDirection='column'
                boxShadow='defaultActive'
                overflow='hidden'
                borderRadius='4px'
                backgroundColor='white'
                gap='6px'
                positionOffsets={{left: '99px', bottom: '35px'}}>
                <Box padding='10px 8px 4px'>
                    <Button width='100%' variant='default'
                            onClick={() => showModal(
                                <HighlightedCellsModal
                                    highlightedCells={highlightedCells}
                                    removeHighlitedCells={removeHighlitedCells} />,
                            )}>Изменить
                        врача</Button>
                </Box>
                <Box display='flex'
                     alignItems='center'
                     justifyContent='space-between'
                     backgroundColor='info'
                     minWidth='250px'
                     padding='10px'>
                    <Typography
                        color='white'>Выбран{highlightedCells.arrCells.length === 1 ? 'а' : 'о'} {highlightedCells.arrCells.length} {word()}</Typography>
                    <Button isIcon
                            variant='danger'
                            size='md'
                            onClick={removeHighlitedCells}>
                        <Icon name='delete' color='white' width='14px' height='14px' />
                    </Button>
                </Box>
            </Box>

        </Portal>
    );
};

export default HighlightedCellsPopover;
