import ServerCommand from "../../../common/server/server-command";
import DateHelper from "../../../common/helpers/date-helper";

export function runCallTasksCreation(careDate: Date, surveyInviteDate: Date, leadPlanDate: Date) {
    ServerCommand.post('call-center/task/run-creation', {
        careDate: DateHelper.formatDateForServer(careDate),
        surveyInviteDate: DateHelper.formatDateForServer(surveyInviteDate),
        leadPlanDate: DateHelper.formatDateForServer(leadPlanDate)
    });
}

export function runManagerTasksCreation() {
    ServerCommand.post('manager/task/run-creation')
}
