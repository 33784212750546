export const WIDTH_TABLE_COLUMNS = {
    category: '260px',
    salary: '7.30vw',
    balance: '7.30vw',
    premium: '122px',
    toBePaid: '122px',
    paid: '170px'
}

export const WIDTH_INNER_TABLE_COLUMNS = {
    working_days_rate: '240px',
    working_days_sum: '240px',
    working_hours_rate: '160px',
    working_hours_count: '180px',
    fixed: '160px',
    services: '160px',
    services_rate_percent: '200px',
    services_sum: '140px',
    services_costs: '140px',
    servicesName: '250px',
    doctor_recommendations_rate: '240px',
    doctor_recommendations_sum: '260px',
    xray_pictures_type: '160px',
    xray_pictures_rate: '200px',
    xray_pictures_count: '180px',
    xray_pictures_sum: '180px',
    receipts_rate: '200px',
    receipts_sum: '200px'
}
