import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import Select from "../../../../../components-ui/Inputs/Select/Select";

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry', isDisabled: true },
    { value: 'vanilla', label: 'Vanilla sasdaas sdasds, sadsasd, sdasd,. sads' }
]

const optionsColored = [
    { value: 'colorFFF', label: 'ColorFFF', color: '#fff' },
    { value: 'color1', label: 'Color1', color: '#d2771d' },
    { value: 'color2', label: 'Color2', color: '#3e8322' },
    { value: 'color3', label: 'Color3', color: '#1635a4' }
]

const multiSelect = [
    { value: 'colorFFF', label: 'ColorFFF' },
    { value: 'color1', label: 'Color1' },
    { value: 'color2', label: 'Color2' },
    { value: 'color3', label: 'Color3' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry', isDisabled: true },
    { value: 'vanilla', label: 'Vanilla sasdaas sdasds, sadsasd, sdasd,. sads' }
]

const linkSelect = [
    { value: 'colorFFF', label: 'ColorFFF' },
    { value: 'color1', label: 'Color1' },
    { value: 'color2', label: 'Color2' },
    { value: 'color3', label: 'Color3' },
]

export default function SelectPage() {
    const [select, setSelect] = useState([options[2]]);
    const [multi, setMulti] = useState(null);
    const [link, setLink] = useState([linkSelect[0]])
    return (
        <>
            <h1>SelectPage</h1>
            <WrapContent>
                <h4>CheckmarksSelect</h4>
                <div>
                    <Select options={multiSelect} selected={multi} onChange={setMulti} widthSelect="260px"
                            placeholder="Выбрать доктора" isCheckmarks={true} closeMenuOnSelect={false}/>
                    <br/>
                    <code>
                        {`<Select options={multiSelect} selected={${JSON.stringify(multi)}} onChange={setMulti} widthSelect="260px" placeholder="Выбрать доктора" isMulti={true} closeMenuOnSelect={false}/>`}
                    </code>
                </div>
                <br/>
                <h4>LinkSelect</h4>
                <div>
                    <Select value={link} onChange={setLink} options={linkSelect} variant="string"/>
                    <br/>
                    <code>
                        {`<Select isDisabled={true}/>`}
                    </code>
                </div>
                <h4>LinkSelect</h4>
                <div>
                    <Select value={link} onChange={setLink} options={linkSelect} variant="link"/>
                    <br/>
                    <code>
                        {`<Select value={link} onChange={setLink} options={linkSelect} variant="link"/>`}
                    </code>
                </div>

                <h4>Disabled</h4>
                <div>
                    <Select isDisabled={true}/>
                    <br/>
                    <code>
                        {`<Select isDisabled={true}/>`}
                    </code>
                </div>
                <h4>No Options</h4>
                <div>
                    <Select />
                    <br/>
                    <code>
                        {`<Select />`}
                    </code>
                </div>

                <h4>Default</h4>
                <div>
                    <Select options={options} value={select} onChange={setSelect} />
                    <br/>
                    <code>
                        {`<Select options={options} value={select} onChange={setSelect} />`}
                    </code>
                </div>

                <h4>Default Label</h4>
                <div>
                    <Select options={options} value={select} onChange={setSelect} label="Зуб: " />
                    <br/>
                    <code>
                        {`<Select options={options} value={select} onChange={setSelect} label="Зуб: " />`}
                    </code>
                </div>

                <h4>Colored Select</h4>
                <div>
                    <Select options={optionsColored} variant="colored" placeholder="Выбрать доктора"/>
                    <br/>
                    <code>
                        {`<Select options={optionsColored} variant="colored" placeholder="Выбрать доктора"/>`}
                    </code>
                </div>
                <br/>

                <h4>Color Select and Size Select</h4>
                <div>
                    <Select options={optionsColored} widthSelect="260px" variant="colored" placeholder="Выбрать доктора" colorSelect="white" sizeSelect="sm"/>
                    <br/>
                    <code>
                        {`<Select options={optionsColored}  widthSelect="260px" variant="colored" placeholder="Выбрать доктора" colorSelect="white" sizeSelect="sm" />`}
                    </code>
                </div>
                <br/>

                <h4>MultiSelect</h4>
                <div>
                    <Select options={multiSelect} selected={multi} onChange={setMulti} widthSelect="260px" placeholder="Выбрать доктора" isMulti={true} closeMenuOnSelect={false}/>
                    <br/>
                    <code>
                        {`<Select options={multiSelect} selected={${JSON.stringify(multi)}} onChange={setMulti} widthSelect="260px" placeholder="Выбрать доктора" isMulti={true} closeMenuOnSelect={false}/>`}
                    </code>
                </div>
                <br/>
            </WrapContent>
        </>
    )
}
