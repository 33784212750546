import React, {useState} from "react";
import TextField from "../../../../../../components-ui/Inputs/TextField/TextField";
import {Label} from "../../../../../../components-ui/DataDisplay/Label";

export default function AlignLabel() {
    const [value, setValue] = useState('');
    return (
        <>
            <div>
                <Label labelText="left label" isRequired={true} minWidthLabel={'150px'} >
                    <TextField value={value}  onChange={(val:string) => setValue(val)}   placeholder="left label 150"/>
                </Label>
                <pre>
                    {
                        `<Label labelText="left label" isRequired={true} minWidthLabel={'150px'} >
                             <TextField value={value}  onChange={(val) => setValue(val)}   placeholder="left label 150"/>
                        </Label>`
                    }
                </pre>
            </div>
            <div>
                <Label labelText="right label" align="right">
                    <TextField value={value} onChange={(val:string) => setValue(val)} widthInput={320} autoFocus={true}/>
                </Label>

                <pre>
                    {
                        `<Label labelText="right label" align="right">
                            <TextField value={value} onChange={(val) => setValue(val)}  widthInput={320} autoFocus={true}/>
                        </Label>`
                    }
                </pre>
            </div>
            <div>
                <Label labelText="topLeft label" align="topLeft">
                    <TextField value={value} onChange={(val:string) => setValue(val)}  widthInput={360} isError={true}/>
                </Label>

                <pre>
                    {
                        `<Label labelText="topLeft label" align="topLeft">
                            <TextField value={value} onChange={(val) => setValue(val)}  widthInput={360} isError={true}/>
                        </Label>`
                    }
                </pre>
            </div>
            <div>
                <Label labelText="top label" align="top">
                    <TextField value={value} onChange={(val:string) => setValue(val)}/>
                </Label>

                <pre>
                    {
                        `<Label labelText="top label" align="top">
                            <TextField value={value} onChange={(val) => setValue(val)}/>
                        </Label>`
                    }
                </pre>
            </div>
        </>
    )
}
