import TreatmentPlan from "./TreatmentPlan";
import Arr from "../../common/helpers/Arr";
import _ from 'underscore';
import {ExpandedPlanItem, PlanItem} from "./plan-types";
import Helper from "../../common/helpers/main";
import StageBuilder from "./StageBuilder";
import {Stage, StagedPlanItem} from "./types";
import PlanFiller from "./PlanFiller";
import {ServiceDirection} from "../../common/types/ServicesDirections";

export default class StagedPlan {
    protected readonly plan: TreatmentPlan;

    protected stages: Stage[];

    protected readonly setStages: SetStages;

    protected readonly servicesGroupsCategoriesMap: ServicesGroupsCategoriesMap;

    protected readonly planFiller: PlanFiller;

    protected readonly directions: ServiceDirection[];

    protected stagedItems?: StagedItemsMap;

    constructor(plan: TreatmentPlan, stages: Stage[], setStages: SetStages, servicesGroupsCategoriesMap: ServicesGroupsCategoriesMap, planFiller: PlanFiller, directions: ServiceDirection[]) {
        this.plan = plan;
        this.stages = stages;
        this.setStages = setStages;
        this.servicesGroupsCategoriesMap = servicesGroupsCategoriesMap;
        this.planFiller = planFiller;
        this.directions = directions;
    }

    /**
     * Возвращает пункты плана лечения, подготовленные для вывода в блоке редактирования этапов - разбитые по
     * направлениям и со всей необходимой информацией (см. тип StagedPlanItem)
     * @param sort - сортировать пукнты плана лечения (по врачу)
     *
     * @return StagedPlanItem[]
     */
    public getItems = (sort: boolean = true): StagedPlanItem[] => {
        const items = _.values(this.getItemsMap());

        if (sort) {
            // сортировка по врачам в порядке, заданном в stage.performers

            const stagesPerformersOrder: StagesPerformersOrder = {};

            this.stages.forEach(stage => {
                stagesPerformersOrder[stage.id] = Arr.flip(stage.performers);
            });

            items.sort((itemA, itemB) => {
                return stagesPerformersOrder[itemA.stageId][itemA.performerId] - stagesPerformersOrder[itemB.stageId][itemB.performerId]
            });
        }

        return items;
    }

    protected getItemsMap(): StagedItemsMap {
        if (typeof this.stagedItems !== 'undefined') return this.stagedItems;

        const stagedItemsMap: StagedItemsMap = {};

        Arr.squeeze(this.plan.expandItems(), StagedPlan.getItemCode, {
            handler: (expandedItems: ExpandedPlanItem[], item: PlanItem, itemCode: string) => {
                const stageId = (item as any).stageId;

                // stageId может быть не определён для доп услуг, если для них не определено направление
                // такие услуги пропускаем
                if (typeof stageId !== 'undefined') {
                    stagedItemsMap[itemCode] = {
                        ..._.pick(item, 'serviceId', 'serviceName', 'serviceGroupId', 'quantity', 'target',
                            'measure', 'price', 'performerId', 'status', 'isAdditional', 'params'),
                        isStageConfirmed: expandedItems.every(item => item.isStageConfirmed),
                        isStageManuallyChanged: expandedItems.some(item => item.isStageManuallyChanged),
                        price: TreatmentPlan.getItemPrice(item),
                        categoryId: item.serviceGroupId in this.servicesGroupsCategoriesMap
                            ? this.servicesGroupsCategoriesMap[item.serviceGroupId]
                            : item.serviceGroupId,
                        direction: (item as any).direction,
                        itemCode,
                        stageId,
                    } as any;
                }
            }
        });

        /* --- дополняем названия услуг (указываем название направлений при необходимости) --- */

        const servicesWithSeveralDirections = _.countBy(_.values(stagedItemsMap), item => StagedPlan.getItemCode(item, false));
        const directionsMap = _.indexBy(this.directions, 'code');

        _.each(stagedItemsMap, item => {
            const shortCode = StagedPlan.getItemCode(item, false);

            if (item.direction && servicesWithSeveralDirections[shortCode] > 1) {
                const direction = directionsMap[item.direction];
                item.serviceName += ` (${direction.name.toLowerCase()})`;
            }
        });

        this.stagedItems = stagedItemsMap;

        return stagedItemsMap;
    }

    protected getItem(itemCode: string): StagedPlanItem {
        return this.getItemsMap()[itemCode];
    }

    /**
     * Перемещает пункт плана в указанный этап
     * @param itemCode - код пункта плана
     * @param stageId - id этапа
     */
    public setItemStage = (itemCode: string, stageId: number) => {
        this.writeItemStage(itemCode, stageId);
        this.updateStagesPerformers(stageId);
        this.save();
    }

    /**
     * Перемещает несколько пунктов плана в указанный этап
     * @param filter - фильтр для выборки пунктов плана, которые нужно переместить в другой этап.
     * Например: {stageId: 'Терапия', performerId: 1234}
     * @param stageId - id этапа
     */
    public setItemsStage = (filter: {[itemField: string]: any}, stageId: number) => {
        const items = this.getItemsMap();

        _.where(items as any, filter).forEach(item => {
            this.writeItemStage((item as StagedPlanItem).itemCode, stageId);
        });

        this.updateStagesPerformers(stageId);

        this.save();
    }

    protected writeItemStage(itemCode: string, stageId: number) {
        const targetItem = this.getItem(itemCode);
        if (targetItem.stageId === stageId) return;

        /* --- автоматическое перемещение обязательных связанных доп услуг --- */

        const requiredServices = this.planFiller.getServiceRequiredServices(targetItem.serviceId);

        if (requiredServices) {
            const itemsMap = this.getItemsMap();

            const additionalItems: {[serviceId: number]: StagedPlanItem} = _.chain(itemsMap)
                .where({
                    stageId: targetItem.stageId,
                    performerId: targetItem.performerId,
                    direction: targetItem.direction
                })
                .indexBy('serviceId')
                .value() as any;

            requiredServices.forEach(requiredService => {
                let requiredItem;

                if (Array.isArray(requiredService)) {
                    //requiredItem = _.values(_.pick(additionalItems, ...requiredService))[0];
                } else {
                    requiredItem = additionalItems[requiredService];
                }

                if (!!requiredItem) {
                    if (requiredItem.quantity === 1) {
                        requiredItem.stageId = stageId;
                        requiredItem.isStageManuallyChanged = true;
                    } else {
                        requiredItem.quantity--;

                        const newItem = Helper.clone(requiredItem);
                        newItem.stageId = stageId;
                        newItem.isStageManuallyChanged = true;

                        const newItemCode = StagedPlan.getItemCode(newItem);
                        if (newItemCode in itemsMap) {
                            itemsMap[newItemCode].quantity++;
                        } else {
                            newItem.quantity = 1;
                            itemsMap[newItemCode] = newItem;
                        }
                    }
                }
            });

            _.filter(this.getItemsMap(), item => item.stageId === targetItem.stageId && item.serviceId in requiredServices)
        }

        targetItem.stageId = stageId;
        targetItem.isStageManuallyChanged = true;
    }

    /**
     * Проверяет, есть ли неподтверждённые изменения этапов в плане
     */
    public hasUnconfirmedChanges = (): boolean => {
        return _.some(this.getItemsMap(), item => !item.isStageConfirmed);
    }

    /**
     * Подтверждает изменения в этапах
     */
    public confirmChanges = () => {
        _.each(this.getItemsMap(), item => item.isStageConfirmed = true);
        this.save();
    }

    protected static getItemCode(item: StagedPlanItem, withDirection: boolean = true): string {
        let code = TreatmentPlan.getPlanItemCode(item, false) + '_' + item.status + '_' + item.stageId;

        if (withDirection) {
            code += '_' + item.direction;
        }

        return code;
    }

    /**
     * Производит обновление врачей (performers) во всех этапах
     * @param targetStageId - id этапа, в который была перемещена услуга / услуги
     */
    protected updateStagesPerformers(targetStageId: number) {
        const stagesItems = _.groupBy(_.values(this.getItemsMap()), 'stageId');
        this.stages = Helper.clone(this.stages);

        this.stages.forEach(stage => {
            const stageItemsPerformers = stagesItems[stage.id]
                ? _.unique(_.pluck(stagesItems[stage.id], 'performerId'))
                : [];

            if (stage.id === targetStageId) {
                // в этапе, в который были перемещены услуги, добавляем новых врачей в конец массива performers
                stage.performers.push(..._.difference(stageItemsPerformers, stage.performers));
            } else {
                // удаляем лишних врачей из массива performers
                stage.performers = _.intersection(stage.performers, stageItemsPerformers);
            }
        });
    }

    protected save() {
        // разбиваем на отдельные услуги
        const items: ExpandedPlanItem[] = Arr.expand(_.values(this.getItemsMap()));

        // объединяем услуги также, как они хранятся в TreatmentPlan
        Arr.squeeze(items, TreatmentPlan.getPlanItemCode, {
            handler: (expandedItems: ExpandedPlanItem[], squeezedItem: PlanItem, itemCode: string) => {
                StageBuilder.squeezeItemStages(expandedItems, squeezedItem);

                // сохраняем в пункты плана информацию, которая могла поменяться в ходе работы объекта
                const item = this.plan.getItem(itemCode);
                item.stages = squeezedItem.stages;
                item.confirmedStages = squeezedItem.confirmedStages;
                item.manuallyChangedStages = squeezedItem.manuallyChangedStages;
            }
        });

        this.plan.save();
        this.setStages(this.stages);
    }
}

type StagedItemsMap = {
    [itemCode: string]: StagedPlanItem
}

type StagesPerformersOrder = {
    [stageId: number]: {
        [performerId: number]: number
    }
}

type SetStages = (stages: Stage[]) => void;

type ServicesGroupsCategoriesMap = {
    [groupId: number]: number
}
