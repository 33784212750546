import { PropsUsePrepareStateForm, ReturnUsePrepareStateForm, ResultUsePrepareStateForm } from "../types/hooks"


export const usePrepareStateForm = ({fields}: PropsUsePrepareStateForm) : ReturnUsePrepareStateForm => {
    return (form: string[]) : ResultUsePrepareStateForm => {
        const result = {} as ResultUsePrepareStateForm
        form.forEach(formField => {
            switch (fields[formField].type) {
                case "enum":
                    result[formField] = null
                    break;
                case "boolean":
                    result[formField] = fields[formField].nullable ? null : false
                    break;
                default:
                    result[formField] = ''
                    break;
            }
        })
        return result
    }
}
