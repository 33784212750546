import React, {useState} from 'react';
import {WrapContent} from '../../../styledUI';
import {OptionButton} from '../../../../../components-ui/Inputs/Button/typesButton';
import ButtonForOptions from '../../../../../components-ui/Inputs/ButtonForOptions/ButtonForOptions';
import {OptionPosition} from '../../../../../components-ui/Inputs/ButtonForOptions/typesButtonForOptions';
import Radio from '../../../../../components-ui/Inputs/Radio/Radio';
import Box from '../../../../../components-ui/Layout/Box';
import Scrollbar from '../../../../../components-ui/DataDisplay/Scrollbar/Scrollbar';

const optionsButton = [
    {
        value: '1',
        label: 'Какой нибудь текст для кнопки',
        onClick: () => console.log('click'),
    },
    {
        value: '2',
        label: 'Второй вариант кнопки',
        onClick: () => console.log('click2'),
    },
    {
        value: '3',
        label: 'Еще одна кнопка',
        onClick: () => console.log('click3'),
    },
] as OptionButton[];

const ButtonForOptionsPage = () => {

    const [statePosition, setStatePosition] = useState<OptionPosition>('right-bottom');

    const [scrollState, setScrollState] = useState<boolean>(false);

    return (
        <>
            <h1>ButtonForOptions</h1>
            <WrapContent>
                <ButtonForOptions options={optionsButton} position={statePosition} />
                <pre>{`<ButtonForOptions options={optionsButton} position={statePosition}/>`}</pre>
                <h3>Position</h3>
                <Radio value='Radio1' checked={statePosition === 'right-bottom'}
                       onClick={() => setStatePosition('right-bottom')} children='right-bottom' />
                <Radio value='Radio2' checked={statePosition === 'right-top'}
                       onClick={() => setStatePosition('right-top')} children='right-top' />
                <Radio value='Radio3' checked={statePosition === 'left-bottom'}
                       onClick={() => setStatePosition('left-bottom')} children='left-bottom' />
                <Radio value='Radio4' checked={statePosition === 'left-top'}
                       onClick={() => setStatePosition('left-top')} children='left-top' />
                <h3>outsideOpenOptions</h3>

                <pre>{`const [scrollState, setScrollState] = useState<boolean>(false)`}</pre>
                <pre>{`
<Scrollbar onScroll={() => setScrollState(false)} height='300px'>
    <ButtonForOptions 
       outsideOpenOptions={scrollState}
       setOutsideOpenOptions={setScrollState}
       options={optionsButton}
       position={'right-bottom'}/>
</Scrollbar>`}

                </pre>

                <Box height='300px' width='540px' border={'text'}>
                    <Scrollbar onScroll={() => setScrollState(false)} height='300px'>
                        <Box height='600px' width='500px' padding='20px'>
                            <Box margin='0 0 20px 0'>
                                <ButtonForOptions outsideOpenOptions={scrollState}
                                                  setOutsideOpenOptions={setScrollState}
                                                  options={optionsButton}
                                                  position={'right-bottom'} />
                            </Box>
                            <Box margin='0 0 20px 0'>
                                <ButtonForOptions outsideOpenOptions={scrollState}
                                                  setOutsideOpenOptions={setScrollState}
                                                  options={optionsButton}
                                                  position={'right-bottom'} />
                            </Box>
                            <Box margin='0 0 20px 0'>
                                <ButtonForOptions outsideOpenOptions={scrollState}
                                                  setOutsideOpenOptions={setScrollState}
                                                  options={optionsButton}
                                                  position={'right-bottom'} />
                            </Box>
                            <Box margin='0 0 20px 0'>
                                <ButtonForOptions outsideOpenOptions={scrollState}
                                                  setOutsideOpenOptions={setScrollState}
                                                  options={optionsButton}
                                                  position={'right-bottom'} />
                            </Box>
                            <Box margin='0 0 20px 0'>
                                <ButtonForOptions outsideOpenOptions={scrollState}
                                                  setOutsideOpenOptions={setScrollState}
                                                  options={optionsButton}
                                                  position={'right-bottom'} />
                            </Box>
                            <Box margin='0 0 20px 0'>
                                <ButtonForOptions outsideOpenOptions={scrollState}
                                                  setOutsideOpenOptions={setScrollState}
                                                  options={optionsButton}
                                                  position={'right-bottom'} />
                            </Box>
                        </Box>
                    </Scrollbar>
                </Box>
            </WrapContent>
        </>
    );
};

export default ButtonForOptionsPage;