import {PropsSLabel, PropsSLabelText} from "../types";
import styled from "styled-components";

const fdLabel = (props: PropsSLabel) => {
    switch (props.align) {
        case "right":
            return 'row-reverse';
        case "top":
        case "topLeft":
            return 'column';
        case "left":
        default:
            return 'row'
    }
};

export const SLabel = styled.div.attrs({className: 'Label'})<PropsSLabel>`
    display: flex;
    flex-direction: ${fdLabel};
    width: ${props => !!props.widthLabelWrap ? props.widthLabelWrap : 'max-content'};
    justify-content: flex-start;
    align-items: ${props => props.align === 'topLeft' || props.align === "leftTop" ? 'flex-start' : 'center'};
    position: relative;
    margin: ${props => !!props.marginWrap ? props.marginWrap : 0};
    cursor: ${props => props.cursor};
`;


const colorText = (props: PropsSLabelText) => {
    const {theme, colorLabel} = props;
    switch (colorLabel) {
        case "textGray":
            return theme?.colors.textGray
        case "text":
        default:
            return theme?.colors.text;
    }
}

const marginLabelText = (props: PropsSLabelText) => {
    const {align, marginLabel} = props;
    if (!!marginLabel) return marginLabel;
    switch (align) {
        case "right":
            return '0 0 0 10px'
        case "top":
        case "topLeft":
            return '0 0 10px'
        case "left":
        case "leftTop":
        default:
            return '0 10px 0 0'
    }
}

export const SLabelText = styled.span.attrs({className: 'SLabelText'})<PropsSLabelText>`
    font-size: ${props => props.theme.fontSizes.small};
    display: inline-block;
    line-height: 1.5;
    color: ${colorText};
    min-width: ${props => !!props.minWidthLabel ? props.minWidthLabel : 'auto'};
    margin: ${marginLabelText};
    ${props => !!props.widthLabel && `
    width: ${props.widthLabel};
    flex-shrink: 0;
    `};
    ${props => !!props.paddingLabel && `padding: ${props.paddingLabel};`};
`;

export const SRequiredText = styled.span.attrs({className: 'SRequiredText'})`
    color: ${props => props.theme.colors.danger};
`
