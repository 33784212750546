import {useEffect, useState} from 'react';
import {GetXrayPicturesListParams, GetXrayPicturesListResult} from '../types';
import {useOptionsBranchesUser} from '../../../common/hooks/useOptionsBranchesUser';
import {OptionSelect} from '../../../components-ui/Inputs/Select/typesSelect';
import ServerCommand from '../../../common/server/server-command';
import DateHelper from '../../../common/helpers/date-helper';
import useServerEvents from './useServerEvents';
import useLoader from '../../../components/Errors';

const useXrayPictures = () => {

    const optionsBranches = useOptionsBranchesUser()

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [selectedBranch, setSelectedBranch] = useState<OptionSelect>(optionsBranches[0])
    const [creator, setCreator] = useState<OptionSelect>({value: 0, label: 'Все сотрудники'})

    const [loaderPage, setLoaderPage] = useLoader(true);

    const [loaderTable, setLoaderTable] = useLoader(false)

    const [xRayPicturesResponse, setXRayPicturesResponse] = useState<GetXrayPicturesListResult>({patients: {}, pictures: []})

    const params = {
        startDate: DateHelper.formatDateForServer(startDate),
        endDate: DateHelper.formatDateForServer(endDate),
        creatorId: creator.value ? creator.value : undefined,
        branchId: selectedBranch.value
    } as GetXrayPicturesListParams

    useEffect(() => {
        setLoaderTable(true)
        ServerCommand
            .get('xray-picture/list', params)
            .then((response: GetXrayPicturesListResult) => {
                setXRayPicturesResponse(response)
                setLoaderPage(false)
                setLoaderTable(false)
            })
    }, [startDate, endDate, creator, selectedBranch])

    useServerEvents({
        selectedBranchId: selectedBranch.value,
        setData: setXRayPicturesResponse,
        creatorId: creator.value,
        startDate: startDate,
        endDate: endDate
    })

    return {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        loaderPage,
        loaderTable,
        selectedBranch,
        setSelectedBranch,
        creator,
        setCreator,
        xRayPicturesResponse,
        optionsBranches
    }

};

export default useXrayPictures;