import React from "react";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";
import DateHelper from "../../../../common/helpers/date-helper";

interface Props {
    timeStart: string
}

export const VisitDate = ({timeStart}: Props) => {
    return <LabelTask label="Дата приёма" children={DateHelper.formatComingDays(timeStart)}/>
}
