import React from "react";
import Tag from "../../../../../../../components-ui/DataDisplay/Tag/Tag";
import TasksContent from "../../../../TasksContent/TasksContent";
import {Divider} from "../../../../../../../components-ui/DataDisplay/Divider";
import useAllDeadline from "../../../../../hooks/useAllDeadline";
import {MethodsCallCenter, OnChangeSelectedScript, TasksDefault} from "../../../../../types/common";
import {InfoCallCenter} from "../../../../../types/hooks";
import {TasksInfo} from "../../../../../../../containers/Tasks";
import {SContainerWrap, SContainerItem, STaskList} from "../../../../../../../containers/Tasks/style";

interface Props {
    nameType: string
    sortTaskType: TasksDefault
    personalPhone: string
    validate: boolean
    infoCallCenter: InfoCallCenter
    methodsCallCenter: MethodsCallCenter
    tasksInfo: TasksInfo
    onChangeSelectedScript: OnChangeSelectedScript
}

const ItemTasks = ({tasksInfo, sortTaskType, validate = true, nameType, ...props}: Props) => {

    const allDeadline = useAllDeadline(sortTaskType);
    return (
        <SContainerWrap isDeadline={allDeadline}>
            <span style={{paddingBottom: '8px'}}>
                <SContainerItem>
                    <STaskList padding="32px 0 0">
                        <Tag children={tasksInfo[nameType].name}/>
                    </STaskList>
                </SContainerItem>
                <TasksContent tasks={sortTaskType} allDeadline={allDeadline} validate={validate} {...props}/>
                <Divider/>
            </span>
        </SContainerWrap>
    )
}

export default ItemTasks
