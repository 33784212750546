import React from "react";
import {OnChangeSelectedScript, TaskCallCenter} from "../../types/common";
import Box from "../../../../components-ui/Layout/Box";
import Button from "../../../../components-ui/Inputs/Button/Button";
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {buttonTitles} from "../../helper/getDataCallCenter";

interface PropsScriptButton {
    task: TaskCallCenter
    margin?: string
    onChangeSelectedScript: OnChangeSelectedScript
}

export const ScriptButton = ({task, margin, onChangeSelectedScript} : PropsScriptButton) => {
    if (!task.scriptId) return null

    return (
        <Box className="button-task-content" margin={margin}>
            <Button {...commonPropsButton}
                    title={buttonTitles.script}
                    variant="default"
                    onClick={() => onChangeSelectedScript(task.scriptId as number)}
                    children="открыть скрипт"/>
        </Box>

    )
}
