import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../data/commonPropsSComponent";
import React from "react";
import { STaskItem } from "../../style";

interface Props {
    children?: React.ReactNode,
    margin?: string
}

export default function SubTitleTask({children, margin}: Props) {

    return (
        <STaskItem>
            <Typography {...commonPropsTypography} fontWeight="bold" margin={!!margin ? margin : "0 0 11px"} children={children} />
        </STaskItem>
    )
}
