import React, {Dispatch, SetStateAction} from 'react';
import {commonLabelOption} from '../../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import { Label } from '../../../../../../components-ui/DataDisplay/Label';
import {SelectedMonth} from '../../../../../Salaries/types';
import Select from '../../../../../../components-ui/Inputs/Select/Select';
import {months, PMonths} from '../../../../../../common/arrays/arrays';
import TextField from '../../../../../../components-ui/Inputs/TextField/TextField';

interface Props {
    selectedMonth: SelectedMonth
    setSelectedMonth: Dispatch<SetStateAction<SelectedMonth>>
}

const SelectDate = ({selectedMonth, setSelectedMonth}: Props) => {
    return (
        <Label {...commonLabelOption}
               isRequired={true}
               labelText='Месяц применения'
               align='leftTop'
               marginLabel='8px 10px 0 0'
        >
            <Select value={months.find((month) => selectedMonth.month === +month.value)}
                    placeholder='Месяц'
                    options={months}
                    onChange={(option: PMonths) => setSelectedMonth(prevState => (
                        {...prevState, month: +option.value}
                    ))
                    }
            />
            <TextField value={selectedMonth.year}
                       placeholder='Год'
                       onChange={(value: string) => {
                           value.length < 5 && setSelectedMonth(prevState => (
                               {...prevState, year: +value.replace(/[_]/gi, '')}
                           ));
                       }
                       }
                       textAlignInput='center'
                       widthInput='80px'
                       mask='9999'
                       margin='0 0 0 15px'
            />
        </Label>
    );
};

export default SelectDate;
