import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const CloseIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M14.54,12l9,9a1.7,1.7,0,0,1,0,2.41h0l-.12.12a1.71,1.71,0,0,1-2.42,0h0l-9-9-9,9a1.7,1.7,0,0,1-2.41,0h0L.5,23.38A1.71,1.71,0,0,1,.5,21h0l9-9L.5,3A1.71,1.71,0,0,1,.5.63h0L.62.5A1.71,1.71,0,0,1,3,.5H3l9,9,9-9a1.7,1.7,0,0,1,2.41,0h0l.12.12A1.71,1.71,0,0,1,23.5,3h0l-9,9Z"/>
        </WrapIcon>
    );
});
