import React from 'react'
import PropTypes from 'prop-types'
import ServerCommand from "../../../../common/server/server-command";
import StepFooter from '../../components/StepFooter/StepFooter';
import {Alert} from "../../../../components-ui/DataDisplay/Alert";
import CaseHistory from "../../../../containers/CaseHistory/CaseHistory"
import Box from "../../../../components-ui/Wraps/old/Box";
import Button from "../../../../components-ui/OldButton/Button";


export default class StepCaseHistory extends React.Component {

    static propTypes = {
        goToNextStep: PropTypes.func,
        visitId: PropTypes.number.isRequired,
    };

    state = {
        data: null,
        isPrint: false,
        loaderNextBtn: false
    };

    componentDidMount() {
        ServerCommand
            .get('case-history/save-form', {
                visitId: this.props.visitId,
            })
            .then(response => this.setState({data: response}));
    }

    render() {
        const {data} = this.state;

        if (data === null) return null;

        let content = this.renderIsContent();
        if (data.visitCaseHistory === null && data.planCaseHistory === null) content = this.renderNoContent();

        return content
    }

    renderIsContent = () => {
        const {data} = this.state;
        const {visitId} = this.props;
        return (<>
            <Alert children="Заполните историю болезни для каждого зуба"/>
            <Box>
                <CaseHistory
                    visitId={visitId}
                    data={data}
                    renderFooter={this.renderFooter}/>
            </Box>
        </>)
    };

    renderNoContent = () => {
        const {visitId, goToNextStep} = this.props;
        return (
            <Box size='sm' modification="center">
                <div className="choice__content--center">
                    <p className="note">Выполненные услуги не нужно описывать в истории болезни</p>
                </div>
                <Button
                    variant="success"
                    text="К гарантиям"
                    loader={this.state.loaderNextBtn}
                    onClick={() => {
                        this.setState({loaderNextBtn: true})
                        ServerCommand.run('visit/set-next-state', {visitId}).then(() => {
                            goToNextStep();
                            this.setState({loaderNextBtn: false})
                        })
                    }}
                />
            </Box>
        )
    };

    renderFooter = (options) => {
        const {isPrint, loaderNextBtn} = this.state;
        const {needPrint, validateFields, isDataChanged, handlePrintButtonClick, canPrint, loaderPrint} = options;

        let nexBtnActive = true;

        if (needPrint) {
            nexBtnActive = isPrint && !validateFields.length
        } else {
            if (isDataChanged() && validateFields.length) {
                nexBtnActive = isPrint;
            }
        }

        if (!canPrint) {
            nexBtnActive = true;
        }


        return (
            <StepFooter
                showPrintBtn={canPrint}
                nextBtnName={'К гарантиям'}
                onNextButtonClick={this.handleNextButtonClick.bind(this, options)}
                nextBtnActive={nexBtnActive}
                onPrintButtonClick={() => {
                    handlePrintButtonClick();
                    if (!validateFields.length) {
                        this.setState({isPrint: true})
                    }
                }}
                printBtnLabel="Пациенту на подпись, вклеить в карту"
                nextBtnLabel={"Распечатайте историю болезни"}
                loaderPrint={loaderPrint}
                loaderNextBtn={loaderNextBtn}
            />
        )
    };

    /* Переход к следующему шагу */
    handleNextButtonClick = (options) => {

        const {validateFields, onClickCheckValidation, saveData, needPrint} = options;
        const {visitId, goToNextStep} = this.props;

        if (validateFields.length) {
            onClickCheckValidation(validateFields);
            return false
        }

        this.setState({loaderNextBtn: true});

        const thenCommand = () => {
            goToNextStep();
            this.setState({loaderNextBtn: false});
        }

        if (needPrint) {
            ServerCommand.run('visit/set-next-state', {visitId}).then(thenCommand);
        } else {

            ServerCommand.run('case-history/save', saveData()).then(() => {
                ServerCommand.run('visit/set-next-state', {visitId}).then(thenCommand);
            });

        }

    };

}
