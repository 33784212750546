import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth16Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 12 24"} width={width || "22"} height={height || "44"} ref={ref}>
            <path d="M3.71,0C4.27-.09,5.8,2.41,7,5.29a22.67,22.67,0,0,1-.81,5.31C5.37,10,3.8,6.54,3,4,3.06.17,3.71,0,3.71,0ZM.68.88A.28.28,0,0,1,.93,1a10,10,0,0,1,.86,2.24s.09.31.25.79a.3.3,0,0,0,.06.18c.74,2.2,2.64,7.39,4.2,7.39h.13a.64.64,0,0,0,.43-.32,20,20,0,0,0,1-6v0a9.2,9.2,0,0,0-.28-3c-.29-.91-.19-1.41,0-1.54a.32.32,0,0,1,.3,0c1.17.52,3,3.78,3,10.3V12c0,1.19,0,2.74-.05,4.08a5.55,5.55,0,0,0-1.3-.73,4.36,4.36,0,0,0-2-.17c-.35,0-.74.07-1.22.07a14,14,0,0,1-1.58-.09,4.23,4.23,0,0,0-1.87.12,3.52,3.52,0,0,0-1.26.65,40,40,0,0,0-.47-5.68C1,9.75,1,9.23.86,8.71.34,5.64-.25,2.13.48,1A.31.31,0,0,1,.68.88ZM12,20.45c-.23,1.7-.66,2.76-1.27,3.13s-1.54.06-2.51-.27A6.8,6.8,0,0,0,6,22.8a3.17,3.17,0,0,0-1.57.59c-1.13.66-2.2,1.28-3.87-.94a3.26,3.26,0,0,1-.3-3.14,5.19,5.19,0,0,1,2.82-3.13A3.83,3.83,0,0,1,4,16a5.85,5.85,0,0,1,.59,0,14.22,14.22,0,0,0,3,0,3.38,3.38,0,0,1,1.61.1,4.71,4.71,0,0,1,1.66,1.13A3.73,3.73,0,0,1,12,20.45Z"/>
        </WrapIcon>
    );
});
