import {InfoManagerTasks, StateManagerTasks} from "../types/components";
import {useMemo} from "react";
import Helper from "../../../common/helpers/main";
import {useDateUpdate} from "../../../common/hooks";
import sortTasks from "../../../containers/Tasks/utils/sortTasks";
import {ManagerTask} from "../types/common";
import _ from "lodash";
import useAppendItems from "../../../containers/Tasks/hooks/useAppendItems";
import {TasksInfo} from "../../../containers/Tasks";
import useBranchesManagerTasks from "./useBranchesManagerTasks";

interface Props {
    state: StateManagerTasks
    infoManagerTasks: InfoManagerTasks
    refScrollList: any
    tasksInfo: TasksInfo
}

const useFilterManagerTasks = ({state, infoManagerTasks, refScrollList, tasksInfo}: Props) => {
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);
    const {selectedFilter, selectedUser} = state;

    const branchesTasks = useBranchesManagerTasks({state})

    const _sortTasks = useMemo(() => {
        return sortTasks(branchesTasks, currentDate, infoManagerTasks.types)
            .sort((taskA, taskB) => +!!taskA.postponedTo - +!!taskB.postponedTo) as ManagerTask[]
    }, [JSON.stringify(branchesTasks)])

    const filterItems = useMemo(() => {
        let res = _.clone(_sortTasks);
        if (selectedFilter !== 'all') res = res.filter(task => task.type === selectedFilter);
        if (selectedUser.value === 'clinic') {
            res = res.filter(task => task.employeeId === null)
        } else if (selectedUser.value !== 'all') {
            res = res.filter(task => task.employeeId === selectedUser.value);
        }

        return res.filter(task => !!tasksInfo[task.type]);
    }, [selectedFilter, selectedUser, JSON.stringify(_sortTasks)])

    return useAppendItems({refScrollList, filterItems}) as [ManagerTask[], any]

}

export default useFilterManagerTasks
