import React from 'react';
import {IXRayPicturesRow} from '../../XrayPicturesTable/types';
import useXrayPicturesModalMark from './hooks/useXrayPicturesModalMark';
import Box from '../../../../../components-ui/Layout/Box';
import SelectCreator from './components/SelectCreator';
import SelectDoctor from './components/SelectDoctor';
import SelectPatient from './components/SelectPatient';
import SelectQualitative from './components/SelectQualitative';
import MarkComment from './components/MarkComment';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import {commonLabelOption, commonSelectOption,
} from '../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import Button from '../../../../../components-ui/Inputs/Button/Button';
import ValidationLinkInfo from '../../../../../components/ValidationLinkInfo/ValidationLinkInfo';
import {Label} from '../../../../../components-ui/DataDisplay/Label';
import SelectPictureType from './components/SelectPictureType';

interface Props {
    xrayPicture?: IXRayPicturesRow
    selectedBranch: number
}

const XrayPicturesModalMark = ({xrayPicture, selectedBranch}: Props) => {

    const {
        state,
        handleComment,
        handleQualitative,
        handleCreator,
        handleDoctor,
        handleType,
        handlePatient,
        saveXrayPicture,
        validationCheck,
        setValidationCheck,
        isValid,
        loader
    } = useXrayPicturesModalMark({xrayPicture, selectedBranch})

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        children={`${!!xrayPicture ? 'Изменение' : 'Добавление'} снимка`} textAlign='center' />
            <Box margin='39px 0 0 0'>
                <SelectCreator creator={state.creator}
                               handleCreator={handleCreator}
                               selectedBranch={selectedBranch}
                               isValid={!state.creator && validationCheck}
                />

                <SelectDoctor doctor={state.doctor}
                              handleDoctor={handleDoctor}
                              selectedBranch={selectedBranch}
                              isValid={!state.doctor && validationCheck}
                />

                <SelectPatient fixedPatient={xrayPicture?.patient}
                               setPatientId={handlePatient}
                               isValid={!state.patientId && validationCheck}
                />

                <SelectPictureType type={state.type}
                                   handleType={handleType}
                                   isValid={!state.type && validationCheck}
                />
                <SelectQualitative qualitative={state.qualitative}
                                   handleQualitative={handleQualitative}
                />

                <MarkComment comment={state.comment}
                             isRequired={!state.qualitative}
                             handleComment={handleComment}
                             isValid={!state.qualitative && !state.comment && validationCheck}
                />

                <Label labelText='' {...commonLabelOption}>
                    <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                        <Button children='Сохранить'
                                size='md'
                                loader={loader}
                                variant={isValid ? 'success' : 'disabled'}
                                onClick={saveXrayPicture}
                        />
                        <Box maxWidth='172px' margin='0 0 0 16px'>
                            <ValidationLinkInfo validate={isValid}
                                                onClickIsValidate={() => setValidationCheck(true)
                            } />
                        </Box>
                    </Box>
                </Label>
            </Box>
        </Box>
    );
};

export default XrayPicturesModalMark;
