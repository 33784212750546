import {useSlideModal} from "../../../../../containers/DialogsManager/containers/SlideModal/SlideModal";
import {usePreparationForAdmission} from "../PreparationForAdmission";
import useLoader from "../../../../../components/Errors";

export default function useSubmit() {
    const {props} = usePreparationForAdmission()
    const [loaderNext, setLoaderNext] = useLoader();
    const {removeSlideModal} = useSlideModal();

    const handleNextState = () => {
        setLoaderNext(true)
        props.setNextState(props.visitId);
        props.addBgColor(props.visitId);
        removeSlideModal();
    }

    return {
        loaderNext,
        handleNextState
    }
}
