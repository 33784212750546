import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const LowerJawIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 23.11"} ref={ref}>
            <path d="M2.07,10.1A1.45,1.45,0,0,0,3.33,8.51l0-.25A1.42,1.42,0,0,0,1.73,7h0A1.44,1.44,0,0,0,.46,8.61l0,.24A1.42,1.42,0,0,0,2.06,10.1Z"/>
            <path d="M2.53,13.22a1.39,1.39,0,0,0,1.16-1.56l-.06-.37A1.34,1.34,0,0,0,2.1,10.15h0A1.37,1.37,0,0,0,.94,11.71l.05.36a1.35,1.35,0,0,0,1.53,1.15Z"/>
            <path d="M3.1,16.34a1.31,1.31,0,0,0,1-1.52l-.09-.5a1.27,1.27,0,0,0-1.48-1h0a1.31,1.31,0,0,0-1,1.51l.09.51a1.29,1.29,0,0,0,1.49,1Z"/>
            <path d="M4.35,19.48a1.22,1.22,0,0,0,.71-1.55l-.28-.79a1.17,1.17,0,0,0-1.5-.7h0A1.21,1.21,0,0,0,2.55,18l.28.78a1.17,1.17,0,0,0,1.49.71Z"/>
            <path  d="M12,22.09a1,1,0,0,0-1-1h-.75a1,1,0,1,0,0,2H11A1,1,0,0,0,12,22.09Z"/>
            <path d="M9.15,22a1,1,0,0,0-.74-1.25l-.74-.2a1,1,0,0,0-1.22.71v0a1,1,0,0,0,.74,1.25l.74.19A1,1,0,0,0,9.14,22Z"/>
            <path d="M6.35,21a1,1,0,0,0-.18-1.43l-.29-.23a1,1,0,0,0-1.37.15l0,0A1,1,0,0,0,4.67,21L5,21.2a1,1,0,0,0,1.37-.15Z"/>
            <path d="M22.74,2.93a1.4,1.4,0,0,0-1.58,1.23h0L21,5.3a1.43,1.43,0,1,0,2.83.36h0L24,4.52A1.43,1.43,0,0,0,22.74,2.93Z"/>
            <path d="M22.29,7a1.42,1.42,0,0,0-1.58,1.24h0l0,.24a1.43,1.43,0,0,0,1.25,1.59,1.4,1.4,0,0,0,1.58-1.23h0l0-.24A1.45,1.45,0,0,0,22.29,7Z"/>
            <path d="M21.92,10.14a1.36,1.36,0,0,0-1.55,1.13h0l-.05.37a1.38,1.38,0,0,0,1.15,1.56A1.35,1.35,0,0,0,23,12.08h0l.05-.37A1.38,1.38,0,0,0,21.92,10.14Z"/>
            <path d="M21.44,13.28a1.29,1.29,0,0,0-1.5,1h0l-.09.5a1.31,1.31,0,0,0,1,1.52,1.28,1.28,0,0,0,1.5-1h0l.09-.5a1.31,1.31,0,0,0-1-1.52Z"/>
            <path d="M20.74,16.45a1.17,1.17,0,0,0-1.51.67v0l-.28.79a1.22,1.22,0,0,0,.71,1.55,1.17,1.17,0,0,0,1.51-.67v0l.28-.78A1.22,1.22,0,0,0,20.74,16.45Z"/>
            <path d="M14.79,22.1a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1h0a1,1,0,0,0,1,1h.75a1,1,0,0,0,1-1Z"/>
            <path d="M17.55,21.24a1,1,0,0,0-1.21-.73h0l-.74.19A1,1,0,0,0,14.85,22a1,1,0,0,0,1.21.73h0l.74-.2A1,1,0,0,0,17.55,21.24Z"/>
            <path d="M19.51,19.54a1,1,0,0,0-1.36-.19l0,0-.29.23A1,1,0,0,0,17.66,21a1,1,0,0,0,1.37.19l0,0,.29-.23A1,1,0,0,0,19.51,19.54Z"/>
            <path d="M.31,2.87S-.56,0,1.93,0c9.34,0,4.3,12.94,10,12.94M23.53,2.84S24.4,0,21.92,0c-9.35,0-4.31,12.94-10,12.94"/>
            <path d="M1.72,6.89A1.44,1.44,0,0,0,3,5.3L2.84,4.17A1.42,1.42,0,0,0,1.27,2.92h0A1.45,1.45,0,0,0,0,4.52L.14,5.65A1.41,1.41,0,0,0,1.71,6.89Z"/>
        </WrapIcon>
    );
});
