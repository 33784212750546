import styled from "styled-components";
import {ITheme} from "../../../common/Theme/Theme";


export const FormRadio = styled.div<{margin?: string}>`
    margin: ${props => !!props.margin ? props.margin : '0 0 10px'}
`;

export const InputRadio = styled.input.attrs({
    type: 'radio'
})`
    display: none;
`;

interface ILabelRadio {
    isHover?: boolean
    active?: boolean
    theme: ITheme,
    error?: boolean
}

const borderLabelRadio = (props: ILabelRadio) => {
    if (!!props.error) {
        return props.theme.colors.danger
    }
    if (props.active) {
        return props.theme.colors.info
    } else {
        if (props.isHover) {
            return props.theme.colors.borderGrayActive
        } else {
            return props.theme.colors.borderGray
        }
    }
};

export const LabelRadio = styled.label<ILabelRadio>`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-right: 0;
    user-select: none;
    max-width: 95%;
    &:before {
        content: "";
        display: inline-block;
        width: 17px;
        height: 17px;
        margin-right: 14px;
        border: ${props => props.active ? '2px' : '1px'} solid ${borderLabelRadio};
        border-radius: 50%;
        box-sizing: border-box;
        transition: all .3s;
        flex-shrink: 0;
    }
`;
