import React from "react";
import BoxBody from "../../../components-ui/Wraps/old/BoxBody";
import CheckupFields from "./CheckupFields";
import Templates from "../../Templates/Templates";
import {useInitialSurvey, ValueProvider} from "../InitialSurveyContext";
import {Tooth} from "../../../common/types/teethmap";

interface PContentInitialSurvey {
    editField: any,
    renderFooter: any,
}

export default function ContentInitialSurvey({editField, renderFooter}: PContentInitialSurvey) {
    const {values, setSelectedFieldName, selectedFieldName, valueHelper, handlersHelper, state, handlersValues, } = useInitialSurvey() as ValueProvider;

    const {validate, isDataChanged, teethMapUnconfirmed, loaderPrint} = valueHelper;
    const {getValidateField, submitInitialSurvey, renderFirstErrorField, scrollToField, scrollToDentalCard} = handlersHelper;
    const {fields, templatesGroupsList, templatesList} = state;
    const {addFromTemplate, canceledValueFields, handleFieldChange} = handlersValues;

    return (
        <>
            <BoxBody modification="case-history">
                <div className="card">
                    <div className="card__wrap" style={{position: 'relative'}}>
                        <CheckupFields
                            fields={fields}
                            fieldsValues={values}
                            handleFieldChange={handleFieldChange}
                            validateFields={getValidateField}
                            editField={editField}
                            handleCurrentFieldName={setSelectedFieldName}
                            fieldName={selectedFieldName}
                            dummySubmit={validate}
                            renderFirstErrorField={renderFirstErrorField}
                        />
                        <Templates elementsList={templatesList}
                                   groups={templatesGroupsList}
                                   addFromTemplate={addFromTemplate}
                                   selectedField={selectedFieldName}
                                   visible={!editField}
                        />
                    </div>
                    {renderFooter({
                        handlePrintButtonClick: submitInitialSurvey,
                        isDataChanged,
                        canceledValueFields,
                        validateFields: !Object.values(getValidateField()).some(valid => !valid),
                        canPrint: true,
                        teethMapUnconfirmed,
                        scrollToField,
                        scrollToDentalCard,
                        loaderPrint
                    })}
                </div>
            </BoxBody>
        </>
    )
};
