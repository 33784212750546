import React from "react";
import { SResetIconWrap } from "./styledResetIcon";
import SVG from "../../../../../common/helpers/SVG";

interface Props {
    isReset?: boolean
    onClickReset?: () => void
}

export default function ResetIcon({isReset, onClickReset}: Props) {
    if (!isReset) return null;
    return (
        <SResetIconWrap onClick={onClickReset}>
            <SVG name="close" />
        </SResetIconWrap>
    )
}
