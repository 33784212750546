import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {PropsError} from "../types";

export const useLoader = (initialState = false) : [boolean, Dispatch<SetStateAction<boolean>>] => {
    const [loader, setLoader] = useState(initialState)
    useEffect(() => {
        const handlerLoader = (event: any) => {
            const reason = event.reason as PropsError;
            if (reason.type === 'server_request_error') {
                setLoader(false)
            }
        }
        window.addEventListener('unhandledrejection', handlerLoader)
        return () => window.removeEventListener('unhandledrejection', handlerLoader)
    }, [])

    return [loader, setLoader]
};
