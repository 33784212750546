import React, {useEffect, useState} from 'react';
import DateTime from "../../components-ui/Inputs/DateTime/DateTime";
import TextField from "../../components-ui/Inputs/TextField/TextField";
import Button from "../../components-ui/Inputs/Button/Button";
import {onChangeDateTime} from "../../components-ui/Inputs/DateTime/typesDateTime";
import DateHelper from "../../common/helpers/date-helper";
import useDebounce from "../../common/hooks/useDebounce";

interface Props {
    withFilter: boolean
    onFilterDateChange: onChangeDateTime
    filterDate: Date | null | any
    searchPlaceholder?: string
    searchQuery: string
    onChangeSearchQuery: (value: string) => void
    handleResetBtn: () => void
}

const Filter = ({
                    onFilterDateChange,
                    filterDate,
                    searchPlaceholder,
                    searchQuery,
                    onChangeSearchQuery,
                    handleResetBtn,
                    withFilter
                }: Props) => {

    const [value, setValue] = useState(searchQuery);
    const debouncedValue = useDebounce(value, 500);

    useEffect(() => {
        onChangeSearchQuery(debouncedValue)
    }, [debouncedValue])


    return (
        <>
            {/* Не работает */}
            {/*{withFilter && <DateTime dateFormat="Цена за d MMMM yyyy"
                                     onChange={onFilterDateChange}
                                     margin="0 20px 0 0"
                                     selected={filterDate}/>
            }*/}
            <TextField placeholder={searchPlaceholder}
                       isSearch={true}
                       value={value}
                       widthInput="100%"
                       onChange={setValue}/>
            <Button variant={(searchQuery !== '' || !DateHelper.isToday(filterDate)) ? "standard" : "disabled"}
                    children="Сбросить"
                    size="md"
                    margin="0 0 0 20px"
                    onClick={() => {
                        handleResetBtn()
                        setValue('')
                    }}/>
        </>
    );
}

export default Filter;
