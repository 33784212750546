import React from 'react';
import PropTypes from 'prop-types';
import './PaymentSchedule.scss';
import Textarea from 'react-textarea-autosize';

export default class PaymentSchedule extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    render() {
        return (
            <div className="payment-schedule">
                <span className="payment-schedule__title">График платежей</span>
                <Textarea name="text-area"
                          value={this.props.value}
                          className={`form-control`}
                          onChange={(e) => this.props.onChange(e)}
                          minRows={3}
                />
                <span className="payment-schedule__desc">Укажите в какие даты и какую сумму пациент будет оплачивать</span>
            </div>
        )
    }


}
