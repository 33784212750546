import React, {useContext, useMemo, useState} from "react";
import usePortal from "react-useportal";
import useInitialDialogs from "./hooks/useInitialDialogs";
import {ContextDialogsManager, Methods} from "./types/common";
import useMethodsDialogs from "./hooks/useMethodsDialogs";
import SlideModal from "./containers/SlideModal/SlideModal";
import Labels from "./containers/Labels/Labels";
import Modal from "./containers/Modal/Modal";
import {StateModal} from "./types/modals";
import {SSlideModal} from './types/slideModals'
import _ from 'lodash'

export const DialogsManagerContext = React.createContext({} as ContextDialogsManager);
export const useDialogsManager = () => useContext(DialogsManagerContext);

export default function DialogsManager({children}: any) {
    const {Portal} = usePortal();
    const [slideModals, setSlideModals] = useInitialDialogs();
    const [modals, setModals] = useState<StateModal[]>([]);
    const methods = {
        ...useMethodsDialogs(setSlideModals, setModals)
    }

    const child = useMemo(() => React.Children.only(children), [children])

    return (
        <>
            <DialogsManagerContext.Provider value={{
                slideModals,
                ...methods,
            }}>
                {child}
                <Portal>
                    {_.map(slideModals, (slideModal, idModal) => {
                        return <MemoSlideModal slideModal={slideModal}
                                               idModal={idModal}
                                               key={idModal + 'slide'}
                                               methods={methods}
                        />
                    })}
                </Portal>
                <Portal>
                    <Labels/>
                </Portal>
                {!!modals.length && (
                    <>
                        {modals.map((modal, index) => {
                            return (
                                <Modal isOpen={true} key={"modal" + index}
                                       closeModal={() => setModals(prevState => {
                                           prevState = prevState.filter((item, idx) => idx !== index)
                                           return [...prevState]
                                       })}
                                       {...modal.options}>
                                    {modal.component}
                                </Modal>
                            )
                        })}
                    </>
                )}
            </DialogsManagerContext.Provider>
        </>
    )
}

// Избегаем лишнего перерендоревания других модальных окон
const MemoSlideModal = ({slideModal, idModal, methods}: {slideModal: SSlideModal, idModal: string, methods: Methods}) => {
    return useMemo(() => {
        return <SlideModal slideModal={slideModal}
                           idModal={idModal}
                           methods={methods}
        />
    }, [slideModal.isMinimized, slideModal.sort, slideModal.labelParams])
}
