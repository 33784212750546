import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {ResponseUserDetail, setFnType} from '../types';
import CardForm from '../../../../../../../components-ui/DataDisplay/CardForm';
import TextField from '../../../../../../../components-ui/Inputs/TextField/TextField';
import Checkbox from '../../../../../../../components-ui/Inputs/Checkbox/Checkbox';
import {useOptionsBranchesUser} from '../../../../../../../common/hooks/useOptionsBranchesUser';
import {OptionSelect} from '../../../../../../../components-ui/Inputs/Select/typesSelect';
import Select from '../../../../../../../components-ui/Inputs/Select/Select';
import ServerCommand from '../../../../../../../common/server/server-command';
import useDebounce from '../../../../../../../common/hooks/useDebounce';
import {ResponseUserUniqueField} from '../types/uniqFields';
import Box from '../../../../../../../components-ui/Layout/Box';
import Typography from '../../../../../../../components-ui/DataDisplay/Typography/Typography';

interface Props {
    state: ResponseUserDetail;
    setDetailFn: (props: setFnType) => void;
    passwordState: {
        value?: string,
        confirm?: string
    };
    setPasswordState: Dispatch<SetStateAction<{
        value?: string,
        confirm?: string
    }>>;
    userId?: number;
    validationState: boolean
    passwordValueValid: boolean
    branchesValid: boolean
    loginState?: string
}

const AccountInfo = ({state, setDetailFn, passwordState, setPasswordState, userId, validationState, passwordValueValid, branchesValid, loginState}: Props) => {

    const branchesOptions = useOptionsBranchesUser();

    const [preLogin, setPreLogin] = useState(state.detail.login ?? '');

    const [canSetLogin, setCanSetLogin] = useState(false);

    const debouncePreLogin = useDebounce(preLogin, 500);

    useEffect(() => {
        if (debouncePreLogin) {
            setCanSetLogin(false);
            ServerCommand.post('user/unique',
                {field: 'login', value: debouncePreLogin}, ({unique}: ResponseUserUniqueField) => {
                    setCanSetLogin(unique);
                },
            );
        }
    }, [debouncePreLogin]);

    useEffect(() => {
        if (loginState) {
                if (canSetLogin || debouncePreLogin === loginState) {
                    setDetailFn({key: "login", value: debouncePreLogin})
                } else {
                    setDetailFn({key: "login", value: ""})
                }
        } else {
            if (canSetLogin) {
                setDetailFn({key: "login", value: debouncePreLogin})
            } else {
                setDetailFn({key: "login", value: ""})
            }
        }

    }, [canSetLogin, loginState, debouncePreLogin])

    const validation = canSetLogin || !!(state.detail.login && debouncePreLogin === state.detail.login)

    return (
        <CardForm style={{margin: '0 0 16px 0'}} title='Учётная запись и права доступа' forms={[
            {
                isRequired: true,
                label: 'Логин',
                input: <Box display="flex" flexDirection="column" gap="7px" maxWidth="374px">
                    <TextField widthInput='100%'
                               isError={validationState && !validation}
                               onChange={(val) => {
                                   if (/^[a-zA-Z_]+$/.test(val) || !val) {
                                       setPreLogin(val)
                                   }
                               }
                               }
                               value={preLogin} />
                    <Typography fontStyle="italic" variant="small">
                        Логин может состоять только из латинских букв и подчёркиваний
                    </Typography>
                </Box>
            },

            {
                isRequired: !userId,
                label: `${userId ? 'Изменить' : 'Добавить'} пароль`,
                input:  <Box display="flex" flexDirection="column" gap="7px" maxWidth="374px">
                    <TextField widthInput='100%'
                               onChange={(value) =>
                                   setPasswordState(prevState => ({...prevState, value}))}
                               isError={validationState && !passwordValueValid}
                               value={passwordState.value ?? ''} />
                    <Typography fontStyle="italic" variant="small">
                        Пароль должен состоять из 6 или более символов
                    </Typography>
                </Box>
            },
            {
                isRequired: !userId,
                label: 'Подтвердить пароль',
                input: <TextField widthInput='100%'
                                  isError={validationState && passwordState?.value !== passwordState?.confirm}
                                  onChange={(confirm) =>
                                      setPasswordState(prevState => ({...prevState, confirm}))}
                                  value={passwordState.confirm ?? ''} />,
            },
            {
                isRequired: state.detail.role !== "director",
                label: 'Филиал',
                input: <Select
                    isError={validationState && !branchesValid}
                    options={branchesOptions}
                    menuPlacement='top'
                    value={branchesOptions.filter(option => state.detail.branches?.includes(option.value))}
                    onChange={(val) => setDetailFn({
                        key: 'branches',
                        value: val.map((value: OptionSelect) => +value.value),
                    })}
                    widthSelect='260px'
                    placeholder='Выбрать филиал'
                    isMulti={true}
                    closeMenuOnSelect={false} />,
            },
            {
                label: 'Работает',
                input: <Checkbox label=''
                                 onChange={(value) => setDetailFn({
                                     key: 'active',
                                     value,
                                 })}
                                 checked={state.detail.active} />,
            },
        ]} />

    );
};

export default AccountInfo;
