import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const InformationIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,21.82A9.82,9.82,0,1,1,21.82,12,9.84,9.84,0,0,1,12,21.82Z"/>
            <path d="M12,5.09a1.46,1.46,0,1,0,1.45,1.46A1.45,1.45,0,0,0,12,5.09Z"/>
            <path d="M12,10.18a1.09,1.09,0,0,0-1.09,1.09v6.55a1.09,1.09,0,0,0,2.18,0V11.27A1.09,1.09,0,0,0,12,10.18Z"/>
        </WrapIcon>
    );
});
