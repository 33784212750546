import React from 'react';
import Paper from "../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Box from "../../../components-ui/Layout/Box";
import {Icon} from "../../../components-ui/DataDisplay/Icon/containers/Icon";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import {Link} from "../../../components-ui/Navigation/Link";
import {Article} from "../types";
import {useDevice} from "../../../common/hooks/useDevice";

interface Props {
    article: Article
    isSlider: boolean
}

const OtherArticle = ({article, isSlider}: Props) => {

    const device = useDevice()

    return (
        <Paper
            key={article.id}
            margin={!isSlider ? "12px 18px 12px 0" : "12px 5px"}
            padding={device !== "mobile" ? "30px 16px" : "27px 16px"}
            width={device !== "mobile" ? "236px" : "97%"}
            wrapHeight={device !== "mobile" ? "204px" : "176px"}
            isHidden={false}
            borderRadius="8px"
        >
            <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                <Box>
                    <Box backgroundColor="primary" borderRadius="16px" width="56px" height="56px" padding="12px"
                         margin="0 auto">
                        <Icon name={article.icon} width="100%" height="100%" color="white"/>
                    </Box>
                    <Typography lineHeight="1.43" fontSize="14px"
                                margin="14px 0 0 0">{article.description}</Typography>
                </Box>
                <Link display="block" notUnderline={true} href={article.link} children={
                    <Typography fontSize="14px" color="info" margin="8px 0 0 0">Подробнее</Typography>
                }/>

            </Box>
        </Paper>
    );
};

export default OtherArticle;