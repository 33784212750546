import React from "react";
import {Divider} from "../../../../../../../components-ui/DataDisplay/Divider";
import Button from "../../../../../../../components-ui/Inputs/Button/Button";
import _ from 'lodash'
import {PatientValues} from "../../../../../../../containers/PatientField";
import {IncomingCallPatient} from "../../../../../types/taskData";
import {MethodsCallCenter} from "../../../../../types/common";
import {StateCallCenter} from "../../../../../types/components";
import { STaskList } from "../../../../../../../containers/Tasks/style";

interface Props {
    patient: IncomingCallPatient | null
    methodsCallCenter: MethodsCallCenter
    state: StateCallCenter
}

const FooterProgressCall = ({state, patient, methodsCallCenter}: Props) => {
    const {WAPVisit} = methodsCallCenter;
    const {detail} = state;
    const patientWAP = {
        ..._.pick(patient, 'id', 'lastName', 'name', 'secondName'),
        personalPhone: detail?.phone
    } as PatientValues;
    return (
        <>
            <Divider/>
            <STaskList padding="23px 20px 32px">
                <Button children="Добавить в лист ожидания" variant="default" fontSize="14px" height="40px"
                        width="100%" margin="0 0 16px" onClick={() => WAPVisit(patientWAP)}/>
                <Button children="Запланировать приём" variant="default" width="100%" fontSize="14px"
                        height="40px" onClick={() => WAPVisit(patientWAP, true)}/>
            </STaskList>
        </>
    )
}

export default FooterProgressCall
