import {useMemo} from "react";
import _ from "lodash";
import {ReceiptsReport} from "../../../../../../types";

interface Props {
    report: ReceiptsReport
    code: string
    allTotalsSum: { [key: string]: number }
}

const useWidthLinePercentage = ({report, allTotalsSum, code}: Props) => {
    return  useMemo(() => {
        let res = 0;
        const max = _.maxBy(_.map(allTotalsSum, (sum, directionCode) => ({
            directionCode,
            sum
        })), (item) => item.sum) as { directionCode: string, sum: number };
        if (max.sum === allTotalsSum[code]) {
            res = 100
        } else {
            res = Math.round(allTotalsSum[code] * 100 / max.sum)
        }
        return res;
    }, [JSON.stringify(report)])
}

export default useWidthLinePercentage
