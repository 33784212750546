import * as types from './actionConstants';
import {ResponseSchedule} from "../../pages/Schedule/types";

export const loadSchedule = (data: ResponseSchedule | any) => {
    return ({
        type: types.LOAD_SCHEDULE,
        payload: data
    })
};

export const loadScheduleUser = (user: any, initialDate: string) => ({
    type: types.LOAD_SCHEDULE_USER,
    payload: {
        user,
        initialDate
    }
})

export const updateSchedule = (newSchedule: any) => {
    return ({
        type: types.UPDATE_SCHEDULE,
        payload: newSchedule
    })
};

export const updateSplittedTime = (splittedTime: any) => ({
    type: types.UPDATE_SPLITTED_TIME,
    payload: splittedTime
});

export const setBranchesFilter = (branches: number[]) => ({
    type: types.FILTER_SET_BRANCHES,
    payload: branches
})

export const setSpecialitiesFilter = (specialities: string) => ({
    type: types.FILTER_SET_SPECIALITIES,
    payload: specialities
})

export const setDoctorFilter = (doctorId: number) => {
    return ({
        type: types.FILTER_SET_DOCTOR,
        payload: doctorId
    })
};

export const setRangeFilter = (data: any) => {
    return ({
        type: types.FILTER_SET_RANGE,
        payload: data
    })
};

export const setSwitchFilter = () => ({
    type: types.FILTER_SET_SWITCH
});

export const resetFilter = () => ({
    type: types.FILTER_RESET
});

export const calendarUpload = (date: any) => ({
    type: types.LOAD_CALENDAR,
    payload: date
});

export const calendarDayClick = (dates: string[]) => ({
    type: types.CALENDAR_DAY_CLICK,
    payload: dates
});

export const addMemorizedPatient = (patient: any) => {
    return ({
        type: types.ADD_MEMORIZED_PATIENT,
        payload: patient
    });
};

export const removeMemorizedPatient = () => ({
    type: types.REMOVE_MEMORIZED_PATIENT
});

export const addTransferPatient = (patient: any) => {
    return ({
        type: types.ADD_TRANSFER_PATIENT,
        payload: patient
    });
};

export const removeTransferPatient = () => ({
    type: types.REMOVE_TRANSFER_PATIENT
});
