import React, {FC} from "react";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";

interface Props {
    errorCode: string
}

const ErrorForm: FC<Props> = ({errorCode}) => {
    return (
        <Typography variant="small">
            {(errorCode === 'invalid_grant') ? 'Неверный логин или пароль' : 'Произошла ошибка при обращении к серверу'}
        </Typography>
    )
}

export default ErrorForm