import {LaboratoryService, LaboratoryServiceGroup} from '../../../../../../../../LaboratoryPricelistPage/types';
import {useMemo, useState} from 'react';
import _ from 'lodash';
import {
    GroupServices
} from '../../../../../../../../LaboratoryServiceRatesPage/containers/LaboratoryRatesTable/hooks/useLaboratoryRatesTable';

interface Props {
    dataResponse: any
}

const useServicesTable = ({dataResponse}: Props) => {

    const rootGroup: LaboratoryServiceGroup[] = [{
        id: 0,
        name: 'Все группы',
        groupId: null,
        deletedAt: null,
        groups: dataResponse.servicesGroups,
    }];
    const initialLaboratoryRatesGroups: GroupServices[] = useMemo(() => {
        const recursionMapGroups = (groups: LaboratoryServiceGroup[]): GroupServices[] => {
            return groups.map(group => {

                if (group.groups && group.groups.length) {
                    group.groups = recursionMapGroups(group.groups);
                }

                const findServices = dataResponse.services.filter((service: LaboratoryService) => service.groupId === group.id);
                return {
                    ...group,
                    services: findServices,
                    isOpen: !group.id,
                };
            });
        };

        return recursionMapGroups(rootGroup);
    }, [dataResponse.services, dataResponse.serviceGroups]);

    const [servicesGroups, setServicesGroups] = useState<GroupServices[]>(_.cloneDeep(initialLaboratoryRatesGroups));

    const [selectedGroup, setSelectedGroup] = useState<GroupServices>(servicesGroups[0]);

    const onClickGroup = (id: number) => {
        setServicesGroups(prevState => {
            const recursionGroups = (groups: GroupServices[]) => {
                groups.forEach((group) => {
                    if (group.id === id) {
                        if (!!group.id) group.isOpen = !group.isOpen;
                        setSelectedGroup(group);
                    }

                    if (group.groups && group.groups.length) {
                        recursionGroups(group.groups as GroupServices[]);
                    }
                });
            };
            recursionGroups(prevState);

            return [...prevState];
        });
    };

    return {servicesGroups, selectedGroup, onClickGroup};
};

export default useServicesTable;
