import React from "react";
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {buttonTitles} from "../../helper/getDataCallCenter";
import preparePatient from "../../helper/preparePatien";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Box from "../../../../components-ui/Layout/Box";
import {CommonButtonProps} from "../../types/components";

interface Props extends CommonButtonProps {
    validate: boolean
    blockedButtons: boolean
}

export const TransferButton = ({task, validate, blockedButtons, margin, methodsCallCenter}: Props) => {
    const {transferVisit} = methodsCallCenter
    return (
        <Box className="button-task-content" margin={margin}>
            <Button {...commonPropsButton} variant={blockedButtons ? "disabled" : validate ? "default" : "disabled"} children="перенести"
                    title={blockedButtons ? buttonTitles.blocked : !validate ? buttonTitles.noteValidate : buttonTitles.transfer}
                    onClick={() => transferVisit(preparePatient(task))}/>
        </Box>
    )
}
