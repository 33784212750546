import styled from "styled-components";
import Helper from "../../../../../../../../common/helpers/main";

interface PropsWrapItem {
    onClick: any
    onMouseEnter: any
    active: boolean
    isNotDoctorsTab: boolean
}

export const WrapItem = styled.div<PropsWrapItem>`
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 12px;
    cursor: ${props => props.isNotDoctorsTab ? props.active ? 'default' : 'pointer' : 'default'};
`

export const HeaderItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
`

interface PropsLineItem {
    active: boolean
    colorBg: string
    widthLine: number
}

export const LineItem = styled.div<PropsLineItem>`
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: ${props => props.theme.colors.lightBlue};
    position: relative;
    ${props => props.active && `
        &::after {
            content: '';
            position: absolute;
            top: -3px;
            left: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            background: transparent;
            border-radius: 6px;
            border: 4px solid rgba(${Helper.Color.hexToRgb(props.colorBg)}, 0.25);
            z-index: -1;
        }
    `}
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: ${props => props.widthLine + '%'};
        background: ${props => props.colorBg};
        border-radius: 2px;
    }
`
