import React from 'react';
import {Editor} from '@tiptap/core';
import Box from '../../../../../../components-ui/Layout/Box';
import Button from '../../../../../../components-ui/Inputs/Button/Button';

interface Props {
    editor: Editor | null
}
const MenuBar = ({editor}: Props) => {

    if (!editor) {
        return null;

    }
    return (
        <Box display='flex' gap='5px' flexWrap='wrap'>
            <Button
                isIcon
                onClick={() => editor.chain().focus().toggleBold().run()}
                variant={editor.can()
                    .chain()
                    .focus()
                    .toggleBold()
                    .run() ? editor.isActive('bold') ? 'success' : 'transparent-default' : 'disabled'}
            >
                <svg xmlns='http://www.w3.org/2000/svg' width='20'
                     height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' fill='none'
                     strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M7 5h6a3.5 3.5 0 0 1 0 7h-6z'></path>
                    <path d='M13 12h1a3.5 3.5 0 0 1 0 7h-7v-7'></path>
                </svg>
            </Button>
            <Button
                isIcon
                onClick={() => editor.chain().focus().toggleItalic().run()}
                variant={editor.can()
                    .chain()
                    .focus()
                    .toggleItalic()
                    .run() ? editor.isActive('italic') ? 'success' : 'transparent-default' : 'disabled'}
            >
                <svg xmlns='http://www.w3.org/2000/svg' width='20'
                     height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' fill='none'
                     strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                    <line x1='11' y1='5' x2='17' y2='5'></line>
                    <line x1='7' y1='19' x2='13' y2='19'></line>
                    <line x1='14' y1='5' x2='10' y2='19'></line>
                </svg>
            </Button>
            <Button
                isIcon
                onClick={() => editor.chain().focus().toggleStrike().run()}
                variant={editor.can()
                    .chain()
                    .focus()
                    .toggleStrike()
                    .run() ? editor.isActive('strike') ? 'success' : 'transparent-default' : 'disabled'}
            >
                <svg xmlns='http://www.w3.org/2000/svg'
                     width='20' height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' fill='none'
                     strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                    <line x1='5' y1='12' x2='19' y2='12'></line>
                    <path
                        d='M16 6.5a4 2 0 0 0 -4 -1.5h-1a3.5 3.5 0 0 0 0 7h2a3.5 3.5 0 0 1 0 7h-1.5a4 2 0 0 1 -4 -1.5'></path>
                </svg>
            </Button>
            <Button
                isIcon
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                variant={editor.isActive('underline') ? 'success' : 'transparent-default'}
            >
                <svg xmlns='http://www.w3.org/2000/svg' width='20'
                     height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' fill='none'
                     strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M7 5v5a5 5 0 0 0 10 0v-5'></path>
                    <path d='M5 19h14'></path>
                </svg>
            </Button>
            {/* <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
                clear marks
            </button>
            <button onClick={() => editor.chain().focus().clearNodes().run()}>
                clear nodes
            </button>
            <button
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={editor.isActive('paragraph') ? 'is-active' : ''}
            >
                Параграф
            </button>*/}
            <Button
                isIcon
                onClick={() => editor.chain().focus().toggleHeading({level: 4}).run()}
                variant={editor.isActive('heading', {level: 4}) ? 'success' : 'transparent-default'}
            >
                <svg xmlns='http://www.w3.org/2000/svg' width='20'
                     height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' fill='none'
                     strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M20 18v-8l-4 6h5'></path>
                    <path d='M4 6v12'></path>
                    <path d='M12 6v12'></path>
                    <path d='M11 18h2'></path>
                    <path d='M3 18h2'></path>
                    <path d='M4 12h8'></path>
                    <path d='M3 6h2'></path>
                    <path d='M11 6h2'></path>
                </svg>
            </Button>
            {editor.can().sinkListItem('listItem') &&
                <Button
                    isIcon
                    onClick={() => editor.chain().focus().sinkListItem('listItem').run()}
                    variant='transparent-default'
                >
                    <svg xmlns='http://www.w3.org/2000/svg' width='20'
                         height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' fill='none'
                         strokeLinecap='round' strokeLinejoin='round'>
                        <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                        <line x1='9' y1='6' x2='20' y2='6'></line>
                        <line x1='9' y1='12' x2='20' y2='12'></line>
                        <line x1='9' y1='18' x2='20' y2='18'></line>
                        <line x1='5' y1='6' x2='5' y2='6.01'></line>
                        <line x1='5' y1='12' x2='5' y2='12.01'></line>
                        <line x1='5' y1='18' x2='5' y2='18.01'></line>
                    </svg>
                </Button>
            }
            <Button
                isIcon
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                variant='transparent-default'
            >
                {editor.isActive('bulletList') ?
                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 44 44' fill='none'>
                        <path d='M36.6667 11H7.33341' stroke='black' strokeWidth='2.75' strokeLinecap='round'
                              strokeLinejoin='round' />
                        <path d='M36.6667 22H18.3334' stroke='black' strokeWidth='2.75' strokeLinecap='round'
                              strokeLinejoin='round' />
                        <path d='M37 33L7 33' stroke='black' strokeWidth='2.75' strokeLinecap='round'
                              strokeLinejoin='round' />
                        <path d='M14 27L9 22.5L14 18' stroke='black' strokeWidth='2.75' strokeLinecap='round'
                              strokeLinejoin='round' />
                    </svg> :
                    <svg xmlns='http://www.w3.org/2000/svg' width='20'
                         height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' fill='none'
                         strokeLinecap='round' strokeLinejoin='round'>
                        <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                        <line x1='9' y1='6' x2='20' y2='6'></line>
                        <line x1='9' y1='12' x2='20' y2='12'></line>
                        <line x1='9' y1='18' x2='20' y2='18'></line>
                        <line x1='5' y1='6' x2='5' y2='6.01'></line>
                        <line x1='5' y1='12' x2='5' y2='12.01'></line>
                        <line x1='5' y1='18' x2='5' y2='18.01'></line>
                    </svg>
                }
            </Button>
            <Button
                isIcon
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                variant={editor.isActive('orderedList') ? 'success' : 'transparent-default'}
            >
                <svg xmlns='http://www.w3.org/2000/svg' width='20'
                     height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' fill='none'
                     strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M11 6h9'></path>
                    <path d='M11 12h9'></path>
                    <path d='M12 18h8'></path>
                    <path d='M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4'></path>
                    <path d='M6 10v-6l-2 2'></path>
                </svg>
            </Button>
            {/*<button
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
            >
                blockquote
            </button>
            <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                horizontal rule
            </button>
            <button onClick={() => editor.chain().focus().setHardBreak().run()}>
                hard break
            </button>*/}
            <Button

                isIcon
                variant='transparent-default'
                onClick={() =>
                    editor.chain().focus().insertTable({rows: 3, cols: 3, withHeaderRow: true}).run()
                }
            >
                <svg xmlns='http://www.w3.org/2000/svg' width='20'
                     height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' fill='none'
                     strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                    <rect x='4' y='4' width='16' height='16' rx='2'></rect>
                    <line x1='4' y1='10' x2='20' y2='10'></line>
                    <line x1='10' y1='4' x2='10' y2='20'></line>
                </svg>
            </Button>
            <Button
                isIcon
                onClick={() => editor.chain().focus().undo().run()}
                variant={editor.can()
                    .chain()
                    .focus()
                    .undo()
                    .run() ? 'transparent-default' : 'disabled'}
            >
                <svg xmlns='http://www.w3.org/2000/svg'
                     width='20' height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' fill='none'
                     strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1'></path>
                </svg>
            </Button>
            <Button
                isIcon
                onClick={() => editor.chain().focus().redo().run()}
                variant={editor.can()
                    .chain()
                    .focus()
                    .redo()
                    .run() ? 'transparent-default' : 'disabled'}
            >
                <svg xmlns='http://www.w3.org/2000/svg'
                     width='20' height='20' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' fill='none'
                     strokeLinecap='round' strokeLinejoin='round'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M15 13l4 -4l-4 -4m4 4h-11a4 4 0 0 0 0 8h1'></path>
                </svg>
            </Button>
        </Box>
    );
};

export default MenuBar;
