import React from 'react';
import useMarkDoneEmployees from './hooks/useMarkDoneLaboratories';
import {GetDoneServicesResult} from '../../../types';
import {IDoneLaboratoryServiceRow} from '../types';
import {OptionSelect} from '../../../../../components-ui/Inputs/Select/typesSelect';
import {Laboratory} from '../../../../LaboratoryPage/types';
import Box from '../../../../../components-ui/Layout/Box';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import {Label} from '../../../../../components-ui/DataDisplay/Label';
import {
    commonLabelOption,
    commonSelectOption,
} from '../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import DateTime from '../../../../../components-ui/Inputs/DateTime/DateTime';
import Select from '../../../../../components-ui/Inputs/Select/Select';
import Counter from '../../../../../components-ui/Inputs/Counter/Counter';
import Button from '../../../../../components-ui/Inputs/Button/Button';
import _ from 'lodash';

interface Props {
    services: GetDoneServicesResult['services'];
    service?: IDoneLaboratoryServiceRow;
    refreshDataFromServer: () => void;
    selectedBranch: OptionSelect;
    laboratories?: Laboratory[];
}

const ModalMarkDoneLaboratoryService = ({services, laboratories, service, refreshDataFromServer, selectedBranch}: Props) => {

    const {
        state,
        loader,
        saveDoneLaboratoryService,
        handleDate,
        handleLaboratory,
        handleDoctor,
        handleService,
        handleQuantity,
        optionsDoctors,
        optionsLaboratories,
        optionsServices
    } = useMarkDoneEmployees({services, laboratories, service, refreshDataFromServer, selectedBranch })

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='middle-title' fontWeight='600'
                        textAlign='center'>
                {`${service ? 'Изменение' : 'Добавление'} услуги`}
            </Typography>
            <Box margin='39px 0 0 0'>
                <Label labelText='Дата' isRequired={true} {...commonLabelOption}>
                    <DateTime selected={state.date}
                              maxDate={new Date()}
                        // @ts-ignore
                              onChange={(val: Date) => {
                                  handleDate(val);
                              }} />
                </Label>
                <Label labelText='Лаборатория' isRequired={true} {...commonLabelOption} >
                    <Select {...commonSelectOption}
                            colorSelect='white'
                            placeholder='Выберите лабораторию'
                            value={state.laboratory}
                            options={optionsLaboratories}
                            onChange={(value) => handleLaboratory(value)}
                    />
                </Label>
                <Label labelText='Врач' isRequired={true} {...commonLabelOption} >
                    <Select {...commonSelectOption}
                            colorSelect='white'
                            placeholder='Выберите врача'
                            variant='colored'
                            value={state.doctor}
                            options={optionsDoctors}
                            onChange={(value) => handleDoctor(value)}
                    />
                </Label>
                <Label labelText='Услуга' isRequired={true} {...commonLabelOption} >
                    <Select {...commonSelectOption}
                            colorSelect='white'
                            placeholder='Выберите услугу'
                            value={state.service}
                            options={optionsServices}
                            onChange={(value) => handleService(value)}
                    />
                </Label>
                <Label labelText='Количество' isRequired={true} {...commonLabelOption} >
                    <Counter value={state.quantity}
                             onChange={(val) => handleQuantity(+val)}/>
                </Label>
            </Box>
            <Label labelText='' {...commonLabelOption}>
                <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                    <Button children='Сохранить'
                            size='md'
                            loader={loader}
                            variant={_.every(state, Boolean) ? 'success' : 'disabled'}
                            onClick={saveDoneLaboratoryService}
                    />
                </Box>
            </Label>
        </Box>
    );
};

export default ModalMarkDoneLaboratoryService;
