import * as React from "react";
import Label from "../../../../components-ui/OldLabel/Label";
import Select from "../../../../components-ui/Inputs/Select/Select";
import Button from "../../../../components-ui/Inputs/Button/Button";
import {PRecommendedVisitDecision} from "../types";
import {BoxBody} from "../../../../components-ui/styledComponent/common/wraps";
import {WrapDecision} from "../styled";
import {useDispatch} from "react-redux";
import {addMemorizedPatient} from "../../../../redux/actions/scheduleActions";
import moment from "moment";
import {useSlideModal} from "../../../DialogsManager/containers/SlideModal/SlideModal";

const recommendedVisitsDecisionsOptions = [
    {
        label: 'не указано',
        value: null
    },
    {
        label: 'Записать сейчас',
        value: 'now'
    },
    {
        label: 'Записать позже',
        value: 'later'
    },
    {
        label: 'Не определился',
        value: 'not_decided'
    },
];

export default function RecommendedVisitDecision({selectedDoctorId, recommendedVisitsDecisions, handleSelectRecommendedVisitDecision, visit} : PRecommendedVisitDecision) {

    const dispatch = useDispatch();

    const selectedDecisionCode = recommendedVisitsDecisions[selectedDoctorId] ? recommendedVisitsDecisions[selectedDoctorId] : null;
    const selectedDecisionOption = recommendedVisitsDecisionsOptions.find(option => option.value === selectedDecisionCode);
    const {closeSlideModal} = useSlideModal();

    return (
        <BoxBody style={{borderTop: "1px solid #b8c0d0"}} nonBorderBottom>
            <Label labelName="Решение о записи"/>
            <WrapDecision>
                <Select options={recommendedVisitsDecisionsOptions}
                        value={selectedDecisionOption}
                        onChange={handleSelectRecommendedVisitDecision}
                />
                {(selectedDecisionCode === 'now') && (
                    <Button
                        children="Записать"
                        size="md"
                        variant="success"
                        onClick={() => {
                            closeSlideModal()
                            const timeStart = moment(visit.timeStart).format('HH:mm')
                            const timeEnd = moment(visit.timeEnd).format('HH:mm')
                            const copiedPatient = {
                                isSocial: visit.isSocial,
                                visitId: visit.id,
                                patient: visit.patient,
                                timeStart,
                                timeEnd,
                            };
                            dispatch(addMemorizedPatient(copiedPatient))
                        }}
                    />

                )}
            </WrapDecision>

        </BoxBody>
    );
}
