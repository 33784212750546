import React, {Dispatch, SetStateAction} from 'react';
import TimeRange from "../../../../../components-ui/Inputs/Slider/TimeRange/TimeRange";
import {setRangeFilter} from "../../../../../redux/actions/scheduleActions";
import {useDispatch, useSelector} from "react-redux";

interface Props {
    time: (string | null)[],
    setTime: Dispatch<SetStateAction<(string | null)[]>>
}

const OpeningTime = ({time, setTime}: Props) => {
    const dispatch = useDispatch();
    const timeLimits = useSelector(state => state.schedule.schedule.timeLimits);
    return (
        <TimeRange timeStart={timeLimits.start}
                   timeEnd={timeLimits.end}
                   value={time}
                   onChange={setTime}
                   onAfterChange={() => dispatch(setRangeFilter(time))}
        />
    );
};

export default OpeningTime;
