import {DentalCardTooth, SetStateConfig} from "../types";
import _ from "underscore";

interface HandleToothButton {
    jaw: string,
    toothItem: DentalCardTooth,
    indexTooth: number,
    ctrlPressed: boolean,
    setConfig: SetStateConfig
}

export const handleToothButton = ({jaw, toothItem, indexTooth, ctrlPressed, setConfig} : HandleToothButton) => {
    setConfig(prevState => {
        if (ctrlPressed) {
            prevState[jaw][indexTooth].selected = !toothItem.selected
        } else {
            _.each(prevState, (teeth, nameConfig) => {
                teeth.forEach((tooth: DentalCardTooth, index) => {
                    if (index === indexTooth && nameConfig === jaw) {
                        tooth.selected = !toothItem.selected;
                    } else {
                        tooth.selected = false;
                    }
                })
            });
        }
        return {
            ...prevState
        }
    });
};
