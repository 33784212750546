import React, {useEffect, useMemo} from "react";
import {Label} from "../../../../../../../components-ui/DataDisplay/Label";
import commonLabelOptions from "../../../../../../../containers/PatientField/helper/commonLabelOptions";
import Select from "../../../../../../../components-ui/Inputs/Select/Select";
import Checkbox from "../../../../../../../components-ui/Inputs/Checkbox/Checkbox";
import _ from "lodash";
import {withoutTrashed} from "../../../../../../../common/helpers/Doctor";
import {useSelector} from "react-redux";
import {useWAP} from "../../../WaitingAndPlanned";
import {OptionSelect} from "../../../../../../../components-ui/Inputs/Select/typesSelect";

export default function DoctorFormWAP() {
    const {form, onChangeField, activeTab} = useWAP();
    const doctorsList = useSelector(state => state.common.doctors);
    const doctors = useMemo(() => {
        let filterDoctor = withoutTrashed(_.cloneDeep(doctorsList))
        if (activeTab === "waiting-list" && form.branches && !!form.branches.length) {
            const branchesMap = form.branches.map(branch => branch.value);
            filterDoctor = filterDoctor.filter(doctor => doctor.branches.some(branch => branchesMap.includes(branch)))
        }
        const mapDoctor = filterDoctor.map(doctor => ({
            label: doctor.fio,
            value: doctor.id,
            color: doctor.color
        })) as OptionSelect[]
        return [{value: 'all-doctors', label: 'Все'}].concat(mapDoctor)
    }, [activeTab, doctorsList, form.branches])

    useEffect(() => {
        if (activeTab === 'waiting-list' && !!form.doctor && form.branches && !!form.branches.length) {
            if (!doctors.some(doctor => +doctor.value === +form.doctor?.value)) {
                onChangeField(null, 'doctor')
            }
        }
    }, [activeTab, doctors, form.branches])

    return (
        <>
            <Label labelText='Врач'
                   {...commonLabelOptions(true, 'WAP')}
                   marginWrap="0 0 19px 0"
            >
                <Select variant="colored"
                        options={doctors}
                        value={form.doctor}
                        widthSelect="334px"
                        onChange={value => {
                            if (value.value === 'all-doctors') {
                                onChangeField(null, 'doctor')
                            } else {
                                onChangeField(value, 'doctor')
                            }

                        }}
                        placeholder="не выбран"/>
            </Label>
            <Label labelText=''
                   {...commonLabelOptions(true, 'WAP')}>
                <Checkbox label="Только к этому врачу"
                          disabled={form.doctor === null}
                          onChange={value => {
                              if (form.doctor !== null) onChangeField(value, 'wantThisDoctor')
                          }}
                          checked={form.wantThisDoctor}/>
            </Label>
        </>
    )
}
