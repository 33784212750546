import React from 'react';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import {IXRayPicturesRow} from '../types';
import XrayPicturesModalDelete from '../../modals/XrayPicturesModalDelete';
import XrayPicturesModalMark from '../../modals/XrayPicturesModalMark';

interface Props {
    selectedBranch: number
}

const useOptions = ({selectedBranch}: Props) => {

    const {showModal} = useDialogsManager();

    return (xrayRow: IXRayPicturesRow) => {
        return [
            {
                value: '0',
                label: 'Изменить',
                onClick: () => showModal(<XrayPicturesModalMark xrayPicture={xrayRow} selectedBranch={selectedBranch}/>),
            },
            {
                value: '1',
                label: 'Удалить',
                onClick: () => showModal(
                    <XrayPicturesModalDelete id={xrayRow.id}/>
                ),
            },
        ]
    }
};

export default useOptions;
