import {Call, MethodsCallCenter, OnChangeSelectedScript, PlannedCall} from "../../../types/common";
import React, {Dispatch, SetStateAction, useState} from "react";
import _ from "underscore";
import SimpleCall from "../containers/SimpleCall/SimpleCall";
import ExtendedCall from "../containers/ExtendedCall/ExtendedCall";
import Paper from "../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import {StateCallCenter} from "../../../types/components";
import {TasksInfo} from "../../../../../containers/Tasks";
import {InfoCallCenter} from "../../../types/hooks";

interface Props {
    calls: Call[]
    setState: Dispatch<SetStateAction<StateCallCenter>>
    plannedCall: PlannedCall
    tasksInfo: TasksInfo
    methodsCallCenter: MethodsCallCenter
    infoCallCenter: InfoCallCenter
    onChangeSelectedScript: OnChangeSelectedScript
    callIndex: number
}

const CallItem = ({plannedCall, calls, setState, tasksInfo, ...props}: Props) => {

    const [zIndex, setZIndex] = useState(11);
    const uniqType = _.uniq(plannedCall.tasks, 'type');
    const uniqPatient = _.uniq(plannedCall.tasks.filter(task => task.targetPerson.name !== null), (task) => task.targetPerson.patientId);
    let content = null;
    const commonProps = {plannedCall, uniqType, uniqPatient, setState, tasksInfo}
    if (uniqType.length > 1 || (uniqType.length === 1 && (uniqType[0].type === 'incoming_call' || uniqType[0].type === 'missed_call'))) {
        content = <SimpleCall {...commonProps}/>
    } else {
        content = <ExtendedCall {...commonProps} setZIndex={setZIndex} calls={calls} {...props}/>
    }
    if (plannedCall.canSaveResult) {
        plannedCall.outgoing = calls.some(call => call.contactPhone === plannedCall.phone) && plannedCall.outgoing;
    }

    return (
        <Paper margin="0 0 12px" zIndex={zIndex} isHidden={false}>
            {content}
        </Paper>
    )
}

export default CallItem
