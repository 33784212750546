import { IPatientField } from "../../PatientField";

export default function addSortField(field: IPatientField, index: number) {
    switch (field.name) {
        case "fio":
            field.sort = 1;
            break;
        case "lastName":
            field.sort = 2;
            break;
        case "name":
            field.sort = 3;
            break;
        case "secondName":
            field.sort = 4;
            break;
        case 'personalBirthday':
            field.sort = 5;
            break;
        case 'personalGender':
            field.sort = 6;
            break;
        case 'phone':
            field.sort = 7;
            break;
        case "personalPhone":
            field.sort = 8;
            break;
        case "phoneOwner":
            field.sort = 9;
            break;
        case "additionalPhone":
            field.sort = 10;
            break;
        case 'email':
            field.sort = 11;
            break;
        case 'address':
            field.sort = 12;
            break;
        case "personalCity":
            field.sort = 13;
            break;
        case "personalStreet":
            field.sort = 14;
            break;
        case "personalHome":
            field.sort = 15;
            break;
        case "personalHousing":
            field.sort = 16;
            break;
        case "personalApartment":
            field.sort = 17;
            break;
        case "residentialAddress":
            field.sort = 18;
            break;
        case 'passportSn':
            field.sort = 19;
            break;
        case 'passportIssuedBy':
            field.sort = 20;
            break;
        case 'passportIssuedDate':
            field.sort = 21;
            break;
        case 'passportAddress':
            field.sort = 22;
            break;
        case 'inn':
            field.sort = 23;
            break;
        case "policy":
            field.sort = 24;
            break;
        case "workCompany":
            field.sort = 25;
            break;
        case "workPosition":
            field.sort = 26;
            break;
        case "source":
            field.sort = 27;
            break;
        case 'discount':
            field.sort = 28;
            break;
        case 'account':
            field.sort = 29;
            break;
        case "familyId":
            field.sort = 30;
            break;
        case "comment":
            field.sort = 31;
            break;
        case "firstVisit":
            field.sort = 32;
            break;
        case "doctorsId":
            field.sort = 33;
            break;
        default:
            field.sort = 35 + index;
            break;
    }
    return field
}
