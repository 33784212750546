import {PatientValues} from "../types/common";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import _ from "lodash";
import {useSelector} from "react-redux";

export default function usePrepareRelations() {
    const patientData = useSelector(state => state.patient)
    const {relationTypesTitles} = patientData
    return (relationsList: PatientValues[]) => {
        const relationsPatients = {} as { [patientId: string]: OptionSelect | null };
        const relationsValidate = {} as { [patientId: string]: boolean };
        relationsList.forEach((patientItem) => {
            const patientRelationTypesTitles = _.cloneDeep(relationTypesTitles);
            if (patientRelationTypesTitles !== null) {
                let relationTypes = patientRelationTypesTitles.general as any;
                if (patientItem.personalGender !== null && patientItem.personalGender !== '') {
                    relationTypes = Object.assign({}, patientRelationTypesTitles.general, patientRelationTypesTitles[patientItem.personalGender])
                }
                relationsPatients[patientItem.id as number] = !patientItem.relationType ? null :
                    {value: patientItem.relationType, label: relationTypes[patientItem.relationType]} as OptionSelect
                relationsValidate[patientItem.id as number] = false;
            }
        });
        return {relationsPatients, relationsValidate}
    }
}
