import React, {useState} from "react";
import {WrapContent} from "../../../styledUI";
import CalendarField from "../../../../../components-ui/Inputs/CalendarField/CalendarField";

export default function CalendarFieldPage() {
    const [value, setValue] = useState('');
    return (
        <>
            <h1>CalendarFieldPage</h1>
            <WrapContent>
                <div>
                    <CalendarField value={value} onChange={setValue} />
                    <pre>
                        {
                            `<CalendarField value="${value}" onChange={setValue} />`
                        }
                    </pre>
                </div>

            </WrapContent>
        </>
    )
}
