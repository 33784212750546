import React, {useRef, useState} from 'react';
import Box from "../../../../../../../../../components-ui/Layout/Box";
import Typography from "../../../../../../../../../components-ui/DataDisplay/Typography/Typography";
import Paper from "../../../../../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import {List} from "../../../../../../../../../components-ui/DataDisplay/List";
import Icon from '../../../../../../../../../components-ui/DataDisplay/Icon';
import AdditionalItem from "./components/AdditionalItem";
import useOutsideClick from "../../../../../../../../../common/hooks/useOutsideClick";
import {HandlersAverageCheck, StateAverageCheck} from "../../../../types";
import {BarDataItem} from "../../../../../../../../../components-ui/Charts/ChartBar";

interface Props {
    codes: string[]
    additionalUsers: string[]
    state: StateAverageCheck
    handlers: HandlersAverageCheck
    data: BarDataItem[]
}

const AdditionalUsers = ({additionalUsers, codes, state, handlers, data}: Props) => {
    const ref = useRef<HTMLElement>(null)
    const [openModal, setOpenModal] = useState(false)
    useOutsideClick(ref, () => setOpenModal(false))

    if (!!additionalUsers.length) {
        return (
            <Box position="relative" ref={ref}>
                <Box display="flex" alignItems="center" cursor="pointer" onClick={() => setOpenModal(!openModal)}>
                    <Typography variant="small" children="Ещё" margin="0 4px 0 0"/>
                    <Icon name="playArrow" transform="rotate(90deg)" width="5px" height="5px"/>
                </Box>
                {openModal && (
                    <Box position="absolute" positionOffsets={{top: '100%', right: '0'}} zIndex={12}>
                        <Paper isModal={true} isHidden={false}>
                            <List>
                                {additionalUsers.map((code, index) => {
                                    return <AdditionalItem code={code} index={index} codes={codes} state={state}
                                                           handlers={handlers} data={data}/>
                                })}
                            </List>
                        </Paper>
                    </Box>
                )}

            </Box>
        );
    } else {
        return null
    }
};

export default AdditionalUsers;
