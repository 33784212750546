import {useSelector} from "react-redux";
import {OptionSelect} from "../../components-ui/Inputs/Select/typesSelect";
import {useMemo} from "react";
import _ from 'lodash'

export const useOptionsCallerTypes = (): OptionSelect[] => {
    const callerTypes = useSelector(state => state.common.callerTypes);
    return useMemo(() => {
        return _.map(callerTypes, (label, value) => ({
            label,
            value
        }))
    }, [callerTypes])
}