import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth35Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 6.55 24"} width={width || "12"} height={height || "44"} ref={ref}>
            <path d="M4.79.59A3,3,0,0,0,3.24,0C2.5.2.71.76.18,1.69A6.88,6.88,0,0,0,.79,6.33a3.32,3.32,0,0,0,2.73,2,1.6,1.6,0,0,0,1.25-.42C6.22,6.65,6.51,3.16,6.55,2.68A3.73,3.73,0,0,0,4.79.59ZM4.22,24c.18-.06.57-1,.45-2.9a21.49,21.49,0,0,1,.46-7.31,30.83,30.83,0,0,0,.8-6,3.69,3.69,0,0,1-.61.7,2.39,2.39,0,0,1-1.66.64H3.43A3.91,3.91,0,0,1,.69,7.71C1.87,21.11,3.76,24,4.22,24Z"/>
        </WrapIcon>
    );
});
