//common
export const SIGN_IN = 'COMMON/SIGN_IN';
export const SIGN_UP = 'COMMON/SIGN_UP';
export const LOAD_COMMON_DATA = 'COMMON/LOAD_COMMON_DATA';
export const RESET_DATA_LOADED = 'COMMON/RESET_DATA_LOADED';
export const SET_SERVER_ERROR = 'COMMON/SET_SERVER_ERROR';
export const SET_IS_SCROLL_AREA = 'COMMON/SET_IS_SCROLL_AREA';


// user
export const LOAD_USER = 'USER/LOAD_USER';

// doctorRole
export const LOAD_VISITS = 'DOCTOR_ROLE/LOAD_VISITS';
export const ADD_VISIT = 'DOCTOR_ROLE/ADD_VISIT';
export const REMOVE_VISIT = 'DOCTOR_ROLE/REMOVE_VISIT';
export const START_VISIT = 'DOCTOR_ROLE/START_VISIT';

//schedule
export const LOAD_SCHEDULE = 'SCHEDULE/LOAD_SCHEDULE';
export const UPDATE_SCHEDULE = 'SCHEDULE/UPDATE_SCHEDULE';
export const UPDATE_SPLITTED_TIME = 'SCHEDULE/UPDATE_SPLITTED_TIME';
export const FILTER_SET_BRANCHES = 'SCHEDULE/FILTER_SET_BRANCHES';
export const FILTER_SET_SPECIALITIES = 'SCHEDULE/FILTER_SET_SPECIALITIES';
export const FILTER_SET_DOCTOR = 'SCHEDULE/FILTER_SET_DOCTOR';
export const FILTER_SET_RANGE = 'SCHEDULE/FILTER_SET_RANGE';
export const FILTER_SET_SWITCH = 'SCHEDULE/FILTER_SET_SWITCH';
export const FILTER_RESET = 'SCHEDULE/FILTER_RESET';
export const LOAD_CALENDAR = 'SCHEDULE/LOAD_CALENDAR';
export const CALENDAR_DAY_CLICK = 'SCHEDULE/CALENDAR_DAY_CLICK';
export const ADD_MEMORIZED_PATIENT = 'SCHEDULE/ADD_MEMORIZED_PATIENT';
export const REMOVE_MEMORIZED_PATIENT = 'SCHEDULE/REMOVE_MEMORIZED_PATIENT';
export const ADD_TRANSFER_PATIENT = 'SCHEDULE/ADD_TRANSFER_PATIENT';
export const REMOVE_TRANSFER_PATIENT = 'SCHEDULE/REMOVE_TRANSFER_PATIENT';
export const LOAD_SCHEDULE_USER = 'SCHEDULE/LOAD_SCHEDULE_USER'

//call-center
export const UPDATE_SAVE_PATIENT_CALL_CENTER = 'CALL/UPDATE_SAVE_PATIENT_CALL_CENTER'

//patient
export const LOAD_PATIENT_DATA = 'PATIENT/LOAD_PATIENT_DATA'
