import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth54Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 21.18 24"} width={width || "14"} height={height || "16"} ref={ref}>
            <path d="M20.81,17.49C18.57,20.61,13,24.42,11.27,24l-1.2-.28C6.87,23,.91,21.65,0,16.44,0,13.19,1,4.37,5.22,1.2A6.15,6.15,0,0,1,10,.11c4.85.7,7.7,5,9,7.44,2.25,4.35,2.57,8.85,1.81,9.9v0Z"/>
        </WrapIcon>
    );
});
