import React, {useState} from 'react';
import {ManagerTask} from "../../types/common";
import Paper from "../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import Helper from "../../../../common/helpers/main";
import {useDateUpdate} from "../../../../common/hooks";
import {SContainerWrap} from '../../../../containers/Tasks/style';
import Header from "./components/Header";
import {TasksInfo} from "../../../../containers/Tasks";
import {MethodsManagerTasks} from "../../types/components";
import PostponeTask from "../../components/PostponeTask";
import ButtonComplete from "./components/ButtonComplete";
import TaskContent from "./components/TaskContent";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";
import _ from 'lodash'
import {useSelector} from "react-redux";
import LabelTask from "../../../../containers/Tasks/components/UI/LabelTask";

interface Props {
    selectedBranches: number[]
    task: ManagerTask
    tasksInfo: TasksInfo
    methodsManagerTasks: MethodsManagerTasks
    handleSelectedUser: (selectedUser: OptionSelect) => void
}

const TaskItem = ({
                      task,
                      tasksInfo,
                      methodsManagerTasks,
                      handleSelectedUser,
                      selectedBranches
                  }: Props) => {
    const user = useSelector(state => state.user)
    const allBranches = useSelector(state => state.common.allBranches)
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);
    const [zIndex, setZIndex] = useState(11);
    const isDeadline = !!task.deadline && !(new Date(task.deadline as any).getTime() > new Date(currentDate).getTime())
    const isPostponedTo = !!task.postponedTo;
    const _selectedBranches = !!selectedBranches.length ? selectedBranches : user.branches
    return (
        <Paper margin="0 0 12px" zIndex={zIndex} isHidden={false}>
            <SContainerWrap isDeadline={isDeadline} isPostponedTo={isPostponedTo}>
                <Header task={task} tasksInfo={tasksInfo} handleSelectedUser={handleSelectedUser}/>
                <PostponeTask task={task} tasksInfo={tasksInfo} setZIndex={setZIndex}
                              postponeServerCommand={methodsManagerTasks.postponeServerCommand}/>
                <TaskContent task={task}
                             methodsManagerTasks={methodsManagerTasks}
                             setZIndex={setZIndex}
                             renderBranches={() => {
                                 if (_selectedBranches.length > 1 && _.difference(_selectedBranches, task.branches).length > 0) {
                                     return (
                                         <>
                                             {task.branches.map((branchId, index) => (
                                                 <LabelTask label={index === 0 ? "Филиалы" : ""}
                                                            key={branchId}
                                                            children={allBranches[branchId].name}/>
                                             ))}
                                         </>
                                     )
                                 } else {
                                     return null
                                 }
                             }}/>
                {task.type !== 'check_caller_type' && (
                    <ButtonComplete taskId={task.id} taskComplete={methodsManagerTasks.taskComplete}/>
                )}
            </SContainerWrap>
        </Paper>
    );
}

export default TaskItem;
