import Obj from "../helpers/object";

interface Errors {
    [code: string]: boolean | object
}

interface Messages {
    [errorCode: string]: string | ((errorParams: object) => string | JSX.Element)
}

export default class ValidationResult {
    errors : Errors;
    messages: Messages|null = null;

    constructor(errors: Errors | string[]) {
        this.errors = (Array.isArray(errors) ? Obj.fill(errors, true) : Obj.filter(errors,(active: boolean) => active)) as Errors;
    }

    addError(errorCode: string, params: object|null = null) : void {
        this.errors[errorCode] = (params !== null) ? params : true;
    }

    deleteError(errorCode: string) : void {
        delete this.errors[errorCode];
    }

    setMessages(messages: Messages) {
        this.messages = messages;
    }

    /**
     * Проверяет, произошла ли ошибка с указанным кодом
     * @param {string} errorCode
     * @return {*}
     */
    hasError(errorCode: string) : boolean {
        return errorCode in this.errors;
    }

    getErrorParam(errorCode: string, paramName: string) : any {
        const errorParams: any = (typeof this.errors[errorCode] === 'object') ? this.errors[errorCode] : {};
        return (paramName in (errorParams as object)) ? errorParams[paramName] : null;
    }

    isSuccess() : boolean {
        return Obj.every(this.errors, (isFail: boolean) => !isFail);
    }

    prepareMessages() : (string | JSX.Element)[] {
        const preparedMessages: (string | JSX.Element)[] = [];

        Obj.forEach(this.errors, (errorParams: boolean | object, errorCode: string) => {
            if(!errorParams) return;

            const message = !!this.messages ? this.messages[errorCode] : null;
            if(message) {
                if(typeof message === 'string') {
                    preparedMessages.push(message);
                } else if(typeof message === 'function') {
                    if(typeof errorParams === 'boolean') {
                        errorParams = {};
                    }

                    preparedMessages.push(message(errorParams));
                }
            }
        });

        return preparedMessages;
    }

    getFullExplanation() : string {
        const preparedMessages = this.prepareMessages().filter(part => typeof part === 'string');
        let explanation = preparedMessages.join(' ');
        return explanation.endsWith('.') ? explanation.substr(0, explanation.length - 1) : explanation;
    }
}