import styled from "styled-components";

export const SBoxIncoming = styled.div.attrs({className: 'SBoxIncoming'})`
    display: flex;
    align-items: center;
    margin-right: 10px;

    .incoming-call-panel-icon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            & > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
`
