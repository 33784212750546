import React, {Dispatch, SetStateAction} from "react";
import {Divider} from "../../../../../../../components-ui/DataDisplay/Divider";
import SubTitleTask from "../../../../../../../containers/Tasks/components/UI/SubTitleTask";
import LabelTask from "../../../../../../../containers/Tasks/components/UI/LabelTask";
import Helper from "../../../../../../../common/helpers/main";
import Typography from "../../../../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsButton, commonPropsTypography} from "../../../../../../../containers/Tasks/data/commonPropsSComponent";
import TextField from "../../../../../../../components-ui/Inputs/TextField/TextField";
import Button from "../../../../../../../components-ui/Inputs/Button/Button";
import _ from "underscore";
import {PatientTransferAndMemorized} from "../../../../../types/hooks";
import Box from "../../../../../../../components-ui/Layout/Box";
import {Doctor} from "../../../../../components/Labels";
import {buttonTitles} from "../../../../../helper/getDataCallCenter";
import {MethodsCallCenter} from "../../../../../types/common";
import {IncomingCallPatient} from "../../../../../types/taskData";
import { STaskList, STaskItem, STaskButtons } from "../../../../../../../containers/Tasks/style";

interface Props {
    methodsCallCenter: MethodsCallCenter
    patient: IncomingCallPatient | null
    futureComment: string
    setFutureComment: Dispatch<SetStateAction<string>>
    validate: boolean
}

const InfoProgressCall = ({methodsCallCenter, patient, futureComment, setFutureComment, validate}: Props) => {
    const {transferVisit, cancelVisit} = methodsCallCenter;

    if (!patient) return null
    if (!patient.lastVisit && !patient.futureVisit) return null

    const onClickTransfer = () => transferVisit({
        visitId: patient?.futureVisit?.id,
        patient: {
            ..._.pick(patient, 'name', 'lastName', 'secondName', 'id', 'cardNumber')
        },
        timeStart: '09:00',
        timeEnd: '09:30'
    } as PatientTransferAndMemorized)

    const propsBox = {} as any;
    if (!validate) {
        propsBox.title = buttonTitles.noteValidate
    }

    return (
        <>
            <Divider/>
            <STaskList padding="14px 0">
                {!!patient.futureVisit && (
                    <>
                        <SubTitleTask children="Будущий приём" margin="0 0 2px"/>
                        <Doctor doctorId={patient.futureVisit.doctorId}/>
                        <LabelTask label="Дата" children={Helper.Date.formatComingTime(patient.futureVisit.timeStart)}/>
                        <STaskItem margin="2px 0">
                            <Typography {...commonPropsTypography} children="Комментарий"/>
                        </STaskItem>
                        <STaskItem margin="2px 0">
                            <TextField onChange={setFutureComment} value={futureComment} isTextArea={true} minHeight="96px" widthInput="100%" size="sm" />
                        </STaskItem>
                        <STaskButtons margin="18px 0 16px">
                            <Box className="button-task-content" {...propsBox}>
                                <Button {...commonPropsButton} variant={validate ? "default" : "disabled"}
                                        children="Перенести" onClick={onClickTransfer}/>
                            </Box>
                            <Box className="button-task-content">
                                <Button {...commonPropsButton} variant="default" children="Отменить"
                                        onClick={() => cancelVisit(patient?.futureVisit?.id as number)}/>
                            </Box>
                        </STaskButtons>
                    </>
                )}
                {!!patient.lastVisit && (
                    <>
                        <SubTitleTask children="Последний приём" margin="0 0 2px"/>
                        <Doctor doctorId={patient.lastVisit.doctorId}/>
                        <LabelTask label="Дата" children={Helper.Date.formatComingTime(patient.lastVisit.timeStart)}/>
                    </>
                )}
            </STaskList>

        </>
    )
}

export default InfoProgressCall
