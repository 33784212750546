import React from 'react';
import {TableBox} from "../../../../components-ui/styledComponent/common/wraps";
import Table from "../../../../components/Table/Table";
import {columns} from "../../data";
import {ITableRow, ResponseAnalyticsDoctorsRecommendations} from "../../types";
import {useTables} from "./hooks/useTables";
import {OptionSelect} from "../../../../components-ui/Inputs/Select/typesSelect";
import {ButtonLink} from "../../../../components-ui/styledComponent/common/commonStyle";
import PatientCard from "../../../../content/PatientCard/PatientCard";
import {useDialogsManager} from "../../../../containers/DialogsManager/DialogsManager";
import {SWrapLoader} from "../../../../components-ui/Feedback/Loader/Loader"

interface Props {
    data: ResponseAnalyticsDoctorsRecommendations
    selectedDoctor: OptionSelect
    loaderData: boolean
}

const Tables = ({data, selectedDoctor, loaderData}: Props) => {
    const {showSlideModal} = useDialogsManager();
    const tables = useTables({data, selectedDoctor})

    const openPatientCard = (patientId: number) => {
        showSlideModal(<PatientCard patientId={patientId}/>)
    }

    const modifyCellContent = (code: string, content: string | number, item: ITableRow) => {
        switch (code) {
            case "patient":
                return <ButtonLink key={code + content} style={{cursor: "pointer"}}
                                   onClick={() => openPatientCard(item.patientId)}>{content}</ButtonLink>;
            default:
                return content;
        }
    }

    return (
        <SWrapLoader loader={loaderData}>
            {tables.map(table => {
                return (
                    <div key={table.doctorId}>
                        <h4>{table.fio}</h4>
                        <TableBox>
                            <Table data={table.tableData}
                                   columns={columns}
                                   isEditable={false}
                                   modifyCellContent={modifyCellContent}
                                   getRowCode={(item: ITableRow) => item.patientId + item.paidSum + item.remainingSum}
                            />
                        </TableBox>
                    </div>
                )
            })}
        </SWrapLoader>
    );
};

export default Tables;
