import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth33Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 6.55 24"} width={width || "12"} height={height || "44"} ref={ref}>
            <path d="M2.63,20.45C4.28,23.48,5,23.93,5.2,24a1.8,1.8,0,0,0,.06-.92c-.77-6.66-.56-8.73.23-14.79a3.23,3.23,0,0,1-2.19.85H3.07A3.34,3.34,0,0,1,1,8.3c.1,4.87.41,9.9,1.63,12.14ZM.07,4.2C.31,6.05,1,8.27,3.13,8.41A2.62,2.62,0,0,0,5.5,7.12c1.08-1.55,1.22-3.76.88-4.32C5.7,1.69,3.6,0,2.87,0A3.61,3.61,0,0,0,.07,2.82a3.56,3.56,0,0,0,0,1.38Z"/>
        </WrapIcon>
    );
});
