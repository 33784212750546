import {useMemo} from "react";
import {PropsSwirl} from "../../../../components/Swirl";
import {StateConsultations} from "../types";
import {ConsultationsReport} from "../../../../types";

interface Props {
    state: StateConsultations
    report: ConsultationsReport
}

const useTextSwirl = ({state, report}: Props) => {
    return useMemo(() => {
        const result = {
            textUp: '',
            textDown: ''
        } as PropsSwirl;

        if (state.currentButton === 'consultationsCount') {
            result.textUp = report.consultationsCount.general.all
            result.textDown = report.consultationsCount.general.all - report.consultationsCount.general.failed
            result.percentage = 100 * (+result.textDown) / +result.textUp
        } else {
            result.textUp = report.plansSums.general.recommended;
            result.textDown = report.plansSums.general.performed
            result.percentage = 100 * report.plansSums.general.performed / report.plansSums.general.recommended
        }

        return result
    }, [state.currentTab, state.currentButton, JSON.stringify(report.consultationsCount.general), JSON.stringify(report.plansSums.general)])
}

export default useTextSwirl
