import React, {useEffect, useState} from 'react';
import Typography from '../../../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../../../components-ui/Layout/Box';
import _ from 'lodash';
import DateHelper from '../../../../../common/helpers/date-helper';
import {commonLabelOption, commonSelectOption} from '../../../../../content/modals/PaymentCreationForm/data';
import Select from '../../../../../components-ui/Inputs/Select/Select';
import {Label} from '../../../../../components-ui/DataDisplay/Label';
import ServerCommand from '../../../../../common/server/server-command';
import {GetScheduleAvailableDoctors, ScheduleAvailableDoctorsResponse} from './types';
import {OptionSelect} from '../../../../../components-ui/Inputs/Select/typesSelect';
import Tag from '../../../../../components-ui/DataDisplay/Tag/Tag';
import Button from '../../../../../components-ui/Inputs/Button/Button';
import {useModal} from '../../../../../containers/DialogsManager/containers/Modal/Modal';

interface Props {
    highlightedCells: {
        chairId: number
        date: string
        arrCells: {timeStart: string, timeEnd: string}[]
    };
    removeHighlitedCells: () => void;
}

const HighlightedCellsModal = ({highlightedCells, removeHighlitedCells}: Props) => {

    const {closeModal} = useModal();

    const cellIntervals = _
        .chain(highlightedCells.arrCells)
        .sortBy('timeStart')
        .value();

    let timeIntervals: {timeStart: string, timeEnd: string}[] = [{...cellIntervals[0]}];

    cellIntervals.forEach((interval, index) => {
            if (index) interval.timeStart === timeIntervals[timeIntervals.length - 1].timeEnd ?
                timeIntervals[timeIntervals.length - 1].timeEnd = interval.timeEnd :
                timeIntervals.push({timeStart: interval.timeStart, timeEnd: interval.timeEnd});
        },
    );

    const [doctorVariants, setDoctorVariants] = useState<OptionSelect[]>();
    const [state, setState] = useState<{loader: boolean, doctor?: OptionSelect}>({loader: false});
    useEffect(() => {
        ServerCommand.get('schedule/available-commands', {
            commands: [{
                code: 'schedule/change-doctor-interval',
                params: {
                    interval: [
                        ...highlightedCells.arrCells,
                    ],
                    chairId: highlightedCells.chairId,
                    date: highlightedCells.date,
                },
                varyParam: 'doctorId',
            }],
        } as GetScheduleAvailableDoctors).then((response: ScheduleAvailableDoctorsResponse) => {
            setDoctorVariants(response[0].variants.map((option: {code: number, name: string}) => ({
                value: option.code,
                label: option.name,
            })));
        });
    }, []);

    const saveScheduleAvailableDoctors = () => {
        setState(prevState => ({...prevState, loader: true}));
        ServerCommand.post('schedule/change-doctor-interval',
            {
                interval: highlightedCells.arrCells,
                date: highlightedCells.date,
                chairId: highlightedCells.chairId,
                doctorId: state.doctor?.value,
            }, () => {
                removeHighlitedCells();
                closeModal();
            });
    };

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        textAlign='center'>Изменить врача</Typography>
            <Box margin='12px 0 0 0'>
                <Typography variant='min-title' fontWeight='normal' textAlign='center'>
                    {DateHelper.formatDate(highlightedCells.date, 'DD MMM YYYY')}, кресло {highlightedCells.chairId}
                </Typography>
            </Box>
            <Box margin='39px 0 0 0'>
                <Typography margin='0 0 15px 0' textAlign='center'>Выбраны интервалы:</Typography>
                <Box display='flex' gap='10px' width='500px' flexWrap='wrap' margin='0 0 25px 0'>
                    {timeIntervals.map((interval) =>
                        <Tag>{`С ${interval.timeStart} по ${interval.timeEnd}`}</Tag>,
                    )}
                </Box>
                <Label labelText={`Выберете доктора`} {...commonLabelOption}>
                    <Select
                        {...commonSelectOption}
                        options={doctorVariants}
                        value={state?.doctor ?? ''}
                        onChange={(doctor: OptionSelect) => {
                            setState(prevState =>
                                ({...prevState, doctor}),
                            );
                        }}
                    />
                </Label>
                <Box display='flex' justifyContent='center' margin='25px 0 0 0'>
                    <Button children='Сохранить'
                            size='md'
                            loader={state.loader}
                            variant={state.doctor?.value !== undefined ? 'success' : 'disabled'}
                            onClick={saveScheduleAvailableDoctors}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default HighlightedCellsModal;

