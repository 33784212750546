import React from 'react';
import {GetLaboratoriesResult} from '../../types';
import useTableFormatter from './hooks/useTableFormatter';
import Table from '../../../../components-ui/DataDisplay/Table';
import Paper from '../../../../containers/UI-Collection/Elements/Surfaces/Paper';
import Button from '../../../../components-ui/Inputs/Button/Button';
import {useDialogsManager} from '../../../../containers/DialogsManager/DialogsManager';
import LaboratoryMark from '../modals/LaboratoryMark';

interface Props {
    data: GetLaboratoriesResult;
    refreshDataFromServer: () => void
}

const LaboratoriesTable = ({data, refreshDataFromServer}: Props) => {
    const {columns} = useTableFormatter({refreshDataFromServer});

    const {showModal} = useDialogsManager();

    return (
        <Paper padding='24px' margin='22px 0 0 0'>

            <Table columns={columns}
                   rows={data.laboratories.filter(lab => !lab.deletedAt)}
                   variantTable='outline'
                   isHovering={false}
                   fontSizeRow='14px'
            />

            <Button margin='24px 0 0 0'
                    variant='default'
                    size='md'
                    children='Добавить'
                    onClick={() => showModal(<LaboratoryMark refreshDataFromServer={refreshDataFromServer}/>)}
            />
        </Paper>
    );
};

export default LaboratoriesTable;
