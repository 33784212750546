import React from 'react';
import Helper from "../../../common/helpers/main";
import Select from "../../../components-ui/Inputs/Select/Select";
import Button from "../../../components-ui/Inputs/Button/Button";
import Fields from "./Fields";
import {PFields} from "../typesCaseHistory";

const FieldsWrap = (props: PFields | any) => {
    const {
        selectedOption, visitTargetSelected, fieldsValues, editMode, onChangeSelectedCopy, onClickButtonCopy,
        selectedDiagnosis
    } = props;


    const typeToothSelected = (visitTargetSelected.length > 0) ? 'visit' : 'plan';
    let options = [{value: 'visit_null', label: 'Не выбран'}];
    const targetSelected = props[typeToothSelected + 'TargetSelected'];

    if (!Helper.isEmptyObj(fieldsValues)) {
        options = options.concat(Helper.mapObj(fieldsValues, (fields: any, key: any) => {
            return {value: key, label: key.split('_')[1], fields, }
        }));
        options = options.filter(option => option.value.indexOf(typeToothSelected) !== -1);
        if (targetSelected.length === 1) {
            options = options.filter(option => option.label !== targetSelected[0]);
        }
        options.forEach((option: any) => {
            if (selectedDiagnosis[option.value] !== undefined) {
                option.diagnosis = selectedDiagnosis[option.value];
            }
        })
    }

    let isDisabledButton = false;
    if (selectedOption !== null) {
        if (selectedOption.value === 'visit_null') {
            isDisabledButton = true;
        }
    } else {
        isDisabledButton = true;
    }

    return (
        <div className="card__box">
            <div className="card-body">
                {(props[typeToothSelected + 'Target'].length > 1) &&
                <>
                    <span className="card__subtitle">Копировать описание зуба</span>
                    <div className="copy-teeth">
                        <Select
                            closeMenuOnSelect={true}
                            options={options}
                            selected={selectedOption}
                            placeholder='Не выбран'
                            onChange={onChangeSelectedCopy}
                            key={typeToothSelected}
                            isDisabled={editMode}
                        />
                        <Button
                            variant={editMode || isDisabledButton ? 'disabled' : (selectedOption !== null && selectedOption.value !== null) ? 'success' : 'disabled'}
                            children='Применить'
                            size="md"
                            onClick={onClickButtonCopy}
                        />
                    </div>
                </>}
                <Fields {...props} />
            </div>
        </div>
    )
};

export default FieldsWrap;
