import Helper from "./main";

let PriceHelper = {

    /**
     * Форматирует цену
     * @param {number} price
     * @return {string}
     */
    format: (price) =>
    {
        price = (typeof price === 'undefined') ? '' : price;

        let sign = '';
        if ((typeof price === 'string') && (price[0] === "+" || price[0] === "-")) {
            sign = price[0];
            price = price.substr(1)
        }

        return sign + Helper.String.numberFormat(price, 2, '.', ' ');
    },

    /**
     * Получает строку интервала цен
     * @param {number} min
     * @param {number} max
     * @return {*}
     */
    getInterval(min, max) {
        if(min === max) {
            return this.format(min);
        }
        else
        {
            return `${this.format(min)} - ${this.format(max)}`;
        }
    }
};

export default PriceHelper
