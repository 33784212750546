import {PlannedCall} from "../../types/common";
import {useSelector} from "react-redux";
import {useMemo} from "react";
import {StateCallCenter} from "../../types/components";

interface Props {
    plannedCalls:  PlannedCall[]
    state: StateCallCenter
}

const useBranchesPlannedCalls = ({plannedCalls, state}: Props) => {
    const callCenterBranches = useSelector(state1 => state1.user.callCenterBranches)

    return useMemo(() => {
        const currentBranches = state.branches && state.branches.length ? state.branches : callCenterBranches
        const result = plannedCalls.map(plannedCall => ({
            ...plannedCall,
            tasks: plannedCall.tasks.filter(task => task.branches.some(branchId => currentBranches.includes(branchId)))
        }))
        return result.filter(plannedCall => !!plannedCall.tasks.length)
    }, [JSON.stringify(plannedCalls), JSON.stringify(state.branches), callCenterBranches])
}

export default useBranchesPlannedCalls
