import React, {forwardRef} from 'react';
import {PropsTable} from "../types/components";
import {SWrapTable, STable} from '../style/common'
import Header from "./Header";
import Rows from "./Rows";
import Total from "./Total";
import useIsScrolling from "../hooks/useIsScrolling";
import useSortTable from "../hooks/useSortTable";
import useHeightHeader from "../hooks/useHeightHeader";

export const Table = forwardRef((
    {
        header,
        isWrap = true,
        margin,
        isHovering = true,
        columns,
        customRowsCells,
        total,
        rows,
        height,
        variantTable = 'default',
        fontSizeRow,
        onScroll,
        subTable,
        ...props
    }: PropsTable, ref: any) => {

    const {isScrolling, _onScroll} = useIsScrolling({onScroll})
    const {stateColumnSort, handleStateColumnSort} = useSortTable({columns})
    const {refHeader, heightHeader} = useHeightHeader()

    return (
        <SWrapTable variantTable={variantTable} isWrap={isWrap} margin={margin} ref={ref}>
            {!!header && header}
            <STable isWrap={isWrap} isHeader={!!header} height={!!height ? `calc(${heightHeader} + ${height} + 4px)` : undefined}>
                <Header header={header} isWrap={isWrap} columns={columns} fontSizeRow={fontSizeRow}
                        variantTable={variantTable} isScrolling={isScrolling} stateColumnSort={stateColumnSort}
                        handleStateColumnSort={handleStateColumnSort} ref={refHeader} customRowsCells={customRowsCells}
                />
                <Rows variantTable={variantTable} isHovering={isHovering} stateColumnSort={stateColumnSort}
                      columns={columns} customRowsCells={customRowsCells} rows={rows} height={height} minHeight={!rows.length ? heightHeader : undefined} fontSizeRow={fontSizeRow} onScroll={_onScroll}
                      {...props}
                />
                <Total fontSizeRow={fontSizeRow}
                       total={total}
                       columns={columns}
                       rows={rows}
                       variantTable={variantTable}/>
            </STable>
            {!!subTable && subTable}
        </SWrapTable>
    );
});
