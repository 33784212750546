import React, {ChangeEvent, useState} from 'react';
import SVG from '../../../common/helpers/SVG';
import {WrapTimeCounter} from './styledTimeCounterNew';
import {StyledInput} from '../styledInput';
import {InputWrap} from '../TextField/textFieldStyled';
import DateHelper from '../../../common/helpers/date-helper';
import InputMask from 'react-input-mask';
import {useForceUpdate} from '../../../common/hooks/useForceUpdate';
import {useUpDownArrows} from '../../../common/hooks/useUpDownArrows';

interface PInputNumber {
    value: string,
    maxValue?: string,
    minValue?: string
    onChange: (value: string) => void,
    step: number
    isDisabledInput?: boolean
    isError?: boolean
    margin?: string
    widthInput?: string | number
}

export default function TimeCounterNew({
                                           value,
                                           minValue,
                                           maxValue = '23:59',
                                           onChange,
                                           step,
                                           isDisabledInput = false,
                                           isError,
                                           widthInput = '100px',
                                           margin,
                                       }: PInputNumber) {


    // Обработка клика на стрелки вверх и вниз, а также нажатия на стрелки вверх и вниз на клавиатуре

    const onClickUp = () => {
        let increasedMinutes = DateHelper.getMinutesByTime(value) + step;
        changeTime(DateHelper.formatMinutes(increasedMinutes));
    };

    const onClickDown = () => {
        let decreasedMinutes = DateHelper.getMinutesByTime(value) - step;
        changeTime(DateHelper.formatMinutes(decreasedMinutes));
    };

    const [focused, setFocused] = useState(false);

    useUpDownArrows(onClickUp, onClickDown, focused);

    // Сохранение времени при вводе на клавиатуре

    const forceUpdate = useForceUpdate();

    const onChangeTimeInput = (e: ChangeEvent<HTMLInputElement>) => {
        changeTime(e.target.value);
        // Принудительно обновляем компонент. Это нужно затем, чтобы инпут перерендеривался при многократной замене
        // значения инпута на одно и то же значение (минимальное или максимальное). Иначе внутри инпута остаётся
        // некорректное значение, из-за чего дальнейший ввод времени с клавиатуры может не приводить к изменению
        // значения инпута.
        forceUpdate();
    };

    /**
     * Изменяет время, предварительно проверяя на соответствие минимальному и максимальному значениям.
     * @param time
     */
    const changeTime = (time: string) => {
        if (minValue && time < minValue) {
            time = minValue;
        } else if (maxValue && time > maxValue) {
            time = maxValue;
        }

        onChange(time);
    };

    const maskInputProps = {
        mask: '99:59',
        maskChar: '0',
        formatChars: {
            '9': '[0-9]',
            '5': '[0-5]',
        },
        as: InputMask,
    } as any;

    return (
        <WrapTimeCounter>
            <InputWrap widthInput={widthInput} margin={margin}>
                <StyledInput placeholder={''}
                             width={widthInput}
                             disabled={isDisabledInput}
                             color='text'
                             value={value}
                             isError={isError}
                             {...maskInputProps}

                             onChange={onChangeTimeInput}
                             onFocus={() => setFocused(true)}
                             onBlur={() => setFocused(false)}
                />
            </InputWrap>
            <SVG name='triangle-fill'
                 onClick={!isDisabledInput ? onClickUp : () => {
                 }}
                 className='input-number-svg input-number-svg-up' />
            <SVG name='triangle-fill'
                 onClick={!isDisabledInput ? onClickDown : () => {
                 }}
                 className='input-number-svg input-number-svg-down' />
        </WrapTimeCounter>
    );
}
