import styled from "styled-components";

export const SResultSearchPatient = styled.div.attrs({className: 'ResultSearchPatient'})<any>`
    position: absolute;
    top: ${props => !!props.topPosition ? props.topPosition + 'px' : '52px'};
    left: 0;
    width: 100%;
    z-index: 30;
    min-width: ${props => !!props.minWidth ? props.minWidth : 'auto'};
    .loader {
        position: relative;
    }
`
