import React from 'react';
import Typography from "../../../../../../../../components-ui/DataDisplay/Typography/Typography";
import Helper from "../../../../../../../../common/helpers/main";
import PatientCard from "../../../../../../../../content/PatientCard/PatientCard";
import {IColumn} from "../../../../../../../../components-ui/DataDisplay/Table";
import {useDialogsManager} from "../../../../../../../../containers/DialogsManager/DialogsManager";

const useColumnsFailed = () => {
    const {showSlideModal} = useDialogsManager();
    return [
        {
            headerName: 'Пациент',
            field: 'patient',
            valueFormatter: ({childrenCell}) => (
                <Typography children={Helper.getFio(childrenCell)}
                            variant="small"
                            color="info"
                            as="span"
                            cursor="pointer"
                            onClick={() => showSlideModal(<PatientCard patientId={childrenCell.id}/>)}/>
            )
        },
        {
            headerName: 'Причина',
            field: 'reasonLabel',
            width: '400px'
        }
    ] as IColumn[]
};

export default useColumnsFailed;
