import React, {Dispatch, SetStateAction} from "react";
import LabelTask from "../../../../../../../containers/Tasks/components/UI/LabelTask";
import Helper from "../../../../../../../common/helpers/main";
import Select from "../../../../../../../components-ui/Inputs/Select/Select";
import Typography from "../../../../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../../../../../../containers/Tasks/data/commonPropsSComponent";
import PatientCard from "../../../../../../PatientCard/PatientCard";
import {useDialogsManager} from "../../../../../../../containers/DialogsManager/DialogsManager";
import {StateCallCenter} from "../../../../../types/components";
import {IncomingCallPatient} from "../../../../../types/taskData";
import {StateDetailed} from "../../../hooks/useDetailed";
import {OptionSelect} from "../../../../../../../components-ui/Inputs/Select/typesSelect";
import {STaskList, STaskItem} from "../../../../../../../containers/Tasks/style";
import {useSelector} from "react-redux";

interface Props {
    state: StateCallCenter
    patient: IncomingCallPatient | null
    detailedState: StateDetailed
    setDetailedState: Dispatch<SetStateAction<StateDetailed>>
    callerTypes: OptionSelect[]
}

const TopProgressCall = ({detailedState, state, setDetailedState, patient, callerTypes}: Props) => {
    const allBranches = useSelector(state1 => state1.common.allBranches)
    const {showSlideModal} = useDialogsManager();
    const {detail} = state;
    return (
        <STaskList padding="14px 0">
            {!!patient && (
                <STaskItem margin="2px 0">
                    <Typography {...commonPropsTypography} children="ФИО"/>
                    <Typography {...commonPropsTypography}
                                display="flex"
                                alignItems="center"
                                cursor="pointer"
                                color="info"
                                onClick={() => {
                                    showSlideModal(<PatientCard patientId={patient?.id}/>)
                                }}
                                children={Helper.getFullName(patient)}/>
                </STaskItem>
            )}
            {!!patient && (
                <LabelTask label="Возраст"
                           children={!!patient.personalBirthday ? Helper.Date.getAge(patient.personalBirthday) : 'не указан'}/>
            )}
            <LabelTask label="Тип"
                       children={(!!detail && detail.canSaveResult) ? (
                           <Select value={detailedState.selectedType}
                                   onChange={selectedType => {
                                       setDetailedState(prevState => ({
                                           ...prevState,
                                           selectedType,
                                           validationAfterSave: true
                                       }))
                                   }}
                                   options={callerTypes}
                                   sizeSelect="sm"
                                   variant="link"/>
                       ) : detailedState.selectedType?.label}
            />
            {!!patient && <LabelTask label="Баланс" children={`${Helper.Price.format(patient.account)} руб.`}/>}
            {!!patient && !!patient.branches &&
                patient.branches.map((branchId, index) => (
                    <LabelTask label={index === 0 ? "Филиалы" : ""}
                               key={branchId}
                               children={allBranches[branchId].name}/>
                ))
            }
        </STaskList>
    )
}

export default TopProgressCall
