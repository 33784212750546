import React, {Dispatch, SetStateAction} from 'react';
import Box from '../../../../../../components-ui/Layout/Box';
import {ModalAddTimesheetScheduleItem, ModalAddTimesheetState} from '../../types';
import {Label} from '../../../../../../components-ui/DataDisplay/Label';
import TimeCounterNew from '../../../../../../components-ui/Inputs/TimeCounterNew/TimeCounterNew';
import DateHelper from '../../../../../../common/helpers/date-helper';
import Button from '../../../../../../components-ui/Inputs/Button/Button';
import Icon from '../../../../../../components-ui/DataDisplay/Icon';
import Scrollbar from '../../../../../../components-ui/DataDisplay/Scrollbar/Scrollbar';
import Checkbox from '../../../../../../components-ui/Inputs/Checkbox/Checkbox';

interface Props {
    state: ModalAddTimesheetState;
    setState: Dispatch<SetStateAction<ModalAddTimesheetState>>;
    setPage: Dispatch<SetStateAction<number>>;
    loader: boolean;
    saveEmployeeSchedule: () => void;
}

interface HandleTime {
    timesheet: ModalAddTimesheetScheduleItem,
    value: string
}

interface HandleIsNotWorking {
    timesheet: ModalAddTimesheetScheduleItem,
    value: boolean
}

const AddTimesheetMarkTime = ({state, setState, setPage, loader, saveEmployeeSchedule}: Props) => {

    const handleStartTime = ({timesheet, value}: HandleTime) => {
        setState(prevState => ({
            ...prevState,
            schedule: prevState.schedule.map(prevTimesheet => prevTimesheet.date === timesheet.date ?
                {...prevTimesheet, startTime: value} : {...prevTimesheet},
            ),
        }));
    };

    const handleEndTime = ({timesheet, value}: HandleTime) => {
        setState(prevState => ({
            ...prevState,
            schedule: prevState.schedule.map(prevTimesheet => prevTimesheet.date === timesheet.date ?
                {...prevTimesheet, endTime: value} : {...prevTimesheet},
            ),
        }));
    };

    const handleIsNotWorkingDay = ({timesheet, value}: HandleIsNotWorking) => {
        setState(prevState => ({
            ...prevState,
            schedule: prevState.schedule.map(prevTimesheet => prevTimesheet.date === timesheet.date ?
                {...prevTimesheet, isNotWorking: value} : {...prevTimesheet},
            ),
        }));
    };

    return (
        <>
            <Scrollbar height='460px'>
                {
                    state.schedule.map(timesheet => {
                        return <Label
                            widthLabel='31%'
                            marginWrap='0 0 20px'
                            widthLabelWrap="600px"
                            isRequired={true}
                            labelText={`${DateHelper.formatDate(String(timesheet.date), 'DD MMM YYYY')} ${new Intl.DateTimeFormat('ru-RU', {weekday: "short"}).format(timesheet.date)}.`}
                        >
                            <Box display='flex' gap='16px' alignItems='center'>
                                <TimeCounterNew value={timesheet.startTime}
                                                maxValue={timesheet.endTime}
                                                onChange={(value) =>
                                                    handleStartTime({timesheet, value})}
                                                step={5}
                                                isDisabledInput={timesheet.isNotWorking}
                                />
                                <TimeCounterNew value={timesheet.endTime}
                                                minValue={timesheet.startTime}
                                                onChange={(value) =>
                                                    handleEndTime({timesheet, value})}
                                                step={5}
                                                isDisabledInput={timesheet.isNotWorking}
                                />
                                <Checkbox checked={timesheet.isNotWorking}
                                          label='Нерабочий день'
                                          onChange={(value) => handleIsNotWorkingDay({timesheet, value})}
                                />
                            </Box>
                        </Label>;
                    })
                }
            </Scrollbar>
            <Box margin='20px'>
                <Label labelText=''
                       widthLabelWrap="600px"
                       widthLabel='166px'
                       marginWrap='0 0 20px'>
                    <Box display='flex' gap='16px'>
                        <Button isIcon variant='default'
                                height='40px'
                                width='40px'
                                children={<Icon name={'arrowThin'} width='10px' height='10px' />}
                                onClick={() => {
                                    setPage(1);
                                }}
                        />
                        <Button children='Сохранить'
                                size='md'
                                variant={state.employee ? 'success' : 'disabled'}
                                loader={loader}
                                onClick={saveEmployeeSchedule}
                        />
                    </Box>
                </Label>
            </Box>
        </>
    );
};

export default AddTimesheetMarkTime;
