import {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {Article, IDataPatientPriceList, IServicesGroup} from "../types";
import {ServiceGroup} from "../../../containers/TreatmentPlansConstructor/types";
import _ from "lodash";

interface Props {
    data: IDataPatientPriceList
    setOtherArticles: Dispatch<SetStateAction<Article[]>>
}

export const usePriceList = ({data, setOtherArticles}: Props) => {
    const [search, setSearch] = useState<string>('');

    const [visibleArticlesNumbers, setVisibleArticlesNumbers] = useState<number[]>([])

    useEffect(() => {
        setOtherArticles(
            data.otherArticles.filter(article => (
                visibleArticlesNumbers.includes(article.id)
            ))
        )
    }, [visibleArticlesNumbers])

    const initialServicesGroups: IServicesGroup[] = useMemo(() => {
        const recursionMapGroups = (groups: ServiceGroup[]): IServicesGroup[] => {
            return groups.map(group => {

                if (group.groups && group.groups.length) {
                    group.groups = recursionMapGroups(group.groups)
                }

                const findServices = data.services.filter(service => service.groupId === group.id)
                return {
                    ...group,
                    services: findServices,
                    isOpen: false
                }
            })
        }

        return recursionMapGroups(data.servicesGroups)
    }, [data.services, data.servicesGroups])

    const [servicesGroups, setServicesGroups] = useState<IServicesGroup[]>(_.cloneDeep(initialServicesGroups))

    const onClickGroup = (id: number) => {
        setServicesGroups(prevState => {
            const recursionGroups = (groups: IServicesGroup[]) => {
                groups.forEach((group) => {
                    if (group.id === id) {
                        group.isOpen = !group.isOpen
                        if (group.articlesIds && group.articlesIds.length) {
                            setVisibleArticlesNumbers(prevVisibleArticles => {
                                return _.uniq([
                                    ...prevVisibleArticles,
                                    //@ts-ignore
                                    ...group.articlesIds
                                ])
                            })
                        }
                    }

                    if (group.groups && group.groups.length) {
                        recursionGroups(group.groups as IServicesGroup[])
                    }
                })
            }
            recursionGroups(prevState)

            return [
                ...prevState,

            ]
        })
    }
    const onResetTable = () => {
        setSearch("")
        setServicesGroups(_.cloneDeep(initialServicesGroups))
    }

    return {search, setSearch, servicesGroups, onClickGroup, onResetTable}
}