import {Dispatch, SetStateAction, useEffect} from "react";
import Helper from "../../../../../common/helpers/main";
import {Tab} from "../../../../../components-ui/Navigation/Tabs/elements/Tab/typesTab";
import {useSelector} from "react-redux";
import {StateDetailed} from "./useDetailed";
import {StateCallCenter} from "../../../types/components";

interface PropsInitialIncoming {
    detailedState: StateDetailed
    setDetailedState: Dispatch<SetStateAction<StateDetailed>>
    state: StateCallCenter
    setScriptsType: Dispatch<SetStateAction<string | null>>
}
const useInitialProgressCall = ({state, detailedState, setScriptsType, setDetailedState}: PropsInitialIncoming) => {

    const user = useSelector(state => state.user);
    const marketingAdmin = user.role === 'marketing-admin';

    useEffect(() => {
        if (!!detailedState.progressCall.length) {
            const phoneOwner = !!state.detail ? state.detail.phoneOwner : null
            if (detailedState.progressCall[detailedState.progressCall.length - 1].payload.patients.length > 1 && !!phoneOwner) {
                const optionsTabsPatients = detailedState.progressCall[detailedState.progressCall.length - 1].payload.patients.map((patient: any) => {
                    return {
                        code: patient.id,
                        name: Helper.getFio(patient)
                    }
                }) as Tab[]

                const tabPatient = optionsTabsPatients.find(option => option.code === phoneOwner.id) as Tab

                setDetailedState(prevState => ({...prevState, optionsTabsPatients, tabPatient: tabPatient.code}))
            } else {
                setDetailedState(prevState => ({...prevState, tabPatient: null, optionsTabsPatients: null}))
            }
        }

    }, [JSON.stringify(detailedState.progressCall)])

    useEffect(() => {
        if (!detailedState.progressCall.length) {
            setDetailedState(prevState => ({...prevState, tabPatient: null, optionsTabsPatients: null}))
            if (!marketingAdmin && !state.isMarketing) setScriptsType(null);
        }
    }, [JSON.stringify(detailedState.progressCall)])
}

export default useInitialProgressCall
