import React from 'react'
import {Popper, Reference, Manager} from 'react-popper'
import PropTypes from 'prop-types'
import CellContextMenu from './CellContextMenu'
import CellDetailInfo from './CellDetailInfo'
import NewVisitForm from '../../../../content/NewVisitForm/NewVisitForm'
import ServerCommand from '../../../../common/server/server-command'
import Helper from '../../../../common/helpers/main'
import PatientCard from "../../../../content/PatientCard/PatientCard";
import Payment from "../../../../content/Payment/Payment";
import ReactDOM from "react-dom";
import Cancellation from "../../../../content/modals/Cancellation/Cancellation";
import UpdateComment from "../../../../content/modals/UpdateComment/UpdateComment";
import Transfer from "../../../../content/modals/Transfer/Transfer";
import moment from 'moment/moment';
import {connect} from "react-redux";
import {addMemorizedPatient, removeMemorizedPatient} from "../../../../redux/actions/scheduleActions";
import _ from 'underscore'
import {DialogsManagerContext} from "../../../../containers/DialogsManager/DialogsManager";

class CellMenu extends React.Component {
    static contextType = DialogsManagerContext;
    static propTypes = {
        renderCell: PropTypes.func.isRequired,
        getCellNode: PropTypes.func.isRequired,
        commands: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
        clientCommands: PropTypes.array,
        detailInfo: PropTypes.any,
        cellType: PropTypes.string.isRequired,
        onCommandResult: PropTypes.func,
        timeStart: PropTypes.string,
        timeEnd: PropTypes.string,
        chairId: PropTypes.number,
        doctorId: PropTypes.number,
        date: PropTypes.string,
        update: PropTypes.func,
        visitId: PropTypes.number,
        patientId: PropTypes.number,
        isResize: PropTypes.bool,
        copiedPatient: PropTypes.any,
        canPatientPhone: PropTypes.bool,
        transferPatient: PropTypes.any,
        onCopyPatient: PropTypes.func,
        isSocial: PropTypes.any,
        isPrimary: PropTypes.any,
        changeTimeHighlight: PropTypes.func,
        currentTime: PropTypes.string,
        currentDate: PropTypes.string,
        comment: PropTypes.string,
        clearPatientsBuffer: PropTypes.func.isRequired
    };

    state = {
        isHovered: false,
        showContextMenu: false,
        showDetailInfo: true,
        commands: null,
        elementWidth: null,
        windowWidth: null,
        isReversMenu: false
    };

    popperSettings = {
        placement: 'bottom-start',
        modifiers: {
            preventOverflow: {
                enabled: true,
                boundariesElement: document.body
            },
            offset: {
                enabled: true
            }
        }
    };

    constructor(props) {
        super(props);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            elementWidth: ReactDOM.findDOMNode(this).getBoundingClientRect().width,
            windowWidth: window.innerWidth
        });
    }


    render() {
        const {timeStart, timeEnd} = this.props;
        const commands = _.clone(this.state.commands);

        let cellPropsMixin = {
            onMouseEnter: this.handleHover.bind(this, true, timeStart, timeEnd),
            onMouseLeave: this.handleHover.bind(this, false, null, null),
            onContextMenu: this.handleContextMenu.bind(this),
            onClick: this.handleCellClick.bind(this)
        };

        this.popperUpdate = null;

        if (!this.isShown()) return this.props.renderCell(cellPropsMixin);

        let popupClassName = ' dayCalendar_popup';
        if (this.needShowContextMenu()) {
            popupClassName += ' context-menu-shown';
        }
        if (this.state.isReversMenu) {
            popupClassName += ' dayCalendar_popup--reverse'
        }

        const popper = (
            <Popper {...this.popperSettings}>
                {({ref, style, placement, scheduleUpdate}) => {
                    this.popperUpdate = scheduleUpdate;
                    return (
                        <>
                            {!this.props.isResize &&
                            <div ref={ref} style={style} x-placement={placement} className={popupClassName}>
                                {this.needShowContextMenu() && (
                                    <CellContextMenu commands={commands.sort(this.sortContextMenu)}
                                                     onShowActionVariants={() => {
                                                         this.setState({showDetailInfo: false});
                                                         this.popperUpdate();
                                                     }}
                                                     onHideActionVariants={() => {
                                                         this.setState({showDetailInfo: true});
                                                         this.popperUpdate();
                                                     }}
                                                     onCommandExec={this.handleMenuAction.bind(this)}
                                                     timeStart={this.props.timeStart}
                                                     timeEnd={this.props.timeEnd}
                                                     chairId={this.props.chairId}
                                                     date={this.props.date}

                                    />
                                )}
                                {this.needShowDetailInfo() && (
                                    <CellDetailInfo {...this.props.detailInfo} comment={this.props.comment} canPatientPhone={this.props.canPatientPhone}/>
                                )}
                                <div className="dClndr_parrow"/>
                            </div>
                            }
                        </>
                    );
                }}
            </Popper>
        );

        return (
            <Manager>
                <Reference>
                    {({ref}) => {
                        cellPropsMixin.ref = ref;
                        return this.props.renderCell(cellPropsMixin, popper);
                    }}
                </Reference>
            </Manager>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /* вешаем / снимаем обработчик, который будет фиксировать клики вне меню */
        let isShown = this.isShown();
        let wasShown = this.isShown(prevState);

        if (isShown !== wasShown) {
            if (isShown) {
                document.addEventListener('mousedown', this.handleOutsideClick);
            } else {
                document.removeEventListener('mousedown', this.handleOutsideClick);
            }
        }

        /* обновляем позицию Popper */
        let isShownContextMenu = this.needShowContextMenu();
        let wasShownContextMenu = this.needShowContextMenu(prevState);
        if (isShownContextMenu && !wasShownContextMenu && this.popperUpdate) {
            this.popperUpdate();
        }
        /**/
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    /**
     * Получает список команд путем фильтрации полного списка команд из свойств компонента в зависимости от типа ячейки cellType
     * @returns {Array}
     */
    getCommands() {
        let result = [];
        let visitCommands = ['visit/payment-form', 'visit/start', 'visit/reject', 'visit/update-comment', 'visit/postpone', 'visit/confirm'];
        let isVisit = (this.props.cellType === 'visit');

        Helper.forEachObj(this.props.commands, entityCommands => {
            entityCommands.forEach(commandCode => {
                if (visitCommands.indexOf(commandCode) !== -1) {
                    if (isVisit) {
                        result.push(commandCode);
                    }
                } else if (!isVisit) {
                    result.push(commandCode);
                }
            });
        });

        return result;
    }

    getClientCommands(commandsFromServer = []) {
        let clientCommands = [];

        let bCanUnite = false;
        let bCanSplit = false;
        let intervals = {};

        /* добавляем команды "Открыть карту пациента" и "Запомнить пациента" */

        if (this.props.cellType === 'visit') {
            clientCommands.push({
                code: 'open-patient-card',
                name: 'Открыть карту пациента',
                available: true
            });
            clientCommands.push({
                code: 'copy-patient',
                name: 'Запомнить пациента',
                available: true
            });
        }

        /* добаляем команду "Записать Фамилия И. О." */

        if (this.props.cellType !== 'visit' && this.props.doctorId !== 0) {

            if (this.props.copiedPatient !== null) {
                if (this.props.date >= this.props.currentDate) {
                    let nameCopiedPatient = Helper.getFio(this.props.copiedPatient.patient)
                    if (!this.props.copiedPatient.patient.id) {
                        nameCopiedPatient = this.props.copiedPatient.patient.cellName;
                    }
                    if (this.props.date === this.props.currentDate) {
                        if (this.props.currentTime < this.props.timeEnd) {
                            clientCommands.push({
                                code: 'paste-patient',
                                name: 'Записать ' + nameCopiedPatient,
                                available: true
                            });
                        }
                    } else {
                        clientCommands.push({
                            code: 'paste-patient',
                            name: 'Записать ' + nameCopiedPatient,
                            available: true
                        });
                    }

                }
            }

            const allowedVisitAdd = commandsFromServer.some(command => command.available && (command.code === 'visit/add'));

            if (!!this.props.transferPatient && allowedVisitAdd) {
                clientCommands.push({
                    code: 'visit/finish-postpone',
                    name: 'Завершить перенос ' + Helper.getFio(this.props.transferPatient.patient),
                    available: true
                });
            }
        }

        /* добавляем команды "Разделить интервал","Объединить интервал" */

        Helper.forEachObj(this.props.timeLine, (timeLineItem, time) => {
            let standardIntervalTime = Helper.Date.getStandardIntervalTime(time);
            intervals[standardIntervalTime] = true;


            if (!!this.props.availableTimeUnite && (this.props.availableTimeUnite.indexOf(standardIntervalTime) !== -1)) {
                bCanUnite = true;
            }

            if (timeLineItem.type === 'standard') {
                bCanSplit = true;
            }

        }, this.props.timeStart, this.props.timeEnd);

        let intervalsCount = Object.keys(intervals).length;
        let intervalWord = 'интервал' + ((intervalsCount > 1) ? 'ы' : '');

        if (bCanSplit) {
            clientCommands.push({
                code: 'split-interval',
                name: 'Разделить ' + intervalWord,
                available: true
            });
        }

        if (bCanUnite) {
            clientCommands.push({
                code: 'unite-interval',
                name: 'Объединить ' + intervalWord,
                available: true
            });
        }


        return clientCommands;
    }

    /**
     * Отображает контекстное меню.
     * Предварительно уточняет на сервере какие команды из списка доступны для выпонения в данный момент для данной ячейки.
     */
    showContextMenu() {
        let commands = this.getCommands();

        if (commands.length) {
            commands = commands.map(commandCode => {
                let commandInfo = {};

                commandInfo.code = commandCode;
                commandInfo.params = {
                    timeStart: this.props.timeStart,
                    timeEnd: this.props.timeEnd,
                    chairId: this.props.chairId,
                    date: this.props.date,
                };

                switch (commandCode) {
                    case 'schedule/change-doctor':
                        commandInfo.params = {
                            timeStart: this.props.timeStart,
                            timeEnd: this.props.timeEnd,
                            chairId: this.props.chairId,
                            date: this.props.date,
                        };
                        commandInfo.varyParam = 'doctorId';
                        break;

                    case 'visit/add':
                        commandInfo.params = {
                            timeStart: this.props.date + ' ' + this.props.timeStart,
                            timeEnd: this.props.date + ' ' + this.props.timeEnd,
                            workChairId: this.props.chairId,
                        };
                        commandInfo.varyParam = 'patientId';
                        break;

                    case 'visit/start':
                    case 'visit/confirm':
                    case 'visit/payment-form':
                        commandInfo.params = {visitId: this.props.visitId};
                        break;

                    case 'visit/update-comment':
                        commandInfo.params = {
                            visitId: this.props.visitId,
                            comment: ''
                        };
                        break;

                    case 'visit/reject':
                        commandInfo.params = {visitId: this.props.visitId};
                        commandInfo.varyParam = 'reason';
                        break;

                    case 'visit/postpone':
                        commandInfo.params = {
                            visitId: this.props.visitId,
                            date: moment().format('YYYY-MM-DD'),
                            timeStart: '00:00',
                            chairId: this.props.chairId
                        };
                        commandInfo.varyParam = 'reason';
                        break;
                    default:
                        break;
                }

                return commandInfo;
            });

            let commandData = {
                'commands': commands
            };

            ServerCommand.get('schedule/available-commands', commandData).then(response => {
                if (response) {
                    let hasAvailableCommands = false;
                    response = response.concat(this.getClientCommands(response));


                    response.forEach(command => {
                        if (command.available) {
                            hasAvailableCommands = true;
                        }
                    });

                    if (hasAvailableCommands) {
                        this.setState({
                            commands: response,
                            showContextMenu: true
                        });
                    }
                }
            });
        } else {
            let clientCommands = this.getClientCommands();

            if (clientCommands.length) {
                this.setState({
                    commands: clientCommands,
                    showContextMenu: true
                });
            }
        }
    }

    hide() {
        this.setState({
            showContextMenu: false,
            showDetailInfo: true
        });
    }

    isShown(state = null) {
        return (this.needShowContextMenu(state) || this.needShowDetailInfo(state));
    }

    needShowDetailInfo(state = null) {
        state = state || this.state;
        return (state.showDetailInfo && !!this.props.detailInfo && (state.isHovered || state.showContextMenu));
    }

    needShowContextMenu(state = null) {
        state = state || this.state;
        return (state.showContextMenu && !!state.commands);
    }

    handleContextMenu(e) {
        e.preventDefault();
        if (this.state.showContextMenu) {
            this.setState({
                showContextMenu: false,
                isReverseMenu: false

            })
        } else {
            const windowWidth = Helper.clone(this.state.windowWidth);
            const elementWidth = Helper.clone(this.state.elementWidth);
            this.showContextMenu();
            if (elementWidth + e.pageX + 220 >= windowWidth) {
                this.setState({isReversMenu: true})
            }
        }
    }

    handleCellClick() {
        if (this.state.showActions) {
            this.setState({
                showContextMenu: false
            });
        }
    }

    handleOutsideClick(e) {
        const cellNode = this.props.getCellNode();
        if (cellNode && !cellNode.contains(e.target)) {
            this.hide();
        }
    }

    handleHover(isHovered, timeStart, timeEnd, e) {
        this.props.changeTimeHighlight(timeStart, timeEnd);
        this.setState({isHovered});
    }

    showPopup(popupContent) {
        this.setState({popup: popupContent})
    }


    /* ---------------------------- обработка пунктов контекстного меню --------------------------------- */

    handleMenuAction(commandCode, variantCode) {
        let specificMethodName = 'process' + Helper.getCamelCase(commandCode);

        if (typeof this[specificMethodName] === 'function') {
            this[specificMethodName](variantCode);
        } else {
            let command = new ServerCommand(commandCode, this.getCommandData(commandCode, variantCode), response => {
                this.onCommandDone(commandCode);
            });

            command.exec();
        }

        this.setState({
            showContextMenu: false,
            showDetailInfo: true
        });
    }


    getCommandData(commandCode, variantCode) {
        let data = {
            timeStart: this.props.timeStart,
            timeEnd: this.props.timeEnd,
            chairId: this.props.chairId,
            date: this.props.date
        };

        switch (commandCode) {
            case 'schedule/change-doctor':
                data.doctorId = variantCode;
                break;

            case 'visit/add':
                data.patientId = variantCode;
                break;

            case 'visit/start':
            case 'visit/confirm':
            case 'visit/payment-form':
                data = {visitId: this.props.visitId};
                break;
            default:
                break;
        }

        return data;
    }

    onCommandDone(commandCode, result = null) {
        if (this.props.onCommandResult) {
            this.props.onCommandResult(commandCode, result);
        }
    }

    processVisitAdd() {
        const {timeStart, chairId, timeEnd, date, doctorId} = this.props;
        let popupId = null;
        _.each(this.context.slideModals, (slideModal, index) => {
            if (!!slideModal.keySlideModal && slideModal.keySlideModal === timeStart + chairId) {
                popupId = index
            }
        })
        if (!!popupId) {
            this.context.openSlideModal(popupId)
        } else {
            this.context.showSlideModal(<NewVisitForm chairId={chairId}
                                                      timeStart={timeStart}
                                                      timeEnd={timeEnd}
                                                      date={date}
                                                      doctorId={doctorId}
                                                      onSuccessSubmit={this.closeNewVisitForm.bind(this, true)}
                                                      onClose={this.closeNewVisitForm.bind(this, false)}
            />)
        }
    }

    processSplitInterval() {
        this.props.splitInterval(this.props.timeStart);
    }

    processUniteInterval() {
        this.props.uniteInterval(this.props.timeStart);
    }

    processOpenPatientCard() {
        this.context.showSlideModal(<PatientCard patientId={this.props.patientId}/>)
    }

    onCopyPatient(transfer) {
        const {isSocial, visitId} = this.props;

        const patientInfo = Object.assign({isSocial, visitId}, this.props.detailInfo);

        this.props.onCopyPatient(patientInfo, transfer);
    }

    processVisitPostpone() {
        this.onCopyPatient(true)
    }

    processVisitFinishPostpone() {
        this.context.showModal(<Transfer
            patient={this.props.transferPatient.patient}
            date={this.props.date}
            timeStart={this.props.timeStart}
            visitId={this.props.transferPatient.visitId}
            chairId={this.props.chairId}
            onTransferDone={() => this.props.clearPatientsBuffer(true)}
        />)
    }

    processCopyPatient() {
        const {isSocial, visitId, addMemorizedPatient} = this.props;
        const patientInfo = Object.assign({isSocial, visitId}, this.props.detailInfo);
        addMemorizedPatient(patientInfo);
    }

    processPastePatient() {
        let {
            copiedPatient: {
                timeStart: oldTimeStart,
                timeEnd: oldTimeEnd,
                isSocial,
                patient: {id: patientId},
                onEndMemorized,
                isOpenNewVisitForm
            },
            chairId: workChairId,
            date,
            timeStart,
            timeEnd
        } = this.props;

        oldTimeEnd = oldTimeEnd.split(':');
        oldTimeStart = oldTimeStart.split(':');
        const oldVisitTime = ((+oldTimeEnd[0] * 60) + +oldTimeEnd[1]) - ((+oldTimeStart[0] * 60) + +oldTimeStart[1]);

        const newTimeStart = timeStart.split(':');
        const newTimeEnd = timeEnd.split(':');
        const newVisitTime = ((+newTimeEnd[0] * 60) + +newTimeEnd[1]) - ((+newTimeStart[0] * 60) + +newTimeStart[1]);

        if (oldVisitTime === 15 && newVisitTime !== 15) {
            timeEnd = timeEnd.split(':');
            timeEnd = (+timeEnd[0] * 60) + +timeEnd[1] - 15;

            let timeEndHours = Math.floor(timeEnd / 60);
            let timeEndMinutes = timeEnd % 60;

            if (String(timeEndHours).length === 1) {
                timeEndHours = '0' + String(timeEndHours)
            }
            if (String(timeEndMinutes).length === 1) {
                timeEndMinutes = '0' + String(timeEndMinutes)
            }

            timeEnd = String(timeEndHours) + ':' + String(timeEndMinutes)

        }
        if (!patientId || isOpenNewVisitForm) {
            this.context.showSlideModal(<NewVisitForm chairId={this.props.chairId}
                                                      timeStart={this.props.timeStart}
                                                      timeEnd={this.props.timeEnd}
                                                      date={this.props.date}
                                                      doctorId={this.props.doctorId}
                                                      copiedPatient={this.props.copiedPatient.patient}
                                                      onEndMemorized={onEndMemorized}
                                                      onSuccessSubmit={this.closeNewVisitForm.bind(this, true)}
                                                      onClose={this.closeNewVisitForm.bind(this, false)}
            />)
        } else {
            const command = new ServerCommand('visit/add', {
                timeStart: date + ' ' + timeStart + ':00',
                timeEnd: date + ' ' + timeEnd + ':00',
                isSocial: !!isSocial,
                patientId,
                workChairId
            });
            command.exec().then(() => (!!onEndMemorized) && onEndMemorized());
        }

    }

    processScheduleChangeDoctor(variantCode) {
        // Запускаем изменение врача в расписании, и не запускаем после этого запрос на обновление расписания.
        // Расписание итак обновится при обработке серверного события ScheduleUpdated
        const commandCode = 'schedule/change-doctor';
        ServerCommand.post(commandCode, this.getCommandData(commandCode, variantCode));
    }


    processVisitGetPaymentForm() {
        this.context.showSlideModal(<Payment visitId={this.props.visitId}/>)
    }

    processVisitReject() {
        this.context.showModal(<Cancellation visitId={this.props.visitId}/>)
    }

    processVisitUpdateComment() {
        // здесь вызвать окно добавления комментария к приёму
        this.context.showModal(<UpdateComment visitId={this.props.visitId} className='comment'/>)
    }

    closeNewVisitForm(bUpdate) {
        if (bUpdate && this.props.update && Helper.isDev()) {
            this.props.update();
        }
    }

    sortContextMenu = (a, b) => {
        if (a.name < b.name) //сортируем строки по возрастанию
            return -1;
        if (a.name > b.name)
            return 1;
        return 0 // Никакой сортировки
    }
}

const mapStateToProps = ((state) => ({
    copiedPatient: state.schedule.copiedPatient,
    canPatientPhone: state.user.canPatientPhone
}));

const mapDispatchToProps = {
    addMemorizedPatient,
    removeMemorizedPatient
};

export default connect(mapStateToProps, mapDispatchToProps)(CellMenu)
