import {useMemo, useRef, useState} from "react";
import useInitialCallCenter from "./useInitialCallCenter";
import useDeleteTaskCalls from "../useDeleteTaskCalls";
import useEchoCalls from "../useEchoCalls";
import useGroupsButtonName from "../useGroupsButtonName";
import useChangePlannedCalls from "./useChangePlannedCalls";
import {useScripts} from "../../components/Scripts";
import useChangeCalls from "./useChangeCalls";
import usePanelCalls from "./usePanelCalls";
import useCallRecovery from "./useCallRecovery";
import useChangeRaisedCalls from "./useChangeRaisedCalls";
import {StateCallCenter} from "../../types/components";
import {useSelector} from "react-redux";
import useMarketingPlannedCalls from "./useMarketingPlannedCalls";
import useNoticeQuantity from "./useNoticeQuantity";
import useMethodsCallCenter from "../useMethodsCallCenter";
import _ from "lodash";
import {callCenterTasksInfo} from "../../helper/getDataCallCenter";
import {allTypesOfTasks} from "../../../../containers/Tasks/data/allTypesOfTasks";
import useHandlersState from "./useHandlersState";

const useCalls = () => {
    const user = useSelector(state => state.user);
    const isMarketingAdmin = user.role === 'marketing-admin';

    const refScrollList = useRef(null) as any;

    const [state, setState] = useState<StateCallCenter>({
        heightFilter: 0,
        selectedFilter: 'all',
        plannedCalls: [],
        detail: null,
        calls: [],
        panelCalls: [],
        raisedCalls: [],
        searchFilter: '',
        isMarketing: isMarketingAdmin,
        branches: user.callCenterBranches
    })

    const tasksInfo = useMemo(() => _.assign(_.cloneDeep(callCenterTasksInfo), {all: allTypesOfTasks}), [])

    const {infoCallCenter, refreshDataFromServer, loader} = useInitialCallCenter({setState, state})

    const marketingPlannedCalls = useMarketingPlannedCalls({infoCallCenter, state})

    const {
        marketingNoticeQuantity,
        notMarketingNoticeQuantity,
        noticesQuantity,
        noticeQuantityTotal
    } = useNoticeQuantity({state, infoCallCenter})

    const groupsButtonName = useGroupsButtonName({state, infoCallCenter, tasksInfo, noticesQuantity})

    const propsScripts = useScripts({state, infoCallCenter})

    const deleteTaskCalls = useDeleteTaskCalls({setState})

    const methodsCallCenter = useMethodsCallCenter(deleteTaskCalls)

    const handlersState = useHandlersState({setState})

    useEchoCalls({setState, state})

    useChangePlannedCalls({state, setState, marketingPlannedCalls, noticeQuantityTotal})

    useChangeCalls({
        refScrollList,
        marketingPlannedCalls,
        onChangeSelectedScript: propsScripts.onChangeSelectedScript,
        state,
        setState
    })

    usePanelCalls({state, setState})

    useCallRecovery({state, setState, marketingPlannedCalls})

    useChangeRaisedCalls({state, setState})

    return {
        refScrollList,
        state,
        setState,
        tasksInfo,
        infoCallCenter,
        marketingPlannedCalls,
        marketingNoticeQuantity,
        notMarketingNoticeQuantity,
        noticesQuantity,
        noticeQuantityTotal,
        groupsButtonName,
        propsScripts,
        methodsCallCenter,
        handlersState,
        refreshDataFromServer,
        loader
    }
}

export default useCalls
