import React, {useEffect, useMemo, useState} from "react";
import {PServiceTable} from "../ItemTable/typesItemTable";
import {WrapCheckbox} from "../ItemTable/styledItemTable";
import Checkbox from "../../../../../../../../../components-ui/Inputs/Checkbox/Checkbox";
import {
    WrapServiceTable,
    ServiceCode,
    ServiceDoctor,
    ServiceService,
    ServiceTarget,
    ServiceDoctorSelect,
    WrapCheckServiceDoctor
} from "./styledServiceTable";
import Select from "../../../../../../../../../components-ui/Inputs/Select/Select";
import {OptionDoctor} from "../../typesTableRecommendedServices";
import {canPerformService} from "../../../../../../../../../common/helpers/Doctor";
import useHover from "../../../../../../../../../common/hooks/useHover";

export default function ServiceTable(
    {
        service,
        optionsDoctors,
        indexGroup,
        indexService,
        handleServiceDoctor,
        handleCheckService,
        groupActions,
        checkboxGroupActions,
        setCheckboxGroupActions,
        selectedDoctorGroupActions,
        optionsDoctorsGroup,
        onChangeDoctorGroupActions
    }: PServiceTable) {
    const [refHover, hover, setHover] = useHover();
    const [refCheckbox, hoverCheckbox, setHoverCheckbox] = useHover();

    const checkboxGroupActionsJSX = <Checkbox label=""
                                              checked={checkboxGroupActions[service.id]}
                                              onChange={(value) => {
                                                  setCheckboxGroupActions(prevState => {
                                                      return {
                                                          ...prevState,
                                                          [service.id]: value
                                                      }
                                                  })
                                              }}/>

    let childrenWrapCheckGroupActionsJSX = checkboxGroupActionsJSX;

    if (hoverCheckbox && checkboxGroupActions[service.id]) {
        childrenWrapCheckGroupActionsJSX = (
            <>
                <Select variant="colored"
                        sizeSelect="sm"
                        colorSelect="white"
                        value={selectedDoctorGroupActions}
                        options={optionsDoctorsGroup}
                        onChange={(doctor) => {
                            onChangeDoctorGroupActions(doctor);
                            setHoverCheckbox(false)
                        }}
                        placeholder="не выбран"
                        widthSelect="260px" />
                {checkboxGroupActionsJSX}
            </>
        )
    }

    return (
        <WrapServiceTable ref={refHover}>
            <WrapCheckbox>
                <Checkbox label={''}
                          checked={service.checked}
                          onChange={(checked: boolean) => handleCheckService(checked, indexGroup, indexService)}/>
            </WrapCheckbox>
            <ServiceCode>
                {service.code}
            </ServiceCode>
            <ServiceService>
                {service.name}
            </ServiceService>
            <ServiceTarget>
                {service.target}
            </ServiceTarget>
            {((!hover && service.doctor !== null) || groupActions) && (
                <ServiceDoctor>
                    {groupActions && <WrapCheckServiceDoctor ref={refCheckbox}
                                                             active={hoverCheckbox && checkboxGroupActions[service.id]}
                                                             children={childrenWrapCheckGroupActionsJSX}/>}
                    {!!service.doctor ? service.doctor.label : '-'}
                </ServiceDoctor>
            )}
            {((hover || service.doctor === null) && !groupActions) && (
                <ServiceDoctorSelect>
                    <Select options={optionsDoctors.filter((doctor: any) => canPerformService(doctor, service))}
                            variant="colored"
                            sizeSelect="sm"
                            colorSelect="white"
                            widthSelect="260px"
                            value={service.doctor}
                            placeholder="не выбран"
                            onChange={(doc: OptionDoctor) => {
                                handleServiceDoctor(doc, indexGroup, indexService);
                                setHover(false);
                            }}
                    />
                </ServiceDoctorSelect>
            )}
        </WrapServiceTable>
    )
}
