import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const DoubleSlashIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 12 24"} ref={ref}>
            <path d="M1.5,0h0A1.5,1.5,0,0,1,3,1.5v21A1.5,1.5,0,0,1,1.5,24h0A1.5,1.5,0,0,1,0,22.5V1.5A1.5,1.5,0,0,1,1.5,0Z"/>
            <path d="M10.5,0h0A1.5,1.5,0,0,1,12,1.5v21A1.5,1.5,0,0,1,10.5,24h0A1.5,1.5,0,0,1,9,22.5V1.5A1.5,1.5,0,0,1,10.5,0Z"/>
        </WrapIcon>
    );
});
