import {useMemo} from "react";
import {PlannedCall, TaskCallCenter} from "../../../types/common";

interface Props {
    plannedCall: PlannedCall
    uniqPatient: TaskCallCenter[]
}

const useContactPersonName = ({plannedCall, uniqPatient}: Props) => {
    return useMemo(() => {
        let isContactPersonName = false;

        if (uniqPatient.length > 1) {
            isContactPersonName = true
        } else if (uniqPatient.length == 1) {
            isContactPersonName = uniqPatient[0].targetPerson.patientId !== plannedCall.phoneOwner?.id
        }

        return isContactPersonName
    }, [plannedCall])
}

export default useContactPersonName
