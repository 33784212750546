import React from 'react';
import PropTypes from "prop-types";
import Button from "../../../components-ui/Inputs/Button/Button";
import Helper from "../../../common/helpers/main";
import ServerCommand from "../../../common/server/server-command";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";
import {formatFioAndCardNumber} from "../../../common/helpers/Patient";

export default class CheckListModal extends React.Component {

    constructor(props) {
        super(props);
        this.handlerLoader = this.handlerLoader.bind(this)
    }

    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        visit: PropTypes.object,
        addBgColor: PropTypes.func
    };

    state = {
        checkList: null,
        saveCheckList: null,
        valid: false,
        loader: false
    };

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.handlerLoader)
        const visitId = +this.props.visit.id;
        ServerCommand.get('visit/check-list', {visitId}).then(response =>
            this.setState({
                checkList: response.checkList,
                saveCheckList: {
                    visitId, leftReview: false
                }
            })
        );
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.handlerLoader)
    }

    render() {

        if (this.state.checkList === null) {
            return null
        }

        const visit = this.props.visit;
        const {saveCheckList} = this.state;

        const checkList = this.state.checkList.map(item => {
            const spanRequired = <span className="checkbox-required" key={item.code + 'req'}> *</span>;
            const label = item.isRequired ? [item.name, spanRequired] : item.name;
            return <Checkbox key={item.code}
                             label={label}
                             onChange={checked => this.validForm(item, checked)}
                             checked={saveCheckList[item.code]}
            />
        });

        return (
            <div className="modal__wrap">
                <h2 className='modal__title'>Чек-лист</h2>
                <span className="modal__text-center">{formatFioAndCardNumber(visit.patient)}</span>
                <div className="choice__box modal__checkboxes">
                    {checkList}
                </div>
                <div className="choice__box choice__box--center">
                    <Button
                        variant={this.state.valid ? 'success' : 'disabled'}
                        children='Готово'
                        loader={this.state.loader}
                        onClick={() => this.submitHandler(+visit.id)}/>
                </div>
            </div>
        )
    }

    handlerLoader(event) {
        const reason = event.reason;
        if (reason.type === 'server_request_error') {
            this.setState({loader: false})
        }
    }


    /** Валидация формы и добаление отмеченных чекбоксов в state saveCheckList
     * @param item {Object}
     * @param checked {boolean}*/
    validForm(item, checked) {

        let saveCheckList = Helper.clone(this.state.saveCheckList);
        let valid = true;

        saveCheckList[item.code] = checked;

        this.state.checkList.forEach((checkbox) => {
            if (checkbox.isRequired) {
                if (!saveCheckList[checkbox.code]) {
                    return valid = false;
                }
            }
            valid = true
        });

        this.setState({saveCheckList, valid});
    }

    /** Обработчик сохранения формы чеклиста
     * @param visitId {number} */
    submitHandler(visitId) {
        this.setState({loader: true})
        const command = new ServerCommand('visit/save-check-list', this.state.saveCheckList);
        command.exec().then(() => {
            this.props.setNextState();
            this.props.addBgColor(visitId);
            this.setState({loader: false})
            this.props.closeModal();
        });
    }
}
