import React from "react";
import {CompletedCall} from "../../../../../types/common";
import LabelTask from "../../../../../../../containers/Tasks/components/UI/LabelTask";
import SVG from "../../../../../../../common/helpers/SVG";
import { SBoxStatusHistory } from "../style";

interface Props {
    call: CompletedCall
}

const StatusHistory = ({call}: Props) => {
    return (
        <LabelTask label="Статус">
            <SBoxStatusHistory isAnswerTime={!!call.answerTime}>
                <SVG name={!!call.answerTime ? 'check' : 'close'}/>
                <span>{!!call.answerTime ? 'Дозвонился' : 'Не дозвонился'}</span>
            </SBoxStatusHistory>
        </LabelTask>
    )
}

export default StatusHistory
