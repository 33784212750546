import {useState} from "react";
import useOutsideClick from "../../../common/hooks/useOutsideClick";
import useTabDown from "../../../common/hooks/useTabDown";

export default function useDisplayPatientList(ref: any) : [boolean, (displayPatientList: boolean) => void] {
    const [displayPatientList, setDisplayPatientList] = useState(false);
    useOutsideClick(ref, () => setDisplayPatientList(false));
    useTabDown(() => setDisplayPatientList(false))
    return [displayPatientList, setDisplayPatientList]
}
