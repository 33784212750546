import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const EfficiencyOfVisits = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 21 24"} ref={ref}>
            <path d="M5.25,0H1.31A1.29,1.29,0,0,0,0,1.26V5.05H6.56V1.26A1.28,1.28,0,0,0,5.25,0Zm9.19,1.26V5.05H21V1.26A1.29,1.29,0,0,0,19.69,0H15.75A1.28,1.28,0,0,0,14.44,1.26Zm0,12.63a3.87,3.87,0,0,1-3.94,3.79,3.87,3.87,0,0,1-3.94-3.79V7.58H0v6.31A10.31,10.31,0,0,0,10.5,24,10.31,10.31,0,0,0,21,13.89V7.58H14.44Z"/>
        </WrapIcon>
    );
});
