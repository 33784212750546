import React, {Dispatch, SetStateAction, useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import Box from "../../../../../../../../components-ui/Layout/Box";
import Typography from "../../../../../../../../components-ui/DataDisplay/Typography/Typography";
import { WidthItems } from '../types';

interface PropsUserOpacity {
    code: string
    setWidthItems: Dispatch<SetStateAction<WidthItems>>
}

const UserOpacity = ({code, setWidthItems}: PropsUserOpacity) => {
    const users = useSelector(state1 => state1.common.users)
    const ref = useRef<HTMLElement>(null)

    useEffect(() => {
        if (!!ref.current) {
            const current = ref.current as HTMLElement;
            setWidthItems(prevState => ({...prevState, [code]: current.getBoundingClientRect().width}))
        } else {
            setWidthItems(prevState => ({...prevState, [code]: 150}))
        }
    }, [ref.current])

    return (
        <Box display="flex" as="span" flexWrap="nowrap" opacity={0} height="1px" >
            <Typography flexShrink={0} variant="small" children={users[+code].fio} ref={ref} />
        </Box>
    )
}

export default UserOpacity;
