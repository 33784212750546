import React, {forwardRef} from 'react';
import {IIcon} from '../../../types/common';
import {WrapIcon} from '../../../containers/WrapIcon';

export const LongArrowThinIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 4 10 11"} ref={ref}>
            <polygon
                fillRule="nonzero"
                points="5.50927734 15 5.50927734 6.98828125 7.95654297 6.98828125 5.00341797 4.03515625 2.05029297 6.98828125 4.49755859 6.98828125 4.49755859 15"
            />
        </WrapIcon>
    );
});
