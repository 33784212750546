import preparePhoneOwnerName from "../helper/preparePhoneOwnerName";
import {defaultOptionsPhoneOwner} from "../helper/defaultOptionsPhoneOwner";
import {useEffect} from "react";
import ServerCommand from "../../../common/server/server-command";
import {ResponsePatientList} from "../types/common";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import {usePatientField} from "../containers/PatientField";
import {PropsUseChangePhoneOwner} from "../types/hooks";

export default function useChangePhoneOwner({field}: PropsUseChangePhoneOwner) {
    const {onChangeField, value, currentPatient, personalPhone} = usePatientField()
    const getOptionsPhoneOwner = (samePhonePatients: any) => {
        const optionsPatients = samePhonePatients.map((patient: any, index: number) => ({
            label: preparePhoneOwnerName(patient),
            value: patient.id,
            sort: index + 2
        }))
        return defaultOptionsPhoneOwner.concat(optionsPatients).sort((a: any, b: any) => a.sort > b.sort ? 1 : -1)
    }
    useEffect(() => {
        if (field.name === 'phoneOwner' && !!personalPhone && !!currentPatient) {
            if (personalPhone.length !== 11) {
                onChangeField(null, field.name)
            } else {
                if (currentPatient.personalPhone === personalPhone) {
                    const newOptions = getOptionsPhoneOwner(currentPatient.samePhonePatients);
                    const phoneOwner = newOptions.find(option => option.value === currentPatient[field.name]);
                    onChangeField(newOptions, 'optionsPhoneOwner')
                    onChangeField(!!phoneOwner ? phoneOwner : null, 'phoneOwner')
                } else {
                    const data = {
                        filter: {personalPhone},
                        limit: 50,
                        select: ['personalPhone', 'name', 'lastName', 'secondName', 'id', 'personalBirthday', 'phoneOwner'],
                        getRelationsToPatient: currentPatient.id
                    } as any;
                    ServerCommand.get('patient/list', data).then((response: ResponsePatientList) => {
                        const newSamePhonePatients = !!response.list ? response.list : [] as any;
                        const newOptions = getOptionsPhoneOwner(newSamePhonePatients);
                        const findSelfPatient = newSamePhonePatients.find((patient: any) => patient.phoneOwner === 'self');
                        let phoneOwner = null as null | OptionSelect;
                        if (!!findSelfPatient) phoneOwner = newOptions.find(option => option.value === findSelfPatient.id) as OptionSelect;
                        onChangeField(newOptions, 'optionsPhoneOwner')
                        onChangeField(phoneOwner, 'phoneOwner')
                    });
                }
            }
        }

    }, [personalPhone])
}
