import {useEffect, useState} from "react";

interface PCurrentDate {
    day: number,
    month: number,
    year: number
}

export default function useCurrentDate() {
    const [currentDate, setCurrentDate] = useState<PCurrentDate>({day: 0, month: 0, year: 0});
    useEffect(() => {
        const newDate = new Date() as any;
        setCurrentDate({
            day: newDate.getDate(),
            month: newDate.getMonth() + 1,
            year: newDate.getFullYear()
        })
    }, []);
    return currentDate;
};
