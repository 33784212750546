import React from 'react';
import {ReceiptsReport} from "../../../../types";
import Box from "../../../../../../components-ui/Layout/Box";
import {Divider} from "../../../../../../components-ui/DataDisplay/Divider";
import Typography from "../../../../../../components-ui/DataDisplay/Typography/Typography";
import Helper from "../../../../../../common/helpers/main";
import Percentage from "./Percentage";
import useTotalSum from "../hooks/useTotalSum";
import Tooltip from "../../../../../../components-ui/DataDisplay/Tooltip/Tooltip";
import {StateReceipts} from "../types";

interface Props {
    report: ReceiptsReport
    state: StateReceipts
}

const TotalSum = ({report, state}: Props) => {
    const sums = useTotalSum({report, state})
    return (
        <>
            <Box margin="32px 0 16px" display="flex" alignItems="center">
                <Typography fontWeight="bold"
                            children={Helper.String.numberFormat(sums.totalSum, 0) + ' рублей'}
                            margin="0 12px 0 0"
                />
                {sums.previousPercentage !== null && (
                    <>
                        <Percentage percent={sums.previousPercentage}
                                    fontSize="16px" margin="0 8px 0 0"
                                    data-for='previousPercentage'
                                    data-tip={`Приращение относительно предыдущего <br/>аналогичного периода (${Helper.Date.formatDate(report.previous.startDate, 'D MMM YYYY')} - ${Helper.Date.formatDate(report.previous.endDate, 'DD MMM YYYY')})`}
                        />
                        <Tooltip id={`previousPercentage`} place="bottom" effect="solid" isArrow={true}/>
                    </>

                )}
                {sums.previousYearPercentage !== null && !!report.previousYear && (
                    <>
                        <Percentage percent={sums.previousYearPercentage}
                                    fontSize="14px"
                                    margin="0 8px 0 0"
                                    data-for='previousYearPercentage'
                                    data-tip={`Приращение относительно аналогичного периода <br/>в прошлом году (${Helper.Date.formatDate(report.previousYear.startDate, 'D MMM YYYY')} - ${Helper.Date.formatDate(report.previousYear.endDate, 'DD MMM YYYY')})`}
                        />
                        <Tooltip id={`previousYearPercentage`} place="bottom" effect="solid" isArrow={true}/>
                    </>

                )}
            </Box>
            <Divider margin="0 0 12px"/>
        </>
    );
};

export default TotalSum;
