import {GetTimesheetListResult} from '../types';
import {useSelector} from 'react-redux';
import DateHelper from '../../../common/helpers/date-helper';
import useServerTimeMoment from '../../../common/hooks/useServerTimeMoment';
import {useEffect, useState} from 'react';

const useNeedCompleteTimeSheet = (data: GetTimesheetListResult) => {

    const scheduleEndTime = useSelector(state => state.common.scheduleEndTime)

    const currentTimeMoment = useServerTimeMoment();

    const scheduleEndTimeMoment = useServerTimeMoment(scheduleEndTime, DateHelper.FORMAT_TIME_MINUTES)
    scheduleEndTimeMoment.subtract(data.timesheetCheckDuration, 'minute')

    const timeSheetDialogBadgeText = data.records.filter((record) => !record.endTime).length

    const [checkTimeCame, setCheckTimeCame] = useState(false)

    useEffect(() => {
        const newCheckTimeCame = !!data.timesheetCheckDuration && scheduleEndTimeMoment.isSameOrBefore(currentTimeMoment, 'minute');

        setCheckTimeCame(newCheckTimeCame)

        if (!!data.timesheetCheckDuration && !newCheckTimeCame) {
            const timeSheetCheckTimeDiff = scheduleEndTimeMoment.diff(currentTimeMoment)

            let timeout: ReturnType<typeof setTimeout>

            if (timeSheetCheckTimeDiff >= 0) {
                timeout = setTimeout(() => setCheckTimeCame(true), timeSheetCheckTimeDiff)
            }

            return () => {
                clearTimeout(timeout)
            }
        }


    }, [data.timesheetCheckDuration])

    return {timeSheetDialogBadgeText, checkTimeCame}
};

export default useNeedCompleteTimeSheet;