import React, {useState} from 'react';
import Box from '../../../../../../../components-ui/Layout/Box';
import Typography from '../../../../../../../components-ui/DataDisplay/Typography/Typography';
import {GetTimesheetListResult} from '../../../../../types';
import SelectEmployee from './components/SelectEmployee';
import ArrivalTimeCounter from './components/ArrivalTimeCounter';
import ModalComment from './components/ModalComment';
import Button from '../../../../../../../components-ui/Inputs/Button/Button';
import {commonLabelOption, commonSelectOption} from './data';
import {Label} from '../../../../../../../components-ui/DataDisplay/Label';
import ValidationLinkInfo from '../../../../../../../components/ValidationLinkInfo/ValidationLinkInfo';
import useTimesheetModalEmployee from './hooks/useTimesheetModalEmployee';
import {IStateTimesheetModal} from '../../types';
import useServerTimeMoment from '../../../../../../../common/hooks/useServerTimeMoment';
import DateHelper from '../../../../../../../common/helpers/date-helper';
import Select from '../../../../../../../components-ui/Inputs/Select/Select';
import {ITimesheetRow} from '../../../types';

interface Props {
    data: GetTimesheetListResult;
    selectedBranchId: number;
    employee?: ITimesheetRow;
    isComing: boolean;
}

const ModalMarkEmployee = ({data, selectedBranchId, employee, isComing}: Props) => {

    const currentTime = useServerTimeMoment().format(DateHelper.FORMAT_TIME_MINUTES);

    const timeIsComing = () => {
        let time = isComing ? employee?.startTime : employee?.endTime;

        return time ?? currentTime;
    };

    const commentIsComing = () => {
        let comment = isComing ? employee?.startTimeComment : employee?.endTimeComment

        return comment ?? ''
    };

    const [state, setState] = useState<IStateTimesheetModal>({
        employee: employee ? {value: employee.employeeId, label: employee.fio} : undefined,
        time: timeIsComing(),
        comment: commentIsComing(),
    });

    const {
        handleEmployee,
        handleComment,
        handleTime,
        validateMark,
        setValidateMark,
        saveEmployee,
    } = useTimesheetModalEmployee({selectedBranchId, state, setState, isComing});

    return (
        <Box padding='45px 30px 76px' minWidth='562px'>
            <Typography as='h1' variant='big-title' fontWeight='600'
                        children={`${isComing ? 'Приход' : 'Уход'} сотрудника`} textAlign='center' />
            <Box margin='39px 0 0 0'>

                {employee ?
                    <Label labelText='Сотрудник' isRequired={true} {...commonLabelOption} >
                        <Select {...commonSelectOption} colorSelect='white' value={state.employee} isDisabled={true} />
                    </Label>
                    :
                    <SelectEmployee
                        isError={validateMark}
                        data={data}
                        value={state.employee}
                        handleEmployee={handleEmployee}
                        selectedBranchId={selectedBranchId}
                    />
                }


                <ArrivalTimeCounter time={state.time}
                                    handleTime={handleTime}
                                    maxValue={isComing && employee?.endTime ? employee.endTime : currentTime}
                                    minValue={!isComing ? employee?.startTime : undefined}
                />
                <ModalComment comment={state.comment} handleComment={handleComment} />

                <Label labelText='' {...commonLabelOption}>
                    <Box display='flex' minWidth={commonSelectOption.widthSelect}>
                        <Button children='Сохранить'
                                size='md'
                                variant={!!state.employee && !!state.time ? 'success' : 'disabled'}
                                onClick={saveEmployee}
                        />
                        <Box maxWidth='172px' margin='0 0 0 16px'>
                            <ValidationLinkInfo validate={!!state.employee}
                                                onClickIsValidate={() => setValidateMark(!state.employee)} />
                        </Box>
                    </Box>
                </Label>

            </Box>
        </Box>
    );
};

export default ModalMarkEmployee;