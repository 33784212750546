import React from 'react';
import Loader from "../../components-ui/Feedback/Loader/Loader";
import Box from "../../components-ui/Layout/Box";

interface Props {
    height?: number
}

const LoaderPage = ({height}: Props) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" position="relative" width="100%"
             height={`calc(100vh - 84px${height ? ' - ' + height + 'px' : ''})`} zIndex={10}>
            <Loader visible={true} isBackground={false}/>
        </Box>
    );
};

export default LoaderPage;