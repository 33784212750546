import React from "react";
import {ThemeProvider} from "styled-components";

export type IColors =  {
    primary: '#00d6d6', // главный цвет,
    secondary: '#f1f3f7', // дополнительный цвет
    secondaryHover: '#e3e5e9',
    error: '#dc3545', // цвет ошибок
    errorHover: '#a71d2a',
    errorActive: '#be2432',
    danger: '#ff3e7f', // цвет опасности
    dangerHover: '#eb2f6e',
    dangerActive: '#e3316d',
    warning: '#fffbe8', // цвет предупреждений
    warningActive: '#fbf1c2',
    warningDark: '#ede8ce',
    warningBright: '#ffee72',
    blue: '#daebff',
    info: '#447ce6', // цвет информационный
    infoHover: '#396fe6',
    infoActive: '#3b5fbd',
    info05: 'rgba(68, 124, 230, 0.5)',
    success: '#3bcd94', // цвет выполнения
    successHover: '#37df9d',
    successActive: '#31b883',
    text: '#2e2f30', // Основной цвет текста,
    textGray: '#6c7783',
    textDarkGray: '#4f5965',
    white: '#ffffff',
    whiteDark: '#fbfbfb',
    borderGray: '#d5dae3',
    borderDark: '#9ea5b2',
    borderGrayActive: '#aeb5c2',
    bgDark: '#636666',
    bgLightBlue: '#e3e6ed',
    bgGray: '#f2f4f9',
    bgButtonGray: '#f5f6f9',
    bgDarkHover: '#858a8a',
    bgDarkActive: '#4d5050',
    online: '#4caf50',
    offline: '#ff004e',
    svgGray: '#a3aec5',
    purple: '#7564ed',
    lightBlue: '#e2e6ed',
    tooltipBgDark: '#313a45',
    darkBlue: '#1b2530',
    tabListColor: '#514005',
    tabListBg: '#ffc80a',
    scriptItemBg: '#e4e4e4',
    incomingColor: '#838383',
    orange: '#ffa33b',
    inc: '#01c702',
    dec: '#ff3d7f',
    transparent: 'transparent'
}

export interface IGradient {
    default: string
    defaultHover: string
    danger: string
    dangerHover: string
    dangerActive: string
}

export interface IShadow {
    default: string
    defaultButton: string,
    defaultButtonActive: string,
    defaultModal: string
    defaultModalLight: string
    defaultActive: string
    dangerActive: string,
    successDefault: string,
    successActive: string,
    tabShadow: string
    popupLabel: string,
    callFilter: string
}

export interface ITheme {
    colors: IColors,
    gradient: IGradient,
    shadow: IShadow,
    fonts: string[],
    fontSizes: {
        small: string,
        medium: string,
        large: string
    }
}

const theme = {
    colors: {
        primary: '#00d6d6', // главный цвет,
        secondary: '#f1f3f7', // дополнительный цвет
        secondaryHover: '#e3e5e9',
        error: '#dc3545', // цвет ошибок
        errorHover: '#a71d2a',
        errorActive: '#be2432',
        danger: '#ff3e7f', // цвет опасности
        dangerHover: '#eb2f6e',
        dangerActive: '#e3316d',
        warning: '#fffbe8', // цвет предупреждений
        warningDark: '#ede8ce',
        warningActive: '#fbf1c2',
        warningBright: '#ffee72',
        blue: '#daebff',
        info: '#447ce6', // цыет информационный
        infoHover: '#396fe6',
        infoActive: '#3b5fbd',
        info05: 'rgba(68, 124, 230, 0.5)',
        success: '#3bcd94', // цвет выполнения
        successHover: '#37df9d',
        successActive: '#31b883',
        text: '#2e2f30', // Основной цвет текста,
        textGray: '#6c7783',
        textDarkGray: '#4f5965',
        white: '#ffffff',
        whiteDark: '#fbfbfb',
        borderGray: '#d5dae3',
        borderDark: '#9ea5b2',
        borderGrayActive: '#aeb5c2',
        bgDark: '#636666',
        bgLightBlue: '#e3e6ed',
        bgGray: '#f2f4f9',
        bgButtonGray: '#f5f6f9',
        bgDarkHover: '#858a8a',
        bgDarkActive: '#4d5050',
        online: '#4caf50',
        offline: '#ff004e',
        svgGray: '#a3aec5',
        purple: '#7564ed',
        lightBlue: '#e2e6ed',
        tooltipBgDark: '#313a45',
        darkBlue: '#1b2530',
        tabListColor: '#514005',
        tabListBg: '#ffc80a',
        scriptItemBg: '#e4e4e4',
        incomingColor: '#838383',
        orange: '#ffa33b',
        inc: '#01c702',
        dec: '#ff3d7f',
        transparent: 'transparent'
    },
    gradient: {
        default: 'linear-gradient(to bottom, #ffffff, #f2f4f9)',
        defaultHover: 'linear-gradient(to bottom, #ffffff, #e7ecf9)',
        danger: `linear-gradient(to bottom, #ff3e7f, #eb2f6e)`,
        dangerHover: 'linear-gradient(to bottom, #ff6498, #f24780)',
        dangerActive: 'linear-gradient(to bottom, #f24780, #e3316d)',
    },
    shadow: {
        default: '0 1px 3px 0 #b8c0d0',
        defaultButton: '0 1px 3px 0 rgba(182, 188, 198, 0.65)',
        defaultButtonActive: 'inset 0 1px 2px 0 rgba(79, 89, 101, 0.5)',
        defaultModal: '0 3px 23px 0 rgba(49, 58, 69, 0.5)',
        defaultModalLight: '0 3px 13px 0 #b8c0d0',
        defaultActive: 'inset 0 1px 2px 0 rgba(79, 89, 101, 0.5)',
        dangerActive: 'inset 0 1px 3px 0 #781637',
        successDefault: '0 1px 3px 0 rgba(17, 104, 70, 0.5)',
        successActive: 'inset 0 1px 3px 0 #116846',
        tabShadow: '0 -1px 3px 0 rgba(182, 188, 198, 0.65)',
        popupLabel: '0 3px 23px 0 rgba(184, 192, 208, 0.5), 0 0 0.5px 0 #bdbdbd',
        callFilter: '0 4px 3px -3px rgba(72, 85, 110, 0.25)'
    },
    fonts: ['Open Sans', 'Arial', 'sans-serif'],
    fontSizes: {
        small: '1rem',
        medium: '2rem',
        large: '3rem'
    }
} as ITheme;

const Theme = ({children}: any) => (
    <ThemeProvider theme={theme} children={children}/>
);

export default Theme;
