import React from 'react';
import PropTypes from 'prop-types'
import Helper from "common/helpers/main";
import Table from "../../../../components/Table/Table";
import './TreatmentProcess.scss'
import TableWithGroupsTree from "../../../../components/TableWithGroupsTree/TableWithGroupsTree";
import {Icon} from "../../../../components/Icons/Icons";
import StepFooter from "../../components/StepFooter/StepFooter";
import ServerCommand from "common/server/server-command";
import CSSTransitionGroup from 'react-transition-group/CSSTransition';
import Counter from "../../../../components-ui/Inputs/Counter/Counter";
import TreatmentPlan from "../../../../containers/TreatmentPlan/TreatmentPlan";
import BoxBody from "../../../../components-ui/Wraps/old/BoxBody";
import Checkbox from "../../../../components-ui/Inputs/Checkbox/Checkbox";
import Arr from "../../../../common/helpers/Arr";
import ValidationResult from "../../../../common/Validation/ValidationResult";
import _ from 'underscore';
import TotalSum from "../../../../components-ui/TotalSum/TotalSum";
import PriceHelper from "../../../../common/helpers/price-helper";
import Tabs from "../../../../components-ui/Navigation/Tabs/Tabs";
import Printer from "../../../../common/server/printer";
import getDoctorsTabs from "../../../../common/TreatmentPlan/DoctorsTabs";
import {isSingleActiveProcedure} from "../../../../common/helpers/treatment-plan-item";

const mainPlanItemsColumns = [
    {
        title: 'Услуга',
        code: 'name',
        containsGroupTitle: true
    },
    {
        title: 'Зуб',
        code: 'target'
    },
    {
        title: 'Статус',
        code: 'statuses'
    },
    {
        title: 'Цена',
        code: 'price',
    }

];

const additionalPlanItemsColumns = [
    {
        title: 'Услуга',
        code: 'serviceName',
        containsGroupTitle: true
    },
    {
        title: 'Количество',
        code: 'quantity'
    },
    {
        title: 'Сумма',
        code: 'sum'
    },

];

const materialColumns = [
    {
        title: 'Код',
        code: 'code',
    },
    {
        title: 'Материал',
        code: 'name',
        searchable: true,
    }
];

const estimateMaterialsColumns = [
    {
        title: 'Материал',
        code: 'name',
    },
    {
        title: 'Количество',
        code: 'quantity',
        editable: true,
        type: 'number',
    }
];

export default class TreatmentProcess extends React.PureComponent {
    static propTypes = {
        planItems: PropTypes.array.isRequired,
        planItemsStatuses: PropTypes.array.isRequired,
        servicesGroups: PropTypes.array.isRequired,
        materials: PropTypes.object.isRequired,
        materialsGroups: PropTypes.array.isRequired,
        goToNextSubStep: PropTypes.func.isRequired,
        visitId: PropTypes.number.isRequired,
        setPlanItems: PropTypes.func.isRequired,
        nextStep: PropTypes.string.isRequired,
        doctors: PropTypes.object.isRequired,
        directions: PropTypes.object.isRequired,
        proceduresCombinations: PropTypes.object.isRequired,
        invoiceReadyDirections: PropTypes.array.isRequired,
        hasInvoicesToPrint: PropTypes.bool.isRequired,
        createdInvoicesDirections: PropTypes.array.isRequired,
    };

    state = {
        selectedPlanItems: [],
        selectedMaterials: {},
        selectedEstimateMaterial: [],
        isAccordionOpen: false,
        openMaterials: false,
        selectedDoctorId: TreatmentPlan.getPerformers(this.props.planItems, this.props.doctors)[0],
        loaderPrint: false,
        loaderNextBtn: false
    };


    render() {
        let selectedMaterials = Object.values(Helper.clone(this.state.selectedMaterials));
        selectedMaterials.forEach(material => material.sum = material.price * material.quantity);

        const validationResult = this.validate();
        validationResult.setMessages({
            'not_performed_service': 'Укажите выполненные процедуры и дополнительные услуги.',
            'not_performed_separate_service': 'Выполненных процедур и дополнительных услуг недостаточно для перехода на следующий шаг.',
            'not_performed_additional_service': 'Укажите выполненные дополнительные услуги.',
            'abandoned_additional_service': 'Все дополнительные услуги должны быть выполнены, так как выполнены все основные услуги.',
            'abandoned_additional_service_without_main_services': 'Все дополнительные услуги должны быть выполнены, так как нет основных услуг.',
        });

        const validationSuccess = validationResult.isSuccess();
        const validationExplanation = validationResult.getFullExplanation();
        const totalSum = this.calcSum();

        return (
            <React.Fragment>
                <div className="treatment-process">
                    <h3>План лечения (согласованный)</h3>
                    {this.renderPlanTables(totalSum)}
                    {/*<BoxBody>*/}
                    {/*    <div className="treatment-process__accordion-btn"*/}
                    {/*         onClick={this.toggleAccordion}>*/}
                    {/*        Расходные материалы*/}
                    {/*    </div>*/}
                    {/*    {this.state.isAccordionOpen ? <CSSTransitionGroup transitionName="anim"*/}
                    {/*                                                      transitionEnterTimeout={300}*/}
                    {/*                                                      transitionLeaveTimeout={300} timeout={300}>*/}
                    {/*        <div className="treatment-process__accordion">*/}
                    {/*            <div className="card">*/}
                    {/*                <div className="treatment-process__materials">*/}
                    {/*                    <TableWithGroupsTree*/}
                    {/*                        groups={this.props.materialsGroups}*/}
                    {/*                        columns={materialColumns}*/}
                    {/*                        data={Object.values(this.props.materials)}*/}
                    {/*                        modifyCellContent={(columnCode, content) => (columnCode === 'code')*/}
                    {/*                            ? [<Icon icon="new-tooth"/>, content]*/}
                    {/*                            : content}*/}
                    {/*                        getRowCode={item => item.id}*/}
                    {/*                        onSelect={this.toggleMaterialSelect.bind(this)}*/}
                    {/*                        selectedRows={Helper.mapObj(this.state.selectedMaterials, material => material.id)}*/}
                    {/*                        searchPlaceholder="Поиск расходных материалов"*/}
                    {/*                        scrollHeight={260}*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </CSSTransitionGroup>: null}*/}
                    {/*</BoxBody>*/}
                    {(selectedMaterials.length > 0) &&
                    <div className="box-body">
                        <p className="table__title">Смета на расходные материалы</p>
                        <div className="card">
                            <div className="treatment-process__estimate">
                                <Table columns={estimateMaterialsColumns}
                                       data={selectedMaterials}
                                       getRowCode={item => item.id}
                                       selectMode="single"
                                       selectedRows={this.state.selectedPlanItems}
                                       onSelect={selectedEstimateMaterial => this.setState({selectedEstimateMaterial})}
                                       isEditable={true}
                                       onChange={this.changeSelectedMaterials.bind(this)}
                                       onRowDelete={this.deleteEstimateMaterial.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    }
                    <StepFooter
                        nextBtnName="К истории болезни"
                        printBtnName="Распечатать счёт"
                        nextBtnActive={validationSuccess}
                        printBtnActive={validationSuccess}
                        onNextButtonClick={this.handleNextButtonClick}
                        showPrintBtn={totalSum > 0}
                        onPrintButtonClick={this.handlePrintInvoices}
                        disabledTip={validationExplanation}
                        printBtnDisabledTip={validationExplanation}
                        loaderPrint={this.state.loaderPrint}
                        loaderNextBtn={this.state.loaderNextBtn}
                    />
                </div>
            </React.Fragment>
        )
    }

    renderPlanTables(totalSum) {
        const {selectedDoctorId} = this.state;
        const {planItems, doctors} = this.props;

        const mainItems = planItems.filter(item => !item.isAdditional && (item.performerId === selectedDoctorId));

        return (
            <>
                <BoxBody modification={"treatment-process"}>
                    <div className="doctors-tabs">
                        <Tabs
                            tabs={getDoctorsTabs({planItems, doctors})}
                            borderBottom={true}
                            noLeftMargin={true}
                            activeTab={selectedDoctorId}
                            onChange={this.handleSelectDoctor}
                        />
                    </div>

                    <p className="table__title">Основные услуги</p>
                    <div className="card">
                        <div className="treatment-process__main plan">
                            <Table columns={mainPlanItemsColumns}
                                   data={mainItems}
                                   prepareDataItem={this.prepareMainPlanItem}
                                   prepareDataNestedItem={this.prepareProcedure}
                                   selectMode="status"
                                   selectedRows={this.state.selectedPlanItems}
                                   groups={this.props.servicesGroups}
                            />
                        </div>
                    </div>
                </BoxBody>

                <BoxBody>
                    <p className="table__title">Дополнительные услуги</p>
                    <div className="card">
                        <div className="treatment-process__additional">
                            <Table columns={additionalPlanItemsColumns}
                                   data={this.getAdditionalPlanItems()}
                                   prepareDataItem={this.prepareAdditionalPlanItem}
                                   groups={this.getAdditionalPlanItemsGroups()}
                                   isEditable={true}
                            />
                        </div>
                    </div>
                    <TotalSum
                        sums={[
                            {title: 'Итого за прием', value: totalSum}
                        ]}
                    />
                </BoxBody>
            </>
        );
    }

    renderPlanItemStatuses(planItem, handleClickTree) {
        const {planItemsStatuses} = this.props;
        return (
            <div className="treatment-statuses">
                {planItemsStatuses.map(status => (
                    <div className={'treatment-status treatment-status--' + planItem.statuses[status.code]}
                         key={status.code}
                         onClick={() => this.handleClickStatus(status.code, planItem.id, handleClickTree)}>
                        {status.name}
                    </div>
                ))}

                {(planItem.statuses.DONE !== 'empty') && (
                    <Icon icon="new-check"
                          iconClass={'treatment-status__check treatment-status__check--' + planItem.statuses.DONE}/>
                )}
            </div>
        );
    }

    renderProcedureName(procedure, planItem) {
        const isDisabled = procedure.isBlocked || isSingleActiveProcedure(procedure, planItem);

        let tooltip = '';

        if (isDisabled) {
            tooltip = procedure.isBlocked ? 'Услуга выполнена в предыдущих приёмах' : 'Нельзя сделать неактивными все процедуры в услуге';
        }

        return (
            <Checkbox label={procedure.name}
                      checked={procedure.isActive}
                      disabled={isDisabled}
                      onChange={() => this.handleCheckProcedureActive(procedure.id, planItem.id)}
                      key={procedure.id}
                      tooltip={tooltip}
            />
        );
    }

    renderProcedureStatus(procedure, planItem) {
        return (
            <Checkbox label="Выполнена"
                      checked={procedure.isDone}
                      disabled={procedure.isBlocked}
                      onChange={() => this.handleCheckProcedureStatus(procedure.id, planItem.id)}
                      key={procedure.id + '_' + procedure.planItemId + '_' + procedure.isDone + '_' + procedure.isBlocked}
                      tooltip={procedure.isBlocked ? 'Услуга выполнена в предыдущих приёмах' : ''}
            />
        );
    }

    renderProcedurePrice(procedure) {
        const price = PriceHelper.format(procedure.price);

        if(!procedure.isDone || procedure.isBlocked) {
            return (
                <span className="gray-color">{price}</span>
            )
        } else {
            return price;
        }
    }

    renderAdditionalItemQuantity(item, itemCode) {
        return (
            <Counter value={item.quantity}
                     onChange={newQuantity => this.changeAdditionalItemQuantity(itemCode, newQuantity - item.quantity)}
                     min={0}
                     max={item.totalQuantity}
            />
        );
    }

    prepareMainPlanItem = ({item: planItem, openedItems, handleClickTree}) => {
        const {proceduresCombinations} = this.props;

        planItem.statuses = this.renderPlanItemStatuses(planItem, handleClickTree);
        planItem.nestedItems = planItem.procedures;
        planItem.handleClickTree = handleClickTree;
        planItem.groupId = planItem.serviceGroupId;
        planItem.rowCode = planItem.id;
        planItem.name = TreatmentPlan.getItemName(planItem, proceduresCombinations);
        planItem.tab = planItem.performerId;

        if(!openedItems[planItem.id] && this.needShowPlanItemPrice(planItem)) {
            const price = this.getPlanItemPrice(planItem);
            planItem.price = (price > 0) ? PriceHelper.format(price) : '';
        } else {
            planItem.price = '';
        }
    };

    prepareAdditionalPlanItem = ({item: planItem}) => {
        const initialPlanItem = Helper.clone(planItem);

        planItem.rowCode = this.getAdditionalPlanItemCode(planItem);

        if(this.needShowPlanItemPrice(planItem)) {
            planItem.sum = PriceHelper.format(TreatmentPlan.getItemPrice(planItem) * planItem.quantity);
        } else {
            planItem.sum = '';
        }

        const quantityInput = this.renderAdditionalItemQuantity(initialPlanItem, planItem.rowCode);
        planItem.quantity = [quantityInput, ' из ' + planItem.totalQuantity];
        planItem.tab = planItem.performerId;
        planItem.groupId = planItem.direction;
    };

    prepareProcedure = ({item: procedure, parentItem: planItem}) => {

        procedure.name = this.renderProcedureName(procedure, planItem);
        if (procedure.isActive) {
            procedure.statuses = this.renderProcedureStatus(procedure, planItem);
        }
        procedure.rowCode = procedure.id;

        if (this.needShowPlanItemPrice(planItem)) {
            procedure.price = this.renderProcedurePrice(procedure);
        } else {
            procedure.price = '';
        }
    };

    getAdditionalPlanItemsGroups() {
        return _.map(this.props.directions, direction => ({
            id: direction.code,
            name: direction.name,
            groupId: 0,
        }));
    }

    calcSum = () => {
        let sum = 0;
        this.props.planItems.forEach(item => {
            if(this.needShowPlanItemPrice(item)) {
                sum += this.getPlanItemPrice(item);
            }
        });

        return sum;
    };

    /**
     * @return {ValidationResult}
     */
    validate = () => {
        const {planItems} = this.props;

        const result = new ValidationResult([
            'not_performed_service',
            'not_performed_separate_service',
            'not_performed_additional_service'
        ]);

        this.props.planItems.forEach(item => {
            const hasDoneProcedures = item.procedures.some(procedure => !procedure.isBlocked && procedure.isDone);

            if(hasDoneProcedures) {
                if(item.isSeparate) {
                    result.deleteError('not_performed_separate_service');
                }

                if(item.isAdditional) {
                    result.deleteError('not_performed_additional_service');
                }

                result.deleteError('not_performed_service');
            }
        });

        if(result.hasError('not_performed_service')) {
            result.deleteError('not_performed_separate_service');
            result.deleteError('not_performed_additional_service');
        }

        const [doctorMainItems, doctorAdditionalItems] = _.chain(planItems)
            .filter(item => item.performerId === window.user.doctorId)
            .partition(item => !item.isAdditional)
            .value();

        if(doctorAdditionalItems.some(item => item.status !== 'DONE')) {
            let abandonedAdditionalService = false;

            if(doctorMainItems.length) {
                if(doctorMainItems.every(item => item.status === 'DONE')) {
                    result.addError('abandoned_additional_service');
                    abandonedAdditionalService = true;
                }
            } else {
                result.addError('abandoned_additional_service_without_main_services');
                abandonedAdditionalService = true;
            }

            if(abandonedAdditionalService) {
                result.deleteError('not_performed_separate_service');
                result.deleteError('not_performed_additional_service');
            }
        }
        if (Helper.isDisableAddedAdditionalService()) {
            result.deleteError('not_performed_additional_service');
        }

        return result;
    };

    handleCheckProcedureActive(procedureId, planItemId) {
        this.updateProcedure(procedureId, planItemId, procedure => {
            procedure.isActive = !procedure.isActive;
            procedure.isDone = false;
        });
    }

    handleCheckProcedureStatus(procedureId, planItemId) {
        this.updateProcedure(procedureId, planItemId, procedure => procedure.isDone = !procedure.isDone);
    }

    updateProcedure = (procedureId, planItemId, handler) => {
        let planItems = Helper.clone(this.props.planItems);
        planItems = Helper.Array.toObject(planItems, 'id');
        const planItem = planItems[planItemId];

        const procedures = Helper.Array.toObject(planItem.procedures, 'id');
        handler(procedures[procedureId]);

        /* --- определение нового статуса пункта плана лечения --- */

        let activeProceduresCount = 0;
        let doneActiveProceduresCount = 0;

        planItem.procedures.forEach(procedure => {
            if (procedure.isActive) {
                activeProceduresCount++;

                if (procedure.isDone) {
                    doneActiveProceduresCount++;
                }
            }
        });

        let newStatus = 'NOT_STARTED';
        if(activeProceduresCount > 0) {
            if(activeProceduresCount === doneActiveProceduresCount) {
                newStatus = 'DONE';
            } else if(doneActiveProceduresCount > 0) {
                newStatus = 'IN_WORK';
            }
        }

        /* --- установка нового статуса пункта плана лечения --- */

        this.setPlanItemStatus(planItem, newStatus);

        this.props.setPlanItems(Object.values(planItems));
    };

    handleClickStatus(statusCode, planItemId, handleTree) {
        let planItems = Helper.clone(this.props.planItems);
        let newState = {};
        const planItem = planItems.find(item => item.id === planItemId);

        this.selectStatus(planItem, statusCode);

        planItem.procedures.forEach(procedure => {
            if (procedure.isActive) {
                if (planItem.statuses.DONE !== 'empty') {
                    procedure.isDone = true;
                }
                if (!procedure.isBlocked) {
                    if (planItem.statuses.IN_WORK === 'empty') {
                        procedure.isDone = false;
                    }
                }
            }
        });

        this.setState(newState);
        this.props.setPlanItems(planItems);
        handleTree(planItemId);
    }

    handleSelectDoctor = selectedDoctorId => this.setState({selectedDoctorId});

    /**
     * Устанавливает новый статус для пункта плана лечения
     * @param planItem
     * @param {string} newStatus - код статуса
     */
    setPlanItemStatus = (planItem, newStatus) => {
        if(planItem.status === newStatus) return;

        let isNextStatus = false;

        _.each(this.props.planItemsStatuses, status => {
            if (status.code === newStatus) {
                // устанавливаемый статус
                if(planItem.statuses[status.code] !== 'blocked') {
                    planItem.statuses[status.code] = 'changeable';
                }
                isNextStatus = true;
            }
            else if (isNextStatus && (planItem.statuses[status.code] !== 'blocked')) {
                // статус после устаналиваемого статуса
                planItem.statuses[status.code] = 'empty';
            }
            else if (planItem.statuses[status.code] === 'empty') {
                // статус до устанавливаемого статуса
                planItem.statuses[status.code] = 'changeable';
            }
        });

        planItem.status = newStatus;
    };

    selectStatus = (planItem, newStatus) => {
        const {planItemsStatuses} = this.props;

        if (planItem.statuses[newStatus] === 'blocked') return;

        if (planItem.statuses[newStatus] === 'empty') {

            /* окрашиваем в зелёный данный статус и предыдущие незакрашенные  */

            Helper.forEachObj(planItemsStatuses, (status) => {

                if (planItem.statuses[status.code] === 'empty') {
                    planItem.statuses[status.code] = 'changeable';
                }

                if (status.code === newStatus) return false; //то же самое что break
            });
            planItem.status = newStatus;
        } else {
            /* сбрасываем зелёный цвет */
            let isNextStatus = false;
            let prevStatusCode = null;
            Helper.forEachObj(planItemsStatuses, (status) => {
                if (status.code === newStatus || isNextStatus) {
                    if (planItem.statuses[status.code] === 'changeable') {
                        planItem.statuses[status.code] = 'empty';
                    }
                    isNextStatus = true;
                } else {
                    prevStatusCode = status.code;
                }

            });

            planItem.status = prevStatusCode;
        }
    };

    toggleAccordion = () => {
        this.setState({isAccordionOpen: !this.state.isAccordionOpen})
    };

    handleNextButtonClick = () => {
        this.setState({loaderNextBtn: true})
        this.save().then(() => {
            this.props.goToNextSubStep();
            this.setState({loaderNextBtn: false});
        });
    };

    handlePrintInvoices = () => {
        this.setState({loaderPrint: true})
        this.save(true, false).then(response => {
            this.setState({loaderPrint: false})
            return Printer.print(response.invoicesDocuments)
        });
    };

    save(generateInvoicesDocuments = false, switchState = true) {
        let data = {
            visitId: this.props.visitId,
            generateInvoicesDocuments,
            switchState
        };

        data.additionalMaterials = Helper.mapObj(this.state.selectedMaterials, (selectedMaterial) => {
            return (
                {
                    id: selectedMaterial.id,
                    quantity: selectedMaterial.quantity,
                }
            )
        });

        data.planStatuses = this.props.planItems.map(planItem => ({
            id: planItem.id,
            status: planItem.status,
            procedures: planItem.procedures.filter(procedure => !procedure.isBlocked).map(procedure => ({
                id: procedure.id,
                isActive: procedure.isActive,
                isDone: procedure.isDone
            }))
        }));


        return ServerCommand.run('visit/register-treatment', data);
    }

    toggleMaterialSelect(selectedMaterialsIds, materialId) {

        let selectedMaterials = Helper.clone(this.state.selectedMaterials);

        if (!!selectedMaterials[materialId]) {
            delete selectedMaterials[materialId]
        } else {
            selectedMaterials[materialId] = {
                id: materialId,
                name: this.props.materials[materialId].name,
                quantity: 1,
                price: this.props.materials[materialId].price
            }
        }

        this.setState({selectedMaterials: selectedMaterials})
    }

    changeSelectedMaterials(materialId, fieldCode, fieldValue) {
        let selectedMaterials = Helper.clone(this.state.selectedMaterials);

        if (fieldCode === 'quantity') {
            selectedMaterials[materialId].quantity = fieldValue
        }

        this.setState({selectedMaterials: selectedMaterials})
    }

    deleteEstimateMaterial(materialId) {
        let selectedMaterials = Helper.clone(this.state.selectedMaterials);
        delete selectedMaterials[materialId];
        this.setState({selectedMaterials: selectedMaterials})
    }

    getAdditionalPlanItems() {
        const {planItems} = this.props;
        const {selectedDoctorId} = this.state;

        /* --- фильтрация --- */
        const additionalItems = planItems.filter(planItem => planItem.isAdditional && !planItem.isBlocked && (planItem.performerId === selectedDoctorId));

        /* --- сжатие с подсчётом количества --- */

        return Arr.squeeze(additionalItems, this.getAdditionalPlanItemCode, {
            quantityField: 'totalQuantity',

            handler: (originalItems, squeezedItem) => {
                //подсчёт выполненного количества
                squeezedItem.quantity = 0;

                originalItems.forEach(originalItem => {
                    if(originalItem.procedures.some(procedure => procedure.isDone && !procedure.isBlocked)) {
                        squeezedItem.quantity++;
                    }
                })
            }
        });
    };

    changeAdditionalItemQuantity(itemCode, quantityDif) {

        if (!quantityDif) return;

        const planItems = Helper.clone(this.props.planItems);
        const notBlockedAdditionalItems = planItems.filter(item => {
            return (this.getAdditionalPlanItemCode(item) === itemCode) && !item.isBlocked;
        });

        const makeDone = (quantityDif > 0);

        // фильтруем, чтобы получить массив дополнительных пунктов плана, которые нужно изменить
        const itemsToChange = notBlockedAdditionalItems.filter(item => item.procedures.some(procedure => procedure.isDone !== makeDone));

        // изменяем соответствующие пункты плана - выполняем, либо наборот отмечаем невыполненными, в зависимости от разницы в количестве, которую необходимо получить
        itemsToChange.splice(0, Math.abs(quantityDif)).forEach(item => {
            item.procedures.forEach(procedure => procedure.isDone = makeDone);
            item.status = makeDone ? 'DONE' : 'NOT_STARTED';
        });

        this.props.setPlanItems(planItems);
    }

    getAdditionalPlanItemCode = item => {
        return TreatmentPlan.getPlanItemCode(item, false) + '_' + item.direction;
    };

    needShowPlanItemPrice(planItem) {
        const {directions} = this.props;
        return directions[planItem.direction].payMode === 'after_performing';
    }

    getPlanItemPrice(planItem) {
        return TreatmentPlan.getItemPrice(planItem, procedure => procedure.isDone && !procedure.isBlocked);
    }
}
