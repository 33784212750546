import {useOptionsBranchesUser} from '../../../common/hooks/useOptionsBranchesUser';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {OptionSelect} from '../../../components-ui/Inputs/Select/typesSelect';
import useLoader from '../../../components/Errors';
import {GetDoneServicesParams, GetDoneServicesResult} from '../types';
import DateHelper from '../../../common/helpers/date-helper';
import ServerCommand from '../../../common/server/server-command';
import useOptionsFilters from './useOptionsFilters';
import {useSelector} from 'react-redux';
import {GetLaboratoriesResult} from '../../LaboratoryPage/types';

const useLaboratoryDoneServicesPage = () => {

    const users = useSelector(state => state.common.users);

    const [laboratories, setLaboratories] = useState<GetLaboratoriesResult['laboratories']>();

    useEffect(() => {
        ServerCommand.get('laboratory/list')
            .then((response: GetLaboratoriesResult) => setLaboratories(response.laboratories));
    }, []);

    const optionsBranches = useOptionsBranchesUser();

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());

    const [selectedBranch, setSelectedBranch] = useState<OptionSelect>(optionsBranches[0]);

    const [doctor, setDoctor] = useState<OptionSelect>({value: 0, label: 'Все врачи', color: '#dedede'});
    const [technic, setTechnic] = useState<OptionSelect>({value: 0, label: 'Все техники'});
    const [laboratory, setLaboratory] = useState<OptionSelect>({value: 0, label: 'Все лаборатории'});

    const [loaderPage, setLoaderPage] = useLoader(true);

    const [loaderTable, setLoaderTable] = useLoader(false);

    const [doneServicesResponse, setDoneServicesResponse] = useState<GetDoneServicesResult>({
        doneEmployeeServices: [],
        doneLaboratoryServices: [],
        services: [],
    });

    const {
        optionsFilteredTechnic,
        optionsFilteredLaboratories,
        optionsFilteredDoctors,
    } = useOptionsFilters({laboratories, selectedBranch});

    const onChangeSelectedBranch = (selectedValue: OptionSelect) => {
        setSelectedBranch(selectedValue);
        if (doctor.value !== 0) {
            if (!users[doctor.value].branches.includes(selectedValue.value)) {
                setDoctor(optionsFilteredDoctors[0]);

            }
        }
        if (technic.value !== 0) {
            if (!users[technic.value].branches.includes(selectedValue.value)) {
                setTechnic(optionsFilteredTechnic[0]);

            }
        }
    };

    const filteredDoneServices = useMemo<GetDoneServicesResult>(() => {
        return {
            ...doneServicesResponse,
            doneLaboratoryServices:
                doneServicesResponse.doneLaboratoryServices
                    .filter(service =>
                        doctor.value ?
                            service.doctorId === doctor.value :
                            service,
                    )
                    .filter(service =>
                        laboratory.value ?
                            service.laboratoryId === laboratory.value :
                            service
                    ),
            doneEmployeeServices:
                doneServicesResponse.doneEmployeeServices
                    .filter(service =>
                        doctor.value ?
                            service.doctorId === doctor.value :
                            service,
                    )
                    .filter(service =>
                        technic.value ?
                            service.employeeId === technic.value :
                            service
                    ),
        };
    }, [doneServicesResponse, doctor, laboratory, technic]);

    const params = {
        startDate: DateHelper.formatDateForServer(startDate),
        endDate: DateHelper.formatDateForServer(endDate),
        branchId: selectedBranch.value,
    } as GetDoneServicesParams;

    useEffect(() => {
        setLoaderTable(true);
        ServerCommand
            .get('laboratory/done-service-list', params)
            .then((response: GetDoneServicesResult) => {
                setDoneServicesResponse(response);
                setLoaderPage(false);
                setLoaderTable(false);
            });
    }, [startDate, endDate, selectedBranch]);

    const refreshDataFromServer = useCallback(() => {
        setLoaderTable(true);
        ServerCommand
            .get('laboratory/done-service-list', params,
            ).then((response: GetDoneServicesResult) => {
            setDoneServicesResponse(response);
            setLoaderTable(false);
        });
    }, [startDate, endDate, selectedBranch]);

    return {
        optionsBranches,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        loaderPage,
        loaderTable,
        selectedBranch,
        laboratories,
        doctor,
        setDoctor,
        technic,
        setTechnic,
        laboratory,
        setLaboratory,
        doneServicesResponse: filteredDoneServices,
        optionsFilteredTechnic,
        optionsFilteredLaboratories,
        optionsFilteredDoctors,
        onChangeSelectedBranch,
        refreshDataFromServer,
    };
};

export default useLaboratoryDoneServicesPage;
