import React from 'react'
import PropTypes from 'prop-types'
import {Icon} from "../Icons/Icons";
import Element from "./Element";

export default class Group extends React.PureComponent {
    static propTypes = {
        group: PropTypes.object.isRequired,
        onClick: PropTypes.func,
        selectedGroupId: PropTypes.number,
        selectedElementsIds: PropTypes.array,
        isFocused: PropTypes.bool.isRequired,
        isMultipleSelect: PropTypes.bool.isRequired,
        dblclickElement: PropTypes.func
    };

    static defaultProps = {
        onClick: () => {},
        dblclickElement: () => {},
    };

    state = {
        isOpened: !!this.props.group.isOpened,
    };

    render() {
        let children = null;
        let childrenElement = null;
        let filling = 'empty';
        const group = this.props.group;

        if (this.isFull()) {
            if (this.state.isOpened && (group.elements === undefined || group.groups !== undefined)) {
                children = (
                    <ul className="tree">
                        {group.groups.map(subGroup => (
                                <Group className="tree__item"
                                       selectedGroupId={this.props.selectedGroupId}
                                       group={subGroup}
                                       key={subGroup.id}
                                       onClick={this.props.onClick}
                                       isFocused={this.props.isFocused}
                                       selectedElementsIds={this.props.selectedElementsIds}
                                       isMultipleSelect={this.props.isMultipleSelect}
                                       dblclickElement={this.props.dblclickElement}
                                />
                            ))}

                    </ul>
                );
            }

            if (this.state.isOpened && group.elements !== undefined) {
                childrenElement = (
                    <ul className="tree">
                        {group.elements.map(element => (
                                <Element
                                    element={element}
                                    key={`element-${element.id}`}
                                    onClick={this.props.onClick}
                                    selectedElementsIds={this.props.selectedElementsIds}
                                    isMultipleSelect={this.props.isMultipleSelect}
                                    dblclick={this.props.dblclickElement}
                                    isFocused={this.props.isFocused}
                                />
                            ))}

                    </ul>
                );
            }

            filling = 'full';
        }

        let className = `tree__item-title-group tree__item-title-group--${filling}`;

        if (this.state.isOpened) {
            className += " tree__item-title-group--opened"
        }

        if (group.id === this.props.selectedGroupId) {
            className += this.props.isFocused ? " tree__item-title-group--focused" : " tree__item-title-group--selected"
        }

        let numbering = null;
        if (group.numbering !== undefined) numbering = group.numbering;

        return (
            <li className="tree__item">
                <div className={className} onClick={this.handleClick.bind(this)}>
                    <Icon icon="new-folder"/>
                    <span className="tree__item-title">{numbering} {group.name}</span>
                </div>
                {children}
                {childrenElement}
            </li>
        );
    }


    handleClick() {
        this.props.onClick(this.props.group, [this.props.group], false);
        if (this.isFull()) {
            this.setState({isOpened: !this.state.isOpened})
        }
    }

    isFull() {
        return (this.props.group.groups && this.props.group.groups.length) || this.props.group.elements !== undefined
    }
}

