import * as React from "react";
import {Component, createRef} from 'react';
import './Schedule.scss';
import TimeInterval from "./elements/TimeInterval";
import Graph from "./Graph/Graph";
import Helper from "../../common/helpers/main";
import {sizeCell} from './helper/constants'
import Arrow from "./elements/Arrow";
import {ScheduleProps, PrevCells, dataCell} from "./types/types";
import {sortName} from "../../common/helpers/graph-helper";

export default class Schedule extends Component<ScheduleProps> {

    private graphWrap = createRef<any>();
    private graph = createRef<any>();
    state = {
        widthGraph: 0,
        widthGraphWrap: 0,
        selectedCell: null,
        currentDoctorId: null,
        showContextMenu: false,
        positionContextMenu: {
            top: 0,
            left: 0
        },
        typeContextMenu: 'default'
    };

    constructor(props: Readonly<ScheduleProps>) {
        super(props);
        this.state.widthGraph = props.chairs.length * (sizeCell.width + 48);
    }

    componentDidMount() {
        this.setState({widthGraphWrap: this.graphWrap.current.offsetWidth - 152})
    }
    /*TODO это временное решение пока мы не переведем этот компонент на хуки*/
    timer = () => {
        return setTimeout(() => {
            if (this.graphWrap.current) {
                this.setState({
                    selectedCell: null,
                    widthGraphWrap: this.graphWrap.current.offsetWidth - 152,
                    widthGraph: this.props.chairs.length * (sizeCell.width + 48)
                })
            }
        }, 500)
    }

    componentDidUpdate(prevProps: Readonly<ScheduleProps>): void {
        if (prevProps.parentKey !== this.props.parentKey) {
            this.timer()
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer())
    }


    render() {
        const {template, chairs, timeInterval, scrollTo, handleScrollTo, selectedBranch} = this.props as any;
        const {selectedCell, positionContextMenu, showContextMenu, typeContextMenu} = this.state;
        const {widthGraph, widthGraphWrap} = this.state;
        const timeStartInterval = Helper.clone(timeInterval);
        const timeEndInterval = Helper.clone(timeInterval);
        timeStartInterval.pop();
        timeEndInterval.shift();



        return (
            <div className="graph-wrap" ref={this.graphWrap}>
                {scrollTo !== 0 && <Arrow onClick={() => this.handleArrowClick(false)}/>}
                <TimeInterval
                    timeInterval={timeStartInterval}
                    selectedCell={selectedCell}
                />
                <Graph timeStartInterval={timeStartInterval}
                       timeEndInterval={timeEndInterval}
                       graphData={template}
                       chairs={chairs}
                       onScroll={handleScrollTo}
                       handleResizeStop={this.handleResizeStop}
                       selectedCell={selectedCell}
                       ref={this.graph}
                       handleClickCell={this.handleClickCell}
                       handleContextMenu={this.handleContextMenu}
                       removeContextMenu={this.removeContextMenu}
                       handleDoctor={this.handleDoctor}
                       showContextMenu={showContextMenu}
                       typeContextMenu={typeContextMenu}
                       positionContextMenu={positionContextMenu}
                       deleteSchedule={this.deleteSchedule}
                       handleConflict={this.handleConflict}
                       scrollTo={scrollTo}
                       selectedBranch={selectedBranch}
                />
                {widthGraphWrap + 20 + scrollTo < widthGraph &&
                <Arrow variant='right' onClick={this.handleArrowClick}/>}
            </div>
        )
    }

    handleArrowClick = (way = true) => {
        let scrollTo = this.graph.current.graph.current.scrollLeft;
        if (way) {
            scrollTo += 218;
        } else {
            scrollTo -= 218;

        }
        this.graph.current.graph.current.scrollTo(scrollTo, 0);
        this.props.handleScrollTo(scrollTo)
    };


    handleResizeStop = (cellArguments: any) => {
        const {data, chairId, resizableWay} = cellArguments;
        if (data.doctorId === 0) return false;

        const {timeStart, timeEndInterval, doctorTime} = data;
        const {timeInterval} = this.props;

        const newState: any = {};

        const newTemplate = Helper.clone(this.props.template);
        const newCells = [];
        let isCookie = false;

        let indexTimeStart: any = timeInterval.indexOf(timeStart);
        let indexTimeEnd: any = timeInterval.indexOf(timeEndInterval);

        if (data.doctorTime !== undefined) {
            indexTimeStart = timeInterval.indexOf(doctorTime.timeStart);
            indexTimeEnd = timeInterval.indexOf(doctorTime.timeEnd);
        }

        let column = newTemplate[chairId];

        if (column === undefined) column = [];

        column.forEach((cell: any) => {
            if (cell.doctorId === data.doctorId && (cell.timeStart === data.timeStart || cell.timeEnd === data.timeEnd)) {
                this.updateTime(cellArguments, cell, newState, indexTimeStart, indexTimeEnd);
            }
            if (cell.doctorId !== 0 && cell.doctorId !== 'cookie') {
                newCells.push(cell);
            }
            if (cell.doctorId === 'cookie') {
                isCookie = true;
                this.updateTime(cellArguments, cell, newState, indexTimeStart, indexTimeEnd);
                newCells.push(cell);
            }
        });

        newTemplate[chairId] = this.joinCell(column, resizableWay);

        if (!isCookie && data.doctorId === 'cookie') {
            const newCell: any = this.addCookieCell(cellArguments, indexTimeStart, indexTimeEnd);
            newCells.push(newCell);
            newState.selectedCell = {chairId, data: newCell};
            newTemplate[chairId] = newCells;
        }

        this.setState(newState);

        this.props.handleTemplate(newTemplate)

    };

    joinCell = (column: any, resizableWay: any) => {

        if (column.length > 1) {
            let prevCell: PrevCells = {
                timeStart: null,
                timeEnd: null,
                doctorId: null,
                index: null
            };
            column = column.sort((a: any, b: any) => sortName(a.timeStart, b.timeStart));
            column = column.filter((cell: any) => cell.doctorId !== 0);
            let indexRemoveCell: number | undefined;
            if (resizableWay === 'top') column = column.reverse();
            column.forEach((cell: any, index: number) => {
                const timeA = resizableWay === 'top' ? 'timeStart' : 'timeEnd';
                const timeB = resizableWay === 'top' ? 'timeEnd' : 'timeStart';
                if (prevCell.index !== null) {
                    if (cell.doctorId === prevCell.doctorId && prevCell[timeA] === cell[timeB]) {
                        cell[timeB] = prevCell[timeB];
                        indexRemoveCell = prevCell.index;
                    }
                }
                prevCell = {
                    timeStart: cell.timeStart,
                    timeEnd: cell.timeEnd,
                    doctorId: cell.doctorId,
                    index
                }
            });

            if (indexRemoveCell !== undefined) column = column.filter((cell: any, index: any) => index !== indexRemoveCell);

            if (resizableWay === 'top') column = column.reverse();
        }
        return column;
    };

    updateTime = (cellArguments: any, cell: any, newState: any, indexTimeStart: any, indexTimeEnd: any) => {
        const {chairId, collCells, resizeWay, resizableWay} = cellArguments;
        const {timeInterval} = this.props;
        if (resizableWay === 'top') {
            if (resizeWay === 'top') {
                cell.timeStart = timeInterval[indexTimeStart - collCells];
            } else {
                cell.timeStart = timeInterval[indexTimeStart + collCells];
            }
            newState.selectedCell = {chairId, data: cell};
        } else {
            if (resizeWay === 'top') {
                cell.timeEnd = timeInterval[indexTimeEnd - collCells];
            } else {
                cell.timeEnd = timeInterval[indexTimeEnd + collCells];
            }
        }
    };

    addCookieCell = (cellArguments: any, indexTimeStart: any, indexTimeEnd: any) => {
        const {data, collCells, resizeWay, resizableWay} = cellArguments;
        const {timeInterval} = this.props;
        let newCell: dataCell = {
            doctorId: data.doctorId
        };

        if (resizableWay === 'top') {
            if (resizeWay === 'top') {
                newCell.timeEnd = data.timeEndInterval;
                newCell.timeStart = timeInterval[indexTimeEnd - collCells - 1];
            }
        } else {
            if (resizeWay === 'bottom') {
                newCell.timeStart = data.timeStart;
                newCell.timeEnd = timeInterval[indexTimeStart + collCells + 1];
            }
        }
        return newCell;
    };

    removeCookieCell = () => {
        const template = Helper.clone(this.props.template);
        const selectedCell = this.state.selectedCell as any;

        if (selectedCell !== null) {
            if (selectedCell.data.doctorId === 'cookie') {
                let chairId: any = null, idx: any = null;
                Helper.forEachObj(template, (chair: any, chairName: any) => {
                    chair.forEach((cell: any, index: any) => {
                        if (cell.doctorId === 'cookie') {
                            chairId = chairName;
                            idx = index;
                        }
                    });
                });

                const newCells: any = [];
                if (idx !== null) {
                    template[chairId].forEach((cell: any, index: any) => {
                        if (idx !== index) newCells.push(cell);
                    });

                    template[chairId] = newCells;
                }
            }
        }

        return template;
    };

    handleClickCell = (selectedCell: any, isShift: any) => {
        const newState: any = {selectedCell};
        let newTemplate: any = Helper.clone(this.props.template);
        if (selectedCell.data.doctorId !== 'cookie' && !isShift) {
            newTemplate = this.removeCookieCell();
        }
        if (isShift) {
            const currentCell = Helper.clone(this.state.selectedCell);
            const {timeInterval} = this.props;

            const indexStart = timeInterval.indexOf(currentCell.data.timeStart);
            const indexEnd = timeInterval.indexOf(selectedCell.data.timeStart);
            let collCells = indexStart - indexEnd;
            if (collCells < 0) {
                newState.selectedCell = currentCell;
                newState.selectedCell.data.timeEnd = selectedCell.data.timeEndInterval;
            } else {
                newState.selectedCell.data.timeEnd = currentCell.data.timeEndInterval;
            }
            if (newState.selectedCell.data.doctorId === 0) {
                newState.selectedCell.data.doctorId = 'cookie';
                if (newTemplate[newState.selectedCell.chairId] === undefined) {
                    newTemplate[newState.selectedCell.chairId] = [];
                }
                newTemplate[newState.selectedCell.chairId].push(newState.selectedCell.data)
            }

        }

        this.setState(newState);
        this.props.handleTemplate(newTemplate);
    };

    handleContextMenu = (positionContextMenu: any, selectedCell: any, typeContextMenu: string) => {
        const newState = {
            showContextMenu: true,
            positionContextMenu,
            selectedCell,
            typeContextMenu
        };
        this.setState(newState);
    };

    removeContextMenu = () => this.setState({showContextMenu: false});

    handleDoctor = (doctor: any): void => {
        const {selectedCell} = this.state;
        const {data, chairId} = selectedCell as any;

        const newState = {
            currentDoctorId: this.state.currentDoctorId,
            showContextMenu: this.state.showContextMenu
        };

        const newTemplate = Helper.clone(this.props.template);
        let column = newTemplate[String(chairId)];

        if (data.doctorTime !== undefined) {
            column.forEach((cell: any) => {
                if (cell.doctorId === data.doctorId) {
                    cell.doctorId = doctor.id;
                }
            })
        } else {

            if (column === undefined) column = [];


            column.push({
                timeStart: data.timeStart,
                timeEnd: data.timeEndInterval,
                doctorId: doctor.id,
            });

            if (column.length > 1) column.sort((a: any, b: any) => sortName(a.timeStart, b.timeStart));
        }

        newState.currentDoctorId = doctor.id;
        newState.showContextMenu = false;

        newTemplate[String(chairId)] = this.joinCell(column, 'bottom');

        this.setState(newState);
        this.props.handleTemplate(newTemplate);
    };

    handleConflict = (type: string) => {
        const {chairId} = this.state.selectedCell as any;

        const newState = {
            selectedCell: null,
            showContextMenu: false
        };

        const newTemplate = Helper.clone(this.props.template);
        newTemplate[chairId] = this.props.allTemplate[type][this.props.dayTab][chairId];

        this.setState(newState);
        this.props.handleTemplate(newTemplate);
    };

    deleteSchedule = (selectedCell: any) => {
        const newState = {
            selectedCell: null,
            showContextMenu: false
        };
        const newTemplate = Helper.clone(this.props.template);

        const newCells: any = [];

        newTemplate[selectedCell.chairId].forEach((cell: any) => {
            if (cell.doctorId !== 0) {
                if (cell.timeStart !== selectedCell.data.timeStart) {
                    newCells.push(cell);
                }
            }
        });

        newTemplate[selectedCell.chairId] = newCells;
        this.setState(newState);
        this.props.handleTemplate(newTemplate);
    };
}
