import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth38Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 14.18 24"} width={width || "26"} height={height || "44"} ref={ref}>
            <path d="M2.64,16.32c1.76,5.05,4.25,7.3,5.48,7.58a.25.25,0,0,0,.29.08.29.29,0,0,0,.17-.28.3.3,0,0,0-.15-.3,10.58,10.58,0,0,0-.74-1.58,13.91,13.91,0,0,1-1.9-7.32.66.66,0,0,1,.44-.62.48.48,0,0,1,.54.36c2.41,6.7,5.54,8.59,6.85,8.65a.6.6,0,0,0,.56-.2.55.55,0,0,0-.13-.4c-.16-.25-.38-.53-.63-.89a12.78,12.78,0,0,1-2.63-5.33,33.47,33.47,0,0,1-.44-7.65,2.86,2.86,0,0,1-.64.16,10.66,10.66,0,0,1-1.21.07,8.13,8.13,0,0,1-3.37-.74,3.79,3.79,0,0,0-1.91-.43A6.31,6.31,0,0,1,.93,7.36a40,40,0,0,0,1.7,9ZM.2,3.65c.07.35.12.68.16,1,.25,1.65.3,2,2.84,1.82a4.51,4.51,0,0,1,2.26.49,7.39,7.39,0,0,0,4.21.62c1.83-.21,2-2.8,2-4.33a3.31,3.31,0,0,0-.47-2A.91.91,0,0,0,10.4,1H10A2.24,2.24,0,0,1,8.72.81C6.74-.39,5.86,0,5.12.44a3.12,3.12,0,0,1-2.19,0C1.93.23.79,0,.3.6c-.35.45-.4,1.51-.11,3Z"/>
        </WrapIcon>
    );
});
