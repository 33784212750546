import React, {useEffect, useRef, useState} from 'react';
import {PCheckbox} from "./typesCheckbox";
import {SCheckboxLabel, SCheckboxAfter, SCheckboxNone, SCheckboxText, SCheckboxTooltip} from "./styledCheckbox";
import SVG from "../../../common/helpers/SVG";

export default function Checkbox({label, checked = false, disabled = false, onChange, tooltip, margin, size = "md"}: PCheckbox) {
    const [isHovered, setIsHovered] = useState(false);
    const [widthTooltip, setWidthTooltip] = useState(0);
    const [positionTooltip, setPositionTooltip] = useState({
        x: 0,
        y: 0
    });
    const ref = useRef(null) as any;

    useEffect(() => {
        if (!!tooltip) setWidthTooltip(ref.current.offsetWidth);
    }, []);

    const styleTooltip = {
        left: positionTooltip.x - 16 - (widthTooltip / 2),
        top: positionTooltip.y + 20
    };

    const handleMouseEnter = (e: any) => {
        if (!!tooltip) {
            if (tooltip.length) {
                setIsHovered(true);
                setPositionTooltip({
                    x: e.clientX,
                    y: e.clientY
                })
            }
        }
    };

    const handleMouseMove = (e: any) => {
        if (!!tooltip) {
            if (tooltip.length) {
                setPositionTooltip({
                    x: e.clientX,
                    y: e.clientY
                })
            }
        }
    };

    const handleMouseLeave = () => {
        if (!!tooltip) {
            if (tooltip.length) setIsHovered(false)
        }
    };

    return (
        <SCheckboxLabel size={size} margin={margin} onClick={() => {
            if (!!onChange && !disabled) onChange(!checked)
        }}>
            {!!tooltip && <SCheckboxNone children={tooltip} ref={ref}/>}
            <SCheckboxText onMouseEnter={handleMouseEnter}
                           onMouseMove={handleMouseMove}
                           onMouseLeave={handleMouseLeave}
                           checked={checked}
                           disabled={disabled}
                           size={size}
            >
                <SCheckboxAfter checked={checked} size={size}>
                    <SVG name="check" />
                </SCheckboxAfter>
                {label}
            </SCheckboxText>
            {isHovered && <SCheckboxTooltip style={styleTooltip} children={tooltip} />}
        </SCheckboxLabel>
    )
}
