import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth73Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 18.68 24"} width={width || "12"} height={height || "16"} ref={ref}>
            <path d="M.21,12C.89,17.27,3,23.6,8.93,24c2.67.17,4.94-1.05,6.77-3.65C18.79,15.91,19.17,9.59,18.21,8c-1.95-3.16-7.94-8-10-8S1.82,2.42.2,8a9.56,9.56,0,0,0,0,4Z"/>
        </WrapIcon>
    );
});
