import React from "react";
import Tag from "../../../../../../../components-ui/DataDisplay/Tag/Tag";
import {Task, TasksInfo} from "../../../../../../../containers/Tasks";
import { SBoxFlex } from "../../../../../../../containers/Tasks/style";

interface Props {
    uniqType: Task[]
    tasksInfo: TasksInfo
}

const TagsSimpleTask = ({uniqType, tasksInfo}: Props) => {
    return (
        <SBoxFlex padding="20px 0 0">
            {uniqType.map(task => <Tag key={task.type + task.id} children={tasksInfo[task.type].name}
                                       margin="0 7px 8px 0"/>)}
        </SBoxFlex>
    )
}

export default TagsSimpleTask
