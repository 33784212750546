import React from 'react';
import {AverageCheckReport} from "../../../types";
import useAverageCheck from "./hooks/useAverageCheck";
import useCodes from "./hooks/useCodes";
import NoReport from "../../../components/NoReport";
import Filter from "./components/Filter";
import ContentWidgetWrap from '../../../components/ContentWidgetWrap';
import Helper from "../../../../../common/helpers/main";
import ChartBar from "../../../../../components-ui/Charts/ChartBar";
import useFormatterUser from "./hooks/useFormatterUser";
import {useSelector} from "react-redux";
import useData from "./hooks/useData";
import useDirectionsColors from "../../../../../common/hooks/useDirectionsColors";
import Legend from "./containers/Legend/Legend";
import useDoctorsColors from "./hooks/useDoctorsColors";
import _ from 'lodash'
import {clinicInfo} from "../../../data/widgetsInfo";

interface Props {
    report: AverageCheckReport
    stateKey: string
}

const AverageCheck = ({report, stateKey}: Props) => {
    const directions = _.clone(useSelector(state1 => state1.common.directions))
    directions.clinic = clinicInfo;
    const {state, handlers} = useAverageCheck();
    const codes = useCodes({report, state});
    const formatterUser = useFormatterUser()
    const data = useData({state, report, stateKey})
    const directionsColors = useDirectionsColors(true)
    const doctorsColors = useDoctorsColors()

    if (!!codes.length && !!data) {
        return (
            <>
                <Filter codes={codes} state={state} handlers={handlers}/>
                <ContentWidgetWrap>
                    <Legend state={state} codes={codes} handlers={handlers} data={data}/>
                    <ChartBar
                        data={data}
                        hoverIndex={state.hoverIndex}
                        setActiveIndex={handlers.handleHoverIndex}
                        colors={state.currentTab === 'directions' ? directionsColors : doctorsColors}
                        paddingTooltip={state.currentTab === 'directions' ? '6px 0' : '2px 0'}
                        formatterValue={value => Helper.String.numberFormat(value, 0)}
                        formatterNameTooltip={value => state.currentTab === 'directions' ? formatterUser(value) : directions[value].name}
                        formatterTitleTooltip={value => state.currentTab !== 'directions' ? formatterUser(value) : directions[value].name}
                        height={245}
                        width="100%"
                    />
                </ContentWidgetWrap>
            </>
        );
    } else {
        return <NoReport/>
    }
};

export default AverageCheck;
