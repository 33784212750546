import React from 'react';
import Typography from "../../../../components-ui/DataDisplay/Typography/Typography";
import {commonPropsTypography} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {STaskItem} from "../../../../containers/Tasks/style";
import {Link} from "../../../../components-ui/Navigation/Link";
import Icon from "../../../../components-ui/DataDisplay/Icon";

interface Props {
    recordLink: string | null
}

export const AudioRecordButton = ({recordLink}: Props) => {
    if (!recordLink) return null;
    return (
        <STaskItem margin="2px 0">
            <Typography {...commonPropsTypography} children="Аудиозапись разговора" flexShrink={0} padding="0 8px 0 0"/>
            <Link href={recordLink}
                  isCircle={true}
                  outline={true}
                  padding="2px 1px 2px 3px"
                  children={<Icon name="playArrow" width="8px" height="8px" color="info"/>}/>
        </STaskItem>
    );
}
