import React from 'react'
import DateHelper from '../../../common/helpers/date-helper'
import {getColor} from "../helperCalendar";
import {DayWrap, DayContent, DayD, DayM} from '../styledCalendar';

interface IDay {
    dayData?: any,
    onClick?: any,
    day?: any,
    curMonth?: any,
    isSelected?: any
}

export default function Day({dayData, onClick, day, curMonth, isSelected}: IDay) {
    let newDayData = Object.assign({}, dayData);
    const color = getColor(newDayData.engagedTime, newDayData.generalTime);
    let dayStyle = {
        backgroundColor: '#' + color.background,
        color: '#' + color.text
    };

    let title = '';
    let freeTime = DateHelper.formatMinutes(newDayData.generalTime - newDayData.engagedTime);
    let generalTime = DateHelper.formatMinutes(newDayData.generalTime);

    title += '<div>Пациентов - ' + Number(newDayData.patientsCount) + '</div>';
    title += '<div>Свободно - ' + freeTime + ' из ' + generalTime + '</div>';

    return (
        <DayWrap active={isSelected} onClick={onClick} data-tip={title}>
            <DayContent style={dayStyle} active={isSelected} current={newDayData.isCurrent}>
                <DayD children={day} />
                <DayM children={curMonth} />
            </DayContent>
        </DayWrap>
    )
}


