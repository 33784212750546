import * as React from "react";
import {Component} from "react";
import './Schedule.scss';
// @ts-ignore
import ServerCommand from 'common/server/server-command'
// @ts-ignore
import Helper from 'common/helpers/main'
// @ts-ignore
import ScheduleProcessor from 'common/schedule/schedule-processor'
// @ts-ignore
import CookieHelper from "common/helpers/cookie-helper"
// @ts-ignore
import GeneralHelper from "common/helpers/general-helper"
import moment from "moment";
import Filter from "./elements/filter/Filter";
import Calendar from "../../containers/Calendar/Calendar";
import ScheduleDay from "./elements/schedule-day/ScheduleDay";
import {
    addMemorizedPatient,
    addTransferPatient,
    loadSchedule, removeMemorizedPatient, removeTransferPatient,
    updateSchedule,
    updateSplittedTime
} from "../../redux/actions/scheduleActions";
import {echo} from "../../common/server/echo";
import Obj from "../../common/helpers/object";
import _ from 'lodash';
import Loader from "../../components-ui/Feedback/Loader/Loader";
import Box from "../../components-ui/Layout/Box";
import LoaderPage from '../../components/LoaderPage';
import {ScheduleUpdatedEventData} from "./types";


export default class Schedule extends Component<any, any> {

    state = {
        loading: false
    }

    render() {

        const {schedule} = this.props;
        if (schedule === null) return <LoaderPage/>;

        return (
            <>
                <Filter/>
                <div className="schedule" style={{
                    pointerEvents: this.state.loading ? 'none' : 'auto'
                }}>
                    <Calendar handleSelectedDates={this.handleSelectedDates}/>
                    <div className="schedule__center">
                        {this.renderDaysSchedule()}
                    </div>
                    <Loader visible={this.state.loading} zIndex={11} />
                </div>
            </>
        );
    }


    renderDaysSchedule = () => {
        let scheduleProcessor = new ScheduleProcessor(
            this.props.schedule,
            this.props.filter,
            this.props.doctors,
            this.props.splittedTime,
            this.props.switcher
        );

        if (scheduleProcessor.isScheduleEmpty()) return (
            <Box display="flex" justifyContent="center" alignItems="center" padding="0 0 0 217px">
                Нет подходящих интервалов расписания
            </Box>);

        scheduleProcessor.process();
        const schedule = scheduleProcessor.getSchedule();
        const timeLine = scheduleProcessor.getTimeLine();
        const filter = scheduleProcessor.getFilter();
        const availableTimeUnite = scheduleProcessor.getAvailableTimeUnite();

        let dayCounter = 0;
        let daysCount = Object.keys(schedule.days).length;
        const scheduleDates = Object.keys(schedule.days);
        const currentDate = moment().format('YYYY-MM-DD');

        return Helper.mapObj(schedule.days, (daySchedule: any, date: any) => {
            dayCounter++;

            if (daySchedule.schedule.length > 0) {
                return (
                    <ScheduleDay {...daySchedule}
                                 timeLimits={schedule.timeLimits}
                                 timeLine={timeLine}
                                 availableTimeUnite={availableTimeUnite}
                                 commands={schedule.commands}
                                 date={date}
                        //@ts-ignore
                                 update={this.updateDaySchedule.bind(this, [date])}
                                 splitInterval={this.splitInterval.bind(this, scheduleDates)}
                                 uniteInterval={this.uniteInterval.bind(this, scheduleDates)}
                                 filter={filter}
                                 doctors={this.props.doctors}
                                 patients={schedule.patients}
                                 serverTimeDiff={this.props.serverTimeDiff}

                                 showLeftTimeLine={dayCounter === 1}
                                 centerRightTimeLine={daysCount !== dayCounter}
                                 resizeVisit={this.resizeVisit.bind(this)}
                                 currentDate={currentDate}
                                 copiedPatient={this.props.copiedPatient}
                                 transferPatient={this.props.transferPatient}
                                 onCopyPatient={this.onCopyPatient}
                                 clearPatientsBuffer={this.clearPatientsBuffer}
                    />
                );
            } else return null;
        });
    };

    handleSelectedDates = (selectedDates: any) => {
        const {schedule} = this.props as any;
        let loadedDates = Object.keys(schedule.days);
        let newDates = Helper.Array.diff(selectedDates, loadedDates);

        if (newDates.length) {
            this.updateDaySchedule(newDates, selectedDates);
        } else {
            let newSchedule = Helper.clone(schedule);
            newSchedule.days = this.deleteExcessDays(selectedDates, schedule.days);
            this.props.dispatch(updateSchedule(newSchedule));
        }
    };

    deleteExcessDays(necessaryDates: any, days: any) {
        return Helper.filterObj(days, (daySchedule: any, date: any) => {
            return (necessaryDates.indexOf(date) !== -1);
        });
    }

    /**
     * Загружает расписание за определённые дни (при этом загруженное расписание за остальные дни не затрагивается)
     * @param dates - даты для загрузки
     * @param allDates - дни, которые нужно отображать после загрузки. По умолчанию отображаются все дни, в ином случае
     * будут удалены те дни, которые не указаны в этом параметре.
     */
    updateDaySchedule(dates: any = null, allDates: any = null) {
        const {schedule} = this.props as any;
        if (!dates) {
            dates = Object.keys(schedule.days);
        }

        ServerCommand.get('schedule/days', {dates, branches: this.props.filter.branches}).then((response: any) => {
            let newSchedule = Helper.clone(this.props.schedule);
            Object.assign(newSchedule.days, response.days);
            Object.assign(newSchedule.patients, response.patients);

            if (allDates) {
                newSchedule.days = this.deleteExcessDays(allDates, newSchedule.days);
            }

            newSchedule.days = Helper.sortObj(newSchedule.days);

            /* resolve может модифицировать новое состояние */
            this.props.dispatch(updateSchedule(newSchedule));
        });
    }

    loadingData = (isLoadFilter: boolean = true) => {
        /* загрузка данных для отобажения расписания */
        this.setState({loading: true})
        const {user, selectedDates} = this.props;
        ServerCommand.get('schedule/main-form', {branches: this.props.filter.branches, dates: selectedDates}).then((response: any) => {
            this.props.dispatch(loadSchedule({...response, user, selectedDates, isLoadFilter}));
            this.setState({loading: false})
        });
    }


    componentDidMount() {
        window.addEventListener('unload', this.finalize);
        this.loadingData()
        echo.private('schedule')
            .listen('VisitCreated', (params: any) => this.addVisit(params.visit))
            .listen('VisitUpdated', (params: any) => this.updateVisit(params.visit))
            .listen('Schedule\\ScheduleUpdated', ({dates, sinceDate}: ScheduleUpdatedEventData) => {
                const {schedule} = this.props as any;
                const loadedDates = _.keys(schedule.days);

                let datesToUpdate = _.intersection(loadedDates, dates);

                if (sinceDate) {
                    const loadedDatesSinceDate = loadedDates.filter(loadedDate => loadedDate >= sinceDate);

                    datesToUpdate.push(...loadedDatesSinceDate);
                    datesToUpdate = _.uniq(datesToUpdate);
                }

                if (datesToUpdate.length > 0) {
                    this.updateDaySchedule(datesToUpdate);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<any>) {
        if (!_.isEqual(prevProps.filter.branches, this.props.filter.branches)) {
            this.loadingData(false)
        }
    }

    componentWillUnmount() {
        this.finalize();
        window.removeEventListener('unload', this.finalize);
    }

    finalize = () => {
        CookieHelper.setCookie('scheduleSplittedTime', JSON.stringify(this.props.splittedTime));
        echo.leaveChannel('schedule');
    };

    splitInterval(dates: any, time: any) {
        let splittedTime = GeneralHelper.clone(this.props.splittedTime);

        dates.forEach((date: any) => {
            if (!splittedTime[date]) {
                splittedTime[date] = [];
            }

            if (splittedTime[date].indexOf(time) === -1) {
                splittedTime[date].push(time);
            }
        });

        this.props.dispatch(updateSplittedTime(splittedTime))
    }

    uniteInterval(dates: any, time: any) {
        let splittedTime = GeneralHelper.clone(this.props.splittedTime);

        dates.forEach((date: any) => {
            if (!splittedTime[date]) return;

            time = Helper.Date.getStandardIntervalTime(time);

            splittedTime[date].splice(splittedTime[date].indexOf(time), 1);
        });
        this.props.dispatch(updateSplittedTime(splittedTime))
    }

    /**
     * Добавляет приём
     * @param visit{Object} - данные приёма
     */
    addVisit(visit: any) {
        const patient = visit.patient;

        if (!this.isChairLoaded(visit.work_chair_id, visit.date)) {
            return;
        }

        const schedule = Helper.clone(this.props.schedule);
        const day = schedule.days[visit.date];

        let updatingChairSchedule = null as any;

        day.schedule.forEach((chairSchedule: any) => {
            if(chairSchedule.chair.id === visit.work_chair_id) {
                updatingChairSchedule = chairSchedule;
            }
        });

        const transformedVisit: any = {};

        Obj.forEach(visit, (value: any, key: string) => {
            transformedVisit[key.toUpperCase()] = value;
        });

        updatingChairSchedule.visits[visit.time_start] = transformedVisit;

        schedule.patients[patient.id] = patient;
        this.props.dispatch(updateSchedule(schedule));
    }

    updateVisit(visit: any) {
        if (!this.isChairLoaded(visit.work_chair_id, visit.date)) {
            return;
        }

        let schedule = Helper.clone(this.props.schedule);
        const day = schedule.days[visit.date];

        let updatingChairSchedule = null as any;

        day.schedule.forEach((chairSchedule: any) => {
            if(chairSchedule.chair.id === visit.work_chair_id) {
                updatingChairSchedule = chairSchedule;
            }
        });

        if(visit.status === 'CANCELED_IN_ADVANCE') {
            delete updatingChairSchedule.visits[visit.time_start];
        } else {
            let visits = Helper.Array.toObject(Object.values(updatingChairSchedule.visits), 'ID');

            const transformedVisit: any = {};

            Obj.forEach(visit, (value: any, key: string) => {
                transformedVisit[key.toUpperCase()] = value;
            });

            visits[visit.id] = transformedVisit;
            updatingChairSchedule.visits = Helper.Array.toObject(Object.values(visits), 'TIME_START');
        }
        this.props.dispatch(updateSchedule(schedule));
    }

    isChairLoaded(chairId: number, date: string): boolean {
        const {schedule} = this.props;

        return date in schedule.days
            && schedule.days[date].schedule.some((chairSchedule: any) => chairSchedule.chair.id === chairId)
    }

    resizeVisit(date: any, timeStart: any, newTimeStart: any, newTimeEnd: any, chairId: any) {
        let schedule = Helper.clone(this.props.schedule);
        const day = schedule.days[date];

        if (!day) return;

        day.schedule = day.schedule.map((chairSchedule: any) => {
            if (chairSchedule.chair.id === chairId) {
                if (chairSchedule.visits[timeStart] !== undefined) {
                    chairSchedule.visits[timeStart].TIME_START = newTimeStart;
                    chairSchedule.visits[timeStart].TIME_END = newTimeEnd;
                }
            }
            return chairSchedule
        });

        this.props.dispatch(updateSchedule(schedule));

    }

    onCopyPatient = (patient: any, receptionTransfer = false) => {
        if (receptionTransfer) {
            this.props.dispatch(addTransferPatient(patient))
        } else {
            this.props.dispatch(addMemorizedPatient(patient))
        }
    };

    clearPatientsBuffer = (isTransfer: any) => {
        if(isTransfer) {
            this.props.dispatch(removeTransferPatient());
        } else {
            this.props.dispatch(removeMemorizedPatient())
        }
    };
}
