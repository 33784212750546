import React from 'react';
import {OptionSelect} from '../../../../../../components-ui/Inputs/Select/typesSelect';
import {useSelector} from 'react-redux';
import {notTrashed} from '../../../../../../common/helpers/general-helper';
import {Label} from '../../../../../../components-ui/DataDisplay/Label';
import {commonLabelOption, commonSelectOption,} from '../../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import Select from '../../../../../../components-ui/Inputs/Select/Select';

interface Props {
    doctor?: OptionSelect
    handleDoctor: (val: OptionSelect) => void
    selectedBranch: number
    isValid: boolean
}

const SelectDoctor = ({doctor, handleDoctor, selectedBranch, isValid}: Props) => {

    const doctors = useSelector(state => state.common.doctors);

    const optionsFilteredDoctors = doctors
        .filter(notTrashed)
        .filter(doc => doc.branches.includes(selectedBranch))
        .sort()
        .map(doc => {
            return {value: doc.id, label: doc.fio, color: doc.color};
        });

    return (
        <Label labelText="Врач" isRequired={true} {...commonLabelOption} >
            <Select {...commonSelectOption}
                    colorSelect="white"
                    placeholder="Выберите врача"
                    variant='colored'
                    value={doctor}
                    options={optionsFilteredDoctors}
                    isError={isValid}
                    onChange={(value) => handleDoctor(value)}
            />
        </Label>
    );
};

export default SelectDoctor;