import React from 'react';
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import Box from '../../../../../components-ui/Layout/Box';
import Paper from '../../../../../containers/UI-Collection/Elements/Surfaces/Paper';

const PaymentAbsentMessage = () => {
    return (
        <Box width="max-content" margin="0 auto">
            <Paper padding="45px 60px 71px">
                <Typography
                    fontWeight="600"
                    variant="big-title"
                    textAlign="center"
                >Оплат пока нет</Typography>
            </Paper>
        </Box>
    );
};

export default PaymentAbsentMessage;