import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const CheckedBoldIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 19.2"} ref={ref}>
            <polygon points="20.37 0 8.97 12.34 3.13 6.11 0 9.44 9.21 19.2 24 3.96 20.37 0"/>
        </WrapIcon>
    );
});
