import React, {Fragment} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {Nav, Link, LinkTitle, LinkOption, WrapSubsNav, SubNav} from "./style";
import Icon from "../../components-ui/DataDisplay/Icon";
import useLinks from "./hooks/useLinks";
import {useSelector} from 'react-redux';
import {useDialogsManager} from "../DialogsManager/DialogsManager";

const NavMenu = () => {

    const user = useSelector(state => state.user)

    const {showModal} = useDialogsManager();

    const links = useLinks();
    const location = useLocation();
    return (
        <Nav>
            {links.map(link => {
                let active = location.pathname === link.link;
                if (!!link.subsNav) active = link.subsNav.some(subNav => location.pathname === subNav.link);

                let children = (
                    <Link active={active} isLink={!!link.link}>
                        <Icon name={link.icon} width="21px" height="21px" color={active ? 'primary' : 'white'} margin="0 0 5px"/>
                        <LinkTitle>{link.name}</LinkTitle>
                        {!!link.subsNav && (
                            <WrapSubsNav>
                                {link.subsNav.map(subNav => {
                                    const activeSubNav = location.pathname === subNav.link;
                                    if (subNav.link) {
                                        return (
                                            <NavLink key={subNav.code} to={subNav.link} exact
                                                     style={{textDecoration: 'none'}}>
                                                <SubNav active={activeSubNav} children={subNav.name}/>
                                            </NavLink>
                                        )
                                    } else {
                                        return (
                                            <a onClick={() => showModal(subNav.component)}>
                                                <SubNav active={activeSubNav} children={subNav.name}/>
                                            </a>
                                        )
                                    }

                                })}
                            </WrapSubsNav>
                        )}
                    </Link>
                )

                if (!!link.link) {
                    children = <NavLink exact to={link.link} style={{textDecoration: 'none'}} children={children}/>
                }

                return (
                    <Fragment key={link.code}>
                        {children}
                    </Fragment>

                )
            })}
            <LinkOption isLink={false}>
                <Icon name="options" width="21px" height="21px" color="white" margin="0 0 5px"/>
                <LinkTitle>Настройки</LinkTitle>
                <WrapSubsNav style={{top: 'auto', bottom: 0}}>
                {user.canEditUsers &&
                    <NavLink key={'employees'} to="/settings/employees" exact
                             style={{textDecoration: 'none'}}>
                        <SubNav active={false} children={"Пользователи"}/>
                    </NavLink>
                }
                    <NavLink key={'kkm'} to="/settings/kkm" exact
                             style={{textDecoration: 'none'}}>
                        <SubNav active={false} children={"Интеграция KKM"}/>
                    </NavLink>
                    <NavLink key={'kkm'} to="/settings/handset" exact
                             style={{textDecoration: 'none'}}>
                        <SubNav active={false} children={"Трубки"}/>
                    </NavLink>
                </WrapSubsNav>
            </LinkOption>
        </Nav>
    )
}

export default NavMenu
