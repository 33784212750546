import React from "react";
import {ControlsWrap, ControlsButton, ControlsArrow} from '../styledCalendar'
import SVG from "../../../common/helpers/SVG";


export default function Controls({handleControl}: any) {
    return (
        <ControlsWrap>

            <ControlsButton onClick={() => handleControl('top', 'month')} data-tip="<div>На месяц назад</div>">
                <ControlsArrow as={SVG} name="arrow-select" month />
                <ControlsArrow as={SVG} name="arrow-select" month two />
            </ControlsButton>
            <ControlsButton onClick={() => handleControl('top', 'week')} data-tip="<div>На неделю назад</div>">
                <ControlsArrow as={SVG} name="arrow-select" />
            </ControlsButton>
            <ControlsButton onClick={() => handleControl('bottom', 'week')} data-tip="<div>На неделю вперёд</div>">
                <ControlsArrow as={SVG} name="arrow-select" down/>
            </ControlsButton>
            <ControlsButton onClick={() => handleControl('bottom', 'month')} data-tip="<div>На месяц вперёд</div>">
                <ControlsArrow as={SVG} name="arrow-select" down month/>
                <ControlsArrow as={SVG} name="arrow-select" down month two/>
            </ControlsButton>
        </ControlsWrap>
    )
}
