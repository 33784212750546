import {useDispatch} from "react-redux";
import {resetDataLoaded} from "../../../redux/actions/commonActions";
import ServerCommand from "../../../common/server/server-command";
import React from "react";
import Paper from "../../UI-Collection/Elements/Surfaces/Paper";
import {List, ListItem} from "../../../components-ui/DataDisplay/List";
import { Divider } from "../../../components-ui/DataDisplay/Divider";

interface IUserMenu {
    submenuIsVisible: boolean
}

export default function UserMenu({submenuIsVisible} : IUserMenu) {
    const dispatch = useDispatch();

    if (!submenuIsVisible) return null;

    const logout = (e: any) => {
        e.preventDefault();
        dispatch(resetDataLoaded());
        ServerCommand.logout();
    };

    return (
        <Paper isModal={true} arrowPosition="top" position="bottom" minWidth="100%">
            <List>
                <Divider width="calc(100% - 32px)" margin="4px auto"/>
                <ListItem children="Выйти" onClick={logout}/>
            </List>
        </Paper>
    )
};
