import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const FolderIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 19.69"} ref={ref}>
            <path d="M24,19.69H0V0H11.05l3,4.48H24Z"/>
        </WrapIcon>
    );
});
