import {TasksInfo} from "../../../containers/Tasks";

export const callCenterTasksInfo = {
    back_call: {
        name: 'Заявка с сайта',
        title: null,
        postponeFor: 1,
        widthButton: 126
    },
    care: {
        name: 'Забота',
        title: 'Выполненные услуги',
        postponeFor: 24,
        widthButton: 74
    },
    confirm_visit: {
        name: 'Подтверждение приёма',
        title: 'Предстоящие приёмы',
        postponeFor: 24,
        widthButton: 192
    },
    incoming_call: {
        name: 'Входящий звонок',
        title: null,
        postponeFor: 1,
        widthButton: 149,
        contents: [],
        buttons: []
    },
    lead_plan: {
        name: 'План лечения',
        title: 'Последний приём',
        postponeFor: 1,
        widthButton: 123
    },
    missed_call: {
        name: 'Пропущенный звонок',
        title: null,
        postponeFor: 1,
        widthButton: 179,
        contents: [],
        buttons: []
    },
    missed_visit: {
        name: 'Пропущенный приём',
        title: 'Пропущенные приёмы',
        postponeFor: 1,
        widthButton: 185
    },
    planned_visit: {
        name: 'Запланированный приём',
        title: null,
        postponeFor: 24,
        widthButton: 203
    },
    schedule_change: {
        name: 'Изменение расписания',
        title: "Отменённые приёмы",
        postponeFor: 24,
        widthButton: 189
    },
    survey_invite: {
        name: 'Приглашение на осмотр',
        title: 'Выполненные услуги',
        postponeFor: 24,
        widthButton: 195
    },
    treatment_refusal: {
        name: 'Отказ от лечения',
        title: 'Последний приём',
        postponeFor: 1,
        widthButton: 168
    },
    waiting_list: {
        name: 'Лист ожидания',
        title: null,
        postponeFor: 24,
        widthButton: 131
    },
    long_absence: {
        name: 'Не были больше года',
        title: null,
        postponeFor: 24,
        widthButton: 175
    }
} as TasksInfo

export const headerTabsDetail = [
    {name: 'Задачи', code: 'task'},
    {name: 'История', code: 'history'},
]

export const buttonTitles = {
    note_down: 'Записать на приём и завершить задачу',
    canceled: 'Отменить приём и завершить задачу',
    confirm: 'Подтвердить приём и завершить задачу',
    addWL: 'Добавить пациента в лист ожидания или запланировать приём',
    refuse: 'Отказаться от предложенного времени и завершить задачу',
    deleteWL: 'Удалить пациента из листа ожидания',
    final: 'Завершить задачу',
    transfer: 'Перенести приём и завершить задачу',
    performed: 'Открыть согласованный план лечения',
    recommended: 'Открыть рекомендуемый план лечения',
    blocked: 'Работать с этой задачей можно только при звонке',
    againstCalls: 'Отметить, что пациент против маркетинговых звонков, и завершить задачу',
    script: 'Открыть скрипт для задачи',
    noteValidate: 'Заполните тему разговора'
}
