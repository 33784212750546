import moment from "moment";
import {getColor, months} from "../helperCalendar";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Helper from "../../../common/helpers/main";
import {calendarDayClick} from "../../../redux/actions/scheduleActions";
// @ts-ignore
import Day from "./Day";
import useCtrlDown from "../../../common/hooks/useCtrlDown";

export default function CalendarBody({handleSelectedDates}: any) {
    let result = [];
    const data = useSelector(state => state.schedule.calendar);
    const selectedDates = useSelector(state => state.schedule.selectedDates);
    let startDate = moment(data.dateFrom);
    let endDate = moment(data.dateTo);
    const [ctrlPressed, setCtrlPressed] = useState(false);
    const dispatch = useDispatch();

    useCtrlDown((value: boolean) => {
       setCtrlPressed(value);
    });

    const handleDayClick = (date: string, dayData: string, isSelected: boolean) => {
        let newSelectedDates;

        if (isSelected) {
            if (ctrlPressed) {
                if (selectedDates.length !== 1) {
                    newSelectedDates = selectedDates.filter((selectedDate: string) => selectedDate !== date);
                } else {
                    newSelectedDates = selectedDates.slice();
                }
            } else {
                newSelectedDates = [date];
            }
        } else {
            if (ctrlPressed) {
                if (selectedDates.indexOf(date) === -1) {
                    newSelectedDates = selectedDates.slice();
                    newSelectedDates.push(date);
                }
            } else {
                newSelectedDates = [date]
            }
        }

        if (!Helper.isEqual(newSelectedDates, selectedDates)) {
            dispatch(calendarDayClick(newSelectedDates));
            handleSelectedDates && handleSelectedDates(newSelectedDates)
        }
    };


    while (endDate.diff(startDate, 'days') >= 0) {
        let curDateString = startDate.format('YYYY-MM-DD');
        let monthIndex = startDate.month();
        let date = startDate.format('YYYY-MM-DD');
        const day = startDate.date();

        const isSelected = selectedDates.indexOf(date) !== -1;

        result.push(
            <Day day={day}
                 curMonth={months[monthIndex]}
                 dayData={data.dateData[curDateString]}
                 isSelected={isSelected}
                 onClick={() => handleDayClick(date, data.dateData[curDateString], isSelected)}
                 key={date}
            />
        );

        startDate.add(1, 'd');
    }

    return (
        <>
            {result}
        </>
    );
};
