import React from "react";
import commonLabelOptions from "../../../../../../../containers/PatientField/helper/commonLabelOptions";
import TextField from "../../../../../../../components-ui/Inputs/TextField/TextField";
import {Label} from "../../../../../../../components-ui/DataDisplay/Label";
import {useWAP} from "../../../WaitingAndPlanned";

export default function CommentFormWAP() {
    const {form, onChangeField} = useWAP()
    const {comment} = form;
    return (
        <Label labelText='Комментарий'
               {...commonLabelOptions(true, 'WAP')}
               align="leftTop"
               marginLabel="5px 10px 0 0"
               marginWrap="0 0 14px"
        >
            <TextField onChange={value => onChangeField(value, 'comment')}
                       isTextArea={true}
                       widthInput="458px"
                       minHeight="80px"
                       value={comment}/>
        </Label>
    )
}
