import React from 'react';
import PropTypes from "prop-types";
import {Icon} from "../../../components/Icons/Icons";
import Helper from "../../../common/helpers/main";
import Button from "../../../components-ui/Inputs/Button/Button";
import Select from "../../../components-ui/Inputs/Select/Select";
import ServerCommand from "../../../common/server/server-command";
import moment from "moment/moment";
import TextArea from "../../../components-ui/Inputs/TextArea";
import Tabs from "../../../components-ui/Navigation/Tabs/Tabs";
import {Label} from "../../../components-ui/DataDisplay/Label";
import DateTime from "../../../components-ui/Inputs/DateTime/DateTime";

export default class CanceledModal extends React.Component {

    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        setNextState: PropTypes.func,
        visit: PropTypes.object
    };

    tabs = [
        {code: 'record', name: 'Запись'},
        {code: 'cancel', name: 'Отказ'}
    ];

    state = {
        activeTab: this.tabs[0].code,
        selectedDate: null,
        selectedTime: null,
        selectedDoctor: null,
        freeDates: null,
        freeTime: null,
        doctors: null,
        reasonRefuse: '',
        commentValue: ''
    };

    componentDidMount() {
        if (this.state.freeDates === null) {
            const command = new ServerCommand('schedule/get-free-dates');
            command.exec().then((response) => {
                this.setState({
                    freeDates: response.freeDates,
                    selectedDate: response.freeDates[0],
                    freeTime: response.freeTime,
                    doctors: response.doctors,
                    selectedTime: this.getTimeOption(response.freeTime[0]),
                    selectedDoctor: this.getDoctorOption(response.freeTime[0].doctors[0], response.doctors)
                });
            });
        }
    }

    render() {
        if (this.state.freeDates === null) {
            return null
        }
        const visit = this.props.visit;
        let button = null;
        let content = null;


        if (this.state.activeTab === 'record') {
            button = <Button
                variant="success"
                children="Записать"
                size="md"
            />;
            content = this.renderRecordContent();
        } else {
            button = <Button
                variant="success"
                children="Готово"
                size="md"
            />;
            content = <TextArea onChange={(e) => this.setState({commentValue: e.target.value})} title="Комментарий"
                                value={this.state.commentValue}/>
        }

        return (
            <div className='popup-photo popup-patient-record kanban-modal kanban-modal--canceled'>
                <div className="popup__wrap">
                    <div className="popup__close" onClick={() => this.props.closeModal()}>
                        <Icon icon="new-close"/>
                    </div>
                    <h2 className='popup__title'>Отменённый приём</h2>
                    <span className="popup__text-center">
                         {Helper.getFio(visit.patient)}(№ {visit.patient.number}), причина «Работа», 
                        <br/>
                        Виноградова И. Б. «Вызвали на работу»
                    </span>
                    <Tabs
                        tabs={this.tabs}
                        onChange={(activeTab) => this.setState({activeTab})}
                        activeTab={this.state.activeTab}
                        variant="primary"
                        align="center"
                    />
                    {content}
                    <div className="choice__box">
                        {button}
                    </div>
                </div>
            </div>
        )
    }

    renderRecordContent() {

        let optionsTime = null;
        let optionsDoctors = null;
        if (this.state.freeTime !== null) {
            optionsTime = this.state.freeTime.map(this.getTimeOption.bind(this));
        }
        if (this.state.doctors !== null) {
            optionsDoctors = this.getFreeDoctors().map((doctor) => this.getDoctorOption(doctor, this.state.doctors));
        }

        return (
            <div className="choice__box">
                <Label labelText="Дата" >
                    <DateTime includeDates={this.state.freeDates}
                              selected={this.state.selectedDate}
                              onChange={this.handleChangeDate.bind(this)}/>
                </Label>
                <div className="choice__date-time">
                    <span>Время</span>
                    <Select options={optionsTime}
                            value={this.state.selectedTime}
                            onChange={this.handleTimeChange.bind(this)}
                    />
                </div>
                <div className="choice__date-time">
                    <span>Врач</span>
                    <Select variant="colored"
                            options={optionsDoctors}
                            value={this.state.selectedDoctor}
                            onChange={(selectedDoctor) => this.setState({selectedDoctor})}
                    />
                </div>
            </div>
        )
    }

    handleChangeDate(date) {
        this.setState({selectedDate: date});
        const command = new ServerCommand('schedule/get-free-time', {date: moment(date).format("YYYY-MM-DD")});
        command.exec().then((response) => this.setState({
            freeTime: response.freeTime,
            doctors: response.doctors,
            selectedTime: this.getTimeOption(response.freeTime[0]),
            selectedDoctor: this.getDoctorOption(response.freeTime[0].doctors[0], response.doctors)
        }));
    }

    getTimeOption(interval, index = 0) {
        return {
            value: interval.start + '_' + interval.end,
            label: `с ${interval.start} до ${interval.end}`,
            index
        }
    }

    getDoctorOption(freeDoctor, doctors) {
        return {
            value: freeDoctor.id,
            chairId: freeDoctor.chairId,
            label: Helper.getFio(doctors[freeDoctor.id]),
            color: doctors[freeDoctor.id].color
        }
    }

    getFreeDoctors() {
        const selectedTime = this.state.selectedTime.value.split('_');
        let freeDoctors = [];
        this.state.freeTime.forEach((interval) => {
            if (interval.start === selectedTime[0] && interval.end === selectedTime[1]) {
                freeDoctors = interval.doctors;
            }
        });
        return freeDoctors
    }

    handleTimeChange(selectedTime) {
        let newState = {selectedTime};

        const newDoctorsList = this.state.freeTime[selectedTime.index].doctors;

        /* если выбранного врача нет в списке врачей на выбранное время - в качестве врача устанавливаем первого из списка */
        if (!newDoctorsList.some(doctor => this.state.selectedDoctor.value === doctor.id)) {
            newState.selectedDoctor = this.getDoctorOption(newDoctorsList[0], this.state.doctors)
        }

        this.setState(newState)
    }
}
