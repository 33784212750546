import React, {Dispatch, SetStateAction, useMemo} from "react";
import useFilterPlannedCalls from "../../hooks/useFilterPlannedCalls";
import Scrollbar from "../../../../components-ui/DataDisplay/Scrollbar/Scrollbar";
import {StateCallCenter} from "../../types/components";
import {MethodsCallCenter, OnChangeSelectedScript, PlannedCall} from "../../types/common";
import {InfoCallCenter} from "../../types/hooks";
import CallItem from "./components/CallItem";
import {TasksInfo} from "../../../../containers/Tasks";
import {SContainer} from "../../../../containers/Tasks/style";

interface Props {
    state: StateCallCenter
    setState: Dispatch<SetStateAction<StateCallCenter>>
    marketingPlannedCalls: PlannedCall[]
    infoCallCenter: InfoCallCenter
    refScrollList: any
    methodsCallCenter: MethodsCallCenter
    tasksInfo: TasksInfo
    onChangeSelectedScript: OnChangeSelectedScript
}

const CallsList = ({state, refScrollList, infoCallCenter, marketingPlannedCalls, ...props}: Props) => {

    const [filterPlannedCalls, onScroll] = useFilterPlannedCalls({
        state,
        marketingPlannedCalls,
        refScrollList,
        infoCallCenter,
        tasksInfo: props.tasksInfo
    });

    return useMemo(() => {
        return (
            <Scrollbar height={`calc(100vh - ${state.heightFilter}px)`}
                       onScroll={onScroll}
                       ref={refScrollList}>
                <SContainer>
                    {filterPlannedCalls.map((plannedCall, callIndex) => {
                        return <CallItem plannedCall={plannedCall}
                                         calls={state.calls}
                                         key={'item-call' + plannedCall.phone}
                                         infoCallCenter={infoCallCenter}
                                         callIndex={callIndex}
                                         {...props}
                        />
                    })}
                </SContainer>
            </Scrollbar>
        )
    }, [refScrollList, infoCallCenter, props.tasksInfo, state.heightFilter, JSON.stringify(state.calls), JSON.stringify(filterPlannedCalls)])
}

export default CallsList
