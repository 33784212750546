import * as React from "react";
import {RowDesc, RowItemDesc, RowGroup, DoctorCell} from "../styledStages";
import Helper from "../../../common/helpers/main";
import SVG from "../../../common/helpers/SVG";
import {CellDesc, IPlanItem} from "../typesStages";
import {useEffect, useState} from "react";
import ItemServices from "./ItemServices";
import {useDrag} from "react-dnd";
import {PlanItemsStatuses} from "../../../containers/TreatmentPlansConstructor/types";
import {useDispatch} from "react-redux";
import {setIsScrollArea} from "../../../redux/actions/commonActions";

interface PPlanItem {
    cellDesc: CellDesc[],
    item: IPlanItem,
    stageId: number,
    itemsStatuses: PlanItemsStatuses | null,
    doctorIndex?: number,
    isDrag?: boolean,
    setIsDrag?: any,
    stageIndex: number
}

export default function PlanItemDescription({cellDesc, item, stageId, itemsStatuses, doctorIndex, isDrag, setIsDrag, stageIndex}: PPlanItem) {
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (item.planItems.length) {
            if (item.planItems.some(el => !el.isStageConfirmed)) setActive(true);
        }
    }, [])

    const handleGroup = () => {
        setActive(!active);
    };
    return (
        <>
            <RowDesc isStageConfirmed>
                {cellDesc.map(cell => <CellItem cell={cell}
                                                item={item}
                                                active={active}
                                                handleGroup={handleGroup}
                                                key={item.itemKey + '_' + cell.code}
                                                stageId={stageId}
                                                isDrag={isDrag}
                                                setIsDrag={setIsDrag}
                                                stageIndex={stageIndex}
                                                doctorIndex={doctorIndex}
                />)}
            </RowDesc>
            {active && item.planItems.map(stagedPlanItem => {
                return <ItemServices cellDesc={cellDesc}
                                     key={stagedPlanItem.itemCode}
                                     stagedPlanItem={stagedPlanItem}
                                     stageIndex={stageIndex}
                                     itemsStatuses={itemsStatuses}/>
            })}
        </>
    )
}

interface PCellItem {
    cell: CellDesc,
    item: IPlanItem,
    active: boolean,
    handleGroup: () => void,
    stageId: number,
    doctorIndex?: number,
    isDrag?: boolean,
    setIsDrag?: any,
    stageIndex: number
}

const CellItem = ({cell, item, active, handleGroup, stageId, doctorIndex, isDrag, setIsDrag, stageIndex}: PCellItem) => {
    const dispatch = useDispatch();
    let name = '' as any;
    let isDnd = false;
    let typeDrag = '';
    let filter = {stageId, performerId: item.performerId} as any;
    let newName = '';
    const props = {} as any;

    switch (cell.code) {
        case "performerId":
            if (!item.doctor.isHidden) {
                newName = item.doctor.fio + '';
                if (newName.length > cell.numberOfCharacters) {
                    newName = item.doctor.fio.slice(0, cell.numberOfCharacters) + '...';
                    props.title = item.doctor.fio;
                }
                name = <DoctorCell {...props}>{newName}</DoctorCell>;
                isDnd = true;
                typeDrag = 'doctor'
            }
            break;
        case "service":
            isDnd = true;
            typeDrag = 'serviceGroup';
            filter.serviceGroupId = item.serviceGroupId;
            newName = item.groupName + '';
            if (newName.length > cell.numberOfCharacters) {
                newName = item.groupName.slice(0, cell.numberOfCharacters) + '...';
                props.title = item.groupName;
            }
            name = <RowGroup active={active} onClick={handleGroup} {...props}>
                <SVG name="triangle-fill"/> {newName}
            </RowGroup>;
            break;
        case "price":
            newName = Helper.Price.format(item.totalPrice) + '';
            if (newName.length > cell.numberOfCharacters) {
                newName = Helper.Price.format(item.totalPrice).slice(0, cell.numberOfCharacters) + '...';
                props.title = Helper.Price.format(item.totalPrice);
            }
            name = <span {...props}>{newName}</span>;
            break;
        default:
            name = ' ';
            break;
    }

    const [{ isDragging }, drag] = useDrag({
        item: {filter, type: 'card', typeDrag, doctorIndex, index: stageIndex},
        begin: () => {
            setIsDrag(true);
            setTimeout(() => {
                dispatch(setIsScrollArea(true));
            }, 500)
        },
        end: () => {
            setIsDrag(false);
            setTimeout(() => {
                dispatch(setIsScrollArea(false));
            }, 500)
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        })
    });

    let rowRef = null;

    if (cell.code === 'performerId' || cell.code === 'service') rowRef = drag;
    return <RowItemDesc isDnd={isDrag ? false : isDnd}
                        children={name}
                        ref={rowRef}
                        width={cell.width}
                        isDragging={isDragging}
    />
};
