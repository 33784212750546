import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";
import {PatientValues} from "../types/common";
import {PatientFields} from "../types/fields";

export default function preparePersonalGender(currentPatient: PatientValues, fields: PatientFields) {
    let personalGender = null as null | OptionSelect;
    if (!!currentPatient.personalGender) {
        const variantsPersonalGender = fields.personalGender.variants as OptionSelect[]
        personalGender = variantsPersonalGender.find(variant => variant.value === currentPatient.personalGender) as OptionSelect;
    }
    return personalGender
}
