import _ from "lodash";
import {Task, Types} from "../index";

interface PreparedTask extends Task {
    isCriticalTask: boolean
}

const sortTasks = (tasks: Task[], currentDate: any, types: Types) => {
    return tasks.filter(task => !!types[task.type]).map((task: any) => {
        let isCriticalTask = false;
        if (task.deadline !== null) isCriticalTask = new Date(task.deadline as any).getTime() <= new Date(currentDate).getTime()
        task.isCriticalTask = isCriticalTask
        return task as PreparedTask;
    }).sort((taskA, taskB) => types[taskA.type].priority - types[taskB.type].priority)
        .sort((taskA, taskB) => +taskB.isCriticalTask - +taskA.isCriticalTask)
        .map(task => {
            return _.omitBy(task, 'isCriticalTask') as Task
        })
}

export default sortTasks
