
export const dataDoubleBar = [
    {
        code: '2021-08-01',
        barOne: 30,
        barTwo: 30
    },
    {
        code: '2021-08-02',
        barOne: 39, barTwo: 39
    },
    {
        code: '2021-08-03',
        barOne: 21, barTwo: 21
    },
    {
        code: '2021-08-04',
        barOne: 21, barTwo: 21
    },
    {
        code: '2021-08-05',
        barOne: 14, barTwo: 14
    },
    {
        code: '2021-08-06',
        barOne: 7, barTwo: 7
    },
    {
        code: '2021-08-07',
        barOne: 1, barTwo: 1
    },
    {
        code: '2021-08-08',
        barOne: 12, barTwo: 12
    }
]
