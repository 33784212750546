import React from "react";
import { StyledInput } from "../styledInput";
import SVG from "../../../common/helpers/SVG";
import { WrapTimeCounter } from "./styledTimeCounter";

interface PInputNumber {
    value: string,
    onChange: (value: string) => void,
    onClickDown: () => void,
    onClickUp: () => void
}

export default function TimeCounter({value, onChange, onClickDown, onClickUp}: PInputNumber) {

    return (
        <WrapTimeCounter>
            <StyledInput onChange={(e: any) => onChange(e.target.value) }
                         value={value}
            />
            <SVG name="triangle-fill"
                 onClick={onClickUp}
                 className="input-number-svg input-number-svg-up"/>
            <SVG name="triangle-fill"
                 onClick={onClickDown}
                 className="input-number-svg input-number-svg-down"/>
        </WrapTimeCounter>
    )
}
