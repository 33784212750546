import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const BackIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 19.94"} ref={ref}>
            <path d="M6.61,11.57l-4.35-5,4.35-5A1,1,0,0,0,5.15.33L.24,6a1,1,0,0,0,0,1.28l4.91,5.62a1,1,0,0,0,.73.33,1,1,0,0,0,.73-1.61Z"/>
            <path d="M16.67,5.62H1A1,1,0,0,0,1,7.56h15.7a5.11,5.11,0,1,1,0,10.21H2.26a1,1,0,0,0,0,1.94H16.67a7,7,0,0,0,0-14.09Z"/>
        </WrapIcon>
    );
});
