import React from 'react';
import FilterWidgetWrap from '../../../../components/FilterWidgetWrap';
import {HandlersCallsEfficiency, StateCallsEfficiency} from "../types";
import Button from "../../../../../../components-ui/Inputs/Button/Button";
import {namesCalls} from "../data/data";
import {OptionSelect} from "../../../../../../components-ui/Inputs/Select/typesSelect";
import Select from '../../../../../../components-ui/Inputs/Select/Select';
import {CallsEfficiencyReport} from "../../../../types";

interface Props {
    state: StateCallsEfficiency
    handlers: HandlersCallsEfficiency
    optionsSelected: OptionSelect[]
    report: CallsEfficiencyReport
}

const Filter = ({state, handlers, optionsSelected, report}: Props) => {
    return (
        <FilterWidgetWrap>
            <Select options={optionsSelected}
                    value={state.currentSelected}
                    onChange={handlers.handleSelected}
                    sizeSelect="sm-md"
                    widthSelect="220px"
            />
            {!!report.count.incoming && (
                <Button children={namesCalls.incoming.toLowerCase()}
                        margin="0 0 0 16px"
                        fontSize="14px"
                        height="32px"
                        noShadow={true}
                        variant={state.currentTab === 'incoming' ? 'info' : 'standard'}
                        onClick={() => handlers.handleCurrentTab('incoming')}
                />
            )}
            {!!report.count.outgoing && (
                <Button children={namesCalls.outgoing.toLowerCase()}
                        margin="0 0 0 8px"
                        fontSize="14px"
                        height="32px"
                        noShadow={true}
                        variant={state.currentTab === 'outgoing' ? 'info' : 'standard'}
                        onClick={() => handlers.handleCurrentTab('outgoing')}
                />
            )}
        </FilterWidgetWrap>
    );
};

export default Filter;
