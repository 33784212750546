import React from "react";
import Tag from "../../../../../../../components-ui/DataDisplay/Tag/Tag";
import {callCenterTasksInfo} from "../../../../../helper/getDataCallCenter";
import TitleTask from "../../../../../components/TitleTask";
import {PlannedCall, TaskCallCenter} from "../../../../../types/common";
import { SBoxFlex, SContainerItem } from "../../../../../../../containers/Tasks/style";

interface Props {
    plannedCall: PlannedCall
    uniqPatient: TaskCallCenter[]
    uniqType: TaskCallCenter[]
}

const HeaderExtendedCall = ({plannedCall, ...props}: Props) => {

    return (
        <SBoxFlex padding="21px 0 0">
            <SContainerItem>
                <Tag children={callCenterTasksInfo[plannedCall.tasks[0].type].name} margin="0 0 11px 0"/>
                <TitleTask isLink={true} {...props} plannedCall={plannedCall} />
            </SContainerItem>
        </SBoxFlex>
    )
}

export default HeaderExtendedCall
