import React, {Dispatch, SetStateAction, useEffect} from "react";
import Paper from "../../../../../../containers/UI-Collection/Elements/Surfaces/Paper";
import {SScriptBtnDots} from "../../../../components/Scripts/style";
import Typography from "../../../../../../components-ui/DataDisplay/Typography/Typography";
import TextField from "../../../../../../components-ui/Inputs/TextField/TextField";
import Box from "../../../../../../components-ui/Layout/Box";
import Button from "../../../../../../components-ui/Inputs/Button/Button";
import useFooterDetailed from "../../hooks/useFooterDetailed";
import Tooltip from "../../../../../../components-ui/DataDisplay/Tooltip/Tooltip";
import useHover from "../../../../../../common/hooks/useHover";
import {buttonTitles} from "../../../../helper/getDataCallCenter";
import {StateCallCenter} from "../../../../types/components";
import {StateDetailed} from "../../hooks/useDetailed";
import {IncomingCallPatient} from "../../../../types/taskData";
import { SContainerItem, STaskList } from "../../../../../../containers/Tasks/style";

interface Props {
    state: StateCallCenter
    detailedState: StateDetailed
    validate: boolean
    setDetailedState: Dispatch<SetStateAction<StateDetailed>>
    isCallSubject: boolean
    setState: Dispatch<SetStateAction<StateCallCenter>>
    patient: IncomingCallPatient | null
    futureComment: string
}

const FooterDetail = ({state, detailedState, validate, isCallSubject, setDetailedState, ...props}: Props) => {
    const {detail} = state;
    const {validationAfterSave} = detailedState;
    const {comment, setComment, optionsButton, saveCallResult, saveAndExisting, loader} = useFooterDetailed({
        state,
        isCallSubject,
        detailedState,
        setDetailedState,
        ...props
    });
    const [refBtn, hoverBtn] = useHover();
    const [refDots, hoverDots] = useHover();
    if (!detail?.canSaveResult) return null;
    const validation = validate && validationAfterSave;
    const dataTip = !validationAfterSave && validate ? "Результат уже сохранён" : buttonTitles.noteValidate;
    return (
        <>
            <Paper margin="0 0 12px" isHidden={false}>
                <STaskList padding="22px 0 40px">
                    <SContainerItem>
                        <Typography children="Комментарий результата звонка" margin="0 0 10px"/>
                        <TextField onChange={value => {
                            setComment(value)
                            setDetailedState(prevState => ({...prevState, validationAfterSave: true}))
                        }} value={comment} isTextArea={true} minHeight="96px" widthInput="100%" size="sm"/>
                    </SContainerItem>
                </STaskList>
            </Paper>
            <Paper margin="0 0 12px" isHidden={false} zIndex={12}>
                <STaskList padding="24px 0">
                    <SContainerItem>
                        <Box display="flex" justifyContent="space-between">
                            <Box ref={refBtn} width="calc(100% - 56px)" position="relative" height="40px">
                                <Box width="100%" height="40px" data-for="tooltip-detail-btn" data-tip={dataTip}>
                                    <Button
                                        children={!isCallSubject ? 'Сохранить результат звонка' : 'Сохранить и записать'}
                                        variant={validation ? "success" : "disabled"}
                                        fontSize="14px"
                                        loader={loader}
                                        onClick={() => !isCallSubject ? saveCallResult() : saveAndExisting()}
                                        height="40px"
                                        width="100%"/>
                                    {(!validation && hoverBtn) && <Tooltip id="tooltip-detail-btn"/>}
                                </Box>
                            </Box>
                            <Box ref={refDots} width="40px" height="40px" position="relative">
                                <SScriptBtnDots data-for="tooltip-detail-dots" data-tip={dataTip} data-place="top">
                                    <Button variant={validation ? "success" : "disabled"}
                                            fontSize="14px"
                                            height="40px"
                                            loader={loader}
                                            options={optionsButton}
                                            width="40px"
                                    />
                                    {(!validation && hoverDots) && <Tooltip id="tooltip-detail-dots" place="top"/>}
                                </SScriptBtnDots>
                            </Box>

                        </Box>
                    </SContainerItem>
                </STaskList>
            </Paper>
        </>
    )
}

export default FooterDetail
