export const data = [
    {
        code: "01",
        therapy: 40000,
        surgery: 24000,
        orthopedics: 34000,
        orthodontics: 54000,
        implantology: 64000,
        children: 14000,
    }
];

export const dataLine = [
    {
        name: 'therapy',
        data: [
            {code: '2021-02', value: 8000},
            {code: '2021-03', value: 5400},
            {code: '2021-04', value: 11000},
            {code: '2021-05', value: 4410},
            {code: '2021-06', value: 1450},
            {code: '2021-07', value: 7000},
        ]
    },
    {
        name: 'surgery',
        data: [
            {code: '2021-02', value: 70000},
            {code: '2021-03', value: 54000},
            {code: '2021-04', value: 100000},
            {code: '2021-05', value: 34100},
            {code: '2021-06', value: 4500},
            {code: '2021-07', value: 60000}
        ]
    },
    {
        name: 'orthopedics',
        data: [
            {code: '2021-02', value: 10000},
            {code: '2021-03', value: 94000},
            {code: '2021-04', value: 16000},
            {code: '2021-05', value: 24010},
            {code: '2021-06', value: 9500},
            {code: '2021-07', value: 20000}
        ]
    },
    {
        name: 'orthodontics',
        data: [
            {code: '2021-03', value: 60000}
        ]
    },
    {
        name: 'implantology',
        data: [
            {code: '2021-02', value: 20000},
            {code: '2021-03', value: 24000},
            {code: '2021-04', value: 36000},
            {code: '2021-05', value: 44100},
            {code: '2021-06', value: 59500}
        ]
    },
    {
        name: 'children',
        data: [
            {code: '2021-03', value: 14000},
            {code: '2021-04', value: 16000},
            {code: '2021-05', value: 14100},
            {code: '2021-06', value: 19500},
            {code: '2021-07', value: 10000},
        ]
    },
]
