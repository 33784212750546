import Arr from "./helpers/Arr";

export class IdGenerator {
    private readonly usedIds: {[usedId: number]: number};

    constructor(usedIds: number[] = []) {
        this.usedIds = Arr.flip(usedIds);
    }

    public generate(): number {
        let id = 1;

        while (id in this.usedIds) {
            id++;
        }

        this.usedIds[id] = 1;
        return id;
    }
}
