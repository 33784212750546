import React from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'
import Helper from "../../common/helpers/main";
import Printer from "../../common/server/printer"
import './TreatmentPlan.scss'
import Table from "../../components/Table/Table";
import ServerCommand from "../../common/server/server-command";
import {Alert} from "../../components-ui/DataDisplay/Alert";
import Box from "../../components-ui/Wraps/old/Box";
import RecommendedPlan from "../TreatmentPlan/RecommendedPlan";
import TreatmentPlan from "../TreatmentPlan/TreatmentPlan";
import Tabs from "../../components-ui/Navigation/Tabs/Tabs";
import Checkbox from "../../components-ui/Inputs/Checkbox/Checkbox";
import Scroll from "../../common/helpers/scroll";
import _ from 'underscore';
import StageBuilder from "../TreatmentPlan/StageBuilder";
import StagedPlan from "../TreatmentPlan/StagedPlan";
import StagesEditor from "../../components/Stages/StagesEditor";
import ValidationResult from "../../common/Validation/ValidationResult";
import Arr from "../../common/helpers/Arr";
import {
    getGroupsCategoriesMap,
    prepareServicesFromServer,
    prepareServicesGroupsFromServer
} from "../../common/Service/Service";
import {BoxBody} from "../../components-ui/styledComponent/common/wraps";
import getDoctorsTabs from "../../common/TreatmentPlan/DoctorsTabs";
import PlanEditorWrap from "./PlanEditor/PlanEditorWrap";

export default class TreatmentPlansConstructor extends React.Component {
    static propTypes = {
        getLoadCommandData: PropTypes.func.isRequired,
        renderFooter: PropTypes.func.isRequired,
        sendPlan: PropTypes.func.isRequired,
        performedOnly: PropTypes.bool,
        initialPrintPrices: PropTypes.bool,
        checkItemsStatuses: PropTypes.bool,
        currentVisitId: PropTypes.number,
        patientId: PropTypes.number.isRequired,
        visit: PropTypes.object,
        isDoctorPrimaryVisit: PropTypes.bool
    };

    static defaultProps = {
        validateNextBtn: () => null,
        performedOnly: false,
        checkItemsStatuses: false,
        initialPlanType: 'recommended',
        initialPrintPrices: true,
        currentVisitId: 0
    };

    state = {
        currentPlanType: null, //код текущей вкладки
        teethMap: null, // зубная карта (содержит config и statusList)
        planItemStatuses: null, //статусы услуг
        performedPlanId: null,
        recommendedPlanId: null,

        services: null, // список услуг
        servicesGroups: null, // список групп
        proceduresCombinations: null, // комбинации процедур

        doctors: null, // список врачей, заполнявших план лечения, а также текущий врач
        directions: null, // список направлений услуг

        performedPlanItems: null, // пункты выполняемого плана
        recommendedPlanItems: null, // пункты рекоммендуемого плана

        servicesRelationsRules: null,

        paymentSchedule: '', //  График платежей

        snapshot: {},

        printRecommended: false,
        printPerformed: false,
        printDentalCard: true,
        printPrices: this.props.initialPrintPrices,
        printStages: true,

        needPrintPlans: false,
        quantityDivideAttractTime: 0,

        recommendedVisitsDecisions: {},

        recommendedServices: null,
        deleteRecommendedServices: [],
        recommendedServicesViewed: null
    };

    firstNotDividedItemNode = null;

    componentDidMount() {
        this.loadInitialData().then(this.saveResponse);
    }

    render() {
        let subTabs = this.props.performedOnly ? [
            {name: 'Согласованный', code: 'performed'},
        ] : [
            {name: 'Рекомендуемый', code: 'recommended'},
            {name: 'Согласованный', code: 'performed'}
        ];

        if (this.state.teethMap === null) return null;

        const {
            printRecommended, printPerformed, servicesGroups, planItemStatuses, currentPlanType, directions,
            recommendedServices, recommendedServicesViewed
        } = this.state;
        const additionalServicesGroups = this.getAdditionalServicesGroups();
        const isRecommended = (currentPlanType === 'recommended');

        const plan = this.getPlan();
        const stages = this.state[currentPlanType + 'PlanStages'];
        const stagedPlan = new StagedPlan(plan, stages, this.setStages, getGroupsCategoriesMap(servicesGroups), plan.filler, directions);
        const planParams = this.state[currentPlanType + 'PlanParams'];

        const showItemsStatuses = !isRecommended && this.props.checkItemsStatuses;

        const requiredDurationStages = this.getRequiredDurationStages(stagedPlan);

        let tipText = this.props.performedOnly ? "В согласованном плане отметьте услуги из рекомендуемого плана, которые пациент согласен лечить." : " В рекомендумом плане добавьте услуги к каждому проблемному зубу. В согласованном плане отметьте услуги из рекомендуемого плана, которые пациент согласен лечить.";

        const footerData = {
            isRecommended,
            savePlan: this.savePlan,
            switchPlan: this.switchPlan,
            validation: this.validate(plan, stagedPlan, requiredDurationStages),
            attractToQuantityDivide: this.attractToQuantityDivide,
            doctors: this.state.doctors,
            canPrint: isRecommended || printPerformed || printRecommended,
            isPlanChanged: this.isPlanChanged
        };

        let wrapperClassName = 'treatment-plan';
        if (isRecommended) {
            wrapperClassName += ' treatment-plan--recommended';
        }

        return (
            <div className={wrapperClassName}>
                <Alert children={tipText} margin="20px 0 44px" padding="9px 20px 11px"/>
                {!this.props.performedOnly &&
                <div className="treatment-plan__top"
                     ref={(ref) => this.top = ref}>
                    <Tabs
                        activeTab={currentPlanType}
                        tabs={subTabs}
                        onChange={currentPlanType => this.setState({currentPlanType})}
                        disabled={isRecommended}
                        borderBottom={true}
                    />
                </div>
                }
                {!isRecommended && this.renderRecommendedPlan(additionalServicesGroups)}

                <PlanEditorWrap teethMap={this.state.teethMap}
                            planTitle={isRecommended ? 'План лечения' : 'Согласованный план'}
                            servicesListTitle={isRecommended ? '' : 'Список всех услуг'}
                            isServicesInitiallyHidden={!isRecommended}
                            key={this.state.currentPlanType}
                            currentPlanType={this.state.currentPlanType}

                            services={this.state.services}
                            servicesGroups={servicesGroups}
                            doctors={this.state.doctors}
                            directions={directions}

                            plan={plan}
                            paymentSchedule={this.state.paymentSchedule}
                            onChangeSchedule={this.onChangeSchedule}
                            checkItemsStatuses={showItemsStatuses}
                            planItemStatuses={planItemStatuses}
                            planItemDisabledTip="Услуга уже в работе"
                            additionalServicesGroups={additionalServicesGroups}
                            quantityDivideAttractTime={this.state.quantityDivideAttractTime}
                            setFirstNotDividedItemRef={this.setFirstNotDividedItemNode}

                            recommendedVisitsDecisions={this.state.recommendedVisitsDecisions}
                            setRecommendedVisitDecision={this.setRecommendedVisitDecision}

                            patientId={this.props.patientId}
                            visit={this.props.visit}

                            recommendedServices={recommendedServices}
                            deleteRecommendedServices={this.deleteRecommendedServices}
                            showRecommendedServices={this.showRecommendedServices()}
                            recommendedServicesViewed={recommendedServicesViewed}
                />

                <Box>
                    <StagesEditor stagedPlan={stagedPlan}
                                  stages={stages}
                                  setStages={this.setStages}
                                  servicesGroups={servicesGroups}
                                  itemsStatuses={showItemsStatuses ? planItemStatuses : null}
                                  requiredDurationStages={requiredDurationStages}
                                  initiallyShow={!planParams || !planParams['skip_stages']}
                    />

                    {!isRecommended && (
                        <div className="treatment-plan__print-options">

                            <div className="print-option">
                                <Checkbox label="Печатать рекомендуемый план"
                                          checked={printRecommended}
                                          onChange={this.handleChangePrintRecommended}
                                />
                            </div>

                            <div className="print-option">
                                <Checkbox label="Печатать согласованный план"
                                          checked={printPerformed}
                                          onChange={this.handleChangePrintPerformed}
                                />
                            </div>

                            <div className="print-option">
                                <Checkbox label="Печатать зубную формулу"
                                          checked={this.state.printDentalCard}
                                          onChange={this.handleChangePrintDentalCard}
                                />
                            </div>

                            <div className="print-option">
                                <Checkbox label="Печатать этапы"
                                          checked={this.state.printStages}
                                          onChange={this.handleChangePrintStages}
                                />
                            </div>

                            <div className="print-option">
                                <Checkbox label="Печатать цены"
                                          checked={this.state.printPrices}
                                          onChange={this.handleChangePrintPrices}
                                />
                            </div>
                        </div>
                    )}

                    {this.props.renderFooter(footerData)}
                </Box>
            </div>
        )
    }

    /**
     * Рендерит рекомендуемый план для выбора пунктов в выполняемом плане
     * @var {array} additionalServicesGroups
     * @return {*}
     */
    renderRecommendedPlan(additionalServicesGroups) {

        const planColumns = [
            {
                title: 'Услуга',
                code: 'name',
                containsGroupTitle: true
            },
            {
                title: 'Зуб',
                code: 'target'
            },
            {
                title: 'Количество',
                code: 'quantity',
            },
            {
                title: 'К оплате',
                code: 'sum',
                type: 'price',
                countTotal: true
            },
        ];

        const {doctors, directions} = this.state;
        const recommendedPlan = this.getRecommendedPlan();
        const recommendedPlanItems = recommendedPlan.getItems();
        const directionsMap = _.indexBy(directions, 'code');

        return (
            <Box>
                <BoxBody nonBorderBottom>
                    <p className="table__title">
                        Рекомендуемый план
                    </p>
                    <div className="card card--recommended">
                        <Table
                            data={recommendedPlanItems}
                            columns={planColumns}
                            getRowGroupId={item => item.serviceGroupId}
                            getRowCode={RecommendedPlan.getItemCode}
                            selectMode="multiple"
                            selectedRows={recommendedPlan.getSelectedItems().map(item => RecommendedPlan.getItemCode(item))}
                            groups={this.state.servicesGroups}
                            highlightedGroupsIds={additionalServicesGroups}

                            tabs={getDoctorsTabs({planItems: recommendedPlanItems, doctors})}

                            onSelect={(selectedItemsCodes, itemCode) => recommendedPlan.toggleItem(itemCode).save()}
                            onSelectMany={itemsCodes => {
                                itemsCodes.forEach((item) => recommendedPlan.toggleItem(item, false));
                                recommendedPlan.save();
                            }}

                            getDisabledTip="Услуга уже в работе"
                            getRowEnabled={recommendedPlan.isItemEnabled}
                            prepareDataItem={itemParams => this.prepareRecommendedItem(itemParams, directionsMap)}
                        />
                    </div>
                </BoxBody>
            </Box>
        )
    }

    prepareRecommendedItem = ({item}, directionsMap) => {
        item.name = RecommendedPlan.getItemName(item, this.state.proceduresCombinations, directionsMap);
        item.target = Array.isArray(item.target) ? item.target.join(', ') : item.target;
        item.sum = TreatmentPlan.getItemPrice(item) * item.quantity;
        item.tab = item.performerId;
    };

    loadInitialData() {
        return ServerCommand.get('treatment-plan/save-form', this.props.getLoadCommandData());
    }

    /**
     * Сохраняет результат запроса начальной информации в state
     * @param response
     */
    saveResponse = (response) => {
        let data = Helper.clone(response);

        const {recommendedPlan, performedPlan} = response;
        /* --- определяем, нужно ли печатать планы --- */
        const needPrintPlans = (!!recommendedPlan && !recommendedPlan.isPrinted) || (!!performedPlan && !performedPlan.isPrinted);

        /* --- преобразование списка статусов зубов к объекту --- */
        data.teethMap.statusList = _.indexBy(data.teethMap.statusList, 'id');

        let newState = {
            teethMap: data.teethMap,
            doneDuties: data.doneDuties,
            currentPlanType: this.getInitialPlanType(recommendedPlan, performedPlan),
            paymentSchedule: response.paymentSchedule,
            planItemStatuses: response.planItemStatuses,
            needPrintPlans,
            services: prepareServicesFromServer(response.services, response.servicesGroups, response.procedures, response.directions, response.servicesRelationsRules, []),
            servicesGroups: prepareServicesGroupsFromServer(response.servicesGroups, response.directions),
            servicesRelationsRules: response.servicesRelationsRules,
            proceduresCombinations: _.indexBy(response.proceduresCombinations, 'id'),
            doctors: _.indexBy(response.doctors, 'id'),
            directions: response.directions,
            recommendedVisitsDecisions: !!response.recommendedVisitsDecisions ? response.recommendedVisitsDecisions : {},
            defaultStages: response.defaultStages,
            printStages: !recommendedPlan || !recommendedPlan.params || !recommendedPlan.params['skip_stages'],
            recommendedServices: _.indexBy(response.recommendedServices, 'id'),
            recommendedServicesViewed: response.recommendedServicesViewed
        };

        this.saveLoadedPlan(recommendedPlan, 'recommended', newState);
        this.saveLoadedPlan(performedPlan, 'performed', newState);

        newState.snapshot = this.getSnapshot(newState);

        this.preFillPlan(recommendedPlan, 'recommended', newState);
        this.preFillPlan(performedPlan, 'performed', newState);

        this.setState(newState);
    };

    getInitialPlanType(recommendedPlan, performedPlan) {
        const {performedOnly, isDoctorPrimaryVisit} = this.props;

        let currentPlanType;
        if (performedOnly) {
            currentPlanType = 'performed';
        } else {
            if (!!recommendedPlan) {
                currentPlanType = (isDoctorPrimaryVisit || (performedPlan && performedPlan.isFulfilledInPrevVisits)) ? 'recommended' : 'performed';
            } else {
                currentPlanType = 'recommended';
            }
        }

        return currentPlanType;
    }

    /**
     * Подготавливает загруженный с сервера план к сохранению
     * @param {object} plan
     * @param {string} planType
     * @param {object} state
     */
    saveLoadedPlan(plan, planType, state) {
        const services = !!state.services ? state.services : this.state.services;
        const servicesGroups = !!state.servicesGroups ? state.servicesGroups : this.state.servicesGroups;
        const directions = !!state.directions ? state.directions : this.state.directions;
        const planParams = !!plan ? plan.params : null;

        state[planType + 'PlanParams'] = planParams;
        state[planType + 'PlanItems'] = !!plan ? TreatmentPlan.prepareItemsFromServer(plan.items, services, servicesGroups, directions, planParams) : [];
        state[planType + 'PlanId'] = !!plan ? plan.id : null;
        state[planType + 'PlanStages'] = !!plan ? plan.stages : null;
    }

    /**
     * Производит предварительное заполнение плана
     * @param planData
     * @param planType
     * @param state
     */
    preFillPlan(planData, planType, state) {
        const plan = this.getPlan(planType, state);
        plan.preFill({
            savedInCurrentVisit: !!planData && planData.savedInCurrentVisit,
            planType
        }).save();
    }

    /**
     * Сохраняет план лечения на сервере, печатая его при необходимости
     * @param isRecommended
     * @return {Promise<any>}
     */
    savePlan = (isRecommended = null) => {
        const {
            printRecommended, printPerformed, printPrices, printStages, deleteRecommendedServices,
            recommendedVisitsDecisions, printDentalCard
        } = this.state;

        return new Promise((resolve, reject) => {
            isRecommended = (isRecommended === null) ? (this.state.currentPlanType === 'recommended') : isRecommended;

            let planId = isRecommended ? this.state.recommendedPlanId : this.state.performedPlanId;
            const planType = isRecommended ? 'recommended' : 'performed';

            const planData = {
                isRecommended,
                paymentSchedule: this.state.paymentSchedule,
                stages: this.state[planType + 'PlanStages'].map(stage => {
                    return _.pick(stage, 'id', 'name', 'duration', 'isNew', 'performers');
                }),
            };

            if (isRecommended) {
                planData.recommendedVisitsDecisions = recommendedVisitsDecisions;
                planData.deleteRecommendedServices = deleteRecommendedServices;
            } else {
                planData.generateRecommendedDocument = printRecommended;
                planData.generatePerformedDocument = printPerformed;
                planData.printDentalCard = printDentalCard && (printRecommended || printPerformed);
                planData.printPrices = printPrices;
                planData.printStages = printStages;
            }

            /* собираем информацию по пунктам плана лечения */

            planData.items = [];

            const planItems = this.getPlan(planType).expandItems();

            planItems.forEach(planItem => {

                const procedures = planItem.procedures.filter(procedure => !procedure.isBlocked);

                const itemData = {
                    serviceId: planItem.serviceId,
                    target: Array.isArray(planItem.target) ? planItem.target.join(', ') : planItem.target,
                    direction: planItem.direction,
                    performerId: planItem.performerId,
                    stageId: planItem.stageId,
                    isStageManuallyChanged: planItem.isStageManuallyChanged,

                    procedures: procedures.map(({procedureId, isActive, order, target}) => {
                        target = Array.isArray(target) ? target.join(', ') : target;
                        return {procedureId, isActive, order, target};
                    })
                };

                if (planItem.id) {
                    itemData.id = planItem.id
                }

                planData.items.push(itemData);
            });

            /* --- отправка на сервер, печать и обновление информации по плану (на основе плана, пришедшего с сервера) --- */

            this.props.sendPlan(planData, planId).then(response => {
                if (response.documents) {
                    Printer.print(response.documents).then(() => {
                        this.setState({
                            snapshot: this.getSnapshot(),
                            needPrintPlans: false,
                            printRecommended: false,
                            printPerformed: false
                        });
                        resolve();


                    })
                } else {
                    resolve();
                }

                const newState = {};
                this.saveLoadedPlan(response.plan, (isRecommended ? 'recommended' : 'performed'), newState);
                this.setState(newState);
            });
        });
    };

    switchPlan = () => {
        this.setState({
            currentPlanType: 'performed'
        });

        Scroll.elementIntoView(this.top, {block: 'start'});
    };

    /**
     * Возвращает пункты текущего плана лечения
     * @return {Array}
     */
    getCurrentPlanItems = () => {
        return this.state[this.state.currentPlanType + 'PlanItems'];
    };

    getAdditionalServicesGroups() {
        const {services} = this.state;
        const additionalServicesGroups = {};

        services.forEach(service => {
            if (service.isAdditional) {
                additionalServicesGroups[service.groupId] = service.groupId;
            }
        });

        return Object.values(additionalServicesGroups);
    }


    getSnapshot(state = null, planType = null) {
        if (state === null) {
            state = this.state;
        }

        if (planType) {
            return Helper.clone(state[planType + 'PlanItems'])
        } else {
            return Helper.clone({
                recommended: state.recommendedPlanItems,
                performed: state.performedPlanItems,
            });
        }
    };

    onChangeSchedule = (e) => {
        this.setState({paymentSchedule: e.target.value})
    };

    /**
     * Получает объект плана (TreatmentPlan)
     * @param type - тип плана
     * @param state - состояние, из которого нужно инициализировать объект плана. По умолчанию текущее состояние компонента
     * @return {TreatmentPlan}
     */
    getPlan = (type = null, state = null) => {

        let allowedDirectStateChange = true;

        if (state === null) {
            state = this.state;
            allowedDirectStateChange = false;
        }

        if (type === null) {
            type = state.currentPlanType;
        }

        const planItemsKey = type + 'PlanItems';
        const planParams = state[type + 'PlanParams'];

        const plan = new TreatmentPlan({
            planItems: state[planItemsKey],
            services: state.services,
            servicesGroups: state.servicesGroups,
            checkStatuses: this.props.checkItemsStatuses,
            teethMap: state.teethMap.config,
            directions: state.directions,
            proceduresCombinations: state.proceduresCombinations,
            forceDirection: !!planParams ? planParams.force_direction : null,
            currentVisitId: this.props.currentVisitId,
            servicesRelationsRules: state.servicesRelationsRules
        });

        const stages = state[type + 'PlanStages'] ? state[type + 'PlanStages'] : [];
        const stageBuilder = new StageBuilder(stages, state.defaultStages, plan);

        plan.listen('save', planItems => {
            let newState = allowedDirectStateChange ? state : {};

            newState[planItemsKey] = planItems;

            if (!Helper.isEqual(state.snapshot[type], this.getSnapshot(newState, type))) {
                newState['print' + Helper.ucfirst(type)] = true;
            }

            newState[type + 'PlanStages'] = stageBuilder.stages;

            if (!allowedDirectStateChange) {
                this.setState(newState);
            }
        })

        return plan;
    };

    /**
     * Возвращает экземпляр рекоммендуемого плана лечения
     * @return {RecommendedPlan}
     */
    getRecommendedPlan() {
        return new RecommendedPlan(
            this.state.recommendedPlanItems,
            this.getPlan('performed'),
            this.props.checkItemsStatuses,
            this.state.directions
        );
    }

    handleChangePrintRecommended = printRecommended => this.setState({printRecommended});

    handleChangePrintPerformed = printPerformed => this.setState({printPerformed});

    handleChangePrintDentalCard = printDentalCard => this.setState({printDentalCard});

    handleChangePrintPrices = printPrices => this.setState({printPrices});

    handleChangePrintStages = printStages => this.setState({printStages});

    setFirstNotDividedItemNode = itemTableRow => {
        this.firstNotDividedItemNode = ReactDOM.findDOMNode(itemTableRow);
    };

    attractToQuantityDivide = () => {
        if (this.firstNotDividedItemNode) {
            Scroll.elementIntoView(this.firstNotDividedItemNode, {block: 'center'}, 500).then(
                () => this.setState({
                    quantityDivideAttractTime: Date.now()
                })
            );
        }
    };

    setRecommendedVisitDecision = (recommendedDoctorId, decisionCode) => {
        this.setState(prevState => {
            const recommendedVisitsDecisions = Helper.clone(prevState.recommendedVisitsDecisions);
            recommendedVisitsDecisions[recommendedDoctorId] = decisionCode;

            return {recommendedVisitsDecisions}
        });
    };

    deleteRecommendedServices = ids => {
        const {recommendedServices, deleteRecommendedServices} = this.state;
        this.setState({
            recommendedServices: _.omit(recommendedServices, ids),
            deleteRecommendedServices: deleteRecommendedServices.concat(ids)
        });
    }

    showRecommendedServices = () => {
        const {currentPlanType, recommendedServices, deleteRecommendedServices} = this.state;

        return (currentPlanType === 'recommended')
            && (_.size(recommendedServices) > 0 || deleteRecommendedServices.length > 0)
    }

    setStages = stages => this.setState({[this.state.currentPlanType + 'PlanStages']: stages})

    isPlanChanged = (planType) => {
        const {snapshot} = this.state;
        return !Helper.isEqual(snapshot[planType], this.getSnapshot(this.state, planType))
    }

    validate(plan, stagedPlan, requiredDurationStages) {
        const {currentPlanType, recommendedVisitsDecisions} = this.state;
        const stages = this.state[currentPlanType + 'PlanStages'];
        const planParams = this.state[currentPlanType + 'PlanParams'];

        const validationResult = new ValidationResult({
            'empty_plan': plan.isEmpty(),
            'not_divided_quantity': plan.needDivideQuantity(),
            'not_added_additional_services': plan.needAddAdditionalServices(),
        });

        if (!planParams || !planParams['skip_stages']) {
            if (stagedPlan.hasUnconfirmedChanges()) {
                validationResult.addError('unconfirmed_stages_changes');
            }

            /* --- проверка заполнения названий этапов --- */

            const emptyNumberStages = [];

            stages.forEach((stage, index) => {
                if (stage.name.trim() === '') {
                    emptyNumberStages.push(index + 1);
                }
            });

            if (emptyNumberStages.length > 0) {
                validationResult.addError('empty_stage_name', {
                    stagesNumbers: emptyNumberStages
                });
            }

            /* --- проверка заполнения сроков выполнения этапов --- */

            if (requiredDurationStages.length > 0) {
                requiredDurationStages = Arr.flip(requiredDurationStages);

                const invalidDurationStages = stages.filter(stage => {
                    return stage.duration.trim() === '' && !stage.isPlanAddition && (stage.id in requiredDurationStages);
                });

                if (invalidDurationStages.length > 0) {
                    validationResult.addError('empty_stage_duration', {
                        stages: invalidDurationStages
                    });
                }
            }
        }


        if (currentPlanType === 'recommended') {
            const notDecidedPerformers = plan.getNotDecidedPerformers(recommendedVisitsDecisions);

            if (notDecidedPerformers.length) {
                validationResult.addError('not_defined_recommended_visit_decision', {
                    forPerformers: notDecidedPerformers
                });
            }
        }


        return validationResult;
    }

    getRequiredDurationStages(stagedPlan) {
        const user = window.user;
        const {currentPlanType} = this.state;
        const planParams = this.state[currentPlanType + 'PlanParams'];

        if (user.role !== 'doctor' || (!!planParams && planParams['skip_stages'])) return [];

        const stages = this.state[currentPlanType + 'PlanStages'];

        const stagesItems = _.groupBy(stagedPlan.getItems(false), 'stageId');
        const requiredDurationStages = [];

        stages.forEach(stage => {
            const stageItems = stagesItems[stage.id];
            if (stageItems) {
                if (stageItems.every(item => item.performerId === user.doctorId) && stageItems.some(item => item.status !== 'DONE')) {
                    requiredDurationStages.push(stage.id);
                }
            }
        });

        return requiredDurationStages;
    }
}
