import React, {forwardRef} from "react";
import {SAvatar, SAvatarStatus, SAvatarWrap, SAvatarText} from "./style";
import { CommonProps, Status } from "./types";
import {IColors} from "../../../common/Theme/Theme";

interface Props extends CommonProps {
    img?: string,
    status?: Status
    bgColorTheme?: keyof IColors
    bgColor?: string
    text?: string
    fontSizeText?: string
}

const Avatar = forwardRef(({img, status, margin, bgColor, bgColorTheme, text, fontSizeText, ...props}: Props, ref: any) => {
    return (
        <SAvatarWrap {...props} ref={ref} margin={margin} bgColor={bgColor} bgColorTheme={bgColorTheme}>
            {!!img && <SAvatar {...props} img={img}/>}
            {!!status && <SAvatarStatus {...props} status={status}/>}
            {!!text && <SAvatarText children={text} size={props.size} fontSizeText={fontSizeText} />}
        </SAvatarWrap>
    )
})

export default Avatar
