import React, {useEffect, useRef, useState} from "react";
import {LinkTable, Row, RowButton, RowDate, RowItem, RowName, RowNumber, RowSpan, SpanButton} from "../styledStages";
import ItemDescription from "./ItemDescription";
import {useDrop} from "react-dnd";
import {Stage, StagedPlanItem} from "../../../containers/TreatmentPlan/types";
import {PlanItemsStatuses} from "../../../containers/TreatmentPlansConstructor/types";
import {ObjServicesGroup} from "../typesStages";
import StagedPlan from "../../../containers/TreatmentPlan/StagedPlan";
import SVG from "../../../common/helpers/SVG";
import _ from "underscore";
import {DragStage} from "./ItemStages";
import Button from "../../../components-ui/Inputs/Button/Button";

interface IPlanAddition {
    stage: Stage,
    stages: Stage[],
    planItems: StagedPlanItem[],
    itemsStatuses: PlanItemsStatuses | null,
    servicesGroups: ObjServicesGroup,
    stagedPlan: StagedPlan,
    setStages: any
}

export default function PlanAddition({stage, stages, planItems, itemsStatuses, servicesGroups, stagedPlan, setStages}: IPlanAddition) {

    const ref = useRef<HTMLDivElement>(null);
    const [hover, setHover] = useState(false);
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (planItems.some(item => !item.isStageConfirmed)) setActive(true);
    }, [planItems.length])

    const handleLink = (e: React.MouseEvent) => {
        e.preventDefault();
        setActive(!active)
    };

    const [, drop] = useDrop({
        accept: 'card',
        drop(elem:DragStage) {
            switch (elem.typeDrag) {
                case "service":
                    const itemCode = elem.itemCode + '' as string;
                    stagedPlan.setItemStage(itemCode, stage.id)
                    break;
                case "serviceGroup":
                case "doctor":
                    stagedPlan.setItemsStage(elem.filter, stage.id)
                    break;
                default:
                    break;
            }
            return {moved: true};
        }
    });

    const propsRowItem = {
        onMouseLeave: () => setHover(false),
        onMouseEnter: () => setHover(true),
        isHover: hover,
        edit: false,
    } as any;

    const handleDelete = () => {
        let newStages = _.clone(stages);
        newStages = newStages.filter((stage: Stage) => !stage.isPlanAddition);
        setStages(newStages);
    }

    return (
        <RowSpan ref={drop}>
                <Row>
                    <RowNumber children={''}/>
                    <RowItem {...propsRowItem}>
                        <RowName>
                            <LinkTable active={active} onClick={handleLink}>
                                <SVG name="triangle"/>
                                {stage.name}
                            </LinkTable>
                        </RowName>
                        <RowDate children={stage.duration} />
                        <RowButton>
                            {hover && <SpanButton title={!!planItems.length ? 'Перенесите все услугу из данного этапа' : 'Удалить'}>
                                <Button isIcon variant={!!planItems.length ? 'disabled' : 'danger'} onClick={handleDelete}>
                                    <SVG name='basket'/>
                                </Button>
                            </SpanButton>}
                        </RowButton>
                    </RowItem>
                </Row>
                <ItemDescription active={active}
                                 planItems={planItems}
                                 itemsStatuses={itemsStatuses}
                                 servicesGroups={servicesGroups}
                                 stageId={stage.id}
                                 stages={stages}
                                 stageIndex={0}
                                 performers={stage.performers}/>
        </RowSpan>
    )
};
