import React, {useState} from "react";
import {FieldsPatient} from "../../../../../common/types/patient";
import {Label} from "../../../../../components-ui/DataDisplay/Label";
import {PropsLabel} from "../../../../../components-ui/DataDisplay/Label/types";
import {SWrapRelationPatient, SWrapButtons} from "../styledPatient";
import Helper from "../../../../../common/helpers/main";
import {PatientRelationTypesTitles, RelationType} from "../../../../../common/types/patientCreationForm";
import PatientCard from "../../../PatientCard";
import SVG from "../../../../../common/helpers/SVG";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import ServerCommand from "../../../../../common/server/server-command";
import Loader from "../../../../../components-ui/Feedback/Loader/Loader";
import {useDialogsManager} from "../../../../../containers/DialogsManager/DialogsManager";
import { STextPhone } from "../../../../../containers/PatientField/style/phoneView";
import {prepareFio, preparePersonalBirthday} from "../../../../../containers/EditableField/helper/prepareTextField";
import useLoader from "../../../../../components/Errors";

interface PropsRelationPatient {
    patient: FieldsPatient,
    relationTypesTitles: PatientRelationTypesTitles,
    currentPatientId: number,
    loadingFromServer: () => void
}

const commonOptionLabel = {
    colorLabel: 'textGray',
    widthLabelWrap: '100%',
    minWidthLabel: '33%',
    marginWrap: "0 0 12px"
} as PropsLabel;

export default function RelationPatient(
    {
        patient,
        relationTypesTitles,
        currentPatientId,
        loadingFromServer
    }: PropsRelationPatient) {

    const [hover, setHover] = useState(false);
    const [loader, setLoader] = useLoader();
    let titles = relationTypesTitles.general;
    const {showSlideModal} = useDialogsManager();
    if (!!patient.personalGender) titles = Object.assign({}, relationTypesTitles.general, relationTypesTitles[patient.personalGender])

    return (
        <SWrapRelationPatient onMouseLeave={() => setHover(false)}
                              onMouseEnter={() => setHover(true)}
                              onMouseMove={() => {
                                  if (!hover) setHover(true);
                              }}>
            <Loader visible={loader}/>
            <Label {...commonOptionLabel}
                   labelText="ФИО">
                <STextPhone onClick={event => {
                    event.stopPropagation();
                    showSlideModal(<PatientCard patientId={patient.id}/>)
                }}>{prepareFio(patient)}</STextPhone>
            </Label>
            <Label {...commonOptionLabel}
                   labelText="Дата рождения">
                {preparePersonalBirthday(patient.personalBirthday)}
            </Label>
            <Label {...commonOptionLabel}
                   labelText="Степень родства">
                {!!patient.relationType ? titles[patient.relationType as keyof RelationType] : '-'}
            </Label>
            <Label {...commonOptionLabel}
                   labelText="Телефон">
                {Helper.Phone.format(patient.personalPhone)}
            </Label>
            {hover && (
                <SWrapButtons>
                    <Button isIcon={true}
                            size="md"
                            variant="transparent-danger"
                            children={<SVG name="basket"/>}
                            onClick={() => {
                                setLoader(true)
                                ServerCommand.post('patient/edit', {
                                    id: currentPatientId,
                                    relations: {[patient.id]: null}
                                }).then(() => {
                                    loadingFromServer();
                                })
                            }}/>
                </SWrapButtons>
            )}
        </SWrapRelationPatient>
    )
}
