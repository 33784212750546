import React from "react";
import {InfoBoxWrap} from "../../../components-ui/styledComponent/common/wraps";
import {useNewVisitForm} from "../NewVisitForm";
import {Label} from "../../../components-ui/DataDisplay/Label";
import Checkbox from "../../../components-ui/Inputs/Checkbox/Checkbox";
import TextField from "../../../components-ui/Inputs/TextField/TextField";
import Select from "../../../components-ui/Inputs/Select/Select";
import commonLabelOptions from "../../../containers/PatientField/helper/commonLabelOptions";
import Helper from "../../../common/helpers/main";
import useOptionsRelation from "../hooks/useOptionsRelation";
import Typography from "../../../components-ui/DataDisplay/Typography/Typography";
import CalendarField from "../../../components-ui/Inputs/CalendarField/CalendarField";

export default function OtherPatient() {
    const {
        form,
        onChangeFieldOtherPatient,
        isOtherPatientValue,
        onChangeValidationOtherPatient,
        isDateOtherPatient,
        fields
    } = useNewVisitForm();
    const optionsRelations = useOptionsRelation(null);
    const {basic, otherPatient} = form;

    if ((!(!!basic.phoneOwner) || basic.phoneOwner.value !== 'other') && !isOtherPatientValue) return null;


    return (
        <>
            <Typography variant="min-title" padding="25px 20px 11px">Владелец телефона</Typography>
            <InfoBoxWrap>
                <Label labelText=""
                       {...commonLabelOptions()}>
                    <Checkbox label="Предоставил данные"
                              checked={otherPatient.isProvidedData}
                              onChange={(value: boolean) => onChangeFieldOtherPatient(value, 'isProvidedData')}/>
                </Label>
                {otherPatient.isProvidedData && (
                    <>
                        <Label labelText={fields.personalPhone.title}
                               isRequired={true}
                               {...commonLabelOptions()}>
                            <TextField onChange={value => {
                                onChangeFieldOtherPatient(value, 'personalPhone');
                                onChangeValidationOtherPatient('personalPhone');
                            }}
                                       value={otherPatient.personalPhone}
                                       mask="+7 (999) 999-99-99"
                                       widthInput="180px"
                                       isError={otherPatient.validationFields.personalPhone}
                                       isDisabled={true}
                                       autoComplete="disabled"
                            />
                        </Label>
                        <Label labelText={fields.lastName.title}
                               isRequired={fields.lastName.required}
                               {...commonLabelOptions()}>
                            <TextField onChange={value => {
                                onChangeFieldOtherPatient(Helper.String.ucFirst(value), 'lastName');
                                onChangeValidationOtherPatient('lastName')
                            }}
                                       value={otherPatient.lastName}
                                       autoComplete="disabled"
                                       isError={otherPatient.validationFields.lastName}
                                       widthInput="335px"/>
                        </Label>
                        <Label labelText={fields.name.title}
                               isRequired={fields.name.required}
                               {...commonLabelOptions()}>
                            <TextField onChange={value => {
                                onChangeFieldOtherPatient(Helper.String.ucFirst(value), 'name')
                                onChangeValidationOtherPatient('name')
                            }}
                                       value={otherPatient.name}
                                       autoComplete="disabled"
                                       isError={otherPatient.validationFields.name}
                                       widthInput="335px"/>
                        </Label>
                        <Label labelText={fields.secondName.title}
                               {...commonLabelOptions()}>
                            <TextField onChange={value => {
                                onChangeFieldOtherPatient(Helper.String.ucFirst(value), 'secondName')
                            }}
                                       value={otherPatient.secondName}
                                       autoComplete="disabled"
                                       widthInput="335px"/>
                        </Label>
                        {isDateOtherPatient && (
                            <Label labelText={fields.personalBirthday.title}
                                   isRequired={fields.personalBirthday.required}
                                   {...commonLabelOptions()}>
                                <CalendarField value={otherPatient.personalBirthday}
                                               widthCalendar="335px"
                                               validateField={{
                                                   day: otherPatient.validationFields.personalBirthday,
                                                   month: otherPatient.validationFields.personalBirthday,
                                                   year: otherPatient.validationFields.personalBirthday
                                               }}
                                               autoFocus={false}
                                               onChange={valueField => {
                                                   onChangeFieldOtherPatient(valueField, 'personalBirthday')
                                                   onChangeValidationOtherPatient('personalBirthday')
                                               }}
                                />
                            </Label>
                        )}
                        <Label labelText={fields.relation.title}
                               isRequired={true}
                               {...commonLabelOptions()}>
                            <Select placeholder={fields.relation.placeholder}
                                    widthSelect="335px"
                                    value={otherPatient.relation}
                                    options={optionsRelations}
                                    isError={otherPatient.validationFields.relation}
                                    onChange={value => {
                                        onChangeFieldOtherPatient(value, 'relation')
                                        onChangeValidationOtherPatient('relation')
                                    }}
                            />
                        </Label>
                    </>
                )}
            </InfoBoxWrap>
        </>
    )
}
