import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const TimeOutlineIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Zm0,22.13A10.13,10.13,0,1,0,1.88,12,10.12,10.12,0,0,0,12,22.13ZM17.17,14a.94.94,0,0,1-1,1.61L11.52,12.8a.94.94,0,0,1-.46-.8V4.5a.94.94,0,1,1,1.88,0v7Z"/>
        </WrapIcon>
    );
});
