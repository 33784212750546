import React from 'react';
import Typography from "../../components-ui/DataDisplay/Typography/Typography";
import {Alert} from "../../components-ui/DataDisplay/Alert";
import Payments from "./containers/Payments";
import Box from "../../components-ui/Layout/Box";


const ReportPayments = () => {

    return (
        <Box padding='62px 20px 80px 28px'>
            <Typography as="h1" variant="title" fontWeight="bold" margin="30px 0">Движение денежных средств</Typography>
            <Payments/>
        </Box>
    );
};

export default ReportPayments;
