import React from "react";
import PropTypes from "prop-types";
import './Invoice.scss';
import ServerCommand from "../../../../common/server/server-command";
import Table from "../../../../components/Table/Table";
import DateHelper from "../../../../common/helpers/date-helper";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Printer from "../../../../common/server/printer";
import SVG from "../../../../common/helpers/SVG";

const invoiceColumns = [
    {
        title: 'Услуга',
        code: 'service',
        containsGroupTitle: true
    },
    {
        title: 'Зуб',
        code: 'target'
    },
    {
        title: 'Цена',
        code: 'price',
        type: 'price',
    },
    {
        title: 'Количество',
        code: 'quantity'
    },
    {
        title: 'Сумма',
        code: 'sum',
        type: 'price',
        countTotal: true
    }
];

export default class InvoiceImport extends React.Component {

    constructor(props) {
        super(props);
        this.handlerLoader = this.handlerLoader.bind(this)
    }

    static propTypes = {
        invoiceId: PropTypes.number.isRequired,
        servicesGroups: PropTypes.array.isRequired,
    };

    state = {
        invoice: null,
        loader: false
    };

    render() {
        const {invoice} = this.state;
        const {servicesGroups} = this.props;

        if (!invoice) return null;

        return (
            <div className="invoice-detail">
                <p className="invoice__header">{this.getInvoiceTitle()}</p>
                <div className="box">
                    <div className="box-body">
                        <div className="card">
                            <div className="invoice__table">
                                <Table data={invoice.items}
                                       selectMode="none"
                                       columns={invoiceColumns}
                                       groups={servicesGroups}
                                       getRowGroupId={item => item.serviceGroupId}
                                       prepareDataItem={this.prepareInvoiceItem}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    prepareInvoiceItem = ({item}) => {
        item.sum = item.quantity * item.price;

        if(item.target === 'шт') {
            item.target = null;
        }
    };

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.handlerLoader)
        const command = new ServerCommand('invoice/detail-import', {
            invoiceId: this.props.invoiceId,
        });
        command.exec().then(response => this.setState({invoice: response.invoice}));
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.handlerLoader)
    }

    getInvoiceTitle() {
        const {invoice} = this.state;
        const invoiceDate = DateHelper.formatDate(invoice.createdAt, 'DD ru_month_gen YYYY');
        const canceled = invoice.isCanceled ? ' (отменён)' : '';

        return `Счёт №${invoice.id} от ${invoiceDate} (импортирован)`;
    }

    handlerLoader(event) {
        const reason = event.reason;
        if (reason.type === 'server_request_error') {
            this.setState({loader: false})
        }
    }
}
