import React from 'react';
import PropTypes from 'prop-types';
import './Payment.scss';
import ServerCommand from "common/server/server-command";
import Select from "components-ui/Inputs/Select/Select";
import Helper from "common/helpers/main"
import Table from "../../components/Table/Table";
import Button from "../../components-ui/Inputs/Button/Button";
import {Label} from "../../components-ui/DataDisplay/Label";
import Box from "../../components-ui/Wraps/old/Box";
import BoxBody from "../../components-ui/Wraps/old/BoxBody";
import Obj from "../../common/helpers/object";
import PriceHelper from "../../common/helpers/price-helper";
import PaymentSchedule from "../../containers/PaymentSchedule/PaymentSchedule";
import Invoice from "../PatientCard/Section/Invoice/Invoice";
import ServiceDirectionName from "../../components/ServiceDirectionName/ServiceDirectionName";
import Printer from "../../common/server/printer";
import Checkbox from "../../components-ui/Inputs/Checkbox/Checkbox";
import TextField from "../../components-ui/Inputs/TextField/TextField";
import _ from 'lodash'
import {SlideModalContext} from "../../containers/DialogsManager/containers/SlideModal/SlideModal";
import Kkm from "../../common/server/kkm";
import {OptionSelect} from "../../components-ui/Inputs/Select/typesSelect";
import {connect} from "react-redux";
import {KkmResponse} from "../../common/types/server";
import {KkmList, ResponseKkmList} from "../../pages/Settings/Kkm/types";
import ValidationLinkInfo from "../../components/ValidationLinkInfo/ValidationLinkInfo";

class Payment extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handlerLoader = this.handlerLoader.bind(this)
    }

    static contextType = SlideModalContext;
    state = {
        invoices: null,
        paymentMethods: null,
        selectedPayment: null,
        cashboxOptions: null,
        selectedCashbox: null,
        kkmOptions: [],
        selectedKkm: null,
        account: null,
        servicesGroupsList: {},
        paymentSchedule: '',
        discountTypes: null,
        selectedDiscountType: null,
        discountValue: '',
        insuranceSum: '',
        patient: null,
        paymentMode: null,
        isAutopaymentPlan: false,
        isPlanFulfilled: false,
        printInvoices: true,
        loader: false,
        kkmError: '',
        kkmSetting: [],
        kkmList: []
    };

    static propTypes = {
        visitId: PropTypes.number.isRequired,
    };

    componentDidMount() {
        // вешаем обработчик для прерывания лоадера при возникновении ошибки
        window.addEventListener('unhandledrejection', this.handlerLoader);

        // запрашиваем данные для отображения формы
        ServerCommand.get('visit/payment-form', {visitId: this.props.visitId}).then(response => {

            /* --- формируем список вариантов способов оплаты --- */

            const paymentMethods = Helper.mapObj(response.paymentMethods, (paymentName, paymentCode) => ({
                value: paymentCode,
                label: paymentName
            }));

            const cashboxOptions = this.props.cashboxOptions

            /* --- формируем список ККМ --- */
            ServerCommand.get('kkm/list', {
                filter: {
                    branchId: response.branchId
                }
            }).then((response) => {

                this.setState({
                    kkmList: response.list,
                    kkmOptions: response.list.map((kkm) => {
                        return {
                            value: kkm.id,
                            label: kkm.name
                        }
                    })
                })

            });

            /* --- формируем список вариантов скидок --- */

            let discountTypes = null;

            if (response.discountTypes !== null) {
                discountTypes = Helper.mapObj(response.discountTypes, (discountName, discountCode) => ({
                    value: discountCode,
                    label: discountName
                }));
            }

            /* --- подготавливаем объект directionsPayments с суммами по направлениям --- */

            let directionsPayments;

            if (!!response.directionsBalance) {
                directionsPayments = Helper.clone(response.directionsBalance);
                Obj.forEach(directionsPayments, (directionPayment, directionCode) => {
                    directionPayment.directionCode = directionCode;
                    directionPayment.payment = 0;
                });
            } else {
                directionsPayments = {};
            }

            /* --- */

            const directions = _.keyBy(response.directions, 'code');
            const paymentMode = response.isSocialVisit ? 'social' : 'standard';

            /* --- сохраняем начальное состояние --- */

            const newState = {
                invoices: response.invoices,
                account: response.account,

                paymentMethods,
                cashboxOptions,
                selectedPayment: paymentMethods[0],
                selectedCashbox: cashboxOptions[0],
                discountTypes,
                selectedDiscountType: !!discountTypes ? discountTypes[0] : null,
                servicesGroupsList: response.servicesGroups,
                paymentSchedule: response.paymentSchedule,
                patient: response.patient,
                directions,
                directionsPayments,
                paymentMode,

                isFixedPaymentMode: response.isSocialVisit,
                isAutopaymentPlan: response.isAutopaymentPlan,
                isPlanFulfilled: response.isPlanFulfilled,

                printInvoices: !response.invoicesPrinted && !this.hasOnlyFullDirectionInvoices(response.invoices, directions)
            };

            this.updateDirectionsPayments(newState, directionsPayments);

            this.setState(newState);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selectedKkm !== this.state.selectedKkm) {
            const getCashier = (kkm) => {

                if (kkm.cashierId) {
                    return {
                        cashierName: this.props.users[kkm.cashierId].fio,
                        cashierINN: this.props.users[kkm.cashierId].inn
                    }
                } else {
                    return {
                        cashierName: this.props.currentUser.fio,
                        cashierINN: this.props.currentUser.inn
                    }
                }

            }

            const kkm = this.state.kkmList.find(kkm => kkm.id === this.state.selectedKkm?.value)

            !!kkm && this.setState({
                kkmSetting: {
                    user: kkm.login,
                    url: kkm.url,
                    password: kkm.password,
                    numberDevice: kkm.numberDevice,
                    ...getCashier(kkm)
                }
            })

        }
    }

    componentWillUnmount() {
        // снимамем обработчик для прерывания лоадера при возникновении ошибки
        window.removeEventListener('unhandledrejection', this.handlerLoader)
    }

    render() {
        const {invoices, patient, isAutopaymentPlan} = this.state;

        if (patient === null) {
            return null
        }

        return (
            <Box>
                <BoxBody modification="no-padding-bottom">
                    <h5 className="block-title">Расчет пациента: {Helper.getFio(patient)}</h5>
                </BoxBody>

                {isAutopaymentPlan ? this.renderOldPlanMessage() : (
                    <>
                        {invoices.map(this.renderInvoice)}
                        {this.renderPaymentSchedule()}
                        {this.renderDirectionsPaymentTable()}
                        {this.renderPaymentOptions()}
                        {this.renderTotalSums()}
                    </>
                )}


            </Box>
        );
    }

    renderPaymentOptions() {
        const {
            discountTypes, discountValue, selectedDiscountType, paymentMethods, cashboxOptions, kkmOptions, selectedKkm,
            selectedPayment, selectedCashbox, paymentMode, insuranceSum, isFixedPaymentMode
        } = this.state;

        return (
            <BoxBody modification="payment-head">

                <Label labelText="Касса" align="topLeft" marginWrap="0 0 20px">
                    <Select options={cashboxOptions}
                            value={selectedCashbox}
                            onChange={this.handleSelectCashBox}
                    />
                </Label>

                {this.state.selectedCashbox.value === 'ADDITIONAL_BOX_OFFICE' &&
                <Label labelText="Способ оплаты" align="topLeft" marginWrap="0 0 20px">
                    <Select options={paymentMethods}
                            value={selectedPayment}
                            onChange={this.handleSelectPaymentType}
                    />
                </Label>
                }

                {this.state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' &&
                <Label labelText="ККМ" align="topLeft" marginWrap="0 0 20px">
                    <Select options={kkmOptions}
                            value={selectedKkm}
                            onChange={this.handleSelectKkm}
                    />
                </Label>
                }

                <Label labelText="Вариант расчёта" align="topLeft" marginWrap="0 0 20px">
                    <Select options={paymentModeOptions}
                            value={paymentModeOptions.find(paymentModeOption => paymentModeOption.value === paymentMode)}
                            isDisabled={isFixedPaymentMode}
                            onChange={this.handleChangePaymentMode}
                    />
                </Label>

                {(discountTypes !== null) && (
                    <Label labelText="Скидка" align="topLeft" marginWrap="0 0 20px">
                        <div className="payment-groups">
                            <TextField isNumber={true}
                                       min={0}
                                       margin="0 20px 0 0"
                                       widthInput="252px"
                                       value={discountValue}
                                       onChange={this.handleChangeDiscount}
                            />
                            <Select options={discountTypes}
                                    value={selectedDiscountType}
                                    onChange={this.handleSelectDiscountType}
                            />
                        </div>
                    </Label>
                )}

                <Label labelText="Оплата по ДМС" align="topLeft" marginWrap="0 0 20px">
                    <div className="payment-groups">
                        <TextField isNumber={true}
                                   min={0}
                                   widthInput="252px"
                                   value={insuranceSum}
                                   onChange={this.handleChangeInsuranceSum}
                        />
                    </div>
                </Label>
            </BoxBody>
        )
    }

    renderInvoice = (invoice) => {
        const {servicesGroupsList, directions} = this.state;
        const directionName = directions[invoice.direction].name;

        if (!invoice || !invoice.items.length) return null;

        return (
            <BoxBody modification="no-padding-top" key={invoice.id}>
                <p className="table__title">
                    {`Счёт по направлению "${directionName}" `}
                    {this.renderInvoiceNote(invoice)}
                </p>
                <div className="card">
                    <div className="invoice__table">
                        <Table
                            columns={invoiceColumns}
                            data={invoice.items}
                            prepareDataItem={this.prepareInvoiceItem}
                            groups={servicesGroupsList}
                            selectMode="none"
                        />
                    </div>
                </div>
            </BoxBody>
        );
    };

    renderInvoiceLink(invoiceId) {
        return (
            <span className="link" onClick={() => this.openInvoicePopup(invoiceId)}>
                счёт №{invoiceId}
            </span>
        )
    };

    renderInvoiceNote(invoice) {
        const invoiceNote = [];

        if (invoice.params && invoice.params['replacement_for']) {
            invoiceNote.push(
                <>
                    (заменён {this.renderInvoiceLink(invoice.params['replacement_for'])})
                </>
            )
        }

        if (invoice.isPaid) {
            let paidNote = 'оплачен';

            if (invoice.params && invoice.params['paid_by']) {
                paidNote += ' - ' + autopaymentReasonsTitles[invoice.params['paid_by']];
            }

            invoiceNote.push('(' + paidNote + ')');
        }

        return invoiceNote;
    }

    renderDirectionsPaymentTable() {
        const {directionsPayments} = this.state;
        if (Helper.isEmptyObj(directionsPayments)) return null;

        const indicateAllSumsForPayment = this.needIndicateAllSumsForPayment();

        return (
            <BoxBody modification="no-padding-vertical">
                <p className="table__title">
                    Оплаты по направлениям
                </p>

                {indicateAllSumsForPayment && this.renderAllSumsIndicatedForPaymentMessage()}

                <div className="card directions-payments-table">
                    <Table columns={directionsPaymentsColumns}
                           data={Object.values(directionsPayments)}
                           prepareDataItem={({item: directionPayment}) => this.prepareDirectionPayment(directionPayment, indicateAllSumsForPayment)}
                    />
                </div>
            </BoxBody>
        );
    }

    renderAllSumsIndicatedForPaymentMessage() {
        const {isPlanFulfilled} = this.state;

        if (isPlanFulfilled) {
            return (
                <p>
                    Согласованный план лечения завершён. Все суммы, которые осталось оплатить, автоматически указаны к оплате.
                </p>
            );
        }

        return (
            <p>
                Денег на балансе пациента хватает на оплату всех счетов. Все суммы, которые осталось оплатить, автоматически указаны к оплате.
            </p>
        );
    }

    renderPaymentSchedule() {
        const {paymentSchedule} = this.state;

        return (
            <BoxBody modification="no-padding-top">
                <div className="payment__payment-schedule">
                    <PaymentSchedule value={paymentSchedule} onChange={this.handleChangePaymentSchedule}/>
                </div>
            </BoxBody>
        );
    }

    renderTotalSums() {
        const {account, invoices, printInvoices} = this.state;

        const totalPaymentSum = this.getTotalPaymentSum();
        const totalPriceToPay = this.getTotalPriceToPay();

        const discountValue = Number(this.state.discountValue);
        const insuranceSum = this.getInsuranceSum();

        const futureAccount = this.getFutureAccount(totalPaymentSum);
        const accountDiff = account - Math.max(futureAccount, 0);

        return (
            <BoxBody>
                <div className="card card-sm">
                    <div className="card-header">
                        На счете пациента {PriceHelper.format(account ? account : 0)} руб.
                    </div>
                    <div className="card-body">
                        <div className="payment-total">
                            {(totalPriceToPay !== totalPaymentSum) && (
                                <div className="payment-total__row">
                                    <div className="payment-total__label">К оплате</div>
                                    <div
                                        className="payment-total__value">{PriceHelper.format(totalPriceToPay)} руб
                                    </div>
                                </div>
                            )}

                            {(discountValue > 0) && (
                                <div className="payment-total__row payment-total__row--discount">
                                    <div className="payment-total__label">Скидка</div>
                                    <div className="payment-total__value">{PriceHelper.format(discountValue)} руб</div>
                                </div>
                            )}

                            {(insuranceSum > 0) && (
                                <div className="payment-total__row payment-total__row--discount">
                                    <div className="payment-total__label">По ДМС</div>
                                    <div className="payment-total__value">{PriceHelper.format(insuranceSum)} руб</div>
                                </div>
                            )}

                            {(totalPriceToPay !== totalPaymentSum && accountDiff > 0) && (
                                <div className="payment-total__row payment-total__row--discount">
                                    <div className="payment-total__label">Со счёта спишется</div>
                                    <div className="payment-total__value">{PriceHelper.format(Math.min(accountDiff, totalPriceToPay))} руб</div>
                                </div>
                            )}

                            <div className="payment-total__row payment-total__row--amount">
                                <div className="payment-total__label">Итого к оплате</div>
                                <div className="payment-total__value">{PriceHelper.format(totalPaymentSum)} руб</div>
                            </div>
                        </div>

                        {(invoices.length > 0) && (
                            <div className="print-invoices">
                                <Checkbox
                                    label={this.getPrintInvoicesLabel()}
                                    checked={printInvoices}
                                    onChange={this.handleChangePrintInvoices}
                                />
                            </div>
                        )}

                        <div className="input-group__row">
                            <Button variant={this.validate() ? "success" : 'disabled'} size="md" loader={this.state.loader}
                                    children="К оплате" onClick={this.submitPayment}/>
                        </div>
                        <span className="text-note">
                            {`Состояние счета после оплаты ${Helper.Price.format(futureAccount)} руб.`}
                        </span>
                        <ValidationLinkInfo validate={!this.state.kkmError} onClickIsValidate={() => {}}
                                            text={this.state.kkmError} linkText={""}/>
                    </div>
                </div>
            </BoxBody>
        );
    };

    validate()
    {
        let valid = true
        if (!!this.state.selectedKkm) {
            valid = valid && !this.state.kkmError
        }
        if (this.state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' && this.state.kkmOptions.length > 0 && !this.state.selectedKkm) {
            valid = false
        }

        return valid
    }

    renderDirectionName(direction) {
        return (
            <ServiceDirectionName direction={direction}/>
        );
    }

    renderDirectionPaymentInput(directionPayment, leftToPay) {
        const directionCode = directionPayment.directionCode;
        return (
            <>
                <TextField isNumber={true}
                           min={0}
                           widthInput="100px"
                           size="min"
                           value={directionPayment.payment}
                           min={0}
                           max={leftToPay}
                           onChange={newPayment => this.handleChangeDirectionPayment(directionCode, newPayment)}
                />
            </>
        );
    }

    renderOldPlanMessage() {
        return (
            <BoxBody modification="no-padding-top">
                <div className="payment-message">
                    <div className="payment-message__text">
                        Пациент продолжает лечение по старому плану, составленному до 4 марта 2020 года
                    </div>
                    <Button children="Завершить приём" size="md" loader={this.state.loader}
                            variant="success" onClick={this.submitPayment}/>
                </div>
            </BoxBody>
        );
    }

    prepareDirectionPayment = (directionPayment, indicateAllSumsForPayment) => {
        const direction = this.state.directions[directionPayment.directionCode];

        directionPayment.rowCode = direction.code;
        directionPayment.name = this.renderDirectionName(direction);

        let leftToPay = directionPayment.sum - directionPayment.paid;
        if (!this.needPayInvoices() && directionPayment.notAcceptedSum) {
            leftToPay -= directionPayment.notAcceptedSum;
        }

        const leftToPayPercent = Math.round(100 * leftToPay / directionPayment.sum);
        directionPayment.leftToPay = `${PriceHelper.format(leftToPay)} (${leftToPayPercent}%)`;

        if (indicateAllSumsForPayment || leftToPay === 0){
            directionPayment.payment = PriceHelper.format(directionPayment.payment);
        } else {
            directionPayment.payment = this.renderDirectionPaymentInput(directionPayment, leftToPay);
        }
    };

    prepareInvoiceItem = ({item}) => {
        item.groupId = item.serviceGroupId;
        item.sum = item.quantity * item.price;
    };

    openInvoicePopup(invoiceId) {
        this.context.showSlideModal(<div>
            <Invoice
                invoiceId={invoiceId}
                servicesGroups={this.state.servicesGroupsList}
            />
        </div>)
    }

    handleChangeDiscount = (discountValue) => this.setState({discountValue});

    handleChangePaymentMode = (paymentModeOption) => {
        const directionsPayments = _.clone(this.state.directionsPayments);
        const paymentMode = paymentModeOption.value;

        this.updateDirectionsPayments({...this.state, paymentMode}, directionsPayments)

        this.setState({paymentMode, directionsPayments})
    };

    handleChangeInsuranceSum = (insuranceSum) => this.setState({insuranceSum});

    handleSelectDiscountType = (selectedDiscountType) => this.setState({selectedDiscountType});

    handleSelectPaymentType = (selectedPayment) => this.setState({selectedPayment});

    handleSelectCashBox = (selectedCashbox) => this.setState({selectedCashbox});

    handleSelectKkm = (selectedKkm) => this.setState({selectedKkm});

    handleChangePaymentSchedule = e => this.setState({paymentSchedule: e.target.value});

    handleChangePrintInvoices = printInvoices => this.setState({printInvoices});

    /**
     * Возвращает сумму наряда
     * @return {*}
     */
    getInvoiceSum = invoice => {
        return invoice.items.reduce((sum, item) => sum + (item.quantity * item.price), 0);
    };

    getInsuranceSum = () => Number(this.state.insuranceSum)

    needPayInvoices() {
        const {paymentMode} = this.state;
        return (paymentMode !== 'netting') && (paymentMode !== 'social');
    }

    getTotalPriceToPay() {
        const {directionsPayments} = this.state;

        return Object.values(directionsPayments).reduce((total, directionPayment) => total + Number(directionPayment.payment), 0);
    }

    /**
     * Возвращает итоговую сумму к оплате
     * @return {number}
     */
    getTotalPaymentSum() {
        const {discountValue, account} = this.state;
        let totalPrice = this.getTotalPriceToPay() - Number(discountValue) - this.getInsuranceSum();

        if (account > 0) {
            totalPrice -= account;
        }

        return Math.max(totalPrice, 0);
    }

    /**
     * Возвращает будущий баланс пациента
     * @param {number} totalPaymentSum
     * @return {number}
     */
    getFutureAccount(totalPaymentSum) {
        const {account, invoices, discountValue} = this.state;
        let invoicesSumToPay = 0;

        if (this.needPayInvoices()) {
            const notAcceptedInvoices = invoices.filter(invoice => !invoice.isAccepted);
            invoicesSumToPay = notAcceptedInvoices.reduce((sum, invoice) => sum + this.getInvoiceSum(invoice) - invoice.paidSum, 0);
        }

        return account - invoicesSumToPay + Number(discountValue) + this.getInsuranceSum() + totalPaymentSum;
    }

    getPrintInvoicesLabel() {
        const {invoices} = this.state;
        return 'Распечатать ' + ((invoices.length === 1) ? 'счёт' : 'счета');
    }

    handleChangeDirectionPayment(directionCode, newPayment) {
        const directionsPayments = Helper.clone(this.state.directionsPayments);
        directionsPayments[directionCode].payment = newPayment;
        this.setState({directionsPayments});
    }

    submitPayment = () => {
        this.setState({loader: true});
        const {
            selectedPayment, selectedCashbox, directionsPayments, selectedDiscountType,
            paymentSchedule, paymentMode, printInvoices, isAutopaymentPlan
        } = this.state;

        const {closeSlideModal} = this.context;

        const discountValue = Number(this.state.discountValue);

        const data = {
            visitId: this.props.visitId,
            paymentMethod: selectedPayment.value,
            cashbox: selectedCashbox.value,
            paymentSum: this.getTotalPaymentSum(),
            paymentSchedule,
            paymentDistribution: {},
            netting: paymentMode === 'netting',
            isSocialVisit: paymentMode === 'social',
            insuranceSum: this.getInsuranceSum(),
            generateInvoicesDocuments: printInvoices
        };

        Obj.forEach(directionsPayments, (directionPayment, directionCode) => {
            data.paymentDistribution[directionCode] = Number(directionPayment.payment);
        });

        if (discountValue > 0) {
            data.discount = {
                sum: discountValue,
                type: selectedDiscountType.value
            };
        }

        if (this.state.selectedCashbox.value !== 'ADDITIONAL_BOX_OFFICE' && this.state.selectedKkm) {
            const kkm = new Kkm(this.state.kkmSetting)

            kkm.registerCheck(
                0,
                true,
                data.paymentSum + discountValue,
                discountValue,
                this.state.selectedCashbox.value === 'MAIN_BOX_OFFICE').then((response) => {

                if (response.Status === 0) {

                    ServerCommand.run('visit/pay', data).then((response) => {
                        if (!isAutopaymentPlan && printInvoices) {
                            Printer.print(response.invoicesDocuments).then(closeSlideModal);
                        } else {
                            closeSlideModal();
                        }

                        this.setState({loader: false});
                    });

                } else {
                    this.setState({
                        loader: false,
                        kkmError: response.Error
                    });
                    console.log(response.Error)
                }
            }).catch(reason => {
                this.setState({
                    loader: false,
                    kkmError: reason
                });
            })
        } else {
            ServerCommand.run('visit/pay', data).then((response) => {
                if (!isAutopaymentPlan && printInvoices) {
                    Printer.print(response.invoicesDocuments).then(closeSlideModal);
                } else {
                    closeSlideModal();
                }

                this.setState({loader: false});
            });
        }

    };

    hasOnlyFullDirectionInvoices(invoices, directions) {
        return invoices.every(invoice => {
            const direction = directions[invoice.direction];
            return direction.payMode === 'full_direction';
        })
    }

    /**
     * Обновляет суммы к оплате при инициализации и переключении режима оплаты. Обновление производится только если
     * план выполнен, тк в этом случае сумма оплаты определяется автоматически
     * @param state
     * @param directionsPayments
     */
    updateDirectionsPayments(state, directionsPayments) {
        state = state ? state : this.state

        if (this.needIndicateAllSumsForPayment(state)) {
            if (state.paymentMode === 'social' || state.paymentMode === 'netting') {
                _.each(directionsPayments, directionPayment => directionPayment.payment = 0);
            } else {
                _.each(directionsPayments, directionPayment => directionPayment.payment = directionPayment.sum - directionPayment.paid);
            }
        }
    }

    handlerLoader(event) {
        const reason = event.reason;
        if (reason.type === 'server_request_error') {
            this.setState({loader: false})
        }
    }

    /**
     * Проверяет, нужно ли указать все неоплаченные суммы к оплате
     * @return {boolean}
     */
    needIndicateAllSumsForPayment = state => {
        state = state ? state : this.state;

        return state.isPlanFulfilled
            || state.account >= _.sumBy(_.values(state.directionsPayments), ({sum, paid}) => sum - paid)
    }
}

const mapStateToProps = state => ({
    cashboxOptions: state.common.cashboxOptions,
    users: state.common.users,
    currentUser: state.user
});

export default connect(mapStateToProps)(Payment)

const invoiceColumns = [
    {
        title: 'Услуга',
        code: 'serviceName',
        containsGroupTitle: true
    },
    {
        title: 'Зуб',
        code: 'target',
    },
    {
        title: 'Цена',
        code: 'price',
        type: 'price'
    },
    {
        title: 'Количество',
        code: 'quantity',
    },
    {
        title: 'К оплате',
        code: 'sum',
        type: 'price',
        countTotal: true
    }
];


const directionsPaymentsColumns = [
    {
        title: 'Направление',
        code: 'name'
    },
    {
        title: 'Стоимость',
        code: 'sum',
        type: 'price'
    },
    {
        title: 'Осталось оплатить',
        code: 'leftToPay'
    },
    {
        title: 'К оплате',
        code: 'payment'
    },
];

const autopaymentReasonsTitles = {
    'social_visit': 'социальный приём',
    'netting': 'взаимозачёт',
};

const paymentModeOptions = [
    {
        label: 'Стандартный',
        value: 'standard'
    },
    {
        label: 'Социальный приём',
        value: 'social'
    },
    {
        label: 'Взаимозачёт',
        value: 'netting'
    }
];
