import React from "react";
import {
    WrapHeadRecommendedServices, ItemHeadRecommendedServices, NumberHeadRecommendedServices,
    TextHeadRecommendedServices, InfoHeadRecommendedServices
} from "./styledHeadRecommendedServices";
import {PHeadRecommendedServices} from "./typesHeadRecommendedServices";
import Tooltip from "../../../../../../../components-ui/DataDisplay/Tooltip/Tooltip";

export default function HeadRecommendedServices({selected, setSelected}: PHeadRecommendedServices) {
    const currentSelected = selected === 'recommended';
    return (
        <WrapHeadRecommendedServices>
            <ItemHeadRecommendedServices onClick={() => setSelected('recommended')}>
                <NumberHeadRecommendedServices selected={currentSelected}>
                    1
                </NumberHeadRecommendedServices>
                <TextHeadRecommendedServices selected={currentSelected}>
                    Рекомендуемые услуги
                </TextHeadRecommendedServices>
                <InfoHeadRecommendedServices children="?"
                                             data-for="infoHead"
                                             data-tip="Список рекомендуемых услуг составляется автоматически<br/>на основании диагнозов из Диагнокат"/>
                <Tooltip id="infoHead" place="bottom" effect="solid" variant="dark" isArrow={true}/>
            </ItemHeadRecommendedServices>
            <ItemHeadRecommendedServices variant="all">
                <NumberHeadRecommendedServices selected={!currentSelected}>
                    2
                </NumberHeadRecommendedServices>
                <TextHeadRecommendedServices selected={!currentSelected}>
                    Все услуги
                </TextHeadRecommendedServices>
            </ItemHeadRecommendedServices>
        </WrapHeadRecommendedServices>
    )
}
