import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const PlusIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M12,0V24M24,12H0"/>
            <polygon points="14.06 24 9.98 24 9.98 14.06 0 14.06 0 9.98 9.98 9.98 9.98 0 14.06 0 14.06 9.98 24 9.98 24 14.06 14.06 14.06 14.06 24"/>
        </WrapIcon>
    );
});
