import React, {Dispatch, SetStateAction} from 'react';
import {ScriptType, ScriptGroupList} from '../../../../types/common';
import Box from '../../../../../../components-ui/Layout/Box';
import {EditorContent, useEditor, BubbleMenu as BubbleMenuComponent} from '@tiptap/react';
import {StarterKit} from '@tiptap/starter-kit';
import {Underline} from '@tiptap/extension-underline';
import Button from '../../../../../../components-ui/Inputs/Button/Button';
import Icon from '../../../../../../components-ui/DataDisplay/Icon';
import {SBoxScript} from '../../style';
import Scrollbar from '../../../../../../components-ui/DataDisplay/Scrollbar/Scrollbar';
import {Table} from '@tiptap/extension-table';
import {TableRow} from '@tiptap/extension-table-row';
import {TableHeader} from '@tiptap/extension-table-header';
import {TableCell} from '@tiptap/extension-table-cell';
import MenuBar from './MenuBar';
import BubbleMenuInsideComponent from './BubbleMenuInsideComponent';
import {useDialogsManager} from '../../../../../../containers/DialogsManager/DialogsManager';
import ModalEditScript from './modals/ModalEditScript';

interface Props {
    selectedScript: ScriptType | null;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
    groupsScript: {[p: string]: ScriptGroupList[]};
    setIsNewScript: Dispatch<SetStateAction<boolean>>;
    refreshDataFromServer: () => void;


}

const EditScript = ({selectedScript, setIsEditing, groupsScript, setIsNewScript, refreshDataFromServer}: Props) => {

    const {showModal} = useDialogsManager();

    const refreshData = () => {
        setIsEditing(false);
        setIsNewScript(false);
        refreshDataFromServer();
    };

    /*const CustomDocument = Document.extend({
        content: 'heading block*',
    });*/

    const editor = useEditor({
        extensions: [
            /* CustomDocument,*/
            StarterKit.configure({
                /*  document: false,*/
            }),
            Underline,
            /*Placeholder.configure({
                placeholder: ({node}) => {
                    if (node.type.name === 'heading') {
                        return 'Заголовок';
                    }

                    return '';
                },
            }),*/
            Table.configure({
                resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCell,
        ],
        content: selectedScript?.text,
    });
    return (
        <Box width='520px' backgroundColor='white' padding='18px 18px 58px 32px'>
            <Box display='flex' justifyContent='space-between' padding='0 9px 6px 0'>
                <MenuBar editor={editor} />
                <Box display='flex' gap='5px'>
                    <Button
                        isIcon
                        onClick={() => {
                            setIsEditing(false);
                            setIsNewScript(false);
                        }}
                        variant='default'
                    >
                        <Icon name='close' />
                    </Button>
                    <Button
                        isIcon
                        onClick={() => {
                            const text = editor?.getHTML();
                            showModal(<ModalEditScript selectedScript={selectedScript}
                                                       text={text || ''}
                                                       groupsScript={groupsScript}
                                                       refreshData={refreshData}
                                />
                                , {
                                    customStyles: {
                                        content: {
                                            maxHeight: 'auto',
                                            transform: 'translate(-50%, -40vh)',
                                            background: "none",
                                            paddingBottom: "9vh"
                                        }
                                    }
                                },
                            );
                        }}

                        variant='success'
                    >
                        <Icon name='checked' color='white' />
                    </Button>
                </Box>
            </Box>
            <Scrollbar height='545px'>
                <SBoxScript>
                    {editor &&
                        <BubbleMenuComponent shouldShow={() =>
                            //true
                            editor?.can().chain().deleteTable().run()
                        }
                                             editor={editor}
                                             tippyOptions={{duration: 100}}>
                            {editor?.can().chain().deleteTable().run() && <BubbleMenuInsideComponent editor={editor} />}
                        </BubbleMenuComponent>
                    }
                    <EditorContent editor={editor} />
                </SBoxScript>
            </Scrollbar>
        </Box>
    )
        ;
};

export default EditScript;
