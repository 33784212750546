import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'react-scrollbar';
import Helper from "../../common/helpers/general-helper";

export default class Scrollbars extends React.Component {
    static propTypes = {
        verticalScrollSide: PropTypes.oneOf(['left', 'right']),
        className: PropTypes.string,
        onScrollEnd: PropTypes.func,
        color: PropTypes.string,
        isScroll: PropTypes.bool
    };

    static defaultProps = {
        verticalScrollSide: 'right',
        className: '',
        stopScroll: false,
        color: 'white'
    };

    lastTopPosition = 0;

    render() {
        let props = Helper.clone(this.props);


        props.className = 'custom-scroll__container ' + this.props.className;

        props.verticalContainerStyle = {
            opacity: '1',
            zIndex: '1',
            background: 'rgba(0, 0, 0, 0.05)',
            margin: '0 8px',
            borderRadius: '3px',
            width: '3px',
        };

        props.verticalScrollbarStyle = {
            background: '#6c7783',
            borderRadius: '3px',
            width: '3px',
            marginLeft: '0px',
            cursor: 'pointer'
        };


        if (this.props.color === 'black') {
            props.verticalScrollbarStyle.background = "#000";
        }

        return (
            <ScrollArea
                {...props}
                onScroll={this.handleScroll.bind(this)}
                stopScrollPropagation={true}
                vertical={!this.props.isScroll}
            />
        );
    }

    handleScroll(value) {
        if (!this.props.onScrollEnd || this.lastTopPosition === value.topPosition) {
            return null
        }

        this.lastTopPosition = value.topPosition;

        if (value.realHeight === (value.containerHeight + value.topPosition)) {
           this.props.onScrollEnd();
        }

    }
}
