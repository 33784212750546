import Helper from "../helpers/main";

export default class Measure {
    type = null;

    constructor(type) {
        this.type = type;
    }

    isComposite() {
        return (this.type === 'SEGMENT');
    }

    /**
     * Получает часть цели услуги, соответствующую указанному номеру зуба
     * @param toothNumber
     * @return {*}
     */
    getTargetUnitByTooth(toothNumber) {
        let targetUnit = null;

        switch (this.type) {
            case 'SEGMENT':
            case 'TOOTH':
                targetUnit = toothNumber;
                break;

            case 'JAW':
                if(toothNumber) {
                    const jawCode = Helper.TeethMap.getJawCode(toothNumber);
                    targetUnit = (jawCode === 'lower') ? 'н.ч.' : 'в.ч.';
                }
                break;

            case 'BOTH_JAWS':
                targetUnit = 'в.ч., н.ч.';
                break;

            case 'ORAL_CAVITY':
                targetUnit = 'полость рта';
                break;

            case 'PRODUCT':
                targetUnit = 'шт';
                break;
            default:
                break;
        }

        return targetUnit;
    }

    requiresActiveTeeth() {
        return this.getTargetUnitByTooth(null) === null;
    }

    /**
     * Возвращает признак того, использует ли данная цель режим полного соответствия с активными зубами плана лечения для определения
     * услуг, применённых к активным зубам. То есть, должны ли выделенные зубы зубной карты включать цели всех пунктов плана лечения
     * с определённой услугой, чтобы эта услуга считалась применённой.
     * @return {boolean}
     */
    usesFullServiceApplyMode() {
        return (this.type === 'TOOTH') || (this.type === 'JAW');
    }

    getType() {
        return this.type;
    }
}
