import React from "react";
import {PropsError} from "../types";

export const networkTroubles = 'Не удалось соединиться с сервером. Возможно, возникли проблемы с подключением к интернету. Подождите 1-2 минуты и повторите попытку. Если ошибка возникает повторно, сообщите об этой ошибке.'

export const ErrorDescription = (error: PropsError, cardNumber?: string) => {
    const {code, description, params, status} = error;
    let content = null;
    switch (code) {
        case "dental_analysis_patient_not_found" :
            content = [`Пациент не найден в Диагнокат. Убедитесь, что снимок загружен в Диагнокат, и указанный ID пациента в
            Диагнокат совпадает с номером карты: ${cardNumber}. `,
                !!params ?
                    <a href={params.helpLink as string} target="_blank" className="link">Как изменить ID пациента в
                        Диагнокат</a> : null]
            break;
        case "dental_analysis_patient_has_no_analyses" :
            content = 'У пациента в Диагнокат нет ни одного анализа снимка. Убедитесь, что снимок загружен в Диагнокат.'
            break;
        case "dental_analysis_not_completed" :
            content = 'Анализ снимка в Диагнокат ещё не завершён. Подождите 1-2 минуты и повторите попытку.'
            break;
        case "dental_analysis_server_request_error" :
            content = 'Не удалось подключиться к Диагнокат, повторите попытку. Если ошибка возникает повторно - заполните зубную формулу вручную, сообшите об этой ошибке.'
            break;
        case "request_error":
            content = `При запросе к серверу возникла ошибка с кодом ${status}. Пожалуйста, сообщите об этой ошибке, опишите действия, которые к ней привели.`
            break;
        default:
            content = description
            break;
    }

    return (
        <>
            {content}
        </>
    )
}
