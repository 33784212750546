import React from "react";
import {commonPropsButton} from "../../../../containers/Tasks/data/commonPropsSComponent";
import {buttonTitles} from "../../helper/getDataCallCenter";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Box from "../../../../components-ui/Layout/Box";
import {CommonButtonProps} from "../../types/components";

interface Props extends CommonButtonProps {
    validate: boolean
    personalPhone?: string
    blockedButtons: boolean
}

export const NoteDownButton = ({validate, task, personalPhone, blockedButtons, margin, methodsCallCenter}: Props) => {
    const {memorizedVisit} = methodsCallCenter;
    return (
        <Box className="button-task-content" margin={margin}>
            <Button {...commonPropsButton} variant={blockedButtons ? "disabled" : validate ? "success" : "disabled"}
                    children="записать"
                    title={blockedButtons ? buttonTitles.blocked : !validate ? buttonTitles.noteValidate : buttonTitles.note_down}
                    onClick={() => {
                        if (!!personalPhone) {
                            memorizedVisit(task, personalPhone, "пациента из заявки")
                        } else {
                            memorizedVisit(task)
                        }
                    }}/>
        </Box>

    )
}
