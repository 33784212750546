import React from 'react';
import Typography from '../../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../../components-ui/Layout/Box';
import useSettingsHandset from './hooks/useSettingsHandset';
import HandsetTable from './components/HandsetTable';
import Button from '../../../components-ui/Inputs/Button/Button';
import AddingEditingEmployee from './components/modals/AddingEditingHandset';
import Paper from '../../../containers/UI-Collection/Elements/Surfaces/Paper';
import {useDialogsManager} from '../../../containers/DialogsManager/DialogsManager';
import LoaderPage from '../../../components/LoaderPage';
import AddingEditingKkm from "./components/modals/AddingEditingHandset";

const SettingsHandsetPage = () => {

    const {
        state,
        setState,
        refreshDataFromServer
    } = useSettingsHandset();

    const {showSlideModal} = useDialogsManager();
    return (
        <Box padding='62px 20px 80px 28px'>
            <Typography variant='title' fontWeight='bold'>Список трубок</Typography>
            <Paper padding='24px' minHeight={600}>
                {state.handsetList
                    ?
                    <HandsetTable data={state.handsetList}
                                  refreshDataFromServer={refreshDataFromServer}
                    />
                    :
                    <LoaderPage height={400}/>
                }
                <Button margin='24px 0 0 0'
                        variant='default'
                        size='md'
                        children='Добавить'
                        onClick={() => showSlideModal(<AddingEditingKkm refreshDataFromServer={refreshDataFromServer}/>)}
                />
            </Paper>
        </Box>
    );
};

export default SettingsHandsetPage;
