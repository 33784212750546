import {ParentNamePatientField} from "../types/common";

export default function commonLabelOptions(isMargin: boolean = true, parentName?: ParentNamePatientField) : {
    colorLabel: "textGray",
    widthLabelWrap: string
    marginWrap: string
    minWidthLabel: string,
} {
    return {
        colorLabel: "textGray",
        widthLabelWrap: "100%",
        marginWrap: isMargin ? parentName === "WAP" ? "0 0 24px 0" : "0 0 28px 0" : '0',
        minWidthLabel: parentName === "WAP" ? "166.5px" : parentName === "NewVisitForm" ? "37%" : "33%"
    }
}
