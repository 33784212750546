import styled from "styled-components";

export const WrapCalendarField = styled.div.attrs({className: 'CalendarField'})<{widthCalendar: string}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${props => props.widthCalendar};
    .InputWrap {
        input {
            padding-right: 0;
            padding-left: 0;
            &::placeholder {
                font-style: normal;
            }
        }
    }
`
