import React from 'react';
import {GetPaymentsCheckFormResult, IPaymentsFilters} from '../../types';
import Table from '../../../../components-ui/DataDisplay/Table';
import useTableFormatter from './hooks/useTableFormatter';
import Box from '../../../../components-ui/Layout/Box';
import Loader from '../../../../components-ui/Feedback/Loader/Loader';
import {OptionSelect} from '../../../../components-ui/Inputs/Select/typesSelect';
import PriceHelper from '../../../../common/helpers/price-helper';

interface Props {
    data: GetPaymentsCheckFormResult,
    refreshDataFromServer: () => void,
    loader: boolean
    selectedBranch: OptionSelect
    filters: IPaymentsFilters
}

const TablePayments = ({data, refreshDataFromServer, loader, selectedBranch, filters}: Props) => {

    const {paymentRows, paymentColumns} = useTableFormatter({data, selectedBranch, filters, refreshDataFromServer});

    let total = 0;

    paymentRows.forEach((payment) => {
        payment.services.forEach((service) => {
            total += service.sum;
        });
    });

    return (
        <Box margin='24px 24px 0 24px'>
            <Loader visible={loader} width={30} height={30} />

            <Table customRowsCells={true}
                   maxHeight='450px'
                   columns={paymentColumns}
                   rows={paymentRows}
                   variantTable='outline'
                   isHovering={false}
                   fontSizeRow='14px'
                   total={!!paymentRows.length ?
                       [{
                           fieldName: 'date',
                           labelWidth: '0',
                           totals: [{label: '', value: 'Итого:', isHighlighted: true}],
                       }, {
                           fieldName: 'sum',
                           labelWidth: '0',
                           totals: [{label: '', value: PriceHelper.format(total), isHighlighted: true}],
                       }] :
                       undefined
                   }
            />
        </Box>

    );
};

export default TablePayments;
