import TreatmentPlan from "./TreatmentPlan";
import Helper from "../../common/helpers/main";
import _ from 'underscore';
import {
    deleteDirection,
    getDirectionQuantity,
    getDirectionsQuantity
} from "../../common/helpers/treatment-plan-item";
import Arr from "../../common/helpers/Arr";

export default class RecommendedPlan {

    planItems = null;

    /**
     * @type {TreatmentPlan}
     */
    performedPlan = null;
    checkStatuses = null;

    constructor(planItems, performedPlan, checkStatuses) {
        planItems = Helper.clone(planItems);

        planItems.forEach(TreatmentPlan.prepareItem);
        planItems = RecommendedPlan.divideItems(planItems);

        this.planItems = _.indexBy(planItems, item => RecommendedPlan.getItemCode(item));
        this.performedPlan = performedPlan;
        this.checkStatuses = checkStatuses;
    }

    getSelectedItems() {
        const result = [];

        Helper.forEachObj(this.planItems, recommendedItem => {
            const appropriatePerformedItems = this.getAppropriatePerformedItems(recommendedItem);
            if (appropriatePerformedItems.length > 0) {
                /*
                пункт рекомендуемого плана является выбранным, если в выполняемом плане суммарное количество по пунктам
                с той же услугой и целью равно или превосходит количество в рекомендуемом плане
                */
                let performedItemsQuantity = appropriatePerformedItems.reduce((allQuantity, planItem) => allQuantity + planItem.quantity, 0);
                if(performedItemsQuantity >= recommendedItem.quantity) {
                    result.push(recommendedItem);
                }
            }
        });

        return result;
    }

    isItemEnabled = (recommendedItem) => {
        if(!this.checkStatuses) return true;

        const appropriatePerformedItems = this.getAppropriatePerformedItems(recommendedItem);

        const blockedPerformedItems = appropriatePerformedItems.filter(performedItem => performedItem.isBlocked);
        const blockedQuantity = blockedPerformedItems.reduce((result, performedItem) => result + performedItem.quantity, 0);

        return recommendedItem.quantity > blockedQuantity;
    };

    /**
     * Переносит пункт рекомендуемого плана лечения в согласованный план, если пункт не является перенесённым (выбранным),
     * в противном случае отменяет перенос
     * @param {string} itemCode
     * @param {boolean} autoAddition - автоматически добавлять доп услуги по направлению, если добавлены все основные услуги
     * @return {RecommendedPlan}
     */
    toggleItem(itemCode, autoAddition = true) {
        const recommendedItem = this.planItems[itemCode];

        const selectedItems = this.getSelectedItems();

        if (selectedItems.includes(recommendedItem)) {
            // если пункт рекомендуемого плана уже был выбран - удаляем его из выполняемого плана

            this.getAppropriatePerformedItems(recommendedItem, false).forEach(performedItem => {
                if(!performedItem.isBlocked && deleteDirection(performedItem, recommendedItem.direction) === null) {
                    this.performedPlan.deleteItem(TreatmentPlan.getPlanItemCode(performedItem));
                }
            });
        } else {
            /*
            если пункт рекомендуемого плана не был выбран - добавляем его в план - при необходимости добавляем соответствующий пункт плана в начальном статусе,
            уставляем недостающее количество
            */

            this.addItem(recommendedItem, selectedItems, autoAddition);
        }

        return this;
    }

    addItem(item, selectedItems, autoAddition = true) {
        const newItemFields = _.pick(item, 'performerId', 'procedures', 'directions');

        const appropriatePerformedItems = this.getAppropriatePerformedItems(item);

        const quantityToAdd = appropriatePerformedItems.reduce((result, performedItem) => (result - performedItem.quantity), getDirectionQuantity(item, item.direction));
        newItemFields.directionsQuantity = {
            [item.direction]: quantityToAdd
        };

        if (this.performedPlan.hasItem(item)) {
            this.performedPlan.setItemQuantity(TreatmentPlan.getPlanItemCode(item), '+=' + quantityToAdd, {[item.direction]: quantityToAdd}, true)
        }
        else {
            newItemFields.quantity = quantityToAdd;

            this.performedPlan.addItem(item.serviceId, item.target, newItemFields);
        }

        /* --- автоматическое добавление доп услуг по направлению (если перенесены все основные услуги по направлению) --- */

        if (autoAddition && !item.isAdditional) {
            const [directionMainItems, directionAdditionalItems] = _.chain(this.planItems)
                .values()
                .where({
                    direction: item.direction,
                    performerId: item.performerId
                })
                .partition({isAdditional: false})
                .value();

            selectedItems = selectedItems.concat(item);

            if (Arr.includesAll(selectedItems, directionMainItems)) {
                const notSelectedAdditionalItems = _.difference(directionAdditionalItems, selectedItems);

                if (notSelectedAdditionalItems.length > 0) {
                    notSelectedAdditionalItems.forEach(additionalItem => this.addItem(additionalItem));
                }
            }
        }
    }

    /**
     * Получает пункты выполняемого плана, соответствующие указанному пункту рекоммендуемого плана
     * @param {string|object} item
     * @param {boolean} pickDirection
     * @return {Array}
     */
    getAppropriatePerformedItems(item, pickDirection = true) {
        if (typeof item === 'string') {
            item = this.planItems[item];
        }

        const result = [];
        const itemShortCode = TreatmentPlan.getPlanItemCode(item, false, false);

        _.each(this.performedPlan.planItems, performedPlanItem => {
            const sameShortCode = itemShortCode === TreatmentPlan.getPlanItemCode(performedPlanItem, false, false);
            const samePerformer = item.performerId === performedPlanItem.performerId;

            if (sameShortCode && (!performedPlanItem.performerId || samePerformer)) {
                // тк пункты рекомендуемого плана разделены по направлениям, отделяем направление от пункта выполняемого плана

                if (pickDirection) {
                    const directionQuantity = getDirectionQuantity(performedPlanItem, item.direction);

                    if (directionQuantity > 0) {
                        const directionPlanItem = _.clone(performedPlanItem);
                        directionPlanItem.direction = item.direction;
                        directionPlanItem.quantity = directionQuantity;

                        result.push(directionPlanItem)
                    }
                } else {
                    result.push(performedPlanItem);
                }
            }
        });

        return result;
    }

    getItems() {
        return Object.values(TreatmentPlan.simplifyItems(this.planItems));
    }

    save() {
        this.performedPlan.save();
    }

    static divideItems(items) {
        const dividedItems = [];

        items.forEach(item => {
            _.each(getDirectionsQuantity(item), (quantity, direction) => {
                const newItem = _.clone(item);
                newItem.direction = direction;
                newItem.quantity = quantity;

                dividedItems.push(newItem);
            });
        });

        return dividedItems;
    }

    static getItemName(item, proceduresCombinations, directionsMap) {
        const direction = directionsMap[item.direction];
        let name = TreatmentPlan.getItemName(item, proceduresCombinations);

        if (item.directions.length > 1) {
            name += ` (${direction.name.toLowerCase()})`
        }

        return name;
    }

    static getItemCode(item) {
        return TreatmentPlan.getPlanItemCode(item) + '_' + item.direction;
    }
}
