import { useEffect } from "react";

const useOutsideClick = (ref: any, callback: any, deps: any[] = []) => {

    useEffect(() => {
        const handleClick = (e: any) => {
            if (!ref.current || ref.current.contains(e.target)) {
                return;
            }
            callback();
        };

        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [ref, callback, ...deps])
};

export default useOutsideClick;
