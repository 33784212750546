import React from 'react';
import PropTypes from 'prop-types';

export default class BoxBody extends React.Component {
    static propTypes = {
        modification: PropTypes.string,
    };
    render() {
        let className = 'box-body';
        if (this.props.modification) {
            className += ' box-body--' + this.props.modification
        }
        return (
            <div className={className}>
                {this.props.children}
            </div>
        )
    }


}
