import React from 'react';
import {commonLabelOption} from '../../../../../../content/TimeSheet/components/TableTimesheetList/modals/containers/ModalMarkEmployee/data';
import {Label} from '../../../../../../components-ui/DataDisplay/Label';
import Checkbox from '../../../../../../components-ui/Inputs/Checkbox/Checkbox';

interface Props {
    qualitative: boolean
    handleQualitative: (val: boolean) => void
}

const SelectQualitative = ({qualitative, handleQualitative}: Props) => {
    return (
        <Label labelText="Качественный" {...commonLabelOption} >
            <Checkbox label='' checked={qualitative} onChange={(val) => handleQualitative(val)}/>
        </Label>
    );
};

export default SelectQualitative;