import styled from "styled-components";
import { variant } from "../types";

interface PropsSWrapSelectCheckbox {
    margin?: string
    ref?: any
    width?: string
}

export const SWrapSelectCheckbox = styled.div<PropsSWrapSelectCheckbox>`
  position: relative;
  width: ${props => props.width ? props.width : 'max-content'};
  ${props => !!props.margin && `margin: ${props.margin};`}
`

interface PropsSSelectCheckbox {
    width?: string
    height?: string
    variant: variant
}

const heightSelect = (props: PropsSSelectCheckbox) => {
    if (!!props.height) {
        return props.height
    } else {
        switch (props.variant) {
            case "sm":
                return '32px';
            case "md":
            default:
                return '40px'
        }
    }
}

const fontSizeSelect = (props: PropsSSelectCheckbox) => {
    switch (props.variant) {
        case "sm":
            return '14px'
        case "md":
        default:
            return '16px'
    }
}

const paddingSelect = (props: PropsSSelectCheckbox) => {
    switch (props.variant) {
        case "sm":
            return '3px 12px 5px 16px'
        case "md":
        default:
            return '6px 12px 10px 16px'
    }
}

export const SSelectCheckbox = styled.div<PropsSSelectCheckbox>`
  cursor: pointer;
  border-radius: 3px;
  border: solid 1px ${props => props.theme.colors.borderGray};
  background-image: ${props => props.theme.gradient.default};
  height: ${heightSelect};
  width: ${props => props.width ? props.width : '250px'};
  font-size: ${fontSizeSelect};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  padding: ${paddingSelect};
  outline: none;
  position: relative;
  user-select: none;

  &:hover {
    background-image: ${props => props.theme.gradient.defaultHover};
  }

  .new-arrow-select {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }
`

interface PropsSSelectCheckboxSpan {
    active: boolean
    title?: any
}

export const SSelectCheckboxSpan = styled.span<PropsSSelectCheckboxSpan>`
  color: ${props => props.active ? '#2e2f30' : '#6c7783'};
  display: block;
  width: 100%;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 45px;
  text-overflow: ellipsis;
`

interface PropsSSelectCheckboxMenu {
    width?: string
}

export const SSelectCheckboxMenu = styled.div<PropsSSelectCheckboxMenu>`
  position: absolute;
  top: 50px;
  left: 0;
  width: ${props => props.width ? props.width : "300px"};
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadow.defaultModalLight};
  background-color: ${props => props.theme.colors.white};
  z-index: 13;
`

export const SSelectCheckboxMenuHeader = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #e2e6ed;
`

interface PropsSSelectContent {
    widthOption: string
}

export const SSelectCheckboxMenuContent = styled.div<PropsSSelectContent>`
  display: flex;
  flex-wrap: wrap;
  label {
    width: ${props => props.widthOption};
  }
`
