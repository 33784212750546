import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const InstructionIcon = forwardRef((props: IIcon, ref) => {
    return (
        <WrapIcon {...props} ref={ref}>
            <path d="M20.48,20.5A12,12,0,1,0,12,24,12,12,0,0,0,20.48,20.5ZM6,21.36l2.56-3.12a7.14,7.14,0,0,0,6.86,0L18,21.36A11.14,11.14,0,0,1,6,21.36ZM7.61,7.63A6.19,6.19,0,1,1,5.78,12,6.2,6.2,0,0,1,7.61,7.63ZM19.85,19.87a13,13,0,0,1-1.13,1l-2.54-3.1a6.34,6.34,0,0,0,1.55-1.55l3.1,2.54A13,13,0,0,1,19.85,19.87ZM21.34,18l-3.12-2.56a7.14,7.14,0,0,0,0-6.86L21.34,6A11.14,11.14,0,0,1,21.34,18Zm-.51-12.7-3.1,2.53a6.34,6.34,0,0,0-1.55-1.55l2.54-3.1A10.34,10.34,0,0,1,20.83,5.31ZM18,2.68,15.43,5.81a7.09,7.09,0,0,0-6.86,0L6,2.68a11.14,11.14,0,0,1,12,0ZM4.15,4.17a11.78,11.78,0,0,1,1.14-1l2.53,3.1A6.34,6.34,0,0,0,6.27,7.84L3.17,5.31A11.78,11.78,0,0,1,4.15,4.17ZM2.66,6,5.79,8.59a7.09,7.09,0,0,0,0,6.86L2.66,18A11.14,11.14,0,0,1,2.66,6Zm.51,12.71,3.1-2.54a6.34,6.34,0,0,0,1.55,1.55l-2.53,3.1A10.34,10.34,0,0,1,3.17,18.74Z"/>
        </WrapIcon>
    );
});
