
export const namesClinicLoading = {
    all: 'Всего пациентов',
    new: 'Первичные пациенты',
    repeated: 'Повторные',
    survey: 'Проф.осмотры'
} as {
    [key: string]: string
}

export const colorsClinicLoading = {
    all: '#00d6d6',
    new: '#447ce6',
    repeated: '#fdbc00',
    survey: '#2d445f'
} as {
    [key: string]: string
}
