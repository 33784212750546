import _ from "lodash";
import {over14YearsOld} from "../helper/over14YearsOld";
import {IComposite} from "../types/common";
import useOptionsRelation from "../../../content/NewVisitForm/hooks/useOptionsRelation";
import { PropsUsePrepareValueField, PropsPrepareValueField, ResultPrepareValueField } from "../types/hooks";
import {PatientFieldKey, IPatientField} from "../types/fields";


export const usePrepareValueField = ({basic, fields}: PropsUsePrepareValueField) => {
    const relationVariants = useOptionsRelation(!!basic.personalGender ? basic.personalGender.value : null)

    const prepareField = (fieldName: PatientFieldKey): IPatientField | null => {
        const field = _.cloneDeep(fields[fieldName]);
        switch (fieldName) {
            case "phoneOwner":
                field.variants = basic.optionsPhoneOwner.sort((a: any, b: any) => a.sort > b.sort ? 1 : -1)
                field.disabled = basic.personalPhone.length !== 11
                break;
            case "hasMissingTeeth":
                if (!over14YearsOld(basic.personalBirthday)) {
                    return null
                }
                break;
            case "relation":
                field.variants = relationVariants;
                break;
            default:
                break;
        }
        return field
    }

    return ({fieldName}: PropsPrepareValueField) : ResultPrepareValueField | null => {
        const field = prepareField(fieldName);
        if (field === null) return null;

        let value = basic[fieldName]
        let error = false as boolean
        let composite = undefined as undefined | IComposite

        if (basic.validationFields[fieldName]) error = basic.validationFields[fieldName]

        if (!!fields[fieldName].subFields) {
            const subFields = fields[fieldName].subFields as string[]
            composite = _.fromPairs(subFields.map(subField => ([[subField], {
                field: prepareField(subField),
                error:  basic.validationFields[subField]
            }])))
            value = _.fromPairs(subFields.map(subField => ([[subField], basic[subField]])))
        }

        return {
            value, error, field, composite
        }
    }
}
