import styled from "styled-components";
import {ITheme} from "../../../../../../../../../common/Theme/Theme";
import Helper from "../../../../../../../../../common/helpers/main";

export const HeadServiceTable = styled.div.attrs({className: 'HeadServiceTable'})`
    display: flex;
    align-items: center;
    width: 100%;
    background: ${props => props.theme.colors.secondary};
    padding: 9px 0 7px;
`

const HeadText = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.textGray};
`

export const HeadCode = styled(HeadText).attrs({className: 'HeadCode'})`
    padding-left: 56px;
    width: 176px;
`

export const HeadService = styled(HeadText).attrs({className: 'HeadService'})`
    width: 438px;
    padding-left: 20px;
`

export const HeadTarget = styled(HeadText).attrs({className: 'HeadTarget'})`
    width: 294px;
    padding-left: 20px;
`

export const HeadDoctor = styled(HeadText).attrs({className: 'HeadDoctor'})`
    width: calc(100% - 908px);
    padding-left: 20px;
`

interface PropsButtonGroupActions {
    theme?: ITheme,
    active: boolean
}

export const ButtonGroupActions = styled.button.attrs({type: 'button', className: 'ButtonGroupActions'})<PropsButtonGroupActions>`
    font-family: ${props => props.theme.fonts.join(', ')};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    margin-left: 16px;
    display: inline-block;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    padding: 0;
    border-bottom: 1px dashed;
    color: ${props => props.active ? props.theme.colors.danger : props.theme.colors.info};
    border-bottom-color: ${props => `rgba(${Helper.Color.hexToRgb(props.active ? props.theme.colors.danger : props.theme.colors.info)}, 0.3)`};
    &:hover {
        border-bottom-color: ${props => `rgba(${Helper.Color.hexToRgb(props.active ? props.theme.colors.danger : props.theme.colors.info)}, 0.6)`};
    }
`
