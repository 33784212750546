import React, {forwardRef} from 'react';
import { WrapIcon } from '../../containers/WrapIcon';
import { IIcon } from '../../types/common';

export const Tooth77Icon = forwardRef(({viewBox, width, height, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 24 14.7"} width={width || "23"} height={height || "14"} ref={ref}>
            <path d="M23,4.16a4.74,4.74,0,0,0-4.55-3.09,14.09,14.09,0,0,0-4.39,1,8.26,8.26,0,0,1-2.23.54,7.57,7.57,0,0,1-2.95-1C7,.56,4.71-.56,3,.32,1.62,1,.64,3.16.12,6.5a7.4,7.4,0,0,0,1.24,5.61,5.2,5.2,0,0,0,3.37,2.15,7.25,7.25,0,0,0,2.78-.19,15.54,15.54,0,0,1,3.33-.39,17,17,0,0,1,4.54.64,7.38,7.38,0,0,0,3.3.31,5.85,5.85,0,0,0,4.55-3,8.66,8.66,0,0,0-.18-7.47Z"/>
        </WrapIcon>
    );
});
