import styled from "styled-components";
import {PropsSWrapBadge, PropsSBadge} from "../types";

export const SWrapBadge = styled.span.attrs({className: 'SWrapBadge'})<PropsSWrapBadge>`
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    vertical-align: middle;
    ${props => !!props.margin && `margin: ${props.margin};`};
`

export const SBadge = styled.span.attrs({className: 'SBadge'})<PropsSBadge>`
    position: absolute;
    ${props => props.vertical === 'bottom' ? 'bottom: -2px;' : 'top: -2px;'};
    ${props => props.horizontal === 'left' ? 'left: -2px;' : 'right: -2px;'};
    min-width: 18px;
    height: 18px;
    border-radius: 9px;
    opacity: 0.95;
    background: ${props => !!props.bg ? props.theme.colors[props.bg] : props.theme.colors.offline};
    color: ${props => !!props.color ? props.theme.colors[props.color] : props.theme.colors.white};
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3px;
    flex-shrink: 0;
`
