import React from "react";
import { STag } from "./styledTag";

export interface PropsTag {
    children?: any,
    margin?: string
}

export default function Tag({children, margin}: PropsTag) {
    return (
        <STag children={children} margin={margin} />
    )
}
