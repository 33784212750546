import React, {useMemo, useState} from "react";
import {WrapTableRecommendedServices, ListTable, Buttons} from "./styledTableRecommendedServices";
import {OptionDoctor, PTableRecommendedServices, RecommendedGroups} from "./typesTableRecommendedServices";
import {useComponentDidMountTable} from "./helper/useComponentDidMountTable";
import HeaderTable from "./elements/HeaderTable";
import ItemTable from "./elements/ItemTable/ItemTable";
import {useSelector} from "react-redux";
import _ from 'lodash'
import Prompt from "../../../../../../../components-ui/DataDisplay/Prompt/Prompt";
import Button from "../../../../../../../components-ui/Inputs/Button/Button";


export default function TableRecommendedServices({recommendedServices, services, addToPlan, servicesGroups, setSelected}: PTableRecommendedServices) {
    const [recommendedGroups, setRecommendedGroups] = useState<RecommendedGroups[]>([]);
    const doctors = useSelector(state => state.common.doctors);
    useComponentDidMountTable({recommendedServices, services, servicesGroups, setRecommendedGroups});

    const optionsDoctors = useMemo(() => {
        return _.cloneDeep(doctors)
            .filter((doctor) => !doctor.deletedAt)
            .map((doctor) => {
            return {
                value: doctor.id,
                label: doctor.fio,
                color: doctor.color,
                specialities: doctor.specialities
            };
        });
    }, []) as OptionDoctor[];

    const handleServiceDoctor = (doctor: OptionDoctor, indexGroup: number, indexService: number) => {
        const newRecommendedGroup = _.cloneDeep(recommendedGroups);
        newRecommendedGroup[indexGroup].services[indexService].doctor = doctor;
        let doctorGroup = newRecommendedGroup[indexGroup].doctor;
        newRecommendedGroup[indexGroup].services.forEach(service => {
            if (service.doctor === null) {
                doctorGroup = null
            } else {
                if (service.doctor.value !== doctor.value) {
                    doctorGroup = null
                }
            }
        });
        if (!newRecommendedGroup[indexGroup].services.some(service => service.doctor?.value !== doctor.value)) {
            doctorGroup = doctor
        }
        newRecommendedGroup[indexGroup].doctor = doctorGroup;
        setRecommendedGroups(newRecommendedGroup);
    };

    const handleGroupDoctor = (doctor: OptionDoctor, indexGroup: number) => {
        const newRecommendedGroup = _.cloneDeep(recommendedGroups);
        newRecommendedGroup[indexGroup].doctor = doctor;
        newRecommendedGroup[indexGroup].services.forEach(service => {
            service.doctor = doctor
        })
        setRecommendedGroups(newRecommendedGroup);
    };

    const handleGroupActionsDoctor = (doctor: OptionDoctor, indexGroup: number, servicesIds: number[]) => {
        const newRecommendedGroup = _.cloneDeep(recommendedGroups);
        let doctorGroup = newRecommendedGroup[indexGroup].doctor;
        newRecommendedGroup[indexGroup].services.forEach(service => {
            if (servicesIds.indexOf(service.id) !== -1) {
                service.doctor = doctor
            }
            if (service.doctor === null) {
                doctorGroup = null
            } else {
                if (service.doctor.value !== doctor.value) {
                    doctorGroup = null
                }
            }
        })
        if (!newRecommendedGroup[indexGroup].services.some(service => service.doctor?.value !== doctor.value)) {
            doctorGroup = doctor
        }
        newRecommendedGroup[indexGroup].doctor = doctorGroup;
        setRecommendedGroups(newRecommendedGroup)
    }

    const handleCheckService = (checked: boolean, indexGroup: number, indexService: number) => {
        const newRecommendedGroup = _.cloneDeep(recommendedGroups);
        newRecommendedGroup[indexGroup].services[indexService].checked = checked;
        newRecommendedGroup[indexGroup].checked = !newRecommendedGroup[indexGroup].services.some(service => !service.checked);
        setRecommendedGroups(newRecommendedGroup);
    };

    const handleCheckGroup = (checked: boolean, indexGroup: number) => {
        const newRecommendedGroup = _.cloneDeep(recommendedGroups);
        newRecommendedGroup[indexGroup].checked = checked;
        newRecommendedGroup[indexGroup].services.forEach(service => {
            service.checked = checked;
        })
        setRecommendedGroups(newRecommendedGroup);
    };

    const validButton = () => {
        let valid = false;
        recommendedGroups.forEach(group => {
            group.services.forEach(service => {
                if (service.checked) valid = true;
            })
        });
        recommendedGroups.forEach(group => {
            group.services.forEach(service => {
                if (service.checked && service.doctor === null) valid = false;
            })
        })
        return valid;
    };

    const hintButton = () => {
        let hint = '';
        if (!validButton()) {
            hint = recommendedGroups.flatMap(group => group.services).some(service => service.checked) ?
                'Укажите врачей для всех выбранных услуг' : 'Выберите услуги'
        }
        return hint
    };

    const onClickButtonAddToPlan = () => {
        const allServices = recommendedGroups.flatMap(group => group.services);
        let newServices = {} as {[key: string] : number};
        allServices.forEach(service => {
            if (service.checked && service.doctor !== null) {
                newServices[service.id] = service.doctor.value;
            }
        });
        addToPlan(newServices);
        setSelected('all');
    };

    return (
        <>
            <WrapTableRecommendedServices>
                <HeaderTable/>
                <ListTable isRecommended={recommendedGroups.length > 0}>
                    {recommendedGroups.map((group, index) => {
                        return (
                            <ItemTable group={group}
                                       key={group.id}
                                       optionsDoctors={optionsDoctors}
                                       indexGroup={index}
                                       handleServiceDoctor={handleServiceDoctor}
                                       handleGroupDoctor={handleGroupDoctor}
                                       handleCheckService={handleCheckService}
                                       handleGroupActionsDoctor={handleGroupActionsDoctor}
                                       handleCheckGroup={handleCheckGroup}/>
                        )
                    })}
                </ListTable>
            </WrapTableRecommendedServices>
            <Buttons>
                <AddButton hintButton={hintButton} validButton={validButton}
                           onClickButtonAddToPlan={onClickButtonAddToPlan}/>
                <Button variant="default"
                        children="Все услуги"
                        onClick={() => setSelected('all')}/>
            </Buttons>
        </>
    )
}

const AddButton = ({hintButton, validButton, onClickButtonAddToPlan} : any) => {
    const [hover, setHover] = useState(false);
    return (
        <span onMouseLeave={() => setHover(false)}
              onMouseEnter={() => setHover(true)}>
            <Button variant={validButton() ? "success" : "disabled"}
                    children="Добавить в план"
                    onClick={onClickButtonAddToPlan}/>
            {!validButton() && hover && (
                <Prompt isTriangle={true} text={hintButton()} />
            )}
        </span>
    )
}


