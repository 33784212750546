import React, {useEffect, useState} from "react";
import SVG from "../../../../../../../common/helpers/SVG";
import Typography from "../../../../../../../components-ui/DataDisplay/Typography/Typography";
import Box from "../../../../../../../components-ui/Layout/Box";
import {StateCallCenter} from "../../../../../types/components";
import { STaskItem } from "../../../../../../../containers/Tasks/style";

interface Props {
    state: StateCallCenter
}

const CallDuration = ({state}: Props) => {
    const {detail, calls} = state
    const call = calls.find(_call => _call.contactPhone === detail?.phone);
    const initialTime = () => {
        if (!!call && !!call.answerTime) {
            return Math.round((new Date().getTime() - new Date(call.answerTime).getTime()) / 1000)
        } else {
            return 0
        }
    }
    const [time, setTime] = useState(initialTime())
    useEffect(() => {
        if (!!call && !!call.answerTime) {
            const timerId = setInterval(() => setTime(initialTime()), 1000);
            return () => clearInterval(timerId);
        }
    }, [JSON.stringify(calls)])
    if (!call) return null;
    const prepareTime = () => {
        if (+time > 60) {
            const min = Math.floor(+time/60);
            let sec = +time%60 as any;
            if (sec < 10) sec = '0' + sec;
            return `${min} мин. ${sec} сек.`
        } else {
            let sec = time as any;
            if (time < 10) sec = '0' + time;
            return `${sec} сек.`
        }
    }
    return (
        <STaskItem margin="6px 0 0" >
            <Box display="flex" alignItems="flex-start">
                <SVG name={`call-center/${call.incoming ? 'incoming' : 'outgoing'}`} className="icon-call-center" />
                <Typography variant="min" color="textGray" children={`${call.incoming ? 'Входящий' : 'Исходящий'}`} />
            </Box>
            {!!call.answerTime && (
                <Typography variant="min" lineHeight="1.33" children={prepareTime()} />
            )}
        </STaskItem>
    )
}

export default CallDuration
