import React from 'react';
import FilterWidgetWrap from "../../../../components/FilterWidgetWrap";
import {HandlersReceipts, StateReceipts} from "../types";
import Tabs from "../../../../../../components-ui/Navigation/Tabs/Tabs";
import {tabsReceipts} from "../data/tabsReceipts";
import Box from "../../../../../../components-ui/Layout/Box";
import {useSelector} from "react-redux";
import Button from "../../../../../../components-ui/Inputs/Button/Button";

interface Props {
    directionsCodes: string[]
    state: StateReceipts
    handlers: HandlersReceipts
}

const Filter = ({state, handlers, directionsCodes}: Props) => {
    const directions = useSelector(state1 => state1.common.directions)

    if (!!directionsCodes.length && !!state.currentTab && !!state.selectedDirection) {
        return (
            <FilterWidgetWrap>
                <Tabs tabs={tabsReceipts} activeTab={state.currentTab} onChange={handlers.handleCurrentTab} variant="buttons" margin="0 16px 0 0"/>
                <Box display="flex">
                    {directionsCodes.length > 1 && (
                        <Button children="все"
                                margin="0 0 0 8px"
                                fontSize="14px"
                                height="32px"
                                noShadow={true}
                                variant={state.selectedDirection === 'all' ? 'info' : 'standard'}
                                onClick={() => handlers.handleSelectedDirection('all')}
                        />
                    )}
                    {directionsCodes.map(code => {
                        return (
                            <Button key={code}
                                    children={directions[code].name.toLowerCase()}
                                    margin="0 0 0 8px"
                                    fontSize="14px"
                                    height="32px"
                                    noShadow={true}
                                    variant={state.selectedDirection === code ? 'info' : 'standard'}
                                    onClick={() => handlers.handleSelectedDirection(code)}
                            />
                        )
                    })}
                </Box>
            </FilterWidgetWrap>
        );
    } else {
        return null
    }


};

export default Filter;
