import React from 'react';
import TaskContent from "../TaskContent";
import {TaskCallCenter} from "../../../../types/common";
import {ScheduleChangeData} from "../../../../types/taskData";
import {Doctor, UniquePatient, VisitDate, VisitTime} from "../../../../components/Labels";
import {AddWLButton, FinalButton, NoteDownButton} from "../../../../components/Buttons";
import prepareIsDeadline from "../../../../helper/prepareIsDeadline";
import {CommonTaskProps} from "../../types/common";
import Helper from "../../../../../../common/helpers/main";
import {useDateUpdate} from "../../../../../../common/hooks";
import VisitBranch from "../../../../components/Labels/VisitBranch";

interface Props extends CommonTaskProps {
    task: TaskCallCenter<ScheduleChangeData>
}

export const ScheduleChange = ({task, uniqPatient, allDeadline, onChangeSelectedScript, ...props}: Props) => {
    const currentDate = Helper.Date.formatDate(useDateUpdate() as any);
    return (
        <TaskContent onChangeSelectedScript={onChangeSelectedScript} buttons={<Buttons task={task} {...props}/>}
                     task={task}
                     isDeadline={prepareIsDeadline(allDeadline, task, currentDate)}>
            <UniquePatient task={task} uniqPatient={uniqPatient}/>
            <VisitBranch branchId={task.payload.branchId}/>
            <VisitDate timeStart={task.payload.timeStart}/>
            <VisitTime timeStart={task.payload.timeStart} timeEnd={task.payload.timeEnd}/>
            <Doctor doctorId={task.payload.doctorId}/>
        </TaskContent>
    );
};

const Buttons = ({
                     task,
                     validate,
                     blockedButtons,
                     personalPhone,
                     methodsCallCenter
                 }: Omit<Props, 'uniqPatient' | 'allDeadline' | 'onChangeSelectedScript'>) => {
    return (
        <>
            <NoteDownButton validate={validate} task={task} blockedButtons={blockedButtons}
                            methodsCallCenter={methodsCallCenter}/>
            <AddWLButton task={task} personalPhone={personalPhone} blockedButtons={blockedButtons}
                         methodsCallCenter={methodsCallCenter}/>
            <FinalButton task={task} blockedButtons={blockedButtons} methodsCallCenter={methodsCallCenter}/>
        </>
    )
}
