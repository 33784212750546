import React from 'react';
import useLaboratoryDoneServicesPage from './hooks/useLaboratoryDoneServicesPage';
import Typography from '../../components-ui/DataDisplay/Typography/Typography';
import Box from '../../components-ui/Layout/Box';
import LaboratoryDoneServicesFilter from './components/Filter';
import LoaderPage from '../../components/LoaderPage';
import DoneEmployeeServicesTable from './components/DoneEmployeeServicesTable';
import DoneLaboratoryServicesTable from './components/DoneLaboratoryServicesTable';

const LaboratoryDoneServicesPage = () => {

    const {
        optionsBranches,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        loaderPage,
        loaderTable,
        selectedBranch,
        laboratories,
        doctor,
        setDoctor,
        technic,
        setTechnic,
        laboratory,
        setLaboratory,
        doneServicesResponse,
        optionsFilteredTechnic,
        optionsFilteredLaboratories,
        optionsFilteredDoctors,
        onChangeSelectedBranch,
        refreshDataFromServer
    } = useLaboratoryDoneServicesPage()

    return (
        <Box padding="62px 20px 80px 28px">
            <Typography variant="title" fontWeight="bold">Выполненные услуги</Typography>

            <LaboratoryDoneServicesFilter selectedBranch={selectedBranch}
                                          optionsBranches={optionsBranches}
                                          startDate={startDate}
                                          setStartDate={setStartDate}
                                          endDate={endDate}
                                          setEndDate={setEndDate}
                                          doctor={doctor}
                                          setDoctor={setDoctor}
                                          technic={technic}
                                          setTechnic={setTechnic}
                                          laboratory={laboratory}
                                          setLaboratory={setLaboratory}
                                          optionsFilteredTechnic={optionsFilteredTechnic}
                                          optionsFilteredLaboratories={optionsFilteredLaboratories}
                                          optionsFilteredDoctors={optionsFilteredDoctors}
                                          onChangeSelectedBranch={onChangeSelectedBranch}
            />
            {!loaderPage ?
                <Box display="flex" flexDirection="column" gap="48px" padding="24px 0 0 0">
                    {optionsFilteredTechnic.length > 1 &&
                        <DoneEmployeeServicesTable
                            doneEmployeeServices={doneServicesResponse.doneEmployeeServices}
                            services={doneServicesResponse.services}
                            loaderTable={loaderTable}
                            refreshDataFromServer={refreshDataFromServer}
                            selectedBranch={selectedBranch}
                        />
                    }
                    {optionsFilteredLaboratories.length > 1 &&
                        <DoneLaboratoryServicesTable
                            doneLaboratoryServices={doneServicesResponse.doneLaboratoryServices}
                            services={doneServicesResponse.services}
                            laboratories={laboratories}
                            loaderTable={loaderTable}
                            refreshDataFromServer={refreshDataFromServer}
                            selectedBranch={selectedBranch}
                        />
                    }
                </Box>
                :
                <LoaderPage height={338}/>
            }
        </Box>
    );
};

export default LaboratoryDoneServicesPage;
