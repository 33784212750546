import React, {forwardRef} from 'react';
import _ from 'lodash'

interface Props {
    myClassName: string
    timeHighlight: any
    timeLine: any
}

const TimeLine = forwardRef((props: Props, ref: any) => {
    const {timeHighlight, myClassName, timeLine} = props;

    return (
        <div className={'dayCalendar_tl ' + myClassName} ref={ref}>
            {_.map(timeLine, (timeLineItem: any, time: any) => {
                let style: any = {};
                let styleTime: any = {
                    display: 'block',
                    textDecoration: 'none',
                };

                if (timeLineItem.height !== null) {
                    style.height = timeLineItem.height;
                }

                if (timeHighlight.timeStart !== null) {
                    if (timeHighlight.timeStart <= time && timeHighlight.timeEnd > time) {
                        styleTime.textDecoration = 'underline';
                        styleTime.fontWeight = 'bold';
                    }
                }
                return (
                    <div className={'dayCalendar_timeItem ' + (timeLine[time].type === 'half' ? 'littleTI' : '')}
                         key={time}
                         style={style}>
                        <span style={styleTime}>{time}</span>
                    </div>
                )
            })}
        </div>
    );
});

export default TimeLine;