import * as React from "react";
import {Service} from "../../types";
import {ModalTitle} from "../../../../components-ui/styledComponent/common/typography";
import {useSelector} from "react-redux";
import {canPerformService, withoutTrashed} from "../../../../common/helpers/Doctor";
import {ServiceDesc, DoctorDesc, CheckedWrapModal, CheckedNameModal} from "../styled";
import Checkbox from "../../../../components-ui/Inputs/Checkbox/Checkbox";
import {useEffect, useState} from "react";
import Select from "../../../../components-ui/Inputs/Select/Select";
import Button from "../../../../components-ui/Inputs/Button/Button";
import Box from "../../../../components-ui/Layout/Box";
import {useModal} from "../../../DialogsManager/containers/Modal/Modal";
import {User} from "../../../../types";
import _ from 'lodash'

interface PModalChooseServiceDoctor {
    service: Service,
    selectedDoctorId: number,
    chooseDoctor: (doctorId: number) => void,
}

export default function ModalChooseServiceDoctor({service, selectedDoctorId, chooseDoctor}: PModalChooseServiceDoctor) {
    const [checked, setChecked] = useState(true);
    const {closeModal} = useModal();
    const currentDoctor = useSelector(state => state.user);
    const doctors = withoutTrashed(_.cloneDeep(useSelector(state => state.common.doctors)));
    const selectedDoctor = doctors.find(doc => doc.id === selectedDoctorId) as User;
    const [valueDoctor, setValueDoctor] = useState<any>(null);

    useEffect(() => {
        setValueDoctor(null);
    }, [checked])

    return (
        <Box width="520px" padding="45px 30px">
            <ModalTitle children="Добавление услуги"/>
            <ServiceDesc children={service.name}/>
            <DoctorDesc children={<PrepareDescDoctor currentDoctor={currentDoctor} selectedDoctor={selectedDoctor}/>}/>
            <CheckedWrapModal>
                <CheckedNameModal children="Врач"/>
                <Checkbox label="подходит по специальности"
                          checked={checked}
                          onChange={setChecked}/>
            </CheckedWrapModal>
            <Select options={prepareOptionsDoctors(doctors, service, checked)}
                    variant="colored"
                    widthSelect="334px"
                    placeholder="не выбран"
                    marginSelect="0 0 32px"
                    value={valueDoctor}
                    onChange={setValueDoctor}
            />
            <Button children="Добавить"
                    variant={valueDoctor !== null ? "success" : "disabled"}
                    size="long"
                    onClick={() => {
                        chooseDoctor(valueDoctor.value);
                        closeModal();
                    }}/>
        </Box>
    );
}

const PrepareDescDoctor = ({currentDoctor, selectedDoctor}: { currentDoctor: User, selectedDoctor: User }) => {
    if (currentDoctor.id !== selectedDoctor.id) {
        return <>
            <span>Услуга не относится к специализации врача </span>
            <b>{selectedDoctor.fio}</b>
            <span> Выберите, кому из врачей нужно добавить её в план.</span>
        </>
    }

    return <>
        <span>Услуга не относится к вашей специализации. Выберите, кому из врачей нужно добавить её в план.</span>
    </>;
}

const prepareOptionsDoctors = (doctors: User[], service: Service, checked: boolean) => {
    if (checked) doctors = doctors.filter(doctor => canPerformService(doctor, service));
    return doctors.map(doctor => {
        return {
            value: doctor.id,
            label: doctor.fio,
            color: doctor.color
        }
    })
}
