import React, {Dispatch, SetStateAction} from 'react';
import {ScriptType} from "../../../types/common";
import Box from "../../../../../components-ui/Layout/Box";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import Scrollbar from "../../../../../components-ui/DataDisplay/Scrollbar/Scrollbar";
import {SBoxScript} from "../style";
import Button from "../../../../../components-ui/Inputs/Button/Button";
import Icon from '../../../../../components-ui/DataDisplay/Icon';
import {useDialogsManager} from '../../../../../containers/DialogsManager/DialogsManager';
import DeleteScript from './EditScript/modals/DeleteScript';
import {useSelector} from "react-redux";

interface Props {
    selectedScript: ScriptType | null
    setIsEditing: Dispatch<SetStateAction<boolean>>
    refreshDataFromServer: () => void;
}

const SelectedScript = ({selectedScript, setIsEditing, refreshDataFromServer}: Props) => {

    const {showModal} = useDialogsManager();

    const user = useSelector(state => state.user);

    return (
        <Box width="520px" backgroundColor="white" padding="18px 18px 58px 32px">
            {!!selectedScript && (
                <>
                    {user.canEditCallScript && (
                          <Box
                              display="flex"
                              justifyContent="flex-end"
                              margin="0 0 15px 0"
                              gap="15px"
                          >
                              <Button variant="default"
                                      size="sm"
                                      startIcon={<Box margin="0 7px 0 0"><Icon name="edit"/></Box>}
                                      children="Изменить"
                                      onClick={() => {setIsEditing(true)}}
                              />
                              {selectedScript &&  <Button variant="default"
                                                          size="sm"
                                                          startIcon={<Box margin="0 7px 0 0"><Icon name="close"/></Box>}
                                                          children="Удалить"
                                                          onClick={() => showModal(<DeleteScript refreshDataFromServer={refreshDataFromServer} id={selectedScript.id}/>)}
                              />}
                          </Box>
                    )}
                    <Typography children={selectedScript.name} variant="small-title" margin="0 0 14px"/>
                    <Scrollbar height="545px">
                        <SBoxScript dangerouslySetInnerHTML={{__html: selectedScript.text}}/>
                    </Scrollbar>
                </>
            )}
        </Box>
    )
}

export default SelectedScript
