import {useSelector} from "react-redux";
import {useMemo} from "react";
import {OptionSelect} from "../../../../../components-ui/Inputs/Select/typesSelect";

export const useOptionsBranches = (): OptionSelect[] => {
    const branches = useSelector(state => state.common.branches)
    return useMemo(() => {
        return branches.map(branch => ({
            value: branch.id,
            label: branch.name
        }))
    }, [])
}
