import {Dispatch, SetStateAction} from "react";
import {StateManagerTasks} from "../types/components";
import {OptionSelect} from "../../../components-ui/Inputs/Select/typesSelect";

interface Props {
    setState: Dispatch<SetStateAction<StateManagerTasks>>
}

const useHandlersState = ({setState}: Props) => {
    const handleHeightFilter = (heightFilter: number) => setState(prevState => ({...prevState, heightFilter}))

    const handleSelectedFilter = (selectedFilter: string) => setState(prevState => ({...prevState, selectedFilter}))

    const handleSelectedUser = (selectedUser: OptionSelect) => setState(prevState => ({...prevState, selectedUser}))

    const handleBranches = (values: OptionSelect[]) => setState(prevState => ({
        ...prevState,
        branches: !!values && !!values.length ? values.map((value: OptionSelect) => +value.value) : []
    }))

    return {
        handleHeightFilter,
        handleSelectedFilter,
        handleSelectedUser,
        handleBranches
    }
}

export default useHandlersState
