import {useMemo} from "react";
import {InfoManagerTasks, StateManagerTasks} from "../types/components";
import useBranchesManagerTasks from "./useBranchesManagerTasks";

interface Props {
    state: StateManagerTasks
    infoManagerTasks: InfoManagerTasks
}

const useNoticeQuantity = ({state, infoManagerTasks}: Props) => {

    const branchesTasks = useBranchesManagerTasks({state})

    return useMemo(() => {
        const noticesQuantity = {
            all: 0,
        } as {[key: string]: number};

        if (!!Object.keys(infoManagerTasks.types).length) {
            branchesTasks.forEach(task => {
                if (noticesQuantity[task.type] === undefined) noticesQuantity[task.type] = 0;
                if (!task.postponedTo) {
                    noticesQuantity.all += 1;
                    noticesQuantity[task.type] += 1;
                }
            })
        }
        return noticesQuantity;
    }, [JSON.stringify(branchesTasks), infoManagerTasks.types])

}

export default useNoticeQuantity
