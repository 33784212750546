import styled from "styled-components";
import {Button, WrapButton} from "../../styledComponents";
import {PGumDiseaseItem, PToothButton} from "./typesToothItem";
import Helper from "../../../../common/helpers/main";

export const ToothWrap = styled(WrapButton)`
    margin-left: 6px;
    position: relative;
    .tooth-standard {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: ${props => props.jaw === 'upper' ? '7px' : '0'};
        margin-top: ${props => props.jaw === 'upper' ? '0' : '7px'};
        stroke-width: 1px;
        stroke: ${props => props.theme.colors.svgGray};
        fill: ${props => props.theme.colors.svgGray};
        cursor: ${props => props.editField ? 'default' : 'pointer'};
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }
    }
    .tooth-check {
        width: 10px;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: ${props => props.jaw === 'upper' ? '7px' : '0'};
        margin-top: ${props => props.jaw === 'upper' ? '0' : '7px'};
        fill: ${props => props.theme.colors.primary};
        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }
    }
`;

const backgroundButton = (props: PToothButton) => {
    const {status, theme, selected, disabled, wisdom} = props;
    if (disabled) {
        return theme?.colors.bgButtonGray
    } else {
        switch (status) {
            case "CURED":
                return selected ? theme?.colors.purple : `rgba(${Helper.Color.hexToRgb(theme?.colors.purple)}, 0.2)`
            case "SICK":
                return selected ? theme?.colors.danger :`rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.2)`
            case "MISSING":
                if (wisdom) {
                    return selected ? theme?.colors.info : theme?.colors.bgButtonGray
                } else {
                    return selected ? theme?.colors.danger :`rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.2)`
                }
            case "HEALTHY":
            default:
                return selected ? theme?.colors.info : theme?.colors.bgButtonGray
        }
    }

};

const focusBackgroundButton = (props: PToothButton) => {
    const {status, theme, selected, disabled, wisdom} = props;
    if (disabled) {
        return theme?.gradient.defaultHover
    } else  {
        switch (status) {
            case "CURED":
                return selected ? theme?.colors.purple : `rgba(${Helper.Color.hexToRgb(theme?.colors.purple)}, 0.4)`
            case "SICK":
                return selected ? theme?.colors.danger :`rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.4)`
            case "MISSING":
                if (wisdom) {
                    return selected ? theme?.colors.info : theme?.gradient.defaultHover
                } else {
                    return selected ? theme?.colors.danger :`rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.4)`
                }
            case "HEALTHY":
            default:
                return selected ? theme?.colors.info : theme?.gradient.defaultHover
        }
    }

}

const hoverBackgroundButton = (props: PToothButton) => {
    const {status, theme, selected, disabled, wisdom} = props;
    if (disabled) {
        return theme?.gradient.defaultHover
    } else {
        switch (status) {
            case "CURED":
                return selected ? theme?.colors.purple : `rgba(${Helper.Color.hexToRgb(theme?.colors.purple)}, 0.3)`
            case "SICK":
                return selected ? theme?.colors.danger :`rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.3)`
            case "MISSING":
                if (wisdom) {
                    return selected ? theme?.colors.info : theme?.gradient.defaultHover
                } else {
                    return selected ? theme?.colors.danger :`rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.3)`
                }
            case "HEALTHY":
            default:
                return selected ? theme?.colors.info : theme?.gradient.defaultHover
        }
    }

}

const activeBackgroundButton = (props: PToothButton) => {
    const {status, theme, selected, disabled, wisdom} = props;
    if (disabled) return theme?.colors.whiteDark
    switch (status) {
        case "CURED":
            return selected ? theme?.colors.purple : `rgba(${Helper.Color.hexToRgb(theme?.colors.purple)}, 0.1)`
        case "SICK":
            return selected ? theme?.colors.danger :`rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.1)`
        case "MISSING":
            if (wisdom) {
                return selected ? theme?.colors.info : theme?.colors.whiteDark
            } else {
                return selected ? theme?.colors.danger :`rgba(${Helper.Color.hexToRgb(theme?.colors.danger)}, 0.1)`
            }
        case "HEALTHY":
        default:
            return selected ? theme?.colors.info : theme?.colors.whiteDark
    }
}

const borderColorButton = (props: PToothButton) => {
    const {theme, status, disabled, unconfirmed, wisdom} = props;
    if (unconfirmed) return theme?.colors.purple;
    if (disabled) return theme?.colors.svgGray;
    switch (status) {
        case "CURED":
            return theme?.colors.purple
        case "SICK":
            return theme?.colors.danger
        case "MISSING":
            return wisdom ? theme?.colors.svgGray : theme?.colors.danger
        case "HEALTHY":
        default:
            return theme?.colors.svgGray
    }
}

const focusBorderColorButton = (props: PToothButton) => {
    const {theme, status, disabled, unconfirmed, wisdom} = props;
    if (unconfirmed) return theme?.colors.purple;
    if (disabled) return theme?.colors.borderGrayActive
    switch (status) {
        case "CURED":
            return theme?.colors.purple
        case "SICK":
            return theme?.colors.danger
        case "MISSING":
            return wisdom ? theme?.colors.borderGrayActive : theme?.colors.danger
        case "HEALTHY":
        default:
            return theme?.colors.borderGrayActive
    }
}

const hoverBorderColorButton = (props: PToothButton) => {
    const {theme, status, disabled, unconfirmed, wisdom} = props;
    if (unconfirmed) return theme?.colors.purple;
    if (disabled) return theme?.colors.borderGrayActive
    switch (status) {
        case "CURED":
            return theme?.colors.purple
        case "SICK":
            return theme?.colors.danger
        case "MISSING":
            return wisdom ? theme?.colors.borderGrayActive : theme?.colors.danger
        case "HEALTHY":
        default:
            return theme?.colors.borderGrayActive
    }
}

const activeBorderColorButton = (props: PToothButton) => {
    const {theme, status, disabled, wisdom} = props;
    if (disabled) return theme?.colors.borderGrayActive
    switch (status) {
        case "CURED":
            return theme?.colors.purple
        case "SICK":
            return theme?.colors.danger
        case "MISSING":
            return wisdom ? theme?.colors.borderGrayActive : theme?.colors.danger
        case "HEALTHY":
        default:
            return theme?.colors.borderGrayActive
    }

}

const colorTextButton = (props: PToothButton) => {
    const {theme, status, selected, disabled, wisdom} = props;
    if (disabled) return theme?.colors.text
    if (selected) {
        return theme?.colors.white
    } else {
        switch (status) {
            case "CURED":
                return theme?.colors.purple
            case "SICK":
                return theme?.colors.danger
            case "MISSING":
                return wisdom ? theme?.colors.text : theme?.colors.danger
            case "HEALTHY":
            default:
                return theme?.colors.text
        }
    }
}

const colorSvgTooth = (props: PToothButton) => {
    const {theme, status, selected, disabled, wisdom} = props;
    if (disabled) return theme?.colors.svgGray
    if (selected) {
        return theme?.colors.white
    } else {
        switch (status) {
            case "CURED":
                return theme?.colors.purple
            case "SICK":
                return theme?.colors.danger
            case "MISSING":
                return wisdom ? theme?.colors.svgGray : theme?.colors.danger
            case "HEALTHY":
            default:
                return theme?.colors.svgGray
        }
    }
}

export const ButtonTooth = styled(Button)<PToothButton>`
    border-color: ${borderColorButton};
    border-width: ${props => props.unconfirmed ? '2px' : '1px'};
    background: ${backgroundButton};
    box-shadow: none;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    position: relative;
    box-sizing: border-box;
    &:focus {
        background: ${focusBackgroundButton};
        border-color: ${focusBorderColorButton};
        box-shadow: ${props => props.theme?.shadow.default};
    }
    &:hover {
        background: ${hoverBackgroundButton};
        border-color: ${hoverBorderColorButton};
        box-shadow: ${props => props.theme?.shadow.default};
    }
    &:active {
        background: ${activeBackgroundButton};
        border-color: ${activeBorderColorButton};
        box-shadow: ${props => props.theme?.shadow.defaultActive};
    }

    .tooth-card-svg {
        opacity: ${props => props.disabled ? 0 : 1};
        fill: ${colorSvgTooth};
        height: 56px;

        ${props => props.status === "MISSING" && `
            width: 100%;
            display: flex;
            align-items: ${props.jaw === 'lower' ? 'flex-end' : 'flex-start'};
            justify-content: center;
            padding: 10px 0;
            & > div {
                display: flex;
                width: 15px;
                height: 15px;
                justify-content: center;
                align-items: center;
                & > svg {
                    width: 100%;
                    height: 100%;
                }
            }
        `}
    }

    .tooth-card-text {
        opacity: ${props => props.disabled ? 0 : 1};
        color: ${colorTextButton};
    };

`

export const GumDiseaseWrap = styled.div.attrs({className: 'GumDiseaseWrap'})`
    position: absolute;
    top: 50%;
    left: -1px;
    transform: translateY(-50%);
    width: calc(100% + 2px);
    height: max-content;
    z-index: 1;
`;

const colorGumDiseaseItem = (props: PGumDiseaseItem) => {
    const {theme, selected} = props;
    if (selected) return theme?.colors.white;
    return theme?.colors.danger
}

export const GumDiseaseItem = styled.div.attrs({className: 'GumDiseaseItem'})<PGumDiseaseItem>`
    height: 2px;
    width: 100%;
    background-color: ${colorGumDiseaseItem};
    margin: 2px 0;
`
