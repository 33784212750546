import {Props} from "../types";

export const getColorStyle = (props: Props) => {
    const {color, currentAs, theme} = props;
    if (!!color) {
        return theme?.colors[color]
    } else {
        switch (currentAs) {
            case "span":
                return 'inherit'
            default:
                return theme?.colors.text
        }
    }
}
