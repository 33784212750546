import React, {forwardRef} from 'react';
import {WrapIcon} from "../../../containers/WrapIcon";
import {IIcon} from "../../../types/common";

export const NotifyOldIcon = forwardRef(({viewBox, ...props}: IIcon, ref) => {
    return (
        <WrapIcon {...props} viewBox={viewBox || "0 0 19.2 24"} ref={ref}>
            <path d="M9.6,2A7,7,0,0,1,16.44,9.2h0v3a5.74,5.74,0,0,0,2.18,4.53h0l.28.21v4.31H12.33A2.8,2.8,0,0,1,9.6,24a2.8,2.8,0,0,1-2.73-2.76H.3V16.93l.28-.21a5.74,5.74,0,0,0,2.18-4.53h0v-3A7,7,0,0,1,9.6,2ZM11,21.24H8.21a1.39,1.39,0,0,0,2.78,0ZM17.55,18H1.65v1.85h15.9ZM9.6,3.44A5.64,5.64,0,0,0,4.11,9.2h0v3a7.28,7.28,0,0,1-1.48,4.39H16.57a7.28,7.28,0,0,1-1.48-4.39h0v-3A5.64,5.64,0,0,0,9.6,3.44ZM5.71,0l.54,1.29A8.68,8.68,0,0,0,1.34,9.2H0A10.11,10.11,0,0,1,5.71,0Zm7.78,0A10.11,10.11,0,0,1,19.2,9.2H17.86A8.68,8.68,0,0,0,13,1.29h0Z"/>
        </WrapIcon>
    );
});
