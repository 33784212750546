import {GetPatientPaymentsResult} from "../types";
import {IColumn} from "../../../../../components-ui/DataDisplay/Table";
import React, {useEffect, useState} from "react";
import DateHelper from "../../../../../common/helpers/date-helper";
import {useSelector} from "react-redux";
import Typography from "../../../../../components-ui/DataDisplay/Typography/Typography";
import Tooltip from "../../../../../components-ui/DataDisplay/Tooltip/Tooltip";
import {Payment, PaymentImport} from "../../../../CheckPayments/types";
import Box from "../../../../../components-ui/Layout/Box";
import Invoice from "../../Invoice/Invoice";
import {useDialogsManager} from "../../../../../containers/DialogsManager/DialogsManager";

interface Props {
    data: GetPatientPaymentsResult
}

interface IPaymentRow extends Omit<Payment, 'patientId' | 'managementEndDate' | 'doctors' | 'mode'> {
}
interface IPaymentImportRow extends Omit<PaymentImport, 'patientId'> {
}


const useTableFormatter = ({data}: Props) => {

    const paymentMethods = useSelector(state => state.common.paymentMethods)

    const discountTypes = useSelector(state => state.common.discountTypes)

    const {showSlideModal} = useDialogsManager()

    const [paymentRows, setPaymentRows] = useState<IPaymentRow[]>([])

    const [paymentImportRows, setPaymentImportRows] = useState<IPaymentImportRow[]>([])

    const paymentColumns: IColumn<IPaymentRow>[] = [
        {
            headerName: 'Дата',
            field: 'date',
            width: '132px'
        },
        {
            headerName: 'Способ оплаты',
            field: 'method',
            width: '322px',
            valueFormatter: (({row}) =>
                    <>
                        <Typography
                            isPartHidden={true}
                            data-for={row.method}
                            data-tip={row.method}>
                            {row.method}
                        </Typography>
                        <Tooltip id={row.method} effect="solid"/>
                    </>
            )
        },
        {
            headerName: 'Счёт',
            field: 'invoices',
            valueFormatter: (({row}) =>
                    <>
                        {row.invoices.map(invoice =>
                            <Box margin="0 0 5px 0"
                                 onClick={() => showSlideModal(
                                     <Invoice
                                         invoiceId={invoice}
                                         servicesGroups={data.servicesGroups}
                                     />
                                 )}
                            >
                                <Typography cursor="pointer" color="info">Счёт №{invoice}</Typography>
                            </Box>
                        )}
                    </>
            )
        },
        {
            headerName: 'Тип скидки',
            field: 'discountType'
        },
        {
            headerName: 'Скидка',
            field: 'discountSum',
            type: 'price',
            countTotal: true

        },
        {
            headerName: 'Оплата по ДМС',
            field: 'insuranceSum',
            type: 'price',
            countTotal: true

        },
        {
            headerName: 'Сумма оплаты',
            field: 'sum',
            type: 'price',
            countTotal: true

        },
    ]

    const paymentImportColumns: IColumn<IPaymentImportRow>[] = [
        {
            headerName: 'Дата',
            field: 'date',
        },
        {
            headerName: 'Сумма оплаты',
            field: 'sum',
            type: 'price',
            countTotal: true

        },
    ]

    useEffect(() => {
        setPaymentRows(data.payments.map(payment => {
            return ({
                id: payment.id,
                date: DateHelper.formatDate(payment.date, 'DD MMM YYYY'),
                method: paymentMethods[payment.method],
                invoices: payment.invoices,
                checks: payment.checks,
                discountType: payment.discountType && discountTypes[payment.discountType],
                discountSum: payment.discountSum,
                insuranceSum: payment.insuranceSum,
                sum: payment.sum
            })
        }))
    }, [data.payments])

    useEffect(() => {
        setPaymentImportRows(data.paymentImports.map(payment => {
            return ({
                id: payment.id,
                date: DateHelper.formatDate(payment.date, 'DD MMM YYYY'),
                sum: payment.sum
            })
        }))
    }, [data.paymentImports])

    return {paymentColumns, paymentRows, paymentImportColumns, paymentImportRows}
};

export default useTableFormatter;
