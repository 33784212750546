import Target from "./Target";
import Helper from "../../../common/helpers/main";
import TargetFactory from "./TargetFactory";
import Measure from "../../../common/Service/Measure";

export default class Segment extends Target {
    matchTeeth(teethNumbers) {
        return Helper.isEqual(teethNumbers, this.targetUnits) ? teethNumbers : false
    };

    getProcedureTargets(procedureMeasureType, teethMap) {
        let procedureTargets;
        let procedureMeasure = new Measure(procedureMeasureType);

        switch (procedureMeasureType) {
            case 'TOOTH':
                /* применяем процедуру к каждому зубу из сегмента */
                procedureTargets = this.targetUnits.map(toothNumber => TargetFactory.create(toothNumber, procedureMeasureType));
                break;

            case 'JAW':
                /* применяем услугу к тем челюстям, зубы из которых включены в состав сегмента */
                let jawNames = this.targetUnits.map(toothNumber => Helper.TeethMap.getJawName(toothNumber));
                procedureTargets = Helper.Array.unique(jawNames).map(jawName => TargetFactory.create(jawName, procedureMeasureType));
                break;

            case 'SEGMENT':
                procedureTargets = [
                    TargetFactory.create(this.targetUnits, procedureMeasureType)
                ];
                break;

            default:
                /* остальные единицы измерения не зависят от переданного зуба */
                procedureTargets = [
                    TargetFactory.create(procedureMeasure.getTargetUnitByTooth(null), procedureMeasureType)
                ];
                break;
        }

        return procedureTargets;
    }
}