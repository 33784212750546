import {Dispatch, SetStateAction, useState} from 'react';
import {OptionSelect} from "../../../../../../../../components-ui/Inputs/Select/typesSelect";
import {SaveTimesheetParams} from '../../../../../../types';
import ServerCommand from '../../../../../../../../common/server/server-command';
import {useModal} from '../../../../../../../../containers/DialogsManager/containers/Modal/Modal';
import {IStateTimesheetModal} from '../../../types';

interface Props {
    state: IStateTimesheetModal
    setState: Dispatch<SetStateAction<IStateTimesheetModal>>
    selectedBranchId: number
    isComing: boolean
}

const useTimesheetModalEmployee = ({selectedBranchId, state, setState, isComing}: Props) => {

    const {closeModal} = useModal()

    const [validateMark, setValidateMark] = useState(false)

    const handleEmployee = (employee: OptionSelect) => {
        setState(prevState => {
            return {...prevState, employee}
        })
        setValidateMark(false)
    }

    const handleComment = (val: string) => {
        setState(prevState => {
            return {...prevState, comment: val}
        })
    }

    const handleTime = (newTime: string) => {
        setState(prevState => {
            return {...prevState, time: newTime}
        })
    }

    const isComingKeyTime = () => {
        return isComing ? 'startTime' : 'endTime'
    }

    const postData = {
        employeeId: state.employee?.value,
        [isComingKeyTime()]: state.time,
        [`${isComingKeyTime()}Comment`]: state.comment,
        branchId: selectedBranchId
    } as SaveTimesheetParams

    const saveEmployee = () => {
        closeModal()
        ServerCommand.post(isComing ? 'timesheet-record/save' : 'timesheet-record/save-end-time', postData).then()
    }

    return {handleEmployee, handleComment, handleTime, validateMark, setValidateMark, saveEmployee}
};

export default useTimesheetModalEmployee;