import styled from "styled-components";

interface ISelectLabel {
    widthLabel?: number
}
export const SelectLabel = styled.div.attrs({className: 'SelectLabel'})<ISelectLabel>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 23px;
    width: max-content;
    position: relative;
    user-select: none;
    :last-child {
        margin-bottom: 0;
    }
    
    span {
        width: ${props => !!props.widthLabel ? props.widthLabel + 'px' : '100px'};
        word-break: break-word;
        margin-right: 25px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: ${props => props.theme.colors.text};
    }
    input {
        width: 250px;
        height: 40px;
        border-radius: 3px;
        border: solid 1px #d5dae3;
        background-image: linear-gradient(to bottom, #ffffff, #f2f4f9);
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        user-select: none;
        letter-spacing: normal;
        color: ${props => props.theme.colors.text};
        padding: 6px 12px 10px 16px;
        cursor: pointer;
        outline: none;
        :focus {
          box-shadow: none;
          border: solid 1px #d5dae3;
        }
    }
    select {
        width: 250px;
        height: 40px;
        border-radius: 3px;
        border: solid 1px #d5dae3;
        background-image: linear-gradient(to bottom, #ffffff, #f2f4f9);
    }
    .new-arrow-select {
        position: absolute;
        bottom: 17px;
        right: 12px;
    }
    .react-datepicker-popper {
        max-width: 250px;
    }
`;

export const ButtonLink = styled.button.attrs({type: "button"})`
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${props => props.theme.colors.info};
    cursor: pointer;
    width: 100%;
    height: 100%;
    text-align: left;
    outline: none;
    :hover {
        color: ${props => props.theme.colors.danger};
    }
    :focus {
        color: ${props => props.theme.colors.danger};
    }
`;

interface IDashedButtonLink {
    minSize?: boolean
}

export const DashedButtonLink = styled(ButtonLink)<IDashedButtonLink>`
    &.history {
        transform: translateY(-14px);
        padding: 0;
        margin-bottom: 10px;
    }
    & > span {
        color: ${props => props.theme.colors.textGray};
        border-bottom: 1px dashed ${props => props.theme.colors.textGray};
        transition: .3s;
        font-size: ${props => props.minSize ? '14px' : '16px'};
    }
    :hover > span {
        color: ${props => props.theme.colors.info};
        border-bottom-color: ${props => props.theme.colors.info};
    }
    &.active > span {
        color: ${props => props.theme.colors.info};
        border-bottom-color: ${props => props.theme.colors.info};
    }
`;

interface IPopupWrap {
    absolute?: boolean
}

export const PopupWrap = styled.div.attrs({className: 'PopupWrap'})<IPopupWrap>`
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 3px 23px 0 rgba(49, 58, 69, 0.2);
    border: solid 1px #ffffff;
    background-color: #ffffff;
    padding: 30px 24px 14px;
    top: 21px;
    left: 0;
    position: ${props => props.absolute ? 'absolute' : 'relative'};
    z-index: 11;
`;

export const PopupTitle = styled.h4.attrs({className: 'PopupTitle'})`
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: ${props => props.theme.colors.text};
    margin: 0 0 12px;
`;

export const PopupDesc = styled.p.attrs({className: 'PopupDesc'})`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin: 0 0 20px;
    color: ${props => props.theme.colors.text};
`;

export const MainContent = styled.div.attrs({className: 'MainContent'})`
    flex-grow: 1;
    width: 100%;
    padding-left: 64px;
    background: #f2f4f9;
    height: max-content;
    min-height: 100%;
`;
