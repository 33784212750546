import React, {FC} from 'react';
import Paper from "../../../containers/UI-Collection/Elements/Surfaces/Paper";

interface Props {
    zIndex: number
}

const WidgetWrap: FC<Props> = ({children, zIndex}) => {
    return (
        <Paper padding="24px" isHidden={false} margin="0 0 24px" minWidth="100%" zIndex={zIndex} minHeight={200}>
            {children}
        </Paper>
    );
};

export default WidgetWrap;
