import React from "react";
import {useDentalCard} from "../../DentalRecords";
import {GeneratedTeethMapConfig, SDentalCard} from "../../types";
import {INITIAL_SURVEY} from "../../../../common/constants/DentalRecords";
import {
    DentalCardButton,
    WrapDentalCardButtons,
    WrapDentalCardButton,
    WrapCheckLoading,
    WrapConfirmButtons
} from "./styledLoadDiactocatButton";
import ServerCommand from "../../../../common/server/server-command";
import Loader from "../Loader/Loader";
import SVG from "../../../../common/helpers/SVG";
import _ from "underscore";
import Button from "../../../../components-ui/Inputs/Button/Button";
import useLoader, {PropsError, ErrorDescription} from "../../../Errors";


export default function LoadDiacnocatButton() {
    const [loader, setLoader] = useLoader();
    const {visitId, parentComponentName, onLoadDiacnocat, config, onClickConfirmButton, onClickConfirmAllButton,
        handleError, cardNumber, editField, dataLoaded, setDataLoaded} = useDentalCard() as SDentalCard;

    if (parentComponentName !== INITIAL_SURVEY) return null;

    let isSelected = false;
    let isUnconfirmed = false;
    _.each(config, (jaw) => {
        jaw.forEach(tooth => {
            if (tooth.selected && tooth.unconfirmed) isSelected = true;
            if (tooth.unconfirmed) isUnconfirmed = true;
        })
    })

    return (
        <WrapDentalCardButtons>
            <WrapDentalCardButton>
                <DentalCardButton variant={loader || editField ? "disabled" : "default"}
                                  size="sm"
                                  children={loader ? "Загрузка данных" : "Загрузить из Диагнокат"}
                                  onClick={(e: any) => {
                                      e.preventDefault();
                                      setLoader(true);
                                      ServerCommand.post('initial-survey/load-dental-analysis',
                                          {visitId})
                                          .then((response: { generatedTeethMapConfig: GeneratedTeethMapConfig }) => {
                                              onLoadDiacnocat(response.generatedTeethMapConfig)
                                              setLoader(false);
                                              setDataLoaded(true);
                                          }, (error: PropsError) => {
                                              handleError(ErrorDescription(error, cardNumber));
                                              setLoader(false);
                                          })
                                  }}
                />
                {!dataLoaded && <Loader visible={loader}/>}
                {dataLoaded && (
                    <WrapCheckLoading>
                        <SVG name="check" className="check-loading-svg"/>
                        <div className="check-loading-text">
                            Загружено
                        </div>
                    </WrapCheckLoading>
                )}
            </WrapDentalCardButton>
            {dataLoaded && isUnconfirmed && (
                <WrapConfirmButtons>
                    <Button variant={isSelected ? "default" : "disabled"}
                            size="sm"
                            onClick={() => onClickConfirmButton()}
                            children="Подтвердить выбранные"/>
                    <Button variant="default"
                            size="sm"
                            onClick={() => onClickConfirmAllButton()}
                            children="Подтвердить все"/>
                </WrapConfirmButtons>
            )}
        </WrapDentalCardButtons>
    )
}
