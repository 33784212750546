import React from "react";
import Select from "../../../../../../../components-ui/Inputs/Select/Select";
import {usePatientField} from "../../../../PatientField";
import {useComposite} from "../EditComposite";
import useWidthInput from "../../../../../hooks/useWidthInput";
import {Label} from "../../../../../../../components-ui/DataDisplay/Label";
import useChangePhoneOwner from "../../../../../hooks/useChangePhoneOwner";
import {IPatientField} from "../../../../../types/fields";

export default function EnumEdit() {
    const {composite, onChangeField, onChangeValidationItem, value, currentPatient} = usePatientField()
    const {subField} = useComposite()
    const field = !!composite ? composite[subField].field : {} as IPatientField
    const widthSelect = useWidthInput(field)
    useChangePhoneOwner({field})

    if (!!composite) {
        let isDisabled = false;
        if (field.name === 'phoneOwner') isDisabled = !!field.disabled
        return (
            <Label labelText={field.title}
                   isRequired={field.required}
                   colorLabel="textGray"
                   align="topLeft"
                   widthLabelWrap={widthSelect}
            >
                <Select options={field.variants}
                        value={value[subField]}
                        placeholder={field.placeholder}
                        widthSelect={widthSelect}
                        widthMenu={!!widthSelect && !!currentPatient ? `calc(36px + ${widthSelect})` : undefined}
                        isDisabled={isDisabled}
                        isError={composite[subField].error}
                        onChange={(newValue: string) => {
                            onChangeField(newValue, field.name)
                            if (!!onChangeValidationItem) onChangeValidationItem(field.name)
                        }}/>
            </Label>

        )
    } else {
        return null
    }

}
