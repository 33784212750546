import React from 'react';
import Notifications from "../Notifications/Notifications";
import NavMenu from "../NavMenu";
import Header from "../Header/Header";
import {MainContent} from "../../components-ui/styledComponent/common/commonStyle";
import ScrollArea from "../../components/ScrollArea/ScrollArea";
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from "react-dnd-html5-backend";
import DialogsManager from "../DialogsManager/DialogsManager";
import useEcho from "./hooks/useEcho";
import {Route} from "react-router-dom";
import ScheduleReducer from "../../pages/Schedule/ScheduleReducer";
import PriceList from "../../pages/PriceList/PriceList";
import PatientsList from "../../pages/PatientsList";
import Materials from "../../pages/Materials/Materials";
import GraphPage from "../../pages/GraphPage/GraphPage";
import Reports from "../../pages/Reports";
import Analytics from "../../pages/Analytics";
import Helper from "../../common/helpers/main";
import TestingDashboard from "../../pages/Testing/TestingDashboard";
import UI from "../../pages/UI-Collection/UI";
import XrayPicturesPage from '../../pages/XRayPicturesPage';
import XrayRatePage from '../../pages/XrayRatePage';
import SalariesPage from '../../pages/Salaries';
import LaboratoryPage from '../../pages/LaboratoryPage';
import LaboratoryPricelistPage from '../../pages/LaboratoryPricelistPage';
import LaboratoryServiceRatesPage from '../../pages/LaboratoryServiceRatesPage';
import SalariesTimeSheetPage from '../../pages/SalariesTimesheet';
import LaboratoryDoneServicesPage from '../../pages/LaboratoryDoneServices';
import SettingsEmployeesPage from '../../pages/Settings/Employees';
import SettingsKkmPage from "../../pages/Settings/Kkm";
import ReportPayments from "../../pages/ReportPayments";
import SettingsHandsetPage from "../../pages/Settings/Handsets";

const Layout = () => {

    useEcho()

    return (
        <DndProvider backend={HTML5Backend}>
            <DialogsManager>
                <>
                    <Notifications/>
                    <NavMenu/>
                    <MainContent>
                        <Header/>
                        <>
                            <Route path='/' exact render={props => <ScheduleReducer {...props}/>}/>
                            <Route path='/pricelist' render={props => <PriceList {...props}/>}/>
                            <Route path='/patients-list' component={PatientsList}/>
                            <Route path='/materials' render={props => <Materials{...props}/>}/>
                            <Route path='/graph' component={GraphPage}/>
                            <Route path='/reports' component={Reports}/>
                            <Route path='/xray/pictures' component={XrayPicturesPage}/>
                            <Route path='/xray/rate' component={XrayRatePage}/>
                            <Route path='/analytics' component={Analytics}/>
                            <Route path='/salaries' exact component={SalariesPage}/>
                            <Route path='/salaries/timesheet' exact component={SalariesTimeSheetPage}/>
                            <Route path='/laboratories' exact component={LaboratoryPage}/>
                            <Route path='/laboratories/pricelist' component={LaboratoryPricelistPage}/>
                            <Route path='/laboratories/service-rates' component={LaboratoryServiceRatesPage}/>
                            <Route path='/laboratories/done-services' component={LaboratoryDoneServicesPage}/>
                            {Helper.isEnabledTestingDashboard() && <Route path='/test' component={TestingDashboard}/>}
                            <Route path='/ui' component={UI}/>
                            <Route path='/settings/employees' component={SettingsEmployeesPage}/>
                            <Route path='/settings/kkm' component={SettingsKkmPage}/>
                            <Route path='/settings/handset' component={SettingsHandsetPage}/>
                            <Route path='/payment-report' component={ReportPayments}/>
                        </>
                    </MainContent>
                    <ScrollArea/>
                </>
            </DialogsManager>
        </DndProvider>
    )
}

export default Layout
