import React from "react";
import PropTypes from "prop-types";
import Table from "../../../../components/Table/Table";
import Button from "../../../../components-ui/OldButton/Button";
import moment from "moment";
import Helper from "../../../../common/helpers/main";
import PopupWriteOff from "./PopupWriteOff";
import ServerCommand from "../../../../common/server/server-command";
import {DialogsManagerContext} from "../../../../containers/DialogsManager/DialogsManager";


export default class ViewWriteOff extends React.Component {
    static contextType = DialogsManagerContext;

    resultColumns = [
        {
            title: 'Материал',
            code: 'name'
        },
        {
            title: 'Количество',
            code: 'quantity',
        },
        {
            title: 'Ед. измерения',
            code: 'measureShortName',
        },
        {
            title: 'Причина',
            code: 'reason',
        },
    ];

    static propTypes = {
        materials: PropTypes.array.isRequired,
        materialsGroups: PropTypes.object.isRequired,
        id: PropTypes.number.isRequired,
        reload: PropTypes.func,
        reasons: PropTypes.object,
    };

    state = {
        detail: null
    };

    componentDidMount() {
        this.loadData();
    }


    render() {

        if (this.state.detail === null || this.props.materials === null) {
            return null
        }

        return (
            <div className="view-write-off">
                <div className="header-popup__title">Накладная (№ {this.state.detail.id})
                    от {moment(this.state.detail.date).format('DD MMMM YYYY')}</div>
                <div className="box">
                    <div className="box-body">

                        <div className="box">
                            <div className="treatment-plan__result-table">
                                <Table columns={this.resultColumns}
                                       data={ Object.values(this.createsTableData()) }
                                       selectMode="none"
                                />
                            </div>
                        </div>
                        <div className="box__btns">
                            <Button variant='default' text="Редактировать" size="long"
                                    onClick={() => this.context.showSlideModal(<PopupWriteOff
                                        reload={this.props.reload}
                                        viewReload={this.loadData.bind(this)}
                                        materials={this.props.materials}
                                        materialsGroups={this.props.materialsGroups}
                                        detail={this.state.detail}
                                        reasons={this.props.reasons}
                                    />)}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /* Обходит цикл внутри цикла. Создает из двух массивов объект */
    createsTableData() {
        let data = {};
        const materials = Helper.clone(this.props.materials);
        const detail = Helper.clone(this.state.detail);
        if (detail.items !== null) {
            detail.items.forEach((item) => {
                data[item.materialId] = item;
                data[item.materialId].reason = this.props.reasons[item.reason];
            });
        }

        materials.forEach((material) => {
            if (data[material.id] !== undefined) {
                data[material.id].name = material.name;
            }
        });

        return data
    }

    /** Загрузка данных с сервера и в случаи необходимости обновление данных */
    loadData() {
        const command = new ServerCommand('material/get-write-off', {
            primary: this.props.id,
            getItems: true
        });
        command.exec().then(response => {
            this.setState({
                detail: response.detail
            })
        })
    }

}
