import React, {useState} from "react";
import TextField from "../../../../../components-ui/Inputs/TextField/TextField";
import {WrapContent} from "../../../styledUI";


const data = [
    {code: 'one', value: 'value-one'},
    {code: 'two', value: 'value-two'},
    {code: 'three', value: 'value-three'},
]

export default function AutocompletePage() {
    const [value, setValue] = useState('');
    return (
        <>
            <h1>Autocomplete</h1>
            <WrapContent>
                <div>
                    <h3>Из styled-components</h3>
                    <h4>variant</h4>
                    <div>
                        <TextField value={value}
                                   onChange={((val: string) => setValue(val))}
                                   dataAutocomplete={data}
                        />
                        <pre>
                        {
                            '<TextField value={value} onChange={((val) => setValue(val))} dataAutocomplete={data}/>'
                        }
                    </pre>
                    </div>
                </div>
            </WrapContent>
        </>
    )
}
